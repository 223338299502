import { contractStateCRUD } from "../../http";
import {
  CONTRACT_STATE_GET_REQUEST,
  CONTRACT_STATE_GET_SUCCESS,
  CONTRACT_STATE_GET_ERROR,
  CONTRACT_STATE_GET_BY_KEY_REQUEST,
  CONTRACT_STATE_GET_BY_KEY_SUCCESS,
  CONTRACT_STATE_GET_BY_KEY_ERROR,
  CONTRACT_STATE_ADD_REQUEST,
  CONTRACT_STATE_ADD_SUCCESS,
  CONTRACT_STATE_ADD_ERROR,
  CONTRACT_STATE_EDIT_REQUEST,
  CONTRACT_STATE_EDIT_SUCCESS,
  CONTRACT_STATE_EDIT_ERROR,
  CONTRACT_STATE_DELETE_REQUEST,
  CONTRACT_STATE_DELETE_SUCCESS,
  CONTRACT_STATE_DELETE_ERROR,
} from "../constants/contractStateConstants";

export const getContractStates = (args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_STATE_GET_REQUEST,
    });

    const data = await contractStateCRUD.search(args);

    dispatch({
      type: CONTRACT_STATE_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: CONTRACT_STATE_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getContractStateByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_STATE_GET_BY_KEY_REQUEST,
    });

    const data = await contractStateCRUD.get(id);
    dispatch({
      type: CONTRACT_STATE_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: CONTRACT_STATE_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addContractState = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_STATE_ADD_REQUEST,
    });

    const response = await contractStateCRUD.create(args);
    await dispatch(getContractStates(args));
    dispatch({
      type: CONTRACT_STATE_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: CONTRACT_STATE_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editContractState = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_STATE_EDIT_REQUEST,
    });

    await contractStateCRUD.edit(data);
    await dispatch(getContractStates(args));
    dispatch({
      type: CONTRACT_STATE_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CONTRACT_STATE_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteContractState = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_STATE_DELETE_REQUEST,
    });

    await contractStateCRUD.delete(id);
    await dispatch(getContractStates(args));

    dispatch({
      type: CONTRACT_STATE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CONTRACT_STATE_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
