import { Grid, CircularProgress } from "@mui/material";
import DashboardLayout from "./Dashboard";

const LoadingLayout = ({ children, loading }) => {
    return (
        <DashboardLayout>
            {loading ?
            <Grid item xs={12}>
                <CircularProgress/>
            </Grid> :

            <>
            {children}
            </>
            }
        </DashboardLayout>
    )
}

export default LoadingLayout;