import React, { useState } from "react";
import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import SimpleTable from "../SimpleTable";
import Toolbar from "@mui/material/Toolbar";
import DictionaryCRUDTable from "../CRUD/Tables/DictionaryCRUDTable";
import { asJSONSafe } from "../../functions";

export default function DictionaryField({
  entityType,
  label,
  dialogTitle,
  value,
  object,
  onChange,
  extraFilter,
  disabled,
}) {
  const [open, setOpen] = useState(false);

  try {
    if (!object)
      object = "";
    object = asJSONSafe(object);
    if (!object.fullName && !object.name && !object.Name) object = "";
  } catch (e) {
    console.error(e);
  }

  return (
    <>
      <Grid container spacing={1}>
        {/* Field Line */}
        <Grid item xs={12}>
          <Toolbar>
            <Typography style={{ fontWeight: "bold", width: "50%" }}>
              {label || "Справочник"}:{" "}
            </Typography>
            <Typography>
              {object?.fullName || object?.name || object?.Name || object}
            </Typography>
            <Button onClick={() => setOpen(true)} disabled={disabled} variant={"outlined"} style={{ marginLeft: "15px" }}>
              Выбрать
            </Button>
          </Toolbar>
        </Grid>
      </Grid>
      {/* Dialog Select*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"80%"}
      >
        <DialogTitle>Выбор {dialogTitle || "записи"}</DialogTitle>
        <Divider />
        <DialogContent>
          <DictionaryCRUDTable
            entityType={entityType}
            extraFilter={extraFilter}
            onClickCreateButton={undefined} // что бы не показывать кнопку создания
            onRowClick={(record, rowIndex) => {
              onChange(record);
              setOpen(false);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"text"}
            style={{ marginLeft: "auto" }}
            onClick={() => setOpen(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
