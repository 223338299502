import { withoutDepartureRequestCRUD } from "../../http";
import {
  WITHOUT_DEPARTURE_REQUEST_GET_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_GET_SUCCESS,
  WITHOUT_DEPARTURE_REQUEST_GET_ERROR,
  WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_SUCCESS,
  WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_ERROR,
  WITHOUT_DEPARTURE_REQUEST_ADD_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_ADD_SUCCESS,
  WITHOUT_DEPARTURE_REQUEST_ADD_ERROR,
  WITHOUT_DEPARTURE_REQUEST_EDIT_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_EDIT_SUCCESS,
  WITHOUT_DEPARTURE_REQUEST_EDIT_ERROR,
  WITHOUT_DEPARTURE_REQUEST_DELETE_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_DELETE_SUCCESS,
  WITHOUT_DEPARTURE_REQUEST_DELETE_ERROR,
  WITHOUT_DEPARTURE_REQUEST_WORKFLOW_UPDATE_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  WITHOUT_DEPARTURE_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/withoutDepartureRequestConstants";

export const getWithoutDepartureRequests = (args) => async (dispatch) => {
  if (args == null)
    return;

  try {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_GET_REQUEST,
    });

    const data = await withoutDepartureRequestCRUD.search(args);

    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWithoutDepartureRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await withoutDepartureRequestCRUD.get(id);
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addWithoutDepartureRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_ADD_REQUEST,
    });

    const response = await withoutDepartureRequestCRUD.create(data);
    await dispatch(getWithoutDepartureRequests(args));
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editWithoutDepartureRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_EDIT_REQUEST,
    });

    await withoutDepartureRequestCRUD.edit(data);
    await dispatch(getWithoutDepartureRequests(args));

    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteWithoutDepartureRequest = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_DELETE_REQUEST,
    });

    await withoutDepartureRequestCRUD.delete(id);
    await dispatch(getWithoutDepartureRequests(args));

    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: WITHOUT_DEPARTURE_REQUEST_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const workflowWithoutDepartureRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: WITHOUT_DEPARTURE_REQUEST_WORKFLOW_UPDATE_REQUEST });

      const response = await withoutDepartureRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      await dispatch({
        type: WITHOUT_DEPARTURE_REQUEST_WORKFLOW_UPDATE_SUCCESS,
      });

      console.log({ response });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: WITHOUT_DEPARTURE_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
