
export function svgToPng(svgSrc, width, height, onPng){
    var img = new Image();
    img.setAttribute('src', svgSrc);
    img.setAttribute('name', 'logo-svg');
    img.setAttribute('width', width);
    img.setAttribute('height', height);
    var canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.style.width = width;
    canvas.style.height = height;
    var ctxt = canvas.getContext('2d');
    ctxt.fillStyle = "rgba(0, 0, 0, 0)";
    ctxt.fillRect(0,0,width,height);
    img.onload = () => {
        ctxt.drawImage(img, 0, 0, 93.3, 102.9);
        const png = canvas.toDataURL();
        onPng(png);
    };
}