import { geoObjectCRUD } from "../../http/CRUD";
import {
  GEO_OBJECT_GET_REQUEST,
  GEO_OBJECT_GET_SUCCESS,
  GEO_OBJECT_GET_ERROR,
  GEO_OBJECT_GET_BY_KEY_REQUEST,
  GEO_OBJECT_GET_BY_KEY_SUCCESS,
  GEO_OBJECT_GET_BY_KEY_ERROR,
  GEO_OBJECT_ADD_REQUEST,
  GEO_OBJECT_ADD_SUCCESS,
  GEO_OBJECT_ADD_ERROR,
  GEO_OBJECT_EDIT_REQUEST,
  GEO_OBJECT_EDIT_SUCCESS,
  GEO_OBJECT_EDIT_ERROR,
  GEO_OBJECT_DELETE_REQUEST,
  GEO_OBJECT_DELETE_SUCCESS,
  GEO_OBJECT_DELETE_ERROR,
} from "../constants/geoObjectConstants";

export const getGeoObjects = (args) => async (dispatch) => {
  try {
    dispatch({
      type: GEO_OBJECT_GET_REQUEST,
    });

    const data = await geoObjectCRUD.search(args);
    dispatch({
      type: GEO_OBJECT_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: GEO_OBJECT_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getGeoObjectByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GEO_OBJECT_GET_BY_KEY_REQUEST,
    });

    const { data } = await geoObjectCRUD.get(`api/v1/geoObject/${id}`);
    dispatch({
      type: GEO_OBJECT_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: GEO_OBJECT_GET_BY_KEY_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const addGeoObject =
  (
    {
      name,
      code,
      description,
      positionLatitude,
      positionLongitude,
      parentId,
      typeId,
    },
    queryArgs
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GEO_OBJECT_ADD_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await geoObjectCRUD.post(
        "api/v1/geoObject",
        {
          name,
          code,
          description,
          positionLatitude,
          positionLongitude,
          parentId,
          typeId,
        },
        config
      );
      dispatch(getGeoObjects(queryArgs || {}));
      dispatch({
        type: GEO_OBJECT_ADD_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: GEO_OBJECT_ADD_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };

export const editGeoObject =
  (
    {
      id,
      name,
      code,
      description,
      positionLatitude,
      positionLongitude,
      parentId,
      typeId,
    },
    queryArgs
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: GEO_OBJECT_EDIT_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await geoObjectCRUD.put(
        "api/v1/geoObject",
        {
          id,
          name,
          code,
          description,
          positionLatitude,
          positionLongitude,
          parentId,
          typeId,
        },
        config
      );
      dispatch(getGeoObjects(queryArgs || {}));
      dispatch({
        type: GEO_OBJECT_EDIT_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: GEO_OBJECT_EDIT_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };

export const deleteGeoObject = (id, queryArgs) => async (dispatch) => {
  try {
    dispatch({
      type: GEO_OBJECT_DELETE_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await geoObjectCRUD.delete("api/v1/geoObject", { data: { id } }, config);
    dispatch(getGeoObjects(queryArgs || {}));
    dispatch({
      type: GEO_OBJECT_DELETE_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: GEO_OBJECT_DELETE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};
