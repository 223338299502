export const DESINSECTION_REQUEST_GET_REQUEST =
  "DESINSECTION_REQUEST_GET_REQUEST";
export const DESINSECTION_REQUEST_GET_SUCCESS =
  "DESINSECTION_REQUEST_GET_SUCCESS";
export const DESINSECTION_REQUEST_GET_ERROR = "DESINSECTION_REQUEST_GET_ERROR";
export const DESINSECTION_REQUEST_GET_BY_KEY_REQUEST =
  "DESINSECTION_REQUEST_GET_BY_KEY_REQUEST";
export const DESINSECTION_REQUEST_GET_BY_KEY_SUCCESS =
  "DESINSECTION_REQUEST_GET_BY_KEY_SUCCESS";
export const DESINSECTION_REQUEST_GET_BY_KEY_ERROR =
  "DESINSECTION_REQUEST_GET_BY_KEY_ERROR";
export const DESINSECTION_REQUEST_ADD_REQUEST =
  "DESINSECTION_REQUEST_ADD_REQUEST";
export const DESINSECTION_REQUEST_ADD_SUCCESS =
  "DESINSECTION_REQUEST_ADD_SUCCESS";
export const DESINSECTION_REQUEST_ADD_ERROR = "DESINSECTION_REQUEST_ADD_ERROR";
export const DESINSECTION_REQUEST_EDIT_REQUEST =
  "DESINSECTION_REQUEST_EDIT_REQUEST";
export const DESINSECTION_REQUEST_EDIT_SUCCESS =
  "DESINSECTION_REQUEST_EDIT_SUCCESS";
export const DESINSECTION_REQUEST_EDIT_ERROR =
  "DESINSECTION_REQUEST_EDIT_ERROR";
export const DESINSECTION_REQUEST_DELETE_REQUEST =
  "DESINSECTION_REQUEST_DELETE_REQUEST";
export const DESINSECTION_REQUEST_DELETE_SUCCESS =
  "DESINSECTION_REQUEST_DELETE_SUCCESS";
export const DESINSECTION_REQUEST_DELETE_ERROR =
  "DESINSECTION_REQUEST_DELETE_ERROR";

export const CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST =
  "CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST";
export const CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS =
  "CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS";
export const CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR =
  "CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR";
