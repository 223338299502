import React from "react";
import DashboardLayout from "../../../layouts/Dashboard";
import {PrescriptionCRUDTable} from "../../../components/CRUD/Tables/PrescriptionCRUDTable";

const Prescription = () => {
  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
        <PrescriptionCRUDTable/>
    </DashboardLayout>
  );
};

export default Prescription;
