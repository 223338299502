import { 
    POSITION_GET_REQUEST, POSITION_GET_SUCCESS, POSITION_GET_ERROR, 
    POSITION_GET_BY_KEY_REQUEST, POSITION_GET_BY_KEY_SUCCESS, POSITION_GET_BY_KEY_ERROR, 
    POSITION_ADD_REQUEST, POSITION_ADD_SUCCESS, POSITION_ADD_ERROR, 
    POSITION_EDIT_REQUEST, POSITION_EDIT_SUCCESS, POSITION_EDIT_ERROR, 
    POSITION_DELETE_REQUEST, POSITION_DELETE_SUCCESS, POSITION_DELETE_ERROR
} from "../constants/positionConstants";

const initialState = {
    positions: [],
    position: {},
    loading: false,
    success: null,
    error: null,
    positionRequestedCount: 0,
};

export const positionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case POSITION_GET_REQUEST:
            return {
                ...state,
                positionRequestedCount: ++state.positionRequestedCount,
                loading: true,
            };
        case POSITION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                positions: action.payload,
                success: true,
            };
        case POSITION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case POSITION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case POSITION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                position: action.payload,
                success: true,
            };
        case POSITION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case POSITION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case POSITION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case POSITION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case POSITION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case POSITION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case POSITION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case POSITION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case POSITION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case POSITION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
