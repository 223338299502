import { 
    REGIONAL_FILIAL_GET_REQUEST, REGIONAL_FILIAL_GET_SUCCESS, REGIONAL_FILIAL_GET_ERROR, 
    REGIONAL_FILIAL_GET_BY_KEY_REQUEST, REGIONAL_FILIAL_GET_BY_KEY_SUCCESS, REGIONAL_FILIAL_GET_BY_KEY_ERROR, 
    REGIONAL_FILIAL_ADD_REQUEST, REGIONAL_FILIAL_ADD_SUCCESS, REGIONAL_FILIAL_ADD_ERROR, 
    REGIONAL_FILIAL_EDIT_REQUEST, REGIONAL_FILIAL_EDIT_SUCCESS, REGIONAL_FILIAL_EDIT_ERROR, 
    REGIONAL_FILIAL_DELETE_REQUEST, REGIONAL_FILIAL_DELETE_SUCCESS, REGIONAL_FILIAL_DELETE_ERROR
} from "../constants/regionalFilialConstants";

const initialState = {
    regionalFilials: [],
    regionalFilial: {},
    loading: false,
    success: null,
    error: null,
    regionalFilialRequestedCount: 0,
};

export const regionalFilialReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REGIONAL_FILIAL_GET_REQUEST:
            return {
                ...state,
                regionalFilialRequestedCount: ++state.regionalFilialRequestedCount,
                loading: true,
            };
        case REGIONAL_FILIAL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                regionalFilials: action.payload,
                success: true,
            };
        case REGIONAL_FILIAL_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REGIONAL_FILIAL_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REGIONAL_FILIAL_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                regionalFilial: action.payload,
                success: true,
            };
        case REGIONAL_FILIAL_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REGIONAL_FILIAL_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REGIONAL_FILIAL_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REGIONAL_FILIAL_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REGIONAL_FILIAL_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REGIONAL_FILIAL_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REGIONAL_FILIAL_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REGIONAL_FILIAL_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REGIONAL_FILIAL_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REGIONAL_FILIAL_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
