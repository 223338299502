import { 
    MEDICAL_ORDER_GET_REQUEST, MEDICAL_ORDER_GET_SUCCESS, MEDICAL_ORDER_GET_ERROR, 
    MEDICAL_ORDER_GET_BY_KEY_REQUEST, MEDICAL_ORDER_GET_BY_KEY_SUCCESS, MEDICAL_ORDER_GET_BY_KEY_ERROR, 
    MEDICAL_ORDER_ADD_REQUEST, MEDICAL_ORDER_ADD_SUCCESS, MEDICAL_ORDER_ADD_ERROR, 
    MEDICAL_ORDER_EDIT_REQUEST, MEDICAL_ORDER_EDIT_SUCCESS, MEDICAL_ORDER_EDIT_ERROR, 
    MEDICAL_ORDER_DELETE_REQUEST, MEDICAL_ORDER_DELETE_SUCCESS, MEDICAL_ORDER_DELETE_ERROR
} from "../constants/medicalOrderConstants";

const initialState = {
    medicalOrders: [],
    medicalOrder: {},
    loading: false,
    success: null,
    error: null,
    medicalOrderRequestedCount: 0,
};

export const medicalOrderReducer = (state = initialState, action) => {
    switch (action.type)     {
        case MEDICAL_ORDER_GET_REQUEST:
            return {
                ...state,
                medicalOrderRequestedCount: ++state.medicalOrderRequestedCount,
                loading: true,
            };
        case MEDICAL_ORDER_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalOrders: action.payload,
                success: true,
            };
        case MEDICAL_ORDER_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case MEDICAL_ORDER_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MEDICAL_ORDER_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                medicalOrder: action.payload,
                success: true,
            };
        case MEDICAL_ORDER_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case MEDICAL_ORDER_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MEDICAL_ORDER_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case MEDICAL_ORDER_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case MEDICAL_ORDER_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MEDICAL_ORDER_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case MEDICAL_ORDER_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case MEDICAL_ORDER_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case MEDICAL_ORDER_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case MEDICAL_ORDER_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
