import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";

import {
    formatDate,
    MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../../functions/formatters";
import {contractRequestCRUD} from "../../../../http/CRUD";
import {
    CONTRACT_REQUEST,
    CONTRACT_REQUEST_CREATE,
} from "../../../../routes/const";
import {EnhancedCRUDTable} from "../../EnhancedCRUDTable";
import CRUDTable from "../../CRUDTable";
import {getProtocolForms} from "../../../../redux/actions/protocolFormActions";
import {getDepartments, getDivisions, getFilials} from "../../../../redux/actions";
import {getFilialDivisions} from "../../../../redux/actions/filialDivisionActions";
import {actsSearchFields} from "../../../../sections/Filters/ActsFilters";
import {getRequestStates} from "../../../../redux/actions/requestStateActions";

export const ContractRequestCRUDTable = (props) => {
    const {t} = useTranslation();
    const {push} = useHistory();
    const {pathname} = useLocation();

    const columns = [
        {title: t("request.tableItem.id"), dataIndex: "id", key: "id"},
        {
            title: t("request.tableItem.number"),
            dataIndex: "number",
            key: "number",
        },
        {
            title: t("request.tableItem.contractId"),
            dataIndex: "contractId",
            key: "contractId",
            render: (row) => row || "-",
        },
        {
            title: t("request.tableItem.status"),
            dataIndex: "state",
            key: "state",
            render: (row) => row?.name || "-",
        },
        {
            title: t("request.tableItem.createdBy"),
            dataIndex: "createdBy",
            key: "createdBy",
            render: (row) => row?.fullName || "-",
        },
        {
            title: 'Наименование клиента',
            dataIndex: "client",
            key: "client",
            render: (row) => row?.legalPerson ? row.legalPerson?.name : row?.person ? row.person?.fullName : "-",
        },
        {
            title: 'Исполнитель заявки',
            dataIndex: "performer",
            key: "performer",
            render: (row) => row?.fullName || "-",
        },
        {
            title: t("request.tableItem.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (row) => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) || "-",
        },
        {
            dataIndex: "filial",
            key: "filial",
            title: "Филиал",
            render: (unknown, row) => {
                return row.filial?.name
            }
        },
        {
            dataIndex: "filialDivision",
            key: "filialDivision",
            title: "Районное отделение",
            render: (unknown, row) => {
                return row.filialDivision?.name
            }
        }
    ];

    const searchFields = [
        ...actsSearchFields,
    ];

    const filterFields = [
        {
        title: "Cтатус",
        key: "stateId",
        compareType: "equals",
        reducerKey: "requestState",
        reducerArrayKey: "requestStates",
        reducerAction: getRequestStates,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            operandPosition: 1,
            parentKey: 'filialId',
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ];


    return (
        <CRUDTable
            searchFields={searchFields}
            filterFields={filterFields}
            search={contractRequestCRUD.search}
            columns={columns}
            onClickDetailsButton={(id) => {
                push(`${pathname}/${id}`)
            }}
            {...props}
        />
    );
};
