import { 
    CONTRACT_GET_REQUEST, CONTRACT_GET_SUCCESS, CONTRACT_GET_ERROR, 
    CONTRACT_GETIN10YEAR_REQUEST, CONTRACT_GETIN10YEAR_SUCCESS, CONTRACT_GETIN10YEAR_ERROR, 
    CONTRACT_GET_BY_KEY_REQUEST, CONTRACT_GET_BY_KEY_SUCCESS, CONTRACT_GET_BY_KEY_ERROR, 
    CONTRACT_ADD_REQUEST, CONTRACT_ADD_SUCCESS, CONTRACT_ADD_ERROR, 
    CONTRACT_EDIT_REQUEST, CONTRACT_EDIT_SUCCESS, CONTRACT_EDIT_ERROR, 
    CONTRACT_DELETE_REQUEST, CONTRACT_DELETE_SUCCESS, CONTRACT_DELETE_ERROR
} from "../constants/contractConstants";

const initialState = {
    contracts: [],
    contractsIn10Year: [],
    contract: {},
    contractIn10Year: {},
    loading: false,
    success: null,
    error: null,
    contractRequestedCount: 0,
    contractIn10YearRequestedCount: 0,
};

export const contractReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_GET_REQUEST:
            return {
                ...state,
                contractRequestedCount: ++state.contractRequestedCount,
                loading: true,
            };
        case CONTRACT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contracts: action.payload,
                success: true,
            };
        case CONTRACT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_GETIN10YEAR_REQUEST:
            return {
                ...state,
                contractIn10YearRequestedCount: ++state.contractIn10YearRequestedCount,
                loading: true,
            };
        case CONTRACT_GETIN10YEAR_SUCCESS:
            return {
                ...state,
                loading: false,
                contractsIn10Year: action.payload,
                success: true,
            };
        case CONTRACT_GETIN10YEAR_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contract: action.payload,
                success: true,
            };
        case CONTRACT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
