/* eslint-disable import/no-anonymous-default-export */
export default {
  '43': {
    name: 'ПРОТОКОЛ исследования образцов на санитарную вирусологию',
    kazakhName: 'Санитариялық вирусологияға үлгілерді зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'probe_name',
        type: 'text',
        label: 'наименование пробы',
        kazakhLabel: 'Сынаманың атауы'
      },
      {
        id: 'sample_obtain_place',
        type: 'text',
        label: 'Место взятия образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата отбора',
        kazakhLabel: 'Үлгінің алынған күні'
      },
      {
        id: 'expiration_date',
        type: 'text',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'reaction_form',
        type: 'text',
        label: 'Вид реакции',
        kazakhLabel: 'Реакцияның түрі'
      },
      {
        id: 'forwarder_name',
        type: 'text',
        label: 'Наименование учреждения направившего образец',
        kazakhLabel: 'Үлгіні жіберген мекеменің атауы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Метод исследования'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результат исследования',
        kazakhLabel: 'Зерттеу нәтижесі'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      }
    ]
  },
  '44': {
    version: 2,
    name: 'ПРОТОКОЛ исследований образцов вид инфекции',
    kazakhName: 'үлгілерді зерттеу (инфекция түрі) ХАТТАМАСЫ',
    fields: [
      {
        id: 'infection_type',
        type: 'text',
        label: 'Вид инфекции',
        kazakhLabel: 'инфекция түрі'
      },
      {
        id: 'full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество обследуемого',
        kazakhLabel: 'Зерттелушінің тегі, аты, әкесінің аты'
      },
      {
        id: 'age',
        type: 'text',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен жайы'
      },
      {
        id: 'work_place',
        type: 'text',
        label: 'Место работы',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        id: 'forwarder_name',
        type: 'text',
        label: 'Наименование учреждения направивший образец',
        kazakhLabel: 'Үлгіні жіберген мекеменің атауы'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'research_variant',
        type: 'text',
        label: 'при исследовании: мазок из зева, носа, ликвор, проба крови, фекалии, взятые',
        kazakhLabel: 'Зерттелгенде: аңқадан, мұрыннан алынған жағынды, жұлын сұйығы, қан үлгісі, нәжіс'
      },
      {
        id: 'sick_day',
        type: 'text',
        label: 'на день болезни',
        kazakhLabel: 'ауыру күнінде'
      },
      {
        id: 'cell_culture',
        type: 'text',
        label: 'на культуре клеток получен',
        kazakhLabel: 'клетка тiрi өсіріндісiнде алынды'
      },
      {
        id: 'virus_detected',
        type: 'text',
        label: 'Выделен вирус',
        kazakhLabel: 'Бөлінген вирус'
      },
      {
        id: 'with_antigen',
        type: 'text',
        label: 'При исследовании с антигенами',
        kazakhLabel: 'Антигендермен зерттеу жүргізу барысында'
      },
      {
        id: 'diagnosis_increase',
        type: 'text',
        label: 'Диагностический прирост антител обнаружен к',
        kazakhLabel: 'Қарсы денелердің диагностикалық өсуі анықталды'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата забора материала',
        kazakhLabel: 'Материалды алу күні'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=990 style='border-collapse:
        collapse;table-layout:fixed;width:743pt'>
        <col width=64 style='width:48pt'>
        <col width=193 style='mso-width-source:userset;mso-width-alt:6855;width:145pt'>
        <col width=64 style='width:48pt'>
        <col width=477 style='mso-width-source:userset;mso-width-alt:16952;width:358pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1515638 width=64 style='height:26.4pt;width:48pt'></td>
         <td class=xl6715638 width=193 style='width:145pt'>&#1178;ан &#1199;лгісі</td>
         <td class=xl6915638 width=64 style='border-left:none;width:48pt'>Ауыру
         к&#1199;ні</td>
         <td colspan=4 class=xl6915638 width=669 style='border-right:1.0pt solid black;
         border-left:none;width:502pt'>Вирус&#1179;а &#1179;арсы иденелер титрі</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1515638 style='height:26.4pt'></td>
         <td class=xl6815638 width=193 style='border-top:none;width:145pt'>образец
         крови</td>
         <td class=xl6615638 width=64 style='border-top:none;border-left:none;
         width:48pt'>День болезни</td>
         <td colspan=4 class=xl6615638 width=669 style='border-right:1.0pt solid black;
         border-left:none;width:502pt'>Титры антител к вирусу</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1515638 style='height:15.0pt'></td>
         <td class=xl6315638 width=193 style='border-top:none;width:145pt'>1</td>
         <td class=xl6415638 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6415638 width=477 style='border-top:none;border-left:none;
         width:358pt'>3</td>
         <td class=xl6415638 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6415638 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6515638 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
        <tr height=24 style='height:18.0pt'>
         <td height=24 class=xl1515638 style='height:18.0pt'></td>
         <td colspan=6 class=xl7015638>_________________________________________________________________________</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="131"><col width="115"><col width="90"><col width="65"><col width="65"><col width="65"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="131" height="80" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қан үлгісі<br>образец крови</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауыру күні<br>День болезни</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="4" width="284" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Вирусқа қарсы иденелер титрі<br>Титры антител к вирусу</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Зерттеу НҚ-ға сәйкестiкке жүргiзiлдi'
      },
    ]
  },
  '45': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности древесного сырья и изделий из дерева',
    kazakhName: 'Ағаш шикізаты және ағаштан жасалған бұйымдардың радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'sample_obtaining_place',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата поступлення образца',
        kazakhLabel: 'Үлгінің келіп түсу уақыты'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором №',
        kazakhLabel: 'Зерттеу жүргізілген құрал №'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке № (от) 20 г.',
        kazakhLabel: 'Сәйкестігі туралы куәлік № (от) 20 ж.'
      },
      {
        id: 'measurement_results',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД (Результаты измерений)',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi Өлшеуер нәтижелері'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1122 style='border-collapse:
        collapse;table-layout:fixed;width:841pt'>
        <col width=64 style='width:48pt'>
        <col width=280 style='mso-width-source:userset;mso-width-alt:9955;width:210pt'>
        <col width=64 style='width:48pt'>
        <col width=458 style='mso-width-source:userset;mso-width-alt:16270;width:343pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=158 style='height:118.8pt'>
         <td height=158 class=xl152174 width=64 style='height:118.8pt;width:48pt'></td>
         <td rowspan=2 class=xl722174 width=280 style='width:210pt'>№</td>
         <td class=xl712174 width=64 style='border-left:none;width:48pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl712174 width=458 style='border-left:none;width:343pt'>&#1198;лестік
         тиімді белсенділікCs-137(Бк/кг)</td>
         <td class=xl712174 width=64 style='border-left:none;width:48pt'>&#1198;лестік
         тиімді р&#1201;&#1179;сат етілген белсенділікті&#1187; де&#1187;гейіCs-137
         (Бк/кг)</td>
         <td class=xl712174 width=64 style='border-left:none;width:48pt'>&#1198;лестік
         тиімді белсенділікSr- 90(Бк/кг)</td>
         <td class=xl712174 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl652174 width=64 style='border-left:none;width:48pt'>&#1198;лестік
         тиімді р&#1201;&#1179;сат етілген белсенділікті&#1187; де&#1187;гейіSr- 90
         (Бк/кг)</td>
        </tr>
        <tr height=158 style='height:118.8pt'>
         <td height=158 class=xl152174 style='height:118.8pt'></td>
         <td class=xl702174 width=64 style='border-top:none;border-left:none;
         width:48pt'>Наименование образца</td>
         <td class=xl702174 width=458 style='border-top:none;border-left:none;
         width:343pt'>Удельная эффективная активность Cs-137(Бк/кг)</td>
         <td class=xl702174 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень удельной эффективной активности Cs-137 (Бк/кг)</td>
         <td class=xl702174 width=64 style='border-top:none;border-left:none;
         width:48pt'>Удельная эффективная активность Sr- 90(Бк/кг)</td>
         <td class=xl702174 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl662174 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень удельной эффективной активности Sr- 90 (Бк/кг)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl152174 style='height:15.0pt'></td>
         <td class=xl672174 width=280 style='border-top:none;width:210pt'>1</td>
         <td class=xl682174 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl682174 width=458 style='border-top:none;border-left:none;
         width:343pt'>3</td>
         <td class=xl682174 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl682174 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl682174 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl692174 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="33"><col width="102"><col width="215"><col width="173"><col width="160"><col width="181"><col width="190"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="33" height="154" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="102" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="215" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік тиімді белсенділік <br>Cs-137(Бк/кг)<br>Удельная эффективная активность <br>Cs-137(Бк/кг)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="173" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік тиімді рұқсат етілген белсенділіктің деңгейіCs-137 (Бк/кг) <br>Допустимый уровень удельной эффективной активности Cs-137 (Бк/кг)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="160" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік тиімді белсенділікSr- 90(Бк/кг)<br>Удельная эффективная активность Sr- 90(Бк/кг)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="181" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің <br>НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="190" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік тиімді рұқсат етілген белсенділіктің деңгейіSr- 90 (Бк/кг)<br>Допустимый уровень удельной эффективной активности Sr- 90 (Бк/кг)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '46': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности почвы и растительности',
    kazakhName: 'Топырақ пен өсімдіктердің радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'sample_obtaining_place',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата поступлення образца',
        kazakhLabel: 'Үлгінің келіп түсу уақыты'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором',
        kazakhLabel: 'Зерттеу жүргізілген құрал'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Сәйкестігі туралы куәлік'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1221 style='border-collapse:
        collapse;table-layout:fixed;width:915pt'>
        <col width=64 style='width:48pt'>
        <col width=298 style='mso-width-source:userset;mso-width-alt:10581;width:223pt'>
        <col width=117 style='mso-width-source:userset;mso-width-alt:4152;width:88pt'>
        <col width=230 style='mso-width-source:userset;mso-width-alt:8163;width:172pt'>
        <col width=64 span=8 style='width:48pt'>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl15942 width=64 style='height:14.4pt;width:48pt'></td>
         <td rowspan=2 class=xl71942 width=298 style='width:223pt'><span
         style='mso-spacerun:yes'>  </span>№<span style='mso-spacerun:yes'> </span></td>
         <td rowspan=2 class=xl70942 width=117 style='width:88pt'>&#1198;лгіні&#1187;
         атауы Наименование образца</td>
         <td class=xl70942 width=230 style='border-left:none;width:172pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td rowspan=2 class=xl70942 width=64 style='width:48pt'>Точка отбора</td>
         <td colspan=7 class=xl70942 width=448 style='border-right:1.0pt solid black;
         border-left:none;width:336pt'>Удельная эффективная активность, Бк/кг</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl15942 style='height:52.8pt'></td>
         <td class=xl69942 width=230 style='border-top:none;border-left:none;
         width:172pt'>НД на метод испытаний</td>
         <td class=xl69942 width=64 style='border-top:none;border-left:none;
         width:48pt'>Суммарная альфа- активность</td>
         <td class=xl69942 width=64 style='border-top:none;border-left:none;
         width:48pt'>Суммарная бета-активность</td>
         <td class=xl69942 width=64 style='border-top:none;border-left:none;
         width:48pt'>Cs- 137</td>
         <td class=xl69942 width=64 style='border-top:none;border-left:none;
         width:48pt'>Ra -226</td>
         <td class=xl69942 width=64 style='border-top:none;border-left:none;
         width:48pt'>Th- 232</td>
         <td class=xl69942 width=64 style='border-top:none;border-left:none;
         width:48pt'>К-40</td>
         <td class=xl65942 width=64 style='border-top:none;border-left:none;
         width:48pt'>Sr - 90</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl15942 style='height:15.0pt'></td>
         <td class=xl66942 width=298 style='border-top:none;width:223pt'>1</td>
         <td class=xl67942 width=117 style='border-top:none;border-left:none;
         width:88pt'>2</td>
         <td class=xl67942 width=230 style='border-top:none;border-left:none;
         width:172pt'>3</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>8</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>9</td>
         <td class=xl67942 width=64 style='border-top:none;border-left:none;
         width:48pt'>10</td>
         <td class=xl68942 width=64 style='border-top:none;border-left:none;
         width:48pt'>11</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="11" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="33"><col width="121"><col width="173"><col width="65"><col width="87"><col width="77"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="2" width="33" height="121" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">  № </font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="121" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="173" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Точка отбора</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="7" width="486" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Удельная эффективная активность, Бк/кг</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Суммарная альфа- активность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Суммарная бета-активность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Cs- 137</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ra -226</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Th- 232</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">К-40</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Sr - 90</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '47': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности строительных материалов и изделий',
    kazakhName: 'Құрылыс материалдары мен бұйымдарының радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'sample_obtaining_place',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором',
        kazakhLabel: 'Зерттеу жүргізілген құрал'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Сәйкестігі туралы куәлік'
      },      
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследования образца проводились на соотвествие НД',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1059 style='border-collapse:
        collapse;table-layout:fixed;width:793pt'>
        <col width=64 style='width:48pt'>
        <col width=350 style='mso-width-source:userset;mso-width-alt:12430;width:262pt'>
        <col width=54 style='mso-width-source:userset;mso-width-alt:1905;width:40pt'>
        <col width=335 style='mso-width-source:userset;mso-width-alt:11918;width:251pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=141 style='height:105.6pt'>
         <td height=141 class=xl155537 width=64 style='height:105.6pt;width:48pt'></td>
         <td rowspan=2 class=xl725537 width=350 style='width:262pt'>№ п/п</td>
         <td class=xl715537 width=54 style='border-left:none;width:40pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl715537 width=335 style='border-left:none;width:251pt'>&#1198;лестік
         тиімді белсенділік, Бк/кг</td>
         <td class=xl715537 width=64 style='border-left:none;width:48pt'>&#1198;лестік
         тиімді р&#1201;&#1179;сат етілген белсенділікті&#1187; де&#1187;гейі,Бк/кг</td>
         <td class=xl715537 width=64 style='border-left:none;width:48pt'>&#1178;&#1201;рылыс
         заттарыны&#1187; сыныптары</td>
         <td class=xl715537 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl655537 width=64 style='border-left:none;width:48pt'>&#1178;&#1201;рылыс
         заттарыны&#1187; пайдалану шарттары</td>
        </tr>
        <tr height=141 style='height:105.6pt'>
         <td height=141 class=xl155537 style='height:105.6pt'></td>
         <td class=xl705537 width=54 style='border-top:none;border-left:none;
         width:40pt'>Наименование образца</td>
         <td class=xl705537 width=335 style='border-top:none;border-left:none;
         width:251pt'>Удельная эффективная активность, Бк/кг</td>
         <td class=xl705537 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень удельной эфективной активности (Бк/кг)</td>
         <td class=xl705537 width=64 style='border-top:none;border-left:none;
         width:48pt'>Класс строительного материала</td>
         <td class=xl705537 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl665537 width=64 style='border-top:none;border-left:none;
         width:48pt'>Условия использования строительных материалов</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl155537 style='height:15.0pt'></td>
         <td class=xl675537 width=350 style='border-top:none;width:262pt'>1</td>
         <td class=xl685537 width=54 style='border-top:none;border-left:none;
         width:40pt'>2</td>
         <td class=xl685537 width=335 style='border-top:none;border-left:none;
         width:251pt'>3</td>
         <td class=xl685537 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl685537 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl685537 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl695537 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="53"><col width="119"><col width="252"><col width="169"><col width="94"><col width="130"><col width="133"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="53" height="153" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ п/п</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="119" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="252" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік тиімді белсенділік, Бк/кг<br>Удельная эффективная активность, Бк/кг</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="169" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік тиімді рұқсат етілген белсенділіктің деңгейі,Бк/кг <br>Допустимый уровень удельной эфективной активности (Бк/кг)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="94" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрылыс заттарының сыныптары<br>Класс строительного материала</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="130" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="133" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрылыс заттарының пайдалану шарттары<br>Условия использования строительных материалов</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '48': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности удобрений',
    kazakhName: 'Тыңайтқыштардың радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 1,
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'sample_selection_date',
        type: 'text',
        label: 'Время отбора образца',
        kazakhLabel: 'Үлгінің уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Топтама нөмірі мерзімі'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Зерттеу жүргізілген құрал (Исследования проводились прибором) №',
        kazakhLabel: 'Зерттеу жүргізілген құрал (Исследования проводились прибором) №'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Сәйкестігі туралы куәлік'
      },      
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'Исследования образца проводились на соотвествие НД',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=882 style='border-collapse:
        collapse;table-layout:fixed;width:661pt'>
        <col width=64 style='width:48pt'>
        <col width=183 style='mso-width-source:userset;mso-width-alt:6513;width:137pt'>
        <col width=64 style='width:48pt'>
        <col width=315 style='mso-width-source:userset;mso-width-alt:11207;width:236pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=176 style='height:132.0pt'>
         <td height=176 class=xl1518899 width=64 style='height:132.0pt;width:48pt'></td>
         <td rowspan=2 class=xl6318899 width=183 style='width:137pt'>№ п/п</td>
         <td class=xl6318899 width=64 style='border-left:none;width:48pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl6318899 width=315 style='border-left:none;width:236pt'>&#1198;лестік
         белсенділік U-238 (Ra 226), Бк/кг</td>
         <td class=xl6318899 width=64 style='border-left:none;width:48pt'>&#1198;лестік
         белсенділік Th-238 Бк/кг</td>
         <td class=xl6318899 width=64 style='border-left:none;width:48pt'>Радионуклидттерді&#1187;
         &#1199;лестік белсенділігіні&#1187; &#1179;осындысыны&#1187; м&#1241;ні
         Бк/кг(А)</td>
         <td class=xl6318899 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl6318899 width=64 style='border-left:none;width:48pt'>Радионуклидттерді&#1187;
         &#1199;лестік белсенділігі р&#1201;&#1179;сат етілген де&#1187;гейі
         &#1179;осындысыны&#1187; Бк/кг (А)</td>
        </tr>
        <tr height=176 style='height:132.0pt'>
         <td height=176 class=xl1518899 style='height:132.0pt'></td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Наименование образца)</td>
         <td class=xl6318899 width=315 style='border-top:none;border-left:none;
         width:236pt'>(Удельная активность U-238 (Ra 226), Бк/кг)</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Удельная активность Th-238 Бк/кг)</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Значение суммы удельной активности радионуклидов (А), Бк/кг)</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень суммы удельной активности радионуклидов (А),
         Бк/кг</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1518899 style='height:14.4pt'></td>
         <td class=xl6318899 width=183 style='border-top:none;width:137pt'>1</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6318899 width=315 style='border-top:none;border-left:none;
         width:236pt'>3</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6318899 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="47"><col width="122"><col width="172"><col width="143"><col width="156"><col width="147"><col width="138"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="47" height="229" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ п/п</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="122" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>(Наименование образца)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="172" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік белсенділік <br>U-238 (Ra 226), Бк/кг<br>(Удельная активность <br>U-238 (Ra 226), Бк/кг)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="143" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестік белсенділік Th-238 Бк/кг<br>(Удельная активность <br>Th-238 Бк/кг)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="156" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радионуклидттердің үлестік белсенділігінің қосындысының мәні Бк/кг(А)<br>(Значение суммы удельной активности радионуклидов (А), Бк/кг)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="147" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="138" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радионуклидттердің үлестік белсенділігі рұқсат етілген деңгейі қосындысының Бк/кг (А)<br>Допустимый уровень суммы удельной активности радионуклидов (А), Бк/кг</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  }
}
