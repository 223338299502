import React, {useEffect, useState} from "react";
import DashboardLayout from "../../../layouts/Dashboard";

import DictionaryCRUDTable from "../../../components/CRUD/Tables/DictionaryCRUDTable";

const CurrencyList = () => {    
    return (
        <DashboardLayout>
           <DictionaryCRUDTable
                entityType="currency"
                hasCode={true}
                hasDescription={true}
            />
        </DashboardLayout>
    )
};

export default CurrencyList;
