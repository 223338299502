import React, {useMemo} from 'react';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxField({label, onChange, value, checked, ...props}) {
  const _value = useMemo(() => {
    if(checked !== undefined) return checked;
    return !!value;
  }, [value, checked])

  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox {...props} checked={_value} onChange={e => onChange(e.target.checked)} />} label={label}  />
    </FormGroup>
  );
}