import DashboardLayout from "../../layouts/Dashboard";
import FilialDivisionCRUDTable from "../../components/CRUD/Tables/FilialDivisionCRUDTable";
import {FILIAL_DIVISION_DETAIL_ROUTE} from "../../routes";
import {useHistory} from "react-router-dom";

const FilialDivisionList = () =>{
    const history = useHistory()
    const detailAction = (id) => {
        history.push(FILIAL_DIVISION_DETAIL_ROUTE(id), {
            review: true
        })
    }

    const editAction = (id) => {
        history.push(FILIAL_DIVISION_DETAIL_ROUTE(id), {
            review: false
        })
    }
   return <DashboardLayout>
        <FilialDivisionCRUDTable withOrganization onClickDetailsButton={detailAction}
                                 onClickEditButton={editAction}/>
    </DashboardLayout>
}

export default FilialDivisionList