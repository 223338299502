export const emptyDate = "0001-01-01T00:00:00";

export function toUIDate(date) {   
    if (date == emptyDate)
        return null;
    
    if (!date)
        return date;
    if (date.indexOf('T') >= 0) {
        const tokens = date.split('T');
        return tokens[0];
    }

    return date;
}


export function autoCompleteFilialAndFilialDivision(authorizedEmployee, setFilialId, setFilialDivisionId) {
    if (authorizedEmployee?.filialId || authorizedEmployee?.filialDivisionId) {
        setFilialDivisionId(authorizedEmployee?.filialDivisionId);
        setFilialId(authorizedEmployee?.filialId);
    }
}

export function yearStart(year) {
    const date = new Date();
    if (!year) {
        year = date.getUTCFullYear();
    }

    return new Date(year, 0, 1, 0, 0, 0, 0);
}

export function yearEnd(year) {
    const date = new Date();
    if (!year) {
        year = date.getUTCFullYear();
    }
    const result = new Date(year + 1, 0, 1, 0, 0, 0, 0);
    result.setMilliseconds(result.getMilliseconds() - 1);
    return result;
}