import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProbes, addProbe, editProbe, deleteProbe, getProbeByKey } from "../../redux/actions/probeActions";
import CreateProbe from "./Popups/CreateProbe";
import ProbeDetails from "./Popups/ProbeDetails";
import EditProbe from "./Popups/EditProbe";
import RemoveProbe from "./Popups/RemoveProbe";
import TableComponent from '../../components/TableComponent/Table'
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

function createData(id, createdAt, schema, data, state, service, referral) {
    return {id, createdAt, schema, data, state, service, referral};
}

let rows = [
    createData(1, "12.03.2021 00:00", null, null, null, null, null),
];
const ProbeList = () => {
    const {t} = useTranslation();
    const columns = [
        {title: t("referralList.tableItem.id"), dataIndex: 'id', key: 'id'},
        {title: t("probeList.tableItem.createdAt"), dataIndex: 'createdAt', key: 'createAt'},
        {title: t("probeList.tableItem.schema"), dataIndex: 'schema', key: 'schema'},
        {title: t("probeList.tableItem.data"), dataIndex: 'data', key: 'data'},
        {title: t("probeList.tableItem.state"), dataIndex: 'state', key: 'state'},
        {title: t("probeList.tableItem.service"), dataIndex: 'service', key: 'service'},
        {title: t("probeList.tableItem.referral"), dataIndex: 'referral', key: 'referral'},
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (_) => {
                return(
                    <>
                        <EditOutlined style={{fontSize: '1.5rem', marginRight: '1rem'}} onClick={() => setEditHandler(_.id)}/>
                        <DeleteOutlined style={{fontSize: '1.5rem'}} onClick={() => setRemoveHandler(_.id)}/>
                    </>
                )
            },
        },
        // {text: t("referralList.tableItem.data")},
        // {text: t("referralList.tableItem.state")},
        // {text: t("referralList.tableItem.object")},


    ]
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);
    const setCreateHandler = async () => {
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setCreateOpen(true);
        setId(null);
    }
    const createSubmit = async (_) => {
        await dispatch(addProbe(_, query));
    }
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getProbeByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setDetailsOpen(true);
        setId(id);
    }

    const [editOpen, setEditOpen] = useState(false);
    const setEditHandler = async (id) => {
        await dispatch(getProbeByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setEditOpen(true);
        setId(id);
    }
    const editSubmit = async (_) => {
        await dispatch(editProbe(_, query));
    }
    const [removeOpen, setRemoveOpen] = useState(false);
    const setRemoveHandler = async (id) => {
        await dispatch(getProbeByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setRemoveOpen(true);
        setId(id);
    }
    const removeSubmit = async (_) => {
        await dispatch(deleteProbe(_.id, query));
    }

    const localData = false;
    const state = useSelector((state) => state.probe);
    if (!localData && state.probes)
    {
        rows = state.probes;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getProbes(query));
        }
    }, [dispatch]);

    const data = []

    rows.forEach(_ => {
        data.push({
            key: _.id,
            id: _.id,
            createdAt: _.createdAt,
            schema: _.schema?.jsonSchema ? 'Да' : 'Нет',
            data: _.data ? 'Да' : 'Нет',
            state: _.state?.name,
            service: _.service?.name,
            referral: _.referral?.number,
        })
    })


    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("probeList.searchLabel")}/>
                <Button color="primary" variant="contained" onClick={() => setCreateHandler()}>
                    {t("probeList.createText")}
                </Button>
            </Box>
            <TableComponent
                columns={columns}
                data={data}
                pagination={false}
            />

            {/*<TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {*/}
            {/*    return {*/}
            {/*        id: _.id,*/}
            {/*        createdAt: _.createdAt,*/}
            {/*        schema: _.schema?.jsonSchema ? 'Да' : 'Нет',*/}
            {/*        data: _.data ? 'Да' : 'Нет',*/}
            {/*        state: _.state?.name,*/}
            {/*        service: _.service?.name,*/}
            {/*        referral: _.referral?.number,*/}
            {/*    }*/}
            {/*})} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>,<EditIcon onClick={() => setEditHandler(_.id)}/>,<DeleteForeverIcon onClick={() => setRemoveHandler(_.id)}/>]}/>*/}


            <CreateProbe action={'create'} title={t("probeList.createText")} open={createOpen} setOpen={setCreateOpen} onSubmit={createSubmit}/>
            <ProbeDetails action={'details'} title={t("probeList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
            <EditProbe action={'edit'} title={t("probeList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit}/>
            <RemoveProbe action={'remove'} title={t("probeList.removeText")} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/>
        </DashboardLayout>
    )
};

export default ProbeList;
