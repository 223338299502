import React, {useCallback, useEffect, useMemo, useState} from "react";
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import {CircularProgress, Grid, Button, LinearProgress} from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {mapAccreditationData, mapEmployeeData, mapUserData} from "../../../functions/viewDataMap";
import {useDispatch} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {v4 as uuidv4} from "uuid";
import {
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect, OrganizationSelect, RoleSelect
} from "../../../components/Select/domainSelects";
import {$authHost, accreditationCRUD, employeeCRUD, userCRUD} from "../../../http";
import useAlert from "../../../hooks/useAlert";
import {ACCREDITATION_DETAIL_ROUTE, ACCREDITATION_LIST_ROUTE, EMPLOYEE_LIST_ROUTE} from "../../../routes";
import PositionField from "../../../components/Relations/PositionField";
import Stack from "@mui/material/Stack";
import useReload from "../../../hooks/useReload";
import { getAccreditations } from "../../../redux/actions";
import { convertDateTime } from "../../../libs/timezone";
import { Box } from "@mui/system";
import { toUIJson } from "../../../functions";

const AccreditationDetailForm = () => {
    const {id} = useParams();
    const history = useHistory()
    const [userData, setUserData] = useState(null)
    const alert = useAlert(false, 1)
    const [isNew, setIsNew] = useState(id === "new")
    const dispatch = useDispatch()
    const {review} = useLocation().state || false
    const [roleBlocks, setRoleBlocks] = useState({})
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedImage(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    };
  

    const { reload, reloadValue } = useReload();

    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: loadingSubmit,
    } = useLoader(false);
    const [fields, setFields] = useState({})
    const submitAccreditationData = useMemo(() => {
        const submitData = mapAccreditationData(fields);
        return submitData;
    }, [fields]);

    const {loading, start, stop} = useLoader(false)
    const fetch = useCallback(async () => {
        try {
            start()
            if (!isNew) {
                const data = await accreditationCRUD.get(id)
                setFields((state) => {
                    return {
                        ...state,
                        departmentId: data.department?.id || null,
                        filialId: data.filial?.id || null,
                        name: data.name || null,
                        filialDivisionId: data.filialDivisionId || null,
                        divisionId: data.divisionId || null,
                        sign: data.sign || null
                    }
                })

                const image = toUIJson(data.sign);

                if(image) setSelectedImage(image.base64);
                console.log(toUIJson(image.base64))
            
            }
            stop()
        } catch (err) {
            stop()
            alert.show(0,"Ошибка загрузки!")

        }

    }, [dispatch, id])

    useEffect(fetch, [fetch, reloadValue])


    const submit = useCallback(() => {
        if (isNew) {
            accreditationCRUD.create({
                ...submitAccreditationData,
            }).then((id) => {
                history.push(ACCREDITATION_LIST_ROUTE)
                alert.show(1, "Знак аккредитации создан") 
            }).catch(error => {
                alert.show(0,error)
            })
        } else {
            accreditationCRUD.edit({
                id: id,
                ...submitAccreditationData,
            }).then(resp=>{
               alert.show(1,"Изменения сохранены")
           }).catch(error => {
               alert.show(0,error)
           })
        }
        
    }, [id, dispatch, submitAccreditationData])


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
    
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    
 
    // On file select (from the pop up)
    const onFileChange = async event => {
        handleImageChange(event);
        const base64 = await convertBase64(event.target.files[0]);
        console.log(base64)

        setFields((state) => {
            return {
                ...state,
                sign: {base64}
            }
        })
    };
 
    // On file upload (click the upload button)
    const onFileUpload = () => {
 
        // Create an object of formData
        const formData = new FormData();
 
        // Update the formData object
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );
 
        // Details of the uploaded file
        console.log(this.state.selectedFile);
 
        // Request made to the backend api
        // Send formData object
        // axios.post("api/uploadfile", formData);
    };
 
    // File content to be displayed after
    // file upload is complete
    const fileData = () => {
 
        if (this.state.selectedFile) {
 
            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>
 
                    <p>File Type: {this.state.selectedFile.type}</p>
 
                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
 
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };


    return (
        <DashboardLayout>
            {loading ? <CircularProgress/> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew ? "Создание" : "Редактирование"} знака аккредитации<br/><Typography
                                style={{fontSize: 12}}>{fields.name || "Название отсутствует"}</Typography>
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <NavLink style={{color: '#2f9e69'}} to={ACCREDITATION_LIST_ROUTE}><span
                                    style={{color: "2f9e69"}}>Вернуться к таблице</span></NavLink>
                            </Stack>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{paddingTop: 16}}>
                    <Paper style={{paddingBottom: 20}}>
                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Основная информация</Typography>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <TextField disabled={review} required margin="normal"
                                               fullWidth id="name" name="name" label="Название"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           name: ev.target.value
                                                       }
                                                   })
                                               }
                                               }
                                               value={fields.name || ""}
                                    />
                                </Grid>
                                

                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={6}>
                                    <Grid item xs={4}>
                                        <input 
                                            style={{ display: 'none' }}
                                            type="file" 
                                            onChange={onFileChange} 
                                            accept="image/*"
                                            id="upload-photo" 
                                            name="upload-photo"
                                            />
                                        <label htmlFor="upload-photo">
                                        <Button
                                            fullWidth
                                            component="span"
                                            variant="contained"
                                        >
                                            Загрузить
                                        </Button>
                                    </label>
                                    {selectedImage && (
                                    <div>
                                    <img src={selectedImage} alt="Uploaded" width="300" />
                                    </div>
                                )}
                                </Grid>
                                    </Grid>
                                </Grid>

                        </Grid>
                        <Grid container spacing={2} xs={12}>
                        </Grid>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={5}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Организация</Typography>                                
                            </Grid>
                            <Grid item xs={12}>
                                <OrganizationSelect
                                    label={"Организация"}
                                    fullWidth
                                    disabled={review}
                                    value={fields.organizationId || ""}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                organizationId: val
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid container marginTop={1} spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <FilialSelect
                                        label={"Филиал"}
                                        disabled={review}
                                        organizationId={fields.organizationId || null}
                                        fullWidth
                                        value={fields.filialId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    filialId: val
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FilialDivisionSelect
                                        disabled={review}
                                        label={"Районное отделение"}
                                        fullWidth
                                        value={fields.filialDivisionId}
                                        isNullable={true}
                                        filialId={fields.filialId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    filialDivisionId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DepartmentSelect
                                        label={"Лаборатория"}
                                        disabled={review}
                                        fullWidth
                                        value={fields.departmentId || ""}
                                        isNullable={true}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    departmentId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DivisionSelect
                                        label={"Отделение лаборатории"}
                                        disabled={review}
                                        fullWidth
                                        value={fields.divisionId || ""}
                                        isNullable={true}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    divisionId: val
                                                }
                                            })
                                        }}/>
                                </Grid>

                            </Grid>


                        </Grid>


                    </Paper>
                </Grid>
                {!review && (
                    <LoadingButton style={{marginTop: "10px"}} loading={loadingSubmit} variant="contained" onClick={() => {
                        submit()
                    }}>
                        Сохранить
                    </LoadingButton>
                )}

                {alert.render()}
            </>}


        </DashboardLayout>
    )
}

export default AccreditationDetailForm