export const REAGENT_GET_REQUEST = 'REAGENT_GET_REQUEST';
export const REAGENT_GET_SUCCESS = 'REAGENT_GET_SUCCESS';
export const REAGENT_GET_ERROR = 'REAGENT_GET_ERROR';
export const REAGENT_GET_BY_KEY_REQUEST = 'REAGENT_GET_BY_KEY_REQUEST';
export const REAGENT_GET_BY_KEY_SUCCESS = 'REAGENT_GET_BY_KEY_SUCCESS';
export const REAGENT_GET_BY_KEY_ERROR = 'REAGENT_GET_BY_KEY_ERROR';
export const REAGENT_ADD_REQUEST = 'REAGENT_ADD_REQUEST';
export const REAGENT_ADD_SUCCESS = 'REAGENT_ADD_SUCCESS';
export const REAGENT_ADD_ERROR = 'REAGENT_ADD_ERROR';
export const REAGENT_EDIT_REQUEST = 'REAGENT_EDIT_REQUEST';
export const REAGENT_EDIT_SUCCESS = 'REAGENT_EDIT_SUCCESS';
export const REAGENT_EDIT_ERROR = 'REAGENT_EDIT_ERROR';
export const REAGENT_DELETE_REQUEST = 'REAGENT_DELETE_REQUEST';
export const REAGENT_DELETE_SUCCESS = 'REAGENT_DELETE_SUCCESS';
export const REAGENT_DELETE_ERROR = 'REAGENT_DELETE_ERROR';
