import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {departmentCRUD} from "../../../http";
import {DEPARTMENT_DETAIL_ROUTE, FILIAL_DETAIL_ROUTE} from "../../../routes";
import React from "react";
import FilialDivisionCRUDTable from "./FilialDivisionCRUDTable";

export default function DepartmentCRUDTable({...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("departmentList.tableItem.id"), dataIndex: "id", key:"id"},
        {title: t("departmentList.tableItem.name"), dataIndex: "name", key:"name"},
        {title: t("departmentList.tableItem.description"), dataIndex: "description", key:"description",render: (unknown, row) => row.description ? row.description : <span style={{color: "gray"}}>Не задано</span>},
        {title: t("departmentList.tableItem.completeWithoutProtocol"), dataIndex: "completeWithoutProtocol", key:"completeWithoutProtocol",render: (unknown, row) => row.completeWithoutProtocol ? 
            "Да" : "Нет"},
        {title: t("departmentList.tableItem.isDeleted"), dataIndex: "isDeleted", key:"isDeleted",render: (unknown, row) => row.isDeleted ? 
        "Да" : "Нет"},
    ]

    const filterDetails = [
        {title: "test", type:"select"}
    ]
    
    const searchFields = [
        {
            title: "Название лаборатории",
            key: "name",
            compareType: "equals",
            operandPosition:1,
            filterType:"normal"
        }
    ]

    return <CRUDTable
        search={departmentCRUD.search}
        columns={columns}
        onClickCreateButton={() => push(DEPARTMENT_DETAIL_ROUTE("new"))}
        searchFields={searchFields}
        filterDetails = {filterDetails}
        {...props}
    />
}