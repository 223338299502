export const probCodes = [
    'мазок',
    'Мазок из зева',
    'Мазок из носа',
    'Мазок из зева и носа',
    'Мокрота',
    'Эндотрахеальный аспират',
    'Трупный материал',
    'Моча',
    'Кал',
    'Кровь',
    'Мазок из горла',
    'Отделяемые от раны',
    'Мазок из ушной раковины',
    'Грудное молоко',
    'Спинно-мозговая жидкость',
    'Желчь',
    'Отделяемый от дыхательных путей',
    'Прочие'
]

export const probStatus = [
    'первичный',
    'повторный',
    'контроль лечения'
]

export const clinicalSymptoms = [
    'Нет',
    'Есть'
]

export const expressTests = [
    'Отрицательный',
    'Положительный',
    'Не проводился'
]

export const statusPatient = [
    'Больной КВИ',
    'Близкий контакт',
    'Потенциальный контакт',
    'Вновь прибывший (из зарубежа)',
    'Плановый (медработники)',
    'Пневмония',
    'ТОРИ',
    'ОРВИ',
    'Группа риска (возраст старше 60 лет)',
    'Группа риска (хронические заболевания)',
    'Подопечные медико-социальных учреждений',
    'Работники медико-социальных учреждений',
    'Плановый сотрудник центральных и местных исполнительных органов',
    'Медработники',
    'Другие',
    'Скрининг персонала',
    'Выезд с Тенгиза',
    'Демобилизация',
    'Завершение лечения',
    'Медицинский скрининг',
    'Мобилизация',
    'ОРЗ',
    'Скрининг',
    'Перевахтовка',
    'Заезд на Тенгиз',
    'Close contact',
    'End of Treatment',
    'Medical screening',
    'Potential contact',
    'Staff screening',
    'Tengiz arrival',
    'Tengiz departure',
    'Близкий контакт / Close contact',
    'Выезд с Тенгиза / Tengiz departure',
    'Завершение лечения / End of Treatment',
    'Заезд на Тенгиз / Tengiz arrival',
    'Медицинский скрининг / Medical screening',
    'Потенциальный контакт / Potential contact',
    'Скрининг персонала / Staff screening',
    'COVID-19 подтвержденный',
    'Без симп COVID-19',
    'Берем. 39 нед. 6 дней. Сроч.роды',
    'Беременная - 37. скрининг',
    'Беременная - 38. скрининг',
    'Беременная - 39. скрининг',
    'Беременность 12 нед.',
    'Беременность 22 нед.',
    'Беременность 23 нед',
    'Беременность 30 нед.',
    'Беременность 34 нед.',
    'Беременность 35 нед',
    'Беременность 36 нед',
    'Беременность 37 нед',
    'Беременность 38 нед',
    'Беременность 39 нед',
    'Беременность 40 нед',
    'Беременность 41 нед',
    'Беременность 8 нед',
    'Бесплодие 2 ст',
    'КВИ',
    'КВИ повтор',
    'Контакт с КВИ',
    'Новорожденный',
    'Обследование',
    'Обследование COVID-19',
    'ОРВИ беременность 20 нед.',
    'ОРИ',
    'Переболев. COVID19',
    'По порталу',
    'Подозрение на КВИ',
    'Правосторонняя пневмония',
    'Скрин онколог',
    'Скрининг - беремен 36 нед.',
    'Скрининг - беремен 40 нед.',
    'Скрининг - беремен 41 нед.',
    'Скрининг (заключенный)',
    'скрининг (киста левого яичника)',
    'скрининг 38 нед 2 дня',
    'скрининг бессимп.больной',
    'Скрининг медперсонала / Med Staff screening',
    'скрининг ОРВИ',
    'скрининг по порталу',
    'скрининг после лечение',
    'скрининг, КВИ повтор',
    'Тест точки происхождения / Point of origin test',
    'обследование КВИ',
    'онкол. больн. женский',
    'скрининг/близкий контакт',
    'Вновь прибывший',
    'Point of origin test / Тест по месту жительства',
    'Лихорадка неясного генеза',
    'COVID 19',
    'COVID 19 завершение лечения',
    'COVID 19 завершение лечения от 26.05.2020',
    'COVID 19 завершение лечения от 27.05.2020',
    'COVID?',
    'n/a',
    'OPS COVID',
    'близкий контакт (повторно)',
    'близкий контакт с КВИ',
    'в контакте',
    'Вирусная инфекция',
    'Высокая температура',
    'гипертермический синдром',
    'двусторонняя пневмония. Ковид?',
    'двухсторонняя пневмония',
    'Демобилизация в связи с увольнением',
    'Завершение карантина',
    'Клиническая смерть',
    'критический персонал',
    'лимфоаденит КВИ?',
    'Лихорадка неясного генеза',
    'не указан',
    'нет данных',
    'О. ларингит',
    'О.фарингит',
    'О.ринит',
    'ОКС',
    'ОРВИ КВИ',
    'ОРЗ COVID?',
    'ОРЗ КВИ',
    'ОРЗ скрининг',
    'ОРЗ, гипер. Синдр.',
    'острый инфаркт',
    'первично',
    'Острый тонзиллит',
    'Острый бронхит',
    'Пневмания КВИ',
    'пневмания скрининг',
    'пневмания скрининг по КВИ',
    'по клиническим показаниям',
    'повторный',
    'Ремобилизация',
    'Самообращение',
    'скрининг 2',
    'скрининг заезд на вахту',
    'скрининг на COVID',
    'скрининг на КВИ',
    'установление наличия COVID',
    'функциональная диарея, cito!',
    'хрон.гастрит функц.диарея',
    'Фарингит'
]

export const benefitsToCDI = [
    'Не выбрано',
    'Участники Великой Отечесвенной войны и лица, приравненные к ним',
    'Участники войны в Афганистане',
    'Лица, принимавшие участие в ликвидации аварии на Чернобыльском АЭС',
    'Многодетные матеря, награжденные подвесками "Алтын алка", "Күміс алқа"',
    'Инвалиды дества',
    'Договор с поликлиникой',
    'Пенсионеры'
]