import {serviceMapCRUD} from "../../http";

export const firstOrDefault = (collection, filterFunc, defaultFunc) => {
    defaultFunc = defaultFunc || (() => {});

    if (collection == null)
        return defaultFunc();

    const filteredItems = collection.filter(filterFunc);
    if (filteredItems.length == 0)
        return defaultFunc();
    return filteredItems[0];
}

export const distinct = (collection, equalsFunc) => {
    const result = [];

    for (let i = 0; i < collection.length; i++) {
        const item = collection[i];
        if (result.filter(_ => equalsFunc(_, item)).length == 0)
            result.push(item);
    }

    return result;
}

export const distinctArrayObject = (collection) => {
    if (collection.length) {
        const ids = collection.map(o => o?.id)
        return collection.filter((el, index) => !ids.includes(el?.id, index + 1));
    }
    return []
}

export const getQueryFilterArray = async (key, key2, key3, value, value2, value3) => {
    if (key === 'departmentId') {
        const {result} = await serviceMapCRUD.search(({paging: {skip: 0},
            filter: {
                departmentId: {
                    operand1: value,
                    operator: "equals",
                },
            }
        }))
        const group = []
        result.map(el =>  {
            if (el.group)
            group.push(el.group)
        })
        return distinctArrayObject(group)
    }
    if (key === 'groupId' && key2) {
        const {result} = await serviceMapCRUD.search(({paging: {skip: 0},
            filter: {
                departmentId: {
                    operand1: value2,
                    operator: "equals",
                },
                groupId: {
                    operand1: value,
                    operator: "equals",
                },
            }
        }))
        const subGroup = []
        result.map(el =>  {
            if (el.subGroup)
                subGroup.push(el.subGroup)
        })

        return distinctArrayObject(subGroup)
    }
    if (key === 'subGroupId' && key3) {
        const {result} = await serviceMapCRUD.search(({paging: {skip: 0},
            filter: {
                departmentId: {
                    operand1: value3,
                    operator: "equals",
                },
                groupId: {
                    operand1: value2,
                    operator: "equals",
                },
                subGroupId: {
                    operand1: value,
                    operator: "equals",
                },
            }
        }))

        const kind = []
        result.map(el =>  {
            if (el.kind)
                kind.push(el.kind)
        })

        return distinctArrayObject(kind)
    }
    return []
}

export const groupBy = (collection, keySelector, keyEqualsFunc) => {
    collection.forEach(_ => {
        _.__key = keySelector(_);
    });

    const keys = collection.map(_ => _.__key);
    const distinctKeys = distinct(keys, keyEqualsFunc);

    const result = [];

    distinctKeys.forEach(key => {
        const group = { key, values: [] };
        collection.forEach(item => {
            if (keyEqualsFunc(item.__key, key))
                group.values.push(item);
        });
        group.first = null;
        if (group.values.length > 0) {
            group.first = group.values[0];
        }
        result.push(group);
    });

    return result;
}

export const sum = (collection, selector) => {
    let result = 0;
    collection.forEach(_ => result += selector(_));
    return result;
}

export const calculateTarficiationGroups = (array, date) => {
    const isDesinsection = (code) => {
        if (code == null)
            return false;

        //return code.startsWith('10') || code.startsWith('12');
        if (!date)
            return code.startsWith('08');

        if (!date.getFullYear)
            date = new Date(date);

        if (date.getFullYear() >= 2023) {
            return code.startsWith('08');
        }

        return code.startsWith('10') || code.startsWith('12');
    }

    const visitGroup = (group) => {
        let idx = 0;

        const groupGroups = groupBy(group.values, _ => _.groupId, (a, b) => a == b);
        group.groupGroups = groupGroups;
        groupGroups.forEach(groupGroup => {
            const researchCodeGroups = groupBy(groupGroup.values, _ => _.researchCode, (a, b) => a == b);
            groupGroup.researchCodeGroups = researchCodeGroups;

            researchCodeGroups.forEach(researchCodeGroup => {           
                const first = researchCodeGroup.first;
                const values = researchCodeGroup.values;
                const summary = {
                    idx: ++idx,
                    researchCode: researchCodeGroup.key,
                    subGroup: first?.subGroup?.name + first?.method?.name + "(одно исследование)",
                    weightCoefficient: first?.weightCoefficient,
                    quantity: sum(values, _ => _.quantity),
                    baseValue: sum(values, _ => _.quantity * _.weightCoefficient),
                    summary: sum(values, _ => _.summary),
                    inconsistenceQuantity: sum(values, _ => _.inconsistenceQuantity)
                };
                researchCodeGroup.totalSummary = summary;
            });
        });        
        const desinsectionValues = group.values.filter(_ => isDesinsection(_.researchCode));
        const notDesinsectionValues = group.values.filter(_ => !isDesinsection(_.researchCode));

        group.totalSummary = {
            quantity: sum(group.values, _ => _.quantity),
            baseValue: sum(group.values, _ => _.quantity * _.weightCoefficient),
            summary: sum(group.values, _ => _.summary),
            inconsistenceQuantity: sum(group.values, _ => _.inconsistenceQuantity)
        };
        group.totalSummaryOfDesinsection = {
            quantity: sum(desinsectionValues, _ => _.quantity),
            baseValue: sum(desinsectionValues, _ => _.quantity * _.weightCoefficient),
            summary: sum(desinsectionValues, _ => _.summary),
            inconsistenceQuantity: sum(desinsectionValues, _ => _.inconsistenceQuantity)
        };
        group.totalSummaryOfNotDesinsection = {
            quantity: sum(notDesinsectionValues, _ => _.quantity),
            baseValue: sum(notDesinsectionValues, _ => _.quantity * _.weightCoefficient),
            summary: sum(notDesinsectionValues, _ => _.summary),
            inconsistenceQuantity: sum(notDesinsectionValues, _ => _.inconsistenceQuantity)
        };
    };

    const departmentGroups = groupBy(array, _ => _.departmentId, (a, b) => a == b );
    departmentGroups.forEach(departmentGroup => {
        const divisionGroups = groupBy(departmentGroup.values.filter(_ => _.divisionId != null), _ => _.divisionId, (a, b) => a == b);
        departmentGroup.divisionGroups = divisionGroups;
        
        if (divisionGroups.length > 0) {
            divisionGroups.forEach(divisionGroup => {
                visitGroup(divisionGroup);
            });
        }
        departmentGroup.values = departmentGroup.values.filter(_ => _.divisionId == null);
        visitGroup(departmentGroup);

        // sum by divisions to department
        if (divisionGroups.length > 0) {
            departmentGroup.totalSummary.quantity += sum(divisionGroups, _ => _.totalSummary.quantity);
            departmentGroup.totalSummary.baseValue += sum(divisionGroups, _ => _.totalSummary.baseValue);
            departmentGroup.totalSummary.summary += sum(divisionGroups, _ => _.totalSummary.summary);
            departmentGroup.totalSummary.inconsistenceQuantity += sum(divisionGroups, _ => _.totalSummary.inconsistenceQuantity);

            departmentGroup.totalSummaryOfDesinsection.quantity += sum(divisionGroups, _ => _.totalSummaryOfDesinsection.quantity);
            departmentGroup.totalSummaryOfDesinsection.baseValue += sum(divisionGroups, _ => _.totalSummaryOfDesinsection.baseValue);
            departmentGroup.totalSummaryOfDesinsection.summary += sum(divisionGroups, _ => _.totalSummaryOfDesinsection.summary);
            departmentGroup.totalSummaryOfDesinsection.inconsistenceQuantity += sum(divisionGroups, _ => _.totalSummaryOfDesinsection.inconsistenceQuantity);

            departmentGroup.totalSummaryOfNotDesinsection.quantity += sum(divisionGroups, _ => _.totalSummaryOfNotDesinsection.quantity);
            departmentGroup.totalSummaryOfNotDesinsection.baseValue += sum(divisionGroups, _ => _.totalSummaryOfNotDesinsection.baseValue);
            departmentGroup.totalSummaryOfNotDesinsection.summary += sum(divisionGroups, _ => _.totalSummaryOfNotDesinsection.summary);
            departmentGroup.totalSummaryOfNotDesinsection.inconsistenceQuantity += sum(divisionGroups, _ => _.totalSummaryOfNotDesinsection.inconsistenceQuantity);
        }
    });
    
    departmentGroups.totalSummary = {
        quantity: sum(departmentGroups, _ => _.totalSummary.quantity),
        baseValue: sum(departmentGroups, _ => _.totalSummary.baseValue),
        summary: sum(departmentGroups, _ => _.totalSummary.summary),
        inconsistenceQuantity: sum(departmentGroups, _ => _.totalSummary.inconsistenceQuantity)
    };
    departmentGroups.totalSummaryOfDesinsection = {
        quantity: sum(departmentGroups, _ => _.totalSummaryOfDesinsection.quantity),
        baseValue: sum(departmentGroups, _ => _.totalSummaryOfDesinsection.baseValue),
        summary: sum(departmentGroups, _ => _.totalSummaryOfDesinsection.summary),
        inconsistenceQuantity: sum(departmentGroups, _ => _.totalSummaryOfDesinsection.inconsistenceQuantity)
    };
    departmentGroups.totalSummaryOfNotDesinsection = {
        quantity: sum(departmentGroups, _ => _.totalSummaryOfNotDesinsection.quantity),
        baseValue: sum(departmentGroups, _ => _.totalSummaryOfNotDesinsection.baseValue),
        summary: sum(departmentGroups, _ => _.totalSummaryOfNotDesinsection.summary),
        inconsistenceQuantity: sum(departmentGroups, _ => _.totalSummaryOfNotDesinsection.inconsistenceQuantity)
    };

    return departmentGroups;
}

export const selectByKeys = (collection, keys, keyFunc) => {
    return collection.filter(_ => keys.filter(k => k == keyFunc(_)).length > 0);
}

// TODO: define classes & entity with key structures
export const updateEntityCollectionItem = (collection, itemKey, updateFunc, keyFunc) => {
    keyFunc = keyFunc || (_ => _.id);

    const item = firstOrDefault(collection, _ => keyFunc(_) === itemKey);
    const updatedItem = updateFunc ? updateFunc(item) : {...item};
    const collectionWithoutItem = collection.filter(_ => keyFunc(_) !== itemKey);
    const updatedCollection = [...collectionWithoutItem, updatedItem];

    return {
        item,
        updatedItem,
        collectionWithoutItem,
        updatedCollection
    };
}