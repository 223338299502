import { anObjectsCRUD } from "../../../http/CRUD";
import CRUDTable from "../CRUDTable";
import React from 'react';

export default function AnObjectCRUDTable({...props}) {
    const columns = [
        {title: "ID", dataIndex: 'id', key: 'id'},
        {title: "Адрес", dataIndex: 'addressLine', key: 'addressLine'},
        {title: "БИН", dataIndex: 'bin', key: 'bin'},
        {title: "Описание", dataIndex: 'description', key: 'description'},
        {title: "Наименование", dataIndex: 'name', key: 'name'},
    ];

    const searchFields = [
        {
            title: "Адрес",
            key: "addressLine",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Бин",
            key: "bin",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Описание",
            key: "description",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Наименование",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    return <CRUDTable
        search={anObjectsCRUD.search}
        columns={columns}
        searchFields={searchFields}
        {...props}
    />
}
