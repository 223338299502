import { 
    SUR_OBJECT_GET_REQUEST, SUR_OBJECT_GET_SUCCESS, SUR_OBJECT_GET_ERROR, 
    SUR_OBJECT_GET_BY_KEY_REQUEST, SUR_OBJECT_GET_BY_KEY_SUCCESS, SUR_OBJECT_GET_BY_KEY_ERROR, 
    SUR_OBJECT_ADD_REQUEST, SUR_OBJECT_ADD_SUCCESS, SUR_OBJECT_ADD_ERROR, 
    SUR_OBJECT_EDIT_REQUEST, SUR_OBJECT_EDIT_SUCCESS, SUR_OBJECT_EDIT_ERROR, 
    SUR_OBJECT_DELETE_REQUEST, SUR_OBJECT_DELETE_SUCCESS, SUR_OBJECT_DELETE_ERROR
} from "../constants/surObjectConstants";

const initialState = {
    surObjects: [],
    surObject: {},
    loading: false,
    success: null,
    error: null,
    surObjectRequestedCount: 0,
};

export const surObjectReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SUR_OBJECT_GET_REQUEST:
            return {
                ...state,
                surObjectRequestedCount: ++state.surObjectRequestedCount,
                loading: true,
            };
        case SUR_OBJECT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                surObjects: action.payload,
                success: true,
            };
        case SUR_OBJECT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SUR_OBJECT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUR_OBJECT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                surObject: action.payload,
                success: true,
            };
        case SUR_OBJECT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SUR_OBJECT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUR_OBJECT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SUR_OBJECT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SUR_OBJECT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUR_OBJECT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SUR_OBJECT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SUR_OBJECT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SUR_OBJECT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SUR_OBJECT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
