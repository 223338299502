import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import { CheckBox, HighlightOff } from "@mui/icons-material";
import { useController, useFieldArray, useFormContext } from "react-hook-form";
import { Button, Dialog, DialogContent, Divider, FormHelperText } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IndicatorCRUDTable from "../CRUD/Tables/IndicatorCRUDTable";
import { serviceGroupCRUD, serviceMapCRUD, serviceSubGroupCRUD } from '../../http';
import RestSelect from '../Select/RestSelect';
import { distinct } from '../../functions/linqSimulation';
import LoadingContainer from '../Loader/LoadingContainer';
import { RHFCheckbox } from './RHFCheckbox';
import RestAutoComplete from '../Autocompletes/RestAutoComplete';
import RHFGroupIndicator from './RHFGroupIndicator';

function RHFIndicator({ label, disabled }) {
    const { control, watch } = useFormContext();
    const { fieldState: { error } } = useController({ name: 'indicators' });

    const [groupId, setGroupId] = useState(null);
    const [serviceSubGroups, setServiceSubGroups] = useState(null);
    const [serviceSubGroupIds, setServiceSubGroupIds] = useState(null);
    const [loading, setLoading] = useState(false);
    // const acts = watch("acts");
    // console.log(acts)

    // useEffect(() => {
    //     if (acts.length == 0) {
    //         return;
    //     }
    //     setGroupQuery({
    //         ...groupQuery,
    //         paging: {
    //             skip: 0
    //         },
    //         filter: {
    //             actFormId: {
    //                 operand1: acts[0].formId,
    //                 operator: 'equals'
    //             }
    //         }
    //     })
    // }, [acts])

    const [query, setQuery] = useState({
        paging: { skip: 0, take: 0 }
    });
    const [groupQuery, setGroupQuery] = useState({
        paging: { skip: 0, take: 10000 }, sort: {
            name: { operator: 'asc' }
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'indicators',
    });

    // const fetchServiceMap = async () => {
    //     const response = await serviceMapCRUD.search({
    //         paging: { skip: 0 },
    //         filter: {
    //             groupId: {
    //                 operand1: groupId,
    //                 operator: 'equals'
    //             }
    //         }
    //     });

    //     return response.result;
    // }

    // const fetchServiceSubGroup = async () => {
    //     if (serviceSubGroups) {
    //         return serviceSubGroups;
    //     }

    //     const response2 = await serviceSubGroupCRUD.search({
    //         paging: { skip: 0 },
    //         sort: {
    //             name: { operator: 'asc' }
    //           }
    //     });
    //     return response2.result;
    // }

    // const fetch = async () => {
    //     setLoading(true);
    //     const serviceMaps = await fetchServiceMap();
    //     const serviceSubGroupIds2 = distinct(serviceMaps.map(_ => _.subGroupId), (a, b) => a == b);
    //     setServiceSubGroupIds(serviceSubGroupIds2);

    //     const serviceSubGroups2 = await fetchServiceSubGroup();
    //     setServiceSubGroups(serviceSubGroups2);

    //     const data = serviceSubGroups2.filter(_ => serviceSubGroupIds2.find(i => i == _.id));

    //     remove();
    //     for (let i = 0; i < data.length; i++) {
    //         const item = data[i];
    //         item.groupId = groupId;
    //         item.checked = true;            
    //     }

    //     append(data);

    //     setLoading(false);
    // }

    // useEffect(() => {
    //     if (!groupId)
    //         return;

    //     fetch();
    // }, [groupId]);

    const deleteColumnStyles = {
        position: "sticky",
        right: 0,
    },
        cellHeadStyles = { fontWeight: "bold" };

    function deleteActHandler(id) {
        const index = fields.findIndex(el => el.actId === id)
        remove(index);
    }

    const [open, setOpen] = useState(false)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{ overflowX: "auto" }}>
                <Typography color={"textSecondary"}>
                    {label || "Показатели"}
                </Typography>
            </Grid>
            {/* <Button size="large" sx={{ marginTop: '20px', marginLeft: "15px" }} onClick={() => setOpen(true)} disabled={disabled} variant={"contained"}>
                Выбрать показатели
            </Button> */}
            <Button size="large" sx={{ marginTop: '20px', marginLeft: "15px" }} onClick={() => append()} disabled={disabled} variant={"contained"}>
                Добавить группу
            </Button>
            {(fields || []).map((item, index) => {
                return (<>
                    <RHFGroupIndicator
                        name={`indicators[${index}]`}
                    />
                    <Button size="large" sx={{ marginTop: '20px', marginLeft: "15px" }} onClick={() => remove(index)} disabled={disabled} variant={"contained"}>
                        Удалить группу
                    </Button>
                </>
                )
            })}


            {/* <Grid container spacing={1}>
                <Grid item xs={6} style={{ overflowX: "auto" }}>
                    <RestAutoComplete
                        restApi={serviceGroupCRUD}
                        query={groupQuery}
                        id={"serviceGroup"}
                        label={"Группа"}
                        value={groupId}
                        onChange={(value, obj) => {
                            setGroupId(value?.id);
                        }}
                        //getOptionLabel={(_) => _.name}
                        fullWidth
                    />

                </Grid>

            </Grid>
            <LoadingContainer loading={loading}>
                <Grid container spacing={1}>
                    <Grid item style={{ overflowX: "auto" }}>
                        <Table>
                            <TableBody>
                                {(fields || []).map((item, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell align={"right"} style={deleteColumnStyles}>
                                                <RHFCheckbox name={`indicators[${index}].checked`} ></RHFCheckbox>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </LoadingContainer> */}

            <Divider />
            <FormHelperText error sx={{ p: 2 }}>
                {error?.message ? error?.message : null}
            </FormHelperText>
        </Grid>
    );
}

export default RHFIndicator;
