export const CONTRACT_SIGNER_GET_REQUEST = 'CONTRACT_SIGNER_GET_REQUEST';
export const CONTRACT_SIGNER_GET_SUCCESS = 'CONTRACT_SIGNER_GET_SUCCESS';
export const CONTRACT_SIGNER_GET_ERROR = 'CONTRACT_SIGNER_GET_ERROR';
export const CONTRACT_SIGNER_GET_BY_KEY_REQUEST = 'CONTRACT_SIGNER_GET_BY_KEY_REQUEST';
export const CONTRACT_SIGNER_GET_BY_KEY_SUCCESS = 'CONTRACT_SIGNER_GET_BY_KEY_SUCCESS';
export const CONTRACT_SIGNER_GET_BY_KEY_ERROR = 'CONTRACT_SIGNER_GET_BY_KEY_ERROR';
export const CONTRACT_SIGNER_ADD_REQUEST = 'CONTRACT_SIGNER_ADD_REQUEST';
export const CONTRACT_SIGNER_ADD_SUCCESS = 'CONTRACT_SIGNER_ADD_SUCCESS';
export const CONTRACT_SIGNER_ADD_ERROR = 'CONTRACT_SIGNER_ADD_ERROR';
export const CONTRACT_SIGNER_EDIT_REQUEST = 'CONTRACT_SIGNER_EDIT_REQUEST';
export const CONTRACT_SIGNER_EDIT_SUCCESS = 'CONTRACT_SIGNER_EDIT_SUCCESS';
export const CONTRACT_SIGNER_EDIT_ERROR = 'CONTRACT_SIGNER_EDIT_ERROR';
export const CONTRACT_SIGNER_DELETE_REQUEST = 'CONTRACT_SIGNER_DELETE_REQUEST';
export const CONTRACT_SIGNER_DELETE_SUCCESS = 'CONTRACT_SIGNER_DELETE_SUCCESS';
export const CONTRACT_SIGNER_DELETE_ERROR = 'CONTRACT_SIGNER_DELETE_ERROR';
