import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

const DirectionMaterialForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample, addSample, deleteSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Table aria-label="simple table" style={{ overflowX: "inherit" }}>
        <TableBody className="border-table-word">
          <TableRow>
            <TableCell className="border-table-word">
              <p>Облысы, ауданы/қаласы (Область, район/город):</p>
              <TextField
                id="outlined-basic"
                label="Область, район/город"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.location?.value}
                onChange={(e) => handleChangeData('location', e.target.value, 'location')}
              />
            </TableCell>
            <TableCell className="border-table-word" align="left">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Кабат (Пол):</p>
                <FormControl style={{ marginLeft: '30px', width: "90%" }}>
                  <InputLabel id="Пол">Пол</InputLabel>
                  <Select
                    labelId="Пол"
                    label="Пол"
                    disabled={isDisabledData}
                    value={data.data?.sex?.value}
                    onChange={(e) => handleChangeData('sex', e.target.value, 'sex')}
                  >
                    <MenuItem value={'Мужской'}>{'Мужской'}</MenuItem>
                    <MenuItem value={'Женский'}>{'Женский'}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p>Күні (Дата):</p>
                <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                  <DatePicker views={['day']} label="Дата"
                    disabled={isDisabledData}
                    value={data.data?.date?.value}
                    onChange={(value) => {
                      handleChangeData('date', value, 'date')
                    }}
                    minDate={new Date('1910-03-01')} renderInput={(params) =>
                      <TextField style={{ marginLeft: '30px', width: "90%" }} margin="normal"  {...params} helperText={null} />
                    } />
                </LocalizationProvider>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className="border-table-word"
              align="left"
              style={{ width: "50%" }}
            >
              <p>Науқастың эпиднөмірі(Эпидномер больного):</p>
              <TextField
                id="outlined-basic"
                label="Эпидномер больного"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.epidNumber?.value}
                onChange={(e) => handleChangeData('epidNumber', e.target.value, 'epidNumber')}
              />
            </TableCell>
            <TableCell
              className="border-table-word"
              style={{ width: "50%" }}
            >
              <p>
                Аурудың Т.А.Ә (болған жағдайда): Ф.И.О (при наличии)
                больного
              </p>
              <TextField
                id="outlined-basic"
                label="Ф.И.О (при наличии)"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.sickName?.value}
                onChange={(e) => handleChangeData('sickName', e.target.value, 'sickName')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border-table-word">
              <p>Туылған күні(Дата рождения):</p>
              <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                <DatePicker views={['day']} label="Дата рождения"
                  disabled={isDisabledData}
                  value={data.data?.birthday?.value}
                  onChange={(value) => {
                    handleChangeData('birthday', value, 'birthday')
                  }}
                  minDate={new Date('1910-03-01')} renderInput={(params) =>
                    <TextField margin="normal"  {...params} helperText={null} />
                  } />
              </LocalizationProvider>
            </TableCell>
            <TableCell className="border-table-word">
              <p>Жасы (Возраст):</p>
              <TextField
                id="outlined-basic"
                label="Возраст"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.age?.value}
                onChange={(e) => handleChangeData('age', e.target.value, 'age')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border-table-word">
              <p>
                Кәмелеттік жасқа толмаған балалар үшін анасының
                /әкесінің/қамқоршысының Т.А.Ә. (болған жағдайда):
                Ф.И.О.(при наличии) матери/отца/опекуна для
                несовершеннолетних детей:
              </p>
              <TextField
                id="outlined-basic"
                label="Ф.И.О.(при наличии) матери/отца/опекуна для
                      несовершеннолетних детей"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.parent?.value}
                onChange={(e) => handleChangeData('parent', e.target.value, 'parent')}
              />
            </TableCell>
            <TableCell className="border-table-word">
              <p>Мекен-жайы (Адрес):</p>
              <TextField
                id="outlined-basic"
                label="Адрес"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.adress?.value}
                onChange={(e) => handleChangeData('adress', e.target.value, 'adress')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border-table-word">
              <p>
                Қызылшаға, қызамыққа қарсы екпелер саны(Количество
                вакцинаций против кори, краснухи):
              </p>
              <TextField
                id="outlined-basic"
                label="Количество
                      вакцинаций против кори, краснухи"
                variant="outlined"
                type="number"
                disabled={isDisabledData}
                value={data.data?.vaccinationsQuantity?.value}
                onChange={(e) => handleChangeData('vaccinationsQuantity', e.target.value, 'vaccinationsQuantity')}
              />
            </TableCell>
            <TableCell className="border-table-word">
              <p>Соңғы екпе егілген күні(Дата последней вакцинации):</p>
              <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                <DatePicker views={['day']} label="Дата рождения"
                  disabled={isDisabledData}
                  value={data.data?.lastVaccinationDate?.value}
                  onChange={(value) => {
                    handleChangeData('lastVaccinationDate', value, 'lastVaccinationDate')
                  }}
                  minDate={new Date('1910-03-01')} renderInput={(params) =>
                    <TextField margin="normal"  {...params} helperText={null} />
                  } />
              </LocalizationProvider>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border-table-word">
              <p>
                Дене қызуының көтерілген күні(Дата повышения температуры):
              </p>
              <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                <DatePicker views={['day']} label="Дата повышения температуры"
                  disabled={isDisabledData}
                  value={data.data?.temperatureRiseDate?.value}
                  onChange={(value) => {
                    handleChangeData('temperatureRiseDate', value, 'temperatureRiseDate')
                  }}
                  minDate={new Date('1910-03-01')} renderInput={(params) =>
                    <TextField margin="normal"  {...params} helperText={null} />
                  } />
              </LocalizationProvider>
            </TableCell>
            <TableCell className="border-table-word">
              <p>Бөртпе пайда болған күні (Дата появления сыпи):</p>
              <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                <DatePicker views={['day']} label="Дата появления сыпи"
                  disabled={isDisabledData}
                  value={data.data?.emergenceRashDate?.value}
                  onChange={(value) => {
                    handleChangeData('emergenceRashDate', value, 'emergenceRashDate')
                  }}
                  minDate={new Date('1910-03-01')} renderInput={(params) =>
                    <TextField margin="normal"  {...params} helperText={null} />
                  } />
              </LocalizationProvider>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border-table-word">
              <p>Бөртпенің түрі(Тип сыпи):</p>
              <TextField
                id="outlined-basic"
                label="Тип сыпи"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.typeOfRash?.value}
                onChange={(e) => handleChangeData('typeOfRash', e.target.value, 'typeOfRash')}
              />
            </TableCell>
            <TableCell className="border-table-word">
              <p>
                Алдын ала қойылған клиникалық диагнозы(Предварительный
                клинический диагноз):
              </p>
              <TextField
                id="outlined-basic"
                label="Предварительный клинический диагноз"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.diagnosis?.value}
                onChange={(e) => handleChangeData('diagnosis', e.target.value, 'diagnosis')}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="border-table-word">
              <p>
                Зерттеу нәтижелерін мына қызметкерге Т.А.Ә.(болған
                жағдайда) жіберу керек(Ф.И.О. (при наличии) сотрудника,
                которому должны быть отправлены результаты исследования):
              </p>
              <TextField
                id="outlined-basic"
                label="Ф.И.О. (при наличии) сотрудника,
                      которому должны быть отправлены результаты исследования"
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.recipient?.value}
                onChange={(e) => handleChangeData('recipient', e.target.value, 'recipient')}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
        <Table
          aria-label="simple table"
          className="border-table-word"
        >
          <TableHead>
            <TableRow>
              <TableCell className="border-table-word">
                Үлгісі (Образец)
              </TableCell>
              <TableCell className="border-table-word">
                Талдау алынған күні (Дата сбора)
              </TableCell>
              <TableCell className="border-table-word">
                Жіберілген күні (Дата отправки)
              </TableCell>
              <TableCell className="border-table-word">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.samples?.value?.map((sample, i) => {
              return <TableRow key={i}>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Образец"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.sample?.value}
                    onChange={(e) => handleChangeSample('sample', e.target.value, i, 'sample')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker views={['day']} label="Дата сбора"
                      disabled={isDisabledData}
                      value={sample.selectionDate?.value}
                      onChange={(value) => {
                        handleChangeSample('selectionDate', value, i, 'selectionDate')
                      }}
                      minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{ width: '100%' }} margin="normal"  {...params} helperText={null} />
                      } />
                  </LocalizationProvider>
                </TableCell>
                <TableCell className="border-table-word">
                  <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker views={['day']} label="Дата отправки"
                      disabled={isDisabledData}
                      value={sample.sendingDate?.value}
                      onChange={(value) => {
                        handleChangeSample('sendingDate', value, i, 'sendingDate')
                      }}
                      minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{ width: '100%' }} margin="normal"  {...params} helperText={null} />
                      } />
                  </LocalizationProvider>
                </TableCell>
                <TableCell className="border-table-word">
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <IconButton color="error"
                      size="large"
                      aria-label="delete"
                      disabled={isDisabledData}
                      onClick={() => { deleteSample(i) }}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isDisabledData}
          variant="contained"
          onClick={addSample}
        >
          Добавить
        </Button>
      </div>
    </div >
  );
};

export default DirectionMaterialForm;
