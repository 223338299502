import { $authHost } from "../../http";
import { 
    SAMPLING_PURPOSE_GET_REQUEST, SAMPLING_PURPOSE_GET_SUCCESS, SAMPLING_PURPOSE_GET_ERROR, 
    SAMPLING_PURPOSE_GET_BY_KEY_REQUEST, SAMPLING_PURPOSE_GET_BY_KEY_SUCCESS, SAMPLING_PURPOSE_GET_BY_KEY_ERROR, 
    SAMPLING_PURPOSE_ADD_REQUEST, SAMPLING_PURPOSE_ADD_SUCCESS, SAMPLING_PURPOSE_ADD_ERROR, 
    SAMPLING_PURPOSE_EDIT_REQUEST, SAMPLING_PURPOSE_EDIT_SUCCESS, SAMPLING_PURPOSE_EDIT_ERROR, 
    SAMPLING_PURPOSE_DELETE_REQUEST, SAMPLING_PURPOSE_DELETE_SUCCESS, SAMPLING_PURPOSE_DELETE_ERROR
} from "../constants/samplingPurposeConstants";

export const getSamplingPurposes = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLING_PURPOSE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/samplingPurpose/query",
            args || {},
            config,
        );
        dispatch({
            type: SAMPLING_PURPOSE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SAMPLING_PURPOSE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getSamplingPurposeByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLING_PURPOSE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/samplingPurpose/${id}`,
        );
        dispatch({
            type: SAMPLING_PURPOSE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SAMPLING_PURPOSE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addSamplingPurpose = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLING_PURPOSE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/samplingPurpose",
            { name, code, description },
            config,
        );
        dispatch(getSamplingPurposes(queryArgs || {}));
        dispatch({
            type: SAMPLING_PURPOSE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SAMPLING_PURPOSE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editSamplingPurpose = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLING_PURPOSE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/samplingPurpose",
            { id, name, code, description },
            config,
        );
        dispatch(getSamplingPurposes(queryArgs || {}));
        dispatch({
            type: SAMPLING_PURPOSE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SAMPLING_PURPOSE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteSamplingPurpose = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLING_PURPOSE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/samplingPurpose",
            { data: { id } },
            config,
        );
        dispatch(getSamplingPurposes(queryArgs || {}));
        dispatch({
            type: SAMPLING_PURPOSE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SAMPLING_PURPOSE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

