import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl, InputLabel, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import { Table } from "../../../components/PDFGenerator/Table";
import DoctorField from "../../../components/Relations/DoctorField";
import EmployeeField from "../../../components/Relations/EmployeeField";
import { GovernmentStandardsSelect, TransportationConditionSelect } from "../../../components/Select/domainSelects";

const DirectionVirusForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          1. Медициналық ұйымның атауы (Наименование медицинской
          организации)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование медицинской организации"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.organizationName?.value}
          onChange={(e) => handleChangeData('organizationName', e.target.value, 'organizationName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Жолданған материал: аңқадан, мұрыннан алынған жағынды, жұлын
          сұйықтығы, қан, нәжіс-вирусқа зерттеу үшін, астын сызыңыз немесе
          толықтырыңыз (Направляется материал: мазок из зева, носа, ликвор,
          кровь, фекалии для исследования на вирус)
        </p>
        <TextField
          id="outlined-basic"
          label="Направляется материал: мазок из зева, носа, ликвор, кровь, фекалии для исследования на вирус"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.materials?.value}
          onChange={(e) => handleChangeData('materials', e.target.value, 'materials')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Науқастың Т.А.Ә (болған жағдайда)(Ф.И.О (при наличии) больного)
        </p>
        <TextField
          id="outlined-basic"
          label="Ф.И.О"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.sickName?.value}
          onChange={(e) => handleChangeData('sickName', e.target.value, 'sickName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>4. Жасы (Возраст)</p>
        <TextField
          id="outlined-basic"
          label="Возраст"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.age?.value}
          onChange={(e) => handleChangeData('age', e.target.value, 'age')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>5. Мекен жайы (Домашний адрес)</p>
        <TextField
          id="outlined-basic"
          label="Домашний адрес"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.homeAdress?.value}
          onChange={(e) => handleChangeData('homeAdress', e.target.value, 'homeAdress')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Мектепке дейінгі балалар ұйымы, оқу орны, жұмыс орны (Детская
          школьная организация, место учебы, место работы)
        </p>
        <TextField
          id="outlined-basic"
          label="Детская школьная организация, место учебы, место работы"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.workPlace?.value}
          onChange={(e) => handleChangeData('workPlace', e.target.value, 'workPlace')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>7. Ауырған күні(Дата заболевания)</p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата заболевания"
            disabled={isDisabledData}
            value={data.data?.illnessDate?.value}
            onChange={(value) => handleChangeData('illnessDate', value, 'illnessDate')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Емдеуге жатқызу күні (Дата госпитализации)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата госпитализации"
            disabled={isDisabledData}
            value={data.data?.hospitalizationDate?.value}
            onChange={(value) => handleChangeData('hospitalizationDate', value, 'hospitalizationDate')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Материал алынған күн (Дата взятия материала)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата взятия материала"
            disabled={isDisabledData}
            value={data.data?.materialSelectionDate?.value}
            onChange={(value) => handleChangeData('materialSelectionDate', value, 'materialSelectionDate')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>10. Диагнозы (Диагноз)</p>
        <TextField
          id="outlined-basic"
          label="Диагноз"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.diagnosis?.value}
          onChange={(e) => handleChangeData('diagnosis', e.target.value, 'diagnosis')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          11. Аурудың негізгі симптомдары (Основные симптомы заболевания)
        </p>
        <TextField
          id="outlined-basic"
          label="Основные симптомы заболевания"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.symptoms?.value}
          onChange={(e) => handleChangeData('symptoms', e.target.value, 'symptoms')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          12. Қысқа эпидемиологиялық анамнез (Краткий эпидемиологической
          анамнез)
        </p>
        <TextField
          id="outlined-basic"
          label="Краткий эпидемиологической анамне"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.anamnesis?.value}
          onChange={(e) => handleChangeData('anamnesis', e.target.value, 'anamnesis')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          13. Екпелер саны (Количество прививок)
        </p>
        <TextField
          id="outlined-basic"
          label="Количество прививок"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          type="number"
          disabled={isDisabledData}
          value={data.data?.vaccinationsQuantity?.value}
          onChange={(e) => handleChangeData('vaccinationsQuantity', e.target.value, 'vaccinationsQuantity')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          14. Соңғы егілу күні (Дата последней прививки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата последней прививки"
            value={data.data?.lastVaccinationDate?.value}
            disabled={isDisabledData}
            onChange={(value) => handleChangeData('lastVaccinationDate', value, 'lastVaccinationDate')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <EmployeeField
        label={"Врач"} fullWidth
        object={data.data?.doctor?.value}
        value={data.data?.doctor?.value.id}
        disabled={isDisabledData}
        onChange={(val) => {
          handleChangeData('doctor', val, 'doctor')
        }}
      />
    </div >
  );
};

export default DirectionVirusForm;
