import { 
    PROBE_SELECTION_REQUEST_GET_REQUEST, PROBE_SELECTION_REQUEST_GET_SUCCESS, PROBE_SELECTION_REQUEST_GET_ERROR, 
    PROBE_SELECTION_REQUEST_GET_BY_KEY_REQUEST, PROBE_SELECTION_REQUEST_GET_BY_KEY_SUCCESS, PROBE_SELECTION_REQUEST_GET_BY_KEY_ERROR, 
    PROBE_SELECTION_REQUEST_ADD_REQUEST, PROBE_SELECTION_REQUEST_ADD_SUCCESS, PROBE_SELECTION_REQUEST_ADD_ERROR, 
    PROBE_SELECTION_REQUEST_EDIT_REQUEST, PROBE_SELECTION_REQUEST_EDIT_SUCCESS, PROBE_SELECTION_REQUEST_EDIT_ERROR, 
    PROBE_SELECTION_REQUEST_DELETE_REQUEST, PROBE_SELECTION_REQUEST_DELETE_SUCCESS, PROBE_SELECTION_REQUEST_DELETE_ERROR
} from "../constants/probeSelectionRequestConstants";

const initialState = {
    probeSelectionRequests: [],
    probeSelectionRequest: {},
    loading: false,
    success: null,
    error: null,
    probeSelectionRequestRequestedCount: 0,
};

export const probeSelectionRequestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PROBE_SELECTION_REQUEST_GET_REQUEST:
            return {
                ...state,
                probeSelectionRequestRequestedCount: ++state.probeSelectionRequestRequestedCount,
                loading: true,
            };
        case PROBE_SELECTION_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                probeSelectionRequests: action.payload,
                success: true,
            };
        case PROBE_SELECTION_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_SELECTION_REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_SELECTION_REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                probeSelectionRequest: action.payload,
                success: true,
            };
        case PROBE_SELECTION_REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_SELECTION_REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_SELECTION_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROBE_SELECTION_REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_SELECTION_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_SELECTION_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROBE_SELECTION_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_SELECTION_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_SELECTION_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROBE_SELECTION_REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
