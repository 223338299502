import { jsonSchemaVisitor } from './jsonSchemaVisitor';
import { toUIJson } from '.';
import { formatDateAsDateTime } from './formatters';

export function mapService(source, references) {
  const result = [];
  const {
    serviceGroups,
    serviceSubGroups,
    serviceKinds,
    serviceTypes,
    unitOfMeasures,
  } = references;

  source.forEach((_) => {
    const group = serviceGroups.find((i) => i.id == _.groupId);
    const subGroup = serviceSubGroups.find((i) => i.id == _.subGroupId);
    const kind = serviceKinds.find((i) => i.id == _.kindId);
    const type = serviceTypes.find((i) => i.id == _.typeId);
    const unit = unitOfMeasures.find((i) => i.id == _.unitId);

    result.push({
      data: _,
      key: _.id,
      id: _.id,
      group: group ? group.name : "-",
      subGroup: subGroup?.name ?? "-",
      name: _.name,
      code: _.code ?? "-",
      price: _.price ?? "-",
      referralSchema: _.referralSchema?.jsonSchema ? "Есть" : "Нету",
      probeSchema: _.probeSchema?.jsonSchema ?? "-",
      resultSchema: _.resultSchema?.jsonSchema ?? "-",
      parent: _.parent?.name ?? "-",
      kind: kind?.name ?? "-",
      type: type?.name ?? "-",
      unit: unit?.name ?? "-",
    });
  });
  return result;
}

export function mapReferralForSubmit(
  source,
  newPatient,
  targetPatient,
  testTubes
) {
  const target = {
    ...source,
    targetPatient: newPatient || null,
    testTubes: [...testTubes],
    specifications: mapReferralSpecificationsForSubmit(
      source.specifications || []
    ),
  };
  target.targetPatientId = targetPatient?.id || null;
  delete target.responsible;
  delete target.responsibleForProbes;
  delete target.performer;
  delete target.department;
  delete target.filial;
  delete target.filialDivision;
  delete target.state;
  return target;
}

export function mapEmployeeForSubmit(source) {
  const target = {
    ...source,
  };
  return target;
}

export function mapObjectReferralForSubmit(data) {
  const { targetPatient, targetObject, testTubes, specifications } = data;

  const target = {
    ...data,
    targetPatientId: targetPatient?.id,
    targetObjectId: targetObject?.id,
    testTubes: testTubes ? [...testTubes] : [],
    specifications: mapReferralSpecificationsForSubmit(specifications || []),
  };

  const probes = target.probes;

  if (probes) {
    for (let i = 0; i < probes.length; i++) {
      probes[i].data = toUIJson(probes[i].data);
    }
  }

  if (target.standard2)
    target.standard2 = toUIJson(target.standard2);
  if (target.diagnosis)
    target.diagnosis = toUIJson(target.diagnosis);
  if (target.infectionKinds)
    target.infectionKinds = toUIJson(target.infectionKinds);
  if (target.regulationOfCU2)
    target.regulationOfCU2 = JSON.stringify(target.regulationOfCU2);

  delete target.targetPatient;
  delete target.targetObject;
  delete target.additionalInfo;
  delete target.medicalOrder;
  delete target.purposeOfSelection;
  delete target.regulationOfCU;
  delete target.samplePreservationMethod;
  delete target.standard;
  delete target.storageCondition;
  delete target.transportationCondition;
  delete target.additionalInfo;
  delete target.createdBy;
  delete target.responsible;
  delete target.performer;
  delete target.department;
  delete target.filial;
  delete target.filialDivision;
  delete target.state;
  delete target.infectionKind;

  return target;
}

export function mapReferralSpecificationsForSubmit(source) {
  const target = [];
  for (let i = 0; i < source.length; i++) {
    const sourceItem = source[i];
    const targetItem = { ...sourceItem };
    delete targetItem.service;
    delete targetItem.department;
    delete targetItem.division;
    target.push(targetItem);
  }

  return target;
}

export function mapReferralObjectProbeType(probeType) {
  switch (probeType) {
    case 1:
      return "Пищевые и непищевые продукты";
    case 2:
      return "Смывы/Пробы из внешней среды/Дез. средства";
    case 3:
      return "Вода/Почва";
    case 4:
      return "Замеры";
    case 5:
      return "КДИ";
    default:
      return "Нет проб";
  }
}

export function cleanObject(obj) {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  }
  return obj;
}

export function mapEmployeeData(data) {
  let target = {
    ...cleanObject(data),
  };
  delete target.userName;
  delete target.passwordHash;
  return target;
}

export function mapUserData(data) {
  let target = {
    ...cleanObject(data),
  };
  delete target.filialId;
  delete target.departmentId;
  delete target.fullName;
  delete target.identityNumber;
  delete target.personnelNumber;
  delete target.positionId;
  delete target.filialDivisionId;
  delete target.divisionId;
  return target;
}

export function mapFilialData(data) {
  let target = {
    ...cleanObject(data),
  };
  return target;
}

export function mapAccreditationData(data) {
  let target = {
    ...cleanObject(data),
  };
  return target;
}

export function mapPlanData(data) {
  let target = {
    ...cleanObject(data),
  };
  return target;
}

export function mapRoleData(data) {
  let target = {
    ...cleanObject(data),
  };
  return target;
}

export function mapContractSpecificationsForSubmit(specifications) {
  if(specifications)
    for (let i = 0; i < specifications.length; i++)
      specifications[i].count = specifications[i].quantity;
}

export function prepareContractRequestChildRequestDataForSubmit(specifications, objects) {
  for (let i = 0; i < specifications.length; i++) {
      specifications[i].contractRequestId = null;;
      specifications[i].contractRequest = null;
  }
    for (let i = 0; i < objects.length; i++) {
      objects[i].contractRequestId = null;;
      objects[i].contractRequest = null;;
    }
}

export function mapResult(item, schema) {
  let result = { id: item.id, number: item.number, createdAt: item.createdAt, createdBy: item.createdBy, form: item.form, filial: item.filial, filialDivision: item.filialDivision };

  let dataItem = item.data;
  if (!dataItem)
      return {};
  dataItem = toUIJson(dataItem);

  if (!schema)
      return result;

  let jsonSchema = schema.jsonSchema;

  //let level0Key = null;
  let level0Value = null;
  //let level1Key = null;
  let level1Value = null;
  //let level2Key = null;
  let level2Value = null;
  //let level3Key = null;
  let level3Value = null;
  jsonSchemaVisitor(jsonSchema, {
      level: 0,
      visit: (key, index, item, isObject, options) => {
        const id = options.id;
          switch (options.level) {
              case 0:
                  let value0 = dataItem[key];

                  if (isObject) {
                      //level0Key = key;
                      level0Value = value0;
                  } else
                      result[id] = value0 || "";
                  break;
              case 1:
                  let value1 = level0Value ? level0Value[key] : '';

                  if (isObject) {
                      //level1Key = key;
                      level1Value = value1;
                  } else
                      result[id] = value1;
                  break;
              case 2:
                  let value2 = level1Value ? level1Value[key] : '';

                  if (isObject) {
                      //level2Key = key;
                      level2Value = value2;
                  } else
                      result[id] = value2;
                  break;
              case 3:
                  let value3 = level2Value ? level2Value[key] : '';

                  if (isObject) {
                      //level3Key = key;
                      level3Value = value3;
                  } else
                      result[id] = value3;
                  break;
              default:
                  throw new Error("Schema level of " + options.level + " is not supported");
          }
      }
  });

  return result;
}

export function mapRole(role) {
  switch (role) {
    case 'SuperAdministrator':
      return "СуперАдминистратор";
    case 'Administrator':
      return "Администратор";
    case 'Patient':
      return "Пациент";
    case 'Marketer':
      return "Маркетолог";
    case 'Supervisor':
      return "Заведующий";
    case 'Performer':
      return "Лаборант";
    case 'Tarificator':
      return "Тарификатор";
    case 'Specialist':
      return "ООП";
    case 'Client':
      return "Клиент";
    case 'Committee':
      return "КСЭК";
    case 'AdministratorReadonly':
      return "Центральный аппарат";
    case 'FilialAdministrator':
      return "Администратор филиала";
    case 'Director':
      return "Директор";
    default:
      return "Не определено";
  }
}

export function mapProtocolSigners(data) {
  const result = {
    performer: data?.research_specialist,
    responsible: data?.lab_director,
    leader: data?.organization_leader
  };
  const signers = [];

  if (result.performer)
    signers.push({
      employeeId: result.performer.id,
      userId: result.performer.userId
    });
  if (result.responsible)
    signers.push({
      employeeId: result.responsible.id,
      userId: result.responsible.userId
    });
  if (result.leader)
    signers.push({
      employeeId: result.leader.id,
      userId: result.leader.userId
    });
  result.signers = signers;

  return result;
}

export function mapProtocolFieldNumber(number, i) {
  return number === undefined ? (i+1) + '. ' : (number ? number + '. ' : '')
}

export function mapOrganizationName(organizationId) {
  switch (organizationId) {
    case 1:
      return "НЦЭ";
    case 2:
      return "КСЭК";
    default:
      return "Не определено";
  }
}

export function mapOrganizationValues() {
  return [
    { id: 1, name: 'НЦЭ' },
    { id: 2, name: 'КСЭК' },
  ];
}
