import {LoadingButton} from "@mui/lab";
import {
    Button,
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";

import {
    PageHeader,
} from "../../../components";
import {renderControls, RequestStateChecker} from "../../../functions";

import DashboardLayout from "../../../layouts/Dashboard";
import {
    workflowServiceWithoutActRequest, workflowWithoutDepartureRequest,
} from "../../../redux/actions";
import {
    getServiceWithoutActRequestByKey,
} from "../../../redux/actions";
import {
    ServiceWithoutActRequestInfoSection,
} from "./Sections";
import {CONTRACT_REQUEST, SERVICE_WITHOUT_ACT_REQUEST} from "../../../routes";

import {protocolCRUD, resultCRUD, serviceWithoutActRequestCRUD} from "../../../http";
import {REQUEST_STATE_ID_CREATED} from "../../../redux/constants/requestStateConstants";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";

export const ServiceWithoutActRequestInfo = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const {serviceWithoutActRequest} = useSelector(
        (s) => s.serviceWithoutActRequest
    );

    const state = useMemo(
        () => new RequestStateChecker(serviceWithoutActRequest?.state?.code),
        [serviceWithoutActRequest?.state?.code]
    );

    const [request, setRequest] = useState(serviceWithoutActRequest);
    const [requestId] = useState(id);
    const [stateId, setStateId] = useState(0);
    const [results, setResults] = useState([]);
    const [protocols, setProtocols] = useState([]);

    const [createResultOpen, setCreateResultOpen] = useState(false);
    const [protocolAddOpen, setProtocolAddOpen] = useState(false);

    const stateCode = serviceWithoutActRequest?.state?.code?.toLowerCase();

    const handleEdit = async () => {
        await serviceWithoutActRequestCRUD.edit(request)
    }

    const handleWorkflow = async (e, nextStateId) => {
        e.preventDefault();
        setLoading(true);

        if (!serviceWithoutActRequest?.state?.id) return setLoading(false);

        const prevStateId = serviceWithoutActRequest.state.id;

        // Updating Workflow
        const updated = await dispatch(
            workflowServiceWithoutActRequest(id, prevStateId, nextStateId)
        );
        if (!updated) return;

        setStateId(nextStateId);
        setLoading(false);
    };

    const handleWorkflowDeclinedToCreate = async () => {
        setLoading(true);

        if (!serviceWithoutActRequest?.state?.id) return setLoading(false);

        const prevStateId = serviceWithoutActRequest.state.id;
        const nextStateId = state.getId(REQUEST_STATE_ID_CREATED);

        // Updating Workflow
        const updated = await dispatch(
            workflowServiceWithoutActRequest(requestId, prevStateId, nextStateId)
        );
        if (!updated) return setLoading(false);

        setStateId(nextStateId);
        setLoading(false);
    };

    const controls = [
        {
            state: "created",
            roles: [],
            buttons: [
                <LoadingButton
                    loading={loading}
                    color="primary"
                    onClick={(e) => handleWorkflow(e, 2)}
                >
                    Начать обработку
                </LoadingButton>,
                <Button
                    color="secondary"
                    onClick={handleEdit}
                >
                    Сохранить
                </Button>,
                <Button
                    variant={"text"}
                    onClick={() => history.push(SERVICE_WITHOUT_ACT_REQUEST)}
                >
                    Вернуться
                </Button>
            ],
        },
        {
            state: "processing",
            roles: [],
            buttons: [
                <LoadingButton
                    loading={loading}
                    color="primary"
                    onClick={(e) => handleWorkflow(e, 3)}
                >
                    Начать исследование
                </LoadingButton>,
                <Button
                    color="secondary"
                    onClick={handleEdit}
                >
                    Сохранить
                </Button>,
                <Button
                    variant={"text"}
                    onClick={() => history.push(SERVICE_WITHOUT_ACT_REQUEST)}
                >
                    Вернуться
                </Button>
            ],
        },
        {
            state: "research",
            roles: [],
            buttons: [
                <LoadingButton
                    variant="outlined"
                    onClick={() => setCreateResultOpen(true)}
                    loading={loading}
                >
                    Добавить в журнал
                </LoadingButton>,
                <LoadingButton
                    variant="outlined"
                    onClick={() => setProtocolAddOpen(true)}
                    loading={loading}
                >
                    Создать протокол
                </LoadingButton>,
                <LoadingButton
                    loading={loading}
                    color="primary"
                    onClick={(e) => handleWorkflow(e, 4)}
                >
                    Завершить
                </LoadingButton>,
                <LoadingButton color="error" onClick={(e) => handleWorkflow(e, 5)}>
                    Отменить
                </LoadingButton>,
                <Button
                    color="secondary"
                    onClick={handleEdit}
                >
                    Сохранить
                </Button>,
                <Button
                    variant={"text"}
                    onClick={() => history.push(SERVICE_WITHOUT_ACT_REQUEST)}
                >
                    Вернуться
                </Button>
            ],
        },
        {
            state: "completed",
            roles: [],
            buttons: [
                <Button
                    variant={"text"}
                    onClick={() => history.push(SERVICE_WITHOUT_ACT_REQUEST)}
                >
                    Вернуться
                </Button>
            ]
        },
        {
            state: "declined",
            roles: [],
            buttons: [
                <LoadingButton
                    loading={loading}
                    onClick={() => {
                        confirmDialog('Пересоздание', 'Вы действительно хотите пересоздать заявку?', async () => {
                            await handleWorkflowDeclinedToCreate()
                        })
                    }
                    }
                >
                    Пересоздать
                </LoadingButton>,
                <Button
                    variant={"text"}
                    onClick={() => history.push(CONTRACT_REQUEST)}
                >
                    Вернуться
                </Button>
            ],
        }
    ];

    const fetch = async () => {
        setLoading(true);

        const response = await dispatch(getServiceWithoutActRequestByKey(requestId));
        setRequest(response);

        const resultsResponse = await resultCRUD.search({
            paging: {skip: 0},
            filter: {
                serviceWithoutActRequestId: {
                    operand1: response.id,
                    operator: 'equals'
                }
            }
        });
        const protocolResponse = await protocolCRUD.search({
            paging: {skip: 0},
            filter: {
                serviceWithoutActRequestId: {
                    operand1: response.id,
                    operator: 'equals'
                }
            }
        });
        setProtocols(protocolResponse.result);
        setResults(resultsResponse.result);

        setLoading(false);
    }

    useEffect(() => {
        async function fetchData() {
            await fetch();
        }

        fetchData();
    }, [dispatch, requestId, stateId]);

    return (
        <DashboardLayout>
            <PageHeader
                heading={`Заявка без акта №${id} ${
                    stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
                }`}
                status={serviceWithoutActRequest.state?.name}
                controls={renderControls(controls, {
                    state: stateCode,
                    roles: [],
                })}
            />
            <ServiceWithoutActRequestInfoSection data={request} fetch={fetch}
                                                 results={results}
                                                 protocols={protocols}
                                                 setData={setRequest}
                                                 createResultOpen={createResultOpen}
                                                 setCreateResultOpen={setCreateResultOpen}
                                                 protocolAddOpen={protocolAddOpen}
                                                 setProtocolAddOpen={setProtocolAddOpen}
            />
        </DashboardLayout>
    );
};
