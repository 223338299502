import { $authHost } from "../../http";
import { 
    BRIGADE_TYPE_GET_REQUEST, BRIGADE_TYPE_GET_SUCCESS, BRIGADE_TYPE_GET_ERROR, 
    BRIGADE_TYPE_GET_BY_KEY_REQUEST, BRIGADE_TYPE_GET_BY_KEY_SUCCESS, BRIGADE_TYPE_GET_BY_KEY_ERROR, 
    BRIGADE_TYPE_ADD_REQUEST, BRIGADE_TYPE_ADD_SUCCESS, BRIGADE_TYPE_ADD_ERROR, 
    BRIGADE_TYPE_EDIT_REQUEST, BRIGADE_TYPE_EDIT_SUCCESS, BRIGADE_TYPE_EDIT_ERROR, 
    BRIGADE_TYPE_DELETE_REQUEST, BRIGADE_TYPE_DELETE_SUCCESS, BRIGADE_TYPE_DELETE_ERROR
} from "../constants/brigadeTypeConstants";

export const getBrigadeTypes = (args) => async (dispatch) => {
    try {
        dispatch({
            type: BRIGADE_TYPE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/brigadeType/query",
            args || {},
            config,
        );
        dispatch({
            type: BRIGADE_TYPE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: BRIGADE_TYPE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getBrigadeTypeByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: BRIGADE_TYPE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/brigadeType/${id}`,
        );
        dispatch({
            type: BRIGADE_TYPE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: BRIGADE_TYPE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addBrigadeType = ({ name, code }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: BRIGADE_TYPE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/brigadeType",
            { name, code },
            config,
        );
        dispatch(getBrigadeTypes(queryArgs || {}));
        dispatch({
            type: BRIGADE_TYPE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: BRIGADE_TYPE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editBrigadeType = ({ id, name, code }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: BRIGADE_TYPE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/brigadeType",
            { id, name, code },
            config,
        );
        dispatch(getBrigadeTypes(queryArgs || {}));
        dispatch({
            type: BRIGADE_TYPE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: BRIGADE_TYPE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteBrigadeType = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: BRIGADE_TYPE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/brigadeType",
            { data: { id } },
            config,
        );
        dispatch(getBrigadeTypes(queryArgs || {}));
        dispatch({
            type: BRIGADE_TYPE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: BRIGADE_TYPE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

