import {CircularProgress, FormControl} from "@mui/material";
import {useEffect, useMemo} from "react";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";

import Select from "./Select";
import {serviceMapCRUD} from "../../http";
import useLoader from "../../hooks/useLoader";
import {distinctArrayObject, getQueryFilterArray} from "../../models/data/EntityCollectionModel";
import { where } from "../../functions/linqSimulation";

const DomainSelect = ({
                          reducerArrayKey,
                          reducerKey,
                          reducerCountKey,
                          reducerAction,
                          reducerQuery,
                          isNullable,
                          initialOptions,
                          filterQuery,
                          ...props
                      }) => {
    const dispatch = useDispatch();
    const {loading, start, stop} = useLoader(false);

    if (initialOptions) {
        reducerArrayKey = 'initialOptions';
    }

    const keyRequestedCount = useMemo(
        () => `${reducerCountKey || reducerKey}RequestedCount`,
        [reducerKey]
    );

    let {[reducerArrayKey]: options, [keyRequestedCount]: requestedCount} =
        useSelector((state) => !initialOptions ? state[reducerKey] : {
            [reducerArrayKey]: initialOptions,
            [keyRequestedCount]: 1
        });

    reducerQuery = reducerQuery || {};
    useEffect(() => {
        if (requestedCount > 0) return;
        dispatch(
            reducerAction({
                paging: {skip: 0},
                sort: {name: {operator: "asc"}},
                ...reducerQuery
            })
        );
    }, [dispatch, requestedCount, reducerAction, reducerQuery])
    if (isNullable) {
        const emptyOption = {};
        emptyOption[props.optionValueKey] = null;
        emptyOption[props.optionLabelKey] = 'Не указано';
        options = [emptyOption, ...options];
    }

    let [items, setItems] = React.useState(options);

    React.useEffect(() => {
        if (props?.filter) {
            options = where(options, props.filter);            
        }        
        if (items.length != options.length)
            setItems(options);
        // if(props.filterDivisionOptions){
        //     console.log(options.filter(props.filterDivisionOptions))
        //     setItems(options.filter(props.filterDivisionOptions))
        //     console.log(items)
        // }
    }, [ options, props?.filter])


    React.useEffect(() => {
        if (props?.parentId?.operand1 && !filterQuery) {
            setItems(options.filter(el => el[props.parentKey] === props.parentId?.operand1))
        }
    }, [props?.parentId?.operand1, filterQuery])

    // React.useEffect(() => {
    //     if(props.filterDivisionOptions && props.filterDivisionOptions != undefined){
    //     console.log(props.filterDivisionOptions)
    //     setItems(options.filter(props.filterDivisionOptions))
    //     }
    // }, [ props?.filterDivisionOptions])

    React.useEffect(() => {
        (async function () {
            if (filterQuery && props?.parentId?.operand1) {
                start()
                let key = props.parentKey;
                let key2 = props.parentKey2;
                let key3 = props.parentKey3;
                let value = props?.parentId?.operand1;
                let value2 = props?.parentId2?.operand1;
                let value3 = props?.parentId3?.operand1;

                let items = await getQueryFilterArray(key, key2, key3, value, value2, value3)

                setItems(items)
                stop()
            }
        }())
    }, [filterQuery, props?.parentId?.operand1, props?.parentId2?.operand1, props?.parentId3?.operand1, props?.parentKey, props?.parentKey2, props?.parentKey3])

    return (
        <FormControl sx={props?.formStyle} disabled={props.disabled} margin={props.margin || "normal"} fullWidth>
            {loading ? <CircularProgress color="secondary" sx={{alignSelf: 'center'}}/> :
                <Select options={items} reducerKey={reducerKey} {...props} />}
        </FormControl>
    );
};

DomainSelect.defaultProps = {
    optionValueKey: "id",
    optionLabelKey: "name",
};

export default DomainSelect;