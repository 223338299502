import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Box,
  Button,
  FormGroup,
  InputLabel,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton, TimePicker } from "@mui/lab";

import {
  getContractRequestByKey,
  getContractByKey,
  getContractRequests,
  workflowContractRequest,
  getContractStates,
  getContracts,
} from "../../../redux/actions";
import {
  PageHeader,
  PageContainer,
  PageFooter,
  Form,
  FormSection,
  CustomDatePicker,
  SelectFieldModal,
  CustomTimePicker,
  SelectField,
  BasicTable,
  ButtonModal,
  NavLinkButton,
  ConditionalContainer,
} from "../../../components";

import { formatDateAsDate } from '../../../functions/formatters';

import DashboardLayout from "../../../layouts/Dashboard";

import {
  DDDRequestCreateModal,
  KDIRequestCreateModal,
  DepartureScheduleRequestCreateModal,
  ServiceWithoutActRequestCreateModal,
  WithoutDepartureRequestCreateModal,
} from "./../../Requests";

import { RequestStateChecker } from "./../../../functions";
import {SelfControlRequestCreateModal} from "../../Requests/SelfControlRequest";

export const ContractRequestInfoSection = ({ mode }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { contractRequest } = useSelector((state) => state.contractRequest);
  // const { contract } = useSelector((s) => s.contract);

  const stateCode = contractRequest?.state?.code?.toLowerCase();
  const state = new RequestStateChecker(stateCode);

  const [educationServices, setEducationServices] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [objects, setObjects] = useState([]);

  const [departureSchedules, setDepartureSchedules] = useState([]);
  const [disinfectionRequests, setDisinfectionRequests] = useState([]);
  const [clinicalRequests, setClinicalRequests] = useState([]);
  const [withoutDepartureRequests, setWithoutDepartureRequests] = useState([]);
  const [serviceWithoutActRequests, setServiceWithoutActRequests] = useState([])
  const [selfControlRequests, setSelfControlRequests] = useState(
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setEducationServices(contractRequest.educationServices);
    setObjects(contractRequest.objects);
    setSpecifications(contractRequest.specifications);

    setDepartureSchedules(contractRequest.departureSchedules);
    setDisinfectionRequests(contractRequest.desinsectionRequests);
    setWithoutDepartureRequests(contractRequest.withoutDepartureRequests);
    setClinicalRequests(contractRequest.clinicalRequests);
    setServiceWithoutActRequests(contractRequest.serviceWithoutActRequests);
    setSelfControlRequests(contractRequest.selfControlRequests);
  }, [contractRequest]);


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection mb={5}>
            <Typography component="h4" align="left" variant="h6">
              Заявка
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Номер заявки"
                  type="number"
                  value={contractRequest?.id || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Статус заявки"
                  type="text"
                  value={contractRequest?.state?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomDatePicker
                      label="Дата заявки"
                      value={contractRequest?.createdAt}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTimePicker
                      label="Время заявки"
                      value={contractRequest?.createdAt}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  type="text"
                  value={contractRequest?.createdBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Филиал"
                  type="text"
                  value={contractRequest?.filial?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Исполнитель заявки"
                  type="text"
                  value={contractRequest?.performer?.fullName}
                  disabled
                />
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  label="Ответственный за отбор"
                  type="text"
                  value={contractRequest?.responsible?.fullName}
                  disabled
                />
              </Grid> */}
            </Grid>
          </FormSection>
          <FormSection mb={5}>
            <Typography component="h4" align="left" variant="h6">
              Договор
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  label="Номер договора"
                  type="number"
                  value={contractRequest?.contractNumber || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Вид договора"
                  type="text"
                  value={contractRequest?.contractType}
                  disabled
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  label="Статус по договору"
                  type="text"
                  value={contractRequest?.contractState?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата договора"
                  value={contractRequest?.contractActionAt}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Исполнитель по договору"
                  type="text"
                  value={contractRequest?.contractFilial?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Районное отделение"
                  type="text"
                  value={contractRequest?.contractFilialDivision?.name}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  label="Статус оплаты"
                  type="text"
                  value={contractRequest?.contractPayState?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Количество исследований"
                  type="number"
                  value={contractRequest?.researchCount || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Сумма договора"
                  type="number"
                  value={contractRequest?.contractSummary || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Сумма реализации"
                  type="number"
                  value={contractRequest?.contractRealizationSummary || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Оплаченная сумма по договору"
                  type="number"
                  value={contractRequest?.contractPaySummary || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Остаток суммы выплаты по договору"
                  type="number"
                  value={contractRequest?.contractPaySummaryRemainder || 0}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={5}>
            <Typography component="h4" align="left" variant="h6">
              Обучение ({educationServices?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("educationService.tableItem.id") },
                    {
                      id: "kind",
                      label: t("educationService.tableItem.kind"),
                      render: (row) => row?.kind?.name,
                    },
                    {
                      id: "service",
                      label: t("educationService.tableItem.service"),
                      render: (row) => row.service?.name,
                    },
                  ]}
                  rows={educationServices}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={5}>
            <Typography component="h4" align="left" variant="h6">
              Объекты ({objects?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    {
                      id: "name",
                      label: t("object.tableItem.name"),
                      render: (row) => row.object?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={objects}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection>
            <Typography component="h4" align="left" variant="h6">
              Перечень услуг ({specifications?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "group",
                      label: 'Группа',
                      render: ({ service }) => service?.group?.name,
                    },
                    {
                      id: "method",
                      label: 'Метод',
                      render: ({ service }) => service?.kind?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                    },
                    {
                      id: "contractCount",
                      label: t("specification.tableItem.quantityContract"),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={specifications}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </FormSection>

          <ConditionalContainer
            condition={state.isProcessing() || state.isFinished()}
          >
            <Divider style={{ margin: "50px 0px" }} />
            <FormSection mb={5}>
              <Typography component="h4" align="left" variant="h6">
                По самоконтролю (
                {selfControlRequests?.length || 0})
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                      cells={[
                        { id: "number", label: t("request.tableItem.number") },
                        {
                          id: "state",
                          label: t("request.tableItem.state"),
                          render: (row) => row?.state?.name,
                        },
                        {
                          id: "createdBy",
                          label: t("request.tableItem.createdBy"),
                          render: (row) => row?.createdBy?.fullName,
                        },
                        // {
                        //   id: "reponsiblePerformerId",
                        //   label: t("request.tableItem.performer"),
                        //   render: (row) => row?.reponsiblePerformerId?.fullName,
                        // },
                        {
                          id: "department",
                          label: t("request.tableItem.department"),
                          render: (row) => row?.department?.name,
                        },
                        {
                          id: "responsible",
                          label: t("request.tableItem.responsible"),
                          render: (row) => row?.responsible?.fullName,
                        },
                        {
                          id: "reponsiblePerformer",
                          label: t("request.tableItem.reponsiblePerformer"),
                          render: (row) => row?.reponsiblePerformer?.fullName,
                        },
                        {
                          id: "departureDate",
                          label: t("request.tableItem.dateOfDeparture"),
                          render: (row) => row?.departureDate,
                        },
                        {
                          id: "createdAt",
                          label: t("request.tableItem.createdAt"),
                        },
                      ]}
                      rows={selfControlRequests}
                      disabled={!state.isProcessing()}
                      button={
                        <ButtonModal modal={SelfControlRequestCreateModal}>
                          Создать заявку
                        </ButtonModal>
                      }
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection
              heading={`График выездов ОПП (${
                departureSchedules?.length || 0
              })`}
              mb={5}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("request.tableItem.id") },
                      {
                        id: "state",
                        label: t("request.tableItem.state"),
                        render: (row) => row?.state?.name,
                      },
                      {
                        id: "createdBy",
                        label: t("request.tableItem.createdBy"),
                        render: (row) => row?.createdBy?.fullName,
                      },
                      {
                        id: "performer",
                        label: t("request.tableItem.performer"),
                        render: ({ performer }) => performer?.fullName,
                      },
                      {
                        id: "filial",
                        label: t("request.tableItem.filial"),
                        render: (row) => row?.filial?.name,
                      },
                      {
                        id: "filialDivision",
                        // TODO: use t
                        label: "Районное отделение",
                        render: (row) => row?.filialDivision?.name,
                      },
                      {
                        id: "authorComment",
                        label: "Комментарий автора",
                      },
                      {
                        id: "performerComment",
                        label: "Комментарий исполнителя",
                      },
                      {
                        id: "dateOfDeparture",
                        label: t("request.tableItem.dateOfDeparture"),
                        render: (row) => formatDateAsDate(row?.dateOfDeparture),
                      },
                      {
                        id: "createdAt",
                        label: t("request.tableItem.createdAt"),
                        render: (row) => formatDateAsDate(row?.createdAt),
                      },
                    ]}
                    rows={departureSchedules}
                    disabled={!state.isProcessing()}
                    button={
                      <ButtonModal modal={DepartureScheduleRequestCreateModal}>
                        Создать заявку
                      </ButtonModal>
                    }
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection mb={5}>
              <Typography component="h4" align="left" variant="h6">
                Заявка в лабораторию без выезда (
                {withoutDepartureRequests?.length || 0})
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("request.tableItem.id") },
                      {
                        id: "state",
                        label: t("request.tableItem.state"),
                        render: ({ state }) => state?.name,
                      },
                      {
                        id: "createdBy",
                        label: t("request.tableItem.createdBy"),
                        render: (row) => row?.createdBy?.fullName,
                      },
                      {
                        id: "performer",
                        label: t("request.tableItem.performer"),
                        render: ({ performer }) => performer?.fullName,
                      },
                      {
                        id: "filial",
                        label: t("request.tableItem.filial"),
                        render: (row) => row?.filial?.name,
                      },
                      {
                        id: "filialDivision",
                        // TODO: use t
                        label: "Районное отделение",
                        render: (row) => row?.filialDivision?.name,
                      },
                      {
                        id: "department",
                        label: t("request.tableItem.department"),
                        render: ({ department }) => department?.name,
                      },
                      {
                        id: "division",
                        label: t("request.tableItem.division"),
                        render: ({ division }) => division?.name,
                      },
                      // {
                      //   id: "specialist",
                      //   label: t("request.tableItem.specialist"),
                      //   render: ({ specialist }) => specialist?.fullName,
                      // },
                      {
                        id: "createdAt",
                        label: t("request.tableItem.createdAt"),
                        render: (row) => formatDateAsDate(row?.createdAt),
                      },
                    ]}
                    rows={withoutDepartureRequests}
                    disabled={!state.isProcessing()}
                    button={
                      <ButtonModal modal={WithoutDepartureRequestCreateModal}>
                        Создать заявку
                      </ButtonModal>
                    }
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection mb={5}>
              <Typography component="h4" align="left" variant="h6">
                Заявка в отдел дезинфекции ({disinfectionRequests?.length || 0})
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("request.tableItem.id") },
                      {
                        id: "state",
                        label: t("request.tableItem.state"),
                        render: ({ state }) => state?.name,
                      },
                      {
                        id: "createdBy",
                        label: t("request.tableItem.createdBy"),
                        render: (row) => row?.createdBy?.fullName,
                      },
                      {
                        id: "performer",
                        label: t("request.tableItem.performer"),
                        render: (row) => row?.performer?.fullName,
                      },
                      {
                        id: "filial",
                        label: t("request.tableItem.filial"),
                        render: ({ filial }) => filial?.name,
                      },
                      {
                        id: "division",
                        label: t("request.tableItem.division"),
                        render: ({ division }) => division?.name,
                      },
                      {
                        id: "departmentHead",
                        label: t("request.tableItem.departmentHead"),
                        render: ({ departmentHead }) =>
                          departmentHead?.fullName,
                      },
                      {
                        id: "specialist",
                        label: t("request.tableItem.specialist"),
                        render: ({ specialist }) => specialist?.fullName,
                      },
                      {
                        id: "departureDate",
                        label: t("request.tableItem.departureDate"),
                      },
                      {
                        id: "createdAt",
                        label: t("request.tableItem.createdAt"),
                      },
                    ]}
                    rows={disinfectionRequests}
                    disabled={!state.isProcessing()}
                    button={
                      <ButtonModal modal={DDDRequestCreateModal}>
                        Создать заявку
                      </ButtonModal>
                    }
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection mb={5}>
              <Typography component="h4" align="left" variant="h6">
                Заявка в лабораторию без акта (
                {serviceWithoutActRequests?.length || 0})
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "number", label: t("request.tableItem.number") },
                      {
                        id: "state",
                        label: t("request.tableItem.state"),
                        render: (row) => row?.state?.name,
                      },
                      {
                        id: "createdBy",
                        label: t("request.tableItem.createdBy"),
                        render: (row) => row?.createdBy?.fullName,
                      },
                      // {
                      //   id: "reponsiblePerformerId",
                      //   label: t("request.tableItem.performer"),
                      //   render: (row) => row?.reponsiblePerformerId?.fullName,
                      // },
                      {
                        id: "department",
                        label: t("request.tableItem.department"),
                        render: (row) => row?.department?.name,
                      },
                      {
                        id: "responsible",
                        label: t("request.tableItem.responsible"),
                        render: (row) => row?.responsible?.fullName,
                      },
                      {
                        id: "reponsiblePerformer",
                        label: t("request.tableItem.reponsiblePerformer"),
                        render: (row) => row?.reponsiblePerformer?.fullName,
                      },
                      {
                        id: "departureDate",
                        label: t("request.tableItem.dateOfDeparture"),
                        render: (row) => row?.departureDate,
                      },
                      {
                        id: "createdAt",
                        label: t("request.tableItem.createdAt"),
                      },
                    ]}
                    rows={serviceWithoutActRequests}
                    disabled={!state.isProcessing()}
                    button={
                      <ButtonModal modal={ServiceWithoutActRequestCreateModal}>
                        Создать заявку
                      </ButtonModal>
                    }
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection>
              <Typography component="h4" align="left" variant="h6">
                Заявка в лабораторию на отработку заявок по КДИ (
                {clinicalRequests?.length || 0})
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "number", label: t("request.tableItem.number") },
                      {
                        id: "state",
                        label: t("request.tableItem.state"),
                        render: (row) => row?.state?.name,
                      },
                      {
                        id: "performer",
                        label: t("request.tableItem.performer"),
                        render: (row) => row?.performer?.fullName,
                      },
                      {
                        id: "filial",
                        label: t("request.tableItem.filial"),
                        render: (row) => row?.filial?.name,
                      },
                      {
                        id: "filialDivision",
                        label: t("request.tableItem.division"),
                        render: (row) => row?.filialDivision?.name,
                      },
                      {
                        id: "responsiblePerformer",
                        label: t("request.tableItem.responsiblePerformer"),
                        render: (row) => row?.responsiblePerformer?.fullName,
                      },

                      {
                        id: "createdAt",
                        label: t("request.tableItem.createdAt"),
                        render: (row) => row?.createdAt,
                      },
                    ]}
                    rows={clinicalRequests}
                    disabled={!state.isProcessing()}
                    button={
                      <ButtonModal modal={KDIRequestCreateModal}>
                        Создать заявку
                      </ButtonModal>
                    }
                  />
                </Grid>
              </Grid>
            </FormSection>
          </ConditionalContainer>
        </PageContainer>
      </Form>
    </>
  );
};
