import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProtocolForms, getProtocolFormByKey } from "../../../redux/actions/protocolFormActions";
import ProtocolForms from '../ProtocolForms/';

import { mapProtocolSigners } from "../../../functions/viewDataMap";
import { asJSONSafe } from "../../../functions";

const style = {
    position: 'absolute',
    top: '10%',
    bottom: '20%',
    left: '20%',
    right: '20%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow:'auto',
    height:'85%',
    display:'block',
    p: 4,
};

export default function EditProtocol({ action, title, open, setOpen, onSubmit, protocol, loading }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [number, setNumber] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date());
    const [formId, setFormId] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const [resultId, setResultId] = useState(null);
    const [filialId, setFilialId] = useState(null);
    const [filialDivisionId, setFilialDivisionId] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [divisionId, setDivisionId] = useState(null);
    const [responsibleId, setResponsibleId] = useState(null);
    const [performerId, setPerformerId] = useState(null);
    const [leaderId, setLeaderId] = useState(null);
    const [stateId, setStateId] = useState(null);
    const [data, setData] = useState()
    const [version, setVersion] = useState('2');

    const emptyState = () => {
        setId(null);
        setNumber('');
        setStateId(null);
        setCreatedAt(new Date());
        setFormId(null);
        setReferralId(null);
        setResultId(null);
        setFilialId(null);
        setFilialDivisionId(null);
        setDepartmentId(null);
        setDivisionId(null);
        setResponsibleId(null);
        setPerformerId(null);
        setVersion(2);
    };
    const handleForm = async (data, protocolTypeNumber) => {
        const protocolForms = await dispatch(getProtocolForms({ paging: { take: 1 }, filter: { number: { operand1: protocolTypeNumber, operator: "equals" } } }))
        const formId = protocolForms[0].id;
        protocol.formId = formId;
        protocol.data = data;

        const protocolSigners = mapProtocolSigners(data);
        protocol.responsibleId = protocolSigners.responsible?.id;
        protocol.performerId = protocolSigners.performer?.id;
        protocol.leaderId = protocolSigners.leader?.id;
        protocol.signers = protocolSigners.signers;

        onSubmit(protocol);
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, data, number, createdAt, formId, referralId, resultId, filialId, filialDivisionId, departmentId, divisionId, responsibleId, performerId, leaderId, stateId,
            version
        } = protocol || {};
        setData(data)
        setId(id);
        setNumber(number);
        setCreatedAt(createdAt);
        if (formId)
        dispatch(getProtocolFormByKey(formId)).then(formProtocolNumber => formProtocolNumber && setFormId(formProtocolNumber.number));
        setReferralId(referralId);
        setResultId(resultId);
        setFilialId(filialId);
        setFilialDivisionId(filialDivisionId);
        setDepartmentId(departmentId);
        setDivisionId(divisionId);
        setResponsibleId(responsibleId);
        setPerformerId(performerId);
        setLeaderId(leaderId);
        setStateId(stateId);
        version && setVersion(version);
    }, [protocol])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Grid container spacing={2} direction='column'>
                            <Grid item>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item style={{ maxWidth: '100%' }}>
                                {formId && <ProtocolForms
                                    protocolID={id}
                                    handleGetProtocol={async () => asJSONSafe(data)}
                                    onEdit={handleForm}
                                    formID={{ id: formId ?? '115' }}
                                    version={version}
                                    protocol={protocol}
                                />}
                            </Grid>
                        </Grid>
                  </Box>
                }
            </Modal>
        </div>
    )
}
