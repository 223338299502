export const DEPARTURE_SCHEDULE_REQUEST_GET_REQUEST =
  "DEPARTURE_SCHEDULE_REQUEST_GET_REQUEST";
export const DEPARTURE_SCHEDULE_REQUEST_GET_SUCCESS =
  "DEPARTURE_SCHEDULE_REQUEST_GET_SUCCESS";
export const DEPARTURE_SCHEDULE_REQUEST_GET_ERROR =
  "DEPARTURE_SCHEDULE_REQUEST_GET_ERROR";
export const DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_REQUEST =
  "DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_REQUEST";
export const DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_SUCCESS =
  "DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_SUCCESS";
export const DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_ERROR =
  "DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_ERROR";
export const DEPARTURE_SCHEDULE_REQUEST_ADD_REQUEST =
  "DEPARTURE_SCHEDULE_REQUEST_ADD_REQUEST";
export const DEPARTURE_SCHEDULE_REQUEST_ADD_SUCCESS =
  "DEPARTURE_SCHEDULE_REQUEST_ADD_SUCCESS";
export const DEPARTURE_SCHEDULE_REQUEST_ADD_ERROR =
  "DEPARTURE_SCHEDULE_REQUEST_ADD_ERROR";
export const DEPARTURE_SCHEDULE_REQUEST_EDIT_REQUEST =
  "DEPARTURE_SCHEDULE_REQUEST_EDIT_REQUEST";
export const DEPARTURE_SCHEDULE_REQUEST_EDIT_SUCCESS =
  "DEPARTURE_SCHEDULE_REQUEST_EDIT_SUCCESS";
export const DEPARTURE_SCHEDULE_REQUEST_EDIT_ERROR =
  "DEPARTURE_SCHEDULE_REQUEST_EDIT_ERROR";
export const DEPARTURE_SCHEDULE_REQUEST_DELETE_REQUEST =
  "DEPARTURE_SCHEDULE_REQUEST_DELETE_REQUEST";
export const DEPARTURE_SCHEDULE_REQUEST_DELETE_SUCCESS =
  "DEPARTURE_SCHEDULE_REQUEST_DELETE_SUCCESS";
export const DEPARTURE_SCHEDULE_REQUEST_DELETE_ERROR =
  "DEPARTURE_SCHEDULE_REQUEST_DELETE_ERROR";

export const DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_REQUEST =
  "DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_REQUEST";
export const DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_SUCCESS =
  "DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_SUCCESS";
export const DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_ERROR =
  "DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_ERROR";
