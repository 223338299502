import { requestStateCRUD } from "../../http/CRUD";
import {
  REQUEST_STATE_GET_REQUEST,
  REQUEST_STATE_GET_SUCCESS,
  REQUEST_STATE_GET_ERROR,
  REQUEST_STATE_GET_BY_KEY_REQUEST,
  REQUEST_STATE_GET_BY_KEY_SUCCESS,
  REQUEST_STATE_GET_BY_KEY_ERROR,
  REQUEST_STATE_ADD_REQUEST,
  REQUEST_STATE_ADD_SUCCESS,
  REQUEST_STATE_ADD_ERROR,
  REQUEST_STATE_EDIT_REQUEST,
  REQUEST_STATE_EDIT_SUCCESS,
  REQUEST_STATE_EDIT_ERROR,
  REQUEST_STATE_DELETE_REQUEST,
  REQUEST_STATE_DELETE_SUCCESS,
  REQUEST_STATE_DELETE_ERROR,
} from "../constants/requestStateConstants";

export const getRequestStates = (args) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_STATE_GET_REQUEST,
    });
    const data = await requestStateCRUD.search(args);
    dispatch({
      type: REQUEST_STATE_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: REQUEST_STATE_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getRequestStateByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_STATE_GET_BY_KEY_REQUEST,
    });

    const data = await requestStateCRUD.get(id);
    dispatch({
      type: REQUEST_STATE_GET_BY_KEY_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: REQUEST_STATE_GET_BY_KEY_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const addRequestState = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_STATE_ADD_REQUEST,
    });

    await requestStateCRUD.create(data);
    dispatch(getRequestStates(args || {}));
    dispatch({
      type: REQUEST_STATE_ADD_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: REQUEST_STATE_ADD_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const editRequestState = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_STATE_EDIT_REQUEST,
    });

    await requestStateCRUD.edit(data);
    dispatch(getRequestStates(args || {}));
    dispatch({
      type: REQUEST_STATE_EDIT_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: REQUEST_STATE_EDIT_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const deleteRequestState = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_STATE_DELETE_REQUEST,
    });

    await requestStateCRUD.delete(id);
    dispatch(getRequestStates(args || {}));
    dispatch({
      type: REQUEST_STATE_DELETE_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: REQUEST_STATE_DELETE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};
