import m_13_2071 from './KZ.M.13.2071.svg'
import m_13_2071_b from './KZ.M.13.2071 black.svg'
import t_13_2472 from './KZ.T.13.2472.svg'
import t_13_2472_b from './KZ.T.13.2472 black.svg'

export default [
  m_13_2071,
  m_13_2071_b,
  t_13_2472,
  t_13_2472_b
]
