export function intersection(a, b, compare) {
    if (!a || !b) return [];
    if (a.length == 0 || b.length == 0) return [];

    const result = [];

    for (let i = 0; i < a.length; i++) {
        let aItem = a[i];
        if (b.filter(_ => compare(aItem, _)).length > 0)
          result.push(aItem);
    }

    return result;
  }