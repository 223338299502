import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Form,
  FormSection,
  SelectField,
} from "../../../components";

import {
  _addAct,
  getWithoutDepartureRequestByKey,
  getDepartureScheduleRequestByKey,
  getActForms,
  getAnObjectReferralByKey, _getActByKey,
} from "./../../../redux/actions";

import { Grid } from "@mui/material";
import { renderSections } from "../../../functions";

import {
  ActWaterCreateSection,
  ActDisinfectantCreateSection,
  ActRadioCreateSection,
  ActProductsCreateSection,
  ActLandCreateSection,
  ActSamplingReportCreateSection,
  ActWasteCreateSection,
  ActTicksCreateSection,
  ActZoonoticCreateSection,
} from "./../../Acts";
import DialogLayout from "../../../components/Dialog/DialogLayout";
import {ActBioTestCreateSection} from "../_sections/ActBioTestCreateSection";
import {ActBacteriologicalTestCreateSection} from "../_sections/ActBacteriologicalTestCreateSection";
import {actCRUD} from "../../../http";
import CreateDirectionActs from "../../Directions/Popups/CreateDirectionActs";

export const ActCreateModal = ({ open, setOpen, actNumber = null, act = null, setValue }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const actForm = useSelector((s) => s.actForm);
  const { withoutDepartureRequest } = useSelector(
    (s) => s.withoutDepartureRequest
  );
  const { departureScheduleRequest } = useSelector(
    (s) => s.departureScheduleRequest
  );
  const { anObjectReferral } = useSelector((s) => s.anObjectReferral);

  const [actFormId, setActFormId] = useState(null);
  const [actForms, setActForms] = useState([]);

  const [actData, setActData] = useState({});
  const [direction, setDirection] = useState({});

  const directionForms = [
    { id: 0, number: '015', directionName: 'DirectionBlood', kazakhName: 'Қанды вирустық гепатиттердің маркерлеріне зерттеуге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ на исследование крови на маркеры вирусных гепатитов' },
    { id: 1, number: '021', directionName: 'DirectionCholera', kazakhName: 'Сынаманы тырысқақ қоздырғышына тексерудің жолдамасы және зерттеу нәтижесі', name: 'Направление и результат исследования материала на наличие возбудителя холеры' },
    { id: 2, number: '017', directionName: 'DirectionMaterial', kazakhName: 'Қызылша мен қызамыққа материалды (зерттелушіден, ауру адамнан) зерттеуге жолдама', name: 'Направление материала (от обследуемого, больного) для исследования на корь, краснуху' },
    { id: 3, number: '019', directionName: 'DirectionMicrobio', kazakhName: 'Адамдардан іріктелген сынамаларды микробиологиялық тексеруге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ материала от людей на микробиологические исследования' },
    { id: 4, number: '018', directionName: 'DirectionParasite', kazakhName: 'Санитариялық-паразитологиялық зерттеуге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ на санитарно-паразитологическое исследование' },
    { id: 5, number: '020', directionName: 'DirectionPeople', kazakhName: 'Адамдардан іріктелген сынамаларды ______________ тексеруге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ материала от людей на __________ исследования' },
    { id: 6, number: '016', directionName: 'DirectionSampling', kazakhName: 'Сынама үлгілерін вирусологиялық зерттеулерге (инфекция түрін көрсетініз)', name: 'НАПРАВЛЕНИЕ на исследование образцов проб на вирусологическое исследование' },
    { id: 7, number: '013', directionName: 'DirectionVirus', kazakhName: 'Вирусологиялық зерттеуге жіберу ЖОЛДАМАСЫ', name: 'НАПРАВЛЕНИЕ на вирусологические исследование' },
  ]

  const options =
    actForms?.map(({ id, name, number }) => ({ label: name, value: number })) || [];

  const handleReset = () => {
    setActFormId(null);
  };

  const handleSelect = (e) => {
    if (e.target.value.includes('Direction')) {
      setDirection(directionForms.find(el => el.directionName === e.target.value))
    }
    e.preventDefault();
    setActFormId(e.target.value);
  };

  useEffect(() => {
    if (act && actNumber) {
      setActFormId(actNumber)
      actCRUD.get(act.id).then(res => {
        let data = {...res};
        data.data = JSON.parse(data.data)
        setActData(data)
      })
    }
  }, [act, actNumber, open])

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    setLoading(true);

    const withoutDepartureRequestId = withoutDepartureRequest?.id
      ? withoutDepartureRequest.id
      : null;
    const departureScheduleRequestId = departureScheduleRequest?.id
      ? departureScheduleRequest.id
      : null;

    const anObjectReferralId = anObjectReferral?.id
      ? anObjectReferral.id
      : null;

    const refreshAction = withoutDepartureRequestId
      ? getWithoutDepartureRequestByKey(withoutDepartureRequestId)
      : departureScheduleRequestId
      ? getDepartureScheduleRequestByKey(departureScheduleRequestId)
      : getAnObjectReferralByKey(anObjectReferralId);

    const form = actForm.actForms.find(({ number }) => number === actFormId);
    const newData = {...data}
    delete newData.responsible
    delete newData.responsibleForProbes
    delete newData.performer
    const _data = {
      ...newData,
      createdAt: new Date(),
      objectReferralId: anObjectReferralId,
      anObjectReferralId: anObjectReferralId,
      withoutDepartureRequestId,
      departureScheduleRequestId,
      number: actFormId,
      form,
      formId: form.id,
      schema: {},
    }

    const createdId = await dispatch(_addAct(_data));
    if (!createdId) return setLoading(false);

    if (setValue) {
      const act = await dispatch(_getActByKey(createdId))
      setValue({...act, actId: act.id})
    } else {
      await dispatch(refreshAction);
    }

    setLoading(false);
    setOpen(false);
    handleReset();
  };


  const sections = [
    {
      mode: "ActWater",
      component: ActWaterCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActDisinfectant",
      component: ActDisinfectantCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActRadio",
      component: ActRadioCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActProducts",
      component: ActProductsCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActLand",
      component: ActLandCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActSamplingReport",
      component: ActSamplingReportCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActWaste",
      component: ActWasteCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActTicks",
      component: ActTicksCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActZoonotic",
      component: ActZoonoticCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActBioTest",
      component: ActBioTestCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "ActBacteriologicalTestC",
      component: ActBacteriologicalTestCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionBlood",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionSampling",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionVirus",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionPeople",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionMicrobio",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionMaterial",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionCholera",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
    {
      mode: "DirectionParasite",
      component: CreateDirectionActs,
      onSubmit: handleSubmit,
    },
  ];

  useEffect(() => {
    dispatch(getActForms({ paging: { skip: 0, take: 1000 } })).then(setActForms);
  }, []);

  return (
      <DialogLayout setOpen={setOpen} open={open} loading={loading} title="Акт: Создание">
        <Form onSubmit={handleSubmit}>
          <FormSection heading="Акт" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectField
                  label="Тип акта"
                  options={options}
                  onChange={handleSelect}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection mb={4}>
            {renderSections(sections, { mode: actFormId, data: actData, direction: direction })}
          </FormSection>
        </Form>
      </DialogLayout>
  );
};

ActCreateModal.defaultProps = {};
