import React from 'react';
import {Button, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import RemoveIcon from "@mui/icons-material/Remove";

function CollectionTube({data, setData}) {
    const handleChangeInput = (event, id, val) => {
        let mas = [...data]
        const idx = data.findIndex(el => el.id == id)
        mas[idx] = {...mas[idx], [val]: event.target.value}
        setData(mas)
    }
    const removeItem = (id) => {
        let mas = [...data]
        setData(mas.filter(el => el.id != id))
    }
    return (
        <>
            {data && data.map((el, idx) => {
                return (<React.Fragment key={idx}>
                    <Grid item xs={5}>
                        <TextField margin="normal"  value={el?.name} onChange={(event) => handleChangeInput(event, el.id, 'name')} fullWidth label="Пробирка" />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField inputProps={{min: 1}} margin="normal" type='number' value={el?.quantity} onChange={(event) => handleChangeInput(event, el.id, 'quantity')} fullWidth label="Введите количество" />
                    </Grid>
                    <Grid item xs={2} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Button onClick={() => removeItem(el.id)}><RemoveIcon/></Button>
                    </Grid>
                </React.Fragment>)
                })}
        </>
    );
}

export default CollectionTube;