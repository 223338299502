import {LOGIN_REQUEST, LOGIN_FAIL, LOGIN_SUCCESS, LOGIN_SET, LOGIN_RESET} from '../constants/authConstants'

const initialState = {
    loading: false,
    success: null,
    isAuth: false,
    userInfo: null,
    authData: null,
    error: null
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                isAuth: true,
                authData: action.payload
            };
        case LOGIN_SET:
            return {
                ...state,
                userInfo: action.payload,
                error: null
            };
        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            };
        case LOGIN_RESET:
            return {...state, loading: false, userInfo: null, error: null, success: false, isAuth: false}
        default:
            return state;
    }
}