/* eslint-disable import/no-anonymous-default-export */
export default {
  '82': {
    name: 'ПРОТОКОЛ серологических исследований на паразитарные заболевания',
    kazakhName: 'Паразиттік ауруларға серологиялық зерттеулер ХАТТАМАСЫ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии), обследуемого',
        kazakhLabel: 'Тексерілушінің тегі, аты, әкесінің аты (болған жағдайда)',
        number: '1'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы',
        number: '2'
      },
      {
        id: 'while_researching',
        type: 'text',
        label: 'При исследовании образцов крови, фекалий и других на',
        kazakhLabel: '-ға қанның, нәжістің, тағы басқалардың үлгілерін зерттегенде',
        number: ''
      },
      {
        id: 'found',
        type: 'text',
        label: 'день болезни в, обнаружено',
        kazakhLabel: 'Ауру күні, анықталды',
        number: '3'
      },
      {
        id: 'reaction_type',
        type: 'text',
        label: 'вид реакции',
        kazakhLabel: 'Реакцияның түрі',
        number: '4'
      },
      {
        id: 'antibodies',
        type: 'text',
        label: 'антитела',
        kazakhLabel: 'Антиденелер',
        number: '5'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері',
        number: '6'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана',
        number: '7'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі',
        number: '8'
      },
      {
        id: 'antigenes',
        type: 'text',
        label: 'антигены',
        kazakhLabel: 'Антигендер',
        number: '9'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результат исследования',
        kazakhLabel: 'Зерттеу нәтижелері',
        number: '10'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістемесінің НҚ-ры',
        number: '11'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді',
        number: '12'
      }
    ]
  },
  '83': {
    name: 'ПРОТОКОЛ санитарно-паразитологического исследования',
    kazakhName: 'Санитариялық-паразитологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'applicant',
        type: 'text',
        label: 'Заявитель',
        kazakhLabel: 'Мәлімдеуші'
      },
      {
        id: 'sample_registration_number',
        type: 'text',
        label: 'Регистрационный номер образца',
        kazakhLabel: 'Үлгіні тіркеу нөмірі'
      },
      {
        id: 'samples_names_and_numbers',
        type: 'text',
        label: 'Наименование и число образцов',
        kazakhLabel: 'Үлгінің атауы мен саны'
      },
      {
        id: 'tara_package_marks',
        type: 'text',
        label: 'тара, упаковка, маркировка',
        kazakhLabel: 'Ыдысы, орауы, маркалануы'
      },
      {
        id: 'creation_date1',
        type: 'datetime',
        label: 'Дата изготовления',
        kazakhLabel: 'Дайындалған күні'
      },
      {
        id: 'creation_date2',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'selection_date_representative',
        type: 'text',
        label: 'Место отбора образца, предъявитель',
        kazakhLabel: 'Үлгі алу орны, ұсынушы'
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата поступления образца',
        kazakhLabel: 'Үлгінің келіп түскен күні'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'research_done_on_nd',
        type: 'text',
        label: 'На соответствие нормативной документации',
        kazakhLabel: 'Нормативтік құжаттарға сәйкестігіне'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результат исследования',
        kazakhLabel: 'Зерттеу нәтижесі'
      },
      {
        id: 'research_results_date',
        type: 'datetime',
        label: 'Дата выдачи результатов',
        kazakhLabel: 'Нәтижелер берілген күн'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістемесінің НҚ-ы'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді'
      }
    ]
  },
  '84': {
    version:2,
    name: 'ПРОТОКОЛ исследования образцов почвы',
    kazakhName: 'Топырақ үлгілерін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Обектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Сынамалар алу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'nd_on_research_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне НҚ'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайлары'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Көрсеткіштердіңатауы\r\n    Наименование показателей',
            '&nbsp;'
          ],
          [
            'Өлшеу бірлігі\r\n    Единица измерения',
            '&nbsp;'
          ],
          [
            'НҚ НҚ бойыншанорма\r\n    Норма по НД',
            '&nbsp;'
          ],
          [
            'Зерттеу әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            '&nbsp;'
          ],
          [
            'Зерттеу нәтижесі\r\n    Результат исследования',
            '&nbsp;'
          ],
          [
            'Зерттеуәдісіне НҚ\r\n    НД на метод испытания',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=716 style='border-collapse:
        collapse;width:537pt'>
       
        <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
        <col width=116 style='mso-width-source:userset;mso-width-alt:3712;width:87pt'>
        <col width=101 style='mso-width-source:userset;mso-width-alt:3242;width:76pt'>
        <col width=167 style='mso-width-source:userset;mso-width-alt:5333;width:125pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=108 style='mso-width-source:userset;mso-width-alt:3456;width:81pt'>
        <tr height=81 style='height:61.0pt'>
         <td height=81 class=xl63 width=119 style='height:61.0pt;width:89pt'>Көрсеткіштердіңатауы<br>
           Наименование показателей</td>
         <td class=xl63 width=116 style='border-left:none;width:87pt'>Өлшеу
         бірлігі<br>
           Единица измерения</td>
         <td class=xl63 width=101 style='border-left:none;width:76pt'>НҚ НҚ
         бойыншанорма<br>
           Норма по НД</td>
         <td class=xl63 width=167 style='border-left:none;width:125pt'>Зерттеу
         әдістеменің НҚ-ры<br>
           НД на метод испытаний</td>
         <td class=xl63 width=105 style='border-left:none;width:79pt'>Зерттеу
         нәтижесі<br>
           Результат исследования</td>
         <td class=xl63 width=108 style='border-left:none;width:81pt'>Зерттеуәдісіне
         НҚ<br>
           НД на метод испытания</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl63 align=right width=119 style='height:16.0pt;
         border-top:none;width:89pt'>1</td>
         <td class=xl63 align=right width=116 style='border-top:none;border-left:none;
         width:87pt'>2</td>
         <td class=xl63 align=right width=101 style='border-top:none;border-left:none;
         width:76pt'>3</td>
         <td class=xl63 align=right width=167 style='border-top:none;border-left:none;
         width:125pt'>4</td>
         <td class=xl63 align=right width=105 style='border-top:none;border-left:none;
         width:79pt'>5</td>
         <td class=xl63 align=right width=108 style='border-top:none;border-left:none;
         width:81pt'>6</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="179"><col width="136"><col width="140"><col width="165"><col width="154"><col width="170"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="179" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштердің атауы<br>Наименование показателей</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="136" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу бірлігі<br>Единица измерения</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="140" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"> НҚ бойынша норма<br>Норма по НД</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="165" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="154" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижесі<br>Результат исследования</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="170" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдісіне НҚ<br>НД на метод испытания</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="6" height="20" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді /(Исследование проб проводились на соответствие НД)__________________</font></td>
            </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '85': {
    name: 'ПРОТОКОЛ исследования готовых блюд, рационов на калорийность',
    kazakhName: 'Дайын тағамдарды, рациондарды құнарлығына зерттеу ХАТТАМАСЫ',
    version:2,
    splitted: true,
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'food_name',
        type: 'text',
        label: 'Наименование блюда, полуфабриката',
        kazakhLabel: 'Тағамның, жартылай фабрикаттың атауы'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',        
        rows: [
          [
            'Тiркеу\r\n  нөмірi\r\n    Регистрационный номер',
            'Тағамдардың\r\n  атауы\r\n    Наименование блюд',
            'Зерттеу\r\n  әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            'Үлестің салмағы\r\n  граммен\r\n    Вес порции в граммах',
            '&nbsp;',
            'Салмағы граммен\r\n  (г)\r\n    Вес в граммах (г)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            ' Рецепт бойынша\r\n    Порецептуре ',
            'Іс\r\n  жүзінде\r\n    Фактически',
            'Іс\r\n  жүзіндегі тығыз бөлігі\r\n    Плотной части фактически',
            'Гарнирдің\r\n  гарнира',
            '&nbsp;',
            'Еттің,\r\n  балықтың\r\n    мяса, рыбы',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            'Рецепт\r\n  бойынша\r\n    По рецептуре',
            'Іс\r\n  жүзінде\r\n    Фактически',
            'Рецепт\r\n  бойынша\r\n    По рецептуре',
            'Іс\r\n  жүзінде\r\n    Фактически'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Химиялық құрамы, өлшем бірлігі - г\r\n    Химический состав в единицах измерения - г',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Құрғақ заттар\r\n    Сухие вещества',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            'Белоктар-көмірсулар\r\n    белки-углеводы',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Рецепт бойынша\r\n    По рецептуре',
            'Ең\r\n  төменгі рұқсат етілетін\r\n    Минимально допустимые',
            '&nbsp;',
            'Іс\r\n  жүзінде\r\n    Фактически',
            '&nbsp;',
            'Рецепт\r\n  бойынша\r\n    По рецептуре',
            '&nbsp;',
            'Төменгі\r\n  рауалы\r\n    Минимально допустимые.',
            '&nbsp;',
            'Іс\r\n  жүзінде\r\n    Фактически'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Химиялық құрамы, өлшем бірлігі г\r\n    Химический состав в единицах измерения г',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Белоктар\r\n    Белки',
            '&nbsp;',
            '&nbsp;',
            'Майлар\r\n    Жиры',
            '&nbsp;',
            '&nbsp;',
            'Көмірсулар\r\n    Углеводы',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Рецепт бойынша\r\n    По рецептуре',
            'Төменгі\r\n  рұқсат етілетін\r\n    Минимальнодопустимые',
            'Іс\r\n  жүзінде\r\n    Фактически',
            'Рецепт\r\n  бойынша\r\n    По рецептуре',
            'Төменгі\r\n  рұқсат етілетін\r\n    Минимальнодопустимые',
            'Іс\r\n  жүзінде\r\n    Фактически',
            'Рецепт\r\n  бойынша\r\n    По рецептуре',
            'Төменгі\r\n  рұқсат етілетін\r\n    Минимальнодопустимые',
            'Іс\r\n  жүзінде\r\n    Фактически',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Құнарлылығы\r\n    Калорийность',
            '&nbsp;',
            '&nbsp;',
            'Ауытқуы\r\n    Отклонение',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&quot;С&quot;\r\n  вит. болуы (мг %)\r\n    Содержание вит. &quot;С&quot;(мг%)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Теория жүзінде\r\n    Теоретическая',
            'Төменгі\r\n  рұқсат етілетін\r\n    Минимально допустимые',
            'Іс\r\n  жүзінде\r\n    Фактически',
            'Белоктар\r\n    Белки',
            'Майлар\r\n    Жиры',
            'Көмірсулар\r\n    Углеводы',
            'Құнарлылығы\r\n    Калорийность',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=963 style='border-collapse:
        collapse;width:722pt'>
       
        <col width=103 style='mso-width-source:userset;mso-width-alt:3285;width:77pt'>
        <col width=97 style='mso-width-source:userset;mso-width-alt:3114;width:73pt'>
        <col width=101 style='mso-width-source:userset;mso-width-alt:3242;width:76pt'>
        <col width=103 style='mso-width-source:userset;mso-width-alt:3285;width:77pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=108 style='mso-width-source:userset;mso-width-alt:3456;width:81pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <col width=71 span=2 style='width:53pt'>
        <tr height=41 style='mso-height-source:userset;height:31.25pt'>
         <td rowspan=3 height=177 class=xl66 width=103 style='height:134.5pt;
         width:77pt'>Тiркеу нөмірi<br>
           Регистрационный номер</td>
         <td rowspan=3 class=xl65 width=97 style='width:73pt'>Тағамдардың атауы<br>
           Наименование блюд</td>
         <td rowspan=3 class=xl65 width=101 style='width:76pt'>Зерттеу әдістеменің
         НҚ-ры<br>
           НД на метод испытаний</td>
         <td colspan=2 class=xl64 width=208 style='border-left:none;width:156pt'>Үлестің
         салмағы граммен<br>
           Вес порции в граммах</td>
         <td colspan=5 class=xl64 width=454 style='border-left:none;width:340pt'>Салмағы
         граммен (г)<br>
           Вес в граммах (г)</td>
        </tr>
        <tr height=41 style='mso-height-source:userset;height:31.75pt'>
         <td rowspan=2 height=136 class=xl67 width=103 style='height:103.25pt;
         border-top:none;width:77pt'><span style='mso-spacerun:yes'> </span>Рецепт
         бойынша<br>
           Порецептуре<span style='mso-spacerun:yes'> </span></td>
         <td rowspan=2 class=xl64 width=105 style='border-top:none;width:79pt'>Іс
         жүзінде<br>
           Фактически</td>
         <td rowspan=2 class=xl64 width=108 style='border-top:none;width:81pt'>Іс
         жүзіндегі тығыз бөлігі<br>
           Плотной части фактически</td>
         <td colspan=2 class=xl64 width=204 style='border-left:none;width:153pt'>Гарнирдің
         гарнира</td>
         <td colspan=2 class=xl64 width=142 style='border-left:none;width:106pt'>Еттің,
         балықтың<br>
           мяса, рыбы</td>
        </tr>
        <tr height=95 style='mso-height-source:userset;height:71.5pt'>
         <td height=95 class=xl64 width=105 style='height:71.5pt;border-top:none;
         border-left:none;width:79pt'>Рецепт бойынша<br>
           По рецептуре</td>
         <td class=xl64 width=99 style='border-top:none;border-left:none;width:74pt'>Іс
         жүзінде<br>
           Фактически</td>
         <td class=xl64 width=71 style='border-top:none;border-left:none;width:53pt'>Рецепт
         бойынша<br>
           По рецептуре</td>
         <td class=xl64 width=71 style='border-top:none;border-left:none;width:53pt'>Іс
         жүзінде<br>
           Фактически</td>
        </tr>
        <tr height=21 style='mso-height-source:userset;height:16.75pt'>
         <td height=21 class=xl63 width=103 style='height:16.75pt;border-top:none;
         width:77pt'>1</td>
         <td class=xl64 width=97 style='border-top:none;border-left:none;width:73pt'>2</td>
         <td class=xl64 width=101 style='border-top:none;border-left:none;width:76pt'>3</td>
         <td class=xl64 width=103 style='border-top:none;border-left:none;width:77pt'>4</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>5</td>
         <td class=xl64 width=108 style='border-top:none;border-left:none;width:81pt'>6</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>7</td>
         <td class=xl64 width=99 style='border-top:none;border-left:none;width:74pt'>8</td>
         <td class=xl64 width=71 style='border-top:none;border-left:none;width:53pt'>9</td>
         <td class=xl64 width=71 style='border-top:none;border-left:none;width:53pt'>10</td>
        </tr>
        <tr height=44 style='mso-height-source:userset;height:33.0pt'>
         <td colspan=10 height=44 class=xl63 width=963 style='height:33.0pt;
         width:722pt'>Химиялық құрамы, өлшем бірлігі - г<br>
           Химический состав в единицах измерения - г</td>
        </tr>
        <tr height=44 style='mso-height-source:userset;height:33.0pt'>
         <td colspan=5 height=44 class=xl63 width=509 style='height:33.0pt;width:382pt'>Құрғақ
         заттар<br>
           Сухие вещества</td>
         <td colspan=5 class=xl64 width=454 style='border-left:none;width:340pt'>Белоктар-көмірсулар<br>
           белки-углеводы</td>
        </tr>
        <tr height=63 style='mso-height-source:userset;height:47.5pt'>
         <td height=63 class=xl63 width=103 style='height:47.5pt;border-top:none;
         width:77pt'>Рецепт бойынша<br>
           По рецептуре</td>
         <td colspan=2 class=xl64 width=198 style='border-left:none;width:149pt'>Ең
         төменгі рұқсат етілетін<br>
           Минимально допустимые</td>
         <td colspan=2 class=xl64 width=208 style='border-left:none;width:156pt'>Іс
         жүзінде<br>
           Фактически</td>
         <td colspan=2 class=xl64 width=213 style='border-left:none;width:160pt'>Рецепт
         бойынша<br>
           По рецептуре</td>
         <td colspan=2 class=xl64 width=170 style='border-left:none;width:127pt'>Төменгі
         рауалы<br>
           Минимально допустимые.</td>
         <td class=xl64 width=71 style='border-top:none;border-left:none;width:53pt'>Іс
         жүзінде<br>
           Фактически</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl63 width=103 style='height:16.0pt;border-top:none;
         width:77pt'>11</td>
         <td colspan=2 class=xl64 width=198 style='border-left:none;width:149pt'>12</td>
         <td colspan=2 class=xl64 width=208 style='border-left:none;width:156pt'>13</td>
         <td colspan=2 class=xl64 width=213 style='border-left:none;width:160pt'>14</td>
         <td colspan=2 class=xl64 width=170 style='border-left:none;width:127pt'>15</td>
         <td class=xl64 width=71 style='border-top:none;border-left:none;width:53pt'>16</td>
        </tr>
        <tr height=41 style='mso-height-source:userset;height:31.25pt'>
         <td colspan=10 height=41 class=xl63 width=963 style='height:31.25pt;
         width:722pt'>Химиялық құрамы, өлшем бірлігі г<br>
           Химический состав в единицах измерения г</td>
        </tr>
        <tr height=40 style='mso-height-source:userset;height:30.5pt'>
         <td colspan=3 height=40 class=xl63 width=301 style='height:30.5pt;width:226pt'>Белоктар<br>
           Белки</td>
         <td colspan=3 class=xl64 width=316 style='border-left:none;width:237pt'>Майлар<br>
           Жиры</td>
         <td colspan=4 class=xl64 width=346 style='border-left:none;width:259pt'>Көмірсулар<br>
           Углеводы</td>
        </tr>
        <tr height=108 style='mso-height-source:userset;height:81.5pt'>
         <td height=108 class=xl63 width=103 style='height:81.5pt;border-top:none;
         width:77pt'>Рецепт бойынша<br>
           По рецептуре</td>
         <td class=xl64 width=97 style='border-top:none;border-left:none;width:73pt'>Төменгі
         рұқсат етілетін<br>
           Минимальнодопустимые</td>
         <td class=xl64 width=101 style='border-top:none;border-left:none;width:76pt'>Іс
         жүзінде<br>
           Фактически</td>
         <td class=xl63 width=103 style='border-top:none;border-left:none;width:77pt'>Рецепт
         бойынша<br>
           По рецептуре</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>Төменгі
         рұқсат етілетін<br>
           Минимальнодопустимые</td>
         <td class=xl64 width=108 style='border-top:none;border-left:none;width:81pt'>Іс
         жүзінде<br>
           Фактически</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Рецепт
         бойынша<br>
           По рецептуре</td>
         <td class=xl64 width=99 style='border-top:none;border-left:none;width:74pt'>Төменгі
         рұқсат етілетін<br>
           Минимальнодопустимые</td>
         <td colspan=2 class=xl64 width=142 style='border-left:none;width:106pt'>Іс
         жүзінде<br>
           Фактически</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl63 width=103 style='height:16.0pt;border-top:none;
         width:77pt'>17</td>
         <td class=xl64 width=97 style='border-top:none;border-left:none;width:73pt'>18</td>
         <td class=xl64 width=101 style='border-top:none;border-left:none;width:76pt'>19</td>
         <td class=xl64 width=103 style='border-top:none;border-left:none;width:77pt'>20</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>21</td>
         <td class=xl64 width=108 style='border-top:none;border-left:none;width:81pt'>22</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>23</td>
         <td class=xl64 width=99 style='border-top:none;border-left:none;width:74pt'>24</td>
         <td colspan=2 class=xl64 width=142 style='border-left:none;width:106pt'>25</td>
        </tr>
        <tr height=44 style='mso-height-source:userset;height:33.5pt'>
         <td colspan=3 height=44 class=xl63 width=301 style='height:33.5pt;width:226pt'>Құнарлылығы<br>
           Калорийность</td>
         <td colspan=4 class=xl64 width=421 style='border-left:none;width:316pt'>Ауытқуы<br>
           Отклонение</td>
         <td colspan=3 rowspan=2 class=xl65 width=241 style='width:180pt'>&quot;С&quot;
         вит. болуы (мг %)<br>
           Содержание вит. &quot;С&quot;(мг%)</td>
        </tr>
        <tr height=92 style='mso-height-source:userset;height:69.0pt'>
         <td height=92 class=xl63 width=103 style='height:69.0pt;border-top:none;
         width:77pt'>Теория жүзінде<br>
           Теоретическая</td>
         <td class=xl64 width=97 style='border-top:none;border-left:none;width:73pt'>Төменгі
         рұқсат етілетін<br>
           Минимально допустимые</td>
         <td class=xl64 width=101 style='border-top:none;border-left:none;width:76pt'>Іс
         жүзінде<br>
           Фактически</td>
         <td class=xl64 width=103 style='border-top:none;border-left:none;width:77pt'>Белоктар<br>
           Белки</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>Майлар<br>
           Жиры</td>
         <td class=xl64 width=108 style='border-top:none;border-left:none;width:81pt'>Көмірсулар<br>
           Углеводы</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>Құнарлылығы<br>
           Калорийность</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl63 width=103 style='height:16.0pt;border-top:none;
         width:77pt'>26</td>
         <td class=xl64 width=97 style='border-top:none;border-left:none;width:73pt'>27</td>
         <td class=xl64 width=101 style='border-top:none;border-left:none;width:76pt'>28</td>
         <td class=xl64 width=103 style='border-top:none;border-left:none;width:77pt'>29</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>30</td>
         <td class=xl64 width=108 style='border-top:none;border-left:none;width:81pt'>31</td>
         <td class=xl64 width=105 style='border-top:none;border-left:none;width:79pt'>32</td>
         <td colspan=3 class=xl64 width=241 style='border-left:none;width:180pt'>33</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="10" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="139"><col width="134"><col width="93"><col width="81"><col width="93"><col width="80"><col width="82"><col width="94"><col width="81"><col width="97"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="3" width="139" height="191" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тiркеу нөмірi<br>Регистрационный <br>номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тағамдардың атауы<br>Наименование блюд</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="93" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="173" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлестің салмағы граммен<br>Вес порции в граммах</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="5" width="433" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Салмағы граммен (г)<br>Вес в граммах (г)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзіндегі тығыз бөлігі<br>Плотной части фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Гарнирдің гарнира</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Еттің, балықтың<br>мяса, рыбы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="110"><col width="169"><col width="87"><col width="116"><col width="110"><col width="99"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="6" width="690" height="43" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Химиялық құрамы, өлшем бірлігі - г<br>Химический состав в единицах измерения - г</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" height="38" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрғақ заттар<br>Сухие вещества</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Белоктар-көмірсулар<br>белки-углеводы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ең төменгі рұқсат етілетін<br>Минимально допустимые</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төменгі рауалы<br>Минимально допустимые.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },
      {
        id: 'form_table_v2_3',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="11" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="119"><col width="65"><col width="65"><col width="93"><col width="103"><col width="65"><col width="65"><col width="96"><col width="90"><col width="104"><col width="96"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan="11" width="959" height="49" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Химиялық құрамы, өлшем бірлігі г<br>Химический состав в единицах измерения г</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" colspan="4" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Белоктар<br>Белки</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Майлар<br>Жиры</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="3" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көмірсулар<br>Углеводы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төменгі рұқсат етілетін<br>Минимально допустимые</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төменгі рауалы<br>Минимально допустимые</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рецепт бойынша<br>По рецептуре</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төменгі рауалы<br>Минимально допустимые</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="22" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdval="18" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">18</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="19" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">19</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="20" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">20</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdval="21" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">21</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="22" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">22</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="23" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">23</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="24" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">24</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="25" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">25</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },
      {
        id: 'form_table_v2_4',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="8" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="115"><col width="112"><col width="96"><col width="77"><col width="65"><col width="90"><col width="115"><col width="112"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" width="322" height="102" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құнарлылығы<br>Калорийность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" width="346" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауытқуы<br>Отклонение</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="112" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">"С" вит. болуы (мг %)<br>Содержание вит. "С"(мг%)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Теория жүзінде<br>Теоретическая</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төменгі рұқсат етілетін<br>Минимально допустимые</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Іс жүзінде<br>Фактически</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Белоктар<br>Белки</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Майлар<br>Жиры</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көмірсулар<br>Углеводы</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құнарлылығы<br>Калорийность</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="26" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">26</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="27" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">27</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="28" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">28</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="29" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">29</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="30" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">30</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="31" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">31</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="32" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">32</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="33" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">33</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді'
      }
    ]
  },
  '87': {
    version:2,
    name: 'ПРОТОКОЛ измерений содержания радона и продуктов его распада в воздухе',
    kazakhName: 'Радонның және оның ауада ыдырауынан пайда болған өнімдердің бар болуын өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Обьектінің атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'measurement_place',
        type: 'text',
        label: 'Место проведения измерений',
        kazakhLabel: 'Өлшеу жүргізілген орын',
        number: '2'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проведены в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объекті өкілінің қатысуымен жүргізілді',
        number: '3'
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеу мақсаты',
        number: '4'
      },      
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеулер құралдары',
        number: '5'
      },
      {
        id: 'name_type_factory_number',
        type: 'text',
        label: 'наименование, тип, заводской номер',
        kazakhLabel: 'Өлшеу құралдары (Средства измерений) атауы, түрі, зауыттық нөмірі',
        number: ''
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Тексеру туралы мәліметтер',
        number: '6'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'берілген күні мен куәліктің нөмірі',
        number: ''
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Тіркеу\r\n  нөмірі\r\n    (Регистрационный номер)',
            '&nbsp;'
          ],
          [
            'Өлшеужүргізілгенорны\r\n    (Место проведения измерений)',
            '&nbsp;'
          ],
          [
            'Радонның өлшенген, теңсалмақты, баламалы, көлемді белсенділігі\r\n  Бк/м3\r\n    (Измеренная, равновесная,эквивалентная, объемная активность радона\r\n  Бк/м3)\r\n    Топырақ бетінен алынған радон ағымының өлшенген тығыздығы\r\n  (мБк/ш.м.·сек)\r\n    (Измеренная плотность потока радона с поверхности грунта (мБк/м2·сек)',
            '&nbsp;'
          ],
          [
            '(Бк/м3 Рұқсат етілетін концентрациясы)\r\n    (Допустимая концентрация Бк/м3)\r\n    Ағынның шекті тығыздығы (мБк/м2·сек)\r\n    (Допустимая плотность потока (мБк/м2·сек)',
            '&nbsp;'
          ],
          [
            'Желдету жағдайы туралы белгілер\r\n    (Отметки о состоянии вентиляции)',
            '&nbsp;'
          ]
        ],
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=920 style='border-collapse:collapse;table-layout:fixed;width:690pt'>
        <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
        <col width=116 style='mso-width-source:userset;mso-width-alt:3712;width:87pt'>
        <col width=101 style='mso-width-source:userset;mso-width-alt:3242;width:76pt'>
        <col width=167 style='mso-width-source:userset;mso-width-alt:5333;width:125pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=108 style='mso-width-source:userset;mso-width-alt:3456;width:81pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <tr height=132 style='mso-height-source:userset;height:99.5pt'>
         <td rowspan=2 height=268 class=xl66 width=119 style='height:201.5pt;
         width:89pt'>Тіркеу нөмірі<br>
           (Регистрационный номер)</td>
         <td rowspan=2 class=xl66 width=116 style='width:87pt'>Өлшеужүргізілгенорны<br>
           (Место проведения измерений)</td>
         <td colspan=2 rowspan=2 class=xl67 width=268 style='border-right:1.0pt solid #AEAAAA;
         border-bottom:1.0pt solid #AEAAAA;width:201pt'>Радонның өлшенген,
         теңсалмақты, баламалы, көлемді белсенділігі Бк/м3<br>
           (Измеренная, равновесная,эквивалентная, объемная активность радона
         Бк/м3)<br>
           Топырақ бетінен алынған радон ағымының өлшенген тығыздығы
         (мБк/ш.м.·сек)<br>
           (Измеренная плотность потока радона с поверхности грунта (мБк/м2·сек)</td>
         <td colspan=2 rowspan=2 class=xl67 width=213 style='border-right:1.0pt solid #AEAAAA;
         border-bottom:1.0pt solid #AEAAAA;width:160pt'>(Бк/м3 Рұқсат етілетін
         концентрациясы)<br>
           (Допустимая концентрация Бк/м3)<br>
           Ағынның шекті тығыздығы (мБк/м2·сек)<br>
           (Допустимая плотность потока (мБк/м2·сек)</td>
         <td colspan=2 rowspan=2 class=xl67 width=204 style='border-right:1.0pt solid #AEAAAA;
         border-bottom:1.0pt solid #AEAAAA;width:153pt'>Желдету жағдайы туралы
         белгілер<br>
           (Отметки о состоянии вентиляции)</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr height=23 style='height:17.0pt'>
         <td height=23 class=xl63 style='height:17.0pt;border-top:none'>1</td>
         <td class=xl63 style='border-top:none;border-left:none'>2</td>
         <td colspan=2 class=xl64 style='border-right:1.0pt solid #AEAAAA;border-left:
         none'>3</td>
         <td colspan=2 class=xl64 style='border-right:1.0pt solid #AEAAAA;border-left:
         none'>4</td>
         <td colspan=2 class=xl64 style='border-right:1.0pt solid #AEAAAA;border-left:
         none'>5</td>
        </tr>       
       </table>`,
       label: '7. Результаты измерений',
       kazakhLabel: '7. Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="126"><col width="105"><col width="181"><col width="138"><col width="107"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="126" height="369" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>(Регистрационный номер)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеужүргізілгенорны<br>(Место проведения измерений)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="181" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радонның өлшенген, теңсалмақты, баламалы, көлемді белсенділігі Бк/м3<br>(Измеренная, равновесная,<br>эквивалентная, объемная активность радона Бк/м3)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="138" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">(Бк/м3 Рұқсат етілетін концентрациясы)<br>(Допустимая концентрация Бк/м3)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Желдету жағдайы туралы белгілер<br>(Отметки о состоянии вентиляции)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Топырақ бетінен алынған радон ағымының өлшенген тығыздығы (мБк/ш.м.·сек)<br>(Измеренная плотность потока радона с поверхности грунта (мБк/м2·сек)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ағынның шекті тығыздығы (мБк/м2·сек)<br>(Допустимая плотность потока (мБк/м2·сек)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді',
        number: '8'
      }
    ]
  },
  '88': {
    version:2,
    name: 'ПРОТОКОЛ измерений промышленных предприятий',
    kazakhName: 'Өнеркәсіп кәсіпорындарын өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен жайы',
        number: '1'
      },
      {
        id: 'place_of_measurements',
        type: 'text',
        label: 'Место проведения замеров (отдел, цех, квартал)',
        kazakhLabel: 'Өлшеулер жүргізілген орын (бөлім, цех, квартал)',
        number: '2'
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеу мақсаты',
        number: '3'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя обследуемого объекта',
        kazakhLabel: 'Өлшеулер тексерілетін объекті өкілінің қатысуымен жүргізілді',
        number: '4'
      },
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеулер құралдары',
        number: '5'
      },
      {
        id: 'inventory',
        type: 'text',
        label: 'наименование, тип, инвентарный номер',
        kazakhLabel: 'атауы, түрі, инвентарлық нөмірі',
        number: ''
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Тексеру туралы мәліметтер',
        number: '6'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'берілген күні мен куәліктің нөмірі',
        number: ''
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгіні зерттеулер НҚ-ға сәйкестiгiне жүргiзiлдi'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Тіркеу\r\n  нөмірі\r\n    (Регистрационный номер)',
            '&nbsp;'
          ],
          [
            'Өлшеу\r\n  жүргізілген орын\r\n    (Место проведения измерений)',
            '&nbsp;'
          ],
          [
            'Дозаның өлшенген қуаты (мкЗв/час, н/сек)\r\n    (Измеренная мощность дозы (мкЗв/час, н/сек))\r\n    Еденнен жоғары (топырақтан)\r\n    (На высоте от пола (грунта))',
            'Зерттеу\r\n  нәтежиелері\r\n    (Результаты измерений)',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Дозаның\r\n  рұқсат етілетін қуаты (мкЗв/час, н/сек)\r\n    (Допустимая мощность дозы (мкЗв/час, н/сек))',
            '&nbsp;'
          ],
          [
            'Альфа-бөлшектер тығыздының ағыны, Бк/см2(б/ см2 х мин)\r\n    (Плотность потока альфа-частиц, Бк/см2(част/см2 х мин))',
            'Зерттеу\r\n  нәтежиелері\r\n    (Результаты измерений)',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Рұқсат\r\n  деңгейі\r\n    (Допустимые уровни)',
            '&nbsp;'
          ],
          [
            'Бета-бөлшектер тығыздының ағыны, Бк/см2(б/см2 х мин)\r\n    (Плотность потока бета-частиц, Бк/см2(част/см2 х мин))',
            'Зерттеу\r\n  нәтежиелері\r\n    (Результаты измерений)',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Рұқсат\r\n  деңгейі\r\n    (Допустимые уровни)',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=933 style='border-collapse:
        collapse;width:700pt'>
       
        <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
        <col width=145 style='mso-width-source:userset;mso-width-alt:4650;width:109pt'>
        <col width=101 style='mso-width-source:userset;mso-width-alt:3242;width:76pt'>
        <col width=151 style='mso-width-source:userset;mso-width-alt:4821;width:113pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=108 style='mso-width-source:userset;mso-width-alt:3456;width:81pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <tr height=132 style='mso-height-source:userset;height:99.5pt'>
         <td rowspan=2 height=268 class=xl65 width=119 style='height:201.5pt;
         width:89pt'>Тіркеу нөмірі<br>
           (Регистрационный номер)</td>
         <td rowspan=2 class=xl65 width=145 style='width:109pt'>Өлшеу жүргізілген
         орын<br>
           (Место проведения измерений)</td>
         <td colspan=2 class=xl63 width=252 style='border-left:none;width:189pt'>Дозаның
         өлшенген қуаты (мкЗв/час, н/сек)<br>
           (Измеренная мощность дозы (мкЗв/час, н/сек))<br>
           Еденнен жоғары (топырақтан)<br>
           (На высоте от пола (грунта))</td>
         <td colspan=2 class=xl63 width=213 style='border-left:none;width:160pt'>Альфа-бөлшектер
         тығыздының ағыны, Бк/см2(б/ см2 х мин)<br>
           (Плотность потока альфа-частиц, Бк/см2(част/см2 х мин))</td>
         <td colspan=2 class=xl63 width=204 style='border-left:none;width:153pt'>Бета-бөлшектер
         тығыздының ағыны, Бк/см2(б/см2 х мин)<br>
           (Плотность потока бета-частиц, Бк/см2(част/см2 х мин))</td>
        </tr>
        <tr height=136 style='mso-height-source:userset;height:102.0pt'>
         <td height=136 class=xl63 width=101 style='height:102.0pt;border-top:none;
         border-left:none;width:76pt'>Зерттеу нәтежиелері<br>
           (Результаты измерений)</td>
         <td class=xl63 width=151 style='border-top:none;border-left:none;width:113pt'>Дозаның
         рұқсат етілетін қуаты (мкЗв/час, н/сек)<br>
           (Допустимая мощность дозы (мкЗв/час, н/сек))</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Зерттеу
         нәтежиелері<br>
           (Результаты измерений)</td>
         <td class=xl63 width=108 style='border-top:none;border-left:none;width:81pt'>Рұқсат
         деңгейі<br>
           (Допустимые уровни)</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Зерттеу
         нәтежиелері<br>
           (Результаты измерений)</td>
         <td class=xl63 width=99 style='border-top:none;border-left:none;width:74pt'>Рұқсат
         деңгейі<br>
           (Допустимые уровни)</td>
        </tr>
        <tr height=23 style='height:17.0pt'>
         <td height=23 class=xl64 style='height:17.0pt;border-top:none'>1</td>
         <td class=xl64 style='border-top:none;border-left:none'>2</td>
         <td class=xl64 style='border-top:none;border-left:none'>3</td>
         <td class=xl64 style='border-top:none;border-left:none'>4</td>
         <td class=xl64 style='border-top:none;border-left:none'>5</td>
         <td class=xl64 style='border-top:none;border-left:none'>6</td>
         <td class=xl64 style='border-top:none;border-left:none'>7</td>
         <td class=xl64 style='border-top:none;border-left:none'>8</td>
        </tr>
       
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="8" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="146"><col width="121"><col width="110"><col width="127"><col width="101"><col width="110"><col width="94"><col width="100"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="146" height="200" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>(Регистрационный <br>номер)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="121" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу жүргізілген орын<br>(Место проведения измерений)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="236" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Дозаның өлшенген қуаты <br>(мкЗв/час, н/сек)<br>(Измеренная мощность дозы <br>(мкЗв/час, н/сек))<br>Еденнен жоғары (топырақтан)<br>(На высоте от пола (грунта))</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="211" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Альфа-бөлшектер тығыздының ағыны, Бк/см2(б/ см2 х мин) <br>(Плотность потока альфа-частиц, Бк/см2(част/см2 х мин))</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="194" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бета-бөлшектер тығыздының ағыны, Бк/см2(б/см2 х мин)<br>(Плотность потока бета-частиц, Бк/см2(част/см2 х мин))</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтежиелері<br>(Результаты измерений)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Дозаның рұқсат етілетін қуаты (мкЗв/час, н/сек)<br>(Допустимая мощность дозы (мкЗв/час, н/сек))</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтежиелері<br>(Результаты измерений)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат деңгейі<br>(Допустимые уровни)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтежиелері<br>(Результаты измерений)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат деңгейі<br>(Допустимые уровни)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  }
}
