import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {checkRoles} from "../../../../functions/checkRoles";
import {LoadingButton} from "@mui/lab";
import {
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect
} from "../../../../components/Select/domainSelects";
import DddFinalDisinfectionForm from "../../../DDD/DddFinalDisinfection/DddFinalDisinfectionForm";
import useDialogs from "../../../../components/Dialog/useDialogs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import useLoader from "../../../../hooks/useLoader";
import {brigadeCRUD} from "../../../../http";
import {getBrigadeTypes} from "../../../../redux/actions/brigadeTypeActions";

function DddFinalSection({ dddName = 'DddFinalDisinfection', onSubmit}) {
    const { alert } = useDialogs();
    const { id } = useParams();
    const isNew = useMemo(() => !(id > 0), [id]);
    const [data, setData] = useState({});
    const { start: startSubmit, stop: stopSubmit, loading: loadingSubmit } = useLoader(false);

    const enhancedSamples = [
        {object: {key: 'object', value: 'Тұрғын үй-жайлар Жилых помещений'}, unit: {key: 'unit', value: 'ml'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
        {object: {key: 'object', value: 'Жалпы пайдалану орны Место общего пользования'}, unit: {key: 'unit', value:'ml'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
        {object: {key: 'object', value: 'Ауладағы дәретханалар Уборных дворовых'}, unit: {key: 'unit', value: 'шт.'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
        {object: {key: 'object', value: 'Қоқыс салатын жәшіктер Мусорных ящиков'}, unit: {key: 'unit', value: 'шт.'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
        {object: {key: 'object', value: 'Ыдыс-аяқжиынтығы Комплект посуды'}, unit: {key: 'unit', value: 'шт.'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
    ]

    const handleChange = useCallback((key, value) => {
        setData({ ...data, [key]: value })
    }, [data]);
    const handleChangeMulti = useCallback((obj) => {
        setData({ ...data, ...obj })
    }, [data]);

    const handleChangeData = useCallback((key, value, key2) => {
        setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
    }, [data]);

    const handleChangeSample = useCallback((key, value, i, key2) => {
        const array = data.data?.samples?.value.slice(0)
        array[i][key] = { 'key': key2, value }
        setData({
            ...data, data: {
                ...data.data,
                samples: { key: 'samples', value: array }
            }
        })
    }, [data]);

    const addSample = useCallback(() => {
        data.data?.samples?.value
            ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, {}] } } })
            : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [{}] } } })
    }, [data]);

    const addSampleEnhanced = useCallback(() => {
        data.data?.samples?.value
            ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, ...enhancedSamples] } } })
            : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...enhancedSamples] } } })
    }, [data]);

    const deleteSample = useCallback((index) => {
        setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i) } } })
    }, [data]);

    const dispatch = useDispatch();
    const { push } = useHistory();

    const schema = {}

    const { loading: fetchLoading, start: fetchStart, stop: fetchStop } = useLoader(true);
    const fetch = useCallback(() => {
        if (isNew) {
            fetchStop();
            return;
        }
        fetchStart();
        brigadeCRUD.get(id)
            .then((data) => {
                let actData = typeof data.data === 'string' ? JSON.parse(data.data) : {}
                setData({ ...data, data: { ...actData } });
            })
            .catch(alert)
            .finally(fetchStop)
    }, [id, fetchStart, fetchStop, isNew]);
    useEffect(fetch, [fetch]);

    const [isDisabledData] = useState(false)

    async function handleSubmit() {
        if (!data.filialId) {
            alert('Филиал нужно заполнить!');
            return
        }
        const brigadesTypes = await dispatch(getBrigadeTypes());
        const brigadeObj = await brigadesTypes.find(a => {
            return a.name == dddName
        })
        const newData = { ...data }
        onSubmit(null, {
            ...newData,
            createdAt: new Date(),
            number: dddName,
            schema,
            type: brigadeObj,
            typeId: brigadeObj.id,
        })
    }

    return (
        <Box component="form">
            <h4 style={{ textAlign: "center" }}>
                Қорытынды дезинфекцияға берілетін НАРЯД
            </h4>
            <h4 style={{ textAlign: "center" }}>
                НАРЯД на заключительную дезинфекцию
            </h4>
            {data.number && <h5 style={{ marginBottom: "30px" }} align="center">
                №{data.number}
            </h5>}
            <DddFinalDisinfectionForm addSample={dddName === 'DddDisinsection' ? addSample : addSampleEnhanced}
                     handleChangeSample={handleChangeSample}
                     isDisabledData={isDisabledData} data={data}
                     handleChangeData={handleChangeData}
                     handleChangeMulti={handleChangeMulti}
                     deleteSample={deleteSample} />
            <FilialSelect
                style={{ marginBottom: "5px" }}
                label={"Филиал"}
                fullWidth
                filialId={checkRoles('AdministratorReadonly') ? null : JSON.parse(localStorage.employee)?.filialId}
                disabled={isDisabledData}
                value={data.filialId}
                onChange={(val) => {
                    handleChangeMulti({
                        filialId: val,
                        filialDivisionId: Number(val) !== Number(data.filialId) ? null : data.filialDivisionId
                    })
                }}
            />
            <FilialDivisionSelect
                style={{ marginBottom: "5px" }}
                label={"Районное отделение"}
                fullWidth
                disabled={isDisabledData}
                value={data.filialDivisionId}
                filialId={data.filialId === 1 ? null : data.filialId}
                onChange={(filialDivisionId, filialDivision) => {
                    handleChangeMulti({
                        filialDivisionId: filialDivisionId,
                        filialId: Number(filialDivision.filialId) !== Number(data.filialId) ? filialDivision.filialId : data.filialId
                    })
                }}
            />
            <Typography gutterBottom variant={'h5'}>Передача проб в лабораторию:</Typography>
            <DepartmentSelect
                style={{ marginBottom: "5px" }}
                label={"Лаборатория"}
                fullWidth
                value={data.departmentId}
                onChange={(val) => handleChange('departmentId', val)}
            />
            <DivisionSelect
                style={{ marginBottom: "5px" }}
                label={"Отдел"}
                fullWidth
                value={data.divisionId}
                onChange={(val) => handleChange('divisionId', val)}
                filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
            />
            <LoadingButton style={{
                border: "1px solid black",
                padding: "5px 10px",
                borderRadius: "6px",
                marginRight: '15px'
            }}
                           variant="contained"
                           disabled={isDisabledData}
                           onClick={handleSubmit}
                           loading={loadingSubmit}>
                {isNew ? "Создать" : "Сохранить"}
            </LoadingButton>
        </Box>    );
}

export default DddFinalSection;
