export const firstOrDefault1 = async (resource, params = {}) => {
    const response = await resource.search(params);
    //console.log('response: ' + JSON.stringify(response));
    return response.result.length > 0 ? response.result[0] : null;
}
export const toList1 = async (resource, params = {}) => {
    const response = await resource.search(params);
    return response.result;
}

export const page = (query, skip, take = 1) => {
    return {
        ...query,
        paging: {
            skip,
            take
        }
    }
}

export const orderBy = (query, sort) => {
    return {
        ...query,
        sort: {
            ...query.sort,
            ...sort
        }
    }
}

export const where = (query, filter) => {
    return {
        ...query,
        filter: {
            ...query.filter,
            ...filter,
            advancedFilter: {
                ...query.filter?.advancedFilter,
                ...filter?.advancedFilter
            }
        }
    }   
}

export const equals = (operand1) => {
    return {
        operand1: operand1,
        operator: 'equals'
    }   
}

export const between = (operand1, operand2) => {
    return {
        operand1: operand1,
        operand2: operand2,
        operator: 'between'
    }   
}

export const orderOperator = (operator = 'asc') => {
    return {
        operator: operator
    }   
}