import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getWithoutDepartureRequests, addWithoutDepartureRequest, editWithoutDepartureRequest, deleteWithoutDepartureRequest, getWithoutDepartureRequestByKey } from "../../redux/actions/withoutDepartureRequestActions";
import CreateWithoutDepartureRequest from "./Popups/CreateWithoutDepartureRequest";
import WithoutDepartureRequestDetails from "./Popups/WithoutDepartureRequestDetails";
import EditWithoutDepartureRequest from "./Popups/EditWithoutDepartureRequest";
import RemoveWithoutDepartureRequest from "./Popups/RemoveWithoutDepartureRequest";

function createData(id, number, createdAt, contractRequest, state, filial) {
    return {id, number, createdAt, contractRequest, state, filial};
}

let rows = [
    createData(1, "abc", "12.03.2021 00:00", null, null, null),
];
const WithoutDepartureRequestList = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("withoutDepartureRequestList.tableItem.id")},
        {text: t("withoutDepartureRequestList.tableItem.number")},
        {text: t("withoutDepartureRequestList.tableItem.createdAt")},
        {text: t("withoutDepartureRequestList.tableItem.contractRequest")},
        {text: t("withoutDepartureRequestList.tableItem.state")},
        {text: t("withoutDepartureRequestList.tableItem.filial")},
        {text: t("withoutDepartureRequestList.tableItem.actions")},
    ];
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);
    const setCreateHandler = async () => {
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setCreateOpen(true);
        setId(null);
    }
    const createSubmit = async (_) => {
        await dispatch(addWithoutDepartureRequest(_, query));
    }
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getWithoutDepartureRequestByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setDetailsOpen(true);
        setId(id);
    }
    
    const [editOpen, setEditOpen] = useState(false);
    const setEditHandler = async (id) => {
        await dispatch(getWithoutDepartureRequestByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setEditOpen(true);
        setId(id);
    }
    const editSubmit = async (_) => {
        await dispatch(editWithoutDepartureRequest(_, query));
    }
    const [removeOpen, setRemoveOpen] = useState(false);
    const setRemoveHandler = async (id) => {
        await dispatch(getWithoutDepartureRequestByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setRemoveOpen(true);
        setId(id);
    }
    const removeSubmit = async (_) => {
        await dispatch(deleteWithoutDepartureRequest(_.id, query));
    }

    const localData = false;
    const state = useSelector((state) => state.withoutDepartureRequest);
    if (!localData && state.withoutDepartureRequests)
    {
        rows = state.withoutDepartureRequests;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getWithoutDepartureRequests(query));
        }
    }, [dispatch]);
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("withoutDepartureRequestList.searchLabel")}/>
                <Button color="primary" variant="contained" onClick={() => setCreateHandler()}>
                    {t("withoutDepartureRequestList.createText")}
                </Button>
            </Box>
            <TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {
                return {
                    id: _.id,
                    number: _.number,
                    createdAt: _.createdAt,
                    contractRequest: _.contractRequest?.number,
                    state: _.state?.name,
                    filial: _.filial?.name,
                }
            })} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>,<EditIcon onClick={() => setEditHandler(_.id)}/>,<DeleteForeverIcon onClick={() => setRemoveHandler(_.id)}/>]}/>
            <CreateWithoutDepartureRequest action={'create'} title={t("withoutDepartureRequestList.createText")} open={createOpen} setOpen={setCreateOpen} onSubmit={createSubmit}/>
            <WithoutDepartureRequestDetails action={'details'} title={t("withoutDepartureRequestList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
            <EditWithoutDepartureRequest action={'edit'} title={t("withoutDepartureRequestList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit}/>
            <RemoveWithoutDepartureRequest action={'remove'} title={t("withoutDepartureRequestList.removeText")} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/>
        </DashboardLayout>
    )
};

export default WithoutDepartureRequestList;
