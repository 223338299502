import React, {useEffect, useState} from 'react';
import {Autocomplete} from "@mui/lab";
import { CircularProgress, TextField } from "@mui/material";
import {useDispatch} from "react-redux";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

function ReferalAutocompleteAsync({label, values, setValues, func, args, disabled, name, department, setPrice, ...props}) {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState()
    const [options, setOptions] = useState([]);
    const loading = open && options.length === 0;
    const dispatch = useDispatch()
    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            if (active) {
                await sleep(1e3);
                const response = await dispatch(func(!args ? {
                    filter: {
                        departmentId: {
                            operand1: department,
                            operator: "equals"
                        },
                        name: {
                            operand1: data,
                            operator: "like"
                        },
                    }
                } : args))
                if (!response.length) {
                    setOptions([])
                    active = false
                    setOpen(false)
                } else {
                    const mas = response.map(el => {
                        return {id: el.id, [name]: el[name], price: el.price}
                    })
                    setOptions(mas);
                }
            }

        })();

        return () => {
            active = false;
        };
    }, [data, dispatch, loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            disabled={disabled}
            id="asynchronous-demo"
            open={open}
            onInput={(event) => {
                setData(event.target.value)
            }}
            onChange={(event, newValue) => {
                setValues(newValue)
                setPrice && setPrice(newValue?.price || 1)
            }}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={values}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option[name] || ""}
            options={options}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress color="inherit" size={20}/>
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            {...props}
        />
    );
}

export default ReferalAutocompleteAsync;