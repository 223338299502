export function jsonSchemaVisitor(source, options) {
    let properties = source.properties;

    let sortedProperties = [];

    Object.keys(properties).map((key, index) => {
        let item = properties[key];
        item.key = key;
        sortedProperties.push(item);
    });

    sortedProperties.sort(compareProperties);

    sortedProperties.map((item, index) => {
        let key = item.key;
        let type = item.type;
        let isObject = type == "object";

        if (options.level == 0)
            options.id = key;
        else
            options.id += "_" + key;

        if (options.visit)
            options.visit(key, index, item, isObject, options);

        if (isObject) {
            options.level++;
            jsonSchemaVisitor(item, options);
            options.level--;
        }
    });
};

function compareProperties(a, b) {
    let order1 = a.order || 0;
    let order2 = b.order || 0;

    if (order1 < order2){
        return -1;
    }
    if (order1 > order2){
        return 1;
    }
    return 0;
}