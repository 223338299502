import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import {CircularProgress, Grid} from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {mapFilialData} from "../../../functions/viewDataMap";
import {useDispatch} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {v4 as uuidv4} from "uuid";
import EmployeeField from "../../../components/Relations/EmployeeField";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {FILIAL_DETAIL_ROUTE, FILIAL_DIVISION_DETAIL_ROUTE, FILIAL_DIVISION_LIST_ROUTE} from "../../../routes";
import useAlert from "../../../hooks/useAlert";
import {GeoObjectSelect, ServiceSelect} from "../../../components/Select/domainSelects";
import {employeeCRUD, filialDivisionCRUD} from "../../../http";
import PhoneShared from "../../../components/PhoneShared";
import {formatDate2} from "../../../functions/formatters";

const FilialDivisionDetailForm = () => {
    const history = useHistory()
    const {id} = useParams();
    const alert = useAlert(false, 1)
    const [isNew, setIsNew] = useState(id === "new")
    const dispatch = useDispatch()
    const [selectedSupervisor, setSelectedSupervisor] = useState({})
    const [serviceBlocks, setServiceBlocks] = useState({})
    const selectedServices = useMemo(() => {
        return Object.keys(serviceBlocks).map(el => serviceBlocks[el].roleId)
    }, [serviceBlocks])
    const [fields, setFields] = useState({
        name: "",
        nameKz: "",
        decription: "",
        bIN: "",
        addressLine: "",
        surId: "",
        addressId: "",
        bankBik: '',
        beneficiaryCode: '',
        bankAccountNumber: '',
        phoneNumber: '',
        supervisorId: "",
        filialId: '',
        proxyNumber: '',
        proxyDate: new Date(),
        proxy: '',
    })
    const submitFilialData = useMemo(() => {
        const submitData = mapFilialData(fields);
        return submitData;
    }, [fields]);

    const {loading, start, stop} = useLoader(false)
    const fetch = useCallback(() => {
        start()
        if (!isNew) {
            filialDivisionCRUD.get(id).then(data => {
                if (data.supervisorId) {
                    employeeCRUD.get(data.supervisorId).then(data => {
                        setSelectedSupervisor(data)
                    })
                }
                setFields((state) => {
                    return {
                        ...state,
                        name: data.name,
                        nameKz: data.nameKz,
                        decription: data.decription,
                        bIN: data.bin,
                        addressLine: data.addressLine,
                        surId: data.surId,
                        addressId: data.addressId,
                        supervisorId: data.supervisorId,
                        bankBik: data.bankBik,
                        beneficiaryCode: data.beneficiaryCode,
                        bankAccountNumber: data.bankAccountNumber,
                        phoneNumber: data.phoneNumber,
                        filialId: data.filialId,
                        proxy: data.proxy,
                        proxyNumber: data.proxyNumber,
                        proxyDate: data.proxyDate
                    }
                })
                stop()
            })
        } else {
            stop()
        }


    }, [dispatch, id])

    useEffect(fetch, [fetch])

    const submit = useCallback(() => {
        if (isNew) {
            filialDivisionCRUD.create({
                ...submitFilialData,
                surId: "2222222"
            }).then((id) => {
                history.push(FILIAL_DIVISION_LIST_ROUTE)
            }).catch(error => {
                alert.show(0,error)
            })
        } else {
            filialDivisionCRUD.edit({
                id: id,
                ...submitFilialData,
                surId: "2222222"
            }).then(resp=>{
               alert.show(1,"Изменения сохранены")
           }).catch(error => {
               alert.show(0,error)
           })
        }

    }, [id, dispatch, submitFilialData])
    return (
        <DashboardLayout>
            {loading ? <CircularProgress/> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew ? "Создание" : "Редактирование"} районных отделений<br/><Typography
                                style={{fontSize: 12}}>{fields.name || "Название районного отделения отсутствует"}</Typography>
                            </Typography>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{paddingTop: 16}}>
                    <Paper style={{paddingBottom: 20}}>
                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Основная
                                    информация</Typography>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={6}>
                                    <TextField required margin="normal"
                                               fullWidth id="antibioticUse" name="antibioticUse" label="Название"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           name: ev.target.value
                                                       }
                                                   })
                                               }
                                               }
                                               value={fields.name}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required margin="normal"
                                               fullWidth  label="Название (kz)"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           nameKz: ev.target.value
                                                       }
                                                   })
                                               }
                                               }
                                               value={fields.nameKz}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField required margin="normal"
                                               fullWidth id="antibioticUse" name="antibioticUse" label="Адрес"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           addressLine: ev.target.value
                                                       }
                                                   })
                                               }
                                               }
                                               value={fields.addressLine}
                                    />
                                </Grid>

                            </Grid>

                            <Grid item xs={12}>
                                <TextField required margin="normal"
                                           fullWidth id="antibioticUse" name="antibioticUse" label="Описание"
                                           onChange={(ev) => {
                                               setFields((state) => {
                                                   return {
                                                       ...state,
                                                       decription: ev.target.value
                                                   }
                                               })
                                           }}
                                           value={fields.decription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField margin="normal"
                                           fullWidth  name="proxy" label="Доверенность"
                                           onChange={(ev) => {
                                               setFields((state) => {
                                                   return {
                                                       ...state,
                                                       proxy: ev.target.value
                                                   }
                                               })
                                           }}
                                           value={fields?.proxy}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField margin="normal"
                                           fullWidth  name="proxyNumber" label="Номер доверенности"
                                           onChange={(ev) => {
                                               setFields((state) => {
                                                   return {
                                                       ...state,
                                                       proxyNumber: ev.target.value
                                                   }
                                               })
                                           }}
                                           value={fields?.proxyNumber}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type={"date"}
                                    margin="normal"
                                    value={fields.proxyDate && formatDate2(fields.proxyDate)}
                                    label="Дата доверенности"
                                    onChange={(ev) => {
                                        let val = ev.target.value ? new Date(ev.target.value) : null
                                        setFields((state) => {
                                            return {
                                                ...state,
                                                proxyDate: val
                                            }
                                        })
                                    }}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {/*<Grid item xs={12}>*/}
                            {/*    <GeoObjectSelect*/}
                            {/*        value={fields.addressId || ""}*/}
                            {/*        onChange={(val) => {*/}
                            {/*            setFields(state => {*/}
                            {/*                return {*/}
                            {/*                    ...state,*/}
                            {/*                    addressId: val*/}
                            {/*                }*/}
                            {/*            })*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <TextField required margin="normal"
                                               fullWidth id="antibioticUse" name="antibioticUse" label="BIN"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           bIN: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.bIN}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <TextField margin="normal"
                                                   fullWidth id="bankBik" name="bankBik" label="Бик"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           bankBik: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.bankBik}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <TextField margin="normal"
                                                   fullWidth id="bankAccountNumber" name="bankAccountNumber" label="ИИК KZ"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           bankAccountNumber: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.bankAccountNumber}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <TextField margin="normal"
                                                   fullWidth id="beneficiaryCode" name="beneficiaryCode" label="КБе"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           beneficiaryCode: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.beneficiaryCode}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <PhoneShared
                                               setValue={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           phoneNumber: ev
                                                       }
                                                   })
                                               }}
                                               value={fields?.phoneNumber}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item xs={12} marginTop={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={"h6"} style={{display: 'inline'}}>Сервисы</Typography>
                                    </Grid>
                                    <Grid container spacing={3} style={{display: "flex", alignItems: "center"}} xs={12}>
                                        {Object.keys(serviceBlocks).map((key) => {
                                            return (serviceBlocks[key].type !== "deleted" ? <>
                                                    <Grid item xs={11}>
                                                        <ServiceSelect
                                                            label={"Сервис"}
                                                            fullWidth
                                                            selectedServices={selectedServices.filter(el => el != serviceBlocks[key].serviceId)}
                                                            value={serviceBlocks[key].serviceId || ""}
                                                            onChange={(val) => {
                                                                setServiceBlocks((state) => {
                                                                    return {
                                                                        ...state,
                                                                        [key]: {
                                                                            ...state[key],
                                                                            serviceId: val
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button onClick={() => {
                                                            setServiceBlocks((state) => {
                                                                delete state[key]
                                                                return {
                                                                    ...state,

                                                                }
                                                            })
                                                        }}>Удалить</Button>
                                                    </Grid>
                                                </> : <></>

                                            )
                                        })}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button onClick={() => {
                                            setServiceBlocks((state) => {
                                                return {
                                                    ...state,
                                                    [uuidv4()]: {
                                                        serviceId: null,
                                                        type: "new"
                                                    },
                                                }
                                            })
                                        }}><AddIcon sx={{marginRight: 2}}/> Добавить сервис</Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid container xs={12} marginTop={2}>
                                <Grid item xs={12}>
                                    <Typography variant={"h6"} style={{display: 'inline'}}>Управление</Typography>
                                </Grid>
                                <EmployeeField
                                    label={"Управляющий"}
                                    fullWidth
                                    object={selectedSupervisor}
                                    value={fields.supervisorId}
                                    onChange={(val) => setFields(state => {
                                        console.log(val);
                                        setSelectedSupervisor(val)
                                        return {
                                            ...state,
                                            supervisorId: val.id
                                        }
                                    })}
                                />
                            </Grid>


                        </Grid>

                    </Paper>
                </Grid>
                <LoadingButton style={{marginTop: "10px"}} variant="contained" onClick={() => {
                    submit()
                }}>
                    Сохранить
                </LoadingButton>
                {alert.render()}
            </>}


        </DashboardLayout>
    )
}

export default FilialDivisionDetailForm