import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {getDepartments, getDivisions, getFilials} from "../../../redux/actions";
import CreateResult from "../../../screens/Result/Popups/CreateResult";
import ResultDetails from "../../../screens/Result/Popups/ResultDetails";
import EditResult from "../../../screens/Result/Popups/EditResult";
import RemoveResult from "../../../screens/Result/Popups/RemoveResult";
import { mapResult } from "../../../functions/viewDataMap";
import { composeResultDynamicHeader } from "../../../functions/viewDataComposition";
import CRUDTable from "../CRUDTable";
import {$authHost, resultCRUD} from "../../../http";
import useReload from "../../../hooks/useReload";
import { formatDateAsDateTime } from "../../../functions/formatters";
import { resultFormCRUD } from "../../../http";
import { getFilialDivisions } from "../../../redux/actions/filialDivisionActions";
import {ExcelContext} from "../../../screens/Protocol/ProtocolList";
import {parseResponse} from "../../../http/utils";
import {convertProtocolToExcel, convertResultToExcel} from "../../../functions";

const ResultCRUDTable = ({resultFormId, alert, extraFilterOR, ...props}) => {
    const {t} = useTranslation();
    const initialRowName = [
        {
            dataIndex: "id",
            key: "id",
            title: "ID"
        },
        {
            dataIndex: "number",
            key: "number",
            title: "Номер"
        },
        {
            dataIndex: "createdAt",
            key: "createdAt",
            title: "Дата время создания",
            render: (unknown, row) => formatDateAsDateTime(row.createdAt)
        },
        {
            dataIndex: "createdBy",
            key: "createdBy",
            title: "Автор",
            render: (unknown, row) => {
                return row.createdBy?.fullName
            }
        },
        {
            dataIndex: "formNumber",
            key: "formNumber",
            title: "Номер формы",
            render: (unknown, row) => {
                return row.form?.number
            }
        },
        {
            dataIndex: "filial",
            key: "filial",
            title: "Филиал",
            render: (unknown, row) => {
                return row.filial?.name
            }
        },
        {
            dataIndex: "filialDivision",
            key: "filialDivision",
            title: "Районное отделение",
            render: (unknown, row) => {
                return row.filialDivision?.name
            }
        }
    ];

    const [rowName, setRowName] = useState(initialRowName);
    const [rowName2, setRowName2] = useState([]);
    const [rowName3, setRowName3] = useState([]);
    const [rowName4, setRowName4] = useState([]);
    const [schema, setSchema] = useState(null);
    const dispatch = useDispatch();
    const { reload, reloadValue } = useReload();

    const [extraFilter, setExtraFilter] = useState(null);
    const [resultForms, setResultForms] = useState(null);

    const [detailsOpen, setDetailsOpen] = useState(false);

    const [editOpen, setEditOpen] = useState(false);

    const editSubmit = async (_) => {
        await resultCRUD.edit(_);
        await reload();
    }
    const [removeOpen, setRemoveOpen] = useState(false);

    const removeSubmit = async (_) => {
        await resultCRUD.delete(_);
        await reload();
    }

    const composeHeader = (form) => {
        let schema = form.schema;

        if (!schema)
            return;

        let json = JSON.parse(schema);

        let jsonSchema = json.jsonSchema;


        return {
            header: composeResultDynamicHeader(jsonSchema, initialRowName, initialRowName.length),
            json,
            jsonSchema
        }
    }


    const [idResult, setIdResult] = useState(null)

    useEffect(async () => {

        let resultForm = props.resultForm;

        if (!resultForm) {
            let resultFormsLocal = resultForms;

            if (!resultFormsLocal) {
                const r = await resultFormCRUD.search({ paging: { skip: 0 } });
                resultFormsLocal = r.result;
                await setResultForms(resultFormsLocal);
            }

            if (!resultFormId) {
                if (rowName.length > initialRowName.length) {
                    setExtraFilter(extraFilterOR);
                    setRowName(initialRowName);
                }
                return;
            }
            resultForm = resultFormsLocal.find(_ => _.id === resultFormId);
        }

        console.log(JSON.stringify(JSON.parse(resultForm.schema)))
        const composedHeader = composeHeader(resultForm);
        setRowName(composedHeader.header.rowName);
        setRowName2(composedHeader.header.rowName2);
        setRowName3(composedHeader.header.rowName3);
        setRowName4(composedHeader.header.rowName4);
        setSchema(composedHeader.json);

        !props.data && setExtraFilter({
            ...extraFilterOR,
            formId: {
                operand1: resultFormId,
                operator: "equals"
            }
        });
    }, [dispatch, resultFormId]);

    const setOpen = (id) => {
        setIdResult(id)
        setDetailsOpen(true)
    }
    const setEdit = (id) => {
        setIdResult(id)
        setEditOpen(true)
    }
    const setRemove = (id) => {
        setIdResult(id)
        setRemoveOpen(true)
    }


    const mapData = (data) => {
        return schema && resultFormId ? data.map(_ => {
            return mapResult(_, schema);
        }) : data;
    }

    let shouldRender = true;

    if (props.data && !schema)
        shouldRender = false;

    const filterFields = [
        {
            title: "Дата создания от",
            key: "createdAt",
            operandPosition: 1,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Дата создания до",
            key: "createdAt",
            operandPosition: 2,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            operandPosition: 1,
            parentKey: 'filialId',
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },

        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Отдел",
            key: "divisionId",
            compareType: "equals",
            reducerKey: "division",
            reducerArrayKey: "divisions",
            reducerAction: getDivisions,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
    ];

    const handleGenerateExcel = async (filter) => {
        if (filter?.createdAt?.operand1 && filter?.createdAt?.operand2 && resultFormId) {
            const filterToServer = {
                ...filter,
                formId: {
                    operand1: resultFormId,
                    operator: "equals"
                }
            };

            const res = await parseResponse($authHost.post(`/api/v1/result/summary/query`, {
                paging: { skip: 0 },
                filter: filterToServer
            }));

            if (res?.length) {
                await convertResultToExcel(res, filter, rowName)
            }


        } else {
            alert.show(0, 'Вы не задали период или форму журнала!')
        }
    }

    return (
        <ExcelContext.Provider value={{handleGenerateExcel}}>
            {shouldRender && <CRUDTable
                onClickEditButton={props.onClickEditButton || setEdit}
                onClickDetailsButton={props.onClickDetailsButton || setOpen}
                onClickDeleteButton={props.onClickDeleteButton || setRemove}
                onRowClick={props.onRowClick || (() => void null)}
                collapsibleTable
                search={props.data || resultCRUD.search}
                filterFields={filterFields}
                extraFilter={extraFilter ? extraFilter : extraFilterOR}
                mapData={mapData}
                reloadValue={props.reloadValue || reloadValue}
                columns={rowName}
                otherColumns={resultFormId ? [rowName2, rowName3, rowName4] : []}
                style={{"padding": "2px !important"}}
                className="result-collapsible-table"
                {...props}
                />}
            <ResultDetails idResult={idResult} action={'details'} title={t("resultList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
            <EditResult idResult={idResult} action={'edit'} title={t("resultList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit}/>
            <RemoveResult action={'remove'} title={t("resultList.removeText")} defaultId={idResult} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/>
        </ExcelContext.Provider>
    )
}

export default ResultCRUDTable;
