import { departureScheduleRequestCRUD } from "../../http";
import {
  DEPARTURE_SCHEDULE_REQUEST_GET_REQUEST,
  DEPARTURE_SCHEDULE_REQUEST_GET_SUCCESS,
  DEPARTURE_SCHEDULE_REQUEST_GET_ERROR,
  DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_REQUEST,
  DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_SUCCESS,
  DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_ERROR,
  DEPARTURE_SCHEDULE_REQUEST_ADD_REQUEST,
  DEPARTURE_SCHEDULE_REQUEST_ADD_SUCCESS,
  DEPARTURE_SCHEDULE_REQUEST_ADD_ERROR,
  DEPARTURE_SCHEDULE_REQUEST_EDIT_REQUEST,
  DEPARTURE_SCHEDULE_REQUEST_EDIT_SUCCESS,
  DEPARTURE_SCHEDULE_REQUEST_EDIT_ERROR,
  DEPARTURE_SCHEDULE_REQUEST_DELETE_REQUEST,
  DEPARTURE_SCHEDULE_REQUEST_DELETE_SUCCESS,
  DEPARTURE_SCHEDULE_REQUEST_DELETE_ERROR,
  DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_REQUEST,
  DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/departureScheduleRequestConstants";

export const getDepartureScheduleRequests = (args) => async (dispatch) => {
  if (!args)
    return;

  try {
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_GET_REQUEST,
    });

    const data = await departureScheduleRequestCRUD.search(args);
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDepartureScheduleRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await departureScheduleRequestCRUD.get(id);
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addDepartureScheduleRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_ADD_REQUEST,
    });

    const response = await departureScheduleRequestCRUD.create(data);
    await dispatch(getDepartureScheduleRequests(args));

    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: DEPARTURE_SCHEDULE_REQUEST_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editDepartureScheduleRequest =
  (data, args) => async (dispatch) => {
    try {
      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_EDIT_REQUEST,
      });

      await departureScheduleRequestCRUD.edit(data);
      await dispatch(getDepartureScheduleRequests(args));

      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_EDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_EDIT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteDepartureScheduleRequest =
  (id, args) => async (dispatch) => {
    try {
      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_DELETE_REQUEST,
      });

      await departureScheduleRequestCRUD.delete(id);
      await dispatch(getDepartureScheduleRequests(args));

      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_DELETE_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const workflowDepartureScheduleRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await departureScheduleRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      const updatedScheduleRequest = await departureScheduleRequestCRUD.get(id);

      console.log({ updatedScheduleRequest });

      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_SUCCESS,
      });

      console.log({ response });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: DEPARTURE_SCHEDULE_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
