import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  BasicModal,
  BasicModalContainer,
  BasicModalHeader,
  BasicTable,
  CustomDatePicker,
  Form,
  FormSection,
} from "../../../../components";
import {
  addWithoutDepartureRequest,
  getAuthorizedEmployee,
  getContractByKey,
  getContractRequestByKey,
} from "../../../../redux/actions";
import {
  FilialDivisionSelect,
  FilialSelect,
  DepartmentSelect,
  DivisionSelect
} from "../../../../components/Select/domainSelects";

import { prepareContractRequestChildRequestDataForSubmit } from "../../../../functions/viewDataMap";
import { trySetCount } from '../../../../models/data/ContractRequestChildrenModel';
import EmployeeField from "../../../../components/Relations/EmployeeField";
import DialogLayout from "../../../../components/Dialog/DialogLayout";
import {autoCompleteFilialAndFilialDivision} from "../../../../functions/dateHelpers";
import { NumberField2 } from "../../../../components/Fields/NumberField2";

export const WithoutDepartureRequestCreateModal = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const authorizedEmployee = useSelector(state=>state.employee.authorizedEmployee)

  const { contract } = useSelector((s) => s.contract);
  const { contractRequest } = useSelector((s) => s.contractRequest);

  const [localCreatedBy, setLocalCreatedBy] = useState({});

  const [localObjects, setLocalObjects] = useState([]);
  const [localSpecifications, setLocalSpecifications] = useState([]);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState([]);

  const [departmentId, setDepartmentId] = useState(null);
  const [divisionId, setDivisionId] = useState(null);

  const [localSpecialistId, setLocalSpecialistId] = useState(null);
  const [localSpecialist, setLocalSpecialist] = useState({});

  const [filialId, setFilialId] = useState(null);
  const [filialDivisionId, setFilialDivisionId] = useState(null);

  const [performerId, setPerformerId] = useState(null);
  const [performer, setPerformer] = useState({});
  const [responsible, setResponsible] = useState({});

  const handleSpecificationQuantityChange = (e, id) => {
    const isChanged = trySetCount(selectedSpecifications, localSpecifications, id, contractRequest,
      setSelectedSpecifications, setLocalSpecifications, e.target.value);

    if (!isChanged)
      return alert("Превышено максимальное значение исследований для этого элемента");
  };

  const handleReset = () => {
    setSelectedObjects([]);
    setSelectedSpecifications([]);
    //setLocalFilial(null);
    setDepartmentId(null);
    setDivisionId(null);

    setLocalSpecialist(null);
    setLocalSpecialistId(null);
    setFilialId(null);

    //setLocalFilialDivision(null);
    setFilialId(null);
    setFilialDivisionId(null);
    setPerformerId(null);
    setPerformer(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking Fields
    if (!selectedSpecifications?.length) return alert("Выберите услуги!");

    prepareContractRequestChildRequestDataForSubmit(selectedSpecifications, selectedObjects);

    setLoading(true);

    const data = {
      contractRequestId: contractRequest.id,
      contractRequestNumber: contractRequest.number,
      specialistId: localSpecialistId,
      filialId: filialId || contractRequest.filialId,
      filialDivisionId: filialDivisionId || contractRequest.contractFilialDivisionId || contractRequest.filialDivisionId,
      departmentId: departmentId,
      divisionId: divisionId,
      objects: selectedObjects,
      specifications: selectedSpecifications,
      performerId: performer?.id,
      responsibleId: responsible?.id
    };

    // Creating
    const createdId = await dispatch(addWithoutDepartureRequest(data));
    if (!createdId) return setLoading(false);

    // Updating Contract Request
    const contractReq = await dispatch(
      getContractRequestByKey(contractRequest.id)
    );
    if (!contractReq) return setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      handleReset();
    }, 500);
  };

  useEffect(() => {
    if (!open)
      return;

    setSelectedObjects([]);
    setSelectedSpecifications([]);

    dispatch(getAuthorizedEmployee()).then(setLocalCreatedBy);
    dispatch(getContractByKey(contractRequest.contractId));
  }, [open]);

  useEffect(() => {
    autoCompleteFilialAndFilialDivision(authorizedEmployee, setFilialId, setFilialDivisionId);
  }, [])

  useEffect(() => {
    if (!contractRequest)
      return;

    setLocalObjects(contractRequest?.objects);
    setLocalSpecifications(contractRequest?.specifications);
  }, [contractRequest]);


  return (
    <DialogLayout open={open} setOpen={setOpen} title="Заявка без выезда: Создание" loading={loading}>
      <Form onSubmit={handleSubmit}>
        <BasicModalContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={contractRequest?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  value={localCreatedBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <EmployeeField
                    style={{ marginBottom: "5px" }}
                    label={"Исполнитель заявки"}
                    object={performer}
                    value={performerId}
                    onChange={(val) => {
                      setPerformer(val)
                      setPerformerId(val?.id)
                    }}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection heading="Договор" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Номер договора"
                  type="number"
                  value={contract?.id || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Статус по договору"
                  type="text"
                  value={contract?.state?.name || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата договора"
                  value={contract?.actionAt}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Объекты (${localObjects?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    {
                      id: "name",
                      label: t("object.tableItem.name"),
                      render: (row) => row?.object?.name,
                    },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={localObjects}
                  disabled={true}
                  selected={selectedObjects}
                  setSelected={setSelectedObjects}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Услуги (${localSpecifications?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                      render: ({ id, count }) => (
                        <NumberField2                          
                          value={count}                          
                          onChange={(e) =>
                            handleSpecificationQuantityChange(e, id)
                          }
                        />
                      ),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={localSpecifications}
                  disabled={true}
                  selected={selectedSpecifications}
                  setSelected={setSelectedSpecifications}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FilialSelect
                  label={"Филиал"}
                  fullWidth
                  value={filialId}
                  onChange={(val) => {
                    setFilialId(val);
                    setFilialDivisionId(Number(val) !== Number(filialId) ?
                      null
                      : filialDivisionId);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FilialDivisionSelect
                  label={"Районное отделение"}
                  fullWidth
                  value={filialDivisionId}
                  filialId={filialId}
                  onChange={(val, filialDivision) => {
                    setFilialDivisionId(val);
                    setFilialId(Number(filialDivision.filialId) !== Number(filialId)
                      ? filialDivision.filialId
                      : filialId);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DepartmentSelect
                  label={"Лаборатория"}
                  fullWidth
                  value={departmentId}
                  onChange={(val) => {
                    if (!val)
                      return;

                    setDepartmentId(val);
                    setDivisionId(Number(val) !== Number(departmentId) ?
                      null
                      : divisionId);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <DivisionSelect
                  label={"Отдел лаборатории"}
                  fullWidth
                  value={divisionId}
                  departmentId={departmentId}
                  onChange={(val, division) => {
                    if (!val)
                      return;

                    setDivisionId(val);
                    setDepartmentId(Number(division.departmentId) !== Number(departmentId)
                      ? division.departmentId
                      : departmentId);
                  }}
                />
              </Grid>
              <EmployeeField
                  label={"Заведующий лаборатории"}
                  fullWidth
                  object={responsible}
                  value={responsible?.id}
                  onChange={(responsible) => {
                    setResponsible(responsible)
                  }}
              />
              {/* <Grid item xs={4}>
                <SelectFieldModal
                  label="Зав. лаборатории"
                  cells={[
                    { id: "id", label: t("employee.tableItem.id") },
                    {
                      id: "name",
                      label: t("employee.tableItem.fullName"),
                      render: ({ fullName }) => fullName,
                    },
                  ]}
                  actionFn={getEmployees}
                  valueName="id"
                  value={performer?.fullName}
                  setValue={setPerformerId}
                  storeData={employees}
                  storeCount={employeeCount || 0}
                />
              </Grid> */}
            </Grid>
          </FormSection>
        </BasicModalContainer>

        <BasicModalContainer>
          <Grid container spacing={2}>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
              >
                Создать заявку
              </LoadingButton>
            </Grid>
          </Grid>
        </BasicModalContainer>
      </Form>
    </DialogLayout>
  );
};
