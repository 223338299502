import { 
    CLIENT_GET_REQUEST, CLIENT_GET_SUCCESS, CLIENT_GET_ERROR, 
    CLIENT_GET_BY_KEY_REQUEST, CLIENT_GET_BY_KEY_SUCCESS, CLIENT_GET_BY_KEY_ERROR, 
    CLIENT_ADD_REQUEST, CLIENT_ADD_SUCCESS, CLIENT_ADD_ERROR, 
    CLIENT_EDIT_REQUEST, CLIENT_EDIT_SUCCESS, CLIENT_EDIT_ERROR, 
    CLIENT_DELETE_REQUEST, CLIENT_DELETE_SUCCESS, CLIENT_DELETE_ERROR
} from "../constants/clientConstants";

const initialState = {
    clients: [],
    client: {},
    loading: false,
    success: null,
    error: null,
    clientRequestedCount: 0,
};

export const clientReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CLIENT_GET_REQUEST:
            return {
                ...state,
                clientRequestedCount: ++state.clientRequestedCount,
                loading: true,
            };
        case CLIENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                clients: action.payload,
                success: true,
            };
        case CLIENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLIENT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                client: action.payload,
                success: true,
            };
        case CLIENT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLIENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CLIENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLIENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CLIENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLIENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLIENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CLIENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
