import m_12_1633 from './KZ.М.12.1633.svg'
import m_12_1633_b from './KZ.М.12.1633 black.svg'
import t_12_E0718 from './KZ.Т.12.Е0718.svg'
import t_12_E0718_b from './KZ.Т.12.Е0718 black.svg'


export default [
  m_12_1633,
  m_12_1633_b,
  t_12_E0718,
  t_12_E0718_b
]
