import { 
    SERVICE_PRICE_LIST_HISTORY_GET_REQUEST, SERVICE_PRICE_LIST_HISTORY_GET_SUCCESS, SERVICE_PRICE_LIST_HISTORY_GET_ERROR, 
    SERVICE_PRICE_LIST_HISTORY_GET_BY_KEY_REQUEST, SERVICE_PRICE_LIST_HISTORY_GET_BY_KEY_SUCCESS, SERVICE_PRICE_LIST_HISTORY_GET_BY_KEY_ERROR, 
    SERVICE_PRICE_LIST_HISTORY_ADD_REQUEST, SERVICE_PRICE_LIST_HISTORY_ADD_SUCCESS, SERVICE_PRICE_LIST_HISTORY_ADD_ERROR, 
    SERVICE_PRICE_LIST_HISTORY_EDIT_REQUEST, SERVICE_PRICE_LIST_HISTORY_EDIT_SUCCESS, SERVICE_PRICE_LIST_HISTORY_EDIT_ERROR, 
    SERVICE_PRICE_LIST_HISTORY_DELETE_REQUEST, SERVICE_PRICE_LIST_HISTORY_DELETE_SUCCESS, SERVICE_PRICE_LIST_HISTORY_DELETE_ERROR
} from "../constants/servicePriceListHistoryConstants";

const initialState = {
    servicePriceListHistories: [],
    servicePriceListHistory: {},
    loading: false,
    success: null,
    error: null,
    servicePriceListHistoryRequestedCount: 0,
};

export const servicePriceListHistoryReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_PRICE_LIST_HISTORY_GET_REQUEST:
            return {
                ...state,
                servicePriceListHistoryRequestedCount: ++state.servicePriceListHistoryRequestedCount,
                loading: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                servicePriceListHistories: action.payload,
                success: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_HISTORY_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                servicePriceListHistory: action.payload,
                success: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_HISTORY_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_HISTORY_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_HISTORY_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PRICE_LIST_HISTORY_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
