import React, {useCallback, useState, useEffect} from 'react';
import {Button, TextField} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import {GenderSelect} from "../../components/Select/domainSelects";
import useValidations from "../../hooks/useValidations";
import Typography from "@mui/material/Typography";
import useLoader from "../../hooks/useLoader";
import LoadingButton from "@mui/lab/LoadingButton";
import {patientsCRUD} from "../../http/CRUD";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";
import InputMask from "react-input-mask";
import {convertIINtoBirthdayDate} from "../../functions/iinConverter"

export default function CreatePatientForm({afterSubmit, onClose}) {
  const [data, setData] = useState({});
  const handleChange = useCallback((key, value) => {
    setSubmitErrors(false);
    setData({...data, [key]: value});
  }, [data]);

  console.log('data', data)

  useEffect(() => {
    handleChange("birthDate", convertIINtoBirthdayDate(data.identityNumber))
  }, [data.identityNumber])

  useEffect(() => {
    if (!data) {
      return;
    }

    let fullName = '';

    if (data.lastName) {
      fullName += data.lastName;
    }    

    if (fullName) {
      fullName += ' ';
    }

    if (data.firstName) {
      fullName += data.firstName;
    } 

    if (fullName) {
      fullName += ' ';
    }

    if (data.secondName) {
      fullName += data.secondName;
    } 

    if (!fullName) {
      return;
    }

    handleChange("fullName", fullName);
  }, [data.lastName, data.firstName, data.secondName])

  const {isValid, renderedErrors} = useValidations(data, [
    {label: "Имя", code: "firstName", rules: ["required"]},
    {label: "Фамилия", code: "lastName", rules: ["required"]},
    {label: "Пол", code: "genderId", rules: ["required"]},
  ]);

  const [showSubmitErrors, setShowSubmitErrors] = useState(null);
  const [submitErrors, setSubmitErrors] = useState(null);

  const {loading, start, stop} = useLoader(false);
  const submit = useCallback(() => {
    if(!isValid) {
      setShowSubmitErrors(true);
      return;
    }
    start();

    patientsCRUD.create(data)
      .then(({id}) => {
        return patientsCRUD.get(id)
          .then((r) => {
            if(afterSubmit) afterSubmit(r);
            if(onClose) onClose();
          })
      })
      .catch(setSubmitErrors)
      .finally(stop)
  }, [onClose, data, isValid, start, stop, afterSubmit]);

  return <Box>
    <DialogTitle>Регистрация пациента</DialogTitle>
    <DialogContent dividers style={{"overflowY": "auto", "max-height":"600px"}}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label={"Имя"}
            onChange={e => handleChange("firstName", e.target.value)}
            fullWidth
            value={data.firstName}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={"Фамилия"}
            onChange={e => handleChange("lastName", e.target.value)}
            fullWidth
            value={data.lastName}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={"Отчество"}
            onChange={e => handleChange("secondName", e.target.value)}
            fullWidth
            value={data.secondName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={"ФИО"}
            onChange={e => handleChange("fullName", e.target.value)}
            fullWidth
            value={data.fullName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={"ИИН"}
            onChange={e => handleChange("identityNumber", e.target.value)}
            fullWidth
            value={data.identityNumber}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={"Место работы"}
            onChange={e => handleChange("placeOfWorkOrEducation", e.target.value)}
            fullWidth
            value={data.placeOfWorkOrEducation}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={"Адрес"}
            onChange={e => handleChange("addressLine", e.target.value)}
            fullWidth
            value={data.addressLine}
          />
        </Grid>
        <Grid item xs={6}>
            <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                <DatePicker 
                  views={['day']} 
                  label="Дата рождения" 
                  value={data.birthDate} 
                  onChange={value => handleChange("birthDate", value)} 
                  minDate={new Date('1910-03-01')} 
                  renderInput={(params) =>
                    <TextField margin="normal"  {...params} helperText={null}/>}
                />
            </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={"Возраст"}
            onChange={e => handleChange("yearsOld", e.target.value)}
            fullWidth
            value={data.yearsOld}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </Grid>
        <Grid item xs={4}>
            <InputMask 
              mask='+7 999 999 99 99' 
              value={data.phoneNumber}
              onChange={e => handleChange("phoneNumber", e.target.value)}>
                {() =>
                    <TextField id="phoneNumber" label="Телефон" name="phoneNumber" margin="normal" type="text"/>
                }
            </InputMask>
        </Grid>

        <Grid item xs={4}>
          <GenderSelect
            label={"Пол"}
            value={data.genderId}
            onChange={val => handleChange("genderId", val)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {(submitErrors || showSubmitErrors) &&
          <Typography color={"secondary"}>
            {submitErrors || renderedErrors}
          </Typography>}
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} style={{marginLeft: "auto", marginRight: 20}}>Отменить</Button>
      <LoadingButton loading={loading} variant={"contained"} onClick={submit}>Сохранить</LoadingButton>
    </DialogActions>
  </Box>
}
