import { anObjectReferralCRUD } from "../../http";
import {
  AN_OBJECT_REFERRAL_GET_REQUEST,
  AN_OBJECT_REFERRAL_GET_SUCCESS,
  AN_OBJECT_REFERRAL_GET_ERROR,
  AN_OBJECT_REFERRAL_GET_BY_KEY_REQUEST,
  AN_OBJECT_REFERRAL_GET_BY_KEY_SUCCESS,
  AN_OBJECT_REFERRAL_GET_BY_KEY_ERROR,
  AN_OBJECT_REFERRAL_ADD_REQUEST,
  AN_OBJECT_REFERRAL_ADD_SUCCESS,
  AN_OBJECT_REFERRAL_ADD_ERROR,
  AN_OBJECT_REFERRAL_EDIT_REQUEST,
  AN_OBJECT_REFERRAL_EDIT_SUCCESS,
  AN_OBJECT_REFERRAL_EDIT_ERROR,
  AN_OBJECT_REFERRAL_DELETE_REQUEST,
  AN_OBJECT_REFERRAL_DELETE_SUCCESS,
  AN_OBJECT_REFERRAL_DELETE_ERROR,
  AN_OBJECT_REFERRAL_WORKFLOW_RUN_REQUEST,
  AN_OBJECT_REFERRAL_WORKFLOW_RUN_SUCCESS,
  AN_OBJECT_REFERRAL_WORKFLOW_RUN_ERROR,
} from "../constants/anObjectReferralConstants";

export const getAnObjectReferrals = (args) => async (dispatch) => {
  try {
    dispatch({
      type: AN_OBJECT_REFERRAL_GET_REQUEST,
    });

    const data = await anObjectReferralCRUD.search(args);
    dispatch({
      type: AN_OBJECT_REFERRAL_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: AN_OBJECT_REFERRAL_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAnObjectReferralByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: AN_OBJECT_REFERRAL_GET_BY_KEY_REQUEST,
    });

    const data = await anObjectReferralCRUD.get(id);

    dispatch({
      type: AN_OBJECT_REFERRAL_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: AN_OBJECT_REFERRAL_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addAnObjectReferral = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: AN_OBJECT_REFERRAL_ADD_REQUEST,
    });

    const response = await anObjectReferralCRUD.create(data);
    await dispatch(getAnObjectReferrals(args));
    dispatch({
      type: AN_OBJECT_REFERRAL_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: AN_OBJECT_REFERRAL_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editAnObjectReferral = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: AN_OBJECT_REFERRAL_EDIT_REQUEST,
    });

    const response = await anObjectReferralCRUD.edit(data);
    await dispatch(getAnObjectReferrals(args));

    dispatch({
      type: AN_OBJECT_REFERRAL_EDIT_SUCCESS,
    });

    return response.affected === 1;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: AN_OBJECT_REFERRAL_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteAnObjectReferral = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: AN_OBJECT_REFERRAL_DELETE_REQUEST,
    });

    await anObjectReferralCRUD.delete(id);
    await dispatch(getAnObjectReferrals(args));

    dispatch({
      type: AN_OBJECT_REFERRAL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: AN_OBJECT_REFERRAL_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const workflowAnObjectReferral =
  (id, prevStateId, nextStateId, comment) => async (dispatch) => {
    try {
      dispatch({ type: AN_OBJECT_REFERRAL_WORKFLOW_RUN_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await anObjectReferralCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId,
        comment
      );
      await dispatch({ type: AN_OBJECT_REFERRAL_WORKFLOW_RUN_SUCCESS });

      console.log({ response });

      return response;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: AN_OBJECT_REFERRAL_WORKFLOW_RUN_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
