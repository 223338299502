import React from "react";
import { Radio } from "./Radio";
import { probeTypeDomain } from "../Domain/domains";

export const BooleanRadio = ({ onChange, value, ...props }) => {
  return (
    <Radio
      options={[
        { value: "true", label: "Да" },
        { value: "false", label: "Нет" },
      ]}
      value={String(value)}
      onChange={(val) => {
        if (val === "true") onChange(true);
        else if (val === "false") onChange(false);
        else onChange(val);
      }}
      {...props}
    />
  );
};

export const ProbeTypeRadio = ({ ...props }) => {
    return (
        <Radio
            label={
                "16. Сынамалардың тізімі/Перечень отобранных образцов (тем, кто пробы не отбирает заполнять не нужно)"
            }
            options={probeTypeDomain.options}
            {...props}
        />
    )
};
