import { 
    REQUEST_TRACE_GET_REQUEST, REQUEST_TRACE_GET_SUCCESS, REQUEST_TRACE_GET_ERROR, 
    REQUEST_TRACE_GET_BY_KEY_REQUEST, REQUEST_TRACE_GET_BY_KEY_SUCCESS, REQUEST_TRACE_GET_BY_KEY_ERROR, 
    REQUEST_TRACE_ADD_REQUEST, REQUEST_TRACE_ADD_SUCCESS, REQUEST_TRACE_ADD_ERROR, 
    REQUEST_TRACE_EDIT_REQUEST, REQUEST_TRACE_EDIT_SUCCESS, REQUEST_TRACE_EDIT_ERROR, 
    REQUEST_TRACE_DELETE_REQUEST, REQUEST_TRACE_DELETE_SUCCESS, REQUEST_TRACE_DELETE_ERROR
} from "../constants/requestTraceConstants";

const initialState = {
    requestTraces: [],
    requestTrace: {},
    loading: false,
    success: null,
    error: null,
    requestTraceRequestedCount: 0,
};

export const requestTraceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REQUEST_TRACE_GET_REQUEST:
            return {
                ...state,
                requestTraceRequestedCount: ++state.requestTraceRequestedCount,
                loading: true,
            };
        case REQUEST_TRACE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                requestTraces: action.payload,
                success: true,
            };
        case REQUEST_TRACE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_TRACE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_TRACE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                requestTrace: action.payload,
                success: true,
            };
        case REQUEST_TRACE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_TRACE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_TRACE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_TRACE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_TRACE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_TRACE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_TRACE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_TRACE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_TRACE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_TRACE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
