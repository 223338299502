/* eslint-disable import/no-anonymous-default-export */
export default {
  '22': {
    name: 'ПРОТОКОЛ исследования материала на наличие возбудителя холеры',
    kazakhName: 'Материалдарды тырысқақ қоздырғышына зерттеу ХАТТАМАСЫ',
    fields: [
      {
        type: 'text',
        id: 'name',
        label: 'Ф.И.О. (при наличии)',
        kazakhLabel: 'Т.А.Ә. (болған жағдайда)'
      },
      {
        type: 'number',
        id: 'age',
        label: 'Возраст',
        kazakhLabel: 'Жасы',
        props: {
          inputProps: {
            min: 0,
            max: 100
          }
        }
      },
      {
        type: 'text',
        id: 'adress',
        label: 'Адрес',
        kazakhLabel: 'Мекен-жайы'
      },
      {
        type: 'text',
        id: 'place_of_work',
        label: 'Место работы',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        type: 'text',
        id: 'diagnosis',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        type: 'datetime',
        id: 'sample_obtaining_date',
        label: 'Дата и время взятия пробы',
        kazakhLabel: 'Сынаманы алған күні және уақыты'
      },
      {
        type: 'switch',
        id: 'first_or_repeated',
        label: 'Первичный или повторный',
        kazakhLabel: 'Бірінші рет немесе қайталап',
        options: [
          'Первичный',
          'Повторный'
        ]
      },
      {
        type: 'text',
        id: 'antibiotic_uses',
        label: 'Применения антибиотика',
        kazakhLabel: 'Антибиотикті қабылдағаны туралы'
      },
      {
        type: 'text',
        id: 'forwarding_organizations',
        label: 'Направляющее учреждения',
        kazakhLabel: 'Жіберген мекеме'
      },
      {
        type: 'text',
        id: 'probe_name',
        label: 'Наименование пробы',
        kazakhLabel: 'Сынама атауы'
      },
      {
        type: 'datetime',
        id: 'sample_delivery_date',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізу күні мен уақыты'
      },
      {
        type: 'text',
        id: 'examination_goal',
        label: 'Цель обследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        type: 'text',
        id: 'material_forwarder',
        label: 'Должность, Ф.И.О. (при наличии), подпись направившего материал',
        kazakhLabel: 'Үлгіні жіберген адамның Т.А.Ә. (болған жағдайда). Қолы'
      },
      {
        type: 'text',
        id: 'trial_document',
        label: 'Нормативный документ на метод испытаний',
        kazakhLabel: 'Зерттеу әдісіне нормативтік құжат'
      },
      {
        type: 'text',
        id: 'complied_with_regulatory_doc',
        label: 'Результат исследования, Исследование образца проводились на соответствие нормативного документа',
        kazakhLabel: 'Зерттеу қорытындысы, Үлгілердің (нің) нормативтік құжаттарға сәйкестiгiне зерттеулер жүргiзiлдi'
      }
    ]
  },
  '23': {
    version: 2,
    name: 'ПРОТОКОЛ исследования образцов пищевых продуктов',
    kazakhName: 'Тамақ өнімдерінің үлгілерін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгі атауы'
      },
      {
        id: 'amount',
        type: 'number',
        label: 'Количество',
        kazakhLabel: 'Саны'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеудің мақсаты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'date',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізу күні мен уақыты'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне НҚ'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод испытаний',
        kazakhLabel: 'Зерттеу әдісіне қолданылған нормативтік құжат (НҚ)'
      },
      // {
      //   id: 'research_results',
      //   type: 'text',
      //   label: 'Результаты исследования',
      //   kazakhLabel: 'Зерттеу нәтижелері Зерттеу нәтижелері'
      // },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=895 style='border-collapse:
        collapse;table-layout:fixed;width:671pt'>
        <col width=64 style='width:48pt'>
        <col width=238 style='mso-width-source:userset;mso-width-alt:8448;width:178pt'>
        <col width=214 style='mso-width-source:userset;mso-width-alt:7623;width:161pt'>
        <col width=315 style='mso-width-source:userset;mso-width-alt:11207;width:236pt'>
        <col width=64 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1517643 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl6517643 width=238 style='width:178pt'>Ингредиенттер ж&#1241;не
         бас&#1179;алар к&#1257;рсеткіштеріні&#1187; атауы</td>
         <td class=xl6517643 width=214 style='border-left:none;width:161pt'>Аны&#1179;тал&#1171;ан
         шо&#1171;ырлану</td>
         <td class=xl6517643 width=315 style='border-left:none;width:236pt'>Нормативтік
         к&#1257;рсеткіштер</td>
         <td class=xl6517643 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістеріне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1517643 style='height:52.8pt'></td>
         <td class=xl6517643 width=238 style='border-top:none;width:178pt'>(Наименование
         показателей ингредиентов и других)</td>
         <td class=xl6517643 width=214 style='border-top:none;border-left:none;
         width:161pt'>(Обнаруженная концентрация)</td>
         <td class=xl6517643 width=315 style='border-top:none;border-left:none;
         width:236pt'>(Нормативные показатели)</td>
         <td class=xl6517643 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на методы исследования)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1517643 style='height:14.4pt'></td>
         <td class=xl6517643 width=238 style='border-top:none;width:178pt'>1</td>
         <td class=xl6517643 width=214 style='border-top:none;border-left:none;
         width:161pt'>2</td>
         <td class=xl6517643 width=315 style='border-top:none;border-left:none;
         width:236pt'>3</td>
         <td class=xl6517643 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
        </tr>
        <![if supportMisalignedColumns]>
        <tr height=0 style='display:none'>
         <td width=64 style='width:48pt'></td>
         <td width=238 style='width:178pt'></td>
         <td width=214 style='width:161pt'></td>
         <td width=315 style='width:236pt'></td>
         <td width=64 style='width:48pt'></td>
        </tr>
        <![endif]>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table cols="4" rules="NONE" cellspacing="0" border="1" style="border-collapse: collapse" class="table table-bordered">
          <colgroup><col width="246"><col width="222"><col width="149"><col width="198"></colgroup>
          <tbody>
            <tr>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1033;1033;General" width="246" valign="MIDDLE" height="78" align="CENTER"><font size="3" face="Times New Roman" color="#000000">Ингредиенттер және басқалар көрсеткіштерінің атауы (Наименование показателей ингредиентов и других)</font></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1033;1033;General" width="222" valign="MIDDLE" align="CENTER"><font size="3" face="Times New Roman" color="#000000">Анықталған шоғырлану (Обнаруженная концентрация)</font></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1033;1033;General" width="149" valign="MIDDLE" align="CENTER"><font size="3" face="Times New Roman" color="#000000">Нормативтік көрсеткіштер <br>(Нормативные показатели)</font></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1033;1033;General" width="198" valign="MIDDLE" align="CENTER"><font size="3" face="Times New Roman" color="#000000">Зерттеу әдістеріне қолданылған НҚ<br>(НД на методы исследования)</font></td>
            </tr>
            <tr>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="1" sdnum="1033;1033;General" valign="MIDDLE" height="21" align="CENTER"><font size="3" face="Times New Roman" color="#000000">1</font></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="2" sdnum="1033;1033;General" valign="MIDDLE" align="CENTER"><font size="3" face="Times New Roman" color="#000000">2</font></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="3" sdnum="1033;1033;General" valign="MIDDLE" align="CENTER"><font size="3" face="Times New Roman" color="#000000">3</font></td>
              <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="4" sdnum="1033;1033;General" valign="MIDDLE" align="CENTER"><font size="3" face="Times New Roman" color="#000000">4</font></td>
            </tr>
          </tbody>
        </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '24': {
    version: 2,
    name: 'ПРОТОКОЛ микробиологического исследования воды',
    kazakhName: 'Суды микробиологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Партия нөмері'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне нормативтік құжат (НҚ)'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'delivery_guy_additional_data',
        type: 'text',
        label: 'Дополнительные сведения о лице, доставившем пробу',
        kazakhLabel: 'Сынама әкелген тұлға туралы қосымша мәліметтер'
      },
      // {
      //   id: 'research_results',
      //   type: 'text',
      //   label: 'Результаты измерений',
      //   kazakhLabel: 'Өлшеу нәтижелері'
      // },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеріне қолданылған НҚ'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=872 style='border-collapse:
        collapse;table-layout:fixed;width:654pt'>
        <col width=64 style='width:48pt'>
        <col width=230 style='mso-width-source:userset;mso-width-alt:8192;width:173pt'>
        <col width=166 style='mso-width-source:userset;mso-width-alt:5888;width:124pt'>
        <col width=284 style='mso-width-source:userset;mso-width-alt:10097;width:213pt'>
        <col width=64 span=2 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1513204 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl6513204 width=230 style='width:173pt'>К&#1257;рсеткіштерді&#1187;
         атауы</td>
         <td rowspan=2 class=xl6513204 width=166 style='width:124pt'>&#1256;лшеу
         бірлігі (Единица измерения)</td>
         <td class=xl6513204 width=284 style='border-left:none;width:213pt'>Н&#1178;
         бойынша нормасы</td>
         <td class=xl6513204 width=64 style='border-left:none;width:48pt'>Зерттеу
         н&#1241;тижесі</td>
         <td class=xl6513204 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістеріне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1513204 style='height:52.8pt'></td>
         <td class=xl6513204 width=230 style='border-top:none;width:173pt'>(Наименование
         показателей)</td>
         <td class=xl6513204 width=284 style='border-top:none;border-left:none;
         width:213pt'>(Норма по НД)</td>
         <td class=xl6513204 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Результат испытания)</td>
         <td class=xl6513204 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на метод испытания)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1513204 style='height:14.4pt'></td>
         <td class=xl6513204 width=230 style='border-top:none;width:173pt'>1</td>
         <td class=xl6513204 width=166 style='border-top:none;border-left:none;
         width:124pt'>2</td>
         <td class=xl6513204 width=284 style='border-top:none;border-left:none;
         width:213pt'>3</td>
         <td class=xl6513204 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6513204 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="154"><col width="129"><col width="152"><col width="128"><col width="135"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="154" height="106" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштердің атауы<br>(Наименование показателей)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="129" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу бірлігі (Единица измерения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="152" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">НҚ бойынша нормасы<br>(Норма по НД)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="128" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижесі<br>(Результат испытания)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="135" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеріне қолданылған НҚ<br>(НД на метод испытания)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '25': {
    version: 2,
    name: 'ПРОТОКОЛ микробиологического исследования смывов',
    kazakhName: 'Шайындыларды микробиологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Партия нөмері'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне нормативтік құжат (НҚ) '
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша деректер'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістемеге қолданылған НҚ'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=903 style='border-collapse:
        collapse;table-layout:fixed;width:677pt'>
        <col width=64 style='width:48pt'>
        <col width=378 style='mso-width-source:userset;mso-width-alt:13425;width:283pt'>
        <col width=64 style='width:48pt'>
        <col width=205 style='mso-width-source:userset;mso-width-alt:7281;width:154pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1526004 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl6626004 width=378 style='width:283pt'>&#1198;лгі н&#1257;мірi</td>
         <td class=xl6726004 width=64 style='border-left:none;width:48pt'>&#1198;лгі
         алын&#1171;ан орын ж&#1241;не н&#1199;ктелер</td>
         <td colspan=3 class=xl6726004 width=333 style='border-left:none;width:250pt'>Н&#1241;тиже</td>
         <td class=xl6826004 width=64 style='border-left:none;width:48pt'>Ескерту</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
         <td height=53 class=xl1526004 style='height:39.6pt'></td>
         <td class=xl6926004 width=378 style='border-top:none;width:283pt'>(Номер
         образца)</td>
         <td class=xl6526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Место и точки отбора)</td>
         <td colspan=3 class=xl6526004 width=333 style='border-left:none;width:250pt'>Результат</td>
         <td class=xl7026004 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Примечание)</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1526004 style='height:52.8pt'></td>
         <td class=xl7626004 width=378 style='border-top:none;width:283pt'>&nbsp;</td>
         <td class=xl7426004 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6526004 width=205 style='border-top:none;border-left:none;
         width:154pt'>Ішек тая&#1179;шалары тобыны&#1187; бактериясы</td>
         <td class=xl6526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>Алтын т&#1199;стес стафилококк</td>
         <td class=xl6526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>Шартты патогенді флора</td>
         <td class=xl7526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1526004 style='height:52.8pt'></td>
         <td class=xl7626004 width=378 style='border-top:none;width:283pt'>&nbsp;</td>
         <td class=xl7426004 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6526004 width=205 style='border-top:none;border-left:none;
         width:154pt'>(Бактерии группы кишечных палочек)</td>
         <td class=xl6526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Золотистый стафилококк)</td>
         <td class=xl6526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Условно патогенная флора)</td>
         <td class=xl7526004 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1526004 style='height:15.0pt'></td>
         <td class=xl7126004 width=378 style='border-top:none;width:283pt'>1</td>
         <td class=xl7226004 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl7226004 width=205 style='border-top:none;border-left:none;
         width:154pt'>3</td>
         <td class=xl7226004 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl7226004 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl7326004 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="90"><col width="123"><col width="180"><col width="113"><col width="94"><col width="108"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="2" width="90" height="168" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгі нөмірi<br>(Номер образца)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="123" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгі алынған орын және нүктелер<br>(Место и точки отбора)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" width="386" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нәтиже<br>Результат</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" width="108" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескерту<br>(Примечание)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ішек таяқшалары тобының бактериясы<br>(Бактерии группы кишечных палочек)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Алтын түстес стафилококк<br>(Золотистый стафилококк)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шартты патогенді флора<br>(Условно патогенная флора)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '26': {
    version: 2,
    name: 'ПРОТОКОЛ микробиологического исследования пищевых продуктов',
    kazakhName: 'Тамақ өнімдерін микробиологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Партия нөмері'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'text',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне қолдалынған нормативтік құжат (НҚ)'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдісіне қолданылған НҚ'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1268 style='border-collapse:
        collapse;table-layout:fixed;width:951pt'>
        <col width=64 style='width:48pt'>
        <col width=533 style='mso-width-source:userset;mso-width-alt:18944;width:400pt'>
        <col width=58 style='mso-width-source:userset;mso-width-alt:2048;width:43pt'>
        <col width=421 style='mso-width-source:userset;mso-width-alt:14961;width:316pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl153967 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl663967 width=533 style='width:400pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl673967 width=58 style='border-left:none;width:43pt'>&#1198;лгіні&#1187;
         н&#1257;мірі</td>
         <td class=xl673967 width=421 style='border-left:none;width:316pt'>Микробиологиялы&#1179;
         к&#1257;рсеткіштер</td>
         <td class=xl673967 width=64 style='border-left:none;width:48pt'>Зерттеу
         н&#1241;тижелері</td>
         <td class=xl673967 width=64 style='border-left:none;width:48pt'>Нормаланатын
         к&#1257;рсеткіш</td>
         <td class=xl683967 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дісіне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl153967 style='height:52.8pt'></td>
         <td class=xl693967 width=533 style='border-top:none;width:400pt'>(Регистрационный
         номер)</td>
         <td class=xl653967 width=58 style='border-top:none;border-left:none;
         width:43pt'>(Номер образца)</td>
         <td class=xl653967 width=421 style='border-top:none;border-left:none;
         width:316pt'>(Микробиологические показатели)</td>
         <td class=xl653967 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Результаты исследований)</td>
         <td class=xl653967 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Нормируемый показатель)</td>
         <td class=xl703967 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на метод испытания)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl153967 style='height:15.0pt'></td>
         <td class=xl713967 width=533 style='border-top:none;width:400pt'>1</td>
         <td class=xl723967 width=58 style='border-top:none;border-left:none;
         width:43pt'>2</td>
         <td class=xl723967 width=421 style='border-top:none;border-left:none;
         width:316pt'>3</td>
         <td class=xl723967 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl723967 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl733967 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="152"><col width="106"><col width="185"><col width="148"><col width="133"><col width="196"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="152" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>(Регистрационный номер)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="106" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің нөмірі<br>(Номер образца) </font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="185" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Микробиологиялық көрсеткіштер<br>(Микробиологические показатели)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="148" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижелері<br>(Результаты исследований)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="133" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормаланатын көрсеткіш<br>(Нормируемый показатель)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="196" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдісіне қолданылған НҚ<br>(НД на метод испытания)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '27': {
    version: 2,
    name: 'ПРОТОКОЛ микробиологического исследования (воздуха, почвы, лекарственных форм)',
    kazakhName: 'Микробиологиялық (ауа, топырақ дәрілік нысандар) зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне қолдалынған нормативтік құжат (НҚ)'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдісіне қолданылған НҚ'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1169 style='border-collapse:
        collapse;table-layout:fixed;width:876pt'>
        <col width=64 style='width:48pt'>
        <col width=671 style='mso-width-source:userset;mso-width-alt:23864;width:503pt'>
        <col width=64 style='width:48pt'>
        <col width=114 style='mso-width-source:userset;mso-width-alt:4039;width:85pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1524248 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl6524248 width=671 style='width:503pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl6524248 width=64 style='border-left:none;width:48pt'>&#1198;лгіні&#1187;
         атауы. &#1198;лгі алын&#1171;ан орын.</td>
         <td colspan=5 class=xl6524248 width=370 style='border-left:none;width:277pt'>Микробиологиялы&#1179;
         к&#1257;рсеткіштер</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1524248 style='height:79.2pt'></td>
         <td class=xl6524248 width=671 style='border-top:none;width:503pt'>(Регистрационный
         номер)</td>
         <td class=xl6524248 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Наименование образца. Место отбора образца)</td>
         <td colspan=5 class=xl6524248 width=370 style='border-left:none;width:277pt'>(Микробиологические
         показатели)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1524248 style='height:14.4pt'></td>
         <td class=xl6624248 width=671 style='border-top:none;width:503pt'>&nbsp;</td>
         <td class=xl6624248 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6724248 width=114 style='border-top:none;border-left:none;
         width:85pt'>&nbsp;</td>
         <td class=xl6724248 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6724248 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6724248 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6724248 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1524248 style='height:14.4pt'></td>
         <td class=xl6524248 width=671 style='border-top:none;width:503pt'>1</td>
         <td class=xl6524248 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6524248 width=114 style='border-top:none;border-left:none;
         width:85pt'>3</td>
         <td class=xl6524248 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6524248 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6524248 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6524248 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="172"><col width="180"><col width="43"><col width="34"><col width="41"><col width="37"><col width="37"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="172" height="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>(Регистрационный номер)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="180" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы. Үлгі алынған орын.<br>(Наименование образца. Место отбора образца)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="5" width="192" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Микробиологиялық көрсеткіштер<br>(Микробиологические показатели)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '28': {
    version: 2,
    name: 'ПРОТОКОЛ микробиологического исследования на носительство',
    kazakhName: 'Тасымалдаушылыққа микробиологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама саны'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне қолдалынған нормативтік құжат (НҚ)'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдісіне қолданылған НҚ'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1137 style='border-collapse:
        collapse;table-layout:fixed;width:853pt'>
        <col width=64 style='width:48pt'>
        <col width=504 style='mso-width-source:userset;mso-width-alt:17920;width:378pt'>
        <col width=63 style='mso-width-source:userset;mso-width-alt:2247;width:47pt'>
        <col width=250 style='mso-width-source:userset;mso-width-alt:8903;width:188pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1522212 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl6522212 width=504 style='width:378pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl6522212 width=63 style='border-left:none;width:47pt'>Т.А.&#1240;.
         (бол&#1171;ан жа&#1171;дайда),</td>
         <td class=xl6522212 width=250 style='border-left:none;width:188pt'>Туыл&#1171;ан
         жылы</td>
         <td class=xl6522212 width=64 style='border-left:none;width:48pt'>Ж&#1201;мыс
         орны</td>
         <td class=xl6522212 width=64 style='border-left:none;width:48pt'>Лауазымы</td>
         <td class=xl6522212 width=64 style='border-left:none;width:48pt'>Н&#1241;тиже</td>
         <td class=xl6522212 width=64 style='border-left:none;width:48pt'>Ескертпе</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
         <td height=53 class=xl1522212 style='height:39.6pt'></td>
         <td class=xl6522212 width=504 style='border-top:none;width:378pt'>(Регистрационный
         номер)</td>
         <td class=xl6522212 width=63 style='border-top:none;border-left:none;
         width:47pt'>(Ф.И.О. (при наличии))</td>
         <td class=xl6522212 width=250 style='border-top:none;border-left:none;
         width:188pt'>(Год рождения)</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Место работы)</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Должность)</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Результат)</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Примечание)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1522212 style='height:14.4pt'></td>
         <td class=xl6522212 width=504 style='border-top:none;width:378pt'>1</td>
         <td class=xl6522212 width=63 style='border-top:none;border-left:none;
         width:47pt'>2</td>
         <td class=xl6522212 width=250 style='border-top:none;border-left:none;
         width:188pt'>3</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6522212 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="145"><col width="122"><col width="113"><col width="113"><col width="107"><col width="86"><col width="111"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="145" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>(Регистрационный номер)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="122" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Т.А.Ә. (болған жағдайда), <br>(Ф.И.О. (при наличии))</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Туылған жылы <br>(Год рождения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жұмыс орны<br>(Место работы)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Лауазымы<br>(Должность)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="86" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нәтиже<br>(Результат)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="111" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескертпе<br>(Примечание)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  }
}
