import { divisionCRUD } from "../../http";
import {
  DIVISION_GET_REQUEST,
  DIVISION_GET_SUCCESS,
  DIVISION_GET_ERROR,
  DIVISION_GET_BY_KEY_REQUEST,
  DIVISION_GET_BY_KEY_SUCCESS,
  DIVISION_GET_BY_KEY_ERROR,
  DIVISION_ADD_REQUEST,
  DIVISION_ADD_SUCCESS,
  DIVISION_ADD_ERROR,
  DIVISION_EDIT_REQUEST,
  DIVISION_EDIT_SUCCESS,
  DIVISION_EDIT_ERROR,
  DIVISION_DELETE_REQUEST,
  DIVISION_DELETE_SUCCESS,
  DIVISION_DELETE_ERROR,
} from "../constants/divisionConstants";

export const getDivisions = (args) => async (dispatch) => {
  try {
    dispatch({
      type: DIVISION_GET_REQUEST,
    });

    const data = await divisionCRUD.search(args);
    dispatch({
      type: DIVISION_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: DIVISION_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDivisionByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DIVISION_GET_BY_KEY_REQUEST,
    });

    const data = await divisionCRUD.get(id);
    dispatch({
      type: DIVISION_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: DIVISION_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addDivision = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DIVISION_ADD_REQUEST,
    });

    const response = await divisionCRUD.create(data);
    await dispatch(getDivisions(args));
    dispatch({
      type: DIVISION_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: DIVISION_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editDivision = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DIVISION_EDIT_REQUEST,
    });

    await divisionCRUD.edit(data);
    await dispatch(getDivisions(args));

    dispatch({
      type: DIVISION_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DIVISION_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteDivision = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: DIVISION_DELETE_REQUEST,
    });

    await divisionCRUD.delete(id);
    await dispatch(getDivisions(args));

    dispatch({
      type: DIVISION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DIVISION_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
