import { createStore, compose, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { genericReducer } from "./reducers/genericReducer";
import { authReducer } from "./reducers/authReducer";
import { roleReducer } from "./reducers/roleReducer";
import { userReducer } from "./reducers/userReducer";
import { userRoleReducer } from "./reducers/userRoleReducer";
import { userTypeReducer } from "./reducers/userTypeReducer";
import { divisionReducer } from "./reducers/divisionReducer";
import { departmentReducer } from "./reducers/departmentReducer";
import { filialReducer } from "./reducers/filialReducer";
import { filialDivisionReducer } from "./reducers/filialDivisionReducer";
import { regionalFilialReducer } from "./reducers/regionalFilialReducer";
import { filialServiceReducer } from "./reducers/filialServiceReducer";
import { employeeReducer } from "./reducers/employeeReducer";
import { positionReducer } from "./reducers/positionReducer";
import { surObjectReducer } from "./reducers/surObjectReducer";
import { serviceKindReducer } from "./reducers/serviceKindReducer";
import { serviceGroupReducer } from "./reducers/serviceGroupReducer";
import { serviceSubGroupReducer } from "./reducers/serviceSubGroupReducer";
import { serviceExpenseReducer } from "./reducers/serviceExpenseReducer";
import { serviceTypeReducer } from "./reducers/serviceTypeReducer";
import { serviceRouteReducer } from "./reducers/serviceRouteReducer";
import { servicePriceListReducer } from "./reducers/servicePriceListReducer";
import { servicePriceListHistoryReducer } from "./reducers/servicePriceListHistoryReducer";
import { serviceReducer } from "./reducers/serviceReducer";
import { serviceMapReducer } from "./reducers/serviceMapReducer";
import { serviceCategoryReducer } from "./reducers/serviceCategoryReducer";
import { servicePackageReducer } from "./reducers/servicePackageReducer";
import { educationServiceReducer } from "./reducers/educationServiceReducer";
import { educationServiceKindReducer } from "./reducers/educationServiceKindReducer";
import { clientReducer } from "./reducers/clientReducer";
import { personReducer } from "./reducers/personReducer";
import { legalPersonReducer } from "./reducers/legalPersonReducer";
import { legalPersonTypeReducer } from "./reducers/legalPersonTypeReducer";
import { contractReducer } from "./reducers/contractReducer";
import { contractFileReducer } from "./reducers/contractFileReducer";
import { contractTypeReducer } from "./reducers/contractTypeReducer";
import { contractStateReducer } from "./reducers/contractStateReducer";
import { contractPayStateReducer } from "./reducers/contractPayStateReducer";
import { contractSpecificationReducer } from "./reducers/contractSpecificationReducer";
import { contractAdditionalAgreementReducer } from "./reducers/contractAdditionalAgreementReducer";
import { contractAdditionalAgreementStateReducer } from "./reducers/contractAdditionalAgreementStateReducer";
import { contractOperationKindReducer } from "./reducers/contractOperationKindReducer";
import { contractSignerReducer } from "./reducers/contractSignerReducer";
import { requestReducer } from "./reducers/requestReducer";
import { requestTraceReducer } from "./reducers/requestTraceReducer";
import { requestStateReducer } from "./reducers/requestStateReducer";
import { requestTypeReducer } from "./reducers/requestTypeReducer";
import { requestSectionReducer } from "./reducers/requestSectionReducer";
import { contractRequestReducer } from "./reducers/contractRequestReducer";
import { contractRequestEducationServiceReducer } from "./reducers/contractRequestEducationServiceReducer";
import { contractRequestObjectReducer } from "./reducers/contractRequestObjectReducer";
import { contractRequestAdditionalAgreementReducer } from "./reducers/contractRequestAdditionalAgreementReducer";
import { clinicalRequestReducer } from "./reducers/clinicalRequestReducer";
import { probeSelectionRequestReducer } from "./reducers/probeSelectionRequestReducer";
import { serviceWithoutActRequestReducer } from "./reducers/serviceWithoutActRequestReducer";
import { withoutDepartureRequestReducer } from "./reducers/withoutDepartureRequestReducer";
import { desinsectionRequestReducer } from "./reducers/desinsectionRequestReducer";
import { departureScheduleRequestReducer } from "./reducers/departureScheduleRequestReducer";
import { requestLockReducer } from "./reducers/requestLockReducer";
import { referralFormReducer } from "./reducers/referralFormReducer";
import { referralReducer } from "./reducers/referralReducer";
import { referralStateReducer } from "./reducers/referralStateReducer";
import { referralTraceReducer } from "./reducers/referralTraceReducer";
import { referralSpecificationReducer } from "./reducers/referralSpecificationReducer";
import { referralFileReducer } from "./reducers/referralFileReducer";
import { patientReducer } from "./reducers/patientReducer";
import { anObjectReferralReducer } from "./reducers/anObjectReferralReducer";
import { anObjectReducer } from "./reducers/anObjectReducer";
import { anObjectKindReducer } from "./reducers/anObjectKindReducer";
import { anObjectSectionReducer } from "./reducers/anObjectSectionReducer";
import { anObjectSubSectionReducer } from "./reducers/anObjectSubSectionReducer";
import { testTubeReducer } from "./reducers/testTubeReducer";
import { brigadeReducer } from "./reducers/brigadeReducer";
import { brigadeTypeReducer } from "./reducers/brigadeTypeReducer";
import { desinfectionReferenceReducer } from "./reducers/desinfectionReferenceReducer";
import { probeReducer } from "./reducers/probeReducer";
import { probeStateReducer } from "./reducers/probeStateReducer";
import { probeTraceReducer } from "./reducers/probeTraceReducer";
import { resultFormReducer } from "./reducers/resultFormReducer";
import { resultReducer } from "./reducers/resultReducer";
import { resultStateReducer } from "./reducers/resultStateReducer";
import { resultTraceReducer } from "./reducers/resultTraceReducer";
import { actFormReducer } from "./reducers/actFormReducer";
import { actReducer } from "./reducers/actReducer";
import { actStateReducer } from "./reducers/actStateReducer";
import { actTraceReducer } from "./reducers/actTraceReducer";
import { protocolFormReducer } from "./reducers/protocolFormReducer";
import { protocolReducer } from "./reducers/protocolReducer";
import { protocolFileReducer } from "./reducers/protocolFileReducer";
import { selfControlRequestReducer } from "./reducers/selfControlRequestReducer";
import { geoObjectReducer } from "./reducers/geoObjectReducer";
import { geoObjectTypeReducer } from "./reducers/geoObjectTypeReducer";
import { unitOfMeasureReducer } from "./reducers/unitOfMeasureReducer";
import { currencyReducer } from "./reducers/currencyReducer";
import { genderReducer } from "./reducers/genderReducer";
import { reagentReducer } from "./reducers/reagentReducer";
import { healthcareAttributeReducer } from "./reducers/healthcareAttributeReducer";
import { formMapReducer } from "./reducers/formMapReducer";
import { samplingPurposeReducer } from "./reducers/samplingPurposeReducer";
import { medicalOrderReducer } from "./reducers/medicalOrderReducer";
import { purposeOfSelectionReducer } from "./reducers/purposeOfSelectionReducer";
import { governmentStandardReducer } from "./reducers/governmentStandardReducer";
import { transportationConditionReducer } from "./reducers/transportationConditionReducer";
import { storageConditionReducer } from "./reducers/storageConditionReducer";
import { samplePreservationMethodReducer } from "./reducers/samplePreservationMethodReducer";
import { referralAdditionalInfoReducer } from "./reducers/referralAdditionalInfoReducer";
import { referralOrderTypeReducer } from "./reducers/referralOrderTypeReducer";
import { technicalRegulationsOfCustomsUnionReducer } from "./reducers/technicalRegulationsOfCustomsUnionReducer";
import { serviceScheduleReducer } from "./reducers/serviceScheduleReducer";
import { transportTypeReducer } from "./reducers/transportTypeReducer";
import { chemicalIndicesReducer } from "./reducers/chemicalIndicesReducer";
import { diseaseReducer } from "./reducers/diseaseReducer";
import { infectionKindReducer } from "./reducers/infectionKindReducer";
import { sicknessKindReducer } from "./reducers/sicknessKindReducer";
import {probeTypeReducer} from "./reducers/probeTypeReducer";
import { organizationFilialMapReducer } from "./reducers/organizationFilialMapReducer";
import {resultTypeReducer} from "./reducers/resultTypeReducer";
import {protocolStateReducer} from "./reducers/protocolStateReducer";

const reducer = combineReducers({
    generic: genericReducer,
    auth: authReducer,
    role: roleReducer,
    user: userReducer,
    userRole: userRoleReducer,
    userType: userTypeReducer,
    division: divisionReducer,
    department: departmentReducer,
    filial: filialReducer,
    filialDivision: filialDivisionReducer,
    regionalFilial: regionalFilialReducer,
    filialService: filialServiceReducer,
    employee: employeeReducer,
    position: positionReducer,
    surObject: surObjectReducer,
    serviceKind: serviceKindReducer,
    serviceGroup: serviceGroupReducer,
    serviceSubGroup: serviceSubGroupReducer,
    serviceExpense: serviceExpenseReducer,
    serviceType: serviceTypeReducer,
    serviceRoute: serviceRouteReducer,
    servicePriceList: servicePriceListReducer,
    servicePriceListHistory: servicePriceListHistoryReducer,
    service: serviceReducer,
    serviceMap: serviceMapReducer,
    serviceCategory: serviceCategoryReducer,
    servicePackage: servicePackageReducer,
    educationService: educationServiceReducer,
    educationServiceKind: educationServiceKindReducer,
    client: clientReducer,
    person: personReducer,
    legalPerson: legalPersonReducer,
    legalPersonType: legalPersonTypeReducer,
    contract: contractReducer,
    contractFile: contractFileReducer,
    contractType: contractTypeReducer,
    contractState: contractStateReducer,
    contractPayState: contractPayStateReducer,
    contractSpecification: contractSpecificationReducer,
    contractAdditionalAgreement: contractAdditionalAgreementReducer,
    contractAdditionalAgreementState: contractAdditionalAgreementStateReducer,
    contractOperationKind: contractOperationKindReducer,
    contractSigner: contractSignerReducer,
    request: requestReducer,
    requestTrace: requestTraceReducer,
    requestState: requestStateReducer,
    requestType: requestTypeReducer,
    probeType:probeTypeReducer,
    requestSection: requestSectionReducer,
    contractRequest: contractRequestReducer,
    contractRequestEducationService: contractRequestEducationServiceReducer,
    contractRequestObject: contractRequestObjectReducer,
    contractRequestAdditionalAgreement: contractRequestAdditionalAgreementReducer,
    clinicalRequest: clinicalRequestReducer,
    probeSelectionRequest: probeSelectionRequestReducer,
    serviceWithoutActRequest: serviceWithoutActRequestReducer,
    withoutDepartureRequest: withoutDepartureRequestReducer,
    desinsectionRequest: desinsectionRequestReducer,
    departureScheduleRequest: departureScheduleRequestReducer,
    selfControlRequest: selfControlRequestReducer,
    requestLock: requestLockReducer,
    referralForm: referralFormReducer,
    referral: referralReducer,
    referralState: referralStateReducer,
    referralTrace: referralTraceReducer,
    referralSpecification: referralSpecificationReducer,
    referralFile: referralFileReducer,
    patient: patientReducer,
    anObjectReferral: anObjectReferralReducer,
    anObject: anObjectReducer,
    anObjectKind: anObjectKindReducer,
    anObjectSection: anObjectSectionReducer,
    anObjectSubSection: anObjectSubSectionReducer,
    testTube: testTubeReducer,
    brigade: brigadeReducer,
    brigadeType: brigadeTypeReducer,
    desinfectionReference: desinfectionReferenceReducer,
    probe: probeReducer,
    probeState: probeStateReducer,
    probeTrace: probeTraceReducer,
    resultForm: resultFormReducer,
    result: resultReducer,
    resultState: resultStateReducer,
    resultTrace: resultTraceReducer,
    resultType: resultTypeReducer,
    actForm: actFormReducer,
    act: actReducer,
    actState: actStateReducer,
    actTrace: actTraceReducer,
    protocolForm: protocolFormReducer,
    protocol: protocolReducer,
    protocolState: protocolStateReducer,
    protocolFile: protocolFileReducer,
    geoObject: geoObjectReducer,
    geoObjectType: geoObjectTypeReducer,
    unitOfMeasure: unitOfMeasureReducer,
    currency: currencyReducer,
    gender: genderReducer,
    reagent: reagentReducer,
    healthcareAttribute: healthcareAttributeReducer,
    formMap: formMapReducer,
    samplingPurpose: samplingPurposeReducer,
    medicalOrder: medicalOrderReducer,
    purposeOfSelection: purposeOfSelectionReducer,
    governmentStandard: governmentStandardReducer,
    transportationCondition: transportationConditionReducer,
    storageCondition: storageConditionReducer,
    samplePreservationMethod: samplePreservationMethodReducer,
    referralAdditionalInfo: referralAdditionalInfoReducer,
    referralOrderType: referralOrderTypeReducer,
    technicalRegulationsOfCustomsUnion: technicalRegulationsOfCustomsUnionReducer,
    serviceSchedule: serviceScheduleReducer,
    transportType: transportTypeReducer,
    chemicalIndices: chemicalIndicesReducer,
    disease: diseaseReducer,
    infectionKind: infectionKindReducer,
    sicknessKind: sicknessKindReducer,
    organizationFilialMap: organizationFilialMapReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancer(applyMiddleware(thunk)));

export default store;
