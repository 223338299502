export const barcodeToPdf = () => {
    let container = document.getElementById("div-svg");
    let mySVG = document.getElementById("barcode-canvas");
    let width = '400px';
    let height = '400px';
    let printWindow = window.open('', 'PrintMap',
        'width=' + width + ',height=' + height);
    printWindow.document.writeln(container.innerHTML);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
}