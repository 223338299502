import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { KDIRequestCRUD } from "../../../../http/CRUD";

import CRUDTable from "../../CRUDTable";
import {
  contractRequestFilterFields,
  contractRequestSearchFields
} from "../../../../sections/Filters/ContractRequestFilter";
import {columnsRequest} from "../../../../sections/Columns/RequestColumns";
import {useTranslation} from "react-i18next";

export const KDIRequestCRUDTable = (props) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const searchFields = [
    ...contractRequestSearchFields,
  ];

  const filterFields = [
    ...contractRequestFilterFields
  ];

  return (
    <CRUDTable
      search={KDIRequestCRUD.search}
      columns={columnsRequest(t)}
      filterFields={filterFields}
      searchFields={searchFields}
      toolbar={false}
      onClickDetailsButton={( id ) => push(`${pathname}/${id}`)}
      onClickCreateButton={() => {}}
      {...props}
    />
  );
};
