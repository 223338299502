import React, {useEffect, useState} from "react";
import DashboardLayout from "../../../layouts/Dashboard";

import DictionaryCRUDTable from "../../../components/CRUD/Tables/DictionaryCRUDTable";

const TransportTypeList = () => {    
    return (
        <DashboardLayout>
            <DictionaryCRUDTable
                entityType="transportType"
                hasCode={true}
                hasDescription={true}
            />
        </DashboardLayout>
    )
};

export default TransportTypeList;
