export const EDUCATION_SERVICE_GET_REQUEST = 'EDUCATION_SERVICE_GET_REQUEST';
export const EDUCATION_SERVICE_GET_SUCCESS = 'EDUCATION_SERVICE_GET_SUCCESS';
export const EDUCATION_SERVICE_GET_ERROR = 'EDUCATION_SERVICE_GET_ERROR';
export const EDUCATION_SERVICE_GET_BY_KEY_REQUEST = 'EDUCATION_SERVICE_GET_BY_KEY_REQUEST';
export const EDUCATION_SERVICE_GET_BY_KEY_SUCCESS = 'EDUCATION_SERVICE_GET_BY_KEY_SUCCESS';
export const EDUCATION_SERVICE_GET_BY_KEY_ERROR = 'EDUCATION_SERVICE_GET_BY_KEY_ERROR';
export const EDUCATION_SERVICE_ADD_REQUEST = 'EDUCATION_SERVICE_ADD_REQUEST';
export const EDUCATION_SERVICE_ADD_SUCCESS = 'EDUCATION_SERVICE_ADD_SUCCESS';
export const EDUCATION_SERVICE_ADD_ERROR = 'EDUCATION_SERVICE_ADD_ERROR';
export const EDUCATION_SERVICE_EDIT_REQUEST = 'EDUCATION_SERVICE_EDIT_REQUEST';
export const EDUCATION_SERVICE_EDIT_SUCCESS = 'EDUCATION_SERVICE_EDIT_SUCCESS';
export const EDUCATION_SERVICE_EDIT_ERROR = 'EDUCATION_SERVICE_EDIT_ERROR';
export const EDUCATION_SERVICE_DELETE_REQUEST = 'EDUCATION_SERVICE_DELETE_REQUEST';
export const EDUCATION_SERVICE_DELETE_SUCCESS = 'EDUCATION_SERVICE_DELETE_SUCCESS';
export const EDUCATION_SERVICE_DELETE_ERROR = 'EDUCATION_SERVICE_DELETE_ERROR';
