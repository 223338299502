import React from 'react';
import CRUDTable from "../CRUDTable";
import {useHistory} from "react-router-dom";
import {patientsCRUD} from "../../../http/CRUD";
import {useTranslation} from "react-i18next";

export default function PatientCRUDTable({...props}) {
  const {t} = useTranslation();

  const columns = [
    {title: t("referralList.tableItem.id"), dataIndex: 'id', key: 'id'},
    {title: "ФИО", dataIndex: 'fullName', key: 'fullName'},
    {title: "ИИН", dataIndex: 'identityNumber', key: 'identityNumber'},
    {title: "Номер телефона", dataIndex: 'phoneNumber', key: 'phoneNumber'},
  ]

  const searchFields = [
    {
        title: "ФИО",
        key: "fullName",
        compareType: "like",
        operandPosition: 1,
        filterType: "normal"
    },
    {
      title: "ИИН",
      key: "identityNumber",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal"
    },
    {
      title: "Номер телефона",
      key: "phoneNumber",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal"
    },
  ];

  return <CRUDTable
    search={patientsCRUD.search}
    columns={columns}
    searchFields={searchFields}
    {...props}
  />
}