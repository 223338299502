import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingContainer from "../../../Loader/LoadingContainer";

const style = {
    position: 'absolute',
    top: '10%',
    bottom: '20%',
    left: '20%',
    right: '20%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow:'auto',
    height:'85%',
    p: 4,
};

export default function CreateEntity({ title, fields, loading, open, setOpen, clear, onSubmit }) {
    const dispatch = useDispatch();
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit();
        clear && await clear();
        await setOpen(false);
    };
    const handleClose = async () => {
        clear && await clear();
        await setOpen(false);
    };
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <LoadingContainer loading={loading}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                {fields && fields.map(_ =>
                                    <Grid item xs={12}>
                                        {_}
                                    </Grid>
                                )}
                                <Grid item xs={6}>
                                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                        {'Сохранить'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </LoadingContainer>
            </Modal>
        </div>
    )
}
