import { 
    SERVICE_SUB_GROUP_GET_REQUEST, SERVICE_SUB_GROUP_GET_SUCCESS, SERVICE_SUB_GROUP_GET_ERROR, 
    SERVICE_SUB_GROUP_GET_BY_KEY_REQUEST, SERVICE_SUB_GROUP_GET_BY_KEY_SUCCESS, SERVICE_SUB_GROUP_GET_BY_KEY_ERROR, 
    SERVICE_SUB_GROUP_ADD_REQUEST, SERVICE_SUB_GROUP_ADD_SUCCESS, SERVICE_SUB_GROUP_ADD_ERROR, 
    SERVICE_SUB_GROUP_EDIT_REQUEST, SERVICE_SUB_GROUP_EDIT_SUCCESS, SERVICE_SUB_GROUP_EDIT_ERROR, 
    SERVICE_SUB_GROUP_DELETE_REQUEST, SERVICE_SUB_GROUP_DELETE_SUCCESS, SERVICE_SUB_GROUP_DELETE_ERROR
} from "../constants/serviceSubGroupConstants";

const initialState = {
    serviceSubGroups: [],
    serviceSubGroup: {},
    loading: false,
    success: null,
    error: null,
    serviceSubGroupRequestedCount: 0,
};

export const serviceSubGroupReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_SUB_GROUP_GET_REQUEST:
            return {
                ...state,
                serviceSubGroupRequestedCount: ++state.serviceSubGroupRequestedCount,
                loading: true,
            };
        case SERVICE_SUB_GROUP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceSubGroups: action.payload,
                success: true,
            };
        case SERVICE_SUB_GROUP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SUB_GROUP_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SUB_GROUP_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceSubGroup: action.payload,
                success: true,
            };
        case SERVICE_SUB_GROUP_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SUB_GROUP_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SUB_GROUP_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_SUB_GROUP_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SUB_GROUP_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SUB_GROUP_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_SUB_GROUP_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SUB_GROUP_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SUB_GROUP_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_SUB_GROUP_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
