import React, { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiRadio from "@mui/material/Radio";

export function Radio({
  label,
  defaultValue,
  required,
  options,
  except,
  onChange,
  disabled,
    value,
  ...props
}) {
  const _options = useMemo(() => {
    const _except = (except || []).map((e) => String(e));
    return (options || []).filter(
      (opt) => !_except.includes(String(opt.value))
    );
  }, [options, except, value]);



  return (
    <FormControl component="fieldset">
      {label && (
        <FormLabel component="legend" required={required}>
          {label}
        </FormLabel>
      )}
      <RadioGroup value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        {...props}
      >
        {_options.map((opt, i) => {
          return (
            <FormControlLabel
              key={i}
              value={opt.value}
              control={<MuiRadio />}
              label={opt.label}
              disabled={disabled}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
