import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import EmployeeField from "../../../components/Relations/EmployeeField";
import { DiagnosisSelect, GeoObjectSelect, GovernmentStandardsSelect } from "../../../components/Select/domainSelects";

const ReferenceDisinsectionForm = ({ isDisabledData, data, handleChange, handleChangeData, handleChangeMulti, handleChangeSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Тегі, аты, әкесінің аты (болған жағдайда), (Фамилия, имя, отчество
          (при наличии))
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data?.fullName}
          onChange={(e) => handleChange('fullName', e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>Жасы (Возраст)</p>
        <TextField
          id="outlined-basic"
          label="Возраст"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          type="number"
          value={data?.age}
          onChange={(e) => handleChange('age', e.target.value)}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>Мекен-жайы (Адрес)</p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <GeoObjectSelect
            id="outlined-basic"
            label="Адрес"
            fullWidth
            value={data?.addressId}
            onChange={(id, object) => {
              const obj = {
                addressId: id,
                addressLine: object.name,
              };
              handleChangeMulti(obj);
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>Диагнозы (Диагноз)</p>
        <div style={{ width: "50%", margin: "0 0 0 30px" }}>
          <DiagnosisSelect
            disabled={isDisabledData}
            label={"Диагноз"}
            fullWidth
            value={data?.diagnosis?.id}
            onChange={(val, obj) => {
              handleChange('diagnosis', obj)
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Сынама алу әдiсiне нормативтік құжат (Нормативный документ на метод отбора)
        </p>
        <div style={{ width: "50%", margin: "0 0 0 30px" }}>
          <GovernmentStandardsSelect
            label={"НД на метод испытаний"}
            fullWidth
            value={data?.data?.standard?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('standard', obj, 'nd_test_method') }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Меморандумға тапсырылды (Время ознакомления)
        </p>
        <div style={{ width: "50%", margin: "0 0 0 30px" }}>
          <LocalizationProvider
            locale={ruLocale}
            dateAdapter={AdapterDateFns}>
            <DateTimePicker
              // views={['day']} 
              label="Время ознакомления"
              disabled={isDisabledData}
              value={data?.data?.time?.value}
              onChange={(value) => {
                handleChangeData('time', value, 'time')
              }}
              minDate={new Date('1910-03-01')} renderInput={(params) => <TextField style={{ margin: "0" }}   {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Пәтер иесінің тегі, аты, әкесінің аты (болған жағдайда), (Фамилия, имя, отчество (при наличии) владельца квартиры)
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data?.data?.homeOwner?.value}
          onChange={(e) => handleChangeData('homeOwner', e.target.value, 'homeOwner')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>Пәтер иесінің наразылығы жоқ, жадынамамен таныстырылды. (Владелец квартиры претензий не имеет, с памяткой ознакомлен)</p>
        <TextField
          id="outlined-basic"
          label="с памяткой ознакомлен"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          value={data?.data?.haveNoComplaints?.value}
          onChange={(e) => handleChangeData('haveNoComplaints', e.target.value, 'haveNoComplaints')}
        />
      </div>
      <EmployeeField
        style={{ marginBottom: "5px" }}
        label={"Дезинфекцию проводил"}
        value={data?.disinfectorId}
        disabled={isDisabledData}
        object={data?.disinfector} onChange={(val) => {
          handleChangeMulti({
            disinfectorId: val?.id, disinfector: val
          })
        }}
      />
    </div >
  );
};

export default ReferenceDisinsectionForm;
