import React from "react";
import DashboardLayout from "../../layouts/Dashboard";
import {ProtocolFormListCRUDTable} from "../../components/CRUD/Tables/ProtocolFormListCRUDTable";

const ProtocolFormList = () => {
    return (
        <DashboardLayout>
            <ProtocolFormListCRUDTable/>
        </DashboardLayout>
    )
};

export default ProtocolFormList;
