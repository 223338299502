import React, {useCallback} from 'react';
import CreatePatientForm from "./CreatePatientForm";
import useDialogs from "../../components/Dialog/useDialogs";

export default function usePatientForm() {
  const {DIALOG, openDialog, closeDialog} = useDialogs();

  const openCreatePatientForm = useCallback(({...props}) => {
    openDialog({
      content: <CreatePatientForm onClose={closeDialog} {...props}/>,
      onClose: closeDialog,
    })
  }, [openDialog, closeDialog]);

  return {openCreatePatientForm, DIALOG}
}
