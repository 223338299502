import { departmentCRUD } from "../../http";
import {
  DEPARTMENT_GET_REQUEST,
  DEPARTMENT_GET_SUCCESS,
  DEPARTMENT_GET_ERROR,
  DEPARTMENT_GET_BY_KEY_REQUEST,
  DEPARTMENT_GET_BY_KEY_SUCCESS,
  DEPARTMENT_GET_BY_KEY_ERROR,
  DEPARTMENT_ADD_REQUEST,
  DEPARTMENT_ADD_SUCCESS,
  DEPARTMENT_ADD_ERROR,
  DEPARTMENT_EDIT_REQUEST,
  DEPARTMENT_EDIT_SUCCESS,
  DEPARTMENT_EDIT_ERROR,
  DEPARTMENT_DELETE_REQUEST,
  DEPARTMENT_DELETE_SUCCESS,
  DEPARTMENT_DELETE_ERROR,
} from "../constants/departmentConstants";

export const getDepartments = (args) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_GET_REQUEST,
    });

    const data = await departmentCRUD.search(args);
    dispatch({
      type: DEPARTMENT_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: DEPARTMENT_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDepartmentByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_GET_BY_KEY_REQUEST,
    });

    const data = await departmentCRUD.get(id);
    dispatch({
      type: DEPARTMENT_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: DEPARTMENT_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addDepartment = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_ADD_REQUEST,
    });

    await departmentCRUD.create(data);
    await dispatch(getDepartments(args));
    dispatch({
      type: DEPARTMENT_ADD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editDepartment = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_EDIT_REQUEST,
    });

    await departmentCRUD.edit(data);
    await dispatch(getDepartments(args));
    dispatch({
      type: DEPARTMENT_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteDepartment = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: DEPARTMENT_DELETE_REQUEST,
    });

    await departmentCRUD.delete(id);
    await dispatch(getDepartments(args));

    dispatch({
      type: DEPARTMENT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DEPARTMENT_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
