import { filialCRUD } from "../../http";
import {
  FILIAL_GET_REQUEST,
  FILIAL_GET_SUCCESS,
  FILIAL_GET_ERROR,
  FILIAL_GET_BY_KEY_REQUEST,
  FILIAL_GET_BY_KEY_SUCCESS,
  FILIAL_GET_BY_KEY_ERROR,
  FILIAL_ADD_REQUEST,
  FILIAL_ADD_SUCCESS,
  FILIAL_ADD_ERROR,
  FILIAL_EDIT_REQUEST,
  FILIAL_EDIT_SUCCESS,
  FILIAL_EDIT_ERROR,
  FILIAL_DELETE_REQUEST,
  FILIAL_DELETE_SUCCESS,
  FILIAL_DELETE_ERROR,
} from "../constants/filialConstants";

export const getFilials = (args) => async (dispatch) => {
  try {
    dispatch({
      type: FILIAL_GET_REQUEST,
    });

    const data = await filialCRUD.search(args);
    dispatch({
      type: FILIAL_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: FILIAL_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getFilialByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: FILIAL_GET_BY_KEY_REQUEST,
    });

    const data = await filialCRUD.get(id);

    dispatch({
      type: FILIAL_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: FILIAL_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addFilial = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: FILIAL_ADD_REQUEST,
    });

    const response = await filialCRUD.create(data);
    await dispatch(getFilials(args));

    dispatch({
      type: FILIAL_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: FILIAL_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const editFilial = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: FILIAL_EDIT_REQUEST,
    });

    await filialCRUD.edit(data);
    await dispatch(getFilials(args));

    dispatch({
      type: FILIAL_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: FILIAL_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const deleteFilial = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: FILIAL_DELETE_REQUEST,
    });

    await filialCRUD.delete(id);
    await dispatch(getFilials(args));
    dispatch({
      type: FILIAL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: FILIAL_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
