import { 
    REFERRAL_STATE_GET_REQUEST, REFERRAL_STATE_GET_SUCCESS, REFERRAL_STATE_GET_ERROR, 
    REFERRAL_STATE_GET_BY_KEY_REQUEST, REFERRAL_STATE_GET_BY_KEY_SUCCESS, REFERRAL_STATE_GET_BY_KEY_ERROR, 
    REFERRAL_STATE_ADD_REQUEST, REFERRAL_STATE_ADD_SUCCESS, REFERRAL_STATE_ADD_ERROR, 
    REFERRAL_STATE_EDIT_REQUEST, REFERRAL_STATE_EDIT_SUCCESS, REFERRAL_STATE_EDIT_ERROR, 
    REFERRAL_STATE_DELETE_REQUEST, REFERRAL_STATE_DELETE_SUCCESS, REFERRAL_STATE_DELETE_ERROR
} from "../constants/referralStateConstants";

const initialState = {
    referralStates: [],
    referralState: {},
    loading: false,
    success: null,
    error: null,
    referralStateRequestedCount: 0,
};

export const referralStateReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REFERRAL_STATE_GET_REQUEST:
            return {
                ...state,
                referralStateRequestedCount: ++state.referralStateRequestedCount,
                loading: true,
            };
        case REFERRAL_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                referralStates: action.payload,
                success: true,
            };
        case REFERRAL_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_STATE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_STATE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                referralState: action.payload,
                success: true,
            };
        case REFERRAL_STATE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_STATE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_STATE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_STATE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_STATE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_STATE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_STATE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_STATE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_STATE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_STATE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
