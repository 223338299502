import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {
    Grid,
} from "@mui/material";
import {Collapse} from "antd";
import {formatDate} from "../functions/formatters";

import EditResult from "../screens/Result/Popups/EditResult";
import EditProtocol from "../screens/Protocol/Popups/EditProtocol";

import { resultCRUD } from "../http";
import { protocolCRUD } from "../http";

export default function EditResultAndProtocolSection({ 
    editResultOpen, 
    setEditResultOpen, 
    editProtocolOpen,
    setEditProtocolOpen,
    editResultId,
    editProtocol,
    startLoading, 
    stopLoading,
    onEditResultSubmitted,
    results
}) {
    results = results || [];

    const {t} = useTranslation();

    const editResultSubmit = async (_) => {
        await startLoading();

        await resultCRUD.edit(_);

        await stopLoading();

        _ = await resultCRUD.get(_.id);
        for (let i = 0; i < results.length; i++) {
            if (results[i].id == _.id) {
                results[i] = _;
                break;
            }
        }

        onEditResultSubmitted && onEditResultSubmitted(_);
    }

    const editProtocolSubmit = async (_) => {
        await startLoading();

        await protocolCRUD.edit(_);

        await stopLoading();
    }

    return (
        <>
            {editResultOpen && (
                <EditResult
                    idResult={editResultId}
                    action={'edit'} 
                    title={t("resultList.editText")} 
                    open={editResultOpen} 
                    setOpen={setEditResultOpen}
                    onSubmit={editResultSubmit}
                />
            )}
            {editProtocolOpen && (
                <EditProtocol 
                    action={'edit'} 
                    title={t("protocolList.editText")} 
                    open={editProtocolOpen} 
                    setOpen={setEditProtocolOpen} 
                    onSubmit={editProtocolSubmit} 
                    //loading={loading} 
                    protocol={editProtocol}/>
            )}
        </>
    )
}    