import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import CRUDTable from "../CRUDTable";
import { requestLockCRUD } from "../../../http";
import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";
import { confirmDialog } from "../../Dialog/DialogDelete";
import { formatDateAsDateTime } from "../../../functions/formatters";
import { getFilials } from "../../../redux/actions";

export default function RequestLockCRUDTable({ ...props }) {
    const { t } = useTranslation();
    const { push } = useHistory();

    const columns = [
        {
            title: t("requestLockList.tableItem.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("requestLockList.tableItem.time"),
            dataIndex: "time",
            key: "time",
            render: (a, row) => formatDateAsDateTime(row.time),
        },
        {
            title: t("requestLockList.tableItem.untilTime"),
            dataIndex: "untilTime",
            key: "untilTime",
            render: (a, row) => formatDateAsDateTime(row.untilTime),
        },
        {
            title: t("requestLockList.tableItem.isLocked"),
            dataIndex: "isLocked",
            key: "isLocked",
            render: (value, row) => value ? 'Да' : 'Нет',
        },
        {
            title: t("requestLockList.tableItem.filial"),
            dataIndex: "filial",
            key: "filial",
            render: (value, row) => value?.name,
        },
    ];

    const searchFields = [
        {
            filterType: "normal",
            title: t("requestLockList.filter.IdFilter"),
            key: "id",
            compareType: "equals",
            operandPosition: 1,
        }
    ];

    const filterFields = [        
        {
            title: "<Месяц/Дата от> с",
            key: "time",
            operandPosition: 1,
            compareType: "between",
            type: "date",
            filterType: "normal",
        },
        {
            title: "<Месяц/Дата от> по",
            key: "time",
            operandPosition: 2,
            compareType: "between",
            type: "date",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ];

    return (
        <CRUDTable
            search={requestLockCRUD.search}
            columns={columns}
            filterFields={filterFields}
            searchFields={searchFields}
            {...props}
        />
    )
}

