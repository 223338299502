import { DatePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Modal, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useDialogs from "../../../components/Dialog/useDialogs";
import EmployeeField from "../../../components/Relations/EmployeeField";
import { DepartmentSelect, DivisionSelect, FilialDivisionSelect, FilialSelect } from "../../../components/Select/domainSelects";
import { style } from "../../../components/TableMUIStyle";
import { checkRoles } from "../../../functions/checkRoles";
import useLoader from "../../../hooks/useLoader";
import { actCRUD } from "../../../http";
import { getActForms } from "../../../redux/actions/actFormActions";
import DirectionBloodForm from "../DirectionBlood/DirectionBloodForm";
import DirectionCholeraForm from "../DirectionCholera/DirectionCholeraForm";
import DirectionMaterialForm from "../DirectionMaterial/DirectionMaterialForm";
import DirectionMicrobioForm from "../DirectionMicrobio/DirectionMicrobioForm";
import DirectionParasiteForm from "../DirectionParasite/DirectionParasiteForm";
import DirectionPeopleForm from "../DirectionPeople/DirectionPeopleForm";
import DirectionSamplingForm from "../DirectionSampling/DirectionSamplingForm";
import DirectionVirusForm from "../DirectionVirus/DirectionVirusForm";

const CreateDirection = ({ open, onClose, ddd, title, onSubmit }) => {
  // часть функционала будет использоваться от актов, т.к. сохраняются направления на эндпоинте актов, и имеют схожий функционал

  const directionForms = [
    { id: 0, number: '015', directionName: 'DirectionBlood', kazakhName: 'Қанды вирустық гепатиттердің маркерлеріне зерттеуге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ на исследование крови на маркеры вирусных гепатитов' },
    { id: 1, number: '021', directionName: 'DirectionCholera', kazakhName: 'Сынаманы тырысқақ қоздырғышына тексерудің жолдамасы және зерттеу нәтижесі', name: 'Направление и результат исследования материала на наличие возбудителя холеры' },
    { id: 2, number: '017', directionName: 'DirectionMaterial', kazakhName: 'Қызылша мен қызамыққа материалды (зерттелушіден, ауру адамнан) зерттеуге жолдама', name: 'Направление материала (от обследуемого, больного) для исследования на корь, краснуху' },
    { id: 3, number: '019', directionName: 'DirectionMicrobio', kazakhName: 'Адамдардан іріктелген сынамаларды микробиологиялық тексеруге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ материала от людей на микробиологические исследования' },
    { id: 4, number: '018', directionName: 'DirectionParasite', kazakhName: 'Санитариялық-паразитологиялық зерттеуге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ на санитарно-паразитологическое исследование' },
    { id: 5, number: '020', directionName: 'DirectionPeople', kazakhName: 'Адамдардан іріктелген сынамаларды ______________ тексеруге ЖОЛДАМА', name: 'НАПРАВЛЕНИЕ материала от людей на __________ исследования' },
    { id: 6, number: '016', directionName: 'DirectionSampling', kazakhName: 'Сынама үлгілерін вирусологиялық зерттеулерге (инфекция түрін көрсетініз)', name: 'НАПРАВЛЕНИЕ на исследование образцов проб на вирусологическое исследование' },
    { id: 7, number: '013', directionName: 'DirectionVirus', kazakhName: 'Вирусологиялық зерттеуге жіберу ЖОЛДАМАСЫ', name: 'НАПРАВЛЕНИЕ на вирусологические исследование' },
  ]

  const { alert, DIALOG } = useDialogs();
  const { t } = useTranslation();
  const actForm = useSelector(state => state.actForm)
  const [data, setData] = useState({});
  const { start: startSubmit, stop: stopSubmit, loading: loadingSubmit } = useLoader(false);
  const stateCode = data.state?.code;

  const [directionForm, setDirectionForm] = useState()

  const handleChange = useCallback((key, value) => {
    setData({ ...data, [key]: value })
  }, [data, directionForm]);
  const handleChangeMulti = useCallback((obj) => {
    setData({ ...data, ...obj })
  }, [data, directionForm]);

  const handleChangeData = useCallback((key, value, key2) => {
    setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
  }, [data, directionForm]);

  const handleChangeSample = useCallback((key, value, i, key2) => {
    const array = data.data?.samples?.value.slice(0)
    array[i][key] = { 'key': key2, value }
    setData({
      ...data, data: {
        ...data.data,
        samples: { key: 'samples', value: array }
      }
    })
  }, [data, directionForm]);

  const addSample = useCallback(() => {
    data.data?.samples?.value
      ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, {}] } } })
      : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [{}] } } })
  }, [data, directionForm]);

  const deleteSample = useCallback((index) => {
    setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i) } } })
  }, [data]);

  const dispatch = useDispatch();

  const schema = {}

  const submit = useCallback(() => new Promise((resolve, reject) => {
    
    if (!data.filialId) {
      alert('Филиал нужно заполнить!');
      return
    }
    const actFormObj = actForm.actForms.find(a => {
      return a.number === directionForm.directionName
    })
    const newData = { ...data }
    delete newData.responsible
    delete newData.responsibleForProbes
    delete newData.performer
    startSubmit();
      actCRUD.create({
        createdAt: new Date(),
        number: directionForm.directionName,
        schema,
        ...newData,
        form: actFormObj,
        formId: actFormObj.id
      }).then((response) => {
        onSubmit(response.id)
        onClose()
        resolve();
      })
      .catch((error) => {
        reject();
        alert(error);
      })
      .finally(stopSubmit)
  }), [data, fetch, actForm, onSubmit, dispatch, stopSubmit, startSubmit, onClose, directionForm])

  useEffect(() => {
    if (actForm.actForms.length === 0) {
      dispatch(getActForms({ paging: { skip: 0, take: 25 } }))
    }
  }, [actForm.actForms.length])

  const [isDisabledData, setIsDisabledData] = useState(false)
  const [isDisabledPerformer, setIsDisabledPerformer] = useState(false)
  useEffect(() => {
    const returnIsDisabledData = () => {
      return (!checkRoles('SuperAdministrator', 'Administrator', 'Specialist'))
    }
    const returnIsDisabledPerformer = () => {
      return ((!checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') || stateCode === undefined))
    }
    setIsDisabledData(returnIsDisabledData())
    setIsDisabledPerformer(returnIsDisabledPerformer())
  }, [stateCode])
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <DIALOG />
        <Typography variant="h5" style={{ fontWeight: '500' }} gutterBottom>{title}</Typography>
        <Typography variant="subtitle2">Наряд №: {ddd?.number}</Typography>
        <div>
          <TextField label="Направление" value={directionForm?.id} onChange={(e) => {
            setDirectionForm({...directionForms[e.target.value]})
          }
          } margin="normal" fullWidth
            id="directionFormId" select>
            {directionForms.map((direction, i) => (
              <MenuItem key={i} value={i}>
                {direction.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {directionForm && <Box component="form" onSubmit={submit}>
          {/* <Paper style={{ marginBottom: '15px' }}>
            <Toolbar>
              <Typography style={{ marginRight: "auto" }} variant={"h6"}>Статус направления: создаётся</Typography>
            </Toolbar>
          </Paper> */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody className="border-table-word">
                  <TableRow>
                    <TableCell className="border-table-word"></TableCell>
                    <TableCell
                      className="border-table-word"
                      align="left"
                    >
                      <p>Нысанның БҚСЖ бойынша коды</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ width: "50%" }}>Код формы по ОКУД</p>
                        <TextField
                          id="outlined-basic"
                          label="Код"
                          style={{ width: "50%" }}
                          variant="outlined"
                        />
                      </div>
                      <p>КҰЖЖ бойынша ұйым коды</p>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p style={{ width: "50%" }}>Код организации по ОКПО</p>
                        <TextField
                          id="outlined-basic"
                          label="Код"
                          style={{ width: "50%" }}
                          variant="outlined"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="border-table-word">
                      <p>
                        Қазақстан Республикасының Денсаулық сақтау министрлігі
                        Министерство здравоохранения Республики Казахстан
                      </p>
                    </TableCell>
                    <TableCell
                      className="border-table-word"
                      align="left"
                    >
                      <p>
                        Қазақстан Республикасының Денсаулық сақтау министрінің
                        2021 жылғы "20" 08 № 84 бұйрығымен бекітілген № {directionForm.number}/е
                        нысанды медициналық құжаттама
                      </p>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="border-table-word">
                      <p>
                        Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк
                        органының атауы Наименование государственного органа
                        санитарно-эпидемиологической службы
                      </p>
                    </TableCell>
                    <TableCell
                      className="border-table-word"
                      align="left"
                    >
                      <p>
                        Медицинская документация Форма № {directionForm.number}/у Утверждена
                        приказом Министра здравоохранения Республики Казахстан
                        от "20" 08 2021 года №84
                      </p>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div style={{ margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h5 align='center'>
              {directionForm?.kazakhName}
            </h5>
            <h5 align='center'>
              {directionForm?.name} (от)
            </h5>
            <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
              <DatePicker views={['day']} label="от"
                disabled={isDisabledData}
                value={data.data?.dateOfDirection?.value}
                onChange={(value) => {
                  handleChangeData('dateOfDirection', value, 'dateOfDirection')
                }}
                minDate={new Date('1910-03-01')} renderInput={(params) =>
                  <TextField margin="normal"  {...params} helperText={null} />
                } />
            </LocalizationProvider>
          </div>
          {directionForm?.directionName === 'DirectionBlood' && <DirectionBloodForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionCholera' && <DirectionCholeraForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionMaterial' && <DirectionMaterialForm addSample={addSample} deleteSample={deleteSample} isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionMicrobio' && <DirectionMicrobioForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionParasite' && <DirectionParasiteForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionPeople' && <DirectionPeopleForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionSampling' && <DirectionSamplingForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          {directionForm?.directionName === 'DirectionVirus' && <DirectionVirusForm isDisabledData={isDisabledData} data={data} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />}
          <EmployeeField
            style={{ marginBottom: "5px" }}
            label={"Ответственный за отбор проб"}
            value={data.responsibleForProbesId}
            disabled={isDisabledData}
            object={data.responsibleForProbes} onChange={(val) => {
              setData({ ...data, responsibleForProbesId: val?.id, responsibleForProbes: val })
            }}
          />
          <FilialSelect
            style={{ marginBottom: "5px" }}
            label={"Филиал"}
            fullWidth
            disabled={isDisabledData}
            value={data.filialId}
            filialId={JSON.parse(localStorage.employee)?.filialId === 1 ? null : JSON.parse(localStorage.employee)?.filialId}
            onChange={(val) => {
              handleChangeMulti({
                filialId: val,
                filialDivisionId: Number(val) !== Number(data.filialId) ? null : data.filialDivisionId
              })
            }}
          />
          <FilialDivisionSelect
            style={{ marginBottom: "5px" }}
            label={"Районное отделение"}
            fullWidth
            disabled={isDisabledData}
            value={data.filialDivisionId}
            filialId={data.filialId === 1 ? null : data.filialId}
            onChange={(filialDivisionId, filialDivision) => {
              handleChangeMulti({
                filialDivisionId: filialDivisionId,
                filialId: Number(filialDivision.filialId) !== Number(data.filialId) ? filialDivision.filialId : data.filialId
              })
            }}
          />
          <Typography gutterBottom variant={'h5'}>Передача проб в лабораторию:</Typography>
          <DepartmentSelect
            disabled={isDisabledData}
            style={{ marginBottom: "5px" }}
            label={"Лаборатория"}
            fullWidth
            value={data.departmentId}
            onChange={(val) => handleChange('departmentId', val)}
          />
          <DivisionSelect
            style={{ marginBottom: "5px" }}
            label={"Отдел"}
            fullWidth
            value={data.divisionId}
            disabled={isDisabledData}
            onChange={(val) => handleChange('divisionId', val)}
            filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
          />
          <EmployeeField
            label={"Заведующий лаборатории/Начальник отдела"}
            fullWidth
            extraFilter={data.departmentId > 0 ? { departmentId: { operand1: data.departmentId, operator: "equals" } } : null}
            disabled={isDisabledData}
            object={data.responsible}
            value={data.responsibleId}
            onChange={(responsible) => {
              const obj = {
                responsible,
                responsibleId: responsible.id,
                departmentId: responsible.departmentId,
              };
              handleChangeMulti(obj);
            }}
          />
          <EmployeeField
            label={"Лаборант"} fullWidth
            object={data.performer}
            value={data.performerId}
            disabled={isDisabledPerformer}
            onChange={(performer) => {
              const obj = {
                performer,
                performerId: performer.id,
              };
              handleChangeMulti(obj);
            }}
          />
          <LoadingButton style={{
            border: "1px solid black",
            padding: "5px 10px",
            borderRadius: "6px",
            marginRight: '15px'
          }}
            variant="contained"
            onClick={submit}
            loading={loadingSubmit}>
            {"Создать"}
          </LoadingButton>
        </Box>}
      </Box >
    </Modal>
  );
};

export default CreateDirection;
