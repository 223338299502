import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import {Button, Checkbox, CircularProgress, Grid} from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {DIVISION_DETAIL_ROUTE, FORM_MAP_LIST_ROUTE} from "../../../routes";
import {departmentCRUD, divisionCRUD} from "../../../http";
import TextField from "@mui/material/TextField";
import {DepartmentSelect} from "../../../components/Select/domainSelects";

const DivisionDetailForm = () => {
    const history = useHistory()
    const {id} = useParams();
    const [isNew,setIsNew] = useState(id==="new")
    const dispatch = useDispatch()
    const [fields, setFields] = useState({})
    const {loading, start, stop} = useLoader(false)
    const fetch = useCallback(() => {
        start()
        if(!isNew){
            divisionCRUD.get(id).then(data => {
                setFields(state=>{
                    return {
                        ...state,
                        name:data.name || null,
                        description:data.description || null,
                        departmentId:data.departmentId || null
                    }

                })
                stop()
            })
        }else{
            stop()
        }

    }, [dispatch, id])

    useEffect(fetch, [fetch])
    const submit = useCallback(() => {
        if (!isNew){
            divisionCRUD.edit({
                id:id,
                ...fields
            }).catch(err=>{
                alert(err)
            })
        }else{
            divisionCRUD.create({
                ...fields
            }).catch(err=>{
                alert(err)
            }).then((resp)=>{setIsNew(false);history.push(DIVISION_DETAIL_ROUTE(resp.id))})
        }


    }, [id, dispatch, fields])
    const deleteForm = useCallback(()=>{
        divisionCRUD.delete(id).then(()=>{
            history.push(FORM_MAP_LIST_ROUTE)
        }).catch(err=>{
            alert(err)
        })
    },[id])
    return (
        <DashboardLayout>
            {loading ? <CircularProgress/> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar style={{display:"flex", justifyContent:"space-between"}}>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew?"Создание":"Редактирование"} Отделения<br/>
                            </Typography>
                            {!isNew &&
                                <Button onClick={()=>{deleteForm()}} style={{background:"#ff4343"}}>
                                    Удалить
                                </Button>}
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{paddingTop: 16}}>
                    <Paper style={{paddingBottom: 20}}>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12}>
                                <TextField required margin="normal"
                                           fullWidth id="personnelNumber" name="personnelNumber" label="Название"
                                           onChange={(ev) => {
                                               setFields((state) => {
                                                   return {
                                                       ...state,
                                                       name: ev.target.value
                                                   }
                                               })
                                           }}
                                           value={fields.name || ""}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required margin="normal"
                                           fullWidth id="personnelNumber" name="personnelNumber" label="Описание"
                                           onChange={(ev) => {
                                               setFields((state) => {
                                                   return {
                                                       ...state,
                                                       description: ev.target.value
                                                   }
                                               })
                                           }}
                                           value={fields.description || ""}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DepartmentSelect
                                    label={"Лаборатория"}
                                    fullWidth
                                    value={fields.departmentId || ""}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                departmentId: val
                                            }
                                        })
                                    }}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Checkbox
                                    id="isDeleted"
                                    name="isDeleted"
                                    style={{background: "#F8F8F8", borderRadius: "15px"}}                                    
                                    checked={fields.isDeleted}                                    
                                    onChange={(e) => {
                                        setFields((state) => {                                            
                                            return {
                                                ...state,
                                                isDeleted: e.target.checked
                                            }
                                        })
                                    }}                                    
                                    >                                    
                                </Checkbox>
                                <span>Удален</span>
                            </Grid>
                        </Grid>


                    </Paper>
                </Grid>
                <LoadingButton style={{marginTop: "10px"}} variant="contained" onClick={() => {
                    submit()
                }}>
                    Сохранить
                </LoadingButton>
            </>}


        </DashboardLayout>
    )
}

export default DivisionDetailForm