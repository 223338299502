export const SAMPLE_PRESERVATION_METHOD_GET_REQUEST = 'SAMPLE_PRESERVATION_METHOD_GET_REQUEST';
export const SAMPLE_PRESERVATION_METHOD_GET_SUCCESS = 'SAMPLE_PRESERVATION_METHOD_GET_SUCCESS';
export const SAMPLE_PRESERVATION_METHOD_GET_ERROR = 'SAMPLE_PRESERVATION_METHOD_GET_ERROR';
export const SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_REQUEST = 'SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_REQUEST';
export const SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_SUCCESS = 'SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_SUCCESS';
export const SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_ERROR = 'SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_ERROR';
export const SAMPLE_PRESERVATION_METHOD_ADD_REQUEST = 'SAMPLE_PRESERVATION_METHOD_ADD_REQUEST';
export const SAMPLE_PRESERVATION_METHOD_ADD_SUCCESS = 'SAMPLE_PRESERVATION_METHOD_ADD_SUCCESS';
export const SAMPLE_PRESERVATION_METHOD_ADD_ERROR = 'SAMPLE_PRESERVATION_METHOD_ADD_ERROR';
export const SAMPLE_PRESERVATION_METHOD_EDIT_REQUEST = 'SAMPLE_PRESERVATION_METHOD_EDIT_REQUEST';
export const SAMPLE_PRESERVATION_METHOD_EDIT_SUCCESS = 'SAMPLE_PRESERVATION_METHOD_EDIT_SUCCESS';
export const SAMPLE_PRESERVATION_METHOD_EDIT_ERROR = 'SAMPLE_PRESERVATION_METHOD_EDIT_ERROR';
export const SAMPLE_PRESERVATION_METHOD_DELETE_REQUEST = 'SAMPLE_PRESERVATION_METHOD_DELETE_REQUEST';
export const SAMPLE_PRESERVATION_METHOD_DELETE_SUCCESS = 'SAMPLE_PRESERVATION_METHOD_DELETE_SUCCESS';
export const SAMPLE_PRESERVATION_METHOD_DELETE_ERROR = 'SAMPLE_PRESERVATION_METHOD_DELETE_ERROR';
