import { DDDRequestCRUD } from "../../http";
import {
  DESINSECTION_REQUEST_GET_REQUEST,
  DESINSECTION_REQUEST_GET_SUCCESS,
  DESINSECTION_REQUEST_GET_ERROR,
  DESINSECTION_REQUEST_GET_BY_KEY_REQUEST,
  DESINSECTION_REQUEST_GET_BY_KEY_SUCCESS,
  DESINSECTION_REQUEST_GET_BY_KEY_ERROR,
  DESINSECTION_REQUEST_ADD_REQUEST,
  DESINSECTION_REQUEST_ADD_SUCCESS,
  DESINSECTION_REQUEST_ADD_ERROR,
  DESINSECTION_REQUEST_EDIT_REQUEST,
  DESINSECTION_REQUEST_EDIT_SUCCESS,
  DESINSECTION_REQUEST_EDIT_ERROR,
  DESINSECTION_REQUEST_DELETE_REQUEST,
  DESINSECTION_REQUEST_DELETE_SUCCESS,
  DESINSECTION_REQUEST_DELETE_ERROR,
  CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST,
  CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/desinsectionRequestConstants";

export const getDesinsectionRequests = (args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINSECTION_REQUEST_GET_REQUEST,
    });

    const data = await DDDRequestCRUD.search(args);

    dispatch({
      type: DESINSECTION_REQUEST_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: DESINSECTION_REQUEST_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDesinsectionRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DESINSECTION_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await DDDRequestCRUD.get(id);

    dispatch({
      type: DESINSECTION_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: DESINSECTION_REQUEST_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addDesinsectionRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINSECTION_REQUEST_ADD_REQUEST,
    });

    const response = await DDDRequestCRUD.create(data);

    console.log({ response });

    await dispatch(getDesinsectionRequests(args));

    dispatch({
      type: DESINSECTION_REQUEST_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: DESINSECTION_REQUEST_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editDesinsectionRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINSECTION_REQUEST_EDIT_REQUEST,
    });

    await DDDRequestCRUD.edit(data);
    dispatch(getDesinsectionRequests(args));
    dispatch({
      type: DESINSECTION_REQUEST_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DESINSECTION_REQUEST_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteDesinsectionRequest = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINSECTION_REQUEST_DELETE_REQUEST,
    });

    await DDDRequestCRUD.delete(id);
    await dispatch(getDesinsectionRequests(args));

    dispatch({
      type: DESINSECTION_REQUEST_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DESINSECTION_REQUEST_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const workflowDesinsectionRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await DDDRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      await dispatch({ type: CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
