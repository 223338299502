import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import useCreateObjectForm from "../../models/Object/useCreateObjectForm";
import AnObjectCRUDTable from "../CRUD/Tables/AnObjectCRUDTable";
import EmployeeCRUDTable from "../CRUD/Tables/EmployeeCRUDTable";
import SimpleTable from "../SimpleTable";

export default function ObjectField({
  departmentId,
  label,
  value,
  object,
  onChange,
  extraFilter,
  disabled
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid container>
        {/* Field Line */}
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
            <Typography style={{ width: "50%" }}>
              {label || "Объект"}:{" "}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '30px', width: '50%' }}>
              <Typography style={{ marginRight: '30px' }}>
                {object?.name || 'Не выбран'}
              </Typography>
              <Button disabled={disabled} onClick={() => setOpen(true)}>Выбрать</Button>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* Dialog Select*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"80%"}
      >
        <DialogTitle>Выбор объекта</DialogTitle>
        <Divider />
        <DialogContent>
          <AnObjectCRUDTable
            extraFilter={extraFilter}
            onClickCreateButton={undefined} // что бы не показывать кнопку создания
            onRowClick={(record, rowIndex) => {
              onChange(record);
              setOpen(false);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"text"}
            style={{ marginLeft: "auto" }}
            onClick={() => setOpen(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}