import { 
    SERVICE_GROUP_GET_REQUEST, SERVICE_GROUP_GET_SUCCESS, SERVICE_GROUP_GET_ERROR, 
    SERVICE_GROUP_GET_BY_KEY_REQUEST, SERVICE_GROUP_GET_BY_KEY_SUCCESS, SERVICE_GROUP_GET_BY_KEY_ERROR, 
    SERVICE_GROUP_ADD_REQUEST, SERVICE_GROUP_ADD_SUCCESS, SERVICE_GROUP_ADD_ERROR, 
    SERVICE_GROUP_EDIT_REQUEST, SERVICE_GROUP_EDIT_SUCCESS, SERVICE_GROUP_EDIT_ERROR, 
    SERVICE_GROUP_DELETE_REQUEST, SERVICE_GROUP_DELETE_SUCCESS, SERVICE_GROUP_DELETE_ERROR
} from "../constants/serviceGroupConstants";

const initialState = {
    serviceGroups: [],
    serviceGroup: {},
    loading: false,
    success: null,
    error: null,
    serviceGroupRequestedCount: 0,
};

export const serviceGroupReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_GROUP_GET_REQUEST:
            return {
                ...state,
                serviceGroupRequestedCount: ++state.serviceGroupRequestedCount,
                loading: true,
            };
        case SERVICE_GROUP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceGroups: action.payload,
                success: true,
            };
        case SERVICE_GROUP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_GROUP_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GROUP_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceGroup: action.payload,
                success: true,
            };
        case SERVICE_GROUP_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_GROUP_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GROUP_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_GROUP_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_GROUP_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GROUP_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_GROUP_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_GROUP_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GROUP_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_GROUP_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
