export const SERVICE_MAP_GET_REQUEST = 'SERVICE_MAP_GET_REQUEST';
export const SERVICE_MAP_GET_SUCCESS = 'SERVICE_MAP_GET_SUCCESS';
export const SERVICE_MAP_GET_ERROR = 'SERVICE_MAP_GET_ERROR';
export const SERVICE_MAP_GET_BY_KEY_REQUEST = 'SERVICE_MAP_GET_BY_KEY_REQUEST';
export const SERVICE_MAP_GET_BY_KEY_SUCCESS = 'SERVICE_MAP_GET_BY_KEY_SUCCESS';
export const SERVICE_MAP_GET_BY_KEY_ERROR = 'SERVICE_MAP_GET_BY_KEY_ERROR';
export const SERVICE_MAP_ADD_REQUEST = 'SERVICE_MAP_ADD_REQUEST';
export const SERVICE_MAP_ADD_SUCCESS = 'SERVICE_MAP_ADD_SUCCESS';
export const SERVICE_MAP_ADD_ERROR = 'SERVICE_MAP_ADD_ERROR';
export const SERVICE_MAP_EDIT_REQUEST = 'SERVICE_MAP_EDIT_REQUEST';
export const SERVICE_MAP_EDIT_SUCCESS = 'SERVICE_MAP_EDIT_SUCCESS';
export const SERVICE_MAP_EDIT_ERROR = 'SERVICE_MAP_EDIT_ERROR';
export const SERVICE_MAP_DELETE_REQUEST = 'SERVICE_MAP_DELETE_REQUEST';
export const SERVICE_MAP_DELETE_SUCCESS = 'SERVICE_MAP_DELETE_SUCCESS';
export const SERVICE_MAP_DELETE_ERROR = 'SERVICE_MAP_DELETE_ERROR';
