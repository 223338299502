import { 
    DEPARTMENT_GET_REQUEST, DEPARTMENT_GET_SUCCESS, DEPARTMENT_GET_ERROR, 
    DEPARTMENT_GET_BY_KEY_REQUEST, DEPARTMENT_GET_BY_KEY_SUCCESS, DEPARTMENT_GET_BY_KEY_ERROR, 
    DEPARTMENT_ADD_REQUEST, DEPARTMENT_ADD_SUCCESS, DEPARTMENT_ADD_ERROR, 
    DEPARTMENT_EDIT_REQUEST, DEPARTMENT_EDIT_SUCCESS, DEPARTMENT_EDIT_ERROR, 
    DEPARTMENT_DELETE_REQUEST, DEPARTMENT_DELETE_SUCCESS, DEPARTMENT_DELETE_ERROR
} from "../constants/departmentConstants";

const initialState = {
    departments: [],
    department: {},
    loading: false,
    success: null,
    error: null,
    departmentRequestedCount: 0,
};

export const departmentReducer = (state = initialState, action) => {
    switch (action.type)     {
        case DEPARTMENT_GET_REQUEST:
            return {
                ...state,
                departmentRequestedCount: ++state.departmentRequestedCount,
                loading: true,
            };
        case DEPARTMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                departments: action.payload,
                success: true,
            };
        case DEPARTMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEPARTMENT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEPARTMENT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                department: action.payload,
                success: true,
            };
        case DEPARTMENT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEPARTMENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEPARTMENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DEPARTMENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEPARTMENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEPARTMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DEPARTMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DEPARTMENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DEPARTMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DEPARTMENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
