
import React from "react";
import DashboardLayout from "../../layouts/Dashboard";
import RequestCRUDTable from "../../components/CRUD/Tables/RequestCRUDTable";


let rows = [];
const RequestList = () => {
    return (
        <DashboardLayout>
            <RequestCRUDTable />
        </DashboardLayout>
    )

};

export default RequestList;
