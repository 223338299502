export const DIVISION_GET_REQUEST = 'DIVISION_GET_REQUEST';
export const DIVISION_GET_SUCCESS = 'DIVISION_GET_SUCCESS';
export const DIVISION_GET_ERROR = 'DIVISION_GET_ERROR';
export const DIVISION_GET_BY_KEY_REQUEST = 'DIVISION_GET_BY_KEY_REQUEST';
export const DIVISION_GET_BY_KEY_SUCCESS = 'DIVISION_GET_BY_KEY_SUCCESS';
export const DIVISION_GET_BY_KEY_ERROR = 'DIVISION_GET_BY_KEY_ERROR';
export const DIVISION_ADD_REQUEST = 'DIVISION_ADD_REQUEST';
export const DIVISION_ADD_SUCCESS = 'DIVISION_ADD_SUCCESS';
export const DIVISION_ADD_ERROR = 'DIVISION_ADD_ERROR';
export const DIVISION_EDIT_REQUEST = 'DIVISION_EDIT_REQUEST';
export const DIVISION_EDIT_SUCCESS = 'DIVISION_EDIT_SUCCESS';
export const DIVISION_EDIT_ERROR = 'DIVISION_EDIT_ERROR';
export const DIVISION_DELETE_REQUEST = 'DIVISION_DELETE_REQUEST';
export const DIVISION_DELETE_SUCCESS = 'DIVISION_DELETE_SUCCESS';
export const DIVISION_DELETE_ERROR = 'DIVISION_DELETE_ERROR';
