import {Box, Button, Dialog, DialogContent, Divider, IconButton} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import GovernmentCRUDTable from "../CRUD/Tables/GovernmentCRUDTable";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {useController, useFieldArray, useFormContext} from "react-hook-form";
import TechnicalRegulationsOfCustomsUnionCRUDTable from "../CRUD/Tables/TechnicalRegulationsOfCustomsUnion";

export default function RHFTechnicalRegulations({
                                          extraFilter,
                                          disabled,
                                      }) {
    const [open, setOpen] = useState(false);

    const {control} = useFormContext();
    const {fieldState: {error}} = useController({name: 'regulationOfCUs'});

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'regulationOfCUs',
    });

    function deleteHandler(id) {
        const index = fields.findIndex(el => el.id === id)
        remove(index);
    }


    return (
        <>
            <Grid container spacing={1}>
                {/* Field Line */}
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Typography style={{fontWeight: "bold"}}>
                            {fields?.length ? fields?.map((el, idx) => {
                                return (<React.Fragment key={idx}><span> {el.name}</span>
                                    <IconButton disabled={disabled}
                                                onClick={() => deleteHandler(el.id)}
                                                size="small"
                                                sx={{margin: '0 3px'}}
                                                children={
                                                    <RemoveCircleIcon/>}/></React.Fragment>)
                            }) : null}
                        </Typography>
                        <Button disabled={disabled} onClick={() => setOpen(true)} variant={"outlined"}
                                style={{marginLeft: "10px"}}>Выбрать</Button>
                    </Box>
                </Grid>
            </Grid>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор ТР/ТС</DialogTitle>
                <Divider/>
                <DialogContent>
                    <TechnicalRegulationsOfCustomsUnionCRUDTable
                        extraFilter={extraFilter}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            let isHas
                            if (fields?.length) {
                                fields?.forEach(el => {
                                    if (el.id == record.id) {
                                        isHas = true
                                    }
                                })
                                if (!isHas) {
                                    append(record)
                                }
                            } else {
                                append(record);
                            }
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{marginLeft: "auto"}}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
