import React from "react";
import { Box } from "@mui/system";

export const Form = (props) => {
  const { children, onSubmit } = props;

  return (
    <Box component="form" onSubmit={onSubmit} {...props}>
      {children}
    </Box>
  );
};

Form.defaultProps = {
  onSubmit: (e) => {
    e.preventDefault();
  },
};
