/* eslint-disable import/no-anonymous-default-export */
export default {
  '62': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности зоны',
    kazakhName: 'Аймақтың радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'sample_selection_date',
        type: 'text',
        label: 'Время отбора образца',
        kazakhLabel: 'Үлгілердің алыну уақыты'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемі'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором',
        kazakhLabel: 'Зерттеу жүргізілген құрал'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Сәйкестігі туралы куәлік'
      },
      {
        id: 'research_of_samples_to_nd_compliance',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'research_of_samples_to_nd_compliance_2',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=886 style='border-collapse:
        collapse;table-layout:fixed;width:664pt'>
        <col width=64 style='width:48pt'>
        <col width=266 style='mso-width-source:userset;mso-width-alt:9443;width:199pt'>
        <col width=64 style='width:48pt'>
        <col width=236 style='mso-width-source:userset;mso-width-alt:8391;width:177pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=194 style='height:145.2pt'>
         <td height=194 class=xl15316 width=64 style='height:145.2pt;width:48pt'></td>
         <td rowspan=2 class=xl70316 width=266 style='width:199pt'>№ п/п</td>
         <td class=xl69316 width=64 style='border-left:none;width:48pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl69316 width=236 style='border-left:none;width:177pt'>Радионуклидттерді&#1187;
         меншікті белсенділігіні&#1187; м&#1241;ні (Ак&#1199;л) Бк/кг</td>
         <td class=xl69316 width=64 style='border-left:none;width:48pt'>Радионуклидттерді&#1187;
         меншікті белсенділігіні&#1187; р&#1201;&#1179;сат етілген де&#1187;гейі
         (Ак&#1199;л) Бк/кг</td>
         <td class=xl69316 width=64 style='border-left:none;width:48pt'>К&#1199;лді&#1187;
         радиациялы&#1179;&nbsp;&#1179;ауіптілік сыныптары</td>
         <td class=xl69316 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl63316 width=64 style='border-left:none;width:48pt'>К&#1199;лді&#1187;
         пайдалану шарттары</td>
        </tr>
        <tr height=176 style='height:132.0pt'>
         <td height=176 class=xl15316 style='height:132.0pt'></td>
         <td class=xl68316 width=64 style='border-top:none;border-left:none;
         width:48pt'>Наименование образца</td>
         <td class=xl68316 width=236 style='border-top:none;border-left:none;
         width:177pt'>Значения удельной активности радионуклидов (Азола) Бк/кг</td>
         <td class=xl68316 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень удельной активности радионуклидов (Азола)
         Бк/кг</td>
         <td class=xl68316 width=64 style='border-top:none;border-left:none;
         width:48pt'>Класс радиационной опасности золы</td>
         <td class=xl68316 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl64316 width=64 style='border-top:none;border-left:none;
         width:48pt'>Условия использования золы</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl15316 style='height:15.0pt'></td>
         <td class=xl65316 width=266 style='border-top:none;width:199pt'>1</td>
         <td class=xl66316 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl66316 width=236 style='border-top:none;border-left:none;
         width:177pt'>3</td>
         <td class=xl66316 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl66316 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl66316 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl67316 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="51"><col width="100"><col width="190"><col width="106"><col width="65"><col width="87"><col width="104"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="51" height="286" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ п/п</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="100" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="190" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радионуклидттердің меншікті белсенділігінің мәні (Акүл) Бк/кг <br>Значения удельной активности радионуклидов (Азола) Бк/кг</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="106" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радионуклидттердің меншікті белсенділігінің рұқсат етілген деңгейі (Акүл) Бк/кг<br>Допустимый уровень удельной активности радионуклидов (Азола) Бк/кг</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Күлдің радиациялық&nbsp;қауіптілік сыныптары<br>Класс радиационной опасности золы</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="87" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="104" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Күлдің пайдалану шарттары<br>Условия использования золы</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
          <tr>
            <td style="border-top: 3px solid #000000" colspan="7" height="44" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi<br>(Исследование образца проводились на соответствие НД)________________</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '63': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности материалов, сырья, изделий',
    kazakhName: 'Материалдың, шикізаттардың, бұйымның радиобелсенділгін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объект атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'measurement_tools',
        type: 'text',
        label: 'Средства измерений, наименование',
        kazakhLabel: 'Өлшеу құралдары, атауы'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемі'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1197 style='border-collapse:
        collapse;table-layout:fixed;width:898pt'>
        <col width=64 style='width:48pt'>
        <col width=277 style='mso-width-source:userset;mso-width-alt:9841;width:208pt'>
        <col width=110 style='mso-width-source:userset;mso-width-alt:3925;width:83pt'>
        <col width=490 style='mso-width-source:userset;mso-width-alt:17408;width:367pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl153586 width=64 style='height:15.0pt;width:48pt'></td>
         <td class=xl153586 width=277 style='width:208pt'></td>
         <td class=xl153586 width=110 style='width:83pt'></td>
         <td class=xl153586 width=490 style='width:367pt'></td>
         <td class=xl153586 width=64 style='width:48pt'></td>
         <td class=xl153586 width=64 style='width:48pt'></td>
         <td class=xl153586 width=64 style='width:48pt'></td>
         <td class=xl153586 width=64 style='width:48pt'></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl153586 style='height:14.4pt'></td>
         <td colspan=2 rowspan=4 class=xl703586 width=387 style='border-right:1.0pt solid #CFCFCF;
         border-bottom:1.0pt solid #CFCFCF;width:291pt'>&nbsp;</td>
         <td class=xl633586 width=490 style='width:367pt'>Нысанны&#1187; Б&#1178;СЖ
         бойынша коды</td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl153586 style='height:14.4pt'></td>
         <td class=xl643586 width=490 style='width:367pt'>Код формы по ОКУД
         ___________________</td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl153586 style='height:14.4pt'></td>
         <td class=xl643586 width=490 style='width:367pt'>К&#1200;ЖЖ бойынша
         &#1201;йым коды</td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl153586 style='height:15.0pt'></td>
         <td class=xl653586 width=490 style='width:367pt'>Код организации по ОКПО
         _____________</td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
        </tr>
        <tr height=35 style='mso-height-source:userset;height:26.4pt'>
         <td height=35 class=xl153586 style='height:26.4pt'></td>
         <td class=xl663586 width=277 style='width:208pt'>&#1178;аза&#1179;стан
         Республикасыны&#1187; Денсаулы&#1179; са&#1179;тау министрлігі</td>
         <td class=xl683586 width=110 style='border-top:none;border-left:none;
         width:83pt'>&nbsp;</td>
         <td class=xl693586 width=490 style='border-top:none;border-left:none;
         width:367pt'>&#1178;аза&#1179;стан Республикасыны&#1187; Денсаулы&#1179;
         са&#1179;тау министріні&#1187;2021 жыл&#1171;ы &quot;___&quot; _________ №___
         б&#1201;йры&#1171;ымен бекітілген № 063/е нысанды медициналы&#1179;
         &#1179;&#1201;жаттама</td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
         <td class=xl153586></td>
        </tr>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl153586 style='height:66.0pt'></td>
         <td rowspan=2 class=xl673586 width=277 style='width:208pt'>№ п/п</td>
         <td class=xl673586 width=110 style='border-left:none;width:83pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl673586 width=490 style='border-left:none;width:367pt'>К&#1257;рсеткіштерді&#1187;
         атауы</td>
         <td class=xl673586 width=64 style='border-left:none;width:48pt'>&#1256;лшем
         бірлігі</td>
         <td class=xl673586 width=64 style='border-left:none;width:48pt'>На&#1179;ты
         &#1199;лестік тиімді белсенділік</td>
         <td class=xl673586 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl673586 width=64 style='border-left:none;width:48pt'>Р&#1201;&#1179;сат
         ететін &#1179;&#1201;рамы</td>
        </tr>
        <tr height=123 style='height:92.4pt'>
         <td height=123 class=xl153586 style='height:92.4pt'></td>
         <td class=xl673586 width=110 style='border-top:none;border-left:none;
         width:83pt'>Наименование образца</td>
         <td class=xl673586 width=490 style='border-top:none;border-left:none;
         width:367pt'>Наименование показателей</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>Единица измерения</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>Фактическая удельная эффективная активность</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимое содержание</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl153586 style='height:14.4pt'></td>
         <td class=xl673586 width=277 style='border-top:none;width:208pt'>1</td>
         <td class=xl673586 width=110 style='border-top:none;border-left:none;
         width:83pt'>2</td>
         <td class=xl673586 width=490 style='border-top:none;border-left:none;
         width:367pt'>3</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl673586 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="66"><col width="115"><col width="185"><col width="98"><col width="118"><col width="118"><col width="111"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="66" height="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ п/п</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="185" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштердің атауы<br>Наименование показателей</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="98" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшем бірлігі<br>Единица измерения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="118" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нақты үлестік тиімді белсенділік<br>Фактическая удельная эффективная активность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="118" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="111" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат ететін құрамы<br>Допустимое содержание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '64': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности пищевых продуктов',
    kazakhName: 'Тамақ өнімдерінің радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объект атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'measurement_tools',
        type: 'text',
        label: 'Средства измерений, наименование',
        kazakhLabel: 'Өлшеу құралдары, атауы'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемі'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=896 style='border-collapse:
        collapse;table-layout:fixed;width:672pt'>
        <col width=64 style='width:48pt'>
        <col width=269 style='mso-width-source:userset;mso-width-alt:9557;width:202pt'>
        <col width=126 style='mso-width-source:userset;mso-width-alt:4465;width:94pt'>
        <col width=181 style='mso-width-source:userset;mso-width-alt:6428;width:136pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl156851 width=64 style='height:79.2pt;width:48pt'></td>
         <td rowspan=2 class=xl726851 width=269 style='width:202pt'>№ п/п</td>
         <td class=xl716851 width=126 style='border-left:none;width:94pt'>&#1198;лгі
         атауы</td>
         <td class=xl716851 width=181 style='border-left:none;width:136pt'>К&#1257;рсеткіштерді&#1187;
         атауы</td>
         <td class=xl716851 width=64 style='border-left:none;width:48pt'>&#1256;лшем
         бірлігі</td>
         <td class=xl716851 width=64 style='border-left:none;width:48pt'>Радионуклиттерді&#1187;
         &#1199;лестік тиімді белсенділігі</td>
         <td class=xl716851 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl656851 width=64 style='border-left:none;width:48pt'>Р&#1201;&#1179;сат
         етілетін &#1179;&#1201;рамы</td>
        </tr>
        <tr height=123 style='height:92.4pt'>
         <td height=123 class=xl156851 style='height:92.4pt'></td>
         <td class=xl706851 width=126 style='border-top:none;border-left:none;
         width:94pt'>Наименование образца</td>
         <td class=xl706851 width=181 style='border-top:none;border-left:none;
         width:136pt'>Наименование показателей</td>
         <td class=xl706851 width=64 style='border-top:none;border-left:none;
         width:48pt'>Единица измерения</td>
         <td class=xl706851 width=64 style='border-top:none;border-left:none;
         width:48pt'>Удельная эффективная активность радионуклидов</td>
         <td class=xl706851 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl666851 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимое содержание</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl156851 style='height:15.0pt'></td>
         <td class=xl676851 width=269 style='border-top:none;width:202pt'>1</td>
         <td class=xl686851 width=126 style='border-top:none;border-left:none;
         width:94pt'>2</td>
         <td class=xl686851 width=181 style='border-top:none;border-left:none;
         width:136pt'>3</td>
         <td class=xl686851 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl686851 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl686851 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl696851 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="41"><col width="122"><col width="129"><col width="99"><col width="146"><col width="97"><col width="102"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="41" height="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ п/п</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="122" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгі атауы<br>Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="129" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштердің <br>атауы<br>Наименование показателей</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="99" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшем бірлігі<br>Единица измерения</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="146" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радионуклиттердің үлестік тиімді белсенділігі<br>Удельная эффективная активность радионуклидов</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="97" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="102" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілетін құрамы<br>Допустимое содержание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '65': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности топливного-энергетического минерального сырья',
    kazakhName: 'Отын-энергетикалық минералды шикізаттың радиобелсенділігін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы',
        number: '1'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы',
        number: '2'
      },
      {
        id: 'selection_place',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгіні алу орны',
        number: '3'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата проведення испытаний',
        kazakhLabel: 'Сынақ жүргізілген күн',
        number: '4'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі',
        number: '5'
      },
      {
        id: 'coal_zolality',
        type: 'text',
        label: 'Зольность угля %',
        kazakhLabel: 'көмірдің күлденуі %',
        number: ''
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемі',
        number: '6'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі',
        number: '7'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі',
        number: '8'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором',
        kazakhLabel: 'Зерттеу аспаппен жүргізілді',
        number: '9'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Сәйкестігі туралы куәлік',
        number: ''
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi',
        number: '10'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1088 style='border-collapse:
        collapse;table-layout:fixed;width:816pt'>
        <col width=64 style='width:48pt'>
        <col width=238 style='mso-width-source:userset;mso-width-alt:8476;width:179pt'>
        <col width=64 style='width:48pt'>
        <col width=466 style='mso-width-source:userset;mso-width-alt:16554;width:349pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl155221 width=64 style='height:66.0pt;width:48pt'></td>
         <td rowspan=2 class=xl695221 width=238 style='width:179pt'>№ п\п</td>
         <td class=xl685221 width=64 style='border-left:none;width:48pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl685221 width=466 style='border-left:none;width:349pt'>Радионуклидттерді&#1187;
         &#1199;лестік белсенділігіні&#1187; м&#1241;ніні&#1187; &#1179;осындысы МММА
         (к&#1257;мір)</td>
         <td class=xl685221 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl685221 width=64 style='border-left:none;width:48pt'>Р&#1201;&#1179;сат
         етілетін де&#1187;гейі (к&#1257;мір)</td>
         <td class=xl685221 width=64 style='border-left:none;width:48pt'>К&#1257;мірді&#1187;
         радиациялы&#1179; &#1179;ауіптілік сыныбы</td>
         <td class=xl635221 width=64 style='border-left:none;width:48pt'>К&#1257;мірді
         пайдалану шарттары</td>
        </tr>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl155221 style='height:66.0pt'></td>
         <td class=xl675221 width=64 style='border-top:none;border-left:none;
         width:48pt'>Наименование образца</td>
         <td class=xl675221 width=466 style='border-top:none;border-left:none;
         width:349pt'>Значения суммы отношений удельной активности радионуклидов к
         МЗУА (Суголь)</td>
         <td class=xl675221 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl675221 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень (Суголь)</td>
         <td class=xl675221 width=64 style='border-top:none;border-left:none;
         width:48pt'>Класс радиационной опасности угля</td>
         <td class=xl645221 width=64 style='border-top:none;border-left:none;
         width:48pt'>Условия использования угля</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl155221 style='height:15.0pt'></td>
         <td class=xl655221 width=238 style='border-top:none;width:179pt'>1</td>
         <td class=xl665221 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl665221 width=466 style='border-top:none;border-left:none;
         width:349pt'>3</td>
         <td class=xl665221 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td colspan=3 class=xl715221 width=192 style='border-right:1.0pt solid black;
         border-left:none;width:144pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="49"><col width="117"><col width="241"><col width="105"><col width="100"><col width="86"><col width="65"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="49" height="121" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">№ п\п</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="117" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="241" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Радионуклидттердің үлестік <br>белсенділігінің мәнінің қосындысы <br>МММА (көмір) <br>Значения суммы отношений <br>удельной активности радионуклидов к <br>МЗУА (С уголь)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Зерттеу әдістеменің НҚ-ры <br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="100" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Рұқсат етілетін деңгейі (көмір)<br>Допустимый уровень (С уголь)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="86" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Көмірдің радиациялық қауіптілік сыныбы <br>Класс радиационной опасности угля</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="65" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Көмірді пайдалану шарттары<br>Условия использования угля</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="3" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '66': {
    name: 'ПРОТОКОЛ спектрометрического измерения человека',
    kazakhName: 'Адамдарды спекторметриялық өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии)',
        kazakhLabel: 'Аты, жөні'
      },
      {
        id: 'passport',
        type: 'text',
        label: 'Документы, удостоверяющие личность (куәлік, төлқұжат) (удостоверение, паспорт',
        kazakhLabel: 'Жеке басын куәландыратын құжаттар №'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен-жайы'
      },
      {
        id: 'gender_age_weight',
        type: 'number',
        label: 'Пол, Возраст, Вес',
        kazakhLabel: 'Жынысы, Жасы, Салмағы'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные данные',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'measurement_done_with_tool',
        type: 'text',
        label: 'измерение проводились на приборе',
        kazakhLabel: 'Өлшеу аспаппен жүргізілді'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Тексеру туралы куәлік'
      },
      {
        id: 'measurement_done_in_geometry',
        type: 'text',
        label: 'Измерение проводилось в геометрии',
        kazakhLabel: 'Өлшеу геометриямен жүргізілді'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },
      {
        id: 'pp_number',
        type: 'text',
        label: '№ п/п',
        kazakhLabel: '№ п/п'
      },
      {
        id: 'measurement_organization',
        type: 'text',
        label: 'Наименование измеряемого органа',
        kazakhLabel: 'Өлшенетін мүшелердіңатауы'
      },
      {
        id: 'specific_effective_activity_cs137',
        type: 'text',
        label: 'Удельная эффективная активность Cs-137Бк/кг',
        kazakhLabel: 'Үлестік тиімді белсенділік Cs-137Бк/кг'
      },
      {
        id: 'specific_effective_activity_mn54b',
        type: 'text',
        label: 'Удельная эффективная активность Mn - 54Бк/кг',
        kazakhLabel: 'Үлестік тиімді белсенділік Mn - 54Бк/кг'
      },
      {
        id: 'specific_effective_activity_cr51',
        type: 'text',
        label: 'Удельная эффективная активность Cr-51 Бк/кг',
        kazakhLabel: 'Үлестік тиімді белсенділік Cr-51 Бк/кг'
      },
      {
        id: 'specific_effective_activity_co60',
        type: 'text',
        label: 'Удельная эффективная активность Co-60 Бк/кг',
        kazakhLabel: 'Үлестік тиімді белсенділік Co-60 Бк/кг'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'specific_effective_activity_i131',
        type: 'text',
        label: 'Удельная эффективная активность I-131 Бк/кг',
        kazakhLabel: 'Үлестік тиімді белсенділік I-131 Бк/кг'
      }
    ]
  },
  '67': {
    version:2,
    name: 'ПРОТОКОЛ исследования снимаемого загрязнения методом мазков',
    kazakhName: 'Алынатын ластануды жағындылар әдісімен зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта,адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'selection_sample_date',
        type: 'datetime',
        label: 'Дата отбора образца',
        kazakhLabel: 'Үлгілердің алынғын күні'
      },
      {
        id: 'iii_object_used',
        type: 'text',
        label: 'Вид ИИИ использующих объектом',
        kazakhLabel: 'Объекті пйдаланатын ИСК түрі'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором №',
        kazakhLabel: 'Зерттеу аспаппен жүргізілген №'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке г.',
        kazakhLabel: 'Сәйкестігі туралы куәлік күні 20 ж.'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },
      {
        id: 'sources_sketcg',
        type: 'text',
        label: 'Схема расположения источников и контрольных точек',
        kazakhLabel: 'Сәуле көздерінің орналасу сызбасы және бықылау нүктесі'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=773 style='border-collapse:
        collapse;table-layout:fixed;width:580pt'>
        <col width=64 style='width:48pt'>
        <col width=294 style='mso-width-source:userset;mso-width-alt:10467;width:221pt'>
        <col width=64 style='width:48pt'>
        <col width=223 style='mso-width-source:userset;mso-width-alt:7936;width:167pt'>
        <col width=64 span=2 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1512024 width=64 style='height:52.8pt;width:48pt'></td>
         <td rowspan=4 class=xl7012024 width=294 style='width:221pt'>№ п/п</td>
         <td class=xl6912024 width=64 style='border-left:none;width:48pt'>Ластан&#1171;ан
         жерді&#1187; ба&#1179;ылау орыны</td>
         <td class=xl6912024 width=223 style='border-left:none;width:167pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td colspan=2 class=xl6912024 width=128 style='border-right:1.0pt solid black;
         border-left:none;width:96pt'>Аны&#1179;тал&#1171;ан белсенділік, Бк/см2&nbsp;</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1512024 style='height:79.2pt'></td>
         <td class=xl6712024 width=64 style='border-top:none;border-left:none;
         width:48pt'>Место контроля снимаемого загрязнения</td>
         <td class=xl6712024 width=223 style='border-top:none;border-left:none;
         width:167pt'>НД на метод испытаний</td>
         <td colspan=2 class=xl6712024 width=128 style='border-right:1.0pt solid black;
         border-left:none;width:96pt'>Обнаруженная активность, Бк/см2</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1512024 style='height:52.8pt'></td>
         <td class=xl6812024 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6812024 width=223 style='border-top:none;border-left:none;
         width:167pt'>&nbsp;</td>
         <td class=xl6712024 width=64 style='border-top:none;border-left:none;
         width:48pt'>Жиынты&#1179; альфа- белсенділік</td>
         <td class=xl6312024 width=64 style='border-top:none;border-left:none;
         width:48pt'>Жиынты&#1179; бета- белсенділік</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1512024 style='height:52.8pt'></td>
         <td class=xl6812024 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6812024 width=223 style='border-top:none;border-left:none;
         width:167pt'>&nbsp;</td>
         <td class=xl6712024 width=64 style='border-top:none;border-left:none;
         width:48pt'>Суммарная альфа-активность</td>
         <td class=xl6312024 width=64 style='border-top:none;border-left:none;
         width:48pt'>Суммарная бета-активность</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1512024 style='height:15.0pt'></td>
         <td class=xl6412024 width=294 style='border-top:none;width:221pt'>1</td>
         <td class=xl6512024 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6512024 width=223 style='border-top:none;border-left:none;
         width:167pt'>3</td>
         <td class=xl6512024 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6612024 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="54"><col width="140"><col width="165"><col width="140"><col width="143"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="2" width="54" height="162" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ п/п</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="140" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ластанған жердің бақылау орыны<br>Место контроля снимаемого загрязнения</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="165" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="2" width="283" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған белсенділік, Бк/см2&nbsp;<br>Обнаруженная активность, Бк/см2</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жиынтық альфа- белсенділік<br>Суммарная альфа-активность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жиынтық бета- белсенділік<br>Суммарная бета-активность</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '68': {
    name: 'ПРОТОКОЛ проведения измерений мощности экспозиционной дозы пациентов после радионуклидной терапии',
    kazakhName: 'Радионуклидтік терапиядан кейін пациенттердің экспозициялық дозасының қуатын өлшеуді жүргізу ХАТТАМАСЫ',
    fields: [
      {
        id: 'full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии)',
        kazakhLabel: 'Аты, жөні, тегі (болған жағдайда)'
      },
      {
        id: 'passport',
        type: 'text',
        label: 'Документы, удостоверяющие личность удостоверение, паспорт, кем выдан, №',
        kazakhLabel: 'Жеке басын куәландыратын құжаттар (куәлік, төлқұжат, кіммен берілген, №)'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен-жайы'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные данные',
        kazakhLabel: 'Қосымша деректор'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'измерение проводились на приборе',
        kazakhLabel: 'Тексеру барысында аспапта өлшеу жүргізілді'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Тексеруі туралы куәлік'
      },
      {
        id: 'research_done_on_1m_distance',
        type: 'text',
        label: 'Измерения проводились на расстоянии 1 м от стоящего пациента с разных сторон и на разных уровнях',
        kazakhLabel: 'Өлшеу әртүрлі жақтан және әртүрлі деңгейде тұрған пациентке 1 м аралықта жүргізілді'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Органның\r\n  атауы\r\n    Наименование органа',
            'Өлшенген ЭДҚ\r\n  (мкЗв/ч)\r\n    Измеренная МЭД (мкЗв/ч)',
            'Рұқсат етілетін\r\n  ЭДҚ (мкЗв/ч)\r\n    Допустимая МЭД (мкЗв/ч)',
            'Органның\r\n  атауы\r\n    Наименование органа',
            'Өлшенген ЭДҚ\r\n  (мкЗв/ч)\r\n    Измеренная МЭД (мкЗв/ч)',
            'Рұқсат етілетін\r\n  ЭДҚ (мкЗв/ч)\r\n    Допустимая МЭД (мкЗв/ч)'
          ],
          [
            'Алдынан (Спереди)',
            'Оң жақтан (С правого бока)'
          ],
          [
            'Қалқанша без (Щитовидная железа)',
            '&nbsp;',
            '&nbsp;',
            'Қалқанша\r\n  без (Щитовидная железа)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Кеуде (Грудь)',
            '&nbsp;',
            '&nbsp;',
            'Кеуде\r\n  (Грудь)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Асқазан (Желудок)',
            '&nbsp;',
            '&nbsp;',
            'Асқазан\r\n  (Желудок)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жыныс мүшелері (Половые органы)',
            '&nbsp;',
            '&nbsp;',
            'Жыныс\r\n  мүшелері (Половые органы)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Артынан\r\n  (Сзади)',
            'Сол\r\n  жақтан (С левого бока)'
          ],
          [
            'Қалқанша без (Щитовидная железа)',
            '&nbsp;',
            '&nbsp;',
            'Қалқанша\r\n  без (Щитовидная железа)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Кеуде (Грудь)',
            '&nbsp;',
            '&nbsp;',
            'Кеуде\r\n  (Грудь)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Асқазан (Желудок)',
            '&nbsp;',
            '&nbsp;',
            'Асқазан\r\n  (Желудок)',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жыныс мүшелері (Половые органы)',
            '&nbsp;',
            '&nbsp;',
            'Жыныс\r\n  мүшелері (Половые органы)',
            '&nbsp;',
            '&nbsp;'
          ]
        ],
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=621 style='border-collapse:
        collapse;width:466pt'>
       
        <col width=101 style='mso-width-source:userset;mso-width-alt:3242;width:76pt'>
        <col width=100 span=2 style='mso-width-source:userset;mso-width-alt:3200;
        width:75pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=108 style='mso-width-source:userset;mso-width-alt:3456;width:81pt'>
        <col width=107 style='mso-width-source:userset;mso-width-alt:3413;width:80pt'>
        <tr height=115 style='mso-height-source:userset;height:86.5pt'>
         <td height=115 class=xl64 width=101 style='height:86.5pt;width:76pt'>Органның
         атауы<br>
           Наименование органа</td>
         <td class=xl64 width=100 style='border-left:none;width:75pt'>Өлшенген ЭДҚ
         (мкЗв/ч)<br>
           Измеренная МЭД (мкЗв/ч)</td>
         <td class=xl64 width=100 style='border-left:none;width:75pt'>Рұқсат етілетін
         ЭДҚ (мкЗв/ч)<br>
           Допустимая МЭД (мкЗв/ч)</td>
         <td class=xl64 width=105 style='border-left:none;width:79pt'>Органның
         атауы<br>
           Наименование органа</td>
         <td class=xl64 width=108 style='border-left:none;width:81pt'>Өлшенген ЭДҚ
         (мкЗв/ч)<br>
           Измеренная МЭД (мкЗв/ч)</td>
         <td class=xl64 width=107 style='border-left:none;width:80pt'>Рұқсат етілетін
         ЭДҚ (мкЗв/ч)<br>
           Допустимая МЭД (мкЗв/ч)</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=3 height=21 class=xl65 style='height:16.0pt'>Алдынан (Спереди)</td>
         <td colspan=3 class=xl65 style='border-left:none'>Оң жақтан (С правого бока)</td>
        </tr>
        <tr height=60 style='mso-height-source:userset;height:45.5pt'>
         <td height=60 class=xl63 width=101 style='height:45.5pt;border-top:none;
         width:76pt'>Қалқанша без (Щитовидная железа)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Қалқанша
         без (Щитовидная железа)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl63 width=101 style='height:16.0pt;border-top:none;
         width:76pt'>Кеуде (Грудь)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Кеуде
         (Грудь)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=41 style='mso-height-source:userset;height:31.75pt'>
         <td height=41 class=xl63 width=101 style='height:31.75pt;border-top:none;
         width:76pt'>Асқазан (Желудок)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Асқазан
         (Желудок)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=77 style='mso-height-source:userset;height:58.75pt'>
         <td height=77 class=xl63 width=101 style='height:58.75pt;border-top:none;
         width:76pt'>Жыныс мүшелері (Половые органы)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Жыныс
         мүшелері (Половые органы)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=24 style='mso-height-source:userset;height:18.5pt'>
         <td colspan=3 height=24 class=xl63 width=301 style='height:18.5pt;width:226pt'>Артынан
         (Сзади)</td>
         <td colspan=3 class=xl63 width=320 style='border-left:none;width:240pt'>Сол
         жақтан (С левого бока)</td>
        </tr>
        <tr height=57 style='mso-height-source:userset;height:43.75pt'>
         <td height=57 class=xl63 width=101 style='height:43.75pt;border-top:none;
         width:76pt'>Қалқанша без (Щитовидная железа)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Қалқанша
         без (Щитовидная железа)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl63 width=101 style='height:16.0pt;border-top:none;
         width:76pt'>Кеуде (Грудь)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Кеуде
         (Грудь)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=45 style='mso-height-source:userset;height:34.75pt'>
         <td height=45 class=xl63 width=101 style='height:34.75pt;border-top:none;
         width:76pt'>Асқазан (Желудок)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Асқазан
         (Желудок)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=84 style='mso-height-source:userset;height:63.0pt'>
         <td height=84 class=xl63 width=101 style='height:63.0pt;border-top:none;
         width:76pt'>Жыныс мүшелері (Половые органы)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl63 width=105 style='border-top:none;border-left:none;width:79pt'>Жыныс
         мүшелері (Половые органы)</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl65 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
       
       </table>`
      }
    ]
  }
}
