import { 
    PROTOCOL_FILE_GET_REQUEST, PROTOCOL_FILE_GET_SUCCESS, PROTOCOL_FILE_GET_ERROR, 
    PROTOCOL_FILE_GET_BY_KEY_REQUEST, PROTOCOL_FILE_GET_BY_KEY_SUCCESS, PROTOCOL_FILE_GET_BY_KEY_ERROR, 
    PROTOCOL_FILE_ADD_REQUEST, PROTOCOL_FILE_ADD_SUCCESS, PROTOCOL_FILE_ADD_ERROR, 
    PROTOCOL_FILE_EDIT_REQUEST, PROTOCOL_FILE_EDIT_SUCCESS, PROTOCOL_FILE_EDIT_ERROR, 
    PROTOCOL_FILE_DELETE_REQUEST, PROTOCOL_FILE_DELETE_SUCCESS, PROTOCOL_FILE_DELETE_ERROR
} from "../constants/protocolFileConstants";

const initialState = {
    protocolFiles: [],
    protocolFile: {},
    loading: false,
    success: null,
    error: null,
    protocolFileRequestedCount: 0,
};

export const protocolFileReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PROTOCOL_FILE_GET_REQUEST:
            return {
                ...state,
                protocolFileRequestedCount: ++state.protocolFileRequestedCount,
                loading: true,
            };
        case PROTOCOL_FILE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                protocolFiles: action.payload,
                success: true,
            };
        case PROTOCOL_FILE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FILE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FILE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                protocolFile: action.payload,
                success: true,
            };
        case PROTOCOL_FILE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FILE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FILE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_FILE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FILE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FILE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_FILE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FILE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FILE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_FILE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
