import { $authHost } from "../../http";
import { 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_ERROR
} from "../constants/technicalRegulationsOfCustomsUnionConstants";

export const getTechnicalRegulationsOfCustomsUnions = (args) => async (dispatch) => {
    try {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/technicalRegulationsOfCustomsUnion/query",
            args || {},
            config,
        );
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getTechnicalRegulationsOfCustomsUnionByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/technicalRegulationsOfCustomsUnion/${id}`,
        );
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addTechnicalRegulationsOfCustomsUnion = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/technicalRegulationsOfCustomsUnion",
            { name, code, description },
            config,
        );
        dispatch(getTechnicalRegulationsOfCustomsUnions(queryArgs || {}));
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editTechnicalRegulationsOfCustomsUnion = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/technicalRegulationsOfCustomsUnion",
            { id, name, code, description },
            config,
        );
        dispatch(getTechnicalRegulationsOfCustomsUnions(queryArgs || {}));
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteTechnicalRegulationsOfCustomsUnion = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/technicalRegulationsOfCustomsUnion",
            { data: { id } },
            config,
        );
        dispatch(getTechnicalRegulationsOfCustomsUnions(queryArgs || {}));
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

