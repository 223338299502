import {
    RESULT_TYPE_GET_REQUEST, RESULT_TYPE_GET_SUCCESS, RESULT_TYPE_GET_ERROR,
} from "../constants/resultConstants";

const initialState = {
    resultTypes: [],
    loading: false,
    success: null,
    error: null,
    resultTypeRequestedCount: 0,
};

export const resultTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case RESULT_TYPE_GET_REQUEST:
            return {
                ...state,
                resultTypeRequestedCount: ++state.resultTypeRequestedCount,
                loading: true,
            };
        case RESULT_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                resultTypes: action.payload,
                success: true,
            };
        case RESULT_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
