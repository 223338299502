import m_16_2210_colorized from '././shymkenskoe/KZ.M.16.2210.svg'
import m_16_2210 from '././shymkenskoe/KZ.M.16.2210 black.svg'
import t_16_1095_colorized from '././shymkenskoe/KZ.T.16.1095.svg'
import t_16_1095 from '././shymkenskoe/KZ.T.16.1095 black.svg'
import t_19_2276_colorized from '././aryskoe/KZ.T.19.2276.svg'
import t_19_2276 from '././aryskoe/KZ.T.19.2276 black.svg'
import t_19_2320_colorized from '././turkestanskoe/KZ.T.19.2320.svg'
import t_19_2320 from '././turkestanskoe/KZ.T.19.2320 black.svg'
import t_19_2321_colorized from '././sairam/KZ.T.19.2321.svg'
import t_19_2321 from '././sairam/KZ.T.19.2321 black.svg'

export default [
  m_16_2210_colorized,
  m_16_2210,
  t_16_1095_colorized,
  t_16_1095,
  t_19_2276_colorized,
  t_19_2276,
  t_19_2320_colorized,
  t_19_2320,
  t_19_2321_colorized,
  t_19_2321
]
