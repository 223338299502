import CancelIcon from '@mui/icons-material/Cancel';
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import ObjectField from "../../../components/Relations/ObjectField";
import {
    PurposeOfSelectionsSelect,
    TransportationConditionSelect
} from "../../../components/Select/domainSelects";
import GovernmentField from "../../../components/Relations/GovernmentField";
import AnObjectField from "../../../components/Relations/AnObjectField";

const ActWaterForm = ({
                          isDisabledData,
                          data,
                          handleChangeData,
                          handleChangeSample,
                          addSample,
                          deleteSample
                      }) => {
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <AnObjectField
                    bold={false}
                    label={"1. Объектінің атауы (Наименование объекта)"}
                    value={data.data?.object?.value}
                    disabled={isDisabledData}
                    onChange={(obj) => {
                        handleChangeData('object', obj, 'facility')
                    }}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <p style={{width: "50%"}}>
                    2. Сынама алынған орын (Место отбора)
                </p>
                <TextField
                    id="outlined-basic"
                    label="Место отбора"
                    style={{width: "50%", margin: "0 0 0 30px"}}
                    variant="outlined"
                    disabled={isDisabledData}
                    value={data.data?.selectionPlace?.value}
                    onChange={(e) => handleChangeData('selectionPlace', e.target.value, 'selection_place')}
                />
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <p style={{width: "50%"}}>
                    3. Сынамалар алу мақсаты (Цель отбора):
                </p>
                <div style={{width: "50%", marginLeft: "30px"}}>
                    <PurposeOfSelectionsSelect
                        label={"Сынамалар алу мақсаты (Цель отбора)"}
                        fullWidth
                        value={data.data?.samplingPurpose?.value?.id}
                        disabled={isDisabledData}
                        onChange={(val, obj) => {
                            handleChangeData('samplingPurpose', obj, 'samplingPurpose')
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <p style={{width: "50%"}}>
                    4. Алынған күні мен уақыты (Дата и время отбора)
                </p>
                <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DateTimePicker views={['day']} label="Дата и время отбора"
                                    disabled={isDisabledData}
                                    value={data.data?.selectionDateTime?.value}
                                    onChange={(value) => handleChangeData('selectionDateTime', value, 'selection_time')}
                                    minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{width: "50%", margin: "0 0 0 30px"}} margin="normal"  {...params}
                                   helperText={null}/>
                    }/>
                </LocalizationProvider>
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <p style={{width: "50%"}}>
                    5. Жеткізілген күні мен уақыты (Дата и время доставки)
                </p>
                <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DateTimePicker views={['day']} label="Дата и время доставки"
                                    disabled={isDisabledData}
                                    value={data.data?.deliveryDateTime?.value}
                                    onChange={(value) => handleChangeData('deliveryDateTime', value, 'delivery_time')}
                                    minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{width: "50%", margin: "0 0 0 30px"}} margin="normal"  {...params}
                                   helperText={null}/>
                    }/>
                </LocalizationProvider>
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <p style={{width: "50%"}}>
                    6. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
                    метод отбора)
                </p>
                <div style={{width: "50%", marginLeft: "30px"}}>
                    <GovernmentField
                        fullWidth
                        disabled={isDisabledData}
                        label={null}
                        array={data.data?.standard?.value}
                        onChange={(val) => {
                            handleChangeData('standard', val, 'nd_selection')
                        }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    marginBottom: "5px",
                    alignItems: "center",
                }}
            >
                <p style={{width: "50%"}}>
                    7. Тасымалдау жағдайы (Условия транспортировки)
                </p>
                <div style={{width: "50%", marginLeft: "30px"}}>
                    <TransportationConditionSelect
                        label={"Условия транспортировки"}
                        fullWidth
                        value={data.data?.transportationCondition?.value?.id}
                        disabled={isDisabledData}
                        onChange={(val, obj) => {
                            handleChangeData('transportationCondition', obj, 'transportation_conditions')
                        }}
                    />
                </div>
            </div>
            <TableContainer component={Paper} style={{marginBottom: "30px", overflow: "hidden"}}>
                <Table
                    aria-label="simple table"
                    className="border-table-word"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className="border-table-word">
                                Үлгілер нөмірi (Номер образца)
                            </TableCell>
                            <TableCell className="border-table-word">
                                Алынған сынамалар атауы, үлгі алынған орын және нүктелер (Наименование отобранных
                                образцов, место и точки отбора)
                            </TableCell>
                            <TableCell className="border-table-word">
                                Үлгілердің мөлшері,саны (Количество пробы, объем)
                            </TableCell>
                            <TableCell className="border-table-word">
                                Қосымша мәліметтер (Дополнительные данные)
                            </TableCell>
                            <TableCell className="border-table-word">
                                Действия
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.data?.samples?.value?.map((sample, i) => {
                            return <TableRow key={i}>
                                <TableCell className="border-table-word">
                                    <TextField
                                        style={{width: '100%'}}
                                        id="outlined-basic"
                                        label="Номер образца"
                                        variant="outlined"
                                        type="number"
                                        disabled={isDisabledData}
                                        value={sample.sampleNumber?.value}
                                        onChange={(e) => handleChangeSample('sampleNumber', e.target.value, i, 'sampleNumber')}
                                    />
                                </TableCell>
                                <TableCell className="border-table-word">
                                    <TextField
                                        style={{width: '100%'}}
                                        id="outlined-basic"
                                        label="Наименование отобранных образцов, место и точки отбора"
                                        variant="outlined"
                                        disabled={isDisabledData}
                                        value={sample.sample?.value}
                                        onChange={(e) => handleChangeSample('sample', e.target.value, i, 'sample')}
                                    />
                                </TableCell>
                                <TableCell className="border-table-word">
                                    <TextField
                                        style={{width: '100%'}}
                                        id="outlined-basic"
                                        label="Количество пробы, объем"
                                        variant="outlined"
                                        disabled={isDisabledData}
                                        value={sample.quantitySize?.value}
                                        onChange={(e) => handleChangeSample('quantitySize', e.target.value, i, 'quantitySize')}
                                    />
                                </TableCell>
                                <TableCell className="border-table-word">
                                    <TextField
                                        style={{width: '100%'}}
                                        id="outlined-basic"
                                        label="Дополнительные данные"
                                        variant="outlined"
                                        disabled={isDisabledData}
                                        value={sample.additionalInfo?.value}
                                        onChange={(e) => handleChangeSample('additionalInfo', e.target.value, i, 'additionalInfo')}
                                    />
                                </TableCell>
                                <TableCell className="border-table-word">
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <IconButton color="error"
                                                    size="large"
                                                    aria-label="delete"
                                                    disabled={isDisabledData}
                                                    onClick={() => {
                                                        deleteSample(i)
                                                    }}>
                                            <CancelIcon/>
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div
                style={{
                    display: "flex",
                    marginBottom: "30px",
                    alignItems: "center",
                }}
            >
                <Button
                    disabled={isDisabledData}
                    variant="contained"
                    onClick={addSample}
                >
                    Добавить
                </Button>
            </div>
        </div>
    );
};

export default ActWaterForm;
