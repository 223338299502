import React, {useMemo} from 'react';
import DashboardLayout from "../layouts/Dashboard";
import Grid from "@mui/material/Grid";
import {Box, Button} from "@mui/material";
import useDialogs from "../components/Dialog/useDialogs";


export default function TestPage() {
  return <DashboardLayout>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <TestDialog/>
      </Grid>
    </Grid>
  </DashboardLayout>
}

function TestDialog() {
  const {alert, confirm, deleteConfirm, DIALOG} = useDialogs();

  const alertData = useMemo(() => ([
    {title: "Вы действительно хотите сделать ЭТО? ", description: "Это будет иметь последствия"},
    {title: "Вы действительно хотите сделать ЭТО? "},
    {description: "Это будет иметь последствия"},
    {},
  ]), []);

  const buttonStyle = {marginBottom: 5};
  const parseButtonPromise = (promise) => promise
    .then(() => console.log('then'))
    .catch(() => console.log('catch'));

  return <Box style={{display: "flex", flexDirection: "column"}}>
    <DIALOG />
    {alertData.map(({title, description}, i) => <Button key={i} style={buttonStyle}
      onClick={() => {
        parseButtonPromise(alert(title, description));
      }}>
      alert ({title && " with title"} {description && " with description"})
    </Button>)}

    {alertData.map(({title, description}, i) => <Button key={i} variant={"outlined"} style={buttonStyle}
      onClick={() => {
        parseButtonPromise(confirm(title, description))
      }}>
      confirm ({title && " with title"} {description && " with description"})
    </Button>)}

    <Button variant={"contained"}
      onClick={() => parseButtonPromise(deleteConfirm("SOME ELEMENT"))}>
      Delete
    </Button>
  </Box>
}