import React from "react";
import ReactBarcode from "react-barcode";

export const Barcode = (props) => {
  return <ReactBarcode id="barcode-canvas" {...props} />;
};

Barcode.defaultProps = {
  value: "000000000000",
  width: 0.9,
  height: 40,
  format: "CODE128",
  displayValue: true,
  fontOptions: "",
  font: "monospace",
  textAlign: "center",
  textPosition: "bottom",
  textMargin: 2,
  fontSize: 12,
  background: "#ffffff",
  lineColor: "#000000",
  margin: 0,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined,
};
