import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  BasicModalHeader,
  BasicModalContainer,
  Form,
} from "../../../components";

// TRASH
import { DatePicker, DateTimePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";

import useDialogs from "../../../components/Dialog/useDialogs";
import EmployeeField from "../../../components/Relations/EmployeeField";
import {
  DepartmentSelect,
  DivisionSelect,
  FilialDivisionSelect,
  FilialSelect,
} from "../../../components/Select/domainSelects";
import { getActForms } from "../../../redux/actions/actFormActions";
import { getGovernmentStandards } from "../../../redux/actions/governmentStandardActions";
import { getStorageConditions } from "../../../redux/actions/storageConditionActions";
import { getTransportationConditions } from "../../../redux/actions/transportationConditionActions";
import ObjectField from "../../../components/Relations/ObjectField";

export const ActDisinfectantCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const actForm = useSelector((state) => state.actForm);

  const handleSubmit = async (e) => {
    setLoading(true);

    await onSubmit(e, data);

    setLoading(false);
  };

  // TRASH
  //configStart
  const actName = "ActDisinfectant";
  const kazakhName = "Дезинфекциялайтын заттардың сынамаларын іріктеу АКТIСI";
  const name = "АКТ отбора проб дезинфицирующих средств";
  //configEnd
  const { alert, DIALOG } = useDialogs();
  const { t } = useTranslation();

  const governmentStandardValues = useSelector(
    (state) => state.governmentStandard
  );
  const transportationConditionValues = useSelector(
    (state) => state.transportationCondition
  );
  const storageConditionValues = useSelector((state) => state.storageCondition);
  const [isOpenSamplingPurpose, setIsOpenSamplingPurpose] = useState(false);


  const handleChange = useCallback(
    (key, value) => {
      setData({ ...data, [key]: value });
    },
    [data]
  );
  const handleChangeMulti = useCallback(
    (obj) => {
      setData({ ...data, ...obj });
    },
    [data]
  );

  const handleChangeData = useCallback(
    (key, value, key2) => {
      setData({ ...data, data: { ...data.data, [key]: { key: key2, value } } });
    },
    [data]
  );

  const handleChangeSample = useCallback(
    (key, value, i, key2) => {
      const array = data.data?.samples?.value.slice(0);
      array[i][key] = { key: key2, value };
      setData({
        ...data,
        data: {
          ...data.data,
          samples: { key: "samples", value: array },
        },
      });
    },
    [data]
  );

  useEffect(() => {
    if (actForm.actForms.length === 0) {
      dispatch(getActForms());
    }
  }, [actForm.actForms.length]);

  useEffect(() => {
    if (governmentStandardValues.governmentStandards.length === 0) {
      dispatch(getGovernmentStandards());
    }
    if (transportationConditionValues.transportationConditions.length === 0) {
      dispatch(getTransportationConditions());
    }
    if (storageConditionValues.storageConditions.length === 0) {
      dispatch(getStorageConditions());
    }
  }, [dispatch]);

  const [isDisabledData, setIsDisabledData] = useState(false);
  const [isDisabledPerformer, setIsDisabledPerformer] = useState(false);

  return (
    <>
      <BasicModalHeader heading="Акт отбора проб дезинфицирующих средств" />
      <BasicModalContainer>
        <Form onSubmit={handleSubmit}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody className="border-table-word">
                <TableRow>
                  <TableCell className="border-table-word"></TableCell>
                  <TableCell className="border-table-word" align="left">
                    <p>Нысанның БҚСЖ бойынша коды</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ width: "50%" }}>Код формы по ОКУД</p>
                      <TextField
                        id="outlined-basic"
                        label="Код"
                        style={{ width: "50%" }}
                        variant="outlined"
                      />
                    </div>
                    <p>КҰЖЖ бойынша ұйым коды</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ width: "50%" }}>Код организации по ОКПО</p>
                      <TextField
                        id="outlined-basic"
                        label="Код"
                        style={{ width: "50%" }}
                        variant="outlined"
                      />
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="border-table-word">
                    <p>
                      Қазақстан Республикасының Денсаулық сақтау министрлігі
                      Министерство здравоохранения Республики Казахстан
                    </p>
                  </TableCell>
                  <TableCell className="border-table-word" align="left">
                    <p>
                      Қазақстан Республикасының Денсаулық сақтау министрінің
                      2021 жылғы "20" 08 № 84 бұйрығымен бекітілген № 009/е
                      нысанды медициналық құжаттама
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="border-table-word">
                    <p>
                      Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк
                      органының атауы Наименование государственного органа
                      санитарно-эпидемиологической службы
                    </p>
                  </TableCell>
                  <TableCell className="border-table-word" align="left">
                    <p>
                      Медицинская документация Форма № 009/у Утверждена приказом
                      Министра здравоохранения Республики Казахстан от "20" 08
                      2021 года №84
                    </p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h5 align="center">{kazakhName}</h5>
            <h5 align="center">{name} (от)</h5>
            <LocalizationProvider
              locale={ruLocale}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                views={["day"]}
                label="от"
                disabled={isDisabledData}
                value={data.data?.dateOfAct?.value}
                onChange={(value) => {
                  handleChangeData("dateOfAct", value, "dateOfAct");
                }}
                minDate={new Date("1910-03-01")}
                renderInput={(params) => (
                  <TextField margin="normal" {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>

          {data.number && (
            <h5 style={{ marginBottom: "30px" }} align="center">
              №{data.number}
            </h5>
          )}
            <ObjectField
                label={"1. Объектінің атауы (Наименование объекта)"} fullWidth
                object={data.data?.object?.value}
                value={data.data?.object?.value?.id}
                onChange={(obj) => { handleChangeData('object', obj, 'facility') }}
            />
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>2. Негіздеме (Основание)</p>
            <TextField
              id="outlined-basic"
              label="Основание"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              disabled={isDisabledData}
              value={data.data?.rationale?.value}
              onChange={(e) =>
                handleChangeData("rationale", e.target.value, "rationale")
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              3. Сынама алынған орын (Место отбора)
            </p>
            <TextField
              id="outlined-basic"
              label="Место отбора"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              disabled={isDisabledData}
              value={data.data?.selectionPlace?.value}
              onChange={(e) =>
                handleChangeData(
                  "selectionPlace",
                  e.target.value,
                  "selection_place"
                )
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              4. Сынамалар алу мақсаты (Цель отбора):
            </p>
            <div
              style={{
                width: "50%",
                marginLeft: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  marginBottom: "10px",
                }}
                variant="text"
                type="button"
                disabled={isDisabledData}
                onClick={() => {
                  setIsOpenSamplingPurpose(true);
                }}
              >
                Выбрать
              </Button>
              <p style={{ marginLeft: "15px", maxWidth: "70%" }}>
                {data.data?.samplingPurpose?.value?.name || "цель не выбрана"}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              5. Алынған күні мен уақыты (Дата и время отбора)
            </p>
            <LocalizationProvider
              locale={ruLocale}
              dateAdapter={AdapterDateFns}
            >
              <DateTimePicker
                views={["day"]}
                label="Дата и время отбора"
                disabled={isDisabledData}
                value={data.data?.selectionDateTime?.value}
                onChange={(value) =>
                  handleChangeData("selectionDateTime", value, "selection_time")
                }
                minDate={new Date("1910-03-01")}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "50%", margin: "0 0 0 30px" }}
                    margin="normal"
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              6. Жеткізілген күні мен уақыты (Дата и время доставки)
            </p>
            <LocalizationProvider
              locale={ruLocale}
              dateAdapter={AdapterDateFns}
            >
              <DateTimePicker
                views={["day"]}
                label="Дата и время доставки"
                disabled={isDisabledData}
                value={data.data?.deliveryDateTime?.value}
                onChange={(value) =>
                  handleChangeData("deliveryDateTime", value, "delivery_time")
                }
                minDate={new Date("1910-03-01")}
                renderInput={(params) => (
                  <TextField
                    style={{ width: "50%", margin: "0 0 0 30px" }}
                    margin="normal"
                    {...params}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              7. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
              метод отбора)
            </p>
            <FormControl style={{ width: "50%", marginLeft: "30px" }}>
              <InputLabel id="Нормативный документ на метод отбора">
                Нормативный документ на метод отбора
              </InputLabel>
              <Select
                labelId="Нормативный документ на метод отбора"
                label="Нормативный документ на метод отбора"
                value={data.data?.standard?.value}
                disabled={isDisabledData}
                onChange={(e) =>
                  handleChangeData("standard", e.target.value, "nd_selection")
                }
              >
                {governmentStandardValues.governmentStandards.length !== 0 &&
                  governmentStandardValues.governmentStandards.map((obj) => {
                    return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              8. Тасымалдау жағдайы (Условия транспортировки)
            </p>
            <FormControl style={{ width: "50%", marginLeft: "30px" }}>
              <InputLabel id="Условия транспортировки">
                Условия транспортировки
              </InputLabel>
              <Select
                labelId="Условия транспортировки"
                label="Условия транспортировки"
                disabled={isDisabledData}
                value={data.data?.transportationCondition?.value}
                onChange={(e) =>
                  handleChangeData(
                    "transportationCondition",
                    e.target.value,
                    "transportation_conditions"
                  )
                }
              >
                {transportationConditionValues.transportationConditions
                  .length !== 0 &&
                  transportationConditionValues.transportationConditions.map(
                    (obj) => {
                      return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                    }
                  )}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>9. Сақтау жағдайы (Условия хранения)</p>
            <FormControl style={{ width: "50%", marginLeft: "30px" }}>
              <InputLabel id="Условия хранения">Условия хранения</InputLabel>
              <Select
                labelId="Условия хранения"
                label="Условия хранения"
                disabled={isDisabledData}
                value={data.data?.storageCondition?.value}
                onChange={(e) =>
                  handleChangeData(
                    "storageCondition",
                    e.target.value,
                    "storage_conditions"
                  )
                }
              >
                {storageConditionValues.storageConditions.length !== 0 &&
                  storageConditionValues.storageConditions.map((obj) => {
                    return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "30px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              10. Қосымша мәліметтер (Дополнительные сведения)
            </p>
            <TextField
              id="outlined-basic"
              label="Дополнительные сведения"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              disabled={isDisabledData}
              value={data.data?.additionalInfo?.value}
              onChange={(e) =>
                handleChangeData(
                  "additionalInfo",
                  e.target.value,
                  "additionalInfo"
                )
              }
            />
          </div>
          <TableContainer
            component={Paper}
            style={{ marginBottom: "30px", overflow: "hidden" }}
          >
            <Table aria-label="simple table" className="border-table-word">
              <TableHead>
                <TableRow>
                  <TableCell className="border-table-word">
                    Тiркелу нөмірi (Регистрационный номер)
                  </TableCell>
                  <TableCell className="border-table-word">
                    Алынған үлгілер тізімі (Перечень отобранных образцов)
                  </TableCell>
                  <TableCell className="border-table-word">
                    Алынған сынаманың саны, салмағы, көлемі (Количество, вес,
                    объем отобранных образцов)
                  </TableCell>
                  <TableCell className="border-table-word">
                    Шығарылған күні (Дата производства)
                  </TableCell>
                  <TableCell className="border-table-word">
                    Орама түрі мен мөр (пломба) нөмірі (Вид упаковки и номер
                    печати) (пломбы)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data?.samples?.value?.map((sample, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell className="border-table-word">
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          label="Регистрационный номер"
                          variant="outlined"
                          type="number"
                          disabled={isDisabledData}
                          value={sample.registrationNumber?.value}
                          onChange={(e) =>
                            handleChangeSample(
                              "registrationNumber",
                              e.target.value,
                              i,
                              "registrationNumber"
                            )
                          }
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          label="Перечень отобранных образцов"
                          variant="outlined"
                          disabled={isDisabledData}
                          value={sample.sample?.value}
                          onChange={(e) =>
                            handleChangeSample(
                              "sample",
                              e.target.value,
                              i,
                              "sample"
                            )
                          }
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          label="Количество, вес, объем отобранных образцов"
                          variant="outlined"
                          disabled={isDisabledData}
                          value={sample.quantityWeightSize?.value}
                          onChange={(e) =>
                            handleChangeSample(
                              "quantityWeightSize",
                              e.target.value,
                              i,
                              "quantityWeightSize"
                            )
                          }
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <LocalizationProvider
                          locale={ruLocale}
                          dateAdapter={AdapterDateFns}
                        >
                          <DatePicker
                            views={["day"]}
                            label="Дата производства"
                            disabled={isDisabledData}
                            value={sample.manufactureDate?.value}
                            onChange={(value) =>
                              handleChangeSample(
                                "manufactureDate",
                                value,
                                i,
                                "manufactureDate"
                              )
                            }
                            minDate={new Date("1910-03-01")}
                            renderInput={(params) => (
                              <TextField
                                style={{ width: "100%" }}
                                margin="normal"
                                {...params}
                                helperText={null}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell className="border-table-word">
                        <TextField
                          style={{ width: "100%" }}
                          id="outlined-basic"
                          label="Вид упаковки и номер печати (пломбы)"
                          variant="outlined"
                          type="sealTypeAndNumber"
                          disabled={isDisabledData}
                          value={sample.sealTypeAndNumber?.value}
                          onChange={(e) =>
                            handleChangeSample(
                              "sealTypeAndNumber",
                              e.target.value,
                              i,
                              "sealTypeAndNumber"
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              marginBottom: "30px",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                border: "1px solid black",
                padding: "5px 10px",
                borderRadius: "6px",
              }}
              variant="contained"
              disabled={isDisabledData}
              onClick={() => {
                data.data?.samples?.value
                  ? setData({
                      ...data,
                      data: {
                        ...data.data,
                        samples: {
                          key: "samples",
                          value: [...data.data?.samples?.value, {}],
                        },
                      },
                    })
                  : setData({
                      ...data,
                      data: {
                        ...data.data,
                        samples: { key: "samples", value: [{}] },
                      },
                    });
              }}
            >
              Добавить
            </Button>
          </div>
          <EmployeeField
            style={{ marginBottom: "5px" }}
            label={"Ответственный за отбор проб"}
            value={data.responsibleForProbesId}
            disabled={isDisabledData}
            object={data.responsibleForProbes}
            onChange={(val) => {
              setData({
                ...data,
                responsibleForProbesId: val?.id,
                responsibleForProbes: val,
              });
            }}
          />
          <FilialSelect
            style={{ marginBottom: "5px" }}
            label={"Филиал"}
            fullWidth
            disabled={isDisabledData}
            value={data.filialId}
            onChange={(val) => {
              handleChangeMulti({
                filialId: val,
                filialDivisionId:
                  Number(val) !== Number(data.filialId)
                    ? null
                    : data.filialDivisionId,
              });
            }}
          />
          <FilialDivisionSelect
            style={{ marginBottom: "5px" }}
            label={"Районное отделение"}
            fullWidth
            disabled={isDisabledData}
            value={data.filialDivisionId}
            filialId={data.filialId}
            onChange={(filialDivisionId, filialDivision) => {
              handleChangeMulti({
                filialDivisionId: filialDivisionId,
                filialId:
                  Number(filialDivision.filialId) !== Number(data.filialId)
                    ? filialDivision.filialId
                    : data.filialId,
              });
            }}
          />
          <Typography gutterBottom variant={"h5"}>
            Передача проб в лабораторию:
          </Typography>
          <DepartmentSelect
            disabled={isDisabledData}
            style={{ marginBottom: "5px" }}
            label={"Лаборатория"}
            fullWidth
            value={data.departmentId}
            onChange={(val) => handleChange("departmentId", val)}
          />
          <DivisionSelect
            style={{ marginBottom: "5px" }}
            label={"Отдел"}
            fullWidth
            value={data.divisionId}
            disabled={isDisabledData}
            onChange={(val) => handleChange("divisionId", val)}
            filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
          />
          <EmployeeField
            label={"Заведующий лаборатории/Начальник отдела"}
            fullWidth
            extraFilter={
              data.departmentId > 0
                ? {
                    departmentId: {
                      operand1: data.departmentId,
                      operator: "equals",
                    },
                  }
                : null
            }
            disabled={isDisabledData}
            object={data.responsible}
            value={data.responsibleId}
            onChange={(responsible) => {
              const obj = {
                responsible,
                responsibleId: responsible.id,
                departmentId: responsible.departmentId,
              };
              handleChangeMulti(obj);
            }}
          />
          <EmployeeField
            label={"Лаборант"}
            fullWidth
            object={data.performer}
            value={data.performerId}
            disabled={isDisabledPerformer}
            onChange={(performer) => {
              const obj = {
                performer,
                performerId: performer.id,
              };
              handleChangeMulti(obj);
            }}
          />
          <LoadingButton
            disabled={isDisabledData}
            loading={loading}
            onClick={handleSubmit}
          >
            Создать
          </LoadingButton>
        </Form>
      </BasicModalContainer>
    </>
  );
};
