import React from "react";
import { useLocation } from "react-router";

import ReferenceDisinfectionCRUDTable from "../../../components/CRUD/Tables/ReferenceDisinfectionCRUDTable";
import DashboardLayout from "../../../layouts/Dashboard";

const ReferenceDisinfectionTable = () => {
  const { pathname } = useLocation()

  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
      <ReferenceDisinfectionCRUDTable />
    </DashboardLayout>
  );
};

export default ReferenceDisinfectionTable;
