import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Box,
  Button,
  FormGroup,
  InputLabel,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingButton, TimePicker } from "@mui/lab";

import {
  getDepartureScheduleRequestByKey,
  editDepartureScheduleRequest
} from "../../../../redux/actions";
import {
  PageHeader,
  PageContainer,
  PageFooter,
  Form,
  FormSection,
  CustomDatePicker,
  SelectFieldModal,
  CustomTimePicker,
  SelectField,
  BasicTable,
  ButtonModal,
  NavLinkButton,
} from "../../../../components";
import {
  FilialDivisionSelect,
  FilialSelect,
} from "../../../../components/Select/domainSelects";

import DashboardLayout from "../../../../layouts/Dashboard";

export const DepartureScheduleRequestEditSection = ({ mode }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [requestId, setRequestId] = useState(id);
  const [request, setRequest] = useState({});

  const { departureScheduleRequest } = useSelector(
    (s) => s.departureScheduleRequest
  );

  const [educationServices, setEducationServices] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [objects, setObjects] = useState([]);
  const [acts, setActs] = useState([]);
  const [performerComment, setPerformerComment] = useState(null);
  const handlePerformerCommentChange = (e) =>
    setPerformerComment(e.target.value);

  const [filialId, setFilialId] = useState(null);
  const [filialDivisionId, setFilialDivisionId] = useState(null);
  const [authorComment, setAuthorComment] = useState(null);
  const [dateOfDeparture, setDateOfDeparture] = useState(new Date());

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      ...departureScheduleRequest,
      filialId,
      filialDivisionId,
      authorComment,
      performerComment,
      dateOfDeparture
    };

    await dispatch(editDepartureScheduleRequest(data));

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleDateOfDepartureChange = (data) => {
    setDateOfDeparture(data);
  };

  const handleAuthorCommentChange = (e) => setAuthorComment(e.target.value);

  useEffect(() => {
    dispatch(getDepartureScheduleRequestByKey(requestId)).then(setRequest);
  }, [dispatch, requestId]);

  useEffect(() => {
    setRequest(departureScheduleRequest);
    setObjects(departureScheduleRequest.objects);
    setSpecifications(departureScheduleRequest.specifications);
    setActs(departureScheduleRequest.acts);
    setFilialId(departureScheduleRequest.filialId);
    setFilialDivisionId(departureScheduleRequest.filialDivisionId);
    setAuthorComment(departureScheduleRequest.authorComment);
    setDateOfDeparture(departureScheduleRequest.dateOfDeparture)
    setPerformerComment(departureScheduleRequest.performerComment);

    console.log({ departureScheduleRequest });
  }, [departureScheduleRequest]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Номер заявки"
                  type="number"
                  value={request?.contractRequestNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={request?.contractRequestId}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  type="text"
                  value={request?.createdBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      label="Дата заявки"
                      value={request?.createdAt}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Объекты ({objects?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    { id: "name", label: t("object.tableItem.name") },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={objects}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Услуги ({specifications?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "group",
                      label: 'Группа',
                      render: ({ service }) => service?.group?.name,
                    },
                    {
                      id: "method",
                      label: 'Метод',
                      render: ({ service }) => service?.kind?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                    },
                    {
                      id: "contractCount",
                      label: t("specification.tableItem.quantityContract"),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={specifications}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FilialSelect
                  label={"Филиал"}
                  fullWidth
                  value={filialId}
                  onChange={(val) => {
                    setFilialId(val);
                    setFilialDivisionId(Number(val) !== Number(filialId) ? 
                      null
                      : filialDivisionId);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FilialDivisionSelect
                  label={"Районное отделение"}
                  fullWidth
                  value={filialDivisionId}
                  filialId={filialId}
                  onChange={(val, filialDivision) => {
                    setFilialDivisionId(val);
                    setFilialId(Number(filialDivision.filialId) !== Number(filialId)
                      ? filialDivision.filialId
                      : filialId);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Комментарий автора"
                  value={authorComment}
                  onChange={handleAuthorCommentChange}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Комментарий исполнителя"
                  value={performerComment}
                  onChange={handlePerformerCommentChange}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата выезда"
                  value={dateOfDeparture}
                  onChange={handleDateOfDepartureChange}
                />
              </Grid>
            </Grid>
          </FormSection>
        </PageContainer>
        <PageFooter>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Сохранить
          </LoadingButton>
        </PageFooter>
      </Form>
    </>
  );
};
