import { 
    SERVICE_EXPENSE_GET_REQUEST, SERVICE_EXPENSE_GET_SUCCESS, SERVICE_EXPENSE_GET_ERROR, 
    SERVICE_EXPENSE_GET_BY_KEY_REQUEST, SERVICE_EXPENSE_GET_BY_KEY_SUCCESS, SERVICE_EXPENSE_GET_BY_KEY_ERROR, 
    SERVICE_EXPENSE_ADD_REQUEST, SERVICE_EXPENSE_ADD_SUCCESS, SERVICE_EXPENSE_ADD_ERROR, 
    SERVICE_EXPENSE_EDIT_REQUEST, SERVICE_EXPENSE_EDIT_SUCCESS, SERVICE_EXPENSE_EDIT_ERROR, 
    SERVICE_EXPENSE_DELETE_REQUEST, SERVICE_EXPENSE_DELETE_SUCCESS, SERVICE_EXPENSE_DELETE_ERROR
} from "../constants/serviceExpenseConstants";

const initialState = {
    serviceExpenses: [],
    serviceExpense: {},
    loading: false,
    success: null,
    error: null,
    serviceExpenseRequestedCount: 0,
};

export const serviceExpenseReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_EXPENSE_GET_REQUEST:
            return {
                ...state,
                serviceExpenseRequestedCount: ++state.serviceExpenseRequestedCount,
                loading: true,
            };
        case SERVICE_EXPENSE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceExpenses: action.payload,
                success: true,
            };
        case SERVICE_EXPENSE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_EXPENSE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EXPENSE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceExpense: action.payload,
                success: true,
            };
        case SERVICE_EXPENSE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_EXPENSE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EXPENSE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_EXPENSE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_EXPENSE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EXPENSE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_EXPENSE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_EXPENSE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EXPENSE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_EXPENSE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
