import * as React from "react";
import {useEffect, useState} from "react";
import DialogLayout from "../../../components/Dialog/DialogLayout";
import {SelectField} from "../../../components";

const ChooseSamplesWithoutAct = ({open, setOpen, data, setChooseSampleObject, resultFormId}) => {
    const [options, setOptions] = useState([])

    const handleSelect = (e) => {
        setChooseSampleObject(e.target.value)
        setOpen(false)
    }

    useEffect(() => {
        if (data) {
            let probes = []
            const {probes: probesReferral} = data;

            if (probesReferral?.length)
                probesReferral.map(el => {
                    if (el?.data) {
                        let localData = typeof el.data === 'string' ? JSON.parse(el.data) : el.data
                        probes.push({...localData})
                    }
                })

            let options = probes?.map(val => {
                switch (resultFormId) {
                    case 325: {
                        return {
                            value: {
                                modelName: val?.List_of_samples_taken,
                                quantityVolume: val['Sample_weight,_volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 223: {
                        return {
                            value: {
                                quantityVolume: val['Sample_weight,_volume'],
                                sampleName: val['List_of_samples_taken'],
                                sampleWeightAndVolume: val['Sample_weight,_volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 227: {
                        return {
                            value: {
                                sampleName: val['List_of_samples_taken'],
                                sampleVolumeWeight: val['Sample_weight,_volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 228: {
                        return {
                            value: {
                                sampleName: val['List_of_samples_taken'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 210:
                    case 207: {
                        return {
                            value: {
                                sampleName: val['List_of_samples_taken'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 243: {
                        return {
                            value: {
                                sampleNameContainerBoxMarkingNumberOfSamples: val['List_of_samples_taken'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 212: {
                        return {
                            value: {
                                sampleNameQuantityVolumePlaceOfReceipt: val['Sample_Volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 333: {
                        return {
                            value: {
                                modelNameSupplierManufacturer: val['List_of_samples_taken'],
                                sampleWeightVolume: val['Sample_weight,_volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 335: {
                        return {
                            value: {
                                modelName: val['List_of_samples_taken'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 332: {
                        return {
                            value: {
                                modelNameSupplierManufacturer: val['List_of_samples_taken'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 220: {
                        return {
                            value: {
                                sampleNameSampleConcentrationQuantityVolume: val['Sample_weight,_volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }
                    case 250: {
                        return {
                            value: {
                                modelName: val['List_of_samples_taken'],
                                quantityVolumeBox: val['Sample_weight,_volume'],
                            },
                            label: `Регистрационный номер: ${val?.Registration_number}, перечень отобранных образцов: ${val?.List_of_samples_taken}`
                        }
                    }

                }
            })
            setOptions(options)
        }
    }, [data, resultFormId])

    return (
        <DialogLayout setOpen={setOpen} open={open} title="Выбор образца">

            <SelectField
                onChange={handleSelect} options={options} label="Показатели"
             />
        </DialogLayout>
    )
}

export default ChooseSamplesWithoutAct
