import { 
    DISEASE_GET_REQUEST, DISEASE_GET_SUCCESS, DISEASE_GET_ERROR, 
    DISEASE_GET_BY_KEY_REQUEST, DISEASE_GET_BY_KEY_SUCCESS, DISEASE_GET_BY_KEY_ERROR, 
    DISEASE_ADD_REQUEST, DISEASE_ADD_SUCCESS, DISEASE_ADD_ERROR, 
    DISEASE_EDIT_REQUEST, DISEASE_EDIT_SUCCESS, DISEASE_EDIT_ERROR, 
    DISEASE_DELETE_REQUEST, DISEASE_DELETE_SUCCESS, DISEASE_DELETE_ERROR
} from "../constants/diseaseConstants";

const initialState = {
    diseases: [],
    disease: {},
    loading: false,
    success: null,
    error: null,
    diseaseRequestedCount: 0,
};

export const diseaseReducer = (state = initialState, action) => {
    switch (action.type)     {
        case DISEASE_GET_REQUEST:
            return {
                ...state,
                diseaseRequestedCount: ++state.diseaseRequestedCount,
                loading: true,
            };
        case DISEASE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                diseases: action.payload,
                success: true,
            };
        case DISEASE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DISEASE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DISEASE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                disease: action.payload,
                success: true,
            };
        case DISEASE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DISEASE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DISEASE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DISEASE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DISEASE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DISEASE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DISEASE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DISEASE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DISEASE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DISEASE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
