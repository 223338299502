import React from "react";

import { Typography, Grid } from "@mui/material";

export const FormSection = (props) => {
  const { heading, children } = props;

  return (
    <Grid container mt={0.5} mb={0.5} {...props}>
      {heading && (
        <Typography component="h4" align="left" variant="h6">
          {heading}
        </Typography>
      )}
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

FormSection.defaultProps = {};
