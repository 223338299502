import { 
    SAMPLE_PRESERVATION_METHOD_GET_REQUEST, SAMPLE_PRESERVATION_METHOD_GET_SUCCESS, SAMPLE_PRESERVATION_METHOD_GET_ERROR, 
    SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_REQUEST, SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_SUCCESS, SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_ERROR, 
    SAMPLE_PRESERVATION_METHOD_ADD_REQUEST, SAMPLE_PRESERVATION_METHOD_ADD_SUCCESS, SAMPLE_PRESERVATION_METHOD_ADD_ERROR, 
    SAMPLE_PRESERVATION_METHOD_EDIT_REQUEST, SAMPLE_PRESERVATION_METHOD_EDIT_SUCCESS, SAMPLE_PRESERVATION_METHOD_EDIT_ERROR, 
    SAMPLE_PRESERVATION_METHOD_DELETE_REQUEST, SAMPLE_PRESERVATION_METHOD_DELETE_SUCCESS, SAMPLE_PRESERVATION_METHOD_DELETE_ERROR
} from "../constants/samplePreservationMethodConstants";

const initialState = {
    samplePreservationMethods: [],
    samplePreservationMethod: {},
    loading: false,
    success: null,
    error: null,
    samplePreservationMethodRequestedCount: 0,
};

export const samplePreservationMethodReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SAMPLE_PRESERVATION_METHOD_GET_REQUEST:
            return {
                ...state,
                samplePreservationMethodRequestedCount: ++state.samplePreservationMethodRequestedCount,
                loading: true,
            };
        case SAMPLE_PRESERVATION_METHOD_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                samplePreservationMethods: action.payload,
                success: true,
            };
        case SAMPLE_PRESERVATION_METHOD_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                samplePreservationMethod: action.payload,
                success: true,
            };
        case SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLE_PRESERVATION_METHOD_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLE_PRESERVATION_METHOD_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SAMPLE_PRESERVATION_METHOD_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLE_PRESERVATION_METHOD_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLE_PRESERVATION_METHOD_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SAMPLE_PRESERVATION_METHOD_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLE_PRESERVATION_METHOD_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLE_PRESERVATION_METHOD_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SAMPLE_PRESERVATION_METHOD_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
