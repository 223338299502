import { 
    CONTRACT_STATE_GET_REQUEST, CONTRACT_STATE_GET_SUCCESS, CONTRACT_STATE_GET_ERROR, 
    CONTRACT_STATE_GET_BY_KEY_REQUEST, CONTRACT_STATE_GET_BY_KEY_SUCCESS, CONTRACT_STATE_GET_BY_KEY_ERROR, 
    CONTRACT_STATE_ADD_REQUEST, CONTRACT_STATE_ADD_SUCCESS, CONTRACT_STATE_ADD_ERROR, 
    CONTRACT_STATE_EDIT_REQUEST, CONTRACT_STATE_EDIT_SUCCESS, CONTRACT_STATE_EDIT_ERROR, 
    CONTRACT_STATE_DELETE_REQUEST, CONTRACT_STATE_DELETE_SUCCESS, CONTRACT_STATE_DELETE_ERROR
} from "../constants/contractStateConstants";

const initialState = {
    contractStates: [],
    contractState: {},
    loading: false,
    success: null,
    error: null,
    contractStateRequestedCount: 0,
};

export const contractStateReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_STATE_GET_REQUEST:
            return {
                ...state,
                contractStateRequestedCount: ++state.contractStateRequestedCount,
                loading: true,
            };
        case CONTRACT_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractStates: action.payload,
                success: true,
            };
        case CONTRACT_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_STATE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_STATE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractState: action.payload,
                success: true,
            };
        case CONTRACT_STATE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_STATE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_STATE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_STATE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_STATE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_STATE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_STATE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_STATE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_STATE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_STATE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
