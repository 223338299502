import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl, InputLabel, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import { Table } from "../../../components/PDFGenerator/Table";
import { GovernmentStandardsSelect, TransportationConditionSelect } from "../../../components/Select/domainSelects";

const DirectionMicrobioForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          1. Сынаманың атауы (Наименование пробы)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование пробы"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.sampleName?.value}
          onChange={(e) => handleChangeData('sampleName', e.target.value, 'sampleName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Зерттелушінің Т.А.Ә. (болған жағдайда) (Ф.И.О. (при наличии) обследуемого)
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.examineName?.value}
          onChange={(e) => handleChangeData('examineName', e.target.value, 'examineName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Жасы (Возраст)
        </p>
        <TextField
          id="outlined-basic"
          label="Возраст"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.age?.value}
          onChange={(e) => handleChangeData('age', e.target.value, 'age')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>4. Мекен жайы (Домашний адрес)</p>
        <TextField
          id="outlined-basic"
          label="Домашний адрес"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.homeAdress?.value}
          onChange={(e) => handleChangeData('homeAdress', e.target.value, 'homeAdress')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>5. Жұмыс орны (Место работы)</p>
        <TextField
          id="outlined-basic"
          label="Место работы"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.workPlace?.value}
          onChange={(e) => handleChangeData('workPlace', e.target.value, 'workPlace')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Диагнозы (Диагноз)
        </p>
        <TextField
          id="outlined-basic"
          label="Диагноз"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.diagnosis?.value}
          onChange={(e) => handleChangeData('diagnosis', e.target.value, 'diagnosis')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>7. Үлгіні жіберген мекеменің атауы (Наименование учреждения направившего образец)</p>
        <TextField
          id="outlined-basic"
          label="Наименование учреждения направившего образец"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.placeSender?.value}
          onChange={(e) => handleChangeData('placeSender', e.target.value, 'placeSender')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Сынаманы жеткізу күні және уақыты (Дата и время доставки материала)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время доставки материала"
            disabled={isDisabledData}
            value={data.data?.deliveryDateTime?.value}
            onChange={(value) => {
              handleChangeData('deliveryDateTime', value, 'deliveryDateTime')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Антибиотикті қабылдау (Применение антибиотиков)
        </p>
        <TextField
          id="outlined-basic"
          label="Применение антибиотиков"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.antibioticsInfo?.value}
          onChange={(e) => handleChangeData('antibioticsInfo', e.target.value, 'antibioticsInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>10. Қаны бірінші рет/қайталап алынды (Кровь взято первично/повторно)</p>
        <FormControl style={{ width: "50%", marginLeft: "30px" }}>
          <InputLabel id="Кровь взято первично/повторно">Кровь взято первично/повторно</InputLabel>
          <Select
            labelId="Кровь взято первично/повторно"
            label="Кровь взято первично/повторно"
            disabled={isDisabledData}
            value={data.data?.bloodInfo?.value}
            onChange={(e) => handleChangeData('bloodInfo', e.target.value, 'bloodInfo')}
          >
            <MenuItem value={'Первично'}>{'Первично'}</MenuItem>
            <MenuItem value={'Повторно'}>{'Повторно'}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          11. Сынамаларды зерттеу мақсаты (Цель исследования пробы)
        </p>
        <TextField
          id="outlined-basic"
          label="Цель исследования пробы"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.researchPurpose?.value}
          onChange={(e) => handleChangeData('researchPurpose', e.target.value, 'researchPurpose')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          12. Зерттеу әдісі, реакция түрі (Метод исследования, вид реакции)
        </p>
        <TextField
          id="outlined-basic"
          label="Метод исследования, вид реакции"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.researchMethod?.value}
          onChange={(e) => handleChangeData('researchMethod', e.target.value, 'researchMethod')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          13. Тасымалдау жағдайы (Условия транспортировки)
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <TransportationConditionSelect
            label={"Условия транспортировки"}
            fullWidth
            value={data.data?.transportationCondition?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('transportationCondition', obj, 'transportation_conditions') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          14. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
          метод отбора)
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <GovernmentStandardsSelect
            label={"Нормативный документ на метод отбора"}
            fullWidth
            value={data.data?.standard?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('standard', obj, 'nd_selection') }}
          />
        </div>
      </div>
    </div >
  );
};

export default DirectionMicrobioForm;
