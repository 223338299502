const objectFieldMatching = {
    adress: 'object',
    organization_name: 'object',
    sample_obtaining_place: 'selectionPlace',
    place_of_selection: 'selectionPlace',
    research_goal: 'samplingPurpose',
    selection_date: 'selectionDateTime',
    delivery_date: 'deliveryDateTime',
    nd_on_research_method: 'standard',
    normative_document: 'standard',
    normative_document_selection: 'standard',
    normative_document_measurements: 'standard',
    transport_conditions: 'transportationCondition',
    storage_conditions: 'storageCondition',
}

const isObjects = ['adress', 'research_goal', 'transport_conditions', 'storage_conditions', 'organization_name']
const isArray = ['nd_on_research_method', 'normative_document', 'normative_document_selection', 'normative_document_measurements']
const isDate = ['delivery_date', 'selection_date']

export const mappingActToProtocolData = (data, fields, formId, actNumber) => {
    let formData = {};

    fields?.map(el => {
        if (objectFieldMatching.hasOwnProperty(el.id) && data[objectFieldMatching[el.id]]?.value) {
            if (isObjects?.includes(el.id)) {
                formData[el.id] = data[objectFieldMatching[el.id]]?.value?.name;
            } else if (isArray.includes(el.id)) {
                formData[el.id] = data[objectFieldMatching[el.id]]?.value?.map(el => el?.name).join(', ');
            } else if (isDate.includes(el.id)) {
                formData[el.id] = new Date(data[objectFieldMatching[el.id]]?.value);
            } else {
                formData[el.id] = data[objectFieldMatching[el.id]]?.value;
            }
        }
    })

    if (actNumber === "ActDisinfectant" && formId === '27') {
        if (data.samples.value?.length) {
            const tableBody = data.samples.value.map(el => {
                return `
                    <tr><td><div>${el.registrationNumber?.value || ""}</div></td><td><div>${el.sealTypeAndNumber?.value || ""}<br></div></td><td><div>3</div></td><td><div>4<br></div></td><td><div>5<br></div></td><td><div>6</div></td><td><div>7</div></td></tr>
                `
            }) ;
                formData.form_table_v2 = `
                    <table><tbody><tr> 
                    <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000"><div>Тіркеу нөмірі<br>(Регистрационный номер)</div></td> <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000"><div>Үлгінің атауы. Үлгі алынған орын.<br>(Наименование образца. Место отбора образца)</div></td> <td colspan="5" style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000"><div>Микробиологиялық көрсеткіштер<br>(Микробиологические показатели)</div></td> </tr>
                    ${tableBody.join('')}  
                    </tbody> </table>
                    `
        }
    }

    return formData
}
