import { LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CircularProgress, Paper, Toolbar, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useDialogs from "../../../components/Dialog/useDialogs";
import { DepartmentSelect, FilialDivisionSelect, FilialSelect } from "../../../components/Select/domainSelects";
import { checkRoles } from "../../../functions/checkRoles";
import useLoader from "../../../hooks/useLoader";
import { desinfectionReferenceCRUD } from "../../../http";
import DashboardLayout from "../../../layouts/Dashboard";
import { REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_SIGNED, REQUEST_STATE_ID_SIGNING } from "../../../redux/constants/requestStateConstants";
import ReferenceDisinfectionPDF from "./ReferenceDisinfectionPDF";
import ReferenceDisinfectionPDFGenerator from "./ReferenceDisinfectionPDFGenerator";
import ReferenceDisinfectionResult from './ReferenceDisinfectionResult'
import ReferenceDisinsectionForm from "./ReferenceDisinsectionForm";

const ReferenceDisinfectionDetail = () => {
  //configStart
  const referenceName = 'ReferenceDisinfection'
  const kazakhName = 'Наряд бойынша дезинфекция жүргізу туралы АНЫҚТАМА'
  const name = 'СПРАВКА о проведении дезинфекции по наряду'
  //configEnd
  const { alert, DIALOG } = useDialogs();
  const { t } = useTranslation();
  const brigadeType = useSelector(state => state.brigadeType)
  const { id } = useParams();
  const isNew = useMemo(() => !(id > 0), [id]);
  const [data, setData] = useState({});
  const { start: startSubmit, stop: stopSubmit, loading: loadingSubmit } = useLoader(false);
  const { start: workflowStart, stop: workflowStop, loading: workflowLoading } = useLoader(false);
  const stateCode = data.state?.code;
  const style = { marginLeft: 10 }
  const [createDirectionOpen, setCreateDirectionOpen] = useState(false)

  const handleChange = useCallback((key, value) => {
    setData({ ...data, [key]: value })
  }, [data]);
  const handleChangeMulti = useCallback((obj) => {
    setData({ ...data, ...obj })
  }, [data]);

  const handleChangeData = useCallback((key, value, key2) => {
    setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
  }, [data]);

  const handleChangeSample = useCallback((key, value, i, key2) => {
    const array = data.data?.samples?.value.slice(0)
    array[i][key] = { 'key': key2, value }
    setData({
      ...data, data: {
        ...data.data,
        samples: { key: 'samples', value: array }
      }
    })
  }, [data]);

  console.log(data)

  const dispatch = useDispatch();
  const { push } = useHistory();

  const schema = {}

  const { loading: fetchLoading, start: fetchStart, stop: fetchStop } = useLoader(true);
  const fetch = useCallback(() => {
    if (isNew) {
      fetchStop();
      return;
    }
    fetchStart();
    desinfectionReferenceCRUD.get(id)
      .then((data) => {
        const actData = typeof data.data === 'string' ? JSON.parse(data.data) : {}
        let diagnosis = typeof data.diagnosis === 'string' ? JSON.parse(data.diagnosis) : {}
        setData({ ...data, data: { ...actData }, diagnosis });
      })
      .catch(alert)
      .finally(fetchStop)
  }, [id, fetchStart, fetchStop, isNew]);
  useEffect(fetch, [fetch]);

  const submit = useCallback(() => new Promise((resolve, reject) => {
    if (!data.filialId) {
      alert('Филиал нужно заполнить!');
      return
    }
    // const brigadeObj = brigadeType.brigadeTypes.find(a => {
    //   return a.name === brigadeName
    // })
    const newData = { ...data }
    startSubmit();
    (isNew
      ? desinfectionReferenceCRUD.create({
        createdAt: new Date(),
        number: referenceName,
        schema,
        // type: brigadeObj,
        // typeId: brigadeObj.id,
        ...newData
      })
      : desinfectionReferenceCRUD.edit({
        id: id,
        number: referenceName,
        schema,
        // type: brigadeObj,
        // typeId: brigadeObj.id,
        ...newData,
      })
    ).then((response) => {
      if (isNew && response.id) {
        push(`/${referenceName}Detail/${response.id}`)
      } else {
        fetch();
      }
      resolve();
      // onSubmit();
    })
      .catch((error) => {

        reject();
        alert(error);
      })
      .finally(stopSubmit)
  }), [data, startSubmit, stopSubmit, isNew, fetch, push, brigadeType]);

  const setStatus = (lastStateCode, nextStateCode, thenAction = fetch) => {
    const validates = [];
    const validateNull = (key, label) => {
      if (!data[key]) validates.push(label + " нужно заполнить!");
    };
    // if (nextStateCode === REQUEST_STATE_ID_PROBESELECTION) {
    //   validateNull("responsibleForProbesId", "Ответственный за отбор проб");
    // } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
    //   validateNull("performerId", "Лаборант");
    // } 
    if (nextStateCode === REQUEST_STATE_ID_SIGNING) {
      validateNull("departmentId", "Лаборатория");
    }
    if (validates.length > 0) {
      alert(validates.join("\n"));
      return;
    }
    workflowStart();
    submit()
      .then(() => desinfectionReferenceCRUD.workflowRun(id, lastStateCode, nextStateCode))
      .then(thenAction)
      .catch(alert)
      .finally(workflowStop);
  };

  // const hasResults = useMemo(() => data.results && data.results.length > 0, [data]);
  // const hasProtocols = useMemo(() => data.protocols && data.protocols.length > 0, [data]);

  const [createResultOpen, setCreateResultOpen] = useState(false);
  // const [deleteActOpen, setDeleteActOpen] = useState(false);

  // useEffect(() => {
  //   if (brigadeType.brigadeTypes.length === 0) {
  //     dispatch(getBrigadeTypes())
  //   }
  // }, [brigadeType.brigadeTypes.length])

  const [isDisabledData, setIsDisabledData] = useState(false)
  const [isDisabledPerformer, setIsDisabledPerformer] = useState(false)
  useEffect(() => {
    const returnIsDisabledData = () => {
      return ([REQUEST_STATE_ID_SIGNED].includes(stateCode) || !checkRoles('SuperAdministrator', 'Administrator', 'Specialist'))
    }
    const returnIsDisabledPerformer = () => {
      return (([REQUEST_STATE_ID_SIGNED].includes(stateCode) || !checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') || stateCode === undefined))
    }
    setIsDisabledData(returnIsDisabledData())
    setIsDisabledPerformer(returnIsDisabledPerformer())
  }, [stateCode])
  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
      <DIALOG />
      <Button
        style={{
          border: "1px solid black",
          padding: "5px 10px",
          borderRadius: "6px",
          marginBottom: '10px'
        }}
        variant="contained"
        type="button"
        onClick={() => { push(`/${referenceName}`) }}
      >
        Вернуться
      </Button>
      {fetchLoading
        ? <CircularProgress />
        : stateCode === REQUEST_STATE_ID_SIGNED
          ? <ReferenceDisinfectionPDF data={data} name={name} kazakhName={kazakhName}>
            <ReferenceDisinfectionPDFGenerator data={data} name={name} kazakhName={kazakhName} />
          </ReferenceDisinfectionPDF>
          : <Box>
            <Paper style={{ marginBottom: '15px' }}>
                <Toolbar>
                  <Typography style={{ marginRight: "auto" }} variant={"h6"}>Статус справки: {data.state?.name || "создаётся"}</Typography>
                  {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_SIGNING)}>Отправить на подпись</LoadingButton>}
                  {REQUEST_STATE_ID_SIGNING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_SIGNING, REQUEST_STATE_ID_SIGNED)}>Подписать</LoadingButton>}
                  {(REQUEST_STATE_ID_SIGNING === stateCode) && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && <LoadingButton
                    style={style} variant={"outlined"} loading={workflowLoading}
                    onClick={() => setCreateResultOpen(true)}
                  >Добавить запись в журнал</LoadingButton>}
                  {/* {REQUEST_STATE_ID_RESEARCH === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && <Tooltip title={!hasResults ? "Необходима запись в журнале" : (hasProtocols ? "Протокол уже создан" : "Создание протокола")}>
                    <div style={style}>
                      <LoadingButton variant={"outlined"} loading={workflowLoading} onClick={() => setProtocolAddOpen(true)} disabled={!hasResults || hasProtocols}>Создать протокол</LoadingButton>
                    </div>
                  </Tooltip>}
                  {REQUEST_STATE_ID_DECLINED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_CREATED)}>Пересоздать</LoadingButton>}
                  {REQUEST_STATE_ID_DECLINED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setDeleteActOpen(true)} color={"warning"}>Удалить</LoadingButton>} */}
                </Toolbar>
              </Paper>
            <h4 style={{ textAlign: "center" }}>
              {kazakhName}
            </h4>
            <h4 style={{ textAlign: "center" }}>
              {name} (от)
            </h4>
            <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
              <DateTimePicker views={['day']} label="от"
                disabled={isDisabledData}
                value={data.data?.dateOfReference?.value}
                onChange={(value) => {
                  handleChangeData('dateOfReference', value, 'dateOfReference')
                }}
                minDate={new Date('1910-03-01')} renderInput={(params) =>
                  <TextField margin="normal"  {...params} helperText={null} />
                } />
            </LocalizationProvider>
            <ReferenceDisinsectionForm isDisabledData={isDisabledData} data={data} handleChange={handleChange} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample} />
            <FilialSelect
              style={{ marginBottom: "5px" }}
              label={"Филиал"}
              fullWidth
              disabled={isDisabledData}
              filialId={checkRoles('AdministratorReadonly') ? null : JSON.parse(localStorage.employee)?.filialId}
              value={data.filialId}
              onChange={(val) => {
                handleChangeMulti({
                  filialId: val,
                  filialDivisionId: Number(val) !== Number(data.filialId) ? null : data.filialDivisionId
                })
              }}
            />
            <FilialDivisionSelect
              style={{ marginBottom: "5px" }}
              label={"Районное отделение"}
              fullWidth
              disabled={isDisabledData}
              value={data.filialDivisionId}
              filialId={data.filialId}
              onChange={(filialDivisionId, filialDivision) => {
                handleChangeMulti({
                  filialDivisionId: filialDivisionId,
                  filialId: Number(filialDivision.filialId) !== Number(data.filialId) ? filialDivision.filialId : data.filialId
                })
              }}
            />
            <DepartmentSelect
              disabled={isDisabledData}
              style={{ marginBottom: "5px" }}
              label={"Лаборатория"}
              fullWidth
              value={data.departmentId}
              onChange={(val) => handleChange('departmentId', val)}
            />
            <LoadingButton style={{
              border: "1px solid black",
              padding: "5px 10px",
              borderRadius: "6px",
              marginRight: '15px'
            }}
              variant="contained"
              disabled={isDisabledData}
              onClick={submit}
              loading={loadingSubmit}>
              {isNew ? "Создать" : "Сохранить"}
            </LoadingButton>
          </Box>}
          {
          createResultOpen && <ReferenceDisinfectionResult
            title={'Создание журнала справки'}
            onClose={() => setCreateResultOpen(false)}
            onSubmit={(response) => {
              handleChange("resultId", response)
              submit({ ...data, resultId: response })
            }}
            ddd={data}
          />
        }
    </DashboardLayout>
  );
};

export default ReferenceDisinfectionDetail;
