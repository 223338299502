import { 
    GOVERNMENT_STANDARD_GET_REQUEST, GOVERNMENT_STANDARD_GET_SUCCESS, GOVERNMENT_STANDARD_GET_ERROR, 
    GOVERNMENT_STANDARD_GET_BY_KEY_REQUEST, GOVERNMENT_STANDARD_GET_BY_KEY_SUCCESS, GOVERNMENT_STANDARD_GET_BY_KEY_ERROR, 
    GOVERNMENT_STANDARD_ADD_REQUEST, GOVERNMENT_STANDARD_ADD_SUCCESS, GOVERNMENT_STANDARD_ADD_ERROR, 
    GOVERNMENT_STANDARD_EDIT_REQUEST, GOVERNMENT_STANDARD_EDIT_SUCCESS, GOVERNMENT_STANDARD_EDIT_ERROR, 
    GOVERNMENT_STANDARD_DELETE_REQUEST, GOVERNMENT_STANDARD_DELETE_SUCCESS, GOVERNMENT_STANDARD_DELETE_ERROR
} from "../constants/governmentStandardConstants";

const initialState = {
    governmentStandards: [],
    governmentStandard: {},
    loading: false,
    success: null,
    error: null,
    governmentStandardRequestedCount: 0,
};

export const governmentStandardReducer = (state = initialState, action) => {
    switch (action.type)     {
        case GOVERNMENT_STANDARD_GET_REQUEST:
            return {
                ...state,
                governmentStandardRequestedCount: ++state.governmentStandardRequestedCount,
                loading: true,
            };
        case GOVERNMENT_STANDARD_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                governmentStandards: action.payload,
                success: true,
            };
        case GOVERNMENT_STANDARD_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GOVERNMENT_STANDARD_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GOVERNMENT_STANDARD_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                governmentStandard: action.payload,
                success: true,
            };
        case GOVERNMENT_STANDARD_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GOVERNMENT_STANDARD_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GOVERNMENT_STANDARD_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GOVERNMENT_STANDARD_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GOVERNMENT_STANDARD_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GOVERNMENT_STANDARD_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GOVERNMENT_STANDARD_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GOVERNMENT_STANDARD_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GOVERNMENT_STANDARD_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GOVERNMENT_STANDARD_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
