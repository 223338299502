import { 
    FILIAL_GET_REQUEST, FILIAL_GET_SUCCESS, FILIAL_GET_ERROR, 
    FILIAL_GET_BY_KEY_REQUEST, FILIAL_GET_BY_KEY_SUCCESS, FILIAL_GET_BY_KEY_ERROR, 
    FILIAL_ADD_REQUEST, FILIAL_ADD_SUCCESS, FILIAL_ADD_ERROR, 
    FILIAL_EDIT_REQUEST, FILIAL_EDIT_SUCCESS, FILIAL_EDIT_ERROR, 
    FILIAL_DELETE_REQUEST, FILIAL_DELETE_SUCCESS, FILIAL_DELETE_ERROR
} from "../constants/filialConstants";

const initialState = {
    filials: [],
    filial: {},
    loading: false,
    success: null,
    error: null,
    filialRequestedCount: 0,
};

export const filialReducer = (state = initialState, action) => {
    switch (action.type)     {
        case FILIAL_GET_REQUEST:
            return {
                ...state,
                filialRequestedCount: ++state.filialRequestedCount,
                loading: true,
            };
        case FILIAL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                filials: action.payload,
                success: true,
            };
        case FILIAL_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                filial: action.payload,
                success: true,
            };
        case FILIAL_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FILIAL_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FILIAL_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FILIAL_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
