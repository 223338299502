import {useTranslation} from "react-i18next";
import CRUDTable from "../CRUDTable";
import {protocolCRUD} from "../../../http";
import React from "react";
import {getProtocolForms} from "../../../redux/actions/protocolFormActions";
import {getDepartments, getDivisions, getFilials} from "../../../redux/actions";
import {getFilialDivisions} from "../../../redux/actions/filialDivisionActions";
import {getProtocolStates} from "../../../redux/actions/protocolStateActions";

export default function ProtocolCRUDTable({...props}) {
    const {t} = useTranslation();

    const columns = [
        {title: t("protocolList.tableItem.id"), dataIndex: "id", key: "id"},
        {title: t("protocolList.tableItem.number"), dataIndex: "number", key: "number"},
        {
            title: t("protocolList.tableItem.state"),
            dataIndex: "state",
            key: "state",
            render: (unknown, row) => ({Created: 'Создан', Signed: 'Подписан', Declined: "Отклонён"}[row.state?.code])
        },
        {
            title: t("protocolList.tableItem.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
            render: (unknown, row) => row.createdAt && Intl.DateTimeFormat('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }).format(new Date(row.createdAt))
        },
        {
            title: t("protocolList.tableItem.form"),
            dataIndex: "form",
            key: "form",
            render: (unknown, row) => row.form ? (row.form.number.length == 2 ? `0${row.form.number}` : row.form.number) : <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: t("protocolList.tableItem.department"),
            dataIndex: "department",
            key: "department",
            render: (unknown, row) => row.department ? row.department.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: t("protocolList.tableItem.division"),
            dataIndex: "division",
            key: "division",
            render: (unknown, row) => row.division ? row.division.name : <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: t("protocolList.tableItem.performer"),
            dataIndex: "performer",
            key: "performer",
            render: (unknown, row) => row.performer ? row.performer.fullName :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: t("protocolList.tableItem.responsible"),
            dataIndex: "responsible",
            key: "responsible",
            render: (unknown, row) => row.responsible ? row.responsible.fullName :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            dataIndex: "filial",
            key: "filial",
            title: "Филиал",
            render: (unknown, row) => {
                return row.filial?.name
            }
        },
        {
            dataIndex: "filialDivision",
            key: "filialDivision",
            title: "Районное отделение",
            render: (unknown, row) => {
                return row.filialDivision?.name
            }
        }
    ]

    const filterFields = [
        {
            title: "Номер протокола",
            key: "formId",
            compareType: "equals",
            reducerKey: "protocolForm",
            reducerArrayKey: "protocolForms",
            reducerAction: getProtocolForms,
            operandPosition: 1,
            optionLabelKey: "number",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Дата создания от",
            key: "createdAt",
            operandPosition: 1,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Дата создания до",
            key: "createdAt",
            operandPosition: 2,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            operandPosition: 1,
            parentKey: 'filialId',
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Отдел",
            key: "divisionId",
            compareType: "equals",
            reducerKey: "division",
            reducerArrayKey: "divisions",
            reducerAction: getDivisions,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Статус",
            key: "stateId",
            compareType: "equals",
            reducerKey: "protocolState",
            reducerArrayKey: "protocolStates",
            reducerAction: getProtocolStates,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
    ];

    const searchFields = [
        {
            title: "Номер",
            key: "number",
            compareType: "equals",
            operandPosition: 1,
            filterType: "normal",
        }]

    return (
        <>
            <CRUDTable
                search={protocolCRUD.search}
                searchFields={searchFields}
                columns={columns.filter(({filial, filialDivision}) => {
                    const employee = window.localStorage.employee ? JSON.parse(window.localStorage.employee) : null;
                    if (filial?.id && employee?.filialId) {
                        if (filial.id !== employee.filialId) return false
                    }
                    if (filialDivision?.id && employee?.filialDivisionId) {
                        if (filialDivision?.id !== employee?.filialDivisionId) return false
                    }
                    return true
                })}
                filterFields={filterFields}
                rowClassNameHandler={(record) => {
                    if (record.state.code === "Signed") {
                        return 'rowSigned'
                    }
                }}
                {...props}
            />
        </>
    )
}
