import m_15_2285 from '././petropavl/KZ.М.15.2285.svg'
import t_15_0798 from '././petropavl/KZ.T.15.0798.svg'
import t_15_0798_b from '././petropavl/KZ.T.15.0798 black.svg'
import t_15_2500 from '././gabita_mus/KZ.T.15.2500.svg'
import t_15_2500_b from '././gabita_mus/KZ.T.15.2500 black.svg'
import t_15_2499 from '././esil/KZ.T.15.2499.svg'
import t_15_2499_b from '././esil/KZ.T.15.2499 black.svg'



export default [
  m_15_2285,
  t_15_0798,
  t_15_0798_b,
  t_15_2500,
  t_15_2500_b,
  t_15_2499,
  t_15_2499_b
]
