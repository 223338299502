import { useMemo } from "react"

export default function useRoles() {
    const defaultRoles = {
        SuperAdministrator: "SuperAdministrator",
        Administrator: "Administrator",
        Committee: "Committee",
        Performer: "Performer"
    };

    const getRoles = () => {
        let r = localStorage.getItem("roles");

        if (!r || r.length == 0) {
            r = "[]";
        }
    
        return JSON.parse(r) || [];
    }

    const getEmployee = () => {
        let e = localStorage.getItem("employee");

        if (!e) {
            return null;
        }
    
        return JSON.parse(e);
    }

    const roles = getRoles();
    const employee = getEmployee();

    const isInRole = (role) => {
        return getRoles().find(_ => _ == role) != null;
    }

    const isInRoles = (values) => {
        return values.find(_ => isInRole(_));
    }

    const isAdministrator = () => {
        return isInRoles([defaultRoles.SuperAdministrator, defaultRoles.Administrator]);
    }

    const isCommittee = () => {
        return isInRole(defaultRoles.Committee);
    }

    const isPerformer = () => {
        return isInRole(defaultRoles.Performer);
    }

    return {
        roles,
        isInRole,
        isInRoles,
        isAdministrator,
        isCommittee,
        getEmployee,
        isPerformer,
        defaultRoles,
        employee
    }
}