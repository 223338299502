import {
  EMPLOYEE_GET_REQUEST,
  EMPLOYEE_GET_SUCCESS,
  EMPLOYEE_GET_ERROR,
  EMPLOYEE_GET_BY_KEY_REQUEST,
  EMPLOYEE_GET_BY_KEY_SUCCESS,
  EMPLOYEE_GET_BY_KEY_ERROR,
  EMPLOYEE_ADD_REQUEST,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_ADD_ERROR,
  EMPLOYEE_EDIT_REQUEST,
  EMPLOYEE_EDIT_SUCCESS,
  EMPLOYEE_EDIT_ERROR,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_ERROR,
  EMPLOYEE_GET_AUTHORIZED_REQUEST,
  EMPLOYEE_GET_AUTHORIZED_SUCCESS,
  EMPLOYEE_GET_AUTHORIZED_ERROR,
} from "../constants/employeeConstants";

const initialState = {
  employees: [],
  employeeCount: 0,
  employee: {},
  authorizedEmployee: {},
  loading: false,
  error: null,
  employeeRequestedCount: 0,
};

export const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPLOYEE_GET_REQUEST:
      return {
        ...state,
        loading: true,
        employeeRequestedCount: ++state.employeeRequestedCount,
      };
    case EMPLOYEE_GET_SUCCESS:
      return {
        ...state,
        employees: action.payload,
        // employeeCount: action.payload
        loading: false,
      };
    case EMPLOYEE_GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EMPLOYEE_GET_AUTHORIZED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_GET_AUTHORIZED_SUCCESS:
      return {
        ...state,
        authorizedEmployee: action.payload,
        loading: false,
      };
    case EMPLOYEE_GET_AUTHORIZED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case EMPLOYEE_GET_BY_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_GET_BY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
      };
    case EMPLOYEE_GET_BY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EMPLOYEE_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_ADD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case EMPLOYEE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case EMPLOYEE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EMPLOYEE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
