import React, {useCallback, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Grid,
  Typography,
  Divider,
} from "@mui/material";

import {
  editDesinsectionRequest,
  getFilials,
} from "../../../../redux/actions";
import {
  PageContainer,
  Form,
  FormSection,
  CustomDatePicker,
  SelectFieldModal,
  BasicTable,
  ButtonModal,
  ConditionalContainer,
} from "../../../../components";

import { RequestStateChecker } from "./../../../../functions";
import {
  DesinfectionReferenceCreateModal,
  ResultCreateModal,
} from "../../../../screens";
import {
  getBrigades,
  getResults,
  getDesinfectionReferences,
} from "../../../../redux/actions";
import EmployeeField from "../../../../components/Relations/EmployeeField";
import {DepartmentSelect} from "../../../../components/Select/domainSelects";
import {DddDisinsectionCreateModal} from "../Modals/DddDisinsectionCreateModal";

export const DDDRequestInfoSection = ({ request, setRequest }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [requestId, setRequestId] = useState(id);

  const { desinsectionRequest } = useSelector((s) => s.desinsectionRequest);
  const { employees } = useSelector((s) => s.employee);

  const stateCode = desinsectionRequest?.state?.code?.toLowerCase();
  const state = new RequestStateChecker(stateCode);

  const [specifications, setSpecifications] = useState([]);
  const [objects, setObjects] = useState([]);
  const [brigades, setBrigades] = useState([]);
  const [desinfectionReferences, setDesinfectionReferences] = useState([]);
  const [results, setResults] = useState([]);

  const [specialistId, setSpecialistId] = useState({});
  const [specialist, setSpecialist] = useState({});
  const [departureDate, setDepartureDate] = useState(request?.departureDate);

  const handleChangeObject = useCallback(
      (objectKey, value) => {
        setRequest({ ...request, [objectKey]: value, [`${objectKey}Id`]: value.id });
      },
      [request]
  );

  const handleDepartureDateChange = (v) => setDepartureDate(v);

  const handleSpecialistUpdate = async (e) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      ...request,
      id: requestId,
      specialistId,
      departureDate,
    };

    // Updating
    await dispatch(editDesinsectionRequest(data));

    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };


  useEffect(() => {
    (async () => {
      dispatch(
        getBrigades({
          filter: {
            desinsectionRequestId: {
              operand1: desinsectionRequest?.id,
              operator: "equals",
            },
          },
          paging: { take: 10 },
        })
      ).then(setBrigades);

      dispatch(
        getResults({
          filter: {
            desinsectionRequestId: {
              operand1: desinsectionRequest?.id,
              operator: "equals",
            },
          },
          paging: { take: 10 },
        })
      ).then(setResults);

      dispatch(
        getDesinfectionReferences({
          filter: {
            desinsectionRequestId: {
              operand1: desinsectionRequest?.id,
              operator: "equals",
            },
          },
          paging: { take: 10 },
        })
      ).then(setDesinfectionReferences);
    })();
  }, [dispatch, desinsectionRequest]);

  useEffect(() => {
    setObjects(desinsectionRequest.objects);
    setSpecifications(desinsectionRequest.specifications);
    setSpecialist(desinsectionRequest.specialist);
    setDepartureDate(desinsectionRequest.departureDate);
    setSpecifications(desinsectionRequest.specifications);

  }, [desinsectionRequest]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Номер заявки"
                  type="number"
                  value={desinsectionRequest?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={desinsectionRequest?.contractRequestId}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  type="text"
                  value={desinsectionRequest?.createdBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      label="Дата заявки"
                      value={desinsectionRequest?.createdAt}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormSection>

          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Объекты ({objects?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    { id: "name", label: t("object.tableItem.name") },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={objects}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Услуги ({specifications?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "group",
                      label: 'Группа',
                      render: ({ service }) => service?.group?.name,
                    },
                    {
                      id: "method",
                      label: 'Метод',
                      render: ({ service }) => service?.kind?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                    },
                    {
                      id: "contractCount",
                      label: t("specification.tableItem.quantityContract"),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={specifications}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <SelectFieldModal
                  label="Филиал"
                  cells={[
                    { id: "id", label: t("filial.tableItem.id") },
                    {
                      id: "name",
                      label: t("filial.tableItem.name"),
                      render: ({ name }) => name,
                    },
                  ]}
                  actionFn={getFilials}
                  valueName="id"
                  value={request?.filial?.name}
                  disabled
                  // setValue={setLocalFilialId}
                  // storeData={filials}
                  // storeCount={filialCount || 0}
                />
              </Grid>
              <Grid item xs={4}>
                <DepartmentSelect
                    label={"Лаборатория"}
                    disabled
                    value={request?.departmentId}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDatePicker
                    label="Дата выезда"
                    value={request?.dateOfDeparture}
                    // onChange={handleDateOfDepartureChange}
                    disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Назначение ответственных" mb={2}>
            <Grid container spacing={2}>
              <EmployeeField
                  label={"Зав. лаборатории"}
                  object={request?.performer}
                  value={request?.performer?.id}
                  onChange={e => handleChangeObject('performer', e)}
              />
              <EmployeeField
                  style={{ marginBottom: "5px" }}
                  label={"Ответсвенный специалист ДДД"}
                  object={request?.specialist}
                  value={request?.specialistId}
                  onChange={(val) => {
                    handleChangeObject('specialist', val)
                  }}
              />
            </Grid>
          </FormSection>

          <ConditionalContainer
            condition={
              false
              //state.isProcessing() || state.isResearch() || state.isFinished()
            }
          >
            <Divider style={{ margin: "50px 0px" }} />
            {/* <FormSection heading="Назначение ответственных за оказание услуги">
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <SelectFieldModal
                    label="Специалист ДДД"
                    cells={[
                      { id: "id", label: t("employee.tableItem.id") },
                      {
                        id: "fullName",
                        label: t("employee.tableItem.fullName"),
                        render: (row) => row?.fullName || "-",
                      },
                    ]}
                    actionFn={getEmployees}
                    valueName="id"
                    value={specialist?.fullName}
                    setValue={setSpecialistId}
                    storeData={employees}
                    disabled={!state.isProcessing()}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6}>
                      <CustomDatePicker
                        label="Дата выезда"
                        value={departureDate}
                        disabled={!state.isProcessing()}
                        onChange={handleDepartureDateChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTimePicker
                        label="Время выезда"
                        value={departureDate}
                        disabled={!state.isProcessing()}
                        onChange={handleDepartureDateChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {state.isProcessing() && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LoadingButton
                      loading={loading}
                      onClick={handleSpecialistUpdate}
                    >
                      Сохранить
                    </LoadingButton>
                  </Grid>
                </Grid>
              )}
            </FormSection> */}
          </ConditionalContainer>

          <ConditionalContainer
            condition={state.isResearch() || state.isFinished()}
          >
            <Divider style={{ margin: "50px 0px" }} />
            <FormSection heading={`Наряды (${brigades?.length || 0})`} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("brigade.tableItem.id") },
                      { id: "number", label: t("brigade.tableItem.number") },
                      {
                        id: "state",
                        label: t("brigade.tableItem.state"),
                        render: (row) => row?.state?.name,
                      },
                      {
                        id: "addressLine",
                        label: t("disinfectionReference.tableItem.addressLine"),
                      },
                      {
                        id: "createdAt",
                        label: t("brigade.tableItem.createdAt"),
                      },
                    ]}
                    rows={brigades}
                    button={
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <ButtonModal modal={DddDisinsectionCreateModal}>
                            Добавить
                          </ButtonModal>
                        </Grid>
                      </Grid>
                    }
                    disabled={!state.isResearch()}
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection
              heading={`Справки (${desinfectionReferences?.length || 0})`}
              mb={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      {
                        id: "id",
                        label: t("disinfectionReference.tableItem.id"),
                      },
                      {
                        id: "number",
                        label: t("disinfectionReference.tableItem.number"),
                      },
                      {
                        id: "fullName",
                        label: t("disinfectionReference.tableItem.fullName"),
                      },
                      {
                        id: "state",
                        label: t("disinfectionReference.tableItem.state"),
                        render: (row) => row?.state?.name,
                      },

                      {
                        id: "createdAt",
                        label: t("disinfectionReference.tableItem.createdAt"),
                      },
                    ]}
                    rows={desinfectionReferences}
                    button={
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <ButtonModal modal={DesinfectionReferenceCreateModal}>
                            Добавить
                          </ButtonModal>
                        </Grid>
                      </Grid>
                    }
                    disabled={!state.isResearch()}
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection heading={`Журналы (${results?.length || 0})`} mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("result.tableItem.id") },
                      { id: "number", label: t("result.tableItem.number") },
                      {
                        id: "state",
                        label: t("result.tableItem.state"),
                        render: (row) => row?.state?.name,
                      },
                      {
                        id: "createdAt",
                        label: t("result.tableItem.createdAt"),
                      },
                    ]}
                    rows={results}
                    button={
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <ButtonModal action="createByDesinfectionRequest" data={request} modal={ResultCreateModal}>
                            Добавить
                          </ButtonModal>
                        </Grid>
                      </Grid>
                    }
                    disabled={!state.isResearch()}
                  />
                </Grid>
              </Grid>
            </FormSection>
          </ConditionalContainer>
        </PageContainer>
      </Form>
    </>
  );
};
