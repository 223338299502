import { 
    AN_OBJECT_REFERRAL_GET_REQUEST, AN_OBJECT_REFERRAL_GET_SUCCESS, AN_OBJECT_REFERRAL_GET_ERROR, 
    AN_OBJECT_REFERRAL_GET_BY_KEY_REQUEST, AN_OBJECT_REFERRAL_GET_BY_KEY_SUCCESS, AN_OBJECT_REFERRAL_GET_BY_KEY_ERROR, 
    AN_OBJECT_REFERRAL_ADD_REQUEST, AN_OBJECT_REFERRAL_ADD_SUCCESS, AN_OBJECT_REFERRAL_ADD_ERROR, 
    AN_OBJECT_REFERRAL_EDIT_REQUEST, AN_OBJECT_REFERRAL_EDIT_SUCCESS, AN_OBJECT_REFERRAL_EDIT_ERROR, 
    AN_OBJECT_REFERRAL_DELETE_REQUEST, AN_OBJECT_REFERRAL_DELETE_SUCCESS, AN_OBJECT_REFERRAL_DELETE_ERROR,
    AN_OBJECT_REFERRAL_WORKFLOW_RUN_REQUEST, AN_OBJECT_REFERRAL_WORKFLOW_RUN_SUCCESS, AN_OBJECT_REFERRAL_WORKFLOW_RUN_ERROR
} from "../constants/anObjectReferralConstants";

const initialState = {
    anObjectReferrals: [],
    anObjectReferral: {},
    loading: false,
    success: null,
    error: null,
    anObjectReferralRequestedCount: 0,
};

export const anObjectReferralReducer = (state = initialState, action) => {
    switch (action.type)     {
        case AN_OBJECT_REFERRAL_GET_REQUEST:
            return {
                ...state,
                anObjectReferralRequestedCount: ++state.anObjectReferralRequestedCount,
                loading: true,
            };
        case AN_OBJECT_REFERRAL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                anObjectReferrals: action.payload,
                success: true,
            };
        case AN_OBJECT_REFERRAL_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_REFERRAL_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_REFERRAL_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                anObjectReferral: action.payload,
                success: true,
            };
        case AN_OBJECT_REFERRAL_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_REFERRAL_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_REFERRAL_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_REFERRAL_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_REFERRAL_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_REFERRAL_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_REFERRAL_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_REFERRAL_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_REFERRAL_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_REFERRAL_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        
        case AN_OBJECT_REFERRAL_WORKFLOW_RUN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_REFERRAL_WORKFLOW_RUN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_REFERRAL_WORKFLOW_RUN_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
