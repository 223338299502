import React, {useCallback, useEffect, useState} from "react";
import {NavLink, useHistory, useParams} from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import {Button, Checkbox, CircularProgress, Grid} from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {DISEASE_INDEX_DETAIL_ROUTE, DISEASE_INDEX_LIST_ROUTE, FORM_MAP_LIST_ROUTE} from "../../../routes";
import {diseaseIndexCRUD, diseaseIndexServiceCRUD} from "../../../http";
import TextField from "@mui/material/TextField";
import ResearchListFieldNoInp from "../../../components/Relations/ResearchListFieldNoInp";
import { validateReferralSpecificationsForSubmit } from "../../../functions/validations";

const DiseaseIndexDetailForm = ({ mode }) => {
    const history = useHistory()
    const {id} = useParams();
    const [isNew,setIsNew] = useState(id==="new")
    const dispatch = useDispatch()
    const [fields, setFields] = useState({})
    const [specifications, setSpecifications] = useState([])
    const {loading, start, stop} = useLoader(false)
    const fetch = useCallback(() => {
        start()
        if(!isNew){
            diseaseIndexCRUD.get(id).then(data => {
                setFields(state=>{
                    return {
                        ...state,
                        name:data.name || null,
                        description:data.description || null,
                    }
                })
                setSpecifications(data.services)
                stop()
            })
        }else{
            stop()
        }

    }, [dispatch, id])

    const renderItemGrid = (children, xs = 12) => (
        <Grid item xs={xs}>
            {children}
        </Grid>
    );

    const handleChange = useCallback(
        (key, value) => setSpecifications({...specifications, [key]: value}),
        [specifications]
    );

    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: submitLoading,
    } = useLoader(false);

    const submitAsync = async () => {
        const services = specifications?.map(_ => {
            return {
                "serviceCode": _.service.code,               
                "serviceId": _.serviceId                     
            };
        });
        console.log(specifications);

        if (!isNew) {
            await diseaseIndexCRUD.edit({
                id:id,
                ...fields,
                services: services
            });
        } else {
            var resp = await diseaseIndexCRUD.create({
                ...fields,
                services: services
            });
            setIsNew(false);
            history.push(DISEASE_INDEX_DETAIL_ROUTE(resp.id));
        }

    }


    useEffect(fetch, [fetch])
    const submit = useCallback(() => {
        // console.log(specifications)
        submitAsync();
        // if (!isNew){
        //     .catch(err=>{
        //         alert(err)
        //     }).then({
        //         if(specifications !== null){
        //             console.log(specifications)
    
        //             specifications?.forEach((e) => {
        //                 console.log(e)
        //                 diseaseIndexServiceCRUD.create({
        //                     "serviceCode": e.code,
        //                     "indexId": id,
        //                     "serviceId": e.id                     
        //                 }).catch(err=>{
        //                     alert(err)
        //                 })
        //             })
        //         }
    
        //     })
        // }else{
        //     diseaseIndexCRUD.create({
        //         ...fields
        //     }).catch(err=>{
        //         alert(err)
        //     }).then((resp)=>{
        //         setIsNew(false);
        //         if(specifications !== null){
        //             specifications?.forEach((e) => {
        //                 diseaseIndexServiceCRUD.create({
        //                     "serviceCode": e.code,
        //                     "indexId": resp.id,
        //                     "serviceId": e.id                     
        //                 }).catch(err=>{
        //                     alert(err)
        //                 })
        //             })
        //         }                
        //     })
        //}


    }, [id, dispatch, fields, specifications])
    const deleteForm = useCallback(()=>{
        diseaseIndexCRUD.delete(id).then(()=>{
            history.push(FORM_MAP_LIST_ROUTE)
        }).catch(err=>{
            alert(err)
        })
    },[id])
    return (
        <DashboardLayout>
            {loading ? <CircularProgress/> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar style={{display:"flex", justifyContent:"space-between"}}>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew?"Создание":"Редактирование"} Показателя <br/>
                            </Typography>
                            <div>
{/* 
                            {!isNew &&
                                <Button onClick={()=>{deleteForm()}} style={{background:"#ff4343", marginRight: "20px"}}>
                                    Удалить
                                </Button>
                                }
                                 */}
                                <NavLink style={{color: '#2f9e69'}} to={DISEASE_INDEX_LIST_ROUTE}><span
                                    style={{color: "2f9e69"}}>Вернуться к таблице</span></NavLink>
                            </div>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{paddingTop: 16}}>
                    <Paper style={{paddingBottom: 20}}>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12}>
                                    <TextField required margin="normal"
                                               fullWidth id="personnelNumber" name="personnelNumber" label="Название"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           name: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.name || ""}
                                    />
                                </Grid>
                            <Grid item xs={12}>
                                <TextField required margin="normal"
                                           fullWidth id="personnelNumber" name="personnelNumber" label="Описание"
                                           onChange={(ev) => {
                                               setFields((state) => {
                                                   return {
                                                       ...state,
                                                       description: ev.target.value
                                                   }
                                               })
                                           }}
                                           value={fields.description || ""}
                                />
                            </Grid>
                            {renderItemGrid(
                            <Grid style={{paddingTop: 16}}>
                            <ResearchListFieldNoInp
                                value={specifications}
                                label='Список исследований'
                                // error={validationError}
                                onChange={(val) => {
     
                                    setSpecifications(val)}}
                                submitLoading={submitLoading}
                                onSubmit={async () => {
                                    // const valid = validateReferralSpecificationsForSubmitmit(specifications, null, null, alert, REQUEST_TYPE_ID_AnObjectReferralRequest,
                                    //     data?.researchStartDateTime, data?.researchEndDateTime);

                                    // if (!valid) {
                                        // setValidationError(true)
                                        // return;
                                    // }
                                    // setValidationError(false)
                                    submit();
                                }
                                }
                            />
                          </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
                <LoadingButton style={{marginTop: "10px"}} variant="contained" onClick={() => {
                    submit()
                }}>
                    Сохранить
                </LoadingButton>
            </>}


        </DashboardLayout>
    )
}

export default DiseaseIndexDetailForm