import React, {useCallback, useEffect, useMemo, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import EmployeeCRUDTable from "../../components/CRUD/Tables/EmployeeCRUDTable";
import {useTranslation} from "react-i18next";
import useReload from "../../hooks/useReload";
import {DISEASE_INDEX_DETAIL_ROUTE, EMPLOYEE_DETAIL_ROUTE} from "../../routes";
import {diseaseIndexCRUD, employeesCRUD} from "../../http";
import {useHistory} from "react-router-dom";
import DiseaseIndexCRUDTable from "../../components/CRUD/Tables/DiseaseIndexCRUDTable";

const DiseaseIndexList = () => {
    const {t} = useTranslation()
    const {reload, reloadValue} = useReload();
    const history = useHistory()
    const detailAction =(id)=>{
        history.push(DISEASE_INDEX_DETAIL_ROUTE(id),{
            review:true
        })
    }

    const editAction =(id)=>{
        history.push(DISEASE_INDEX_DETAIL_ROUTE(id),{
            review:false
        })
    }

    const deleteAction =async(id)=>{
        try{
            await diseaseIndexCRUD.delete(id)
            reload()
        }catch (err){

        }
    }
    return (
        <DashboardLayout>
            <DiseaseIndexCRUDTable
                onClickDetailsButton={detailAction}
                onClickDeleteButton={deleteAction}
                onClickEditButton={editAction} reloadValue={reloadValue}/>
        </DashboardLayout>
    )

};

export default DiseaseIndexList;
