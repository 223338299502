import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
  formatDate,
  MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../../functions/formatters";
import { withoutDepartureRequestCRUD } from "../../../../http";
import CRUDTable from "../../CRUDTable";
import {
  contractRequestFilterFields,
  contractRequestSearchFields
} from "../../../../sections/Filters/ContractRequestFilter";

export const WithoutDepartureRequestCRUDTable = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const columns = [
    { title: t("request.tableItem.id"), dataIndex: "id", key: "id" },
    {
      title: t("request.tableItem.contractRequest"),
      dataIndex: "contractRequestId",
      key: "contractRequestId",
      render: (row) => row || "-",
    },
    {
      title: t("request.tableItem.number"),
      dataIndex: "number",
      key: "number",
      render: (row) => {
        return row || "-"
      },
    },
    {
      title: t("request.tableItem.filial"),
      dataIndex: "filial",
      key: "filial",
      render: (row) => {
        return row?.name || "-"
      },
    },
    {
      title: t("request.tableItem.filialDivision"),
      dataIndex: "filialDivision",
      key: "filialDivision",
      render: (row) => {
          return row?.name || "-"
      },
    },
    {
      title: t("request.tableItem.status"),
      dataIndex: "state",
      key: "state",
      render: (row) => row?.name || "-",
    },
    {
      title: t("request.tableItem.createdBy"),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (row) => row?.fullName || "-",
    },
    {
      title: t("request.tableItem.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (row) => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) || "-",
    },
  ];

  const searchFields = [
    ...contractRequestSearchFields,
  ];

  const filterFields = [
    ...contractRequestFilterFields
  ];

  return (
    <CRUDTable
      search={withoutDepartureRequestCRUD.search}
      columns={columns}
      filterFields={filterFields}
      searchFields={searchFields}
      toolbar={false}
      onClickDetailsButton={( id ) => {
        push(`${pathname}/${id}`)
      }}
      {...props}
    />
  );
};
