import React, {useEffect, useState} from "react";
import DashboardLayout from "../../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSamplingPurposes, addSamplingPurpose, editSamplingPurpose, deleteSamplingPurpose, getSamplingPurposeByKey } from "../../../redux/actions/samplingPurposeActions";
import CreateSamplingPurpose from "./Popups/CreateSamplingPurpose";
import SamplingPurposeDetails from "./Popups/SamplingPurposeDetails";
import EditSamplingPurpose from "./Popups/EditSamplingPurpose";
import RemoveSamplingPurpose from "./Popups/RemoveSamplingPurpose";

function createData(id, name, code, description) {
    return {id, name, code, description};
}

let rows = [
    createData(1, "abc", "abc", "abc"),
];
const SamplingPurposeList = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("samplingPurposeList.tableItem.id")},
        {text: t("samplingPurposeList.tableItem.name")},
        {text: t("samplingPurposeList.tableItem.code")},
        {text: t("samplingPurposeList.tableItem.description")},
        {text: t("samplingPurposeList.tableItem.actions")},
    ];
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);
    const setCreateHandler = async () => {
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setCreateOpen(true);
        setId(null);
    }
    const createSubmit = async (_) => {
        await dispatch(addSamplingPurpose(_, query));
    }
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getSamplingPurposeByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setDetailsOpen(true);
        setId(id);
    }
    
    const [editOpen, setEditOpen] = useState(false);
    const setEditHandler = async (id) => {
        await dispatch(getSamplingPurposeByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setEditOpen(true);
        setId(id);
    }
    const editSubmit = async (_) => {
        await dispatch(editSamplingPurpose(_, query));
    }
    const [removeOpen, setRemoveOpen] = useState(false);
    const setRemoveHandler = async (id) => {
        await dispatch(getSamplingPurposeByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setRemoveOpen(true);
        setId(id);
    }
    const removeSubmit = async (_) => {
        await dispatch(deleteSamplingPurpose(_.id, query));
    }

    const localData = false;
    const state = useSelector((state) => state.samplingPurpose);
    if (!localData && state.samplingPurposes)
    {
        rows = state.samplingPurposes;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getSamplingPurposes(query));
        }
    }, [dispatch]);
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("samplingPurposeList.searchLabel")}/>
                <Button color="primary" variant="contained" onClick={() => setCreateHandler()}>
                    {t("samplingPurposeList.createText")}
                </Button>
            </Box>
            <TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {
                return {
                    id: _.id,
                    name: _.name,
                    code: _.code,
                    description: _.description,
                }
            })} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>,<EditIcon onClick={() => setEditHandler(_.id)}/>,<DeleteForeverIcon onClick={() => setRemoveHandler(_.id)}/>]}/>
            <CreateSamplingPurpose action={'create'} title={t("samplingPurposeList.createText")} open={createOpen} setOpen={setCreateOpen} onSubmit={createSubmit}/>
            <SamplingPurposeDetails action={'details'} title={t("samplingPurposeList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
            <EditSamplingPurpose action={'edit'} title={t("samplingPurposeList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit}/>
            <RemoveSamplingPurpose action={'remove'} title={t("samplingPurposeList.removeText")} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/>
        </DashboardLayout>
    )
};

export default SamplingPurposeList;
