import { 
    TRANSPORTATION_CONDITION_GET_REQUEST, TRANSPORTATION_CONDITION_GET_SUCCESS, TRANSPORTATION_CONDITION_GET_ERROR, 
    TRANSPORTATION_CONDITION_GET_BY_KEY_REQUEST, TRANSPORTATION_CONDITION_GET_BY_KEY_SUCCESS, TRANSPORTATION_CONDITION_GET_BY_KEY_ERROR, 
    TRANSPORTATION_CONDITION_ADD_REQUEST, TRANSPORTATION_CONDITION_ADD_SUCCESS, TRANSPORTATION_CONDITION_ADD_ERROR, 
    TRANSPORTATION_CONDITION_EDIT_REQUEST, TRANSPORTATION_CONDITION_EDIT_SUCCESS, TRANSPORTATION_CONDITION_EDIT_ERROR, 
    TRANSPORTATION_CONDITION_DELETE_REQUEST, TRANSPORTATION_CONDITION_DELETE_SUCCESS, TRANSPORTATION_CONDITION_DELETE_ERROR
} from "../constants/transportationConditionConstants";

const initialState = {
    transportationConditions: [],
    transportationCondition: {},
    loading: false,
    success: null,
    error: null,
    transportationConditionRequestedCount: 0,
};

export const transportationConditionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case TRANSPORTATION_CONDITION_GET_REQUEST:
            return {
                ...state,
                transportationConditionRequestedCount: ++state.transportationConditionRequestedCount,
                loading: true,
            };
        case TRANSPORTATION_CONDITION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                transportationConditions: action.payload,
                success: true,
            };
        case TRANSPORTATION_CONDITION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORTATION_CONDITION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORTATION_CONDITION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                transportationCondition: action.payload,
                success: true,
            };
        case TRANSPORTATION_CONDITION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORTATION_CONDITION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORTATION_CONDITION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TRANSPORTATION_CONDITION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORTATION_CONDITION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORTATION_CONDITION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TRANSPORTATION_CONDITION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORTATION_CONDITION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORTATION_CONDITION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TRANSPORTATION_CONDITION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
