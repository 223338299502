export const actToObjectReferralMap = (data, type) => {
    const dataFromObjectReferral = {}
            Object.keys(data).map(el => {
                switch (data[el].key) {
                    case 'facility': {
                        dataFromObjectReferral.targetObject = data[el].value
                        break;
                    }
                    case 'object': {
                        dataFromObjectReferral.sampleSentOrganizationName = data[el].value
                        break;
                    }
                    case 'nd_selection': {
                        dataFromObjectReferral.standard2 = data[el].value
                        break;
                    }
                    case 'samplingPurpose': {
                        dataFromObjectReferral.purposeOfSelectionId = data[el].value?.id ? data[el].value?.id : null
                        break;
                    }
                    case "storage_conditions": {
                        dataFromObjectReferral.storageConditionId = data[el].value?.id ? data[el].value?.id : null
                        break;
                    }
                    case "preservationMethod": {
                        dataFromObjectReferral.samplePreservationMethodId = data[el].value?.id ? data[el].value?.id : null
                        break;
                    }
                    case "transportation_conditions": {
                        dataFromObjectReferral.transportationConditionId = data[el].value?.id ? data[el].value?.id : null
                        break;
                    }
                    case "selection_place": {
                        dataFromObjectReferral.samplingLocation = data[el].value
                        break;
                    }
                    case "samples": {
                        switch (type) {
                            case 'ActLand':
                            case 'ActWater': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Sample_registration_number: el.registrationNumber?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                ['Sample_weight,_volume']: el.size?.value,
                                                ['Type_of_packaging_and_number_of_the_seal']: el.sealTypeAndNumber?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                            case 'ActZoonotic': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Registration_number: el.sampleNumber?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                ['Number_of_samples_taken,_volume']: el.quantitySize?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                            case 'ActDisinfectant': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Registration_number: el.registrationNumber?.value,
                                                Number_of_samples_taken: el.quantityWeightSize?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                Type_of_packaging_and_number_of_the_seal: el.sealTypeAndNumber?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                            case 'ActProducts': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Registration_number: el.registrationNumber?.value,
                                                The_number_of_product_samples_taken_with_an_indication_of_the_manufacturer_series: el.quantity?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                Date_of_manufacture: el.manufactureInfo?.value,
                                                ['Expiry_date_(before)']: el.expirationDate?.value,
                                                ['Sample_weight']: el.weightSize?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                            case 'ActWaste': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Registration_number: el.registrationNumber?.value,
                                                Number_of_samples_taken: el.quantity?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                Type_of_packaging_and_number_of_the_seal: el.sealTypeAndNumber?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                            case 'ActRadio': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Registration_number: el.registrationNumber?.value,
                                                Number_of_samples_taken: el.quantity?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                'Sample_weight,_volume': el.weightSize?.value,
                                                'Type_of_packaging_and_number_of_the_seal': el.sealTypeAndNumber?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                            case 'ActSamplingReport': {
                                let probes = [];
                                if (Array.isArray(data[el].value)) {
                                    data[el].value.map(el => {
                                        probes.push({
                                            data: {
                                                Registration_number: el.registrationNumber?.value,
                                                Date_of_manufacture: el.manufactureInfo?.value,
                                                List_of_samples_taken: el.sample?.value,
                                                Number_of_samples_taken: el.quantity?.value,
                                                ['Sample_weight,_volume']: el.weightSize?.value,
                                            }
                                        })
                                    })
                                }
                                dataFromObjectReferral.probes = probes
                                break;
                            }
                        }
                        break;
                    }
                }
            })
    return dataFromObjectReferral
}
