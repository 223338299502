import React, {useEffect, useMemo, useState} from 'react';
import TextField from "@mui/material/TextField";
import useReload from '../../../hooks/useReload';
import CRUDTable from "../CRUDTable";
import {entityCRUD} from "../../../http/CRUD";
import CreateEntity from './Popups/CreateEntity';
import EditEntity from './Popups/EditEntity';
import DetailsEntity from './Popups/DetailsEntity';
import RemoveEntity from './Popups/RemoveEntity';

export default function EntityCRUDTable({ hasId = true,
  createEntityTitle,
  editEntityTitle,
  detailsEntityTitle,
  removeEntityTitle,
  canCreate = true,
  canEdit = true,
  canDetails = true,
  canRemove = true,
  createFields,
  editFields,
  detailsFields,
  removeFields,
  entity,
  setEntity,
  setValue,
  clearEntity,
  ...props }) {
  const crud = useMemo(() => entityCRUD(props.entityType), [props.entityType]);
  const columns = [
  ];

  hasId && columns.push({title: "ID", dataIndex: 'id', key: 'id'});
  props.columns && columns.push(...props.columns);

  const searchFields = [
  ];

  hasId && searchFields.push(
    {
      title: "ID",
      key: "id",
      compareType: "equals",
      operandPosition: 1,
      filterType: "normal"
    });
  props.searchFields && searchFields.push(...props.searchFields);

  const { reload, reloadValue } = useReload();
  const [loading, setLoading] = useState(false);
  const load = async (func) => {
    await setLoading(true);
    func && await func();
    await setLoading(false);
  };
  const [createOpen, setCreateOpen] = useState(false);
  const createHandler = async () => {
    if (!canCreate) {
      return;
    }

    await setCreateOpen(true);
  }
  const createSubmit = async (_) => {
    if (!canCreate) {
      return;
    }

    await load(async () => {
      await crud.create(entity);
    });
    await reload();
  };
  const [editOpen, setEditOpen] = useState(false);
  const editHandler = async (id) => {
    if (!canEdit) {
      return;
    }

    await load(async () => {
      const data = await crud.get(id);
      await setEntity(data);
    });
    await setEditOpen(true);
  }
  const editSubmit = async (_) => {
    if (!canEdit) {
      return;
    }

    await load(async () => {
      await crud.edit(entity);
    });
    await reload();
  }
  const [detailsOpen, setDetailsOpen] = useState(false);
  const detailsHandler = async (id) => {
    if (!canDetails) {
      return;
    }

    await load(async () => {
      const data = await crud.get(id);
      await setEntity(data);
    });
    await setDetailsOpen(true);
  }
  const [removeOpen, setRemoveOpen] = useState(false);
  const removeHandler = async (id) => {
    if (!canRemove) {
      return;
    }

    await load(async () => {
      const data = await crud.get(id);
      await setEntity(data);
    });
    await setRemoveOpen(true);
  }
  const removeSubmit = async (id) => {
    if (!canRemove) {
      return;
    }

    await load(async () => {
      await crud.delete(entity.id);
    });
    await reload();
  }

  const createEntityFields = [    
  ];
  createFields && createEntityFields.push(...createFields);

  const editEntityFields = [
  ];
  hasId && editEntityFields.push(
    "ID: " + entity.id
  );
  editFields && editEntityFields.push(...editFields);

  const detailsEntityFields = [
  ];
  hasId && detailsEntityFields.push(
    "ID: " + entity.id,
  );
  detailsFields && detailsEntityFields.push(...detailsFields);

  const removeEntityFields = [
  ];
  hasId && removeEntityFields.push(
    "ID: " + entity.id,
  );
  removeFields && removeEntityFields.push(...removeFields);

  return <>
    <CRUDTable
      {...props}
      search={crud.search}
      columns={columns}
      searchFields={searchFields}
      reloadValue={reloadValue}
      onClickCreateButton={canCreate ? createHandler : null}
      onClickEditButton={canEdit ? editHandler : null}
      onClickDetailsButton={canDetails ? detailsHandler : null}
      onClickDeleteButton={canRemove ? removeHandler : null}
    />
    {canCreate && <CreateEntity
      title={createEntityTitle || 'Добавление'}
      loading={loading}
      clear={clearEntity}
      open={createOpen} 
      setOpen={setCreateOpen} 
      onSubmit={createSubmit}      
      fields={createEntityFields}
    />}
    {canEdit && <EditEntity
      title={editEntityTitle || 'Изменение'}
      loading={loading}
      clear={clearEntity}
      open={editOpen} 
      setOpen={setEditOpen} 
      onSubmit={editSubmit}
      fields={editEntityFields}
    />}
    {canDetails && <DetailsEntity
      title={detailsEntityTitle || 'Просмотр'}
      clear={clearEntity}
      open={detailsOpen} 
      setOpen={setDetailsOpen} 
      fields={detailsEntityFields}
    />}
    {canRemove && <RemoveEntity
      title={removeEntityTitle || 'Удаление'}
      loading={loading}
      clear={clearEntity}
      open={removeOpen} 
      setOpen={setRemoveOpen} 
      onSubmit={removeSubmit}
      fields={removeEntityFields}
    />}
  </>
}