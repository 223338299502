import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import DateTimePicker from "@mui/lab/DateTimePicker";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGeoObjects } from "../../../redux/actions/geoObjectActions";
import { getEmployees } from "../../../redux/actions/employeeActions";
import ReferenceDisinsectionForm from "../../DDD/ReferenceDisinfection/ReferenceDisinsectionForm"

import { BasicModalContainer, Form } from "./../../../components";
import EmployeeField from "../../../components/Relations/EmployeeField";
import { toUIJson } from "../../../functions";

export const DesinfectionReferenceCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(null);
  const [number, setNumber] = useState("");
  const [createdAt, setCreatedAt] = useState(new Date());
  const [fullName, setFullName] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [age, setAge] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [diagnosis, setDiagnosis] = useState("");
  const [disinfector, setDisinfector] = useState({});
  const [data, setData] = useState({});
  const [disinfectorId, setDisinfectorId] = useState(null);
  const { desinfectionReferences, desinfectionReference } = useSelector(
    (state) => state.desinfectionReference
  );

  const kazakhName = 'Наряд бойынша дезинфекция жүргізу туралы АНЫҚТАМА'
  const name = 'СПРАВКА о проведении дезинфекции по наряду'


  const handleChange = useCallback((key, value) => {
    setData({ ...data, [key]: value })
  }, [data]);
  const handleChangeMulti = useCallback((obj) => {
    setData({ ...data, ...obj })
  }, [data]);

  const handleChangeData = useCallback((key, value, key2) => {
    setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
  }, [data]);

  const handleChangeSample = useCallback((key, value, i, key2) => {
    const array = data.data?.samples?.value.slice(0)
    array[i][key] = { 'key': key2, value }
    setData({
      ...data, data: {
        ...data.data,
        samples: { key: 'samples', value: array }
      }
    })
  }, [data]);


  const handleSubmit = async (e) => {
    setLoading(true);

    console.log(data)

    await onSubmit(e, data);

    setLoading(false);
  };

  // ---

  const emptyState = () => {
    setId(null);
    setNumber("");
    setCreatedAt(new Date());
    setFullName("");
    setAddressLine("");
    setAge(null);
    setAddressId(null);
    setDiagnosis(null);
    setDisinfectorId(null);
  };
  const handleForm = async (e) => {
    e.preventDefault();
    onSubmit({
      id,
      number,
      createdAt,
      fullName,
      addressLine,
      age,
      addressId,
      diagnosis,
      disinfectorId,
    });

    emptyState();
  };

  useEffect(() => {
    const {
      id,
      number,
      createdAt,
      fullName,
      addressLine,
      age,
      addressId,
      disinfectorId,
    } = desinfectionReference || {};
    setId(id);
    setNumber(number);
    setCreatedAt(createdAt);
    setFullName(fullName);
    setAddressLine(addressLine);
    setAge(age);
    setAddressId(addressId);
    setDisinfectorId(disinfectorId);
  }, [desinfectionReference, desinfectionReferences]);

  useEffect(() => {
    let response;
    // const getGeoObjectsAsync = async () => {
    //   response = await dispatch(
    //     getGeoObjects({ paging: { skip: 0, take: 10 } })
    //   );
    // };
    const getEmployeesAsync = async () => {
      response = await dispatch(
        getEmployees({ paging: { skip: 0, take: 10 } })
      );
    };
    // if (geoObjectRequestedCount == 0) {
    //   getGeoObjectsAsync();
    // }

  }, []);
  return (
    <>
      <BasicModalContainer>
        <h4 style={{ textAlign: "center" }}>
          {kazakhName}
        </h4>
        <h4 style={{ textAlign: "center" }}>
          {name}
        </h4>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
              <DateTimePicker views={['day']} label="от"
                value={data?.data?.dateOfReference?.value}
                onChange={(value) => {
                  handleChangeData('dateOfReference', value, 'dateOfReference')
                }}
                minDate={new Date('1910-03-01')} renderInput={(params) =>
                  <TextField margin="normal"  {...params} helperText={null} />
                } />
            </LocalizationProvider>
        <Form onSubmit={handleSubmit}>
        <ReferenceDisinsectionForm data={data} handleChange={handleChange} handleChangeData={handleChangeData} handleChangeMulti={handleChangeMulti} handleChangeSample={handleChangeSample}/>
            <Grid item xs={6}>
              <LoadingButton loading={loading} onClick={handleSubmit}>
                Создать
              </LoadingButton>
            </Grid>
        </Form>
      </BasicModalContainer>
    </>
  );
};
