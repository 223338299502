// ﻿import { BrigadeList, ClinicalRequestList, ContractSpecificationList, DepartureScheduleRequestList, DesinfectionReferenceList, DesinsectionRequestList, PatientList, ProbeList, ProtocolFormList, ProtocolList, ReagentList, ReferralFormList, ResultFormList, ResultList, ServiceExpenseList, ServiceKindList, ServiceList, ServicePriceList, ServiceWithoutActRequestList, SignUp, Tariffication, WithoutDepartureRequestList } from "../screens";
// import { ChemicalIndicesList, CurrencyList, DiseaseList, GenderList, GovernmentStandardList, InfectionKindList, MedicalOrderList, PositionList, PurposeOfSelectionList, ReferralAdditionalInfoList, ReferralOrderTypeList, SamplePreservationMethodList, SamplingPurposeList, ServiceScheduleList, SicknessKindList, StorageConditionList, TechnicalRegulationsOfCustomsUnionList, TransportationConditionList, TransportTypeList, UnitOfMeasureList } from "../screens";

import {
  ACT_BACTERIOLOGICAL_DETAIL,
  ACT_BACTERIOLOGICAL_TEST, ACT_BACTERIOLOGICAL_TEST_DSEK,
  ACT_BIO_TEST, ACT_BIO_TEST_DETAIL, ACT_BIO_TEST_DSEK,
  ACT_DISINFECTANT_DSEK, ACT_LAND_DSEK, ACT_PRODUCTS_DSEK, ACT_RADIO_DSEK, ACT_SAMPLING_REPORT_DSEK,
  ACT_TICKS_DSEK,
  ACT_WASTE_DSEK,
  ACT_WATER_DSEK, ACT_ZOONOTIC_DSEK,
  DEPARTMENT_DETAIL_ROUTE,
  DEPARTMENT_LIST_ROUTE,
  DIVISION_DETAIL_ROUTE,
  DIVISION_LIST_ROUTE,
  FILIAL_DIVISION_DETAIL_ROUTE,
  PRESCRIPTION_CREATE,
  PRESCRIPTION_EDIT,
  PRESCRIPTION_INFO,
  REFERRAL,
  REFERRAL_CREATE,
  RESOLUTION,
  RESOLUTION_CREATE,
  RESOLUTION_EDIT,
  RESOLUTION_INFO, SELF_CONTROL_REQUEST, SELF_CONTROL_REQUEST_CREATE, SELF_CONTROL_REQUEST_INFO, SERVICE_LIST_ADD_ROUTE,
  SELF_CONTROL_REQUEST_EDIT,
  SERVICE_LIST_CLIENT_ADD_ROUTE, SERVICE_LIST_CLIENT_EDIT_ROUTE,
  SERVICE_LIST_CLIENT_ROUTE, SERVICE_LIST_EDIT_ROUTE
} from ".";
import {
  ClinicalRequestList,
  ContractSpecificationList,
  DepartureScheduleRequestList,
  DesinfectionReferenceList,
  DesinsectionRequestList,
  PatientList,
  ProbeList,
  ProtocolFormList,
  ProtocolList,
  ReagentList,
  ReferralFormList,
  ResultFormList,
  ResultList,
  ServiceExpenseList,
  ServiceKindList,
  ServiceList,
  ServicePriceList,
  ServiceWithoutActRequestList,
  SignUp,
  WithoutDepartureRequestList,
  Tariffication,
  ReferralCreate,
  BrigadeList,
  RequestList,
  ReferralTable,
  ReferralDetail,
} from "../screens";
import {ReferralCopy} from "../screens/Referral/ReferralCopy"
import {ObjectReferralCopy} from "../screens/AnObjectReferral/ObjectReferralCopy"
import {
  ChemicalIndicesList,
  CurrencyList,
  DiseaseList,
  GenderList,
  GovernmentStandardList,
  InfectionKindList,
  MedicalOrderList,
  PositionList,
  PurposeOfSelectionList,
  ReferralAdditionalInfoList,
  ReferralOrderTypeList,
  SamplePreservationMethodList,
  SamplingPurposeList,
  ServiceScheduleList,
  SicknessKindList,
  StorageConditionList,
  TechnicalRegulationsOfCustomsUnionList,
  TransportationConditionList,
  TransportTypeList,
  UnitOfMeasureList,
  AnObjectSectionList,
  AnObjectSubSectionList,
  DiseaseIndexList,
  EmployeeList
} from "../screens";
// import { EmployeeList } from "../screens";
// import { DiseaseIndexList } from "../screens/DiseaseIndex/DiseaseIndexList.jsx";

import {
  ContractRequestCreate,
  ContractRequestInfo,
  ContractRequestTable,
  DDDRequestCreate,
  DDDRequestInfo,
  DDDRequestTable,
  DepartureScheduleRequestInfo,
  DepartureScheduleRequestTable,
  KDIRequestCreate,
  KDIRequestInfo,
  KDIRequestTable,
  ObjectReferral,
  ObjectReferralCreate,
  ObjectReferralTable,
  ServiceWithoutActRequestInfo,
  ServiceWithoutActRequestTable,
  WithoutDepartureRequestInfo,
  WithoutDepartureRequestTable,
} from "../screens";
import ActDisinfectantDetail from "../screens/Acts/ActDisinfectant/ActDisinfectantDetail";
import ActLandDetail from "../screens/Acts/ActLand/ActLandDetail";
import ActProductsDetail from "../screens/Acts/ActProducts/ActProductsDetail";
import ActRadioDetail from "../screens/Acts/ActRadio/ActRadioDetail";
import ActSamplingReportDetail from "../screens/Acts/ActSamplingReport/ActSamplingReportDetail";
import ActTable from "../screens/Acts/ActTable";
import ActTicksDetail from "../screens/Acts/ActTicks/ActTicksDetail";
import ActWasteDetail from "../screens/Acts/ActWaste/ActWasteDetail";
import ActBioTestDetail from "../screens/Acts/ActBioTest/ActBioTestDetail";
import ActBacteriologicalTestDetail from "../screens/Acts/ActBacteriologicalTest/ActBacteriologicalTestDetail";
import ActWaterDetail from "../screens/Acts/ActWater/ActWaterDetail";
import ActZoonoticDetail from "../screens/Acts/ActZoonotic/ActZoonoticDetail";
import DddDisinsectionDetail from "../screens/DDD/DddDisinsection/DddDisinsectionDetail";
import DddDisinsectionTable from "../screens/DDD/DddDisinsection/DddDisinsectionTable";
import DddFinalDisinfectionDetail from "../screens/DDD/DddFinalDisinfection/DddFinalDisinfectionDetail";
import DddFinalDisinfectionTable from "../screens/DDD/DddFinalDisinfection/DddFinalDisinfectionTable";
import ReceiptForThingsDetail from "../screens/DDD/ReceiptForThings/ReceiptForThingsDetail";
import ReceiptForThingsTable from "../screens/DDD/ReceiptForThings/ReceiptForThingsTable"
import ReferenceDisinfectionDetail from "../screens/DDD/ReferenceDisinfection/ReferenceDisinfectionDetail";
import ReferenceDisinfectionTable from "../screens/DDD/ReferenceDisinfection/ReferenceDisinfectionTable";
import DirectionBloodDetail from "../screens/Directions/DirectionBlood/DirectionBloodDetail";
import DirectionCholeraDetail from "../screens/Directions/DirectionCholera/DirectionCholeraDetail";
import DirectionMaterialDetail from "../screens/Directions/DirectionMaterial/DirectionMaterialDetail";
import DirectionMicrobioDetail from "../screens/Directions/DirectionMicrobio/DirectionMicrobioDetail";
import DirectionParasiteDetail from "../screens/Directions/DirectionParasite/DirectionParasiteDetail";
import DirectionPeopleDetail from "../screens/Directions/DirectionPeople/DirectionPeopleDetail";
import DirectionSamplingDetail from "../screens/Directions/DirectionSampling/DirectionSamplingDetail";
import DirectionTable from "../screens/Directions/DirectionTable";
import DirectionVirusDetail from "../screens/Directions/DirectionVirus/DirectionVirusDetail";

import EmployeeDetailForm from "../screens/Employee/DetailForm/EmployeeDetailForm";
import DiseaseIndexDetailForm from "../screens/DiseaseIndex/DetailForm/DiseaseIndexDetailForm";

import FilialDetialForm from "../screens/Filial/DetailForm/FilialDetialForm";
import FilialList from "../screens/Filial/FilialList";
import Prescription from "../screens/KSEK/Prescription/Prescription";
import Resolution from "../screens/KSEK/Resolution/Resolution";

import { RequestLockList } from "../screens";
import { CreateRequestLock } from "../screens/RequestLock/CreateRequestLock";
import { EditRequestLock } from "../screens/RequestLock/EditRequestLock";
import { DetailsRequestLock } from "../screens/RequestLock/DetailsRequestLock";

import { OrganizationFilialMapList } from "../screens";
import { CreateOrganizationFilialMap } from "../screens/OrganizationFilialMap/CreateOrganizationFilialMap";
import { EditOrganizationFilialMap } from "../screens/OrganizationFilialMap/EditOrganizationFilialMap";
import { OrganizationFilialMapDetails } from "../screens/OrganizationFilialMap/OrganizationFilialMapDetails";

import SignIn2 from "../screens/SignIn2/SignIn2";
import TestPage from "../screens/TestPage";
import {
  ACT_DISINFECTANT,
  ACT_DISINFECTANT_DETAIL,
  ACT_LAND,
  ACT_LAND_DETAIL,
  ACT_PRODUCTS,
  ACT_PRODUCTS_DETAIL,
  ACT_RADIO,
  ACT_RADIO_DETAIL,
  ACT_SAMPLING,
  ACT_SAMPLING_REPORT,
  ACT_SAMPLING_REPORT_DETAIL,
  ACT_TICKS,
  ACT_TICKS_DETAIL,
  ACT_WASTE,
  ACT_WASTE_DETAIL,
  ACT_WATER,
  ACT_WATER_DETAIL,
  ACT_ZOONOTIC,
  ACT_ZOONOTIC_DETAIL,
  BRIGADE_LIST_ROUTE,
  CHEMICAL_INDICES_LIST_ROUTE,
  CLINICAL_REQUEST_LIST_ROUTE,
  CONTRACT_REQUEST,
  CONTRACT_REQUEST_CREATE,
  CONTRACT_REQUEST_EDIT,
  CONTRACT_REQUEST_INFO,
  CONTRACT_SPECIFICATION_LIST_ROUTE,
  CURRENCY_LIST_ROUTE,
  DDD_REQUEST,
  DDD_REQUEST_CREATE,
  DDD_REQUEST_EDIT,
  DDD_REQUEST_INFO,
  DEPARTURE_SCHEDULE,
  DEPARTURE_SCHEDULE_CREATE,
  DEPARTURE_SCHEDULE_EDIT,
  DEPARTURE_SCHEDULE_INFO,
  DEPARTURE_SCHEDULE_REQUEST_LIST_ROUTE,
  DESINFECTION_REFERENCE_LIST_ROUTE,
  DESINSECTION_REQUEST_LIST_ROUTE,
  DIRECTION_BLOOD,
  DIRECTION_BLOOD_DETAIL,
  DIRECTION_CHOLERA,
  DIRECTION_CHOLERA_DETAIL,
  DIRECTION_MATERIAL,
  DIRECTION_MATERIAL_DETAIL,
  DIRECTION_MICROBIO,
  DIRECTION_MICROBIO_DETAIL,
  DIRECTION_PARASITE,
  DIRECTION_PARASITE_DETAIL,
  DIRECTION_PEOPLE,
  DIRECTION_PEOPLE_DETAIL,
  DIRECTION_SAMPLING,
  DIRECTION_SAMPLING_DETAIL,
  DIRECTION_VIRUS,
  DIRECTION_VIRUS_DETAIL,
  DISEASE_LIST_ROUTE,
  EMPLOYEE_DETAIL_ROUTE,
  EMPLOYEE_LIST_ROUTE,
  FILIAL_DETAIL_ROUTE,
  FILIAL_LIST_ROUTE,
  GENDER_LIST_ROUTE,
  GOVERNMENT_STANDARD_LIST_ROUTE,
  INFECTION_KIND_LIST_ROUTE,
  KDI_REQUEST,
  KDI_REQUEST_CREATE,
  KDI_REQUEST_EDIT,
  KDI_REQUEST_INFO,
  LOGIN_ROUTE,
  MEDICAL_ORDER_LIST_ROUTE,
  OBJECT_REFERRAL,
  OBJECT_REFERRAL_COPY,
  OBJECT_REFERRAL_CREATE,
  OBJECT_REFERRAL_DETAIL_ROUTE,
  OBJECT_REFERRAL_EDIT,
  OBJECT_REFERRAL_INFO,
  OBJECT_REFERRAL_LIST_ROUTE,
  PATIENT_LIST_ROUTE,
  POSITION_LIST_ROUTE,
  PRESCRIPTION,
  PROBE_LIST_ROUTE,
  PROTOCOL_FORM_LIST_ROUTE,
  PROTOCOL_LIST_ROUTE,
  PROTOCOL_LIST_ROUTE_OR,
  PURPOSE_OF_SELECTION_LIST_ROUTE,
  REAGENT_LIST_ROUTE,
  RECEIPT_THINGS,
  RECEIPT_THINGS_DETAIL,
  REFERENCE_DISINFECTION,
  REFERRAL_ADDITIONAL_INFO_LIST_ROUTE,
  REFERRAL_DETAIL_ROUTE,
  REFERRAL_COPY,
  REFERRAL_FORM_LIST_ROUTE,
  REFERRAL_ORDER_TYPE_LIST_ROUTE,
  REQUEST_LIST_ROUTE,
  RESULT_FORM_LIST_ROUTE,
  RESULT_LIST_ROUTE,
  RESULT_LIST_ROUTE_OR,
  SAMPLE_PRESERVATION_METHOD_LIST_ROUTE,
  SAMPLING_PURPOSE_LIST_ROUTE,
  AN_OBJECT_SECTION_LIST_ROUTE,
  AN_OBJECT_SUB_SECTION_LIST_ROUTE,
  SERVICE_EXPENSE_LIST_ROUTE,
  SERVICE_KIND_LIST_ROUTE,
  SERVICE_LIST_ROUTE,
  SERVICE_PRICE_LIST_ROUTE,
  SERVICE_SCHEDULE_LIST_ROUTE,
  SERVICE_WITHOUT_ACT_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_CREATE,
  SERVICE_WITHOUT_ACT_REQUEST_EDIT,
  SERVICE_WITHOUT_ACT_REQUEST_INFO,
  SERVICE_WITHOUT_ACT_REQUEST_LIST_ROUTE,
  SICKNESS_KIND_LIST_ROUTE,
  SIGN_UP_ROUTE,
  STORAGE_CONDITION_LIST_ROUTE,
  TARIFFICATION_ROUTE,
  TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_LIST_ROUTE,
  TRANSPORT_TYPE_LIST_ROUTE,
  TRANSPORTATION_CONDITION_LIST_ROUTE,
  UNIT_OF_MEASURE_LIST_ROUTE,
  WITHOUT_DEPARTURE_REQUEST,
  WITHOUT_DEPARTURE_REQUEST_CREATE,
  WITHOUT_DEPARTURE_REQUEST_EDIT,
  WITHOUT_DEPARTURE_REQUEST_INFO,
  WITHOUT_DEPARTURE_REQUEST_LIST_ROUTE,
  AN_OBJECT_LIST_ROUTE,
  RESULT_LIST_ROUTE_PAID,
  PROTOCOL_LIST_ROUTE_PAID,
DISEASE_INDEX_LIST_ROUTE,
DISEASE_INDEX_DETAIL_ROUTE,
ACCREDITATION_LIST_ROUTE,
ACCREDITATION_DETAIL_ROUTE,
SERVICE_MAP_LIST_ROUTE,
SERVICE_MAP_EDIT_ROUTE,
SERVICE_MAP_DETAILS_ROUTE,
SERVICE_MAP_CREATE_ROUTE,
SERVICE_GROUP_LIST_ROUTE,
SERVICE_GROUP_EDIT_ROUTE,
SERVICE_GROUP_DETAILS_ROUTE,
SERVICE_GROUP_CREATE_ROUTE,
PLAN_LIST_ROUTE,
PLAN_DETAIL_ROUTE,
PLAN_EDIT_ROUTE,
DASHBOARD_ROUTE,
REQUEST_TARIFICATIONS_QUANTITY_INDICES,
REQUEST_TARIFICATIONS_QUANTITY_INDICES_2,
REQUEST_TARIFICATIONS_QUANTITY_INDICES_3,
REQUEST_TARIFICATIONS_QUANTITY_INDICES_4,
REQUEST_TARIFICATIONS_QUANTITY_DEPARTMENTS,
ACT_BUY_PRODUCTS,
ACT_BUY_PRODUCTS_DETAIL,
ACT_BUY_PRODUCTS_DSEK,
} from "./const";
import {
  DDD_DISINSECTION,
  DDD_DISINSECTION_DETAIL,
  DDD_FINAL_DISINFECTION,
  DDD_FINAL_DISINFECTION_DETAIL,
    REFERENCE_DISINFECTION_DETAIL,
    FILIAL_DIVISION_LIST_ROUTE, FORM_MAP_LIST_ROUTE, FORM_MAP_DETAIL_ROUTE,
} from "./const";

import {
  REQUEST_TARIFICATIONS_QUANTITY
} from "./const";



// import DddDisinsectionDetail from "../screens/DDD/DddDisinsection/DddDisinsectionDetail";
// import DddFinalDisinfectionDetail from "../screens/DDD/DddFinalDisinfection/DddFinalDisinfectionDetail";
// import DddFinalDisinfection from "../screens/DDD/DddFinalDisinfection/DddFinalDisinfectionDetail";
// import DisinfectionTable from "../screens/DDD/DisinfectionTable";
import AccreditationDetailForm from "../screens/Accreditations/DetailForm/AccreditationDetailForm";
import filialDivisionList from "../screens/FilialDivision/FilialDivisionList";
import FilialDivisionList from "../screens/FilialDivision/FilialDivisionList";
import FormMapList from "../screens/FormMap/FormMapList";
import FormMapDetailForm from "../screens/FormMap/DetailForm/FormMapDetailForm";
import DivisionList from "../screens/Division/DivisionList";
import DivisionDetailForm from "../screens/Division/DetailForm/DivisionDetailForm";
import DepartmentDetailForm from "../screens/Department/DetailForm/DepartmentDetailForm";
import DepartmentList from "../screens/Department/DepartmentList";
import AnObjectList from "../screens/Dictionaries/AnObject/AnObjectList";
import FilialDivisionDetailForm from "../screens/FilialDivision/DetailForm/FilialDivisionDetialForm";

import { REQUEST_LOCK_LIST_ROUTE } from "./const";
import { REQUEST_LOCK_CREATE_ROUTE } from "./const";
import { REQUEST_LOCK_EDIT_ROUTE } from "./const";
import { REQUEST_LOCK_DETAILS_ROUTE } from "./const";

import { ORGANIZATION_FILIAL_MAP_LIST_ROUTE } from "./const";
import { ORGANIZATION_FILIAL_MAP_CREATE_ROUTE } from "./const";
import { ORGANIZATION_FILIAL_MAP_EDIT_ROUTE } from "./const";
import { ORGANIZATION_FILIAL_MAP_DETAILS_ROUTE } from "./const";

import ResolutionCreate from "../screens/KSEK/Resolution/ResolutionCreate";
import PrescriptionCreate from "../screens/KSEK/Prescription/PrescriptionCreate";
import ServiceClientList from "../screens/Service/ServiceClientList";
import ServiceClientAdd from "../screens/Service/ServiceClientAdd";
import ServiceAdd from "../screens/Service/ServiceAdd";
import {SelfControlRequest, SelfControlRequestTable} from "../screens/Requests/SelfControlRequest";
import {SelfControlRequestEditSection} from "../screens/Requests/SelfControlRequest/Sections/SelfControlRequestEditSection"
import AccreditationList from "../screens/Accreditations/AccreditationList";

import { ServiceMapList } from "../screens";
import { CreateServiceMap } from "../screens/ServiceMap/CreateServiceMap";
import { EditServiceMap } from "../screens/ServiceMap/EditServiceMap";
import { ServiceMapDetails } from "../screens/ServiceMap/ServiceMapDetails";

// reportAndAnalytics
import RequestTarificationsQuantity from "../screens/ReportAndAnalytics/RequestTarificationsQuantity";

import PlanDetailForm from "../screens/Plan/DetailForm/PlanDetailForm";
import PlanList from "../screens/Plan/PlanList";
import PlanEditForm from "../screens/Plan/DetailForm/PlanEditForm";
import ServiceGroupList from "../screens/ServiceGroups/ServiceGroupList";
import { CreateServiceGroup } from "../screens/ServiceGroups/CreateServiceGroup";
import { EditServiceGroup } from "../screens/ServiceGroups/EditServiceGroup";
import { ServiceGroupDetails } from "../screens/ServiceGroups/ServiceGroupDetails";
import { Dashboard } from "../screens/Dashboard/Dashboard";
import RequestTarificationsQuantityIndices from "../screens/ReportAndAnalytics/RequestTarificationsQuantityIndices";
import RequestTarificationsQuantityIndices2 from "../screens/ReportAndAnalytics/RequestTarificationsQuantityIndices2";
import RequestTarificationsQuantityIndices3 from "../screens/ReportAndAnalytics/RequestTarificationsQuantityIndices3";
import RequestTarificationsQuantityIndices4 from "../screens/ReportAndAnalytics/RequestTarificationsQuantityIndices4";
import requestTarificationsQuantityDepartments from "../screens/ReportAndAnalytics/RequestTarificationsQuantityDepartments";
import RequestTarificationsQuantityDepartments from "../screens/ReportAndAnalytics/RequestTarificationsQuantityDepartments";
import ActBuyProductsDetail from "../screens/Acts/ActBuyProducts/ActBuyProductsDetail";

export const publicRoutes = [
  {
    path: LOGIN_ROUTE,
    Component: SignIn2,
  },
  {
    path: SIGN_UP_ROUTE,
    Component: SignUp,
  },
  {
    path: PROTOCOL_LIST_ROUTE,
    Component: ProtocolList,
  },
];

export const authRoutes = [
  {
    path:ACCREDITATION_LIST_ROUTE,
    Component:AccreditationList
  },
  {
    path: ACCREDITATION_DETAIL_ROUTE(),
    Component: AccreditationDetailForm,
  },
  {
    path:DIVISION_DETAIL_ROUTE(),
    Component:DivisionDetailForm
  },
  {
    path:DIVISION_LIST_ROUTE,
    Component:DivisionList
  },
  {
    path:DEPARTMENT_DETAIL_ROUTE(),
    Component:DepartmentDetailForm
  },
  {
    path:DEPARTMENT_LIST_ROUTE,
    Component:DepartmentList
  },
  {
    path:FORM_MAP_DETAIL_ROUTE(),
    Component:FormMapDetailForm
  },
  {
    path:FORM_MAP_LIST_ROUTE,
    Component:FormMapList
  },
  {
    path:FILIAL_DIVISION_LIST_ROUTE,
    Component:FilialDivisionList
  },
  {
    path: FILIAL_DETAIL_ROUTE(),
    Component: FilialDetialForm,
  },
  {
    path: FILIAL_DIVISION_DETAIL_ROUTE(),
    Component: FilialDivisionDetailForm,
  },
  {
    path: FILIAL_LIST_ROUTE,
    Component: FilialList,
  },
  {
    path: EMPLOYEE_DETAIL_ROUTE(),
    Component: EmployeeDetailForm,
  },
  {
    path: EMPLOYEE_LIST_ROUTE,
    Component: EmployeeList,
  },
  {
    path: DISEASE_INDEX_DETAIL_ROUTE(),
    Component: DiseaseIndexDetailForm,
  },
  {
    path: DISEASE_INDEX_LIST_ROUTE,
    Component: DiseaseIndexList,
  },

  {
    path: RESOLUTION,
    Component: Resolution,
  },
  { path: RESOLUTION_CREATE, Component: ResolutionCreate },
  { path: RESOLUTION_EDIT, Component: ResolutionCreate },
  {
    path: PRESCRIPTION,
    Component: Prescription,
  },
  {
    path: PRESCRIPTION_EDIT,
    Component: PrescriptionCreate,
  },
  {
    path: PRESCRIPTION_CREATE,
    Component: PrescriptionCreate,
  },
  {
    path: DDD_DISINSECTION,
    Component: DddDisinsectionTable,
  },
  {
    path: DDD_DISINSECTION_DETAIL(),
    Component: DddDisinsectionDetail,
  },
  {
    path: DDD_FINAL_DISINFECTION,
    Component: DddFinalDisinfectionTable,
  },
  {
    path: DDD_FINAL_DISINFECTION_DETAIL(),
    Component: DddFinalDisinfectionDetail,
  },
  {
    path: RECEIPT_THINGS,
    Component: ReceiptForThingsTable,
  },
  {
    path: RECEIPT_THINGS_DETAIL(),
    Component: ReceiptForThingsDetail,
  },
  {
    path: REFERENCE_DISINFECTION,
    Component: ReferenceDisinfectionTable,
  },
  {
    path: REFERENCE_DISINFECTION_DETAIL(),
    Component: ReferenceDisinfectionDetail,
  },
  {
    path: DIRECTION_VIRUS,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_VIRUS_DETAIL(),
    Component: DirectionVirusDetail,
  },
  {
    path: DIRECTION_BLOOD,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_BLOOD_DETAIL(),
    Component: DirectionBloodDetail,
  },
  {
    path: DIRECTION_CHOLERA,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_CHOLERA_DETAIL(),
    Component: DirectionCholeraDetail,
  },
  {
    path: DIRECTION_MATERIAL,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_MATERIAL_DETAIL(),
    Component: DirectionMaterialDetail,
  },
  {
    path: DIRECTION_MICROBIO,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_MICROBIO_DETAIL(),
    Component: DirectionMicrobioDetail,
  },
  {
    path: DIRECTION_PEOPLE,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_PEOPLE_DETAIL(),
    Component: DirectionPeopleDetail,
  },
  {
    path: DIRECTION_SAMPLING,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_SAMPLING_DETAIL(),
    Component: DirectionSamplingDetail,
  },
  {
    path: DIRECTION_PARASITE,
    Component: DirectionTable,
  },
  {
    path: DIRECTION_PARASITE_DETAIL(),
    Component: DirectionParasiteDetail,
  },
  {
    path: ACT_WASTE,
    Component: ActTable,
  },
  {
    path: ACT_WASTE_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_WASTE_DETAIL(),
    Component: ActWasteDetail,
  },
  {
    path: ACT_BIO_TEST,
    Component: ActTable,
  },
  {
    path: ACT_BIO_TEST_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_BIO_TEST_DETAIL(),
    Component: ActBioTestDetail,
  },
  {
    path: ACT_BUY_PRODUCTS,
    Component: ActTable,
  },
  {
    path: ACT_BUY_PRODUCTS_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_BUY_PRODUCTS_DETAIL(),
    Component: ActBuyProductsDetail,
  },
  {
    path: ACT_BACTERIOLOGICAL_TEST,
    Component: ActTable,
  },
  {
    path: ACT_BACTERIOLOGICAL_TEST_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_BACTERIOLOGICAL_DETAIL(),
    Component: ActBacteriologicalTestDetail,
  },
  {
    path: ACT_TICKS,
    Component: ActTable,
  },
  {
    path: ACT_TICKS_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_TICKS_DETAIL(),
    Component: ActTicksDetail,
  },
  {
    path: ACT_DISINFECTANT,
    Component: ActTable,
  },
  {
    path: ACT_DISINFECTANT_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_DISINFECTANT_DETAIL(),
    Component: ActDisinfectantDetail,
  },
  {
    path: ACT_ZOONOTIC,
    Component: ActTable,
  },
  {
    path: ACT_ZOONOTIC_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_ZOONOTIC_DETAIL(),
    Component: ActZoonoticDetail,
  },
  {
    path: ACT_RADIO,
    Component: ActTable,
  },
  {
    path: ACT_RADIO_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_RADIO_DETAIL(),
    Component: ActRadioDetail,
  },
  {
    path: ACT_LAND,
    Component: ActTable,
  },
  {
    path: ACT_LAND_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_LAND_DETAIL(),
    Component: ActLandDetail,
  },
  {
    path: ACT_PRODUCTS,
    Component: ActTable,
  },
  {
    path: ACT_PRODUCTS_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_PRODUCTS_DETAIL(),
    Component: ActProductsDetail,
  },
  {
    path: ACT_WATER,
    Component: ActTable,
  },
  {
    path: ACT_WATER_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_WATER_DETAIL(),
    Component: ActWaterDetail,
  },
  {
    path: ACT_SAMPLING_REPORT,
    Component: ActTable,
  },
  {
    path: ACT_SAMPLING_REPORT_DSEK,
    Component: ActTable,
  },
  {
    path: ACT_SAMPLING_REPORT_DETAIL(),
    Component: ActSamplingReportDetail,
  },
  {
    path: SERVICE_LIST_ROUTE,
    Component: ServiceList,
  },
  {
    path: SERVICE_LIST_CLIENT_ROUTE,
    Component: ServiceClientList,
  },
  {
    path: SERVICE_LIST_CLIENT_ADD_ROUTE,
    Component: ServiceClientAdd,
  }  ,
  {
    path: SERVICE_LIST_ADD_ROUTE,
    Component: ServiceAdd,
  }  ,
  {
    path: SERVICE_LIST_EDIT_ROUTE + '/:id',
    Component: ServiceAdd,
  }  ,
  {
    path: SERVICE_LIST_CLIENT_EDIT_ROUTE + '/:id',
    Component: ServiceClientAdd,
  },
  {
    path: TARIFFICATION_ROUTE,
    Component: Tariffication,
  },
  {
    path:PLAN_LIST_ROUTE,
    Component: PlanList
  },
  {
    path: PLAN_DETAIL_ROUTE(),
    Component: PlanDetailForm,
  },
  {
    path: PLAN_EDIT_ROUTE(),
    Component: PlanEditForm,
  },
  {
    path: REFERRAL_FORM_LIST_ROUTE,
    Component: ReferralFormList,
  },

  {
    path: CLINICAL_REQUEST_LIST_ROUTE,
    Component: ClinicalRequestList,
  },
  {
    path: DEPARTURE_SCHEDULE_REQUEST_LIST_ROUTE,
    Component: DepartureScheduleRequestList,
  },
  {
    path: DESINSECTION_REQUEST_LIST_ROUTE,
    Component: DesinsectionRequestList,
  },
  {
    path: SERVICE_WITHOUT_ACT_REQUEST_LIST_ROUTE,
    Component: ServiceWithoutActRequestList,
  },
  {
    path: WITHOUT_DEPARTURE_REQUEST_LIST_ROUTE,
    Component: WithoutDepartureRequestList,
  },
  {
    path: PROBE_LIST_ROUTE,
    Component: ProbeList,
  },
  {
    path: BRIGADE_LIST_ROUTE,
    Component: BrigadeList,
  },
  {
    path: DESINFECTION_REFERENCE_LIST_ROUTE,
    Component: DesinfectionReferenceList,
  },
  {
    path: RESULT_FORM_LIST_ROUTE,
    Component: ResultFormList,
  },
  {
    path: RESULT_LIST_ROUTE,
    Component: ResultList,
  },
  {
    path: RESULT_LIST_ROUTE_PAID,
    Component: ResultList,
  },
  {
    path: RESULT_LIST_ROUTE_OR,
    Component: ResultList,
  },
  {
    path: PATIENT_LIST_ROUTE,
    Component: PatientList,
  },
  {
    path: PROTOCOL_FORM_LIST_ROUTE,
    Component: ProtocolFormList,
  },
  {
    path: PROTOCOL_LIST_ROUTE,
    Component: ProtocolList,
  },
  {
    path: PROTOCOL_LIST_ROUTE_PAID,
    Component: ProtocolList,
  },
  {
    path: PROTOCOL_LIST_ROUTE_OR,
    Component: ProtocolList,
  },
  {
    path: SERVICE_KIND_LIST_ROUTE,
    Component: ServiceKindList,
  },
  {
    path: SERVICE_PRICE_LIST_ROUTE,
    Component: ServicePriceList,
  },
  {
    path: SERVICE_EXPENSE_LIST_ROUTE,
    Component: ServiceExpenseList,
  },
  {
    path: CONTRACT_SPECIFICATION_LIST_ROUTE,
    Component: ContractSpecificationList,
  },
  {
    path: REAGENT_LIST_ROUTE,
    Component: ReagentList,
  },
  // dictionaries
  {
    path: CURRENCY_LIST_ROUTE,
    Component: CurrencyList,
  },
  {
    path: GENDER_LIST_ROUTE,
    Component: GenderList,
  },
  {
    path: GOVERNMENT_STANDARD_LIST_ROUTE,
    Component: GovernmentStandardList,
  },
  {
    path: MEDICAL_ORDER_LIST_ROUTE,
    Component: MedicalOrderList,
  },
  {
    path: POSITION_LIST_ROUTE,
    Component: PositionList,
  },
  {
    path: PURPOSE_OF_SELECTION_LIST_ROUTE,
    Component: PurposeOfSelectionList,
  },
  {
    path: REFERRAL_ADDITIONAL_INFO_LIST_ROUTE,
    Component: ReferralAdditionalInfoList,
  },
  {
    path: REFERRAL_ORDER_TYPE_LIST_ROUTE,
    Component: ReferralOrderTypeList,
  },
  {
    path: SAMPLE_PRESERVATION_METHOD_LIST_ROUTE,
    Component: SamplePreservationMethodList,
  },
  {
    path: SAMPLING_PURPOSE_LIST_ROUTE,
    Component: SamplingPurposeList,
  },
  {
    path: SERVICE_SCHEDULE_LIST_ROUTE,
    Component: ServiceScheduleList,
  },
  {
    path: STORAGE_CONDITION_LIST_ROUTE,
    Component: StorageConditionList,
  },
  {
    path: TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_LIST_ROUTE,
    Component: TechnicalRegulationsOfCustomsUnionList,
  },
  {
    path: TRANSPORTATION_CONDITION_LIST_ROUTE,
    Component: TransportationConditionList,
  },
  {
    path: TRANSPORT_TYPE_LIST_ROUTE,
    Component: TransportTypeList,
  },
  {
    path: UNIT_OF_MEASURE_LIST_ROUTE,
    Component: UnitOfMeasureList,
  },
  {
    path: CHEMICAL_INDICES_LIST_ROUTE,
    Component: ChemicalIndicesList,
  },
  {
    path: DISEASE_LIST_ROUTE,
    Component: DiseaseList,
  },
  {
    path: INFECTION_KIND_LIST_ROUTE,
    Component: InfectionKindList,
  },
  {
    path: SICKNESS_KIND_LIST_ROUTE,
    Component: SicknessKindList,
  },
  {
    path: REQUEST_LIST_ROUTE,
    Component: RequestList,
  },
  {
    path: AN_OBJECT_SECTION_LIST_ROUTE,
    Component: AnObjectSectionList,
  },
  {
    path: AN_OBJECT_LIST_ROUTE,
    Component: AnObjectList,
  },
  {
    path: AN_OBJECT_SUB_SECTION_LIST_ROUTE,
    Component: AnObjectSubSectionList,
  },

  // ContractRequest
  { path: CONTRACT_REQUEST, Component: ContractRequestTable },
  { path: CONTRACT_REQUEST_CREATE, Component: ContractRequestCreate },
  { path: CONTRACT_REQUEST_INFO, Component: ContractRequestInfo },
  { path: CONTRACT_REQUEST_EDIT, Component: ContractRequestInfo },

  // DDDRequest
  { path: DDD_REQUEST, Component: DDDRequestTable },
  { path: DDD_REQUEST_CREATE, Component: DDDRequestTable },
  { path: DDD_REQUEST_INFO, Component: DDDRequestInfo },
  { path: DDD_REQUEST_EDIT, Component: DDDRequestInfo },

  // KDIRequest
  { path: KDI_REQUEST, Component: KDIRequestTable },
  { path: KDI_REQUEST_CREATE, Component: KDIRequestTable },
  { path: KDI_REQUEST_INFO, Component: KDIRequestInfo },
  { path: KDI_REQUEST_EDIT, Component: KDIRequestInfo },

  // WithoutDepartureRequest
  { path: WITHOUT_DEPARTURE_REQUEST, Component: WithoutDepartureRequestTable },
  {
    path: WITHOUT_DEPARTURE_REQUEST_CREATE,
    Component: WithoutDepartureRequestTable,
  },
  {
    path: WITHOUT_DEPARTURE_REQUEST_INFO,
    Component: WithoutDepartureRequestInfo,
  },

  // SelfContractRequest
  { path: SELF_CONTROL_REQUEST, Component: SelfControlRequestTable },
  {
    path: SELF_CONTROL_REQUEST_CREATE,
    Component: SelfControlRequestTable,
  },
  {
    path: SELF_CONTROL_REQUEST_INFO,
    Component: SelfControlRequest,
  },
  {
    path: SELF_CONTROL_REQUEST_EDIT(":id"),
    Component: SelfControlRequestEditSection,
  },

  // ServiceWithoutActRequest
  {
    path: SERVICE_WITHOUT_ACT_REQUEST,
    Component: ServiceWithoutActRequestTable,
  },
  {
    path: SERVICE_WITHOUT_ACT_REQUEST_CREATE,
    Component: ServiceWithoutActRequestTable,
  },
  {
    path: SERVICE_WITHOUT_ACT_REQUEST_INFO,
    Component: ServiceWithoutActRequestInfo,
  },

  { path: DASHBOARD_ROUTE, Component: Dashboard },


  // DepartureSchedules
  { path: DEPARTURE_SCHEDULE, Component: DepartureScheduleRequestTable },
  { path: DEPARTURE_SCHEDULE_CREATE, Component: DepartureScheduleRequestTable },
  { path: DEPARTURE_SCHEDULE_INFO, Component: DepartureScheduleRequestInfo },
  { path: DEPARTURE_SCHEDULE_EDIT, Component: DepartureScheduleRequestInfo },

  // Referral
  { path: REFERRAL, Component: ReferralTable },
  { path: REFERRAL_DETAIL_ROUTE(), Component: ReferralDetail },
  { path: REFERRAL_CREATE, Component: ReferralCreate },
  { path: REFERRAL_COPY(), Component: ReferralCopy },
  // { path: REFERRAL_INFO, Component: Referral },
  // { path: REFERRAL_EDIT, Component: ObjectReferral },

  // ObjectReferral
  { path: OBJECT_REFERRAL, Component: ObjectReferralTable },
  { path: OBJECT_REFERRAL_CREATE, Component: ObjectReferralCreate },
  { path: OBJECT_REFERRAL_INFO, Component: ObjectReferral },
  { path: OBJECT_REFERRAL_EDIT, Component: ObjectReferral },
  { path: OBJECT_REFERRAL_COPY(), Component: ObjectReferralCopy },

  // Request lock
  {
    path: REQUEST_LOCK_LIST_ROUTE,
    Component: RequestLockList,
  },
  {
      path: REQUEST_LOCK_CREATE_ROUTE,
      Component: CreateRequestLock,
  },
  {
      path: REQUEST_LOCK_EDIT_ROUTE(":id"),
      Component: EditRequestLock,
  },
  {
      path: REQUEST_LOCK_DETAILS_ROUTE(":id"),
      Component: DetailsRequestLock,
  },

  {
    path: ORGANIZATION_FILIAL_MAP_LIST_ROUTE,
    Component: OrganizationFilialMapList,
  },
  {
      path: ORGANIZATION_FILIAL_MAP_CREATE_ROUTE,
      Component: CreateOrganizationFilialMap,
  },
  {
      path: ORGANIZATION_FILIAL_MAP_EDIT_ROUTE(":id"),
      Component: EditOrganizationFilialMap,
  },
  {
      path: ORGANIZATION_FILIAL_MAP_DETAILS_ROUTE(":id"),
      Component: OrganizationFilialMapDetails,
  },

  // reportAndAnalytics
  {
    path: REQUEST_TARIFICATIONS_QUANTITY,
    Component: RequestTarificationsQuantity,
  },
  {
    path: REQUEST_TARIFICATIONS_QUANTITY_INDICES,
    Component: RequestTarificationsQuantityIndices,
  },
  {
    path: REQUEST_TARIFICATIONS_QUANTITY_INDICES_2,
    Component: RequestTarificationsQuantityIndices2,
  },
  {
    path: REQUEST_TARIFICATIONS_QUANTITY_INDICES_3,
    Component: RequestTarificationsQuantityIndices3,
  },
  {
    path: REQUEST_TARIFICATIONS_QUANTITY_INDICES_4,
    Component: RequestTarificationsQuantityIndices4,
  },
  {
    path: REQUEST_TARIFICATIONS_QUANTITY_DEPARTMENTS,
    Component: RequestTarificationsQuantityDepartments,
  },

  {
        path: SERVICE_MAP_LIST_ROUTE,
        Component: ServiceMapList,
    },
    {
        path: SERVICE_MAP_CREATE_ROUTE,
        Component: CreateServiceMap,
    },
    {
        path: SERVICE_MAP_EDIT_ROUTE(":id"),
        Component: EditServiceMap,
    },
    {
        path: SERVICE_MAP_DETAILS_ROUTE(":id"),
        Component: ServiceMapDetails,
    },

    {
      path: SERVICE_GROUP_LIST_ROUTE,
      Component: ServiceGroupList,
  },
  {
      path: SERVICE_GROUP_CREATE_ROUTE,
      Component: CreateServiceGroup,
  },
  {
      path: SERVICE_GROUP_EDIT_ROUTE(":id"),
      Component: EditServiceGroup,
  },
  {
      path: SERVICE_GROUP_DETAILS_ROUTE(":id"),
      Component: ServiceGroupDetails,
  },

  {
    path: "/test",
    Component: TestPage,
  },
];
