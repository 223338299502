import { $authHost } from "../../http";
import { 
    SERVICE_SUB_GROUP_GET_REQUEST, SERVICE_SUB_GROUP_GET_SUCCESS, SERVICE_SUB_GROUP_GET_ERROR, 
    SERVICE_SUB_GROUP_GET_BY_KEY_REQUEST, SERVICE_SUB_GROUP_GET_BY_KEY_SUCCESS, SERVICE_SUB_GROUP_GET_BY_KEY_ERROR, 
    SERVICE_SUB_GROUP_ADD_REQUEST, SERVICE_SUB_GROUP_ADD_SUCCESS, SERVICE_SUB_GROUP_ADD_ERROR, 
    SERVICE_SUB_GROUP_EDIT_REQUEST, SERVICE_SUB_GROUP_EDIT_SUCCESS, SERVICE_SUB_GROUP_EDIT_ERROR, 
    SERVICE_SUB_GROUP_DELETE_REQUEST, SERVICE_SUB_GROUP_DELETE_SUCCESS, SERVICE_SUB_GROUP_DELETE_ERROR
} from "../constants/serviceSubGroupConstants";

export const getServiceSubGroups = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_SUB_GROUP_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/serviceSubGroup/query",
            args || {},
            config,
        );
        dispatch({
            type: SERVICE_SUB_GROUP_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_SUB_GROUP_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceSubGroupByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_SUB_GROUP_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/serviceSubGroup/${id}`,
        );
        dispatch({
            type: SERVICE_SUB_GROUP_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_SUB_GROUP_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addServiceSubGroup = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_SUB_GROUP_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/serviceSubGroup",
            { name, code, description },
            config,
        );
        dispatch(getServiceSubGroups(queryArgs || {}));
        dispatch({
            type: SERVICE_SUB_GROUP_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_SUB_GROUP_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editServiceSubGroup = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_SUB_GROUP_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/serviceSubGroup",
            { id, name, code, description },
            config,
        );
        dispatch(getServiceSubGroups(queryArgs || {}));
        dispatch({
            type: SERVICE_SUB_GROUP_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_SUB_GROUP_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteServiceSubGroup = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_SUB_GROUP_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/serviceSubGroup",
            { data: { id } },
            config,
        );
        dispatch(getServiceSubGroups(queryArgs || {}));
        dispatch({
            type: SERVICE_SUB_GROUP_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_SUB_GROUP_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

