import { 
    BRIGADE_GET_REQUEST, BRIGADE_GET_SUCCESS, BRIGADE_GET_ERROR, 
    BRIGADE_GET_BY_KEY_REQUEST, BRIGADE_GET_BY_KEY_SUCCESS, BRIGADE_GET_BY_KEY_ERROR, 
    BRIGADE_ADD_REQUEST, BRIGADE_ADD_SUCCESS, BRIGADE_ADD_ERROR, 
    BRIGADE_EDIT_REQUEST, BRIGADE_EDIT_SUCCESS, BRIGADE_EDIT_ERROR, 
    BRIGADE_DELETE_REQUEST, BRIGADE_DELETE_SUCCESS, BRIGADE_DELETE_ERROR
} from "../constants/brigadeConstants";

const initialState = {
    brigades: [],
    brigade: {},
    loading: false,
    success: null,
    error: null,
    brigadeRequestedCount: 0,
};

export const brigadeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case BRIGADE_GET_REQUEST:
            return {
                ...state,
                brigadeRequestedCount: ++state.brigadeRequestedCount,
                loading: true,
            };
        case BRIGADE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                brigades: action.payload,
                success: true,
            };
        case BRIGADE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                brigade: action.payload,
                success: true,
            };
        case BRIGADE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case BRIGADE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case BRIGADE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case BRIGADE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
