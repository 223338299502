import {
  CONTRACT_REQUEST_GET_REQUEST,
  CONTRACT_REQUEST_GET_SUCCESS,
  CONTRACT_REQUEST_GET_ERROR,
  CONTRACT_REQUEST_GET_BY_KEY_REQUEST,
  CONTRACT_REQUEST_GET_BY_KEY_SUCCESS,
  CONTRACT_REQUEST_GET_BY_KEY_ERROR,
  CONTRACT_REQUEST_CREATE_REQUEST,
  CONTRACT_REQUEST_CREATE_SUCCESS,
  CONTRACT_REQUEST_CREATE_ERROR,
  CONTRACT_REQUEST_UPDATE_REQUEST,
  CONTRACT_REQUEST_UPDATE_SUCCESS,
  CONTRACT_REQUEST_UPDATE_ERROR,
  CONTRACT_REQUEST_DELETE_REQUEST,
  CONTRACT_REQUEST_DELETE_SUCCESS,
  CONTRACT_REQUEST_DELETE_ERROR,
} from "../constants/contractRequestConstants";

const initialState = {
  contractRequests: [],
  contractRequest: {},
  loading: false,
  success: null,
  error: null,
  contractRequestRequestedCount: 0,
};

export const contractRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONTRACT_REQUEST_GET_REQUEST:
      return {
        ...state,
        contractRequestRequestedCount: ++state.contractRequestRequestedCount,
        loading: true,
      };
    case CONTRACT_REQUEST_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        contractRequests: action.payload,
        success: true,
      };
    case CONTRACT_REQUEST_GET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CONTRACT_REQUEST_GET_BY_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONTRACT_REQUEST_GET_BY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        contractRequest: action.payload,
        success: true,
      };
    case CONTRACT_REQUEST_GET_BY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CONTRACT_REQUEST_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONTRACT_REQUEST_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CONTRACT_REQUEST_CREATE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CONTRACT_REQUEST_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONTRACT_REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CONTRACT_REQUEST_UPDATE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case CONTRACT_REQUEST_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONTRACT_REQUEST_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case CONTRACT_REQUEST_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
