import React, {createContext, useState} from "react";
import { useTranslation } from "react-i18next";
import useReload from "../../hooks/useReload";
import DashboardLayout from "../../layouts/Dashboard";
import ProtocolCRUDTable from "../../components/CRUD/Tables/ProtocolCRUDTable";
import {$authHost, protocolCRUD} from "../../http";
import CreateProtocol from "./Popups/CreateProtocol";
import EditProtocol from "./Popups/EditProtocol";
import ProtocolDetails from "./Popups/ProtocolDetails";
import RemoveProtocol from "./Popups/RemoveProtocol";
import {convertProtocolToExcel} from "../../functions";
import useAlert from "../../hooks/useAlert";
import {parseResponse} from "../../http/utils";
import {confirmDialog} from "../../components/Dialog/DialogDelete";
import {checkRoles} from "../../functions/checkRoles";
import SignProtocolDetails from "./Popups/SignProtocolDetails";
import {useLocation} from "react-router-dom";
import qs from 'qs';
import { PROTOCOL_STATE_IDS_SIGNED } from "../../redux/constants/protocolConstants";

export const ExcelContext = createContext();

const ProtocolList = () => {
    const {t} = useTranslation();
    const { reload, reloadValue } = useReload();
    const {search} = useLocation()
    const [id, setId] = useState(null);
    const [protocol, setProtocol] = useState(null);
    const [loading, setLoading] = useState(false);
    const alert = useAlert(false, 1);
    const start = () => {
        setLoading(true);
    };

    const { pathname } = useLocation()

    let isObjectReferral = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.includes('ObjectReferral')
    let isPaid = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.includes('Paid')


    const extraFilter = isObjectReferral || isPaid ? {
        "orderType": {
          "operand1": isObjectReferral ? 2 : 1,
          "operator": "equals"
        }
    } : null;


    const query = qs.parse(search?.replace('?', ''));
    const isSee = query?.see;

    React.useEffect(() => {
        if (search) {
            const query = qs.parse(search?.replace('?', ''));
            if (query?.see && query?.guid) {
                start();
                protocolCRUD.get(query?.see + '/' + query?.guid).then(data => {
                    if (data.stateId === PROTOCOL_STATE_IDS_SIGNED) {
                        setProtocol(data);
                        stop();
                        setSignDetailsOpen(true)
                    } else {
                        alert.show(0, "Доступ запрещен так как протокол не подписан");
                    }
                }, (error) => {
                    alert.show(0, "Доступ запрещен: " + error);
                });
            }
        }
    }, [search])

    const stop = () => {
        setLoading(false);
    };
    const [createOpen, setCreateOpen] = useState(false);

    const createSubmit = async (_) => {
        protocolCRUD.create(_).then(_ => {
            reload();
        }).catch(alert);
    }
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [signDetailsOpen, setSignDetailsOpen] = useState(false);

    const detailsHandler = async (id) => {
        start();
        protocolCRUD.get(id).then(data => {
            setProtocol(data);
            stop();

            setCreateOpen(false);
            setDetailsOpen(false);
            setEditOpen(false);
            setRemoveOpen(false);
            setDetailsOpen(true);
            setId(id);
        }).catch(alert);
    }

    const [editOpen, setEditOpen] = useState(false);

    const editHandler = async (id) => {
        start();
        protocolCRUD.get(id).then(data => {
            setProtocol(data);
            stop();

            setCreateOpen(false);
            setDetailsOpen(false);
            setEditOpen(false);
            setRemoveOpen(false);
            setEditOpen(true);
            setId(id);
        }).catch(alert);
    }
    const editSubmit = async (_) => {
        protocolCRUD.edit(_).then(_ => {
            reload();
        }).catch(alert);
    }
    const [removeOpen, setRemoveOpen] = useState(false);

    const removeHandler = async (id) => {
        confirmDialog('Удаление протокола', 'Вы действительно хотите удалить этот протокол?', async () => {
            try {
                await removeSubmit(id);
            } catch (e) {
                alert.show(0, 'Не удалось удалить, ошибка ' + e);
            }
        })
    }
    const removeSubmit = async (id) => {
        protocolCRUD.delete(id).then(_ => {
            reload();
        }).catch(alert);
    }

    const handleGenerateExcel = async (filter) => {
        if (filter?.createdAt?.operand1 && filter?.createdAt?.operand2) {
            const filterToServer = {
                ...filter
            };

            const res = await parseResponse($authHost.post(`/api/v1/protocol/summary/query`, {
                paging: { skip: 0 },
                filter: filterToServer
            }));


            if (res?.length) {
                await convertProtocolToExcel(res, filter)
            }
        } else {
            alert.show(0, 'Вы не задали период!')
        }
    }

    return (
        <DashboardLayout>
            <ExcelContext.Provider value={{handleGenerateExcel}}>
                {!isSee && <><ProtocolCRUDTable onClickEditButton={editHandler}
                                   editVisibleFunc={(_) => {
                                       const locked = ['Signed'].includes(_?.state?.code);
                                       return !locked;
                                   }}
                                   onClickDeleteButton={removeHandler}
                                   deleteVisibleFunc={(_) => {
                                       const locked = ['Signed', 'Approval'].includes(_?.state?.code) && !checkRoles("SuperAdministrator");
                                       return !locked;
                                   }}
                                   onClickDetailsButton={detailsHandler}
                                   reloadValue={reloadValue} extraFilter={extraFilter}/>

                <CreateProtocol action={'create'} title={t("protocolList.createText")} open={createOpen} setOpen={setCreateOpen} onSubmit={createSubmit}/>
                {/* {detailsOpen &&  */}
                <ProtocolDetails action={'details'} title={t("protocolList.detailsText")} reload={reload} open={detailsOpen} setOpen={setDetailsOpen} protocol={protocol}/>
                {/* } */}
                <EditProtocol action={'edit'} title={t("protocolList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit} loading={loading} protocol={protocol}/>
                <RemoveProtocol action={'remove'} title={t("protocolList.removeText")} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/></>}
                <SignProtocolDetails action={'details'} title={"Просмотр (подписанный)"} reload={reload} open={signDetailsOpen} setOpen={setSignDetailsOpen} protocol={protocol}/>
                {alert.render()}
            </ExcelContext.Provider>       
        </DashboardLayout>
    )
};

export default ProtocolList;
