import { 
    AN_OBJECT_GET_REQUEST, AN_OBJECT_GET_SUCCESS, AN_OBJECT_GET_ERROR, 
    AN_OBJECT_GET_BY_KEY_REQUEST, AN_OBJECT_GET_BY_KEY_SUCCESS, AN_OBJECT_GET_BY_KEY_ERROR, 
    AN_OBJECT_ADD_REQUEST, AN_OBJECT_ADD_SUCCESS, AN_OBJECT_ADD_ERROR, 
    AN_OBJECT_EDIT_REQUEST, AN_OBJECT_EDIT_SUCCESS, AN_OBJECT_EDIT_ERROR, 
    AN_OBJECT_DELETE_REQUEST, AN_OBJECT_DELETE_SUCCESS, AN_OBJECT_DELETE_ERROR
} from "../constants/anObjectConstants";

const initialState = {
    anObjects: [],
    anObject: {},
    loading: false,
    success: null,
    error: null,
    anObjectRequestedCount: 0,
};

export const anObjectReducer = (state = initialState, action) => {
    switch (action.type)     {
        case AN_OBJECT_GET_REQUEST:
            return {
                ...state,
                anObjectRequestedCount: ++state.anObjectRequestedCount,
                loading: true,
            };
        case AN_OBJECT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                anObjects: action.payload,
                success: true,
            };
        case AN_OBJECT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                anObject: action.payload,
                success: true,
            };
        case AN_OBJECT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
