import { 
    CONTRACT_SIGNER_GET_REQUEST, CONTRACT_SIGNER_GET_SUCCESS, CONTRACT_SIGNER_GET_ERROR, 
    CONTRACT_SIGNER_GET_BY_KEY_REQUEST, CONTRACT_SIGNER_GET_BY_KEY_SUCCESS, CONTRACT_SIGNER_GET_BY_KEY_ERROR, 
    CONTRACT_SIGNER_ADD_REQUEST, CONTRACT_SIGNER_ADD_SUCCESS, CONTRACT_SIGNER_ADD_ERROR, 
    CONTRACT_SIGNER_EDIT_REQUEST, CONTRACT_SIGNER_EDIT_SUCCESS, CONTRACT_SIGNER_EDIT_ERROR, 
    CONTRACT_SIGNER_DELETE_REQUEST, CONTRACT_SIGNER_DELETE_SUCCESS, CONTRACT_SIGNER_DELETE_ERROR
} from "../constants/contractSignerConstants";

const initialState = {
    contractSigners: [],
    contractSigner: {},
    loading: false,
    success: null,
    error: null,
    contractSignerRequestedCount: 0,
};

export const contractSignerReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_SIGNER_GET_REQUEST:
            return {
                ...state,
                contractSignerRequestedCount: ++state.contractSignerRequestedCount,
                loading: true,
            };
        case CONTRACT_SIGNER_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractSigners: action.payload,
                success: true,
            };
        case CONTRACT_SIGNER_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_SIGNER_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_SIGNER_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractSigner: action.payload,
                success: true,
            };
        case CONTRACT_SIGNER_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_SIGNER_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_SIGNER_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_SIGNER_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_SIGNER_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_SIGNER_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_SIGNER_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_SIGNER_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_SIGNER_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_SIGNER_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
