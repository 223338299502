import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Form,
  FormSection, SelectField,
} from "../../../components";
import { getAnObjectReferralByKey } from "../../../redux/actions/anObjectReferralActions";
import { addBrigade } from "../../../redux/actions/brigadeActions";

import {
  getDesinsectionRequestByKey,
} from "./../../../redux/actions";

import DialogLayout from "../../../components/Dialog/DialogLayout";
import {renderSections} from "../../../functions";
import {Grid} from "@mui/material";
import {getBrigadeTypes} from "../../../redux/actions/brigadeTypeActions";
import {DddDisinsectionCreateSection} from "../../DDD/_sections/DddDisinsectionCreateSection";
import {DddFinalDisinfectionCreateSection} from "../../DDD/_sections/DddFinalDisinfectionCreateSection";

export const BrigadeCreateModal = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { brigadeTypes, brigadeTypeRequestedCount } = useSelector(
      (s) => s.brigadeType
  );

  useEffect(() => {
    let response;
    const getBrigadeTypesAsync = async () => {
      response = await dispatch(
          getBrigadeTypes({ paging: { skip: 0, take: 10 } })
      );
    };
    if (brigadeTypeRequestedCount == 0) {
      getBrigadeTypesAsync();
    }
  }, []);

  const handleSelect = (e) => {
    e.preventDefault();
    setBrigadeFormId(e.target.value);
  };


  const options =
      brigadeTypes?.map(({  id, name, code }) => ({ id, label: name, value: code })) || [];


  const { desinsectionRequest } = useSelector((s) => s.desinsectionRequest);
  const { anObjectReferral } = useSelector((s) => s.anObjectReferral);

  const [brigadeFormId, setBrigadeFormId] = useState(null);

  const handleReset = () => {};

  const handleSubmit = async (e, data) => {
    e.preventDefault();
    let typeId = options.find(el => el.value === brigadeFormId).id

    setLoading(true);

    const desinsectionRequestId = desinsectionRequest?.id
      ? desinsectionRequest.id
      : null;

    const anObjectReferralId = anObjectReferral?.id
      ? anObjectReferral?.id
      : null;

    const refreshAction = desinsectionRequestId
      ? getDesinsectionRequestByKey(desinsectionRequestId)
      : getAnObjectReferralByKey(anObjectReferralId);

    const _data = {
      ...data,
      desinsectionRequestId,
      objectReferralId: anObjectReferralId,
      typeId,
    };

    const brigadeId = await dispatch(addBrigade(_data));
    if (!brigadeId) return setLoading(false);

    await dispatch(refreshAction);

    setLoading(false);
    setOpen(false);
    handleReset();
  };

  const sections = [
    {
      mode: "DddDisinsection",
      component: DddDisinsectionCreateSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "DddFinalDisinfection",
      component: DddFinalDisinfectionCreateSection,
      onSubmit: handleSubmit,
    },
  ];


  return (
      <DialogLayout setOpen={setOpen} open={open} loading={loading} title="Наряд: Создание">
        <Form onSubmit={handleSubmit}>
          <FormSection heading="Акт" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectField
                    label="Тип акта"
                    options={options}
                    onChange={handleSelect}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection mb={4}>
            {renderSections(sections, { mode: brigadeFormId })}
          </FormSection>
        </Form>
      </DialogLayout>
  );
};

BrigadeCreateModal.defaultProps = {};
