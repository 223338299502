export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'
export const OPEN_REQUEST = 'OPEN_REQUEST'
export const CLOSE_REQUEST = 'CLOSE_REQUEST'
export const OPEN_DICTIONARIES = 'OPEN_DICTIONARIES'
export const CLOSE_DICTIONARIES = 'CLOSE_DICTIONARIES'
export const OPEN_ACT = 'OPEN_ACT'
export const OPEN_ACT_DSEK = 'OPEN_ACT_DSEK'
export const CLOSE_ACT = 'CLOSE_ACT'
export const CLOSE_ACT_DSEK = 'CLOSE_ACT_DSEK'
export const OPEN_DIRECTION = 'OPEN_DIRECTION'
export const CLOSE_DIRECTION = 'CLOSE_DIRECTION'
export const OPEN_DDD = 'OPEN_DDD'
export const CLOSE_DDD = 'CLOSE_DDD'
export const OPEN_KSEC = 'OPEN_KSEC'
export const CLOSE_KSEC = 'CLOSE_KSEC'
export const OPEN_REPORT_AND_ANALYTICS = 'OPEN_REPORT_AND_ANALYTICS'
export const CLOSE_REPORT_AND_ANALYTICS = 'CLOSE_REPORT_AND_ANALYTICS'
export const OPEN_ADMINISTRATION = 'OPEN_ADMINISTRATION'
export const CLOSE_ADMINISTRATION = 'CLOSE_ADMINISTRATION'
export const OPEN_REFERAL = 'OPEN_REFERAL'
export const CLOSE_REFERAL = 'CLOSE_REFERAL'
export const OPEN_OBJECT_REFERAL = 'OPEN_OBJECT_REFERAL'
export const CLOSE_OBJECT_REFERAL = 'CLOSE_OBJECT_REFERAL'
export const OPEN_RESULT = 'OPEN_RESULT'
export const CLOSE_RESULT = 'CLOSE_RESULT'
export const OPEN_PROTOCOL = 'OPEN_PROTOCOL'
export const CLOSE_PROTOCOL = 'CLOSE_PROTOCOL'
