import { serviceWithoutActRequestCRUD } from "../../http";
import {
  SERVICE_WITHOUT_ACT_REQUEST_GET_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_GET_SUCCESS,
  SERVICE_WITHOUT_ACT_REQUEST_GET_ERROR,
  SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_SUCCESS,
  SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_ERROR,
  SERVICE_WITHOUT_ACT_REQUEST_ADD_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_ADD_SUCCESS,
  SERVICE_WITHOUT_ACT_REQUEST_ADD_ERROR,
  SERVICE_WITHOUT_ACT_REQUEST_EDIT_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_EDIT_SUCCESS,
  SERVICE_WITHOUT_ACT_REQUEST_EDIT_ERROR,
  SERVICE_WITHOUT_ACT_REQUEST_DELETE_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_DELETE_SUCCESS,
  SERVICE_WITHOUT_ACT_REQUEST_DELETE_ERROR,
  SERVICE_WITHOUT_ACT_REQUEST_WORKFLOW_UPDATE_REQUEST,
  SERVICE_WITHOUT_ACT_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  SERVICE_WITHOUT_ACT_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/serviceWithoutActRequestConstants";

export const getServiceWithoutActRequests = (args) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_GET_REQUEST,
    });

    const data = await serviceWithoutActRequestCRUD.search(args);

    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getServiceWithoutActRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await serviceWithoutActRequestCRUD.get(id);
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addServiceWithoutActRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_ADD_REQUEST,
    });

    const response = await serviceWithoutActRequestCRUD.create(data);
    await dispatch(getServiceWithoutActRequests(args));

    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: SERVICE_WITHOUT_ACT_REQUEST_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editServiceWithoutActRequest =
  (data, args) => async (dispatch) => {
    try {
      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_EDIT_REQUEST,
      });

      await serviceWithoutActRequestCRUD.edit(data);
      await dispatch(getServiceWithoutActRequests(args));

      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_EDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_EDIT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteServiceWithoutActRequest =
  (id, args) => async (dispatch) => {
    try {
      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_DELETE_REQUEST,
      });

      await serviceWithoutActRequestCRUD.delete(id);
      await dispatch(getServiceWithoutActRequests(args));

      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_DELETE_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const workflowServiceWithoutActRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: SERVICE_WITHOUT_ACT_REQUEST_WORKFLOW_UPDATE_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await serviceWithoutActRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      await dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_WORKFLOW_UPDATE_SUCCESS,
      });

      console.log({ response });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: SERVICE_WITHOUT_ACT_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
