import {DatePicker, LoadingButton} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {CircularProgress, Grid, Toolbar} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router";

import useDialogs from "../../components/Dialog/useDialogs";
import RemovePopup from "../../components/Popups/RemovePopup";
import EmployeeField from "../../components/Relations/EmployeeField";
import ResearchListField from "../../components/Relations/ResearchListField";
import {
    ContractSelect,
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect
} from "../../components/Select/domainSelects";
import {checkRoles} from "../../functions/checkRoles";
import useLoader from "../../hooks/useLoader";
import {
    actCRUD,
    anObjectReferralCRUD,
    protocolCRUD,
    resolutionActCRUD,
    resolutionCRUD,
    resultCRUD,
    departureScheduleRequestCRUD
} from "../../http/CRUD";
import DashboardLayout from "../../layouts/Dashboard";
import {getActForms} from "../../redux/actions/actFormActions";
import {addProtocol} from "../../redux/actions/protocolActions";
import {
    REQUEST_STATE_ID_COMPLETED,
    REQUEST_STATE_ID_CREATED,
    REQUEST_STATE_ID_DECLINED,
    REQUEST_STATE_ID_PROBESELECTION,
    REQUEST_STATE_ID_PROCESSING,
    REQUEST_STATE_ID_RESEARCH
} from "../../redux/constants/requestStateConstants";
import CreateProtocol from "../Protocol/Popups/CreateProtocol";
import ActPDF from "./ActPDF";
import CreateResult from "../Result/Popups/CreateResult";
import {Barcode, FormSection, BasicTable} from "../../components";
import ResultsAndProtocolsSection from "../../sections/ResultsAndProtocolsSection";
import useReload from "../../hooks/useReload";
import EditResultAndProtocolSection from "../../sections/EditResultAndProtocolSection";
import {OBJECT_REFERRAL_CREATE, OBJECT_REFERRAL_ROUTE, RESOLUTION_EDIT_ROUTE} from "../../routes";
import {barcodeToPdf} from "../../functions/barcodeHelpers";
import {actToObjectReferralMap} from "../../functions/mapping/acts";
import useRoles from "../../hooks/useRoles";
import {ORGANIZATION_ID_NCE} from "../../redux/constants/organizationConstants";
import {toUIDate} from "../../functions/dateHelpers";
import qs from "qs";
import {SERVICE_TYPE_PAYED} from "../../redux/constants/serviceTypeConstants";
import { asJSONSafe } from "../../functions";

const withActDetail = (ActForm, ActPDFGenerator, actFormKazakhName, actFormName, actFormNumber, actName) => {
    return () => {
        const {alert, DIALOG} = useDialogs();
        const {t} = useTranslation();
        const actForm = useSelector(state => state.actForm)
        const {id} = useParams();
        const isNew = useMemo(() => !(id > 0), [id]);
        const [data, setData] = useState({});
        const {start: startSubmit, stop: stopSubmit, loading: loadingSubmit} = useLoader(false);
        const {start: workflowStart, stop: workflowStop, loading: workflowLoading} = useLoader(false);
        const [results, setResults] = React.useState([]);
        const [protocols, setProtocols] = React.useState([]);
        const [resolutions, setResolutions] = React.useState([]);
        const [specifications, setSpecifications] = useState([]);

        const style = {marginLeft: 10}
        const stateCode = data.state?.code;
        const {reload, reloadValue} = useReload();
        const {isCommittee, employee} = useRoles();
        const [anObjectReferrals, setAnObjectReferrals] = useState([]);
        const {filialId, filialDivisionId} = useSelector(state => state.employee.authorizedEmployee)

        const location = useLocation();
        const queryParams = qs.parse(location.search?.replace('?', ''));
        const orderType = queryParams.orderType || 1;

        useEffect(() => {
            if (actForm.actForms.length === 0) {
                dispatch(getActForms({paging: {skip: 0}}));
            }
        }, [actForm.actForms.length]);

        const handleChange = useCallback((key, value) => {
            setData({...data, [key]: value})
        }, [data]);
        const handleChangeMulti = useCallback((obj) => {
            setData(prevState => ({...prevState, ...obj}))
        }, [data]);

        const handleChangeData = useCallback((key, value, key2) => {
            setData({...data, data: {...data.data, [key]: {'key': key2, value}}})
        }, [data]);

        const handleChangeSample = useCallback((key, value, i, key2) => {
            const array = data.data?.samples?.value.slice(0)
            array[i][key] = {'key': key2, value}
            setData({
                ...data, data: {
                    ...data.data,
                    samples: {key: 'samples', value: array}
                }
            })
        }, [data]);

        const addSample = useCallback(() => {
            data.data?.samples?.value
                ? setData({
                    ...data,
                    data: {...data.data, samples: {key: 'samples', value: [...data.data?.samples?.value, {}]}}
                })
                : setData({...data, data: {...data.data, samples: {key: 'samples', value: [{}]}}})
        }, [data]);

        const deleteSample = useCallback((index) => {
            setData({
                ...data,
                data: {
                    ...data.data,
                    samples: {key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i)}
                }
            })
        }, [data]);

        const dispatch = useDispatch();
        const {push, goBack} = useHistory();

        useEffect(() => {
            (async function () {
                if (filialId || filialDivisionId) {
                    handleChangeMulti({
                        filialId: filialId,
                        filialDivisionId: filialDivisionId,
                    });
                }
            }())
        }, [filialId, filialDivisionId])

        const schema = {}

        const getAnObjectReferrals = React.useCallback(async (id) => {
            const {result} = await anObjectReferralCRUD.search({
                paging: {skip: 0},
                filter: {
                    actId: {
                        operand1: id,
                        operator: 'equals'
                    }
                }
            });
            setAnObjectReferrals(result);
        }, [])

        const getProtocolsAndResults = React.useCallback(async (id) => {
            const {result} = await resultCRUD.search({
                paging: {skip: 0},
                filter: {
                    actId: {
                        operand1: id,
                        operator: 'equals'
                    }
                }
            });
            const protocolResponse = await protocolCRUD.search({
                paging: {skip: 0},
                filter: {
                    actId: {
                        operand1: id,
                        operator: 'equals'
                    }
                }
            });
            setProtocols(protocolResponse.result);
            setResults(result);
        }, [])

        const {loading: fetchLoading, start: fetchStart, stop: fetchStop} = useLoader(true);
        const fetch = useCallback(() => {
            if (isNew) {
                fetchStop();
                return;
            }
            fetchStart();
            actCRUD.get(id)
                .then(async (data) => {
                    let actData = typeof data.data === 'string' ? asJSONSafe(data.data) : {}
                    setData({...data, data: {...actData}});
                    await getProtocolsAndResults(id);
                    await getAnObjectReferrals(id);
                    if(data.departureScheduleRequestId) {
                    const depScheduleRequest = await departureScheduleRequestCRUD.get(data.departureScheduleRequestId);
                    setSpecifications(depScheduleRequest.specifications)
                }

                }).then(async () => {
                const {result} = await resolutionActCRUD.search({
                    filter: {
                        actId: {
                            operand1: id,
                            operator: "equals"
                        },
                    }
                })
                if (result?.length) {
                    if (result[0]) {
                        setResolutionId(result[0]?.resolutionId)
                        setResolutionActId(result[0]?.id)
                    }
                    result?.map(async el => {
                        const data = await resolutionCRUD.get(el.resolutionId);
                        setResolutions((prev) => [...prev, data])
                    })
                }
            })
                .catch(alert)
                .finally(fetchStop)
        }, [id, fetchStart, fetchStop, isNew]);

        useEffect(fetch, [fetch, reloadValue]);

        const submit = useCallback(() => new Promise((resolve, reject) => {
            if (!data.filialId) {
                alert('Филиал нужно заполнить!');
                return
            }

            const actFormObj = actForm?.actForms?.find(a => {
                return a.number === actName
            })
            const newData = {...data}
            delete newData.responsible
            delete newData.responsibleForProbes
            delete newData.performer
            startSubmit();
            (isNew
                    ? actCRUD.create({
                        createdAt: new Date(),
                        number: actName,
                        schema,
                        ...newData,
                        form: actFormObj,
                        formId: actFormObj?.id,
                        orderType: orderType
                    })
                    : actCRUD.edit({
                        id: id,
                        number: actName,
                        schema,
                        ...newData,
                        form: actFormObj,
                        formId: actFormObj?.id,
                        orderType: orderType
                    })
            )
                .then((response) => {
                    if (isNew && response.id) {
                        push(`/${actName}Detail/${response.id}`)
                    } else {
                        fetch();
                    }
                    resolve();
                    // onSubmit();
                })
                .catch((error) => {
                    reject();
                    alert(error);
                })
                .finally(stopSubmit)
        }), [data, startSubmit, stopSubmit, isNew, fetch, push, actForm]);

        const setStatus = (lastStateCode, nextStateCode, thenAction = fetch, nce) => {
            const validates = [];
            const validateNull = (key, label) => {
                if (!data[key]) validates.push(label + " нужно заполнить!");
            };
            if (nextStateCode === REQUEST_STATE_ID_PROBESELECTION) {
                validateNull("responsibleForProbesId", "Ответственный за отбор проб");
            } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
                validateNull("performerId", "Лаборант");
            } else if (nextStateCode === REQUEST_STATE_ID_PROCESSING && !nce) {
                validateNull("departmentId", "Лаборатория");
                validateNull("responsibleId", "Заведующий лаборатории/Начальник отдела");
            }
            if (validates.length > 0) {
                alert(validates.join("\n"));
                return;
            }
            workflowStart();
            submit()
                .then(() => actCRUD.workflowRun(id, lastStateCode, nextStateCode))
                .then(thenAction)
                .catch(alert)
                .finally(workflowStop);
        };

        const hasResults = useMemo(() => data.results && data.results.length > 0, [data]);
        const hasProtocols = useMemo(() => data.protocols && data.protocols.length > 0, [data]);

        const [protocolAddOpen, setProtocolAddOpen] = useState(false);
        const [resolutionId, setResolutionId] = useState(null)
        const [resolutionActId, setResolutionActId] = useState(null)
        const [createResultOpen, setCreateResultOpen] = useState(false);
        const [deleteActOpen, setDeleteActOpen] = useState(false);
        const [editResultId, setEditResultId] = useState(null);
        const [editProtocolOpen, setEditProtocolOpen] = useState(false);
        const [editProtocol, setEditProtocol] = useState(null);
        const [editResultOpen, setEditResultOpen] = useState(false);

        const handleEditResultOpen = async (resultId) => {
            setEditResultId(resultId);
            setEditResultOpen(true);
        }


        const isNotPaid = React.useCallback(() => {
                if (data?.number) {
                    return +data.number[0] === 2
                }
                return false
            }, [data?.number, data?.id]
        )

        const isPaid = React.useCallback(() => {
                if (data?.number) {
                    return +data.number[0] === 1
                }
                return false
            }, [data?.number, data?.id]
        )

        const [isDisabledData, setIsDisabledData] = useState(false)
        const [isDisabledPerformer, setIsDisabledPerformer] = useState(false)
        useEffect(() => {
            const returnIsDisabledData = () => {
                return ([REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_COMPLETED].includes(stateCode) || !checkRoles('SuperAdministrator', 'Administrator', 'Specialist', 'Committee'))
            }
            const returnIsDisabledPerformer = () => {
                return (([REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_COMPLETED].includes(stateCode) || !checkRoles('SuperAdministrator', 'Administrator', 'Supervisor', 'Committee') || stateCode === undefined))
            }
            setIsDisabledData(returnIsDisabledData())
            setIsDisabledPerformer(returnIsDisabledPerformer())
        }, [stateCode])

        const handleEditProtocolOpen = async (protocolId) => {
            await startSubmit();

            const protocol = await protocolCRUD.get(protocolId);
            await setEditProtocol(protocol);
            await setEditProtocolOpen(true);

            await stopSubmit();
        }

        const handleGenerateObjectReferral = async () => {
            const dataToObjectReferral = actToObjectReferralMap(data.data, actName);

            const objectReferralData = {
                ...dataToObjectReferral,
                filialId: data.filialId,
                divisionId: data.divisionId,
                createdById: data.createdById,
                departmentId: data.departmentId,
                responsibleId: data.responsibleId,
                responsible: data.responsible,
                performerId: data.performerId,
                performer: data.performer,
                resolutionActId: resolutionActId,
                actId: data.id,
                actName,
                governmentContractId: data?.governmentContractId,
                results: data?.results,
                protocols: data?.protocols,
                resolutionId,
                emergencyNotificationNumber: data.number,
                emergencyNotificationDate: toUIDate(data.createdAt)
            }

            push({
                pathname: OBJECT_REFERRAL_CREATE,
                state: {data: objectReferralData}
            })
        }


        const PaidControls = React.useMemo(() => {
            return <>
                {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&
                    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                   onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROBESELECTION)}>Отбор
                        Пробы</LoadingButton>}
                {REQUEST_STATE_ID_PROBESELECTION === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&
                    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                   onClick={() => setStatus(REQUEST_STATE_ID_PROBESELECTION, REQUEST_STATE_ID_PROCESSING)}>Отправить
                        в лабораторию</LoadingButton>}
                {REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') &&
                    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                   onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH)}>Назначить
                        исполнителя</LoadingButton>}
                {REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') &&
                    <LoadingButton style={style} variant={"contained"} color={"warning"}
                                   loading={workflowLoading}
                                   onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_DECLINED)}>Отклонить
                        с комментариями</LoadingButton>}
                {REQUEST_STATE_ID_RESEARCH === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Performer') &&
                    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                   onClick={() => setStatus(REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_COMPLETED)}>Завершить</LoadingButton>}
            </>
        }, [stateCode, data?.id, data])

        const NotPaidControls = React.useMemo(() => {
            return <>
                {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&
                    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                   onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROCESSING, fetch, true)}>Отправить
                        в НЦЭ</LoadingButton>}
                {/*{REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&*/}
                {/*    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}*/}
                {/*                   onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH)}>Назначить*/}
                {/*        исполнителя</LoadingButton>}*/}
                {/*{REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&*/}
                {/*    <LoadingButton style={style} variant={"contained"} color={"warning"}*/}
                {/*                   loading={workflowLoading}*/}
                {/*                   onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_DECLINED)}>Отклонить*/}
                {/*        с комментариями</LoadingButton>}*/}
                {/*{REQUEST_STATE_ID_RESEARCH === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Committee') &&*/}
                {/*    <LoadingButton style={style} variant={"contained"} loading={workflowLoading}*/}
                {/*                   onClick={() => setStatus(REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_COMPLETED)}>Завершить</LoadingButton>}*/}
            </>
        }, [stateCode, data?.id, data])

        const RenderStatus = React.useCallback(() => {
            if (isNotPaid() && REQUEST_STATE_ID_PROCESSING === stateCode) {
                return 'Отправлен в НЦЭ'
            }
            return data.state?.name
        }, [data.state?.name, stateCode])

        return (
            <DashboardLayout style={{display: "flex", flexDirection: "column"}}>
                <DIALOG/>
                <Button
                    style={{
                        marginBottom: '10px'
                    }}
                    onClick={() => {
                        goBack()
                    }}
                >
                    Вернуться
                </Button>
                {(REQUEST_STATE_ID_COMPLETED === stateCode) && <Button
                    style={{
                        marginLeft: '10px',
                        marginBottom: '10px'
                    }}
                    variant="contained"
                >
                    Подписать
                </Button>}
                {fetchLoading
                    ? <CircularProgress/>
                    : stateCode === REQUEST_STATE_ID_COMPLETED
                        ? <ActPDF data={data} name={actFormName} kazakhName={actFormKazakhName}>
                            <ActPDFGenerator data={data} name={actFormName} kazakhName={actFormKazakhName}/>
                        </ActPDF>
                        : <Box component="form" onSubmit={submit}>
                            <Paper style={{marginBottom: '15px'}}>
                                <Toolbar>
                                    <Typography style={{marginRight: "auto"}} variant={"h6"}>Статус
                                        акта: {RenderStatus() || "создаётся"}</Typography>

                                    {isNotPaid() && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&
                                        <LoadingButton onClick={handleGenerateObjectReferral} style={style}
                                                       variant={"contained"} loading={workflowLoading}>Создать заявку по ГЗ</LoadingButton>}
                                    {isNotPaid() ? NotPaidControls : PaidControls}
                                    {(REQUEST_STATE_ID_RESEARCH === stateCode) && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && isPaid() &&
                                        <LoadingButton
                                            style={style} variant={"outlined"} loading={workflowLoading}
                                            onClick={() => setCreateResultOpen(true)}
                                        >Добавить запись в журнал</LoadingButton>}
                                    {REQUEST_STATE_ID_RESEARCH === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && isPaid() &&
                                        <Tooltip
                                            title={!hasResults ? "Необходима запись в журнале" : (hasProtocols ? "Протокол уже создан" : "Создание протокола")}>
                                            <div style={style}>
                                                <LoadingButton disabled={!hasResults} variant={"outlined"}
                                                               loading={workflowLoading}
                                                               onClick={() => setProtocolAddOpen(true)}>Создать
                                                    протокол{hasProtocols ? " (уже создан)" : ""}</LoadingButton>
                                            </div>
                                        </Tooltip>}
                                    {isPaid() && <>
                                        <LoadingButton
                                            color="success"
                                            onClick={barcodeToPdf}
                                            sx={{marginLeft: '10px', marginRight: '20px'}}
                                        >
                                            Печать штрих кода
                                        </LoadingButton>
                                        <div id="div-svg">
                                            <Barcode value={data?.number}/>
                                        </div>
                                    </>
                                    }
                                    {REQUEST_STATE_ID_DECLINED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist', 'Comittee') &&
                                        <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                                       onClick={() => setStatus(REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_CREATED)}>Пересоздать</LoadingButton>}
                                    {REQUEST_STATE_ID_DECLINED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') &&
                                        <LoadingButton style={style} variant={"contained"} loading={workflowLoading}
                                                       onClick={() => setDeleteActOpen(true)}
                                                       color={"warning"}>Удалить</LoadingButton>}
                                </Toolbar>
                            </Paper>
                            {isCommittee() && employee &&
                                <Grid container>
                                    <FilialSelect
                                        style={{marginBottom: "5px"}}
                                        label={"Филиал пользователя"}
                                        fullWidth
                                        disabled={true}
                                        value={employee.filialId}
                                    />
                                    {employee.filialDivisionId && <FilialDivisionSelect
                                        style={{marginBottom: "5px"}}
                                        label={"Районное отделение пользователя"}
                                        fullWidth
                                        disabled={true}
                                        value={employee.filialDivisionId}
                                    />}
                                </Grid>
                            }
                            {!isNew && !isCommittee() && <FormSection heading="Журналы и протоколы" mb={4}>
                                <ResultsAndProtocolsSection
                                    results={results}
                                    protocols={protocols}
                                    onResultClick={async (_) => {
                                        await handleEditResultOpen(_);
                                    }}
                                    onProtocolClick={async (_) => {
                                        await handleEditProtocolOpen(_);
                                    }}
                                    resultsReloadValue={reloadValue}
                                    fetch={fetch}
                                    startLoading={async () => {
                                        //await startSubmit();
                                    }}
                                    stopLoading={async () => {
                                        //await stopSubmit();
                                    }}
                                />
                            </FormSection>}
                            <FormSection>
                                <Grid container>
                                    <ContractSelect
                                        label={"Договор КСЭК"}
                                        disabled={isDisabledData}
                                        year={(new Date()).getFullYear()}
                                        fullWidth
                                        value={data.governmentContractId}
                                        onChange={(val, obj) => {
                                            handleChangeMulti({
                                                governmentContractId: val,
                                                governmentContract: obj
                                            });
                                        }}
                                    />
                                    <FilialSelect
                                        style={{marginBottom: "5px"}}
                                        label={"Филиал"}
                                        fullWidth
                                        filialId={(checkRoles('AdministratorReadonly') || isCommittee()) ? null : JSON.parse(localStorage.employee)?.filialId}
                                        disabled={isDisabledData}
                                        value={data.filialId}
                                        organizationId={isCommittee() ? ORGANIZATION_ID_NCE : null}
                                        onChange={(val) => {
                                            handleChangeMulti({
                                                filialId: val,
                                                filialDivisionId: Number(val) !== Number(data.filialId) ? null : data.filialDivisionId
                                            })
                                        }}
                                    />
                                    <FilialDivisionSelect
                                        style={{marginBottom: "5px"}}
                                        label={"Районное отделение"}
                                        fullWidth
                                        // extraFilter={data.filialId > 0 ? { filialId: { operand1: data.filialId, operator: "equals" } } : null}
                                        disabled={isDisabledData}
                                        value={data.filialDivisionId}
                                        organizationId={isCommittee() ? ORGANIZATION_ID_NCE : null}
                                        filialId={data.filialId === 1 ? null : data.filialId}
                                        onChange={(filialDivisionId, filialDivision) => {
                                            handleChangeMulti({
                                                filialDivisionId: filialDivisionId,
                                                filialId: Number(filialDivision.filialId) !== Number(data.filialId) ? filialDivision.filialId : data.filialId
                                            })
                                        }}
                                    />
                                </Grid>
                            </FormSection>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableBody className="border-table-word">
                                            <TableRow>
                                                <TableCell className="border-table-word"></TableCell>
                                                <TableCell
                                                    className="border-table-word"
                                                    align="left"
                                                >
                                                    <p>Нысанның БҚСЖ бойынша коды</p>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <p style={{width: "50%"}}>Код формы по ОКУД</p>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Код"
                                                            style={{width: "50%"}}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                    <p>КҰЖЖ бойынша ұйым коды</p>
                                                    <div style={{display: "flex", alignItems: "center"}}>
                                                        <p style={{width: "50%"}}>Код организации по ОКПО</p>
                                                        <TextField
                                                            id="outlined-basic"
                                                            label="Код"
                                                            style={{width: "50%"}}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="border-table-word">
                                                    <p>
                                                        Қазақстан Республикасының Денсаулық сақтау министрлігі
                                                        Министерство здравоохранения Республики Казахстан
                                                    </p>
                                                </TableCell>
                                                <TableCell
                                                    className="border-table-word"
                                                    align="left"
                                                >
                                                    <p>
                                                        Қазақстан Республикасының Денсаулық сақтау министрінің
                                                        2021 жылғы "20" 08 № 84 бұйрығымен бекітілген № {actFormNumber}/е
                                                        нысанды медициналық құжаттама
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="border-table-word">
                                                    <p>
                                                        Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк
                                                        органының атауы Наименование государственного органа
                                                        санитарно-эпидемиологической службы
                                                    </p>
                                                </TableCell>
                                                <TableCell
                                                    className="border-table-word"
                                                    align="left"
                                                >
                                                    <p>
                                                        Медицинская документация Форма № {actFormNumber}/у Утверждена
                                                        приказом Министра здравоохранения Республики Казахстан
                                                        от "20" 08 2021 года №84
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                            <div style={{
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}>
                                <h5 align='center'>
                                    {actFormKazakhName}
                                </h5>
                                <h5 align='center'>
                                    {actFormName} (от)
                                </h5>
                                <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                    <DatePicker views={['day']} label="от"
                                                disabled={isDisabledData}
                                                value={data.data?.dateOfAct?.value}
                                                onChange={(value) => {
                                                    handleChangeData('dateOfAct', value, 'dateOfAct')
                                                }}
                                                minDate={new Date('1910-03-01')} renderInput={(params) =>
                                        <TextField margin="normal" {...params} helperText={null}/>
                                    }/>
                                </LocalizationProvider>
                            </div>

                            {data.number && <h5 style={{marginBottom: "30px"}} align="center">
                                №{data.number}
                            </h5>}
                            <ActForm addSample={addSample}
                                     handleChangeSample={handleChangeSample}
                                     isDisabledData={isDisabledData} data={data}
                                     handleChangeData={handleChangeData}
                                     handleChangeMulti={handleChangeMulti}
                                     deleteSample={deleteSample}/>
                            {REQUEST_STATE_ID_RESEARCH === stateCode && <ResearchListField
                                type={SERVICE_TYPE_PAYED}
                                label='Список Исследований'
                                value={data.specifications}
                                onChange={(val) => handleChange("specifications", val)}
                            />}
                            {isPaid() ? <EmployeeField
                                style={{marginBottom: "5px"}}
                                label={"Ответственный за отбор проб"}
                                value={data.responsibleForProbesId}
                                disabled={isDisabledData}
                                object={data.responsibleForProbes} onChange={(val) => {
                                setData(prevState => ({
                                    ...prevState,
                                    responsibleForProbesId: val?.id,
                                    responsibleForProbes: val
                                }))
                            }}
                            /> : null}
                            {isNotPaid() && <>
                                <Typography gutterBottom variant={'h5'}>Заявки по ГЗ:</Typography>
                                <table>
                                    {anObjectReferrals.map(_ => <tr>
                                        <td onClick={() => {
                                            push(OBJECT_REFERRAL_ROUTE(_.id))
                                        }}>{_.number}</td>
                                    </tr>)}
                                </table>
                            </>}
                            {isNotPaid() && <>
                                <Typography gutterBottom variant={'h5'}>Постановления:</Typography>
                                <table>
                                    {resolutions.map(_ => <tr>
                                        <td onClick={() => {
                                            push(RESOLUTION_EDIT_ROUTE(_.id))
                                        }}>{_.number}</td>
                                    </tr>)}
                                </table>
                            </>}
                            <FormSection mb={4}>
                                <Typography component="h4" align="left" variant="h6">
                                Услуги ({specifications?.length || 0})
                                </Typography>
                                <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BasicTable
                                        order="asc"
                                    cells={[
                                        { id: "id", label: t("specification.tableItem.id") },
                                        {
                                        id: "object",
                                        label: t("specification.tableItem.object"),
                                        render: ({ object }) => object?.name,
                                        },
                                        {
                                        id: "serviceKind",
                                        label: t("specification.tableItem.serviceKind"),
                                        render: ({ serviceKind }) => serviceKind?.name,
                                        },
                                        {
                                        id: "service",
                                        label: t("specification.tableItem.service"),
                                        render: ({ service }) => service?.name,
                                        },
                                        {
                                        id: "unit",
                                        label: t("specification.tableItem.unit"),
                                        render: ({ unit }) => unit?.name,
                                        },
                                        {
                                        id: "schedule",
                                        label: t("specification.tableItem.schedule"),
                                        render: ({ schedule }) => schedule?.name,
                                        },
                                        {
                                        id: "group",
                                        label: 'Группа',
                                        render: ({ service }) => service?.group?.name,
                                        },
                                        {
                                        id: "method",
                                        label: 'Метод',
                                        render: ({ service }) => service?.kind?.name,
                                        },
                                        {
                                        id: "count",
                                        label: t("specification.tableItem.quantity"),
                                        },
                                        {
                                        id: "contractCount",
                                        label: t("specification.tableItem.quantityContract"),
                                        },
                                        { id: "price", label: t("specification.tableItem.price") },
                                    ]}
                                    rows={specifications}
                                    disabled
                                    />
                                </Grid>
                                </Grid>
                            </FormSection>
                            {checkRoles('SuperAdministrator', 'Administrator', 'Specialist', 'Supervisor', 'Performer') && isPaid() ? <>
                                <Typography gutterBottom variant={'h5'}>Передача проб в лабораторию:</Typography>
                                <DepartmentSelect
                                    disabled={isDisabledData}
                                    style={{marginBottom: "5px"}}
                                    label={"Лаборатория"}
                                    fullWidth
                                    value={data.departmentId}
                                    onChange={(val) => handleChange('departmentId', val)}
                                />
                                <DivisionSelect
                                    style={{marginBottom: "5px"}}
                                    label={"Отдел"}
                                    fullWidth
                                    value={data.divisionId}
                                    disabled={isDisabledData}
                                    onChange={(val) => handleChange('divisionId', val)}
                                    filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
                                />
                                <EmployeeField
                                    label={"Заведующий лаборатории/Начальник отдела"}
                                    fullWidth
                                    // extraFilter={data.departmentId > 0 ? {
                                    //     departmentId: {
                                    //         operand1: data.departmentId,
                                    //         operator: "equals"
                                    //     }
                                    // } : null}
                                    disabled={isDisabledData}
                                    object={data.responsible}
                                    value={data.responsibleId}
                                    onChange={(responsible) => {
                                        const obj = {
                                            responsible,
                                            responsibleId: responsible.id,
                                            departmentId: responsible.departmentId,
                                        };
                                        handleChangeMulti(obj);
                                    }}
                                />
                                <EmployeeField
                                    label={"Врач/специалист лаборатории"} fullWidth
                                    object={data.performer}
                                    value={data.performerId}
                                    disabled={isDisabledPerformer}
                                    onChange={(performer) => {
                                        const obj = {
                                            performer,
                                            performerId: performer.id,
                                        };
                                        handleChangeMulti(obj);
                                    }}
                                />
                            </> : null}
                            <LoadingButton style={{
                                marginRight: '15px'
                            }}
                                           variant="contained"
                                // disabled={isDisabledData}
                                           onClick={submit}
                                           loading={loadingSubmit}>
                                {isNew ? "Создать" : "Сохранить"}
                            </LoadingButton>
                        </Box>}
                <EditResultAndProtocolSection
                    editResultOpen={editResultOpen}
                    setEditResultOpen={setEditResultOpen}
                    editProtocolOpen={editProtocolOpen}
                    setEditProtocolOpen={setEditProtocolOpen}
                    editResultId={editResultId}
                    editProtocol={editProtocol}
                    startLoading={async () => {
                        await startSubmit();
                    }}
                    stopLoading={async () => {
                        await stopSubmit();
                    }}
                    onEditResultSubmitted={async (_) => {
                        reload();
                    }}
                    results={results}
                />
                {createResultOpen && (
                    <CreateResult
                        action={"createByAct"}
                        title={'Создание журнала для акта'}
                        onClose={() => setCreateResultOpen(false)}
                        onSubmit={async (response) => {
                            await fetch();
                        }}
                        referral={data}
                    />
                )}
                {
                    deleteActOpen && <RemovePopup
                        deleteAction={() => actCRUD.delete(id)}
                        title={`Вы действительно хотите удалить акт №${data.number}?`}
                        onClose={() => setDeleteActOpen(false)}
                        onSubmit={() => push(`/${actName}`)}
                    />
                }
                {
                    protocolAddOpen && <CreateProtocol
                        action={"createByAct"}
                        open={protocolAddOpen}
                        initNumber={data.number}
                        title={`Создание протокола для акта №${data.number}?`}
                        onClose={() => setDeleteActOpen(false)}
                        onSubmit={(data) => {
                            return dispatch(addProtocol(data, {"sort": {"id": {"operator": "asc"}}}))
                                .then(fetch);
                        }}
                        setOpen={setProtocolAddOpen}
                        request={data}
                        act={data}
                    />
                }
            </DashboardLayout>
        );
    };
}
export default withActDetail;
