import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {CircularProgress, Tab} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getServiceKinds} from "../../../redux/actions/serviceKindActions";
import {getServiceTypes} from "../../../redux/actions/serviceTypeActions";
import {getUnitOfMeasures} from "../../../redux/actions/unitOfMeasureActions";
import {getServiceGroups} from "../../../redux/actions/serviceGroupActions";
import {getServiceSubGroups} from "../../../redux/actions/serviceSubGroupActions";
import {parseSchema} from "../../../functions/index";
import {Descriptions, Tabs, Typography} from 'antd';
import { InfoCircleOutlined, FormOutlined } from '@ant-design/icons';
const { TabPane } = Tabs;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ServiceDetails({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [referralSchema, setReferralSchema] = useState(null);
    const [probeSchema, setProbeSchema] = useState(null);
    const [resultSchema, setResultSchema] = useState(null);
    const [parentId, setParentId] = useState('');
    const [kindId, setKindId] = useState('');
    const [typeId, setTypeId] = useState('');
    const [unitId, setUnitId] = useState('');
    const [groupId, setGroupId] = useState('');
    const [subGroupId, setSubGroupId] = useState('');
    const [jsonSchema, setJsonSchema] = useState('');
    const [referralJsonSchema, setReferralJsonSchema] = useState('');
    const [probeJsonSchema, setProbeJsonSchema] = useState('');
    const [resultJsonSchema, setResultJsonSchema] = useState('');
    const [value, setValue] = React.useState('1');
    const { loading, services, service } = useSelector((state) => state.service);
    const { serviceKinds, serviceKindRequestedCount } = useSelector((state) => state.serviceKind);
    const { serviceTypes, serviceTypeRequestedCount } = useSelector((state) => state.serviceType);
    const { unitOfMeasures, unitOfMeasureRequestedCount } = useSelector((state) => state.unitOfMeasure);
    const { serviceGroups, serviceGroupRequestedCount } = useSelector((state) => state.serviceGroup);
    const { serviceSubGroups, serviceSubGroupRequestedCount } = useSelector((state) => state.serviceSubGroup);
    const emptyState = () => {
        setId(null);
        setName('');
        setCode('');
        setReferralSchema('');
        setProbeSchema('');
        setResultSchema('');
        setParentId('');
        setKindId('');
        setTypeId('');
        setUnitId('');
        setGroupId('');
        setSubGroupId('');
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, name, code, referralSchema, probeSchema, resultSchema, parentId, kindId, typeId, unitId, groupId, subGroupId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function createData(name, value) {
        return { name, value };
    }

    const rows = [
        createData('Наименование', name),
        createData('Код', code),
        createData('Направление', 'Посмотреть'),
        createData('Проба', probeSchema?.jsonSchema ?? '-'),
        createData('Результат', resultSchema?.jsonSchema ?? '-'),
        createData('Родительская', services.find(_ => _.id === parentId)?.name ?? '-'),
        createData('Вид', serviceKinds.find(_ => _.id === kindId)?.name ?? '-'),
        createData('Тип', serviceTypes.find(_ => _.id === typeId)?.name ?? '-'),
        createData('Ед.измерения', unitOfMeasures.find(_ => _.id === unitId)?.name ?? '-'),
        createData('Группа', serviceGroups.find(_ => _.id === groupId)?.name ?? '-'),
        createData('Подгруппа', serviceSubGroups.find(_ => _.id === subGroupId)?.name ?? '-')
    ];

    useEffect(() => {
        const {
            id, name, code, referralSchema, probeSchema, resultSchema, parentId, kindId, typeId, unitId, groupId, subGroupId
        } = service || {};
        setId(id);
        setName(name);
        setCode(code);
        setReferralSchema(referralSchema);
        setProbeSchema(probeSchema);
        setResultSchema(resultSchema);
        setParentId(parentId);
        setKindId(kindId);
        setTypeId(typeId);
        setUnitId(unitId);
        setGroupId(groupId);
        setSubGroupId(subGroupId);

        let referralSchemaJson = parseSchema(referralSchema);
        let probeSchemaJson = parseSchema(probeSchema);
        let resultSchemaJson = parseSchema(resultSchema);

        setJsonSchema(referralSchemaJson?.jsonSchema);
        setReferralJsonSchema(referralSchemaJson?.jsonSchema);
        setProbeJsonSchema(probeSchemaJson?.jsonSchema);
        setResultJsonSchema(resultSchemaJson?.jsonSchema);
    }, [service, services])
    useEffect(() => {
        if (!open)
            return;

        let response;
        const getServiceKindsAsync = async () => {
            response = await dispatch(getServiceKinds({ paging: { skip: 0 } }));
        }
        const getServiceTypesAsync = async () => {
            response = await dispatch(getServiceTypes({ paging: { skip: 0 } }));
        }
        const getUnitOfMeasuresAsync = async () => {
            response = await dispatch(getUnitOfMeasures({ paging: { skip: 0 } }));
        }
        const getServiceGroupsAsync = async () => {
            response = await dispatch(getServiceGroups({ paging: { skip: 0 } }));
        }
        const getServiceSubGroupsAsync = async () => {
            response = await dispatch(getServiceSubGroups({ paging: { skip: 0 } }));
        }
        if (serviceKindRequestedCount == 0)
        {
            getServiceKindsAsync();
        }
        if (serviceTypeRequestedCount == 0)
        {
            getServiceTypesAsync();
        }
        if (unitOfMeasureRequestedCount == 0)
        {
            getUnitOfMeasuresAsync();
        }
        if (serviceGroupRequestedCount == 0)
        {
            getServiceGroupsAsync();
        }
        if (serviceSubGroupRequestedCount == 0)
        {
            getServiceSubGroupsAsync();
        }
    }, [dispatch, action])

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Tabs defaultActiveKey="1">
                                <TabPane
                                    tab={
                                        <span>
                                              <InfoCircleOutlined/>
                                              Детали
                                            </span>
                                    }
                                    key="1"
                                >
                                    <Descriptions
                                        size="middle"
                                    >
                                        {
                                            rows.map(_ => {
                                                return(
                                                    <Descriptions.Item label={_.name}>{_.value}</Descriptions.Item>

                                                )
                                            })
                                        }
                                    </Descriptions>
                                </TabPane>
                                {/* <TabPane
                                    style={{width: "40rem"}}
                                    tab={
                                        <span>
                                              <FormOutlined/>
                                            Форма направления
                                        </span>
                                    }
                                    key="2"
                                >
                                    {
                                        referralJsonSchema === '' ?
                                            <p>Форма направления отсутствует</p>
                                            :
                                            <Form
                                                schema={referralJsonSchema ? parseSchema(referralJsonSchema) : {}}
                                                disabled
                                            >
                                                <button style={{display: 'none'}}/>
                                            </Form>
                                    }
                                </TabPane>
                                <TabPane
                                    style={{width: "40rem"}}
                                    tab={
                                        <span>
                                              <FormOutlined/>
                                            Форма пробы
                                        </span>
                                    }
                                    key="3"
                                >
                                    {
                                        probeJsonSchema === '' ?
                                            <p>Форма пробы отсутствует</p>
                                            :
                                            <Form
                                                schema={probeJsonSchema ? parseSchema(probeJsonSchema) : {}}
                                                disabled
                                            >
                                                <button style={{display: 'none'}}/>
                                            </Form>
                                    }
                                </TabPane>
                                <TabPane
                                    style={{width: "40rem"}}
                                    tab={
                                        <span>
                                              <FormOutlined/>
                                            Форма результата
                                        </span>
                                    }
                                    key="4"
                                >
                                    {
                                        resultJsonSchema === '' ?
                                            <p>Форма результата отсутствует</p>
                                            :
                                            <Form
                                                schema={resultJsonSchema ? parseSchema(resultJsonSchema) : {}}
                                                disabled
                                            >
                                                <button style={{display: 'none'}}/>
                                            </Form>
                                    }
                                </TabPane> */}
                            </Tabs>
                            {/*<TabContext value={value}>*/}
                            {/*    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>*/}
                            {/*        <TabList onChange={handleChange} aria-label="lab API tabs example">*/}
                            {/*            <Tab label="Детали" value="1"/>*/}
                            {/*            <Tab label="Форма" value="2"/>*/}
                            {/*        </TabList>*/}
                            {/*    </Box>*/}
                            {/*    <TabPanel value="1">*/}
                            {/*        <TableContainer component={Paper}>*/}
                            {/*            <TableComponent*/}
                            {/*                sx={{minWidth: 650}}*/}
                            {/*                aria-label="simple table"*/}
                            {/*                size={'small'}*/}
                            {/*            >*/}
                            {/*                <TableBody>*/}
                            {/*                    {rows.map((row) => (*/}
                            {/*                        <TableRow*/}
                            {/*                            key={row.name}*/}
                            {/*                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}*/}
                            {/*                        >*/}
                            {/*                            <TableCell component="th" scope="row">*/}
                            {/*                                {row.name}*/}
                            {/*                            </TableCell>*/}
                            {/*                            <TableCell align="right">{*/}
                            {/*                                row.value === 'Посмотреть' ?*/}
                            {/*                                    <a href="#" onClick={() => setValue('2')}>{row.value}</a>*/}
                            {/*                                    : row.value*/}
                            {/*                            }</TableCell>*/}
                            {/*                        </TableRow>*/}
                            {/*                    ))}*/}
                            {/*                </TableBody>*/}
                            {/*            </TableComponent>*/}
                            {/*        </TableContainer>*/}
                            {/*    </TabPanel>*/}
                            {/*    <TabPanel value="2">*/}
                            {/*        <Form*/}
                            {/*            schema={jsonSchema ? JSON.parse(jsonSchema) : {}}*/}
                            {/*        />*/}
                            {/*    </TabPanel>*/}
                            {/*</TabContext>*/}
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
