import { 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_ERROR, 
    TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_REQUEST, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_SUCCESS, TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_ERROR
} from "../constants/technicalRegulationsOfCustomsUnionConstants";

const initialState = {
    technicalRegulationsOfCustomsUnions: [],
    technicalRegulationsOfCustomsUnion: {},
    loading: false,
    success: null,
    error: null,
    technicalRegulationsOfCustomsUnionRequestedCount: 0,
};

export const technicalRegulationsOfCustomsUnionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_REQUEST:
            return {
                ...state,
                technicalRegulationsOfCustomsUnionRequestedCount: ++state.technicalRegulationsOfCustomsUnionRequestedCount,
                loading: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                technicalRegulationsOfCustomsUnions: action.payload,
                success: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                technicalRegulationsOfCustomsUnion: action.payload,
                success: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
