import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import { CircularProgress, Grid, Button, LinearProgress, TableHead, TableRow, TableCell, TableBody, IconButton } from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { mapPlanData, mapEmployeeData, mapUserData } from "../../../functions/viewDataMap";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ruLocale from "date-fns/locale/ru";
import { v4 as uuidv4 } from "uuid";
import {
    ContractSelect,
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect, OrganizationSelect, ServiceSelect
} from "../../../components/Select/domainSelects";
import { planCRUD } from "../../../http";
import useAlert from "../../../hooks/useAlert";
import { PLAN_DETAIL_ROUTE, PLAN_LIST_ROUTE } from "../../../routes";
import PositionField from "../../../components/Relations/PositionField";
import Stack from "@mui/material/Stack";
import useReload from "../../../hooks/useReload";
import { getPlans } from "../../../redux/actions";
import { convertDateTime } from "../../../libs/timezone";
import { Box } from "@mui/system";
import { toUIJson } from "../../../functions";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ServiceField from "../../../components/Relations/ServiceField";
import { Table } from "../../../components/PDFGenerator/Table";
import { NumberField2 } from "../../../components/Fields/NumberField2";
import { formatDateAsMonthYear } from "../../../functions/formatters";
import { toUIDate } from "../../../functions/dateHelpers";

const PlanDetailForm = () => {
    const { id } = useParams();
    const history = useHistory()
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)
    const [userData, setUserData] = useState(null)
    const alert = useAlert(false, 1)
    const [isNew, setIsNew] = useState(id === "new")
    const dispatch = useDispatch()
    const { review } = useLocation().state || false
    const [roleBlocks, setRoleBlocks] = useState({})
    const [selectedImage, setSelectedImage] = useState(null);
    const cellHeadStyles = { fontWeight: "bold" };
    const [planData, setPlanData] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const { reload, reloadValue } = useReload();

    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: loadingSubmit,
    } = useLoader(false);
    const [fields, setFields] = useState({})
    const submitPlanData = useMemo(() => {
        const submitData = mapPlanData(fields);
        return submitData;
    }, [fields]);

    const { loading, start, stop } = useLoader(false)
    const fetch = useCallback(async () => {
        try {
            start()
            if (!isNew) {
                const data = await planCRUD.get(id)
                setFields((state) => {
                    return {
                        ...state,
                        departmentId: data.department?.id || null,
                        filialId: data.filial?.id || null,
                        filialDivisionId: data.filialDivisionId || null,
                        contractId: data.contractId || null,
                        serviceId: data.serviceId || null,
                        service: data.service || null,
                        divisionId: data.divisionId || null,
                        sign: data.sign || null,
                        year: new Date()
                    }
                })

                const image = toUIJson(data.sign);

                if (image) setSelectedImage(image.base64);
                console.log(toUIJson(image.base64))
            }
            else {
                setFields((state) => {
                    return {
                        ...state,
                        year: new Date()
                    }
                })
            }
            stop()
        } catch (err) {
            stop()
            alert.show(0, "Ошибка загрузки!")

        }

    }, [dispatch, id])

    useEffect(fetch, [fetch, reloadValue])



    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    // On file select (from the pop up)
    const onFileChange = async event => {
        handleImageChange(event);
        const base64 = await convertBase64(event.target.files[0]);
        console.log(base64)

        setFields((state) => {
            return {
                ...state,
                sign: { base64 }
            }
        })
    };

    // On file upload (click the upload button)
    const onFileUpload = () => {

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "myFile",
            this.state.selectedFile,
            this.state.selectedFile.name
        );

        // Details of the uploaded file
        console.log(this.state.selectedFile);

        // Request made to the backend api
        // Send formData object
        // axios.post("api/uploadfile", formData);
    };

    // File content to be displayed after
    // file upload is complete
    const fileData = () => {

        if (this.state.selectedFile) {

            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {this.state.selectedFile.name}</p>

                    <p>File Type: {this.state.selectedFile.type}</p>

                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>

                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose before Pressing the Upload button</h4>
                </div>
            );
        }
    };

    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    useEffect(() => {
        if (fields.service && fields.year && fields.contract) {
            const newRows = months.map((month) => {
                fields.year.setMonth(month);
                return({
                    contract: fields.contract, // Replace with your selected contract
                    service: fields.service,
                    month: fields.year,
                    number: 0,
                    sum: 0,
            })});
            setPlanData(newRows);
            console.log(planData)
        }
    }, [fields]);


    const createPlanObjects = (data) => {
        return data.map((item) => ({
          createdAt: (new Date()).toISOString(),
          month: item.month.toISOString(), // Assuming 'month' is a Date object
          amount: item.number,
          sum: parseFloat(item.sum),
          price: item.service.price,
          costCoefficient: item.service.costCoefficient,
          isDeleted: false,
          createdById: authorizedEmployee.id,
          serviceId: item.service.id,
          groupId: item.service.groupId,
          subGroupId: item.service.subGroupId,
          kindId: item.service.kindId,
          unitId: item.service.unitId,
          priceListId: item.service.prices[0].id,
          contractId: item.contract.id,
          filialId: fields.filialId,
          filialDivisionId: fields.filialDivisionId,
          departmentId: fields.departmentId,
          divisionId: fields.divisionId,
          stateId: 1
        }));
      };

      const submit = useCallback(() => {
        if (isNew) {
            if(planData){
                console.log(planData)
                var planList = createPlanObjects(planData); 
                planList.map((e) => {
                    planCRUD.create({
                        ...e,
                    }).then((id) => {
                        
                    }).catch(error => {
                        alert.show(0, error)
                    })
                })
            }
            history.push(PLAN_LIST_ROUTE)
        } else {
            // planCRUD.edit({
            //     id: id,
            //     ...submitPlanData,
            // }).then(resp => {
            //     alert.show(1, "Изменения сохранены")
            // }).catch(error => {
            //     alert.show(0, error)
            // })
        }
    }, [dispatch, planData])



    return (
        <DashboardLayout>
            {loading ? <CircularProgress /> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew ? "Создание" : "Редактирование"} плана<br /><Typography
                                    style={{ fontSize: 12 }}>{fields.id || "Название отсутствует"}</Typography>
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <NavLink style={{ color: '#2f9e69' }} to={PLAN_LIST_ROUTE}><span
                                    style={{ color: "2f9e69" }}>Вернуться к таблице</span></NavLink>
                            </Stack>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{ paddingTop: 16 }}>
                    <Paper style={{ paddingBottom: 20 }}>
                        <Grid container xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant={"h6"} style={{ display: 'inline' }}>Основная информация</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ContractSelect
                                    label={"Договор"}
                                    year={
                                        (new Date()).getFullYear()
                                    }
                                    fullWidth
                                    value={fields.contractId}
                                    onChange={(val, obj) => {
                                        setFields((state) => {
                                            return {
                                                ...state,
                                                contractId: val,
                                                contract: obj
                                            }
                                        })

                                    }}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <FilialSelect
                                    label={"Филиал"}
                                    disabled={review}
                                    organizationId={fields.organizationId || null}
                                    fullWidth
                                    value={fields.filialId || ""}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                filialId: val
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FilialDivisionSelect
                                    disabled={review}
                                    label={"Районное отделение"}
                                    fullWidth
                                    value={fields.filialDivisionId}
                                    isNullable={true}
                                    filialId={fields.filialId || ""}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                filialDivisionId: val
                                            }
                                        })
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                <DepartmentSelect
                                    label={"Лаборатория"}
                                    disabled={review}
                                    fullWidth
                                    value={fields.departmentId || ""}
                                    isNullable={true}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                departmentId: val
                                            }
                                        })
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                <DivisionSelect
                                    label={"Отделение лаборатории"}
                                    disabled={review}
                                    fullWidth
                                    value={fields.divisionId || ""}
                                    isNullable={true}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                divisionId: val
                                            }
                                        })
                                    }} />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <DatePicker label={'Год'} views={['year']} /> */}
                                <LocalizationProvider
                                    locale={ruLocale}
                                    dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        views={["year"]}
                                        label="Год"
                                        value={fields.year}
                                        onChange={(value) => {
                                            console.log(value)
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    year: value
                                                }
                                            })
                                        }}
                                        minDate={new Date("1910-03-01")}
                                        renderInput={(params) => (
                                            <TextField margin="normal" {...params} helperText={null} />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 24 }}>
                                <ServiceField
                                    label={"Услуга"}
                                    value={fields.serviceId}
                                    object={fields.service}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                serviceId: val.id,
                                                service: val
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} xs={12}>
                        </Grid>

                        <Grid container xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
                            <Grid item xs={12} marginTop={5}>
                                <Typography variant={"h6"} style={{ display: 'inline' }}>Планы</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                Договор
                                            </TableCell>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                Услуга
                                            </TableCell>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                Месяц
                                            </TableCell>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                Количество
                                            </TableCell>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                Сумма
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            (planData || []).map((item, i) => {
                                                const handleChange = (key, val) => {
                                                    planData[i][key] = val;
                                                    setPlanData([...planData]);
                                                    console.log(planData)
                                                };
                                                let prices = item.sum || [];
                                                let price = prices.length > 0 ? prices[0].price : 0;
                                                if (!item.price) item.price = price;
                                                return (
                                                    <TableRow>
                                                        <TableCell variant={"head"} style={cellHeadStyles}>
                                                            {item.contract?.name}
                                                        </TableCell>
                                                        <TableCell variant={"head"} style={cellHeadStyles}>
                                                            {item.service?.name}
                                                        </TableCell>
                                                        <TableCell variant={"head"} style={cellHeadStyles}>
                                                            {formatDateAsMonthYear(item.month)}
                                                        </TableCell>
                                                        <TableCell variant={"head"} style={cellHeadStyles}>
                                                            <NumberField2
                                                                fullWidth
                                                                value={item.number}
                                                                defaultValue={1}
                                                                onChange={(e) =>
                                                                    {
                                                                        handleChange("number", parseInt(e.target.value))
                                                                        handleChange("sum", parseInt(e.target.value)*item.service?.price)
                                                                        // console.log(item)
                                                                    }
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell variant={"head"} style={cellHeadStyles}>
                                                            {/* <NumberField2
                                                                fullWidth
                                                                value={item.sum}
                                                                defaultValue={1}
                                                                onChange={(e) =>
                                                                    {
                                                                        handleChange("sum", parseFloat(e.target.value).toFixed(1))
                                                                        console.log(item)

                                                                    }
                                                                }
                                                                step="1"
                                                            /> */}
                                                            <TextField
                                                                type="number"
                                                                // disabled
                                                                fullWidth
                                                                variant="standard"
                                                                value={item.sum}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                onChange={(e) =>
                                                                    {
                                                                        handleChange("sum", e.target.value)
                                                                        console.log(item)

                                                                    }
                                                                }
                                                            />

                                                        </TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>

                            </Grid>
                            <Grid container marginTop={1} spacing={2} xs={12}>

                            </Grid>


                        </Grid>


                    </Paper>
                </Grid>
                {!review && (
                    <LoadingButton style={{ marginTop: "10px" }} loading={loadingSubmit} variant="contained" onClick={() => {
                        submit()
                    }}>
                        Сохранить
                    </LoadingButton>
                )}

                {alert.render()}
            </>}


        </DashboardLayout>
    )
}

export default PlanDetailForm