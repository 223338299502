import { contractCRUD } from "../../http/CRUD";
import {
  CONTRACT_GET_REQUEST,
  CONTRACT_GET_SUCCESS,
  CONTRACT_GET_ERROR,
  CONTRACT_GET_BY_KEY_REQUEST,
  CONTRACT_GET_BY_KEY_SUCCESS,
  CONTRACT_GET_BY_KEY_ERROR,
  CONTRACT_ADD_REQUEST,
  CONTRACT_ADD_SUCCESS,
  CONTRACT_ADD_ERROR,
  CONTRACT_EDIT_REQUEST,
  CONTRACT_EDIT_SUCCESS,
  CONTRACT_EDIT_ERROR,
  CONTRACT_DELETE_REQUEST,
  CONTRACT_DELETE_SUCCESS,
  CONTRACT_DELETE_ERROR,
  CONTRACT_GETIN10YEAR_REQUEST,
  CONTRACT_GETIN10YEAR_SUCCESS,
  CONTRACT_GETIN10YEAR_ERROR,
} from "../constants/contractConstants";
import { CONTRACT_TYPE_ID_GOVERNMENT_REQUESTS } from "../constants/contractTypeConstants";

export const getContracts = (args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_GET_REQUEST,
    });

    const data = await contractCRUD.search(args);

    dispatch({
      type: CONTRACT_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: CONTRACT_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getGovernmentContractsIn10Years = (args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_GETIN10YEAR_REQUEST,
    });

    const startYear = (new Date()).getFullYear() - 10;
    const endYear = (new Date()).getFullYear();
    args = args || { filter: {} };
    args.filter = { ...args.filter,
      actionAt: { operand1: new Date(startYear, 1, 1), operand2: new Date(endYear, 12, 31), operator: "between" },
      typeId: { operand1: CONTRACT_TYPE_ID_GOVERNMENT_REQUESTS, operator: "equals" }
    }

    const data = await contractCRUD.search(args);
    const items = data.result;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      try {
        item.parsedActionAt = new Date(item.actionAt);
      } catch (e) {
        console.log(e);
      }
    }

    dispatch({
      type: CONTRACT_GETIN10YEAR_SUCCESS,
      payload: data.result,
    });

    return data.result;
  } catch (e) {
    dispatch({
      type: CONTRACT_GETIN10YEAR_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getGovernmentContracts = (args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_GET_REQUEST,
    });

    const year = (new Date()).getFullYear();
    args = args || { filter: {} };
    args.filter = { ...args.filter,
      actionAt: { operand1: new Date(year, 1, 1), operand2: new Date(year, 12, 31), operator: "between" },
      typeId: { operand1: CONTRACT_TYPE_ID_GOVERNMENT_REQUESTS, operator: "equals" }
    }

    const data = await contractCRUD.search(args);
    const items = data.result;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      try {
        item.parsedActionAt = new Date(item.actionAt);
      } catch (e) {
        console.log(e);
      }
    }

    dispatch({
      type: CONTRACT_GET_SUCCESS,
      payload: data.result,
    });

    return data.result;
  } catch (e) {
    dispatch({
      type: CONTRACT_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getContractByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_GET_BY_KEY_REQUEST,
    });

    const data = await contractCRUD.get(id);
    dispatch({
      type: CONTRACT_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (e) {
    dispatch({
      type: CONTRACT_GET_BY_KEY_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const addContract = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_ADD_REQUEST,
    });

    await contractCRUD.create(data);
    dispatch(getContracts(args || {}));
    dispatch({
      type: CONTRACT_ADD_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: CONTRACT_ADD_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const editContract = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_EDIT_REQUEST,
    });

    await contractCRUD.edit(data);
    dispatch(getContracts(args || {}));
    dispatch({
      type: CONTRACT_EDIT_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: CONTRACT_EDIT_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const deleteContract = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_DELETE_REQUEST,
    });

    await contractCRUD.delete(id);

    dispatch(getContracts(args || {}));
    dispatch({
      type: CONTRACT_DELETE_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: CONTRACT_DELETE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};
