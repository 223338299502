import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

// import { PageHeaderHeading } from "./PageHeaderHeading";
// import { PageHeaderStatus } from "./PageHeaderStatus";
// import { PageHeaderControls } from "./PageHeaderControls";

export const BasicModalHeader = (props) => {
  const { heading, status, controls, children } = props;

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        padding: "20px 0px",
        minHeight: "50px",
      }}
      {...props}
    >
      <Grid
        item
        xs={8}
        style={{ display: "flex", justifyContent: "flex-start" }}
      >
        {heading && (
          <Grid item {...props}>
            <Typography component="h1" align="center" variant="h5">
              {heading}
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs={4}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {controls && (
          <Grid item sx={{ marginLeft: "40px" }} {...props}>
            {controls}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

BasicModalHeader.defaultProps = {
  heading: "Страница",
};
