import React, { useState } from "react";
import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import PatientCRUDTable from "../CRUD/Tables/PatientCRUDTable";
import SimpleTable from "../SimpleTable";
import Toolbar from "@mui/material/Toolbar";

import useCreatePatientForm from "../../models/Patient/usePatientForm";

export default function PatientField({ value, object, disabled, onChange }) {
  const [open, setOpen] = useState(false);

  const { openCreatePatientForm, DIALOG } = useCreatePatientForm(); 

  return (
    <>
      <DIALOG />
      <Grid container spacing={1} style={{ paddingLeft: 10 }}>
        {/* Field Line */}
        <Grid item xs={12}>
          <Toolbar>
            <Typography variant={"h6"}>Пациент</Typography>
            <Button onClick={() => setOpen(true)} disabled={disabled} variant={"outlined"} style={{ marginLeft: "15px" }}>
              Выбрать пациента
            </Button>
            <Button
              onClick={() => openCreatePatientForm({ afterSubmit: onChange })}
              style={{ marginLeft: "10px" }}
              variant={"outlined"}
              disabled={disabled}
            >
              + Регистрация пациента
            </Button>
          </Toolbar>
        </Grid>
        {/* Info Line */}
        <Grid container spacing={1} item xs={12}>
          <SimpleTable
            data={object || {}}
            columns={[
              { label: "ФИО", code: "fullName" },
              { label: "ИИН", code: "identityNumber" },
              {
                label: "Дата рождения",
                code: "birthDate",
                type: "date",
                format: "DD MMMM YYYY",
              },
              { label: "Возраст", code: "yearsOld" },
              { label: "Пол", value: object?.gender?.name },
              { label: "Адрес", value: object?.addressLine },
              {
                label: "Место учёбы/работы",
                value: object?.placeOfWorkOrEducation,
              },
              { label: "Контактный телефон", code: "phoneNumber" },
            ]}
            defaultEmptyValue={"-"}
          />
        </Grid>
      </Grid>
      {/* Dialog Select*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"80%"}
      >
        <DialogTitle>Выбор пациента</DialogTitle>
        <Divider />
        <DialogContent>
          <PatientCRUDTable
            onClickCreateButton={undefined} // что бы не показывать кнопку создания
            onRowClick={(record, rowIndex) => {
              onChange(record);
              setOpen(false);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"text"}
            style={{ marginLeft: "auto" }}
            onClick={() => setOpen(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
