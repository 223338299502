import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import ActCRUDTable from "../../components/CRUD/Tables/ActCRUDTable";
import {actCRUD, actFormCRUD} from "../../http";
import DashboardLayout from "../../layouts/Dashboard";
import useReload from "../../hooks/useReload";
import {confirmDialog} from "../../components/Dialog/DialogDelete";
import {checkRoles} from "../../functions/checkRoles";

const ActTable = () => {
  const { pathname } = useLocation()
  const [actForm, setActForm] = useState()
  let isDSEK = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.includes('DSEK')
  debugger;
  useEffect(() => {
    let path = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.replace('DSEK', '')
    actFormCRUD.search({
      "filter": {
        "number": {
          "operand1": path,
          "operator": 1
        },
      }
    }).then((data) => {
      setActForm(data.result[0])
    })
  }, [])

  const { reload, reloadValue } = useReload();

  const handleDelete = (_) => {
    return confirmDialog('Удаление акта, ID:' + _, 'Вы действительно хотите удалить этот акт?', async () => {
      await actCRUD.delete(_);
      await reload();
    })
  }

  const extraFilter = {
    "formId": {
      "operand1": actForm?.id,
      "operator": 'equals'
    },
    "orderType": {
      "operand1": isDSEK ? 2 : 1,
      "operator": 1
    }
  }

  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
      {actForm?.id
        ? <ActCRUDTable reloadValue={reloadValue} onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} extraFilter={extraFilter} />
        : <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>}
    </DashboardLayout>
  );
};

export default ActTable;
