import React from 'react';
import CRUDTable from "../CRUDTable";
import {serviceCRUD} from "../../../http/CRUD";
import {getDepartments, getDivisions} from "../../../redux/actions";
import {getServiceGroups} from '../../../redux/actions/serviceGroupActions';
import {getServiceSubGroups} from '../../../redux/actions/serviceSubGroupActions';
import {getServiceKinds} from '../../../redux/actions/serviceKindActions';

export default function ServiceCRUDTable({ defaultFilter, ...props}) {
    const columns = [
        {title: "ID", dataIndex: 'id', key: 'id'},
        {title: "Наименование", dataIndex: 'name', key: 'name'},
        {title: "Группа", dataIndex: 'group', key: 'group', render: _ => _?.name},
        {title: "Подгруппа", dataIndex: 'subGroup', key: 'subGroup', render: _ => _?.name},
        {title: "Метод", dataIndex: 'kind', key: 'kind', render: _ => _?.name},
        {title: "Код", dataIndex: 'code', key: 'code'},
        {title: "Ед. измерения", dataIndex: 'unit', key: 'unit', render: _ => _?.name},
        {title: "Лаборатория", dataIndex: 'department', key: 'department', render: _ => _?.name},
        {title: "Отдел", dataIndex: 'division', key: 'division', render: _ => _?.name},
        {title: "Весовой коэффициент", dataIndex: 'costCoefficient', key: 'costCoefficient'},
        {title: !props.isTypeGovernment ? "Цена" : "Единый базовый тариф", dataIndex: 'price', key: 'price'},
    ]

    const searchFields = [
        {
            title: "Код",
            key: "code",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ];

    const filterFields = [
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Отдел",
            key: "divisionId",
            compareType: "equals",
            reducerKey: "division",
            reducerArrayKey: "divisions",
            reducerAction: getDivisions,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Группа",
            key: "groupId",
            parentKey: 'departmentId',
            compareType: "equals",
            reducerKey: "serviceGroup",
            reducerArrayKey: "serviceGroups",
            reducerAction: getServiceGroups,
            filterQuery: true,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Подгруппа",
            key: "subGroupId",
            parentKey: 'groupId',
            parentKey2: 'departmentId',
            compareType: "equals",
            reducerKey: "serviceSubGroup",
            reducerArrayKey: "serviceSubGroups",
            reducerAction: getServiceSubGroups,
            filterQuery: true,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Метод",
            key: "methodId",
            parentKey: 'subGroupId',
            parentKey2: 'groupId',
            parentKey3: 'departmentId',
            compareType: "equals",
            reducerKey: "serviceKind",
            reducerArrayKey: "serviceKinds",
            reducerAction: getServiceKinds,
            filterQuery: true,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
    ];

    return <CRUDTable
        search={serviceCRUD.search}
        columns={columns}
        searchFields={searchFields}
        filterFields={filterFields}
        defaultFilter={defaultFilter}
        {...props}
    />
}