import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import DirectionCRUDTable from "../../components/CRUD/Tables/DirectionCRUDTable";
import {actCRUD, actFormCRUD} from "../../http";
import DashboardLayout from "../../layouts/Dashboard";
import {checkRoles} from "../../functions/checkRoles";
import {confirmDialog} from "../../components/Dialog/DialogDelete";
import useReload from "../../hooks/useReload";
import useRoles from "../../hooks/useRoles";

const DirectionTable = () => {
  const { pathname } = useLocation()
  const { reload, reloadValue } = useReload();

  const { isCommittee } = useRoles();

  const [actForm, setActForm] = useState()
  useEffect(() => {
    actFormCRUD.search({
      "filter": {
        "number": {
          "operand1": `${pathname[1].toUpperCase()}${pathname.substring(2)}`,
          "operator": 1
        }
      }
    }).then((data) => {
      setActForm(data.result[0])
    })
  }, [])
  const employee = localStorage.employee ? JSON.parse(localStorage.employee) : {}
  const extraFilter = {
    "formId": {
      "operand1": actForm?.id,
      "operator": 'equals'
    }
  }
  if (employee?.filialId !== 1) {    
    const filialIdName = isCommittee() ? "sourceFilialId" : "filialId";
    const filialDivisionIdName = isCommittee() ? "sourceFilialDivisionId" : "filialDivisionId";

    if (employee?.filialId) {
      extraFilter[filialIdName] = {
        "operand1": employee?.filialId,
        "operator": "equals"
      }
    }
    if (employee?.departmentId) {
      extraFilter["departmentId"] = {
        "operand1": employee?.departmentId,
        "operator": "equals"
      }
    }
    if (employee?.filialDivisionId) {
      extraFilter[filialDivisionIdName] = {
        "operand1": employee?.filialDivisionId,
        "operator": "equals"
      }
    }
  }

  const handleDelete = (_) => {
    return confirmDialog('Удаление направления, ID:' + _, 'Вы действительно хотите удалить этот акт?', async () => {
      await actCRUD.delete(_);
      await reload();
    })
  }


  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
      {actForm?.id
        ? <DirectionCRUDTable reloadValue={reloadValue} onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} extraFilter={extraFilter} />
        : <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>}
    </DashboardLayout>
  );
};

export default DirectionTable;
