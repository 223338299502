export const normalizeIndicators = (value) => {
    let version = 2;

    if (value?.length > 0 && !value[0].indicators) {
        version = 1;
    }

    switch (version) {
        case 1:
            return [ { indicators: value, groupId: value[0].groupId } ];
    }

    return value;
}