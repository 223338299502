import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {Button, Dialog, DialogContent, Divider} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import React, {useState} from "react";

const defaultProps = {
  label: "Элемент",
  getObjectName: (object) => object.fullName || object.name || object.id
}

const RelationField = ({value, label, object, onChange, getObjectName, CRUDTableComponent, ...props}) => {
  const [open, setOpen] = useState(false);

  return <>
    <Grid container spacing={1}>
      {/* Field Line */}
      <Grid item xs={12}>
        <Toolbar>
          <Typography style={{fontWeight: "bold", width: "50%"}}>{label}: </Typography>
          <Typography>{getObjectName(object || {})}</Typography>
          <Button onClick={() => setOpen(true)}>Выбрать</Button>
        </Toolbar>
      </Grid>
    </Grid>
    {/* Dialog Select*/}
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={"80%"}>
      <DialogTitle>Выбор</DialogTitle>
      <Divider/>
      <DialogContent>
        <CRUDTableComponent
          onClickCreateButton={undefined} // что бы не показывать кнопку создания
          onRowClick={(record, rowIndex) => {
            if(onChange) onChange(record);
            setOpen(false);
          }}
        />
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button variant={"text"} style={{marginLeft: "auto"}} onClick={() => setOpen(false)}>Отменить</Button>
      </DialogActions>
    </Dialog>
  </>
}

RelationField.defaultProps = defaultProps;

export default RelationField;