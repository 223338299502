export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_REQUEST = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_REQUEST';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_SUCCESS = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_SUCCESS';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_ERROR = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_ERROR';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_REQUEST = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_REQUEST';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_SUCCESS = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_SUCCESS';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_ERROR = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_ERROR';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_REQUEST = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_REQUEST';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_SUCCESS = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_SUCCESS';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_ERROR = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_ERROR';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_REQUEST = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_REQUEST';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_SUCCESS = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_SUCCESS';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_ERROR = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_ERROR';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_REQUEST = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_REQUEST';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_SUCCESS = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_SUCCESS';
export const CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_ERROR = 'CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_ERROR';
