export const CLINICAL_REQUEST_GET_REQUEST = "CLINICAL_REQUEST_GET_REQUEST";
export const CLINICAL_REQUEST_GET_SUCCESS = "CLINICAL_REQUEST_GET_SUCCESS";
export const CLINICAL_REQUEST_GET_ERROR = "CLINICAL_REQUEST_GET_ERROR";
export const CLINICAL_REQUEST_GET_BY_KEY_REQUEST =
  "CLINICAL_REQUEST_GET_BY_KEY_REQUEST";
export const CLINICAL_REQUEST_GET_BY_KEY_SUCCESS =
  "CLINICAL_REQUEST_GET_BY_KEY_SUCCESS";
export const CLINICAL_REQUEST_GET_BY_KEY_ERROR =
  "CLINICAL_REQUEST_GET_BY_KEY_ERROR";
export const CLINICAL_REQUEST_ADD_REQUEST = "CLINICAL_REQUEST_ADD_REQUEST";
export const CLINICAL_REQUEST_ADD_SUCCESS = "CLINICAL_REQUEST_ADD_SUCCESS";
export const CLINICAL_REQUEST_ADD_ERROR = "CLINICAL_REQUEST_ADD_ERROR";
export const CLINICAL_REQUEST_EDIT_REQUEST = "CLINICAL_REQUEST_EDIT_REQUEST";
export const CLINICAL_REQUEST_EDIT_SUCCESS = "CLINICAL_REQUEST_EDIT_SUCCESS";
export const CLINICAL_REQUEST_EDIT_ERROR = "CLINICAL_REQUEST_EDIT_ERROR";
export const CLINICAL_REQUEST_DELETE_REQUEST =
  "CLINICAL_REQUEST_DELETE_REQUEST";
export const CLINICAL_REQUEST_DELETE_SUCCESS =
  "CLINICAL_REQUEST_DELETE_SUCCESS";
export const CLINICAL_REQUEST_DELETE_ERROR = "CLINICAL_REQUEST_DELETE_ERROR";

export const CLINICAL_REQUEST_WORKFLOW_UPDATE_REQUEST =
  "CLINICAL_REQUEST_WORKFLOW_UPDATE_REQUEST";
export const CLINICAL_REQUEST_WORKFLOW_UPDATE_SUCCESS =
  "CLINICAL_REQUEST_WORKFLOW_UPDATE_SUCCESS";
export const CLINICAL_REQUEST_WORKFLOW_UPDATE_ERROR =
  "CLINICAL_REQUEST_WORKFLOW_UPDATE_ERROR";
