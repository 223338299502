import withActDetail from "../withActDetail";
import ActRadioForm from "./ActRadioForm";
import ActRadioPDFGenerator from "./ActRadioPDFGenerator";

const actFormKazakhName = 'Радиобелсендiлiкті зерттеуге үлгілерді іріктеу АКТIСI'
const actFormName = 'АКТ отбора образцов на исследование радиоактивности'
const actFormNumber = '008'
const actName = 'ActRadio'

export default withActDetail(ActRadioForm, ActRadioPDFGenerator, actFormKazakhName, actFormName, actFormNumber, actName)
