import { 
    SERVICE_MAP_GET_REQUEST, SERVICE_MAP_GET_SUCCESS, SERVICE_MAP_GET_ERROR, 
    SERVICE_MAP_GET_BY_KEY_REQUEST, SERVICE_MAP_GET_BY_KEY_SUCCESS, SERVICE_MAP_GET_BY_KEY_ERROR, 
    SERVICE_MAP_ADD_REQUEST, SERVICE_MAP_ADD_SUCCESS, SERVICE_MAP_ADD_ERROR, 
    SERVICE_MAP_EDIT_REQUEST, SERVICE_MAP_EDIT_SUCCESS, SERVICE_MAP_EDIT_ERROR, 
    SERVICE_MAP_DELETE_REQUEST, SERVICE_MAP_DELETE_SUCCESS, SERVICE_MAP_DELETE_ERROR
} from "../constants/serviceMapConstants";

const initialState = {
    serviceMaps: [],
    serviceMap: {},
    loading: false,
    success: null,
    error: null,
    serviceMapRequestedCount: 0,
};

export const serviceMapReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_MAP_GET_REQUEST:
            return {
                ...state,
                serviceMapRequestedCount: ++state.serviceMapRequestedCount,
                loading: true,
            };
        case SERVICE_MAP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceMaps: action.payload,
                success: true,
            };
        case SERVICE_MAP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_MAP_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_MAP_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceMap: action.payload,
                success: true,
            };
        case SERVICE_MAP_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_MAP_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_MAP_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_MAP_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_MAP_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_MAP_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_MAP_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_MAP_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_MAP_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_MAP_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
