import React from "react";
import { Grid, Box, Paper, Typography } from "@mui/material";

export const BasicModalFooter = (props) => {
  const { children } = props;

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        padding: "10px 0px",
        minHeight: "30px",
      }}
      {...props}
    >
      {children}
    </Grid>
  );
};

BasicModalFooter.defaultProps = {};
