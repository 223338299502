import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import {
  formatDate,
  MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../functions/formatters";
import { anObjectReferralCRUD } from "../../../http/CRUD";
import { OBJECT_REFERRAL_ROUTE, OBJECT_REFERRAL_COPY } from "../../../routes";
import { mapReferralObjectProbeType } from "../../../functions/viewDataMap";
import CRUDTable from "../CRUDTable";
import { checkRoles } from '../../../functions/checkRoles';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { confirmDialog } from '../../Dialog/DialogDelete';

import { REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROCESSING } from "../../../redux/constants/requestStateConstants";

import { objectReferralFilterFields, referralSearchFields } from "../../../sections/Filters/ReferralFilters";

import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";
import { commentWithSupervisorDialog } from "../../Dialog/CommentWithSupervisorDialog";

export const ObjectReferralCRUDTable = ({ ...props }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [otherFilter, setOtherFilter] = useState({})

  useEffect(() => {
    if (props?.stateId) {
      setOtherFilter({ stateId: {operator: "equals", operand1: +props?.stateId} })
    }
  }, [props?.stateId])

  const canDelete = checkRoles("SuperAdministrator", "Administrator");
  const canClone = checkRoles('Specialist', 'Supervisor', 'Performer');
  const {id} = useSelector(state=>state.employee.authorizedEmployee);
  const isFilialAdministrator = checkRoles("FilialAdministrator");


  const alert = useAlert(false, 1);
  const { reload, reloadValue } = useReload();

  const itemActions = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        {(row?.state?.code == REQUEST_STATE_ID_COMPLETED) && <Grid item xs={12}><Button id="deleteReferal" color="info" onClick={async () => {
          confirmDialog('Откат на исследование', 'Вы действительно хотите откатить на исследование этот элемент?', async () => {
            try {
            await anObjectReferralCRUD.workflowRun(row?.id, REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_RESEARCH)
            await reload();
          } catch (e) {
            console.log(e);
            alert.show(0, 'Не удалось откатить, ошибка ' + e);
          }
        })
        }}>На исследование</Button></Grid>
        }
        <Grid item xs={12}>
          <Button id="deleteReferal" color="warning" onClick={async () => {
            confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
              try {
                await anObjectReferralCRUD.delete(row?.id)
                await reload();
              } catch (e) {
                console.log(e);
                alert.show(0, 'Не удалось удалить, ошибка ' + e);
              }
          })
          }}>Удалить</Button>
        </Grid>
        { 
        ((row?.state?.code === REQUEST_STATE_ID_CREATED || row?.state?.code === REQUEST_STATE_ID_RESEARCH) ) &&
        <Grid item xs={12}>
        <Button id="copyReferal" color="secondary" onClick={async () => {
          push({
            pathname: OBJECT_REFERRAL_COPY(row.id)
          })
        }}>Клонировать</Button>
        </Grid>
        }
      </Grid>
    }
  }

  const itemActionsForFilialAdministrator = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        {(row?.state?.code == REQUEST_STATE_ID_COMPLETED) && <Grid item xs={12}><Button id="deleteReferal" color="info" onClick={async () => {
            commentWithSupervisorDialog('Откат на исследование', 'Вы действительно хотите откатить на исследование этот элемент?', async (_) => {
              try {
               // console.log(_, supervisorId)
                await anObjectReferralCRUD.workflowRun(row?.id, REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_RESEARCH, _.comment, _.directorId)
                await reload();
              } catch (e) {
                console.log(e);
                alert.show(0, 'Не удалось откатить, ошибка ' + e);
              }
            })
        }}>На исследование</Button></Grid>}
      </Grid>
    }
  }

  const itemActionClone = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        { 
        ((row?.state?.code === REQUEST_STATE_ID_CREATED || row?.state?.code === REQUEST_STATE_ID_RESEARCH || row?.state?.code === REQUEST_STATE_ID_PROCESSING)) &&
        <Grid item xs={12}>
        <Button id="copyReferal" color="secondary" onClick={async () => {
          push({
            pathname: OBJECT_REFERRAL_COPY(row.id)
          })
        }}>Клонировать</Button>
        </Grid>
        }
      </Grid>
    }
  }

  const columns = [
    { title: t("referralList.tableItem.id"), dataIndex: "id", key: "id" },
    {
      title: t("referralList.tableItem.number"),
      dataIndex: "number",
      key: "number",
    },

    {
      title: "Объект",
      dataIndex: "Объект",
      key: "Объект",
      render: (unknown, row) =>
        row.targetObjectId ? (
          row.targetObject.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Филиал",
      dataIndex: "Филиал",
      key: "Филиал",
      render: (unknown, row) =>
        row.filialId ? (
          row.filial.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Лаборатория",
      dataIndex: "Лаборатория",
      key: "Лаборатория",
      render: (unknown, row) =>
        row.departmentId ? (
          row.department.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Создано",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (unknown, row) => formatDate(row.createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS),
    },

    {
      title: "Статус",
      dataIndex: "state",
      key: "state",
      render: (unknown, row) =>
        row.state ? (
          row.state.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Ответственный",
      dataIndex: "responsible",
      key: "responsible",
      render: (a, row) => row?.responsible?.fullName || "-",
    },
    {
      title: "Исполнитель",
      dataIndex: "performer",
      key: "performer",
      render: (a, row) => row?.performer?.fullName || "-",
    },
    {
      title: "Автор",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (unknown, row) => row?.createdBy?.fullName || "-",
    },
    {
      title: "Вид пробы",
      dataIndex: "ВидПробы",
      key: "ВидПробы",
      render: (unknown, row) =>
        row.probeType ? (
          mapReferralObjectProbeType(row.probeType)
        ) : (
          <span style={{ color: "gray" }}>Не задана</span>
        ),
    },
    {...(canDelete && { ...itemActions })},
    { ...(isFilialAdministrator && { ...itemActionsForFilialAdministrator }) },
    {...(canClone && { ...itemActionClone })},
  ];

  const searchFields = [
    ...referralSearchFields
  ]

  const filterFields = [
    ...objectReferralFilterFields
  ];

  return (
    <CRUDTable
      search={anObjectReferralCRUD.search}
      columns={columns}
      onRowClick={({ id }) => push(OBJECT_REFERRAL_ROUTE(id))}
      onClickCreateButton={() => push(OBJECT_REFERRAL_ROUTE("create"))}
      extraFilter={props?.stateId && otherFilter}
      filterFields={filterFields}
      searchFields={searchFields}
      reloadValue={props.reloadValue || reloadValue}
      {...props}
    />
  );
};

