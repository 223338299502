import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {positionCRUD} from "../../../http";
import React from "react";

export default function PositionCRUDTable({...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("positionList.tableItem.id"), dataIndex: "id", key:"id"},
        {title: t("positionList.tableItem.name"), dataIndex: "name", key:"name"},
    ]

    const searchFields =  [{
        title: "Название",
        key: "name",
        compareType: "like",
        operandPosition:1,
        filterType:"normal"
    }]


    return <CRUDTable
        search={positionCRUD.search}
        columns={columns}
        searchFields={searchFields}
        collapsibleTable={false}
        {...props}
    />
}