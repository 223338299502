export const CLIENT_GET_REQUEST = 'CLIENT_GET_REQUEST';
export const CLIENT_GET_SUCCESS = 'CLIENT_GET_SUCCESS';
export const CLIENT_GET_ERROR = 'CLIENT_GET_ERROR';
export const CLIENT_GET_BY_KEY_REQUEST = 'CLIENT_GET_BY_KEY_REQUEST';
export const CLIENT_GET_BY_KEY_SUCCESS = 'CLIENT_GET_BY_KEY_SUCCESS';
export const CLIENT_GET_BY_KEY_ERROR = 'CLIENT_GET_BY_KEY_ERROR';
export const CLIENT_ADD_REQUEST = 'CLIENT_ADD_REQUEST';
export const CLIENT_ADD_SUCCESS = 'CLIENT_ADD_SUCCESS';
export const CLIENT_ADD_ERROR = 'CLIENT_ADD_ERROR';
export const CLIENT_EDIT_REQUEST = 'CLIENT_EDIT_REQUEST';
export const CLIENT_EDIT_SUCCESS = 'CLIENT_EDIT_SUCCESS';
export const CLIENT_EDIT_ERROR = 'CLIENT_EDIT_ERROR';
export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_ERROR = 'CLIENT_DELETE_ERROR';
