import { 
    GEO_OBJECT_TYPE_GET_REQUEST, GEO_OBJECT_TYPE_GET_SUCCESS, GEO_OBJECT_TYPE_GET_ERROR, 
    GEO_OBJECT_TYPE_GET_BY_KEY_REQUEST, GEO_OBJECT_TYPE_GET_BY_KEY_SUCCESS, GEO_OBJECT_TYPE_GET_BY_KEY_ERROR, 
    GEO_OBJECT_TYPE_ADD_REQUEST, GEO_OBJECT_TYPE_ADD_SUCCESS, GEO_OBJECT_TYPE_ADD_ERROR, 
    GEO_OBJECT_TYPE_EDIT_REQUEST, GEO_OBJECT_TYPE_EDIT_SUCCESS, GEO_OBJECT_TYPE_EDIT_ERROR, 
    GEO_OBJECT_TYPE_DELETE_REQUEST, GEO_OBJECT_TYPE_DELETE_SUCCESS, GEO_OBJECT_TYPE_DELETE_ERROR
} from "../constants/geoObjectTypeConstants";

const initialState = {
    geoObjectTypes: [],
    geoObjectType: {},
    loading: false,
    success: null,
    error: null,
    geoObjectTypeRequestedCount: 0,
};

export const geoObjectTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case GEO_OBJECT_TYPE_GET_REQUEST:
            return {
                ...state,
                geoObjectTypeRequestedCount: ++state.geoObjectTypeRequestedCount,
                loading: true,
            };
        case GEO_OBJECT_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                geoObjectTypes: action.payload,
                success: true,
            };
        case GEO_OBJECT_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_TYPE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_TYPE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                geoObjectType: action.payload,
                success: true,
            };
        case GEO_OBJECT_TYPE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_TYPE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_TYPE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GEO_OBJECT_TYPE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_TYPE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GEO_OBJECT_TYPE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GEO_OBJECT_TYPE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GEO_OBJECT_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GEO_OBJECT_TYPE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
