import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid, TextField } from "@mui/material";
import useDialogs from "../../components/Dialog/useDialogs";
import useLoader from "../../hooks/useLoader";
import { renderControls } from "../../functions";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import { formatDateAsDate, formatDateAsDateTime } from "../../functions/formatters";
import { getOrganizationFilialMapByKey } from "../../redux/actions/organizationFilialMapActions";
import { ORGANIZATION_FILIAL_MAP_LIST_ROUTE } from "../../routes";
import { FilialSelect } from "../../components/Select/domainSelects";
import { GridItem } from "../../components/Fields/GridItem";

export const OrganizationFilialMapDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const [data, setData] = useState({});

    const {alert, DIALOG} = useDialogs();

    const fetch = async () => {
        fetchStart();

        const item = await dispatch(getOrganizationFilialMapByKey(id));
        if (!item) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setData(item);
        fetchStop();
    };

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, fetchStart, fetchStop]);

    const controls = [
    {
        state: "current",
        buttons: [
            <Button
                variant={"text"}
                onClick={() => push(ORGANIZATION_FILIAL_MAP_LIST_ROUTE)}
            >
                Вернуться
            </Button>,
        ],
    }
    ];

    return (
        <DashboardLayout>
            <DIALOG/>
            <PageHeader heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
            </PageHeader>
            <PageContainer>
                <FormSection>
                    <Grid container>
                        <GridItem xs={6}>
                            <FilialSelect
                                label={"Филиал отобразить c"}
                                disabled={true}
                                fullWidth
                                value={data.sourceFilialId || ""}
                                onChange={(value, obj) => {
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6}>
                            <FilialSelect
                                label={"Филиал отобразить на"}
                                disabled={true}
                                fullWidth
                                value={data.targetFilialId || ""}
                                onChange={(value, obj) => {
                                }}
                            />
                        </GridItem>
                    </Grid>
                </FormSection>
            </PageContainer>
            <PageFooter heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
            </PageFooter>
        </DashboardLayout>
    )
};
