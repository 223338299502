import { educationServiceCRUD } from "../../http/CRUD";
import {
  EDUCATION_SERVICE_GET_REQUEST,
  EDUCATION_SERVICE_GET_SUCCESS,
  EDUCATION_SERVICE_GET_ERROR,
  EDUCATION_SERVICE_GET_BY_KEY_REQUEST,
  EDUCATION_SERVICE_GET_BY_KEY_SUCCESS,
  EDUCATION_SERVICE_GET_BY_KEY_ERROR,
  EDUCATION_SERVICE_ADD_REQUEST,
  EDUCATION_SERVICE_ADD_SUCCESS,
  EDUCATION_SERVICE_ADD_ERROR,
  EDUCATION_SERVICE_EDIT_REQUEST,
  EDUCATION_SERVICE_EDIT_SUCCESS,
  EDUCATION_SERVICE_EDIT_ERROR,
  EDUCATION_SERVICE_DELETE_REQUEST,
  EDUCATION_SERVICE_DELETE_SUCCESS,
  EDUCATION_SERVICE_DELETE_ERROR,
} from "../constants/educationServiceConstants";

export const getEducationServices = (args) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_GET_REQUEST,
    });

    const data = await educationServiceCRUD.search(args);

    dispatch({
      type: EDUCATION_SERVICE_GET_SUCCESS,
      payload: data.result,
    });

    return data.result;
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getEducationServiceByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_GET_BY_KEY_REQUEST,
    });

    const data = await educationServiceCRUD.get(id);
    dispatch({
      type: EDUCATION_SERVICE_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_GET_BY_KEY_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const addEducationService = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_ADD_REQUEST,
    });

    await educationServiceCRUD.create(data);
    dispatch(getEducationServices(args || {}));
    dispatch({
      type: EDUCATION_SERVICE_ADD_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_ADD_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const editEducationService = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_EDIT_REQUEST,
    });

    await educationServiceCRUD.edit(data);
    dispatch(getEducationServices(args || {}));
    dispatch({
      type: EDUCATION_SERVICE_EDIT_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_EDIT_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const deleteEducationService = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_DELETE_REQUEST,
    });

    await educationServiceCRUD.delete(id);
    dispatch(getEducationServices(args || {}));
    dispatch({
      type: EDUCATION_SERVICE_DELETE_SUCCESS,
    });
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_DELETE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};
