import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useDialogs from "../../components/Dialog/useDialogs";
import useLoader from "../../hooks/useLoader";
import { renderControls } from "../../functions";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import CheckboxField from "../../components/Fields/CheckboxField";
import { TextField2 } from "../../components/Fields/TextField2";
import { TextPhoneField } from "../../components/Fields/TextPhoneField";
import { DateField } from "../../components/Fields/DateField";
import { DateTimeField } from "../../components/Fields/DateTimeField";
import { NumberField } from "../../components/Fields/NumberField";
import { GridItem } from "../../components/Fields/GridItem";
import { ResearchObjectSelect } from "../../components/Select/domainSelects";
import { ServiceGroupSelect } from "../../components/Select/domainSelects";
import { ServiceSubGroupSelect } from "../../components/Select/domainSelects";
import { ServiceKindSelect } from "../../components/Select/domainSelects";
import { GovernmentStandardSelect } from "../../components/Select/domainSelects";
import { TechnicalRegulationsOfCustomsUnionSelect } from "../../components/Select/domainSelects";
import { changeData, changeDataMany } from "../../functions/changeHandlers";
import { getServiceMapByKey } from "../../redux/actions/serviceMapActions";
import { editServiceMap } from "../../redux/actions/serviceMapActions";
import { SERVICE_MAP_LIST_ROUTE } from "../../routes";
import { submitData2 } from "../../functions/submitHandlers";
import RestSelect from "../../components/Select/RestSelect";
import { governmentCRUD, researchObjectCRUD, serviceCRUD, serviceGroupCRUD, serviceKindCRUD, serviceSubGroupCRUD, technicalRegulationsOfCustomsUnionCRUD } from "../../http";
import useResourceLinq from "../../data/useResourceLinq";
import { equals, page, where } from "../../functions/resourceLinq1";
import Label from "../../components/Fields/label";

export const EditServiceMap = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const [data, setData] = useState({});
    const [serviceGroupQuery, setServiceGroupQuery] = useState({
        paging: { skip: 0 },
        filter: {
        },
        sort: {
            name: {
                operator: 'asc'
            }
        }
    });
    const [serviceSubGroupQuery, setServiceSubGroupQuery] = useState({
        paging: { skip: 0 },
        filter: {
            // groupId: {
            //     operand1: 0,
            //     operator: 'equals'
            // }
        },
        sort: {
            name: {
                operator: 'asc'
            }
        }
    });
    const [services, setServices] = useState([]);

    const {
        loading: submitLoading,
        start: startSubmit,
        stop: stopSubmit,
    } = useLoader(false);

    const handleChange = useCallback((key, value) => changeData(data, setData, key, value), [data]);
    const handleChangeMany = useCallback((obj) => changeDataMany(data, setData, obj), [data]);

    const serviceResource = useResourceLinq(serviceCRUD);

    const fetchServices = async (groupId, subGroupId) => {        
        const response = await serviceResource.toList(where(page(null, 0, null), {
            groupId: equals(groupId),
            subGroupId: equals(subGroupId)
        }));

        setServices(response);
    }

    useEffect(() => {
        if (!data.groupId || !data.subGroupId) {
            setServices([]);
            return;
        }

        fetchServices(data.groupId, data.subGroupId);
    }, [data.groupId, data.subGroupId]);

    const {alert, DIALOG} = useDialogs();

    const fetch = async () => {
        fetchStart();

        const item = await dispatch(getServiceMapByKey(id));
        if (!item) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setData(item);
        fetchStop();
    };

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, fetchStart, fetchStop]);

    const handleSubmit = async (e) => {
        e?.preventDefault();

        await submitData2(data, async () =>
        {
            return true;
        }, startSubmit, stopSubmit, async () =>
        {
            return await dispatch(editServiceMap(data));
        });
    };

    const controls = [
    {
        state: null,
        buttons: [
            <LoadingButton loading={submitLoading} onClick={handleSubmit}>
                Сохранить
            </LoadingButton>,
            <Button
                variant={"text"}
                onClick={() => push(SERVICE_MAP_LIST_ROUTE)}
            >
                Вернуться
            </Button>,
        ],
    }
    ];

    return (
        <DashboardLayout>
            <>
                <DIALOG/>
                <PageHeader heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
                </PageHeader>
                <PageContainer>
                    <FormSection>
                        <Grid container>
                        <GridItem xs={6}>
                                <RestSelect
                                    restApi={researchObjectCRUD}
                                    id={"object"}
                                    label={"Объект исследования"}
                                    value={data.objectId}
                                    onChange={(value, obj) => handleChangeMany({ researchObject: obj, objectId: value })}
                                    fullWidth
                                    isNullable={true}
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={serviceGroupCRUD}
                                    query={serviceGroupQuery}
                                    id={"group"}
                                    label={"Группа"}
                                    value={data.groupId}
                                    onChange={(value, obj) => handleChangeMany({ serviceGroup: obj, groupId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={serviceSubGroupCRUD}
                                    query={serviceSubGroupQuery}
                                    id={"subGroup"}
                                    label={"Вид исследования"}
                                    value={data.subGroupId}
                                    onChange={(value, obj) => handleChangeMany({ serviceSubGroup: obj, subGroupId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={serviceKindCRUD}
                                    id={"kind"}
                                    label={"Метод"}
                                    value={data.kindId}
                                    onChange={(value, obj) => handleChangeMany({ serviceKind: obj, kindId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={governmentCRUD}
                                    id={"researchStandard"}
                                    label={"НД на метод исследования"}
                                    value={data.researchStandardId}
                                    onChange={(value, obj) => handleChangeMany({ governmentStandard: obj, researchStandardId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={governmentCRUD}
                                    id={"governmentStandard"}
                                    label={"НД на метод отбора"}
                                    value={data.governmentStandardId}
                                    onChange={(value, obj) => handleChangeMany({ governmentStandard: obj, governmentStandardId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={technicalRegulationsOfCustomsUnionCRUD}
                                    id={"regulationOfCU"}
                                    label={"Документ санитарно-эпидемиологического нормирования (на соответствие, которого проводится исследование)"}
                                    value={data.regulationOfCUId}
                                    onChange={(value, obj) => handleChangeMany({ regulationOfCU: obj, regulationOfCUId: value })}
                                    fullWidth
                                />
                            </GridItem>
                        </Grid>
                        <Grid container>
                            <Label>Исследования</Label>
                            <table className="table">
                                <thead>
                                    <th>ID</th>
                                    <th>Наименование</th>
                                </thead>
                                <tbody>
                                    {services.map(_ => <tr>
                                        <td>{_.id}</td>
                                        <td>{_.name}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                        </Grid>
                    </FormSection>
                </PageContainer>
                <PageFooter heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
                </PageFooter>
            </>
        </DashboardLayout>
    )
};
