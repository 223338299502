import {
  EDUCATION_SERVICE_GET_REQUEST,
  EDUCATION_SERVICE_GET_SUCCESS,
  EDUCATION_SERVICE_GET_ERROR,
  EDUCATION_SERVICE_GET_BY_KEY_REQUEST,
  EDUCATION_SERVICE_GET_BY_KEY_SUCCESS,
  EDUCATION_SERVICE_GET_BY_KEY_ERROR,
  EDUCATION_SERVICE_ADD_REQUEST,
  EDUCATION_SERVICE_ADD_SUCCESS,
  EDUCATION_SERVICE_ADD_ERROR,
  EDUCATION_SERVICE_EDIT_REQUEST,
  EDUCATION_SERVICE_EDIT_SUCCESS,
  EDUCATION_SERVICE_EDIT_ERROR,
  EDUCATION_SERVICE_DELETE_REQUEST,
  EDUCATION_SERVICE_DELETE_SUCCESS,
  EDUCATION_SERVICE_DELETE_ERROR,
} from "../constants/educationServiceConstants";

const initialState = {
  educationServices: [],
  educationService: {},
  loading: false,
  success: null,
  error: null,
  educationServiceRequestedCount: 0,
};

export const educationServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDUCATION_SERVICE_GET_REQUEST:
      return {
        ...state,
        loading: true,
        educationServiceRequestedCount: ++state.educationServiceRequestedCount,
      };
    case EDUCATION_SERVICE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        educationServices: action.payload,
        success: true,
      };
    case EDUCATION_SERVICE_GET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case EDUCATION_SERVICE_GET_BY_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDUCATION_SERVICE_GET_BY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        educationService: action.payload,
        success: true,
      };
    case EDUCATION_SERVICE_GET_BY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case EDUCATION_SERVICE_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDUCATION_SERVICE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case EDUCATION_SERVICE_ADD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case EDUCATION_SERVICE_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDUCATION_SERVICE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case EDUCATION_SERVICE_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case EDUCATION_SERVICE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EDUCATION_SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case EDUCATION_SERVICE_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
