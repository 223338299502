import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import {
  BasicModal,
  BasicModalHeader,
  BasicModalContainer,
  Form,
  FormSection,
  SelectField,
} from "../../../components";
import { addBrigade } from "../../../redux/actions/brigadeActions";
import { addDesinfectionReference } from "../../../redux/actions/desinfectionReferenceActions";

import {
  getActForms,
  getDesinsectionRequestByKey,
  getAnObjectReferralByKey,
} from "./../../../redux/actions";

import { DesinfectionReferenceCreateSection } from "./../_sections";
import DialogLayout from "../../../components/Dialog/DialogLayout";

export const DesinfectionReferenceCreateModal = ({
  open,
  setOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { desinsectionRequest } = useSelector((s) => s.desinsectionRequest);
  const { anObjectReferral } = useSelector((s) => s.anObjectReferral);

  const handleReset = () => {};

  const handleSubmit = async (e, data) => {
    e.preventDefault();

    setLoading(true);

    const desinsectionRequestId = desinsectionRequest?.id
      ? desinsectionRequest.id
      : null;

    const anObjectReferralId = anObjectReferral?.id
      ? anObjectReferral?.id
      : null;

    const refreshAction = desinsectionRequestId
      ? getDesinsectionRequestByKey(desinsectionRequestId)
      : getAnObjectReferralByKey(anObjectReferralId);

    const _data = {
      ...data,
      desinsectionRequestId,
      anObjectReferralId,
    };

    console.log({ _data });

    // Creating
    const desinfectionReferenceId = await dispatch(
      addDesinfectionReference(_data)
    );
    if (!desinfectionReferenceId) return setLoading(false);

    // Updating Request
    await dispatch(refreshAction);

    console.log({ refreshAction, desinfectionReferenceId });

    setLoading(false);
    setOpen(false);
    handleReset();
  };

  return (
      <DialogLayout setOpen={setOpen} open={open} loading={loading} title="Справка: Создание">
        <FormSection mb={4}>
          <DesinfectionReferenceCreateSection onSubmit={handleSubmit} />
        </FormSection>
      </DialogLayout>
  );
};

DesinfectionReferenceCreateModal.defaultProps = {};
