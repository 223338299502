import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {CircularProgress, Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProtocolForms} from "../../../redux/actions/protocolFormActions";
import {getReferrals} from "../../../redux/actions/referralActions";
import {getResults} from "../../../redux/actions/resultActions";
import {getActs} from "../../../redux/actions/actActions";
import {getReferralByKey} from "../../../redux/actions/referralActions";
import {getAnObjectReferrals} from '../../../redux/actions/anObjectReferralActions'
import {getFormMaps} from '../../../redux/actions/formMapActions'
import ProtocolForms from '../ProtocolForms/';
import RequestField from '../../../components/Relations/RequestField'
import {
    REQUEST_TYPE_ID_ReferralRequest,
    REQUEST_TYPE_ID_AnObjectReferralRequest,
    REQUEST_TYPE_ID_Act,
    REQUEST_TYPE_ID_ServiceWithoutActRequest
} from '../../../redux/constants/requestTypeConstants'

import {distinct} from '../../../models/data/EntityCollectionModel';

import {mapProtocolSigners} from "../../../functions/viewDataMap";
import {getServiceWithoutActRequests} from "../../../redux/actions";

const style = {
    position: 'absolute',
    top: '10%',
    bottom: '20%',
    left: '20%',
    right: '20%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    height: '85%',
    p: 4,
};

export default function CreateProtocol({action, act, referral, title, open, setOpen, onSubmit, ...props}) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [number, setNumber] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date());
    const [formId, setFormId] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const [resultId, setResultId] = useState(null);
    const [filialId, setFilialId] = useState(null);
    const [filialDivisionId, setFilialDivisionId] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [divisionId, setDivisionId] = useState(null);
    const [responsibleId, setResponsibleId] = useState(null);
    const [performerId, setPerformerId] = useState(null);
    const [protocolData, setProtocolData] = useState(null);
    const [protocolFormID, setFormID] = useState(null);
    const [initNumberInput, setInitNumberInput] = useState('');
    const [initNumber, setInitNumber] = useState(Number(props.initNumber));
    const {loading, protocols, protocol} = useSelector((state) => state.protocol);
    const [allowedProtocols, setAllowedProtocols] = React.useState([]);
    const [currentAction, setCurrentAction] = useState(action);
    const [selectedResultId, setSelectedResultId] = useState(null)
    const [version, setVersion] = useState('2');

    const [forms, setForms] = useState(null);

    const emptyState = () => {
        setId(null);
        setNumber('');
        setCreatedAt(new Date());
        setFormId(null);
        setReferralId(null);
        setResultId(null);
        setFilialId(null);
        setFilialDivisionId(null);
        setDepartmentId(null);
        setDivisionId(null);
        setResponsibleId(null);
        setPerformerId(null);
        setInitNumber(null)
        setInitNumberInput('');
        setVersion(2);
    };

    const handleForm = async (data, protocolTypeNumber) => {

        const id = initNumber || number || Date.now()
        const protocolForms = await dispatch(getProtocolForms({
            paging: {take: 1},
            filter: {number: {operand1: protocolTypeNumber, operator: "equals"}}
        }))
        const getReferralsFunc = {
            'create': getReferrals,
            'createByReferral': getReferrals,
            'createByObjectReferral': getAnObjectReferrals,
            'createByAct': getActs,
            'createByServiceWithoutActRequest': getServiceWithoutActRequests
        }[currentAction]

        if (protocolForms.length === 0)
            throw alert('Не найдена форма протокола в базе данных! Добавьте форму протокола в соответствующей вкладке с номером ' + protocolTypeNumber);
        const formId = protocolForms[0].id;
        const referral = await dispatch(getReferralsFunc({
            paging: {take: 1},
            filter: {number: {operand1: initNumber, operator: "equals"}}
        }))
        const results = await dispatch(getResults({
            paging: {skip: 0},
            filter: {number: {operand1: initNumber, operator: "equals"}}
        }))

        let resultId = results?.[0]?.id;

        if (results.length > 1) {
            if (selectedResultId)
                resultId = selectedResultId;
            else {
                if (forms == null)
                    throw alert('Ошибка загрузки информации сопоставления по формам для протокола ' + protocolTypeNumber);
                // try to detect by protocol form
                const detectByFormId = forms.formMaps.filter(_ => _.protocolFormId == formId);

                //if (detectByFormId.length > 1)
                //  alertMultipleResultsByProtocolFound(protocolTypeNumber);

                const resultsByResultFormId = results.filter(_ => detectByFormId.find(i => i.resultFormId == _.formId));

                // if (resultsByResultFormId.length > 1)
                //   alertMultipleResultsByProtocolFound(protocolTypeNumber);

                if (resultsByResultFormId.length == 0) {
                    throw alert('Не найден журнал для протокола ' + protocolTypeNumber);
                }

                if (resultsByResultFormId.length > 1) {
                    resultId = selectedResultId;
                } else {
                    resultId = resultsByResultFormId?.[0]?.id;
                }
            }
        }

        const protocolSigners = mapProtocolSigners(data);

        setInitNumber(null)
        const responseResult = onSubmit({
            data, // collected from component
            id,
            number: String(id),
            createdAt: new Date(),
            formId: Number(formId),
            ...(currentAction === 'create' && {referralId: referral?.[0]?.id ?? referralId ?? 1}),
            ...(currentAction === 'createByReferral' && {referralId: referral?.[0]?.id ?? referralId}),
            ...(currentAction === 'createByObjectReferral' && {objectReferralId: referral?.[0]?.id ?? referralId}),
            ...(currentAction === 'createByAct' && {actId: referral?.[0]?.id ?? referralId}),
            ...(currentAction === 'createByServiceWithoutActRequest' && {serviceWithoutActRequestId: referral?.[0]?.id ?? referralId}),
            resultId,
            filialId,
            filialDivisionId,
            departmentId,
            divisionId,
            responsibleId: protocolSigners.responsible?.id,
            performerId: protocolSigners.performer?.id,
            leaderId: protocolSigners.leader?.id,
            signers: protocolSigners.signers
        });
        if (typeof responseResult === 'object' && responseResult.then) {
            responseResult
                .then(() => setOpen(false))
                .catch(alert)
        } else {
            setOpen(false);
        }

        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id,
            number,
            createdAt,
            formId,
            referralId,
            resultId,
            filialId,
            filialDivisionId,
            departmentId,
            divisionId,
            responsibleId,
            performerId,
            version
        } = protocol || {};
        setId(id);
        setNumber(number);
        setCreatedAt(createdAt);
        setFormId(Number(formId));
        setReferralId(referralId);
        setResultId(resultId);
        filialId && setFilialId(filialId);
        filialDivisionId && setFilialDivisionId(filialDivisionId);
        departmentId && setDepartmentId(departmentId);
        divisionId && setDivisionId(divisionId);
        responsibleId && setResponsibleId(responsibleId);
        performerId && setPerformerId(performerId);
        version && setVersion(version);
    }, [protocol, protocols])

    useEffect(async () => {
        if (!open || !currentAction || !initNumber)
            return;

        switch (currentAction) {
            case 'createByAct':
                await dispatch(getActs({
                    paging: {take: 1},
                    filter: {number: {operand1: initNumber, operator: "equals"}}
                })).then(acts => {
                    const {departmentId, filialId, performerId, responsibleId, filialDivisionId, divisionId} = acts[0]
                    filialId && setFilialId(filialId)
                    filialDivisionId && setFilialDivisionId(filialDivisionId)
                    departmentId && setDepartmentId(departmentId)
                    divisionId && setDivisionId(divisionId)
                    responsibleId && setResponsibleId(responsibleId)
                    performerId && setPerformerId(performerId)
                })
                break;
            default:
                if (!props.request) return;

                const {departmentId, filialId, performerId, responsibleId, filialDivisionId, divisionId} = props.request

                setFilialId(filialId);
                setFilialDivisionId(filialDivisionId);
                await setDepartmentId(departmentId);
                setDivisionId(divisionId);

                setPerformerId(performerId);
                setResponsibleId(responsibleId);
                break;
        }

        setProtocolData(null);

        await fetchForms();
    }, [initNumber, currentAction, open]);

    const fetchForms = async () => {
        let query = {paging: {skip: 0}, filter: {number: {operand1: initNumber, operator: "equals"}}};
        const acts = await dispatch(getActs(query));
        const results = await dispatch(getResults(query));

        const parseData = dataObject => Object.fromEntries(Object.entries(JSON.parse(dataObject)).map(([_, {
            key,
            ...value
        }]) => ([key, value])))
        let data = {};
        if (acts?.[0]?.data) {
            data = {...data, act: parseData(acts?.[0]?.data)};
        }
        if (results?.[0]?.data) {
            data = {...data, journal: parseData(results?.[0]?.data)};
        }

        setProtocolData(data);

        const filterDepartmentId = props.request.departmentId || JSON.parse(window.localStorage.employee).departmentId;
        query = {paging: {take: 1000}, filter: {departmentId: {operand1: filterDepartmentId, operator: "equals"}}}
        let formMaps = await dispatch(getFormMaps(query));

        const resultFormIds = results.map(_ => _.formId);
        if (resultFormIds.length > 0)
            formMaps = formMaps.filter(_ => resultFormIds.includes(_.resultFormId));
        let protocolNumbers = formMaps.filter(_ => _.protocolFormId).map(({protocolForm: {number}}) => number);
        protocolNumbers = distinct(protocolNumbers, (a, b) => a == b);
        setAllowedProtocols(protocolNumbers);

        setForms({
            acts,
            results,
            formMaps,
            protocolNumbers
        });
    }

    const collectRequestData = async () => {
        const requestID = Number(initNumberInput.number)

        switch (initNumberInput.typeId) {
            case REQUEST_TYPE_ID_ReferralRequest:
                setCurrentAction('createByReferral');
                break;
            case REQUEST_TYPE_ID_AnObjectReferralRequest:
                setCurrentAction('createByObjectReferral');
                break;
            case REQUEST_TYPE_ID_Act:
                setCurrentAction('createByAct');
                break;
            case REQUEST_TYPE_ID_ServiceWithoutActRequest:
                setCurrentAction('createByServiceWithoutActRequest');
                break;
        }

        setInitNumber(requestID)
        setReferralId(initNumberInput.referralId)
        const {filialId, filialDivisionId, departmentId, divisionId, responsibleId, performerId} =
            await dispatch(getReferralByKey(Number(initNumberInput.referralId)))
        filialId && setFilialId(filialId)
        filialDivisionId && setFilialDivisionId(filialDivisionId)
        departmentId && setDepartmentId(departmentId)
        divisionId && setDivisionId(divisionId)
        responsibleId && setResponsibleId(responsibleId)
        performerId && setPerformerId(performerId)
    }


    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Grid container spacing={2} direction='column'>
                        {initNumber ? (
                            !protocolData
                                ? <Grid item><CircularProgress/></Grid>
                                : <>
                                    <Grid item>
                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                            {title}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{maxWidth: '100%'}}>
                                        {protocolData
                                            ? <ProtocolForms
                                                referral={referral}
                                                protocolData={protocolData}
                                                onSubmit={handleForm}
                                                selectedResultId={selectedResultId}
                                                setSelectedResultId={setSelectedResultId}
                                                allowedProtocols={allowedProtocols}
                                                result={forms?.results}
                                                formMaps={forms?.formMaps}
                                                version={version}
                                                act={act}
                                            />
                                            : <CircularProgress/>
                                        }
                                    </Grid>
                                </>
                        ) : <>
                            <Grid item><Typography variant='h6' component='h2'>Создать протокол</Typography></Grid>
                            <Grid item>
                                <RequestField
                                    label='Заявка'
                                    fullWidth
                                    value={initNumberInput}
                                    object={initNumberInput}
                                    onChange={setInitNumberInput}
                                />
                            </Grid>
                            {initNumberInput && <Grid item>
                                <Button variant='contained' onClick={collectRequestData} fullWidth>
                                    Далее
                                </Button>
                            </Grid>}
                        </>
                        }
                    </Grid>
                </Box>
            </Modal>
        </div>
    )
}
