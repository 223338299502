export const RESULT_GET_REQUEST = 'RESULT_GET_REQUEST';
export const RESULT_GET_SUCCESS = 'RESULT_GET_SUCCESS';
export const RESULT_GET_ERROR = 'RESULT_GET_ERROR';
export const RESULT_GET_BY_KEY_REQUEST = 'RESULT_GET_BY_KEY_REQUEST';
export const RESULT_GET_BY_KEY_SUCCESS = 'RESULT_GET_BY_KEY_SUCCESS';
export const RESULT_GET_BY_KEY_ERROR = 'RESULT_GET_BY_KEY_ERROR';
export const RESULT_ADD_REQUEST = 'RESULT_ADD_REQUEST';
export const RESULT_ADD_SUCCESS = 'RESULT_ADD_SUCCESS';
export const RESULT_ADD_ERROR = 'RESULT_ADD_ERROR';
export const RESULT_EDIT_REQUEST = 'RESULT_EDIT_REQUEST';
export const RESULT_EDIT_SUCCESS = 'RESULT_EDIT_SUCCESS';
export const RESULT_EDIT_ERROR = 'RESULT_EDIT_ERROR';
export const RESULT_DELETE_REQUEST = 'RESULT_DELETE_REQUEST';
export const RESULT_DELETE_SUCCESS = 'RESULT_DELETE_SUCCESS';
export const RESULT_DELETE_ERROR = 'RESULT_DELETE_ERROR';

export const RESULT_TYPE_GET_REQUEST = 'RESULT_TYPE_GET_REQUEST';
export const RESULT_TYPE_GET_SUCCESS = 'RESULT_TYPE_GET_SUCCESS';
export const RESULT_TYPE_GET_ERROR = 'RESULT_TYPE_GET_ERROR';