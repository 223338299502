export const AN_OBJECT_GET_REQUEST = 'AN_OBJECT_GET_REQUEST';
export const AN_OBJECT_GET_SUCCESS = 'AN_OBJECT_GET_SUCCESS';
export const AN_OBJECT_GET_ERROR = 'AN_OBJECT_GET_ERROR';
export const AN_OBJECT_GET_BY_KEY_REQUEST = 'AN_OBJECT_GET_BY_KEY_REQUEST';
export const AN_OBJECT_GET_BY_KEY_SUCCESS = 'AN_OBJECT_GET_BY_KEY_SUCCESS';
export const AN_OBJECT_GET_BY_KEY_ERROR = 'AN_OBJECT_GET_BY_KEY_ERROR';
export const AN_OBJECT_ADD_REQUEST = 'AN_OBJECT_ADD_REQUEST';
export const AN_OBJECT_ADD_SUCCESS = 'AN_OBJECT_ADD_SUCCESS';
export const AN_OBJECT_ADD_ERROR = 'AN_OBJECT_ADD_ERROR';
export const AN_OBJECT_EDIT_REQUEST = 'AN_OBJECT_EDIT_REQUEST';
export const AN_OBJECT_EDIT_SUCCESS = 'AN_OBJECT_EDIT_SUCCESS';
export const AN_OBJECT_EDIT_ERROR = 'AN_OBJECT_EDIT_ERROR';
export const AN_OBJECT_DELETE_REQUEST = 'AN_OBJECT_DELETE_REQUEST';
export const AN_OBJECT_DELETE_SUCCESS = 'AN_OBJECT_DELETE_SUCCESS';
export const AN_OBJECT_DELETE_ERROR = 'AN_OBJECT_DELETE_ERROR';
