export const normalizeHtml = (html) => {
    html = html.replaceAll(`<font face="Times New Roman" size="3"`, `<font face="Times New Roman" size="1"`)
    while (html.indexOf('  ') > 0)
        html = html.replaceAll('  ', ' ');
    html = html.replaceAll('; ', ';');
    html = html.replaceAll(': ', ':');

    html = html.replaceAll(`<font face="Times New Roman" size="3"`, `<font face="Times New Roman" size="1"` ); 
    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;`, `` )
    // //font-family: &quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;      
    //html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;`, `` )
    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif`, ``);
    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;mso-bidi-font-family:Mangal;`, ``)
    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;`, ``)
    //html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;`, ``);
    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,&quot;serif&quot;`, ``)  
    html = html.replaceAll(';mso-fareast-font-family:TimesNewRomanPSMT', '');
    html = html.replaceAll(' class="MsoTableGrid"', '');
    html = html.replaceAll(' class="MsoNormal"', '');
    html = html.replaceAll('class="MsoNormalTable"', '');                        
    html = html.replaceAll(';mso-ansi-language:KZ', '');
    html = html.replaceAll(' class="MsoNormalCxSpMiddle"', '');
    html = html.replaceAll(';mso-bidi-theme-font:minor-bidi', '');
    html = html.replaceAll(' class="MsoNoSpacing"', '');
    html = html.replaceAll('<p>​</p>', '');
    html = html.replaceAll('mso-fareast-font-family:Calibri', 'mso-fareast-font-family:Times New Roman')
    //font-family:Symbol;
    // html = html.replaceAll('font-family:&quot;Calibri&quot', 'font-family:&quot;Times New Roman&quot')
    // html = html.replaceAll('font-family:&quot;Helvetica Neue&quot', 'font-family:&quot;Times New Roman&quot')
    html = html.replaceAll(/font-family:&quot;[\w,\s]*&quot/g, 'font-family:&quot;Times New Roman&quot')
    html = html.replaceAll(/font-family:[\w,\s]*;/g, 'font-family:Times New Roman;')
    html = html.replaceAll(/font:[\w,\s,.]*pt &quot;[\w,\s]*&quot/g, '')
    html = html.replaceAll(';font-size:inherit', '');
    html = html.replaceAll('text-decoration:none', '');

    //

    // /font:[\w,\s,.]*pt &quot;[\w,\s]*&quot/g
    //&quot;Times New Roman&quot
    //font:7.0pt &quot;Times New Roman&quot

    return html;
}