import { $authHost } from "./";

export const defaultConfig = {
  headers: { "Content-Type": "application/json" },
};

export function CRUD(baseUrl) {
  const API = "/api/v1";

  return {
    search: function (params = {}) {
      return parseResponse(
        $authHost.post(`${API}/${baseUrl}/query`, params, defaultConfig)
      );
    },
    firstOrDefault: async function (params = {}) {
      const response = await this.search(params);
      console.log('response: ' + JSON.stringify(response));
      return response.result.length > 0 ? response.result[0] : null;
    },
    toList: async function (params = {}) {
      const response = await this.search(params);
      return response.result;
    },
    get: function (id) {      
      const uri = baseUrl ? `${API}/${baseUrl}/${id}` : `${API}/${id}`;
      return parseResponse($authHost.get(uri));
    },
    create: function (data) {
      return parseResponse(
         $authHost.post(`${API}/${baseUrl}`, data, defaultConfig)
      );
    },
    edit: function (data) {
      return parseResponse(
        $authHost.put(`${API}/${baseUrl}`, data, defaultConfig)
      );
    },
    delete: function (id) {
      return parseResponse(
        $authHost.delete(`${API}/${baseUrl}`, { data: { id } }, defaultConfig)
      );
    },
    workflowRun: function (id, oldStateId, newStateId, comment, directorId) {
      return parseResponse(
        $authHost.post(
          `${API}/${baseUrl}/workflow/run`,
          { id: parseInt(id), oldStateId, newStateId, comment, directorId },
          defaultConfig
        )
      );
    },
    workflowSignRun: function (id, comment) {
      return parseResponse(
        $authHost.post(
          `${API}/${baseUrl}/workflow/sign/run`,
          { id: parseInt(id), comment },
          defaultConfig
        )
      );
    },
    workflowSignRunProtocol: function (request) {
      return parseResponse(
          $authHost.post(
              `${API}/${baseUrl}/workflow/sign/run`,
              request,
              defaultConfig
          )
      );
    },
    post: function (url, params = {}) {
      const uri = baseUrl ? `${API}/${baseUrl}/${url}` : `${API}/${url}`;

      return parseResponse(        
        $authHost.post(uri, params, defaultConfig)
      );
    },
  };
}

export function parseResponse(promise) {
  return new Promise((resolve, reject) => {
    promise
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) =>{
        reject(parseError(error))
      } );
  });
}

export function parseResponse2(response) {
  return response.data;
}

function parseError(error) {
  return (
    error.response?.data?.message ||
    (error.response?.data?.errors && error.response?.data?.errors?.length
      ? error.response.data.errors.join(", ")
      : error.message)
  );
}
