import DashboardLayout from "../../layouts/Dashboard";
import FormMapCRUDTable from "../../components/CRUD/Tables/FormMapCRUDTable";
import {useHistory} from "react-router-dom";
import {EMPLOYEE_DETAIL_ROUTE, FORM_MAP_DETAIL_ROUTE} from "../../routes";
import {employeesCRUD, formMapCRUD} from "../../http";
import useReload from "../../hooks/useReload";


const FormMapList = ()=>{
    const history = useHistory()
    const {reload, reloadValue} = useReload();

    const detailAction =(id)=>{
        history.push(FORM_MAP_DETAIL_ROUTE(id),{
            review:true
        })
    }

    const editAction =(id)=>{
        history.push(FORM_MAP_DETAIL_ROUTE(id),{
            review:false
        })
    }

    const deleteAction =async(id)=>{
        try{
            await formMapCRUD.delete(id)
            reload()
        }catch (err){

        }
    }
    return (
        <DashboardLayout>
            <FormMapCRUDTable  onClickDetailsButton={detailAction}
                               onClickDeleteButton={deleteAction}
                               onClickEditButton={editAction} reloadValue={reloadValue}/>
        </DashboardLayout>
    )
}

export default FormMapList