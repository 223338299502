import React, {useEffect} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import ServiceMapCRUDTable from "../../components/CRUD/Tables/ServiceMapCRUDTable";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SERVICE_MAP_CREATE_ROUTE } from "../../routes";
import { SERVICE_MAP_EDIT_ROUTE } from "../../routes";
import { SERVICE_MAP_DETAILS_ROUTE } from "../../routes";
import { serviceMapCRUD } from "../../http";
import useReload from "../../hooks/useReload";

const ServiceMapList = () => {
    const location = useLocation();

    const { push } = useHistory();

    const { reload, reloadValue } = useReload();

    const handleDelete = async (id) => {
        await serviceMapCRUD.delete(id);
        await reload();
    }

    return (
        <DashboardLayout>
            <ServiceMapCRUDTable
                onClickCreateButton={() => push(SERVICE_MAP_CREATE_ROUTE)}
                onRowClick={(record, rowIndex) => push(SERVICE_MAP_EDIT_ROUTE(record.id), { record })}
                onClickDetailsButton={(id) => push(SERVICE_MAP_DETAILS_ROUTE(id), { id })}
                onClickDeleteButton={(id) => handleDelete(id)}
                reloadValue={reloadValue}
            />
        </DashboardLayout>
    )
};

export default ServiceMapList;
