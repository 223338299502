import { 
    REQUEST_SECTION_GET_REQUEST, REQUEST_SECTION_GET_SUCCESS, REQUEST_SECTION_GET_ERROR, 
    REQUEST_SECTION_GET_BY_KEY_REQUEST, REQUEST_SECTION_GET_BY_KEY_SUCCESS, REQUEST_SECTION_GET_BY_KEY_ERROR, 
    REQUEST_SECTION_ADD_REQUEST, REQUEST_SECTION_ADD_SUCCESS, REQUEST_SECTION_ADD_ERROR, 
    REQUEST_SECTION_EDIT_REQUEST, REQUEST_SECTION_EDIT_SUCCESS, REQUEST_SECTION_EDIT_ERROR, 
    REQUEST_SECTION_DELETE_REQUEST, REQUEST_SECTION_DELETE_SUCCESS, REQUEST_SECTION_DELETE_ERROR
} from "../constants/requestSectionConstants";

const initialState = {
    requestSections: [],
    requestSection: {},
    loading: false,
    success: null,
    error: null,
    requestSectionRequestedCount: 0,
};

export const requestSectionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REQUEST_SECTION_GET_REQUEST:
            return {
                ...state,
                requestSectionRequestedCount: ++state.requestSectionRequestedCount,
                loading: true,
            };
        case REQUEST_SECTION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                requestSections: action.payload,
                success: true,
            };
        case REQUEST_SECTION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_SECTION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_SECTION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                requestSection: action.payload,
                success: true,
            };
        case REQUEST_SECTION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_SECTION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_SECTION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_SECTION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_SECTION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_SECTION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_SECTION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_SECTION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_SECTION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_SECTION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
