import React, {useEffect, useMemo} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import {PageContainer, PageHeader} from "../../components";
import {useForm, useWatch} from "react-hook-form";
import {FormProvider} from "../../components/hook-form";
import {Button, Grid} from "@mui/material";
import RHFTextField from "../../components/hook-form/RHFTextField";
import {UnitOfMeasure} from "../../components/Select/domainSelects";
import LoadingButton from "@mui/lab/LoadingButton";
import {serviceSchema} from "../../models/schemas/serviceSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import { serviceCRUD} from "../../http";
import {useHistory, useParams} from "react-router-dom";
import { SERVICE_LIST_CLIENT_ROUTE} from "../../routes";
import {renderControls} from "../../functions";
import useAlert from "../../hooks/useAlert";

const ServiceClientAdd = () => {
    const defaultValues = React.useMemo(() => ({
        unitId: '',
        name: '',
        code: '',
        costCoefficient: 0,
        typeId: 3,
    }), []);

    const {id} = useParams();
    const {push} = useHistory()
    const isEditing = useMemo(() => id, [id]);
    const alert = useAlert(false, 1);

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(serviceSchema)
    });

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: {isSubmitting},
    } = methods;

    useEffect(() => {
        (async function () {
            if (isEditing) {
                const res = await serviceCRUD.get(id);
                reset({name: res.name, costCoefficient: res?.costCoefficient, unitId: res?.unitId, code: res?.code});

            }
        }())
    }, [isEditing])

    const onSubmit = async state => {
        if (isEditing) {
            await serviceCRUD.edit({
                ...state,
                id,
                typeId: 3,
                code: "safas"
            })
            alert.show(1, "Изменения сохранены")
        } else {
            const res = await serviceCRUD.create({
                ...state,
                typeId: 3,
                code: "safas"
            })
            alert.show(1, "Услуга добавлена")
            push(SERVICE_LIST_CLIENT_ROUTE)
        }
    }

    const unitId = useWatch({
        control,
        name: "unitId",
    });


    return (
        <DashboardLayout>
            <PageHeader controls={renderControls([    {
                state: "created",
                roles: [],
                buttons: [
                    <Button
                        variant={"text"}
                        onClick={() => push(SERVICE_LIST_CLIENT_ROUTE)}
                    >
                        Вернуться
                    </Button>
                ],
            }], {
                state: 'created',
                roles: [],
            })}  heading={isEditing ? `Услуга по требованию заказчика #${id}` : "Услуга по требованию заказчика: Создание"}>
            </PageHeader>

            <PageContainer>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Название услуги
                            </p>
                            <RHFTextField label="Название услуги" name="code"/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Полное наименование услуги
                            </p>
                            <RHFTextField label="Полное наименование" name="name"/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Ставка НДС
                            </p>
                            <RHFTextField type="number" label="Ставка НДС" name="costCoefficient"/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Ед. измерения
                            </p>
                            <UnitOfMeasure
                                fullWidth
                                value={unitId}
                                onChange={(val) => setValue('unitId', val)}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton loading={isSubmitting} type="submit" variant="contained"
                                   sx={{mt: 3, mb: 2, alignSelf: 'flex-end'}}>
                        {isEditing ? "Сохранить" : 'Добавить'}
                    </LoadingButton>
                </FormProvider>
            </PageContainer>
            {alert.render()}
        </DashboardLayout>
    )
};

export default ServiceClientAdd;
