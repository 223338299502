import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import {
  formatDate,
  MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../functions/formatters";
import { prescriptionCRUD} from "../../../http";
import {PRESCRIPTION_EDIT_ROUTE, PRESCRIPTION_ROUTE} from "../../../routes";
import CRUDTable from "../CRUDTable";
import { checkRoles } from '../../../functions/checkRoles';
import { Button } from '@mui/material';
import { confirmDialog } from '../../Dialog/DialogDelete';

import {
  REQUEST_STATE_ID_CREATED,
} from "../../../redux/constants/requestStateConstants";

import { objectReferralFilterFields, referralSearchFields } from "../../../sections/Filters/ReferralFilters";

import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";

export const PrescriptionCRUDTable = ({ ...props }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [otherFilter, setOtherFilter] = useState({})

  useEffect(() => {
    if (props?.stateId) {
      setOtherFilter({ stateId: {operator: "equals", operand1: +props?.stateId} })
    }
  }, [props?.stateId])

  const canDelete = checkRoles("SuperAdministrator");

  const alert = useAlert(false, 1);
  const { reload, reloadValue } = useReload();

  const itemActions = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button id="deleteReferal" color="warning" onClick={async () => {
            confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
              try {
                await prescriptionCRUD.delete(row?.id)
                await reload();
              } catch (e) {
                console.log(e);
                alert.show(0, 'Не удалось удалить, ошибка ' + e);
              }
          })
          }}>Удалить</Button>
        </Grid>
        {(row?.state?.code === REQUEST_STATE_ID_CREATED) && <Grid item xs={12}>
          <Button onClick={async () => {
            confirmDialog('Отправка в НЦЭ', 'Вы действительно хотите отправить эту заявку в НЦЭ?', async () => {
              try {
                await prescriptionCRUD.workflowRun(row?.id, 1, 2)
                reload()
              } catch (e) {
                console.log(e);
                alert.show(0, 'Не удалось удалить, ошибка ' + e);
              }
            })
          }} id="deleteReferal">
            Отправить в НЦЭ
          </Button>
        </Grid>}
      </Grid>
    }
  }

  const columns = [
    { title: t("referralList.tableItem.id"), dataIndex: "id", key: "id" },
    {
      title: t("referralList.tableItem.number"),
      dataIndex: "number",
      key: "number",
    },

    {
      title: "Объект",
      dataIndex: "Объект",
      key: "Объект",
      render: (unknown, row) =>
        row.targetObjectId ? (
          row.targetObject.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Филиал",
      dataIndex: "Филиал",
      key: "Филиал",
      render: (unknown, row) =>
        row.filialId ? (
          row.filial.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Создано",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (unknown, row) => formatDate(row.createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS),
    },

    {
      title: "Статус",
      dataIndex: "state",
      key: "state",
      render: (unknown, row) =>
        row.state ? (
          row.state.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Автор",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (unknown, row) => row?.createdBy?.fullName || "-",
    },
    {...(canDelete && { ...itemActions })}
    ,
  ];

  const searchFields = [
    ...referralSearchFields
  ]

  const filterFields = [
    ...objectReferralFilterFields
  ];

  return (
    <CRUDTable
      search={prescriptionCRUD.search}
      columns={columns}
      onRowClick={({ id }) => push(PRESCRIPTION_EDIT_ROUTE(id))}
      onClickCreateButton={() => push(PRESCRIPTION_ROUTE("create"))}
      extraFilter={props?.stateId && otherFilter}
      filterFields={filterFields}
      searchFields={searchFields}
      reloadValue={props.reloadValue || reloadValue}
      {...props}
    />
  );
};
