import { 
    REQUEST_LOCK_GET_REQUEST, REQUEST_LOCK_GET_SUCCESS, REQUEST_LOCK_GET_ERROR, 
    REQUEST_LOCK_GET_BY_KEY_REQUEST, REQUEST_LOCK_GET_BY_KEY_SUCCESS, REQUEST_LOCK_GET_BY_KEY_ERROR, 
    REQUEST_LOCK_ADD_REQUEST, REQUEST_LOCK_ADD_SUCCESS, REQUEST_LOCK_ADD_ERROR, 
    REQUEST_LOCK_EDIT_REQUEST, REQUEST_LOCK_EDIT_SUCCESS, REQUEST_LOCK_EDIT_ERROR, 
    REQUEST_LOCK_DELETE_REQUEST, REQUEST_LOCK_DELETE_SUCCESS, REQUEST_LOCK_DELETE_ERROR
} from "../constants/requestLockConstants";

const initialState = {
    requestLocks: [],
    requestLock: {},
    loading: false,
    success: null,
    error: null,
    requestLockRequestedCount: 0,
};

export const requestLockReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REQUEST_LOCK_GET_REQUEST:
            return {
                ...state,
                requestLockRequestedCount: ++state.requestLockRequestedCount,
                loading: true,
            };
        case REQUEST_LOCK_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                requestLocks: action.payload,
                success: true,
            };
        case REQUEST_LOCK_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_LOCK_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_LOCK_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                requestLock: action.payload,
                success: true,
            };
        case REQUEST_LOCK_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_LOCK_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_LOCK_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_LOCK_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_LOCK_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_LOCK_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_LOCK_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_LOCK_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_LOCK_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_LOCK_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
