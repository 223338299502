import m_12_1633 from '././kyzylorda/KZ.М.12.1633.svg'
import m_12_1633_b from '././kyzylorda/KZ.М.12.1633 black.svg'
import t_12_E0718 from '././kyzylorda/KZ.Т.12.Е0718.svg'
import t_12_E0718_b from '././kyzylorda/KZ.Т.12.Е0718 black.svg'
import t_12_2512 from '././aral/KZ.Т.12.2512.svg'
import t_12_2512_b from '././aral/KZ.Т.12.2512 black.svg'
import t_12_2498 from '././karmakshinskoe/KZ.Т.12.2498.svg'
import t_12_2498_b from '././karmakshinskoe/KZ.Т.12.2498 black.svg'
import t_12_2505 from '././shieliskoe/KZ.Т.12.2505.svg'
import t_12_2505_b from '././shieliskoe/KZ.Т.12.2505 black.svg'
import t_12_2480 from '././zhalagash/KZ.Т.12.2480.svg'
import t_12_2480_b from '././zhalagash/KZ.Т.12.2480 black.svg'
import t_12_2528 from '././kazaly/KZ.Т.12.2528 Kazaly.svg'
import t_12_2528_b from '././kazaly/KZ.Т.12.2528 Kazaly black.svg'
import t_12_2529 from '././syrdarya/KZ.Т.12.2529 Syrdarya.svg'
import t_12_2529_b from '././syrdarya/KZ.Т.12.2529 Syrdarya black.svg'

import t_12_2022 from './t_12_2022.png'
import t_12_2026 from './t_12_2026.png'

export default [
  m_12_1633,
  m_12_1633_b,
  // t_12_2022,
  // t_12_2026,
  t_12_E0718,
  t_12_E0718_b,
  t_12_2512,
  t_12_2512_b,
  t_12_2498,
  t_12_2498_b,
  t_12_2505,
  t_12_2505_b,
  t_12_2480,
  t_12_2480_b,
  t_12_2528,
  t_12_2528_b,
  t_12_2529,
  t_12_2529_b
]
