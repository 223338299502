import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import React, { useCallback, useMemo } from 'react';
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";

const Select = ({options, margin, fullWidth, label, loading, onChange, value,
                  optionValueKey, optionLabelKey, getOptionLabel,
                  required, filterOptions, reducerKey,  ...props}) => {
  const uniqueId = useMemo(() => generateUniqueID(), []);
  const _options = useMemo(() => {
    if(filterOptions) return (options || []).filter(filterOptions);
    return options || [];
  }, [options, filterOptions]);

  const optionsMap = useMemo(() => {
    return _options.reduce((acc, opt, i) => {
      acc[opt[optionValueKey]] = i;
      return acc;
    }, {});
  }, [_options, optionValueKey]);

  const _getOptionLabel = useCallback((item) => {
    if(getOptionLabel) return getOptionLabel(item);
    if(reducerKey == "protocolForm"){
      if(item[optionLabelKey].length == 2)
      return `0${item[optionLabelKey]}`;
    } 
    return item[optionLabelKey];
  }, [optionLabelKey, getOptionLabel])

  return <FormControl fullWidth={fullWidth}>
    {label && <InputLabel id={`${uniqueId}-select`} required>{label}</InputLabel>}
    <MuiSelect
      labelId={`${uniqueId}-select`}
      margin={margin || "normal"}
      label={label}
      value={value || ""}
      onChange={(e) => {
        onChange(e.target.value, _options[optionsMap[e.target.value]])
      }}
      {...props}
    >
      <MenuItem value="">
        <em>Нет выбора</em>
      </MenuItem>
      {_options.map(_ => (
        <MenuItem key={_[optionValueKey]} value={_[optionValueKey]}>
          {_getOptionLabel(_)}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
};

Select.defaultProps = {
  optionValueKey: 'value',
  optionLabelKey: 'label',
};

export default Select;
