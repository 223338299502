export const CONTRACT_REQUEST_GET_REQUEST = "CONTRACT_REQUEST_GET_REQUEST";
export const CONTRACT_REQUEST_GET_SUCCESS = "CONTRACT_REQUEST_GET_SUCCESS";
export const CONTRACT_REQUEST_GET_ERROR = "CONTRACT_REQUEST_GET_ERROR";

export const CONTRACT_REQUEST_GET_BY_KEY_REQUEST =
  "CONTRACT_REQUEST_GET_BY_KEY_REQUEST";
export const CONTRACT_REQUEST_GET_BY_KEY_SUCCESS =
  "CONTRACT_REQUEST_GET_BY_KEY_SUCCESS";
export const CONTRACT_REQUEST_GET_BY_KEY_ERROR =
  "CONTRACT_REQUEST_GET_BY_KEY_ERROR";

export const CONTRACT_REQUEST_CREATE_REQUEST =
  "CONTRACT_REQUEST_CREATE_REQUEST";
export const CONTRACT_REQUEST_CREATE_SUCCESS =
  "CONTRACT_REQUEST_CREATE_SUCCESS";
export const CONTRACT_REQUEST_CREATE_ERROR = "CONTRACT_REQUEST_CREATE_ERROR";

export const CONTRACT_REQUEST_UPDATE_REQUEST =
  "CONTRACT_REQUEST_UPDATE_REQUEST";
export const CONTRACT_REQUEST_UPDATE_SUCCESS =
  "CONTRACT_REQUEST_UPDATE_SUCCESS";
export const CONTRACT_REQUEST_UPDATE_ERROR = "CONTRACT_REQUEST_UPDATE_ERROR";

export const CONTRACT_REQUEST_DELETE_REQUEST =
  "CONTRACT_REQUEST_DELETE_REQUEST";
export const CONTRACT_REQUEST_DELETE_SUCCESS =
  "CONTRACT_REQUEST_DELETE_SUCCESS";
export const CONTRACT_REQUEST_DELETE_ERROR = "CONTRACT_REQUEST_DELETE_ERROR";

export const CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST =
  "CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST";
export const CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS =
  "CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS";
export const CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR =
  "CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR";
