import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import {
  formatDate,
  MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../functions/formatters";
import { referalsCRUD } from "../../../http/CRUD";
import { REFERRAL_ROUTE, REFERRAL_DETAIL_ROUTE, REFERRAL_COPY } from "../../../routes/const";
import { getPatientName } from "../../Autocompletes/PatientsAutocomplete";
import CRUDTable from "../CRUDTable";
import { Button } from "@mui/material";
import { checkRoles } from "../../../functions/checkRoles";
import { referralCRUD } from "../../../http";

import { referralSearchFields } from "../../../sections/Filters/ReferralFilters";
import { referralFilterFields } from "../../../sections/Filters/ReferralFilters";

import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";
import { confirmDialog } from '../../Dialog/DialogDelete';

import { REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_CREATED } from "../../../redux/constants/requestStateConstants";
import { commentDialog } from "../../Dialog/CommentDialog";
import { commentWithSupervisorDialog } from "../../Dialog/CommentWithSupervisorDialog";
import { ContactsOutlined } from "@ant-design/icons";

export default function ReferralCRUDTable({ ...props }) {
  const { t } = useTranslation();
  const { push } = useHistory();

  const [otherFilter, setOtherFilter] = React.useState();

  React.useEffect(() => {
    if (props?.stateId) {
      setOtherFilter({ stateId: { operator: "equals", operand1: +props?.stateId } })
    }
  }, [props?.stateId])

  const canDoPrivileged = checkRoles("SuperAdministrator", "Administrator");
  const isFilialAdministrator = checkRoles("FilialAdministrator");
  const canClone = checkRoles('Specialist', 'Supervisor', 'Performer');
  const { id } = useSelector(state => state.employee.authorizedEmployee);

  const alert = useAlert(false, 1);
  const { reload, reloadValue } = useReload();

  const itemActions = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        {(row?.state?.code == REQUEST_STATE_ID_COMPLETED) && <Grid item xs={12}><Button id="deleteReferal" color="info" onClick={async () => {
          confirmDialog('Откат на исследование', 'Вы действительно хотите откатить на исследование этот элемент?', async () => {
            try {
              await referalsCRUD.workflowRun(row?.id, REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_RESEARCH)
              await reload();
            } catch (e) {
              console.log(e);
              alert.show(0, 'Не удалось откатить, ошибка ' + e);
            }
          })
        }}>На исследование</Button></Grid>}
        <Grid item xs={12}>
          <Button id="deleteReferal" color="warning" onClick={async () => {
            confirmDialog('Удаление элемента', 'Вы действительно хотите удалить этот элемент?', async () => {
              try {
                await referalsCRUD.delete(row?.id)
                await reload();
              } catch (e) {
                console.log(e);
                alert.show(0, 'Не удалось удалить, ошибка ' + e);
              }
            })
          }}>Удалить</Button>
        </Grid>
        {
          (row?.state?.code === REQUEST_STATE_ID_CREATED || row?.state?.code === REQUEST_STATE_ID_RESEARCH) &&
          <Grid item xs={12}>
            <Button id="copyReferal" color="secondary" onClick={async () => {
              push({
                pathname: REFERRAL_COPY(row.id)
              })
            }}>Клонировать</Button>
          </Grid>
        }
      </Grid>
    }
  }

  const itemActionsForFilialAdministrator = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        {(row?.state?.code == REQUEST_STATE_ID_COMPLETED) && <Grid item xs={12}><Button id="deleteReferal" color="info" onClick={async () => {
            commentWithSupervisorDialog('Откат на исследование', 'Вы действительно хотите откатить на исследование этот элемент?', async (_) => {
              try {
               // console.log(_, supervisorId)
                await referalsCRUD.workflowRun(row?.id, REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_RESEARCH, _.comment, _.directorId)
                await reload();
              } catch (e) {
                console.log(e);
                alert.show(0, 'Не удалось откатить, ошибка ' + e);
              }
            })
        }}>На исследование</Button></Grid>}
      </Grid>
    }
  }

  const itemActionClone = {
    title: "Действия",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return <Grid container spacing={1}>
        {
          (row?.state?.code === REQUEST_STATE_ID_CREATED || row?.state?.code === REQUEST_STATE_ID_RESEARCH) &&
          <Grid item xs={12}>
            <Button id="copyReferal" color="secondary" onClick={async () => {
              push({
                pathname: REFERRAL_COPY(row.id)
              })
            }}>Клонировать</Button>
          </Grid>
        }
      </Grid>
    }
  }

  //referalsCRUD.workflowRun(id, lastStateCode, nextStateCode)

  const columns = [
    { title: t("referralList.tableItem.id"), dataIndex: "id", key: "id" },
    {
      title: t("referralList.tableItem.number"),
      dataIndex: "number",
      key: "number",
    },
    {
      title: t("referralList.tableItem.createdAt"),
      dataIndex: "createdAt",
      key: "createAt",
      render: (a, row) =>
        formatDate(row.createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS),
    },
    {
      title: t("referralList.tableItem.createdBy"),
      dataIndex: "createdBy",
      key: "createBy",
      render: (a, row) => row.createdBy?.fullName,
    },
    {
      title: "Филиал",
      dataIndex: "Филиал",
      key: "Филиал",
      render: (unknown, row) =>
        row.filialId ? (
          row.filial.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Лаборатория",
      dataIndex: "Лаборатория",
      key: "Лаборатория",
      render: (unknown, row) =>
        row.departmentId ? (
          row.department.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Ответственный",
      dataIndex: "responsible",
      key: "responsible",
      render: (a, row) => row?.responsible?.fullName || "-",
    },
    {
      title: "Исполнитель",
      dataIndex: "performer",
      key: "performer",
      render: (a, row) => row?.performer?.fullName || "-",
    },
    {
      title: "Пациент",
      dataIndex: "targetPatient",
      key: "targetPatient",
      render: (unknown, row) =>
        row.targetPatient ? (
          getPatientName(row.targetPatient)
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    {
      title: "Наименование учреждения направившего образец",
      dataIndex: "sampleSentOrganizationName",
      key: "sampleSentOrganizationName",
      render: (unknown, row) => <span style={{ }}>{row.sampleSentOrganizationName}</span>
    },
    {
      title: "Статус",
      dataIndex: "state",
      key: "state",
      render: (unknown, row) =>
        row.state ? (
          row.state.name
        ) : (
          <span style={{ color: "gray" }}>Не задан</span>
        ),
    },
    { ...(canDoPrivileged && { ...itemActions }) },
    { ...(isFilialAdministrator && { ...itemActionsForFilialAdministrator }) },
    { ...(canClone && { ...itemActionClone }) }
  ];

  const searchFields = [
    ...referralSearchFields,
    {
      title: "ФИО пациента",
      key: "targetPatientFullName",
      key1: "targetPatient",
      key2: "fullName",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal",
    },
    {
      title: "Наименование учреждения направившего образец",
      key: "sampleSentOrganizationName",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal",
    },
    //
  ];

  const filterFields = [
    ...referralFilterFields
  ];

  return (
    <CRUDTable
      search={referalsCRUD.search}
      columns={columns}
      onClickCreateButton={() => push(REFERRAL_DETAIL_ROUTE("new"))}
      onRowClick={(record, rowIndex) =>
        push({
          pathname: REFERRAL_DETAIL_ROUTE(record.id),
          state: {
            record,
          },
        })
      }
      filterFields={filterFields}
      extraFilter={props?.stateId && otherFilter}
      searchFields={searchFields}
      reloadValue={props.reloadValue || reloadValue}
      rowClassNameHandler={(record) => {
          if (record.externallyReceived) {
              return 'rowExternallyReceived'
          }
      }}
      {...props}
    />
  );
}
