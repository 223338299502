export const ACT_GET_REQUEST = 'ACT_GET_REQUEST';
export const ACT_GET_SUCCESS = 'ACT_GET_SUCCESS';
export const ACT_GET_ERROR = 'ACT_GET_ERROR';
export const ACT_GET_BY_KEY_REQUEST = 'ACT_GET_BY_KEY_REQUEST';
export const ACT_GET_BY_KEY_SUCCESS = 'ACT_GET_BY_KEY_SUCCESS';
export const ACT_GET_BY_KEY_ERROR = 'ACT_GET_BY_KEY_ERROR';
export const ACT_ADD_REQUEST = 'ACT_ADD_REQUEST';
export const ACT_ADD_SUCCESS = 'ACT_ADD_SUCCESS';
export const ACT_ADD_ERROR = 'ACT_ADD_ERROR';
export const ACT_EDIT_REQUEST = 'ACT_EDIT_REQUEST';
export const ACT_EDIT_SUCCESS = 'ACT_EDIT_SUCCESS';
export const ACT_EDIT_ERROR = 'ACT_EDIT_ERROR';
export const ACT_DELETE_REQUEST = 'ACT_DELETE_REQUEST';
export const ACT_DELETE_SUCCESS = 'ACT_DELETE_SUCCESS';
export const ACT_DELETE_ERROR = 'ACT_DELETE_ERROR';

export const ACT_WORKFLOW_RUN_REQUEST = 'ACT_WORKFLOW_RUN_REQUEST';
export const ACT_WORKFLOW_RUN_SUCCESS = 'ACT_WORKFLOW_RUN_SUCCESS';
export const ACT_WORKFLOW_RUN_ERROR = 'ACT_WORKFLOW_RUN_ERROR';