import DirectionCholeraForm from "./DirectionCholeraForm";
import DirectionCholeraPDFGenerator from "./DirectionCholeraPDFGenerator";

import withDirectionDetail from "../withDirectionDetail";

const directionFormKazakhName = 'Сынаманы тырысқақ қоздырғышына тексерудің жолдамасы және зерттеу нәтижесі'
const directionFormName = 'Направление и результат исследования материала на наличие возбудителя холеры'
const directionFormNumber = '021'
const directionName = 'DirectionCholera'

export default withDirectionDetail(DirectionCholeraForm, DirectionCholeraPDFGenerator, directionFormKazakhName,  directionFormName,  directionFormNumber, directionName)