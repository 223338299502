import t_05_0594_b from './KZ.T.05.0594 black.svg'
import t_05_0594 from './KZ.T.05.0594.svg'
import t_05_0294 from './KZ.T.05.0294.svg'
import m_05_2165 from './KZ.М.05.2165 black.svg'
import m_05_2165_b from './KZ.М.05.2165.svg'

// import t_05_0294 from './t_05_0294.png'

export default [
  t_05_0594_b,
  t_05_0594,
  t_05_0294,
  m_05_2165,
  m_05_2165_b,
]
