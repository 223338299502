import {
    formatDate,
    MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../functions/formatters";
import {
    anObjectReferralCRUD,
    requestCRUD,
    actFormCRUD,
    brigadeTypeCRUD,
    actCRUD,
    brigadeCRUD
} from "../../../http/CRUD";
//import { REQUEST_DETAIL_ROUTE } from "../../../routes/const";
import CRUDTable from "../CRUDTable";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { mapReferralObjectProbeType } from "../../../functions/viewDataMap";
import {
    OBJECT_REFERRAL_DETAIL_ROUTE,
    REFERRAL_DETAIL_ROUTE,
    CONTRACT_REQUEST_ROUTE,
    KDI_REQUEST_ROUTE,
    DEPARTURE_SCHEDULE_REQUEST_ROUTE,
    DDD_REQUEST_ROUTE,
    SERVICE_WITHOUT_ACT_REQUEST_ROUTE,
    WITHOUT_DEPARTURE_REQUEST_ROUTE,
    ACT_ROUTE,
    BRIGADE_ROUTE, RESOLUTION_ROUTE, RESOLUTION_EDIT_ROUTE, PRESCRIPTION_EDIT_ROUTE, SELF_CONTROL_REQUEST_ROUTE
} from "../../../routes";
import { getReferralStates } from "../../../redux/actions/referralStateActions";
import { getRequestTypes } from "../../../redux/actions/requestTypeActions";
import {
    REQUEST_TYPE_ID_AnObjectReferralRequest,
    REQUEST_TYPE_ID_ReferralRequest,
    REQUEST_TYPE_ID_ContractRequest,
    REQUEST_TYPE_ID_ClinicalRequest,
    REQUEST_TYPE_ID_DepartureScheduleRequest,
    REQUEST_TYPE_ID_DesinsectionRequest,
    REQUEST_TYPE_ID_ServiceWithoutActRequest,
    REQUEST_TYPE_ID_WithoutDepartureRequest,
    REQUEST_TYPE_ID_Act,
    REQUEST_TYPE_ID_Brigade,
    REQUEST_TYPE_ID_Protocol, REQUEST_TYPE_ID_Resolution, REQUEST_TYPE_ID_Prescription, REQUEST_TYPE_ID_SelfControl
} from '../../../redux/constants/requestTypeConstants';
import { composeRequestData } from '../../../functions/viewDataComposition';

import ProtocolDetails from '../../../screens/Protocol/Popups/ProtocolDetails';
import { protocolCRUD } from "../../../http";
import LoadingContainer from '../../../components/Loader/LoadingContainer';

export default function RequestCRUDTable({ ...props }) {
    const { t } = useTranslation();
    const { push } = useHistory();

    const [loading, setLoading] = useState(false);
    const [actForms, setActForms] = useState(null);
    const [brigadeTypes, setBrigadeTypes] = useState(null);

    const [protocol, setProtocol] = useState(null);
    const [protocolDetailsOpen, setProtocolDetailsOpen] = useState(false);
    const protocolDetailsHandler = async (id) => {
        await startLoading();

        protocolCRUD.get(id).then(async data => {
            await stopLoading();
            setProtocol(data);
            setProtocolDetailsOpen(true);
        }).catch(async _ => {
            await stopLoading();
            alert(_);
        });
    }

    const startLoading = async () => {
        await setLoading(true);
    }

    const stopLoading = async () => {
        await setLoading(false);
    }

    const handleData = async (data) => {
        let actFormsData = actForms;
        let brigadeTypesData = brigadeTypes;

        if (!actFormsData) {
            actFormsData = (await actFormCRUD.search({ paging: { skip: 0 } })).result;
            setActForms(actFormsData);
        }

        if (!brigadeTypesData) {
            brigadeTypesData = (await brigadeTypeCRUD.search({ paging: { skip: 0 } })).result;
            setBrigadeTypes(brigadeTypesData);
        }

        composeRequestData(data, actFormsData, brigadeTypesData);
    }

    const columns = props.columns ?? [
        { title: t("referralList.tableItem.id"), dataIndex: "id", key: "id" },
        {
            title: t("referralList.tableItem.number"),
            dataIndex: "number",
            key: "number",
            render: (unknown, row) =>
                row.number ? (
                    row.number
                ) : (
                    <span style={{ color: "gray" }}>Не задан</span>
                ),
        },
        {
            title: t("referralList.tableItem.createdAt"),
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: t("referralList.tableItem.state"),
            dataIndex: "state",
            key: "state",
            render: (unknown, row) =>
                row.stateId ? (
                    row.state.name
                ) : (
                    <span style={{ color: "gray" }}>Не задан</span>
                ),
        },
        {
            title: t("referralList.tableItem.type"),
            dataIndex: "type.name",
            key: "type.name",
            render: (unknown, row) =>
                row.typeId ? (
                    row.type.name + (
                        row.act?.form ? " (" + row.act.form.number + ")" : ""
                    ) + (
                        row.brigade?.type ? " (" + row.brigade.type.name + ")" : ""
                    )
                ) : (
                    <span style={{ color: "gray" }}>Не задан</span>
                ),
        },
        {
            title: t("referralList.tableItem.performer"),
            dataIndex: "performer.fullName",
            key: "performer.fullName",
            render: (unknown, row) =>
                row.performer ? row.performer.fullName : (
                    <span style={{ color: "gray" }}>Не задан</span>
                ),
        },
    ];
    const searchFields = [
        {
            title: "Номер",
            key: "number",
            compareType: "equals",
            operandPosition: 1,
            filterType: "normal",
        },
    ];

    const filterFields = [
        {
            title: "Cтатус",
            key: "stateId",
            compareType: "equals",
            reducerKey: "referralState",
            reducerArrayKey: "referralStates",
            reducerAction: getReferralStates,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Тип",
            key: "typeId",
            compareType: "equals",
            reducerKey: "requestType",
            reducerArrayKey: "requestTypes",
            reducerAction: getRequestTypes,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Дата создания с",
            key: "createdAt",
            operandPosition: 1,
            compareType: "between",
            type: "date",
            filterType: "normal",
        },
        {
            title: "Дата создания по",
            key: "createdAt",
            operandPosition: 2,
            compareType: "between",
            type: "date",
            filterType: "normal",
        },
    ];

    return (
        <>
            <LoadingContainer loading={loading}>
                <CRUDTable
                    search={requestCRUD.search}
                    columns={columns}
                    onData={handleData}
                    onRowClick={async (record, rowIndex) => {
                        let pathname = null;

                        switch (record.typeId) {
                            case REQUEST_TYPE_ID_ReferralRequest:
                                pathname = REFERRAL_DETAIL_ROUTE(record.referralId);
                                break;
                            case REQUEST_TYPE_ID_AnObjectReferralRequest:
                                pathname = OBJECT_REFERRAL_DETAIL_ROUTE(record.anObjectReferralId);
                                break;
                            case REQUEST_TYPE_ID_ContractRequest:
                                pathname = CONTRACT_REQUEST_ROUTE(record.contractRequestId);
                                break;
                            case REQUEST_TYPE_ID_ClinicalRequest:
                                pathname = KDI_REQUEST_ROUTE(record.clinicalRequestId);
                                break;
                            case REQUEST_TYPE_ID_SelfControl:
                                const request = await requestCRUD.get(record.id);

                                pathname = SELF_CONTROL_REQUEST_ROUTE(request.selfControlRequestId);
                                break;
                            case REQUEST_TYPE_ID_DepartureScheduleRequest:
                                pathname = DEPARTURE_SCHEDULE_REQUEST_ROUTE(record.departureScheduleRequestId);
                                break;
                            case REQUEST_TYPE_ID_DesinsectionRequest:
                                pathname = DDD_REQUEST_ROUTE(record.desinsectionRequestId);
                                break;
                            case REQUEST_TYPE_ID_ServiceWithoutActRequest:
                                pathname = SERVICE_WITHOUT_ACT_REQUEST_ROUTE(record.serviceWithoutActRequestId);
                                break;
                            case REQUEST_TYPE_ID_WithoutDepartureRequest:
                                pathname = WITHOUT_DEPARTURE_REQUEST_ROUTE(record.withoutDepartureRequestId);
                                break;
                            case REQUEST_TYPE_ID_Resolution:
                                pathname = RESOLUTION_EDIT_ROUTE(record.resolutionId);
                                break;
                            case REQUEST_TYPE_ID_Prescription:
                                pathname = PRESCRIPTION_EDIT_ROUTE(record.prescriptionId);
                                break;
                            case REQUEST_TYPE_ID_Act:
                               // debugger;
                                if (!record.actId)
                                    return;

                                if (!record.act)
                                    record.act = await actCRUD.get(record.actId);

                                let form = record.act?.form;
                                if (!form) {
                                    alert('К акту нет привязана форма!');
                                    return;
                                }
                                pathname = ACT_ROUTE(form.number, record.actId);
                                break;
                            case REQUEST_TYPE_ID_Brigade:
                                if (!record.brigadeId)
                                    return;

                                if (!record.brigade)
                                    record.brigade = await brigadeCRUD.get(record.brigadeId);

                                let type = record.brigade?.type;
                                if (!type)
                                    return;
                                pathname = BRIGADE_ROUTE(type.code, record.brigadeId);
                                break;
                            case REQUEST_TYPE_ID_Protocol:
                                await protocolDetailsHandler(record.protocolId);
                                break;
                        }

                        if (pathname != null)
                            push({
                                pathname,
                                state: record,
                            });
                    }}
                    searchFields={searchFields}
                    filterFields={filterFields}
                    {...props}
                />
            </LoadingContainer>
            <ProtocolDetails action={'details'} title={t("protocolList.detailsText")} open={protocolDetailsOpen}
                setOpen={setProtocolDetailsOpen} protocol={protocol} />
        </>
    );
}
