import React, { useCallback, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { FormControl } from "@mui/material";
import { FormLabel } from "@mui/material";
import { TextField } from "@mui/material";
import { toUIDate } from "../../functions/dateHelpers";

export const DateField = ({ id, label, value, onChange, xs = 12 }) => {
    return (
      <Grid item xs={xs}>
        <FormControl component="fieldset">
          {label && <FormLabel component="legend">{label}</FormLabel>}
          <TextField
              InputLabelProps={{ shrink: true }}
              type={"date"}
              margin="normal"
              value={toUIDate(value)}
              onChange={(e) => onChange(e.target.value, e)}
              fullWidth
              id={id}
              name={id}
          />
        </FormControl>
      </Grid>
    );
  };