import React from 'react';
import DialogTitle from "@mui/material/DialogTitle";
import {CircularProgress, Dialog, DialogContent, Divider} from "@mui/material";

function DialogLayout({ open, setOpen, title, children, loading }) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            sx={{height: '90%', margin: 'auto'}}
            maxWidth={"80%"}
        >
            <DialogTitle>{ title }</DialogTitle>
            <Divider />
            <DialogContent>
                {loading ? <CircularProgress/> : children}
            </DialogContent>
        </Dialog>
    );
}

export default DialogLayout;
