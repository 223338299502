export class Domain {
  options = [];
  optionsMap = {};

  constructor(options) {
    this.options = options;
    this.optionsMap = options.reduce((acc, next) => {
      acc[next.id] = acc.length;
      return acc;
    }, {})
  }

  label (value) {
    return this.option(value)?.label;
  }
  option (value) {
    return this.options[this.optionsMap[value]];
  }
}