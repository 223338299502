import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import {CircularProgress, Grid} from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {FORM_MAP_LIST_ROUTE} from "../../../routes";
import {formMapCRUD} from "../../../http";
import {
    ActFormSelect,
    BrigadeSelect,
    DepartmentSelect, ProbTypeSelect,
    ProtocolFormSelect,
    ResultFormSelect, ResultTypeSelect, RoleSelect
} from "../../../components/Select/domainSelects";
import useAlert from "../../../hooks/useAlert";
import Button from "@mui/material/Button";
import {v4 as uuidv4} from "uuid";
import AddIcon from "@mui/icons-material/Add";
import {getRoles} from "../../../redux/actions/roleActions";

const FormMapDetailForm = () => {
    const history = useHistory()
    const {id} = useParams();
    const [isNew, setIsNew] = useState(id === "new")
    const [roleBlocks, setRoleBlocks] = useState({})
    const {roles} = useSelector(state => state.role);


    const selectedRoles = useMemo(() => {
        return Object.keys(roleBlocks).map(el => roleBlocks[el].roleId)
    }, [roleBlocks])

    const dispatch = useDispatch()
    const alert = useAlert(false, 1)
    const [fields, setFields] = useState({})
    const {loading, start, stop} = useLoader(false)
    const fetch = useCallback(() => {
        start()
        if (!isNew) {
            formMapCRUD.get(id).then(async data => {
                const {result} = await dispatch(getRoles({paging: {skip: 0}}));

                let roleParsing = {}

                JSON.parse(data?.roles)?.map(row => {
                    const role = result.find(el => el.code === row)
                    if (role)
                        roleParsing[uuidv4()] = {
                            code: role?.code,
                            roleId: role?.id,
                            type: "new"
                        }
                })

                if (Object.keys(roleParsing)?.length) {
                    setRoleBlocks(roleParsing)
                }

                setFields(state => {
                    return {
                        ...state,
                        brigadeTypeId: data.brigadeTypeId || null,
                        protocolFormId: data.protocolFormId || null,
                        resultFormId: data.resultFormId || null,
                        departmentId: data.departmentId || null,
                        probeTypeId: data.probeTypeId || null,
                        actFormId: data.actFormId || null,
                        resultTypeId: data?.resultTypeId || null
                    }
                })
                stop()
            })
        } else {
            stop()
        }

    }, [dispatch, id])

    useEffect(fetch, [fetch])
    const submit = useCallback(async () => {
        const roles = Object.keys(roleBlocks).map(el => {
            return roleBlocks[el].code
        })
        try {
            if (!isNew) {
                await formMapCRUD.edit({
                    id: id,
                    ...fields,
                    roles: JSON.stringify(roles)
                })
                alert.show(1, "Изменения сохранены")
            } else {
                await formMapCRUD.create({
                    ...fields,
                    roles: JSON.stringify(roles)
                })

                history.push(FORM_MAP_LIST_ROUTE)
            }
        } catch (err) {
            alert.show(0, err)
        }
    }, [id, dispatch, fields, roleBlocks])

    return (
        <DashboardLayout>
            {loading ? <CircularProgress/> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar style={{display: "flex", justifyContent: "space-between"}}>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew ? "Создание" : "Редактирование"} Формы<br/>
                            </Typography>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{paddingTop: 16}}>
                    <Paper style={{paddingBottom: 20}}>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <DepartmentSelect
                                        label={"Лаборатория"}
                                        fullWidth
                                        value={fields.departmentId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    departmentId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ProtocolFormSelect
                                        fullWidth
                                        value={fields.protocolFormId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    protocolFormId: val
                                                }
                                            })
                                        }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <ResultFormSelect
                                        fullWidth
                                        value={fields.resultFormId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    resultFormId: val
                                                }
                                            })
                                        }}/>
                                </Grid>

                                <Grid item xs={12}>
                                    <BrigadeSelect
                                        fullWidth
                                        value={fields.brigadeTypeId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    brigadeTypeId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ProbTypeSelect
                                        fullWidth
                                        value={fields.probeTypeId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    probeTypeId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ActFormSelect
                                        fullWidth
                                        value={fields.actFormId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    actFormId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <ResultTypeSelect
                                        fullWidth
                                        value={fields?.resultTypeId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    resultTypeId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                {fields?.resultTypeId === 2 && <Grid container xs={12}>
                                    <Grid item xs={12} marginTop={5}>
                                        <Typography variant={"h6"} style={{display: 'inline'}}>Роли для учётных журналов</Typography>
                                    </Grid>
                                    <Grid container spacing={3} style={{display: "flex", alignItems: "center"}} xs={12}>
                                        {Object.keys(roleBlocks).map((key) => {
                                            return (roleBlocks[key].type !== "deleted" ? <>
                                                    <Grid item xs={11}>
                                                        <RoleSelect
                                                            label={"Роль"}
                                                            fullWidth
                                                            selectedRoles={selectedRoles.filter(el => el != roleBlocks[key].roleId)}
                                                            value={roleBlocks[key].roleId || ""}
                                                            onChange={(val) => {
                                                                let code = roles?.find(el => el.id === val).code
                                                                setRoleBlocks((state) => {
                                                                    return {
                                                                        ...state,
                                                                        [key]: {
                                                                            ...state[key],
                                                                            code,
                                                                            roleId: val
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button onClick={() => {
                                                            setRoleBlocks((state) => {
                                                                delete state[key]
                                                                return {
                                                                    ...state,

                                                                }
                                                            })
                                                        }}>Удалить</Button>
                                                    </Grid>
                                                </> : <></>

                                            )
                                        })}


                                        <Grid item xs={12}>
                                            <Button onClick={() => {
                                                setRoleBlocks((state) => {
                                                    return {
                                                        ...state,
                                                        [uuidv4()]: {
                                                            roleId: null,
                                                            type: "new"
                                                        },
                                                    }
                                                })
                                            }}><AddIcon sx={{marginRight: 2}}/> Добавить роль</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            </Grid>


                        </Grid>


                    </Paper>
                </Grid>
                <LoadingButton style={{marginTop: "10px"}} variant="contained" onClick={() => {
                    submit()
                }}>
                    Сохранить
                </LoadingButton>
                {alert.render()}
            </>}


        </DashboardLayout>
    )
}

export default FormMapDetailForm