import React from 'react';

import {anObjectsCRUD, serviceCRUD} from "../../../http/CRUD";
import CRUDTable from "../CRUDTable";
import useReload from "../../../hooks/useReload";

export default function ServiceClientCRUDTable({...props}) {
    const {reload, reloadValue} = useReload();
    const columns = [
        {title: "ID", dataIndex: 'id', key: 'id'},
        {
            title: "Название", key: 'name', render: (unknown, row) => row.name ? row.name : row.group ? row.group.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {title: "Ценовой коэффицент", dataIndex: 'costCoefficient', key: 'costCoefficient'},
        {
            title: "Единица услуги", key: 'kind', render: (unknown, row) => row?.unit ? row.unit?.name :
                <span style={{color: "gray"}}>Не задан</span>
        }
    ];
    const searchFields = [
        {
            title: "Название",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
    ]

    return (
        <>
            <CRUDTable search={props.search || serviceCRUD.search}
                       columns={columns}
                       searchFields={searchFields}
                       reloadValue={reloadValue}
                       extraFilter={{
                               typeId: {
                                   operand1: 3,
                                   operator: "equals"
                               }
                       }}
                       {...props}
            />
        </>
    );
}
