import { $authHost } from "../../http";
import { 
    GENDER_GET_REQUEST, GENDER_GET_SUCCESS, GENDER_GET_ERROR, 
    GENDER_GET_BY_KEY_REQUEST, GENDER_GET_BY_KEY_SUCCESS, GENDER_GET_BY_KEY_ERROR, 
    GENDER_ADD_REQUEST, GENDER_ADD_SUCCESS, GENDER_ADD_ERROR, 
    GENDER_EDIT_REQUEST, GENDER_EDIT_SUCCESS, GENDER_EDIT_ERROR, 
    GENDER_DELETE_REQUEST, GENDER_DELETE_SUCCESS, GENDER_DELETE_ERROR
} from "../constants/genderConstants";

export const getGenders = (args) => async (dispatch) => {
    try {
        dispatch({
            type: GENDER_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/gender/query",
            args || {},
            config,
        );
        dispatch({
            type: GENDER_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: GENDER_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getGenderByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: GENDER_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/gender/${id}`,
        );
        dispatch({
            type: GENDER_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: GENDER_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addGender = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: GENDER_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/gender",
            { name, code, description },
            config,
        );
        dispatch(getGenders(queryArgs || {}));
        dispatch({
            type: GENDER_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: GENDER_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editGender = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: GENDER_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/gender",
            { id, name, code, description },
            config,
        );
        dispatch(getGenders(queryArgs || {}));
        dispatch({
            type: GENDER_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: GENDER_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteGender = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: GENDER_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/gender",
            { data: { id } },
            config,
        );
        dispatch(getGenders(queryArgs || {}));
        dispatch({
            type: GENDER_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: GENDER_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

