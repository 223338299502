import {LOGIN_ROUTE, REFERRAL, REQUEST_LIST_ROUTE, RESOLUTION} from "../../routes";
import {authRoutes, publicRoutes} from "../../routes";
import React, {useEffect} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {useSelector} from "react-redux";
import { useMemo } from "react";
import useRoles from "../../hooks/useRoles";
import { isPublicProtocolSee } from "../../functions/renderProtocol";

const AppRouter = () => {
    let hasToken = !!localStorage.getItem("token") != null &&
        localStorage.getItem("token") != "null";    
    const isAuth = useMemo(()=>{
        return hasToken;
    }, [hasToken]);
    const { isCommittee } = useRoles();
    const { search } = useLocation();
    const location = useLocation().pathname;

    const history = useHistory();

    const knownRoute = (authRoutes?.find(_ => _.path.startsWith(location)) != null ||
        publicRoutes?.find(_ => _.path?.startsWith(location)) != null) && location != '/';
    const protocolSee = isPublicProtocolSee("/protocolList", search);

    return (
        <div>
            <Switch>
                {isAuth && authRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} component={Component} exact/>
                )}
                {publicRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} component={Component} exact/>
                )}
                {!knownRoute && <Redirect to={!isCommittee() ? REQUEST_LIST_ROUTE : RESOLUTION}/>}
                {!isAuth && !protocolSee && <Redirect to={LOGIN_ROUTE}/>}
            </Switch>
        </div>
    );
};

export default AppRouter;