import { firstOrDefault1, toList1 } from "../functions/resourceLinq1";

export default function useResourceLinq(resource) {
    //this.resource = resource;

    return {
        ...resource,
        query: { },
        firstOrDefault: async (params) => {
            return await firstOrDefault1(resource, params);
        },
        toList: async (params) => {
            return await toList1(resource, params);
        }
    }
}