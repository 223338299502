import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import create from 'zustand';
import {Grid, TextField} from '@mui/material';

const useCommentDialogStore = create((set) => ({
    message: '',
    title: '',
    onSubmit: undefined,
    close: () => set({onSubmit: undefined}),
}));


export const commentDialog = (title, message, onSubmit) => {
    useCommentDialogStore.setState({
        title,
        message,
        onSubmit,
    });
};

const CommentDialog = () => {
    const {title, message, onSubmit, close} = useCommentDialogStore();
    const [comment, setComment] = useState(null);
    return (
            <Dialog
                open={Boolean(onSubmit)}
                onClose={close}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                margin="normal"
                                value={comment}
                                onChange={(e) =>
                                    setComment(e.target.value)
                                }
                                fullWidth
                                id="comment"
                                name="comment"
                                label="Комментарий"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => close()} color="error" variant="contained">
                        Отменить
                    </Button>
                    <Button onClick={() => {
                        if (onSubmit) {
                            onSubmit({ comment });
                        }
                        close();
                    }} color="primary" variant="contained">
                        Подтвердить
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default CommentDialog;
