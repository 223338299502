import { $authHost } from "../../http";
import { 
    PATIENT_GET_REQUEST, PATIENT_GET_SUCCESS, PATIENT_GET_ERROR, 
    PATIENT_GET_BY_KEY_REQUEST, PATIENT_GET_BY_KEY_SUCCESS, PATIENT_GET_BY_KEY_ERROR, 
    PATIENT_ADD_REQUEST, PATIENT_ADD_SUCCESS, PATIENT_ADD_ERROR, 
    PATIENT_EDIT_REQUEST, PATIENT_EDIT_SUCCESS, PATIENT_EDIT_ERROR, 
    PATIENT_DELETE_REQUEST, PATIENT_DELETE_SUCCESS, PATIENT_DELETE_ERROR
} from "../constants/patientConstants";

export const getPatients = (args) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/patient/query",
            args || {},
            config,
        );
        dispatch({
            type: PATIENT_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: PATIENT_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getPatientByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/patient/${id}`,
        );
        dispatch({
            type: PATIENT_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: PATIENT_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addPatient = ({ firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, addressLine, placeOfWorkOrEducation, genderId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/patient",
            { firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, addressLine, placeOfWorkOrEducation, genderId },
            config,
        );
        dispatch(getPatients(queryArgs || {}));
        dispatch({
            type: PATIENT_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editPatient = ({ id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, addressLine, placeOfWorkOrEducation, genderId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/patient",
            { id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, addressLine, placeOfWorkOrEducation, genderId },
            config,
        );
        dispatch(getPatients(queryArgs || {}));
        dispatch({
            type: PATIENT_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deletePatient = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/patient",
            { data: { id } },
            config,
        );
        dispatch(getPatients(queryArgs || {}));
        dispatch({
            type: PATIENT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

