import { SearchPanel, TableCustom } from "../../../components";
import DashboardLayout from "../../../layouts/Dashboard";

import DictionaryCRUDTable from "../../../components/CRUD/Tables/DictionaryCRUDTable";

const GovernmentStandardList = () => {    
    return (
        <DashboardLayout>
            <DictionaryCRUDTable
                entityType="governmentStandard"
                hasCode={true}
                hasDescription={true}
            />
        </DashboardLayout>
    )
};

export default GovernmentStandardList;
