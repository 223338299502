import m_02_2243_colorized from './KZ.М.02.2243.svg'
import m_02_2243 from './KZ.М.02.2243 black.svg'
import t_02_0575_colorized from './KZ.T.02.0575.svg'
import t_02_0575 from './KZ.T.02.0575 black.svg'

export default [
  m_02_2243_colorized,
  m_02_2243,
  t_02_0575_colorized,
  t_02_0575
]
