export const requestStateIds = {
    undefined: 0,
    // Создан
    created: 1,
    // В процессе
    processing: 2,
    // Проведение исследовании
    research: 3,
    // Завершено
    completed: 4,
    // Отклонено
    declined: 5,
    // Отбор проб
    probeSelection: 6,
    negotiation: 7
}