import React, {useCallback, useEffect, useMemo, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import EmployeeCRUDTable from "../../components/CRUD/Tables/EmployeeCRUDTable";
import {useTranslation} from "react-i18next";
import useReload from "../../hooks/useReload";
import {EMPLOYEE_DETAIL_ROUTE} from "../../routes";
import {employeesCRUD} from "../../http";
import {useHistory} from "react-router-dom";

let rows = [];
const RequestList = () => {
    const {t} = useTranslation()
    const {reload, reloadValue} = useReload();
    const history = useHistory()
    const detailAction =(id)=>{
        history.push(EMPLOYEE_DETAIL_ROUTE(id),{
            review:true
        })
    }

    const editAction =(id)=>{
        history.push(EMPLOYEE_DETAIL_ROUTE(id),{
            review:false
        })
    }

    const deleteAction =async(id)=>{
        try{
            await employeesCRUD.delete(id)
            reload()
        }catch (err){

        }
    }
    return (
        <DashboardLayout>
            <EmployeeCRUDTable
                withOrganization={true}
                onClickDetailsButton={detailAction}
                onClickDeleteButton={deleteAction}
                onClickEditButton={editAction} reloadValue={reloadValue}/>
        </DashboardLayout>
    )

};

export default RequestList;
