import { 
    PURPOSE_OF_SELECTION_GET_REQUEST, PURPOSE_OF_SELECTION_GET_SUCCESS, PURPOSE_OF_SELECTION_GET_ERROR, 
    PURPOSE_OF_SELECTION_GET_BY_KEY_REQUEST, PURPOSE_OF_SELECTION_GET_BY_KEY_SUCCESS, PURPOSE_OF_SELECTION_GET_BY_KEY_ERROR, 
    PURPOSE_OF_SELECTION_ADD_REQUEST, PURPOSE_OF_SELECTION_ADD_SUCCESS, PURPOSE_OF_SELECTION_ADD_ERROR, 
    PURPOSE_OF_SELECTION_EDIT_REQUEST, PURPOSE_OF_SELECTION_EDIT_SUCCESS, PURPOSE_OF_SELECTION_EDIT_ERROR, 
    PURPOSE_OF_SELECTION_DELETE_REQUEST, PURPOSE_OF_SELECTION_DELETE_SUCCESS, PURPOSE_OF_SELECTION_DELETE_ERROR
} from "../constants/purposeOfSelectionConstants";

const initialState = {
    purposeOfSelections: [],
    purposeOfSelection: {},
    loading: false,
    success: null,
    error: null,
    purposeOfSelectionRequestedCount: 0,
};

export const purposeOfSelectionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PURPOSE_OF_SELECTION_GET_REQUEST:
            return {
                ...state,
                purposeOfSelectionRequestedCount: ++state.purposeOfSelectionRequestedCount,
                loading: true,
            };
        case PURPOSE_OF_SELECTION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                purposeOfSelections: action.payload,
                success: true,
            };
        case PURPOSE_OF_SELECTION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PURPOSE_OF_SELECTION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PURPOSE_OF_SELECTION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                purposeOfSelection: action.payload,
                success: true,
            };
        case PURPOSE_OF_SELECTION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PURPOSE_OF_SELECTION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PURPOSE_OF_SELECTION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PURPOSE_OF_SELECTION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PURPOSE_OF_SELECTION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PURPOSE_OF_SELECTION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PURPOSE_OF_SELECTION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PURPOSE_OF_SELECTION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PURPOSE_OF_SELECTION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PURPOSE_OF_SELECTION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
