import m_14_2145_colorized from '././pavlodar/KZ.М.14.2145.svg'
import m_14_2145 from '././pavlodar/KZ.М.14.2145 black.svg'
import t_14_1293_colorized from '././pavlodar/KZ.T.14.Е1213.svg'
import t_14_1293 from '././pavlodar/KZ.T.14.Е1213 black.svg'

export default [
  m_14_2145_colorized,
  m_14_2145,
  t_14_1293_colorized,
  t_14_1293
]
