export async function submitData(data, validate, setLoading, submit) {
    if (!await validate()) {
        return false;
    }

    setLoading(true);

    if (!await submit()) {
        await setLoading(false);

        return false;
    }

    await setLoading(false);

    return true;
}

export async function submitData2(data, validate, startSubmit, stopSubmit, submit) {
    if (!await validate()) {
        return false;
    }

    await startSubmit();

    if (!await submit()) {
        await stopSubmit();

        return false;
    }

    await stopSubmit();

    return true;
}