import { Autocomplete, Button, TextField } from "@mui/material"
import { useEffect, useState } from "react";

const RestAutocomplete = ({ restApi, query, items, id, label, value, onChange, onInputChange, onSearch, optionLabelKey, take, inputAdornment, ...props }) => {
    const [options, setOptions] = useState(items || []);
    const [text, setText] = useState("");
    const labelKey = optionLabelKey || "name";

    const searchAsync = async () => {
        if (!restApi)
              return;

        if (query?.paging)
            query.paging.take = take || 5000;

        const searchArgs = query || { paging: { skip: 0, take: take || 5000 } };

        searchArgs.filter = searchArgs.filter || {};
        if (text) {
            searchArgs.filter[labelKey] = {
                operand1: text,
                operator: 'startsWith'
            }
        }
        else {
            searchArgs.filter[labelKey] = null;
        }        
        
        const response = await restApi.search(searchArgs);
        await setOptions(response.result);
    };

    useEffect(() => {        
        async function search() {
            await searchAsync();
        }

        search();
    }, [restApi, query]);

    useEffect(() => {        
        setOptions([...options]);
    }, [value]);

    const findOption = (value) => {
        if (!value)
            return '';

        if (!options)
            return null;
        const result = options.find(_ => _.id == value);
        return result;
    }

    const findOptionName = (value) => {
        if (!value)
            return '';

        if (!options)
            return null;
        const result = options.find(_ => _.id == value);
        return props.getOptionLabel ? props.getOptionLabel(result) : result?.name;
    }

    const option = findOption(value);

    return (
        <>
        <Autocomplete
            id={id}
            disablePortal={true}
            value={findOption(value)}
            //inputValue={findOptionName(value)}
            inputValue={text || (option ? option[labelKey]: "")}
            onChange={(_, value) => {
                onChange(value);
            }}
            onInputChange={(e, value) => {
                if (value == 'undefined') {
                    return;
                }                      

                setText(value);
                onInputChange && onInputChange(value);
            }}
            noOptionsText='Не найдено'
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
            }}
            renderInput={params => <TextField {...params} label={label} 
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                    <>
                        {/* TODO иконка */}
                        <Button onClick={async () => await searchAsync()}>Поиск</Button>
                        {inputAdornment}
                        {params.InputProps.endAdornment}
                    </>
                    ),
                }} 
                />}            
            getOptionLabel={_ => _.name}
            options={options}
            {...props}
        />
        </>
    )
}

export default RestAutocomplete;