import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGenders } from "../../../redux/actions/genderActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditPatient({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [fullName, setFullName] = useState('');
    const [identityNumber, setIdentityNumber] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());
    const [yearsOld, setYearsOld] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [rpnId, setRpnId] = useState('');
    const [genderId, setGenderId] = useState(null);
    const { loading, patients, patient } = useSelector((state) => state.patient);
    const { genders } = useSelector((state) => state.gender);
    const emptyState = () => {
        setId(null);
        setFirstName('');
        setLastName('');
        setSecondName('');
        setFullName('');
        setIdentityNumber('');
        setBirthDate(new Date());
        setYearsOld(null);
        setPhoneNumber('');
        setRpnId('');
        setGenderId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, genderId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, genderId
        } = patient || {};
        setId(id);
        setFirstName(firstName);
        setLastName(lastName);
        setSecondName(secondName);
        setFullName(fullName);
        setIdentityNumber(identityNumber);
        setBirthDate(birthDate);
        setYearsOld(yearsOld);
        setPhoneNumber(phoneNumber);
        setRpnId(rpnId);
        setGenderId(genderId);
    }, [patient, patients])
    useEffect(() => {
        let response;
        const getGendersAsync = async () => {
            response = await dispatch(getGenders());
        }
        if (genders.length == 0)
        {
            getGendersAsync();
        }
    }, [dispatch, action])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={firstName} onChange={(e) => setFirstName(e.target.value)} fullWidth id="firstName" name="firstName" label="Имя" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth id="lastName" name="lastName" label="Фамилия" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={secondName} onChange={(e) => setSecondName(e.target.value)} fullWidth id="secondName" name="secondName" label="Отчество" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={fullName} onChange={(e) => setFullName(e.target.value)} fullWidth id="fullName" name="fullName" label="ФИО" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={identityNumber} onChange={(e) => setIdentityNumber(e.target.value)} fullWidth id="identityNumber" name="identityNumber" label="ИИН" />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                        <DatePicker views={['day']} label="Дата рождения" value={birthDate} onChange={(newValue) => {
                                            setBirthDate(newValue);
                                        }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                                            <TextField margin="normal"  {...params} helperText={null}/>
                                        }/>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={yearsOld} onChange={(e) => setYearsOld(e.target.value)} fullWidth id="yearsOld" name="yearsOld" label="Возраст" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <InputMask mask='+7 999 999 99 99' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}>
                                        {() =>
                                            <TextField id="phoneNumber" label="Телефон" name="phoneNumber" margin="normal" type="text"/>
                                        }
                                    </InputMask>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={rpnId} onChange={(e) => setRpnId(e.target.value)} fullWidth id="rpnId" name="rpnId" label="ID системы РПН" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={genderId} onChange={(e) => setGenderId(e.target.value)} margin="normal" fullWidth id="genderId" select>
                                        {genders && genders.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
