import { 
    REFERRAL_SPECIFICATION_GET_REQUEST, REFERRAL_SPECIFICATION_GET_SUCCESS, REFERRAL_SPECIFICATION_GET_ERROR, 
    REFERRAL_SPECIFICATION_GET_BY_KEY_REQUEST, REFERRAL_SPECIFICATION_GET_BY_KEY_SUCCESS, REFERRAL_SPECIFICATION_GET_BY_KEY_ERROR, 
    REFERRAL_SPECIFICATION_ADD_REQUEST, REFERRAL_SPECIFICATION_ADD_SUCCESS, REFERRAL_SPECIFICATION_ADD_ERROR, 
    REFERRAL_SPECIFICATION_EDIT_REQUEST, REFERRAL_SPECIFICATION_EDIT_SUCCESS, REFERRAL_SPECIFICATION_EDIT_ERROR, 
    REFERRAL_SPECIFICATION_DELETE_REQUEST, REFERRAL_SPECIFICATION_DELETE_SUCCESS, REFERRAL_SPECIFICATION_DELETE_ERROR
} from "../constants/referralSpecificationConstants";

const initialState = {
    referralSpecifications: [],
    referralSpecification: {},
    loading: false,
    success: null,
    error: null,
    referralSpecificationRequestedCount: 0,
};

export const referralSpecificationReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REFERRAL_SPECIFICATION_GET_REQUEST:
            return {
                ...state,
                referralSpecificationRequestedCount: ++state.referralSpecificationRequestedCount,
                loading: true,
            };
        case REFERRAL_SPECIFICATION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                referralSpecifications: action.payload,
                success: true,
            };
        case REFERRAL_SPECIFICATION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_SPECIFICATION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_SPECIFICATION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                referralSpecification: action.payload,
                success: true,
            };
        case REFERRAL_SPECIFICATION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_SPECIFICATION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_SPECIFICATION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_SPECIFICATION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_SPECIFICATION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_SPECIFICATION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_SPECIFICATION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_SPECIFICATION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_SPECIFICATION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_SPECIFICATION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
