import React from "react";

import { PageContainer, PageHeader } from "../../../components";
import { WithoutDepartureRequestCRUDTable } from "../../../components/CRUD/Tables";
import DashboardLayout from "../../../layouts/Dashboard";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";
import {contractRequestCRUD, withoutDepartureRequestCRUD} from "../../../http";
import useReload from "../../../hooks/useReload";
import {checkRoles} from "../../../functions/checkRoles";

export const WithoutDepartureRequestTable = () => {

    const { reload, reloadValue } = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление заявки без выезда, ID:' + _, 'Вы действительно хотите удалить эту сводную заявку?', async () => {
            await withoutDepartureRequestCRUD.delete(_);
            await reload();
        })
    }

    return (
        <DashboardLayout>
            <PageHeader heading="Заявки без выезда" />
            <PageContainer>
                <WithoutDepartureRequestCRUDTable reloadValue={reloadValue} onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null}/>
            </PageContainer>
        </DashboardLayout>
    )
};
