import AnObjectCRUDTable from "../CRUD/Tables/AnObjectCRUDTable";
import SimpleTable from "../SimpleTable";
import {Button, Dialog, DialogContent, Divider} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";

import useCreateObjectForm from "../../models/Object/useCreateObjectForm";
import {useController} from "react-hook-form";

export default function AnObjectField({value, onChange, disabled, label, bold = true}) {
    const [open, setOpen] = useState(false);
    const {openCreateObjectForm, DIALOG} = useCreateObjectForm();

    return (
        <>
            <DIALOG/>
            <Grid container spacing={1}>
                {/* Field Line */}
                <Grid item xs={12}>
                    <Toolbar>
                        <Typography variant={bold && "h6"}>
                            {label || 'Объект'} {!value && "не выбран    "}
                        </Typography>
                        <Button onClick={() => setOpen(true)} disabled={disabled} variant={"outlined"}
                                style={{marginLeft: "15px"}}>
                            Выбрать
                        </Button>
                        <Button
                            onClick={() => openCreateObjectForm({afterSubmit: onChange})}
                            style={{marginLeft: "15px"}}
                            variant={"outlined"}
                            disabled={disabled}
                        >
                            + Создать объект
                        </Button>
                    </Toolbar>
                </Grid>

                {/* Info Line */}
                <Grid container spacing={1} item xs={12}>
                    <SimpleTable
                        data={value || {}}
                        columns={[
                            {label: "2.1 Наименование объекта", value: value?.name || "-"},
                            {label: "2.2 БИН/ИИН объекта", value: value?.bin || "-"},
                            {
                                label: "2.3 Организационно-правовая форма",
                                value: value?.targetObjectLegalPersonType?.name || "-",
                            },
                            {label: "2.4 Адрес", value: value?.addressLine || "-"},
                            {
                                label: "3.1 Раздел объекта",
                                code: "description",
                                value: value?.section?.name || "-",
                            },
                            {
                                label: "3.2 Подраздел объекта",
                                code: "description",
                                value: value?.subSection?.name || "-",
                            },
                            {label: "3.3 Объект", code: "description", value: "-"},
                        ]}
                    />
                </Grid>
            </Grid>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор объекта</DialogTitle>
                <Divider/>
                <DialogContent>
                    <AnObjectCRUDTable
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            onChange(record);
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{marginLeft: "auto"}}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
