import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

DirectionPeoplePDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data
  const schema = [
    {
      key: '1. Сынаманың атауы (Наименование пробы)',
      value: actData?.sampleName?.value
    },
    {
      key: '2. Зерттелушінің Т.А.Ә. (болған жағдайда) (Ф.И.О. (при наличии) обследуемого)',
      value: actData?.examineName?.value
    },
    {
      key: '3. Жасы (Возраст)',
      value: actData?.age?.value
    },
    {
      key: '4. Зерттелушінің ЖСН (ИИН обследуемого)',
      value: actData?.IIN?.value
    },
    {
      key: '5. Мекен жайы (Домашний адрес)',
      value: actData?.homeAdress?.value
    },
    {
      key: '6. Жұмыс орны (Место работы)',
      value: actData?.workPlace?.value
    },
    {
      key: '7. Диагнозы (Диагноз)',
      value: actData?.diagnosis?.value
    },
    {
      key: '8. Үлгіні жіберген мекеменің атауы (Наименование учреждения направившего образец)',
      value: actData?.placeSender?.value
    },
    {
      key: '9. Наманың алынған күні және уақыты (Дата и время забора материала)',
      value: actData?.takeDateTime?.value ? formatTime(new Date(actData?.takeDateTime.value)) : ' '
    },
    {
      key: '10. Антибиотикті қабылдау (Применение антибиотиков)',
      value: actData?.antibioticsInfo?.value
    },
    {
      key: '11. Қаны бірінші рет/қайталап алынды (Кровь взято первично/повторно)',
      value: actData?.bloodInfo?.value
    },
    {
      key: '12. Сынамаларды зерттеу мақсаты (Цель исследования пробы)',
      value: actData?.researchPurpose?.value
    },
    {
      key: '13. Материалды жеткізу күні және уақыты (Дата и время доставки материала)',
      value: actData?.deliveryDateTime?.value ? formatTime(new Date(actData?.deliveryDateTime.value)) : ' '
    },
    {
      key: '14. Тасымалдау жағдайы (Условия транспортировки)',
      value: actData?.transportationCondition?.value
    },
    {
      key: '15. Ауырған күні (Дата заболевания)',
      value: actData?.sickDate?.value ? formatDate(new Date(actData?.sickDate.value)) : ' '
    },
    {
      key: '16. Қай елден келді (Страна прибытия)',
      value: actData?.arrivingLand?.value
    },
    {
      key: '17. Ауырған адаммен байланыс (Контакт с больным)',
      value: actData?.sickContact?.value
    },
    {
      key: '18. Симптомдары (Симптомы)',
      value: actData?.symptoms?.value
    },
    {
      key: '19. Сынаманың жіберу күні және уақыты (Дата и время направление материала)',
      value: actData?.directionDate?.value ? formatTime(new Date(actData?.directionDate.value)) : ' '
    },
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Table style={styles.table}>
          <Row>
            <Cell></Cell>
            <Cell>
              <Text>Нысанның БҚСЖ бойынша коды</Text>
              <Text>Код формы по ОКУД ___________________</Text>
              <Text>КҰЖЖ бойынша ұйым коды</Text>
              <Text>Код организации по ОКПО _____________</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрлігі Министерство здравоохранения Республики Казахстан</Text>
            </Cell>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы "__" __________ № бұйрығымен бекітілген №____020/е нысанды медициналық құжаттама</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк органының атауы Наименование государственного органа санитарно-эпидемиологической службы</Text>
            </Cell>
            <Cell>
              <Text>Медицинская документация Форма № 020/у Утверждена приказом Министра здравоохранения Республики Казахстан от "__" ______ 2021 года №______</Text>
            </Cell>
          </Row>
        </Table>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {actData?.dateOfDirection?.value ? formatDate(new Date(actData.dateOfDirection.value)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Сынаманы қабылдаған адамның қолы (Подпись принявшего анализ):</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зерттеу жүргiзген маманның Т.А.Ә.(болған жағдайда), лауазымы (Ф.И.О.(при наличии), должность специалиста проводившего исследование): {data.performer?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зертхана меңгерушісінің Т.А.Ә. (болған жағдайда), қолы. (Ф.И.О.(при наличии), подпись заведующего лабораторией): {data.responsible?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
          <Text style={styles.string}>Направление составлено в экземплярах(кол-во) _____</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function DirectionPeoplePDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
