import React, {useEffect, useMemo, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadingLayout from "../../../layouts/LoadingLayout";

import {
  workflowDesinsectionRequest,
} from "../../../redux/actions";
import {
  PageHeader,
} from "../../../components";
import { DDDRequestInfoSection } from "./Sections";
import {
  getDesinsectionRequestByKey,
} from "../../../redux/actions/";

import {renderControls, RequestStateChecker} from "./../../../functions";
import {DDDRequestCRUD} from "../../../http";
import {CONTRACT_REQUEST, DDD_REQUEST, DEPARTURE_SCHEDULE, KDI_REQUEST} from "../../../routes";
import {REQUEST_STATE_ID_CREATED} from "../../../redux/constants/requestStateConstants";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";

export const DDDRequestInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false);

  const [requestId, setRequestId] = useState(id);
  const [stateId, setStateId] = useState(0);

  const { desinsectionRequest } = useSelector((s) => s.desinsectionRequest);

  const stateCode = desinsectionRequest?.state?.code?.toLowerCase();

  const state = useMemo(
      () => new RequestStateChecker(desinsectionRequest?.state?.code),
      [desinsectionRequest?.state?.code]
  );

  const [request, setRequest] = useState({});

  const handleWorkflow = async (e, nextStateId) => {
    e.preventDefault();
    setLoading(true);

    if (!desinsectionRequest?.state?.id) return setLoading(false);

    const prevStateId = desinsectionRequest.state.id;

    // Updating Workflow
    const updated = await dispatch(
      workflowDesinsectionRequest(id, prevStateId, nextStateId)
    );
    if (!updated) return;

    setStateId(nextStateId);
    setLoading(false);
  };

  const handleWorkflowDeclinedToCreate = async () => {
    setLoading(true);

    if (!desinsectionRequest?.state?.id) return setLoading(false);

    const prevStateId = desinsectionRequest.state.id;
    const nextStateId = state.getId(REQUEST_STATE_ID_CREATED);

    // Updating Workflow
    const updated = await dispatch(
        workflowDesinsectionRequest(requestId, prevStateId, nextStateId)
    );
    if (!updated) return setLoading(false);

    setStateId(nextStateId);
    setLoading(false);
  };


  const handleEdit = async () => {
    await DDDRequestCRUD.edit(request)
  }

  const controls = [
    {
      state: "created",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 2)}
        >
          Начать обработку
        </LoadingButton>,
        <Button
            color="secondary"
            onClick={handleEdit}
        >
          Сохранить
        </Button>,
        <Button
            variant={"text"}
            onClick={() => history.push(DDD_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "processing",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 3)}
        >
          Начать исследование
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(DDD_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "research",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 4)}
        >
          Завершить
        </LoadingButton>,
        <LoadingButton color="warning" onClick={(e) => handleWorkflow(e, 5)}>
          Отклонить с комментариями
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(DDD_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "completed",
      roles: [],
      buttons: [
        <Button
            variant={"text"}
            onClick={() => history.push(DEPARTURE_SCHEDULE)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "declined",
      roles: [],
      buttons: [
        <LoadingButton
            loading={loading}
            onClick={() => {
              confirmDialog('Пересоздание', 'Вы действительно хотите пересоздать заявку?', async () => {
                await handleWorkflowDeclinedToCreate()
              })
            }
            }
        >
          Пересоздать
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    }
  ];

  useEffect(async () => {
    setLoading(true);

    const data = await dispatch(getDesinsectionRequestByKey(requestId));
    setRequest(data)
    setLoading(false);
  }, [dispatch, requestId, stateId]);

  return (
    <LoadingLayout loading={loading}>
      <PageHeader
        heading={`ДДД заявка №${id} ${
          stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
        }`}
        status={desinsectionRequest.state?.name}
        controls={renderControls(controls, {
          state: stateCode,
          roles: [],
        })}
      />
      <DDDRequestInfoSection setRequest={setRequest} request={request}/>
    </LoadingLayout>
  );
};
