import React, { useEffect, useMemo, useState } from 'react';
import { Loader, PageContainer, PageHeader } from "../../../components";
import { Button, Grid } from "@mui/material";
import { OBJECT_REFERRAL_CREATE, PRESCRIPTION, PRESCRIPTION_EDIT_ROUTE } from "../../../routes";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/Dashboard";
import RemoveIcon from '@mui/icons-material/Remove';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import { FormProvider } from "../../../components/hook-form";
import { useForm, useWatch } from "react-hook-form";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import useReload from "../../../hooks/useReload";
import LoadingButton from "@mui/lab/LoadingButton";
import RHFDate from "../../../components/hook-form/RHFDate";
import AnObjectField from "../../../components/Relations/AnObjectField";
import { FilialDivisionSelect, FilialSelect, OrderTypeSelect } from "../../../components/Select/domainSelects";
import EmployeeField from "../../../components/Relations/DoctorField";
import { $s3host, postForDownloadFileS3, prescriptionCRUD } from "../../../http";
import { useSelector } from "react-redux";
import useLoader from "../../../hooks/useLoader";
import { RHFUploadSingleFile } from "../../../components/hook-form/RHFUpload";
import Typography from "@mui/material/Typography";
import RHFIndicator from "../../../components/hook-form/RHFIndicator";
import { yupResolver } from "@hookform/resolvers/yup";
import { prescriptionSchema } from "../../../models/schemas/prescriptionSchema";
import { Document, Page } from "react-pdf";
import { REQUEST_STATE_ID_CREATED } from "../../../redux/constants/requestStateConstants";
import { asJSONSafe, renderControls, RequestStateChecker, State } from "../../../functions";
import { controlsKSEK } from "../../../sections/Header/KSESHeader";
import { useTranslation } from "react-i18next";
import RHFGovernment from "../../../components/hook-form/RHFGovernment";
import { ORGANIZATION_ID_NCE } from '../../../redux/constants/organizationConstants';
import useRoles from '../../../hooks/useRoles';
import RHFActField from '../../../components/hook-form/RHFActField';
import RHFTechnicalRegulations from '../../../components/hook-form/RHFTechnicalRegulations';
import { normalizeIndicators } from '../../../functions/normalization';

function PrescriptionCreate(props) {
    const { push } = useHistory();
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    const defaultValues = useMemo(() => ({
        targetDate: new Date(),
        createdAt: new Date(),
        number: '',
        acts: [],
        indicators: [],
        targetObjectId: '',
        targetObject: {},
        orderTypeId: "",
        standards: [],
        createdBy: {},
        createdById: '',
        filialId: "",
        filialDivisionId: "",
    }), []);
    const { reload, reloadValue } = useReload();
    const { id } = useParams();
    const isEditing = useMemo(() => id, [id]);
    const [isCreated, setIsCreated] = useState(true)
    const [recreated, setRecreated] = useState(false);
    const { t } = useTranslation();

    const disabled = (!isCreated && isEditing)
    const [file, setFile] = useState(null)
    const [fileSee, setFileSee] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { employee } = useRoles();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const handleGenerateObjectReferral = async () => {
        debugger;
        const objectReferralData = {
            filialId: value.filialId,
            filialDivisionId: value.filialDivisionId,
            createdById: value.createdById,
            departmentId: value.departmentId,
            prescriptionId: value.id,
            indicators: value.indicators,
        }


        push({
            pathname: OBJECT_REFERRAL_CREATE,
            state: {data: objectReferralData}
        })
    }


    const methods = useForm({
        defaultValues,
        resolver: yupResolver(prescriptionSchema)
    });
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(false);

    const {
        handleSubmit,
        setValue, watch,
        control,
        reset,
        formState: { isSubmitting },
    } = methods;

    const value = watch()

    const state = useMemo(
        () => new RequestStateChecker(value?.state?.code),
        [value?.state?.code]
    );
    const stateCode = state?.stateCode?.toLowerCase();

    useEffect(() => {
        (async function () {
            if (isEditing) {
                fetchStart()
                const res = await prescriptionCRUD.get(id);
                setIsCreated(res?.state?.code === REQUEST_STATE_ID_CREATED)
                setRecreated(res.recreated);
                // const { bucketName, key, objectName } = JSON.parse(res.acts[0].actPath)
                // try {
                //     const response = await postForDownloadFileS3({
                //         bucketName,
                //         key,
                //         objectName
                //         //}, `https://ekpo.nce.kz/s3/api/v1/file/download`)
                //     }, `${$s3host.defaults.baseURL}api/v1/file/download`)
                //     const file = new Blob([response.data], { type: 'application/pdf' });
                //     const fileURL = URL.createObjectURL(file);
                //     setFile(fileURL)
                // } catch (e) {
                //     console.error(e);
                // }
                // setFileSee(true)
                // const acts = res?.acts.map(el => ({ actId: el.actId, ...el?.act }))
                reset({ ...res, indicators: normalizeIndicators(asJSONSafe(res?.indicators)), standards: asJSONSafe(res?.standards), regulationOfCUs: asJSONSafe(res?.regulationOfCUs || []), createdBy: authorizedEmployee, createdById: authorizedEmployee.id });
                fetchStop()
            }
        }())
    }, [reloadValue]);


    React.useEffect(() => {
        if (authorizedEmployee) {
            setValue('createdBy', authorizedEmployee)
            setValue('createdById', authorizedEmployee.id)
        }
    }, [authorizedEmployee, authorizedEmployee?.id])

    const onSubmit = async state => {
        debugger;
        let acts = [];
        if (!isEditing) {
            for (let el of state.acts) {
                let formData = new FormData()
                formData.append("file", el)
                const size = el.size
                const { data: s3Path } = await $s3host.post("/api/v1/file/upload?userName=user1&path=prescription&size=" + size, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                acts.push({ actPath: s3Path })
            }
        }
        if (isEditing) {
            const data = { ...state }
            delete data.acts;
            await prescriptionCRUD.edit({ ...data, id: +id, indicators: JSON.stringify(state.indicators), standards: JSON.stringify(state?.standards) })
        } else {
            const { id } = await prescriptionCRUD.create({ ...state, indicators: JSON.stringify(state.indicators), standards: JSON.stringify(state?.standards), acts })
            return push(PRESCRIPTION_EDIT_ROUTE(id));
        }

    }
    const targetObject = useWatch({
        control,
        name: "targetObject",
    });


    const orderTypeId = useWatch({
        control,
        name: "orderTypeId",
    });

    const createdBy = useWatch({
        control,
        name: "createdBy",
    });

    const filialId = useWatch({
        control,
        name: "filialId",
    });
    const filialDivisionId = useWatch({
        control,
        name: "filialDivisionId",
    });


    return (
        <DashboardLayout>
            <PageHeader controls={renderControls(controlsKSEK(value, reload, push, prescriptionCRUD, null, handleGenerateObjectReferral, 'Prescription'), {
                state: stateCode,
                roles: [],
            })} heading={isEditing ? `Предписание #${value?.number || ""} ${stateCode === State.Processsing ? '(отправлено в НЦЭ)' :
                    stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
                }` : "Предписание: Создание"}>

            </PageHeader>
            <PageContainer>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingBottom: "20px",
                        }}
                    >
                        <p>
                            Приложение 4 к правилам проведения санитарно-эпидемиологической
                            экспертизы
                        </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <p>Форма</p>
                    </div>
                    <h4 style={{ textAlign: "center", fontWeight: '700' }}>
                        Предписание о проведении санитарно-эпидемиологической экспертизы
                    </h4>
                    {employee &&
                        <Grid container>
                            <FilialSelect
                                style={{ marginBottom: "5px" }}
                                label={"Филиал пользователя"}
                                fullWidth
                                disabled={true}
                                value={employee.filialId}
                            />
                            {employee.filialDivisionId && <FilialDivisionSelect
                                style={{ marginBottom: "5px" }}
                                label={"Районное отделение пользователя"}
                                fullWidth
                                disabled={true}
                                value={employee.filialDivisionId}
                            />}
                        </Grid>
                    }
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <RHFTextField disabled={disabled} label="№" name="number" />
                        </Grid>
                        <Grid item xs={6}>
                            <RHFDate disabled={disabled} label="Дата" name="createdAt" />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Дата назначения</p>
                            <RHFDate disabled={disabled} style={{ width: "50%", marginLeft: "30px" }} label="Дата назначения"
                                name="targetDate" />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <AnObjectField disabled={disabled}
                                label="Наименование проверяемого субъекта"
                                value={targetObject}
                                onChange={(obj) => {
                                    setValue('targetObject', obj)
                                    setValue('targetObjectId', obj.id)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>
                                Должностное лицо санитарно-эпидемиологической службы
                            </p>
                            <RHFTextField disabled={disabled} style={{ width: "50%", marginLeft: "30px" }} label="ФИО"
                                name="responsibleFullName" />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Grid item xs={12} style={{overflowX: "auto"}}>
                                <RHFActField disabled={disabled} label="Акты" reload={reload}>
                        </RHFActField>
                            </Grid>
                            {!isEditing ? <RHFUploadSingleFile name="acts"/> : fileSee ? <div className="document-pdf">
                                {file && <Document

                                    file={file}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                >
                                    <Page pageNumber={pageNumber}/>
                                </Document>}
                                <div className="document-pdf__buttons">
                                    <Button disabled={pageNumber === 1} onClick={() => setPageNumber(prev => prev - 1)}
                                            variant="outlined" startIcon={<RemoveIcon/>}>
                                        Предыдущая страница
                                    </Button>
                                    <Button disabled={pageNumber === numPages}
                                            onClick={() => setPageNumber(prev => prev + 1)} variant="contained"
                                            sx={{marginLeft: '10px'}} endIcon={<PlusOneIcon/>}>
                                        Следующая страница
                                    </Button>
                                </div>
                                <p>Страница {pageNumber} из {numPages}</p>
                            </div> : <Loader/>}
                        </Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Установил (указать характер складывающейся
                                санитарно-эпидемиологической ситуации на
                                территории, группе объектов, нарушения законодательства Республики Казахстан в области
                                санитарно-эпидемиологического благополучия населения)
                            </p>
                            <RHFTextField disabled={disabled} style={{ width: "50%", marginLeft: "30px" }} label="Характер ситуации"
                                name="establishedSituationDescription" />
                        </Grid>


                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>
                                Основание вынесения Предписания
                            </p>
                            <OrderTypeSelect
                                disabled={disabled}
                                fullWidth
                                formStyle={{ width: "50%", marginLeft: "30px" }}
                                required={false}
                                label="Тип заказа"
                                value={orderTypeId}
                                onChange={(val) => setValue('orderTypeId', val)}
                            />
                        </Grid>
                        {orderTypeId == 11 && <Grid item xs={12} style={{ overflowX: "auto" }}>
                            <RHFActField disabled={disabled} label="Акты" reload={reload}>
                            </RHFActField>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <EmployeeField
                                disabled
                                label={"Создал заявку"}
                                fullWidth
                                object={createdBy}
                                value={createdBy?.id}
                                onChange={(obj) => {
                                    setValue('createdBy', obj)
                                    setValue('createdById', obj.id)
                                }}
                            />
                        </Grid>
                        <h6 style={{ fontWeight: "bold" }}>Постановил</h6>
                        <Grid item xs={12}>
                            <FilialSelect
                                disabled={disabled}
                                label={"Филиал"}
                                organizationId={ORGANIZATION_ID_NCE}
                                fullWidth
                                value={filialId}
                                onChange={(val) => {
                                    setValue('filialId', val)
                                    setValue('filialDivisionId', Number(val) !== Number(filialId)
                                        ? null
                                        : filialDivisionId)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FilialDivisionSelect
                                disabled={disabled}
                                label={"Наименование отделения"}
                                organizationId={ORGANIZATION_ID_NCE}
                                fullWidth
                                value={filialDivisionId}
                                filialId={filialId}
                                onChange={(filialDivisionId, filialDivision) => {
                                    setValue('filialDivisionId', filialDivisionId)
                                    setValue('filialId', Number(filialDivision.filialId) !==
                                        Number(filialId)
                                        ? filialDivision.filialId
                                        : filialId)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Документ санитарно-эпидемиологического нормирования (на
                                соответствие, которого проводится исследование (ГОСТ)
                            </p>
                            <RHFGovernment disabled={disabled} />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Документ санитарно-эпидемиологического нормирования (на
                                соответствие, которого проводится исследование (ТР/ТС)
                            </p>
                            <RHFTechnicalRegulations disabled={disabled} />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <RHFIndicator disabled={disabled} />
                        </Grid>
                    </Grid>
                    <LoadingButton disabled={disabled} loading={isSubmitting} type="submit" variant="contained"
                        sx={{ mt: 3, mb: 2, alignSelf: 'flex-end' }}>
                        {isEditing ? 'Сохранить' : 'Добавить'}
                    </LoadingButton>
                </FormProvider>
            </PageContainer>
        </DashboardLayout>
    );
}

export default PrescriptionCreate;
