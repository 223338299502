import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { HighlightOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import { Dialog, DialogContent, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import ReferralSpecificationCRUDTable from "../CRUD/Tables/ReferralSpectificationCRUDTable";
import ServiceCRUDTable from "../CRUD/Tables/ServiceCRUDTable";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DepartmentSelect } from "../Select/domainSelects";
import { SERVICE_TYPE_GOVERNMENT } from "../../redux/constants/serviceTypeConstants";
import useReload from "../../hooks/useReload";
import { LoadingButton } from "@mui/lab";
import { NumberField2 } from "../Fields/NumberField2";

const ResearchListFieldNoInp = ({ label, value, error, onChange, disabled, shouldSubmit, submitLoading, onSubmit, type = SERVICE_TYPE_GOVERNMENT }) => {
  const deleteColumnStyles = {
      position: "sticky",
      right: 0,
    },
    cellHeadStyles = { fontWeight: "bold", width: '70px' };

  const [open, setOpen] = useState(false);

  const { reload, reloadValue } = useReload();
  const [extraFilter, setExtraFilter] = useState({
    typeId: { operand1: type, operator: "equals" },
  });

  useEffect(() => {
    setExtraFilter({
      ...extraFilter,
    });
  }, []);
  useEffect(() => {
    reload();
  }, [extraFilter]);

  return (
    <Grid container spacing={2} sx={{border: error && '1px dashed red'}}>
      <Grid item xs={12} style={{ overflowX: "auto" }}>
        <Typography color={"textSecondary"}>
          {label || "17. Список исследований"}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell  size="small" variant={"head"} style={cellHeadStyles}>
                Номер
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Код тарификатора
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Наименование
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Наименование лаборатории
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Наименование отдела
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Группа исследования
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Подгруппа исследования
              </TableCell>
              <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                Метод исследования
              </TableCell>
              <TableCell style={deleteColumnStyles} />
            </TableRow>
          </TableHead>
          <TableBody>
            {(value || []).map((item, i) => {
              const handleChange = (key, val) => {
                value[i][key] = val;
                onChange([...value]);
              };
              let prices = item.service?.prices || [];
              let priceItem = prices.length > 0 ? prices[0] : null;

              // item.price = 0;
              // item.weightCoefficient = 0;
              if (priceItem) {
                if (!item.price) item.price = priceItem.price;
                if (!item.weightCoefficient)
                  item.weightCoefficient = priceItem.costCoefficient;
              }

              return (
                <TableRow>
                  <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                    {i + 1}
                  </TableCell>{" "}
                  {/*№ n/n                                */}                  
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.service?.code}
                  </TableCell>{" "}
                  {/*Код тарификатора                     */}
                  {/* <TableCell size="small" variant={"head"} style={cellHeadStyles}>
                    {item.service?.name}
                  </TableCell>{" "} */}
                  {/*Наименование исследование            */}
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.service?.name || 'Не выбрано'}
                  </TableCell>{" "}
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.department?.name || 'Не выбрано'}
                  </TableCell>{" "}
                  {/*Наименование лаборатории             */}
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.division?.name || 'Не выбрано'}
                  </TableCell>{" "}
                  {/*Наименование отдела                  */}
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.service?.group?.name || 'Не выбрано'}
                  </TableCell>{" "}
                  {/*Группа исследования                  */}
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.service?.subGroup?.name || 'Не выбрано'}
                  </TableCell>{" "}
                  {/*Подгруппа исследования               */}
                  <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.service?.kind?.name || 'Не выбрано'}
                  </TableCell>{" "}
                  {/*Метод исследования                   */}
                  {/* <TableCell variant={"head"} style={cellHeadStyles}>
                    {item.service?.unit?.name || 'Не выбрано'}
                  </TableCell>{" "} */}
                  <TableCell align={"right"} style={deleteColumnStyles}>
                    <IconButton
                      onClick={() => {
                        value.splice(i, 1);
                        onChange([...value]);
                      }}
                    >
                      <HighlightOff />
                    </IconButton>
                  </TableCell>

                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <Button onClick={() => setOpen(true)} disabled={disabled}>
          Добавить
        </Button>
        {shouldSubmit && <LoadingButton loading={submitLoading} onClick={onSubmit} style={{"margin-left": "15px"}}>
            Сохранить исследования
        </LoadingButton>}
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"80%"}
      >
        <DialogTitle>Выбор</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ServiceCRUDTable
                isTypeGovernment={true}
                searchByIdHide={true}
                reloadValue={reloadValue}
                extraFilter={extraFilter}
                onClickCreateButton={undefined} // что бы не показывать кнопку создания
                onRowClick={(record, rowIndex) => {
                  let specification = {
                    service: record,
                    department: record.department,
                    division: record.division,

                    serviceId: record.id,
                    departmentId: record.departmentId,
                    divisionId: record.divisionId,
                    price: record.price,
                    weightCoefficient: record.costCoefficient || 0,
                    quantity: record.quantity,
                    inconsistenceQuantity: record.inconsistenceQuantity,
                  };
                  if (onChange) onChange([...(value || []), specification]);
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          {
            <Button
              variant={"text"}
              style={{ marginLeft: "auto" }}
              onClick={() => setOpen(false)}
              disabled={disabled}
            >
              Отменить
            </Button>
          }
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default ResearchListFieldNoInp;
