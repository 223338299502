export const ncaLayerState = {
    undefined: "undefined",
    connected: "connected",
    disconnected: "disconnected",
    connectionError: "connectionError"
}

export function ncaLayer(port) {
    this.storage = 'PKCS12';
    this.state = ncaLayerState.undefined;

    if (!port)
        port = 13579;
    this.port = port;

    var that = this;


    this.webSocket = new WebSocket('wss://127.0.0.1:' + port);
    this.webSocket.onopen = function (event) {
        that.onopen();
    };
    this.webSocket.onclose = function (event) {
        if (event.wasClean) {
            console.log('connection has been closed');
        } else {
            that.oncloseerror(event);
        }        
        that.onclose(event);
    };
    this.webSocket.onmessage = function (event) {
        var result = JSON.parse(event.data);

        if (result != null) {
            var rw = {
                code: result['code'],
                message: result['message'],
                responseObject: result['responseObject'],
                getResult: function () {
                    return this.result;
                },
                getMessage: function () {
                    return this.message;
                },
                getResponseObject: function () {
                    return this.responseObject;
                },
                getCode: function () {
                    return this.code;
                }
            };
            that.onmessage(rw);
        }
        // console.log(event);
    };

    this.onopen = function (event) {
        console.log("Connection opened");
        this.state = ncaLayerState.connected;
        this.onreadyState();
    }

    this.onclose = function (event) {
        console.log('Code: ' + event.code + ' Reason: ' + event.reason);
        if (this.state == ncaLayerState.connectionError)
            return;
        this.state = ncaLayerState.disconnected;
        this.onreadyState();
    }

    this.oncloseerror = function (event) {
        console.log('Connection error');
        this.state = ncaLayerState.connectionError;
        this.onreadyState();
    }

    this.onreadyState = function () {
        this.onready && this.onready(this.state);
    }

    this.onmessage = function (msg) {
    }

    this.connect = function() {
    }

    this.close = function () {
        this.webSocket?.close();
        this.webSocket = null;
    }

    this.getKeyInfo = function() {
        var getKeyInfo = {
            "module": "kz.gov.pki.knca.commonUtils",
            "method": "getKeyInfo",
            "args": [this.storage]
        };
        this.webSocket.send(JSON.stringify(getKeyInfo));
    }

    this.signXml = function(storageName, keyType, xmlToSign) {
        var signXml = {
            "module": "kz.gov.pki.knca.commonUtils",
            "method": "signXml",
            "args": [storageName, keyType, xmlToSign, "", ""]
        };
        this.webSocket.send(JSON.stringify(signXml));
    }

    this.signXmlCAdES = function (storageName, keyType, base64ToSign) {
        var signCAdES = {
            "module": "kz.gov.pki.knca.commonUtils",
            "method": "createCAdESFromBase64",
                "args": [storageName, keyType, base64ToSign, false]
        };
        this.webSocket.send(JSON.stringify(signCAdES));
    }
};