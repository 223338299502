import React from "react";
import Typography from "@mui/material/Typography";
import {
    Grid,
} from "@mui/material";
import {Collapse} from "antd";
import {formatDate} from "../functions/formatters";

import ResultCRUDTable from "../components/CRUD/Tables/ResultCRUDTable";
import { distinct } from "../models/data/EntityCollectionModel";
import { confirmDialog } from "../components/Dialog/DialogDelete";
import { resultCRUD } from "../http";
import { protocolCRUD } from "../http";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { PROTOCOL_STATE_IDS_CREATED } from "../redux/constants/protocolConstants";

import useAlert from "../hooks/useAlert";
import { protocolStateIds } from "../models/protocolStateIds";

export default function ResultsAndProtocolsSection({ resolution, results, protocols, onResultClick, onProtocolClick, resultsReloadValue, fetch, hasProtocols,
    startLoading, stopLoading, readonly }) {
    results = results || [];
    protocols = protocols || [];

    let formIds = results.map(_ => _.form?.id).filter(_ => _ != null);
    formIds = distinct(formIds, (a, b) => a == b);

    const alert = useAlert(false, 1);

    const handleWillDeleteProtocol = async (protocolId, formNumber) => {
        confirmDialog('Удаление протокола', 'Вы действительно хотите удалить протокол форма ' + formNumber + '?', async () => {
          await startLoading();
          try {
            await protocolCRUD.delete(protocolId);
          } catch (e) {
            console.log(e);
            alert.show(0, 'Не удалось удалить, ошибка ' + e);
          }
          await stopLoading();
          await fetch();
        });
    }

    return (
        <Grid item xs={12}>
            <Collapse onChange={() => {
            }}>
                {!resolution ?
                    <Collapse.Panel
                    header={
                        <Typography variant={"h6"}
                                    style={{display: "inline"}}>
                            Записи в журнале ({results.length})
                        </Typography>
                    }
                    key="1"
                >
                        <Grid container spacing={1}>
                    {formIds?.map(_ => {
                        const resultsByFormId = results.filter(i => i.form?.id === _);
                        const first = resultsByFormId.length > 0 ? resultsByFormId[0] : null;
                        const data = {
                            result: resultsByFormId,
                            total: resultsByFormId.length
                        }
                        return (
                            <>
                                <Grid item xs={12}>
                                    {first?.form?.number}
                                </Grid>
                                <Grid item xs={12} style={{overflowX: "auto"}}>
                                    <ResultCRUDTable resultFormId={_} resultForm={first?.form} data={data}
                                        onClickEditButton={!readonly && !hasProtocols ? async (_) => await onResultClick(_) : null}
                                        onClickDetailsButton={null}
                                        onClickDeleteButton={!readonly && !hasProtocols ? (async (_) => {
                                            await confirmDialog('Удаление записи журнала, ID:' + _, 'Вы действительно хотите удалить запись из журнала без возможности восстановления?', async () => {
                                                await resultCRUD.delete(_);
                                                await fetch();
                                            })
                                        }) : null}
                                        //onRowClick={async (_) => await onResultClick(_.id)}
                                        reloadValue={resultsReloadValue}
                                        pagination={false}
                                        />
                                </Grid>
                            </>
                        )
                    })}
                    </Grid>
                    {results?.map((_) => (
                        <div>
                            <a
                                href
                                onClick={async () => {
                                    if (hasProtocols)
                                        return;
                                    await onResultClick(_.id);
                                }}
                            >
                                {_.id}) {_.form?.number}
                            </a>
                        </div>
                    ))}
                </Collapse.Panel> : null}
                <Collapse.Panel
                    header={
                        <Typography variant={"h6"}
                                    style={{display: "inline"}}>
                            Протокол ({protocols.length})
                        </Typography>
                    }
                    key="2"
                >
                    {protocols?.map((protocol, i) => {
                        return (
                            <div>
                            <a
                                href
                                onClick={async () => {
                                    // debugger;
                                    // if (protocol.stateId == protocolStateIds.signed) {
                                    //     alert.show(0, 'Запрещается редактировать подписанный протокол');
                                    //     return;
                                    // }
                                    await onProtocolClick(protocol.id);
                                }}
                            >
                                {protocol.number} (форма {protocol.form?.number}, журнал {protocol.resultId}) от {formatDate(protocol.createdAt)}
                            </a>
                            {!readonly && <a href style={{position: 'relative'}}
                                onClick={async () => {
                                    await handleWillDeleteProtocol(protocol.id, protocol.form?.number);
                                }}
                            >
                                {protocol.stateId == PROTOCOL_STATE_IDS_CREATED &&
                                    <DeleteForeverIcon style={{fontSize: '1.5rem', position: 'absolute', top: '-3px', left: '3px'}} onClick={() => true}/>}
                            </a>}
                            </div>
                        );
                    })}
                </Collapse.Panel>
            </Collapse>
        </Grid>
    )
}
