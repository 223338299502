import { 
    CONTRACT_ADDITIONAL_AGREEMENT_GET_REQUEST, CONTRACT_ADDITIONAL_AGREEMENT_GET_SUCCESS, CONTRACT_ADDITIONAL_AGREEMENT_GET_ERROR, 
    CONTRACT_ADDITIONAL_AGREEMENT_GET_BY_KEY_REQUEST, CONTRACT_ADDITIONAL_AGREEMENT_GET_BY_KEY_SUCCESS, CONTRACT_ADDITIONAL_AGREEMENT_GET_BY_KEY_ERROR, 
    CONTRACT_ADDITIONAL_AGREEMENT_ADD_REQUEST, CONTRACT_ADDITIONAL_AGREEMENT_ADD_SUCCESS, CONTRACT_ADDITIONAL_AGREEMENT_ADD_ERROR, 
    CONTRACT_ADDITIONAL_AGREEMENT_EDIT_REQUEST, CONTRACT_ADDITIONAL_AGREEMENT_EDIT_SUCCESS, CONTRACT_ADDITIONAL_AGREEMENT_EDIT_ERROR, 
    CONTRACT_ADDITIONAL_AGREEMENT_DELETE_REQUEST, CONTRACT_ADDITIONAL_AGREEMENT_DELETE_SUCCESS, CONTRACT_ADDITIONAL_AGREEMENT_DELETE_ERROR
} from "../constants/contractAdditionalAgreementConstants";

const initialState = {
    contractAdditionalAgreements: [],
    contractAdditionalAgreement: {},
    loading: false,
    success: null,
    error: null,
    contractAdditionalAgreementRequestedCount: 0,
};

export const contractAdditionalAgreementReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_ADDITIONAL_AGREEMENT_GET_REQUEST:
            return {
                ...state,
                contractAdditionalAgreementRequestedCount: ++state.contractAdditionalAgreementRequestedCount,
                loading: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractAdditionalAgreements: action.payload,
                success: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractAdditionalAgreement: action.payload,
                success: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_ADDITIONAL_AGREEMENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
