import {
  GEO_OBJECT_GET_REQUEST,
  GEO_OBJECT_GET_SUCCESS,
  GEO_OBJECT_GET_ERROR,
  GEO_OBJECT_GET_BY_KEY_REQUEST,
  GEO_OBJECT_GET_BY_KEY_SUCCESS,
  GEO_OBJECT_GET_BY_KEY_ERROR,
  GEO_OBJECT_ADD_REQUEST,
  GEO_OBJECT_ADD_SUCCESS,
  GEO_OBJECT_ADD_ERROR,
  GEO_OBJECT_EDIT_REQUEST,
  GEO_OBJECT_EDIT_SUCCESS,
  GEO_OBJECT_EDIT_ERROR,
  GEO_OBJECT_DELETE_REQUEST,
  GEO_OBJECT_DELETE_SUCCESS,
  GEO_OBJECT_DELETE_ERROR,
} from "../constants/geoObjectConstants";

const initialState = {
  geoObjects: [],
  geoObject: {},
  loading: false,
  success: null,
  error: null,
  geoObjectRequestedCount: 0,
};

export const geoObjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GEO_OBJECT_GET_REQUEST:
      return {
        ...state,
        loading: true,
        geoObjectRequestedCount: ++state.geoObjectRequestedCount,
      };
    case GEO_OBJECT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        geoObjects: action.payload,
        success: true,
      };
    case GEO_OBJECT_GET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GEO_OBJECT_GET_BY_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GEO_OBJECT_GET_BY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        geoObject: action.payload,
        success: true,
      };
    case GEO_OBJECT_GET_BY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GEO_OBJECT_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GEO_OBJECT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case GEO_OBJECT_ADD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GEO_OBJECT_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GEO_OBJECT_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case GEO_OBJECT_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case GEO_OBJECT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GEO_OBJECT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case GEO_OBJECT_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
