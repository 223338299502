import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContracts } from "../../../redux/actions/contractActions";
import { getServices } from "../../../redux/actions/serviceActions";
import { getServiceKinds } from "../../../redux/actions/serviceKindActions";
import { getUnitOfMeasures } from "../../../redux/actions/unitOfMeasureActions";
import { getServicePriceLists } from "../../../redux/actions/servicePriceListActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ContractSpecificationDetails({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [price, setPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [contractId, setContractId] = useState('');
    const [serviceId, setServiceId] = useState('');
    const [serviceKindId, setServiceKindId] = useState('');
    const [unitId, setUnitId] = useState('');
    const [servicePriceListId, setServicePriceListId] = useState('');
    const { loading, contractSpecifications, contractSpecification } = useSelector((state) => state.contractSpecification);
    const { contracts } = useSelector((state) => state.contract);
    const { services } = useSelector((state) => state.service);
    const { serviceKinds } = useSelector((state) => state.serviceKind);
    const { unitOfMeasures } = useSelector((state) => state.unitOfMeasure);
    const { servicePriceLists } = useSelector((state) => state.servicePriceList);
    const emptyState = () => {
        setId(null);
        setPrice('');
        setQuantity('');
        setContractId('');
        setServiceId('');
        setServiceKindId('');
        setUnitId('');
        setServicePriceListId('');
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, price, quantity, contractId, serviceId, serviceKindId, unitId, servicePriceListId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, price, quantity, contractId, serviceId, serviceKindId, unitId, servicePriceListId
        } = contractSpecification || {};
        setId(id);
        setPrice(price);
        setQuantity(quantity);
        setContractId(contractId);
        setServiceId(serviceId);
        setServiceKindId(serviceKindId);
        setUnitId(unitId);
        setServicePriceListId(servicePriceListId);
    }, [contractSpecification, contractSpecifications])
    useEffect(() => {
        let response;
        const getContractsAsync = async () => {
            response = await dispatch(getContracts());
        }
        const getServicesAsync = async () => {
            response = await dispatch(getServices());
        }
        const getServiceKindsAsync = async () => {
            response = await dispatch(getServiceKinds());
        }
        const getUnitOfMeasuresAsync = async () => {
            response = await dispatch(getUnitOfMeasures());
        }
        const getServicePriceListsAsync = async () => {
            response = await dispatch(getServicePriceLists());
        }
        if (contracts.length == 0)
        {
            getContractsAsync();
        }
        if (services.length == 0)
        {
            getServicesAsync();
        }
        if (serviceKinds.length == 0)
        {
            getServiceKindsAsync();
        }
        if (unitOfMeasures.length == 0)
        {
            getUnitOfMeasuresAsync();
        }
        if (servicePriceLists.length == 0)
        {
            getServicePriceListsAsync();
        }
    }, [dispatch, action])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    Стоимость: {price}
                                </Grid>
                                <Grid item xs={6}>
                                    Количество: {quantity}
                                </Grid>
                                <Grid item xs={6}>
                                    Контракт: {contracts.find(_ => _.id == contractId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Услуга: {services.find(_ => _.id == serviceId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Вид услуги: {serviceKinds.find(_ => _.id == serviceKindId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Ед. измерения: {unitOfMeasures.find(_ => _.id == unitId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Прейскурант: {servicePriceLists.find(_ => _.id == servicePriceListId)?.name}
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
