import { 
    CONTRACT_OPERATION_KIND_GET_REQUEST, CONTRACT_OPERATION_KIND_GET_SUCCESS, CONTRACT_OPERATION_KIND_GET_ERROR, 
    CONTRACT_OPERATION_KIND_GET_BY_KEY_REQUEST, CONTRACT_OPERATION_KIND_GET_BY_KEY_SUCCESS, CONTRACT_OPERATION_KIND_GET_BY_KEY_ERROR, 
    CONTRACT_OPERATION_KIND_ADD_REQUEST, CONTRACT_OPERATION_KIND_ADD_SUCCESS, CONTRACT_OPERATION_KIND_ADD_ERROR, 
    CONTRACT_OPERATION_KIND_EDIT_REQUEST, CONTRACT_OPERATION_KIND_EDIT_SUCCESS, CONTRACT_OPERATION_KIND_EDIT_ERROR, 
    CONTRACT_OPERATION_KIND_DELETE_REQUEST, CONTRACT_OPERATION_KIND_DELETE_SUCCESS, CONTRACT_OPERATION_KIND_DELETE_ERROR
} from "../constants/contractOperationKindConstants";

const initialState = {
    contractOperationKinds: [],
    contractOperationKind: {},
    loading: false,
    success: null,
    error: null,
    contractOperationKindRequestedCount: 0,
};

export const contractOperationKindReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_OPERATION_KIND_GET_REQUEST:
            return {
                ...state,
                contractOperationKindRequestedCount: ++state.contractOperationKindRequestedCount,
                loading: true,
            };
        case CONTRACT_OPERATION_KIND_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractOperationKinds: action.payload,
                success: true,
            };
        case CONTRACT_OPERATION_KIND_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_OPERATION_KIND_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_OPERATION_KIND_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractOperationKind: action.payload,
                success: true,
            };
        case CONTRACT_OPERATION_KIND_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_OPERATION_KIND_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_OPERATION_KIND_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_OPERATION_KIND_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_OPERATION_KIND_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_OPERATION_KIND_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_OPERATION_KIND_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_OPERATION_KIND_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_OPERATION_KIND_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_OPERATION_KIND_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
