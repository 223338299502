import t_09_2216 from '././baiterek/KZ.T.09.2216.svg'
import t_09_2216_b from '././baiterek/KZ.T.09.2216 black.svg'

import m_09_2201 from '././uralsk/KZ.M.09.2201.svg'
import m_09_2201_b from '././uralsk/KZ.M.09.2201 black.svg'

import t_09_0592 from '././uralsk/KZ.T.09.0592.svg'
import t_09_0592_b from '././uralsk/KZ.T.09.0592 black.svg'

export default [
  t_09_2216,
  t_09_2216_b,
  m_09_2201,
  m_09_2201_b,
  t_09_0592,
  t_09_0592_b,
]
