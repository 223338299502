/* eslint-disable import/no-anonymous-default-export */
export default {
  '36': {
    version: 2,
    splitted: true,
    name: 'ПРОТОКОЛ измерения уровней вибрации',
    kazakhName: 'Діріл деңгейлерін өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Полное наименование хозяйствующего объекта, адрес',
        kazakhLabel: 'Шаруашылық объектісінің толық атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'place',
        type: 'text',
        label: 'цех, участок',
        kazakhLabel: 'цех, учаске',
        number: ''
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеу мақсаты',
        number: '2'
      },
      {
        id: 'done_with_representative',
        type: 'text',
        label: 'Замеры проводились в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объекті өкілінің қатысуымен жүргізілді',
        number: '3'
      },
      {
        id: 'research_methods',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеу құралдары',
        number: '4'
      },
      {
        id: 'inventory_number',
        type: 'text',
        label: 'наименование, тип, инвентарный номер',
        kazakhLabel: 'атауы, түрі, инвентарлық нөмірі',
        number: ''
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Тексеру туралы мәліметтер',
        number: '5'
      },
      {
        id: 'evidence_date',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'куәліктің берілген күні мен нөмірі',
        number: ''
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі',
        number: '6'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД), в соответствии с которой проводились измерения',
        kazakhLabel: 'Жүргізілген өлшеулер мен берілген санитариялық-эпидемиологиялық қорытынды мына Нормативтік құжатқа (НҚ) сай жүргізілді',
        number: '7'
      },
      {
        id: 'vibration_sources',
        type: 'text',
        label: 'Основные источники вибрации и характер создаваемой вибрации',
        kazakhLabel: 'Негізгі діріл көздері мен олар тудыратын дірілдің сипаты',
        number: '8'
      },
      {
        id: 'working_personal_number',
        type: 'number',
        label: 'Количество работающих человек',
        kazakhLabel: 'Жұмыс істейтін адамдардың саны',
        number: '9'
      },
      {
        id: 'vibration_room',
        type: 'file',
        label: 'эскиз помещения с указанием вибрация',
        kazakhLabel: 'Діріл көзі көрсетілген үй-жайдың кескіні аумақтың, жұмыс орнының, қол машинасының',
        number: '10'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <col width=64 style='width:48pt'>
          <col width=300 style='mso-width-source:userset;mso-width-alt:10666;width:225pt'>
          <col width=64 style='width:48pt'>
          <col width=326 style='mso-width-source:userset;mso-width-alt:11576;width:244pt'>
          <col width=64 span=13 style='width:48pt'>
          <tr height=106 style='height:79.2pt'>
            <td class=xl7121974 width=300 style='width:225pt'>№</td>
            <td class=xl7421974 width=64 style='border-left:none;width:48pt'>Дірілді&#1187;&nbsp;&#1241;серіне
            ба&#1171;алау ж&#1199;ргізілген &#1179;&#1201;рыл&#1171;ы</td>
            <td colspan=2 class=xl7421974 width=390 style='border-left:none;width:292pt'>Діріл
            т&#1199;рі</td>
            <td class=xl6321974 width=64 style='border-left:none;width:48pt'>Акселерометр
            ба&#1171;дары (ось)</td>
          </tr>
          <tr height=176 style='height:132.0pt'>
            <td class=xl7221974 width=300 style='border-top:none;width:225pt'>пп</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Оборудование, для которого была проведена оценка воздействия
            вибрации)</td>
            <td colspan=2 class=xl6821974 width=390 style='border-left:none;width:292pt'>(Тип
            вибрации)</td>
            <td class=xl6421974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Ориентация акселерометра (ось))</td>
          </tr>
          <tr height=35 style='height:26.4pt'>
            <td class=xl7521974 width=300 style='border-top:none;width:225pt'>&nbsp;</td>
            <td class=xl6921974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl6821974 width=326 style='border-top:none;border-left:none;
            width:244pt'>Жалпы</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>Жергілікті</td>
            <td class=xl7321974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
          <tr height=35 style='height:26.4pt'>
            <td class=xl7521974 width=300 style='border-top:none;width:225pt'>&nbsp;</td>
            <td class=xl6921974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl6821974 width=326 style='border-top:none;border-left:none;
            width:244pt'>(Общая)</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Локальная)</td>
            <td class=xl7321974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl6521974 width=300 style='border-top:none;width:225pt'>1</td>
            <td class=xl6621974 width=64 style='border-top:none;border-left:none;
            width:48pt'>2</td>
            <td class=xl6621974 width=326 style='border-top:none;border-left:none;
            width:244pt'>4</td>
            <td class=xl6621974 width=64 style='border-top:none;border-left:none;
            width:48pt'>5</td>
            <td class=xl6721974 width=64 style='border-top:none;border-left:none;
            width:48pt'>6</td>
          </tr>
       </table>`,
       label: 'Эскиз рабочих мест',
       kazakhLabel: 'Жұмыс орнының сұлбасы'
      },
      {
        id: 'form_table_2',
        type: 'table',
        htmlTable: `<table border="1" class="table table-bordered" cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <tr height=141 style='height:105.6pt'>
            <td height=141 class=xl1521974 style='height:105.6pt'></td>
            <td rowspan=3 class=xl6821974 width=300 style='width:225pt'>№ пп</td>
            <td colspan=11 class=xl6821974 width=966 style='border-left:none;width:724pt'>Октавалы&#1179;
            жолакта дБ-мен ортагеометрлік жиілігі Гц-діріл &#1199;деуіні&#1187;
            де&#1187;гейі</td>
            <td class=xl6821974 width=64 style='border-left:none;width:48pt'>Оське діріл
            &#1199;дейткішті&#1187; т&#1199;зетілген де&#1187;гейі, дБ</td>
            <td class=xl6821974 width=64 style='border-left:none;width:48pt'>Цикл&#1171;а,
            операция&#1171;а діріл&#1199;дейткішті&#1187; т&#1199;зетілген де&#1187;гейі,
            дБ</td>
            <td class=xl6821974 width=64 style='border-left:none;width:48pt'>Діріл
            &#1199;дейткішті&#1187; эквивалентті т&#1199;зетілген де&#1187;гейі, дБ</td>
            <td class=xl6821974 width=64 style='border-left:none;width:48pt'>Діріл&#1199;дейткішті&#1187;
            шекті эквивалентті т&#1199;зетілген де&#1187;гейі, дБ</td>
          </tr>
          <tr height=176 style='height:132.0pt'>
            <td height=176 class=xl1521974 style='height:132.0pt'></td>
            <td colspan=11 class=xl6821974 width=966 style='border-left:none;width:724pt'>(Уровни
            виброускорения, дБ, в третьоктавных полосах со среднегеометрическими
            частотами, Гц)</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Коррректированный уровень виброускорения для оси, дБ)</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Корректированный уровень виброускорения для цикла, операции, дБ)</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Эквивалентный корректированный уровень виброускорения, дБ)</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Допустимый эквивалентный корректированный уровень
            виброускорения, дБ)</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td height=19 class=xl1521974 style='height:14.4pt'></td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>1</td>
            <td class=xl6821974 width=326 style='border-top:none;border-left:none;
            width:244pt'>2</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>4</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>8</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>16</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>31,5</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>63</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>125</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>250</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>500</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>1000</td>
            <td class=xl6921974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl6921974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl6921974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl6921974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td height=19 class=xl1521974 style='height:14.4pt'></td>
            <td class=xl6821974 width=300 style='border-top:none;width:225pt'>7</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>8</td>
            <td class=xl6821974 width=326 style='border-top:none;border-left:none;
            width:244pt'>9</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>10</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>11</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>12</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>13</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>14</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>15</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>16</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>17</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>18</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>19</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>20</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>21</td>
            <td class=xl6821974 width=64 style='border-top:none;border-left:none;
            width:48pt'>22</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td height=19 class=xl1521974 style='height:14.4pt'></td>
            <td class=xl7021974 width=300 style='border-top:none;width:225pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=326 style='border-top:none;border-left:none;
            width:244pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7021974 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="49"><col width="151"><col width="78"><col width="99"><col width="136"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="2" width="49" height="139" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ пп</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="151" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Дірілдің&nbsp;әсеріне бағалау жүргізілген құрылғы<br>(Оборудование, для которого была проведена оценка воздействия вибрации)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="177" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Діріл түрі<br>(Тип вибрации)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" width="136" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Акселерометр бағдары (ось)<br>(Ориентация акселерометра (ось))</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жалпы<br>(Общая)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жергілікті<br>(Локальная)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Эскиз рабочих мест',
       kazakhLabel: 'Жұмыс орнының сұлбасы'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="16" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="55"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="62"><col width="124"><col width="124"><col width="145"><col width="149"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="55" height="252" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ пп</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="11" width="707" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Октавалық жолакта дБ-мен ортагеометрлік жиілігі Гц-діріл үдеуінің деңгейі<br>(Уровни виброускорения, дБ, в третьоктавных полосах со среднегеометрическими частотами, Гц)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="124" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Оське діріл үдейткіштің түзетілген деңгейі, дБ<br>(Коррректированный уровень виброускорения для оси, дБ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="124" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Циклға, операцияға дірілүдейткіштің түзетілген деңгейі, дБ<br>(Корректированный уровень виброускорения для цикла, операции, дБ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="145" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Діріл үдейткіштің эквивалентті түзетілген деңгейі, дБ<br>(Эквивалентный корректированный уровень виброускорения, дБ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="149" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Дірілүдейткіштің шекті эквивалентті түзетілген деңгейі, дБ<br>(Допустимый эквивалентный корректированный уровень виброускорения, дБ)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="31.5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">31.5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="63" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">63</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="125" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">125</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="250" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">250</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="500" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">500</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="18" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">18</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="19" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">19</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="20" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">20</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="21" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">21</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="22" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">22</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="16" height="24" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="4" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi (Исследование проводились на соответствие НД)_______________________________</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },
    ]
  },
  '37': {
    version: 2,
    splitted: false,
    name: 'ПРОТОКОЛ измерения уровней шума, звукоизоляций',
    kazakhName: 'Шу, дыбыс деңгейлерінің оқшаулануын өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Полное наименование хозяйствующего объекта, адрес',
        kazakhLabel: 'Шаруашылық объектісінің толық атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'place',
        type: 'text',
        label: 'цех, участок',
        kazakhLabel: 'цех, учаске',
        number: ''
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеу мақсаты',
        number: '2'
      },
      {
        id: 'measured_with_representative',
        type: 'text',
        label: 'Замеры проводились в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объекті өкілінің қатысуымен жүргізілді',
        number: '3'
      },
      {
        id: 'inventory',
        type: 'text',
        label: 'Средства измерений, наименование, тип, инвентарный номер',
        kazakhLabel: 'Өлшеу құралдары атауы, түрі, инвентарлық нөмірі',
        number: '4'
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Тексеру туралы мәліметтер',
        number: '5'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'Дата и номер свидетельства',
        kazakhLabel: 'Куәліктің берілген күні мен нөмірі',
        number: '6'
      },
      {
        id: 'normative_document_measurements',
        type: 'text',
        label: 'Нормативный документ (НД), в соответствии с которой проводились измерения',
        kazakhLabel: 'Жүргізілген өлшеулер мына нормативтік құжатқа (НҚ) сай жүргізілді',
        number: '7'
      },
      {
        id: 'noise_sources',
        type: 'text',
        label: 'Основные источники шума и характер создаваемого ими шума',
        kazakhLabel: 'Негізгі шу көздері мен олар тудыратын шудың сипаты',
        number: '8'
      },
      {
        id: 'working_personal_number',
        type: 'number',
        label: 'Количество работающих человек',
        kazakhLabel: 'Жұмыс істейтін адамдардың саны',
        number: '9'
      },
      {
        id: 'place_sketch',
        type: 'file',
        label: 'Эскиз помещения (территории, рабочего места, ручной машины) с нанесением источника шума и указанием стрелками мест установки и ориентации микрофонов (датчиков). Порядковые номера точек замеров',
        kazakhLabel: 'Шу көзі және микрофондардың (датчиктер) орнатылған орындары мен олардың бағыты тілдермен көрсетілген үй-жайдың кескіні (аумақтың, жұмыс орнының, қол машинасының). Өлшеу нүктелерінің реттік нөмірлері.',
        number: '10'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi.',
        number: '11'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <col width=64 style='width:48pt'>
          <col width=202 style='mso-width-source:userset;mso-width-alt:7196;width:152pt'>
          <col width=64 style='width:48pt'>
          <col width=313 style='mso-width-source:userset;mso-width-alt:11121;width:235pt'>
          <col width=64 span=14 style='width:48pt'>
          <tr height=211 style='height:158.4pt'>
            <td class=xl7319945 width=202 style='width:152pt'>Тіркеу н&#1257;мірі</td>
            <td class=xl7519945 width=64 style='border-left:none;width:48pt'>Кескіні
            бойынша н&#1199;кте н&#1257;мірі</td>
            <td class=xl7519945 width=313 style='border-left:none;width:235pt'>&#1256;лшеу
            орны (жабды&#1179;ты&#1187; маркасы, т&#1199;рі, &#1179;&#1201;жат деректері
            к&#1257;рсетілсін)</td>
            <td class=xl7519945 width=64 style='border-left:none;width:48pt'>&#1178;осымша
            м&#1241;ліметтер (&#1257;лшеу шарттары, шуды&#1187; ж&#1201;мыс ауысша
            ішіндегі &#1241;серіні&#1187; &#1201;за&#1179;ты&#1171;ы)</td>
            <td colspan=6 class=xl7519945 width=384 style='border-right:1.0pt solid black;
            border-left:none;width:288pt'>Шуды&#1187; сипаты</td>            
          </tr>
          <tr height=211 style='height:158.4pt'>
            <td class=xl7419945 width=202 style='border-top:none;width:152pt'>(Регистрационный
            номер)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Номер точки по эскизу)</td>
            <td class=xl7019945 width=313 style='border-top:none;border-left:none;
            width:235pt'>(Место замера (указать марку, тип, паспортные данные
            оборудования))</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Дополнительные сведения (условия замера, продолжительность
            воздействия шума в течение рабочей смены))</td>
            <td colspan=6 class=xl7019945 width=384 style='border-right:1.0pt solid black;
            border-left:none;width:288pt'>Характер шума</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td class=xl7619945 width=202 style='border-top:none;width:152pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7119945 width=313 style='border-top:none;border-left:none;
            width:235pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td colspan=2 class=xl7019945 width=128 style='border-left:none;width:96pt'>Спектр
            Бойынша</td>
            <td colspan=4 class=xl7019945 width=256 style='border-right:1.0pt solid black;
            border-left:none;width:192pt'>Уа&#1179;ыт сипаттамалары бойынша</td>            
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td class=xl7619945 width=202 style='border-top:none;width:152pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7119945 width=313 style='border-top:none;border-left:none;
            width:235pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td colspan=2 class=xl7019945 width=128 style='border-left:none;width:96pt'>По
            спектру</td>
            <td colspan=4 class=xl7019945 width=256 style='border-right:1.0pt solid black;
            border-left:none;width:192pt'>По временным характеристикам</td>            
          </tr>
          <tr height=35 style='height:26.4pt'>
            <td class=xl7619945 width=202 style='border-top:none;width:152pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7119945 width=313 style='border-top:none;border-left:none;
            width:235pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Ке&#1187;&#1179;уысты</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Тонды</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Т&#1201;ра&#1179;ты</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Тол&#1179;ымалы</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&#1198;зілмелі</td>
            <td class=xl6419945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Импульсті</td>
          </tr>
          <tr height=35 style='height:26.4pt'>
            <td class=xl7619945 width=202 style='border-top:none;width:152pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7119945 width=313 style='border-top:none;border-left:none;
            width:235pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Широкоплостной)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Тональный)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Постоянный)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Колеблющийся)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Прерывистый)</td>
            <td class=xl6419945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Импульсный)</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl6519945 width=202 style='border-top:none;width:152pt'>1</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>2</td>
            <td class=xl6619945 width=313 style='border-top:none;border-left:none;
            width:235pt'>3</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>4</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>5</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>6</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>7</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>8</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>9</td>
            <td class=xl6719945 width=64 style='border-top:none;border-left:none;
            width:48pt'>10</td>
          </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_2',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <tr height=246 style='height:184.8pt'>
            <td colspan=14 class=xl7019945 width=1283 style='width:963pt'>Октавалы&#1179;
            жола&#1179;та дБ-мен ортагеометриялы&#1179; жиілігі Гц-да&#1171;ы
            дыбысты&#1179; &#1179;ысымны&#1187; де&#1187;гейлері</td>
            <td class=xl7019945 width=64 style='border-left:none;width:48pt'>LА дыбыс
            де&#1187;гейі (дыбысты&#1187; балама де&#1187;геі)/ LА, дБА дыбысты&#1187;
            е&#1187; жо&#1171;ар&#1171;ы де&#1187;гейі</td>
            <td class=xl7019945 width=64 style='border-left:none;width:48pt'>Норма
            бойынша LА дыбысты&#1187; р&#1201;&#1179;сат етілетін де&#1187;гейі / LА, дБА
            дыбысыны&#1187; е&#1187; жо&#1171;ар&#1171;ы р&#1201;&#1179;сат берілетін
            де&#1187;гейі</td>
          </tr>
          <tr height=229 style='height:171.6pt'>
            <td colspan=14 class=xl7019945 width=1283 style='width:963pt'>(Уровни
            звукового давления в дБ октавных полосах со среднегеометрическими частотами в
            Гц)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Уровень звука LА (эквивалентный уровень звука)) / (Максимальный
            уровень звука LА, дБА)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Допустимый уровень звука LА по норме) / (Максимальный допустимый
            уровень звука LА, дБА)</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl7019945 width=202 style='border-top:none;width:152pt'>1</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>25</td>
            <td class=xl7019945 width=313 style='border-top:none;border-left:none;
            width:235pt'>26</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>8</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>16</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>31,5</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>63</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>125</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>250</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>500</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>1000</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>2000</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>4000</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>8000</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7119945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl7019945 width=202 style='border-top:none;width:152pt'>11</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>12</td>
            <td class=xl7019945 width=313 style='border-top:none;border-left:none;
            width:235pt'>13</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>14</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>15</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>16</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>17</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>18</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>19</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>20</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>21</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>22</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>23</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>24</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>25</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>26</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl7219945 width=202 style='border-top:none;width:152pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=313 style='border-top:none;border-left:none;
            width:235pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
       </table>`,
       label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_3',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <tr height=19 style='height:14.4pt'>
            <td colspan=16 class=xl7319945 width=1411 style='border-right:1.0pt solid black;
            width:1059pt'>Октавалы&#1179; жола&#1179;та дБ-мен &#1199;штен бірі
            ортагеометриялы&#1179; жиілігі Гц-да&#1171;ы дыбысты&#1179;
            &#1179;ысымны&#1187; де&#1187;гейлері</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td colspan=16 class=xl7419945 width=1411 style='border-right:1.0pt solid black;
            width:1059pt'>(Уровни звукового давления в дБ третьоктавных полосах со
            среднегеометрическими частотами в Гц)</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td class=xl7419945 width=202 style='border-top:none;width:152pt'>100</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>125</td>
            <td class=xl7019945 width=313 style='border-top:none;border-left:none;
            width:235pt'>160</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>200</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>250</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>320</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>400</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>500</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>630</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>800</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>1000</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>1250</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>1600</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>2000</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>2500</td>
            <td class=xl6419945 width=64 style='border-top:none;border-left:none;
            width:48pt'>3150</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl6519945 width=202 style='border-top:none;width:152pt'>27</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>28</td>
            <td class=xl6619945 width=313 style='border-top:none;border-left:none;
            width:235pt'>29</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>30</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>31</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>32</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>33</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>34</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>35</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>36</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>37</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>38</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>39</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>40</td>
            <td class=xl6619945 width=64 style='border-top:none;border-left:none;
            width:48pt'>41</td>
            <td class=xl6719945 width=64 style='border-top:none;border-left:none;
            width:48pt'>42</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl6919945 width=202 style='width:152pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=313 style='width:235pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
            <td class=xl6819945 width=64 style='width:48pt'>&nbsp;</td>
          </tr>
       </table>`,
       label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_4',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <tr height=19 style='height:14.4pt'>
            <td colspan=2 class=xl7019945 width=266 style='width:200pt'>Aуа шуын
            о&#1179;шаулау индексі Rw -дБ</td>
            <td colspan=2 class=xl7019945 width=377 style='border-left:none;width:283pt'>Cо&#1179;&#1179;ы
            шуыны&#1187; келтірілген де&#1187;гей индексі Lnw-дБ</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td colspan=2 class=xl7019945 width=266 style='width:200pt'>(Индекс изоляции
            воздушного шума Rw –дБ)</td>
            <td colspan=2 class=xl7019945 width=377 style='border-left:none;width:283pt'>(Индекс
            изоляции приведенного ударного шума Lnw-дБ)</td>
          </tr>
          <tr height=70 style='height:52.8pt'>
            <td rowspan=2 class=xl7019945 width=202 style='border-top:none;width:152pt'>&#1256;лшеулер
            (Измеренное)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Нормативті к&#1257;рсеткіш</td>
            <td rowspan=2 class=xl7019945 width=313 style='border-top:none;width:235pt'>&#1256;лшеулер
            (Измеренное)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>Нормативті к&#1257;рсеткіш</td>
          </tr>
          <tr height=70 style='height:52.8pt'>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Нормативный показатель)</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Нормативный показатель)</td>
          </tr>
          <tr height=19 style='height:14.4pt'>            
            <td class=xl7019945 width=202 style='border-top:none;width:152pt'>43</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>44</td>
            <td class=xl7019945 width=313 style='border-top:none;border-left:none;
            width:235pt'>45</td>
            <td class=xl7019945 width=64 style='border-top:none;border-left:none;
            width:48pt'>46</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td class=xl7219945 width=202 style='border-top:none;width:152pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
            <td class=xl7219945 width=313 style='border-top:none;border-left:none;
            width:235pt'>&nbsp;</td>
            <td class=xl7219945 width=64 style='border-top:none;border-left:none;
            width:48pt'>&nbsp;</td>
          </tr>
       </table>`,
       label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="10" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="100"><col width="100"><col width="100"><col width="175"><col width="99"><col width="65"><col width="65"><col width="74"><col width="65"><col width="83"></colgroup>
        <tbody>
          <tr style="height:300px">
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000;vertical-align:middle;mso-rotate:90" rowspan="3" width="100" height="234" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі&nbsp;(Регистрационный номер)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;vertical-align:middle;mso-rotate:90" rowspan="3" width="100" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кескіні бойынша нүкте нөмірі<br>(Номер точки по эскизу)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;vertical-align:middle;mso-rotate:90" rowspan="3" width="100" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу орны (жабдықтың маркасы, түрі, құжат деректері көрсетілсін)<br>(Место замера (указать марку, тип, паспортные данные оборудования))</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;vertical-align:middle;mso-rotate:90" rowspan="3" width="175" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қосымша мәліметтер (өлшеу шарттары, шудың жұмыс ауысша ішіндегі әсерінің ұзақтығы)<br>(Дополнительные сведения (условия замера, продолжительность воздействия шума в течение рабочей смены))</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000;vertical-align:middle;mso-rotate:90" colspan="6" width="450" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шудың сипаты<br>Характер шума</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Спектр Бойынша<br>По спектру</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="4" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Уақыт сипаттамалары бойынша<br>По временным характеристикам</font></td>
            </tr>
          <tr style="height:200px">
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;mso-rotate:90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кеңқуысты<br>(Широкоплостной)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;mso-rotate:90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тонды<br>(Тональный)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;mso-rotate:90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тұрақты<br>(Постоянный)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;mso-rotate:90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Толқымалы (Колеблющийся)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;mso-rotate:90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үзілмелі<br>(Прерывистый)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000;mso-rotate:90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Импульсті<br>(Импульсный)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="17" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="46"><col width="45"><col width="39"><col width="32"><col width="34"><col width="43"><col width="28"><col width="41"><col width="41"><col width="37"><col width="48"><col width="44"><col width="43"><col width="46"><col width="108"><col width="116"><col width="65"></colgroup>
        <tbody>
          <tr>
            <td style="border-bottom: 1px solid #000000" colspan="16" width="790" height="24" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="4" color="#000000">Продолжение таблицы</font></td>
            <td width="65" align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="14" height="378" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Октавалық жолақта дБ-мен ортагеометриялық жиілігі Гц-дағы дыбыстық қысымның деңгейлері<br>(Уровни звукового давления в дБ октавных полосах со среднегеометрическими частотами в Гц)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">LА дыбыс деңгейі (дыбыстың балама деңгеі)/ LА, дБА дыбыстың ең жоғарғы деңгейі<br>(Уровень звука LА (эквивалентный уровень звука)) / (Максимальный уровень звука LА, дБА) </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Норма бойынша LА дыбыстың рұқсат етілетін деңгейі / LА, дБА дыбысының ең жоғарғы рұқсат берілетін деңгейі<br>(Допустимый уровень звука LА по норме) / (Максимальный допустимый уровень звука LА, дБА)</font></td>
            <td align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="25" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">25</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="26" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">26</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="31.5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">31.5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="63" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">63</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="125" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">125</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="250" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">250</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="500" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">500</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-bottom: 3px solid #cfcfcf" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="18" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">18</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="19" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">19</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="20" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">20</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="21" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">21</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="22" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">22</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="23" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">23</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="24" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">24</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="25" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">25</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="26" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">26</font></td>
            <td style="border-bottom: 3px solid #cfcfcf; border-right: 3px solid #cfcfcf" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_v2_3',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="16" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="33"><col width="40"><col width="43"><col width="48"><col width="46"><col width="51"><col width="50"><col width="46"><col width="49"><col width="49"><col width="52"><col width="51"><col width="53"><col width="59"><col width="55"><col width="65"></colgroup>
        <tbody>
          <tr>
            <td style="border-bottom: 3px solid #000000" colspan="16" width="789" height="20" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Продолжение таблицы</font></td>
            </tr>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan="16" height="42" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Октавалық жолақта дБ-мен үштен бірі ортагеометриялық жиілігі Гц-дағы дыбыстық қысымның деңгейлері<br>(Уровни звукового давления в дБ третьоктавных полосах со среднегеометрическими частотами в Гц)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="100" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">100</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="125" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">125</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="160" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">160</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="200" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">200</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="250" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">250</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="320" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">320</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="400" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">400</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="500" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">500</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="630" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">630</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="800" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">800</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1250" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1250</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1600" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1600</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2000" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2000</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2500" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2500</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="3150" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3150</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="27" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">27</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="28" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">28</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="29" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">29</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="30" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">30</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="31" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">31</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="32" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">32</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="33" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">33</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="34" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">34</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="35" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">35</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="36" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">36</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="37" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">37</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="38" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">38</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="39" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">39</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="40" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">40</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="41" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">41</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="42" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">42</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_v2_4',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="4" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="164"><col width="182"><col width="131"><col width="175"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #cfcfcf" colspan="4" width="652" height="24" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="4" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Продолжение таблицы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="88" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Aуа шуын оқшаулау индексі  Rw -дБ<br>(Индекс изоляции воздушного шума Rw –дБ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Cоққы шуының келтірілген деңгей индексі Lnw-дБ<br>(Индекс изоляции приведенного ударного шума Lnw-дБ)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="59" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер (Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативті көрсеткіш<br>(Нормативный показатель)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер (Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативті көрсеткіш<br>(Нормативный показатель)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="43" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">43</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="44" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">44</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="45" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">45</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="46" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">46</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
    ]
  },
  '38': {
    version: 2,
    splitted: false,
    name: 'ПРОТОКОЛ измерения электромагнитного поля',
    kazakhName: 'Электромагниттік өрісті өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Полное наименование хозяйствующего объекта, адрес',
        kazakhLabel: 'Шаруашылық жүргізуші нысанының толық атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'place',
        type: 'text',
        label: 'цех, участок',
        kazakhLabel: 'цех, учаске',
        number: ''
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеу мақсаты',
        number: '2'
      },
      {
        id: 'measured_with_representative',
        type: 'text',
        label: 'Замеры проводились в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объекті өкілінің қатысуымен жүргізілді',
        number: '3'
      },
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеу құралдары',
        number: '4'
      },
      {
        id: 'inventory',
        type: 'text',
        label: 'наименование, тип, инвентарный номер',
        kazakhLabel: 'атауы, түрі, инвентарлық нөмірі',
        number: ''
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Тексеру туралы мәліметтер',
        number: '5'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'берілген күні мен куәліктің нөмірі',
        number: ''
      },
      {
        id: 'normative_documentation',
        type: 'text',
        label: 'Нормативно-техническая документация в соответствии с которой проводились измерения и давалось санитарно-эпидемиологическое заключение',
        kazakhLabel: 'Өлшеулер жүргізу мен санитариялық-эпидемиологиялық қорытынды беру келесі нормативтік-техникалық құжаттамаға сай жүргізілді',
        number: '6'
      },
      {
        id: 'sketch',
        type: 'file',
        label: 'Эскиз помещения с указанием размещения оборудования, источников электромагнитных излучений обозначенных номерами организаций-изготовителей) и нанесением точек замеро',
        kazakhLabel: 'Өндіруі ұйым нөмірлерімен белгіленген жабдықтардың, электромагниттік сәулелену көздерінің орналасуы көрсетілген және өлшеу нүктелері үй-жайдың кескіні',
        number: '7'
      },
      // {
      //   id: 'research_on_nd',
      //   type: 'text',
      //   label: 'Исследование проводились на соответствие НД',
      //   kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi',
      //   number: '8'
      // },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=880 style='border-collapse:
        collapse;table-layout:fixed;width:659pt'>
        <col width=64 style='width:48pt'>
        <col width=243 style='mso-width-source:userset;mso-width-alt:8647;width:182pt'>
        <col width=215 style='mso-width-source:userset;mso-width-alt:7651;width:161pt'>
        <col width=157 style='mso-width-source:userset;mso-width-alt:5575;width:118pt'>
        <col width=64 span=9 style='width:48pt'>
        <tr height=123 style='height:92.4pt'>
          <td class=xl7023972 width=243 style='width:182pt'>Рет бойынша н&#1257;мірі</td>
          <td class=xl7223972 width=215 style='border-left:none;width:161pt'>Кескіні
          бойынша н&#1199;ктені&#1187; орны</td>
          <td class=xl7223972 width=157 style='border-left:none;width:118pt'>&#1256;лшеу
          орны</td>
          <td class=xl7223972 width=64 style='border-left:none;width:48pt'>С&#1241;уле
          к&#1257;зінен ара&#1179;ашы&#1179;ты&#1171;ы, м</td>
          <td class=xl7223972 width=64 style='border-left:none;width:48pt'>Еденнен
          биіктігі, м</td>
          <td class=xl7223972 width=64 style='border-left:none;width:48pt'>Ж&#1201;мыс
          кезе&#1187;і кезінде ЭМ&#1256; аума&#1171;ында болу уы&#1179;ыты</td>
          <td colspan=6 class=xl7223972 width=384 style='border-right:1.0pt solid black;
          border-left:none;width:288pt'>ЭМ&#1256; кернеулілігі</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
          <td class=xl7123972 width=243 style='border-top:none;width:182pt'>(Номер по
          порядку)</td>
          <td class=xl6823972 width=215 style='border-top:none;border-left:none;
          width:161pt'>(Номер точек по эскизу)</td>
          <td class=xl6823972 width=157 style='border-top:none;border-left:none;
          width:118pt'>(Место измерения)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Расстояние от источника в м.)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Высота от пола в м.)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Время пребывания в зоне ЭМП в течении смены)</td>
          <td colspan=6 class=xl6823972 width=384 style='border-right:1.0pt solid black;
          border-left:none;width:288pt'>Напряженность ЭМП</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7323972 width=243 style='border-top:none;width:182pt'>&nbsp;</td>
          <td class=xl6923972 width=215 style='border-top:none;border-left:none;
          width:161pt'>&nbsp;</td>
          <td class=xl6923972 width=157 style='border-top:none;border-left:none;
          width:118pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td colspan=2 class=xl6823972 width=128 style='border-left:none;width:96pt'>Электрлік
          &#1179;&#1201;рамы бойынша, кВ/м,В/м</td>
          <td colspan=2 class=xl6823972 width=128 style='border-left:none;width:96pt'>&#1178;&#1201;рамында&#1171;ы
          магнит бойынша А /м, мкТл</td>
          <td colspan=2 class=xl6823972 width=128 style='border-right:1.0pt solid black;
          border-left:none;width:96pt'>Электростатикалы&#1179; &#1257;ріс кВ/м,</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7323972 width=243 style='border-top:none;width:182pt'>&nbsp;</td>
          <td class=xl6923972 width=215 style='border-top:none;border-left:none;
          width:161pt'>&nbsp;</td>
          <td class=xl6923972 width=157 style='border-top:none;border-left:none;
          width:118pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td colspan=2 class=xl6823972 width=128 style='border-left:none;width:96pt'>(По
          электрической составляющей, кВ/м,В/м)</td>
          <td colspan=2 class=xl6823972 width=128 style='border-left:none;width:96pt'>По
          магнитной составляющей А /м, мкТл</td>
          <td colspan=2 class=xl6823972 width=128 style='border-right:1.0pt solid black;
          border-left:none;width:96pt'>Электростатическое поле кВ/м</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td class=xl7323972 width=243 style='border-top:none;width:182pt'>&nbsp;</td>
          <td class=xl6923972 width=215 style='border-top:none;border-left:none;
          width:161pt'>&nbsp;</td>
          <td class=xl6923972 width=157 style='border-top:none;border-left:none;
          width:118pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&#1256;лшеулер</td>
          <td rowspan=2 class=xl6823972 width=64 style='border-top:none;width:48pt'>Шекті
          р&#1201;&#1179;сат етілген (Предельно-допустимое)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&#1256;лшеулер</td>
          <td rowspan=2 class=xl6823972 width=64 style='border-top:none;width:48pt'>Шекті
          р&#1201;&#1179;сат етілген (Предельно-допустимое)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&#1256;лшеулер</td>
          <td rowspan=2 class=xl6423972 width=64 style='border-top:none;width:48pt'>Шекті
          р&#1201;&#1179;сат етілген (Предельно-допустимое)</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td class=xl7323972 width=243 style='border-top:none;width:182pt'>&nbsp;</td>
          <td class=xl6923972 width=215 style='border-top:none;border-left:none;
          width:161pt'>&nbsp;</td>
          <td class=xl6923972 width=157 style='border-top:none;border-left:none;
          width:118pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6923972 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Измеренное)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Измеренное)</td>
          <td class=xl6823972 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Измеренное)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
          <td class=xl6523972 width=243 style='border-top:none;width:182pt'>1</td>
          <td class=xl6623972 width=215 style='border-top:none;border-left:none;
          width:161pt'>2</td>
          <td class=xl6623972 width=157 style='border-top:none;border-left:none;
          width:118pt'>3</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>4</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>5</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>6</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>7</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>8</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>9</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>10</td>
          <td class=xl6623972 width=64 style='border-top:none;border-left:none;
          width:48pt'>11</td>
          <td class=xl6723972 width=64 style='border-top:none;border-left:none;
          width:48pt'>12</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_2',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <tr height=19 style='height:14.4pt'>
            <td colspan=2 class=xl6823972 width=458 style='width:343pt'>Инфра&#1179;ызыл,
            ультрак&#1199;лгін лазерлік с&#1241;улелену интенсивтілігі, Вт/м2, Дж/м2</td>
            <td colspan=2 class=xl6823972 width=221 style='border-left:none;width:166pt'>Энергия
            а&#1171;ысыны&#1187; ты&#1171;ызды&#1171;ы Вт/м2, МкВт/см 2</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td colspan=2 class=xl6823972 width=458 style='width:343pt'>(Интенсивность:
            инфракрасного; ультрафиолетового; лазерного излучения, Вт/м2, Дж/м2)</td>
            <td colspan=2 class=xl6823972 width=221 style='border-left:none;width:166pt'>(Плотность
            потока энергии Вт/м2, МкВт/см 2)</td>
          </tr>
          <tr height=53 style='height:39.6pt'>
            <td class=xl6823972 width=243 style='border-top:none;width:182pt'>&#1256;лшеулер</td>
            <td rowspan=2 class=xl6823972 width=215 style='border-top:none;width:161pt'>Шекті
            р&#1201;&#1179;сат етілген (Предельно-допустимое)</td>
            <td class=xl6823972 width=157 style='border-top:none;border-left:none;
            width:118pt'>&#1256;лшеулер</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>Шекті р&#1201;&#1179;сат етілген</td>
          </tr>
          <tr height=70 style='height:52.8pt'>
            <td class=xl6823972 width=243 style='border-top:none;width:182pt'>(Измеренное)</td>
            <td class=xl6823972 width=157 style='border-top:none;border-left:none;
            width:118pt'>(Измеренное)</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Предельно-допустимое)</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td class=xl6823972 width=243 style='border-top:none;width:182pt'>13</td>
            <td class=xl6823972 width=215 style='border-top:none;border-left:none;
            width:161pt'>14</td>
            <td class=xl6823972 width=157 style='border-top:none;border-left:none;
            width:118pt'>15</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>16</td>
          </tr>
       </table>`,
       label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_3',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1539 style='border-collapse:
          collapse;table-layout:fixed;width:1155pt'>
          <tr height=19 style='height:14.4pt'>
            <td colspan=7 class=xl7023972 width=871 style='border-right:1.0pt solid black;
            width:653pt'>ЭМ&#1256; энергиясын шы&#1171;аратын
            &#1179;ондыр&#1171;ыларды&#1187; тізбелері мен параметрлері</td>
          </tr>
          <tr height=19 style='height:14.4pt'>
            <td colspan=7 class=xl7123972 width=871 style='border-right:1.0pt solid black;
            width:653pt'>(Перечни и параметры установок излучающих энергию ЭМП)</td>
          </tr>
          <tr height=123 style='height:92.4pt'>
            <td class=xl7123972 width=243 style='border-top:none;width:182pt'>&#1178;ондыр&#1171;ыны&#1187;
            атауы</td>
            <td class=xl6823972 width=215 style='border-top:none;border-left:none;
            width:161pt'>ЭМС жиілігі</td>
            <td class=xl6823972 width=157 style='border-top:none;border-left:none;
            width:118pt'>ЭМС к&#1257;зіні&#1187; &#1179;уаты</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>&#1256;лшеу кезіндегі к&#1257;зді&#1187; ж&#1201;мыс режимі
            (&#1179;уаттылы&#1171;ы)</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>Шы&#1171;ар&#1171;ан зауытты&#1187; н&#1257;мірі</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>Шы&#1171;арыл&#1171;ан жылы</td>
            <td class=xl6423972 width=64 style='border-top:none;border-left:none;
            width:48pt'>Ескерту</td>
          </tr>
          <tr height=141 style='height:105.6pt'>
            <td class=xl7123972 width=243 style='border-top:none;width:182pt'>(Наименование
            установки)</td>
            <td class=xl6823972 width=215 style='border-top:none;border-left:none;
            width:161pt'>(Частота ЭМИ)</td>
            <td class=xl6823972 width=157 style='border-top:none;border-left:none;
            width:118pt'>(Мощность источника ЭМИ)</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Режим работы (мощность) источника при измерении)</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Заводской номер источника)</td>
            <td class=xl6823972 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Год выпуска источника)</td>
            <td class=xl6423972 width=64 style='border-top:none;border-left:none;
            width:48pt'>(Примечание)</td>
          </tr>
          <tr height=20 style='height:15.0pt'>
            <td class=xl6523972 width=243 style='border-top:none;width:182pt'>17</td>
            <td class=xl6623972 width=215 style='border-top:none;border-left:none;
            width:161pt'>18</td>
            <td class=xl6623972 width=157 style='border-top:none;border-left:none;
            width:118pt'>19</td>
            <td class=xl6623972 width=64 style='border-top:none;border-left:none;
            width:48pt'>20</td>
            <td class=xl6623972 width=64 style='border-top:none;border-left:none;
            width:48pt'>21</td>
            <td class=xl6623972 width=64 style='border-top:none;border-left:none;
            width:48pt'>22</td>
            <td class=xl6723972 width=64 style='border-top:none;border-left:none;
            width:48pt'>23</td>
          </tr>
          <tr height=24 style='height:18.0pt'>
            <td colspan=3 class=xl7423972>_________________________________________________________________________</td>
            <td class=xl1523972></td>
            <td class=xl1523972></td>
            <td class=xl1523972></td>
            <td class=xl1523972></td>
          </tr>
       </table>`,
       label: 'Разворот',
       kazakhLabel: 'Артқы беті'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table cellspacing="0" cols="6" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="129"><col width="131"><col width="88"><col width="121"><col width="123"><col width="113"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="129" height="277" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рет бойынша нөмірі<br>(Номер по порядку)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="131" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кескіні бойынша нүктенің орны<br>(Номер точек по эскизу)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="88" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу орны<br>(Место измерения)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="121" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сәуле көзінен арақашықтығы, м<br>(Расстояние от источника в м.)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="123" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Еденнен биіктігі, м<br>(Высота от пола в м.)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жұмыс кезеңі кезінде ЭМӨ аумағында болу уықыты<br>(Время пребывания в зоне ЭМП в течении смены)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="10" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="103"><col width="94"><col width="103"><col width="71"><col width="103"><col width="100"><col width="118"><col width="136"><col width="106"><col width="100"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="10" height="49" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ЭМӨ кернеулілігі<br>Напряженность ЭМП</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="149" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Электрлік құрамы бойынша, кВ/м,В/м<br>(По электрической составляющей, кВ/м,В/м)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрамындағы магнит бойынша А /м, мкТл<br>По магнитной составляющей А /м, мкТл</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Электростатикалық өріс кВ/м,<br>Электростатическое поле кВ/м</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Инфрақызыл, ультракүлгін лазерлік сәулелену интенсивтілігі, Вт/м2, Дж/м2<br>(Интенсивность: инфракрасного; ультрафиолетового; лазерного излучения, Вт/м2, Дж/м2)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Энергия ағысының тығыздығы Вт/м2, МкВт/см 2<br>(Плотность потока энергии Вт/м2, МкВт/см 2)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="96" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шекті рұқсат етілген (Предельно-допустимое)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шекті рұқсат етілген </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шекті рұқсат етілген (Предельно-допустимое)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шекті рұқсат етілген <br>(Предельно-допустимое)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измеренное)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шекті рұқсат етілген<br>(Предельно-допустимое)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Кестені&#1187; жал&#1171;асы'
      },
      {
        id: 'form_table_v2_3',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="130"><col width="105"><col width="105"><col width="108"><col width="86"><col width="87"><col width="108"></colgroup>
        <tbody>
          <tr>
            <td width="130" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General" colspan="7"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Артқы беті (Разворот)</font></td>
          </tr>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan="7" height="43" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ЭМӨ энергиясын шығаратын қондырғылардың тізбелері мен параметрлері<br>(Перечни и параметры установок излучающих энергию ЭМП)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="210" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қондырғының атауы<br>(Наименование установки)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ЭМС жиілігі<br>(Частота ЭМИ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ЭМС көзінің қуаты<br>(Мощность источника ЭМИ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу кезіндегі көздің жұмыс режимі (қуаттылығы)<br>(Режим работы (мощность) источника при измерении)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шығарған зауыттың нөмірі<br>(Заводской номер источника)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шығарылған жылы<br>(Год выпуска источника)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескерту<br>(Примечание)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="18" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">18</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="19" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">19</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="20" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">20</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="21" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">21</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="22" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">22</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="23" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">23</font></td>
          </tr>
          <tr>
            <td style="border-top: 3px solid #000000" colspan="7" height="56" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="4" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi<br>(Исследование проводились на соответствие НД)_____________</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Разворот',
       kazakhLabel: 'Артқы беті'
      },
    ]
  },
  '39': {
    version:2,
    name: 'ПРОТОКОЛ исследования растениеводческой продукции на содержание нитратов',
    kazakhName: 'Өсімдік шаруашылығы өнімін нитраттардың бар болуына зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'partion_magnitude',
        type: 'text',
        label: 'Величина партии',
        kazakhLabel: 'Партия мөлшері'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Сынама алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=880 style='border-collapse:
        collapse;table-layout:fixed;width:659pt'>
        <col width=64 style='width:48pt'>
        <col width=134 style='mso-width-source:userset;mso-width-alt:4750;width:100pt'>
        <col width=130 style='mso-width-source:userset;mso-width-alt:4608;width:97pt'>
        <col width=424 style='mso-width-source:userset;mso-width-alt:15075;width:318pt'>
        <col width=64 span=2 style='width:48pt'>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1513918 width=64 style='height:79.2pt;width:48pt'></td>
         <td class=xl6913918 width=134 style='width:100pt'>&#1256;німні&#1187; атауы</td>
         <td class=xl7113918 width=130 style='border-left:none;width:97pt'>Нитраттарды&#1187;
         аны&#1179;тал&#1171;ан&#1179;алды&#1179; &#1179;&#1201;рамы</td>
         <td rowspan=2 class=xl7113918 width=424 style='width:318pt'>мг/кг</td>
         <td class=xl7113918 width=64 style='border-left:none;width:48pt'>РЕМД
         нормативті к&#1257;рсеткіштері, мг/кг</td>
         <td class=xl6313918 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістеріне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1513918 style='height:66.0pt'></td>
         <td class=xl7013918 width=134 style='border-top:none;width:100pt'>(Наименование
         продукции)</td>
         <td class=xl6813918 width=130 style='border-top:none;border-left:none;
         width:97pt'>(Обнаруженное остаточное содержание нитратов)</td>
         <td class=xl6813918 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Нормативные показатели МДУ, мг/кг)</td>
         <td class=xl6413918 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на метод испытаний)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1513918 style='height:15.0pt'></td>
         <td class=xl6513918 width=134 style='border-top:none;width:100pt'>1</td>
         <td class=xl6613918 width=130 style='border-top:none;border-left:none;
         width:97pt'>2</td>
         <td class=xl6613918 width=424 style='border-top:none;border-left:none;
         width:318pt'>3</td>
         <td class=xl6613918 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6713918 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="148"><col width="217"><col width="57"><col width="188"><col width="161"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="148" height="120" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өнімнің атауы<br>(Наименование продукции)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="217" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нитраттардың анықталғанқалдық құрамы<br>(Обнаруженное остаточное содержание нитратов)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="57" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">мг/кг</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="188" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">РЕМД нормативті көрсеткіштері, мг/кг (Нормативные показатели МДУ, мг/кг)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="161" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеріне қолданылған НҚ<br>(НД на метод испытаний)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '40': {
    version:2,
    name: 'ПРОТОКОЛ исследования образцов сельскохозяйственной продукции, воды, почвы, воздушной среды на определение остаточных количеств пестицидов (Лаборатория высокотехнологических исследований)',
    kazakhName: 'Ауылшаруашылық өнімдерін, тамақ өнімдерін, суды, топырақты, ауа ортасын пестицидтердің қалдық көлемін анықтауға зерттеу (Жоғары технологиялық зерттеулер зертханасы, (бұдан әрі – ЖТЗ)) ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объекті атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгі атауы'
      },
      {
        id: 'number',
        type: 'number',
        label: 'Количество',
        kazakhLabel: 'Саны'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу алу мақсаты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партии',
        kazakhLabel: 'Топтама саны'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Сынама алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'normative_document_selection',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне қолданылған нормативтік құжат (НҚ)'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=796 style='border-collapse:
        collapse;table-layout:fixed;width:597pt'>
        <col width=64 style='width:48pt'>
        <col width=266 style='mso-width-source:userset;mso-width-alt:9472;width:200pt'>
        <col width=64 style='width:48pt'>
        <col width=338 style='mso-width-source:userset;mso-width-alt:12003;width:253pt'>
        <col width=64 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1531613 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl6331613 width=266 style='width:200pt'>Ингредиенттер ж&#1241;не
         бас&#1179;алар к&#1257;рсеткіштеріні&#1187; атауы</td>
         <td class=xl6331613 width=64 style='border-left:none;width:48pt'>Аны&#1179;тал&#1171;ан
         шо&#1171;ырлану</td>
         <td class=xl6331613 width=338 style='border-left:none;width:253pt'>Нормативтік
         к&#1257;рсеткіштер</td>
         <td class=xl6331613 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістеріне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1531613 style='height:52.8pt'></td>
         <td class=xl6331613 width=266 style='border-top:none;width:200pt'>Наименование
         показателей ингредиентов и других</td>
         <td class=xl6331613 width=64 style='border-top:none;border-left:none;
         width:48pt'>Обнаруженная концентрация</td>
         <td class=xl6331613 width=338 style='border-top:none;border-left:none;
         width:253pt'>Нормативные показатели</td>
         <td class=xl6331613 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на методы исследования</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1531613 style='height:14.4pt'></td>
         <td class=xl6331613 width=266 style='border-top:none;width:200pt'>1</td>
         <td class=xl6331613 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6331613 width=338 style='border-top:none;border-left:none;
         width:253pt'>3</td>
         <td class=xl6331613 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="4" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="251"><col width="146"><col width="140"><col width="169"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="251" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ингредиенттер және басқалар көрсеткіштерінің атауы (Наименование показателей ингредиентов и других)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="146" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған шоғырлану (Обнаруженная концентрация)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="140" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативтік көрсеткіштер<br>(Нормативные показатели)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="169" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеріне қолданылған НҚ (НД на методы исследования)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '41': {
    version: 2,
    name: 'ПРОТОКОЛ исследования дезинфицирующих средств',
    kazakhName: 'Дезинфекциялайтын заттарды зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование хозяйствующего субъекта, организации, адрес',
        kazakhLabel: 'Шаруашылық жүргiзушi субъекттiң, ұйымның атауы, мекен-жайы'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Сынама алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткiзілген күні мен уақыты'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша деректер'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'percent_of_active_substance',
        type: 'text',
        label: '% содержание действующего вещества',
        kazakhLabel: 'Белсенді заттың % кұрамы'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },            
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=816 style='border-collapse:
        collapse;table-layout:fixed;width:613pt'>
        <col width=64 style='width:48pt'>
        <col width=224 style='mso-width-source:userset;mso-width-alt:7964;width:168pt'>
        <col width=134 style='mso-width-source:userset;mso-width-alt:4778;width:101pt'>
        <col width=202 style='mso-width-source:userset;mso-width-alt:7196;width:152pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1513417 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl6313417 width=224 style='width:168pt'>&#1198;лгіні&#1187;
         н&#1257;мірi</td>
         <td class=xl6313417 width=134 style='border-left:none;width:101pt'>&#1198;лгілерді&#1187;
         алын&#1171;ан орны</td>
         <td class=xl6313417 width=202 style='border-left:none;width:152pt'>Заттарды&#1187;
         агрегатты&#1179; к&#1199;йi</td>
         <td class=xl6313417 width=64 style='border-left:none;width:48pt'>Белсендi
         затты&#1187; аны&#1179;тал&#1171;ан &#1179;&#1201;рамы</td>
         <td rowspan=2 class=xl6313417 width=64 style='width:48pt'>Нормативтi
         к&#1257;рсеткiш (Нормативный показатель)</td>
         <td class=xl6313417 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дiстерiне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=123 style='height:92.4pt'>
         <td height=123 class=xl1513417 style='height:92.4pt'></td>
         <td class=xl6313417 width=224 style='border-top:none;width:168pt'>(Номер
         образца)</td>
         <td class=xl6313417 width=134 style='border-top:none;border-left:none;
         width:101pt'>(Место отбора образца)</td>
         <td class=xl6313417 width=202 style='border-top:none;border-left:none;
         width:152pt'>(Агрегатное состояние веществ)</td>
         <td class=xl6313417 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Обнаруженное содержание активного вещества)</td>
         <td class=xl6313417 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на методы исследования)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1513417 style='height:14.4pt'></td>
         <td class=xl6413417 width=224 style='border-top:none;width:168pt'>&nbsp;</td>
         <td class=xl6413417 width=134 style='border-top:none;border-left:none;
         width:101pt'>&nbsp;</td>
         <td class=xl6413417 width=202 style='border-top:none;border-left:none;
         width:152pt'>&nbsp;</td>
         <td class=xl6413417 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6413417 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6413417 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="111"><col width="112"><col width="126"><col width="141"><col width="116"><col width="118"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="111" height="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің нөмірi<br>(Номер образца)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="112" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгілердің алынған орны<br>(Место отбора образца)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="126" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Заттардың агрегаттық күйi<br>(Агрегатное состояние веществ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="141" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Белсендi заттың анықталған құрамы<br>(Обнаруженное содержание активного вещества)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="116" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативтi көрсеткiш (Нормативный показатель)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="118" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдiстерiне қолданылған НҚ<br>(НД на методы исследования)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="20" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="6" height="63" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },      
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Зерттеу НҚ-ға сәйкес жүргiзiлд'
      },
    ]
  },
  '42': {
    name: 'ПРОТОКОЛ серологических исследований на вид инфекции',
    kazakhName: 'серологиялық зерттеулер инфекция түріне',
    fields: [
      {
        id: 'infection_type',
        type: 'text',
        label: 'Вид инфекции',
        kazakhLabel: 'инфекция түрі'
      },
      {
        id: 'examinatee_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество обследуемого',
        kazakhLabel: 'Зерттелушінің тегі, аты, әкесінің аты'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'age',
        type: 'text',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'adress',
        type: 'text',
        label: 'Адрес',
        kazakhLabel: 'Мекен-жайы'
      },
      {
        id: 'organization_name',
        type: 'text',
        label: 'Название организации направившая образец',
        kazakhLabel: 'Үлгіні жіберген мекемелердің атауы'
      },
      {
        id: 'probe_name',
        type: 'text',
        label: 'наименование пробы',
        kazakhLabel: 'Сынаманың атауы'
      },
      {
        id: 'sick_day',
        type: 'text',
        label: 'на день болезни',
        kazakhLabel: 'Ауырған күні'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'цель исследования',
        kazakhLabel: 'Зерттеу максаты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата доставки материала',
        kazakhLabel: 'Материалды жеткізу күні'
      },
      {
        id: 'sample_obtain_date',
        type: 'datetime',
        label: 'Дата забора материала',
        kazakhLabel: 'Материалдың алынған күні'
      },
      {
        id: 'research_result',
        type: 'text',
        label: 'результат исследования',
        kazakhLabel: 'Зерттеу нәтижесі'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'примечание',
        kazakhLabel: 'Ескерту'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Зерттеу НҚ-ға сәйкестiкке жүргiзiлдi'
      }
    ]
  }
}
