import { serviceMapCRUD } from "../../http";
import { 
    SERVICE_MAP_GET_REQUEST, SERVICE_MAP_GET_SUCCESS, SERVICE_MAP_GET_ERROR, 
    SERVICE_MAP_GET_BY_KEY_REQUEST, SERVICE_MAP_GET_BY_KEY_SUCCESS, SERVICE_MAP_GET_BY_KEY_ERROR, 
    SERVICE_MAP_ADD_REQUEST, SERVICE_MAP_ADD_SUCCESS, SERVICE_MAP_ADD_ERROR, 
    SERVICE_MAP_EDIT_REQUEST, SERVICE_MAP_EDIT_SUCCESS, SERVICE_MAP_EDIT_ERROR, 
    SERVICE_MAP_DELETE_REQUEST, SERVICE_MAP_DELETE_SUCCESS, SERVICE_MAP_DELETE_ERROR
} from "../constants/serviceMapConstants";

export const getServiceMaps = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_MAP_GET_REQUEST
        });

        const data = await serviceMapCRUD.search(args);
        dispatch({
            type: SERVICE_MAP_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_MAP_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceMapByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_MAP_GET_BY_KEY_REQUEST
        });

        const data = await serviceMapCRUD.get(id);
        dispatch({
            type: SERVICE_MAP_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: SERVICE_MAP_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addServiceMap = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_MAP_ADD_REQUEST
        });

        const response = await serviceMapCRUD.create(data);
        await dispatch(getServiceMaps(args));
        dispatch({
            type: SERVICE_MAP_ADD_SUCCESS,
        });
        return response.id;
    } catch (error) {
        dispatch({
            type: SERVICE_MAP_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editServiceMap = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_MAP_EDIT_REQUEST
        });

        const response = await serviceMapCRUD.edit(data);
        await dispatch(getServiceMaps(args));
        dispatch({
            type: SERVICE_MAP_EDIT_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: SERVICE_MAP_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteServiceMap = (id, args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_MAP_DELETE_REQUEST
        });

        const response = await serviceMapCRUD.delete(id);
        await dispatch(getServiceMaps(args));
        dispatch({
            type: SERVICE_MAP_DELETE_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: SERVICE_MAP_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

