export const CONTRACT_STATE_GET_REQUEST = 'CONTRACT_STATE_GET_REQUEST';
export const CONTRACT_STATE_GET_SUCCESS = 'CONTRACT_STATE_GET_SUCCESS';
export const CONTRACT_STATE_GET_ERROR = 'CONTRACT_STATE_GET_ERROR';
export const CONTRACT_STATE_GET_BY_KEY_REQUEST = 'CONTRACT_STATE_GET_BY_KEY_REQUEST';
export const CONTRACT_STATE_GET_BY_KEY_SUCCESS = 'CONTRACT_STATE_GET_BY_KEY_SUCCESS';
export const CONTRACT_STATE_GET_BY_KEY_ERROR = 'CONTRACT_STATE_GET_BY_KEY_ERROR';
export const CONTRACT_STATE_ADD_REQUEST = 'CONTRACT_STATE_ADD_REQUEST';
export const CONTRACT_STATE_ADD_SUCCESS = 'CONTRACT_STATE_ADD_SUCCESS';
export const CONTRACT_STATE_ADD_ERROR = 'CONTRACT_STATE_ADD_ERROR';
export const CONTRACT_STATE_EDIT_REQUEST = 'CONTRACT_STATE_EDIT_REQUEST';
export const CONTRACT_STATE_EDIT_SUCCESS = 'CONTRACT_STATE_EDIT_SUCCESS';
export const CONTRACT_STATE_EDIT_ERROR = 'CONTRACT_STATE_EDIT_ERROR';
export const CONTRACT_STATE_DELETE_REQUEST = 'CONTRACT_STATE_DELETE_REQUEST';
export const CONTRACT_STATE_DELETE_SUCCESS = 'CONTRACT_STATE_DELETE_SUCCESS';
export const CONTRACT_STATE_DELETE_ERROR = 'CONTRACT_STATE_DELETE_ERROR';
