import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  _addReferral,
  getClinicalRequestByKey,
  editClinicalRequest,
} from "./../../../redux/actions";

import ReferralData from "../ReferralData";
import useLoader from "../../../hooks/useLoader";
import DialogLayout from "../../../components/Dialog/DialogLayout";

export const ReferralCreateModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();

  const { clinicalRequest } = useSelector((s) => s.clinicalRequest);

  const [data, setData] = useState({
    paymentPassed: false,
  });

  const {
    start: startSubmit,
    stop: stopSubmit,
    loading: loadingSubmit,
  } = useLoader(false);

  const handleSubmit = async () => {
    startSubmit()
    const clinicalRequestId = clinicalRequest?.id ? clinicalRequest.id : null;

    const _data = {
      ...data,
      clinicalRequestId,
    };

    const referralId = await dispatch(_addReferral(_data));
    if (!referralId) return stopSubmit();

    await dispatch(editClinicalRequest({ ...clinicalRequest, referralId }));
    await dispatch(getClinicalRequestByKey(clinicalRequestId))
    stopSubmit();
    setOpen(false);
  };

  return (
      <DialogLayout setOpen={setOpen} open={open} loading={loadingSubmit} title="Единоразовая заявка: создание">
        <ReferralData data={data} setData={setData} handleSubmit={handleSubmit}/>
      </DialogLayout>
  );
};

ReferralCreateModal.defaultProps = {};
