import DirectionPeopleForm from "./DirectionPeopleForm";
import DirectionPeoplePDFGenerator from "./DirectionPeoplePDFGenerator";

import withDirectionDetail from "../withDirectionDetail";

const directionFormKazakhName = 'Адамдардан іріктелген сынамаларды ______________ тексеруге ЖОЛДАМА'
const directionFormName = 'НАПРАВЛЕНИЕ материала от людей на __________ исследования'
const directionFormNumber = '020'
const directionName = 'DirectionPeople'

export default withDirectionDetail(DirectionPeopleForm, DirectionPeoplePDFGenerator, directionFormKazakhName,  directionFormName,  directionFormNumber, directionName)