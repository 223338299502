import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LoadingButton from "@mui/lab/LoadingButton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv6";
import ruLocale from "date-fns/locale/ru";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { style } from "../../../components/TableMUIStyle";
import useLoader from "../../../hooks/useLoader";
import { getReferrals } from "../../../redux/actions/referralActions";
import { addResult } from "../../../redux/actions/resultActions";
import { getResultForms } from "../../../redux/actions/resultFormActions";
import { getFormMaps } from "../../../redux/actions/formMapActions";
import Autocomplete from '@mui/material/Autocomplete';

import { distinct, selectByKeys } from '../../../models/data/EntityCollectionModel';
import { mappingActToResultData, resultsIdHasSamples } from "../../../functions/mapping/results";
import ChooseSamples from "./ChooseSamples";
import {
    mappingObjectReferralToResultData,
    resultsIdObjectReferralHasSamples,
    resultsIdObjectReferralHasSamplesNoAct
} from "../../../functions/mapping/objectReferral";
import ChooseSamplesWithoutAct from "./ChooseSamplesWithoutAct";
import { checkRoles } from "../../../functions/checkRoles";
import useRoles from "../../../hooks/useRoles";

export default function CreateResult({ action, act, title, schema, formId, onClose, onSubmit, referral, query }) {
    const dispatch = useDispatch();
    const { employee } = useRoles();
    const [createdAt, setCreatedAt] = useState(new Date());
    const [formSchema, setFormSchema] = useState(null);
    const [data, setData] = useState({});
    const [referralId, setReferralId] = useState(null);
    const { loading } = useSelector((state) => state.result);
    const { referrals, referralRequestedCount } = useSelector((state) => state.referral);

    // createByReferral
    const [resultFormId, setResultFormId] = useState(null);
    const [resultForm, setResultForm] = useState(null);
    const [resultForm2, setResultForm2] = useState(null);
    const [resultForms, setResultForms] = useState([])

    const [chooseSamples, setChooseSamples] = useState(false);
    const [chooseSamplesWithoutAct, setChooseSamplesWithoutAct] = useState(false);
    const [chooseSampleObject, setChooseSampleObject] = useState({});
    const [chooseSampleObjectWithoutAct, setChooseSampleObjectWithoutAct] = useState({});

    const [formData, setFormData] = useState({});

    const { loading: submitLoading, start: submitStart, stop: submitStop } = useLoader(false);

    const submit = useCallback((_) => {
        submitStart();
        dispatch(addResult(_, query || {}))
            .then((r) => {
                onSubmit(r);
                onClose();
            })
            .catch(alert)
            .finally(submitStop);
    }, [onSubmit, dispatch, submitStart, submitStop, onClose]);

    const handleForm = async (e) => {
        debugger;
        e.preventDefault();
        switch (action) {
            case "create":
                let referralById = referrals.find(_ => _.id == referralId);
                let number = referralById?.number;
                let filialId = referralById?.filialId;
                let filialDivisionId = referralById?.filialDivisionId;
                let departmentId = referralById?.departmentId;
                let divisionId = referralById?.divisionId;
                submit({
                    createdAt, schema: schema, data: formData, formId, referralId,
                    number, filialId, filialDivisionId, departmentId, divisionId
                });
                break;
            case "createByReferral":
            case "createByDesinfectionRequest":
            case "createWithoutRequest":    
            case "createByServiceWithoutActRequest":
            case "createByAct":
            case "createByObjectReferral":
                if (resultForm == null) {
                    alert('Нужно выбрать форму направления');
                    break;
                }

                if (resultForm.schema == null) {
                    alert('Не определена схема формы');
                    break;
                }

                const request = {
                    createdAt, schema: JSON.parse(resultForm.schema), data: formData,
                    formId: resultForm.id,
                    filialId: referral ? referral?.filialId : employee.filialId,
                    filialDivisionId: referral ? referral?.filialDivisionId : employee.filialDivisionId,
                    departmentId: referral ? referral?.departmentId : employee.departmentId,
                    divisionId: referral ? referral?.divisionId : employee.divisionId,
                    number: referral?.number
                };

                switch (action) {
                    case "createByReferral":
                        request.referralId = referral.id;
                        break;
                    case "createByObjectReferral":
                        request.objectReferralId = referral.id;
                        break;
                    case "createByDesinfectionRequest":
                        request.desinsectionRequestId = referral.id;
                        break;
                    case "createByAct":
                        request.actId = referral.id;
                        break;
                    case "createByServiceWithoutActRequest":
                        request.serviceWithoutActRequestId = referral.id;
                        break;
                }

                submit(request);
                break;
        }
    };
    useEffect(async () => {
        // TODO:
        if (action == 'create')
            await dispatch(getReferrals({ paging: { skip: 0, take: 300 } }));

        const formMap = await dispatch(getFormMaps({
            filter: {
                departmentId: {
                    operand1: referral?.departmentId,
                    operator: "equals",
                }
            },
            paging: { skip: 0 },
        }));


        let resultFormIds = formMap?.map(el => {
            return el.resultFormId
        });

        resultFormIds = distinct(resultFormIds, (a, b) => a == b);

        if (action === 'createByReferral' || action === 'createByObjectReferral' || action === 'createByDesinfectionRequest' ||
            action === 'createByServiceWithoutActRequest' || action === 'createByAct') {
            if (resultFormIds.length > 0) {
                let resultForms = await dispatch(getResultForms({
                    paging: { skip: 0 },
                }, true));


                const resultMarriage = await dispatch(getResultForms({
                    filter: {
                        number: {
                            operand1: '144у',
                            operator: "like",
                        }
                    },
                    paging: { skip: 0 },
                }, true));

                resultForms = selectByKeys(resultForms, resultFormIds, _ => _.id);

                if (checkRoles("SuperAdministrator", "Administrator", "Specialist")) {
                    resultForms = [...resultForms, ...resultMarriage]
                }
                setResultForms(resultForms);
            }
        }
        if (action === 'createWithoutRequest') {
            const formMap = await dispatch(getFormMaps({
                filter: {
                    resultTypeId: {
                        operand1: 2,
                        operator: "equals"
                    }
                },
                paging: { skip: 0 },
            }));

            let forMapFiltered = formMap.filter(row => {
                if (row?.roles) {
                    return checkRoles(...JSON.parse(row?.roles))
                }
            })

            let resultFormIds = forMapFiltered?.map(el => {
                return el.resultFormId
            });

            resultFormIds = distinct(resultFormIds, (a, b) => a == b);

            if (resultFormIds.length > 0) {
                let resultForms = await dispatch(getResultForms({
                    paging: { skip: 0 },
                }, true));

                resultForms = selectByKeys(resultForms, resultFormIds, _ => _.id);

                setResultForms(resultForms);
            }
        }

    }, [dispatch, action, referral]);
    useEffect(() => {
        if (!resultFormId)
            return;
        let resultForm = resultForms.find(_ => _.id == resultFormId);

        let schema = resultForm.schema;

        if (!schema)
            return;
        let json = JSON.parse(schema);
        setFormSchema(json);
        setResultForm(resultForm);
    }, [dispatch, resultFormId]);

    useEffect(() => {
        if (act && act?.data) {
            const actFormData = mappingActToResultData(JSON.parse(act.data), act.form.number, resultFormId, chooseSampleObject)
            setFormData(prev => ({ ...prev, ...actFormData }))
        }
    }, [act, resultFormId, chooseSampleObject])

    // useEffect(() => {
    //     if (!act?.data && referral?.probes && referral?.probeType) {
    //         const referralFormData = mappingObjectReferralToResultData(referral, resultFormId, chooseSampleObjectWithoutAct)
    //         setFormData(prev => ({...prev, ...referralFormData}))
    //     }
    // }, [act, resultFormId, referral?.probeType, referral?.probes, chooseSampleObjectWithoutAct])

    useEffect(() => {
        console.log(referral)
        if (referral) {
            const referralFormData = mappingObjectReferralToResultData(referral, resultFormId, chooseSampleObjectWithoutAct)
            setFormData(prev => ({ ...prev, ...referralFormData }))
        }
    }, [resultFormId, referral])

    useEffect(() => {
        if (act && act?.data) {
            if (resultsIdHasSamples.includes(resultFormId)) {
                const actData = JSON.parse(act.data);
                if (actData?.samples?.value?.length) {
                    setChooseSamples(true)
                }
            }
        }
    }, [act, resultFormId])

    useEffect(() => {
        if (!act?.data && referral?.probes && referral?.probeType) {
            if (resultsIdObjectReferralHasSamples.includes(resultFormId)) {
                if (referral.probes?.length) {
                    setChooseSamplesWithoutAct(true)
                }
            }
        }
    }, [act, resultFormId, referral?.probeType, referral?.probes])

    // useEffect(() => {
    //     if (referral?.probes && referral?.probeType) {
    //         if (resultsIdObjectReferralHasSamplesNoAct.includes(resultFormId)) {
    //             if (referral.probes?.length) {
    //                 setChooseSamplesWithoutAct(true)
    //             }
    //         }
    //     }
    // }, [resultFormId, referral])

    let currentSchema = null;

    switch (action) {
        case "create":
            currentSchema = schema;
            break;
        case "createByReferral":
        case "createByDesinfectionRequest":
        case 'createByServiceWithoutActRequest':
        case 'createByAct':
        case 'createWithoutRequest':
        case "createByObjectReferral":
            currentSchema = formSchema;
            break;
    }

    return (
        <div>
            <Modal open={true} onClose={onClose} aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {loading ? <CircularProgress /> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                        <DatePicker views={['day']} label="Создано" value={createdAt}
                                            onChange={(newValue) => {
                                                setCreatedAt(newValue);
                                            }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                                                <TextField margin="normal"  {...params} helperText={null} />
                                            } />
                                    </LocalizationProvider>
                                </Grid>
                                {action == 'create' &&
                                    <Grid item xs={6}>
                                        <TextField required value={referralId}
                                            onChange={(e) => setReferralId(e.target.value)} margin="normal" fullWidth
                                            id="referralId" select>
                                            {referrals && referrals.map(_ => (
                                                <MenuItem key={_.id} value={_.id}>
                                                    {_.number}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                }
                                {(action == 'createWithoutRequest') &&
                                    <>
                                        <Grid item xs={12}>
                                            Форма журнала
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                disablePortal
                                                name='resultFormId'
                                                value={resultForm2}
                                                onChange={(_, value) => {
                                                    setResultFormId(value?.id);
                                                    setResultForm2(value);
                                                }}
                                                noOptionsText='Ничего не найдено'
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                options={resultForms && resultForms.map(_ => ({ id: _.id, label: `${_.name}` }))}
                                                renderInput={params => <TextField {...params} label='Тип формы' />}
                                            />
                                        </Grid>
                                    </>
                                }
                                {(action == 'createByReferral' || action == 'createByObjectReferral' || action === 'createByDesinfectionRequest' ||
                                    action === 'createByServiceWithoutActRequest' || action === 'createByAct') &&
                                    <>
                                        <Grid item xs={6}>
                                            Направление №: {referral?.number}
                                        </Grid>
                                        <Grid item xs={12}>
                                            Форма журнала
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                disablePortal
                                                name='resultFormId'
                                                value={resultForm2}
                                                //onChange={(e) => setResultFormId(e.target.value)}
                                                onChange={(_, value) => {
                                                    setResultFormId(value?.id);
                                                    setResultForm2(value);
                                                }}
                                                noOptionsText='Ничего не найдено'
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                options={resultForms && resultForms.map(_ => ({ id: _.id, label: `${_.name}` }))}
                                                renderInput={params => <TextField {...params} label='Тип формы' />}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Form validator={validator}
                                        schema={currentSchema ? currentSchema.jsonSchema : {}}
                                        uiSchema={currentSchema ? currentSchema.uiSchema : {}}
                                        formData={formData}
                                        //FieldTemplate={CustomFieldTemplate}
                                        onChange={({ formData }) => setFormData(formData)}
                                        onError={err => console.error('FORM ERROR: ', err)}
                                    >
                                        <button style={{ display: 'none' }} />
                                    </Form>
                                </Grid>
                                {action != 'details' &&
                                    <Grid item xs={6}>
                                        <LoadingButton loading={submitLoading} type="submit" fullWidth variant="contained"
                                            sx={{ mt: 3, mb: 2 }}>
                                            {'Сохранить'}
                                        </LoadingButton>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
            {act?.data ? <ChooseSamples resultFormId={resultFormId} setChooseSampleObject={setChooseSampleObject}
                data={act?.data ? JSON.parse(act?.data) : {}} open={chooseSamples} setOpen={setChooseSamples} /> : null}
            {!act?.data ? <ChooseSamplesWithoutAct resultFormId={resultFormId} setChooseSampleObject={setChooseSampleObjectWithoutAct}
                data={referral} open={chooseSamplesWithoutAct} setOpen={setChooseSamplesWithoutAct} /> : null}
        </div>
    )
}
