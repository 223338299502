import React from 'react';
import RelationField from "./RelationField";
import InfectionKindCRUDTable from "../CRUD/Tables/InfectionKindCRUDTable";
import DiagnosisCRUDTable from "../CRUD/Tables/DiagnosisCRUDTable";

export const InfectionKindField = ({...props}) => <RelationField
  label={"Заболеваемость"}
  CRUDTableComponent={InfectionKindCRUDTable}
  {...props}
/>

export const DiagnosisField = ({...props}) => <RelationField
  label={"Диагноз"}
  CRUDTableComponent={DiagnosisCRUDTable}
  {...props}
/>