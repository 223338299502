import { 
    ORGANIZATION_FILIAL_MAP_GET_REQUEST, ORGANIZATION_FILIAL_MAP_GET_SUCCESS, ORGANIZATION_FILIAL_MAP_GET_ERROR, 
    ORGANIZATION_FILIAL_MAP_GET_BY_KEY_REQUEST, ORGANIZATION_FILIAL_MAP_GET_BY_KEY_SUCCESS, ORGANIZATION_FILIAL_MAP_GET_BY_KEY_ERROR, 
    ORGANIZATION_FILIAL_MAP_ADD_REQUEST, ORGANIZATION_FILIAL_MAP_ADD_SUCCESS, ORGANIZATION_FILIAL_MAP_ADD_ERROR, 
    ORGANIZATION_FILIAL_MAP_EDIT_REQUEST, ORGANIZATION_FILIAL_MAP_EDIT_SUCCESS, ORGANIZATION_FILIAL_MAP_EDIT_ERROR, 
    ORGANIZATION_FILIAL_MAP_DELETE_REQUEST, ORGANIZATION_FILIAL_MAP_DELETE_SUCCESS, ORGANIZATION_FILIAL_MAP_DELETE_ERROR
} from "../constants/organizationFilialMapConstants";

const initialState = {
    organizationFilialMaps: [],
    organizationFilialMap: {},
    loading: false,
    success: null,
    error: null,
    organizationFilialMapRequestedCount: 0,
};

export const organizationFilialMapReducer = (state = initialState, action) => {
    switch (action.type)     {
        case ORGANIZATION_FILIAL_MAP_GET_REQUEST:
            return {
                ...state,
                organizationFilialMapRequestedCount: ++state.organizationFilialMapRequestedCount,
                loading: true,
            };
        case ORGANIZATION_FILIAL_MAP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                organizationFilialMaps: action.payload,
                success: true,
            };
        case ORGANIZATION_FILIAL_MAP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ORGANIZATION_FILIAL_MAP_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORGANIZATION_FILIAL_MAP_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                organizationFilialMap: action.payload,
                success: true,
            };
        case ORGANIZATION_FILIAL_MAP_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ORGANIZATION_FILIAL_MAP_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORGANIZATION_FILIAL_MAP_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ORGANIZATION_FILIAL_MAP_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ORGANIZATION_FILIAL_MAP_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORGANIZATION_FILIAL_MAP_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ORGANIZATION_FILIAL_MAP_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ORGANIZATION_FILIAL_MAP_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ORGANIZATION_FILIAL_MAP_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ORGANIZATION_FILIAL_MAP_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
