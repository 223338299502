import React from "react";
import { Typography, Grid } from "@mui/material";

export const PageHeaderStatus = (props) => {
  const { children } = props;

  return (
    <Grid item {...props}>
      <Typography
        component="span"
        align="center"
        variant="caption"
        sx={{ margin: "0px auto" }}
      >
        Статус: {children}
      </Typography>
    </Grid>
  );
};

PageHeaderStatus.defaultProps = {};
