import React, { useCallback, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";
import { numberInputOnWheelPreventChange } from "../../functions/inputHelpers";

export const NumberField2 = ({ id, label, value, onChange, preventWheel=true, step }) => {
    return (
      <TextField
        type="number"
        variant="standard"
        value={value}
        size="small"
        InputProps={{
          inputProps: { min: 0, maxLength: 5,step: step },
        }}
        sx={{ margin: 0 }}
        onChange={onChange}
        onInput={(e) => {
          e.preventDefault();
        }}
        onWheel={(e) => preventWheel ? numberInputOnWheelPreventChange(e) : {}}
      />
    );
  };