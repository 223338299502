import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS} from "./formatters";
import XLSX from "xlsx-js-style";

const thinBorder = {style: 'thin', color: 'auto'};
const thinBorders = {top: thinBorder, bottom: thinBorder, left: thinBorder, right: thinBorder};

const centerBold10_2 = {
    alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
    border: thinBorders,
    font: {sz: 12, bold: true}
};

const header = {
    alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
    border: thinBorders,
    font: {sz: 12, bold: true},
    fill: {
        patternType: "solid",
        fgColor: {rgb: "9BC2E6"},
        bgColor: {rgb: "9BC2E6"}
    }
};

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
}

export function cloneQuery(source) {
    return {
        paging: source?.paging,
        filter: source?.filter,
        sort: source?.sort,
        options: source?.options,
    };
}

export function parseSchema(source) {
    if (!source) return source;

    if (typeof source === "string" || source instanceof String) {
        source = source.replaceAll("'", '"');

        return JSON.parse(source);
    }
    return source;
}

export const asJSON = (s) => {
  try {
    if (s?.length > 0)
        s = normalizeJson(s);

    return s ? JSON.parse(s) : null
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const asJSON2 = (s) => {
  if (s?.length > 0)
        s = normalizeJson(s);

  return s?.length ? JSON.parse(s) : s;
}

export const asJSONSafe = (s, defaultValue) => {
  try {
      if (s?.length > 0)
        s = normalizeJson(s);

      return s?.length ? JSON.parse(s) : s;
  } catch (e) {
      console.log("Error asJSONSafe:" + s);
      return defaultValue;
  }
}

export function toUIJson(source) {
    if (!source)
        return source;

    if (source && typeof source === 'string') {
        source = normalizeJson(source);

        try {
            return JSON.parse(source);
        } catch (e) {
            console.error(e);
            return null;
        }
    }
    if (source && typeof source === 'object') return source;
    return source;
}

export function normalizeJson(source) {
    if (source.indexOf('"') == 0) {
        while (source.indexOf('"') == 0) {
            source = source.replaceAll('\\\\', "\\");
            source = source.substring(1, source.length - 1);
            if (source.length > 1 && source[0] == '\\') {
                source = source.substring(1, source.length - 1);
            }
        }

        source = source.replaceAll('\\"', '"');
        if (source.indexOf('"') == 0) {
            source = source.substring(1, source.length - 1);
        }
    }

    return source;
}

//
export * from "./renderHelpers";

export function parseJsonSafe(source) {
    if (!source)
        return null;
    try {
        return JSON.parse(source);
    } catch (e) {
        console.error(e);
        return null;
    }
}

export async function fetchAsBase64(url, callback) {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        // const objUrl = window.URL.createObjectURL(blob);
        // callback(objUrl);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            var base64data = reader.result;
            callback(base64data);
        }
    } catch (e) {
        console.error(e);
    }
}

export async function fetchAsString(url, callback) {
    try {
        const response = await fetch(url);
        const result = await response.text();
        // const objUrl = window.URL.createObjectURL(blob);
        // callback(objUrl);
        // var reader = new FileReader();
        // reader.readAsDataURL(blob);
        // reader.onloadend = function () {
        //     var base64data = reader.result;
        //     callback(base64data);
        // }
        return result;
    } catch (e) {
        console.error(e);
    }
}

export async function convertProtocolToExcel(res, filter) {
    const arrayToExcel = res.map(el => {
        return {
            filial: el?.filialName || "Не задано",
            filialDivision: el?.filialDivisionName || "Не задано",
            number: el?.number || "Не задано",
            department: el?.departmentName || 'Не задано',
            state: el?.stateName || "Не задано",
            form: el?.formName || "Не задано",
            createdBy: el?.createdByName || "Не задано",
        }
    })
    const thinBorder = {style: 'thin', color: 'auto'};
    const thinBorders = {top: thinBorder, bottom: thinBorder, left: thinBorder, right: thinBorder};

    const centerBold10_2 = {
        alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
        border: thinBorders,
        font: {sz: 12, bold: true}
    };

    const header = {
        alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
        border: thinBorders,
        font: {sz: 12, bold: true},
        fill: {
            patternType: "solid",
            fgColor: {rgb: "9BC2E6"},
            bgColor: {rgb: "9BC2E6"}
        }
    };


    const wb = XLSX.utils.book_new();

    let name;

    name = `Протоколы. Отчетный период c ${formatDate(filter?.createdAt?.operand1, MOMENT_FORMAT_DATE_DMY_DOTS)} по ${formatDate(filter?.createdAt?.operand2, MOMENT_FORMAT_DATE_DMY_DOTS)}`

    const merges = [];
    const aoa = [
        [
            {t: "s", v: "Филиал", s: header},
            {t: "s", v: "Районное отделение", s: header},
            {t: "s", v: "Штрих-код", s: header},
            {
                t: "s", v: "Лаборатория", s: header
            },
            {
                t: "s", v: "Статус", s: header
            },
            {t: "s", v: "Форма", s: header},
            {t: "s", v: "Автор", s: header},
        ],
    ];
    arrayToExcel.map(element => {
        aoa.push([
            {t: "s", v: element.filial, s: centerBold10_2},
            {t: "s", v: element.filialDivision, s: centerBold10_2},
            {
                t: "s",
                v: element.number,
                s: centerBold10_2
            },
            {t: "s", v: element.department, s: centerBold10_2},
            {t: "s", v: element.state, s: centerBold10_2},
            {t: "s", v: element.form, s: centerBold10_2},
            {t: "s", v: element.createdBy, s: centerBold10_2},
        ])
    })
    const sheet = XLSX.utils.aoa_to_sheet(aoa);
    sheet['!cols'] = [{width: 40}, {width: 40}, {width: 40}, {width: 40}, {width: 40}, {width: 40}, {width: 40}];
    sheet['!rows'] = [{hpx: 40, hpt: 10}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
    sheet['!merges'] = merges;
    XLSX.utils.book_append_sheet(wb, sheet, 'Отчет');
    const output = await XLSX.write(wb, {type: 'array'});
    let blob = await new Blob([output], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    let objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.href = objectUrl
    link.download = `${name}.xlsx`
    document.body.appendChild(link)
    link.click()
    link.remove()
}

export async function convertResultToExcel(res, filter, rowName) {
    const noNeedToParse = ['filial', 'filialDivision', 'number', 'department', 'state', 'formNumber', 'createdBy', 'id']

    const arrayToExcel = res.map(el => {
        const data = JSON.parse(el.data)
        return {
            filial: el?.filialName || "Не задано",
            filialDivision: el?.filialDivisionName || "Не задано",
            number: el?.number || "Не задано",
            department: el?.departmentName || 'Не задано',
            state: el?.stateName || "Не задано",
            form: el?.formName || "Не задано",
            createdBy: el?.createdByName || "Не задано",
            ...data
        }
    })
    const rowNameExcel = []

    rowName.map(el => {
        if (!noNeedToParse.includes(el.dataIndex)) {
            rowNameExcel.push({t: "s", key: el.dataIndex, v: el.title, s: header})
        }
    })

    const wb = XLSX.utils.book_new();

    let name;

    name = `Журналы. Отчетный период c ${formatDate(filter?.createdAt?.operand1, MOMENT_FORMAT_DATE_DMY_DOTS)} по ${formatDate(filter?.createdAt?.operand2, MOMENT_FORMAT_DATE_DMY_DOTS)}`


    const merges = [];
    const aoa = [
        [
            {t: "s", key: 'filial', v: "Филиал", s: header},
            {t: "s", key: 'filialDivision', v: "Районное отделение", s: header},
            {t: "s", key: 'number', v: "Штрих-код", s: header},
            {
                t: "s", key: 'department', v: "Лаборатория", s: header
            },
            {
                t: "s", key: 'state', v: "Статус", s: header
            },
            {t: "s", key: 'form', v: "Форма", s: header},
            {t: "s", key: 'createdBy', v: "Автор", s: header},
            ...rowNameExcel
        ],
    ];

    arrayToExcel.map(element => {
        const objectToParse = Object.keys(element)

        const aoaToPush = []

        aoa[0].map(el => {
            if (element[el.key])
                aoaToPush.push({t: "s", v: element[el.key], s: centerBold10_2})
            else
                aoaToPush.push({t: "s", v: '', s: centerBold10_2})
        })

        aoa.push(aoaToPush)
    })

    console.log(aoa)

    let cols = []
    let rows = []
    const sheet = XLSX.utils.aoa_to_sheet(aoa);

    Object.keys(arrayToExcel[0]).map((el, idx) => {
        cols.push({width: 40})
        if (idx === 0) {
            rows.push({hpx: 40, hpt: 10})
        } else {
            rows.push({})
        }
    })


    sheet['!cols'] = cols;
    sheet['!rows'] = rows;
    sheet['!merges'] = merges;
    XLSX.utils.book_append_sheet(wb, sheet, 'Отчет');
    const output = await XLSX.write(wb, {type: 'array'});
    let blob = await new Blob([output], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
    let objectUrl = URL.createObjectURL(blob);
    const link = document.createElement('a')
    link.href = objectUrl
    link.download = `${name}.xlsx`
    document.body.appendChild(link)
    link.click()
    link.remove()
}

