import React from "react";
import {makeStyles} from "@mui/styles";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import CheckboxField from "../CheckboxField";
import {toUIJson} from "../../functions";

const defaultProps = {
    columnsCount: 4,
};


const CheckboxesMultiColumnField = ({
                                        label,
                                        value,
                                        onChange,
                                        options,
                                        columnsCount,
                                        disabled,
                                        ...props
                                    }) => {

    let newValue = toUIJson(value)

    const handleChange = (key, newValue) => {
        if (newValue && !(value || []).includes(key)) {
            onChange([...(value || []), key]);
        } else if (!newValue && (value || []).includes(key)) {
            value.splice(value.indexOf(key), 1);
        }
    };

    return (
        <Box>
            {label && <Typography color={"textSecondary"}>{label}</Typography>}
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
                }}
            >
                {disabled ? (options || []).filter(el => {
                    return (toUIJson(value)?.indexOf(el.label) > -1)
                }).map((opt, i) => {
                    return (
                        <CheckboxField
                            style={{padding: 2, paddingLeft: 10}}
                            label={opt.label}
                            checked={(value || []).includes(opt.value)}
                            onChange={(val) => handleChange(opt.value, val)}
                            key={i}
                            disabled={disabled}
                        />
                    );
                }) : (options || []).map((opt, i) => {
                    return (
                        <CheckboxField
                            style={{padding: 2, paddingLeft: 10}}
                            label={opt.label}
                            checked={(value || []).includes(opt.value)}
                            onChange={(val) => handleChange(opt.value, val)}
                            key={i}
                            disabled={disabled}
                        />
                    );
                })}
            </Box>
        </Box>
    );
};

CheckboxesMultiColumnField.defaultProps = defaultProps;

export default CheckboxesMultiColumnField;
