import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { EnhancedCRUDTable } from '..';
import { checkRoles } from "../../../functions/checkRoles";
import { formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS } from "../../../functions/formatters";
import { desinfectionReferenceCRUD } from '../../../http';
import CRUDTable from "../CRUDTable";

export default function DesinfectionReferenceCRUDTable({ ...props }) {
  const { t } = useTranslation();
  const { push } = useHistory()
  const { pathname } = useLocation()
  const columns = [
    { title: "№", dataIndex: 'id', key: 'id' },
    { title: "Создано", dataIndex: 'createdAt', key: 'createdAt', render: row => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) },
    { title: "Номер", dataIndex: 'number', key: 'number' },
    { title: "Фамилия, имя, отчество (при наличии)", dataIndex: 'fullName', key: 'fullName'
    },
    { title: "Диагноз", dataIndex: 'diagnosis', key: 'diagnosis', render: (unknown, row) => {
      const diagnosis = typeof row.diagnosis === 'string' ? JSON.parse(row.diagnosis) : {}
      return diagnosis?.name ? diagnosis.name : <span style={{ color: "gray" }}>Не задан</span> }},
    {
      title: "Дезинфекцию проводил", dataIndex: 'disinfector', key: 'disinfector', render: (unknown, row) => row.disinfector?.fullName ? row.disinfector.fullName : <span style={{ color: "gray" }}>Не задан</span> 
    },
    {
      title: "ФИО (при наличии) владельца квартиры", dataIndex: 'homeOwner', key: 'homeOwner', render: (unknown, row) => {
        const data = typeof row.data === 'string' ? JSON.parse(row.data) : {}
        return data?.homeOwner?.value ? data.homeOwner.value : <span style={{ color: "gray" }}>Не задан</span> 
      }
    },
    { title: "Статус", dataIndex: 'test', key: 'test', render: (unknown, row) => row.state ? row.state.name : <span style={{ color: "gray" }}>Не задан</span> },
    // {title: "rpnId", dataIndex: 'rpnId', key: 'rpnId'},
  ];

  return <EnhancedCRUDTable
    search={desinfectionReferenceCRUD.search}
    columns={columns}
    onRowClick={(object) => { push(`${pathname}Detail/${object.id}`) }}
    onClickCreateButton={checkRoles('SuperAdministrator', 'Administrator', 'Specialist') ? () => push(`${pathname}Detail/new`) : null}
    {...props}
  />
}
