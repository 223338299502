import { confirmDialog } from "../../components/Dialog/DialogDelete";
import { PRESCRIPTION } from "../../routes";
import { Button } from "@mui/material";
import React from "react";
import {
    REQUEST_STATE_ID_COMPLETED,
    REQUEST_STATE_ID_CREATED,
    REQUEST_STATE_ID_DECLINED,
    REQUEST_STATE_ID_PROCESSING
} from "../../redux/constants/requestStateConstants";
import { commentDialog } from "../../components/Dialog/CommentDialog";
import { addRange } from "../../functions/linqSimulation";


export const controlsKSEK = (row, reload, push, crud, returnRoute, handleGenerateObjectReferral, type) => {
    const processingButtons = [];
    if (type == 'Prescription') {
        processingButtons.push(<Button onClick={async()=> await handleGenerateObjectReferral()}
            >Создать
            заявку по ГЗ</Button>);
    }
    if (row?.protocols?.length > 0) {
        processingButtons.push(<Button onClick={async () => {
            confirmDialog('Завершить', 'Вы действительно хотите завершить?', async () => {
                try {
                    await crud.workflowRun(row?.id, REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_COMPLETED)
                    reload()
                } catch (e) {
                    console.log(e);
                    alert.show(0, 'Не удалось завершить, ошибка ' + e);
                }
            })
        }} id="complete">
            Завершить
        </Button>);
    }
    addRange(processingButtons, [
    <Button
        color="warning"
        onClick={() => {
            commentDialog('Изменение статуса', 'Укажите комментарий для отклонения.', async (_) => {
                await crud.workflowRun(row?.id, REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_DECLINED);
                reload();
            });
        }
        }
    >
        Отклонить с комментариями
    </Button>,
    <Button
        variant={"text"}
        onClick={() => push(returnRoute || PRESCRIPTION)}
    >
        Вернуться
    </Button>]);    

    return [
    {
        state: "created",
        roles: [],
        buttons: [
            <Button onClick={async () => {
                confirmDialog('Отправка в НЦЭ', 'Вы действительно хотите отправить эту заявку в НЦЭ?', async () => {
                    try {
                        await crud.workflowRun(row?.id, 1, 2)
                        reload()
                    } catch (e) {
                        console.log(e);
                        alert.show(0, 'Не удалось удалить, ошибка ' + e);
                    }
                })
            }} id="deleteReferal">
                Отправить в НЦЭ
            </Button>,
            <Button
                variant={"text"}
                onClick={() => push(returnRoute || PRESCRIPTION)}
            >
                Вернуться
            </Button>
        ],
    },
    {
        state: "processing",
        roles: [],
        buttons: processingButtons        
    },
    {
        state: "completed",
        roles: [],
        buttons: [
            <Button
                variant={"text"}
                onClick={() => push(returnRoute || PRESCRIPTION)}
            >
                Вернуться
            </Button>
        ],
    },
    {
        state: "declined",
        roles: [],
        buttons: [
            <Button
                color="warning"
                onClick={async () => {
                    await crud.workflowRun(row?.id, REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_CREATED);
                    reload();
                }
                }
            >
                Пересоздать
            </Button>,
            <Button
                variant={"text"}
                onClick={() => push(returnRoute || PRESCRIPTION)}
            >
                Вернуться
            </Button>
        ],
    }
]
};
