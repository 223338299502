import React from "react";

import { PageContainer, PageHeader } from "../../../components";
import { KDIRequestCRUDTable } from "../../../components/CRUD/Tables";
import DashboardLayout from "../../../layouts/Dashboard";
import useReload from "../../../hooks/useReload";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";
import {contractRequestCRUD, KDIRequestCRUD, resultCRUD} from "../../../http";
import {checkRoles} from "../../../functions/checkRoles";

export const KDIRequestTable = () => {
    const { reload, reloadValue } = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление заявки КДИ, ID:' + _, 'Вы действительно хотите удалить эту заявку?', async () => {
            await KDIRequestCRUD.delete(_);
            await reload();
        })
    }

    return (<DashboardLayout>
        <PageHeader heading="Заявки на КДИ"/>
        <PageContainer>
            <KDIRequestCRUDTable onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} reloadValue={reloadValue}/>
        </PageContainer>
    </DashboardLayout>)
};
