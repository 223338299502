import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function ActPDF({ data, children }) {
  const formatTime = Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format

  return (
    <Box >
      <Typography variant="h6" component="h2">
        Просмотр
      </Typography>
      <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Номер</span>: {data.number}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Создано</span>: {data.createdAt ? formatTime(new Date(data.createdAt)) : ' '}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Филиал</span>: {data.filial?.name}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Районнное отделение</span>: {data.filialDivision?.name}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Лаборатория</span>: {data.department?.name}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Отдел</span>: {data.division?.name}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Ответственный за отбор проб</span>: {data.responsibleForProbes?.fullName}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Заведующий лабораторией</span>: {data.responsible?.fullName}
          </Grid>
          <Grid item xs={6}>
            <span style={{ fontWeight: 500 }}>Исполнитель (лаборант)</span>: {data.performer?.fullName}
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
