export const EMPLOYEE_GET_REQUEST = "EMPLOYEE_GET_REQUEST";
export const EMPLOYEE_GET_SUCCESS = "EMPLOYEE_GET_SUCCESS";
export const EMPLOYEE_GET_ERROR = "EMPLOYEE_GET_ERROR";
export const EMPLOYEE_GET_BY_KEY_REQUEST = "EMPLOYEE_GET_BY_KEY_REQUEST";
export const EMPLOYEE_GET_BY_KEY_SUCCESS = "EMPLOYEE_GET_BY_KEY_SUCCESS";
export const EMPLOYEE_GET_BY_KEY_ERROR = "EMPLOYEE_GET_BY_KEY_ERROR";
export const EMPLOYEE_ADD_REQUEST = "EMPLOYEE_ADD_REQUEST";
export const EMPLOYEE_ADD_SUCCESS = "EMPLOYEE_ADD_SUCCESS";
export const EMPLOYEE_ADD_ERROR = "EMPLOYEE_ADD_ERROR";
export const EMPLOYEE_EDIT_REQUEST = "EMPLOYEE_EDIT_REQUEST";
export const EMPLOYEE_EDIT_SUCCESS = "EMPLOYEE_EDIT_SUCCESS";
export const EMPLOYEE_EDIT_ERROR = "EMPLOYEE_EDIT_ERROR";
export const EMPLOYEE_DELETE_REQUEST = "EMPLOYEE_DELETE_REQUEST";
export const EMPLOYEE_DELETE_SUCCESS = "EMPLOYEE_DELETE_SUCCESS";
export const EMPLOYEE_DELETE_ERROR = "EMPLOYEE_DELETE_ERROR";

export const EMPLOYEE_GET_AUTHORIZED_REQUEST =
  "EMPLOYEE_GET_AUTHORIZED_REQUEST";
export const EMPLOYEE_GET_AUTHORIZED_SUCCESS =
  "EMPLOYEE_GET_AUTHORIZED_SUCCESS";
export const EMPLOYEE_GET_AUTHORIZED_ERROR = "EMPLOYEE_GET_AUTHORIZED_ERROR";
