import React from "react";
import DashboardLayout from "../../layouts/Dashboard";
import AccreditationCRUDTable from "../../components/CRUD/Tables/AccreditationCRUDTable";
import {PLAN_EDIT_ROUTE} from "../../routes";
import {divisionCRUD, filialCRUD, planCRUD} from "../../http";
import {useHistory} from "react-router-dom";
import useReload from "../../hooks/useReload";
import PlanCRUDTable from "../../components/CRUD/Tables/PlanCRUDTable";

const PlanList = () => {
    const history = useHistory()
    const {reload, reloadValue} = useReload();
    const detailAction = (id) => {
        history.push(PLAN_EDIT_ROUTE(id), {
            review: true
        })
    }

    const editAction = (id) => {
        history.push(PLAN_EDIT_ROUTE(id), {
            review: false
        })
    }

    const deleteAction = async (id) => {
        try {
            await planCRUD.delete(id)
            reload()
        } catch (err) {

        }
    }
    return (
        <DashboardLayout>
            <PlanCRUDTable 
                withOrganization={true}
                onClickDetailsButton={detailAction}
                             onClickDeleteButton={deleteAction}
                             onClickEditButton={editAction} reloadValue={reloadValue}/>
        </DashboardLayout>
    )
};

export default PlanList;
