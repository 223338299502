import React, {useEffect, useMemo, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  workflowClinicalRequest,
} from "../../../redux/actions";
import {
  PageHeader,
} from "../../../components";

import DashboardLayout from "../../../layouts/Dashboard";

import { KDIRequestInfoSection } from "./Sections";
import {
  getClinicalRequestByKey,
} from "../../../redux/actions/";

import {renderControls, RequestStateChecker} from "./../../../functions";
import {KDIRequestCRUD} from "../../../http";
import {CONTRACT_REQUEST, KDI_REQUEST} from "../../../routes";
import {REQUEST_STATE_ID_CREATED} from "../../../redux/constants/requestStateConstants";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";

export const KDIRequestInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory()

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("read");

  const [requestId] = useState(id);
  const [stateId, setStateId] = useState(0);
  const [request, setRequest] = useState({});

  const { clinicalRequest } = useSelector((s) => s.clinicalRequest);

  const stateCode = clinicalRequest?.state?.code?.toLowerCase();

  const state = useMemo(
      () => new RequestStateChecker(clinicalRequest?.state?.code),
      [clinicalRequest?.state?.code]
  );

  const isEditMode = mode === "edit";

  const handleSwitchMode = (e, mode) => {
    e.preventDefault();

    switch (mode) {
      case "read":
        return setMode("read");
      case "edit":
        return setMode("edit");
      default:
        return setMode("read");
    }
  };

  const handleWorkflow = async (e, nextStateId) => {
    e.preventDefault();
    setLoading(true);

    if (!clinicalRequest?.state?.id) return setLoading(false);

    const prevStateId = clinicalRequest.state.id;

    // Updating Workflow
    const updated = await dispatch(
      workflowClinicalRequest(id, prevStateId, nextStateId)
    );
    if (!updated) return;

    setStateId(nextStateId);
    setLoading(false);
  };

  const handleWorkflowDeclinedToCreate = async () => {
    setLoading(true);

    if (!clinicalRequest?.state?.id) return setLoading(false);

    const prevStateId = clinicalRequest.state.id;
    const nextStateId = state.getId(REQUEST_STATE_ID_CREATED);

    // Updating Workflow
    const updated = await dispatch(
        workflowClinicalRequest(requestId, prevStateId, nextStateId)
    );
    if (!updated) return setLoading(false);

    setStateId(nextStateId);
    setLoading(false);
  };


  const handleEdit = async () => {
    console.log(request)
    await KDIRequestCRUD.edit({...request})
  }

  const controls = [
    {
      state: "created",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 2)}
        >
          Начать обработку
        </LoadingButton>,
        <>
          {isEditMode ? (
            <Button
              color="secondary"
              onClick={(e) => handleSwitchMode(e, "read")}
            >
              Просмотр
            </Button>
          ) : (
            <Button
              color="secondary"
              onClick={handleEdit}
            >
              Сохранить
            </Button>
          )}
        </>,
        <Button
            variant={"text"}
            onClick={() => history.push(KDI_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "processing",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 4)}
        >
          Завершить
        </LoadingButton>,
        <LoadingButton color="warning" onClick={(e) => handleWorkflow(e, 5)}>
          Отклонить с комментариями
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(KDI_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "completed",
      roles: [],
      buttons: [
        <Button
            variant={"text"}
            onClick={() => history.push(KDI_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "declined",
      roles: [],
      buttons: [
        <LoadingButton
            loading={loading}
            onClick={() => {
              confirmDialog('Пересоздание', 'Вы действительно хотите пересоздать заявку?', async () => {
                await handleWorkflowDeclinedToCreate()
              })
            }
            }
        >
          Пересоздать
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    }

  ];

  useEffect(() => {
    if (!requestId) return;
    dispatch(getClinicalRequestByKey(requestId)).then(setRequest);
  }, [dispatch, requestId, stateId]);

  return (
    <DashboardLayout>
      <PageHeader
        heading={`КДИ заявка №${id} ${
          stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
        }`}
        status={clinicalRequest.state?.name}
        controls={renderControls(controls, {
          state: stateCode,
          roles: [],
        })}
      />
      <KDIRequestInfoSection request={request} setRequest={setRequest}/>
    </DashboardLayout>
  );
};
