import React, {useEffect, useRef, useState} from 'react';
import {Box, TextField} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useHistory} from "react-router-dom";
import {ListItemText, ListItemIcon, ListItemButton, ListItem} from '@mui/material';
import List from "@mui/material/List";
import SearchIcon from '@mui/icons-material/Search';


const SearchPanel = ({label, search, searchName}) => {
    const [focused, setFocused] = useState(false)
    const [searchState, setSearchState] = useState('')
    const [searchResult, setSearchResult] = useState(search)
    useEffect(() => {
        document.body.addEventListener('click', handleOutsideClick)
        return () => {
            document.body.removeEventListener('click', handleOutsideClick)
        }
    })
    const panel = useRef(null);
    const handleOutsideClick = async (e) => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (!path.includes(panel.current)) {
            setFocused(false)
        }
    }
    const history = useHistory()
    useEffect(() => {
        const results = search.filter(elem => {
            return elem[searchName]?.toString().toLowerCase().includes(searchState.toLowerCase())
        })
        setSearchResult(results);
    }, [search, searchName, searchState]);

    return (
        <Paper sx={{padding: '10px 10px 0 20px', display: 'inline-flex'}} square>
            <Box ref={panel} sx={{display: 'inline-flex', flexDirection: 'column', position: 'relative'}}>
                <TextField
                    onFocus={() => setFocused(true)}
                    variant="standard"
                    onChange={(e) => setSearchState(e.target.value)}
                    sx={{width: '300px'}}
                    label={label}
                    name="client"/>
                {focused && <Box sx={{position: 'absolute', width: '300px', top: '49px', zIndex: '5'}}>
                    <Paper>
                        <nav aria-label="main mailbox folders">
                            <List>
                                {searchResult && searchResult.map((el, idx) => (
                                    <ListItem onClick={(e) => history.push('client/' + idx)} key={idx} disablePadding>
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <SearchIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={el.name}/>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </nav>
                    </Paper>
                </Box>}
            </Box>
        </Paper>
    );
};

export default SearchPanel;