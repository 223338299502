export const ACT_FORM_GET_REQUEST = 'ACT_FORM_GET_REQUEST';
export const ACT_FORM_GET_SUCCESS = 'ACT_FORM_GET_SUCCESS';
export const ACT_FORM_GET_ERROR = 'ACT_FORM_GET_ERROR';
export const ACT_FORM_GET_BY_KEY_REQUEST = 'ACT_FORM_GET_BY_KEY_REQUEST';
export const ACT_FORM_GET_BY_KEY_SUCCESS = 'ACT_FORM_GET_BY_KEY_SUCCESS';
export const ACT_FORM_GET_BY_KEY_ERROR = 'ACT_FORM_GET_BY_KEY_ERROR';
export const ACT_FORM_ADD_REQUEST = 'ACT_FORM_ADD_REQUEST';
export const ACT_FORM_ADD_SUCCESS = 'ACT_FORM_ADD_SUCCESS';
export const ACT_FORM_ADD_ERROR = 'ACT_FORM_ADD_ERROR';
export const ACT_FORM_EDIT_REQUEST = 'ACT_FORM_EDIT_REQUEST';
export const ACT_FORM_EDIT_SUCCESS = 'ACT_FORM_EDIT_SUCCESS';
export const ACT_FORM_EDIT_ERROR = 'ACT_FORM_EDIT_ERROR';
export const ACT_FORM_DELETE_REQUEST = 'ACT_FORM_DELETE_REQUEST';
export const ACT_FORM_DELETE_SUCCESS = 'ACT_FORM_DELETE_SUCCESS';
export const ACT_FORM_DELETE_ERROR = 'ACT_FORM_DELETE_ERROR';
