import { protocolCRUD } from "../../http";
import {
  PROTOCOL_GET_REQUEST,
  PROTOCOL_GET_SUCCESS,
  PROTOCOL_GET_ERROR,
  PROTOCOL_GET_BY_KEY_REQUEST,
  PROTOCOL_GET_BY_KEY_SUCCESS,
  PROTOCOL_GET_BY_KEY_ERROR,
  PROTOCOL_ADD_REQUEST,
  PROTOCOL_ADD_SUCCESS,
  PROTOCOL_ADD_ERROR,
  PROTOCOL_EDIT_REQUEST,
  PROTOCOL_EDIT_SUCCESS,
  PROTOCOL_EDIT_ERROR,
  PROTOCOL_DELETE_REQUEST,
  PROTOCOL_DELETE_SUCCESS,
  PROTOCOL_DELETE_ERROR,
} from "../constants/protocolConstants";

export const getProtocols = (args) => async (dispatch) => {
  try {
    dispatch({
      type: PROTOCOL_GET_REQUEST,
    });

    const data = await protocolCRUD.search(args);
    dispatch({
      type: PROTOCOL_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: PROTOCOL_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getProtocolByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PROTOCOL_GET_BY_KEY_REQUEST,
    });

    const data = await protocolCRUD.get(id);
    dispatch({
      type: PROTOCOL_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: PROTOCOL_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addProtocol = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: PROTOCOL_ADD_REQUEST,
    });

    const response = await protocolCRUD.create(data);
    await dispatch(getProtocols(args));

    dispatch({
      type: PROTOCOL_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: PROTOCOL_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editProtocol = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: PROTOCOL_EDIT_REQUEST,
    });

    await protocolCRUD.edit(data);
    await dispatch(getProtocols(args));

    dispatch({
      type: PROTOCOL_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROTOCOL_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteProtocol = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: PROTOCOL_DELETE_REQUEST,
    });

    await protocolCRUD.delete(id);
    await dispatch(getProtocols(args));

    dispatch({
      type: PROTOCOL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROTOCOL_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
