import {LOGIN_FAIL, LOGIN_REQUEST, LOGIN_RESET, LOGIN_SET, LOGIN_SUCCESS} from "../constants/authConstants";
import {$authHost, $host} from "../../http";
import jwtDecode from "jwt-decode";
import {LOGIN_ROUTE} from "../../routes/const";

export const login = (userName, password, xml, signedXml) => async (dispatch) => {
    try {
        dispatch({
            type: LOGIN_REQUEST,
        });

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const request = { userName: userName, password: password};

        if (xml && signedXml) {
            request.signature = {
                xml,
                signedXml
            }
        }

        const { data } = await $host.post(
            "api/v1/authenticate",
            request,
            config
        );
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("roles", JSON.stringify(data.roles));
        const info = {
            userName: userName
        }
        localStorage.setItem("userInfo", JSON.stringify(info));
        localStorage.setItem("employee", JSON.stringify(data.employee));
        await dispatch({
            type: LOGIN_SET,
            payload: info
        });
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
        return error
    }
};

export const check = () => async (dispatch) => {
    try {
        const token = localStorage.getItem('token');

        // const inf = JSON.parse(localStorage.getItem('userInfo'))
        // const refreshToken = localStorage.getItem('refreshToken')
        // const { data } = await $authHost.post("/api/v1/refreshToken",
        //     { username: inf.userName, refreshToken});
        // localStorage.setItem("refreshToken", data.refreshToken);
        // localStorage.setItem("token", data.token);
        if (token) {
            dispatch({
                type: LOGIN_SUCCESS,
            });
        } else {
            dispatch(logout());
        }
    } catch (error) {
        dispatch(logout())
    }
};

export const logout = () => async (dispatch) => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("userInfo");
    await localStorage.removeItem("refreshToken");
    await dispatch({ type: LOGIN_RESET });
    return LOGIN_ROUTE
};