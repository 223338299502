import {getContractStates, getFilials} from "../../redux/actions";
import {getRequestStates} from "../../redux/actions/requestStateActions";
import {getFilialDivisions} from "../../redux/actions/filialDivisionActions";

export const contractRequestSearchFields = [
    {
        title: "Номер",
        key: "number",
        compareType: "like",
        operandPosition:1,
        filterType:"normal"
    }
];

export const contractRequestFilterFields = [
    {
        title: "Cтатус",
        key: "stateId",
        compareType: "equals",
        reducerKey: "requestState",
        reducerArrayKey: "requestStates",
        reducerAction: getRequestStates,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Филиал",
        key: "filialId",
        compareType: "equals",
        reducerKey: "filial",
        reducerArrayKey: "filials",
        reducerAction: getFilials,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Районное отделение",
        key: "filialDivisionId",
        compareType: "equals",
        reducerKey: "filialDivision",
        reducerArrayKey: "filialDivisions",
        reducerAction: getFilialDivisions,
        operandPosition: 1,
        parentKey: 'filialId',
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    }
]