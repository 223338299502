import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Grid, FormControl, FormLabel, TextField, Divider, DialogContent, Dialog, Typography} from "@mui/material";
import TarifficationPdf from "./TarifficationPDF";
import DialogTitle from "@mui/material/DialogTitle";
import {FilialSelect} from "../../components/Select/domainSelects";
import Button from "@mui/material/Button";
import {requestTarificationCRUD} from "../../http";
import Box from "@mui/material/Box";
import {LoadingOutlined} from "@ant-design/icons";
import { calculateTarficiationGroups } from '../../models/data/EntityCollectionModel';

function PDFDocument({...props}) {
    return (
        <TarifficationPdf {...props}/>
    )
}

export default function TarifficationPDFGenerator(props) {

    const handleClose = () => {
        props.handleClose()
    }

    const [loading, setLoading] = useState(false)
    const [initialValue, setInitialValue] = useState([])
    const [groupedData, setGroupedData] = useState([]);

    useEffect(async () => {
        setLoading(true)

        // const researchEndDateTime1 = withoutTime(props.dataFromFilterCrud?.researchEndDateTime1);
        // const researchEndDateTime2 = withoutTime(props.dataFromFilterCrud?.researchEndDateTime2);

        const filter = {
            researchEndDateTime: {
                operand1: props.dataFromFilterCrud?.researchEndDateTime1,
                operand2: props.dataFromFilterCrud?.researchEndDateTime2,
                operator: "between"
            },
        };
        if (props.dataFromFilterCrud?.contract) {
            filter.contractId = {operand1: props.dataFromFilterCrud?.contract, operator: "equals"};
        }
        if (props.dataFromFilterCrud?.filial) {
            filter.filialId = {operand1: props.dataFromFilterCrud?.filial, operator: "equals"};
        }
        if (props.dataFromFilterCrud?.filialDivision) {
            filter.filialDivisionId = {operand1: props.dataFromFilterCrud?.filialDivision, operator: "equals"}
        }
        if (props.dataFromFilterCrud?.department) {
            filter.departmentId = {operand1: props.dataFromFilterCrud?.department, operator: "equals"}
        }
        if (props.dataFromFilterCrud?.division) {
            filter.divisionId = {operand1: props.dataFromFilterCrud?.division, operator: "equals"}
        }
        if (props.dataFromFilterCrud?.serviceGroup) {
            filter.groupId = {operand1: props.dataFromFilterCrud?.serviceGroup, operator: "equals"}
        }
        if (props.dataFromFilterCrud?.serviceSubGroup) {
            filter.subGroupId = {operand1: props.dataFromFilterCrud?.serviceSubGroup, operator: "equals"}
        }
        if (props.dataFromFilterCrud?.serviceKind) {
            filter.methodId = {operand1: props.dataFromFilterCrud?.serviceKind, operator: "equals"}
        }
        const search = await requestTarificationCRUD.post('summary/query', {
            paging: {skip: 0},
            filter: filter
        });
        
        await setInitialValue(search);
        await setGroupedData(calculateTarficiationGroups(search, props.dataFromFilterCrud?.researchEndDateTime1));
        setLoading(false)
    }, [props.open])


    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            fullWidth
            PaperProps={{sx: {maxHeight: '85%'}}}
            maxWidth={"80%"}
        >
            <DialogTitle>Генерация отчёта</DialogTitle>
            <Divider/>
            <DialogContent>
                { loading ? <LoadingOutlined/> :
                   (initialValue?.length ? <Box sx={{width: '100%'}}>
                       <PDFViewer style={{width: '100%', minHeight: '600px'}}>
                           <PDFDocument 
                            filials={props.filials}
                            filialDivisions={props.filialDivisions}
                            contracts={props.contracts}
                            filter={props.dataFromFilterCrud} initialValue={initialValue}
                            groupedData={groupedData}/>
                       </PDFViewer>
                   </Box> : <Box>
                       <Typography>Отчётов по этим данным нет</Typography>
                   </Box>)
                }
            </DialogContent>
        </Dialog>
    )
}
