import { $authHost } from "../../http";
import { 
    FORM_MAP_GET_REQUEST, FORM_MAP_GET_SUCCESS, FORM_MAP_GET_ERROR, 
    FORM_MAP_GET_BY_KEY_REQUEST, FORM_MAP_GET_BY_KEY_SUCCESS, FORM_MAP_GET_BY_KEY_ERROR, 
    FORM_MAP_ADD_REQUEST, FORM_MAP_ADD_SUCCESS, FORM_MAP_ADD_ERROR, 
    FORM_MAP_EDIT_REQUEST, FORM_MAP_EDIT_SUCCESS, FORM_MAP_EDIT_ERROR, 
    FORM_MAP_DELETE_REQUEST, FORM_MAP_DELETE_SUCCESS, FORM_MAP_DELETE_ERROR
} from "../constants/formMapConstants";

export const getFormMaps = (args) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_MAP_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/formMap/query",
            args || {},
            config,
        );
        dispatch({
            type: FORM_MAP_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: FORM_MAP_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getFormMapByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_MAP_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/formMap/${id}`,
        );
        dispatch({
            type: FORM_MAP_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: FORM_MAP_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addFormMap = ({ departmentId, referralFormId, resultFormId, protocolFormId,brigadeTypeId  }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_MAP_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/formMap",
            { departmentId, referralFormId, resultFormId, protocolFormId,brigadeTypeId },
            config,
        );
        dispatch(getFormMaps(queryArgs || {}));
        dispatch({
            type: FORM_MAP_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FORM_MAP_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editFormMap = ({ id, departmentId, referralFormId, resultFormId, protocolFormId, brigadeTypeId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_MAP_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/formMap",
            { id, departmentId, referralFormId, resultFormId, protocolFormId, brigadeTypeId },
            config,
        );
        dispatch(getFormMaps(queryArgs || {}));
        dispatch({
            type: FORM_MAP_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FORM_MAP_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });

        throw error
    }
};

export const deleteFormMap = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: FORM_MAP_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/formMap",
            { data: { id } },
            config,
        );
        dispatch(getFormMaps(queryArgs || {}));
        dispatch({
            type: FORM_MAP_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: FORM_MAP_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

