import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl, InputLabel, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import { Table } from "../../../components/PDFGenerator/Table";
import { GovernmentStandardsSelect, TransportationConditionSelect } from "../../../components/Select/domainSelects";

const DirectionSamplingForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>1. Тіркеу № (регистрационный №)</p>
        <TextField
          id="outlined-basic"
          label="регистрационный №"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.registrationNumber?.value}
          type="number"
          onChange={(e) => handleChangeData('registrationNumber', e.target.value, 'registrationNumber')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Материал жіберілетін зертханасының атауы (Наименование
          лаборатории, в которую направляется материал)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование лаборатории, в которую направляется материал"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.laboratory?.value}
          onChange={(e) => handleChangeData('laboratory', e.target.value, 'laboratory')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Сынаманың атауы және саны (Наименование и число образцов)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование и число образцов"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.samplesInfo?.value}
          onChange={(e) => handleChangeData('samplesInfo', e.target.value, 'samplesInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          4. Сынаманы жіберген мекеменің атауы (Наименование учреждения
          направившего образец)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование учреждения направившего образец"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.placeSender?.value}
          onChange={(e) => handleChangeData('placeSender', e.target.value, 'placeSender')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5. Сынама алынған орын, уақыты, мезгілі (Место, дата и время
          отбора)
        </p>
        <TextField
          id="outlined-basic"
          label="Место, дата и время отбора"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.selectionDatePlace?.value}
          onChange={(e) => handleChangeData('selectionDatePlace', e.target.value, 'selectionDatePlace')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Сынамаларды вирусологиялық зертханаға жеткізу уақыты және
          шарттары (Время и условия доставки проб в вирусологическую
          лабораторию)
        </p>
        <TextField
          id="outlined-basic"
          label="Время и условия доставки проб в вирусологическую лабораторию"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.samplesConditions?.value}
          onChange={(e) => handleChangeData('samplesConditions', e.target.value, 'samplesConditions')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Зерттеу мақсаты (Цель исследования)
        </p>
        <TextField
          id="outlined-basic"
          label="Цель исследования"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.researchPurpose?.value}
          onChange={(e) => handleChangeData('researchPurpose', e.target.value, 'researchPurpose')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Зерттеуге дәлелдеме: ағымдығы санитариялық қадағалау
          тәртібімен, эпидемиологиялық көрсеткіші бойынша (астын сызу немесе
          қосып жазу) (Показания к исследованию: в порядке текущего
          санитарного надзора, по эпидемиологическим показаниям )
        </p>
        <TextField
          id="outlined-basic"
          label="Показания к исследованию: в порядке текущего санитарного надзора, по эпидемиологическим показаниям"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.researchIndications?.value}
          onChange={(e) => handleChangeData('researchIndications', e.target.value, 'researchIndications')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Зерттелушінің Т.А.Ә (болған жағдайда) (Ф.И.О (при наличии)
          обследуемого)
        </p>
        <TextField
          id="outlined-basic"
          label="Ф.И.О (при наличии) обследуемого"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.examineName?.value}
          onChange={(e) => handleChangeData('examineName', e.target.value, 'examineName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>10. Жасы (Возраст)</p>
        <TextField
          id="outlined-basic"
          label="Возраст"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.age?.value}
          onChange={(e) => handleChangeData('age', e.target.value, 'age')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          11. Зерттелушінің ЖСН (ИИН обследуемого)
        </p>
        <TextField
          id="outlined-basic"
          label="ИИН обследуемого"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.IIN?.value}
          onChange={(e) => handleChangeData('IIN', e.target.value, 'IIN')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>12. Мекен жайы (Домашний адрес)</p>
        <TextField
          id="outlined-basic"
          label="Домашний адрес"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.homeAdress?.value}
          onChange={(e) => handleChangeData('homeAdress', e.target.value, 'homeAdress')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>13. Жұмыс орны (Место работы)</p>
        <TextField
          id="outlined-basic"
          label="Место работы"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.workPlace?.value}
          onChange={(e) => handleChangeData('workPlace', e.target.value, 'workPlace')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>14. Диагноз (Диагноз)</p>
        <TextField
          id="outlined-basic"
          label="Диагноз"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.diagnosis?.value}
          onChange={(e) => handleChangeData('diagnosis', e.target.value, 'diagnosis')}
        />
      </div>
    </div >
  );
};

export default DirectionSamplingForm;
