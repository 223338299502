export const probeCodes = [
    'мазок',
    'Мазок из зева',
    'Мазок из носа',
    'Мазок из зева и носа',
    'Мокрота',
    'Эндотрахеальный аспират',
    'Трупный материал',
    'Моча',
    'Кал',
    'Кровь',
    'Мазок из горла',
    'Отделяемые от раны',
    'Мазок из ушной раковины',
    'Грудное молоко',
    'Спинно-мозговая жидкость',
    'Желчь',
    'Отделяемый от дыхательных путей',
    'Прочие'
]

export const patientStatuses = [
    'Больной',
    'Близкий контакт',
    'Потенциальный контакт (организ.коллективы)',
    'Вновь прибывший (из зарубежа)',
    'Плановый',
    'Медработники'
]

export const probeStatuses = [
    'первичный',
    'повторный',
    'контроль лечения'
]

export const clinicalSymptoms = [
    'Нет',
    'Есть'
]

export const expressTests = [
    'Отрицательный',
    'Положительный',
    'Не проводился'
]

export const researcherStatuses1 = [
    'По эпидемиологическим показаниям',
    'С профилактической целью',
    'С целью эпидемиологического надзора'
]

export const researcherStatuses2 = [
    'Больной COVID – 19',
    'Самообращение с признаками не исключающие COVID-19, Пневмония',
    'Самообращение с признаками не исключающие COVID-19, ТОРИ',
    'Самообращение с признаками не исключающие COVID-19, ОРВИ',
    'По эпидемиологическим показаниям',
    'БК, Самообращения (эпид показания)',
    'БК, Профилактических скринингов',
    'БК, Проф.скрининг вновь прибывших из-за рубежа',
    'БК, Эпидемиологического надзора',
    'ПК, Самообращения (эпид показания)',
    'ПК, Профилактических скринингов',
    'ПК, Проф.скрининг вновь прибывших из-за рубежа',
    'ПК, Эпидемиологического надзора'
]

export const researcherStatuses3 = [
    'Вновь прибывший (из зарубежа)',
    'Медицинские работники (плановое исследование)',
    'Социальные работники',
    'Призывники МО',
    'Призывники МВД',
    'Призывники Нац. Гвардия',
    'Другие'
]

export const researcherStatuses4 = [
    'Пациенты при экстренной госпитализации в стационар',
    'Пациенты при плановой госпитализации в стационар',
    'Беременные пациенты при экстренной госпитализации в стационар',
    'Беременные пациенты при плановой госпитализации в стационар',
    'Пациенты, находящиеся на гемодиализе',
    'Лица, вновь поступающие МСУ и др. закрытые учреждения',
    'в рамках ДЭН, состоит на Д-учете (да/нет, если да, то по какому заболеванию)'
]