import React from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS} from "../../../functions/formatters";
import {actCRUD} from '../../../http';
import CRUDTable from "../CRUDTable";
import {actsFilterFields, actsSearchFields} from "../../../sections/Filters/ActsFilters";
import {State} from "../../../functions";

export default function ActCRUDTable({...props}) {
    const {push} = useHistory()
    const {pathname} = useLocation()
    let isDSEK = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.includes('DSEK')

    const columns = [
        {title: "№", dataIndex: 'id', key: 'id'},
        {
            title: "Создано",
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: row => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS)
        },
        {title: "Номер", dataIndex: 'number', key: 'number'},
        {
            title: "Наименование объекта", dataIndex: 'test', key: 'test', render: (unknown, row) => {
                const data = typeof row.data === 'string' ? JSON.parse(row.data) : {}
                return data?.object?.value?.name ? data.object.value.name :
                    <span style={{color: "gray"}}>Не задан</span>
            }
        },
        {
            title: "Филиал",
            dataIndex: 'filial',
            key: 'filial',
            render: (unknown, row) => row.filial ? row.filial.name : <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Исполнитель", dataIndex: 'test', key: 'test', render: (unknown, row) => {
                switch (row.state?.code) {
                    case 'ProbeSelection':
                        return row.responsibleForProbes ? row.responsibleForProbes.fullName : ''
                    case 'Research':
                        return row.performer ? row.performer.fullName : ''
                    case 'Completed':
                        return row.performer ? row.performer.fullName : ''
                    case 'Created':
                        return ''
                    case 'Processing':
                        return row.responsible ? row.responsible.fullName : ''
                    case 'Declined':
                        return ''
                    default:
                        return ''
                }
            }
        },
        {
            title: "Статус", dataIndex: 'test', key: 'test', render: (unknown, row) => {

                if (row.state?.code.toLowerCase() === State.Processsing && row.number[0] === '2') {
                    return 'Отправлен в НЦЭ'
                }

                if (row.state) {
                    return row.state.name
                } else {
                    return <span style={{color: "gray"}}>Не задан</span>
                }
            }
        },
    ];

    const searchFields = [
        ...actsSearchFields,
    ];

    const filterFields = [
        ...actsFilterFields
    ];


    return <CRUDTable
        search={actCRUD.search}
        columns={columns}
        filterFields={filterFields}
        searchFields={searchFields}
        onClickDetailsButton={(id) => push(`${pathname.replace('DSEK', '')}Detail/${id}?orderType=${isDSEK ? 2 : 1}`)}
        onClickCreateButton={() => push(`${pathname.replace('DSEK', '')}Detail/new?orderType=${isDSEK ? 2 : 1}`)}
        {...props}
    />
}
