import {Box, Button, Dialog, DialogContent, Divider, IconButton} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import GovernmentCRUDTable from "../CRUD/Tables/GovernmentCRUDTable";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {technicalRegulationsOfCustomsUnionCRUD} from "../../http";

export default function RegulationOfCuIdField({
                                            departmentId,
                                            label,
                                            value,
                                            onChange,
                                            extraFilter,
                                            disabled,
                                            array,
                                            object
                                        }) {
    const [open, setOpen] = useState(false);

    const handleDelete = (id) => {
        return () => {
            const newArray = [...array]
            const mas = newArray.filter(el => el.id !== +id)
            onChange(mas);
        }
    }

    return (
        <>
            <Grid container spacing={1}>
                {/* Field Line */}
                <Grid item xs={12}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        {label !== null ? <Typography style={{padding: '20px 0', width: "50%"}}>
                            {label || "ТР/ТС"}:{" "}
                        </Typography> : null}
                        <Typography style={{fontWeight: "bold"}}>
                            {array?.length ? array?.map((el, idx) => {
                                return (<React.Fragment key={idx}><span>{el.code} - {el.name} - {el.description}</span>
                                    <IconButton disabled={disabled} size="small" onClick={handleDelete(el.id)} sx={{margin: '0 3px'}} children={
                                                                                                         <RemoveCircleIcon/>}/></React.Fragment>)
                            }) : null}
                        </Typography>
                        <Button disabled={disabled} onClick={() => setOpen(true)} variant={"outlined"}
                                style={{marginLeft: "10px"}}>Выбрать</Button>
                    </Box>
                </Grid>
            </Grid>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор ТР/ТС</DialogTitle>
                <Divider/>
                <DialogContent>
                    <GovernmentCRUDTable
                        search={technicalRegulationsOfCustomsUnionCRUD.search}
                        extraFilter={extraFilter}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            let isHas
                            if (array?.length) {
                                array?.forEach(el => {
                                    if (el.id == record.id) {
                                        isHas = true
                                    }
                                })
                                if (!isHas) {
                                    const mas = [...array, {...record}]
                                    onChange(mas)
                                }
                            } else {
                                onChange([record]);
                            }
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{marginLeft: "auto"}}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
