import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {divisionCRUD} from "../../../http";
import {DIVISION_DETAIL_ROUTE} from "../../../routes";
import React from "react";

export default function DivisionCRUDTable({...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("divisionList.tableItem.id"), dataIndex: "id", key:"id"},
        {title: t("divisionList.tableItem.name"), dataIndex: "name", key:"name"},
        {title: t("divisionList.tableItem.department"), dataIndex: "department", key:"department",render: (unknown, row) => row.department ? row.department.name : <span style={{color: "gray"}}>Не задано</span>},
        {title: t("divisionList.tableItem.description"), dataIndex: "description", key:"description",render: (unknown, row) => row.description ? row.description : <span style={{color: "gray"}}>Не задано</span>},
        {title: t("divisionList.tableItem.isDeleted"), dataIndex: "isDeleted", key:"isDeleted",render: (unknown, row) => row.isDeleted ? 
            "Да" : "Нет"},
    ]

    const filterDetails = [
        {title: "test", type:"select"}
    ]

    const searchFields = [
        {
            title: "Название лаборатории",
            key: "name",
            compareType: "equals",
            operandPosition:1,
            filterType:"normal"
        }
    ]


    return <CRUDTable
        search={divisionCRUD.search}
        columns={columns}
        onClickCreateButton={() => push(DIVISION_DETAIL_ROUTE("new"))}
        searchFields={searchFields}

        filterDetails = {filterDetails}
        {...props}
    />
}