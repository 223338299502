import { formatDate2 } from "../functions/formatters";

const formatTime = Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
}).format

export const protocolModelFormatField = (field, data, type) => {
    if (!data) {
        return ''
    }

    switch (type) {
        case 'text':
        case 'number':
            return data;

        case 'employee':
            return data?.fullName ?? data;

        case 'switch':
            return data;
        case 'date':
            try {
                return formatDate2(new Date(data));
            } catch (e) {
                return data;
            }

        case 'datetime':
            try {
                return formatTime(new Date(data));
            } catch (e) {
                return data;
            }
        default:
            return data;
    }
}