import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import {
    REQUEST_STATE_ID_CREATED,
} from "../../redux/constants/requestStateConstants";
import Typography from "@mui/material/Typography";
import { EMPLOYEE_LIST_ROUTE, OBJECT_REFERRAL, OBJECT_REFERRAL_LIST_ROUTE, OBJECT_REFERRAL_ROUTE } from "./../../routes";
import {
    Grid,
    TextField,
    Button,
    FormLabel,
    FormControl,
    CircularProgress,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import DashboardLayout from "./../../layouts/Dashboard";
import {
    AdditionalInfoSelect,
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect,
    InfectionKindSelect,
    MedicalOrderSelect,
    OrderTypeSelect,
    PurposeOfSelectionsSelect,
    ReferralAdditionalInfosSelect,
    SamplePreservationMethodSelect,
    StorageConditionSelect,
    TechnicalRegulationsOfCustomsUnionsSelect,
    TransportationConditionSelect,
    DiagnosisSelect,
} from "./../../components/Select/domainSelects";

import { BooleanRadio, ProbeTypeRadio } from "./../../components/Radio/radios";
import ProbeTypeBlockTableForm from "./../../components/BlockTableForm/ProbeTypeBlockTableForm";
import AnObjectField from "./../../components/Relations/AnObjectField";
import CheckboxField from "./../../components/CheckboxField";
import EmployeeField from "./../../components/Relations/EmployeeField";
import PatientField from "./../../components/Relations/PatientField";
import { InfectionKindField } from "./../../components/Relations/relationFields";
import SimpleTable from "./../../components/SimpleTable";
import ResearchListField from "./../../components/Relations/ResearchListField";
import { mapObjectReferralForSubmit } from "./../../functions/viewDataMap";
import {
    probeStatuses,
    probeCodes,
    patientStatuses,
    researcherStatuses1,
    researcherStatuses2,
    researcherStatuses3,
    researcherStatuses4,
} from "./objectReferralStates";
import ObjectReferalRadio from "./ObjectReferalRadio";
import CollectionTube from "./CollectionTube";
import AddIcon from "@mui/icons-material/Add";

import {
    FormSection,
    PageContainer,
    PageHeader,
    PageFooter,
} from "./../../components";

import {
    addAnObjectReferral,
} from "./../../redux/actions";
import GovernmentField from "../../components/Relations/GovernmentField";

import { Divider } from "@mui/material";

import { ContractSelect } from "./../../components/Select/domainSelects";

import { validateReferralForSubmit } from "../../functions/validations";
import useDialogs from "../../components/Dialog/useDialogs";
import { prescriptionCRUD, protocolCRUD, resolutionActCRUD, resolutionCRUD, resultCRUD, serviceCRUD, serviceGroupCRUD, serviceMapCRUD } from "../../http";
import RegulationOfCuIdField from "../../components/Relations/RegulationOfCuIdField";
import { asJSONSafe, toUIJson } from "../../functions";
import { normalize } from "../../functions/stringHelpers";
import useLoader from "../../hooks/useLoader";
import { selectMany } from "../../functions/linqSimulation";

export const ObjectReferralCreate = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const { state } = useLocation()
    const [data, setData] = useState({
        probeType: null,
    });
    const [actId, setActId] = useState(null);
    const [prescriptionId, setPrescriptionId] = useState(null);

    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(false);


    const fetchSpecificationsAsync = async (actId, prescriptionId) => {
        let indicators = [];

        if (actId) {
            const resolutionAct = await resolutionActCRUD.firstOrDefault({
                paging: { take: 1 },
                filter: {
                    actId: { operand1: actId, operator: 'equals' }
                }
            });

            const resolutionId = resolutionAct?.resolutionId;

            if (resolutionAct == null || !resolutionId) {
                alert('Ошибка: Не удалось найти постановление по акту: ' + actId);
                return;
            }

            const resolution = await resolutionCRUD.get(resolutionId);

            if (resolution == null) {
                alert('Ошибка: Не удалось найти постановление по акту: ' + actId + ', ' + resolutionId);
                return;
            }

            indicators = asJSONSafe(resolution.indicators);
        } else if (prescriptionId) {
            const prescription = await prescriptionCRUD.get(prescriptionId);

            if (prescription == null) {
                alert('Ошибка: Не удалось найти предписание по акту: ' + prescriptionId);
                return;
            }

            indicators = asJSONSafe(prescription.indicators);
        }

        const services = (await serviceCRUD.toList({ paging: { skip: 0 }, filter: { typeId: { operand1: 2, operator: 'equals' } } })).filter(_ => !_.isDeleted);
        const serviceMaps = await serviceMapCRUD.search({ paging: { skip: 0 } })

        const version = indicators.length > 0 && indicators[0].indicators ? 2 : 1;

        switch (version) {
            case 2:
                indicators = selectMany(indicators, _ => _.indicators);
                break;
        }   

        indicators = indicators.filter(_ => _.checked);
        
        if (!indicators || indicators.length == 0) {
            alert('Ошибка: В данном постановлении не найдены исследования');
            return;
        }                

        const specifications = await mapIndicatorsToSpecifications(indicators, services, serviceMaps);
        console.log('specifications: ' + JSON.stringify(specifications));

        return specifications;
    }

    const mapIndicatorsToSpecifications = async (indicators, services) => {
        const groupId = indicators[0].groupId;
        const group = await serviceGroupCRUD.get(groupId);
        services = services.filter(_ => indicators.find(i => normalize(i.name) == normalize(_.subGroup?.name) &&
            _.groupId == i.groupId));

        return services.map(_ => {
            return {
                serviceId: _.id,
                service: _,
                department: _.department,
                departmentId: _.departmentId,
                division: _.division,
                divisionId: _.divisionId,
                quantity: 0,
                inconsistenceQuantity: 0
            };
        });
    }

    const fetch = async () => {
        if (!state?.data) {
            return;
        }

        fetchStart();

        let probeType;
        let actName = state?.data.actName;
        if (actName === 'ActProducts') {
            probeType = 1;
        }
        if (actName === 'ActWater' || actName === 'ActLand') {
            probeType = 3;
        }
        if (actName === 'ActZoonotic' || actName === 'ActDisinfectant' || actName === 'ActWaste' || actName === 'ActSamplingReport' || actName === 'ActRadio') {
            probeType = 2;
        }

        const newData = { ...data, ...state.data, probeType };

        if (state.data.actId || state.data.prescriptionId) {
            const specifications = await fetchSpecificationsAsync(state.data.actId, state.data.prescriptionId);
            newData.specifications = specifications;
        }

        setData(newData);
        setActId(state.data.actId)
        setPrescriptionId(state.data.prescriptionId);
        console.log(state.data.actId)
        setTargetObject(state?.data?.targetObject)
        setFromAct(true);
        fetchStop();
    }

    React.useEffect(() => {
        if (!state?.data) {
            return;
        }

        fetch();
    }, [state?.data])

    const [loading, setLoading] = useState(false);

    const { alert, DIALOG } = useDialogs();

    const [targetObject, setTargetObject] = useState(null);
    const [fromAct, setFromAct] = useState(false);
    const [testTubes, setTestTubes] = useState([]);

    const { filialId, filialDivisionId, id } = useSelector(state => state.employee.authorizedEmployee)
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)


    useEffect(() => {
        (async function () {
            if (id) {
                handleChangeObject('createdBy', authorizedEmployee)
                handleChange("orderTypeId", 1)
            }
        }())
    }, [authorizedEmployee])

    useEffect(() => {
        (async function () {
            if (filialId || filialDivisionId) {
                handleChangeMulti({
                    filialId: filialId,
                    filialDivisionId: filialDivisionId,
                });
            }
        }())
    }, [filialId, filialDivisionId])

    const handleChange = useCallback(
        (key, value) => setData(prevState => ({ ...prevState, [key]: value })),
        [data]
    );

    const handleChangeMulti = useCallback(
        (obj) => setData(prev => ({ ...prev, ...obj })),
        [data]
    );

    const handleChangeObject = useCallback(
        (key, value) => setData(prev => ({ ...prev, [key]: value, [`${key}Id`]: value.id })),
        [data]
    );

    const handleSubmit = async () => {
        setLoading(true);


        data.targetObject = targetObject;
        data.testTubes = testTubes;
        let _data = mapObjectReferralForSubmit(data);

        if (!validateReferralForSubmit(_data, alert)) {
            setLoading(false);
            return;
        }

        if (fromAct) {
            _data.actId = actId;
            delete _data.results
            delete _data.actName
            delete _data.protocols
        }

        // Creating
        const createdId = await dispatch(addAnObjectReferral(_data));
        if (!createdId) return setLoading(false);

        if (fromAct) {
            if (data?.results?.length) {
                data?.results.map(async el => {
                    await resultCRUD.edit({ ...el, objectReferralId: createdId })
                })
            }
            if (data?.protocols?.length) {
                data?.protocols.map(async el => {
                    await protocolCRUD.edit({ ...el, objectReferralId: createdId })
                })
            }
        }

        // Redirecting
        setLoading(false);
        return push(OBJECT_REFERRAL_ROUTE(createdId));
    };

    const renderTextField = (key, label, xs = 12) => {
        return (
            <Grid item xs={xs}>
                <TextField
                    margin="normal"
                    value={data[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    fullWidth
                    id={key}
                    name={key}
                    label={label}
                />
            </Grid>
        );
    };
    const renderDateField = (key, label, xs = 12) => {
        return (
            <Grid item xs={xs}>
                <FormControl component="fieldset">
                    {label && <FormLabel component="legend">{label}</FormLabel>}
                    <TextField
                        type={"date"}
                        margin="normal"
                        value={data[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                        fullWidth
                        id={key}
                        name={key}
                    />
                </FormControl>
            </Grid>
        );
    };
    const renderDatetimeField = (key, label, xs = 12) => {
        return (
            <Grid item xs={xs}>
                <FormControl component="fieldset">
                    {label && <FormLabel component="legend">{label}</FormLabel>}
                    <TextField
                        type={"datetime-local"}
                        margin="normal"
                        value={data[key]}
                        onChange={(e) => handleChange(key, e.target.value)}
                        fullWidth
                        id={key}
                        name={key}
                    />
                </FormControl>
            </Grid>
        );
    };
    const renderItemGrid = (children, xs = 12) => (
        <Grid item xs={xs}>
            {children}
        </Grid>
    );

    return (
        <DashboardLayout>
            <DIALOG />
            <>
                <PageHeader heading={`Заявка по госзаказу ${fromAct ? '(на основе акта)' : ''}: Создание`}>
                    <Button
                        variant={"text"}
                        onClick={() => push(OBJECT_REFERRAL)}
                    >
                        Вернуться
                    </Button>
                </PageHeader>
                {fetchLoading ? (
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                ) :
                <>
                    <PageContainer>
                        <FormSection>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ContractSelect
                                        label={"Договор КСЭК"}
                                        year={
                                            (new Date()).getFullYear()
                                        }
                                        fullWidth
                                        value={data.governmentContractId}
                                        onChange={(val, obj) => {
                                            handleChangeMulti({
                                                governmentContractId: val,
                                                governmentContract: obj
                                            });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FilialSelect
                                        label={"Наименование организации"}
                                        fullWidth
                                        value={data.filialId}
                                        filialIds={data.governmentContract?.filials ? data.governmentContract?.filials.map(_ => _.filialId) : null}
                                        onChange={(val) => {
                                            handleChangeMulti({
                                                filialId: val,
                                                filialDivisionId:
                                                    Number(val) !== Number(data.filialId)
                                                        ? null
                                                        : data.filialDivisionId,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FilialDivisionSelect
                                        label={"Наименование отделения"}
                                        fullWidth
                                        value={data.filialDivisionId}
                                        filialId={data.filialId}
                                        onChange={(filialDivisionId, filialDivision) => {
                                            handleChangeMulti({
                                                filialDivisionId: filialDivisionId,
                                                filialId:
                                                    Number(filialDivision.filialId) !==
                                                        Number(data.filialId)
                                                        ? filialDivision.filialId
                                                        : data.filialId,
                                            });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label={"Очаг для ДДД"}
                                        value={data.isFocusOfInfection}
                                        onChange={(val) => {
                                            handleChange("isFocusOfInfection", val);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {data.isFocusOfInfection ? (
                                        <>
                                            <AnObjectField
                                                value={targetObject}
                                                onChange={(obj) => {
                                                    setTargetObject({ ...obj })
                                                }}
                                            />
                                            {renderTextField(
                                                "focusAddressLine",
                                                "Место нахождения очага (адрес)"
                                            )}
                                            {renderTextField("infectedFullName", "ФИО зараженного")}
                                            {renderItemGrid(
                                                <InfectionKindField
                                                    label={"Заболеваемость"}
                                                    fullWidth
                                                    object={data.infectionKind}
                                                    value={data.infectionKindId}
                                                    onChange={(val) =>
                                                        handleChangeObject("infectionKind", val)
                                                    }
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <AnObjectField
                                                value={targetObject}
                                                onChange={(obj) => {
                                                    setTargetObject({ ...obj })
                                                }}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </FormSection>

                        {renderTextField(
                            "emergencyNotificationNumber",
                            "4. Номер акта о назначении проверки / экстренного извещения",
                            6
                        )}
                        {renderDateField(
                            "emergencyNotificationDate",
                            "4. Дата акта о назначении проверки / экстренного извещения",
                            6
                        )}

                        {/* Дата */}
                        {renderTextField(
                            "samplingLocation",
                            "5. Место отбора образца / обработки для дез"
                        )}

                        {/* 6. Цель отбора образца / обработки для дез - samplingPurposeId*/}
                        {renderItemGrid(
                            <PurposeOfSelectionsSelect
                                label={"6. Цель отбора образца / обработки для дез"}
                                fullWidth
                                value={data.purposeOfSelectionId}
                                onChange={(val) => handleChange("purposeOfSelectionId", val)}
                            />
                        )}
                        {!data.isFocusOfInfection && renderItemGrid(
                            <RegulationOfCuIdField
                                fullWidth
                                array={toUIJson(data.regulationOfCU2)}
                                onChange={(val) => handleChangeObject("regulationOfCU2", val)}
                            />
                        )}

                        {/* Список приказов для ДДД */}
                        {renderItemGrid(
                            <MedicalOrderSelect
                                fullWidth
                                value={data.medicalOrderId}
                                onChange={(val) => handleChange("medicalOrderId", val)}
                            />
                        )}

                        <Grid
                            item
                            container
                            spacing={1}
                            xs={12}
                            style={{ alignItems: "center" }}
                        >
                            {/* Цель отбора (дополнительное поле, заполняется при необходимости)*/}
                            {renderItemGrid(
                                <AdditionalInfoSelect
                                    fullWidth
                                    value={data.additionalInfoId}
                                    onChange={(val) => handleChange("additionalInfoId", val)}
                                />,
                                6
                            )}
                            {/* {renderTextField(
              "purposeOfSelectionLine",
              "Цель отбора (дополнительное поле, заполняется при необходимости)",
              6
            )} */}
                        </Grid>

                        {/* 7. Дата и время отбора проб (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {!data.isFocusOfInfection && renderDatetimeField(
                            "sampleSelectionTime",
                            "777. Дата и время отбора проб (тем, кто пробы не отбирает заполнять не нужно)",
                            6
                        )}

                        {/* 8. Дата и время доставки проб (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {!data.isFocusOfInfection && renderDatetimeField(
                            "sampleDeliveryTime",
                            "8. Дата и время доставки проб (тем, кто пробы не отбирает заполнять не нужно)",
                            6
                        )}
                        {!data.isFocusOfInfection && <GovernmentField
                            fullWidth
                            value={data.standard2Id}
                            array={data.standard2}
                            onChange={(val) => handleChangeObject("standard2", val)}
                        />}

                        {/* 10. Условия транспортировки (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {!data.isFocusOfInfection && renderItemGrid(
                            <TransportationConditionSelect
                                fullWidth
                                value={data.transportationConditionId}
                                onChange={(val) => handleChange("transportationConditionId", val)}
                            />
                        )}

                        {/* 11. Условия хранения (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {!data.isFocusOfInfection && renderItemGrid(
                            <StorageConditionSelect
                                fullWidth
                                value={data.storageConditionId}
                                onChange={(val) => handleChange("storageConditionId", val)}
                            />
                        )}

                        {/* 12. Методы консервации образца */}
                        {!data.isFocusOfInfection && renderItemGrid(
                            <SamplePreservationMethodSelect
                                fullWidth
                                value={data.samplePreservationMethodId}
                                onChange={(val) =>
                                    handleChange("samplePreservationMethodId", val)
                                }
                            />
                        )}

                        {/* 13. Дополнительные сведения */}
                        {!data.isFocusOfInfection && renderItemGrid(
                            <ReferralAdditionalInfosSelect
                                fullWidth
                                value={data.additionalInfoId}
                                onChange={(val) => handleChange("additionalInfoId", val)}
                            />
                        )}

                        {/* 14. №, дата акта от Департамента */}
                        {renderTextField("actInfo", "14. №, дата акта от Департамента")}

                        {/* 15. Тип заказа */}
                        {renderItemGrid(
                            <OrderTypeSelect
                                fullWidth
                                value={data.orderTypeId}
                                onChange={(val) => handleChange("orderTypeId", val)}
                                disabled={true}
                            />
                        )}

                        {/* 16. Перечень отобранных образцов (тем, кто пробы не отбирает, заполнять не нужно) */}
                        {renderItemGrid(
                            <ProbeTypeRadio
                                value={data.probeType}
                                onChange={(val) => {
                                    handleChange("probeType", val)
                                }}
                            />
                        )}
                        {!!data.probeType && data.probeType < 5 && (
                            <ProbeTypeBlockTableForm
                                probeType={data.probeType}
                                data={data.probes}
                                onChange={(val) => handleChange("probes", val)}
                            />
                        )}
                        {data.probeType == 5 && (
                            <>
                                <PatientField
                                    object={data.targetPatient}
                                    value={data.targetPatientId}
                                    onChange={(val) => handleChangeObject("targetPatient", val)}
                                />
                                <SimpleTable columns={[]} data={{}} />
                                <Grid item xs={12}>
                                    <DiagnosisSelect
                                        value={data.diagnosis}
                                        onChange={(val) => handleChange("diagnosis", val)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.antibioticUse}
                                        onChange={(e) =>
                                            handleChange("antibioticUse", e.target.value)
                                        }
                                        fullWidth
                                        id="antibioticUse"
                                        name="antibioticUse"
                                        label="Применение антибиотиков"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InfectionKindSelect
                                        value={data.infectionKinds}
                                        onChange={(val) => handleChange("infectionKinds", val)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.researchMaterial}
                                        onChange={(e) =>
                                            handleChange("researchMaterial", e.target.value)
                                        }
                                        fullWidth
                                        id="researchMaterial"
                                        name="antibioticUse"
                                        label="Материал исследований"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.sampleSentOrganizationName}
                                        onChange={(e) =>
                                            handleChange("sampleSentOrganizationName", e.target.value)
                                        }
                                        fullWidth
                                        id="sampleSentOrganizationName"
                                        name="sampleSentOrganizationName"
                                        label="Наименование учреждения направившего образец"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.sampleCollectedFullName}
                                        onChange={(e) =>
                                            handleChange("sampleCollectedFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="sampleCollectedFullName"
                                        name="sampleCollectedFullName"
                                        label="ФИО специалиста, отбиравшего пробу, должность"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.arrivalCountry}
                                        onChange={(e) =>
                                            handleChange("arrivalCountry", e.target.value)
                                        }
                                        fullWidth
                                        id="arrivalCountry"
                                        name="arrivalCountry"
                                        label="ФИО специалиста, отбиравшего пробу, должность"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        label="Статус отбора пробы"
                                        array={probeStatuses}
                                        value={data.probeStatus}
                                        setValue={(val) => handleChange("probeStatus", val)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={patientStatuses}
                                        value={data.patientStatus}
                                        setValue={(val) => {
                                        }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BooleanRadio
                                        label={"Наличие клинических симптомов КВИ"}
                                        value={data.clinicalSymptomsCVI}
                                        onChange={(val) => handleChange("clinicalSymptomsCVI", val)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        label="Наименование пробы"
                                        array={probeCodes}
                                        value={data.probeCode}
                                        setValue={(val) => handleChange("probeCode", val)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        label="16. Статус исследуемого"
                                        array={researcherStatuses1}
                                        value={data.researcherStatus1}
                                        setValue={(val) => handleChange("researcherStatus1", val)}
                                        required
                                    />
                                </Grid>
                                <Divider style={{ margin: "10px 0px" }} />
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={researcherStatuses2}
                                        value={data.researcherStatus2}
                                        setValue={(val) => handleChange("researcherStatus2", val)}
                                        required
                                    />
                                </Grid>
                                <Divider style={{ margin: "10px 0px" }} />
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={researcherStatuses3}
                                        value={data.researcherStatus3}
                                        setValue={(val) => handleChange("researcherStatus3", val)}
                                        required
                                    />
                                </Grid>
                                <Divider style={{ margin: "10px 0px" }} />
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={researcherStatuses4}
                                        value={data.researcherStatus4}
                                        setValue={(val) => handleChange("researcherStatus4", val)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.sickFullName}
                                        onChange={(e) => handleChange("sickFullName", e.target.value)}
                                        fullWidth
                                        id="sickFullName"
                                        name="sickFullName"
                                        label="ФИО больного"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.closeContactFullName}
                                        onChange={(e) =>
                                            handleChange("closeContactFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="closeContactFullName"
                                        name="closeContactFullName"
                                        label="Ф.И.О. близкого контакта"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.potentialContactFullName}
                                        onChange={(e) =>
                                            handleChange("potentialContactFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="potentialContactFullName"
                                        name="potentialContactFullName"
                                        label="Ф.И.О. потенциального контакта"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.clinicProbeNumberForCovid}
                                        onChange={(e) =>
                                            handleChange("clinicProbeNumberForCovid", e.target.value)
                                        }
                                        fullWidth
                                        id="clinicProbeNumberForCovid"
                                        name="clinicProbeNumberForCovid"
                                        label="Номер пробы поликлиники для covid"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.referralNumber}
                                        onChange={(e) =>
                                            handleChange("referralNumber", e.target.value)
                                        }
                                        fullWidth
                                        id="referralNumber"
                                        name="referralNumber"
                                        label="Номер направления"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.researchPurpose}
                                        onChange={(e) =>
                                            handleChange("researchPurpose", e.target.value)
                                        }
                                        fullWidth
                                        id="researchPurpose"
                                        name="researchPurpose"
                                        label="Цель исследования"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"h5"} style={{ display: "inline" }}>
                                        Пробирка для забора
                                    </Typography>
                                    <Button
                                        onClick={() =>
                                            setTestTubes([
                                                ...testTubes,
                                                { id: uuidv4(), name: "Пусто", quantity: 0 },
                                            ])
                                        }
                                        sx={{ marginLeft: 2 }}
                                    >
                                        <AddIcon sx={{ marginRight: 2 }} /> Добавить блок
                                    </Button>
                                </Grid>
                                <CollectionTube
                                    setData={(tubes) => {
                                        console.log("setDataTubes", tubes);
                                        setTestTubes([...tubes]);
                                    }}
                                    data={testTubes}
                                />
                            </>
                        )}

                        {/* 17. Список исследований - todo коды? */}

                        {!actId && !prescriptionId && data.stateId &&
                            data.state?.code != REQUEST_STATE_ID_CREATED &&
                            renderItemGrid(
                                <ResearchListField
                                    value={data.specifications}
                                    onChange={(val) => handleChange("specifications", val)}
                                />
                            )}
                        {(actId || prescriptionId) &&
                            renderItemGrid(
                                <ResearchListField
                                    value={data.specifications}
                                    mode={'readonly'}
                                    disabled={false}
                                    onChange={(val) => handleChange("specifications", val)}
                                />
                            )}


                        {/* 18. Дата начала исследования  - todo*/}
                        {renderDateField(
                            "researchStartDateTime",
                            "18. Дата начала исследования "
                        )}

                        {/* 19. Дата окончания исследования  - todo*/}
                        {renderDateField(
                            "researchEndDateTime",
                            "Дата окончания исследования"
                        )}

                        <Grid item xs={12}>
                            <DepartmentSelect
                                label={"Лаборатория/дез отделы/очаги"}
                                fullWidth
                                value={data.departmentId}
                                onChange={(departmentId) => {
                                    const obj = { departmentId };
                                    // if (data.responsible?.departmentId != departmentId) {
                                    //   obj.responsible = null;
                                    //   obj.responsibleId = null;
                                    // }
                                    handleChangeMulti(obj);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DivisionSelect
                                isNullable={true}
                                label={"Отдел лаборатории (если имеется) "}
                                fullWidth
                                value={data.divisionId}
                                departmentId={data.departmentId}
                                onChange={(val) => handleChange("divisionId", val)}
                            />
                        </Grid>

                        {/* <Grid item xs={12}>
            <EmployeeField
              label={"Создал заявку"}
              fullWidth
              disabled={data.createdBy}
              object={data.createdBy}
              value={data.createdById}
              onChange={(val) => handleChangeObject("createdBy", val)}
            />
          </Grid> */}
                        <Grid item xs={12}>
                            <EmployeeField
                                label={"Заведующий лаборатории/Начальник отдела"}
                                fullWidth
                                object={data.responsible}
                                value={data.responsibleId}
                                onChange={(responsible) => {
                                    const obj = {
                                        responsible,
                                        responsibleId: responsible.id,
                                        //departmentId: responsible.departmentId,
                                    };
                                    handleChangeMulti(obj);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployeeField
                                label={"Врач/Специалист/Лаборант/Дезинфектор/Дез. инструктор"}
                                fullWidth
                                object={data.performer}
                                value={data.performerId}
                                onChange={(performer) => {
                                    const obj = {
                                        performer,
                                        performerId: performer.id,
                                    };
                                    handleChangeMulti(obj);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} />
                    </PageContainer>
                      <PageFooter>
                      <LoadingButton loading={loading} onClick={handleSubmit}>
                          Создать заявку
                      </LoadingButton>
                  </PageFooter>
                  </>
                }
              
            </>
        </DashboardLayout>
    );
};
