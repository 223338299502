export const SAMPLING_PURPOSE_GET_REQUEST = 'SAMPLING_PURPOSE_GET_REQUEST';
export const SAMPLING_PURPOSE_GET_SUCCESS = 'SAMPLING_PURPOSE_GET_SUCCESS';
export const SAMPLING_PURPOSE_GET_ERROR = 'SAMPLING_PURPOSE_GET_ERROR';
export const SAMPLING_PURPOSE_GET_BY_KEY_REQUEST = 'SAMPLING_PURPOSE_GET_BY_KEY_REQUEST';
export const SAMPLING_PURPOSE_GET_BY_KEY_SUCCESS = 'SAMPLING_PURPOSE_GET_BY_KEY_SUCCESS';
export const SAMPLING_PURPOSE_GET_BY_KEY_ERROR = 'SAMPLING_PURPOSE_GET_BY_KEY_ERROR';
export const SAMPLING_PURPOSE_ADD_REQUEST = 'SAMPLING_PURPOSE_ADD_REQUEST';
export const SAMPLING_PURPOSE_ADD_SUCCESS = 'SAMPLING_PURPOSE_ADD_SUCCESS';
export const SAMPLING_PURPOSE_ADD_ERROR = 'SAMPLING_PURPOSE_ADD_ERROR';
export const SAMPLING_PURPOSE_EDIT_REQUEST = 'SAMPLING_PURPOSE_EDIT_REQUEST';
export const SAMPLING_PURPOSE_EDIT_SUCCESS = 'SAMPLING_PURPOSE_EDIT_SUCCESS';
export const SAMPLING_PURPOSE_EDIT_ERROR = 'SAMPLING_PURPOSE_EDIT_ERROR';
export const SAMPLING_PURPOSE_DELETE_REQUEST = 'SAMPLING_PURPOSE_DELETE_REQUEST';
export const SAMPLING_PURPOSE_DELETE_SUCCESS = 'SAMPLING_PURPOSE_DELETE_SUCCESS';
export const SAMPLING_PURPOSE_DELETE_ERROR = 'SAMPLING_PURPOSE_DELETE_ERROR';
