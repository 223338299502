import m_10_2266_colorized from './KZ.M.10.2266.svg'
import m_10_2266 from './KZ.M.10.2266 black.svg'
import t_10_0737_colorized from './KZ.T.10.0737.svg'
import t_10_0737 from './KZ.T.10.0737 black.svg'

export default [
  m_10_2266_colorized,
  m_10_2266,
  t_10_0737_colorized,
  t_10_0737
]
