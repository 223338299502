import React, {useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useLocation } from "react-router";
import Box from "@mui/material/Box";
import { ResultFormSelect } from "../../components/Select/domainSelects";
import ResultCRUDTable from "../../components/CRUD/Tables/ResultCRUDTable";
import CreateResult from "./Popups/CreateResult";
import useAlert from "../../hooks/useAlert";

const ResultList = () => {

    const [resultFormId, setResultFormId] = useState(null);
    const [createResultOpen, setCreateResultOpen] = useState(false);
    const alert = useAlert(false, 1);
    const { pathname } = useLocation()

    let isObjectReferral = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.includes('ObjectReferral')
    let isPaid = `${pathname[1].toUpperCase()}${pathname.substring(2)}`.includes('Paid')


    const extraFilterOR = isObjectReferral || isPaid ? {
        "orderType": {
          "operand1": isObjectReferral ? 2 : 1,
          "operator": "equals"
        }
    } : null;

    return (
        <DashboardLayout>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <ResultFormSelect
                    label={'Выберите журнал'}
                    fullWidth
                    value={resultFormId}
                    onChange={(value) => setResultFormId(value)}
                    isNullable={true}
                />
            </Box>
            <ResultCRUDTable alert={alert} onClickCreateButton={() => setCreateResultOpen(true)} resultFormId={resultFormId} extraFilterOR={extraFilterOR}/>
            {createResultOpen && (
            <CreateResult
                action={'createWithoutRequest'}
                title={'Создание журнала без заявки'}
                onClose={() => setCreateResultOpen(false)}
                onSubmit={async (response) => {
                    console.log(response);
                }}
            />)}
            {alert.render()}
        </DashboardLayout>
    )
};

export default ResultList;
