import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Checkbox, CircularProgress, Dialog, FormControlLabel, FormGroup, Grid} from "@mui/material";
import Paper from "@mui/material/Paper";
import PatientField from "../../components/Relations/PatientField";
import ServiceListField from "../../components/Relations/ServiceListField";
import DictionaryField from "../../components/Relations/DictionaryField";
import TextField from "@mui/material/TextField";
import ReferalRadio from "./ReferalRadio";
import {benefitsToCDI, expressTests, probCodes, probStatus, statusPatient} from "./stateReferal";
import CheckboxesMultiColumnField from "../../components/Fields/CheckboxesMultiColumnField";
import {BooleanRadio} from "../../components/Radio/radios";
import {Radio} from "../../components/Radio/Radio";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {v4 as uuidv4} from "uuid";
import AddIcon from "@mui/icons-material/Add";
import CollectionTube from "./CollectionTube";
import {
    REQUEST_STATE_ID_CREATED
} from "../../redux/constants/requestStateConstants";
import Select from "../../components/Select/Select";
import {
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect
} from "../../components/Select/domainSelects";
import EmployeeField from "../../components/Relations/EmployeeField";
import {Collapse} from "antd";
import SimpleTable from "../../components/SimpleTable";
import {fetchPdf} from "../Protocol/Popups/ProtocolDetails";
import {formatDate} from "../../functions/formatters";
import CreateResult from "../Result/Popups/CreateResult";
import RemovePopup from "../../components/Popups/RemovePopup";
import {referalsCRUD} from "../../http";
import {REFERRAL_LIST_ROUTE} from "../../routes";
import CreateProtocol from "../Protocol/Popups/CreateProtocol";
import {addProtocol} from "../../redux/actions/protocolActions";
import Box from "@mui/material/Box";
import {Document, Page} from "react-pdf";
import useLoader from "../../hooks/useLoader";
import Toolbar from "@mui/material/Toolbar";
import LoadingButton from "@mui/lab/LoadingButton";
import useDialogs from "../../components/Dialog/useDialogs";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

function ReferralData({handleSubmit, data, setData}) {
    const { alert, DIALOG } = useDialogs();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { push, goBack } = useHistory();
    const { id } = useParams();
    const isNew = true;
    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: loadingSubmit,
    } = useLoader(false);


    const handleChange = useCallback(
        (key, value) => {
            setData({ ...data, [key]: value });
        },
        [data]
    );
    const handleChangeMulti = useCallback(
        (obj) => {
            setData({ ...data, ...obj });
        },
        [data]
    );

    const [targetPatient, setTargetPatient] = useState(null);

    const [newPatient, setNewPatient] = useState(null);
    const handleChangeNewPatient = useCallback(
        (key, value) => setNewPatient({ ...newPatient, [key]: value }),
        [newPatient]
    );

    const [testTubes, setTestTubes] = useState([]);

    const [formType, setFormType] = useState("edit");
    const isEditing = useMemo(() => formType === "edit", [formType]);
    const isView = useMemo(() => formType === "view", [formType]);

    const renderItemGrid = (children, xs = 12) => (
        <Grid item xs={xs}>
            {children}
        </Grid>
    );

    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const fetch = useCallback(() => {
        if (isNew) {
            fetchStop();
            return;
        }
    }, [id, fetchStart, fetchStop, isNew]);
    useEffect(fetch, [fetch]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    console.log('data', data)

    const [protocolAddOpen, setProtocolAddOpen] = useState(false);
    const [protocolViewPdf, setProtocolViewPdf] = useState(false);
    const [protocolViewOpen, setProtocolViewOpen] = useState(false);
    const [createResultOpen, setCreateResultOpen] = useState(false);
    const [deleteReferralOpen, setDeleteReferralOpen] = useState(false);

    const title = useMemo(() => {
        return isNew
            ? "Создание единоразовой заявки"
            : `${isEditing ? "Изменение" : "Просмотр"} единоразовой заявки ` +
            `№${data.number}.`;
    }, [data, isNew, isEditing]);

    const hasResults = useMemo(
        () => data.results && data.results.length > 0,
        [data]
    );
    const hasProtocols = useMemo(
        () => data.protocols && data.protocols.length > 0,
        [data]
    );

    const handleSubmitLocal = () => {
        handleSubmit()
    }

    const renderedToolbarButtons = useMemo(() => {
        const style = { marginLeft: 10 };
        return (
            <>
                <div style={{ margin: "auto" }} />
                {(isEditing || isNew) && (
                    <LoadingButton
                        variant="contained"
                        style={style}
                        onClick={handleSubmitLocal}
                        loading={loadingSubmit}
                    >
                        Создать
                    </LoadingButton>
                )}
            </>
        );
    }, [isNew, isEditing, loadingSubmit, handleSubmit, push]);


    const renderToolbar = useCallback(
        (withTitle = true) => {
            return (
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar>
                            {renderedToolbarButtons}
                        </Toolbar>
                    </Paper>
                </Grid>
            );
        },
        [title, renderedToolbarButtons, data, isNew]
    );

    console.log('data.targetPatient', data.targetPatient)

    return (
        <>
            <DIALOG />

            <Grid container spacing={1}>
                {fetchLoading ? (
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        {/* region FormEdit */}

                        {isEditing && (
                            <Grid item xs={12} style={{ paddingTop: 16 }}>
                                <Paper>
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{ paddingLeft: 20, paddingRight: 20 }}
                                    >
                                        <Grid item xs={12}>
                                            <PatientField
                                                value={targetPatient?.id}
                                                object={targetPatient}
                                                onChange={(pat) => setTargetPatient({ ...pat })}
                                            />
                                        </Grid>

                                        {renderItemGrid(
                                            <ServiceListField
                                                label="Список исследований"
                                                value={data.specifications}
                                                onChange={(val) =>
                                                    handleChange("specifications", val)
                                                }
                                            />
                                        )}
                                        <Grid item xs={12}>
                                            <DictionaryField
                                                entityType="disease"
                                                label={"Диагноз"}
                                                dialogTitle={"Диагноз"}
                                                value={data.diagnosis}
                                                object={data.diagnosis}
                                                onChange={(val) => {
                                                    setData({
                                                        ...data,
                                                        diagnosis: val,
                                                    });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                margin="normal"
                                                value={data.antibioticUse}
                                                onChange={(e) =>
                                                    handleChange("antibioticUse", e.target.value)
                                                }
                                                fullWidth
                                                id="antibioticUse"
                                                name="antibioticUse"
                                                label="Применение антибиотиков"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ReferalRadio
                                                label="6. Наименование пробы *п (мазок из зева и носа/трупный материал)"
                                                array={probCodes}
                                                value={data.probeCode}
                                                setValue={(val) => handleChange("probeCode", val)}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ReferalRadio
                                                label="7. Статус отбора пробы"
                                                array={probStatus}
                                                value={data.probeStatus}
                                                setValue={(val) => handleChange("probeStatus", val)}
                                                required
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <CheckboxesMultiColumnField
                                                label={"8. Статус пациента"}
                                                value={data.patientStatus}
                                                onChange={(val) => handleChange("patientStatus", val)}
                                                options={statusPatient.map((label) => ({
                                                    label,
                                                    value: label,
                                                }))}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <BooleanRadio
                                                label={"Наличие клинических симптомов КВИ"}
                                                value={data.clinicalSymptomsCVI}
                                                onChange={(val) =>
                                                    handleChange("clinicalSymptomsCVI", val)
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Radio
                                                label="Результаты экспресс теста"
                                                required
                                                options={expressTests.map((et, i) => ({
                                                    label: et,
                                                    value: i + 1,
                                                }))}
                                                value={data.expressTestResult}
                                                onChange={(val) =>
                                                    handleChange("expressTestResult", val)
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                margin="normal"
                                                value={data.sampleSentOrganizationName}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "sampleSentOrganizationName",
                                                        e.target.value
                                                    )
                                                }
                                                fullWidth
                                                id="sampleSentOrganizationName"
                                                name="sampleSentOrganizationName"
                                                label="13. Наименование учреждения направившего образец"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                margin="normal"
                                                value={data.referralNumber}
                                                onChange={(e) =>
                                                    handleChange("referralNumber", e.target.value)
                                                }
                                                fullWidth
                                                id="referralNumber"
                                                name="referralNumber"
                                                label="Номер направления"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                margin="normal"
                                                value={data.probeNumber}
                                                onChange={(e) =>
                                                    handleChange("probeNumber", e.target.value)
                                                }
                                                fullWidth
                                                id="probeNumber"
                                                name="probeNumber"
                                                label="Номер пробы"
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography variant={"h5"} style={{ display: "inline" }}>
                                                Пробирки
                                            </Typography>
                                            <Button
                                                onClick={() =>
                                                    setTestTubes([
                                                        ...testTubes,
                                                        { id: uuidv4(), name: "Пусто", quantity: 0 },
                                                    ])
                                                }
                                                sx={{ marginLeft: 2 }}
                                            >
                                                <AddIcon sx={{ marginRight: 2 }} />Добавить блок
                                            </Button>
                                        </Grid>
                                        <CollectionTube
                                            setData={(tubes) => {
                                                console.log("setDataTubes", tubes);
                                                setTestTubes([...tubes]);
                                            }}
                                            data={testTubes}
                                        />

                                        {data.stateId &&
                                        data.state?.code != REQUEST_STATE_ID_CREATED &&
                                        renderItemGrid(
                                            <ServiceListField
                                                label="Список исследований"
                                                value={data.specifications}
                                                onChange={(val) =>
                                                    handleChange("specifications", val)
                                                }
                                            />
                                        )}

                                        <Grid container spacing={1} item xs={12}>
                                            <Grid item xs={2}>
                                                <Typography variant="h6" component="h2">
                                                    Оплата по ФЛ
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        label="Оплата прошла"
                                                        checked={data.paymentPassed}
                                                        onChange={(event) =>
                                                            handleChange(
                                                                "paymentPassed",
                                                                event.target.checked
                                                            )
                                                        }
                                                        control={<Checkbox defaultChecked />}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Select
                                                    options={benefitsToCDI.map((label, value) => ({
                                                        label,
                                                        value,
                                                    }))}
                                                    value={data.benefitsToCDI}
                                                    onChange={(val) => handleChange("benefitsToCDI", val)}
                                                    fullWidth
                                                    id="benefitsToCDI"
                                                    name="benefitsToCDI"
                                                    label="Льготы на право получения услуг по КДИ на безвозмездной основе предоставляются следующим лицам/договор с поликлиникой"
                                                    required
                                                />
                                            </Grid>
                                            {/* <Grid item xs={12}>
                        <BooleanRadio
                          label={"Заявка на COVID-19"}
                          value={data.isCovid19}
                          onChange={(val) => handleChange("isCovid19", val)}
                        />
                      </Grid> */}
                                            <Grid item xs={12}>
                                                <DepartmentSelect
                                                    label={"Лаборатория"}
                                                    fullWidth
                                                    value={data.departmentId}
                                                    onChange={(val) => handleChange("departmentId", val)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DivisionSelect
                                                    label={"Отдел"}
                                                    fullWidth
                                                    value={data.divisionId}
                                                    onChange={(val) => handleChange("divisionId", val)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant={"h5"}>
                                                    Передача проб в лабораторию:
                                                </Typography>
                                            </Grid>

                                            {/*<Grid item xs={12} >Отправить заявку на доработку в ОПП (сотруднику, который создал заявку)</Grid>*/}

                                            <Grid item xs={12}>
                                                <EmployeeField
                                                    label={"Ответственный за отбор проб"}
                                                    value={data.responsibleForProbesId}
                                                    object={data.responsibleForProbes}
                                                    onChange={(val) => {
                                                        setData({
                                                            ...data,
                                                            responsibleForProbesId: val?.id,
                                                            responsibleForProbes: val,
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FilialSelect
                                                    label={"Филиал"}
                                                    fullWidth
                                                    value={data.filialId}
                                                    onChange={(val) => {
                                                        handleChangeMulti({
                                                            filialId: val,
                                                            filialDivisionId:
                                                                Number(val) !== Number(data.filialId)
                                                                    ? null
                                                                    : data.filialDivisionId,
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FilialDivisionSelect
                                                    label={"Районное отделение"}
                                                    fullWidth
                                                    value={data.filialDivisionId}
                                                    filialId={data.filialId}
                                                    onChange={(filialDivisionId, filialDivision) => {
                                                        handleChangeMulti({
                                                            filialDivisionId: filialDivisionId,
                                                            filialId:
                                                                Number(filialDivision.filialId) !==
                                                                Number(data.filialId)
                                                                    ? filialDivision.filialId
                                                                    : data.filialId,
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant={"h5"}>
                                                    Передача проб в лабораторию:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EmployeeField
                                                    label={"Заведующий лаборатории/Начальник отдела"}
                                                    fullWidth
                                                    extraFilter={
                                                        data.departmentId > 0
                                                            ? {
                                                                departmentId: {
                                                                    operand1: data.departmentId,
                                                                    operator: "equals",
                                                                },
                                                            }
                                                            : null
                                                    }
                                                    object={data.responsible}
                                                    value={data.responsibleId}
                                                    onChange={(responsible) => {
                                                        const obj = {
                                                            responsible,
                                                            responsibleId: responsible.id,
                                                            departmentId: responsible.departmentId,
                                                        };
                                                        handleChangeMulti(obj);
                                                    }}
                                                />
                                                {/* <EmployeesSelect label={"Заведующий лаборатории/Начальник отдела"} fullWidth value={data.responsibleId} onChange={(val) => handleChange('responsibleId', val)}/> */}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <EmployeeField
                                                    label={"Лаборант"}
                                                    fullWidth
                                                    object={data.performer}
                                                    value={data.performerId}
                                                    onChange={(performer) => {
                                                        const obj = {
                                                            performer,
                                                            performerId: performer.id,
                                                        };
                                                        handleChangeMulti(obj);
                                                    }}
                                                />
                                                {/* <EmployeesSelect label={"Лаборант"} fullWidth value={data.performerId} onChange={(val) => handleChange('performerId', val)}/> */}
                                            </Grid>
                                        </Grid>

                                        {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                                        {/*  <Grid item xs={6}>*/}
                                        {/*    <TextField label="Ссылка на договор ЮЛ или ИП" margin="normal" fullWidth select>*/}
                                        {/*      <MenuItem value={0}>*/}
                                        {/*        <em>Нет</em>*/}
                                        {/*      </MenuItem>*/}
                                        {/*    </TextField>*/}
                                        {/*  </Grid>}*/}
                                        {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                                        {/*  <Grid item xs={6}>*/}
                                        {/*    <TextField label="Ссылка на договор ФЛ" margin="normal" fullWidth select>*/}
                                        {/*      <MenuItem value={0}>*/}
                                        {/*        <em>Нет</em>*/}
                                        {/*      </MenuItem>*/}
                                        {/*    </TextField>*/}
                                        {/*  </Grid>}*/}
                                        {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                                        {/*  <Grid item xs={6}>*/}
                                        {/*    <TextField label="Ссылка на сводную заявку" margin="normal" fullWidth select>*/}
                                        {/*      <MenuItem value={0}>*/}
                                        {/*        <em>Нет</em>*/}
                                        {/*      </MenuItem>*/}
                                        {/*    </TextField>*/}
                                        {/*  </Grid>}*/}
                                        {/*  /!* todo Надо будет включить на определённом статусе*/}
                                        {/*                  <Grid item xs={12}>*/}
                                        {/*                      <Typography variant="h6" component="h2">*/}
                                        {/*                      Заказные услуги*/}
                                        {/*                      </Typography>*/}
                                        {/*                  </Grid>*/}
                                        {/*                  <Grid item xs={12}>*/}
                                        {/*                      Блок для добавления услуг (Service)*/}
                                        {/*                  </Grid>*/}
                                        {/*                  <Grid item xs={12}>*/}
                                        {/*                      <ReferalTable row={tableRow} setRow={setTableRow}/>*/}
                                        {/*                  </Grid>*!/*/}
                                        <Grid item xs={12} />
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}
                        {/* endregion */}

                        {/* region FormView */}
                        {isView && (
                            <Grid item xs={12}>
                                <Collapse defaultActiveKey={"1"} onChange={() => {}}>
                                    <Typography
                                        variant={"h5"}
                                        align={"left"}
                                        style={{
                                            background: "rgba(50, 205, 50, 0.1)",
                                            fontWeight: "bold",
                                            fontSize: 14,
                                            padding: 15,
                                            marginBottom: 0,
                                        }}
                                    >
                                        Подробная информация по единоразовой заявке №{data.number} -
                                        создано {data.createdAt}
                                    </Typography>
                                    <Collapse.Panel
                                        header={
                                            <Typography variant={"h6"} style={{ display: "inline" }}>
                                                Направление
                                            </Typography>
                                        }
                                        key="1"
                                        style={{ background: "rgba(50, 205, 50, 0.1)" }}
                                    >
                                        <SimpleTable
                                            columns={[
                                                // {label: "ID", code: "id"},
                                                //{label: "№", code: "number"},
                                                { label: "ОТ", code: "createdAt", type: "datetime" },
                                                {
                                                    label: "Оплата по ФЛ",
                                                    code: "paymentPassed",
                                                    type: "boolean",
                                                },
                                                // {label: "COVID19", code: "number", type: "boolean"},
                                                // {label: "clinicalSymptomsCVI", code: "clinicalSymptomsCVI", type: "boolean"},
                                                // {label: "Количество спецификаций", code: "specificationsSummary"},
                                            ]}
                                            data={data}
                                        />
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header={
                                            <Typography variant={"h6"} style={{ display: "inline" }}>
                                                Пациент {!data.targetPatient && "не указан"}
                                            </Typography>
                                        }
                                        key="2"
                                        style={{ background: "rgba(50, 205, 50, 0.1)" }}
                                    >
                                        {data.targetPatient ? (
                                            <SimpleTable
                                                columns={[
                                                    // {label: "ID", code: "id"},
                                                    { label: "ФИО", code: "fullName" },
                                                    { label: "ИИН", code: "identityNumber" },
                                                    // {label: "Фамилия", code: "lastName"},
                                                    // {label: "Имя", code: "firstName"},
                                                    // {label: "Отчество", code: "secondName"},
                                                    { label: "Дата рождения", code: "birthDate" },
                                                    { label: "Возраст", code: "yearsOld" },
                                                    { label: "Пол", code: "gender" },
                                                    { label: "Адрес", code: "addressLine" }, // todo
                                                    { label: "Место учебы, работы", code: "placeOfWorkOrEducation" },
                                                    { label: "Контактный телефон", code: "phoneNumber" },
                                                    { label: "Диагноз", code: "gender" }, // todo
                                                    { label: "Применение антибиотиков", code: "gender" }, // todo
                                                    {
                                                        label:
                                                            "6. Наименование пробы *п (мазок из зева и носа/трупный материал) ",
                                                        code: "gender",
                                                    }, // todo
                                                    { label: "7. Статус отбора пробы ", code: "gender" }, // todo
                                                    { label: "8. Статус пациента\n", code: "gender" }, // todo
                                                    {
                                                        label: "Наличие клинических симптомов КВИ\n",
                                                        code: "gender",
                                                    }, // todo
                                                    {
                                                        label: "Результаты экспресс теста\n",
                                                        code: "gender",
                                                    }, // todo
                                                    {
                                                        label:
                                                            "13. Наименование учреждения, направившего образец ",
                                                        code: "gender",
                                                    }, // todo
                                                    { label: "Номер направления", code: "gender" }, // todo
                                                    { label: "Номер пробы ", code: "gender" }, // todo
                                                ]}
                                                data={data.targetPatient}
                                            />
                                        ) : (
                                            <Typography>Нет данных</Typography>
                                        )}
                                    </Collapse.Panel>

                                    <SimpleTable
                                        columns={[
                                            {
                                                label: "Пробирка для забора",
                                                code: "",
                                                value: "Количество",
                                            },
                                            { label: "Оплата по ФЛ", code: "", value: "Количество" },
                                            {
                                                label:
                                                    "Льготы на право получения услуг по КДИ на безвозмездной основе предоставляются следующим лицам/" +
                                                    "договор с поликлиникой",
                                                code: "",
                                                value: "Не выбрано",
                                            },
                                        ]}
                                        data={data}
                                    />

                                    <Collapse.Panel
                                        header={
                                            <Typography variant={"h6"} style={{ display: "inline" }}>
                                                Заказные услуги
                                            </Typography>
                                        }
                                        key="3"
                                        style={{ background: "rgba(50, 205, 50, 0.1)" }}
                                    ></Collapse.Panel>

                                    <SimpleTable
                                        columns={[
                                            {
                                                label: "Количество исследований",
                                                code: "",
                                                value: "1",
                                            },
                                            {
                                                label: "Заявка на коронавирус COVID-19",
                                                code: "isCovid19",
                                                type: "bool",
                                            },
                                            {
                                                label: "Лаборатория",
                                                code: "",
                                                value: "Бактериологическая лаборатория",
                                            },
                                            { label: "Отдел", code: "", value: "Нет выбора" },
                                            {
                                                label:
                                                    "Отправить заявку на доработку в ОПП (сотруднику, который создал\n" +
                                                    "заявку)",
                                                code: "",
                                                value: "Нет",
                                            },
                                        ]}
                                        data={data}
                                    />
                                    <Collapse.Panel
                                        header={
                                            <Typography variant={"h6"} style={{ display: "inline" }}>
                                                Записи в журнале
                                            </Typography>
                                        }
                                        key="4"
                                        style={{ background: "rgba(50, 205, 50, 0.1)" }}
                                    >
                                        {data.results.map((_) => (
                                            <div>{_.form?.number}</div>
                                        ))}
                                    </Collapse.Panel>
                                    <Collapse.Panel
                                        header={
                                            <Typography variant={"h6"} style={{ display: "inline" }}>
                                                Создание протокола
                                            </Typography>
                                        }
                                        key="5"
                                        style={{ background: "rgba(50, 205, 50, 0.1)" }}
                                    >
                                        {data.protocols.map((protocol, i) => {
                                            return (
                                                <a
                                                    href
                                                    onClick={() => {
                                                        fetchPdf().then((response) => {
                                                            const file = new Blob([response.data], {
                                                                type: "application/pdf",
                                                            });
                                                            setProtocolViewPdf(URL.createObjectURL(file));
                                                            setProtocolViewOpen(true);
                                                        });
                                                    }}
                                                >
                                                    {protocol.id} от {formatDate(protocol.createdAt)}
                                                </a>
                                            );
                                        })}
                                    </Collapse.Panel>
                                </Collapse>
                            </Grid>
                        )}
                        {/* endregion */}
                        {renderToolbar(false)}
                    </>
                )}
            </Grid>
            {createResultOpen && (
                <CreateResult
                    action={"createByReferral"}
                    title={t("resultList.createByReferralText")}
                    onClose={() => setCreateResultOpen(false)}
                    onSubmit={(response) => {
                        fetch();
                    }}
                    referral={data}
                />
            )}
            {deleteReferralOpen && (
                <RemovePopup
                    deleteAction={() => referalsCRUD.delete(id)}
                    title={`Вы действительно хотите удалить сводную заявку №${data.number}?`}
                    onClose={() => setDeleteReferralOpen(false)}
                    onSubmit={() => push(REFERRAL_LIST_ROUTE)}
                />
            )}
            {protocolAddOpen && (
                <CreateProtocol
                    action={"createByReferral"}
                    open={protocolAddOpen}
                    initNumber={data.number}
                    title={`Создание протокола для сводной заявки №${data.number}?`}
                    onClose={() => setDeleteReferralOpen(false)}
                    onSubmit={(data) => {
                        return dispatch(
                            addProtocol(data, { sort: { id: { operator: "asc" } } })
                        ).then(fetch);
                    }}
                    setOpen={setProtocolAddOpen}
                    request={data}
                    referral={data}
                    // departmentId={data.departmentId}
                    // filialId={data.filialId}
                    // performerId={data.performerId}
                    // responsibleId={data.responsibleId}
                    // filialDivisionId={data.filialDivisionId}
                    // divisionId={data.divisionId}
                />
            )}
            <Dialog
                open={protocolViewOpen}
                onClose={() => setProtocolViewOpen(false)}
                fullWidth
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Document
                                file={protocolViewPdf}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
}

export default ReferralData;
