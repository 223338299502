import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { SERVICE_GROUP_CREATE_ROUTE } from "../../routes";
import { SERVICE_GROUP_EDIT_ROUTE } from "../../routes";
import { SERVICE_GROUP_DETAILS_ROUTE } from "../../routes";
import { serviceGroupCRUD } from "../../http";
import useReload from "../../hooks/useReload";
import ServiceGroupCRUDTable from "../../components/CRUD/Tables/ServiceGroupCRUDTable";

const ServiceGroupList = () => {
    const location = useLocation();

    const { push } = useHistory();

    const { reload, reloadValue } = useReload();

    const handleDelete = async (id) => {
        await serviceGroupCRUD.delete(id);
        await reload();
    }

    const [filter, setFilter] = useState({
        name: {
            operand1: "Вода",
            operator: "like"
        }
    });

    return (
        <DashboardLayout>
            <ServiceGroupCRUDTable
                onClickCreateButton={() => push(SERVICE_GROUP_CREATE_ROUTE)}
                onRowClick={(record, rowIndex) => push(SERVICE_GROUP_EDIT_ROUTE(record.id), { record })}
                onClickDetailsButton={(id) => push(SERVICE_GROUP_DETAILS_ROUTE(id), { id })}
                onClickDeleteButton={(id) => handleDelete(id)}
                reloadValue={reloadValue}
                defaultFilter={filter}
            />
        </DashboardLayout>
    )
};

export default ServiceGroupList;
