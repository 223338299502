import m_13_2071 from '././aktau/KZ.M.13.2071.svg'
import m_13_2071_b from '././aktau/KZ.M.13.2071 black.svg'
import t_13_e0493_colorized from '././aktau/KZ.T.13.E0493.svg'
import t_13_e0493 from '././aktau/KZ.T.13.E0493 black.svg'
import t_13_2509 from '././karakianskoe/KZ.T.13.2509.svg'
import t_13_2509_b from '././karakianskoe/KZ.T.13.2509 black.svg'
import t_13_2490 from '././beineu/KZ.T.13.2490.svg'
import t_13_2490_b from '././beineu/KZ.T.13.2490 black.svg'
import t_13_2472 from '././mangistau/KZ.T.13.2472.svg'
import t_13_2472_b from '././mangistau/KZ.T.13.2472 black.svg'
import t_13_2501 from '././tupkaragan/KZ.T.13.2501.svg'
import t_13_2501_b from '././tupkaragan/KZ.T.13.2501 black.svg'
import t_13_2481 from '././zhanaozen/KZ.T.13.2481.svg'
import t_13_2481_b from '././zhanaozen/KZ.T.13.2481 black.svg'

export default [
  m_13_2071,
  m_13_2071_b,
  t_13_e0493_colorized,
  t_13_e0493,
  t_13_2509,
  t_13_2509_b,
  t_13_2490, 
  t_13_2490_b,
  t_13_2472,
  t_13_2472_b,
  t_13_2501,
  t_13_2501_b,
  t_13_2481,
  t_13_2481_b
]
