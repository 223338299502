import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { EnhancedCRUDTable } from '..';
import { checkRoles } from "../../../functions/checkRoles";
import { formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS } from "../../../functions/formatters";
import { actCRUD } from '../../../http';
import CRUDTable from "../CRUDTable";
import {actsFilterFields, actsSearchFields} from "../../../sections/Filters/ActsFilters";

export default function ActCRUDTable({ ...props }) {
  const { t } = useTranslation();
  const { push } = useHistory()
  const { pathname } = useLocation()
  const columns = [
    { title: "№", dataIndex: 'id', key: 'id' },
    { title: "Создано", dataIndex: 'createdAt', key: 'createdAt', render: row => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) },
    { title: "Номер", dataIndex: 'number', key: 'number' },
    {
      title: "Ф.И.О (при наличии) больного/обследуемого", dataIndex: 'test', key: 'test', render: (unknown, row) => {
        const data = typeof row.data === 'string' ? JSON.parse(row.data) : {}
        return (data?.examineName?.value || data?.sickName?.value)
         ? data?.examineName?.value || data?.sickName?.value
         : <span style={{ color: "gray" }}>Не задан</span>
      }
    },
    { title: "Филиал", dataIndex: 'filial', key: 'filial', render: (unknown, row) => row.filial ? row.filial.name : <span style={{ color: "gray" }}>Не задан</span> },
    {
      title: "Исполнитель", dataIndex: 'test', key: 'test', render: (unknown, row) => {
        switch (row.state?.code) {
          case 'ProbeSelection':
            return row.responsibleForProbes ? row.responsibleForProbes.fullName : ''
          case 'Research':
            return row.performer ? row.performer.fullName : ''
          case 'Completed':
            return row.performer ? row.performer.fullName : ''
          case 'Created':
            return ''
          case 'Processing':
            return row.responsible ? row.responsible.fullName : ''
          case 'Declined':
            return ''
          default:
            return ''
        }
      }
    },
    { title: "Статус", dataIndex: 'test', key: 'test', render: (unknown, row) => row.state ? row.state.name : <span style={{ color: "gray" }}>Не задан</span> },
    // {title: "rpnId", dataIndex: 'rpnId', key: 'rpnId'},
  ];

  const searchFields = [
    ...actsSearchFields,
  ];

  const filterFields = [
    ...actsFilterFields
  ];


  return <CRUDTable
    search={actCRUD.search}
    columns={columns}
    filterFields={filterFields}
    searchFields={searchFields}
    onRowClick={(object) => { push(`${pathname}Detail/${object.id}`) }}
    onClickEditButton={(object) => { push(`${pathname}Detail/${object.id}`) }}
    onClickDetailsButton={(object) => { push(`${pathname}Detail/${object.id}`) }}
    onClickCreateButton={checkRoles('SuperAdministrator', 'Administrator', 'Specialist', "Committee", "CommitteeAdministrator" ) ? () => push(`${pathname}Detail/new`) : null}
    {...props}
  />
}
