import React from 'react';
import CRUDTable from "../CRUDTable";
import {formatDate} from "../../../functions/formatters";
import {getContractStates} from "../../../redux/actions";
import {contractCRUD} from "../../../http";

export default function ContractCRUDTable({...props}) {
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {
            title: "Название",
            key: 'name',
            render: (unknown, row) => row.name ? row.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Дата окончания договора", dataIndex: 'actionAt', key: 'actionAt',
            render: (unknown, row) => row.actionAt ? formatDate(row.actionAt) :
                <span style={{color: "gray"}}>Не задана</span>
        },
        {
            title: "Клиент",
            key: 'client',
            render: (unknown, row) => row?.typeId === 5 ? <span style={{color: "gray"}}>Госзаказ</span> : row.client?.legalPersonId ? row.client?.legalPerson?.name : row.client?.personId ? row.client?.person?.fullName :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Тип договора",
            key: 'typeId',
            render: (unknown, row) => row.typeId ? row.type?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Филиал",
            key: 'filial',
            render: (unknown, row) => row.filialId ? row.filial?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Сумма",
            key: 'summary',
            render: (unknown, row) => row.summary ? row.summary :
                <span style={{color: "gray"}}>Не задано</span>
        },
        {
            title: "Статус",
            key: 'state',
            render: (unknown, row) => row.state ? row.state?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
    ]
    const searchFields = [
        {
            title: "Название договора",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Номер",
            key: "number",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Cтатус",
            key: "stateId",
            compareType: "equals",
            reducerKey: "contractState",
            reducerArrayKey: "contractStates",
            reducerAction: getContractStates,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Клиент",
            key: "clientId",
            fieldName: "ClientField",
            compareType: "equals",
            operandPosition: 1,
            type: "table",
            filterType: "normal",
        }
    ]

    return (
        <CRUDTable search={contractCRUD.search}
                   columns={columns}
                   {...props}
                   filterFields={filterFields}
                   searchFields={searchFields}
        />
    );
}
