import { 
    CONTRACT_FILE_GET_REQUEST, CONTRACT_FILE_GET_SUCCESS, CONTRACT_FILE_GET_ERROR, 
    CONTRACT_FILE_GET_BY_KEY_REQUEST, CONTRACT_FILE_GET_BY_KEY_SUCCESS, CONTRACT_FILE_GET_BY_KEY_ERROR, 
    CONTRACT_FILE_ADD_REQUEST, CONTRACT_FILE_ADD_SUCCESS, CONTRACT_FILE_ADD_ERROR, 
    CONTRACT_FILE_EDIT_REQUEST, CONTRACT_FILE_EDIT_SUCCESS, CONTRACT_FILE_EDIT_ERROR, 
    CONTRACT_FILE_DELETE_REQUEST, CONTRACT_FILE_DELETE_SUCCESS, CONTRACT_FILE_DELETE_ERROR
} from "../constants/contractFileConstants";

const initialState = {
    contractFiles: [],
    contractFile: {},
    loading: false,
    success: null,
    error: null,
    contractFileRequestedCount: 0,
};

export const contractFileReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_FILE_GET_REQUEST:
            return {
                ...state,
                contractFileRequestedCount: ++state.contractFileRequestedCount,
                loading: true,
            };
        case CONTRACT_FILE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractFiles: action.payload,
                success: true,
            };
        case CONTRACT_FILE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_FILE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_FILE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractFile: action.payload,
                success: true,
            };
        case CONTRACT_FILE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_FILE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_FILE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_FILE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_FILE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_FILE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_FILE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_FILE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_FILE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_FILE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
