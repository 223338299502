export const TRANSLATIONS_EN = {
    dashboard: {
        administration: "Administration",
        listItem: {
            users:"Employee",
            roles:"Roles",
            employes:"Сотрудники",
            filials:"Филиалы",
            filialDivisions:"Отделения",
            departments:"Лаборатории",
            divisions:"Отделы",
            serviceList: "Услуги",
            referralFormList: "Формы направления",
            referralList: "Направления",
            objectReferralList: "Направления (объект)",
            clinicalRequestList: "Заявки по КДИ",
            departureScheduleRequestList: "Заявки по графику отбора проб",
            desinsectionRequestList: "Заявки в отдел ДДД",
            serviceWithoutActRequestList: "Заявки в лабораторию без акта",
            withoutDepartureRequestList: "Заявки в лабораторию без выезда",
            brigadeList: "Наряд",
            desinfectionReferenceList: "Справка по дезинфекции",
            resultFormList: "Формы журнала",
            resultList: "Журналы",
            protocolFormList: "Формы протокола",
            protocolList: "Протоколы",
            patientList: "Пациенты",
            serviceKindList: "Виды услуг",
            servicePriceList: "Прейскурант",
            serviceExpenseList: "Расходы услуг",
            contractSpecificationList: "Приложение специфика",
            reagentList: "Реагенты",
            currencyList: "Валюты",
            genderList: "Пол ФЛ",
            governmentStandardList: "ГОСТ",
            medicalOrderList: "Приказы",
            positionList: "Должности",
            purposeOfSelectionList: "Место отбора образца",
            referralAdditionalInfoList: "ReferralAdditionalInfo",
            referralOrderTypeList: "ReferralOrderType",
            samplePreservationMethodList: "Методы консервации образца",
            samplingPurposeList: "SamplingPurposeList",
            serviceScheduleList: "График",
            storageConditionList: "Условия хранения",
            technicalRegulationsOfCustomsUnionList: "ТР ТС",
            transportationConditionList: "Условия транспортировки",
            transportTypeList: "Вид транспорта",
            unitOfMeasureList: "Ед измерения",
            chemicalIndicesList: "Химические показатели",
            diseaseList: "Диагнозы",
            infectionKindList: "Виды инфекции",
            sicknessKindList: "Виды заболеваний",
            requestLockList: "Блокировка тарификатора",
            exit: 'Выход',
            diseaseIndex: 'Показатели'
        },
        dictionaries: "Справочники"
    },
    serviceList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            price: 'Цена',
            referralSchema: 'Направление',
            probeSchema: 'Проба',
            resultSchema: 'Результат',
            parent: 'Родительская',
            kind: 'Метод',
            type: 'Тип',
            unit: 'Ед.измерения',
            group: 'Группа',
            subGroup: 'Подгруппа',
            actions: 'Actions'
        },
        searchLabel: 'Find Service...',
        detailsText: 'Просмотр',
    },
    referralFormList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            schema: 'Форма',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find ReferralForm...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    referralList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            schema: 'Форма',
            data: 'Данные',
            service: 'Услуга',
            contract: 'Контракт',
            object: 'Объект',
            performer: "Исполнитель",
            actions: 'Actions'
        },
        searchLabel: 'Find Referral...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    objectReferralList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            schema: 'Форма',
            data: 'Данные',
            state: 'Статус',
            service: 'Услуга',
            contract: 'Контракт',
            object: 'Объект',
            actions: 'Actions'
        },
        searchLabel: 'Find AnObjectReferral...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    clinicalRequestList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            contractRequest: 'Сводная заявка',
            state: 'Статус',
            referral: 'Направление',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find ClinicalRequest...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    departureScheduleRequestList: {
        tableItem: {
            id: 'ID',
            createdAt: 'Создано',
            name: 'Наименование объекта',
            addressLine: 'Адрес',
            dateOfDeparture: 'Дата выезда',
            authorComment: 'Комментарий автора',
            performerComment: 'Комментарий исполнителя',
            contractRequest: 'Сводная заявка',
            state: 'Статус',
            actions: 'Actions'
        },
        searchLabel: 'Find DepartureScheduleRequest...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    desinsectionRequestList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            departureDate: 'Дата выезда',
            contractRequest: 'Сводная заявка',
            state: 'Статус',
            departmentHead: 'Заведующий лаборатории/Начальник отдела',
            specialist: 'Специалист ДДД',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find DesinsectionRequest...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    serviceWithoutActRequestList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            contractRequest: 'Сводная заявка',
            state: 'Статус',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find ServiceWithoutActRequest...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    withoutDepartureRequestList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            contractRequest: 'Сводная заявка',
            state: 'Статус',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find WithoutDepartureRequest...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    brigadeList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            addressLine: 'Адрес',
            schema: 'Форма',
            data: 'Данные',
            address: 'Адрес',
            disinfector: 'Дезинфектор',
            type: 'Вид наряда',
            actions: 'Actions'
        },
        searchLabel: 'Find Brigade...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    desinfectionReferenceList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            fullName: 'ФИО',
            addressLine: 'Адрес',
            age: 'Возраст',
            address: 'Адрес',
            disinfector: 'Дезинфектор',
            actions: 'Actions'
        },
        searchLabel: 'Find DesinfectionReference...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    resultFormList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            schema: 'Форма',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find ResultForm...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    resultList: {
        tableItem: {
            id: 'ID',
            createdAt: 'Создано',
            schema: 'Форма',
            data: 'Данные',
            state: 'Статус',
            service: 'Услуга',
            referral: 'Направление',
            actions: 'Actions'
        },
        searchLabel: 'Find Result...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
        createByReferralText: 'Добавить запись журнала',
    },
    protocolFormList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            createdAt: 'Создано',
            schema: 'Форма',
            filial: 'Филиал',
            actions: 'Actions'
        },
        searchLabel: 'Find ProtocolForm...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    protocolList: {
        tableItem: {
            id: 'ID',
            number: 'Номер',
            state: 'Статус',
            createdAt: 'Создано',
            form: 'Форма',
            referral: 'Направление',
            result: 'Журнал',
            filial: 'Филиал',
            filialDivision: 'Районнное отделение',
            department: 'Лаборатория',
            division: 'Отдел',
            responsible: 'Заведующий лабораторией',
            performer: 'Исполнитель (лаборант)',
            actions: 'Actions'
        },
        searchLabel: 'Find Protocol...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    patientList: {
        tableItem: {
            id: 'ID',
            firstName: 'Имя',
            lastName: 'Фамилия',
            secondName: 'Отчество',
            fullName: 'ФИО',
            identityNumber: 'ИИН',
            birthDate: 'Дата рождения',
            yearsOld: 'Возраст',
            phoneNumber: 'Телефон',
            rpnId: 'ID системы РПН',
            gender: 'Пол',
            actions: 'Actions'
        },
        searchLabel: 'Find Patient...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    serviceKindList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find ServiceKind...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    servicePriceList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            price: 'Стоимость',
            service: 'Услуга',
            unit: 'Единица измерения',
            currency: 'Валюта',
            actions: 'Actions'
        },
        searchLabel: 'Find ServicePriceList...',
        detailsText: 'Просмотр',
    },
    serviceExpenseList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            summary: 'Сумма',
            service: 'Услуга',
            reagent: 'Реагент',
            actions: 'Actions'
        },
        searchLabel: 'Find ServiceExpense...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    contractSpecificationList: {
        tableItem: {
            id: 'ID',
            price: 'Стоимость',
            quantity: 'Количество',
            contract: 'Контракт',
            service: 'Услуга',
            serviceKind: 'Вид услуги',
            unit: 'Ед. измерения',
            servicePriceList: 'Прейскурант',
            actions: 'Actions'
        },
        searchLabel: 'Find ContractSpecification...',
        detailsText: 'Просмотр',
    },
    reagentList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            description: 'Описание',
            price: 'Стоимость',
            actions: 'Actions'
        },
        searchLabel: 'Find Reagent...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    currencyList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find Currency...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    genderList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find Gender...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    governmentStandardList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find GovernmentStandard...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    medicalOrderList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find MedicalOrder...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    positionList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find Position...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    purposeOfSelectionList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find PurposeOfSelection...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    referralAdditionalInfoList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find ReferralAdditionalInfo...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    referralOrderTypeList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find ReferralOrderType...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    samplePreservationMethodList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find SamplePreservationMethod...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    samplingPurposeList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find SamplingPurpose...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    serviceScheduleList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find ServiceSchedule...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    storageConditionList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find StorageCondition...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    technicalRegulationsOfCustomsUnionList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find TechnicalRegulationsOfCustomsUnion...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    transportationConditionList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find TransportationCondition...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    transportTypeList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find TransportType...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    unitOfMeasureList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find UnitOfMeasure...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    chemicalIndicesList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find ChemicalIndices...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    diseaseList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find Disease...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    infectionKindList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find InfectionKind...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    sicknessKindList: {
        tableItem: {
            id: 'ID',
            name: 'Наименование',
            code: 'Код',
            description: 'Описание',
            actions: 'Actions'
        },
        searchLabel: 'Find SicknessKind...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
    requestLockList: {
        tableItem: {
            id: 'ID',
            time: 'Месяц/Дата от',            
            untilTime: 'Дата до',
            isLocked: 'Заблокирован',
            filial: 'Филиал',
            actions: 'Actions'
        },
        filter: {
            IdFilter: 'ID',
            TimeFilter: 'Месяц',
        },
        searchLabel: 'Find RequestLock...',
        createText: 'Добавить',
        detailsText: 'Просмотр',
        editText: 'Изменить',
        removeText: 'Удалить',
    },
};
