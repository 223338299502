import { Grid } from "@mui/material";
import { requestStateIds } from "../models/requestStateIds";

export const renderSections = (sections, { mode, data, direction }) => {
  if (!sections || !mode) return <></>;

  const section = sections.filter((el) => el.mode === mode)[0];
  const Component = section?.component || <></>;
  if (!section) return <></>;

  return <Component directionForm={direction} data={data} mode={mode} onSubmit={section?.onSubmit} />;
};

export const renderControls = (controls, { state, role }) => {
  const control = controls.filter(
    (ctrl) =>
      (ctrl.state ? ctrl.state === state : true) &&
      (ctrl.roles?.length ? ctrl.roles.some(role) : true)
  )[0];
  if (!control) return <></>;

  return (
    <Grid container columnSpacing={2} style={{ justifyContent: "flex-end" }}>
      {control.buttons.map((button) => (
        <Grid item>{button}</Grid>
      ))}
    </Grid>
  );
};

export const State = {
  Created: "created",
  Processsing: "processing",
  Research: "research",
  Completed: "completed",
  Declined: "declined",
  Negotiation: "negotiation",
};

export class RequestStateChecker {
  constructor(stateCode) {
    this.stateCode = stateCode?.toLowerCase();
  }

  isCreated() {
    return !this.stateCode || this.stateCode === State.Created;
  }
  isProcessing() {
    return this.stateCode === State.Processsing;
  }
  isResearch() {
    return this.stateCode === State.Research;
  }
  isCompleted() {
    return this.stateCode === State.Completed;
  }
  isDeclined() {
    return this.stateCode === State.Declined;
  }
  isFinished() {
    return this.isCompleted() || this.isDeclined();
  }

  getId(stateCode) {
    switch (stateCode?.toLowerCase()) {
      case "created":
        return requestStateIds.created;
      case "processing":
        return requestStateIds.processing;
      case "research":
        return requestStateIds.research;
      case "completed":
        return requestStateIds.completed;
      case "declined":
        return requestStateIds.declined;
      case "negotiation":
        return requestStateIds.negotiation;
    }
  }
}
