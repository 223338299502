import React, {useCallback} from 'react';
import CreateObjectForm from "./CreateObjectForm";
import useDialogs from "../../components/Dialog/useDialogs";

export default function useCreateObjectForm() {
  const {DIALOG, openDialog, closeDialog} = useDialogs();

  const openCreateObjectForm = useCallback(({...props}) => {
    openDialog({
      content: <CreateObjectForm onClose={closeDialog} {...props}/>,
      onClose: closeDialog,
    })
  }, [openDialog, closeDialog]);

  return {openCreateObjectForm, DIALOG}
}
