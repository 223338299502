export const INFECTION_KIND_GET_REQUEST = 'INFECTION_KIND_GET_REQUEST';
export const INFECTION_KIND_GET_SUCCESS = 'INFECTION_KIND_GET_SUCCESS';
export const INFECTION_KIND_GET_ERROR = 'INFECTION_KIND_GET_ERROR';
export const INFECTION_KIND_GET_BY_KEY_REQUEST = 'INFECTION_KIND_GET_BY_KEY_REQUEST';
export const INFECTION_KIND_GET_BY_KEY_SUCCESS = 'INFECTION_KIND_GET_BY_KEY_SUCCESS';
export const INFECTION_KIND_GET_BY_KEY_ERROR = 'INFECTION_KIND_GET_BY_KEY_ERROR';
export const INFECTION_KIND_ADD_REQUEST = 'INFECTION_KIND_ADD_REQUEST';
export const INFECTION_KIND_ADD_SUCCESS = 'INFECTION_KIND_ADD_SUCCESS';
export const INFECTION_KIND_ADD_ERROR = 'INFECTION_KIND_ADD_ERROR';
export const INFECTION_KIND_EDIT_REQUEST = 'INFECTION_KIND_EDIT_REQUEST';
export const INFECTION_KIND_EDIT_SUCCESS = 'INFECTION_KIND_EDIT_SUCCESS';
export const INFECTION_KIND_EDIT_ERROR = 'INFECTION_KIND_EDIT_ERROR';
export const INFECTION_KIND_DELETE_REQUEST = 'INFECTION_KIND_DELETE_REQUEST';
export const INFECTION_KIND_DELETE_SUCCESS = 'INFECTION_KIND_DELETE_SUCCESS';
export const INFECTION_KIND_DELETE_ERROR = 'INFECTION_KIND_DELETE_ERROR';
