import { 
    SERVICE_SCHEDULE_GET_REQUEST, SERVICE_SCHEDULE_GET_SUCCESS, SERVICE_SCHEDULE_GET_ERROR, 
    SERVICE_SCHEDULE_GET_BY_KEY_REQUEST, SERVICE_SCHEDULE_GET_BY_KEY_SUCCESS, SERVICE_SCHEDULE_GET_BY_KEY_ERROR, 
    SERVICE_SCHEDULE_ADD_REQUEST, SERVICE_SCHEDULE_ADD_SUCCESS, SERVICE_SCHEDULE_ADD_ERROR, 
    SERVICE_SCHEDULE_EDIT_REQUEST, SERVICE_SCHEDULE_EDIT_SUCCESS, SERVICE_SCHEDULE_EDIT_ERROR, 
    SERVICE_SCHEDULE_DELETE_REQUEST, SERVICE_SCHEDULE_DELETE_SUCCESS, SERVICE_SCHEDULE_DELETE_ERROR
} from "../constants/serviceScheduleConstants";

const initialState = {
    serviceSchedules: [],
    serviceSchedule: {},
    loading: false,
    success: null,
    error: null,
    serviceScheduleRequestedCount: 0,
};

export const serviceScheduleReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_SCHEDULE_GET_REQUEST:
            return {
                ...state,
                serviceScheduleRequestedCount: ++state.serviceScheduleRequestedCount,
                loading: true,
            };
        case SERVICE_SCHEDULE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceSchedules: action.payload,
                success: true,
            };
        case SERVICE_SCHEDULE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SCHEDULE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SCHEDULE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceSchedule: action.payload,
                success: true,
            };
        case SERVICE_SCHEDULE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SCHEDULE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SCHEDULE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_SCHEDULE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SCHEDULE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SCHEDULE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_SCHEDULE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_SCHEDULE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_SCHEDULE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_SCHEDULE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
