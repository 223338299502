import { firstOrDefault, updateEntityCollectionItem } from '../data/EntityCollectionModel';

// TODO: use classes

export const trySetCount = (selectedSpecifications, localSpecifications, selectedId, contractRequest,
    setSelectedSpecifications, setLocalSpecifications, newCount) =>
{
    let result = true;

    newCount = parseInt(Number(newCount));

    const isSelected =
      selectedSpecifications?.filter(_ => _.id === selectedId)?.length > 0 || false;
    const isNumber = /[0-9]/.test(newCount);

    if (!isSelected || !isNumber) return result;

    const contractRequestSpecificationBySelectedId = firstOrDefault(contractRequest?.specifications, _ => _.id === selectedId);
    // use contract request specification to detect constraint of count
    const max = contractRequestSpecificationBySelectedId?.count || 1000000;

    const min = 0;

    if (newCount > max) {
        result = false;
        newCount = max;
    }

    const updatedSelectedSpecificationsInfo =
        updateEntityCollectionItem(selectedSpecifications, selectedId, _ => {
            return {..._, count: newCount};
        });
    const updatedLocalSpecificationsInfo =
        updateEntityCollectionItem(localSpecifications, selectedId, _ => {
            return {..._, count: newCount};
        });
    setSelectedSpecifications(updatedSelectedSpecificationsInfo.updatedCollection);
    setLocalSpecifications(updatedLocalSpecificationsInfo.updatedCollection);

    return result;
}
