import React, {useState, useEffect} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import ReferralCRUDTable from "../../components/CRUD/Tables/ReferralCRUDTable";
import { useLocation } from "react-router-dom";

export const ReferralTable = () => {
  const location = useLocation()
  const [stateId, setStateId] = useState(location?.search?.split('=')[1])
  useEffect(() => {
      if (location?.search) {
        setStateId(location?.search?.split('=')[1])
      } else {
        setStateId(null)
      }
  }, [location])
  return (
    <DashboardLayout>
      <ReferralCRUDTable stateId={stateId} filterId="ReferralCRUDTableFilter"/>
    </DashboardLayout>
  );
};
