export const REQUEST_TYPE_GET_REQUEST = 'REQUEST_TYPE_GET_REQUEST';
export const REQUEST_TYPE_GET_SUCCESS = 'REQUEST_TYPE_GET_SUCCESS';
export const REQUEST_TYPE_GET_ERROR = 'REQUEST_TYPE_GET_ERROR';
export const REQUEST_TYPE_GET_BY_KEY_REQUEST = 'REQUEST_TYPE_GET_BY_KEY_REQUEST';
export const REQUEST_TYPE_GET_BY_KEY_SUCCESS = 'REQUEST_TYPE_GET_BY_KEY_SUCCESS';
export const REQUEST_TYPE_GET_BY_KEY_ERROR = 'REQUEST_TYPE_GET_BY_KEY_ERROR';
export const REQUEST_TYPE_ADD_REQUEST = 'REQUEST_TYPE_ADD_REQUEST';
export const REQUEST_TYPE_ADD_SUCCESS = 'REQUEST_TYPE_ADD_SUCCESS';
export const REQUEST_TYPE_ADD_ERROR = 'REQUEST_TYPE_ADD_ERROR';
export const REQUEST_TYPE_EDIT_REQUEST = 'REQUEST_TYPE_EDIT_REQUEST';
export const REQUEST_TYPE_EDIT_SUCCESS = 'REQUEST_TYPE_EDIT_SUCCESS';
export const REQUEST_TYPE_EDIT_ERROR = 'REQUEST_TYPE_EDIT_ERROR';
export const REQUEST_TYPE_DELETE_REQUEST = 'REQUEST_TYPE_DELETE_REQUEST';
export const REQUEST_TYPE_DELETE_SUCCESS = 'REQUEST_TYPE_DELETE_SUCCESS';
export const REQUEST_TYPE_DELETE_ERROR = 'REQUEST_TYPE_DELETE_ERROR';

export const REQUEST_TYPE_ID_Undefined = 0;
// Заявка от клиента
export const REQUEST_TYPE_ID_ClientRequest = 1;
// Единоразовая заявка
export const REQUEST_TYPE_ID_ReferralRequest = 2;
// Заявка по госзаказу
export const REQUEST_TYPE_ID_AnObjectReferralRequest = 3;
// Сводная заявка
export const REQUEST_TYPE_ID_ContractRequest = 4;
// Заявка на КДИ
export const REQUEST_TYPE_ID_ClinicalRequest = 5;
// Отбор проб
export const REQUEST_TYPE_ID_ProbeSelectionRequest = 6;
// Заявка на исследование без акта
export const REQUEST_TYPE_ID_ServiceWithoutActRequest = 7;
// Заявка без выезда
export const REQUEST_TYPE_ID_WithoutDepartureRequest = 8;
// Заявка на ДДД
export const REQUEST_TYPE_ID_DesinsectionRequest = 9;
// График отбора проб
export const REQUEST_TYPE_ID_DepartureScheduleRequest = 10;
// Акт
export const REQUEST_TYPE_ID_Act = 11;
// Наряд
export const REQUEST_TYPE_ID_Brigade = 12;
// Протокол
export const REQUEST_TYPE_ID_Protocol = 13;

export const REQUEST_TYPE_ID_Resolution = 15;

export const REQUEST_TYPE_ID_Prescription = 14;

export const REQUEST_TYPE_ID_SelfControl = 17;