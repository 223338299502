import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useDialogs from "../../components/Dialog/useDialogs";
import useLoader from "../../hooks/useLoader";
import { renderControls } from "../../functions";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import CheckboxField from "../../components/Fields/CheckboxField";
import { TextField2 } from "../../components/Fields/TextField2";
import { TextPhoneField } from "../../components/Fields/TextPhoneField";
import { DateField } from "../../components/Fields/DateField";
import { DateTimeField } from "../../components/Fields/DateTimeField";
import { NumberField } from "../../components/Fields/NumberField";
import { GridItem } from "../../components/Fields/GridItem";
import { FilialSelect } from "../../components/Select/domainSelects";
import { changeData, changeDataMany } from "../../functions/changeHandlers";
import { getOrganizationFilialMapByKey } from "../../redux/actions/organizationFilialMapActions";
import { editOrganizationFilialMap } from "../../redux/actions/organizationFilialMapActions";
import { ORGANIZATION_FILIAL_MAP_LIST_ROUTE } from "../../routes";
import { submitData2 } from "../../functions/submitHandlers";

export const EditOrganizationFilialMap = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const [data, setData] = useState({});

    const {
        loading: submitLoading,
        start: startSubmit,
        stop: stopSubmit,
    } = useLoader(false);

    const handleChange = useCallback((key, value) => changeData(data, setData, key, value), [data]);
    const handleChangeMany = useCallback((obj) => changeDataMany(data, setData, obj), [data]);

    const {alert, DIALOG} = useDialogs();

    const fetch = async () => {
        fetchStart();

        const item = await dispatch(getOrganizationFilialMapByKey(id));
        if (!item) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setData(item);
        fetchStop();
    };

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, fetchStart, fetchStop]);

    const handleSubmit = async (e) => {
        e?.preventDefault();

        await submitData2(data, async () =>
        {
            return true;
        }, startSubmit, stopSubmit, async () =>
        {
            return await dispatch(editOrganizationFilialMap(data));
        });
    };

    const controls = [
    {
        state: "current",
        buttons: [
            <LoadingButton loading={submitLoading} onClick={handleSubmit}>
                Сохранить
            </LoadingButton>,
            <Button
                variant={"text"}
                onClick={() => push(ORGANIZATION_FILIAL_MAP_LIST_ROUTE)}
            >
                Вернуться
            </Button>,
        ],
    }
    ];

    return (
        <DashboardLayout>
            <>
                <DIALOG/>
                <PageHeader heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
                </PageHeader>
                <PageContainer>
                    <FormSection>
                        <Grid container>
                            <GridItem xs={6}>
                                <FilialSelect
                                    label={"Филиал отобразить c"}
                                    fullWidth
                                    value={data.sourceFilialId || ""}
                                    onChange={(value, obj) => {
                                        handleChangeMany({ sourceFilial: obj, sourceFilialId: value })
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <FilialSelect
                                    label={"Филиал отобразить на"}
                                    fullWidth
                                    value={data.targetFilialId || ""}
                                    onChange={(value, obj) => {
                                        handleChangeMany({ targetFilial: obj, targetFilialId: value })
                                    }}
                                />
                            </GridItem>
                        </Grid>
                    </FormSection>
                </PageContainer>
                <PageFooter heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
                </PageFooter>
            </>
        </DashboardLayout>
    )
};
