import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'
import {Button} from "@mui/material";

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

DirectionPeoplePDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data
  const schema = [
    {
      key: '1. Зерттелушінің Т.А.Ә. (болған жағдайда) (Ф.И.О. (при наличии) обследуемого)',
      value: actData?.examineName?.value
    },
    {
      key: '2. Жасы (Возраст)',
      value: actData?.age?.value
    },
    {
      key: '3. Мекен-жайы (Адрес)',
      value: actData?.adress?.value
    },
    {
      key: '4. Жұмыс орны (Место работы)',
      value: actData?.workPlace?.value
    },
    {
      key: '5. Диагнозы (Диагноз)',
      value: actData?.diagnosis?.value
    },
    {
      key: '6. Наманың алынған күні және уақыты (Дата и время забора материала)',
      value: actData?.takeDateTime?.value ? formatTime(new Date(actData?.takeDateTime.value)) : ' '
    },
    {
      key: '7. Бірінші рет немесе қайталап (Первичный или повторный)',
      value: actData?.firstTimeOrRepeated?.value
    },
    {
      key: '8. Антибиотикті қабылдағаны туралы (Применения антибиотика)',
      value: actData?.antibioticsInfo?.value
    },
    {
      key: '9. Жіберген мекеме (Направляющее учреждения)',
      value: actData?.placeSender?.value
    },
    {
      key: '10. Үлгіні жіберген адамның Т.А.Ә (болған жағдайда), (Ф.И.О (при наличии), направившего материал)',
      value: actData?.senderName?.value
    },
    {
      key: '11. Сынаманың № (№ анализа)',
      value: actData?.analysis?.value
    },
    {
      key: '12. Қабылдау күні және уақыты (Дата и время приема)',
      value: actData?.receptionDateTime?.value ? formatTime(new Date(actData?.receptionDateTime.value)) : ' '
    },
    {
      key: '13. Зерттеу қорытындысы (Результат исследования)',
      value: actData?.result?.value
    },
    {
      key: '14. Қорытынды берілген күн (Дата выдачи ответа)',
      value: actData?.responseDate?.value ? formatDate(new Date(actData?.responseDate.value)) : ' '
    },
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Table style={styles.table}>
          <Row>
            <Cell></Cell>
            <Cell>
              <Text>Нысанның БҚСЖ бойынша коды</Text>
              <Text>Код формы по ОКУД ___________________</Text>
              <Text>КҰЖЖ бойынша ұйым коды</Text>
              <Text>Код организации по ОКПО _____________</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрлігі Министерство здравоохранения Республики Казахстан</Text>
            </Cell>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы "__" __________ № бұйрығымен бекітілген №____021/е нысанды медициналық құжаттама</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк органының атауы Наименование государственного органа санитарно-эпидемиологической службы</Text>
            </Cell>
            <Cell>
              <Text>Медицинская документация Форма № 021/у Утверждена приказом Министра здравоохранения Республики Казахстан от "__" ______ 2021 года №______</Text>
            </Cell>
          </Row>
        </Table>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {actData?.dateOfDirection?.value ? formatDate(new Date(actData.dateOfDirection.value)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Жіберушінің қолы (болған жағдайда) (Подпись направившего материал (при наличии)):</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зерттеу жүргiзген маманның Т.А.Ә.(болған жағдайда), лауазымы (Ф.И.О.(при наличии), должность специалиста проводившего исследование): {data.performer?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зертхана меңгерушісінің Т.А.Ә. (болған жағдайда), қолы. (Ф.И.О.(при наличии), подпись заведующего лабораторией): {data.responsible?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
          <Text style={styles.string}>Направление составлено в экземплярах(кол-во) _____</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function DirectionPeoplePDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        <Button>
          {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
        </Button>
      </PDFDownloadLink>
    </>
  )
}
