import { desinfectionReferenceCRUD } from "../../http";
import {
  DESINFECTION_REFERENCE_GET_REQUEST,
  DESINFECTION_REFERENCE_GET_SUCCESS,
  DESINFECTION_REFERENCE_GET_ERROR,
  DESINFECTION_REFERENCE_GET_BY_KEY_REQUEST,
  DESINFECTION_REFERENCE_GET_BY_KEY_SUCCESS,
  DESINFECTION_REFERENCE_GET_BY_KEY_ERROR,
  DESINFECTION_REFERENCE_ADD_REQUEST,
  DESINFECTION_REFERENCE_ADD_SUCCESS,
  DESINFECTION_REFERENCE_ADD_ERROR,
  DESINFECTION_REFERENCE_EDIT_REQUEST,
  DESINFECTION_REFERENCE_EDIT_SUCCESS,
  DESINFECTION_REFERENCE_EDIT_ERROR,
  DESINFECTION_REFERENCE_DELETE_REQUEST,
  DESINFECTION_REFERENCE_DELETE_SUCCESS,
  DESINFECTION_REFERENCE_DELETE_ERROR,
} from "../constants/desinfectionReferenceConstants";

export const getDesinfectionReferences = (args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINFECTION_REFERENCE_GET_REQUEST,
    });

    const data = await desinfectionReferenceCRUD.search(args);
    dispatch({
      type: DESINFECTION_REFERENCE_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: DESINFECTION_REFERENCE_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDesinfectionReferenceByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DESINFECTION_REFERENCE_GET_BY_KEY_REQUEST,
    });

    const data = await desinfectionReferenceCRUD.get(id);
    dispatch({
      type: DESINFECTION_REFERENCE_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: DESINFECTION_REFERENCE_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addDesinfectionReference = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINFECTION_REFERENCE_ADD_REQUEST,
    });

    const response = await desinfectionReferenceCRUD.create(data);
    await dispatch(getDesinfectionReferences(args));
    dispatch({
      type: DESINFECTION_REFERENCE_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: DESINFECTION_REFERENCE_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editDesinfectionReference = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINFECTION_REFERENCE_EDIT_REQUEST,
    });

    await desinfectionReferenceCRUD.edit(data);
    await dispatch(getDesinfectionReferences(args));
    dispatch({
      type: DESINFECTION_REFERENCE_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DESINFECTION_REFERENCE_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteDesinfectionReference = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: DESINFECTION_REFERENCE_DELETE_REQUEST,
    });

    await desinfectionReferenceCRUD.delete(id);
    await dispatch(getDesinfectionReferences(args));

    dispatch({
      type: DESINFECTION_REFERENCE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DESINFECTION_REFERENCE_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
