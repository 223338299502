/* eslint-disable import/no-anonymous-default-export */
export default {
  '57': {
    version:2,
    name: 'ПРОТОКОЛ дозиметрических измерений рентгеновского излучения в рентгеновском кабинете',
    kazakhName: 'Рентген кабинетіндегі рентген сәулесін дозиметриялық өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'organization_name',
        type: 'text',
        label: 'Наименование учреждения',
        kazakhLabel: 'Мекеменің атауы'
      },
      {
        id: 'adress',
        type: 'text',
        label: 'Адрес: корпус, этаж, комната',
        kazakhLabel: 'Мекен жайы: Корпусы, Қабат, Бөлме'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объект өкілінің қатысуымен жүргізілді'
      },
      {
        id: 'room_purpose',
        type: 'text',
        label: 'Назначение кабинета',
        kazakhLabel: 'Бөлме атауы'
      },
      {
        id: 'telephone',
        type: 'text',
        label: 'телефон',
        kazakhLabel: 'телефоны'
      },
      {
        id: 'apparat',
        type: 'text',
        label: 'Аппарат',
        kazakhLabel: 'Аппарат'
      },
      {
        id: 'technical_passport',
        type: 'text',
        label: 'Технический паспорт',
        kazakhLabel: 'Техникалық паспорт'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'срок действия до',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'common_kv_filter',
        type: 'text',
        label: 'Общий фильтр кВ',
        kazakhLabel: 'Анодтық кернеу кВ'
      },
      {
        id: 'additional_filter',
        type: 'text',
        label: 'Дополнительный фильтр',
        kazakhLabel: 'Қосымша сүзгі'
      },
      {
        id: 'measurement_done_with_equalcloth_phantom',
        type: 'text',
        label: 'Измерения проводились с тканеэквивалентным фантомом',
        kazakhLabel: 'Измерения проводились с тканеэквивалентным фантомом'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Cертификат (о поверке)',
        kazakhLabel: 'Дозиметр түрі (дозиметром типа), сәйкестігі туралы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },      
      // {
      //   id: 'research_on_nd',
      //   type: 'text',
      //   label: 'Исследование образца проводились на соответствие НД',
      //   kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      // },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1425 style='border-collapse:
        collapse;table-layout:fixed;width:1069pt'>
        <col width=64 style='width:48pt'>
        <col width=250 style='mso-width-source:userset;mso-width-alt:8903;width:188pt'>
        <col width=64 style='width:48pt'>
        <col width=471 style='mso-width-source:userset;mso-width-alt:16753;width:353pt'>
        <col width=64 span=9 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1524013 width=64 style='height:66.0pt;width:48pt'></td>
         <td class=xl7624013 width=250 style='width:188pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=471 style='border-left:none;width:353pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td class=xl7724013 width=64 style='border-left:none;width:48pt'>&nbsp;</td>
         <td rowspan=10 class=xl7724013 width=64 style='border-bottom:1.0pt solid black;
         width:48pt'>&nbsp;</td>
         <td class=xl6324013 width=64 style='border-left:none;width:48pt'>Кабинетті&#1187;
         &#1199;стінде (Над кабинетом)</td>
        </tr>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1524013 style='height:66.0pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6424013 width=64 style='border-top:none;border-left:none;
         width:48pt'>Кабинетті&#1187; астында (Под кабинетом)</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1524013 style='height:79.2pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6424013 width=64 style='border-top:none;border-left:none;
         width:48pt'>А &#1179;абыр&#1171;асыны&#1187; артында (За стеной А)</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1524013 style='height:79.2pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6424013 width=64 style='border-top:none;border-left:none;
         width:48pt'>Б &#1179;абыр&#1171;асыны&#1187; артында (За стеной Б)</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1524013 style='height:79.2pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6424013 width=64 style='border-top:none;border-left:none;
         width:48pt'>В &#1179;абыр&#1171;асыны&#1187; артында (За стеной В)</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1524013 style='height:79.2pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6424013 width=64 style='border-top:none;border-left:none;
         width:48pt'>Г &#1179;абыр&#1171;асыны&#1187; артында (За стеной Г)</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1524013 style='height:79.2pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6424013 width=64 style='border-top:none;border-left:none;
         width:48pt'>Д &#1179;абыр&#1171;асыны&#1187; артында (За стеной Д)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1524013 style='height:14.4pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td rowspan=3 class=xl7324013 width=64 style='border-bottom:1.0pt solid black;
         border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1524013 style='height:14.4pt'></td>
         <td class=xl7824013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6824013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1524013 style='height:15.0pt'></td>
         <td class=xl7024013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=471 style='border-top:none;border-left:none;
         width:353pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7124013 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Размещение оборудования',
       kazakhLabel: 'Жабдықтың орналасуы'
      },
      {
        id: 'form_table_2',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1425 style='border-collapse:
        collapse;table-layout:fixed;width:1069pt'>
        <col width=64 style='width:48pt'>
        <col width=250 style='mso-width-source:userset;mso-width-alt:8903;width:188pt'>
        <col width=64 style='width:48pt'>
        <col width=471 style='mso-width-source:userset;mso-width-alt:16753;width:353pt'>
        <col width=64 span=9 style='width:48pt'>
        <tr height=24 style='height:18.0pt'>
          <td height=24 class=xl1524013 style='height:18.0pt'></td>
          <td colspan=3 class=xl8124013>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &#1256;ЛШЕМДЕР
          Н&#1240;ТИЖЕЛЕРІ (РЕЗУЛЬТАТЫ ИЗМЕРЕНИЙ)</td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
        </tr>
        <tr height=24 style='height:18.0pt'>
          <td height=24 class=xl1524013 style='height:18.0pt'></td>
          <td colspan=3 class=xl7924013>(Ж&#1201;мыс ж&#1199;ктемесі кезінде (при
          рабочей нагрузке) ____ мА·мин/нед.апта)</td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6624013 width=250 style='width:188pt'>№№</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>&#1256;лшеу
          ж&#1199;ргізілген орынны&#1187; атауы</td>
          <td rowspan=6 class=xl6624013 width=64 style='width:48pt'>С&#1241;уле
          ба&#1171;ыты (Направление излучения)</td>
          <td class=xl6624013 width=64 style='border-left:none;width:48pt'>I изм,</td>
          <td colspan=4 rowspan=3 class=xl6624013 width=256 style='width:192pt'>Доза
          &#1179;уаты (Мощность дозы)</td>
          <td rowspan=6 class=xl6624013 width=64 style='width:48pt'>ДМД, мкЗв/ч</td>
          <td class=xl6624013 width=64 style='border-left:none;width:48pt'>Ескертпе</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>точ.</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>(Наименование
          места измерения)</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>мА</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>Примечание</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>изм.</td>
          <td colspan=2 class=xl6724013 width=535 style='border-left:none;width:401pt'>&nbsp;</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>1</td>
          <td colspan=2 class=xl6724013 width=535 style='border-left:none;width:401pt'>&nbsp;</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td rowspan=3 class=xl6624013 width=64 style='border-top:none;width:48pt'>МД
          изм</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>•</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>•</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>•</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6724013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
          <td colspan=2 class=xl6724013 width=535 style='border-left:none;width:401pt'>&nbsp;</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>D изм,</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>D прив,</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>Е, мкЗв/ч</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6724013 width=250 style='border-top:none;width:188pt'>&nbsp;</td>
          <td colspan=2 class=xl6724013 width=535 style='border-left:none;width:401pt'>&nbsp;</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>мкГр/ч</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>мкГр/ч</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>1</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>2</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>3</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>4</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>5</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>6</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>7</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>8</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>9</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>10</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=20 style='height:15.0pt'>
          <td height=20 class=xl1524013 style='height:15.0pt'></td>
          <td class=xl7524013 width=250 style='width:188pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=471 style='width:353pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl7524013 width=64 style='width:48pt'></td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl8024013 width=1297 style='border-right:1.0pt solid black;
          width:973pt'>Рентген кабинетпен жапсарлас &#1199;й-жайлар(Помещения, смежные
          с процедурной рентгеновского кабинета)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl6924013 width=1297 style='border-right:1.0pt solid black;
          width:973pt'>(Фон)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>1</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>Кабинетті&#1187;
          &#1199;стінде (Над кабинетом)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>2</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>Кабинетті&#1187;
          астында (Под кабинетом)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=70 style='height:52.8pt'>
          <td height=70 class=xl1524013 style='height:52.8pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>3</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'><span style='mso-spacerun:yes'>  </span>А
          &#1179;абыр&#1171;асыны&#1187; артында<span style='mso-spacerun:yes'> </span></td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>&#1178;абыр&#1171;а Стена</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>4</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>За стеной А</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Терезе Окно</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>5</td>
          <td class=xl6724013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Есік Дверь</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>6</td>
          <td rowspan=3 class=xl6624013 width=64 style='border-top:none;width:48pt'>Б
          &#1179;абыр&#1171;асыны&#1187; артында За стеной Б</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>&#1178;абыр&#1171;а Стена</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>7</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Терезе Окно</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>8</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Есік Дверь</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>9</td>
          <td rowspan=3 class=xl6624013 width=64 style='border-top:none;width:48pt'>В
          &#1179;абыр&#1171;асыны&#1187; артында За стеной В</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>&#1178;абыр&#1171;а Стена</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>10</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Терезе Окно</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>11</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Есік Дверь</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>12</td>
          <td rowspan=3 class=xl6624013 width=64 style='border-top:none;width:48pt'>Г
          &#1179;абыр&#1171;асыны&#1187; артында За стеной Г</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>&#1178;абыр&#1171;а Стена</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>13</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Терезе Окно</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>14</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Есік Дверь</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>15</td>
          <td rowspan=3 class=xl6624013 width=64 style='border-top:none;width:48pt'>Д
          &#1179;абыр&#1171;асыны&#1187; артында За стеной Д</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>&#1178;абыр&#1171;а Стена</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>16</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Терезе Окно</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>17</td>
          <td class=xl6624013 width=471 style='border-top:none;border-left:none;
          width:353pt'>Есік Дверь</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl6924013 width=1297 style='border-right:1.0pt solid black;
          width:973pt'>Рентген аппаратты бас&#1179;ару пультіндегі рентген
          зертханашыны&#1187; ж&#1201;мыс орны (Рабочее место рентгенолаборанта у
          пульта управления рентгеновским аппаратом)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>18</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>Бас
          (Голова)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td class=xl6924013 width=250 style='border-top:none;width:188pt'>19</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>Кеуде
          (Грудь)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7324013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td rowspan=2 class=xl6924013 width=250 style='border-top:none;width:188pt'>20</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>Жынысты&#1179;
          бездері</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=2 class=xl7324013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>(Гонады)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=20 style='height:15.0pt'>
          <td height=20 class=xl1524013 style='height:15.0pt'></td>
          <td class=xl6524013 width=250 style='border-top:none;width:188pt'>21</td>
          <td colspan=2 class=xl8524013 width=535 style='border-left:none;width:401pt'><span
          style='mso-spacerun:yes'>  </span>Ая&#1179; (Ноги)<span
          style='mso-spacerun:yes'> </span></td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7124013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7224013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl8324013 width=1297 style='border-right:1.0pt solid #CFCFCF;
          width:973pt'>••</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl6624013 width=1297 style='width:973pt'>Е-ні&#1187;
          есептелген м&#1241;ндері Е (Рассчитанные значения Е)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl6624013 width=1297 style='width:973pt'>Рентген
          кабинетті&#1187; ем-шараб болмеші (Процедурная рентгеновского кабинета)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=11 class=xl6624013 width=1297 style='width:973pt'>Врач
          рентгенологты&#1187; ж&#1201;мыс орыны (Рабочее место врача-рентгенолога)</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>22</td>
          <td colspan=2 rowspan=2 class=xl6624013 width=535 style='width:401pt'>Бас
          (Голова)</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>Тік (Вертик)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>23</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>К&#1257;лдене&#1187; (Гориз)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>24</td>
          <td colspan=2 rowspan=2 class=xl6624013 width=535 style='width:401pt'><span
          style='mso-spacerun:yes'>  </span>Кеуде (Грудь)<span
          style='mso-spacerun:yes'> </span></td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>Тік (Вертик)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>25</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>К&#1257;лдене&#1187; (Гориз)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>26</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>Жынысты&#1179;
          бездері</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>Тік (Вертик)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>27</td>
          <td colspan=2 class=xl6624013 width=535 style='border-left:none;width:401pt'>(Гонады)</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>К&#1257;лдене&#1187; (Гориз)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>28</td>
          <td colspan=2 rowspan=2 class=xl6624013 width=535 style='width:401pt'>Ая&#1179;
          (Ноги)</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>Тік (Вертик)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=250 style='border-top:none;width:188pt'>29</td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>К&#1257;лдене&#1187; (Гориз)</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=3 rowspan=4 class=xl6624013 width=785 style='width:589pt'>Барлы&#1171;ы
          (Всего)</td>
          <td rowspan=3 class=xl6624013 width=64 style='border-top:none;width:48pt'>Тік
          (Вертик)</td>
          <td colspan=3 class=xl6624013 width=192 style='border-left:none;width:144pt'>•</td>
          <td rowspan=3 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=3 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=3 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td rowspan=3 class=xl6824013 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=3 class=xl6624013 width=192 style='border-left:none;width:144pt'>Есептелген
          Е</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td height=19 class=xl1524013 style='height:14.4pt'></td>
          <td colspan=3 class=xl6624013 width=192 style='border-left:none;width:144pt'>•</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=35 style='height:26.4pt'>
          <td height=35 class=xl1524013 style='height:26.4pt'></td>
          <td class=xl6624013 width=64 style='border-top:none;border-left:none;
          width:48pt'>К&#1257;лдене&#1187; (Гориз)</td>
          <td colspan=3 class=xl6624013 width=192 style='border-left:none;width:144pt'>Рассчитанные
          Е</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6824013 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl1524013></td>
        </tr>
        <tr height=24 style='height:18.0pt'>
          <td height=24 class=xl1524013 style='height:18.0pt'></td>
          <td class=xl7424013 colspan=3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &#1198;лгілерді&#1187; (ні&#1187;) Н&#1178;-&#1171;а с&#1241;йкестiгiне
          зерттеулер ж&#1199;ргiзiлдi</td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
        </tr>
        <tr height=24 style='height:18.0pt'>
          <td height=24 class=xl1524013 style='height:18.0pt'></td>
          <td class=xl7424013 colspan=3>(Исследование образца проводились на
          соответствие НД)________________</td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
          <td class=xl1524013></td>
        </tr>
        <![if supportMisalignedColumns]>
        <tr height=0 style='display:none'>
          <td width=64 style='width:48pt'></td>
          <td width=250 style='width:188pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=471 style='width:353pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
          <td width=64 style='width:48pt'></td>
        </tr>
        <![endif]>
       </table>`,
       label: 'Размещение оборудования',
       kazakhLabel: 'Жабдықтың орналасуы'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="12" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="49"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="117"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="49" height="32" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="10" width="65" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="117" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Кабинеттің үстінде (Над кабинетом)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Кабинеттің астында (Под кабинетом)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">А қабырғасының артында (За стеной А)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Б қабырғасының артында (За стеной Б)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">В қабырғасының артында (За стеной В)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Г қабырғасының артында (За стеной Г)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Д қабырғасының артында (За стеной Д)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="19" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="19" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="19" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Размещение оборудования',
       kazakhLabel: 'Жабдықтың орналасуы'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="11" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="55"><col width="102"><col width="134"><col width="85"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="96"></colgroup>
        <tbody>
          <tr>
            <td colspan="11" width="859" height="20" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ӨЛШЕМДЕР НӘТИЖЕЛЕРІ (РЕЗУЛЬТАТЫ ИЗМЕРЕНИЙ)</font></td>
            </tr>
          <tr>
            <td style="border-bottom: 1px solid #000000" colspan="11" height="20" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">(Жұмыс жүктемесі кезінде (при рабочей нагрузке) ____ мА·мин/нед.апта)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="110" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№№<br>точ.<br>изм.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу жүргізілген орынның атауы<br>(Наименование места измерения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сәуле бағыты (Направление излучения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">I изм,<br>мА</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Доза қуаты (Мощность дозы)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ДМД, мкЗв/ч</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескертпе<br>Примечание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">МД изм</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">•<br>D изм,<br>мкГр/ч</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">•<br>D прив,<br>мкГр/ч</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">•<br>Е, мкЗв/ч</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
          </tr>
          <tr>
            <td height="19" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan="11" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рентген кабинетпен жапсарлас үй-жайлар(Помещения, смежные с процедурной рентгеновского кабинета)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan="11" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">(Фон)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кабинеттің үстінде (Над кабинетом)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кабинеттің астында (Под кабинетом)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="28" align="JUSTIFY" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">А қабырғасының артында <br>За стеной АА қабырғасының артында <br>За стеной АА қабырғасының артында <br>За стеной А</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қабырға Стена</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Терезе Окно</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="37" align="JUSTIFY" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Есік Дверь</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Б қабырғасының артында <br>За стеной ББ қабырғасының артында <br>За стеной ББ қабырғасының артында <br>За стеной Б</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қабырға Стена</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Терезе Окно</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="46" align="JUSTIFY" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Есік Дверь</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">В қабырғасының артында <br>За стеной ВВ қабырғасының артында <br>За стеной ВВ қабырғасының артында <br>За стеной В</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қабырға Стена</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Терезе Окно</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="47" align="JUSTIFY" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Есік Дверь</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Г қабырғасының артында <br>За стеной ГГ қабырғасының артында <br>За стеной ГГ қабырғасының артында <br>За стеной Г</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қабырға Стена</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Терезе Окно</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="50" align="JUSTIFY" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Есік Дверь</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Д қабырғасының артында <br>За стеной ДД қабырғасының артында <br>За стеной Д</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қабырға Стена</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Терезе Окно</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="39" align="JUSTIFY" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Есік Дверь</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan="11" height="36" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рентген аппаратты басқару пультіндегі рентген зертханашының жұмыс орны (Рабочее место рентгенолаборанта у пульта управления рентгеновским аппаратом)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="18" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">18</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бас (Голова)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="19" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">19</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кеуде (Грудь)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdval="20" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">20</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жыныстық бездері (Гонады)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdval="21" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">21</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">  Аяқ (Ноги) </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-left: 3px solid #cfcfcf; border-right: 3px solid #cfcfcf" colspan="11" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">••</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="11" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Е-нің есептелген мәндері Е (Рассчитанные значения Е)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="11" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рентген кабинеттің ем-шараб болмеші (Процедурная рентгеновского кабинета)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="11" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Врач рентгенологтың жұмыс орыны (Рабочее место врача-рентгенолога)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="40" align="JUSTIFY" valign="MIDDLE" sdval="22" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">22</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" rowspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бас (Голова)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тік (Вертик)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="40" align="JUSTIFY" valign="MIDDLE" sdval="23" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">23</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көлденең (Гориз)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="40" align="JUSTIFY" valign="MIDDLE" sdval="24" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">24</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" rowspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">  Кеуде (Грудь) </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тік (Вертик)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="40" align="JUSTIFY" valign="MIDDLE" sdval="25" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">25</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көлденең (Гориз)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="42" align="JUSTIFY" valign="MIDDLE" sdval="26" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">26</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жыныстық бездері</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тік (Вертик)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="42" align="JUSTIFY" valign="MIDDLE" sdval="27" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">27</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">(Гонады)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көлденең (Гориз)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="40" align="JUSTIFY" valign="MIDDLE" sdval="28" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">28</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" rowspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аяқ (Ноги)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тік (Вертик)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="40" align="JUSTIFY" valign="MIDDLE" sdval="29" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">29</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көлденең (Гориз)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" rowspan="4" height="105" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Барлығы (Всего)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тік (Вертик)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">•</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Есептелген Е</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">•</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көлденең (Гориз)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рассчитанные Е</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Размещение оборудования',
       kazakhLabel: 'Жабдықтың орналасуы'
      },
    ]
  },
  '58': {
    version:2,
    name: 'Металды, металдан және метал қалдықтарынан жасалған бұйымдарды дозиметриялық бақылау ХАТТАМАСЫ',
    kazakhName: 'ПРОТОКОЛ дозиметрического контроля металла, изделия из металла и металлолома',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'measurement_place',
        type: 'text',
        label: 'Место проведения замеров (площадка, цех, квартал, склад',
        kazakhLabel: 'Өлшеулер жүргізілетін орын'
      },
      {
        id: 'measurement_adress',
        type: 'text',
        label: 'Метод измерения',
        kazakhLabel: 'Өлшеулер әдісі'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объект өкілінің қатысуымен жүргізілді'
      },
      {
        id: 'measurement_methods',
        type: 'text',
        label: 'Средства измерений / наименование',
        kazakhLabel: 'Өлшеу құралдары атауы'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемі'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'Сведения о государственной поверке (дата и номер свидетельства)',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер, берілген күні мен куәліктің нөмірі'
      },
      {
        id: 'med_indicator',
        type: 'text',
        label: 'МЭД (показатель) естественного гамма-фона местности',
        kazakhLabel: 'Аймақтың табиғи гамма-аяның ЭМҚ (көрсеткіш)ҒҒҒ'
      },
      {
        id: 'measurement_results',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1504 style='border-collapse:
        collapse;table-layout:fixed;width:1128pt'>
        <col width=64 style='width:48pt'>
        <col width=336 style='mso-width-source:userset;mso-width-alt:11946;width:252pt'>
        <col width=64 style='width:48pt'>
        <col width=528 style='mso-width-source:userset;mso-width-alt:18773;width:396pt'>
        <col width=64 span=8 style='width:48pt'>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1528104 width=64 style='height:26.4pt;width:48pt'></td>
         <td class=xl6328104 width=336 style='width:252pt'>№р/н</td>
         <td class=xl6328104 width=64 style='border-left:none;width:48pt'>Объект атауы</td>
         <td colspan=2 class=xl6328104 width=592 style='border-left:none;width:444pt'>Альфа-б&#1257;лшектер
         а&#1171;ыны, Бк/см2х мин</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td colspan=3 class=xl6328104 width=192 style='border-left:none;width:144pt'>Бета-б&#1257;лшектер
         а&#1171;ыны, Бк /см2хминПоток</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>Гамма-с&#1241;улеленуді&#1187;
         ЭМ&#1178; мкЗв /с</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1528104 style='height:26.4pt'></td>
         <td class=xl6328104 width=336 style='border-top:none;width:252pt'>№п/п</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Наименование</td>
         <td colspan=2 class=xl6328104 width=592 style='border-left:none;width:444pt'>Поток
         Альфа-частиц, Бк /см2х мин</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>НД
         на метод испытаний</td>
         <td colspan=3 class=xl6328104 width=192 style='border-left:none;width:144pt'>Бета-частиц,
         Бк /см2х мин</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>МЭД
         гамма-излучения, мкЗв/ час</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
         <td height=53 class=xl1528104 style='height:39.6pt'></td>
         <td class=xl6428104 width=336 style='border-top:none;width:252pt'>&nbsp;</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>образца</td>
         <td class=xl6328104 width=528 style='border-top:none;border-left:none;
         width:396pt'>Зерттеун&#1241;тежиелері</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Р&#1201;&#1179;сат етілен де&#1187;гейі</td>
         <td rowspan=2 class=xl6528104 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>Зерттеун&#1241;тежиелері</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Р&#1201;&#1179;сат етілен де&#1187;гейі</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>Зерттеу
         н&#1241;тежиелері</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Р&#1201;&#1179;сат етілен де&#1187;гейі</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
         <td height=53 class=xl1528104 style='height:39.6pt'></td>
         <td class=xl6428104 width=336 style='border-top:none;width:252pt'>&nbsp;</td>
         <td class=xl6428104 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6328104 width=528 style='border-top:none;border-left:none;
         width:396pt'>Результаты измерений</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимые уровни</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>Результаты
         измерений</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимые уровни</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>Результаты
         измерений</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимые уровни</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1528104 style='height:14.4pt'></td>
         <td class=xl6328104 width=336 style='border-top:none;width:252pt'>1</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6328104 width=528 style='border-top:none;border-left:none;
         width:396pt'>3</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>6</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
         <td colspan=2 class=xl6328104 width=128 style='border-left:none;width:96pt'>8</td>
         <td class=xl6328104 width=64 style='border-top:none;border-left:none;
         width:48pt'>9</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="9" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="48"><col width="115"><col width="128"><col width="108"><col width="140"><col width="119"><col width="105"><col width="102"><col width="106"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="48" height="188" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№ р/н<br>№п/п</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Объект атауы<br>Наименование образца</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="236" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Альфа-бөлшектер ағыны, Бк/см2х мин Поток Альфа-частиц, Бк /см2х мин</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="140" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="224" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бета-бөлшектер ағыны, Бк /см2хмин Поток Бета-частиц, Бк /см2х мин</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="208" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Гамма-сәулеленудің ЭМҚ мкЗв /с<br>МЭД гамма-излучения, мкЗв/ час</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу <br>нәтежиелері<br>Результаты измерений</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілен деңгейі<br>Допустимые уровни</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтежиелері Результаты измерений</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілен деңгейі<br>Допустимые уровни</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтежиелері Результаты измерений</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілен деңгейі<br>Допустимые уровни</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
          </tr>
          <tr>
            <td colspan="9" height="58" align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi<br>(Исследование образца проводились на соответствие НД)_________________________</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '59': {
    version:2,
    name: 'ПРОТОКОЛ измерений содержания радона и продуктов его распада в воздухе помещений',
    kazakhName: 'Үй-жайлар ауасында радонның және оның ыдырау өнімдерінің құрамын өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'measurement_place',
        type: 'text',
        label: 'Место проведения измерений',
        kazakhLabel: 'Өлшеу жүргізілген орын',
        number: '2'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проведены в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объекті өкілінің қатысуымен жүргізілді',
        number: '3'
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеулер мақсаты',
        number: '4'
      },
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеу құралдары',
        number: '5'
      },
      {
        id: 'name_type_factory_number',
        type: 'text',
        label: 'наименование, тип, заводской номер',
        kazakhLabel: 'атауы, түрі, зауыттың нөмірі',
        number: ''
      },
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственнойповерке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер',
        number: '6'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'берілген күні мен куәліктің нөмірі',
        number: ''
      },
      {
        id: "nd_conformance",
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Үлгілерді (нің) зерттеулер НҚ-ға сәйкестiгiне жүргiзiлдi',
        number: '7'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1036 style='border-collapse:
        collapse;table-layout:fixed;width:776pt'>
        <col width=64 style='width:48pt'>
        <col width=326 style='mso-width-source:userset;mso-width-alt:11576;width:244pt'>
        <col width=139 style='mso-width-source:userset;mso-width-alt:4949;width:104pt'>
        <col width=315 style='mso-width-source:userset;mso-width-alt:11207;width:236pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1525545 width=64 style='height:79.2pt;width:48pt'></td>
         <td class=xl7025545 width=326 style='width:244pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl7325545 width=139 style='border-left:none;width:104pt'>&#1256;лшеу
         ж&#1199;ргізілген орны</td>
         <td class=xl7325545 width=315 style='border-left:none;width:236pt'>Радонны&#1187;&nbsp;&#1257;лшенген,
         те&#1187;салма&#1179;ты, баламалы к&#1257;лемді белсенділігі, Бк/м3</td>
         <td class=xl7325545 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl7325545 width=64 style='border-left:none;width:48pt'>Бк/м3
         р&#1201;&#1179;сат етілен шекті концентрациясы</td>
         <td class=xl6325545 width=64 style='border-left:none;width:48pt'>Желдету
         жа&#1171;дайы туралы белгілер</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1525545 style='height:79.2pt'></td>
         <td class=xl7125545 width=326 style='border-top:none;width:244pt'>Регистрационный
         номер</td>
         <td class=xl6825545 width=139 style='border-top:none;border-left:none;
         width:104pt'>Место проведения измерений</td>
         <td class=xl6825545 width=315 style='border-top:none;border-left:none;
         width:236pt'>(Измеренная, равновесная, эквивалентная, объемная активность
         радона, Бк/м3)</td>
         <td class=xl6825545 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на метод испытаний)</td>
         <td class=xl6825545 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Допустимая концентрация Бк/м3)</td>
         <td class=xl6425545 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Отметки о состоянии вентиляции)</td>
        </tr>
        <tr height=141 style='height:105.6pt'>
         <td height=141 class=xl1525545 style='height:105.6pt'></td>
         <td class=xl7425545 width=326 style='border-top:none;width:244pt'>&nbsp;</td>
         <td class=xl6925545 width=139 style='border-top:none;border-left:none;
         width:104pt'>&nbsp;</td>
         <td class=xl6925545 width=315 style='border-top:none;border-left:none;
         width:236pt'>&nbsp;</td>
         <td class=xl6925545 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6825545 width=64 style='border-top:none;border-left:none;
         width:48pt'>А&#1171;ынны&#1187; р&#1201;&#1179;сат етілен шекті
         ты&#1171;ызды&#1171;ы (мБк/ш.м·с)</td>
         <td class=xl7225545 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1525545 style='height:79.2pt'></td>
         <td class=xl7425545 width=326 style='border-top:none;width:244pt'>&nbsp;</td>
         <td class=xl6925545 width=139 style='border-top:none;border-left:none;
         width:104pt'>&nbsp;</td>
         <td class=xl6925545 width=315 style='border-top:none;border-left:none;
         width:236pt'>&nbsp;</td>
         <td class=xl6925545 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6825545 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Допустимая плотность потока (мБк/м2·сек)</td>
         <td class=xl7225545 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1525545 style='height:15.0pt'></td>
         <td class=xl6525545 width=326 style='border-top:none;width:244pt'>1</td>
         <td class=xl6625545 width=139 style='border-top:none;border-left:none;
         width:104pt'>2</td>
         <td class=xl6625545 width=315 style='border-top:none;border-left:none;
         width:236pt'>3</td>
         <td class=xl6625545 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6625545 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6725545 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="138"><col width="145"><col width="327"><col width="120"><col width="172"><col width="115"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="138" height="191" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>Регистрационный <br>номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="145" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу жүргізілген орны <br>Место проведения измерений</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="327" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радонның&nbsp;өлшенген, теңсалмақты, баламалы көлемді белсенділігі, Бк/м3 <br>(Измеренная, равновесная, эквивалентная, объемная активность радона, Бк/м3)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="120" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры <br>(НД на метод испытаний)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="172" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бк/м3 рұқсат етілен шекті концентрациясы <br>(Допустимая концентрация Бк/м3) Ағынның рұқсат етілен шекті тығыздығы (мБк/ш.м·с) <br>(Допустимая плотность потока (мБк/м2·сек)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Желдету жағдайы туралы белгілер <br>(Отметки о состоянии вентиляции)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '60': {
    version:2,
    name: 'ПРОТОКОЛ измерения индивидуальных доз',
    kazakhName: 'Жеке мөлшерлерді өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'persons_categories',
        type: 'text',
        label: 'Категория лиц А,Б,В',
        kazakhLabel: 'Адамдар категориясы',
        number: '2'
      },
      {
        id: 'measurement_method',
        type: 'text',
        label: 'Метод измерения',
        kazakhLabel: 'Өлшеулер мақсаты',
        number: '3'
      },
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеу құралдары',
        number: '4'
      },
      {
        id: 'name',
        type: 'text',
        label: 'наименование',
        kazakhLabel: 'атауы',
        number: ''
      },
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер',
        number: '5'
      },
      {
        id: "nd_conformance",
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгіні зерттеу НҚ-ға сәйкестiкке жүргiзiлдi',
        number: '6'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=924 style='border-collapse:
        collapse;table-layout:fixed;width:694pt'>
        <col width=64 style='width:48pt'>
        <col width=382 style='mso-width-source:userset;mso-width-alt:13596;width:287pt'>
        <col width=64 style='width:48pt'>
        <col width=222 style='mso-width-source:userset;mso-width-alt:7907;width:167pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1510512 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl6910512 width=382 style='width:287pt'>Тiркеу н&#1257;мірi</td>
         <td class=xl7110512 width=64 style='border-left:none;width:48pt'>Тегі, аты,
         &#1241;кесіні&#1187;аты</td>
         <td class=xl7110512 width=222 style='border-left:none;width:167pt'>Лауазымы</td>
         <td class=xl7110512 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl7110512 width=64 style='border-left:none;width:48pt'>Дозиметрді&#1187;
         н&#1257;мірi</td>
         <td class=xl6310512 width=64 style='border-left:none;width:48pt'>мЗв-мен
         &#1257;лшенген доза</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1510512 style='height:52.8pt'></td>
         <td class=xl7010512 width=382 style='border-top:none;width:287pt'>Регистрационный
         номер</td>
         <td class=xl6810512 width=64 style='border-top:none;border-left:none;
         width:48pt'>Фамилия, имя,отчество</td>
         <td class=xl6810512 width=222 style='border-top:none;border-left:none;
         width:167pt'>Должность</td>
         <td class=xl6810512 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6810512 width=64 style='border-top:none;border-left:none;
         width:48pt'>Номер дозиметра</td>
         <td class=xl6410512 width=64 style='border-top:none;border-left:none;
         width:48pt'>Измеренная доза в мЗв</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1510512 style='height:15.0pt'></td>
         <td class=xl6510512 width=382 style='border-top:none;width:287pt'>1</td>
         <td class=xl6610512 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6610512 width=222 style='border-top:none;border-left:none;
         width:167pt'>3</td>
         <td class=xl6610512 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6610512 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6710512 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
       </table>`,
       label: '7. Результаты измерений',
       kazakhLabel: '7. Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="147"><col width="115"><col width="90"><col width="126"><col width="115"><col width="104"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="147" height="96" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тiркеу нөмірi<br>Регистрационный номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тегі, аты, әкесінің аты <br>Фамилия, имя,отчество</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Лауазымы<br>Должность</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="126" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Дозиметрдің нөмірi Номер дозиметра</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="104" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">мЗв-мен өлшенген доза <br>Измеренная доза в мЗв</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: "nd_conformance_2",
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi',
        number: '8'
      }
    ]
  },
  '61': {
    name: 'ПРОТОКОЛ микробиологического исследования',
    kazakhName: 'Микробиологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'sample_selection_date',
        type: 'datetime',
        label: 'Дата взятия биологического образца',
        kazakhLabel: 'Биологиялық үлгi алынған күн',
        number: '1'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі',
        number: '2'
      },
      {
        id: 'analysis_issue_date',
        type: 'datetime',
        label: 'Дата выдачи анализа',
        kazakhLabel: 'Талдаудың берілген күні',
        number: '3'
      },
      {
        id: 'full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество обследуемого',
        kazakhLabel: 'Тексерілушінің тегі, аты, жөні',
        number: '4'
      },
      {
        id: 'age',
        type: 'number',
        label: 'возраст',
        kazakhLabel: 'жасы',
        number: ''
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы',
        number: '5'
      },
      {
        id: 'med_organization',
        type: 'text',
        label: 'Медицинская организация',
        kazakhLabel: 'Медициналық ұйымы',
        number: '6'
      },
      {
        id: 'filial',
        type: 'text',
        label: 'отделение',
        kazakhLabel: 'бөлімше',
        number: ''
      },
      {
        id: 'when_researching',
        type: 'text',
        label: 'При исследовании',
        kazakhLabel: 'Зерттегенде',
        number: '7'
      },
      {
        id: 'mark_sample',
        type: 'text',
        label: 'указать образец',
        kazakhLabel: 'үлгiнi көрсетіңіз',
        number: ''
      },
      {
        id: 'result',
        type: 'text',
        label: 'Результат',
        kazakhLabel: 'Нәтижесi',
        number: '8'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры',
        number: '9'
      },
      {
        id: 'research_on_nd_done',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi',
        number: '10'
      }
    ]
  }
}
