import { resultFormCRUD } from "../../http";
import { $authHost } from "../../http";
import { 
    RESULT_FORM_GET_REQUEST, RESULT_FORM_GET_SUCCESS, RESULT_FORM_GET_ERROR, 
    RESULT_FORM_GET_BY_KEY_REQUEST, RESULT_FORM_GET_BY_KEY_SUCCESS, RESULT_FORM_GET_BY_KEY_ERROR, 
    RESULT_FORM_ADD_REQUEST, RESULT_FORM_ADD_SUCCESS, RESULT_FORM_ADD_ERROR, 
    RESULT_FORM_EDIT_REQUEST, RESULT_FORM_EDIT_SUCCESS, RESULT_FORM_EDIT_ERROR, 
    RESULT_FORM_DELETE_REQUEST, RESULT_FORM_DELETE_SUCCESS, RESULT_FORM_DELETE_ERROR
} from "../constants/resultFormConstants";

export const getResultForms = (args, local = false) => async (dispatch) => {
    try {
        {!local && dispatch({
            type: RESULT_FORM_GET_REQUEST
        });}
        const data = await resultFormCRUD.search(args);
        {!local && dispatch({
            type: RESULT_FORM_GET_SUCCESS,
            payload: data.result,
        });}
        return data.result;
    } catch (error) {
        dispatch({
            type: RESULT_FORM_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getResultFormByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RESULT_FORM_GET_BY_KEY_REQUEST
        });

        const data = await resultFormCRUD.get(id);
        dispatch({
            type: RESULT_FORM_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: RESULT_FORM_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addResultForm = (data, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: RESULT_FORM_ADD_REQUEST
        });
        const response = await resultFormCRUD.create(data);
        dispatch(getResultForms(queryArgs || {}));
        dispatch({
            type: RESULT_FORM_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: RESULT_FORM_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editResultForm = (data, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: RESULT_FORM_EDIT_REQUEST
        });
        await resultFormCRUD.edit(data);
        dispatch(getResultForms(queryArgs || {}));
        dispatch({
            type: RESULT_FORM_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: RESULT_FORM_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteResultForm = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: RESULT_FORM_DELETE_REQUEST
        });
        await resultFormCRUD.delete(id);
        dispatch(getResultForms(queryArgs || {}));
        dispatch({
            type: RESULT_FORM_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: RESULT_FORM_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

