import { $authHost } from "../../http";
import {
  REFERRAL_GET_REQUEST,
  REFERRAL_GET_SUCCESS,
  REFERRAL_GET_ERROR,
  REFERRAL_GET_BY_KEY_REQUEST,
  REFERRAL_GET_BY_KEY_SUCCESS,
  REFERRAL_GET_BY_KEY_ERROR,
  REFERRAL_ADD_REQUEST,
  REFERRAL_ADD_SUCCESS,
  REFERRAL_ADD_ERROR,
  REFERRAL_EDIT_REQUEST,
  REFERRAL_EDIT_SUCCESS,
  REFERRAL_EDIT_ERROR,
  REFERRAL_DELETE_REQUEST,
  REFERRAL_DELETE_SUCCESS,
  REFERRAL_DELETE_ERROR,
  REFERRAL_WORKFLOW_RUN_REQUEST,
  REFERRAL_WORKFLOW_RUN_SUCCESS,
  REFERRAL_WORKFLOW_RUN_ERROR,
} from "../constants/referralConstants";

export const getReferrals = (args) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_GET_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await $authHost.post(
      "/api/v1/referral/query",
      args || {},
      config
    );
    dispatch({
      type: REFERRAL_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: REFERRAL_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getReferralByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_GET_BY_KEY_REQUEST,
    });

    const { data } = await $authHost.get(`/api/v1/referral/${id}`);
    dispatch({
      type: REFERRAL_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: REFERRAL_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addReferral =
  (
    specifications,
    testTubes,
    number,
    createdAt,
    diagnosis,
    probeCode,
    probeStatus,
    antibioticUse,
    clinicalSymptomsCVI,
    paymentPassed,
    departmentId,
    targetPatient,
    targetPatientId,
    expressTestResult,
    BenefitsToCDI,
    patientStatus
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REFERRAL_ADD_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await $authHost.post(
        "/api/v1/referral",
        {
          specifications,
          probeCode,
          probeStatus,
          testTubes,
          number,
          createdAt,
          diagnosis,
          antibioticUse,
          clinicalSymptomsCVI,
          paymentPassed,
          departmentId,
          targetPatient,
          targetPatientId,
          expressTestResult,
          BenefitsToCDI,
          patientStatus,
        },
        config
      );
      dispatch(getReferrals());
      dispatch({
        type: REFERRAL_ADD_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REFERRAL_ADD_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : (error.response.data.errors &&
                error.response.data.errors.length > 0 &&
                error.response.data.errors.join(", ")) ||
              error.message,
      });
    }
  };

export const editReferral =
  (
    {
      id,
      number,
      createdAt,
      schema,
      data,
      diagnosis,
      antibioticUse,
      probeCode,
      probeStatus,
      patientStatus,
      clinicalSymptomsCVI,
      expressTestResult,
      paymentPassed,
      benefitsToCDI,
      specificationsSummary,
      isCovid19,
      formId,
      stateId,
      serviceId,
      contractId,
      objectId,
      responsibleId,
      targetPatientId,
      filialId,
      departmentId,
    },
    queryArgs
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REFERRAL_EDIT_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await $authHost.put(
        "/api/v1/referral",
        {
          id,
          number,
          createdAt,
          schema,
          data,
          diagnosis,
          antibioticUse,
          probeCode,
          probeStatus,
          patientStatus,
          clinicalSymptomsCVI,
          expressTestResult,
          paymentPassed,
          benefitsToCDI,
          specificationsSummary,
          isCovid19,
          formId,
          stateId,
          serviceId,
          contractId,
          objectId,
          responsibleId,
          targetPatientId,
          filialId,
          departmentId,
        },
        config
      );
      dispatch(getReferrals(queryArgs || {}));
      dispatch({
        type: REFERRAL_EDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REFERRAL_EDIT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteReferral = (id, queryArgs) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_DELETE_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await $authHost.delete("/api/v1/referral", { data: { id } }, config);
    dispatch(getReferrals(queryArgs || {}));
    dispatch({
      type: REFERRAL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REFERRAL_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const runReferralWorkflow =
  ({ id, oldStateId, newStateId, queryArgs }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REFERRAL_WORKFLOW_RUN_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await $authHost.post(
        "/api/v1/referral/workflow/run",
        { data: { id, oldStateId, newStateId } },
        config
      );
      dispatch(getReferrals(queryArgs || {}));
      dispatch({
        type: REFERRAL_WORKFLOW_RUN_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REFERRAL_WORKFLOW_RUN_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
