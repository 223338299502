export const CURRENCY_GET_REQUEST = 'CURRENCY_GET_REQUEST';
export const CURRENCY_GET_SUCCESS = 'CURRENCY_GET_SUCCESS';
export const CURRENCY_GET_ERROR = 'CURRENCY_GET_ERROR';
export const CURRENCY_GET_BY_KEY_REQUEST = 'CURRENCY_GET_BY_KEY_REQUEST';
export const CURRENCY_GET_BY_KEY_SUCCESS = 'CURRENCY_GET_BY_KEY_SUCCESS';
export const CURRENCY_GET_BY_KEY_ERROR = 'CURRENCY_GET_BY_KEY_ERROR';
export const CURRENCY_ADD_REQUEST = 'CURRENCY_ADD_REQUEST';
export const CURRENCY_ADD_SUCCESS = 'CURRENCY_ADD_SUCCESS';
export const CURRENCY_ADD_ERROR = 'CURRENCY_ADD_ERROR';
export const CURRENCY_EDIT_REQUEST = 'CURRENCY_EDIT_REQUEST';
export const CURRENCY_EDIT_SUCCESS = 'CURRENCY_EDIT_SUCCESS';
export const CURRENCY_EDIT_ERROR = 'CURRENCY_EDIT_ERROR';
export const CURRENCY_DELETE_REQUEST = 'CURRENCY_DELETE_REQUEST';
export const CURRENCY_DELETE_SUCCESS = 'CURRENCY_DELETE_SUCCESS';
export const CURRENCY_DELETE_ERROR = 'CURRENCY_DELETE_ERROR';
