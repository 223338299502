import t_06_2257 from './KZ.M.06.2257.svg'
import t_06_2257_b from './KZ.M.06.2257 black.svg'

import t06_e1246 from './KZ.T.06.Е1246.svg'
import t06_e1246_b from './KZ.T.06.Е1246 black.svg'

export default [
  t_06_2257,
  t_06_2257_b,
  t06_e1246,
  t06_e1246_b
]
