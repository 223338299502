import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Grid,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  addDesinsectionRequest,
  getContractRequestByKey,
  getEmployeeByKey,
  getAuthorizedEmployee,
  getContractByKey,
} from "../../../../redux/actions";
import {
  BasicModalContainer,
  CustomDatePicker,
  Form,
  FormSection,
  BasicTable,
} from "../../../../components";
import {
  FilialDivisionSelect,
  FilialSelect,
  DepartmentSelect,
} from "../../../../components/Select/domainSelects";

import { prepareContractRequestChildRequestDataForSubmit } from "../../../../functions/viewDataMap";
import { trySetCount } from '../../../../models/data/ContractRequestChildrenModel';
import EmployeeField from "../../../../components/Relations/EmployeeField";
import DialogLayout from "../../../../components/Dialog/DialogLayout";
import {autoCompleteFilialAndFilialDivision} from "../../../../functions/dateHelpers";
import { NumberField2 } from "../../../../components/Fields/NumberField2";

export const DDDRequestCreateModal = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const authorizedEmployee = useSelector(state=>state.employee.authorizedEmployee)
  const { contract } = useSelector((s) => s.contract);
  const { contractRequest } = useSelector((s) => s.contractRequest);
  const [departmentId, setDepartmentId] = useState(10);

  const [localCreatedById, setLocalCreatedById] = useState(null);
  const [localCreatedBy, setLocalCreatedBy] = useState({});

  const [localObjects, setLocalObjects] = useState([]);
  const [localSpecifications, setLocalSpecifications] = useState([]);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState([]);

  const [filialId, setFilialId] = useState(null);
  //const [localFilial, setLocalFilial] = useState({});
  const [filialDivisionId, setFilialDivisionId] = useState(null);

  const [performerId, setPerformerId] = useState(null);
  const [performer, setPerformer] = useState({});

  const [dateOfDeparture, setDateOfDeparture] = useState(new Date());

  const [authorComment, setAuthorComment] = useState(null);
  const [performerComment, setPerformerComment] = useState(null);

  const handleDateOfDepartureChange = (d) => setDateOfDeparture(d);

  const handleAuthorComment = (e) => setAuthorComment(e.target.value);

  const handleSpecificationQuantityChange = (e, id) => {
    const isChanged = trySetCount(selectedSpecifications, localSpecifications, id, contractRequest,
      setSelectedSpecifications, setLocalSpecifications, e.target.value);

    if (!isChanged)
      return alert("Превышено максимальное значение исследований для этого элемента");
  };

  useEffect(() => {
    autoCompleteFilialAndFilialDivision(authorizedEmployee, setFilialId, setFilialDivisionId);
  }, [])

  const handleReset = () => {
    setSelectedObjects([]);
    setSelectedSpecifications([]);
    setFilialId(null);
    setFilialId(null);
    setFilialDivisionId(null);
    setPerformerId(null);
    setPerformer(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking Fields
    if (!selectedSpecifications?.length) return alert("Выберите услуги!");

    prepareContractRequestChildRequestDataForSubmit(selectedSpecifications, selectedObjects);

    setLoading(true);

    const data = {
      contractRequestId: contractRequest.id,
      contractRequestNumber: contractRequest.number,
      performerId: performerId,
      filialId: filialId || contractRequest.filialId,
      filialDivisionId: filialDivisionId || contractRequest.contractFilialDivisionId || contractRequest.filialDivisionId,
      objects: selectedObjects,
      specifications: selectedSpecifications,
      departmentId: departmentId,
      authorComment: authorComment,
      dateOfDeparture: dateOfDeparture,
    };

    // Creating
    const createdId = await dispatch(addDesinsectionRequest(data));
    if (!createdId) return setLoading(false);

    // Updating Contract Request
    const contractReq = await dispatch(
      getContractRequestByKey(contractRequest.id)
    );
    if (!contractReq) return setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setOpen(false);

      handleReset();
    }, 500);
  };

  useEffect(() => {
    if (!open)
      return;

    dispatch(getAuthorizedEmployee()).then(setLocalCreatedBy);
    dispatch(getContractByKey(contractRequest.contractId));
  }, [open]);

  useEffect(() => {
    if (!contractRequest)
      return;

    setLocalObjects(contractRequest?.objects);
    setLocalSpecifications(contractRequest?.specifications);
  }, [contractRequest]);

  useEffect(() => {
    if (!performerId) return;

    dispatch(getEmployeeByKey(performerId)).then(setPerformer);
  }, [dispatch, performerId]);

  return (
    <DialogLayout title="Заявка на ДДД: Создание" loading={loading} open={open} setOpen={setOpen}>
      <Form onSubmit={handleSubmit}>
        <BasicModalContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={contractRequest?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  value={localCreatedBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <EmployeeField
                    style={{ marginBottom: "5px" }}
                    label={"Исполнитель заявки"}
                    object={performer}
                    value={performerId}
                    onChange={(val) => {
                      setPerformer(val)
                      setPerformerId(val?.id)
                    }}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection heading="Договор" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Номер договора"
                  type="number"
                  value={contract?.id || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Статус по договору"
                  type="text"
                  value={contract?.state?.name || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата договора"
                  value={contract?.actionAt}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Объекты (${localObjects?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    {
                      id: "name",
                      label: t("object.tableItem.name"),
                    },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={localObjects}
                  disabled={true}
                  selected={selectedObjects}
                  setSelected={setSelectedObjects}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Услуги (${localSpecifications?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                      render: ({ id, count }) => (
                        <NumberField2
                          value={count}                          
                          onChange={(e) =>
                            handleSpecificationQuantityChange(e, id)
                          }
                        />
                      ),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={localSpecifications}
                  disabled={true}
                  selected={selectedSpecifications}
                  setSelected={setSelectedSpecifications}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FilialSelect
                  label={"Филиал"}
                  fullWidth
                  value={filialId}
                  onChange={(val) => {
                    setFilialId(val);
                    setFilialDivisionId(Number(val) !== Number(filialId) ?
                      null
                      : filialDivisionId);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <FilialDivisionSelect
                  label={"Районное отделение"}
                  fullWidth
                  value={filialDivisionId}
                  filialId={filialId}
                  onChange={(val, filialDivision) => {
                    setFilialDivisionId(val);
                    setFilialId(Number(filialDivision.filialId) !== Number(filialId)
                      ? filialDivision.filialId
                      : filialId);
                  }}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <SelectFieldModal
                  label="Зав. лаборатории"
                  cells={[
                    { id: "id", label: t("employee.tableItem.id") },
                    {
                      id: "fullName",
                      label: t("employee.tableItem.fullName"),
                    },
                    {
                      id: "position",
                      label: t("employee.tableItem.position"),
                      render: (row) => row?.position?.name,
                    },
                    {
                      id: "filial",
                      label: t("employee.tableItem.filial"),
                      render: (row) => row?.filial?.name,
                    },
                    {
                      id: "department",
                      label: t("employee.tableItem.department"),
                      render: (row) => row?.department?.name,
                    },
                  ]}
                  actionFn={getEmployees}
                  valueName="id"
                  value={localDepartmentHead?.fullName}
                  setValue={setLocalDepartmentHeadId}
                />
              </Grid> */}
            </Grid>

            <Grid container spacing={2}>
              {/* <Grid item xs={8}>
                <SelectFieldModal
                  label="Ответсвенный специалист ДДД"
                  cells={[
                    { id: "id", label: t("employee.tableItem.id") },
                    {
                      id: "fullName",
                      label: t("employee.tableItem.fullName"),
                    },
                    {
                      id: "position",
                      label: t("employee.tableItem.position"),
                      render: (row) => row?.position?.name,
                    },
                    {
                      id: "filial",
                      label: t("employee.tableItem.filial"),
                      render: (row) => row?.filial?.name,
                    },
                    {
                      id: "department",
                      label: t("employee.tableItem.department"),
                      render: (row) => row?.department?.name,
                    },
                  ]}
                  actionFn={getDepartments}
                  valueName="id"
                  value={localSpecialist?.fullName}
                  setValue={setLocalSpecialistId}
                />
              </Grid> */}
              <Grid item xs={4}>
                <DepartmentSelect
                    label={"Лаборатория"}
                    disabled
                    value={departmentId}
                    onChange={(val) =>
                        setDepartmentId(val)
                    }
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Комментарий автора"
                  value={authorComment}
                  onChange={handleAuthorComment}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDatePicker
                  label="Дата выезда"
                  value={dateOfDeparture}
                  onChange={handleDateOfDepartureChange}
                />
              </Grid>
            </Grid>
          </FormSection>
        </BasicModalContainer>

        <BasicModalContainer>
          <Grid container spacing={2}>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
              >
                Создать заявку
              </LoadingButton>
            </Grid>
          </Grid>
        </BasicModalContainer>
      </Form>
    </DialogLayout>
  );
};
