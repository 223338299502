import './App.scss';
import {BrowserRouter as Router} from "react-router-dom";
import React from 'react'
import AppRouter from "./components/AppRouter/AppRouter";
import "./lang/i18n";
import 'antd/dist/antd.css'
import './libs/timezone'
import {pdfjs} from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
    // const dispatch = useDispatch()
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     (async function () {
    //         setLoading(true)
    //         await dispatch(check())
    //         setLoading(false)
    //     }())
    // }, [dispatch])

    // if (loading) {
    //     return (
    //         <div style={{height: "100vh", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    //             <CircularProgress/>
    //         </div>
    //     );
    // }

    return (
        <Router>
            <div className="wrapper">
                <div className="content">
                    <AppRouter/>
                </div>
            </div>
        </Router>
    );
}

export default App;
