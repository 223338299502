import { 
    REAGENT_GET_REQUEST, REAGENT_GET_SUCCESS, REAGENT_GET_ERROR, 
    REAGENT_GET_BY_KEY_REQUEST, REAGENT_GET_BY_KEY_SUCCESS, REAGENT_GET_BY_KEY_ERROR, 
    REAGENT_ADD_REQUEST, REAGENT_ADD_SUCCESS, REAGENT_ADD_ERROR, 
    REAGENT_EDIT_REQUEST, REAGENT_EDIT_SUCCESS, REAGENT_EDIT_ERROR, 
    REAGENT_DELETE_REQUEST, REAGENT_DELETE_SUCCESS, REAGENT_DELETE_ERROR
} from "../constants/reagentConstants";

const initialState = {
    reagents: [],
    reagent: {},
    loading: false,
    success: null,
    error: null,
    reagentRequestedCount: 0,
};

export const reagentReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REAGENT_GET_REQUEST:
            return {
                ...state,
                reagentRequestedCount: ++state.reagentRequestedCount,
                loading: true,
            };
        case REAGENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                reagents: action.payload,
                success: true,
            };
        case REAGENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REAGENT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REAGENT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                reagent: action.payload,
                success: true,
            };
        case REAGENT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REAGENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REAGENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REAGENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REAGENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REAGENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REAGENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REAGENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REAGENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REAGENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
