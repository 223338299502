import React, {useEffect, useMemo, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  getDepartureScheduleRequestByKey,
  workflowDepartureScheduleRequest, workflowServiceWithoutActRequest,
} from "../../../redux/actions";
import {
  PageHeader,
} from "../../../components";

import DashboardLayout from "../../../layouts/Dashboard";

import {
  DepartureScheduleRequestInfoSection,
  DepartureScheduleRequestEditSection,
} from "./Sections";

import {
  renderControls,
  renderSections, RequestStateChecker,
} from "./../../../functions/renderHelpers";
import {CONTRACT_REQUEST, DEPARTURE_SCHEDULE, WITHOUT_DEPARTURE_REQUEST} from "../../../routes";
import {REQUEST_STATE_ID_CREATED} from "../../../redux/constants/requestStateConstants";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";

export const DepartureScheduleRequestInfo = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("read");

  const [requestId, setRequestId] = useState(id);
  const [request, setRequest] = useState({});
  const [stateId, setStateId] = useState(0);

  const { departureScheduleRequest } = useSelector(
    (s) => s.departureScheduleRequest
  );

  const state = useMemo(
      () => new RequestStateChecker(departureScheduleRequest?.state?.code),
      [departureScheduleRequest?.state?.code]
  );

  const stateCode = departureScheduleRequest?.state?.code?.toLowerCase();

  const isEditMode = mode === "edit";

  const handleSwitchMode = (e, mode) => {
    e.preventDefault();

    switch (mode) {
      case "read":
        return setMode("read");
      case "edit":
        return setMode("edit");
      default:
        return setMode("read");
    }
  };

  const handleWorkflow = async (e, nextStateId) => {
    e.preventDefault();
    setLoading(true);

    if (!departureScheduleRequest?.state?.id) return setLoading(false);

    const prevStateId = departureScheduleRequest.state.id;

    // Updating Workflow
    const updated = await dispatch(
      workflowDepartureScheduleRequest(id, prevStateId, nextStateId)
    );
    if (!updated) return;

    setStateId(nextStateId);
    setLoading(false);
  };

  const handleWorkflowDeclinedToCreate = async () => {
    setLoading(true);

    if (!departureScheduleRequest?.state?.id) return setLoading(false);

    const prevStateId = departureScheduleRequest.state.id;
    const nextStateId = state.getId(REQUEST_STATE_ID_CREATED);

    // Updating Workflow
    const updated = await dispatch(
        workflowDepartureScheduleRequest(requestId, prevStateId, nextStateId)
    );
    if (!updated) return setLoading(false);

    setStateId(nextStateId);
    setLoading(false);
  };


  const sections = [
    {
      mode: "read",
      component: DepartureScheduleRequestInfoSection,
    },
    {
      mode: "edit",
      component: DepartureScheduleRequestEditSection,
    },
  ];

  const controls = [
    {
      state: "created",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 2)}
        >
          Начать обработку
        </LoadingButton>,
        <>
          {isEditMode ? (
            <>
              <Button
                color="secondary"
                onClick={(e) => handleSwitchMode(e, "read")}
              >
                Просмотр
              </Button>
            </>
          ) : (
            <Button
              color="secondary"
              onClick={(e) => handleSwitchMode(e, "edit")}
            >
              Редактировать
            </Button>
          )}
        </>,
        <Button
            variant={"text"}
            onClick={() => history.push(DEPARTURE_SCHEDULE)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "processing",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 4)}
        >
          Завершить
        </LoadingButton>,
        <LoadingButton color="warning" onClick={(e) => handleWorkflow(e, 5)}>
          Отклонить с комментариями
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(DEPARTURE_SCHEDULE)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "completed",
      roles: [],
      buttons: [
        <Button
            variant={"text"}
            onClick={() => history.push(DEPARTURE_SCHEDULE)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "declined",
      roles: [],
      buttons: [
        <LoadingButton
            loading={loading}
            onClick={() => {
              confirmDialog('Пересоздание', 'Вы действительно хотите пересоздать заявку?', async () => {
                await handleWorkflowDeclinedToCreate()
              })
            }
            }
        >
          Пересоздать
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    }

  ];

  useEffect(() => {
    dispatch(getDepartureScheduleRequestByKey(requestId)).then(setRequest);
  }, [dispatch, requestId, stateId]);

  return (
    <DashboardLayout>
      <PageHeader
        heading={`График отбора №${id} ${
          stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
        }`}
        controls={renderControls(controls, {
          state: stateCode,
          roles: [],
        })}
      />
      {renderSections(sections, { mode })}
    </DashboardLayout>
  );
};
