import { $authHost } from "../../http";
import { 
    REFERRAL_STATE_GET_REQUEST, REFERRAL_STATE_GET_SUCCESS, REFERRAL_STATE_GET_ERROR, 
    REFERRAL_STATE_GET_BY_KEY_REQUEST, REFERRAL_STATE_GET_BY_KEY_SUCCESS, REFERRAL_STATE_GET_BY_KEY_ERROR, 
    REFERRAL_STATE_ADD_REQUEST, REFERRAL_STATE_ADD_SUCCESS, REFERRAL_STATE_ADD_ERROR, 
    REFERRAL_STATE_EDIT_REQUEST, REFERRAL_STATE_EDIT_SUCCESS, REFERRAL_STATE_EDIT_ERROR, 
    REFERRAL_STATE_DELETE_REQUEST, REFERRAL_STATE_DELETE_SUCCESS, REFERRAL_STATE_DELETE_ERROR
} from "../constants/referralStateConstants";

export const getReferralStates = (args) => async (dispatch) => {
    try {
        dispatch({
            type: REFERRAL_STATE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/referralState/query",
            args || {},
            config,
        );
        dispatch({
            type: REFERRAL_STATE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: REFERRAL_STATE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getReferralStateByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: REFERRAL_STATE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/referralState/${id}`,
        );
        dispatch({
            type: REFERRAL_STATE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: REFERRAL_STATE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addReferralState = ({ name, code }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: REFERRAL_STATE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/referralState",
            { name, code },
            config,
        );
        dispatch(getReferralStates(queryArgs || {}));
        dispatch({
            type: REFERRAL_STATE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REFERRAL_STATE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editReferralState = ({ id, name, code }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: REFERRAL_STATE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/referralState",
            { id, name, code },
            config,
        );
        dispatch(getReferralStates(queryArgs || {}));
        dispatch({
            type: REFERRAL_STATE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REFERRAL_STATE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteReferralState = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: REFERRAL_STATE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/referralState",
            { data: { id } },
            config,
        );
        dispatch(getReferralStates(queryArgs || {}));
        dispatch({
            type: REFERRAL_STATE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REFERRAL_STATE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

