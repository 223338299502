import React, {useState, useCallback} from "react";

import {
    LoadingButton,
    LocalizationProvider,
} from "@mui/lab";

import AdapterDateFns from "@mui/lab/AdapterDateFns";

import {
    Button,
    Grid,
    Typography,
} from "@mui/material";

import RequestTarificationCRUDTable from "../../components/CRUD/Tables/RequestTarificationCRUDTable";
import DashboardLayout from "../../layouts/Dashboard";
import {
    Form,
    FormSection,
    PageContainer,
    PageHeader,
    PageFooter,
} from "../../components";
import {contractCRUD, filialCRUD, filialDivisionCRUD, requestTarificationCRUD} from "../../http";
import TarifficationPDFGenerator from "./TarifficationPDFGenerator";
import useAlert from "../../hooks/useAlert";
import {useSelector} from "react-redux";

import XLSX from "xlsx-js-style";
import {calculateTarficiationGroups} from "../../models/data/EntityCollectionModel";
import {formatDate, formatFloat, intToWords, MOMENT_FORMAT_DATE_DMY_DOTS} from "../../functions/formatters";
import useRoles from "../../hooks/useRoles";

const Tariffication = () => {
    const [loading, setLoading] = useState(false)
    const [totalDate, setTotalDate] = useState(null)
    const [summary, setSummary] = useState(null)
    const [open, setOpen] = useState(false)
    const alert = useAlert(false, 1)
    const {filials} = useSelector((state) => state.filial);
    const {filialDivisions} = useSelector((state) => state.filialDivision);
    const {contracts} = useSelector((state) => state.contract);
    const [dataFromFilterCrud, setDataFromFilterCrud] = useState({});
    const { isInRole, isAdministrator, defaultRoles } = useRoles();

    const handleChangeFilterCrud = useCallback(
        (key, value) => setDataFromFilterCrud({...dataFromFilterCrud, [key]: value}),
        [dataFromFilterCrud]
    );
    ///

    const handleDidRemoveFilter = () => {
        setDataFromFilterCrud({...dataFromFilterCrud, filialDivision: null, filialDivisionId: null});
    };

    const validateSubmit = () => {
        // admin or ksek
        const privilegedUser = isAdministrator() || isInRole(defaultRoles.Committee);

        let allowed = true;

        // researchEndDateTime1 and researchEndDateTime2 are required
        if (!dataFromFilterCrud.hasOwnProperty('researchEndDateTime1') || !dataFromFilterCrud.hasOwnProperty('researchEndDateTime2')) {
            allowed = false;
        } else {
            if (!privilegedUser) {
                // filial & contract are required for not privileged user
                if (!dataFromFilterCrud.hasOwnProperty('contract')) {
                    allowed = false;
                }
            }
        }

        if (!allowed) {
            if (!privilegedUser) {
                alert.show(0, "Необходимо указать в фильтрах: 1) Отчётный период 2) Договор")
            } else {
                alert.show(0, "Необходимо указать в фильтрах: 1) Отчётный период")
            }
        }

        return allowed;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateSubmit()) {
            return;
        }        

        setOpen(true);
    };

    function roundTo2(figureToRound){
        var roundOff = Math.round((figureToRound* 100 ).toFixed(2))/100;
        return roundOff;
    }

    const renderGroupGroups = (groupGroups, aoa, leftBold10, centerBold10_2, merges) => {
        return groupGroups && groupGroups.map(groupGroup => {
            aoa.push([
                {t: "s", v: groupGroup.first?.group?.name, s: leftBold10}, "", "", "", "", "", "", ""
            ]);
            addMerge(merges, `A${aoa.length}`, `H${aoa.length}`);
            groupGroup.researchCodeGroups && groupGroup.researchCodeGroups.map(researchCodeGroup => {
                aoa.push([
                    {t: "s", v: researchCodeGroup.totalSummary.idx, s: centerBold10_2},
                    {t: "s", v: researchCodeGroup.totalSummary.researchCode, s: centerBold10_2},
                    {
                        t: "s",
                        v: researchCodeGroup.first?.subGroup?.name + "\n" + researchCodeGroup.first?.method?.name + "\n" + '(одно исследование)',
                        s: centerBold10_2
                    },
                    {t: "s", v: formatFloat(researchCodeGroup.totalSummary.weightCoefficient), s: centerBold10_2},
                    {t: "s", v: formatFloat(researchCodeGroup.totalSummary.quantity), s: centerBold10_2},
                    {t: "s", v: formatFloat(roundTo2(researchCodeGroup.totalSummary.baseValue)), s: centerBold10_2},
                    {t: "s", v: formatFloat(roundTo2(researchCodeGroup.totalSummary.summary)), s: centerBold10_2},
                    {
                        t: "s",
                        v: formatFloat(researchCodeGroup.totalSummary.inconsistenceQuantity),
                        s: centerBold10_2
                    },
                ])
            })
        })
    }

    const renderGroupTotal = (group, title, aoa, centerBold10_2) => {
        let quantity = group.totalSummary.quantity || '0';
        let baseValue = roundTo2(group.totalSummary.baseValue) || '0';
        let summary = roundTo2(roundTo2(group.totalSummaryOfNotDesinsection.summary) + roundTo2(group.totalSummaryOfDesinsection.summary)) || '0';
        let inconsistenceQuantity = group.totalSummary.inconsistenceQuantity || '0';
        aoa.push([
            {t: "s", v: '', s: centerBold10_2},
            {t: "s", v: `Итого`, s: centerBold10_2},
            {t: "s", v: `(${title})`, s: centerBold10_2},
            {t: "s", v: '', s: centerBold10_2},
            {t: "s", v: formatFloat(quantity), s: centerBold10_2},
            {t: "s", v: formatFloat(baseValue), s: centerBold10_2},
            {t: "s", v: formatFloat(summary), s: centerBold10_2},
            {t: "s", v: formatFloat(inconsistenceQuantity), s: centerBold10_2},
        ]);
    }

    const handleSubmit2 = async (e) => {
        e.preventDefault();

        if (!validateSubmit()) {
            return;
        }

        setLoading(true)

        const filter = {
            researchEndDateTime: {
                operand1: dataFromFilterCrud?.researchEndDateTime1,
                operand2: dataFromFilterCrud?.researchEndDateTime2,
                operator: "between"
            },
        };
        if (dataFromFilterCrud?.contract) {
            filter.contractId = {operand1: dataFromFilterCrud?.contract, operator: "equals"};
        }
        if (dataFromFilterCrud?.filial) {
            filter.filialId = {operand1: dataFromFilterCrud?.filial, operator: "equals"};
        }
        if (dataFromFilterCrud?.filialDivision) {
            filter.filialDivisionId = {operand1: dataFromFilterCrud?.filialDivision, operator: "equals"}
        }
        if (dataFromFilterCrud?.department) {
            filter.departmentId = {operand1: dataFromFilterCrud?.department, operator: "equals"}
        }
        if (dataFromFilterCrud?.division) {
            filter.divisionId = {operand1: dataFromFilterCrud?.division, operator: "equals"}
        }
        if (dataFromFilterCrud?.serviceGroup) {
            filter.groupId = {operand1: dataFromFilterCrud?.serviceGroup, operator: "equals"}
        }
        if (dataFromFilterCrud?.serviceSubGroup) {
            filter.subGroupId = {operand1: dataFromFilterCrud?.serviceSubGroup, operator: "equals"}
        }
        if (dataFromFilterCrud?.serviceKind) {
            filter.methodId = {operand1: dataFromFilterCrud?.serviceKind, operator: "equals"}
        }
        if (dataFromFilterCrud?.researchCode1) {
            filter.researchCode = {operand1: dataFromFilterCrud?.researchCode1, operator: "equals"}
        }

        const contract = filter.contractId?.operand1 ? await contractCRUD.get(filter?.contractId?.operand1) : null;
        const filial = filter.filialId?.operand1 ? await filialCRUD.get(filter.filialId?.operand1) : null;
        const filialDivision = dataFromFilterCrud?.filialDivision ? 
            (await filialDivisionCRUD.get(filter.filialDivisionId?.operand1)) : null;

        const search = await requestTarificationCRUD.post('summary/query', {
            paging: {skip: 0},
            filter: filter
        });
        const data = await calculateTarficiationGroups(search, dataFromFilterCrud?.researchEndDateTime1);
        const first = data.length > 0 ? data[0].first : null;
        let filialDivisionName = first?.filialDivision?.name;
        if (!filialDivisionName) {
            filialDivisionName = "";
        }                
        //console.log(data)

        // if (!file)
        //     return;
        const thinBorder = {style: 'thin', color: 'auto'};
        const thinBorders = {top: thinBorder, bottom: thinBorder, left: thinBorder, right: thinBorder};
        const thickBorder = {style: 'medium', color: 'auto'};
        const thickBorders = {top: thickBorder, bottom: thickBorder, left: thickBorder, right: thickBorder};
        const signatureBorders = {bottom: thickBorder};
        const right8 = {alignment: {horizontal: 'right', wrapText: true}, font: {sz: 8}};
        const center10 = {alignment: {horizontal: 'center'}, font: {sz: 10}};
        const centerTotal = {alignment: {horizontal: 'center', wrapText: true}, font: {sz: 10}};
        const centerBold = {alignment: {horizontal: 'center'}, font: {bold: true}};
        const centerBold10 = {alignment: {horizontal: 'center'}, font: {sz: 10, bold: true}};
        const centerBold10_2 = {
            alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
            border: thinBorders,
            font: {sz: 9, bold: true}
        };
        const signature = {
            alignment: {horizontal: 'center', vertical: 'center', wrapText: true},
            border: signatureBorders,
            font: {sz: 9, bold: true}
        };
        const leftBold10 = {
            alignment: {horizontal: 'left', vertical: 'center'},
            font: {sz: 9, bold: true}
        };
        const left10_2 = {
            alignment: {horizontal: 'left', vertical: 'center'},
            border: thickBorders,
            font: {sz: 9}
        };
        const left10 = {alignment: {horizontal: 'left'}, font: {sz: 10}};

        const wb = XLSX.utils.book_new();

        const e1 = XLSX.utils.decode_cell('E1');
        const h1 = XLSX.utils.decode_cell('H1');
        const merges = [];
        merges.push({s: e1, e: h1});
        for (let i = 2; i <= 11; i++) {
            addMerge(merges, 'A' + i, 'H' + i);
        }

        const name = `отчетный период c ${formatDate(dataFromFilterCrud?.researchEndDateTime1, MOMENT_FORMAT_DATE_DMY_DOTS)} по ${formatDate(dataFromFilterCrud?.researchEndDateTime2, MOMENT_FORMAT_DATE_DMY_DOTS)}`

        const aoa = [
            ["", "", "", "", {
                t: "s", v: "Приложение" + "\n" +
                    "к приказу Председателя Комитета охраны общественного" + "\n" +
                    "здоровья Министерства здравоохранения" + "\n" +
                    "Республики Казахстан от 17 января 2019 года №9-НК",
                s: right8
            }, "", "", ""],
            [{t: "s", v: "Счет реестр", s: centerBold}, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: "за оказанные услуги по проведению лабораторных исследований, включая инструментальные замеры при" + "\n" + "проведении санитарно-",
                s: center10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: "эпидемиологической экспертизы,очаговой дезинфекции,дезинсекции,дератизации в очагах инфекционных и" + "\n" + "паразитарных заболеваний человека",
                s: centerBold10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s", v: name,
                s: centerBold10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: `Наименование договора КСЭК: ${(contract?.name) || ''} `,
                s: left10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: `Наименование организации: ${filial?.name || ""} `,
                s: left10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: `Районное отделение: ${filialDivision?.name || ""} `,
                s: left10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: 'Код и наименование бюджетной программы : 070 "Охрана общественного здоровья"',
                s: left10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s",
                v: 'Код и наименование бюджетной подпрограммы : 100 "Обеспечение санитарно эпидемиологического благополучия населения"',
                s: left10
            }, "", "", "", "", "", "", ""],
            [{
                t: "s", v: "Источник финансирования: Республиканский бюджет",
                s: center10
            }, "", "", "", "", "", "", ""],
            [
                {t: "s", v: "№ п/п", s: centerBold10_2},
                {t: "s", v: "Код услуги", s: centerBold10_2},
                {t: "s", v: "Наименование", s: centerBold10_2},
                {
                    t: "s", v: "Весовой" + "\n" +
                        "коэффициент", s: centerBold10_2
                },
                {
                    t: "s", v: "Количество" + "\n" +
                        "услуг", s: centerBold10_2
                },
                {t: "s", v: "Количество" + "\n" + "базовых" + "\n" + "ставок", s: centerBold10_2},
                {t: "s", v: "Стоимость" + "\n" + "в тенге", s: centerBold10_2},
                {
                    t: "s",
                    v: "Количество исследований" + "\n" + "по которым получены" + "\n" + "результаты не соответствующие" + "\n" + "требованиям НТД",
                    s: centerBold10_2
                },
            ],
        ];
        // TODO: generate by data
        data.length && data.map((_) => {
            aoa.push([
                {t: "s", v: _.first?.department?.name, s: leftBold10}, "", "", "", "", "", "", ""
            ]);
            addMerge(merges, `A${aoa.length}`, `H${aoa.length}`);
            _.divisionGroups && _.divisionGroups.map(divisionGroup => {
                    aoa.push([
                        {t: "s", v: divisionGroup.first?.division?.name, s: leftBold10}, "", "", "", "", "", "", ""
                    ])
                    addMerge(merges, `A${aoa.length}`, `H${aoa.length}`);
                    {
                        renderGroupGroups(divisionGroup.groupGroups, aoa, leftBold10, centerBold10_2, merges)
                    }
                    {
                        renderGroupTotal(divisionGroup, divisionGroup.first?.division?.name, aoa, centerTotal)
                    }
                }
            )
            {
                renderGroupGroups(_.groupGroups, aoa, leftBold10, centerBold10_2, merges)
            }
            {
                renderGroupTotal(_, _.first?.department?.name, aoa, centerTotal)
            }

        })
        {
            data && data.totalSummary && renderGroupTotal(data, 'Все лаборатории', aoa, center10)
        }
        let quantity = data.totalSummaryOfNotDesinsection.quantity || '0';
        let baseValue = roundTo2(data.totalSummaryOfNotDesinsection.baseValue) || '0';
        let summary = roundTo2(data.totalSummaryOfNotDesinsection.summary) || '0';
        let inconsistenceQuantity = data.totalSummaryOfNotDesinsection.inconsistenceQuantity || '0';
        aoa.push([
            {t: "s", v: '', s: centerTotal},
            {t: "s", v: `Итого`, s: centerTotal},
            {
                t: "s",
                v: `(в том числе: по лабораторным исследованиям включая инструментальные замеры)`,
                s: centerTotal
            },
            {t: "s", v: '', s: centerTotal},
            {t: "s", v: formatFloat(quantity), s: centerTotal},
            {t: "s", v: formatFloat(baseValue), s: centerTotal},
            {t: "s", v: formatFloat(summary), s: centerTotal},
            {t: "s", v: formatFloat(inconsistenceQuantity), s: centerTotal},
        ]);
        quantity = data.totalSummaryOfDesinsection.quantity || '0';
        baseValue = roundTo2(data.totalSummaryOfDesinsection.baseValue) || '0';
        summary = roundTo2(data.totalSummaryOfDesinsection.summary) || '0';
        inconsistenceQuantity = data.totalSummaryOfDesinsection.inconsistenceQuantity || '0';
        aoa.push([
            {t: "s", v: '', s: centerTotal},
            {t: "s", v: `Итого`, s: centerTotal},
            {t: "s", v: `(по очаговой дезинфекции, дезинсекции и дератизации)`, s: centerTotal},
            {t: "s", v: '', s: centerTotal},
            {t: "s", v: formatFloat(quantity), s: centerTotal},
            {t: "s", v: formatFloat(baseValue), s: centerTotal},
            {t: "s", v: formatFloat(summary), s: centerTotal},
            {t: "s", v: formatFloat(inconsistenceQuantity), s: centerTotal},
        ]);
        const sum = data.totalSummary.summary.toFixed(0)            
        const res = intToWords(sum, 'тг')
        aoa.push([
            {t: "s", v: `Сумма прописью: ${res} тенге`, s: leftBold10}, "", "", "", "", "", "", ""
        ]);
        addMerge(merges, `A${aoa.length}`, `H${aoa.length}`);
        aoa.push([
            {t: "s", v: '', s: centerTotal},
            {t: "s", v: `Исполнитель`, s: centerTotal},
        ])
        aoa.push([
            {t: "s", v: '', s: centerTotal},
            {t: "s", v: `Телефон`, s: centerTotal},
        ])
        const array = ['Email', 'Директор', 'Главный бухгалтер']
        array.map(el => {
            aoa.push([
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: el, s: centerTotal},
                {t: "s", v: ``, s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
                {t: "s", v: '', s: signature},
            ])
            addMerge(merges, `E${aoa.length}`, `L${aoa.length}`);
            aoa.push([
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: ``, s: centerTotal},
                {t: "s", v: ``, s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: 'Подпись', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: 'ФИО', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
            ])
            aoa.push([
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: ``, s: centerTotal},
                {t: "s", v: ``, s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
                {t: "s", v: '', s: centerTotal},
            ])
        })

        const sheet = XLSX.utils.aoa_to_sheet(aoa);
        sheet['!cols'] = [{width: 7.85546875}, {width: 7.85546875}, {width: 35.140625}, {width: 7.85546875}];
        sheet['!rows'] = [{hpx: 60, hpt: 10}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {hpx: 60, hpt: 10}];
        sheet['!merges'] = merges;
        XLSX.utils.book_append_sheet(wb, sheet, 'Отчет');
        const output = await XLSX.write(wb, {type: 'array'});
        let blob = await new Blob([output], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        let objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a')
        link.href = objectUrl
        link.download = `Тарификатор, ${name}.xlsx`
        document.body.appendChild(link)
        link.click()
        link.remove()
        setLoading(false)
    }

    const addMerge = (merges, cell1, cell2) => {
        const s = XLSX.utils.decode_cell(cell1);
        const e = XLSX.utils.decode_cell(cell2);
        merges.push({s: s, e: e});
    }

    const changeMonth = async (value, researchEndDateTime) => {
        await requestTarificationCRUD.edit({...value, researchEndDateTime: researchEndDateTime[value.id]})
        alert.show(1, "Отчётный месяц изменён")
    }

    React.useEffect(() => {
        const find = async () => {
            if (!totalDate) {
                const search = await requestTarificationCRUD.search({
                    paging: {take: 10, skip: 0, returnCount: true},
                    includeSummary: true
                })
                setTotalDate(search.total)
                setSummary(search.summary)
            }
        }
        find()
    }, [])

    const handleResult = async (data) => {
        setSummary(data?.summary);
    };

    return (
        <DashboardLayout>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <PageHeader heading="Тарификатор"/>
                <Form onSubmit={handleSubmit}>
                    <PageContainer>
                        <FormSection>
                            <Grid
                                container
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                            </Grid>
                        </FormSection>

                        <FormSection>
                            {totalDate && <RequestTarificationCRUDTable
                                handleChangeFilterCrud={handleChangeFilterCrud}
                                onDidRemoveFilter={handleDidRemoveFilter}
                                changeMonth={changeMonth}
                                extraQuery={{includeSummary: true}}
                                onResult={handleResult}
                                totalDate={totalDate}/>}
                        </FormSection>
                    </PageContainer>
                    <PageFooter style={{justifyContent: 'flex-start'}}>
                        <Button onClick={handleSubmit}>
                            Скачать отчет
                        </Button>
                        {<LoadingButton loading={loading} sx={{marginLeft: 3}} onClick={handleSubmit2}>
                            Скачать отчет (excel)
                        </LoadingButton>}
                    </PageFooter>
                </Form>
            </LocalizationProvider>

            <TarifficationPDFGenerator dataFromFilterCrud={dataFromFilterCrud}
                                       filials={filials}
                                       filialDivisions={filialDivisions}
                                       contracts={contracts}
                                       open={open}
                                       handleClose={() => setOpen(false)}/>
            {alert.render()}
        </DashboardLayout>
    );
};

export default Tariffication
