export const CONTRACT_GET_REQUEST = 'CONTRACT_GET_REQUEST';
export const CONTRACT_GET_SUCCESS = 'CONTRACT_GET_SUCCESS';
export const CONTRACT_GET_ERROR = 'CONTRACT_GET_ERROR';
export const CONTRACT_GETIN10YEAR_REQUEST = 'CONTRACT_GETIN10YEAR_REQUEST';
export const CONTRACT_GETIN10YEAR_SUCCESS = 'CONTRACT_GETIN10YEAR_SUCCESS';
export const CONTRACT_GETIN10YEAR_ERROR = 'CONTRACT_GETIN10YEAR_ERROR';
export const CONTRACT_GET_BY_KEY_REQUEST = 'CONTRACT_GET_BY_KEY_REQUEST';
export const CONTRACT_GET_BY_KEY_SUCCESS = 'CONTRACT_GET_BY_KEY_SUCCESS';
export const CONTRACT_GET_BY_KEY_ERROR = 'CONTRACT_GET_BY_KEY_ERROR';
export const CONTRACT_ADD_REQUEST = 'CONTRACT_ADD_REQUEST';
export const CONTRACT_ADD_SUCCESS = 'CONTRACT_ADD_SUCCESS';
export const CONTRACT_ADD_ERROR = 'CONTRACT_ADD_ERROR';
export const CONTRACT_EDIT_REQUEST = 'CONTRACT_EDIT_REQUEST';
export const CONTRACT_EDIT_SUCCESS = 'CONTRACT_EDIT_SUCCESS';
export const CONTRACT_EDIT_ERROR = 'CONTRACT_EDIT_ERROR';
export const CONTRACT_DELETE_REQUEST = 'CONTRACT_DELETE_REQUEST';
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS';
export const CONTRACT_DELETE_ERROR = 'CONTRACT_DELETE_ERROR';
