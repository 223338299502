import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS} from "../../functions/formatters";
import IconButton from "@mui/material/IconButton";
import {HighlightOff} from "@mui/icons-material";
import {actCRUD} from "../../http";
import {useController, useFieldArray, useFormContext} from "react-hook-form";
import {Button, Dialog, DialogContent, Divider, FormHelperText} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import ActCRUDTable from "../CRUD/Tables/ActCRUDTable";
import {ACT_ROUTE} from "../../routes";
import {useHistory} from "react-router-dom";

function RHFActField({label, disabled = false}) {
    const {control} = useFormContext();
    const {fieldState: {error}} = useController({name: 'acts'});
    const {field: {onChange}} = useController({name: 'targetObject'});
    const {field: {onChange: onChangeFilial}} = useController({name: 'filialId'});
    const {field: {onChange: onChangeFilialDivision}} = useController({name: 'filialDivisionId'});
    const {push} = useHistory()

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'acts',
    });


    const deleteColumnStyles = {
            position: "sticky",
            right: 0,
        },
        cellHeadStyles = {fontWeight: "bold", fontSize: 14};

    function deleteActHandler(id) {
        const index = fields.findIndex(el => el.actId === id)
        remove(index);
    }

    const [open, setOpen] = useState(false)

    const handleGoToAct = async (actId) => {
        let act;
        act = await actCRUD.get(actId);

        let form = act?.form;
        if (!form)
            return;
        push(ACT_ROUTE(form.number, actId))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} style={{overflowX: "auto"}}>
                <Typography color={"textSecondary"}>
                    {label || "Список актов"}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{overflowX: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                № n/n
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Номер акта
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Дата создания
                            </TableCell>
                            <TableCell style={deleteColumnStyles}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(fields || []).map((item) => {
                            return (
                                <TableRow>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.actId}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.number}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {formatDate(item.createdAt, MOMENT_FORMAT_DATE_DMY_DOTS)}
                                    </TableCell>
                                    <TableCell align={"right"} style={deleteColumnStyles}>
                                        <Button size="large" onClick={() => handleGoToAct(item.actId)} variant="contained">
                                            Смотреть
                                        </Button>
                                        <IconButton disabled={disabled}
                                            onClick={() => deleteActHandler(item.actId)}
                                        >
                                            <HighlightOff/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
            <Button sx={{marginTop: '20px', marginLeft: "15px"}} size="large" onClick={() => setOpen(true)} disabled={disabled}
                    variant={"contained"}>
                Выбрать акт
            </Button>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор акта</DialogTitle>
                <Divider/>
                <DialogContent>
                    <ActCRUDTable
                        onClickDetailsButton={undefined} // что бы не показывать кнопку создания
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record) => {
                            onChangeFilial(record?.filialId)
                            onChangeFilialDivision(record?.filialDivisionId)
                            if (record.data) {
                                const actParsing = JSON.parse(record.data)
                                if (actParsing?.object) {
                                    onChange(actParsing?.object?.value)
                                }
                            }
                            append({...record, actId: record.id});
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{marginLeft: "auto"}}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
            <FormHelperText error sx={{p: 2}}>
                {error?.message ? error?.message : null}
            </FormHelperText>
        </Grid>
    );
}

export default RHFActField;
