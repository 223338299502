import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { EnhancedCRUDTable } from '..';
import { checkRoles } from "../../../functions/checkRoles";
import { formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS } from "../../../functions/formatters";
import { receiptCRUD } from '../../../http';
import CRUDTable from "../CRUDTable";

export default function ReceiptCRUDTable({ ...props }) {
  const { t } = useTranslation();
  const { push } = useHistory()
  const { pathname } = useLocation()
  const columns = [
    { title: "№", dataIndex: 'id', key: 'id' },
    { title: "Создано", dataIndex: 'createdAt', key: 'createdAt', render: row => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) },
    { title: "Номер", dataIndex: 'number', key: 'number' },
    { title: "Фамилия, имя, отчество (при наличии)", dataIndex: 'citizenName', key: 'citizenName', render: (unknown, row) => row.data ? JSON.parse(row.data).citizenName.value : <span style={{ color: "gray" }}>Не задан</span> 
    },
    {
        title: "Адрес", dataIndex: 'addressLine', key: 'addressLine', render: (unknown, row) => {
          return row.addressLine ? row.addressLine : <span style={{ color: "gray" }}>Не задан</span>
        }
    },
    { title: "Статус", dataIndex: 'test', key: 'test', render: (unknown, row) => row.state ? row.state.name : <span style={{ color: "gray" }}>Не задан</span> },
  ];

  return <EnhancedCRUDTable
    search={receiptCRUD.search}
    columns={columns}
    onRowClick={(object) => { push(`${pathname}Detail/${object.id}`) }}
    onClickCreateButton={checkRoles('SuperAdministrator', 'Administrator', 'Specialist') ? () => push(`${pathname}Detail/new`) : null}
    {...props}
  />
}
