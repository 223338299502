import { 
    RESULT_GET_REQUEST, RESULT_GET_SUCCESS, RESULT_GET_ERROR, 
    RESULT_GET_BY_KEY_REQUEST, RESULT_GET_BY_KEY_SUCCESS, RESULT_GET_BY_KEY_ERROR, 
    RESULT_ADD_REQUEST, RESULT_ADD_SUCCESS, RESULT_ADD_ERROR, 
    RESULT_EDIT_REQUEST, RESULT_EDIT_SUCCESS, RESULT_EDIT_ERROR, 
    RESULT_DELETE_REQUEST, RESULT_DELETE_SUCCESS, RESULT_DELETE_ERROR
} from "../constants/resultConstants";

const initialState = {
    results: [],
    result: {},
    resultTotal: 0,
    loading: false,
    success: null,
    error: null,
    resultRequestedCount: 0,
};

export const resultReducer = (state = initialState, action) => {
    switch (action.type)     {
        case RESULT_GET_REQUEST:
            return {
                ...state,
                resultRequestedCount: ++state.resultRequestedCount,
                loading: true,
            };
        case RESULT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                results: action.payload.result,
                resultTotal: action.payload.total,
                success: true,
            };
        case RESULT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.payload,
                success: true,
            };
        case RESULT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
