import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuidv4} from "uuid";
import { toUIDate } from '../../functions/dateHelpers';

import {
    REQUEST_STATE_ID_COMPLETED,
    REQUEST_STATE_ID_CREATED,
    REQUEST_STATE_ID_DECLINED,
    REQUEST_STATE_ID_PROCESSING,
    REQUEST_STATE_ID_RESEARCH,
} from "./../../redux/constants/requestStateConstants";
import Typography from "@mui/material/Typography";
import useLoader from "./../../hooks/useLoader";
import {
    actCRUD,
    anObjectReferralCRUD,
    resultCRUD,
} from "./../../http/CRUD";
import {
    ACT_ROUTE,
    OBJECT_REFERRAL,
    OBJECT_REFERRAL_ROUTE
} from "./../../routes/const";
import {
    CircularProgress,
    Dialog,
    Divider,
    Grid,
    TextField,
    Button,
    FormLabel,
    FormControl,
    Box,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import {LoadingButton} from "@mui/lab";
import DashboardLayout from "./../../layouts/Dashboard";

import CreateResult from "./../Result/Popups/CreateResult";
import RemovePopup from "./../../components/Popups/RemovePopup";
import {
    AdditionalInfoSelect,
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect,
    ContractSelect,
    InfectionKindSelect,
    MedicalOrderSelect,
    OrderTypeSelect,
    PurposeOfSelectionsSelect,
    ReferralAdditionalInfosSelect,
    SamplePreservationMethodSelect,
    StorageConditionSelect,
    TechnicalRegulationsOfCustomsUnionsSelect,
    TransportationConditionSelect,
    DiagnosisSelect,
} from "./../../components/Select/domainSelects";

import {BooleanRadio, ProbeTypeRadio} from "./../../components/Radio/radios";
import ProbeTypeBlockTableForm from "./../../components/BlockTableForm/ProbeTypeBlockTableForm";
import CreateProtocol from "./../Protocol/Popups/CreateProtocol";
import {addProtocol} from "./../../redux/actions/protocolActions";
import {Document, Page} from "react-pdf";
import AnObjectField from "./../../components/Relations/AnObjectField";
import CheckboxField from "./../../components/CheckboxField";
import useDialogs from "./../../components/Dialog/useDialogs";
import EmployeeField from "./../../components/Relations/EmployeeField";
import PatientField from "./../../components/Relations/PatientField";
import {InfectionKindField} from "./../../components/Relations/relationFields";
import SimpleTable from "./../../components/SimpleTable";
import ResearchListField from "./../../components/Relations/ResearchListField";
import {mapObjectReferralForSubmit} from "./../../functions/viewDataMap";
import {
    probeStatuses,
    probeCodes,
    patientStatuses,
    researcherStatuses1,
    researcherStatuses2,
    researcherStatuses3,
    researcherStatuses4,
} from "./objectReferralStates";
import ObjectReferalRadio from "./ObjectReferalRadio";
import CollectionTube from "./CollectionTube";
import AddIcon from "@mui/icons-material/Add";

import {RequestStateChecker, renderControls, toUIJson} from "./../../functions";

import {
    ConditionalContainer,
    FormSection,
    PageContainer,
    PageHeader,
    BasicTable,
    PageFooter,
    ButtonModal,
    Barcode,
} from "./../../components";
import {
    BrigadeCreateModal,
    DesinfectionReferenceCreateModal,
} from "./../../screens";
import {
    getAnObjectReferralByKey,
    getBrigades,
    getDesinfectionReferences,
    addAnObjectReferral,
    workflowAnObjectReferral,
    editAnObjectReferral,
} from "./../../redux/actions";

import {barcodeToPdf} from "../../functions/barcodeHelpers";
import GovernmentField from "../../components/Relations/GovernmentField";

import ResultsAndProtocolsSection from "../../sections/ResultsAndProtocolsSection";
import EditResultAndProtocolSection from "../../sections/EditResultAndProtocolSection";

import { protocolCRUD } from "../../http";

import { validateReferralSpecificationsForSubmit } from "../../functions/validations";

import { REQUEST_TYPE_ID_AnObjectReferralRequest } from "../../redux/constants/requestTypeConstants";
import { confirmDialog } from '../../components/Dialog/DialogDelete';

import useReload from "../../hooks/useReload";

import { IfElse as If } from "../../components/ConditionalContainer";
import { commentDialog } from "../../components/Dialog/CommentDialog";

import { validateReferralForSubmit } from "../../functions/validations";
import RegulationOfCuIdField from "../../components/Relations/RegulationOfCuIdField";
import {checkRoles} from "../../functions/checkRoles";

export const ObjectReferralCopy = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id} = useParams();
    const isNew = useMemo(() => !(id > 0), [id]);
    const {push} = useHistory();
    const [loading, setLoading] = useState(false);
    
    const [data, setData] = useState({});
    const [stateId, setStateId] = useState(null);
    const state = useMemo(
        () => new RequestStateChecker(data?.state?.code),
        [data?.state?.code]
    );
    const stateCode = state?.stateCode?.toLowerCase();
    console.log('Hello');
    const [act, setAct] = useState({});
    const [hasAct, setHasAct] = useState(false);
    const [fromAct, setFromAct] = useState(false);
    const [actId, setActId] = useState(null);

    
    React.useEffect(() => {
        if (state?.data) {
            let probeType;
            let actName = state?.data.actName;
            if (actName === 'ActProducts') {
                probeType = 1;
            }
            if (actName === 'ActWater' || actName === 'ActLand') {
                probeType = 3;
            }
            if (actName === 'ActZoonotic' || actName === 'ActDisinfectant' || actName === 'ActWaste' || actName === 'ActSamplingReport' || actName === 'ActRadio') {
                probeType = 2;
            }

            setData((prev) => ({...prev, ...state.data, probeType}))
            setActId(state.data.actId)
            console.log(state.data.actId)
            setTargetObject(state?.data?.targetObject)
            setFromAct(true)
        }
    }, [state?.data])

    const [brigades, setBrigades] = useState([]);
    const [desinfectionReferences, setDesinfectionReferences] = useState([]);

    const {anObjectReferral} = useSelector((s) => s.anObjectReferral);

    // TRASH**
    const {alert, DIALOG} = useDialogs();
    // const isNew = useMemo(() => !(id > 0), [id]);
    const [targetObject, setTargetObject] = useState(null);
    const [testTubes, setTestTubes] = useState([]);

    const handleChange = useCallback(
        (key, value) => setData({...data, [key]: value}),
        [data]
    );

    const { reload, reloadValue } = useReload();

    const handleChangeMulti = useCallback(
        (obj) => setData({...data, ...obj}),
        [data]
    );

    const handleChangeObject = useCallback(
        (key, value) => setData({...data, [key]: value, [`${key}Id`]: value.id}),
        [data]
    );

    const ThreeFieldsCanBeEdited = useMemo(() => {
        return state.isCompleted() && checkRoles("SuperAdministrator");
    }, [state])


    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);

    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: submitLoading,
    } = useLoader(false);

    const {
        start: workflowStart,
        stop: workflowStop,
        loading: workflowLoading,
    } = useLoader(false);

    const handleSubmit = async () => {
        setLoading(true);


        data.targetObject = targetObject;
        data.testTubes = testTubes;
        let _data = mapObjectReferralForSubmit(data);

        if (!validateReferralForSubmit(_data, alert)) {
            setLoading(false);
            return;
        }

        if (fromAct) {
            _data.actId = actId;
            delete _data.results
            delete _data.actName
            delete _data.protocols
        }

        // Creating
        const createdId = await dispatch(addAnObjectReferral(_data));
        if (!createdId) return setLoading(false);

        if (fromAct) {
            if (data?.results?.length) {
                data?.results.map(async el => {
                    await resultCRUD.edit({...el, objectReferralId: createdId})
                })
            }
            if (data?.protocols?.length) {
                data?.protocols.map(async el => {
                    await protocolCRUD.edit({...el, objectReferralId: createdId})
                })
            }
        }

        // Redirecting
        setLoading(false);
        return push(OBJECT_REFERRAL_ROUTE(createdId));
    };



    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const [protocolAddOpen, setProtocolAddOpen] = useState(false);
    const [protocolViewPdf, setProtocolViewPdf] = useState(false);
    const [protocolViewOpen, setProtocolViewOpen] = useState(false);
    const [createResultOpen, setCreateResultOpen] = useState(false);
    const [editResultOpen, setEditResultOpen] = useState(false);
    const [editResultId, setEditResultId] = useState(null);
    const [deleteReferralOpen, setDeleteReferralOpen] = useState(false);

    const [editProtocolOpen, setEditProtocolOpen] = useState(false);
    const [editProtocol, setEditProtocol] = useState(null);

    const handleEditResultOpen = async (resultId) => {
        setEditResultId(resultId);
        setEditResultOpen(true);
    }

    const handleEditProtocolOpen = async (protocolId) => {
        await startSubmit();

        const protocol = await protocolCRUD.get(protocolId);
        await setEditProtocol(protocol);
        await setEditProtocolOpen(true);

        await stopSubmit();
    }

    const hasResults = useMemo(
        () => data.results && data.results.length > 0,
        [data]
    );
    const hasProtocols = useMemo(
        () => data.protocols && data.protocols.length > 0,
        [data]
    );

    const [validationError, setValidationError] = useState(false)

    const renderTextField = (key, label, xs = 12, props) => {
        return (
            <Grid item xs={xs}>
                <TextField
                    margin="normal"
                    value={data[key]}
                    onChange={(e) => handleChange(key, e.target.value)}
                    fullWidth
                    id={key}
                    name={key}
                    label={label}
                    {...props}
                />
            </Grid>
        );
    };

    const renderDateField = (key, label, xs = 12) => {
        return (
            <Grid item xs={xs}>
                <FormControl component="fieldset">
                    {label && <FormLabel component="legend">{label}</FormLabel>}
                    <TextField
                        type={"date"}
                        margin="normal"
                        value={toUIDate(data[key])}
                        label="Дата"
                        onChange={(e) => handleChange(key, e.target.value)}
                        fullWidth
                        id={key}
                        name={key}
                    />
                </FormControl>
            </Grid>
        );
    };

    const renderDatetimeField = (key, label, xs = 12, props) => {
        return (
            <Grid item xs={xs}>
                <FormControl component="fieldset" disabled={props?.disabled}>
                    {label && <FormLabel component="legend">{label}</FormLabel>}
                    <TextField
                        type={"datetime-local"}
                        margin="normal"
                        value={data[key]}
                        label="Дата и время"
                        onChange={(e) => handleChange(key, e.target.value)}
                        fullWidth
                        id={key}
                        name={key}
                    />
                </FormControl>
            </Grid>
        );
    };
    const renderItemGrid = (children, xs = 12) => (
        <Grid item xs={xs}>
            {children}
        </Grid>
    );

    useEffect(() => {
        if (!anObjectReferral?.id) return;

        (async () => {
            dispatch(
                getBrigades({
                    filter: {
                        objectReferralId: {
                            operand1: anObjectReferral?.id,
                            operator: "equals",
                        },
                    },
                    paging: {take: 10},
                    sort: {createdAt: {operator: "desc"}},
                })
            ).then(setBrigades);

            dispatch(
                getDesinfectionReferences({
                    filter: {
                        objectReferralId: {
                            operand1: anObjectReferral?.id,
                            operator: "equals",
                        },
                    },
                    paging: {take: 10},
                    sort: {createdAt: {operator: "desc"}},
                })
            ).then(setDesinfectionReferences);
        })();



        setData(anObjectReferral);
    }, [dispatch, anObjectReferral]);


    const fetch = async () => {
        fetchStart();

        const objectReferral = await dispatch(getAnObjectReferralByKey(id));
        if (!objectReferral) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setHasAct(objectReferral?.actId != null);

        if (objectReferral?.actId) {
            const act = await actCRUD.get(objectReferral?.actId)
            setAct(act)
        }

        if (objectReferral.regulationOfCU2)
            objectReferral.regulationOfCU2 = toUIJson(objectReferral.regulationOfCU2);

        setData({...objectReferral, id: null, state: null, stateId: null, targetPatient: null, targetPatientId: null, number: null});
        setTargetObject(objectReferral?.targetObject);
        setTestTubes(objectReferral?.testTubes);

        fetchStop();
    }

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, stateId, fetchStart, fetchStop]);

    useEffect(() => {
        data.departmentId = null;
        data.divisionId = null;
    },[data.departmentId, data.divisionId])

    return (
        <DashboardLayout>
            <DIALOG/>

            {fetchLoading ? (
                <Grid item xs={12}>
                    <CircularProgress/>
                </Grid>
            ) : (
                <>
                <PageHeader heading={`Заявка по госзаказу ${fromAct ? '(на основе акта)' : ''}: Копирование`}>
                    <Button
                        variant={"text"}
                        onClick={() => push(OBJECT_REFERRAL)}
                    >
                        Вернуться
                    </Button>
                </PageHeader>
                    {Object.keys(act)?.length ? <PageContainer>
                        <Typography gutterBottom variant={'h5'}>Родительский акт:</Typography>
                        <table>
                            <tr>
                                <td onClick={() => {
                                    push(ACT_ROUTE(act?.form?.number, act?.id))
                                }}>{act?.number}</td>
                            </tr>
                        </table>
                    </PageContainer> : null}
                    <PageContainer>
                        {!isNew && ((hasAct && !(state.isCreated() || state.isProcessing())) || !hasAct) && <FormSection heading={"Журналы и протоколы"} mb={4}>
                            <ResultsAndProtocolsSection
                                results={data?.results}
                                protocols={data?.protocols}
                                onResultClick={async (_) => {
                                    await handleEditResultOpen(_);
                                }}
                                onProtocolClick={async (_) => {
                                    await handleEditProtocolOpen(_);
                                }}
                                resultsReloadValue={reloadValue}
                                fetch={fetch}
                                startLoading={async () => {
                                    await startSubmit();
                                }}
                                stopLoading={async () => {
                                    await stopSubmit();
                                }}
                            />
                        </FormSection>}
                        <FormSection heading="Заявка" mb={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <If condition={state.isCreated() || ThreeFieldsCanBeEdited}
                                        elseIf={
                                            <TextField
                                                margin="normal"
                                                value={data.governmentContract?.name}
                                                fullWidth
                                                id="governmentContractId"
                                                name="governmentContractId"
                                                label="Договор КСЭК"
                                                disabled={true}
                                            />
                                        }>
                                        <ContractSelect
                                            label={"Договор КСЭК"}
                                            year={(new Date()).getFullYear()}
                                            fullWidth
                                            value={data.governmentContractId}
                                            onChange={(val, obj) => {
                                              handleChangeMulti({
                                                governmentContractId: val,
                                                governmentContract: obj
                                              });
                                            }}
                                            disabled={!state.isCreated() && !ThreeFieldsCanBeEdited}
                                        />
                                    </If>
                                </Grid>
                                <Grid item xs={12}>
                                    <If condition={state.isCreated()}
                                        elseIf={
                                            <TextField
                                                margin="normal"
                                                value={data.filial?.name}
                                                fullWidth
                                                id="filialId"
                                                name="filialId"
                                                label="Наименование организации"
                                                disabled={true}
                                            />
                                        }>
                                        <FilialSelect
                                            label={"Наименование организации"}
                                            fullWidth
                                            value={data.filialId}
                                            filialIds={data.governmentContract?.filials ? data.governmentContract?.filials.map(_ => _.filialId) : null}
                                            onChange={(val) => {
                                                handleChangeMulti({
                                                    filialId: val,
                                                    filialDivisionId:
                                                        Number(val) !== Number(data.filialId)
                                                            ? null
                                                            : data.filialDivisionId,
                                                });
                                            }}
                                            disabled={!state.isCreated()}
                                        />
                                    </If>
                                </Grid>
                                <Grid item xs={12}>
                                <If condition={state.isCreated()}
                                        elseIf={
                                            <TextField
                                                margin="normal"
                                                value={data.filialDivision?.name}
                                                fullWidth
                                                id="filialDivisionId"
                                                name="filialDivisionId"
                                                label="Наименование отделения"
                                                disabled={true}
                                            />
                                        }
                                    >
                                        <FilialDivisionSelect
                                            label={"Наименование отделения"}
                                            fullWidth
                                            value={data.filialDivisionId}
                                            filialId={data.filialId}
                                            onChange={(filialDivisionId, filialDivision) => {
                                                handleChangeMulti({
                                                    filialDivisionId: filialDivisionId,
                                                    filialId:
                                                        Number(filialDivision.filialId) !==
                                                        Number(data.filialId)
                                                            ? filialDivision.filialId
                                                            : data.filialId,
                                                });
                                            }}
                                        />
                                    </If>
                                    {/* <FilialDivisionSelect
                                        label={"Наименование отделения"}
                                        fullWidth
                                        value={data.filialDivisionId}
                                        filialId={data.filialId}
                                        onChange={(filialDivisionId, filialDivision) => {
                                            handleChangeMulti({
                                                filialDivisionId: filialDivisionId,
                                                filialId:
                                                    Number(filialDivision.filialId) !==
                                                    Number(data.filialId)
                                                        ? filialDivision.filialId
                                                        : data.filialId,
                                            });
                                        }}
                                        disabled={!state.isCreated()}
                                    /> */}
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckboxField
                                        label={"Очаг для ДДД"}
                                        value={data.isFocusOfInfection}
                                        onChange={(val) => {
                                            handleChange("isFocusOfInfection", val);
                                        }}
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {data.isFocusOfInfection ? (
                                        <>
                                            {renderTextField(
                                                "focusAddressLine",
                                                "Место нахождения очага (адрес)",
                                                12,
                                                {disabled: !state.isCreated()}
                                            )}
                                            {renderTextField(
                                                "infectedFullName",
                                                "ФИО зараженного",
                                                12,
                                                {disabled: !state.isCreated()}
                                            )}
                                            {renderItemGrid(
                                                <InfectionKindField
                                                    label={"Заболеваемость"}
                                                    fullWidth
                                                    object={data.infectionKind}
                                                    value={data.infectionKindId}
                                                    onChange={(val) =>
                                                        handleChangeObject("infectionKind", val)
                                                    }
                                                    disabled={!state.isCreated()}
                                                />
                                            )}
                                        </>
                                    ) : ((state.isCreated() || !checkRoles("Performer", "Supervisor")) &&
                                        <>
                                            <AnObjectField
                                                value={targetObject}
                                                onChange={(obj) => setTargetObject({...obj})}
                                                disabled={!state.isCreated()}
                                            />
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </FormSection>

                        {renderTextField(
                            "emergencyNotificationNumber",
                            "4. Номер акта о назначении проверки / экстренного извещения",
                            6,
                            {disabled: !state.isCreated()}
                        )}
                        {renderDateField(
                            "emergencyNotificationDate",
                            "4. Дата акта о назначении проверки / экстренного извещения",
                            6,
                            {disabled: !state.isCreated()}
                        )}

                        {/* Дата */}
                        {renderTextField(
                            "samplingLocation",
                            "5. Место отбора образца / обработки для дез",
                            12,
                            {disabled: !state.isCreated()}
                        )}

                        {/* 6. Цель отбора образца / обработки для дез - samplingPurposeId*/}
                        {renderItemGrid(
                            <PurposeOfSelectionsSelect
                                label={"6. Цель отбора образца / обработки для дез"}
                                fullWidth
                                value={data.purposeOfSelectionId}
                                onChange={(val) => handleChange("purposeOfSelectionId", val)}
                                disabled={!state.isCreated()}
                            />
                        )}

                        {/* ТР/ТС */}
                        {data.regulationOfCUId ? renderItemGrid(
                            <TechnicalRegulationsOfCustomsUnionsSelect
                                fullWidth
                                value={data.regulationOfCUId}
                                onChange={(val) => handleChange("regulationOfCUId", val)}
                                disabled={!state.isCreated()}
                            />
                        ) : renderItemGrid(
                            <RegulationOfCuIdField
                            fullWidth
                            array={toUIJson(data.regulationOfCU2)}
                            onChange={(val) => handleChangeObject("regulationOfCU2", val)}
                            disabled={!state.isCreated()}
                            />
                            )
                        }

                        {/* Список приказов для ДДД */}
                        {renderItemGrid(
                            <MedicalOrderSelect
                                fullWidth
                                value={data.medicalOrderId}
                                onChange={(val) => handleChange("medicalOrderId", val)}
                                disabled={!state.isCreated()}
                            />
                        )}

                        <Grid
                            item
                            container
                            spacing={1}
                            xs={12}
                            style={{alignItems: "center"}}
                        >
                            {/* Цель отбора (дополнительное поле, заполняется при необходимости)*/}
                            {renderItemGrid(
                                <AdditionalInfoSelect
                                    fullWidth
                                    value={data.additionalInfoId}
                                    onChange={(val) => handleChange("additionalInfoId", val)}
                                    disabled={!state.isCreated()}
                                />,
                                6
                            )}
                            {renderTextField(
                                "purposeOfSelectionLine",
                                "Цель отбора (дополнительное поле, заполняется при необходимости)",
                                6,
                                {disabled: !state.isCreated()}
                            )}
                        </Grid>

                        {/* 7. Дата и время отбора проб (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {renderDatetimeField(
                            "sampleSelectionTime",
                            "7. Дата и время отбора проб (тем, кто пробы не отбирает заполнять не нужно)",
                            6,
                            {disabled: !state.isCreated()}
                        )}

                        {/* 8. Дата и время доставки проб (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {renderDatetimeField(
                            "sampleDeliveryTime",
                            "8. Дата и время доставки проб (тем, кто пробы не отбирает заполнять не нужно)",
                            6,
                            {disabled: !state.isCreated()}
                        )}

                        {/* 9. НД на метод отбора (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {/*{renderItemGrid(*/}
                        {/*    <GovernmentStandardsSelect*/}
                        {/*        fullWidth*/}
                        {/*        value={data.standardId}*/}
                        {/*        onChange={(val) => handleChange("standardId", val)}*/}
                        {/*        disabled={!state.isCreated()}*/}
                        {/*    />*/}
                        {/*)}*/}

                        <GovernmentField
                            fullWidth
                            disabled={!state.isCreated()}
                            value={data.standard2Id}
                            array={toUIJson(data.standard2)}
                            onChange={(val) => handleChangeObject("standard2", val)}
                        />

                        {/* 10. Условия транспортировки (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {renderItemGrid(
                            <TransportationConditionSelect
                                fullWidth
                                value={data.transportationConditionId}
                                onChange={(val) =>
                                    handleChange("transportationConditionId", val)
                                }
                                disabled={!state.isCreated()}
                            />
                        )}

                        {/* 11. Условия хранения (тем, кто пробы не отбирает заполнять не нужно)*/}
                        {renderItemGrid(
                            <StorageConditionSelect
                                fullWidth
                                value={data.storageConditionId}
                                onChange={(val) => handleChange("storageConditionId", val)}
                                disabled={!state.isCreated()}
                            />
                        )}

                        {/* 12. Методы консервации образца */}
                        {renderItemGrid(
                            <SamplePreservationMethodSelect
                                fullWidth
                                value={data.samplePreservationMethodId}
                                onChange={(val) =>
                                    handleChange("samplePreservationMethodId", val)
                                }
                                disabled={!state.isCreated()}
                            />
                        )}

                        {/* 13. Дополнительные сведения */}
                        {renderItemGrid(
                            <ReferralAdditionalInfosSelect
                                fullWidth
                                value={data.additionalInfoId}
                                onChange={(val) => handleChange("additionalInfoId", val)}
                                disabled={!state.isCreated()}
                            />
                        )}

                        {/* 14. №, дата акта от Департамента */}
                        {renderTextField(
                            "actInfo",
                            "14. №, дата акта от Департамента",
                            12,
                            {
                                disabled: !state.isCreated(),
                            }
                        )}

                        {/* 15. Тип заказа */}
                        {renderItemGrid(
                            <OrderTypeSelect
                                fullWidth
                                value={data.orderTypeId}
                                onChange={(val) => handleChange("orderTypeId", val)}
                                disabled={!state.isCreated()}
                            />
                        )}

                        {/* 16. Перечень отобранных образцов (тем, кто пробы не отбирает, заполнять не нужно) */}
                        {renderItemGrid(
                            <ProbeTypeRadio
                                value={data.probeType}
                                onChange={(val) => handleChange("probeType", val)}
                                // disabled={!state.isCreated()}
                            />
                        )}
                        {!!data.probeType && data.probeType < 5 && (
                            <ProbeTypeBlockTableForm
                                probeType={data.probeType}
                                data={data.probes}
                                onChange={(val) => handleChange("probes", val)}
                                // disabled={!state.isCreated()}
                            />
                        )}
                        {data.probeType == 5 && (
                            <>
                                <PatientField
                                    object={data.targetPatient}
                                    value={data.targetPatientId}
                                    onChange={(val) => handleChangeObject("targetPatient", val)}
                                    disabled={!state.isCreated()}
                                />
                                <SimpleTable columns={[]} data={{}}/>
                                <Grid item xs={12}>
                                    <DiagnosisSelect
                                        value={toUIJson(data.diagnosis)}
                                        onChange={(val) => handleChange("diagnosis", val)}
                                        fullWidth
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.antibioticUse}
                                        onChange={(e) =>
                                            handleChange("antibioticUse", e.target.value)
                                        }
                                        fullWidth
                                        id="antibioticUse"
                                        name="antibioticUse"
                                        label="Применение антибиотиков"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InfectionKindSelect
                                        value={data.infectionKinds}
                                        onChange={(val) => handleChange("infectionKinds", val)}
                                        fullWidth
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.researchMaterial}
                                        onChange={(e) =>
                                            handleChange("researchMaterial", e.target.value)
                                        }
                                        fullWidth
                                        id="researchMaterial"
                                        name="antibioticUse"
                                        label="Материал исследований"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.sampleSentOrganizationName}
                                        onChange={(e) =>
                                            handleChange("sampleSentOrganizationName", e.target.value)
                                        }
                                        fullWidth
                                        id="sampleSentOrganizationName"
                                        name="sampleSentOrganizationName"
                                        label="Наименование учреждения направившего образец"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.sampleCollectedFullName}
                                        onChange={(e) =>
                                            handleChange("sampleCollectedFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="sampleCollectedFullName"
                                        name="sampleCollectedFullName"
                                        label="ФИО специалиста, отбиравшего пробу, должность"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.arrivalCountry}
                                        onChange={(e) =>
                                            handleChange("arrivalCountry", e.target.value)
                                        }
                                        fullWidth
                                        id="arrivalCountry"
                                        name="arrivalCountry"
                                        label="ФИО специалиста, отбиравшего пробу, должность"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        label="Статус отбора пробы"
                                        array={probeStatuses}
                                        value={data.probeStatus}
                                        setValue={(val) => handleChange("probeStatus", val)}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={patientStatuses}
                                        value={data.patientStatus}
                                        setValue={(val) => {
                                        }}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BooleanRadio
                                        label={"Наличие клинических симптомов КВИ"}
                                        value={data.clinicalSymptomsCVI}
                                        onChange={(val) => handleChange("clinicalSymptomsCVI", val)}
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        label="Наименование пробы"
                                        array={probeCodes}
                                        value={data.probeCode}
                                        setValue={(val) => handleChange("probeCode", val)}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        label="16. Статус исследуемого"
                                        array={researcherStatuses1}
                                        value={data.researcherStatus1}
                                        setValue={(val) => handleChange("researcherStatus1", val)}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Divider style={{margin: "10px 0px"}}/>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={researcherStatuses2}
                                        value={data.researcherStatus2}
                                        setValue={(val) => handleChange("researcherStatus2", val)}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Divider style={{margin: "10px 0px"}}/>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={researcherStatuses3}
                                        value={data.researcherStatus3}
                                        setValue={(val) => handleChange("researcherStatus3", val)}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Divider style={{margin: "10px 0px"}}/>
                                <Grid item xs={12}>
                                    <ObjectReferalRadio
                                        array={researcherStatuses4}
                                        value={data.researcherStatus4}
                                        setValue={(val) => handleChange("researcherStatus4", val)}
                                        required
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.sickFullName}
                                        onChange={(e) =>
                                            handleChange("sickFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="sickFullName"
                                        name="sickFullName"
                                        label="ФИО больного"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.closeContactFullName}
                                        onChange={(e) =>
                                            handleChange("closeContactFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="closeContactFullName"
                                        name="closeContactFullName"
                                        label="Ф.И.О. близкого контакта"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.potentialContactFullName}
                                        onChange={(e) =>
                                            handleChange("potentialContactFullName", e.target.value)
                                        }
                                        fullWidth
                                        id="potentialContactFullName"
                                        name="potentialContactFullName"
                                        label="Ф.И.О. потенциального контакта"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.clinicProbeNumberForCovid}
                                        onChange={(e) =>
                                            handleChange("clinicProbeNumberForCovid", e.target.value)
                                        }
                                        fullWidth
                                        id="clinicProbeNumberForCovid"
                                        name="clinicProbeNumberForCovid"
                                        label="Номер пробы поликлиники для covid"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.referralNumber}
                                        onChange={(e) =>
                                            handleChange("referralNumber", e.target.value)
                                        }
                                        fullWidth
                                        id="referralNumber"
                                        name="referralNumber"
                                        label="Номер направления"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        margin="normal"
                                        value={data.researchPurpose}
                                        onChange={(e) =>
                                            handleChange("researchPurpose", e.target.value)
                                        }
                                        fullWidth
                                        id="researchPurpose"
                                        name="researchPurpose"
                                        label="Цель исследования"
                                        disabled={!state.isCreated()}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant={"h5"} style={{display: "inline"}}>
                                        Пробирка для забора
                                    </Typography>
                                    <Button
                                        onClick={() =>
                                            setTestTubes([
                                                ...testTubes,
                                                {id: uuidv4(), name: "Пусто", quantity: 0},
                                            ])
                                        }
                                        sx={{marginLeft: 2}}
                                        disabled={!state.isCreated()}
                                    >
                                        <AddIcon sx={{marginRight: 2}}/> Добавить блок
                                    </Button>
                                </Grid>
                                <CollectionTube
                                    setData={(tubes) => {
                                        setTestTubes([...tubes]);
                                    }}
                                    data={testTubes}
                                />
                            </>
                        )}

                        {/* 17. Список исследований - todo коды? */}
                        {data.stateId &&
                        renderItemGrid(
                            <ResearchListField
                                value={data.specifications}
                                error={validationError}
                                onChange={(val) => {
                                    if (validationError)
                                        setValidationError(false)
                                    handleChange("specifications", val)}}
                                disabled={!state.isResearch()}
                                shouldSubmit={state.isResearch()}
                                submitLoading={submitLoading}
                                onSubmit={async () => {
                                    const valid = validateReferralSpecificationsForSubmit(data?.specifications, null, null, alert, REQUEST_TYPE_ID_AnObjectReferralRequest,
                                        data?.researchStartDateTime, data?.researchEndDateTime);

                                    if (!valid) {
                                        setValidationError(true)
                                        return;
                                    }
                                    setValidationError(false)
                                    handleSubmit();
                                }
                                }
                            />
                        )}


                        {/* 18. Дата начала исследования  - todo*/}
                        {renderDateField(
                            "researchStartDateTime",
                            "18. Дата начала исследования ",
                            12,
                            {disabled: !state.isCreated() && !state.isResearch()}
                        )}

                        {/* 19. Дата окончания исследования  - todo*/}
                        {renderDateField(
                            "researchEndDateTime",
                            "Дата окончания исследования (цены тарификатора зависят от этой даты)",
                            12,
                            {disabled: !state.isCreated() && !state.isResearch()}
                        )}

                        <Grid item xs={12}>
                            <DepartmentSelect
                                label={"Лаборатория/дез отделы/очаги"}
                                fullWidth
                                value={data.departmentId}
                                onChange={(departmentId) => {
                                    const obj = {departmentId};
                                    handleChangeMulti(obj);
                                }}
                                // disabled={!state.isCreated() && !ThreeFieldsCanBeEdited}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DivisionSelect
                                isNullable={true}
                                label={"Отдел лаборатории (если имеется) "}
                                fullWidth
                                value={data.divisionId}
                                onChange={(val) => handleChange("divisionId", val)}
                                disabled={!state.isCreated() && !ThreeFieldsCanBeEdited}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployeeField
                                label={"Заведующий лаборатории/Начальник отдела"}
                                fullWidth
                                object={data.responsible}
                                value={data.responsibleId}
                                onChange={(responsible) => {
                                    const obj = {
                                        responsible,
                                        responsibleId: responsible.id,
                                        //departmentId: responsible.departmentId,
                                    };
                                    handleChangeMulti(obj);
                                }}
                                disabled={!state.isCreated()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployeeField
                                label={"Врач/Специалист/Лаборант/Дезинфектор/Дез. инструктор"}
                                fullWidth
                                object={data.performer}
                                value={data.performerId}
                                onChange={(performer) => {
                                    const obj = {
                                        performer,
                                        performerId: performer.id,
                                    };
                                    handleChangeMulti(obj);
                                }}
                                disabled={!state.isCreated() && !state.isProcessing()}
                            />
                        </Grid>
                        <Grid item xs={12}/>

                        <ConditionalContainer condition={state?.isResearch()}>
                            <Divider style={{margin: "50px 0px"}}/>
                            {/* <FormSection heading={`Акты (${acts?.length || 0})`} mb={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BasicTable
                                            cells={[
                                                {id: "id", label: t("act.tableItem.id")},
                                                {id: "number", label: t("act.tableItem.number")},
                                                {
                                                    id: "createdAt",
                                                    label: t("act.tableItem.createdAt"),
                                                },
                                            ]}
                                            rows={acts}
                                            button={
                                                <Grid container columnSpacing={2}>
                                                    <Grid item>
                                                        <ButtonModal modal={ActCreateModal}>
                                                            Добавить акт
                                                        </ButtonModal>
                                                    </Grid>
                                                </Grid>
                                            }
                                            disabled={!state.isResearch()}
                                        />
                                    </Grid>
                                </Grid>
                            </FormSection> */}
                            <FormSection heading={`Наряды (${brigades?.length || 0})`} mb={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BasicTable
                                            cells={[
                                                {id: "id", label: t("brigade.tableItem.id")},
                                                {id: "number", label: t("brigade.tableItem.number")},
                                                {
                                                    id: "state",
                                                    label: t("brigade.tableItem.state"),
                                                    render: (row) => row?.state?.name,
                                                },
                                                {
                                                    id: "addressLine",
                                                    label: t(
                                                        "disinfectionReference.tableItem.addressLine"
                                                    ),
                                                },
                                                {
                                                    id: "createdAt",
                                                    label: t("brigade.tableItem.createdAt"),
                                                },
                                            ]}
                                            rows={brigades}
                                            button={
                                                <Grid container columnSpacing={2}>
                                                    <Grid item>
                                                        <ButtonModal modal={BrigadeCreateModal}>
                                                            Добавить
                                                        </ButtonModal>
                                                    </Grid>
                                                </Grid>
                                            }
                                            disabled={!state.isResearch()}
                                        />
                                    </Grid>
                                </Grid>
                            </FormSection>
                            <FormSection
                                heading={`Справки (${desinfectionReferences?.length || 0})`}
                                mb={4}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <BasicTable
                                            cells={[
                                                {
                                                    id: "id",
                                                    label: t("disinfectionReference.tableItem.id"),
                                                },
                                                {
                                                    id: "number",
                                                    label: t("disinfectionReference.tableItem.number"),
                                                },
                                                {
                                                    id: "fullName",
                                                    label: t("disinfectionReference.tableItem.fullName"),
                                                },
                                                {
                                                    id: "state",
                                                    label: t("disinfectionReference.tableItem.state"),
                                                    render: (row) => row?.state?.name,
                                                },

                                                {
                                                    id: "createdAt",
                                                    label: t("disinfectionReference.tableItem.createdAt"),
                                                },
                                            ]}
                                            rows={desinfectionReferences}
                                            button={
                                                <Grid container columnSpacing={2}>
                                                    <Grid item>
                                                        <ButtonModal
                                                            modal={DesinfectionReferenceCreateModal}
                                                        >
                                                            Добавить
                                                        </ButtonModal>
                                                    </Grid>
                                                </Grid>
                                            }
                                            disabled={!state.isResearch()}
                                        />
                                    </Grid>
                                </Grid>
                            </FormSection>
                        </ConditionalContainer>
                    </PageContainer>
                    <PageFooter>
                        <LoadingButton loading={loading} onClick={handleSubmit}>
                            Копировать заявку
                        </LoadingButton>
                    </PageFooter>
                </>
            )}

            {createResultOpen && (
                <CreateResult
                    action={"createByObjectReferral"}
                    title={t("resultList.createByReferralText")}
                    onClose={() => setCreateResultOpen(false)}
                    onSubmit={async (response) => {
                        await fetch();
                    }}
                    act={act}
                    referral={data}
                />
            )}
            <EditResultAndProtocolSection
                editResultOpen={editResultOpen}
                setEditResultOpen={setEditResultOpen}
                editProtocolOpen={editProtocolOpen}
                setEditProtocolOpen={setEditProtocolOpen}
                editResultId={editResultId}
                editProtocol={editProtocol}
                startLoading={async () => {
                    await startSubmit();
                }}
                stopLoading={async () => {
                    await stopSubmit();
                }}
                onEditResultSubmitted={async (_) => {
                    reload();
                }}
                results={data.results}
            />
            {deleteReferralOpen && (
                <RemovePopup
                    deleteAction={() => anObjectReferralCRUD.delete(id)}
                    title={`Вы действительно хотите удалить заявку по госзаказу №${data.number}?`}
                    onClose={() => setDeleteReferralOpen(false)}
                    onSubmit={() => push(OBJECT_REFERRAL)}
                />
            )}
            {protocolAddOpen && (
                <CreateProtocol
                    action={"createByObjectReferral"}
                    open={protocolAddOpen}
                    initNumber={data.number}
                    title={`Создание протокола для заявки по госзаказу №${data.number}?`}
                    onClose={() => setDeleteReferralOpen(false)}
                    onSubmit={async (data) => {
                        await dispatch(addProtocol(data, {sort: {id: {operator: "asc"}}}));
                        await fetch();
                    }}
                    act={act}
                    setOpen={setProtocolAddOpen}
                    request={data}
                    referral={data}
                />
            )}
            {/* {editProtocolOpen && (
                <EditProtocol
                    action={'edit'}
                    title={t("protocolList.editText")}
                    open={editProtocolOpen}
                    setOpen={setEditProtocolOpen}
                    onSubmit={editProtocolSubmit}
                    //loading={loading}
                    protocol={editProtocol}/>
            )} */}
            <Dialog
                open={protocolViewOpen}
                onClose={() => setProtocolViewOpen(false)}
                fullWidth
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Document
                                file={protocolViewPdf}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber}/>
                            </Document>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </DashboardLayout>
    );
};
