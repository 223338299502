import DirectionMaterialForm from "./DirectionMaterialForm";
import DirectionMaterialPDFGenerator from "./DirectionMaterialPDFGenerator";

import withDirectionDetail from "../withDirectionDetail";

const directionFormKazakhName = 'Қызылша мен қызамыққа материалды (зерттелушіден, ауру адамнан) зерттеуге жолдама'
const directionFormName = 'Направление материала (от обследуемого, больного) для исследования на корь, краснуху'
const directionFormNumber = '017'
const directionName = 'DirectionMaterial'

export default withDirectionDetail(DirectionMaterialForm, DirectionMaterialPDFGenerator, directionFormKazakhName,  directionFormName,  directionFormNumber, directionName)
