import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getServicePriceLists, getServicePriceListByKey } from "../../redux/actions/servicePriceListActions";
import ServicePriceListDetails from "./Popups/ServicePriceListDetails";

function createData(id, name, price, service, unit, currency) {
    return {id, name, price, service, unit, currency};
}

let rows = [
    createData(1, "abc", 1.0, null, null, null),
];
const ServicePriceList = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("servicePriceList.tableItem.id")},
        {text: t("servicePriceList.tableItem.name")},
        {text: t("servicePriceList.tableItem.price")},
        {text: t("servicePriceList.tableItem.service")},
        {text: t("servicePriceList.tableItem.unit")},
        {text: t("servicePriceList.tableItem.currency")},
        {text: t("servicePriceList.tableItem.actions")},
    ];
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getServicePriceListByKey(id));
        setDetailsOpen(false);
        setDetailsOpen(true);
        setId(id);
    }
    

    const localData = false;
    const state = useSelector((state) => state.servicePriceList);
    if (!localData && state.servicePriceLists)
    {
        rows = state.servicePriceLists;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getServicePriceLists(query));
        }
    }, [dispatch]);
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("servicePriceList.searchLabel")}/>
            </Box>
            <TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {
                return {
                    id: _.id,
                    name: _.name,
                    price: _.price,
                    service: _.service?.name,
                    unit: _.unit?.name,
                    currency: _.currency?.name,
                }
            })} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>]}/>
            <ServicePriceListDetails action={'details'} title={t("servicePriceList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
        </DashboardLayout>
    )
};

export default ServicePriceList;
