import React, {useCallback, useEffect, useMemo, useState} from "react";

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {
    BasicModalHeader,
    BasicModalContainer,
    Form,
} from "../../../components";
import {DatePicker, DateTimePicker, LoadingButton} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";

import useDialogs from "../../../components/Dialog/useDialogs";
import EmployeeField from "../../../components/Relations/EmployeeField";
import {
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect, PurposeOfSelectionsSelect, SamplingPurposeSelect,
} from "../../../components/Select/domainSelects";
import useLoader from "../../../hooks/useLoader";
import {actCRUD} from "../../../http/CRUD";
import {getActForms} from "../../../redux/actions/actFormActions";
import {getGovernmentStandards} from "../../../redux/actions/governmentStandardActions";
import {getSamplePreservationMethods} from "../../../redux/actions/samplePreservationMethodActions";
import {getStorageConditions} from "../../../redux/actions/storageConditionActions";
import {getTransportationConditions} from "../../../redux/actions/transportationConditionActions";
import {
    REQUEST_STATE_ID_COMPLETED,
    REQUEST_STATE_ID_DECLINED,
    REQUEST_STATE_ID_PROBESELECTION,
    REQUEST_STATE_ID_RESEARCH,
} from "../../../redux/constants/requestStateConstants";

import ObjectField from "../../../components/Relations/ObjectField";

export const ActWaterCreateSection = ({onSubmit}) => {
    const dispatch = useDispatch();
    const {push} = useHistory();

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({});
    const actForm = useSelector((s) => s.actForm);

    const handleSubmit = async (e) => {
        setLoading(true);

        await onSubmit(e, data);

        setLoading(false);
    };

    // TRASH**
    //configStart
    const actName = "ActWater";
    const kazakhName = "Су үлгілерін іріктеу АКТIСI";
    const name = "АКТ отбора образцов воды";
    //configEnd
    const {alert, DIALOG} = useDialogs();
    const {t} = useTranslation();
    const governmentStandardValues = useSelector(
        (state) => state.governmentStandard
    );
    const transportationConditionValues = useSelector(
        (state) => state.transportationCondition
    );
    const storageConditionValues = useSelector((state) => state.storageCondition);
    const samplePreservationMethodValues = useSelector(
        (state) => state.samplePreservationMethod
    );
    const {id} = useParams();
    const isNew = useMemo(() => !(id > 0), [id]);

    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: loadingSubmit,
    } = useLoader(false);
    const {
        start: workflowStart,
        stop: workflowStop,
        loading: workflowLoading,
    } = useLoader(false);
    const style = {marginLeft: 10};
    const stateCode = data.state?.code;

    const handleChange = useCallback(
        (key, value) => {
            setData({...data, [key]: value});
        },
        [data]
    );
    const handleChangeMulti = useCallback(
        (obj) => {
            setData({...data, ...obj});
        },
        [data]
    );

    const handleChangeData = useCallback(
        (key, value, key2) => {
            setData({...data, data: {...data.data, [key]: {key: key2, value}}});
        },
        [data]
    );

    const handleChangeSample = useCallback(
        (key, value, i, key2) => {
            const array = data.data?.samples?.value.slice(0);
            array[i][key] = {key: key2, value};
            setData({
                ...data,
                data: {
                    ...data.data,
                    samples: {key: "samples", value: array},
                },
            });
        },
        [data]
    );


    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (actForm.actForms.length === 0) {
            dispatch(getActForms());
        }
    }, [actForm.actForms.length]);

    useEffect(() => {
        if (governmentStandardValues.governmentStandards.length === 0) {
            dispatch(getGovernmentStandards());
        }
        if (transportationConditionValues.transportationConditions.length === 0) {
            dispatch(getTransportationConditions());
        }
        if (storageConditionValues.storageConditions.length === 0) {
            dispatch(getStorageConditions());
        }
        if (samplePreservationMethodValues.samplePreservationMethods.length === 0) {
            dispatch(getSamplePreservationMethods());
        }
    }, [dispatch]);

    useEffect(() => {
        if (
            [REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_DECLINED].includes(
                stateCode
            )
        ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [stateCode]);

    useEffect(() => {
        setLoading(false);
    }, []);

    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    useEffect(() => {
        setData({
            ...data,
            responsibleForProbesId: authorizedEmployee?.id,
            responsibleForProbes: authorizedEmployee,
        });
    }, [])

    return (
        <>
            <BasicModalHeader heading="Акт отбора образцов воды"/>
            <BasicModalContainer>
                <Form onSubmit={handleSubmit}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody className="border-table-word">
                                <TableRow>
                                    <TableCell className="border-table-word"></TableCell>
                                    <TableCell className="border-table-word" align="left">
                                        <p>Нысанның БҚСЖ бойынша коды</p>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <p style={{width: "50%"}}>Код формы по ОКУД</p>
                                            <TextField
                                                id="outlined-basic"
                                                label="Код"
                                                style={{width: "50%"}}
                                                variant="outlined"
                                            />
                                        </div>
                                        <p>КҰЖЖ бойынша ұйым коды</p>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <p style={{width: "50%"}}>Код организации по ОКПО</p>
                                            <TextField
                                                id="outlined-basic"
                                                label="Код"
                                                style={{width: "50%"}}
                                                variant="outlined"
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="border-table-word">
                                        <p>
                                            Қазақстан Республикасының Денсаулық сақтау министрлігі
                                            Министерство здравоохранения Республики Казахстан
                                        </p>
                                    </TableCell>
                                    <TableCell className="border-table-word" align="left">
                                        <p>
                                            Қазақстан Республикасының Денсаулық сақтау министрінің
                                            2021 жылғы "20" 08 № 84 бұйрығымен бекітілген № 004/е
                                            нысанды медициналық құжаттама
                                        </p>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="border-table-word">
                                        <p>
                                            Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк
                                            органының атауы Наименование государственного органа
                                            санитарно-эпидемиологической службы
                                        </p>
                                    </TableCell>
                                    <TableCell className="border-table-word" align="left">
                                        <p>
                                            Медицинская документация Форма № 004/у Утверждена приказом
                                            Министра здравоохранения Республики Казахстан от "20" 08
                                            2021 года №84
                                        </p>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <>
                        <div
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <h5 align="center">{kazakhName}</h5>
                            <h5 align="center">{name} (от)</h5>
                            <LocalizationProvider
                                locale={ruLocale}
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    views={["day"]}
                                    label="от"
                                    value={data.data?.dateOfAct?.value}
                                    onChange={(value) => {
                                        handleChangeData("dateOfAct", value, "dateOfAct");
                                    }}
                                    minDate={new Date("1910-03-01")}
                                    renderInput={(params) => (
                                        <TextField margin="normal" {...params} helperText={null}/>
                                    )}
                                />
                            </LocalizationProvider>
                        </div>

                        {data.number && (
                            <h5 style={{marginBottom: "30px"}} align="center">
                                №{data.number}
                            </h5>
                        )}
                        <ObjectField
                            label={"1. Объектінің атауы (Наименование объекта)"} fullWidth
                            object={data.data?.object?.value}
                            value={data.data?.object?.value?.id}
                            onChange={(obj) => { handleChangeData('object', obj, 'facility') }}
                        />
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                2. Тексеру белгіленгені туралы актінің нөмірі (Номер акта о
                                назначении проверки)
                            </p>
                            <TextField
                                id="outlined-basic"
                                label="Номер акта"
                                style={{width: "50%", marginLeft: "30px"}}
                                variant="outlined"
                                type="number"
                                value={data.data?.number?.value}
                                onChange={(e) =>
                                    handleChangeData("number", e.target.value, "number")
                                }
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                3. Тағайындау актісі күні (Дата акта о назначении проверки)
                            </p>
                            <LocalizationProvider
                                locale={ruLocale}
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    views={["day"]}
                                    label="Дата акта"
                                    value={data.data?.date?.value}
                                    onChange={(value) => {
                                        handleChangeData("date", value, "date");
                                    }}
                                    minDate={new Date("1910-03-01")}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{width: "50%", margin: "0 0 0 30px"}}
                                            margin="normal"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                4. Сынама үлгісі алынған орын (Место отбора образца)
                            </p>
                            <TextField
                                id="outlined-basic"
                                label="Место отбора"
                                style={{width: "50%", marginLeft: "30px"}}
                                variant="outlined"
                                value={data.data?.selectionPlace?.value}
                                onChange={(e) =>
                                    handleChangeData(
                                        "selectionPlace",
                                        e.target.value,
                                        "selection_place"
                                    )
                                }
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                5. Сынамалар алу мақсаты (Цель отбора):
                            </p>
                            <PurposeOfSelectionsSelect
                                label={"Сынамалар алу мақсаты (Цель отбора)"}
                                fullWidth
                                value={data.data?.samplingPurpose?.value?.id}
                                onChange={(val, obj) => { handleChangeData('samplingPurpose', obj, 'samplingPurpose') }}
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                6. Алынған күні мен уақыты (Дата и время отбора)
                            </p>
                            <LocalizationProvider
                                locale={ruLocale}
                                dateAdapter={AdapterDateFns}
                            >
                                <DateTimePicker
                                    views={["day"]}
                                    label="Дата и время отбора"
                                    value={data.data?.selectionDateTime?.value}
                                    onChange={(value) =>
                                        handleChangeData(
                                            "selectionDateTime",
                                            value,
                                            "selection_time"
                                        )
                                    }
                                    minDate={new Date("1910-03-01")}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{width: "50%", margin: "0 0 0 30px"}}
                                            margin="normal"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                7. Жарамдылық мерзімі (Срок годности)
                            </p>
                            <LocalizationProvider
                                locale={ruLocale}
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    views={["day"]}
                                    label="Срок годности"
                                    value={data.data?.expirationDate?.value}
                                    onChange={(value) =>
                                        handleChangeData("expirationDate", value, "expirationDate")
                                    }
                                    minDate={new Date("1910-03-01")}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{width: "50%", margin: "0 0 0 30px"}}
                                            margin="normal"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                8. Жеткізілген күні мен уақыты (Дата и время доставки)
                            </p>
                            <LocalizationProvider
                                locale={ruLocale}
                                dateAdapter={AdapterDateFns}
                            >
                                <DateTimePicker
                                    views={["day"]}
                                    label="Дата и время доставки"
                                    value={data.data?.deliveryDateTime?.value}
                                    onChange={(value) =>
                                        handleChangeData("deliveryDateTime", value, "delivery_time")
                                    }
                                    minDate={new Date("1910-03-01")}
                                    renderInput={(params) => (
                                        <TextField
                                            style={{width: "50%", margin: "0 0 0 30px"}}
                                            margin="normal"
                                            {...params}
                                            helperText={null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                9. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
                                метод отбора)
                            </p>
                            <FormControl style={{width: "50%", marginLeft: "30px"}}>
                                <InputLabel id="Нормативный документ на метод отбора">
                                    Нормативный документ на метод отбора
                                </InputLabel>
                                <Select
                                    labelId="Нормативный документ на метод отбора"
                                    label="Нормативный документ на метод отбора"
                                    value={data.data?.standard?.value}
                                    onChange={(e) =>
                                        handleChangeData("standard", e.target.value, "nd_selection")
                                    }
                                >
                                    {governmentStandardValues.governmentStandards.length !== 0 &&
                                        governmentStandardValues.governmentStandards.map((obj) => {
                                            return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                10. Тасымалдау жағдайы (Условия транспортировки)
                            </p>
                            <FormControl style={{width: "50%", marginLeft: "30px"}}>
                                <InputLabel id="Условия транспортировки">
                                    Условия транспортировки
                                </InputLabel>
                                <Select
                                    labelId="Условия транспортировки"
                                    label="Условия транспортировки"
                                    value={data.data?.transportationCondition?.value}
                                    onChange={(e) =>
                                        handleChangeData(
                                            "transportationCondition",
                                            e.target.value,
                                            "transportation_conditions"
                                        )
                                    }
                                >
                                    {transportationConditionValues.transportationConditions
                                            .length !== 0 &&
                                        transportationConditionValues.transportationConditions.map(
                                            (obj) => {
                                                return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                                            }
                                        )}
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                11. Сақтау жағдайы (Условия хранения)
                            </p>
                            <FormControl style={{width: "50%", marginLeft: "30px"}}>
                                <InputLabel id="Условия хранения">Условия хранения</InputLabel>
                                <Select
                                    labelId="Условия хранения"
                                    label="Условия хранения"
                                    value={data.data?.storageCondition?.value}
                                    onChange={(e) =>
                                        handleChangeData(
                                            "storageCondition",
                                            e.target.value,
                                            "storage_conditions"
                                        )
                                    }
                                >
                                    {storageConditionValues.storageConditions.length !== 0 &&
                                        storageConditionValues.storageConditions.map((obj) => {
                                            return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>12. Таңбалау (маркировка)</p>
                            <TextField
                                id="outlined-basic"
                                label="Маркировка"
                                style={{width: "50%", marginLeft: "30px"}}
                                variant="outlined"
                                value={data.data?.mark?.value}
                                onChange={(e) =>
                                    handleChangeData("mark", e.target.value, "mark")
                                }
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "5px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                13. Үлгілерді консервациялау әдiстерi (Методы консервации
                                образца)
                            </p>
                            <FormControl style={{width: "50%", marginLeft: "30px"}}>
                                <InputLabel id="Методы консервации образца">
                                    Методы консервации образца
                                </InputLabel>
                                <Select
                                    labelId="Методы консервации образца"
                                    label="Методы консервации образца"
                                    value={data.data?.preservationMethod?.value}
                                    onChange={(e) =>
                                        handleChangeData(
                                            "preservationMethod",
                                            e.target.value,
                                            "preservationMethod"
                                        )
                                    }
                                >
                                    {samplePreservationMethodValues.samplePreservationMethods
                                            .length !== 0 &&
                                        samplePreservationMethodValues.samplePreservationMethods.map(
                                            (obj) => {
                                                return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                                            }
                                        )}
                                </Select>
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "30px",
                                alignItems: "center",
                            }}
                        >
                            <p style={{width: "50%"}}>
                                14. Қосымша мәліметтер (Дополнительные сведения)
                            </p>
                            <TextField
                                id="outlined-basic"
                                label="Дополнительные сведения"
                                style={{width: "50%", marginLeft: "30px"}}
                                variant="outlined"
                                value={data.data?.additionalInfo?.value}
                                onChange={(e) =>
                                    handleChangeData(
                                        "additionalInfo",
                                        e.target.value,
                                        "additionalInfo"
                                    )
                                }
                            />
                        </div>
                        <TableContainer
                            component={Paper}
                            style={{marginBottom: "30px", overflow: "hidden"}}
                        >
                            <Table aria-label="simple table" className="border-table-word">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="border-table-word">
                                            Тiркелу нөмірi (Регистрационный номер)
                                        </TableCell>
                                        <TableCell className="border-table-word">
                                            Алынған үлгілер тізімі (Перечень отобранных образцов)
                                        </TableCell>
                                        <TableCell className="border-table-word">
                                            Үлгінің көлемi (Объем образца)
                                        </TableCell>
                                        <TableCell className="border-table-word">
                                            Орама түрі мен мөр (пломба) нөмірі (Вид упаковки и номер
                                            печати (пломбы)
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.data?.samples?.value?.map((sample, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell className="border-table-word">
                                                    <TextField
                                                        style={{width: "100%"}}
                                                        id="outlined-basic"
                                                        label="Регистрационный номер"
                                                        variant="outlined"
                                                        type="number"
                                                        value={sample.registrationNumber?.value}
                                                        onChange={(e) =>
                                                            handleChangeSample(
                                                                "registrationNumber",
                                                                e.target.value,
                                                                i,
                                                                "registrationNumber"
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell className="border-table-word">
                                                    <TextField
                                                        style={{width: "100%"}}
                                                        id="outlined-basic"
                                                        label="Перечень отобранных образцов"
                                                        variant="outlined"
                                                        value={sample.sample?.value}
                                                        onChange={(e) =>
                                                            handleChangeSample(
                                                                "sample",
                                                                e.target.value,
                                                                i,
                                                                "sample"
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell className="border-table-word">
                                                    <TextField
                                                        style={{width: "100%"}}
                                                        id="outlined-basic"
                                                        label="Объем образца"
                                                        variant="outlined"
                                                        value={sample.size?.value}
                                                        onChange={(e) =>
                                                            handleChangeSample(
                                                                "size",
                                                                e.target.value,
                                                                i,
                                                                "size"
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell className="border-table-word">
                                                    <TextField
                                                        style={{width: "100%"}}
                                                        id="outlined-basic"
                                                        label="Вид упаковки и номер печати (пломбы)"
                                                        variant="outlined"
                                                        type="sealTypeAndNumber"
                                                        value={sample.sealTypeAndNumber?.value}
                                                        onChange={(e) =>
                                                            handleChangeSample(
                                                                "sealTypeAndNumber",
                                                                e.target.value,
                                                                i,
                                                                "sealTypeAndNumber"
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div
                            style={{
                                display: "flex",
                                marginBottom: "30px",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                style={{
                                    border: "1px solid black",
                                    padding: "5px 10px",
                                    borderRadius: "6px",
                                }}
                                variant="contained"
                                onClick={() => {
                                    setData({
                                        ...data,
                                        data: {
                                            ...data.data,
                                            samples: {
                                                key: "samples",
                                                value: data?.data?.samples?.value ? [...data?.data?.samples?.value, {}] : [{}],
                                            },
                                        },
                                    });
                                }}
                            >
                                Добавить
                            </Button>
                        </div>
                        <EmployeeField disabled
                                       style={{marginBottom: "5px"}}
                                       label={"Ответственный за отбор проб"}
                                       value={data.responsibleForProbesId}
                                       object={data.responsibleForProbes}
                                       onChange={(val) => {
                                           setData({
                                               ...data,
                                               responsibleForProbesId: val?.id,
                                               responsibleForProbes: val,
                                           });
                                       }}
                        />
                        <FilialSelect
                            style={{marginBottom: "5px"}}
                            label={"Филиал"}
                            fullWidth
                            value={data.filialId}
                            onChange={(val) => {
                                handleChangeMulti({
                                    filialId: val,
                                    filialDivisionId:
                                        Number(val) !== Number(data.filialId)
                                            ? null
                                            : data.filialDivisionId,
                                });
                            }}
                        />
                        <FilialDivisionSelect
                            style={{marginBottom: "5px"}}
                            label={"Районное отделение"}
                            fullWidth
                            value={data.filialDivisionId}
                            filialId={data.filialId}
                            onChange={(filialDivisionId, filialDivision) => {
                                handleChangeMulti({
                                    filialDivisionId: filialDivisionId,
                                    filialId:
                                        Number(filialDivision.filialId) !== Number(data.filialId)
                                            ? filialDivision.filialId
                                            : data.filialId,
                                });
                            }}
                        />
                        <Typography gutterBottom variant={"h5"}>
                            Передача проб в лабораторию:
                        </Typography>
                        <DepartmentSelect
                            style={{marginBottom: "5px"}}
                            label={"Лаборатория"}
                            fullWidth
                            value={data.departmentId}
                            onChange={(val) => handleChange("departmentId", val)}
                        />
                        <DivisionSelect
                            style={{marginBottom: "5px"}}
                            label={"Отдел"}
                            fullWidth
                            value={data.divisionId}
                            onChange={(val) => handleChange("divisionId", val)}
                            filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
                        />
                        <EmployeeField
                            label={"Заведующий лаборатории/Начальник отдела"}
                            fullWidth
                            extraFilter={
                                data.departmentId > 0
                                    ? {
                                        departmentId: {
                                            operand1: data.departmentId,
                                            operator: "equals",
                                        },
                                    }
                                    : null
                            }
                            object={data.responsible}
                            value={data.responsibleId}
                            onChange={(responsible) => {
                                const obj = {
                                    responsible,
                                    responsibleId: responsible.id,
                                    departmentId: responsible.departmentId,
                                };
                                handleChangeMulti(obj);
                            }}
                        />
                        <EmployeeField
                            label={"Лаборант"}
                            fullWidth
                            object={data.performer}
                            value={data.performerId}
                            disabled={isDisabled}
                            onChange={(performer) => {
                                const obj = {
                                    performer,
                                    performerId: performer.id,
                                };
                                handleChangeMulti(obj);
                            }}
                        />
                    </>
                    <LoadingButton onClick={handleSubmit} loading={loading}>
                        Создать акт
                    </LoadingButton>
                </Form>
            </BasicModalContainer>
        </>
    );
};
