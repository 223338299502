/* eslint-disable import/no-anonymous-default-export */
export default {
  '49': {
    version:2,
    name: 'ПРОТОКОЛ исследования радиоактивности объектов окружающей среды и отходов призводства',
    kazakhName: 'Қоршаған орта объектілерінің және өндіріс қалдықтарының радиобелсенділін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алу орны'
      },
      {
        id: 'testing_date',
        type: 'datetime',
        label: 'Дата проведення испытания',
        kazakhLabel: 'Зерттеу жүргізілген күн'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_done_with',
        type: 'text',
        label: 'Исследования проводились прибором',
        kazakhLabel: 'Зерттеу жүргізілген құрал'
      },
      {
        id: 'check_certificate',
        type: 'text',
        label: 'Свидетельство о поверке',
        kazakhLabel: 'Сәйкестігі туралы куәлік'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1094 style='border-collapse:
        collapse;table-layout:fixed;width:820pt'>
        <col width=64 style='width:48pt'>
        <col width=246 style='mso-width-source:userset;mso-width-alt:8732;width:184pt'>
        <col width=64 style='width:48pt'>
        <col width=528 style='mso-width-source:userset;mso-width-alt:18773;width:396pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=141 style='height:105.6pt'>
         <td height=141 class=xl1520758 width=64 style='height:105.6pt;width:48pt'></td>
         <td rowspan=2 class=xl7020758 width=246 style='width:184pt'>№</td>
         <td class=xl6920758 width=64 style='border-left:none;width:48pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl6920758 width=528 style='border-left:none;width:396pt'>Сынама алу
         н&#1199;ктесі</td>
         <td class=xl6920758 width=64 style='border-left:none;width:48pt'>Радионуклидттерді&#1187;
         тиімді белсенділігі (Бк/кг)</td>
         <td class=xl6920758 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl6320758 width=64 style='border-left:none;width:48pt'>&#1198;лемтік
         тиімді р&#1199;&#1179;сат етілген белсенділік де&#1187;гейі(Бк/кг)</td>
        </tr>
        <tr height=141 style='height:105.6pt'>
         <td height=141 class=xl1520758 style='height:105.6pt'></td>
         <td class=xl6820758 width=64 style='border-top:none;border-left:none;
         width:48pt'>Наименование образца</td>
         <td class=xl6820758 width=528 style='border-top:none;border-left:none;
         width:396pt'>Точка обора</td>
         <td class=xl6820758 width=64 style='border-top:none;border-left:none;
         width:48pt'>Удельная эфективная активность радионуклидов, Бк/кг</td>
         <td class=xl6820758 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6420758 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень удельной эфективной активности (Бк/кг)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1520758 style='height:15.0pt'></td>
         <td class=xl6520758 width=246 style='border-top:none;width:184pt'>1</td>
         <td class=xl6620758 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6620758 width=528 style='border-top:none;border-left:none;
         width:396pt'>3</td>
         <td class=xl6620758 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6620758 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6720758 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="36"><col width="115"><col width="126"><col width="155"><col width="94"><col width="118"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="36" height="191" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="126" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сынама алу нүктесі<br>Точка обора</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="155" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радионуклидттердің тиімді белсенділігі (Бк/кг)<br>Удельная эфективная активность радионуклидов, Бк/кг</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="94" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="118" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлемтік тиімді рүқсат етілген белсенділік деңгейі(Бк/кг)<br>Допустимый уровень удельной эфективной активности (Бк/кг)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Өлшеу нәтижелері'
      }
    ]
  },
  '50': {
    version:2,
    name: 'ПРОТОКОЛ исследование радиоактивности воды',
    kazakhName: 'Судың радиобелсенділгін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Материалдың, бұйымның атауы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'методисследования',
        kazakhLabel: 'Өлшеулер мақсаты'
      },
      {
        id: 'partion_volume',
        type: 'text',
        label: 'Объем партии, из которой отобран образец',
        kazakhLabel: 'Үлгі алынған партияның көлемi'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'sample_number',
        type: 'number',
        label: 'Количество образцов',
        kazakhLabel: 'Үгiлердiң саны'
      },
      {
        id: 'factory_number_type',
        type: 'text',
        label: 'наименование, тип, заводской номер',
        kazakhLabel: 'Өлшеу құралдары (Средства измерений) атауы, түрі, зауыттық нөмірі'
      },
      {
        id: 'government_check_info',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Сведения о государственной поверке'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер (Сведения о государственной поверке) берілген күні мен куәліктің нөмірі'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=906 style='border-collapse:
        collapse;table-layout:fixed;width:680pt'>
        <col width=64 style='width:48pt'>
        <col width=261 style='mso-width-source:userset;mso-width-alt:9272;width:196pt'>
        <col width=64 style='width:48pt'>
        <col width=325 style='mso-width-source:userset;mso-width-alt:11548;width:244pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=88 style='height:66.0pt'>
         <td height=88 class=xl1532269 width=64 style='height:66.0pt;width:48pt'></td>
         <td rowspan=2 class=xl6532269 width=261 style='width:196pt'>№</td>
         <td class=xl6532269 width=64 style='border-left:none;width:48pt'>Ингредиенттер
         к&#1257;рсеткіштеріні&#1187; атауы</td>
         <td class=xl6532269 width=325 style='border-left:none;width:244pt'>&#1256;лшем
         бірлігі</td>
         <td rowspan=2 class=xl6532269 width=64 style='width:48pt'>Аны&#1179;тал&#1171;ан
         м&#1241;ні Обнаруженное значение</td>
         <td class=xl6532269 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl6532269 width=64 style='border-left:none;width:48pt'>Р&#1201;&#1179;сат
         етілетін &#1179;&#1201;рамы</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1532269 style='height:79.2pt'></td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>Наименование показателей ингредиентов</td>
         <td class=xl6532269 width=325 style='border-top:none;border-left:none;
         width:244pt'>Единица измерения</td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимое содержание</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1532269 style='height:14.4pt'></td>
         <td class=xl6532269 width=261 style='border-top:none;width:196pt'>1</td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>3</td>
         <td class=xl6532269 width=325 style='border-top:none;border-left:none;
         width:244pt'>4</td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6532269 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="39"><col width="131"><col width="135"><col width="98"><col width="123"><col width="89"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="39" height="92" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">№</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="131" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Ингредиенттер көрсеткіштерінің атауы<br>Наименование показателей ингредиентов</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="135" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Өлшем бірлігі<br>Единица измерения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="98" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Анықталған мәні Обнаруженное значение</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="123" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="89" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Рұқсат етілетін құрамы<br>Допустимое содержание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Өлшеу нәтижелері'
      }
    ]
  },
  '51': {
    version:2,
    name: 'ПРОТОКОЛ дозиметрического контроля рабочих мест',
    kazakhName: 'Жұмыс орындарын дозиметриялық бақылау ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объект атауы, мекенжайы',
        number: '1'
      },
      {
        id: 'measurement_place',
        type: 'text',
        label: 'Место проведения замеров',
        kazakhLabel: 'Өлшеулер жүргізілетін орын',
        number: '2'
      },
      {
        id: 'otdel_ceh',
        type: 'text',
        label: 'отдел, цех',
        kazakhLabel: 'бөлім, цех,',
        number: ''
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя обследуемого объекта',
        kazakhLabel: 'Өлшеулер тексерілетін объект өкілінің қатысуымен жүргізілді',
        number: '3'
      },
      {
        id: 'inventorization_etc',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеулер мақсаты',
        number: '4'
      },
      {
        id: 'measurement_method',
        type: 'text',
        label: 'метод измерения',
        kazakhLabel: 'Өлшеулер әдісі',
        number: '5'
      },
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средства измерений',
        kazakhLabel: 'Өлшеу құралдары',
        number: '6'
      },
      {
        id: 'name',
        type: 'text',
        label: 'наименование',
        kazakhLabel: 'атауы',
        number: ''
      },
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер',
        number: '7'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения об условиях измерения',
        kazakhLabel: 'Өлшеу шартатары туралы қосымша деректер',
        number: '8'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi',
        number: '9'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=846 style='border-collapse:
        collapse;table-layout:fixed;width:635pt'>
        <col width=64 style='width:48pt'>
        <col width=254 style='mso-width-source:userset;mso-width-alt:9045;width:191pt'>
        <col width=64 style='width:48pt'>
        <col width=208 style='mso-width-source:userset;mso-width-alt:7395;width:156pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=123 style='height:92.4pt'>
         <td height=123 class=xl1525101 width=64 style='height:92.4pt;width:48pt'></td>
         <td rowspan=2 class=xl6325101 width=254 style='width:191pt'>№ п/п</td>
         <td class=xl6325101 width=64 style='border-left:none;width:48pt'>&#1256;лшеулер
         ж&#1199;ргізілген орын</td>
         <td class=xl6325101 width=208 style='border-left:none;width:156pt'>С&#1241;улелену
         т&#1199;рлері</td>
         <td class=xl6325101 width=64 style='border-left:none;width:48pt'>&#1256;лшеу
         н&#1199;ктесі (жо&#1171;ар&#1171;ы бетінен ара &#1179;ашы&#1179;ты&#1179;,
         м;см)</td>
         <td class=xl6325101 width=64 style='border-left:none;width:48pt'>&#1256;лшенген
         дозасы(мкЗв/ч, част/(см2*мин),</td>
         <td class=xl6325101 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl6325101 width=64 style='border-left:none;width:48pt'>Р&#1201;ксат
         етілетін де&#1187;гейі</td>
        </tr>
        <tr height=158 style='height:118.8pt'>
         <td height=158 class=xl1525101 style='height:118.8pt'></td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>Место проведения измерений</td>
         <td class=xl6325101 width=208 style='border-top:none;border-left:none;
         width:156pt'>Виды излучения</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>Точка измерения(расстояния от измеряемой поверхности, м; см)</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>Измеренная доза(мкЗв/ч, част/(см2*мин),</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимый уровень</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1525101 style='height:14.4pt'></td>
         <td class=xl6325101 width=254 style='border-top:none;width:191pt'>1</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl6325101 width=208 style='border-top:none;border-left:none;
         width:156pt'>3</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6325101 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="51"><col width="131"><col width="123"><col width="187"><col width="107"><col width="120"><col width="97"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="51" height="92" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">№ п/п</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="131" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Өлшеулер жүргізілген орын<br>Место проведения измерений</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="123" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Сәулелену түрлері<br>Виды излучения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="187" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Өлшеу нүктесі (жоғарғы бетінен ара қашықтық, м;см)<br>Точка измерения(расстояния от измеряемой поверхности, м; см)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Өлшенген дозасы(мкЗв/ч, част/(см2*мин),<br>Измеренная доза(мкЗв/ч, част/(см2*мин)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="120" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="97" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Рұксат етілетін деңгейі<br>Допустимый уровень</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Өлшеу нәтижелері'
      }
    ]
  },
  '52': {
    version:2,
    name: 'ПРОТОКОЛ дозиметрического контроля',
    kazakhName: 'Дозиметриялық бақылау ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объект атауы, мекенжайы'
      },
      {
        id: 'measurement_places',
        type: 'text',
        label: 'Место проведения замеров (отдел, цех, квартал)',
        kazakhLabel: 'Өлшеулер жүргізілген орын (бөлім, цех, квартал)'
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеулер мақсаты'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя обследуемого объекта',
        kazakhLabel: 'Өлшеулер тексерілетін объект өкілінің қатысуымен жүргізілді'
      },      
      // {
      //   id: 'measurement_tool',
      //   type: 'text',
      //   label: 'Средства измерений',
      //   kazakhLabel: 'Өлшеу құралдары'
      // },
      {
        id: 'name_type_factory_number',
        type: 'text',
        label: 'наименование, тип, заводской номер',
        kazakhLabel: 'Өлшеулер құралдары атауы, түрі, зауыттық нөмірі (Средства измерений)'
      },      
      // {
      //   id: 'government_check',
      //   type: 'text',
      //   label: 'Сведения о государственной поверке',
      //   kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер'
      // },
      {
        id: 'certificate_date_number',
        type: 'text',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'Тексеру туралы мәліметтер (Сведения о поверке) берілген күні мен куәліктің нөмірі'
      },
      {
        id: 'measurement_results',
        type: 'text',
        label: 'Дополнительные сведения об условиях измерения',
        kazakhLabel: 'Өлшеу шарттары туралы қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1172 style='border-collapse:
        collapse;table-layout:fixed;width:879pt'>
        <col width=64 style='width:48pt'>
        <col width=252 style='mso-width-source:userset;mso-width-alt:8960;width:189pt'>
        <col width=117 style='mso-width-source:userset;mso-width-alt:4152;width:88pt'>
        <col width=355 style='mso-width-source:userset;mso-width-alt:12629;width:266pt'>
        <col width=64 span=6 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1526578 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl6926578 width=252 style='width:189pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl7126578 width=117 style='border-left:none;width:88pt'>&#1256;лшеу
         ж&#1199;ргізілген орын</td>
         <td colspan=2 class=xl7126578 width=419 style='border-left:none;width:314pt'>Дозаны&#1187;
         &#1257;лшенген &#1179;уаты(мкЗв/час, н/сек)</td>
         <td class=xl7126578 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td colspan=4 class=xl7126578 width=256 style='border-right:1.0pt solid black;
         border-left:none;width:192pt'>Дозаны&#1187; р&#1201;&#1179;сат етілетін
         &#1179;уаты (мкЗв/час, н/сек)</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1526578 style='height:52.8pt'></td>
         <td class=xl7026578 width=252 style='border-top:none;width:189pt'>Регистрационный
         номер</td>
         <td class=xl6726578 width=117 style='border-top:none;border-left:none;
         width:88pt'>Место проведения измерений</td>
         <td colspan=2 class=xl6726578 width=419 style='border-left:none;width:314pt'>Измеренная
         мощность дозы(мкЗв/час, н/сек)</td>
         <td class=xl6726578 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td colspan=4 class=xl6726578 width=256 style='border-right:1.0pt solid black;
         border-left:none;width:192pt'>Допустимая мощность дозы (мкЗв/час, н/сек)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1526578 style='height:14.4pt'></td>
         <td class=xl7226578 width=252 style='border-top:none;width:189pt'>&nbsp;</td>
         <td class=xl6826578 width=117 style='border-top:none;border-left:none;
         width:88pt'>&nbsp;</td>
         <td colspan=2 class=xl6726578 width=419 style='border-left:none;width:314pt'>Еденнен
         жо&#1171;ары (топыра&#1179;тан)</td>
         <td rowspan=3 class=xl7526578 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
         <td colspan=2 rowspan=2 class=xl7526578 width=128 style='width:96pt'>&nbsp;</td>
         <td rowspan=2 class=xl7526578 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
         <td rowspan=2 class=xl7326578 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1526578 style='height:14.4pt'></td>
         <td class=xl7226578 width=252 style='border-top:none;width:189pt'>&nbsp;</td>
         <td class=xl6826578 width=117 style='border-top:none;border-left:none;
         width:88pt'>&nbsp;</td>
         <td colspan=2 class=xl6726578 width=419 style='border-left:none;width:314pt'>На
         высоте от пола (грунта)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1526578 style='height:14.4pt'></td>
         <td class=xl7226578 width=252 style='border-top:none;width:189pt'>&nbsp;</td>
         <td class=xl6826578 width=117 style='border-top:none;border-left:none;
         width:88pt'>&nbsp;</td>
         <td class=xl6726578 width=355 style='border-top:none;border-left:none;
         width:266pt'>1,5м</td>
         <td class=xl6726578 width=64 style='border-top:none;border-left:none;
         width:48pt'>1м</td>
         <td class=xl6726578 width=64 style='border-top:none;border-left:none;
         width:48pt'>0,1м</td>
         <td class=xl6726578 width=64 style='border-top:none;border-left:none;
         width:48pt'>1,5м</td>
         <td class=xl6726578 width=64 style='border-top:none;border-left:none;
         width:48pt'>1м</td>
         <td class=xl6326578 width=64 style='border-top:none;border-left:none;
         width:48pt'>0,1м</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1526578 style='height:15.0pt'></td>
         <td class=xl6426578 width=252 style='border-top:none;width:189pt'>1</td>
         <td class=xl6526578 width=117 style='border-top:none;border-left:none;
         width:88pt'>2</td>
         <td class=xl6526578 width=355 style='border-top:none;border-left:none;
         width:266pt'>3</td>
         <td class=xl6526578 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6526578 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6526578 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6526578 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
         <td class=xl6526578 width=64 style='border-top:none;border-left:none;
         width:48pt'>8</td>
         <td class=xl6626578 width=64 style='border-top:none;border-left:none;
         width:48pt'>9</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="9" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="119"><col width="140"><col width="108"><col width="65"><col width="123"><col width="65"><col width="65"><col width="59"><col width="40"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="3" width="119" height="152" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Тіркеу нөмірі<br>Регистрационный номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="140" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Өлшеу жүргізілген орын<br>Место проведения измерений</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="173" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Дозаның өлшенген қуаты(мкЗв/час, н/сек)<br>Измеренная мощность дозы(мкЗв/час, н/сек)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="123" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="4" rowspan="2" width="228" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Дозаның рұқсат етілетін қуаты (мкЗв/час, н/сек)<br>Допустимая мощность дозы (мкЗв/час, н/сек)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Еденнен жоғары (топырақтан)<br>На высоте от пола (грунта)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1,5м</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1м</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">0,1м</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1,5м</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1м</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">0,1м</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="19" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">9</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      }
    ]
  },
  '53': {
    version:2,
    name: 'ПРОТОКОЛ исследований радиоактивности атмосферного воздуха и осадков',
    kazakhName: 'Атмосфералық ауаның және жауын-шашынның радиобелсенділгін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объект атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'метод исследования',
        kazakhLabel: 'Зерттеу әдісі ('
      },
      {
        id: 'air_volume',
        type: 'text',
        label: 'Объем прокаченного воздуха/осадков взятой на исследование',
        kazakhLabel: 'Сұрып алынған ауаның көлемi/алынған тұнбаны зерттеу'
      },
      {
        id: 'time',
        type: 'text',
        label: 'Время',
        kazakhLabel: 'Уақыт'
      },
      {
        id: 'measurement_methods',
        type: 'text',
        label: 'Средства измерений, наименование',
        kazakhLabel: 'Өлшеу құралдары атауы'
      },
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер'
      },
      {
        id: 'measurement_results',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1061 style='border-collapse:
        collapse;table-layout:fixed;width:795pt'>
        <col width=64 style='width:48pt'>
        <col width=263 style='mso-width-source:userset;mso-width-alt:9358;width:197pt'>
        <col width=116 style='mso-width-source:userset;mso-width-alt:4124;width:87pt'>
        <col width=362 style='mso-width-source:userset;mso-width-alt:12856;width:271pt'>
        <col width=64 span=4 style='width:48pt'>
        <tr height=176 style='height:132.0pt'>
         <td height=176 class=xl1528182 width=64 style='height:132.0pt;width:48pt'></td>
         <td class=xl6328182 width=263 style='width:197pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl6328182 width=116 style='border-left:none;width:87pt'>&#1198;лгіні&#1187;
         атауы</td>
         <td class=xl6328182 width=362 style='border-left:none;width:271pt'>К&#1257;рсеткіштерді&#1187;
         атауы</td>
         <td class=xl6328182 width=64 style='border-left:none;width:48pt'>&#1256;лшем
         бірлігі</td>
         <td class=xl6328182 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl6328182 width=64 style='border-left:none;width:48pt'>Ай&#1179;ындал&#1171;ан
         концентрация/ Радионуклидтерді&#1187; &#1199;лестік тиімді белсенділік, Бк/кг</td>
         <td class=xl6328182 width=64 style='border-left:none;width:48pt'>Р&#1201;&#1179;сат
         ететін &#1179;&#1201;рамы</td>
        </tr>
        <tr height=211 style='height:158.4pt'>
         <td height=211 class=xl1528182 style='height:158.4pt'></td>
         <td class=xl6328182 width=263 style='border-top:none;width:197pt'>Регистрационный
         Номер</td>
         <td class=xl6328182 width=116 style='border-top:none;border-left:none;
         width:87pt'>Наименование образца</td>
         <td class=xl6328182 width=362 style='border-top:none;border-left:none;
         width:271pt'>Наименование показателей</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>Единица измерения</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>Обнаруженная концентрация/ Удельная эффективная активность
         радионуклидов, Бк/кг</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>Допустимое содержание</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1528182 style='height:14.4pt'></td>
         <td class=xl6328182 width=263 style='border-top:none;width:197pt'>1</td>
         <td class=xl6328182 width=116 style='border-top:none;border-left:none;
         width:87pt'>2</td>
         <td class=xl6328182 width=362 style='border-top:none;border-left:none;
         width:271pt'>3</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl6328182 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
        </tr>
       </table>`,
       label: 'Результаты измерений',
       kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="158"><col width="120"><col width="175"><col width="95"><col width="121"><col width="131"><col width="102"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="158" height="279" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>Регистрационный номер</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="120" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің атауы<br>Наименование образца</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="175" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштердің атауы<br>Наименование показателей</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="95" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшем бірлігі<br>Единица измерения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="121" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің <br>НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="131" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Айқындалған концентрация/ Радионуклидтердің үлестік тиімді белсенділік, Бк/кг<br>Обнаруженная концентрация/ Удельная эффективная активность радионуклидов, Бк/кг</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="102" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат ететін құрамы<br>Допустимое содержание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
      kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) \ufffd\ufffdҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      }
    ]
  },
  '54': {
    name: 'ПРОТОКОЛ исследований на висцеральный и кожный лейшманиоз',
    kazakhName: 'Висцеральды және тері лейшманиозына зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование и число образцов',
        kazakhLabel: 'Нұсқаның атауы және саны'
      },
      {
        id: 'sample_directed',
        type: 'text',
        label: 'Наименование учреждения направившего образец',
        kazakhLabel: 'Сынаманы жіберген мекеменің атау'
      },
      {
        id: 'sample_place',
        type: 'text',
        label: 'Место, дата и время отбора',
        kazakhLabel: 'Сынама алынған орын, уақыты, мезгілі'
      },
      {
        id: 'sample_delivery_conditions',
        type: 'text',
        label: 'Время и условия доставки проб в лабораторию',
        kazakhLabel: 'Сынамаларды зертханаға жеткізу уақыты және шарттары'
      },
      {
        id: 'research_purpose',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'patient_fullName',
        type: 'text',
        label: '(Ф.И.О. (при наличии) обследуемого)',
        kazakhLabel: 'Тексерілушінің тегі, аты, әкесінің аты (болған жағдайда)'
      },
      {
        id: 'patient_yearsOld',
        type: 'text',
        label: '(Возраст)',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'patient_identity',
        type: 'text',
        label: '(ИИН обследуемого)',
        kazakhLabel: 'Зерттелушінің ЖСН'
      },
      {
        id: 'patient_address',
        type: 'text',
        label: '(Домашний адрес)',
        kazakhLabel: 'Мекен жайы'
      },
      {
        id: 'patient_workPlace',
        type: 'text',
        label: '(Место работы)',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        id: 'patient_diagnosis',
        type: 'text',
        label: '(Диагноз)',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: '(метод исследования)',
        kazakhLabel: 'Зерттеу әдісі'
      },
      {
        id: 'normative_document2',
        type: 'text',
        label: '(НД на метод отбора)',
        kazakhLabel: 'Үлгі алу әдiсiне НҚ'
      },
      {
        id: 'research_result',
        type: 'text',
        label: '(Результат исследования)',
        kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) \ufffd\ufffdҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      }
    ]
  },
  '56': {
    name: 'ПРОТОКОЛ дозиметрических и радиометрических измерений при работе с радиоактивными веществами в открытом виде',
    kazakhName: 'Ашық түрдегі радиобелсенді заттармен жұмыс кезіндегі дозиметриялық және радиометриялық өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress_name',
        type: 'text',
        label: 'Наименование объекта,адрес',
        kazakhLabel: 'Объект атауы, мекенжайы'
      },
      {
        id: 'measurement_place',
        type: 'text',
        label: 'Место проведения измерений',
        kazakhLabel: 'Өлшеулер жүргізілген орын'
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеулер мақсаты ('
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проведены в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объект өкілінің қатысуымен жүргізілді'
      },
      {
        id: 'name_type_factory_number',
        type: 'text',
        label: 'наименование, тип, заводской номер',
        kazakhLabel: 'Өлшеу құралдары (Средства измерений) атауы, түрі, зауыттың нөмірі'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер (Сведения о государственной поверке) берілген күні мен куәліктің нөмірі'
      },
      {
        id: 'control_dots_schema',
        type: 'text',
        label: 'Схема расположения источников и контрольных точек измерения',
        kazakhLabel: 'Көздердің және өлшенетін бақылау н.ктелерінің орналасу схемасы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование образца проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      }
    ]
  }
}
