import { serviceGroupCRUD } from "../../http";
import { 
    SERVICE_GROUP_GET_REQUEST, SERVICE_GROUP_GET_SUCCESS, SERVICE_GROUP_GET_ERROR, 
    SERVICE_GROUP_GET_BY_KEY_REQUEST, SERVICE_GROUP_GET_BY_KEY_SUCCESS, SERVICE_GROUP_GET_BY_KEY_ERROR, 
    SERVICE_GROUP_ADD_REQUEST, SERVICE_GROUP_ADD_SUCCESS, SERVICE_GROUP_ADD_ERROR, 
    SERVICE_GROUP_EDIT_REQUEST, SERVICE_GROUP_EDIT_SUCCESS, SERVICE_GROUP_EDIT_ERROR, 
    SERVICE_GROUP_DELETE_REQUEST, SERVICE_GROUP_DELETE_SUCCESS, SERVICE_GROUP_DELETE_ERROR
} from "../constants/serviceGroupConstants";

export const getServiceGroups = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GROUP_GET_REQUEST
        });

        const data = await serviceGroupCRUD.search(args);
        dispatch({
            type: SERVICE_GROUP_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_GROUP_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceGroupByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GROUP_GET_BY_KEY_REQUEST
        });

        const data = await serviceGroupCRUD.get(id);
        dispatch({
            type: SERVICE_GROUP_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: SERVICE_GROUP_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addServiceGroup = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GROUP_ADD_REQUEST
        });

        const response = await serviceGroupCRUD.create(data);
        await dispatch(getServiceGroups(args));
        dispatch({
            type: SERVICE_GROUP_ADD_SUCCESS,
        });
        return response.id;
    } catch (error) {
        dispatch({
            type: SERVICE_GROUP_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editServiceGroup = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GROUP_EDIT_REQUEST
        });

        const response = await serviceGroupCRUD.edit(data);
        await dispatch(getServiceGroups(args));
        dispatch({
            type: SERVICE_GROUP_EDIT_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: SERVICE_GROUP_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteServiceGroup = (id, args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GROUP_DELETE_REQUEST
        });

        const response = await serviceGroupCRUD.delete(id);
        await dispatch(getServiceGroups(args));
        dispatch({
            type: SERVICE_GROUP_DELETE_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: SERVICE_GROUP_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

