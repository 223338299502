import React, {useCallback, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Box,
  Button,
  FormGroup,
  InputLabel,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton, TimePicker } from "@mui/lab";

import {
  getContractRequestByKey,
  getContractByKey,
  getContractRequests,
  getWithoutDepartureRequestByKey,
} from "../../../../redux/actions";
import {
  PageHeader,
  PageContainer,
  Form,
  FormSection,
  CustomDatePicker,
  BasicTable,
  ConditionalContainer,
  ButtonModal,
  CustomTimePicker,
} from "../../../../components";

import {
  ReferralRequestCreateModal,
  ActWaterCreateModal,
  ActDisinfectantCreateModal,
  ActCreateModal,
} from "../../..";
import { RequestStateChecker } from "../../../../functions";
import EmployeeField from "../../../../components/Relations/EmployeeField";

export const WithoutDepartureRequestInfoSection = ({ request, setRequest }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [requestId, setRequestId] = useState(id);

  const handleChangeObject = useCallback(
      (objectKey, value) => {
        setRequest({ ...request, [objectKey]: value, [`${objectKey}Id`]: value.id });
      },
      [request]
  );

  const { withoutDepartureRequest } = useSelector(
    (s) => s.withoutDepartureRequest
  );

  const stateCode = request?.state?.code?.toLowerCase();
  const state = new RequestStateChecker(stateCode);

  const [specifications, setSpecifications] = useState([]);
  const [objects, setObjects] = useState([]);
  const [acts, setActs] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(getWithoutDepartureRequestByKey(requestId)).then(setRequest);
  }, [dispatch, requestId]);

  useEffect(() => {
    console.log({ withoutDepartureRequest });

    setRequest(withoutDepartureRequest);
    setObjects(withoutDepartureRequest.objects);
    setSpecifications(withoutDepartureRequest.specifications);
    setActs(withoutDepartureRequest.acts);
  }, [withoutDepartureRequest]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Номер заявки"
                  type="number"
                  value={request?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={request?.contractRequestId}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  type="text"
                  value={request?.createdBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      label="Дата заявки"
                      value={request?.createdAt}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Объекты ({objects?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    { id: "name", label: t("object.tableItem.name") },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={objects}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Услуги ({specifications?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "group",
                      label: 'Группа',
                      render: ({ service }) => service?.group?.name,
                    },
                    {
                      id: "method",
                      label: 'Метод',
                      render: ({ service }) => service?.kind?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                    },
                    {
                      id: "contractCount",
                      label: t("specification.tableItem.quantityContract"),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={specifications}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Филиал"
                  value={request?.filial?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Районный отдел"
                  value={request?.filialDivision?.name}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Лаборатория"
                  value={request?.department?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Отдел лаборатории"
                  value={request?.division?.name}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection heading="Назначение ответственных" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EmployeeField
                    label={"Зав. лаборатории"}
                    object={request?.responsible}
                    value={request?.responsible?.id}
                    onChange={e => handleChangeObject('responsible', e)}
                />
              </Grid>
              <Grid item xs={12}>
                <EmployeeField
                    label={"Исполнитель"}
                    object={request?.performer}
                    value={request?.performer?.id}
                    onChange={e => handleChangeObject('performer', e)}
                />
              </Grid>
            </Grid>
          </FormSection>

          <ConditionalContainer
            condition={
              state.isProcessing() || state.isResearch() || state.isFinished()
            }
          >
            <Divider style={{ margin: "50px 0px" }} />
            <FormSection heading={`Акты (${request?.acts?.length || 0})`}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("act.tableItem.id") },
                      { id: "number", label: t("act.tableItem.number") },
                      {
                        id: "createdAt",
                        label: t("act.tableItem.createdAt"),
                      },
                    ]}
                    rows={acts}
                    button={
                      <Grid container columnSpacing={2}>
                        <Grid item>
                          <ButtonModal modal={ActCreateModal}>
                            Добавить акт
                          </ButtonModal>
                        </Grid>
                      </Grid>
                    }
                    disabled={!state.isProcessing()}
                  />
                </Grid>
              </Grid>
            </FormSection>
          </ConditionalContainer>
        </PageContainer>
      </Form>
    </>
  );
};
