
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { dashboardCRUD } from "../../http";
import { yearEnd, yearStart } from "../../functions/dateHelpers";
import FilterDatePicker from "../../components/DatePicker/FilterDatePiecker";
import { Button, Stack } from "@mui/material";
import { Divider } from "antd";
import { LoadingButton } from "@mui/lab";
import {sum} from "../../functions/linqSimulation";
import { roundTo2 } from "../../functions/formatters";

const RequestTarificationsQuantityIndices4 = () => {
    const start = yearStart();
    const end = yearEnd();
    
    const [query, setQuery] = useState({
        paging: { skip: 0 },
        filter: {
            researchEndDateTime: {
                operand1: start,
                operand2: end,
                operator: "between"
            }
        }
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAsync = async () => {
        setLoading(true);        

        const response = await dashboardCRUD.post('requestTarifications/quantity/indices/table4', query);
        setData(response);

        setLoading(false);
    }


    return (
        <DashboardLayout>
            <Stack direction="row" alignItems="center" justifyContent="normal">
                <label style={{minWidth:"360px"}}>Дезинфекция, дезинсекция, дератизация по государственному заказу за ПЕРИОД</label>
                <FilterDatePicker key="start" label="от" value={query.filter.researchEndDateTime.operand1} handleFunction={(value) =>
                {
                    if (!value) {
                        return;
                    }
                    query.filter.researchEndDateTime.operand1 = value;
                    setQuery({...query});
                }} style={{width:"250px"}} />
                <FilterDatePicker key="start" label="до" value={query.filter.researchEndDateTime.operand2} handleFunction={(value) =>
                {
                    if (!value) {
                        return;
                    }
                    query.filter.researchEndDateTime.operand2 = value;
                    setQuery({...query});
                }} style={{width:"250px", marginLeft: "10px"}} />
                <LoadingButton loading={loading} style={{marginLeft: "10px"}} onClick={() => fetchAsync()}>
                    Сформировать
                </LoadingButton>                  
            </Stack>

            <div style={{height: "10px"}}></div>
            
            <table style={{width: "100%"}}>
                <thead className="ant-table-thead">
                    <tr>
                        <th rowSpan={2} className="ant-table-cell">

                        </th>
                        <th rowSpan={2} style={{verticalAlign:"top"}} className="ant-table-cell">
                            Договора c ДСЭК  (Регионы)
                        </th>
                        <th colSpan={4} style={{verticalAlign:"top"}} className="ant-table-cell">
                        Дезинфекция
                        </th>
                        <th colSpan={4} className="ant-table-cell">
                        Дезинсекция
                        </th>
                        <th colSpan={4} style={{verticalAlign:"top"}} className="ant-table-cell">
                        Дератизация
                        </th>
                    </tr>
                    <tr>                                                
                        <th>
                        план
                        </th>
                        <th>
                        факт
                        </th>
                        <th>
                        %
                        </th>
                        <th>
                        не соответствие
                        </th>
                        <th>
                        план
                        </th>
                        <th>
                        факт
                        </th>
                        <th>
                        %
                        </th>
                        <th>
                        не соответствие
                        </th>
                        <th>
                        план
                        </th>
                        <th>
                        факт
                        </th>
                        <th>
                        %
                        </th>
                        <th>
                        не соответствие
                        </th>
                    </tr>
                </thead>
                <tbody className="ant-table-tbody">
                    {data && data.map((_, index) => {
                     
                        return <tr className="ant-table-row">
                            <td className="ant-table-cell">{index + 1}</td>
                            <td className="ant-table-cell">{_.name}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(_.quantity1)}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(_.inconsistenceQuantity1)}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(_.quantity2)}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(_.inconsistenceQuantity2)}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(_.quantity3)}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(_.inconsistenceQuantity3)}</td>
                        </tr>})
                    }
                    {data.length > 0 && <tr className="ant-table-row" style={{backgroundColor:"#3cb37188"}}>
                        <td></td>
                        <td>Всего:</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(sum(data,_ => _.quantity1))}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(sum(data,_ => _.inconsistenceQuantity1))}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(sum(data,_ => _.quantity2))}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(sum(data,_ => _.inconsistenceQuantity2))}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(sum(data,_ => _.quantity3))}</td>
                            <td className="ant-table-cell">0</td>
                            <td className="ant-table-cell">{roundTo2(sum(data,_ => _.inconsistenceQuantity3))}</td>
                    </tr>}
                </tbody>
            </table>
        </DashboardLayout>
    )

};

export default RequestTarificationsQuantityIndices4;
