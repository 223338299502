import * as Yup from 'yup';

export const prescriptionSchema = Yup.object().shape({
    number: Yup.string('Это не строка').required('Номер нужно указывать'),
    responsibleFullName: Yup.string('Это не строка').required('Должностное лицо санитарно-эпидемиологической службы ( Ф.И.О.) должно быть заполнено'),
    // acts: Yup.array()
    //     .min(1, 'Список актов обязателен к заполнению'),
    indicators: Yup.array()
        .min(1, 'Показатели обязательны к заполнению'),
});
