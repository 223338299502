import {getActStates} from "../../redux/actions/actStateActions";
import {getFilials} from "../../redux/actions";
import {getRequestStates} from "../../redux/actions/requestStateActions";

export const brigadeSearchFields = [
    {
        title: "Номер",
        key: "number",
        compareType: "like",
        operandPosition:1,
        filterType:"normal"
    }
];

export const brigadeFilterFields = [
    {
        title: "Cтатус",
        key: "stateId",
        compareType: "equals",
        reducerKey: "requestState",
        reducerArrayKey: "requestStates",
        reducerAction: getRequestStates,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Филиал",
        key: "filialId",
        compareType: "equals",
        reducerKey: "filial",
        reducerArrayKey: "filials",
        reducerAction: getFilials,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
]
