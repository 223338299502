import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { asJSON2, asJSONSafe, fetchAsBase64, fetchAsString } from "../../../functions";
import forms from "../ProtocolForms/forms";
import { toUIDate } from "../../../functions/dateHelpers";
import QRCode from "qrcode";
import { generateLink, renderPageNumeration, renderPageNumerationAndWatermark } from "../../../functions/renderProtocol";
import htmlConverter from 'html2json'
import { mapProtocolFieldNumber } from "../../../functions/viewDataMap";
import { useDispatch } from "react-redux";
import { getProtocolFormByKey } from "../../../redux/actions/protocolFormActions";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import optionalLogo from '../PDFGenerator/logos/common/ilac_mra.svg';
import { svgToPng } from "../../../functions/svgToPng";
import { injectVfsTimesNewRoman } from "../../../functions/pdfMake/timesNewRomanFonts";
import { normalizeHtml } from "../../../functions/html";
import { protocolModelFormatField } from "../../../models/protocolModel";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
injectVfsTimesNewRoman(pdfMake.vfs);

// pdfMake.fonts = {
//     // All 4 components must be defined
//     TimesNewRoman: {
//         normal: 'Times-New-Roman-Regular.ttf',
//         bold: 'Times-New-Roman-Bold.ttf',
//         italics: 'Times-New-Roman-Italic.ttf',
//         bolditalics: 'Times-New-Roman-BoldItalic.ttf'
//     }
// };

var htmlToPdfmake = require("html-to-pdfmake");

const renderEmployeePosition = (employee) => {
    let result = "";

    if (!employee?.position)
        return result;

    if (employee.position.nameKz && employee.position.name)
        return `${employee.position.nameKz} (${employee.position.name})`;

    return employee.position.name;
}

const renderEmployeeName = (employee) => {
    let result = "";

    if (!employee)
        return result;

    return employee.fullName;
}


export const ProtocolPdf = ({ open, handleClose, number, logo, date, protocol, clientPdf, setClientPdf, addOptionalLogo, protocolData, formId, protocolSigners, forceLandscape }) => {
    const formatTime = Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }).format
    const [data, setData] = useState(null);
    const [dataUrl, setDataUrl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [signers, setSigners] = useState([]);
    const [optionalLogoUrl, setOptionalLogoUrl] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [splitted, setSplitted] = useState(false);
    const dispatch = useDispatch();

    useEffect(async () => {
        if (!optionalLogo) return;

        await fetchAsBase64(optionalLogo, (_) => {
            svgToPng(_, 95, 103, png => {
                setOptionalLogoUrl(png);
            });
        });
    }, [optionalLogo, addOptionalLogo]);

    useEffect(async () => {
        if (!logo) return;

        await fetchAsBase64(logo, (_) => {
            svgToPng(_, 95, 103, png => {
                setLogoUrl(png);
            });
        });
    }, [logo]);


    useEffect(() => {
        if (formId === undefined) return
        dispatch(getProtocolFormByKey(formId)).then((obj) => {
            const number = obj?.number;
            const theForm = forms[number];
            setSplitted(theForm?.splitted || false);

            setSigners(Object.fromEntries(['research_specialist', 'lab_director', 'organization_leader'].map((signerTitle, i) => {
                const titleID = ['performer', 'responsible', 'leader'][i]
                const employeeID = protocol[titleID]?.id
                if (employeeID == null)
                    return null;

                const isSigned = protocolSigners.find(signer => signer.employeeId === employeeID)?.isSigned
                return [signerTitle, isSigned]
            }).filter(_ => _)));
        });
    }, [formId])


    const fetch = async () => {
        setLoading(true);
        const form = forms[protocol.form?.number];

        const fields = form?.fields || [];
        const version = form?.version;


        // if (tableFields.length == 0) {
        //     return "";
        // }
        //  const response = await QRCode.toDataURL(url);
        const data = asJSONSafe(protocol.data);
        let qrLink = null;

        let link = generateLink(protocol.id, protocol.guid);
        if (link) {
            qrLink = await QRCode.toDataURL(link.url)
        }


        const content = [];

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const label = field.label;
            const id = field.id;
            if (field.type == "table") {
                if (field.version == 2) {
                    try {
                        let html = htmlConverter.json2html(data[id]);
                    html = normalizeHtml(html);
                    console.log('html2:' + html);
                    const e = htmlToPdfmake(html);
                    content.push([e])
                        html = html.replaceAll(`<font face="Times New Roman" size="3"`, `<font face="Times New Roman" size="1"`)
                        console.log(html);
                        while (html.indexOf('  ') > 0)
                        html = html.replaceAll('  ', ' ');
                    html = html.replaceAll('; ', ';');
                    html = html.replaceAll(': ', ':');

                    html = html.replaceAll(`<font face="Times New Roman" size="3"`, `<font face="Times New Roman" size="1"` ); 
                    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;;`, `` )
                    // //font-family: &quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;      
                    //html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;mso-fareast-font-family:&quot;Times New Roman&quot;`, `` )
                    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif`, ``);
                    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;mso-bidi-font-family:Mangal;`, ``)
                    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,serif;`, ``)
                    html = html.replaceAll(`font:7.0pt &quot;Times New Roman&quot;`, ``);
                    html = html.replaceAll(`font-family:&quot;Times New Roman&quot;,&quot;serif&quot;`, ``)  
                    html = html.replaceAll(';mso-fareast-font-family:TimesNewRomanPSMT', '');
                    html = html.replaceAll(' class="MsoTableGrid"', '');
                    html = html.replaceAll(' class="MsoNormal"', '');
                    html = html.replaceAll('class="MsoNormalTable"', '');                        
                    html = html.replaceAll(';mso-ansi-language:KZ', '');
                    html = html.replaceAll(' class="MsoNormalCxSpMiddle"', '');
                    html = html.replaceAll(';mso-bidi-theme-font:minor-bidi', '');
                    html = html.replaceAll('font-family:&quot;inherit&quot;', ``);
                    html = html.replaceAll(' class="MsoNoSpacing"', '');
                    html = html.replaceAll('<p>​</p>', '');                    
                        content.push([htmlToPdfmake(html)])
                    } catch (e) {
                        content.push([e.toString()]);
                        console.error(e);
                    }
                }
                // console.log(asJSONSafe(data[id]));
                //      const html = htmlConverter.json2html(value2);
            } else {
                const value = protocolModelFormatField(field, data[field.id], field.type);
                //mapProtocolFieldNumber(field.number, i)
                //mapProtocolFieldNumber(field.number, i) + field.kazakhLabel + "(" + field.label + "):
                content.push([`${mapProtocolFieldNumber(field.number, i)} ${field.kazakhLabel} (${label}): ${value || ""}`]);
            }
        }


        //      content.push([`Результат: ${data?.complied_with_regulatory_doc || data?.research_result || data?.research_results || ''}`]);

        const images = [];

        if (optionalLogoUrl && addOptionalLogo) {
            images.push({ image: optionalLogoUrl, width: 70, height: 70 });
        } else
            images.push('');
        if (logoUrl) {
            images.push({ image: logoUrl, width: 65, height: 70, marginLeft: addOptionalLogo ? 10 : 0 });
        } else
            images.push('');

        const imagesCell = addOptionalLogo && optionalLogoUrl ? {
            marginLeft: 5,
            layout: 'noBorders',
            table: {
                widths: [70, 70],
                body: [
                    //[{ image: optionalLogoUrl, width: 50, height: 50 }, { image: logo, width: 50, height: 50 }, 'TEST']
                    [...images]

                ]
            }
        } : {
            marginLeft: 5,
            layout: 'noBorders',
            table: {
                widths: [0, 70],
                body: [
                    //[{ image: optionalLogoUrl, width: 50, height: 50 }, { image: logo, width: 50, height: 50 }, 'TEST']
                    [...images]
                ]
            }
        };

        const docDefinition = {
            watermark: { angle: -70, text: 'Қазақстан Республикасы Денсаулық сақтау министрлігі Санитариялық-эпидемиологиялық бақылау комитетінің «Ұлттық сараптама орталығы» шаруашылық жүргізу құқығындағы республикалық мемлекеттік кәсіпорны', color: 'gray', opacity: 0.3, bold: true, italics: false, fontSize: 20 },
            info: {
                title: 'Протокол',
                author: 'NCE',
                subject: 'Result'
            },
            pageOrientation: splitted || forceLandscape ? 'landscape' : 'portrait',
            pageSize: 'A4',
            pageMargins: [56, 28, 28, 28],
            defaultStyle: {
                font: 'TimesNewRoman',
            },
            //pageMargins: [56, 28, 28, 28],            
            // pageOrientation: 'landscape',
            // pageSize: 'A4',
            // pageMargins: [56, 28, 28, 28],
            content:
                [
                    {
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            widths: ['*', '*'],
                            body: [
                                [imagesCell, 'Нысанның БҚСЖ бойынша коды\nКод формы по ОКУД ___________________\nКҰЖЖ бойынша ұйым коды\nКод организации по ОКПО _____________'],
                                ['Қазақстан Республикасының Денсаулық сақтау министрлігі\nМинистерство здравоохранения Республики Казахстан', `Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы 20 тамызынан №84 бұйрығымен Бекітілген №${protocol.form?.number} нысанды медициналық құжаттама`],
                                [`Қазақстан Республикасы Денсаулық сақтау министрлігі ${protocol?.filial?.nameKz || ""}\n\n${protocol?.filial?.name}`,
                                    `Медицинская документация Форма №${protocol.form?.number}\nУтверждена приказом Министра здравоохранения\nРеспублики Казахстан от 20 августа 2021 года №84`],
                            ]
                        }
                    },                    
                    { text: `${form?.kazakhName + '\n' + form?.name}`, fontSize: 14, alignment: 'center', marginTop: 10, bold: true },
                    {
                        text: `№${protocol.number} ${Intl.DateTimeFormat('ru-RU', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric'
                        }).format(new Date(protocol?.createdAt))} ж. (г.)`, fontSize: 12, alignment: 'center', marginTop: 10, marginBottom: 10
                    },
                    {
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            //dontBreakRows: true,
                            widths: ['*'],
                            body: content
                        },
                    },
                    {
                        layout: 'noBorders',
                        marginTop: 10,
                        table: {
                            dontBreakRows: true,
                            widths: ['*', '*', '*'],
                            body: [
                                [
                                    `${renderEmployeePosition(protocolData?.research_specialist)}`,
                                    signers.research_specialist ? 'Қол қойылды(Подписано)' : 'Қолы (Подпись)',
                                    `${renderEmployeeName(protocolData?.research_specialist)}`
                                ],
                                [
                                    `${renderEmployeePosition(protocolData?.lab_director)}`,
                                    signers.lab_director ? 'Қол қойылды(Подписано)' : 'Қолы (Подпись)',
                                    `${renderEmployeeName(protocolData?.lab_director)}`
                                ],
                                [
                                    `${renderEmployeePosition(protocolData?.organization_leader)}`,
                                    signers.organization_leader ? 'Қол қойылды(Подписано)' : 'Қолы (Подпись)',
                                    `${renderEmployeeName(protocolData?.organization_leader)}`
                                ]
                            ],
                        }
                    },
                    {
                        text: `
                    Хаттама __ данада толтырылды (Протокол составлен в ___ экземплярах)
                    Хаттама берілген күні (Дата выдачи протокола) ${Intl.DateTimeFormat('ru-RU', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric'
                    }).format(protocolData.dateOfIssue) + " ж. (г.)"}
                    Парақтар саны (Количество страниц)
                    Сынау нәтижелері тек қана сыналуға жататын үлгілерге қолданылады
                    (Результаты исследования распространяются только на образцы, подвергнутые испытаниям)
                    Рұқсатсыз хаттаманы жартылай қайта басуға ТЫЙЫМ САЛЫНҒАН
                    (Частичная перепечатка протокола без разрешения ЗАПРЕЩЕНА)
                    Санитариялық дәрігердің немесе гигиенист дәрігердің зерттелген өнімдердің,химиялық заттардың, физикалық және радиациялық факторлардың үлгілері/сынамалары туралы қорытындысы
                    (Заключение санитарного врача или врача-гигиениста по образцам/пробам исследуемой продукции, химических веществ, физических и радиационных факторов):
                    ____________________________________________________________________ ____________________________________________________________________
                    `, fontSize: 11, alignment: 'left', marginTop: 10, bold: true
                    },

                    { image: qrLink },
                ],
            styles: {
                header: {
                    alignment: 'center',
                    margin: [0, 5],
                    fontSize: 11,
                    bold: true
                },
                bigHeader: {
                    alignment: 'center',
                    margin: [0, 0, 0, 10],
                    fontSize: 16,
                    bold: true
                },
                table: {
                    fontSize: 5,
                    margin: [0, 0, 10, 0],
                    border: '5px'
                },
                td: {
                    size: 1
                },
                text: {
                    fontSize: 9,
                    margin: [0, 0, 0, 0],
                },
            },        
        };

        pdfMake.fonts = {
            // All 4 components must be defined
            TimesNewRoman: {
                normal: 'Times-Regular.ttf',
                bold: 'Times-Bold.ttf',
                italics: 'Times-Italic.ttf',
                bolditalics: 'Times-BoldItalic.ttf'
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-MediumItalic.ttf'
            }
        };
        // pdfMake.fonts.TimesNewRoman = {
        //     normal: 'Times-New-Roman-Regular.ttf',
        //     bold: 'Times-New-Roman-Bold.ttf',
        //     italics: 'Times-New-Roman-Italic.ttf',
        //     bolditalics: 'Times-New-Roman-BoldItalic.ttf'
        // };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBase64((result) => {
            setClientPdf(result);
        });
        // pdfDocGenerator.download(_ => {
        //
        // });
        pdfDocGenerator.getDataUrl((dataUrl) => {
            // const blob = b64toBlob(dataUrl, 'application/pdf');
            // const blobUrl = URL.createObjectURL(blob);

            // window.location = blobUrl;
            setDataUrl(dataUrl);
        });
        setLoading(false);
    }



    useEffect(() => {
        fetch();
    }, [logoUrl, optionalLogoUrl, addOptionalLogo, forceLandscape]);


    return (
        !loading
            ? <iframe src={dataUrl} style={{ width: '100%', minHeight: '900px' }}></iframe>
            : <CircularProgress size={200} />

    )
}
///