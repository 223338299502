export const TRANSLATIONS_RU = {
  dashboard: {
    administration: "Администрирование",
    reportAndAnalytics: "Отчеты и аналитика",
    listItem: {
        users: "Пользователи",
        roles: "Роли",
        employees: "Сотрудники",
        filials: "Филиалы",
      formMap:"Сопоставление форм",
        filialDivisions: "Районные отделения",
        departments: "Лаборатории",
        divisions: "Отделения",
      serviceList: "Исследования",
      serviceListClient: "Исследования для заказчиков",
      referralFormList: "Формы направления",
      referralList: "Единоразовые заявки",
      objectReferralList: "Заявки по госзаказу",
      contractRequest: "Сводные заявки",
      request1: "Заявка на КДИ",
      request2: "Заявка без выезда",
      request3: "Заявка на исследование без АКТ",
      request4: "График отбора проб",
      request5: "Заявка на ДДД",
      request6: "Заявка по самоконтролю",
      actWater: "Акт отбора образцов воды",
      actBioTest: "Акт отбора био тестов",
      actBuyProducts: "Акт покупки продукции (товара)",
      actBacteriologicalTest: "Акт отбора бактериологических тестов",
      actWaste: "Акт отбора смывов",
      actSampling: "Акт отбора проб",
      actLand: "Акт отбора образцов почвы",
      actRadio: "Акт отбора образцов на радиоактивность",
      actProducts: "Акт отбора пищевых продуктов",
      actDisinfectant: "АКТ отбора проб дезинфицирующих средств",
      actZoonotic:
        "Акт отбора проб внешней среды на зоонозные и особо опасные инфекции",
      actTicks: "Акт отбора клещей",
      directionVirus: "Направление на вирусологические исследование",
      directionDisinfectionChamber: "Направление в дезинфекционную камеру",
      directionBlood:
        "Направление на исследование крови на маркеры вирусных гепатитов",
      directionSampling:
        "Направление на исследование образцов проб на вирусологическое исследование",
      directionMaterial:
        "Направление материала (от обследуемого, больного) для исследования на корь, краснуху",
      directionParasite:
        "Направление на санитарно-паразитологическое исследование",
      directionMicroBio:
        "Направление материала от людей на микробиологические исследования",
      directionPeople: "Направление материала от людей на исследования",
      directionCholera:
        "Направление и результат исследования материала на наличие возбудителя холеры",
      detailDisinsection: "Наряд на дезинсекцию, дератизацию",
      detailFinalDisinfection: "Наряд на заключительную дезинфекцию",
      receiptForThings: "Квитанция на вещи",
      referenceDisinfection: "Справка о проведении дезинфекции по наряду",
      regulation: "Предписания",
      prescription: "Постановления",
      tariffication: "Тарификатор",
      plan: "Планы",
      clinicalRequestList: "Заявки на КДИ",
      departureScheduleRequestList: "График отбора проб",
      desinsectionRequestList: "Заявки в отдел ДДД",
      serviceWithoutActRequestList: "Заявка на исследование без акта",
      withoutDepartureRequestList: "Заявка без выезда",
      brigadeList: "Наряд",
      desinfectionReferenceList: "Справка по дезинфекции",
      resultFormList: "Формы журнала",
      resultList: "Журналы",
      resultListAll: "Все журналы",
      resultListPaid: "Журналы (платные)",
      resultListOR: "Журналы (госзаказ)",
      protocolFormList: "Формы протокола",
      protocolList: "Протоколы",
      protocolListAll: "Все протоколы",
      protocolListPaid: "Протоколы (платные)",
      protocolListOR: "Протоколы (госзаказ)",
      patientList: "Пациенты",
      serviceKindList: "Виды услуг",
      servicePriceList: "Прейскурант",
      serviceExpenseList: "Расходы",
      contractSpecificationList: "Приложение",
      requestList: "Потоки работ",
      dashboard: "Дашборд",
      reagentList: "Реагенты",
      currencyList: "Валюты",
      genderList: "Пол ФЛ",
      governmentStandardList: "ГОСТ",
      medicalOrderList: "Приказы",
      positionList: "Должности",
      purposeOfSelectionList: "Место отбора образца",
      referralAdditionalInfoList: "Дополнительная информация",
      referralOrderTypeList: "Типы заказа",
      samplePreservationMethodList: "Методы консервации образца",
      samplingPurposeList: "SamplingPurposeList",
      serviceScheduleList: "График",
      storageConditionList: "Условия хранения",
      technicalRegulationsOfCustomsUnionList: "ТР ТС",
      transportationConditionList: "Условия транспортировки",
      transportTypeList: "Вид транспорта",
      unitOfMeasureList: "Ед измерения",
      chemicalIndicesList: "Химические показатели",
      diseaseList: "Диагнозы",
      infectionKindList: "Виды инфекции",
      sicknessKindList: "Виды заболеваний",
      anObjectSectionList: "Раздел объекта",
      anObjectSubSectionList: "Подраздел объекта",
      requestLockList: "Блокировка тарификатора",
      organizationFilialMapList: "Отображение филиалов организаций",
      accreditationList: "Знак аккредитации",
      exit: 'Выход',
      diseaseIndex: 'Показатели',
      summary: "Отчет по показателям тарификатора",
      serviceMapList: "Сопоставление исследований",
      summaryIndices: "Корь, ОРВИ и Грипп в рамках госзаказа",
      summaryIndices2: "Мониторинг объектов контроля и окружающей среды",
      summaryIndices3: "Мониторинг ГМО, ДНК и ЖКС",
      summaryIndices4: "Дезинфекция, дезинсекция и дератизация",
      summaryDepartments: "Планирование и исполнение исследований госзаказа по лабораториям",
      serviceGroupList: "Группы"
},
    requests: "Заявки по сводной",
    acts: "Акты (платные)",
    actsDSEK: "Акты (ксэк)",
    dictionaries: "Справочники",
    directions: "Направления",
    ddd: "Дезинфекция,дезинсекция,дератизация",
    ksec: "КСЭК МЗ РК",

    mode: {
      read: "Просмотр",
      edit: "Редактирование",
    },
    state: {
      created: "Создано",
      processing: "В обработке",
      research: "На исследовании",
      completed: "Завершено",
      declined: "Отменено",
      negotiation: "На согласовании"
    },
  },
    filialList:{
        tableItem:{
            id: '№',
            name:"Название филиала",
            address: "Адресс",
            filialDivision:"Районное отделение",
            supervisor: "Руководитель",
          bin:"BIN"
        },
        searchLabel: 'Найти Сотрудника...',
        detailsText: 'Просмотр',
    },
  departmentList:{
    tableItem:{
      id: '№',
      name:"Название лаборатории",
      description:"Описание",
      completeWithoutProtocol: "Завершение без протокола",
      isDeleted: "Удален"
    },
    searchLabel: 'Найти Лабораторию...',
    detailsText: 'Просмотр',
  },

  divisionList:{
    tableItem:{
      id: '№',
      name:"Название отделения",
      description:"Описание",
      department: "Лаборатория",
      isDeleted: "Удален"
    },
    searchLabel: 'Найти Лабораторию...',
    detailsText: 'Просмотр',
  },
  filialDivisionList:{
    tableItem:{
      id: '№',
      name:"Название",
      address: "Адресс",
      filialDivision:"Районное отделение",
      supervisor: "Руководитель"
    },
    searchLabel: 'Найти Сотрудника...',
    detailsText: 'Просмотр',
  },
  formMap:{
    tableItem:{
      id: '№',
      department:"Лаборатория",
      protocolForm: "Форма протокола",
      brigadeType:"Вид наряда",
      resultForm:"Форма журнала",
      actForm:"Форма акта",
      probeType:"Тип пробы"
    },
    searchLabel: 'Найти Form Map...',
    detailsText: 'Просмотр',
  },

    employeeList:{
        tableItem: {
            id: '№',
            name:"ФИО",
            filial: 'Филиал',
            filialDivision:"Районное отделение",
            department: 'Лаборатория',
            position:"Должность",
            division:"Отдел"
        },
        searchLabel: 'Найти Филиал...',
        detailsText: 'Просмотр',
    },
  serviceList: {
    tableItem: {
      id: "№",
      name: "Наименование",
      code: "Код",
      price: "Цена",
      referralSchema: "Направление",
      probeSchema: "Проба",
      resultSchema: "Результат",
      parent: "Родительская",
      kind: "Метод",
      type: "Тип",
      unit: "Ед.измерения",
      group: "Группа",
      subGroup: "Подгруппа",
      actions: "Действия",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  request: {
    tableItem: {
      id: "№",
      contractId: "Номер договора",
      performer: "Исполнитель",
      filial: "Филиал",
      filialDivision: "Районное отделение",
      number: "Номер",
      createdAt: "Дата создания",
      createdBy: "Автор",
      status: "Статус",
      state: "Статус",
      name: "Наименование",
      department: "Лаборатория",
      responsiblePerformer: "Лаборант",
      reponsiblePerformer: "Лаборант",
      division: "Отдел лаборатории",
      specialist: "Специалист",
      departmentHead: "Зав. лабораторией",
      responsible: "Зав. лабораторией",
      departureDate: "Дата прибытия",
      dateOfDeparture: "Дата выезда",
      actions: "Действия",
      contractRequest: "Сводная заявка",
      contractRequestId: "Сводная заявка",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  contractRequest: {
    tableItem: {
      id: "№",
      contractId: "Номер договора",
      author: "Автор",
      performer: "Исполнитель",
      createdAt: "Дата создания",
      status: "Статус",
      name: "Наименование",
      code: "Код",
      price: "Цена",
      referralSchema: "Направление",
      probeSchema: "Проба",
      resultSchema: "Результат",
      parent: "Родительская",
      kind: "Метод",
      type: "Тип",
      unit: "Ед.измерения",
      group: "Группа",
      subGroup: "Подгруппа",
      actions: "Действия",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  act: {
    tableItem: {
      id: "№",
      number: "Номер акта",
      createdAt: "Дата создания",
    },
  },

  contract: {
    tableItem: {
      id: "№",
      number: "№",
      contractId: "Номер договора",
      createdAt: "Дата создания",
      actionAt: "Дата создания",
      author: "Автор",
      performer: "Исполнитель",
      status: "Статус",
      name: "Наименование",
      code: "Код",
      state: "Статус",
      price: "Цена",
      client: "Клиент",
      filial: "Филиал",
      referralSchema: "Направление",
      probeSchema: "Проба",
      resultSchema: "Результат",
      parent: "Родительская",
      kind: "Метод",
      summary: "Сумма",
      type: "Тип",
      unit: "Ед.измерения",
      group: "Группа",
      subGroup: "Подгруппа",
      actions: "Действия",
    },
  },

  brigade: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Дата создания",
      state: "Статус",
      addressLine: "Адрес",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  result: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Дата создания",
      state: "Статус",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  department: {
    tableItem: {
      id: "№",
      number: "Номер",
      name: "Наименование",
      createdAt: "Дата создания",
      state: "Статус",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  filial: {
    tableItem: {
      id: "№",
      number: "Номер",
      name: "Наименование",
      createdAt: "Дата создания",
      state: "Статус",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  disinfectionReference: {
    tableItem: {
      id: "№",
      number: "Номер",
      fullName: "Имя",
      addressLine: "Адрес",
      createdAt: "Дата создания",
      state: "Статус",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  educationService: {
    tableItem: {
      id: "№",
      name: "Наименование",
      kind: "Вид обучения",
      service: "Форма обучения",
      createdAt: "Дата создания",
      status: "Статус",
    },
    searchLabel: "Найти Service...",
    detailsText: "Просмотр",
  },
  referralFormList: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Создано",
      schema: "Форма",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти ReferralForm...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  referralList: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Создано",
      createdBy: "Автор",
      schema: "Форма",
      data: "Данные",
      state: "Статус",
      service: "Услуга",
      type: "Тип",
      performer:"Исполнитель",
      contragent: "Исполнитель",
      contract: "Контракт",
      object: "Объект",
      actions: "Действия",
    },
    searchLabel: "Найти Referral...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },

  specification: {
    tableItem: {
      id: "№",
      name: "Наименование",
      packet: "Пакет",
      object: "Объект",
      serviceKind: "Вид услуги",
      service: "Наименование услуги",
      schedule: "График",
      quantity: "Количество",
      quantityContract: "Количество по договору",
      price: "Цена",
      unit: "Единица измерения",
      createdAt: "Создано",
      state: "Статус",
      actions: "Действия",
    },
  },
  division: {
    tableItem: {
      id: "№",
      name: "Наименование",
      createdAt: "Создано",
      state: "Статус",
      actions: "Действия",
    },
  },

  object: {
    tableItem: {
      id: "№",
      name: "Наименование",
      locality: "Область",
      district: "Район",
      city: "Населенный пункт",
      address: "Адрес",
      addressLine: "Адрес",
      createdAt: "Создано",
    },
    searchLabel: "Найти AnObjectReferral...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },

  objectReferralList: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Создано",
      schema: "Форма",
      data: "Данные",
      state: "Статус",
      service: "Услуга",
      contract: "Контракт",
      object: "Объект",
      actions: "Действия",
    },
    searchLabel: "Найти AnObjectReferral...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  clinicalRequestList: {
    tableItem: {
      id: "ID",
      number: "Номер",
      createdAt: "Создано",
      contractRequest: "Сводная заявка",
      state: "Статус",
      referral: "Направление",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти ClinicalRequest...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  departureScheduleRequestList: {
    tableItem: {
      id: "ID",
      createdAt: "Создано",
      name: "Наименование объекта",
      addressLine: "Адрес",
      dateOfDeparture: "Дата выезда",
      authorComment: "Комментарий автора",
      performerComment: "Комментарий исполнителя",
      contractRequest: "Сводная заявка",
      state: "Статус",
      actions: "Действия",
    },
    searchLabel: "Найти DepartureScheduleRequest...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  desinsectionRequestList: {
    tableItem: {
      id: "ID",
      number: "Номер",
      createdAt: "Создано",
      departureDate: "Дата выезда",
      contractRequest: "Сводная заявка",
      state: "Статус",
      departmentHead: "Заведующий лаборатории/Начальник отдела",
      specialist: "Специалист ДДД",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти DesinsectionRequest...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  serviceWithoutActRequestList: {
    tableItem: {
      id: "ID",
      number: "Номер",
      createdAt: "Создано",
      contractRequest: "Сводная заявка",
      state: "Статус",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти ServiceWithoutActRequest...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  withoutDepartureRequestList: {
    tableItem: {
      id: "ID",
      number: "Номер",
      createdAt: "Создано",
      contractRequest: "Сводная заявка",
      state: "Статус",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти WithoutDepartureRequest...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  brigadeList: {
    tableItem: {
      id: "ID",
      number: "Номер",
      createdAt: "Создано",
      addressLine: "Адрес",
      schema: "Форма",
      data: "Данные",
      address: "Адрес",
      disinfector: "Дезинфектор",
      type: "Вид наряда",
      actions: "Действия",
    },
    searchLabel: "Найти Brigade...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  desinfectionReferenceList: {
    tableItem: {
      id: "ID",
      number: "Номер",
      createdAt: "Создано",
      fullName: "ФИО",
      addressLine: "Адрес",
      age: "Возраст",
      address: "Адрес",
      disinfector: "Дезинфектор",
      actions: "Действия",
    },
    searchLabel: "Найти DesinfectionReference...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  resultFormList: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Создано",
      schema: "Форма",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти ResultForm...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  resultList: {
    tableItem: {
      id: "№",
      createdAt: "Создано",
      schema: "Форма",
      data: "Данные",
      state: "Статус",
      service: "Услуга",
      referral: "Направление",
      actions: "Действия",
    },
    searchLabel: "Найти Result...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
    createByReferralText: "Добавить запись журнала",
  },
  protocolFormList: {
    tableItem: {
      id: "№",
      number: "Номер",
      createdAt: "Создано",
      schema: "Форма",
      filial: "Филиал",
      actions: "Действия",
    },
    searchLabel: "Найти ProtocolForm...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  protocolList: {
    tableItem: {
      id: "№",
      number: "Штрих-код",
      state: "Статус",
      createdAt: "Создано",
      form: "Форма",
      referral: "Направление",
      result: "Журнал",
      filial: "Филиал",
      filialDivision: "Районнное отделение",
      department: "Лаборатория",
      division: "Отдел",
      responsible: "Заведующий лабораторией",
      performer: "Исполнитель (лаборант)",
      actions: "Действия",
    },
    searchLabel: "Найти Protocol...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  patientList: {
    tableItem: {
      id: "ID",
      firstName: "Имя",
      lastName: "Фамилия",
      secondName: "Отчество",
      fullName: "ФИО",
      identityNumber: "ИИН",
      birthDate: "Дата рождения",
      yearsOld: "Возраст",
      phoneNumber: "Телефон",
      rpnId: "ID системы РПН",
      gender: "Пол",
      actions: "Действия",
    },
    searchLabel: "Найти Patient...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  serviceKindList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти ServiceKind...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  servicePriceList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      price: "Стоимость",
      service: "Услуга",
      unit: "Единица измерения",
      currency: "Валюта",
      actions: "Действия",
    },
    searchLabel: "Найти ServicePriceList...",
    detailsText: "Просмотр",
  },
  serviceExpenseList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      summary: "Сумма",
      service: "Услуга",
      reagent: "Реагент",
      actions: "Действия",
    },
    searchLabel: "Найти ServiceExpense...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  contractSpecificationList: {
    tableItem: {
      id: "ID",
      price: "Стоимость",
      quantity: "Количество",
      contract: "Контракт",
      service: "Услуга",
      serviceKind: "Вид услуги",
      unit: "Ед. измерения",
      servicePriceList: "Прейскурант",
      actions: "Действия",
    },
    searchLabel: "Найти ContractSpecification...",
    detailsText: "Просмотр",
  },
  reagentList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      description: "Описание",
      price: "Стоимость",
      actions: "Действия",
    },
    searchLabel: "Найти Reagent...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  currencyList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти Currency...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  genderList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти Gender...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  governmentStandardList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти GovernmentStandard...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  medicalOrderList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти MedicalOrder...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  positionList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти Position...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  purposeOfSelectionList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти PurposeOfSelection...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  referralAdditionalInfoList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти ReferralAdditionalInfo...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  referralOrderTypeList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти ReferralOrderType...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  samplePreservationMethodList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти SamplePreservationMethod...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  samplingPurposeList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти SamplingPurpose...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  serviceScheduleList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти ServiceSchedule...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  storageConditionList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти StorageCondition...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  technicalRegulationsOfCustomsUnionList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти TechnicalRegulationsOfCustomsUnion...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  transportationConditionList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти TransportationCondition...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  transportTypeList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти TransportType...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  unitOfMeasureList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти UnitOfMeasure...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  chemicalIndicesList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти ChemicalIndices...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  diseaseList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти Disease...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  infectionKindList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти InfectionKind...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  sicknessKindList: {
    tableItem: {
      id: "ID",
      name: "Наименование",
      code: "Код",
      description: "Описание",
      actions: "Действия",
    },
    searchLabel: "Найти SicknessKind...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  requestList: {
    tableItem: {
      id: `ID`,
      createdAt: "Дата создания",
      actions: "Действия",
      type: `Тип`,
      state: {
        name: `Статус`,
      },
      performer: "Исполнитель",
    },
  },

  employee: {
    tableItem: {
      id: "№",
      fullName: "Имя",
      position: "Должность",
      filial: "Филиал",
      division: "Районный отдел",
      department: "Лаборатория",
      createdAt: "Создано",
      actions: "Действия",
    },
    searchLabel: "Найти ResultForm...",
    createText: "Добавить",
    detailsText: "Просмотр",
    editText: "Изменить",
    removeText: "Удалить",
  },
  requestLockList: {
    tableItem: {
        id: 'ID',
        time: 'Месяц/Дата от',
        untilTime: 'Дата до',
        isLocked: 'Заблокирован',
        filial: 'Филиал',
        actions: 'Actions'
    },
    filter: {
        IdFilter: 'ID',
        TimeFilter: 'Месяц',
    },
    searchLabel: 'Find RequestLock...',
    createText: 'Добавить',
    detailsText: 'Просмотр',
    editText: 'Изменить',
    removeText: 'Удалить',
  },
  organizationFilialMapList: {
    tableItem: {
        id: 'ID',
        sourceFilial: 'Филиал отобразить c',
        targetFilial: 'Филиал отобразить на',
        actions: 'Действия'
    },
    filter: {
        IdFilter: 'ID',
    },
    searchLabel: 'Найти...',
    createText: 'Добавить',
    detailsText: 'Просмотр',
    editText: 'Изменить',
    removeText: 'Удалить',
  },
  serviceMapList: {
    tableItem: {
        id: 'ID',
        object: 'Объект исследования',
        group: 'Группа',
        subGroup: 'Вид исследования',
        kind: 'Метод',
        researchStandard: 'НД на метод исследования',
        governmentStandard: 'НД на метод отбора',
        regulationOfCU: 'Документ санитарно-эпидемиологического нормирования',
        actions: 'Действия'
    },
    filter: {
        IdFilter: 'ID',
    },
    searchLabel: 'Найти ServiceMap...',
    createText: 'Добавить',
    detailsText: 'Просмотр',
    editText: 'Изменить',
    removeText: 'Удалить',
},
};
