import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import {
    PurposeOfSelectionsSelect,
} from "../../../components/Select/domainSelects";
import AnObjectField from "../../../components/Relations/AnObjectField";

const ActRadioForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample, addSample, deleteSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
          <AnObjectField
              bold={false}
              label={"1. Объектінің атауы (Наименование объекта)"}
              value={data.data?.object?.value}
              disabled={isDisabledData}
              onChange={(obj) => {
                  handleChangeData('object', obj, 'facility')
              }}
          />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Тексеру белгіленгені туралы актінің нөмірі (Номер акта о назначении проверки)
        </p>
        <TextField
          id="outlined-basic"
          label="Номер акта"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          type="number"
          value={data.data?.number?.value}
          onChange={(e) => handleChangeData('number', e.target.value, 'number')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Тағайындау актісі күні (Дата акта о назначении проверки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата акта"
            disabled={isDisabledData}
            value={data.data?.date?.value}
            onChange={(value) => {
              handleChangeData('date', value, 'date')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          4. Сынама үлгісі алынған орын (Место отбора образца)
        </p>
        <TextField
          id="outlined-basic"
          label="Место отбора"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.selectionPlace?.value}
          onChange={(e) => handleChangeData('selectionPlace', e.target.value, 'selection_place')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5. Сынамалар алу мақсаты (Цель отбора):
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <PurposeOfSelectionsSelect
            label={"Сынамалар алу мақсаты (Цель отбора)"}
            fullWidth
            value={data.data?.samplingPurpose?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('samplingPurpose', obj, 'samplingPurpose') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5.1 Наименования нормативных документов
        </p>
        <TextField
          id="outlined-basic"
          label="Наименования нормативных документов"
          style={{ width: "50%",  margin: "10px 0 10px 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.documentsName?.value}
          onChange={(e) =>
            handleChangeData(
              "documentsName",
              e.target.value,
              "documentsName"
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Сынама алынған партияның көлемi (Объем партии, из которой отобран образец)
        </p>
        <TextField
          id="outlined-basic"
          label="Объем партии, из которой отобран образец"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.sampleSize?.value}
          onChange={(e) => handleChangeData('sampleSize', e.target.value, 'sampleSize')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Жергiлiктi табиғи радиациялық аяның деңгейi (Уровень естественного радиационного фона на местности)
        </p>
        <TextField
          id="outlined-basic"
          label="Уровень естественного радиационного фона на местности"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.radiationLevel?.value}
          onChange={(e) => handleChangeData('radiationLevel', e.target.value, 'radiationLevel')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Сынама алынған партияның бетiндегi радиациялық аяның деңгейi (мөлшер қуаты) (Уровень радиационного фона (мощность дозы) на поверхности партии, от которой отобран образец)
        </p>
        <TextField
          id="outlined-basic"
          label="Уровень радиационного фона на поверхности партии"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.sampleRadiationLevel?.value}
          onChange={(e) => handleChangeData('sampleRadiationLevel', e.target.value, 'sampleRadiationLevel')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Гамма-сәулелену мөлшерінің қуаттылығы бойынша партияның бiртектiлiгi (Однородность партии по мощности дозы гамма-излучения)
        </p>
        <TextField
          id="outlined-basic"
          label="Однородность партии по мощности дозы гамма-излучения"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.sampleUniformity?.value}
          onChange={(e) => handleChangeData('sampleUniformity', e.target.value, 'sampleUniformity')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          10. Қосымша мәліметтер (Дополнительные сведения)
        </p>
        <TextField
          id="outlined-basic"
          label="Дополнительные сведения"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.additionalInfo?.value}
          onChange={(e) => handleChangeData('additionalInfo', e.target.value, 'additionalInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          11. ФИО присутствующего при отборе
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО присутствующего при отборе"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.representativeFullName?.value}
          onChange={(e) => handleChangeData('representativeFullName', e.target.value, 'representativeFullName')}
        />
      </div>
      <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
        <Table
          aria-label="simple table"
          className="border-table-word"
        >
          <TableHead>
            <TableRow>
              <TableCell className="border-table-word">
                Тiркелу нөмірi (Регистрационный номер)
              </TableCell>
              <TableCell className="border-table-word">
                Алынған үлгілер тізімі (Перечень отобранных образцов)
              </TableCell>
              <TableCell className="border-table-word">
                Алынған сынамалар саны (Количество отобранных образцов)
              </TableCell>
              <TableCell className="border-table-word">
                Сынаманың салмағы, көлемі (Вес, объем образца)
              </TableCell>
              <TableCell className="border-table-word">
                Орама түрі мен мөр (пломба) нөмірі (Вид упаковки и номер печати (пломбы)
              </TableCell>
              <TableCell className="border-table-word">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.samples?.value?.map((sample, i) => {
              return <TableRow key={i}>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Регистрационный номер"
                    variant="outlined"
                    type="number"
                    disabled={isDisabledData}
                    value={sample.registrationNumber?.value}
                    onChange={(e) => handleChangeSample('registrationNumber', e.target.value, i, 'registrationNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Перечень отобранных образцов"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.sample?.value}
                    onChange={(e) => handleChangeSample('sample', e.target.value, i, 'sample')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Количество отобранных образцов"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.quantity?.value}
                    onChange={(e) => handleChangeSample('quantity', e.target.value, i, 'quantity')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Вес, объём образца"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.weightSize?.value}
                    onChange={(e) => handleChangeSample('weightSize', e.target.value, i, 'weightSize')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Вид упаковки и номер печати (пломбы)"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.sealTypeAndNumber?.value}
                    onChange={(e) => handleChangeSample('sealTypeAndNumber', e.target.value, i, 'sealTypeAndNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <IconButton color="error"
                      size="large"
                      aria-label="delete"
                      disabled={isDisabledData}
                      onClick={() => { deleteSample(i) }}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isDisabledData}
          variant="contained"
          onClick={addSample}
        >
          Добавить
        </Button>
      </div>
    </div >
  );
};

export default ActRadioForm;
