export const PROTOCOL_GET_REQUEST = 'PROTOCOL_GET_REQUEST';
export const PROTOCOL_GET_SUCCESS = 'PROTOCOL_GET_SUCCESS';
export const PROTOCOL_GET_ERROR = 'PROTOCOL_GET_ERROR';
export const PROTOCOL_GET_BY_KEY_REQUEST = 'PROTOCOL_GET_BY_KEY_REQUEST';
export const PROTOCOL_GET_BY_KEY_SUCCESS = 'PROTOCOL_GET_BY_KEY_SUCCESS';
export const PROTOCOL_GET_BY_KEY_ERROR = 'PROTOCOL_GET_BY_KEY_ERROR';
export const PROTOCOL_ADD_REQUEST = 'PROTOCOL_ADD_REQUEST';
export const PROTOCOL_ADD_SUCCESS = 'PROTOCOL_ADD_SUCCESS';
export const PROTOCOL_ADD_ERROR = 'PROTOCOL_ADD_ERROR';
export const PROTOCOL_EDIT_REQUEST = 'PROTOCOL_EDIT_REQUEST';
export const PROTOCOL_EDIT_SUCCESS = 'PROTOCOL_EDIT_SUCCESS';
export const PROTOCOL_EDIT_ERROR = 'PROTOCOL_EDIT_ERROR';
export const PROTOCOL_DELETE_REQUEST = 'PROTOCOL_DELETE_REQUEST';
export const PROTOCOL_DELETE_SUCCESS = 'PROTOCOL_DELETE_SUCCESS';
export const PROTOCOL_DELETE_ERROR = 'PROTOCOL_DELETE_ERROR';

export const PROTOCOL_STATE_IDS_CREATED = 1;
export const PROTOCOL_STATE_IDS_SIGNED = 2;


export const PROTOCOL_STATE_GET_REQUEST = 'PROTOCOL_STATE_GET_REQUEST';
export const PROTOCOL_STATE_GET_SUCCESS = 'PROTOCOL_STATE_GET_SUCCESS';
export const PROTOCOL_STATE_GET_ERROR = 'PROTOCOL_STATE_GET_ERROR';