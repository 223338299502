import React, {useCallback, useMemo} from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {HighlightOff, Edit} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

const BlockTableForm = ({columns, data, onChange, canAdd, canDelete}) => {

  const hasRowActions = useMemo(() => canDelete, [canDelete]);

  const renderField = useCallback((column, row) => {
    const handleChange = (value) => {
      row[column.code] = value;
      onChange([...data]);
    }
    const defaultProps = {
      label: null,
      margin: 'dense',
      placeholder: "Введите текст",
      value: row[column.code],
      onChange: e => handleChange(e.target.value),
    };

    switch (row.state) {
      case 'edit':
        switch (column.type) {
          case "textarea":
            return <TextField
              multiline
              rows={4}
              rowsMax={250}
              {...defaultProps}
              style={{minWidth:"250px"}}
            />;
          case "date":
            return <TextField
              type={"date"}
              {...defaultProps}
            />;
          case "datetime":
            return <TextField
              type={"datetime-local"}
              {...defaultProps}
            />;
          case "number":
            return <TextField
              type={"number"}
              placeholder={"Введите число"}
              {...defaultProps}
            />;
          case "string":
          default:
            return <TextField
              type={"string"}
              {...defaultProps}
            />;
        }
        break;
        default:
          return row[column.code] || "Не заполнено";
    }

    
  }, [data, onChange]);

  const deleteColumnStyles = {
    position: 'sticky',
    right: 0,
  }

  const setEdit = (item) => {
    if (item.state == 'edit')
      return;

    for (let i = 0; i < data.length; i++)
      data[i].state = '';
    item.state = 'edit';    
  }

  return <Grid container spacing={1}>
    <Grid item xs={12} style={{overflowX: "auto"}}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, j) => {
              return <TableCell key={j} style={{verticalAlign: "baseline", minWidth: 150, fontWeight: "bold"}}>{column.label}</TableCell>
            })}
            {hasRowActions && <TableCell style={deleteColumnStyles}/>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => {
            return <TableRow key={i} onClick={(e)=> {              
              setEdit(item);
              onChange([...data]);
            }}>
              {columns.map((column, j) => {
                return <TableCell key={j} style={{verticalAlign: "top"}}>
                  {renderField(column, item)}
                </TableCell>
              })}
              {hasRowActions && <TableCell align={"right"} style={deleteColumnStyles}>
                <IconButton onClick={() => {
                  setEdit(item);
                  onChange([...data]);
                }}><Edit/></IconButton>
                <IconButton onClick={() => {
                  data.splice(i, 1);
                  onChange([...data]);
                }}><HighlightOff/></IconButton>                
              </TableCell>}
            </TableRow>
          })}
        </TableBody>
      </Table>
    </Grid>
    {!!canAdd && <Grid item xs={12}>
      <Button  variant={"text"} onClick={() => {
        const newItem = {};
        data = [...data, newItem];
        setEdit(newItem);
        onChange([...data]);
      }}>Добавить блок</Button>
    </Grid>}
  </Grid>
}

BlockTableForm.defaultProps = {
  canAdd: true,
  canDelete: true,
};

BlockTableForm.propTypes = {
  // canAdd: PropTypes.
};


export default BlockTableForm;
