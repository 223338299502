import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useDialogs from "../../components/Dialog/useDialogs";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import CheckboxField from "../../components/Fields/CheckboxField";
import { TextField2 } from "../../components/Fields/TextField2";
import { TextPhoneField } from "../../components/Fields/TextPhoneField";
import { DateField } from "../../components/Fields/DateField";
import { DateTimeField } from "../../components/Fields/DateTimeField";
import { NumberField } from "../../components/Fields/NumberField";
import { GridItem } from "../../components/Fields/GridItem";
import { FilialSelect } from "../../components/Select/domainSelects";
import { changeData, changeDataMany } from "../../functions/changeHandlers";
import { addRequestLock } from "../../redux/actions/requestLockActions";
import { REQUEST_LOCK_LIST_ROUTE } from "../../routes";
import { submitData } from "../../functions/submitHandlers";

export const CreateRequestLock = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const handleChange = useCallback((key, value) => changeData(data, setData, key, value), [data]);
    const handleChangeMany = useCallback((obj) => changeDataMany(data, setData, obj), [data]);

    const {alert, DIALOG} = useDialogs();

    const handleSubmit = async () => {
        if (await submitData(data, async () =>
        {
            return true;
        }, setLoading, async () =>
        {
            return await dispatch(addRequestLock(data));
        }))
        {
            return push(REQUEST_LOCK_LIST_ROUTE);
        }
    };

    return (
        <DashboardLayout>
            <>
                <DIALOG/>
                <PageHeader heading="Создание новой записи">
                    <Button variant={"text"} onClick={() => push(REQUEST_LOCK_LIST_ROUTE)}>
                        Вернуться
                    </Button>
                </PageHeader>
                <PageContainer>
                    <FormSection>
                        <Grid container>
                            <DateField
                                id={"time"}
                                label={"Месяц"}
                                value={data["time"]}
                                onChange={(value) => handleChange("time", value)}
                                xs={6}
                            />
                            <GridItem xs={6}>
                                <Grid container>
                                    <GridItem xs={6}>
                                        {data.isUntilTime && <DateField
                                            id={"untilTime"}
                                            label={"Дата до"}
                                            value={data["untilTime"]}
                                            onChange={(value) => handleChange("untilTime", value)}
                                            xs={6}
                                        />}
                                    </GridItem>
                                    <CheckboxField
                                        id={"isUntilTime"}
                                        label={"Дата до"}
                                        value={data["isUntilTime"]}
                                        onChange={(value) => handleChange("isUntilTime", value)}
                                        xs={6}
                                    />
                                </Grid>
                            </GridItem>
                            
                            <CheckboxField
                                id={"isLocked"}
                                label={"Заблокирован"}
                                value={data["isLocked"]}
                                onChange={(value) => handleChange("isLocked", value)}
                                xs={12}
                            />
                            <GridItem xs={12}>
                                <FilialSelect
                                    id={"filial"}
                                    label={"Филиал"}
                                    value={data.filialId}
                                    onChange={(value, obj) => handleChangeMany({ filial: obj, filialId: value })}
                                    fullWidth
                                />
                            </GridItem>
                        </Grid>
                    </FormSection>
                </PageContainer>
                <PageFooter>
                    <LoadingButton loading={loading} onClick={handleSubmit}>
                        Создать запись
                    </LoadingButton>
                </PageFooter>
            </>
        </DashboardLayout>
    )
};
