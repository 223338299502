import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../../redux/actions/serviceActions";
import { getReagents } from "../../../redux/actions/reagentActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditServiceExpense({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [summary, setSummary] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [reagentId, setReagentId] = useState(null);
    const { loading, serviceExpenses, serviceExpense } = useSelector((state) => state.serviceExpense);
    const { services } = useSelector((state) => state.service);
    const { reagents } = useSelector((state) => state.reagent);
    const emptyState = () => {
        setId(null);
        setName('');
        setSummary(null);
        setServiceId(null);
        setReagentId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, name, summary, serviceId, reagentId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, name, summary, serviceId, reagentId
        } = serviceExpense || {};
        setId(id);
        setName(name);
        setSummary(summary);
        setServiceId(serviceId);
        setReagentId(reagentId);
    }, [serviceExpense, serviceExpenses])
    useEffect(() => {
        let response;
        const getServicesAsync = async () => {
            response = await dispatch(getServices());
        }
        const getReagentsAsync = async () => {
            response = await dispatch(getReagents());
        }
        if (services.length == 0)
        {
            getServicesAsync();
        }
        if (reagents.length == 0)
        {
            getReagentsAsync();
        }
    }, [dispatch, action])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={name} onChange={(e) => setName(e.target.value)} fullWidth id="name" name="name" label="Наименование" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={summary} onChange={(e) => setSummary(e.target.value)} fullWidth id="summary" name="summary" label="Стоимость" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={serviceId} onChange={(e) => setServiceId(e.target.value)} margin="normal" fullWidth id="serviceId" select>
                                        {services && services.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={reagentId} onChange={(e) => setReagentId(e.target.value)} margin="normal" fullWidth id="reagentId" select>
                                        {reagents && reagents.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
