import { 
    FORM_MAP_GET_REQUEST, FORM_MAP_GET_SUCCESS, FORM_MAP_GET_ERROR, 
    FORM_MAP_GET_BY_KEY_REQUEST, FORM_MAP_GET_BY_KEY_SUCCESS, FORM_MAP_GET_BY_KEY_ERROR, 
    FORM_MAP_ADD_REQUEST, FORM_MAP_ADD_SUCCESS, FORM_MAP_ADD_ERROR, 
    FORM_MAP_EDIT_REQUEST, FORM_MAP_EDIT_SUCCESS, FORM_MAP_EDIT_ERROR, 
    FORM_MAP_DELETE_REQUEST, FORM_MAP_DELETE_SUCCESS, FORM_MAP_DELETE_ERROR
} from "../constants/formMapConstants";

const initialState = {
    formMaps: [],
    formMap: {},
    loading: false,
    success: null,
    error: null,
    formMapRequestedCount: 0,
};

export const formMapReducer = (state = initialState, action) => {
    switch (action.type)     {
        case FORM_MAP_GET_REQUEST:
            return {
                ...state,
                formMapRequestedCount: ++state.formMapRequestedCount,
                loading: true,
            };
        case FORM_MAP_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                formMaps: action.payload,
                success: true,
            };
        case FORM_MAP_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FORM_MAP_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FORM_MAP_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                formMap: action.payload,
                success: true,
            };
        case FORM_MAP_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FORM_MAP_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FORM_MAP_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FORM_MAP_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FORM_MAP_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FORM_MAP_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FORM_MAP_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FORM_MAP_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FORM_MAP_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FORM_MAP_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
