import { $authHost } from "../../http";
import {
    SERVICE_GET_REQUEST, SERVICE_GET_SUCCESS, SERVICE_GET_ERROR,
    SERVICE_GET_BY_KEY_REQUEST, SERVICE_GET_BY_KEY_SUCCESS, SERVICE_GET_BY_KEY_ERROR,
    SERVICE_ADD_REQUEST, SERVICE_ADD_SUCCESS, SERVICE_ADD_ERROR,
    SERVICE_EDIT_REQUEST, SERVICE_EDIT_SUCCESS, SERVICE_EDIT_ERROR,
    SERVICE_DELETE_REQUEST, SERVICE_DELETE_SUCCESS, SERVICE_DELETE_ERROR,
    SERVICE_GET_CHILDS_REQUEST, SERVICE_GET_CHILDS_SUCCESS, SERVICE_GET_CHILDS_ERROR
} from "../constants/serviceConstants";
import { cloneQuery } from '../../functions/index';

export const getServices = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
            mode: 'no-cors'
        };

        const {data} = await $authHost.post(
            "/api/v1/service/query",
            args || {},
            config,
        );
        dispatch({
            type: SERVICE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceChilds = ({item, query, parentId}) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GET_CHILDS_REQUEST,
            payload: item
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
            mode: 'no-cors'
        };

        var args = cloneQuery(query);
        args.filter = {
            parentId: {
                operand1: parentId,
                operator: "equals"
            }
        };
        const {data} = await $authHost.post(
            "api/v1/service/query",
            args,
            config,
        );
        dispatch({
            type: SERVICE_GET_CHILDS_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_GET_CHILDS_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/service/${id}`,
        );
        dispatch({
            type: SERVICE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addService = ({ name, code, referralSchema, probeSchema, resultSchema, parentId, kindId, typeId, unitId, groupId, subGroupId, filialId, departmentId, divisionId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/service",
            { name, code, referralSchema, probeSchema, resultSchema, parentId, kindId, typeId, unitId, groupId, subGroupId, filialId, departmentId, divisionId },
            config,
        );
        dispatch(getServices(queryArgs || {}));
        dispatch({
            type: SERVICE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editService = ({ id, name, code, referralSchema, probeSchema, resultSchema, parentId, kindId, typeId, unitId, groupId, subGroupId, filialId, departmentId, divisionId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/service",
            { id, name, code, referralSchema, probeSchema, resultSchema, parentId, kindId, typeId, unitId, groupId, subGroupId, filialId, departmentId, divisionId },
            config,
        );
        dispatch(getServices(queryArgs || {}));
        dispatch({
            type: SERVICE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteService = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/service",
            { data: { id } },
            config,
        );
        dispatch(getServices(queryArgs || {}));
        dispatch({
            type: SERVICE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

