import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {formMapCRUD} from "../../../http";
import {FORM_MAP_DETAIL_ROUTE} from "../../../routes";
import React from "react";
import {getDepartments} from "../../../redux/actions";

export default function FormMapCRUDTable({...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("formMap.tableItem.id"), dataIndex: "id", key:"id"},
        {title: t("formMap.tableItem.department"), dataIndex: "department", key:"department",render: (unknown, row) => row.department ? row.department.name : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("formMap.tableItem.protocolForm"), dataIndex: "protocolForm", key:"protocolForm",render: (unknown, row) => row.protocolForm ? row.protocolForm.name ?  row.protocolForm.name : row.protocolForm.number: <span style={{color: "gray"}}>Не задан</span>},
        {title: t("formMap.tableItem.resultForm"), dataIndex: "resultForm", key:"resultForm",render: (unknown, row) => row.resultForm ? row.resultForm.name ?  row.resultForm.name : row.resultForm.number : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("formMap.tableItem.actForm"), dataIndex: "actForm", key:"actForm",render: (unknown, row) => row.actForm ? row.actForm.name ?  row.actForm.name : row.actForm.number : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("formMap.tableItem.probeType"), dataIndex: "probeType", key:"probeType",render: (unknown, row) => row.probeType ? row.probeType.name ?  row.probeType.name : row.probeType.number : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("formMap.tableItem.brigadeType"),dataIndex: "brigadeType", key: "brigadeType", render: (unknown, row) => row.brigadeType ? row.brigadeType.name : <span style={{color: "gray"}}>Не задан</span>},

    ]

    const searchFields = [
        {
            title: "Id формы протокола",
            key: "protocolFormId",
            compareType: "equals",
            operandPosition:1,
            filterType:"normal"
        },
        {
            title: "Id формы журнала",
            key: "resultFormId",
            compareType: "equals",
            operandPosition:1,
            filterType:"normal"
        }
    ];


    const filterFields = [
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey:"department",
            reducerArrayKey:"departments",
            reducerAction:getDepartments,
            operandPosition:1,
            optionLabelKey:"name",
            type:"normal",
            filterType:"normal"
        },
    ]


    return <CRUDTable
        search={formMapCRUD.search}
        columns={columns}
        onClickCreateButton={() => push(FORM_MAP_DETAIL_ROUTE("new"))}
        filterFields={filterFields}
        searchFields={searchFields}
        {...props}
    />
}