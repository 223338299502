import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDesinfectionReferences, addDesinfectionReference, editDesinfectionReference, deleteDesinfectionReference, getDesinfectionReferenceByKey } from "../../redux/actions/desinfectionReferenceActions";
import CreateDesinfectionReference from "./Popups/CreateDesinfectionReference";
import DesinfectionReferenceDetails from "./Popups/DesinfectionReferenceDetails";
import EditDesinfectionReference from "./Popups/EditDesinfectionReference";
import RemoveDesinfectionReference from "./Popups/RemoveDesinfectionReference";

function createData(id, number, createdAt, fullName, addressLine, age, address, disinfector) {
    return {id, number, createdAt, fullName, addressLine, age, address, disinfector};
}

let rows = [
    createData(1, "abc", "12.03.2021 00:00", "abc", "abc", 1, null, null),
];
const DesinfectionReferenceList = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("desinfectionReferenceList.tableItem.id")},
        {text: t("desinfectionReferenceList.tableItem.number")},
        {text: t("desinfectionReferenceList.tableItem.createdAt")},
        {text: t("desinfectionReferenceList.tableItem.fullName")},
        {text: t("desinfectionReferenceList.tableItem.addressLine")},
        {text: t("desinfectionReferenceList.tableItem.age")},
        {text: t("desinfectionReferenceList.tableItem.address")},
        {text: t("desinfectionReferenceList.tableItem.disinfector")},
        {text: t("desinfectionReferenceList.tableItem.actions")},
    ];
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);
    const setCreateHandler = async () => {
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setCreateOpen(true);
        setId(null);
    }
    const createSubmit = async (_) => {
        await dispatch(addDesinfectionReference(_, query));
    }
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getDesinfectionReferenceByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setDetailsOpen(true);
        setId(id);
    }
    
    const [editOpen, setEditOpen] = useState(false);
    const setEditHandler = async (id) => {
        await dispatch(getDesinfectionReferenceByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setEditOpen(true);
        setId(id);
    }
    const editSubmit = async (_) => {
        await dispatch(editDesinfectionReference(_, query));
    }
    const [removeOpen, setRemoveOpen] = useState(false);
    const setRemoveHandler = async (id) => {
        await dispatch(getDesinfectionReferenceByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setRemoveOpen(true);
        setId(id);
    }
    const removeSubmit = async (_) => {
        await dispatch(deleteDesinfectionReference(_.id, query));
    }

    const localData = false;
    const state = useSelector((state) => state.desinfectionReference);
    if (!localData && state.desinfectionReferences)
    {
        rows = state.desinfectionReferences;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getDesinfectionReferences(query));
        }
    }, [dispatch]);
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("desinfectionReferenceList.searchLabel")}/>
                <Button color="primary" variant="contained" onClick={() => setCreateHandler()}>
                    {t("desinfectionReferenceList.createText")}
                </Button>
            </Box>
            <TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {
                return {
                    id: _.id,
                    number: _.number,
                    createdAt: _.createdAt,
                    fullName: _.fullName,
                    addressLine: _.addressLine,
                    age: _.age,
                    address: _.address?.name,
                    disinfector: _.disinfector?.fullName,
                }
            })} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>,<EditIcon onClick={() => setEditHandler(_.id)}/>,<DeleteForeverIcon onClick={() => setRemoveHandler(_.id)}/>]}/>
            <CreateDesinfectionReference action={'create'} title={t("desinfectionReferenceList.createText")} open={createOpen} setOpen={setCreateOpen} onSubmit={createSubmit}/>
            <DesinfectionReferenceDetails action={'details'} title={t("desinfectionReferenceList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
            <EditDesinfectionReference action={'edit'} title={t("desinfectionReferenceList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit}/>
            <RemoveDesinfectionReference action={'remove'} title={t("desinfectionReferenceList.removeText")} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/>
        </DashboardLayout>
    )
};

export default DesinfectionReferenceList;
