import { referralCRUD } from "../../http";
import {
  REFERRAL_GET_REQUEST,
  REFERRAL_GET_SUCCESS,
  REFERRAL_GET_ERROR,
  REFERRAL_GET_BY_KEY_REQUEST,
  REFERRAL_GET_BY_KEY_SUCCESS,
  REFERRAL_GET_BY_KEY_ERROR,
  REFERRAL_ADD_REQUEST,
  REFERRAL_ADD_SUCCESS,
  REFERRAL_ADD_ERROR,
  REFERRAL_EDIT_REQUEST,
  REFERRAL_EDIT_SUCCESS,
  REFERRAL_EDIT_ERROR,
  REFERRAL_DELETE_REQUEST,
  REFERRAL_DELETE_SUCCESS,
  REFERRAL_DELETE_ERROR,
  REFERRAL_WORKFLOW_REQUEST,
  REFERRAL_WORKFLOW_SUCCESS,
  REFERRAL_WORKFLOW_ERROR,
} from "../constants/referralConstants";

export const _getReferrals = (args) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_GET_REQUEST,
    });

    const data = await referralCRUD.create(args);
    dispatch({
      type: REFERRAL_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: REFERRAL_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _getReferralByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_GET_BY_KEY_REQUEST,
    });

    const data = await referralCRUD.get(id);
    dispatch({
      type: REFERRAL_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: REFERRAL_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _addReferral = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_ADD_REQUEST,
    });

    const response = await referralCRUD.create(data);
    // await dispatch(_getReferrals(args));

    dispatch({
      type: REFERRAL_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: REFERRAL_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : (error.response.data.errors &&
              error.response.data.errors.length > 0 &&
              error.response.data.errors.join(", ")) ||
            error.message,
    });
  }
};

export const _editReferral = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_EDIT_REQUEST,
    });

    await referralCRUD.put(data);
    await dispatch(_getReferrals(args));

    dispatch({
      type: REFERRAL_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REFERRAL_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _deleteReferral = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: REFERRAL_DELETE_REQUEST,
    });

    await referralCRUD.delete(id);
    dispatch(_getReferrals(args));
    dispatch({
      type: REFERRAL_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REFERRAL_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _workflowReferral =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({
        type: REFERRAL_WORKFLOW_REQUEST,
      });

      await referralCRUD.workflowRun(id, prevStateId, nextStateId);
      dispatch({
        type: REFERRAL_WORKFLOW_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: REFERRAL_WORKFLOW_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
