import React from "react";
import { Grid, Typography } from "@mui/material";

export const PageHeaderHeading = (props) => {
  const { children } = props;

  return (
    <Grid container columnSpacing={2} {...props}>
      <Grid item>
        <Typography component="h1" align="center" variant="h5">
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

PageHeaderHeading.defaultProps = {
  children: "Страница",
};
