import t_08_2157 from './KZ.M.04.2157.svg'
import t_08_2157_b from '././KZ.M.04.2157 black.svg'

import t_08_0758 from './KZ.T.04.0758 black.svg'
import t_08_0758_b from './KZ.T.04.0758 black.svg'

import t_20_0758 from './KZ.T.20.0758.svg'
import m_20_2157_b from './KZ.M.20.2157.svg'
import m_20_2157 from './KZ.M.20.2157 black1.svg'

export default [
  t_08_2157,
  t_08_2157_b,
  t_08_0758,
  t_08_0758_b,
  t_20_0758,
  m_20_2157_b,
  m_20_2157
]
