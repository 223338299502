import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid, TextField } from "@mui/material";
import useDialogs from "../../components/Dialog/useDialogs";
import useLoader from "../../hooks/useLoader";
import { renderControls } from "../../functions";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import { formatDateAsDate, formatDateAsDateTime } from "../../functions/formatters";
import { SERVICE_GROUP_LIST_ROUTE } from "../../routes";
import { getServiceGroupByKey } from "../../redux/actions/serviceGroupActions";

export const ServiceGroupDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const [data, setData] = useState({});

    const {alert, DIALOG} = useDialogs();

    const fetch = async () => {
        fetchStart();

        const item = await dispatch(getServiceGroupByKey(id));
        if (!item) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setData(item);
        fetchStop();
    };

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, fetchStart, fetchStop]);

    const controls = [
    {
        state: "current",
        buttons: [
            <Button
                variant={"text"}
                onClick={() => push(SERVICE_GROUP_LIST_ROUTE)}
            >
                Вернуться
            </Button>,
        ],
    }
    ];

    return (
        <DashboardLayout>
            <DIALOG/>
            <PageHeader heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
            </PageHeader>
            <PageContainer>
                <FormSection>
                    <Grid container>
                        {/* <LabelField
                            id={"object"}
                            label={"Объект исследования"}
                            value={data.object}
                            xs={6}
                        />
                        <LabelField
                            id={"group"}
                            label={"Группа"}
                            value={data.group}
                            xs={6}
                        />
                        <LabelField
                            id={"subGroup"}
                            label={"Вид исследования"}
                            value={data.subGroup}
                            xs={6}
                        />
                        <LabelField
                            id={"kind"}
                            label={"Метод"}
                            value={data.kind}
                            xs={6}
                        />
                        <LabelField
                            id={"researchStandard"}
                            label={"НД на метод исследования"}
                            value={data.researchStandard}
                            xs={6}
                        />
                        <LabelField
                            id={"governmentStandard"}
                            label={"НД на метод отбора"}
                            value={data.governmentStandard}
                            xs={6}
                        />
                        <LabelField
                            id={"regulationOfCU"}
                            label={"Документ санитарно-эпидемиологического нормирования (на соответствие, которого проводится исследование)"}
                            value={data.regulationOfCU}
                            xs={6}
                        /> */}
                    </Grid>
                </FormSection>
            </PageContainer>
            <PageFooter heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
            </PageFooter>
        </DashboardLayout>
    )
};
