import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPatients, addPatient, editPatient, deletePatient, getPatientByKey } from "../../redux/actions/patientActions";
import CreatePatient from "./Popups/CreatePatient";
import PatientDetails from "./Popups/PatientDetails";
import EditPatient from "./Popups/EditPatient";
import RemovePatient from "./Popups/RemovePatient";

function createData(id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, gender) {
    return {id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, gender};
}

let rows = [
    createData(1, "abc", "abc", "abc", "abc", "abc", "12.03.2021 00:00", 1, "abc", "abc", null),
];
const PatientList = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("patientList.tableItem.id")},
        {text: t("patientList.tableItem.firstName")},
        {text: t("patientList.tableItem.lastName")},
        {text: t("patientList.tableItem.secondName")},
        {text: t("patientList.tableItem.fullName")},
        {text: t("patientList.tableItem.identityNumber")},
        {text: t("patientList.tableItem.birthDate")},
        {text: t("patientList.tableItem.yearsOld")},
        {text: t("patientList.tableItem.phoneNumber")},
        {text: t("patientList.tableItem.rpnId")},
        {text: t("patientList.tableItem.gender")},
        {text: t("patientList.tableItem.actions")},
    ];
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);
    const setCreateHandler = async () => {
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setCreateOpen(true);
        setId(null);
    }
    const createSubmit = async (_) => {
        await dispatch(addPatient(_, query));
    }
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getPatientByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setDetailsOpen(true);
        setId(id);
    }
    
    const [editOpen, setEditOpen] = useState(false);
    const setEditHandler = async (id) => {
        await dispatch(getPatientByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setEditOpen(true);
        setId(id);
    }
    const editSubmit = async (_) => {
        await dispatch(editPatient(_, query));
    }
    const [removeOpen, setRemoveOpen] = useState(false);
    const setRemoveHandler = async (id) => {
        await dispatch(getPatientByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setRemoveOpen(true);
        setId(id);
    }
    const removeSubmit = async (_) => {
        await dispatch(deletePatient(_.id, query));
    }

    const localData = false;
    const state = useSelector((state) => state.patient);
    if (!localData && state.patients)
    {
        rows = state.patients;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getPatients(query));
        }
    }, [dispatch]);
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("patientList.searchLabel")}/>
                <Button color="primary" variant="contained" onClick={() => setCreateHandler()}>
                    {t("patientList.createText")}
                </Button>
            </Box>
            <TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {
                return {
                    id: _.id,
                    firstName: _.firstName,
                    lastName: _.lastName,
                    secondName: _.secondName,
                    fullName: _.fullName,
                    identityNumber: _.identityNumber,
                    birthDate: _.birthDate,
                    yearsOld: _.yearsOld,
                    phoneNumber: _.phoneNumber,
                    rpnId: _.rpnId,
                    gender: _.gender?.name,
                }
            })} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>,<EditIcon onClick={() => setEditHandler(_.id)}/>,<DeleteForeverIcon onClick={() => setRemoveHandler(_.id)}/>]}/>
            <CreatePatient action={'create'} title={t("patientList.createText")} open={createOpen} setOpen={setCreateOpen} onSubmit={createSubmit}/>
            <PatientDetails action={'details'} title={t("patientList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
            <EditPatient action={'edit'} title={t("patientList.editText")} open={editOpen} setOpen={setEditOpen} onSubmit={editSubmit}/>
            <RemovePatient action={'remove'} title={t("patientList.removeText")} open={removeOpen} setOpen={setRemoveOpen} onSubmit={removeSubmit}/>
        </DashboardLayout>
    )
};

export default PatientList;
