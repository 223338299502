import { 
    RESULT_STATE_GET_REQUEST, RESULT_STATE_GET_SUCCESS, RESULT_STATE_GET_ERROR, 
    RESULT_STATE_GET_BY_KEY_REQUEST, RESULT_STATE_GET_BY_KEY_SUCCESS, RESULT_STATE_GET_BY_KEY_ERROR, 
    RESULT_STATE_ADD_REQUEST, RESULT_STATE_ADD_SUCCESS, RESULT_STATE_ADD_ERROR, 
    RESULT_STATE_EDIT_REQUEST, RESULT_STATE_EDIT_SUCCESS, RESULT_STATE_EDIT_ERROR, 
    RESULT_STATE_DELETE_REQUEST, RESULT_STATE_DELETE_SUCCESS, RESULT_STATE_DELETE_ERROR
} from "../constants/resultStateConstants";

const initialState = {
    resultStates: [],
    resultState: {},
    loading: false,
    success: null,
    error: null,
    resultStateRequestedCount: 0,
};

export const resultStateReducer = (state = initialState, action) => {
    switch (action.type)     {
        case RESULT_STATE_GET_REQUEST:
            return {
                ...state,
                resultStateRequestedCount: ++state.resultStateRequestedCount,
                loading: true,
            };
        case RESULT_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                resultStates: action.payload,
                success: true,
            };
        case RESULT_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_STATE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_STATE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                resultState: action.payload,
                success: true,
            };
        case RESULT_STATE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_STATE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_STATE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_STATE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_STATE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_STATE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_STATE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_STATE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_STATE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_STATE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
