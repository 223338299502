import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

DddDisinsectionPDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data
  const schema = [
    {
      key: '1. Шаруашылық жүргізуші объектінің (Полное наименование хозяйствующего объекта)',
      value: actData?.object?.value?.name
    },
    {
      key: '2. Мекен-жайы (Адрес)',
      value: data.addressLine
    },
    {
      key: '3. Жәндіктің, кеміргіштің түрі (Вид насекомого, грызуна)',
      value: actData?.insectType?.value
    },
    {
      key: '4. Алаңда шарт өтінім бойынша дезинсекциялау, дератизациялау(Выполнена дезинсекция, дератизация по договору или заявке на площади)',
      value: actData?.isDisinsectionComplete?.value ? `${actData?.isDisinsectionComplete?.value} м2` : ' '
    },
    {
      key: '5. Өңдеу күні (Дата обработки)',
      value: actData?.treatmentDate?.value ? formatTime(new Date(actData.treatmentDate?.value)) : ' '
    }
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {data.createdAt ? formatDate(new Date(data.createdAt)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <Table style={styles.table}>
          <Row>
            <Cell >
              <Text style={styles.sampleBold}>
                Өнделген заттар, үй-жайлар,аумақтар Обработаны вещи, помещения,территории:
              </Text>
            </Cell>
            <Cell >
              <Text style={styles.sampleBold}>
                Зерттеу әдістеменің НҚ-ры НД на метод испытаний:
              </Text>
            </Cell>
            <Cell>
              <Text style={styles.sampleBold}>
                Жұмсалған инсектицидтер, ратицидтер Расход инсектицидов, ратициды (Атауы Наименование):
              </Text>
            </Cell>
            <Cell>
              <Text style={styles.sampleBold}>
                Жұмсалған инсектицидтер, ратицидтер Расход инсектицидов, ратициды (Саны (кг) Количество (кг)):
              </Text>
            </Cell>
          </Row>
          {actData?.samples?.value.map(el => {
            return <Row>
              <Cell >
                <Text style={styles.sampleRegular}>
                  {el.processedThings?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.sample?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.name?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.quantity?.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>

        <View style={{ fontFamily: 'Times New Roman' }}>
          <Text style={styles.simpleString}>Дезинсекциялауды, дератизациялауды орындаған адамның тегі, аты, әкесінің аты (болған жағдайда), (Фамилия, имя, отчество (при наличии), лица выполнявшего дезинсекцию, дератизацию): {data.disinfector?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Жұмыс орындалды, наразылық жоқ (Работа выполнена, претензий нет): {actData?.haveNoComplaints?.value}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Тапсырыс беруші өкілінің тегі, аты, әкесінің аты (болған жағдайда) мен қолы (Фамилия, имя, отчество (при наличии) и подпись ответственного представителя заказчика) : {data.desinsectionRequest?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function DddDisinsectionPDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
