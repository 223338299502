const initialState = {
    probeTypes: [],
    probeType: {},
    loading: false,
    success: null,
    error: null,
    probeTypeRequestedCount: 0,
};

export const probeTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case "PROBE_TYPE_GET_REQUEST":
            return {
                ...state,
                probeTypeRequestedCount: ++state.probeTypeRequestedCount,
                loading: true,
            };
        case "PROBE_TYPE_GET_SUCCESS":
            return {
                ...state,
                loading: false,
                probeTypes: action.payload,
                success: true,
            };
        case "PROBE_TYPE_GET_ERROR":
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case "PROBE_TYPE_GET_BY_KEY_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PROBE_TYPE_GET_BY_KEY_SUCCESS":
            return {
                ...state,
                loading: false,
                probeType: action.payload,
                success: true,
            };
        case "PROBE_TYPE_GET_BY_KEY_ERROR":
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case "PROBE_TYPE_ADD_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PROBE_TYPE_ADD_SUCCESS":
            return {
                ...state,
                loading: false,
                success: true,
            };
        case "PROBE_TYPE_ADD_ERROR":
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case "PROBE_TYPE_EDIT_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PROBE_TYPE_EDIT_SUCCESS":
            return {
                ...state,
                loading: false,
                success: true,
            };
        case "PROBE_TYPE_EDIT_ERROR":
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case "PROBE_TYPE_DELETE_REQUEST":
            return {
                ...state,
                loading: true,
            };
        case "PROBE_TYPE_DELETE_SUCCESS":
            return {
                ...state,
                loading: false,
                success: true,
            };
        case "PROBE_TYPE_DELETE_ERROR":
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
