import React from "react";

import { PageContainer, PageHeader } from "../../../components";
import { WithoutDepartureRequestCRUDTable } from "../../../components/CRUD/Tables";
import DashboardLayout from "../../../layouts/Dashboard";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";
import useReload from "../../../hooks/useReload";
import {checkRoles} from "../../../functions/checkRoles";
import {selfControlRequestCRUD} from "../../../http";
import {SelfControlRequestCRUDTable} from "../../../components/CRUD/Tables/Request/SelfControlRequestCRUDTable";

export const SelfControlRequestTable = () => {

    const { reload, reloadValue } = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление заявки по самоконтролю, ID:' + _, 'Вы действительно хотите удалить эту сводную заявку?', async () => {
            await selfControlRequestCRUD.delete(_);
            await reload();
        })
    }

    return (
        <DashboardLayout>
            <PageHeader heading="Заявки по самоконтролю" />
            <PageContainer>
                <SelfControlRequestCRUDTable reloadValue={reloadValue} onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null}/>
            </PageContainer>
        </DashboardLayout>
    )
};
