import { $authHost } from "../../http";
import { 
    CONTRACT_SPECIFICATION_GET_REQUEST, CONTRACT_SPECIFICATION_GET_SUCCESS, CONTRACT_SPECIFICATION_GET_ERROR, 
    CONTRACT_SPECIFICATION_GET_BY_KEY_REQUEST, CONTRACT_SPECIFICATION_GET_BY_KEY_SUCCESS, CONTRACT_SPECIFICATION_GET_BY_KEY_ERROR, 
    CONTRACT_SPECIFICATION_ADD_REQUEST, CONTRACT_SPECIFICATION_ADD_SUCCESS, CONTRACT_SPECIFICATION_ADD_ERROR, 
    CONTRACT_SPECIFICATION_EDIT_REQUEST, CONTRACT_SPECIFICATION_EDIT_SUCCESS, CONTRACT_SPECIFICATION_EDIT_ERROR, 
    CONTRACT_SPECIFICATION_DELETE_REQUEST, CONTRACT_SPECIFICATION_DELETE_SUCCESS, CONTRACT_SPECIFICATION_DELETE_ERROR
} from "../constants/contractSpecificationConstants";

export const getContractSpecifications = (args) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_SPECIFICATION_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/contractSpecification/query",
            args || {},
            config,
        );
        dispatch({
            type: CONTRACT_SPECIFICATION_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: CONTRACT_SPECIFICATION_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getContractSpecificationByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_SPECIFICATION_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/contractSpecification/${id}`,
        );
        dispatch({
            type: CONTRACT_SPECIFICATION_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: CONTRACT_SPECIFICATION_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addContractSpecification = ({ price, quantity, contractId, serviceKindId, serviceId, unitId, servicePriceListId, objectId, scheduleId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_SPECIFICATION_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/contractSpecification",
            { price, quantity, contractId, serviceKindId, serviceId, unitId, servicePriceListId, objectId, scheduleId },
            config,
        );
        dispatch(getContractSpecifications(queryArgs || {}));
        dispatch({
            type: CONTRACT_SPECIFICATION_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_SPECIFICATION_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editContractSpecification = ({ id, price, quantity, contractId, serviceKindId, serviceId, unitId, servicePriceListId, objectId, scheduleId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_SPECIFICATION_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/contractSpecification",
            { id, price, quantity, contractId, serviceKindId, serviceId, unitId, servicePriceListId, objectId, scheduleId },
            config,
        );
        dispatch(getContractSpecifications(queryArgs || {}));
        dispatch({
            type: CONTRACT_SPECIFICATION_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_SPECIFICATION_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteContractSpecification = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: CONTRACT_SPECIFICATION_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/contractSpecification",
            { data: { id } },
            config,
        );
        dispatch(getContractSpecifications(queryArgs || {}));
        dispatch({
            type: CONTRACT_SPECIFICATION_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: CONTRACT_SPECIFICATION_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

