import { $authHost } from "../../http";
import { 
    SERVICE_TYPE_GET_REQUEST, SERVICE_TYPE_GET_SUCCESS, SERVICE_TYPE_GET_ERROR, 
    SERVICE_TYPE_GET_BY_KEY_REQUEST, SERVICE_TYPE_GET_BY_KEY_SUCCESS, SERVICE_TYPE_GET_BY_KEY_ERROR, 
    SERVICE_TYPE_ADD_REQUEST, SERVICE_TYPE_ADD_SUCCESS, SERVICE_TYPE_ADD_ERROR, 
    SERVICE_TYPE_EDIT_REQUEST, SERVICE_TYPE_EDIT_SUCCESS, SERVICE_TYPE_EDIT_ERROR, 
    SERVICE_TYPE_DELETE_REQUEST, SERVICE_TYPE_DELETE_SUCCESS, SERVICE_TYPE_DELETE_ERROR
} from "../constants/serviceTypeConstants";

export const getServiceTypes = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_TYPE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/serviceType/query",
            args || {},
            config,
        );
        dispatch({
            type: SERVICE_TYPE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_TYPE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceTypeByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_TYPE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/serviceType/${id}`,
        );
        dispatch({
            type: SERVICE_TYPE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_TYPE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addServiceType = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_TYPE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/serviceType",
            { name, code, description },
            config,
        );
        dispatch(getServiceTypes(queryArgs || {}));
        dispatch({
            type: SERVICE_TYPE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_TYPE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editServiceType = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_TYPE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/serviceType",
            { id, name, code, description },
            config,
        );
        dispatch(getServiceTypes(queryArgs || {}));
        dispatch({
            type: SERVICE_TYPE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_TYPE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteServiceType = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_TYPE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/serviceType",
            { data: { id } },
            config,
        );
        dispatch(getServiceTypes(queryArgs || {}));
        dispatch({
            type: SERVICE_TYPE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_TYPE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

