import { getReferralStates } from "../../redux/actions/referralStateActions";
import { getFilials, getGovernmentContracts, getGovernmentContractsIn10Years } from "../../redux/actions";
import { getDepartments } from "../../redux/actions";
import { getFilialDivisions } from "../../redux/actions/filialDivisionActions";

export const referralSearchFields = [
    {
        title: "Номер",
        key: "number",
        compareType: "equals",
        operandPosition:1,
        filterType:"normal"
    },
    {
      title: "ФИО пациента",
      key: "targetPatientFullName",
      key1: "targetPatient",
      key2: "fullName",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal",
    },
  {
      title: "Иин пациента",
      key: "targetPatientFullName",
      key1: "targetPatient",
      key2: "identityNumber",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal",
    },
];

export const referralFilterFields = [
    {
      title: "Cтатус",
      key: "stateId",
      compareType: "equals",
      reducerKey: "referralState",
      reducerArrayKey: "referralStates",
      reducerAction: getReferralStates,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
    },
    {
      title: "Филиал",
      key: "filialId",
      compareType: "equals",
      reducerKey: "filial",
      reducerArrayKey: "filials",
      reducerAction: getFilials,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
    },
    {
      title: "Дата создания с",
      key: "createdAt",
      operandPosition: 1,
      compareType: "between",
      type: "date",
      filterType: "normal",
    },
    {
      title: "Дата создания по",
      key: "createdAt",
      operandPosition: 2,
      compareType: "between",
      type: "date",
      filterType: "normal",
    },
    {
      title: "Лаборатория",
      key: "departmentId",
      compareType: "equals",
      reducerKey: "department",
      reducerArrayKey: "departments",
      reducerAction: getDepartments,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
    },
    {
      title: "Источник",
      key: "sourceType",
      compareType: "equals",
      options: [{ id: 1, name: 'МО' }],
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
      nullable: true
    },
  ];

  export const objectReferralFilterFields = [
    {
      title: "Cтатус",
      key: "stateId",
      compareType: "equals",
      reducerKey: "referralState",
      reducerArrayKey: "referralStates",
      reducerAction: getReferralStates,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
    },
    {
      title: "Договор",
      key: "governmentContractId",
      compareType: "equals",
      reducerKey: "contract",
      reducerCountKey: 'contractIn10Year',
      reducerArrayKey: "contractsIn10Year",
      reducerAction: getGovernmentContractsIn10Years,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
  },
    {
      title: "Филиал",
      key: "filialId",
      compareType: "equals",
      reducerKey: "filial",
      reducerArrayKey: "filials",
      reducerAction: getFilials,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
    },
    {
      title: "Районное отделение",
      key: "filialDivisionId",
      compareType: "equals",
      reducerKey: "filialDivision",
      reducerArrayKey: "filialDivisions",
      reducerAction: getFilialDivisions,
      operandPosition: 1,
      parentKey: 'filialId',
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
  },
    {
      title: "Дата завершения исследования с",
      key: "researchEndDateTime",
      operandPosition: 1,
      compareType: "between",
      type: "date2",
      filterType: "normal",
    },
    {
      title: "Дата завершения исследования по",
      key: "researchEndDateTime",
      operandPosition: 2,
      compareType: "between",
      type: "date2",
      filterType: "normal",
    },
    {
      title: "Лаборатория",
      key: "departmentId",
      compareType: "equals",
      reducerKey: "department",
      reducerArrayKey: "departments",
      reducerAction: getDepartments,
      operandPosition: 1,
      optionLabelKey: "name",
      type: "normal",
      filterType: "normal",
    }
  ];
