import React from "react";

import { PageContainer, PageHeader } from "../../../components";
import { DepartureScheduleRequestCRUDTable } from "../../../components/CRUD/Tables";
import DashboardLayout from "../../../layouts/Dashboard";
import useReload from "../../../hooks/useReload";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";
import {departureScheduleRequestCRUD} from "../../../http";
import {checkRoles} from "../../../functions/checkRoles";

export const DepartureScheduleRequestTable = () => {
    const { reload, reloadValue } = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление графика отбора, ID:' + _, 'Вы действительно хотите удалить эту заявку?', async () => {
            await departureScheduleRequestCRUD.delete(_);
            await reload();
        })
    }
    return (
        <DashboardLayout>
            <PageHeader heading="График отбора" />
            <PageContainer>
                <DepartureScheduleRequestCRUDTable onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} reloadValue={reloadValue}/>
            </PageContainer>
        </DashboardLayout>
    )
};
