/*
  Как делать маппинг ключей:
  1. Добавляем в объект ключ номер формы (например 115) в кавычках
  2. Внутри этого объекта: ключ — key из бд, значение — id поля в компоненте
  Например {
    {
      '390': {
        act_1100_10: 'age'
      }
    }
  }
  значит что есть номер формы протокола 390 для которого из актов и журналов возвращается ключ act_1100_10 и значение
  надо присвоить полю возраст в форме
*/

/* eslint-disable import/no-anonymous-default-export */
export default {
  "22": {
    "0": "name",
    "1": "age",
    "2": "adress",
    "3": "place_of_work",
    "4": "diagnosis",
    "5": "datetime_of_probe",
    "6": "first_or_repeated",
    "7": "antibiotic_uses",
    "8": "forwarding_organizations",
    "9": "probe_name",
    "10": "datetime_of_delivery",
    "11": "examination_goal",
    "12": "material_forwarder",
    "13": "trial_document",
    "14": "complied_with_regulatory_doc"
  },
  "23": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "amount",
    "4": "research_goal",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "expiration_date",
    "9": "selection_date",
    "10": "delivery_date",
    "11": "nd_selection_method",
    "12": "transport_conditions",
    "13": "storage_conditions",
    "14": "additional_data",
    "15": "normative_document",
    "16": "research_results"
  },
  "24": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "research_date",
    "9": "normative_document",
    "10": "transport_conditions",
    "11": "storage_conditions",
    "12": "delivery_guy_additional_data",
    "13": "research_results",
    "14": "nd_selection_method"
  },
  "25": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "research_date",
    "9": "normative_document",
    "10": "additional_data",
    "11": "nd_selection_method",
    "12": "research_results"
  },
  "26": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "expiration_date",
    "9": "research_date",
    "10": "normative_document",
    "11": "transport_conditions",
    "12": "storage_conditions",
    "13": "nd_selection_method",
    "14": "research_results"
  },
  "27": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "research_date",
    "9": "normative_document",
    "10": "transport_conditions",
    "11": "storage_conditions",
    "12": "nd_selection_method",
    "13": "additional_data",
    "14": "research_results"
  },
  "28": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "research_date",
    "9": "normative_document",
    "10": "transport_conditions",
    "11": "storage_conditions",
    "12": "nd_selection_method",
    "13": "additional_data",
    "14": "research_results"
  },
  "29": {
    "0": "full_name",
    "1": "first_or_repeated",
    "2": "med_organization",
    "3": "sample_obtain_date",
    "4": "research_end_date",
    "5": "volume",
    "6": "partion_number",
    "7": "patogen_infection",
    "8": "bifidobacteria",
    "9": "lactobacteria",
    "10": "colibacillus",
    "11": "colibacillus_low",
    "12": "lacto_colibacillus",
    "13": "hemolitic_colibacillus",
    "14": "protei",
    "15": "enterbacteria",
    "16": "stafilocock",
    "17": "other_stafilacocks",
    "18": "entercock",
    "19": "clostridia",
    "20": "candida_shrooms",
    "21": "ngob"
  },
  "30": {
    "0": "adress",
    "1": "sample_name",
    "2": "producer",
    "3": "obtain_expiration_dates",
    "4": "normative_document",
    "5": "selection_act",
    "6": "sample_description",
    "7": "volume",
    "8": "production_expiration_dates",
    "9": "partion_number",
    "10": "research_start_end_dates",
    "11": "indicator_name",
    "12": "concentration",
    "13": "normative_indicators",
    "14": "nd_research_method",
    "15": "research_on_nd"
  },
  "32": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "mass",
    "4": "partion_number",
    "5": "research_goal",
    "6": "selection_date",
    "7": "partion_number",
    "8": "production_date",
    "9": "delivery_date",
    "10": "research_date",
    "11": "normative_document",
    "12": "transport_conditions",
    "13": "storage_conditions",
    "14": "production_expiration_date",
    "15": "research_results"
  },
  "33": {
    "0": "organization_name",
    "1": "place_of_selection",
    "2": "research_goals",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "prob_amount",
    "6": "research_date",
    "7": "normative_document",
    "8": "additional_data",
    "9": "measurement_results"
  },
  "34": {
    "0": "adress",
    "1": "sample_name",
    "2": "producer",
    "3": "normative_document",
    "4": "research_start_end_dates",
    "5": "sample_description",
    "6": "volume",
    "7": "partion_number",
    "8": "production_date",
    "9": "expiration_date",
    "10": "components_migration",
    "11": "model_environment_organoleptic",
    "12": "model_environment_physicochemical",
    "13": "model_environment_toxic",
    "14": "concentration_organoleptic",
    "15": "concentration_physicochemical",
    "16": "concentration_toxic",
    "17": "allowed_concentration_organoleptic",
    "18": "allowed_concentration_physicochemical",
    "19": "allowed_concentration_toxic",
    "20": "nd_selection_method_organoleptic",
    "21": "nd_selection_method_physicochemical",
    "22": "nd_selection_method_toxic"
  },
  "35": {
    "0": "name",
    "1": "measurements_goals",
    "2": "measurements_done_with_representative",
    "3": "tools_and_inventory_number",
    "4": "certificate_date_number",
    "5": "normative_document",
    "6": "main_sources",
    "7": "persons_working",
    "8": "volume",
    "9": "research_on_nd",
    "10": "working_places"
  },
  "36": {
    "0": "adress",
    "1": "place",
    "2": "research_goal",
    "3": "done_with_representative",
    "4": "research_methods",
    "5": "inventory_number",
    "6": "check_data",
    "7": "evidence_date",
    "8": "production_date",
    "9": "normative_document",
    "10": "vibration_sources",
    "11": "working_personal_number",
    "12": "vibration_room",
    "13": "working_places_sketch",
    "14": "reviewed_equipment",
    "15": "vibration_type",
    "16": "vibroacceleration_level",
    "17": "vibration_level",
    "18": "vibration_level_cycle",
    "19": "equal_vibration_level"
  },
  "37": {
    "0": "adress",
    "1": "place",
    "2": "measurement_goal",
    "3": "measured_with_representative",
    "4": "inventory",
    "5": "check_data",
    "6": "certificate_date_number",
    "7": "normative_document_measurements",
    "8": "noise_sources",
    "9": "working_personal_number",
    "10": "place_sketch",
    "11": "research_on_nd",
    "12": "measurement_results",
    "13": "registration_number",
    "14": "sketch_dot_number",
    "15": "measurement_place",
    "16": "additional_data",
    "17": "volume_pressure_level",
    "18": "max_volume",
    "19": "isolation_index",
    "20": "noise_isolation_index"
  },
  "38": {
    "0": "adress",
    "1": "place",
    "2": "measurement_goal",
    "3": "measured_with_representative",
    "4": "measurement_tool",
    "5": "inventory",
    "6": "check_data",
    "7": "certificate_date_number",
    "8": "normative_documentation",
    "9": "sketch",
    "10": "number",
    "11": "time_during_session",
    "12": "sketch_dot_number",
    "13": "measurement_place",
    "14": "source_distance",
    "15": "height_from_floor",
    "16": "time_of_being_in_emp",
    "17": "emp_power",
    "18": "intensity",
    "19": "energy_density",
    "20": "setup_settings",
    "21": "setup_name",
    "22": "emp_frequency",
    "23": "emp_source_power",
    "24": "power_when_measuring",
    "25": "source_number_factory",
    "26": "source_release_year",
    "27": "research_on_nd"
  },
  "39": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "research_goals",
    "4": "partion_magnitude",
    "5": "selection_date",
    "6": "delivery_date",
    "7": "additional_data",
    "8": "volume",
    "9": "partion_number",
    "10": "production_date",
    "11": "research_results",
    "12": "product_name",
    "13": "nitrate_concentration",
    "14": "mdu_indicators",
    "15": "nd_selection_method"
  },
  "40": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "number",
    "4": "research_goals",
    "5": "volume",
    "6": "partion_number",
    "7": "production_date",
    "8": "expiration_date",
    "9": "selection_date",
    "10": "delivery_date",
    "11": "normative_document_selection",
    "12": "transport_conditions",
    "13": "storage_conditions",
    "14": "research_results",
    "15": "nd_research_method"
  },
  "41": {
    "0": "adress",
    "1": "selection_date",
    "2": "delivery_date",
    "3": "additional_data",
    "4": "research_goals",
    "5": "percent_of_active_substance",
    "6": "volume",
    "7": "production_date",
    "8": "research_result",
    "9": "sample_number",
    "10": "place_of_selection",
    "11": "sample_state",
    "12": "active_concentration",
    "13": "normative_value",
    "14": "nd_research_method",
    "15": "research_on_nd"
  },
  "42": {
    "0": "infection_type",
    "1": "examinatee_full_name",
    "2": "diagnosis",
    "3": "age",
    "4": "adress",
    "5": "organization_name",
    "6": "probe_name",
    "7": "sick_day",
    "8": "research_goal",
    "9": "delivery_date",
    "10": "sample_obtain_date",
    "11": "research_result",
    "12": "additional_data",
    "13": "research_on_nd"
  },
  "43": {
    "0": "probe_name",
    "1": "sample_obtain_place",
    "2": "selection_date",
    "3": "expiration_date",
    "4": "transport_conditions",
    "5": "reaction_form",
    "6": "forwarder_name",
    "7": "research_method",
    "8": "research_results",
    "9": "research_on_nd"
  },
  "44": {
    "0": "infection_type",
    "1": "full_name",
    "2": "age",
    "3": "home_adress",
    "4": "work_place",
    "5": "forwarder_name",
    "6": "diagnosis",
    "7": "research_variant",
    "8": "sick_day",
    "9": "cell_culture",
    "10": "virus_detected",
    "11": "with_antigen",
    "12": "diagnosis_increase",
    "13": "selection_date",
    "14": "transport_conditions",
    "15": "blood_sample",
    "16": "sick_date",
    "17": "antigen_titles",
    "18": "research_on_nd"
  },
  "45": {
    "0": "adress",
    "1": "sample_name",
    "2": "sample_obtaining_place",
    "3": "sample_obtaining_date",
    "4": "research_method",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "expiration_date",
    "9": "research_done_with",
    "10": "check_certificate",
    "11": "measurement_results",
    "12": "sample_name_",
    "13": "ok_effective_active_level_cs137",
    "14": "max_effective_active_level_cs137",
    "15": "ok_effective_active_level_sr90",
    "16": "nd_research_method",
    "17": "max_effective_active_level_sr90"
  },
  "46": {
    "0": "adress",
    "1": "sample_name",
    "2": "sample_obtaining_place",
    "3": "sample_obtaining_date",
    "4": "research_method",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "research_done_with",
    "9": "check_certificate",
    "10": "research_on_nd",
    "11": "measurement_results",
    "12": "sample_name",
    "13": "nd_research_method",
    "14": "ok_effective_active_level",
    "15": "alpha_active_sum",
    "16": "beta_active_sum"
  },
  "47": {
    "0": "adress",
    "1": "sample_name",
    "2": "sample_obtaining_place",
    "3": "research_method",
    "4": "volume",
    "5": "partions_number",
    "6": "production_date",
    "7": "expiration_date",
    "8": "research_done_with",
    "9": "check_certificate",
    "10": "measurement_results",
    "11": "specific_effective_activity",
    "12": "permissible_level_specific_effective_activity",
    "13": "building_material_class",
    "14": "research_on_nd",
    "15": "buiding_materials_conditions"
  },
  "48": {
    "0": "adress",
    "1": "sample_name",
    "2": "place_of_selection",
    "3": "sample_selection_date",
    "4": "volume",
    "5": "production_date",
    "6": "research_method",
    "7": "research_done_with",
    "8": "check_certificate",
    "9": "measurement_results",
    "10": "sample_name",
    "11": "specific_activity_u238",
    "12": "specific_activity_th238",
    "13": "max_sum_specific_activity_radionyklud",
    "14": "nd_selection_method",
    "15": "permissible_level_specific_radionuklid_activity"
  },
  "49": {
    "0": "adress",
    "1": "sample_name",
    "2": "place_of_selection",
    "3": "testing_date",
    "4": "research_method",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "research_done_with",
    "9": "check_certificate",
    "10": "research_on_nd",
    "11": "measurement_results",
    "12": "sample_name",
    "13": "obor_dot",
    "14": "specific_effective_activity",
    "15": "nd_selection_method",
    "16": "permissable_level_of_effective_activity"
  },
  "50": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "research_method",
    "4": "partion_volume",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "sample_number",
    "9": "factory_number_type",
    "10": "certificate_date_number",
    "11": "research_on_nd",
    "12": "measurement_results",
    "13": "indicators_names",
    "14": "unit_of_measurement",
    "15": "discovered_value",
    "16": "nd_selection_method",
    "17": "acceptable_level"
  },
  "51": {
    "0": "adress",
    "1": "measurement_place",
    "2": "otdel_ceh",
    "3": "measurement_done_with_representative",
    "4": "inventorization_etc",
    "5": "measurement_method",
    "6": "measurement_tool",
    "7": "name",
    "8": "government_check",
    "9": "additional_data",
    "10": "research_on_nd",
    "11": "measurement_results",
    "12": "measurement_place_",
    "13": "radiation_type",
    "14": "measurement_dot",
    "15": "measured_doze",
    "16": "nd_selection_method",
    "17": "acceptable_level"
  },
  "52": {
    "0": "adress",
    "1": "measurement_places",
    "2": "measurement_goal",
    "3": "measurement_done_with_representative",
    "4": "name_type_factory_number",
    "5": "certificate_date_number",
    "6": "measurement_results",
    "7": "registration_number",
    "8": "measurement_place",
    "9": "power_of_doze",
    "10": "height_from_floor",
    "11": "nd_selection_method",
    "12": "acceptable_power_of_doze",
    "13": "research_on_nd"
  },
  "53": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "research_method",
    "4": "air_volume",
    "5": "time",
    "6": "measurement_methods",
    "7": "government_check",
    "8": "measurement_results",
    "9": "registration_number",
    "10": "sample_name",
    "11": "indicators_names",
    "12": "measurement_unit",
    "13": "nd_selection_method",
    "14": "specific_effective_activity",
    "15": "acceptable_level",
    "16": "research_on_nd"
  },
  "56": {
    "0": "adress_name",
    "1": "measurement_place",
    "2": "measurement_goal",
    "3": "measurement_done_with_representative",
    "4": "name_type_factory_number",
    "5": "certificate_date_number",
    "6": "control_dots_schema",
    "7": "nd_selection_method",
    "8": "research_on_nd"
  },
  "57": {
    "0": "organization_name",
    "1": "adress",
    "2": "measurement_done_with_representative",
    "3": "telephone",
    "4": "apparat",
    "5": "technical_passport",
    "6": "expiration_date",
    "7": "common_kv_filter",
    "8": "additional_filter",
    "9": "measurement_done_with_equalcloth_phantom",
    "10": "check_certificate",
    "11": "nd_selection_method",
    "12": "cabinet_sketch",
    "13": "near_cabinet_room",
    "14": "equipment_places",
    "15": "under_cabinet",
    "16": "near_wall_a",
    "17": "near_wall_b",
    "18": "near_wall_c",
    "19": "near_wall_d",
    "20": "near_wall_e",
    "21": "research_on_nd"
  },
  "58": {
    "0": "adress",
    "1": "measurement_place",
    "2": "measurement_adress",
    "3": "measurement_done_with_representative",
    "4": "measurement_methods",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "certificate_date_number",
    "9": "med_indicator",
    "10": "measurement_results",
    "11": "sample_name",
    "12": "alpha_particles_stream",
    "13": "nd_selection_method",
    "14": "beta_particles_stream",
    "15": "med_gamma_indicator",
    "16": "research_on_nd"
  },
  "59": {
    "0": "adress",
    "1": "measurement_place",
    "2": "measurement_done_with_representative",
    "3": "measurement_goal",
    "4": "measurement_tool",
    "5": "name_type_factory_number",
    "6": "government_check",
    "7": "certificate_date_number",
    "8": "research_on_nd",
    "9": "measurement_results",
    "10": "registration_number",
    "11": "research_place",
    "12": "radon_activity_volume",
    "13": "nd_selection_method",
    "14": "acceptable_stream",
    "15": "ventillation_marks",
    "16": "research_on_nd"
  },
  "60": {
    "0": "adress",
    "1": "persons_categories",
    "2": "measurement_method",
    "3": "measurement_tool",
    "4": "name",
    "5": "government_check",
    "6": "research_on_nd",
    "7": "measurement_results",
    "8": "registration_number",
    "9": "full_name",
    "10": "position",
    "11": "nd_selection_method",
    "12": "dosimiter_number",
    "13": "doze_in_m3v",
    "14": "research_on_nd"
  },
  "61": {
    "0": "sample_selection_date",
    "1": "expiration_date",
    "2": "analysis_issue_date",
    "3": "full_name",
    "4": "age",
    "5": "diagnosis",
    "6": "med_organization",
    "7": "filial",
    "8": "when_researching",
    "9": "mark_sample",
    "10": "result",
    "11": "nd_selection_method",
    "12": "research_on_nd_done"
  },
  "62": {
    "0": "adress",
    "1": "sample_name",
    "2": "place_of_selection",
    "3": "sample_selection_date",
    "4": "research_method",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "research_done_with",
    "9": "check_certificate",
    "10": "research_on_nd",
    "11": "measurement_results",
    "12": "sample_name",
    "13": "asola_specific_activity",
    "14": "permissible_specific_activity_radionyklid",
    "15": "danger_zone_class",
    "16": "nd_selection_method",
    "17": "specific_use_conditions",
    "18": "research_on_nd"
  },
  "63": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "research_method",
    "4": "measurement_tools",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "government_check",
    "9": "research_on_nd",
    "10": "measurement_results",
    "11": "sample_name",
    "12": "indicators_names",
    "13": "measurement_unit",
    "14": "factical_specific_effective_activity",
    "15": "nd_selection_method",
    "16": "acceptable_content"
  },
  "64": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "research_method",
    "4": "measurement_tools",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "government_check",
    "9": "research_on_nd",
    "10": "measurement_results",
    "11": "sample_name",
    "12": "indicator_names",
    "13": "measurement_unit",
    "14": "radionuklid_specific_effective_activity",
    "15": "nd_selection_method",
    "16": "acceptable_content"
  },
  "65": {
    "0": "adress",
    "1": "sample_name",
    "2": "selection_place",
    "3": "research_date",
    "4": "research_method",
    "5": "coal_zolality",
    "6": "volume",
    "7": "partions_number",
    "8": "production_date",
    "9": "research_done_with",
    "10": "check_certificate",
    "11": "research_on_nd",
    "12": "measurement_results",
    "13": "sample_name_2",
    "14": "specific_activity_sum_msua",
    "15": "nd_selection_method",
    "16": "acceptable_level",
    "17": "coal_radiation_danger",
    "18": "coal_conditions"
  },
  "66": {
    "0": "full_name",
    "1": "passport",
    "2": "home_adress",
    "3": "gender_age_weight",
    "4": "additional_data",
    "5": "measurement_done_with_tool",
    "6": "check_certificate",
    "7": "measurement_done_in_geometry",
    "8": "research_on_nd",
    "9": "pp_number",
    "10": "measurement_organization",
    "11": "specific_effective_activity_cs137",
    "12": "specific_effective_activity_mn54b",
    "13": "specific_effective_activity_cr51",
    "14": "specific_effective_activity_co60",
    "15": "nd_selection_method",
    "16": "specific_effective_activity_i131"
  },
  "67": {
    "0": "adress",
    "1": "sample_name",
    "2": "selection_sample_date",
    "3": "iii_object_used",
    "4": "research_method",
    "5": "research_done_with",
    "6": "check_certificate",
    "7": "research_on_nd",
    "8": "sources_sketcg",
    "9": "pp_number",
    "10": "dirty_place_control",
    "11": "nd_selection_method",
    "12": "observed_activity",
    "13": "alfa_activity_sum",
    "14": "beta_activity_sum"
  },
  "68": {
    "0": "full_name",
    "1": "passport",
    "2": "home_adress",
    "3": "additional_data",
    "4": "research_done_with",
    "5": "check_certificate",
    "6": "research_done_on_1m_distance",
    "7": "nd_selection_method",
    "8": "form_table"
  },
  "69": {
    "0": "adress",
    "1": "food_name",
    "2": "selection_date",
    "3": "delivery_date",
    "4": "volume",
    "5": "partion_number",
    "6": "production_date",
    "7": "expiration_date",
    "8": "research_date",
    "9": "additional_data",
    "10": "nd_selection_method",
    "11": "research_on_nd"
  },
  "70": {
    "0": "selection_sample_place",
    "1": "sample_type",
    "2": "normative_document_type",
    "3": "probe_selection_date_time",
    "4": "probe_delivery_date_time",
    "5": "measurement_tools_used_for_selection",
    "6": "check_data",
    "7": "place_details",
    "8": "relief",
    "9": "green_array",
    "10": "its_height",
    "11": "dirty_source_distance",
    "12": "near_objects",
    "13": "selector_full_name",
    "14": "representative_position_full_name",
    "15": "form_table",
    "16": "filter_atmosphere_air",
    "17": "form_table"
  },
  "71": {
    "0": "adress",
    "1": "selection_air_place",
    "2": "normative_document_of_selection",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "transport_conditions",
    "6": "storage_conditions",
    "7": "tools_used_for_selection",
    "8": "check_data",
    "9": "room_characteristic",
    "10": "working_personal_number",
    "11": "main_dirty_sources",
    "12": "place_sketch_dot_numbers",
    "13": "representative_full_name",
    "14": "technological_process_characteristic",
    "15": "working_equipment_percentage",
    "16": "has_hand_operations",
    "17": "selector_full_name",
    "18": "measurements_results",
    "19": "form_table",
    "20": "form_table"
  },
  "72": {
    "0": "adress",
    "1": "adress_specific",
    "2": "measurement_goal",
    "3": "measurement_done_with_representative",
    "4": "photoelectric_luximeter",
    "5": "check_data",
    "6": "certificate_date_number",
    "7": "normative_document_measurement",
    "8": "place_sketch",
    "9": "fake_lightning_measurement_result",
    "10": "form_table1",
    "11": "research_on_nd",
    "12": "form_table"
  },
  "73": {
    "0": "adress",
    "1": "measurement_place",
    "2": "adress_details",
    "3": "measurement_goal",
    "4": "measurement_done_with_representative",
    "5": "full_name_position",
    "6": "measurement_tool",
    "7": "name_mark_inventory_number",
    "8": "check_data",
    "9": "certificate_date_number",
    "10": "result_normative_document",
    "11": "room_characteristic",
    "12": "room_sketch",
    "13": "air_meteo_research_results",
    "14": "air_temp",
    "15": "pressure",
    "16": "form_table1",
    "17": "form_table"
  },
  "74": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goals",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "research_date",
    "9": "nd_on_research_method",
    "10": "transport_conditions",
    "11": "storage_conditions",
    "12": "water_conservation_methods",
    "13": "nd_selection_method",
    "14": "form_table"
  },
  "75": {
    "0": "adress",
    "1": "sample_name",
    "2": "place_of_selection",
    "3": "research_goals",
    "4": "selection_date",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "delivery_date",
    "9": "research_date",
    "10": "nd_on_research_method",
    "11": "transport_conditions",
    "12": "storage_conditions",
    "13": "nd_selection_method",
    "14": "form_table"
  },
  "76": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "sample_name",
    "3": "number",
    "4": "research_goals",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "selection_date",
    "9": "delivery_date",
    "10": "research_date",
    "11": "nd_on_research_method",
    "12": "transport_conditions",
    "13": "storage_conditions",
    "14": "additional_data",
    "15": "form_table"
  },
  "77": {
    "0": "applicant_full_name",
    "1": "adress_age",
    "2": "place_of_work",
    "3": "foundation_place_prediction",
    "4": "type_of_dick",
    "5": "nd_selection_method",
    "6": "research_on_nd"
  },
  "78": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "research_date",
    "9": "nd_on_research_method",
    "10": "additional_data",
    "11": "form_table"
  },
  "80": {
    "0": "applicant_full_name",
    "1": "age_adress",
    "2": "place_of_work_adress",
    "3": "type_of_research",
    "4": "found_while_research",
    "5": "helmint_eggs",
    "6": "volume",
    "7": "partions_number",
    "8": "production_date",
    "9": "cists",
    "10": "nd_selection_method",
    "11": "research_on_nd"
  },
  "81": {
    "0": "blood_research",
    "1": "including",
    "2": "tonkih_mazkov",
    "3": "thicc_drops",
    "4": "applicant_full_name",
    "5": "age",
    "6": "home_adress",
    "7": "malyariaya",
    "8": "rod_type_parasite",
    "9": "parasitemy_intensity",
    "10": "volume",
    "11": "partions_number",
    "12": "production_date",
    "13": "nd_selection_method",
    "14": "research_on_nd"
  },
  "82": {
    "0": "applicant_full_name",
    "1": "diagnosis",
    "2": "while_researching",
    "3": "found",
    "4": "reaction_type",
    "5": "antibodies",
    "6": "volume",
    "7": "partions_number",
    "8": "production_date",
    "9": "antigenes",
    "10": "research_results",
    "11": "nd_selection_method",
    "12": "research_on_nd"
  },
  "83": {
    "0": "applicant",
    "1": "sample_registration_number",
    "2": "samples_names_and_numbers",
    "3": "tara_package_marks",
    "4": "production_date",
    "5": "expiration_date",
    "6": "volume",
    "7": "partions_number",
    "8": "production_date",
    "9": "expiration_date",
    "10": "selection_date_representative",
    "11": "sample_obtaining_date",
    "12": "research_goals",
    "13": "research_done_on_nd",
    "14": "research_results",
    "15": "research_results_date",
    "16": "nd_selection_method",
    "17": "research_on_nd"
  },
  "84": {
    "0": "adress",
    "1": "place_of_selection",
    "2": "research_goal",
    "3": "selection_date",
    "4": "delivery_date",
    "5": "research_date",
    "6": "nd_on_research_method",
    "7": "transport_conditions",
    "8": "storage_conditions",
    "9": "form_table"
  },
  "85": {
    "0": "adress",
    "1": "food_name",
    "2": "selection_date",
    "3": "expiration_date",
    "4": "delivery_date",
    "5": "research_date",
    "6": "additional_data",
    "7": "form_table"
  },
  "87": {
    "0": "adress",
    "1": "measurement_place",
    "2": "measurement_done_with_representative",
    "3": "measurement_goal",
    "4": "name_type_factory_number",
    "5": "check_data",
    "6": "certificate_date_number",
    "7": "form_table"
  },
  "88": {
    "0": "adress",
    "1": "place_of_measurements",
    "2": "measurement_goal",
    "3": "measurement_done_with_representative",
    "4": "measurement_tool",
    "5": "inventory",
    "6": "check_data",
    "7": "certificate_date_number",
    "8": "research_on_nd",
    "9": "form_table"
  },
  "285": {
    "0": "applicant_full_name",
    "1": "age",
    "2": "home_adress",
    "3": "place_of_work",
    "4": "diagnosis",
    "5": "forwarder_org_name",
    "6": "sample_name",
    "7": "first_or_repeated",
    "8": "sample_obtaining_date",
    "9": "probe_delivery_date",
    "10": "transport_conditions",
    "11": "research_goal",
    "12": "research_results",
    "13": "antibodies",
    "14": "nd_selection_method",
    "15": "antigenes",
    "16": "research_on_nd"
  },
  "286": {
    "0": "applicant_full_name",
    "1": "age",
    "2": "home_adress",
    "3": "place_of_work",
    "4": "diagnosis",
    "5": "forwarder_full_name",
    "6": "sample_name",
    "7": "sample_obtaining_date",
    "8": "sample_delivery_date",
    "9": "research_goal",
    "10": "research_results",
    "11": "form_table"
  },
  "287": {
    "0": "applicant_full_name",
    "1": "age",
    "2": "home_adress",
    "3": "place_of_work",
    "4": "diagnosis",
    "5": "forwarder_org_name",
    "6": "sample_name",
    "7": "first_or_repeated",
    "8": "sample_obtaining_date",
    "9": "sample_delivery_date",
    "10": "research_goal",
    "11": "nd_selection_method",
    "12": "serelogic_research_results",
    "13": "iersinoz",
    "14": "pseudotuberkulez",
    "15": "leptospiroz",
    "16": "listerioz",
    "17": "pasterelliz",
    "18": "tiff",
    "19": "tylaremia",
    "20": "brucellez",
    "21": "hedelson_reaction",
    "22": "rait_reaction",
    "23": "rbp",
    "24": "rsk",
    "25": "syberia_yazva"
  },
  "288": {
    "0": "applicant_full_name",
    "1": "age",
    "2": "home_adress",
    "3": "diagnosis",
    "4": "forwarder_org_name",
    "5": "volume",
    "6": "partions_number",
    "7": "production_date",
    "8": "sample_name",
    "9": "sample_delivery_date",
    "10": "sample_obtaining_date",
    "11": "research_goal",
    "12": "research_results",
    "13": "form_table"
  },
  "289": {
    "0": "applicant_full_name",
    "1": "iin",
    "2": "age",
    "3": "home_adress",
    "4": "place_of_work",
    "5": "contact_number",
    "6": "diagnosis",
    "7": "forwarder_full_name",
    "8": "sample_name",
    "9": "sample_delivery_date",
    "10": "sample_obtaining_date",
    "11": "transport_conditions",
    "12": "research_goal",
    "13": "research_results"
  }
}
