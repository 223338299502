import { 
    REQUEST_GET_REQUEST, REQUEST_GET_SUCCESS, REQUEST_GET_ERROR, 
    REQUEST_GET_BY_KEY_REQUEST, REQUEST_GET_BY_KEY_SUCCESS, REQUEST_GET_BY_KEY_ERROR, 
    REQUEST_ADD_REQUEST, REQUEST_ADD_SUCCESS, REQUEST_ADD_ERROR, 
    REQUEST_EDIT_REQUEST, REQUEST_EDIT_SUCCESS, REQUEST_EDIT_ERROR, 
    REQUEST_DELETE_REQUEST, REQUEST_DELETE_SUCCESS, REQUEST_DELETE_ERROR
} from "../constants/requestConstants";

const initialState = {
    requests: [],
    request: {},
    loading: false,
    success: null,
    error: null,
    requestRequestedCount: 0,
};

export const requestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REQUEST_GET_REQUEST:
            return {
                ...state,
                requestRequestedCount: ++state.requestRequestedCount,
                loading: true,
            };
        case REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                requests: action.payload,
                success: true,
            };
        case REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                request: action.payload,
                success: true,
            };
        case REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
