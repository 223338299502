import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BasicModalHeader,
  BasicModalContainer,
  Form,
} from "../../../components";
import { DatePicker, DateTimePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import EmployeeField from "../../../components/Relations/EmployeeField";
import {GeoObjectSelect, GovernmentStandardsSelect,
} from "../../../components/Select/domainSelects";
import useLoader from "../../../hooks/useLoader";
import { getActForms } from "../../../redux/actions/actFormActions";
import { getGovernmentStandards } from "../../../redux/actions/governmentStandardActions";
import { getStorageConditions } from "../../../redux/actions/storageConditionActions";
import { getTransportationConditions } from "../../../redux/actions/transportationConditionActions";
import ObjectField from "../../../components/Relations/ObjectField";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

export const DddDisinsectionCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const actForm = useSelector((s) => s.actForm);

  const handleSubmit = async (e) => {
    setLoading(true);

    await onSubmit(e, data);

    setLoading(false);
  };

  const governmentStandardValues = useSelector(
    (state) => state.governmentStandard
  );
  const transportationConditionValues = useSelector(
    (state) => state.transportationCondition
  );
  const storageConditionValues = useSelector((state) => state.storageCondition);


  const handleChangeMulti = useCallback(
    (obj) => {
      setData({ ...data, ...obj });
    },
    [data]
  );

  const handleChangeData = useCallback(
    (key, value, key2) => {
      setData({ ...data, data: { ...data.data, [key]: { key: key2, value } } });
    },
    [data]
  );

  const handleChangeSample = useCallback(
    (key, value, i, key2) => {
      const array = data.data?.samples?.value.slice(0);
      array[i][key] = { key: key2, value };
      setData({
        ...data,
        data: {
          ...data.data,
          samples: { key: "samples", value: array },
        },
      });
    },
    [data]
  );

  useEffect(() => {
    if (actForm.actForms.length === 0) {
      dispatch(getActForms());
    }
  }, [actForm.actForms.length]);

  useEffect(() => {
    if (governmentStandardValues.governmentStandards.length === 0) {
      dispatch(getGovernmentStandards());
    }
    if (transportationConditionValues.transportationConditions.length === 0) {
      dispatch(getTransportationConditions());
    }
    if (storageConditionValues.storageConditions.length === 0) {
      dispatch(getStorageConditions());
    }
  }, [dispatch]);

  const [isDisabledData, setIsDisabledData] = useState(false);

  const addSample = useCallback(() => {
    data.data?.samples?.value
        ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, {}] } } })
        : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [{}] } } })
  }, [data]);

  const deleteSample = useCallback((index) => {
    setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i) } } })
  }, [data]);

  return (
    <>
      <BasicModalHeader heading="НАРЯД на дезинcекцию, дератизацию" />
      <BasicModalContainer>
        <Form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                  display: "flex",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
            >
              <ObjectField
                  label={"Шаруашылық жүргізуші объектінің (Полное наименование хозяйствующего объекта)"} fullWidth
                  object={data.data?.object?.value}
                  value={data.data?.object?.value?.id}
                  disabled={isDisabledData}
                  onChange={(obj) => { handleChangeData('object', obj, 'facility') }}
              />
            </div>
            <div
                style={{
                  display: "flex",
                  marginBottom: "5px",
                  alignItems: "center",
                }}
            >
              <p style={{ width: "50%" }}>Мекен-жайы (Адрес)</p>
              <div style={{ width: "50%", marginLeft: "30px" }}>
                <GeoObjectSelect
                    id="outlined-basic"
                    label="Адрес"
                    fullWidth
                    value={data.addressId}
                    onChange={(id, object) => {
                      const obj = {
                        addressId: id,
                        addressLine: object.name,
                      };
                      handleChangeMulti(obj);
                    }}
                />
              </div>
            </div>

            <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
            >
              <p style={{ width: "50%" }}>
                Жәндіктің, кеміргіштің түрі (Вид насекомого, грызуна)
              </p>
              <TextField
                  id="outlined-basic"
                  label="Вид насекомого, грызуна"
                  style={{ width: "50%", marginLeft: "30px" }}
                  variant="outlined"
                  value={data.data?.insectType?.value}
                  onChange={(e) => handleChangeData('insectType', e.target.value, 'insectType')}
              />
            </div>
            <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
            >
              <p style={{ width: "50%" }}>
                Алаңда шарт өтінім бойынша дезинсекциялау,
                дератизациялау(Выполнена дезинсекция, дератизация по договору или
                заявке на площади)
              </p>
              <TextField
                  id="outlined-basic"
                  label="площадь (м2)"
                  style={{ width: "50%", marginLeft: "30px" }}
                  variant="outlined"
                  type="number"
                  value={data.data?.isDisinsectionComplete?.value}
                  onChange={(e) => handleChangeData('isDisinsectionComplete', e.target.value, 'isDisinsectionComplete')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">(м2)</InputAdornment>,
                  }}
              />
            </div>
            <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
            >
              <p style={{ width: "50%" }}>Өңдеу күні (Дата обработки)</p>
              <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                <DatePicker views={['day']} label="Дата обработки"
                    // disabled={isDisabledData}
                            value={data.data?.treatmentDate?.value}
                            onChange={(value) => handleChangeData('treatmentDate', value, 'treatmentDate')}
                            minDate={new Date('1910-03-01')} renderInput={(params) =>
                    <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
                } />
              </LocalizationProvider>
            </div>
            <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
            >
              <p style={{ width: "50%" }}>
                Тапсырыс беруші өкілінің тегі, аты, әкесінің аты (болған жағдайда)
                (Фамилия, имя, отчество (при наличии) ответственного представителя заказчика)
              </p>
              <TextField
                  id="outlined-basic"
                  label="ФИО"
                  style={{ width: "50%", marginLeft: "30px" }}
                  variant="outlined"
                  value={data.data?.customerName?.value}
                  onChange={(e) => handleChangeData('customerName', e.target.value, 'customerName')}
              />
            </div>
            <div
                style={{
                  display: "flex",
                  marginBottom: "20px",
                  alignItems: "center",
                }}
            >
              <p style={{ width: "50%" }}>
                Жұмыс орындалды, наразылық жоқ (Работа выполнена, претензий нет)
              </p>
              <TextField
                  id="outlined-basic"
                  label="Претензий не имею"
                  style={{ width: "50%", marginLeft: "30px" }}
                  variant="outlined"
                  value={data.data?.haveNoComplaints?.value}
                  onChange={(e) => handleChangeData('haveNoComplaints', e.target.value, 'haveNoComplaints')}
              />
            </div>
            <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
              <Table
                  aria-label="simple table"
                  className="border-table-word"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="border-table-word">
                      Обработаны вещи, помещения, территории
                    </TableCell>
                    <TableCell className="border-table-word">
                      НД на метод испытаний
                    </TableCell>
                    <TableCell align="center" colSpan={2} >
                      Расход инсектицидов, ратициды
                    </TableCell>
                    <TableCell className="border-table-word">
                      Действия
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="border-table-word">
                    </TableCell>
                    <TableCell className="border-table-word">
                    </TableCell>
                    <TableCell className="border-table-word">
                      Атауы(Наименование)
                    </TableCell>
                    <TableCell className="border-table-word">
                      Саны(Количество (кг))
                    </TableCell>
                    <TableCell className="border-table-word">
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.data?.samples?.value?.map((sample, i) => {
                    return <TableRow key={i}>
                      <TableCell className="border-table-word">
                        <TextField
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            label="Обработаны вещи, помещения, территории"
                            variant="outlined"
                            value={sample.processedThings?.value}
                            onChange={(e) => handleChangeSample('processedThings', e.target.value, i, 'processedThings')}
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <GovernmentStandardsSelect
                            label={"НД на метод испытаний"}
                            fullWidth
                            value={sample.standard?.value?.id}
                            disabled={isDisabledData}
                            onChange={(val, obj) => handleChangeSample('standard', obj, i, 'nd_selection')}
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <TextField
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            label="Наименование"
                            variant="outlined"
                            value={sample.name?.value}
                            onChange={(e) => handleChangeSample('name', e.target.value, i, 'name')}
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <TextField
                            style={{ width: '100%' }}
                            id="outlined-basic"
                            label="Количество (кг)"
                            variant="outlined"
                            type="number"
                            value={sample.quantity?.value}
                            onChange={(e) => handleChangeSample('quantity', e.target.value, i, 'quantity')}
                        />
                      </TableCell>
                      <TableCell className="border-table-word">
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <IconButton color="error"
                                      size="large"
                                      aria-label="delete"
                                      disabled={isDisabledData}
                                      onClick={() => { deleteSample(i) }}>
                            <CancelIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div
                style={{
                  display: "flex",
                  marginBottom: "30px",
                  alignItems: "center",
                }}
            >
              <Button
                  disabled={isDisabledData}
                  variant="contained"
                  onClick={addSample}
              >
                Добавить
              </Button>
            </div>
            <div
                style={{
                  display: "flex",
                  marginBottom: "10px",
                  alignItems: "center",
                }}
            >
              <EmployeeField
                  label={"Фамилия, имя, отчество (при наличии), лица выполнявшего дезинсекцию, дератизацию"}
                  fullWidth
                  object={data.disinfector}
                  value={data.disinfectorId}
                  onChange={(disinfector) => {
                    const obj = {
                      disinfector,
                      disinfectorId: disinfector.id,
                    };
                    handleChangeMulti(obj);
                  }}
              />
            </div>
          </div >
          <LoadingButton loading={loading} onClick={handleSubmit}>
            Создать
          </LoadingButton>
        </Form>
      </BasicModalContainer>
    </>
  );
};
