export const DESINFECTION_REFERENCE_GET_REQUEST = 'DESINFECTION_REFERENCE_GET_REQUEST';
export const DESINFECTION_REFERENCE_GET_SUCCESS = 'DESINFECTION_REFERENCE_GET_SUCCESS';
export const DESINFECTION_REFERENCE_GET_ERROR = 'DESINFECTION_REFERENCE_GET_ERROR';
export const DESINFECTION_REFERENCE_GET_BY_KEY_REQUEST = 'DESINFECTION_REFERENCE_GET_BY_KEY_REQUEST';
export const DESINFECTION_REFERENCE_GET_BY_KEY_SUCCESS = 'DESINFECTION_REFERENCE_GET_BY_KEY_SUCCESS';
export const DESINFECTION_REFERENCE_GET_BY_KEY_ERROR = 'DESINFECTION_REFERENCE_GET_BY_KEY_ERROR';
export const DESINFECTION_REFERENCE_ADD_REQUEST = 'DESINFECTION_REFERENCE_ADD_REQUEST';
export const DESINFECTION_REFERENCE_ADD_SUCCESS = 'DESINFECTION_REFERENCE_ADD_SUCCESS';
export const DESINFECTION_REFERENCE_ADD_ERROR = 'DESINFECTION_REFERENCE_ADD_ERROR';
export const DESINFECTION_REFERENCE_EDIT_REQUEST = 'DESINFECTION_REFERENCE_EDIT_REQUEST';
export const DESINFECTION_REFERENCE_EDIT_SUCCESS = 'DESINFECTION_REFERENCE_EDIT_SUCCESS';
export const DESINFECTION_REFERENCE_EDIT_ERROR = 'DESINFECTION_REFERENCE_EDIT_ERROR';
export const DESINFECTION_REFERENCE_DELETE_REQUEST = 'DESINFECTION_REFERENCE_DELETE_REQUEST';
export const DESINFECTION_REFERENCE_DELETE_SUCCESS = 'DESINFECTION_REFERENCE_DELETE_SUCCESS';
export const DESINFECTION_REFERENCE_DELETE_ERROR = 'DESINFECTION_REFERENCE_DELETE_ERROR';
