import React, {useState} from 'react';
import {Button, Dialog, DialogContent, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import RequestCRUDTable from "../CRUD/Tables/RequestCRUDTable";
import SimpleTable from "../SimpleTable";
import Toolbar from "@mui/material/Toolbar";

export default function RequestField({value, object, onChange}) {
  const [open, setOpen] = useState(false);

  return <>
    <Grid container spacing={1} style={{paddingLeft: 10}}>
      {/* Field Line */}
      <Grid item xs={12}>
        <Toolbar>
          <Typography variant={"h6"}>Заявка</Typography>
          <Button onClick={() => setOpen(true)}>Выбрать</Button>
        </Toolbar>
      </Grid>
      {/* Info Line */}
      <Grid container spacing={1} item xs={12}>
        <SimpleTable data={object || {}} columns={[
          {label: "ID", code: "id"},
          {label: "Номер", code: "number"},
          {label: "Тип", code: "type.name"},
          {label: "Состояние", code: "state.name"},
          {label: "Дата", code: "createdAt", type: 'datetime'},
          {label: "Филиал", code: "filial.name"},
        ]} defaultEmptyValue={"-"} />
      </Grid>
    </Grid>
    {/* Dialog Select*/}
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={"80%"}>
      <DialogTitle>Выбор заявки</DialogTitle>
      <Divider/>
      <DialogContent>
        <RequestCRUDTable
          onClickCreateButton={undefined} // что бы не показывать кнопку создания
          columns={[
            { title: 'ID', dataIndex: 'id', key: 'id' },
            { title: 'Номер', dataIndex: 'number', key: 'number' },
            { title: 'Тип', dataIndex: ['type', 'name'], key: 'type.name' },
            { title: 'Состояние', dataIndex: ['state', 'name'], key: 'state.name' },
            { title: 'Дата', dataIndex: 'createdAt', key: 'createdAt' },
            { title: 'Филиал', dataIndex: ['filial', 'name'], key: 'filial.name' },
          ]} defaultEmptyValue={"-"}
          onRowClick={(record, rowIndex) => {
            onChange(record);
            setOpen(false);
          }}
        />
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Button variant={"text"} style={{marginLeft: "auto"}} onClick={() => setOpen(false)}>Отменить</Button>
      </DialogActions>
    </Dialog>
  </>
}
