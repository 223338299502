import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {accreditationCRUD} from "../../../http";
import {ACCREDITATION_DETAIL_ROUTE } from "../../../routes";
import React from "react";
import { mapOrganizationName, mapOrganizationValues } from "../../../functions/viewDataMap";
import { toUIJson } from "../../../functions";

export default function AccreditationCRUDTable({withOrganization, ...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("filialList.tableItem.id"), dataIndex: "id", key:"id"},
        {title: "Наименование", dataIndex: "name", key:"name"},
        {
            title: 'Знак',
            dataIndex: "sign",
            render: sign => <img width="100" src={toUIJson(sign) ? toUIJson(sign).base64 : null} />  
        },
        {
            title: t("requestLockList.tableItem.filial"),
            dataIndex: "filial",
            key: "filial",
            render: (value, row) => value?.name,
        },
        {
            title: "Районное отделение",
            dataIndex: "filialDivision",
            key: "filialDivision",
            render: (value, row) => value?.name,
        },
        {title: t("divisionList.tableItem.department"), dataIndex: "department", key:"department",render: (unknown, row) => row.department ? row.department.name : <span style={{color: "gray"}}>Не задано</span>},
        {title: "Отделение лаборотории", dataIndex: "division", key:"division",render: (unknown, row) => row.division ? row.division.name : <span style={{color: "gray"}}>Не задано</span>},

    ]

    const searchFields = [
        {
            title: "Наименование",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Организация",
            key: "organizationId",
            compareType: "equals",
            reducerKey: "organization",
            options: mapOrganizationValues(),         
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ]

    return <CRUDTable
        search={accreditationCRUD.search}
        columns={columns}
        onClickCreateButton={() => push(ACCREDITATION_DETAIL_ROUTE("new"))}
        // onRowClick={(record, rowIndex) => push({
        //     pathname: ACCREDITATION_DETAIL_ROUTE(record.id),
        //     state:{
        //         record
        //     }
        // })}
        reducerFilterKey={"accreditationId"}
        reducerParentKey={"id"}
        searchFields={searchFields}
        filterFields = {filterFields}
        {...props}
    />
}