import DirectionParasiteForm from "./DirectionParasiteForm";
import DirectionParasitePDFGenerator from "./DirectionParasitePDFGenerator";

import withDirectionDetail from "../withDirectionDetail";

const directionFormKazakhName = 'Санитариялық-паразитологиялық зерттеуге ЖОЛДАМА'
const directionFormName = 'НАПРАВЛЕНИЕ на санитарно-паразитологическое исследование'
const directionFormNumber = '018'
const directionName = 'DirectionParasite'

export default withDirectionDetail(DirectionParasiteForm, DirectionParasitePDFGenerator, directionFormKazakhName,  directionFormName,  directionFormNumber, directionName)