export function convertIINtoBirthdayDate(iin) {
    // Check if the provided IIN is valid
    if (!isValidIIN(iin)) {
        return null;
    }

    // Extract the birth date information from the IIN
    const yearPrefix = (iin.charAt(6) === '3' || iin.charAt(6) === '4') ? '19' : '20';
    const birthYear = yearPrefix + iin.substr(0, 2);
    const birthMonth = iin.substr(2, 2);
    const birthDay = iin.substr(4, 2);

    // Create a Date object with the extracted birth date information
    const birthday = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

    return birthday;
}
  
  // Function to check if the provided IIN is valid
export function isValidIIN(iin) {
    // IIN should be a string of 12 digits
    if (!/^\d{12}$/.test(iin)) {
      return false;
    }
    return true;
}
