import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import ObjectField from "../../../components/Relations/ObjectField";
import {
    PurposeOfSelectionsSelect,
    StorageConditionSelect,
    TransportationConditionSelect
} from "../../../components/Select/domainSelects";
import GovernmentField from "../../../components/Relations/GovernmentField";
import AnObjectField from "../../../components/Relations/AnObjectField";

const ActProductsForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample, addSample, deleteSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
          <AnObjectField
              bold={false}
              label={"1. Объектінің атауы (Наименование объекта)"}
              value={data.data?.object?.value}
              disabled={isDisabledData}
              onChange={(obj) => {
                  handleChangeData('object', obj, 'facility')
              }}
          />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Тексеру белгіленгені туралы актінің нөмірі мен күні (Номер и дата акта о назначении проверки)
        </p>
        <TextField
          id="outlined-basic"
          label="Номер акта"
          disabled={isDisabledData}
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          value={data.data?.numberandDate?.value}
          onChange={(e) => handleChangeData('numberandDate', e.target.value, 'numberandDate')}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Тағайындау актісі күні (Дата акта о назначении проверки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата акта"
            disabled={isDisabledData}
            value={data.data?.date?.value}
            onChange={(value) => {
              handleChangeData('date', value, 'date')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div> */}
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          4. Сынама үлгісі алынған орын, мекенжай (Место отбора образца, адрес)
        </p>
        <TextField
          id="outlined-basic"
          label="Место отбора образца, адрес"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          disabled={isDisabledData}
          variant="outlined"
          value={data.data?.selectionPlace?.value}
          onChange={(e) => handleChangeData('selectionPlace', e.target.value, 'selection_place')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5. Сынамалар алу мақсаты (Цель отбора):
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <PurposeOfSelectionsSelect
            label={"Сынамалар алу мақсаты (Цель отбора)"}
            fullWidth
            value={data.data?.samplingPurpose?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('samplingPurpose', obj, 'samplingPurpose') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5.1 Наименования нормативных документов
        </p>
        <TextField
          id="outlined-basic"
          label="Наименования нормативных документов"
          style={{ width: "50%",  margin: "10px 0 10px 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.documentsName?.value}
          onChange={(e) =>
            handleChangeData(
              "documentsName",
              e.target.value,
              "documentsName"
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Алынған күні мен уақыты (Дата и время отбора)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время отбора"
            disabled={isDisabledData}
            value={data.data?.selectionDateTime?.value}
            onChange={(value) => handleChangeData('selectionDateTime', value, 'selection_time')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Жарамдылық мерзімі (Срок годности)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Срок годности"
            disabled={isDisabledData}
            value={data.data?.expirationDate?.value}
            onChange={(value) => handleChangeData('expirationDate', value, 'expirationDate')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Жеткізілген күні мен уақыты (Дата и время доставки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время доставки"
            disabled={isDisabledData}
            value={data.data?.deliveryDateTime?.value}
            onChange={(value) => handleChangeData('deliveryDateTime', value, 'delivery_time')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
          метод отбора)
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
            <GovernmentField
                fullWidth
                disabled={isDisabledData}
                label={null}
                array={data.data?.standard?.value}
                onChange={(val) => { handleChangeData('standard', val, 'nd_selection') }}
            />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          10. Тасымалдау жағдайы (Условия транспортировки)
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <TransportationConditionSelect
            label={"Условия транспортировки"}
            fullWidth
            value={data.data?.transportationCondition?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('transportationCondition', obj, 'transportation_conditions') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>11. Сақтау жағдайы (Условия хранения)</p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <StorageConditionSelect
            label={"Условия хранения"}
            fullWidth
            value={data.data?.storageCondition?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('storageCondition', obj, 'storage_conditions') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          12. Орама түрі пломба нөмірі (Вид упаковки номер печати (пломбы))
        </p>
        <TextField
          style={{ width: "50%", margin: "0 0 0 30px" }}
          id="outlined-basic"
          label="Вид упаковки и номер печати (пломбы)"
          variant="outlined"
          type="sealTypeAndNumber"
          disabled={isDisabledData}
          value={data.data?.sealTypeAndNumber?.value}
          onChange={(e) => handleChangeData('sealTypeAndNumber', e.target.value, 'sealTypeAndNumber')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          13. Қосымша мәліметтер (Дополнительные сведения)
        </p>
        <TextField
          id="outlined-basic"
          label="Дополнительные сведения"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.additionalInfo?.value}
          onChange={(e) => handleChangeData('additionalInfo', e.target.value, 'additionalInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          14. ФИО присутствующего при отборе
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО присутствующего при отборе"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.representativeFullName?.value}
          onChange={(e) => handleChangeData('representativeFullName', e.target.value, 'representativeFullName')}
        />
      </div>
      <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
        <Table
          aria-label="simple table"
          className="border-table-word"
        >
          <TableHead>
            <TableRow>
              <TableCell className="border-table-word">
                Тiркелу нөмірi (Регистрационный номер)
              </TableCell>
              <TableCell className="border-table-word">
                Алынған үлгілер тізімі (Перечень отобранных образцов)
              </TableCell>
              <TableCell className="border-table-word">
                Өндірушісі, сериясы (партия нөмірі) көрсетілген сынама үлгілерінің саны (Количество отобранных образцов продукции с указанием производителя, серия (номера партии)
              </TableCell>
              <TableCell className="border-table-word">
                Шығарылған күні (Дата производства)
              </TableCell>
              <TableCell className="border-table-word">
                Сынаманың салмағы, көлемі (Вес, объем образца)
              </TableCell>
              <TableCell className="border-table-word">
                Сақтау мерзімі (Cрок годности)
              </TableCell>
              <TableCell className="border-table-word">
                Күні, ТАӘ, үлгіні (сынама) қабылдаған тұлғаның қолы Дата, ФИО, подпись лица принявшего образец (пробу)
              </TableCell>
              <TableCell className="border-table-word">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.samples?.value?.map((sample, i) => {
              return <TableRow key={i}>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Регистрационный номер"
                    variant="outlined"
                    type="number"
                    disabled={isDisabledData}
                    value={sample.registrationNumber?.value}
                    onChange={(e) => handleChangeSample('registrationNumber', e.target.value, i, 'registrationNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Перечень отобранных образцов"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.sample?.value}
                    onChange={(e) => handleChangeSample('sample', e.target.value, i, 'sample')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Количество отобранных образцов"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.quantity?.value}
                    type="number"
                    onChange={(e) => handleChangeSample('quantity', e.target.value, i, 'quantity')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker views={['day']} label="Дата производства"
                      disabled={isDisabledData}
                      value={sample.manufactureInfo?.value}
                      onChange={(value) => handleChangeSample('manufactureInfo', value, i, 'manufactureInfo')}
                      minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{ width: "100%" }} margin="normal"  {...params} helperText={null} />
                      } />
                  </LocalizationProvider>
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Вес, объем образца"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.weightSize?.value}
                    onChange={(e) => handleChangeSample('weightSize', e.target.value, i, 'weightSize')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker views={['day']} label="Cрок годности"
                      disabled={isDisabledData}
                      value={sample.expirationDate?.value}
                      onChange={(value) => handleChangeSample('expirationDate', value, i, 'expirationDate')}
                      minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{ width: "100%" }} margin="normal"  {...params} helperText={null} />
                      } />
                  </LocalizationProvider>
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Дата, ФИО, подпись лица принявшего образец"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.receiptInfo?.value}
                    onChange={(e) => handleChangeSample('receiptInfo', e.target.value, i, 'receiptInfo')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <IconButton color="error"
                      size="large"
                      aria-label="delete"
                      disabled={isDisabledData}
                      onClick={() => { deleteSample(i) }}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isDisabledData}
          variant="contained"
          onClick={addSample}
        >
          Добавить
        </Button>
      </div>
    </div >
  );
};

export default ActProductsForm;
