import { organizationFilialMapCRUD } from "../../http";
import { 
    ORGANIZATION_FILIAL_MAP_GET_REQUEST, ORGANIZATION_FILIAL_MAP_GET_SUCCESS, ORGANIZATION_FILIAL_MAP_GET_ERROR, 
    ORGANIZATION_FILIAL_MAP_GET_BY_KEY_REQUEST, ORGANIZATION_FILIAL_MAP_GET_BY_KEY_SUCCESS, ORGANIZATION_FILIAL_MAP_GET_BY_KEY_ERROR, 
    ORGANIZATION_FILIAL_MAP_ADD_REQUEST, ORGANIZATION_FILIAL_MAP_ADD_SUCCESS, ORGANIZATION_FILIAL_MAP_ADD_ERROR, 
    ORGANIZATION_FILIAL_MAP_EDIT_REQUEST, ORGANIZATION_FILIAL_MAP_EDIT_SUCCESS, ORGANIZATION_FILIAL_MAP_EDIT_ERROR, 
    ORGANIZATION_FILIAL_MAP_DELETE_REQUEST, ORGANIZATION_FILIAL_MAP_DELETE_SUCCESS, ORGANIZATION_FILIAL_MAP_DELETE_ERROR
} from "../constants/organizationFilialMapConstants";

export const getOrganizationFilialMaps = (args) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_GET_REQUEST
        });

        const data = await organizationFilialMapCRUD.search(args);
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getOrganizationFilialMapByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_GET_BY_KEY_REQUEST
        });

        const data = await organizationFilialMapCRUD.get(id);
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addOrganizationFilialMap = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_ADD_REQUEST
        });

        const response = await organizationFilialMapCRUD.create(data);
        await dispatch(getOrganizationFilialMaps(args));
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_ADD_SUCCESS,
        });
        return response.id;
    } catch (error) {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editOrganizationFilialMap = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_EDIT_REQUEST
        });

        const response = await organizationFilialMapCRUD.edit(data);
        await dispatch(getOrganizationFilialMaps(args));
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_EDIT_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteOrganizationFilialMap = (id, args) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_DELETE_REQUEST
        });

        const response = await organizationFilialMapCRUD.delete(id);
        await dispatch(getOrganizationFilialMaps(args));
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_DELETE_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: ORGANIZATION_FILIAL_MAP_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

