import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import { PageHeaderHeading } from "./PageHeaderHeading";
import { PageHeaderStatus } from "./PageHeaderStatus";
import { PageHeaderControls } from "./PageHeaderControls";

export const PageHeader = (props) => {
  const { heading, status, controls, mode, children } = props;

  return (
    <Grid
      container
      component={Paper}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "25px 30px",
        boxSizing: "border-box",
        minHeight: "50px",
      }}
      {...props}
    >
      {heading && (
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <PageHeaderHeading>{heading}</PageHeaderHeading>
        </Grid>
      )}
      {children ? <Grid item>{children}</Grid> : null}
      {controls ? (
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {controls}
        </Grid>
      ) : null}
    </Grid>
  );
};

PageHeader.defaultProps = {
  heading: "Страница",
};
