import React, {useState, useEffect} from "react";
import DashboardLayout from "../../layouts/Dashboard";

import { ObjectReferralCRUDTable } from "./../../components";
import { useLocation } from 'react-router-dom';

export const ObjectReferralTable = () => {
  const location = useLocation()
  const [stateId, setStateId] = useState(location?.search?.split('=')[1])
  useEffect(() => {
      if (location?.search) {
        setStateId(location?.search?.split('=')[1])
      } else {
        setStateId(null)
      }
  }, [location])
  return (
    <DashboardLayout>
      <ObjectReferralCRUDTable stateId={stateId}/>
    </DashboardLayout>
  )
};
