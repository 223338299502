import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BasicModalHeader,
  BasicModalContainer,
  Form,
} from "../../../components";
import { DatePicker, DateTimePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";
import EmployeeField from "../../../components/Relations/EmployeeField";
import {
    DepartmentSelect, DiagnosisSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect, GeoObjectSelect, GovernmentStandardsSelect, PurposeOfSelectionsSelect,
} from "../../../components/Select/domainSelects";
import useLoader from "../../../hooks/useLoader";
import { getActForms } from "../../../redux/actions/actFormActions";
import { getGovernmentStandards } from "../../../redux/actions/governmentStandardActions";
import { getStorageConditions } from "../../../redux/actions/storageConditionActions";
import { getTransportationConditions } from "../../../redux/actions/transportationConditionActions";
import ObjectField from "../../../components/Relations/ObjectField";
import GovernmentField from "../../../components/Relations/GovernmentField";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

export const DddFinalDisinfectionCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const actForm = useSelector((s) => s.actForm);

  const handleSubmit = async (e) => {
    setLoading(true);

    await onSubmit(e, data);

    setLoading(false);
  };


  const governmentStandardValues = useSelector(
    (state) => state.governmentStandard
  );
  const transportationConditionValues = useSelector(
    (state) => state.transportationCondition
  );
  const storageConditionValues = useSelector((state) => state.storageCondition);


  const handleChange = useCallback(
    (key, value) => {
      setData({ ...data, [key]: value });
    },
    [data]
  );
  const handleChangeMulti = useCallback(
    (obj) => {
      setData({ ...data, ...obj });
    },
    [data]
  );

  const handleChangeData = useCallback(
    (key, value, key2) => {
      setData({ ...data, data: { ...data.data, [key]: { key: key2, value } } });
    },
    [data]
  );

  const handleChangeSample = useCallback(
    (key, value, i, key2) => {
      const array = data.data?.samples?.value.slice(0);
      array[i][key] = { key: key2, value };
      setData({
        ...data,
        data: {
          ...data.data,
          samples: { key: "samples", value: array },
        },
      });
    },
    [data]
  );

  const schema = {};

  const {
    loading: fetchLoading,
    start: fetchStart,
    stop: fetchStop,
  } = useLoader(true);

  useEffect(() => {
    if (actForm.actForms.length === 0) {
      dispatch(getActForms());
    }
  }, [actForm.actForms.length]);

  useEffect(() => {
    if (governmentStandardValues.governmentStandards.length === 0) {
      dispatch(getGovernmentStandards());
    }
    if (transportationConditionValues.transportationConditions.length === 0) {
      dispatch(getTransportationConditions());
    }
    if (storageConditionValues.storageConditions.length === 0) {
      dispatch(getStorageConditions());
    }
  }, [dispatch]);

  const [isDisabledData, setIsDisabledData] = useState(false);
  const [isDisabledPerformer, setIsDisabledPerformer] = useState(false);

  const addSample = useCallback(() => {
    data.data?.samples?.value
        ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, {}] } } })
        : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [{}] } } })
  }, [data]);

  const deleteSample = useCallback((index) => {
    setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i) } } })
  }, [data]);

  return (
    <>
      <BasicModalHeader heading="НАРЯД на заключительную дезинфекцию" />
      <BasicModalContainer>
        <Form onSubmit={handleSubmit}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ margin: '0 auto' }}>
                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                        <DatePicker views={['day']} label="от"
                                    disabled={isDisabledData}
                                    value={data.data?.dateOfAct?.value}
                                    onChange={(value) => {
                                        handleChangeData('dateOfAct', value, 'dateOfAct')
                                    }}
                                    minDate={new Date('1910-03-01')} renderInput={(params) =>
                            <TextField margin="normal" {...params} helperText={null} />
                        } />
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "5px",
                        marginTop: "15px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Дезинфекциялауға өтінім қабылданды(Заявка на дезинфекцию принята)
                    </p>
                    <TextField
                        id="outlined-basic"
                        label="Заявка на дезинфекцию принята"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.disinfectionRequestAccepted?.value}
                        onChange={(e) => handleChangeData('disinfectionRequestAccepted', e.target.value, 'disinfectionRequestAccepted')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "5px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Уйымның атауы(наименование организации)
                    </p>
                    <TextField
                        id="outlined-basic"
                        label="Наименование организации"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.sendingOrganization?.value}
                        onChange={(e) => handleChangeData('sendingOrganization', e.target.value, 'sending_organization')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Науқастың жасы (возраст больного)</p>
                    <TextField
                        id="outlined-basic"
                        label="Возраст больного"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        type="number"
                        value={data.data?.age?.value}
                        onChange={(e) => handleChangeData('age', e.target.value, 'age')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Науқастың тегі, аты, әкесінің аты (Фамилия, имя, отчество больного)</p>
                    <TextField
                        id="outlined-basic"
                        label="Фамилия, имя, отчество больного"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.namePatient?.value}
                        onChange={(e) => handleChangeData('namePatient', e.target.value, 'name_patient')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Мекен-жайы (Адрес)</p>
                    <div style={{ width: "50%", marginLeft: "30px" }}>
                        <GeoObjectSelect
                            id="outlined-basic"
                            label="Адрес"
                            fullWidth
                            value={data.addressId}
                            onChange={(id, object) => {
                                const obj = {
                                    addressId: id,
                                    addressLine: object.name,
                                };
                                handleChangeMulti(obj);
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "5px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Диагнозы (Диагноз)</p>
                    <div style={{ width: "50%", margin: "0 0 0 30px" }}>
                        <DiagnosisSelect
                            disabled={isDisabledData}
                            label={"Диагноз"}
                            fullWidth
                            value={data.data?.diagnosis?.value?.id}
                            onChange={(val, obj) => {
                                handleChangeData('diagnosis', obj, 'diagnosis')
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Емдеуге жатқызылды (Госпитализация состоялась)
                    </p>
                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                        <DateTimePicker views={['day']} label="Дата госпитализации"
                                        value={data.data?.hospitalizationDate?.value}
                                        onChange={(value) => handleChangeData('hospitalizationDate', value, 'hospitalizationDate')}
                                        minDate={new Date('1910-03-01')} renderInput={(params) =>
                            <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
                        } />
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Ауруханаға (В больницу)</p>
                    <TextField
                        id="outlined-basic"
                        label="В больницу"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.inHospital?.value}
                        onChange={(e) => handleChangeData('inHospital', e.target.value, 'inHospital')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Үйде қалдырылды (оставлен дома)</p>
                    <TextField
                        id="outlined-basic"
                        label="Оставлен дома"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.leftAtHome?.value}
                        onChange={(e) => handleChangeData('leftAtHome', e.target.value, 'leftAtHome')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Көшіру наряды (Наряд на эвакуацию) №</p>
                    <TextField
                        id="outlined-basic"
                        label="Наряд на эвакуацию"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        type="number"
                        value={data.data?.orderNumber?.value}
                        onChange={(e) => handleChangeData('orderNumber', e.target.value, 'order_number')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Дезинфекциялауға (Наряд на дезинфекцию вручен)
                    </p>
                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                        <DateTimePicker views={['day']} label="Наряд на дезинфекцию вручен"
                                        value={data.data?.orderDate?.value}
                                        onChange={(value) => handleChangeData('orderDate', value, 'orderDate')}
                                        minDate={new Date('1910-03-01')} renderInput={(params) =>
                            <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
                        } />
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "15px",
                        alignItems: "center",
                    }}
                >
                    <EmployeeField
                        label={"ФИО должностного лица, вручившего наряд за дезинфекцию"}
                        value={data.data?.sendingNameChamber?.value?.id}
                        object={data.data?.sendingNameChamber?.value}
                        disabled={isDisabledData}
                        onChange={(val) => {
                            handleChangeData('sendingNameChamber', val, 'sending_name_chamber')
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "15px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Зерттеу әдістеменің НҚ-ры (НД на метод испытаний)
                    </p>
                    <div style={{ width: "50%", margin: "0 0 0 30px" }}>
                        <GovernmentStandardsSelect
                            label={"НД на метод испытаний"}
                            fullWidth
                            value={data.data?.standard?.value?.id}
                            disabled={isDisabledData}
                            onChange={(val, obj) => handleChangeData('standard', obj, 'nd_test_method')}
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Дезинфекциялау бригадасы (Дезбригада)
                    </p>
                    <TextField
                        id="outlined-basic"
                        label="Дезбригада"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.desbrigade?.value}
                        onChange={(e) => handleChangeData('desbrigade', e.target.value, 'desbrigade')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "15px",
                        alignItems: "center",
                    }}
                >
                    <EmployeeField
                        label={"Дезинструктор"}
                        value={data.data?.disinstructor?.value?.id}
                        object={data.data?.disinstructor?.value}
                        disabled={isDisabledData}
                        onChange={(val) => {
                            handleChangeData('disinstructor', val, 'disinstructor')
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "10px",
                        alignItems: "center",
                    }}
                >
                    <EmployeeField
                        label={"Фамилия, имя, отчество (при наличии), лица выполнявшего дезинсекцию, дератизацию"}
                        fullWidth
                        object={data.disinfector}
                        value={data.disinfectorId}
                        onChange={(disinfector) => {
                            const obj = {
                                disinfector,
                                disinfectorId: disinfector.id,
                            };
                            handleChangeMulti(obj);
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "15px",
                        alignItems: "center",
                    }}
                >
                    <EmployeeField
                        label={"Диспетчер"}
                        value={data.data?.dispatcher?.value?.id}
                        object={data.data?.dispatcher?.value}
                        disabled={isDisabledData}
                        onChange={(val) => {
                            handleChangeData('dispatcher', val, 'dispatcher')
                        }}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Дезинфекциялаудың жүргізілмеу(Причины не состоявшейся или поздней
                        (дезинфекция начата))
                    </p>
                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                        <DateTimePicker views={['day']} label="Дезинфекция начата"
                                        value={data.data?.disinfectionStart?.value}
                                        onChange={(value) => handleChangeData('disinfectionStart', value, 'disinfectionStart')}
                                        minDate={new Date('1910-03-01')} renderInput={(params) =>
                            <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
                        } />
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>Дезинфекциялау басталды(Окончена)</p>
                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                        <DateTimePicker views={['day']} label="Дезинфекция окончена"
                                        value={data.data?.disinfectionEnd?.value}
                                        onChange={(value) => handleChangeData('disinfectionEnd', value, 'disinfectionEnd')}
                                        minDate={new Date('1910-03-01')} renderInput={(params) =>
                            <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
                        } />
                    </LocalizationProvider>
                </div>

                {"data"}
                <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
                    <Table
                        aria-label="simple table"
                        className="border-table-word"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell className="border-table-word">
                                    Объектілер тізбесі Перечень объектов
                                </TableCell>
                                <TableCell className="border-table-word">
                                    Өлшем бірлігі Единица измерения
                                </TableCell>
                                <TableCell className="border-table-word">
                                    Орындалды Выполнено
                                </TableCell>
                                <TableCell className="border-table-word">
                                    Жұмсалған дезинфекциялау заттарының атауы Наименование израсходованых дезинфицирующих средств
                                </TableCell>
                                <TableCell className="border-table-word">
                                    Мөлшері, кг Количество в кг
                                </TableCell>
                                <TableCell className="border-table-word">
                                    Действия
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.data?.samples?.value?.map((sample, i) => {
                                return <TableRow key={i}>
                                    <TableCell className="border-table-word">
                                        {sample.object?.value}
                                    </TableCell>
                                    <TableCell className="border-table-word">
                                        {sample.unit?.value}
                                    </TableCell>
                                    <TableCell className="border-table-word">
                                        <TextField
                                            style={{ width: '100%' }}
                                            id="outlined-basic"
                                            label="Выполнено"
                                            variant="outlined"
                                            value={sample.done?.value}
                                            onChange={(e) => handleChangeSample('done', e.target.value, i, 'done')}
                                        />
                                    </TableCell>
                                    <TableCell className="border-table-word">
                                        <TextField
                                            style={{ width: '100%' }}
                                            id="outlined-basic"
                                            label="Наименование израсходованых дезинфицирующих средств"
                                            variant="outlined"
                                            value={sample.names?.value}
                                            onChange={(e) => handleChangeSample('names', e.target.value, i, 'names')}
                                        />
                                    </TableCell>
                                    <TableCell className="border-table-word">
                                        <TextField
                                            style={{ width: '100%' }}
                                            id="outlined-basic"
                                            label="Количество"
                                            variant="outlined"
                                            value={sample.quantity?.value}
                                            onChange={(e) => handleChangeSample('quantity', e.target.value, i, 'quantity')}
                                            type='number'
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        (кг)
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell className="border-table-word">
                                        <div style={{display: 'flex', justifyContent: 'center'}}>
                                            <IconButton color="error"
                                                        size="large"
                                                        aria-label="delete"
                                                        disabled={isDisabledData}
                                                        onClick={() => { deleteSample(i) }}>
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "30px",
                        alignItems: "center",
                    }}
                >
                    <Button
                        disabled={isDisabledData}
                        variant="contained"
                        onClick={addSample}
                    >
                        Добавить
                    </Button>
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        marginTop: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Камералық дезинфекциялау үшін заттардың саны(Количество вещей для
                        камерной дезинфекции) кг
                    </p>
                    <TextField
                        id="outlined-basic"
                        label="Количество вещей для камерной дезинфекции"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        type="number"
                        value={data.data?.thingsQuantity?.value}
                        onChange={(e) => handleChangeData('thingsQuantity', e.target.value, 'thingsQuantity')}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        marginBottom: "20px",
                        marginTop: "20px",
                        alignItems: "center",
                    }}
                >
                    <p style={{ width: "50%" }}>
                        Санитариялық өңделуге жататын(Подлежат санитарной обработке)
                    </p>
                    <TextField
                        id="outlined-basic"
                        label="Подлежат санитарной обработке"
                        style={{ width: "50%", marginLeft: "30px" }}
                        variant="outlined"
                        value={data.data?.subjectToSanitization?.value}
                        onChange={(e) => handleChangeData('subjectToSanitization', e.target.value, 'subjectToSanitization')}
                    />
                </div>
            </div >
          <LoadingButton loading={loading} onClick={handleSubmit}>
            Создать
          </LoadingButton>
        </Form>
      </BasicModalContainer>
    </>
  );
};
