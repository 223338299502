import React, {useEffect, useMemo} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import {PageContainer, PageHeader} from "../../components";
import {useForm, useWatch} from "react-hook-form";
import {FormProvider} from "../../components/hook-form";
import {Button, Grid} from "@mui/material";
import RHFTextField from "../../components/hook-form/RHFTextField";
import {
    ServiceCategorySelect,
    ServiceGroupSelect,
    ServiceKindSelect,
    ServiceSubGroupSelect,
    UnitOfMeasure
} from "../../components/Select/domainSelects";
import LoadingButton from "@mui/lab/LoadingButton";
import {serviceSchema} from "../../models/schemas/serviceSchema";
import {yupResolver} from "@hookform/resolvers/yup";
import {serviceCRUD, servicePriceListCRUD} from "../../http";
import {useHistory, useParams} from "react-router-dom";
import {SERVICE_LIST_CLIENT_ROUTE, SERVICE_LIST_ROUTE} from "../../routes";
import {renderControls} from "../../functions";
import useAlert from "../../hooks/useAlert";
import {uuid} from "@tinymce/tinymce-react/lib/es2015/main/ts/Utils";
import { v4 as uuidv4 } from "uuid";

const ServiceAdd = () => {
    const defaultValues = React.useMemo(() => ({
        unitId: '',
        name: '',
        code: '',
        groupId: '',
        subGroupId: '',
        categoryId: '',
        kindId: '',
        typeId: 2,
        price: 0,
    }), []);

    const {id} = useParams();
    const {push} = useHistory()
    const isEditing = useMemo(() => id, [id]);
    const alert = useAlert(false, 1);

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(serviceSchema)
    });

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: {isSubmitting},
    } = methods;

    useEffect(() => {
        (async function () {
            if (isEditing) {
                const res = await serviceCRUD.get(id);
                reset({...res});

            }
        }())
    }, [isEditing])

    const onSubmit = async state => {
        if (isEditing) {
            await serviceCRUD.edit({
                ...state,
                id,
                typeId: 2,
                // code: "safas"
            })
            alert.show(1, "Изменения сохранены")
        } else {
            const {id} = await serviceCRUD.create({
                ...state,
                typeId: 2,
                // code: "safas"
            })
            await servicePriceListCRUD.create({
                name: uuidv4(),
                "price": state.price,
                "costCoefficient": 0,
                "serviceId": id,
            })
            alert.show(1, "Услуга добавлена")
            push(SERVICE_LIST_ROUTE)
        }
    }

    const unitId = useWatch({
        control,
        name: "unitId",
    });

    const groupId = useWatch({
        control,
        name: "groupId",
    });

    const subGroupId = useWatch({
        control,
        name: "subGroupId",
    });

    const categoryId = useWatch({
        control,
        name: "categoryId",
    });

    const kindId = useWatch({
        control,
        name: "kindId",
    });


    return (
        <DashboardLayout>
            <PageHeader controls={renderControls([    {
                state: "created",
                roles: [],
                buttons: [
                    <Button
                        variant={"text"}
                        onClick={() => push(SERVICE_LIST_ROUTE)}
                    >
                        Вернуться
                    </Button>
                ],
            }], {
                state: 'created',
                roles: [],
            })}  heading={isEditing ? `Услуга #${id}` : "Услуга: Создание"}>
            </PageHeader>

            <PageContainer>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Код услуги
                            </p>
                            <RHFTextField label="Код услуги" name="code"/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Полное наименование услуги
                            </p>
                            <RHFTextField label="Полное наименование" name="name"/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Цена
                            </p>
                            <RHFTextField label="Полное наименование" name="price" type="number"/>
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Ед. измерения
                            </p>
                            <UnitOfMeasure
                                fullWidth
                                value={unitId}
                                onChange={(val) => setValue('unitId', val)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Группа
                            </p>
                            <ServiceGroupSelect
                                fullWidth
                                value={groupId}
                                onChange={(val) => setValue('groupId', val)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Подгруппа
                            </p>
                            <ServiceSubGroupSelect
                                fullWidth
                                value={subGroupId}
                                onChange={(val) => setValue('subGroupId', val)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Метод
                            </p>
                            <ServiceKindSelect
                                fullWidth
                                value={kindId}
                                onChange={(val) => setValue('kindId', val)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
                            <p style={{width: "50%"}}>
                                Категория
                            </p>
                            <ServiceCategorySelect
                                fullWidth
                                value={categoryId}
                                onChange={(val) => setValue('categoryId', val)}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton loading={isSubmitting} type="submit" variant="contained"
                                   sx={{mt: 3, mb: 2, alignSelf: 'flex-end'}}>
                        {isEditing ? "Сохранить" : 'Добавить'}
                    </LoadingButton>
                </FormProvider>
            </PageContainer>
            {alert.render()}
        </DashboardLayout>
    )
};

export default ServiceAdd;
