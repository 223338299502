import * as React from "react";
import {useEffect, useState} from "react";
import "./SignIn2.scss";
import background from "../../assets/img/login.gif";
import {Button, Form, Input, Switch, Checkbox} from "antd";
import {useHistory} from "react-router-dom";
import {REQUEST_LIST_ROUTE, RESOLUTION} from "../../routes/const";
import {useDispatch, useSelector} from "react-redux";
import {login} from "../../redux/actions/authActions";
import {useForm} from "antd/es/form/Form";
import useRoles from "../../hooks/useRoles";
import { useNCALayerClient } from "../../functions/sign/SignHooks";
import { env } from "../../env";
import { AndroidFilled } from "@ant-design/icons";
import { Android } from "@mui/icons-material";
import { Link } from "@mui/material";

const SignIn2 = () => {
    const [form] = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");    
    const user = useSelector((state) => state.auth);
    const {loading, success, error} = user;
    const { isCommittee } = useRoles();

    const [ client, ready ] = useNCALayerClient();
    // not working why?
    //const [isEcpMethod, setIsEcpMethod] = useState(env.ecpMethodEnabled || process.env.NODE_ENV !== 'development');
    const [isEcpMethod, setIsEcpMethod] = useState(true);
    console.log(env.ecpMethodEnabled);
    //const [isEcpMethod, setIsEcpMethod] = useState(true);
    const [ecpLoginXml, setEcpLoginXml] = useState(null);
    const [ecpReady, setEcpReady] = useState(false);
    const [ecpMessage, setEcpMessage] = useState(null);
    const [ecpLoginSignedXml, setEcpLoginSignedXml] = useState(null);

    useEffect(() => {
        if (!client)
            return;

        client.onopen = function() {
            setEcpReady(true);
            setEcpMessage(null);
        }
    
        client.onclose = function() {
            setEcpReady(false);
            setEcpMessage("Ошибка соединения с провайдером ЭЦП (NCALayer)");
        }    

        client.onmessage = function (msg) {
            setEcpLoginSignedXml(msg.responseObject);
        };
      
    }, [client]);

    useEffect(() => {
        if (success) {
            setEmail("");
            setPassword("");
            history.push(!isCommittee() ? REQUEST_LIST_ROUTE : RESOLUTION);
        }
    }, [history, success]);

    useEffect(() => {
        if (!email)
            return;

        if (email == 'admin')
            setIsEcpMethod(false);
        else
            setIsEcpMethod(true);
    }, [email]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await dispatch(login(email, password, ecpLoginXml, ecpLoginSignedXml));
    };

    const validateAndSubmit = (event) => {
        if (!isEcpMethod) {
            form
                .validateFields()
                .then(() => handleSubmit(event))
                .catch((err) => console.log(err));
        } else {
            handleSubmit(event);
        }
    };
    const willSelectEcp = () => {
        const d = new Date();
        const n = d.getTime();
        const loginXml = '<login><time>' + n + '</time></login>';
        setEcpLoginXml(loginXml);

        client.signXml('PKCS12', 'AUTHENTICATION', loginXml);
    }
    return (
        <div className="layout">
            <div className="layout__left">
            </div>
            <div className="layout__right">
                <p className="layout__right-header">НАЦИОНАЛЬНЫЙ ЦЕНТР ЭКСПЕРТИЗЫ</p>
                <p className="layout__right-subheader">
                    Лабораторно информационная система
                </p>
            </div>
            <div className="layout__login">
                <div className="layout__login-logo">
                    <img src={background} alt="login"/>
                </div>
                <div className="layout__login-form">
                    <p className="enter">Вход в систему</p>
                    <table style={{width:"100%"}}>
                        <tr>
                            <td>
                                <p className="access">Войти для доступа к системе</p>
                            </td>
                            <td>
                                <Checkbox
                                    id="isEcpMethod"
                                    name="isEcpMethod"
                                    style={{background: "#F8F8F8", borderRadius: "15px"}}
                                    value={isEcpMethod}                                    
                                    onChange={(e) => {
                                        setIsEcpMethod(e.target.checked);
                                    }}
                                    disabled
                                    >
                                    <span>Двухфакторная по ЭЦП</span>
                                </Checkbox>
                            </td>
                        </tr>
                    </table>                    
                    
                    <Form form={form}>
                        <Form.Item
                        style={{marginBottom: "12px"}}
                        name="email"
                        rules={[{required: true, message: <></>}]}
                        >
                            <Input
                                placeholder="Логин"
                                autoFocus
                                autoComplete="email"
                                required
                                style={{background: "#F8F8F8", borderRadius: "15px"}}
                                name="email"                            
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{marginBottom: error ? "16px" : "26px"}}
                            name="password"
                            rules={[{required: true, message: <></>}]}
                        >
                            <Input
                                placeholder="Пароль"
                                type="password"
                                autoComplete="current-password"
                                id="password"
                                name="password"
                                style={{background: "#F8F8F8", borderRadius: "15px"}}
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Item>
                        {isEcpMethod && <>
                            <Form.Item
                                style={{marginBottom: "12px"}}
                                name="chooseEcp"
                            >
                                <Button
                                    block={false}
                                    type="ghost"
                                    style={{
                                        background:
                                            "linear-gradient(30.19deg, #2E9645 -3.48%, #86DF27 109.43%)",
                                        borderRadius: "15px",
                                        width: "100%",
                                        height: "45px",
                                        borderColor: "transparent",
                                    }}
                                    onClick={willSelectEcp}
                                    loading={loading}
                                    disabled={!ecpReady}
                                >
                                    Выбрать ЭЦП
                                </Button>
                                {ecpMessage && <span>{ecpMessage}</span>}
                            </Form.Item>
                        </>}                        
                        {error && <p className="access-error">Неправильный логин или пароль {error}</p>}

                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Switch
                                    style={{width: "46px", height: "18px"}}
                                    defaultChecked
                                />{" "}
                                <span
                                    style={{
                                        color: "#625E5C",
                                        fontFamily: "PT Serif",
                                        fontSize: "16px",
                                    }}>
                                    Запомнить меня
                                </span>
                            </div>
                            <Button
                                block={false}
                                type="primary"
                                htmlType="submit"
                                style={{
                                    background:
                                        "linear-gradient(30.19deg, #2E9645 -3.48%, #86DF27 109.43%)",
                                    borderRadius: "15px",
                                    width: "114px",
                                    height: "45px",
                                    borderColor: "transparent",
                                }}
                                onClick={validateAndSubmit}
                                loading={loading}
                                disabled={process.env.NODE_ENV === 'development' ? false : (isEcpMethod && !ecpLoginSignedXml)}
                            >
                                Войти
                            </Button>
                        </div>
                        <Form.Item
                        style={{marginBottom: "12px", marginTop: "12px"}}
                        >
                        <Link 
                        href="https://ekpo.nce.kz/s3/api/v1/file/download2?bucketName=app&key=mobile&objectName=6127b567-8c01-49a5-8574-9bfd9d18bfef__app-release.apk">
                            <AndroidFilled style={{fontSize:22, marginRight: "5px"}}/>
                            Скачать мобильное приложение
                            </Link>
                            </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default SignIn2;
