import { 
    AN_OBJECT_KIND_GET_REQUEST, AN_OBJECT_KIND_GET_SUCCESS, AN_OBJECT_KIND_GET_ERROR, 
    AN_OBJECT_KIND_GET_BY_KEY_REQUEST, AN_OBJECT_KIND_GET_BY_KEY_SUCCESS, AN_OBJECT_KIND_GET_BY_KEY_ERROR, 
    AN_OBJECT_KIND_ADD_REQUEST, AN_OBJECT_KIND_ADD_SUCCESS, AN_OBJECT_KIND_ADD_ERROR, 
    AN_OBJECT_KIND_EDIT_REQUEST, AN_OBJECT_KIND_EDIT_SUCCESS, AN_OBJECT_KIND_EDIT_ERROR, 
    AN_OBJECT_KIND_DELETE_REQUEST, AN_OBJECT_KIND_DELETE_SUCCESS, AN_OBJECT_KIND_DELETE_ERROR
} from "../constants/anObjectKindConstants";

const initialState = {
    anObjectKinds: [],
    anObjectKind: {},
    loading: false,
    success: null,
    error: null,
    anObjectKindRequestedCount: 0,
};

export const anObjectKindReducer = (state = initialState, action) => {
    switch (action.type)     {
        case AN_OBJECT_KIND_GET_REQUEST:
            return {
                ...state,
                anObjectKindRequestedCount: ++state.anObjectKindRequestedCount,
                loading: true,
            };
        case AN_OBJECT_KIND_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                anObjectKinds: action.payload,
                success: true,
            };
        case AN_OBJECT_KIND_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_KIND_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_KIND_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                anObjectKind: action.payload,
                success: true,
            };
        case AN_OBJECT_KIND_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_KIND_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_KIND_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_KIND_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_KIND_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_KIND_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_KIND_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case AN_OBJECT_KIND_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case AN_OBJECT_KIND_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case AN_OBJECT_KIND_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
