import m_03_2069 from '././kokshetau/KZ.M.03.2069.svg'
import t_03_0011 from './t_03_0011.png'
import t_03_1470 from '././burabai/KZ.T.03.1470.svg'
import t_03_1477 from '././stepnogorsk/KZ.T.03.1477.svg'
import t_03_1616 from '././atbasar/KZ.T.03.1616.svg'
import t_03_e0810 from '././kokshetau/KZ.T.03.E0810.svg'

export default [
  m_03_2069,
  // t_03_0011,
  t_03_1470,
  t_03_1477,
  t_03_1616,
  t_03_e0810
]
