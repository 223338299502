import { $authHost } from "../../http";
import { 
    SERVICE_CATEGORY_GET_REQUEST, SERVICE_CATEGORY_GET_SUCCESS, SERVICE_CATEGORY_GET_ERROR, 
    SERVICE_CATEGORY_GET_BY_KEY_REQUEST, SERVICE_CATEGORY_GET_BY_KEY_SUCCESS, SERVICE_CATEGORY_GET_BY_KEY_ERROR, 
    SERVICE_CATEGORY_ADD_REQUEST, SERVICE_CATEGORY_ADD_SUCCESS, SERVICE_CATEGORY_ADD_ERROR, 
    SERVICE_CATEGORY_EDIT_REQUEST, SERVICE_CATEGORY_EDIT_SUCCESS, SERVICE_CATEGORY_EDIT_ERROR, 
    SERVICE_CATEGORY_DELETE_REQUEST, SERVICE_CATEGORY_DELETE_SUCCESS, SERVICE_CATEGORY_DELETE_ERROR
} from "../constants/serviceCategoryConstants";

export const getServiceCategories = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_CATEGORY_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/serviceCategory/query",
            args || {},
            config,
        );
        dispatch({
            type: SERVICE_CATEGORY_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_CATEGORY_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getServiceCategoryByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_CATEGORY_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/serviceCategory/${id}`,
        );
        dispatch({
            type: SERVICE_CATEGORY_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SERVICE_CATEGORY_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addServiceCategory = ({ name, code, description, display, useCalculator }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_CATEGORY_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/serviceCategory",
            { name, code, description, display, useCalculator },
            config,
        );
        dispatch(getServiceCategories(queryArgs || {}));
        dispatch({
            type: SERVICE_CATEGORY_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_CATEGORY_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editServiceCategory = ({ id, name, code, description, display, useCalculator }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_CATEGORY_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/serviceCategory",
            { id, name, code, description, display, useCalculator },
            config,
        );
        dispatch(getServiceCategories(queryArgs || {}));
        dispatch({
            type: SERVICE_CATEGORY_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_CATEGORY_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteServiceCategory = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SERVICE_CATEGORY_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/serviceCategory",
            { data: { id } },
            config,
        );
        dispatch(getServiceCategories(queryArgs || {}));
        dispatch({
            type: SERVICE_CATEGORY_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SERVICE_CATEGORY_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

