import { REQUEST_TYPE_ID_Act, REQUEST_TYPE_ID_Brigade } from '../redux/constants/requestTypeConstants';

import { jsonSchemaVisitor } from './jsonSchemaVisitor';

export function composeRequestData(data, actForms, brigadeTypes) {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
  
      switch (item.typeId) {
        case REQUEST_TYPE_ID_Act:
          const formId = item.act?.formId;
          if (!actForms || !formId)
            return;
          const actForm = actForms.find(_ => _.id == formId);
          if (!actForm)
            return;
          item.act.form = actForm;
          break;
        case REQUEST_TYPE_ID_Brigade:
          const typeId = item.brigade?.typeId;
          if (!brigadeTypes || !typeId)
            return;
          const brigadeType = brigadeTypes.find(_ => _.id == typeId);
          if (!brigadeType)
            return;
          item.brigade.type = brigadeType;
          break;
      }
    }
  }

  export function composeResultDynamicHeader(jsonSchema, rowName, fixed) {
    let newRowName = [ ...rowName ];

    let newRowName2 = [ ];
    let newRowName3 = [ ];
    let newRowName4 = [ ];

    for (let i = 0; i < fixed; i++) {
      newRowName2.push({ title: "" });
      newRowName3.push({ title: "" });
      newRowName4.push({ title: "" });
    }

    // let newRowName2 = [ { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" } ];
    // let newRowName3 = [ { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" } ];
    // let newRowName4 = [ { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" }, { title: "" } ];

    let maxLevel = 0;
    jsonSchemaVisitor(jsonSchema, {
        level: 0,
        visit: (key, index, item, isObject, options) => {
            let title = item.title;
            let id = options.id;
            switch (options.level) {
                case 0:
                    newRowName.push({ title: title, key: id, dataIndex: id });
                    if (!isObject) {
                        newRowName2.push({ title: '' });
                        newRowName3.push({ title: '' });
                        newRowName4.push({ title: '' });
                    }
                    break;
                case 1:
                    newRowName2.push({ title: title, key: id, dataIndex: id });
                    if (index >= 1) {
                        newRowName.push({ title: '' });
                    }

                    if (!isObject) {
                        newRowName3.push({ title: '' });
                        newRowName4.push({ title: '' });
                    }
                    break;
                case 2:
                    newRowName3.push({ title: title, key: id, dataIndex: id });
                    if (index >= 1) {
                        newRowName.push({ title: '' });
                        newRowName2.push({ title: '' });
                    }

                    if (!isObject)
                      newRowName4.push({ title: '' });
                    break;
                case 3:
                    newRowName4.push({ title: title, key: id, dataIndex: id });
                    if (index >= 1) {
                        newRowName.push({ title: '' });
                        newRowName2.push({ title: '' });
                        newRowName3.push({ title: '' });
                    }
                    break;
                default:
                    throw new Error("Schema level of " + options.level + " is not supported");
            }
        }
    });

    if (newRowName2.length == 0 || newRowName2.every(_ => _.title == ''))
        newRowName2.length = 0;
    if (newRowName3.length == 0 || newRowName3.every(_ => _.title == ''))
        newRowName3.length = 0;
    if (newRowName4.length == 0 || newRowName4.every(_ => _.title == ''))
        newRowName4.length = 0;

    if (newRowName2.length > 0)
        newRowName2.push({ title: '' });
    if (newRowName3.length > 0)
        newRowName3.push({ title: '' });
    if (newRowName4.length > 0)
        newRowName4.push({ title: '' });

    // move ids up
    let arrays = [newRowName4, newRowName3, newRowName2];
    for (let i = 0; i < arrays.length; i++) {
      const array = arrays[i];

      for (let j = 0; j < array.length; j++) {
        let item = array[j];
        if (item.title && !newRowName[j].movedUp) {
          newRowName[j].key = item.key;
          newRowName[j].dataIndex = item.dataIndex;
          newRowName[j].movedUp = true;
        }
      }
    }

    return {
      rowName: newRowName,
      rowName2: newRowName2,
      rowName3: newRowName3,
      rowName4: newRowName4
    }

    // setRowName(newRowName);
    // setRowName2(newRowName2);
    // setRowName3(newRowName3);
    // setRowName4(newRowName4);
  }