import m_16_2210_colorized from './KZ.M.16.2210.svg'
import m_16_2210 from './KZ.M.16.2210 black.svg'
import t_16_1095_colorized from './KZ.T.16.1095.svg'
import t_16_1095 from './KZ.T.16.1095 black.svg'

export default [
  m_16_2210_colorized,
  m_16_2210,
  t_16_1095_colorized,
  t_16_1095
]
