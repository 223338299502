import React, { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Grid,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  addClinicalRequest,
  getContractRequestByKey,
  getDepartmentByKey,
  getDepartments,
  getClinicalRequestByKey,
  getDivisionByKey,
  getDivisions,
  getEmployeeByKey,
  getEmployees,
  getFilialByKey,
  getFilials,
  getAuthorizedEmployee,
  getContractByKey,
} from "../../../../redux/actions";
import {
  BasicModalContainer,
  CustomDatePicker,
  Form,
  FormSection,
  BasicTable,
} from "../../../../components";
import EmployeeField from "../../../../components/Relations/EmployeeField";
import {FilialDivisionSelect, FilialSelect} from "../../../../components/Select/domainSelects";
import DialogLayout from "../../../../components/Dialog/DialogLayout";
import {trySetCount} from "../../../../models/data/ContractRequestChildrenModel";
import {autoCompleteFilialAndFilialDivision} from "../../../../functions/dateHelpers";
import {prepareContractRequestChildRequestDataForSubmit} from "../../../../functions/viewDataMap";
import { NumberField2 } from "../../../../components/Fields/NumberField2";

export const KDIRequestCreateModal = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const authorizedEmployee = useSelector(state=>state.employee.authorizedEmployee)

  const { contractRequest } = useSelector((s) => s.contractRequest);

  const [localCreatedById, setLocalCreatedById] = useState("");
  const [localCreatedBy, setLocalCreatedBy] = useState({});

  const [localObjects, setLocalObjects] = useState([]);
  const [localSpecifications, setLocalSpecifications] = useState([]);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState([]);

  const [localDepartmentId, setLocalDepartmentId] = useState(null);
  const [localDepartment, setLocalDepartment] = useState({});

  const [localDepartmentHeadId, setLocalDepartmentHeadId] = useState(null);
  const [localDepartmentHead, setLocalDepartmentHead] = useState({});

  const [localSpecialistId, setLocalSpecialistId] = useState(null);
  const [localSpecialist, setLocalSpecialist] = useState({});

  const [localFilialId, setLocalFilialId] = useState(null);
  const [localFilial, setLocalFilial] = useState({});

  const [localFilialDivisionId, setLocalFilialDivisionId] = useState(null);
  const [localFilialDivision, setLocalFilialDivision] = useState({});

  const [localPerformerId, setLocalPerformerId] = useState(null);
  const [localPerformer, setLocalPerformer] = useState({});

  const [localResponsiblePerformerId, setLocalResponsiblePerformerId] =
    useState(null);
  const [localResponsiblePerformer, setLocalResponsiblePerformer] = useState(
    {}
  );

  const handleSpecificationQuantityChange = (e, id) => {
    const isChanged = trySetCount(selectedSpecifications, localSpecifications, id, contractRequest,
        setSelectedSpecifications, setLocalSpecifications, e.target.value);

    if (!isChanged)
      return alert("Превышено максимальное значение исследований для этого элемента");
  };

  const handleReset = () => {
    setSelectedObjects([]);
    setSelectedSpecifications([]);
    setLocalFilial(null);
    setLocalDepartment(null);
    setLocalDepartmentHead(null);
    setLocalSpecialist(null);
    setLocalFilial(null);
    setLocalFilialDivision(null);
    setLocalFilial(null);
    setLocalDepartmentId(null);
    setLocalDepartmentHeadId(null);
    setLocalSpecialistId(null);
    setLocalFilialId(null);
    setLocalFilialDivisionId(null);
    setLocalResponsiblePerformerId(null);
    setLocalResponsiblePerformer(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking Fields
    if (!selectedSpecifications?.length) return alert("Выберите услуги!");

    prepareContractRequestChildRequestDataForSubmit(selectedSpecifications, selectedObjects);

    setLoading(true);

    const data = {
      contractRequestId: contractRequest.id,
      departmentId: localDepartmentId,
      departmentHeadId: localDepartmentHeadId,
      specialistId: localSpecialistId,
      filialId: localFilialId || contractRequest.filialId,
      filialDivisionId: localFilialDivisionId || contractRequest.filialDivisionId || contractRequest.contractFilialDivisionId,
      createdId: localCreatedById,
      objects: selectedObjects,
      specifications: selectedSpecifications,
      reponsiblePerformerId: localResponsiblePerformerId,
      performerId: localPerformerId,
    };

    // Creating
    const createdId = await dispatch(addClinicalRequest(data));
    if (!createdId) return setLoading(false);

    // Updating Contract Request
    const contractReq = await dispatch(
      getContractRequestByKey(contractRequest.id)
    );
    if (!contractReq) return setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      handleReset();
    }, 500);
  };

  useEffect(() => {
    autoCompleteFilialAndFilialDivision(authorizedEmployee, setLocalFilialId, setLocalFilialDivisionId);
  }, [])

  useEffect(() => {
    if (!open)
      return;

    dispatch(getAuthorizedEmployee()).then(setLocalCreatedBy);
    dispatch(getContractByKey(contractRequest.contractId));
  }, [open]);

  useEffect(() => {
    if (!contractRequest)
      return;

    setLocalObjects(contractRequest?.objects);
    setLocalSpecifications(contractRequest?.specifications);
  }, [contractRequest]);

  useEffect(() => {
    if (!localFilialId) return;
    dispatch(getFilialByKey(localFilialId)).then(setLocalFilial);
  }, [dispatch, localFilialId]);

  useEffect(() => {
    if (!localPerformerId) return;
    dispatch(getEmployeeByKey(localPerformerId)).then(setLocalPerformer);
  }, [dispatch, localPerformerId]);

  useEffect(() => {
    if (!localDepartmentId) return;
    dispatch(getDepartmentByKey(localDepartmentId)).then(setLocalDepartment);
  }, [dispatch, localDepartmentId]);

  useEffect(() => {
    if (!localDepartmentHeadId) return;
    dispatch(getEmployeeByKey(localDepartmentHeadId)).then(
      setLocalDepartmentHead
    );
  }, [dispatch, localDepartmentHeadId]);

  useEffect(() => {
    if (!localSpecialistId) return;
    dispatch(getEmployeeByKey(localSpecialistId)).then(setLocalSpecialist);
  }, [dispatch, localSpecialistId]);

  useEffect(() => {
    if (!localFilialDivisionId) return;
    dispatch(getDivisionByKey(localFilialDivisionId)).then(
      setLocalFilialDivision
    );
  }, [dispatch, localFilialDivisionId]);

  useEffect(() => {
    if (!localResponsiblePerformerId) return;
    dispatch(getEmployeeByKey(localResponsiblePerformerId)).then(
      setLocalResponsiblePerformer
    );
  }, [dispatch, localResponsiblePerformerId]);

  return (
      <DialogLayout setOpen={setOpen} open={open} loading={loading} title="Заявка на КДИ: создание">
      <Form onSubmit={handleSubmit}>
        <BasicModalContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={contractRequest?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  value={localCreatedBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <EmployeeField
                    style={{ marginBottom: "5px" }}
                    label={"Исполнитель заявки"}
                    object={localPerformer}
                    value={localPerformerId}
                    onChange={(val) => {
                      setLocalPerformer(val)
                      setLocalPerformerId(val?.id)
                    }}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection heading="Договор" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Номер договора"
                  type="number"
                  value={contractRequest?.contractNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Статус по договору"
                  type="text"
                  value={contractRequest?.contractState?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата договора"
                  value={contractRequest?.contractActionAt}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Объекты (${localObjects?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    {
                      id: "name",
                      label: t("object.tableItem.name"),
                    },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                    // {
                    //   id: "controls",
                    //   label: "",
                    //   render: ({ id }) => (
                    //     <Box
                    //       sx={{
                    //         display: "flex",
                    //         justifyContent: "flex-end",
                    //       }}
                    //     >
                    //       <IconButton
                    //         sx={{ height: "25px", width: "25px" }}
                    //         children={<DeleteIcon />}
                    //         onClick={() => handleDeleteObject(id)}
                    //       />
                    //     </Box>
                    //   ),
                    // },
                  ]}
                  rows={localObjects}
                  disabled={true}
                  selected={selectedObjects}
                  setSelected={setSelectedObjects}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Услуги (${localSpecifications?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                      render: ({ id, count }) => (
                        <NumberField2
                          value={count}                          
                          onChange={(e) =>
                            handleSpecificationQuantityChange(e, id)
                          }
                        />
                      ),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={localSpecifications}
                  disabled={true}
                  selected={selectedSpecifications}
                  setSelected={setSelectedSpecifications}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FilialSelect
                    label={"Филиал"}
                    fullWidth
                    value={localFilial?.id}
                    onChange={(filialId, filial) => {
                      setLocalFilialId(filialId)
                      setLocalFilial(filial)
                    }}
                />
              </Grid>
              <Grid item xs={12}>
                <FilialDivisionSelect
                    label={"Районное отделение"}
                    fullWidth
                    value={localFilialDivisionId}
                    filialId={localFilial?.id}
                    onChange={(filialDivisionId, filialDivision) => {
                      setLocalFilialDivisionId(filialDivisionId);
                      setLocalFilialDivision(filialDivision)
                    }}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Назначение ответственных" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EmployeeField
                    label={"Ответственный лаборант"}
                    object={localResponsiblePerformer}
                    value={localResponsiblePerformer?.id}
                    onChange={(val) => {
                      setLocalResponsiblePerformer(val)
                      setLocalResponsiblePerformerId(val?.id)
                    }}
                />
              </Grid>
            </Grid>
          </FormSection>
        </BasicModalContainer>

        <BasicModalContainer>
          <Grid container spacing={2}>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
              >
                Создать заявку
              </LoadingButton>
            </Grid>
          </Grid>
        </BasicModalContainer>
      </Form>
      </DialogLayout>
  );
};
