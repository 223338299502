export const PROBE_STATE_GET_REQUEST = 'PROBE_STATE_GET_REQUEST';
export const PROBE_STATE_GET_SUCCESS = 'PROBE_STATE_GET_SUCCESS';
export const PROBE_STATE_GET_ERROR = 'PROBE_STATE_GET_ERROR';
export const PROBE_STATE_GET_BY_KEY_REQUEST = 'PROBE_STATE_GET_BY_KEY_REQUEST';
export const PROBE_STATE_GET_BY_KEY_SUCCESS = 'PROBE_STATE_GET_BY_KEY_SUCCESS';
export const PROBE_STATE_GET_BY_KEY_ERROR = 'PROBE_STATE_GET_BY_KEY_ERROR';
export const PROBE_STATE_ADD_REQUEST = 'PROBE_STATE_ADD_REQUEST';
export const PROBE_STATE_ADD_SUCCESS = 'PROBE_STATE_ADD_SUCCESS';
export const PROBE_STATE_ADD_ERROR = 'PROBE_STATE_ADD_ERROR';
export const PROBE_STATE_EDIT_REQUEST = 'PROBE_STATE_EDIT_REQUEST';
export const PROBE_STATE_EDIT_SUCCESS = 'PROBE_STATE_EDIT_SUCCESS';
export const PROBE_STATE_EDIT_ERROR = 'PROBE_STATE_EDIT_ERROR';
export const PROBE_STATE_DELETE_REQUEST = 'PROBE_STATE_DELETE_REQUEST';
export const PROBE_STATE_DELETE_SUCCESS = 'PROBE_STATE_DELETE_SUCCESS';
export const PROBE_STATE_DELETE_ERROR = 'PROBE_STATE_DELETE_ERROR';
