import { 
    SERVICE_PACKAGE_GET_REQUEST, SERVICE_PACKAGE_GET_SUCCESS, SERVICE_PACKAGE_GET_ERROR, 
    SERVICE_PACKAGE_GET_BY_KEY_REQUEST, SERVICE_PACKAGE_GET_BY_KEY_SUCCESS, SERVICE_PACKAGE_GET_BY_KEY_ERROR, 
    SERVICE_PACKAGE_ADD_REQUEST, SERVICE_PACKAGE_ADD_SUCCESS, SERVICE_PACKAGE_ADD_ERROR, 
    SERVICE_PACKAGE_EDIT_REQUEST, SERVICE_PACKAGE_EDIT_SUCCESS, SERVICE_PACKAGE_EDIT_ERROR, 
    SERVICE_PACKAGE_DELETE_REQUEST, SERVICE_PACKAGE_DELETE_SUCCESS, SERVICE_PACKAGE_DELETE_ERROR
} from "../constants/servicePackageConstants";

const initialState = {
    servicePackages: [],
    servicePackage: {},
    loading: false,
    success: null,
    error: null,
    servicePackageRequestedCount: 0,
};

export const servicePackageReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_PACKAGE_GET_REQUEST:
            return {
                ...state,
                servicePackageRequestedCount: ++state.servicePackageRequestedCount,
                loading: true,
            };
        case SERVICE_PACKAGE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                servicePackages: action.payload,
                success: true,
            };
        case SERVICE_PACKAGE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PACKAGE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PACKAGE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                servicePackage: action.payload,
                success: true,
            };
        case SERVICE_PACKAGE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PACKAGE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PACKAGE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PACKAGE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PACKAGE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PACKAGE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PACKAGE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PACKAGE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PACKAGE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PACKAGE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
