import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import { ActResult, ActSamplingPurpose, ActObject, ActPDF } from "..";

import {
  BasicModal,
  BasicModalHeader,
  BasicModalContainer,
  Form,
  FormSection,
} from "../../../components";

// TRASH

import { DatePicker, DateTimePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CircularProgress, InputAdornment, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";

import useDialogs from "../../../components/Dialog/useDialogs";
import RemovePopup from "../../../components/Popups/RemovePopup";
import EmployeeField from "../../../components/Relations/EmployeeField";
import {
  DepartmentSelect,
  DivisionSelect,
  FilialDivisionSelect,
  FilialSelect,
} from "../../../components/Select/domainSelects";
import { checkRoles } from "../../../functions/checkRoles";
import useLoader from "../../../hooks/useLoader";
import { actCRUD } from "../../../http/CRUD";
import DashboardLayout from "../../../layouts/Dashboard";
import { getActForms } from "../../../redux/actions/actFormActions";
import { getGovernmentStandards } from "../../../redux/actions/governmentStandardActions";
import { addProtocol } from "../../../redux/actions/protocolActions";
import { getSamplePreservationMethods } from "../../../redux/actions/samplePreservationMethodActions";
import { getStorageConditions } from "../../../redux/actions/storageConditionActions";
import { getTransportationConditions } from "../../../redux/actions/transportationConditionActions";
import {
  REQUEST_STATE_ID_COMPLETED,
  REQUEST_STATE_ID_CREATED,
  REQUEST_STATE_ID_DECLINED,
  REQUEST_STATE_ID_PROBESELECTION,
  REQUEST_STATE_ID_PROCESSING,
  REQUEST_STATE_ID_RESEARCH,
} from "../../../redux/constants/requestStateConstants";
import CreateProtocol from "../../Protocol/Popups/CreateProtocol";
import CreateResult from "../../Result/Popups/CreateResult";

export const ActSamplingReportCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});
  const actForm = useSelector((s) => s.actForm);

  const handleSubmit = async (e) => {
    setLoading(true);

    await onSubmit(e, data);

    setLoading(false);
  };

  // TRASH**

  //configStart
  const actName = "ActSamplingReport";
  const kazakhName =
    " Сынамаларды (ауаны (ауа ортасын бактериологиялық зерттеу), дәріліктүрлерді, стерильділікке, полимер және басқа да материалдарүлгілерін, олардан жасалған бұйымдарды, химиялық заттар менкомпозицияларды, балалар мен жасөспірімдерге арналған өнімдерді)іріктеу АКТIСI";
  const name =
    "АКТ отбора проб (воздуха (бактериологическое исследование воздушнойсреды), лекарственных форм, на стерильность, образцов полимерных идругих материалов, изделий из них, химических веществ и композиций,продукции для детей и подростков)";
  //configEnd
  const { alert, DIALOG } = useDialogs();
  const { t } = useTranslation();
  const governmentStandardValues = useSelector(
    (state) => state.governmentStandard
  );
  const transportationConditionValues = useSelector(
    (state) => state.transportationCondition
  );
  const storageConditionValues = useSelector((state) => state.storageCondition);
  const samplePreservationMethodValues = useSelector(
    (state) => state.samplePreservationMethod
  );
  const { id } = useParams();
  const isNew = useMemo(() => !(id > 0), [id]);
  const [isOpenObject, setIsOpenObject] = useState(false);
  const [isOpenSamplingPurpose, setIsOpenSamplingPurpose] = useState(false);

  const {
    start: startSubmit,
    stop: stopSubmit,
    loading: loadingSubmit,
  } = useLoader(false);
  const {
    start: workflowStart,
    stop: workflowStop,
    loading: workflowLoading,
  } = useLoader(false);
  const style = { marginLeft: 10 };
  const stateCode = data.state?.code;

  const handleChange = useCallback(
    (key, value) => {
      setData({ ...data, [key]: value });
    },
    [data]
  );
  const handleChangeMulti = useCallback(
    (obj) => {
      setData({ ...data, ...obj });
    },
    [data]
  );

  const handleChangeData = useCallback(
    (key, value, key2) => {
      setData({ ...data, data: { ...data.data, [key]: { key: key2, value } } });
    },
    [data]
  );

  const handleChangeSample = useCallback(
    (key, value, i, key2) => {
      const array = data.data?.samples?.value.slice(0);
      array[i][key] = { key: key2, value };
      setData({
        ...data,
        data: {
          ...data.data,
          samples: { key: "samples", value: array },
        },
      });
    },
    [data]
  );

  const schema = {};

  const {
    loading: fetchLoading,
    start: fetchStart,
    stop: fetchStop,
  } = useLoader(true);

  useEffect(() => {
    if (actForm.actForms.length === 0) {
      dispatch(getActForms());
    }
  }, [actForm.actForms.length]);

  useEffect(() => {
    if (governmentStandardValues.governmentStandards.length === 0) {
      dispatch(getGovernmentStandards());
    }
    if (transportationConditionValues.transportationConditions.length === 0) {
      dispatch(getTransportationConditions());
    }
    if (storageConditionValues.storageConditions.length === 0) {
      dispatch(getStorageConditions());
    }
    if (samplePreservationMethodValues.samplePreservationMethods.length === 0) {
      dispatch(getSamplePreservationMethods());
    }
  }, [dispatch]);

  const [isDisabledData, setIsDisabledData] = useState(false);
  const [isDisabledPerformer, setIsDisabledPerformer] = useState(false);
  useEffect(() => {
    const returnIsDisabledData = () => {
      return (
        [
          REQUEST_STATE_ID_PROCESSING,
          REQUEST_STATE_ID_RESEARCH,
          REQUEST_STATE_ID_DECLINED,
          REQUEST_STATE_ID_COMPLETED,
        ].includes(stateCode) ||
        !checkRoles(["SuperAdministrator", "Administrator", "Specialist"])
      );
    };
    const returnIsDisabledPerformer = () => {
      return (
        [
          REQUEST_STATE_ID_RESEARCH,
          REQUEST_STATE_ID_DECLINED,
          REQUEST_STATE_ID_COMPLETED,
        ].includes(stateCode) ||
        !checkRoles(["SuperAdministrator", "Administrator", "Supervisor"]) ||
        stateCode === undefined
      );
    };
    //setIsDisabledData(returnIsDisabledData());
    //setIsDisabledPerformer(returnIsDisabledPerformer());
  }, [stateCode]);

  return (
    <>
      <BasicModalHeader heading="Акт отбора проб" />
      <BasicModalContainer>
        <Form onSubmit={handleSubmit}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody className="border-table-word">
                <TableRow>
                  <TableCell className="border-table-word"></TableCell>
                  <TableCell className="border-table-word" align="left">
                    <p>Нысанның БҚСЖ бойынша коды</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ width: "50%" }}>Код формы по ОКУД</p>
                      <TextField
                        id="outlined-basic"
                        label="Код"
                        style={{ width: "50%" }}
                        variant="outlined"
                      />
                    </div>
                    <p>КҰЖЖ бойынша ұйым коды</p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p style={{ width: "50%" }}>Код организации по ОКПО</p>
                      <TextField
                        id="outlined-basic"
                        label="Код"
                        style={{ width: "50%" }}
                        variant="outlined"
                      />
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="border-table-word">
                    <p>
                      Қазақстан Республикасының Денсаулық сақтау министрлігі
                      Министерство здравоохранения Республики Казахстан
                    </p>
                  </TableCell>
                  <TableCell className="border-table-word" align="left">
                    <p>
                      Қазақстан Республикасының Денсаулық сақтау министрінің
                      2021 жылғы "20" 08 № 84 бұйрығымен бекітілген № 005/е
                      нысанды медициналық құжаттама
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="border-table-word">
                    <p>
                      Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк
                      органының атауы Наименование государственного органа
                      санитарно-эпидемиологической службы
                    </p>
                  </TableCell>
                  <TableCell className="border-table-word" align="left">
                    <p>
                      Медицинская документация Форма № 005/у Утверждена приказом
                      Министра здравоохранения Республики Казахстан от "20" 08
                      2021 года №84
                    </p>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <>
            <div
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h5 align="center">{kazakhName}</h5>
              <h5 align="center">{name} (от)</h5>
              <LocalizationProvider
                locale={ruLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  views={["day"]}
                  label="от"
                  disabled={isDisabledData}
                  value={data.data?.dateOfAct?.value}
                  onChange={(value) => {
                    handleChangeData("dateOfAct", value, "dateOfAct");
                  }}
                  minDate={new Date("1910-03-01")}
                  renderInput={(params) => (
                    <TextField margin="normal" {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>

            {data.number && (
              <h5 style={{ marginBottom: "30px" }} align="center">
                №{data.number}
              </h5>
            )}
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                1. Тексеру белгіленгені туралы актінің нөмірі (Номер акта о
                назначении проверки)
              </p>
              <TextField
                id="outlined-basic"
                label="Номер акта"
                style={{ width: "50%", marginLeft: "30px" }}
                variant="outlined"
                disabled={isDisabledData}
                type="number"
                value={data.data?.number?.value}
                onChange={(e) =>
                  handleChangeData("number", e.target.value, "number")
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                2. Тағайындау актісі күні (Дата акта о назначении проверки)
              </p>
              <LocalizationProvider
                locale={ruLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  views={["day"]}
                  label="Дата акта"
                  disabled={isDisabledData}
                  value={data.data?.date?.value}
                  onChange={(value) => {
                    handleChangeData("date", value, "date");
                  }}
                  minDate={new Date("1910-03-01")}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "50%", margin: "0 0 0 30px" }}
                      margin="normal"
                      {...params}
                      helperText={null}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                3. Сынама үлгісі алынған орын, мекенжай (Место отбора образца,
                адрес)
              </p>
              <TextField
                id="outlined-basic"
                label="Место отбора"
                style={{ width: "50%", marginLeft: "30px" }}
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.selectionPlace?.value}
                onChange={(e) =>
                  handleChangeData(
                    "selectionPlace",
                    e.target.value,
                    "selection_place"
                  )
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                4. Сынамалар алу мақсаты (Цель отбора):
              </p>
              <div
                style={{
                  width: "50%",
                  marginLeft: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    marginBottom: "10px",
                  }}
                  disabled={isDisabledData}
                  variant="text"
                  type="button"
                  onClick={() => {
                    setIsOpenSamplingPurpose(true);
                  }}
                >
                  Выбрать
                </Button>
                <p style={{ marginLeft: "15px", maxWidth: "70%" }}>
                  {data.data?.samplingPurpose?.value?.name || "цель не выбрана"}
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                5. Алынған күні мен уақыты (Дата и время отбора)
              </p>
              <LocalizationProvider
                locale={ruLocale}
                dateAdapter={AdapterDateFns}
              >
                <DateTimePicker
                  views={["day"]}
                  label="Дата и время отбора"
                  disabled={isDisabledData}
                  value={data.data?.selectionDateTime?.value}
                  onChange={(value) =>
                    handleChangeData(
                      "selectionDateTime",
                      value,
                      "selection_time"
                    )
                  }
                  minDate={new Date("1910-03-01")}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "50%", margin: "0 0 0 30px" }}
                      margin="normal"
                      {...params}
                      helperText={null}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                6. Жеткізілген күні мен уақыты (Дата и время доставки)
              </p>
              <LocalizationProvider
                locale={ruLocale}
                dateAdapter={AdapterDateFns}
              >
                <DateTimePicker
                  views={["day"]}
                  label="Дата и время доставки"
                  disabled={isDisabledData}
                  value={data.data?.deliveryDateTime?.value}
                  onChange={(value) =>
                    handleChangeData("deliveryDateTime", value, "delivery_time")
                  }
                  minDate={new Date("1910-03-01")}
                  renderInput={(params) => (
                    <TextField
                      style={{ width: "50%", margin: "0 0 0 30px" }}
                      margin="normal"
                      {...params}
                      helperText={null}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                7. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
                метод отбора)
              </p>
              <FormControl style={{ width: "50%", marginLeft: "30px" }}>
                <InputLabel id="Нормативный документ на метод отбора">
                  Нормативный документ на метод отбора
                </InputLabel>
                <Select
                  labelId="Нормативный документ на метод отбора"
                  label="Нормативный документ на метод отбора"
                  disabled={isDisabledData}
                  value={data.data?.standard?.value}
                  onChange={(e) =>
                    handleChangeData("standard", e.target.value, "nd_selection")
                  }
                >
                  {governmentStandardValues.governmentStandards.length !== 0 &&
                    governmentStandardValues.governmentStandards.map((obj) => {
                      return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                8. Тасымалдау жағдайы (Условия транспортировки)
              </p>
              <FormControl style={{ width: "50%", marginLeft: "30px" }}>
                <InputLabel id="Условия транспортировки">
                  Условия транспортировки
                </InputLabel>
                <Select
                  labelId="Условия транспортировки"
                  label="Условия транспортировки"
                  disabled={isDisabledData}
                  value={data.data?.transportationCondition?.value}
                  onChange={(e) =>
                    handleChangeData(
                      "transportationCondition",
                      e.target.value,
                      "transportation_conditions"
                    )
                  }
                >
                  {transportationConditionValues.transportationConditions
                    .length !== 0 &&
                    transportationConditionValues.transportationConditions.map(
                      (obj) => {
                        return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                      }
                    )}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "5px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                9. Үлгілерді консервациялау әдiстерi (Методы консервации
                образца){" "}
              </p>
              <FormControl style={{ width: "50%", marginLeft: "30px" }}>
                <InputLabel id="Методы консервации образца">
                  Методы консервации образца
                </InputLabel>
                <Select
                  labelId="Методы консервации образца"
                  label="Методы консервации образца"
                  disabled={isDisabledData}
                  value={data.data?.preservationMethod?.value}
                  onChange={(e) =>
                    handleChangeData(
                      "preservationMethod",
                      e.target.value,
                      "preservationMethod"
                    )
                  }
                >
                  {samplePreservationMethodValues.samplePreservationMethods
                    .length !== 0 &&
                    samplePreservationMethodValues.samplePreservationMethods.map(
                      (obj) => {
                        return <MenuItem value={obj.name}>{obj.name}</MenuItem>;
                      }
                    )}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "30px",
                alignItems: "center",
              }}
            >
              <p style={{ width: "50%" }}>
                10. Қосымша мәліметтер (Дополнительные сведения)
              </p>
              <TextField
                id="outlined-basic"
                label="Дополнительные сведения"
                style={{ width: "50%", marginLeft: "30px" }}
                variant="outlined"
                disabled={isDisabledData}
                value={data.data?.additionalInfo?.value}
                onChange={(e) =>
                  handleChangeData(
                    "additionalInfo",
                    e.target.value,
                    "additionalInfo"
                  )
                }
              />
            </div>
            <TableContainer
              component={Paper}
              style={{ marginBottom: "30px", overflow: "hidden" }}
            >
              <Table aria-label="simple table" className="border-table-word">
                <TableHead>
                  <TableRow>
                    <TableCell className="border-table-word">
                      Үлгіні тiркеу нөмірi (Регистрационный номер образца)
                    </TableCell>
                    <TableCell className="border-table-word">
                      Алынған үлгілер атауының тізбесі (Перечень наименований
                      отобранных образцов)
                    </TableCell>
                    <TableCell className="border-table-word">
                      Алынған үлгілер саны (Количество отобранных образцов)
                    </TableCell>
                    <TableCell className="border-table-word">
                      Үлгінің салмағы, көлемі (Вес, объем образца)
                    </TableCell>
                    <TableCell className="border-table-word">
                      Шығарылған күні, жарамдылық мерзімі, сериясы, партия
                      нөмері (Дата производства, срок годности, серия, номер
                      партии)
                    </TableCell>
                    <TableCell className="border-table-word">
                      Күні, ТАӘ, үлгіні (сынама) қабылдаған тұлғаның қолы (Дата,
                      ФИО, подпись лица принявшего образец (пробу))
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.data?.samples?.value?.map((sample, i) => {
                    return (
                      <TableRow key={i}>
                        <TableCell className="border-table-word">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Регистрационный номер"
                            variant="outlined"
                            type="number"
                            disabled={isDisabledData}
                            value={sample.registrationNumber?.value}
                            onChange={(e) =>
                              handleChangeSample(
                                "registrationNumber",
                                e.target.value,
                                i,
                                "registrationNumber"
                              )
                            }
                          />
                        </TableCell>
                        <TableCell className="border-table-word">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Перечень отобранных образцов"
                            variant="outlined"
                            disabled={isDisabledData}
                            value={sample.sample?.value}
                            onChange={(e) =>
                              handleChangeSample(
                                "sample",
                                e.target.value,
                                i,
                                "sample"
                              )
                            }
                          />
                        </TableCell>
                        <TableCell className="border-table-word">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Количество"
                            variant="outlined"
                            disabled={isDisabledData}
                            value={sample.quantity?.value}
                            type="number"
                            onChange={(e) =>
                              handleChangeSample(
                                "quantity",
                                e.target.value,
                                i,
                                "quantity"
                              )
                            }
                          />
                        </TableCell>
                        <TableCell className="border-table-word">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Вес, объем образца"
                            variant="outlined"
                            disabled={isDisabledData}
                            value={sample.weightSize?.value}
                            onChange={(e) =>
                              handleChangeSample(
                                "weightSize",
                                e.target.value,
                                i,
                                "weightSize"
                              )
                            }
                          />
                        </TableCell>
                        <TableCell className="border-table-word">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Дата производства, срок годности, серия, номер партии"
                            variant="outlined"
                            disabled={isDisabledData}
                            value={sample.manufactureInfo?.value}
                            onChange={(e) =>
                              handleChangeSample(
                                "manufactureInfo",
                                e.target.value,
                                i,
                                "manufactureInfo"
                              )
                            }
                          />
                        </TableCell>
                        <TableCell className="border-table-word">
                          <TextField
                            style={{ width: "100%" }}
                            id="outlined-basic"
                            label="Дата, ФИО, подпись лица принявшего образец (пробу)"
                            variant="outlined"
                            disabled={isDisabledData}
                            value={sample.receiptInfo?.value}
                            onChange={(e) =>
                              handleChangeSample(
                                "receiptInfo",
                                e.target.value,
                                i,
                                "receiptInfo"
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                display: "flex",
                marginBottom: "30px",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  border: "1px solid black",
                  padding: "5px 10px",
                  borderRadius: "6px",
                }}
                disabled={isDisabledData}
                variant="contained"
                onClick={() => {
                  data.data?.samples?.value
                    ? setData({
                        ...data,
                        data: {
                          ...data.data,
                          samples: {
                            key: "samples",
                            value: [...data.data?.samples?.value, {}],
                          },
                        },
                      })
                    : setData({
                        ...data,
                        data: {
                          ...data.data,
                          samples: { key: "samples", value: [{}] },
                        },
                      });
                }}
              >
                Добавить
              </Button>
            </div>
            <EmployeeField
              style={{ marginBottom: "5px" }}
              label={"Ответственный за отбор проб"}
              value={data.responsibleForProbesId}
              disabled={isDisabledData}
              object={data.responsibleForProbes}
              onChange={(val) => {
                setData({
                  ...data,
                  responsibleForProbesId: val?.id,
                  responsibleForProbes: val,
                });
              }}
            />
            <FilialSelect
              style={{ marginBottom: "5px" }}
              label={"Филиал"}
              fullWidth
              disabled={isDisabledData}
              value={data.filialId}
              onChange={(val) => {
                handleChangeMulti({
                  filialId: val,
                  filialDivisionId:
                    Number(val) !== Number(data.filialId)
                      ? null
                      : data.filialDivisionId,
                });
              }}
            />
            <FilialDivisionSelect
              style={{ marginBottom: "5px" }}
              label={"Районное отделение"}
              fullWidth
              disabled={isDisabledData}
              value={data.filialDivisionId}
              filialId={data.filialId}
              onChange={(filialDivisionId, filialDivision) => {
                handleChangeMulti({
                  filialDivisionId: filialDivisionId,
                  filialId:
                    Number(filialDivision.filialId) !== Number(data.filialId)
                      ? filialDivision.filialId
                      : data.filialId,
                });
              }}
            />
            <Typography gutterBottom variant={"h5"}>
              Передача проб в лабораторию:
            </Typography>
            <DepartmentSelect
              disabled={isDisabledData}
              style={{ marginBottom: "5px" }}
              label={"Лаборатория"}
              fullWidth
              value={data.departmentId}
              onChange={(val) => handleChange("departmentId", val)}
            />
            <DivisionSelect
              style={{ marginBottom: "5px" }}
              label={"Отдел"}
              fullWidth
              value={data.divisionId}
              disabled={isDisabledData}
              onChange={(val) => handleChange("divisionId", val)}
              filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
            />
            <EmployeeField
              label={"Заведующий лаборатории/Начальник отдела"}
              fullWidth
              extraFilter={
                data.departmentId > 0
                  ? {
                      departmentId: {
                        operand1: data.departmentId,
                        operator: "equals",
                      },
                    }
                  : null
              }
              disabled={isDisabledData}
              object={data.responsible}
              value={data.responsibleId}
              onChange={(responsible) => {
                const obj = {
                  responsible,
                  responsibleId: responsible.id,
                  departmentId: responsible.departmentId,
                };
                handleChangeMulti(obj);
              }}
            />
            <EmployeeField
              label={"Лаборант"}
              fullWidth
              object={data.performer}
              value={data.performerId}
              disabled={isDisabledPerformer}
              onChange={(performer) => {
                const obj = {
                  performer,
                  performerId: performer.id,
                };
                handleChangeMulti(obj);
              }}
            />
          </>
          <LoadingButton loading={loading} onClick={handleSubmit}>
            Создать
          </LoadingButton>
        </Form>
      </BasicModalContainer>
    </>
  );
};
