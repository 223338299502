import { 
    DIVISION_GET_REQUEST, DIVISION_GET_SUCCESS, DIVISION_GET_ERROR, 
    DIVISION_GET_BY_KEY_REQUEST, DIVISION_GET_BY_KEY_SUCCESS, DIVISION_GET_BY_KEY_ERROR, 
    DIVISION_ADD_REQUEST, DIVISION_ADD_SUCCESS, DIVISION_ADD_ERROR, 
    DIVISION_EDIT_REQUEST, DIVISION_EDIT_SUCCESS, DIVISION_EDIT_ERROR, 
    DIVISION_DELETE_REQUEST, DIVISION_DELETE_SUCCESS, DIVISION_DELETE_ERROR
} from "../constants/divisionConstants";

const initialState = {
    divisions: [],
    division: {},
    loading: false,
    success: null,
    error: null,
    divisionRequestedCount: 0,
};

export const divisionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case DIVISION_GET_REQUEST:
            return {
                ...state,
                divisionRequestedCount: ++state.divisionRequestedCount,
                loading: true,
            };
        case DIVISION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                divisions: action.payload,
                success: true,
            };
        case DIVISION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DIVISION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DIVISION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                division: action.payload,
                success: true,
            };
        case DIVISION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DIVISION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DIVISION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DIVISION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DIVISION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DIVISION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DIVISION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DIVISION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DIVISION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DIVISION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
