import { LoadingButton } from "@mui/lab";
import {
  Button,
} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {useHistory, useParams, useLocation} from "react-router-dom";

import {
  PageHeader,
} from "../../../components";
import DashboardLayout from "../../../layouts/Dashboard";
import {
  workflowSelfControlRequest
} from "../../../redux/actions/selfControlRequestActions";
import { getSelfControlRequestByKey } from "../../../redux/actions/selfControlRequestActions";
import {renderControls, RequestStateChecker} from "./../../../functions";
import {
SelfControlRequestEditSection,
  SelfControlRequestInfoSection,
} from "./Sections";
import { selfControlRequestCRUD } from "../../../http/CRUD";
import {CONTRACT_REQUEST, DEPARTURE_SCHEDULE, SELF_CONTROL_REQUEST} from "../../../routes";
import {REQUEST_STATE_ID_CREATED} from "../../../redux/constants/requestStateConstants";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";

export const SelfControlRequest = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {review} = useLocation().state || false
  const dispatch = useDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false);

  const [requestId, setRequestId] = useState(id);
  const [stateId, setStateId] = useState(0);
  const [request, setRequest] = useState({});

  const { selfControlRequest } = useSelector(
    (s) => s.selfControlRequest
  );

  const stateCode = selfControlRequest?.state?.code?.toLowerCase();

  const state = useMemo(
      () => new RequestStateChecker(selfControlRequest?.state?.code),
      [selfControlRequest?.state?.code]
  );

  const handleEdit = async (e) => {
    e.preventDefault();
    console.log(request)
    await selfControlRequestCRUD.edit({...request});

  };

  const handleWorkflow = async (e, nextStateId) => {
    e.preventDefault();
    setLoading(true);

    if (!selfControlRequest?.state?.id) return setLoading(false);

    const prevStateId = selfControlRequest.state.id;

    // Updating Workflow
    const updated = await dispatch(
      workflowSelfControlRequest(id, prevStateId, nextStateId)
    );
    if (!updated) return;

    setStateId(nextStateId);
    setLoading(false);
  };

  const handleWorkflowDeclinedToCreate = async () => {
    setLoading(true);

    if (!selfControlRequest?.state?.id) return setLoading(false);

    const prevStateId = selfControlRequest.state.id;
    const nextStateId = state.getId(REQUEST_STATE_ID_CREATED);

    // Updating Workflow
    const updated = await dispatch(
      workflowSelfControlRequest(requestId, prevStateId, nextStateId)
    );
    if (!updated) return setLoading(false);

    setStateId(nextStateId);
    setLoading(false);
  };


  const controls = [
    {
      state: "created",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 2)}
        >
          Начать обработку
        </LoadingButton>,
        <Button
            color="secondary"
            onClick={handleEdit}
        >
          Сохранить
        </Button>,
        <Button
            variant={"text"}
            onClick={() => history.push(SELF_CONTROL_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "processing",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 4)}
        >
          Завершить
        </LoadingButton>,
        <LoadingButton color="warning" onClick={(e) => handleWorkflow(e, 5)}>
          Отклонить с комментариями
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(SELF_CONTROL_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "completed",
      roles: [],
      buttons: [
        <Button
            variant={"text"}
            onClick={() => history.push(DEPARTURE_SCHEDULE)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "declined",
      roles: [],
      buttons: [
        <LoadingButton
            loading={loading}
            onClick={() => {
              confirmDialog('Пересоздание', 'Вы действительно хотите пересоздать заявку?', async () => {
                await handleWorkflowDeclinedToCreate()
              })
            }
            }
        >
          Пересоздать
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    }
  ];

  useEffect(() => {
    dispatch(getSelfControlRequestByKey(requestId));
  }, [dispatch, requestId, stateId]);

  return (
    <DashboardLayout>
      <PageHeader
        heading={`Заявка по самоконтролю №${id} ${
          stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
        }`}
        status={selfControlRequest.state?.name}
        controls={renderControls(controls, {
          state: stateCode,
          roles: [],
        })}
      />
      {review ? <SelfControlRequestInfoSection request={request} setRequest={setRequest}/> : <SelfControlRequestEditSection/>}
    </DashboardLayout>
  );
};
