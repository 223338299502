import { 
    CLINICAL_REQUEST_GET_REQUEST, CLINICAL_REQUEST_GET_SUCCESS, CLINICAL_REQUEST_GET_ERROR, 
    CLINICAL_REQUEST_GET_BY_KEY_REQUEST, CLINICAL_REQUEST_GET_BY_KEY_SUCCESS, CLINICAL_REQUEST_GET_BY_KEY_ERROR, 
    CLINICAL_REQUEST_ADD_REQUEST, CLINICAL_REQUEST_ADD_SUCCESS, CLINICAL_REQUEST_ADD_ERROR, 
    CLINICAL_REQUEST_EDIT_REQUEST, CLINICAL_REQUEST_EDIT_SUCCESS, CLINICAL_REQUEST_EDIT_ERROR, 
    CLINICAL_REQUEST_DELETE_REQUEST, CLINICAL_REQUEST_DELETE_SUCCESS, CLINICAL_REQUEST_DELETE_ERROR
} from "../constants/clinicalRequestConstants";

const initialState = {
    clinicalRequests: [],
    clinicalRequest: {},
    loading: false,
    success: null,
    error: null,
    clinicalRequestRequestedCount: 0,
};

export const clinicalRequestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CLINICAL_REQUEST_GET_REQUEST:
            return {
                ...state,
                clinicalRequestRequestedCount: ++state.clinicalRequestRequestedCount,
                loading: true,
            };
        case CLINICAL_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                clinicalRequests: action.payload,
                success: true,
            };
        case CLINICAL_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLINICAL_REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLINICAL_REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                clinicalRequest: action.payload,
                success: true,
            };
        case CLINICAL_REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLINICAL_REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLINICAL_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CLINICAL_REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLINICAL_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLINICAL_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CLINICAL_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLINICAL_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CLINICAL_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CLINICAL_REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
