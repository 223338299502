import { 
    SERVICE_WITHOUT_ACT_REQUEST_GET_REQUEST, SERVICE_WITHOUT_ACT_REQUEST_GET_SUCCESS, SERVICE_WITHOUT_ACT_REQUEST_GET_ERROR, 
    SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_REQUEST, SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_SUCCESS, SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_ERROR, 
    SERVICE_WITHOUT_ACT_REQUEST_ADD_REQUEST, SERVICE_WITHOUT_ACT_REQUEST_ADD_SUCCESS, SERVICE_WITHOUT_ACT_REQUEST_ADD_ERROR, 
    SERVICE_WITHOUT_ACT_REQUEST_EDIT_REQUEST, SERVICE_WITHOUT_ACT_REQUEST_EDIT_SUCCESS, SERVICE_WITHOUT_ACT_REQUEST_EDIT_ERROR, 
    SERVICE_WITHOUT_ACT_REQUEST_DELETE_REQUEST, SERVICE_WITHOUT_ACT_REQUEST_DELETE_SUCCESS, SERVICE_WITHOUT_ACT_REQUEST_DELETE_ERROR
} from "../constants/serviceWithoutActRequestConstants";

const initialState = {
    serviceWithoutActRequests: [],
    serviceWithoutActRequest: {},
    loading: false,
    success: null,
    error: null,
    serviceWithoutActRequestRequestedCount: 0,
};

export const serviceWithoutActRequestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_WITHOUT_ACT_REQUEST_GET_REQUEST:
            return {
                ...state,
                serviceWithoutActRequestRequestedCount: ++state.serviceWithoutActRequestRequestedCount,
                loading: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceWithoutActRequests: action.payload,
                success: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceWithoutActRequest: action.payload,
                success: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_WITHOUT_ACT_REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
