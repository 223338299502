import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Box,
  Button,
  FormGroup,
  InputLabel,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Typography,
  Divider,
} from "@mui/material";
import { LoadingButton, TimePicker } from "@mui/lab";

import { getDepartureScheduleRequestByKey } from "../../../../redux/actions";
import {
  PageHeader,
  PageContainer,
  Form,
  FormSection,
  CustomDatePicker,
  BasicTable,
  ConditionalContainer,
  ButtonModal,
  CustomTimePicker,
} from "../../../../components";

import { ActCreateModal } from "./../../../Acts";

import { ReferralRequestCreateModal } from "../../..";
import { RequestStateChecker } from "../../../../functions";
import ActField from "../../../../components/Relations/ActField";
import useReload from "../../../../hooks/useReload";

export const DepartureScheduleRequestInfoSection = ({ mode }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { reload, reloadValue } = useReload();


  const [requestId, setRequestId] = useState(id);
  const [request, setRequest] = useState({});

  const { departureScheduleRequest } = useSelector(
    (s) => s.departureScheduleRequest
  );

  const stateCode = request?.state?.code?.toLowerCase();
  const state = new RequestStateChecker(stateCode);

  const [specifications, setSpecifications] = useState([]);
  const [objects, setObjects] = useState([]);

  const [acts, setActs] = useState([]);

  const [performerComment, setPerformerComment] = useState(null);
  const handlePerformerCommentChange = (e) =>
    setPerformerComment(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(getDepartureScheduleRequestByKey(requestId)).then(setRequest);
  }, [dispatch, requestId, reloadValue]);

  useEffect(() => {
    setObjects(departureScheduleRequest.objects);
    setSpecifications(departureScheduleRequest.specifications);
    setActs(departureScheduleRequest.acts);
    setPerformerComment(departureScheduleRequest.performerComment);
  }, [departureScheduleRequest]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Номер заявки"
                  type="number"
                  value={request?.contractRequestNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={request?.contractRequestId}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  type="text"
                  value={request?.createdBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      label="Дата заявки"
                      value={request?.createdAt}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Объекты ({objects?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    {
                      id: "name",
                      label: t("object.tableItem.name"),
                      render: (row) => row?.object?.name,
                    },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={objects}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Услуги ({specifications?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                    order="asc"
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "group",
                      label: 'Группа',
                      render: ({ service }) => service?.group?.name,
                    },
                    {
                      id: "method",
                      label: 'Метод',
                      render: ({ service }) => service?.kind?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                    },
                    {
                      id: "contractCount",
                      label: t("specification.tableItem.quantityContract"),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={specifications}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Филиал"
                  value={request?.filial?.name}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Районное отделение"
                  value={request?.filialDivision?.name}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Комментарий автора"
                  value={request?.authorComment}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Комментарий исполнителя"
                  value={performerComment}
                  onChange={handlePerformerCommentChange}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата выезда"
                  value={request?.dateOfDeparture}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <ConditionalContainer
            condition={state.isProcessing() || state.isFinished()}
          >
            <Divider style={{ margin: "50px 0px" }} />

            <ActField departureSchedules reload={reload} value={acts} button={<ButtonModal modal={ActCreateModal}>
              Добавить акт
            </ButtonModal>}/>
          </ConditionalContainer>
        </PageContainer>
      </Form>
    </>
  );
};
