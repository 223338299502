import * as React from "react";
import {useEffect, useState} from "react";
import DialogLayout from "../../../components/Dialog/DialogLayout";
import {SelectField} from "../../../components";

const ChooseSamples = ({open, setOpen, data, setChooseSampleObject, resultFormId}) => {
    const [options, setOptions] = useState([])

    const handleSelect = (e) => {
        setChooseSampleObject(e.target.value)
        setOpen(false)
    }

    useEffect(() => {
        if (data) {
            const {samples} = data;
            let options = samples?.value?.map(val => {
                switch (resultFormId) {
                    case 212: {
                        return {
                            value: {
                                sampleNameQuantityVolumePlaceOfReceipt: val?.weightSize?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 223: {
                        return {
                            value: {
                                sampleWeightAndVolume: val?.weightSize?.value || val?.quantitySize?.value,
                                sampleName: val?.sample?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 207: {
                        return {
                            value: {
                                sampleWeightAndVolume: val?.weightSize?.value || val?.quantitySize?.value,
                                sampleName: val?.sample?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 210: {
                        return {
                            value: {
                                sampleWeightAndVolume: val?.weightSize?.value || val?.quantitySize?.value,
                                sampleName: val?.sample?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 325: {
                        return {
                            value: {
                                modelName: val?.sample?.value,
                                quantityVolume: val?.size?.value || val?.quantity?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 243: {
                        return {
                            value: {
                                sampleNameContainerBoxMarkingNumberOfSamples: val?.sample?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 250: {
                        return {
                            value: {
                                quantityVolumeBox: val?.ticksQuantity?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, № образца: ${val?.sampleNumber?.value}`
                        }
                    }
                    case 220: {
                        return {
                            value: {
                                sampleNameSampleConcentrationQuantityVolume: val?.quantityWeightSize?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 227: {
                        return {
                            value: {
                                sampleName: val?.sample?.value,
                                sampleVolumeWeight: val?.weightSize?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 228: {
                        return {
                            value: {
                                sampleName: val?.sample?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 333: {
                        return {
                            value: {
                                modelNameSupplierManufacturer: val?.sample?.value,
                                sampleWeightVolume: val?.weightSize?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 335: {
                        return {
                            value: {
                                modelName: val?.sample?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 288: {
                        return {
                            value: {
                                sampleName: val?.sample?.value,
                                sampleWeightG: val?.weightSize?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                    case 332: {
                        return {
                            value: {
                                modelName: val?.sample?.value,
                                sampleWeightG: val?.weightSize?.value,
                            },
                            label: `Регистрационный номер: ${val?.registrationNumber?.value}, перечень отобранных образцов: ${val?.sample?.value}`
                        }
                    }
                }
            })
            setOptions(options)
        }
    }, [data, resultFormId])

    return (
        <DialogLayout setOpen={setOpen} open={open} title="Выбор образца">

            <SelectField
                onChange={handleSelect} options={options} label="Показатели"
             />
        </DialogLayout>
    )
}

export default ChooseSamples
