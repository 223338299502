import { 
    REFERRAL_TRACE_GET_REQUEST, REFERRAL_TRACE_GET_SUCCESS, REFERRAL_TRACE_GET_ERROR, 
    REFERRAL_TRACE_GET_BY_KEY_REQUEST, REFERRAL_TRACE_GET_BY_KEY_SUCCESS, REFERRAL_TRACE_GET_BY_KEY_ERROR, 
    REFERRAL_TRACE_ADD_REQUEST, REFERRAL_TRACE_ADD_SUCCESS, REFERRAL_TRACE_ADD_ERROR, 
    REFERRAL_TRACE_EDIT_REQUEST, REFERRAL_TRACE_EDIT_SUCCESS, REFERRAL_TRACE_EDIT_ERROR, 
    REFERRAL_TRACE_DELETE_REQUEST, REFERRAL_TRACE_DELETE_SUCCESS, REFERRAL_TRACE_DELETE_ERROR
} from "../constants/referralTraceConstants";

const initialState = {
    referralTraces: [],
    referralTrace: {},
    loading: false,
    success: null,
    error: null,
    referralTraceRequestedCount: 0,
};

export const referralTraceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case REFERRAL_TRACE_GET_REQUEST:
            return {
                ...state,
                referralTraceRequestedCount: ++state.referralTraceRequestedCount,
                loading: true,
            };
        case REFERRAL_TRACE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                referralTraces: action.payload,
                success: true,
            };
        case REFERRAL_TRACE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_TRACE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_TRACE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                referralTrace: action.payload,
                success: true,
            };
        case REFERRAL_TRACE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_TRACE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_TRACE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_TRACE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_TRACE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_TRACE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_TRACE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case REFERRAL_TRACE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case REFERRAL_TRACE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case REFERRAL_TRACE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
