import DepartmentCRUDTable from "../../components/CRUD/Tables/DepartmentCRUDTable";
import DashboardLayout from "../../layouts/Dashboard";
import {useHistory} from "react-router-dom";
import useReload from "../../hooks/useReload";
import {DEPARTMENT_DETAIL_ROUTE, FORM_MAP_DETAIL_ROUTE} from "../../routes";
import {departmentCRUD, formMapCRUD} from "../../http";


const DepartmentList = ()=>{
    const history = useHistory()
    const {reload, reloadValue} = useReload();

    const detailAction =(id)=>{
        history.push(DEPARTMENT_DETAIL_ROUTE(id),{
            review:true
        })
    }

    const editAction =(id)=>{
        history.push(DEPARTMENT_DETAIL_ROUTE(id),{
            review:false
        })
    }

    const deleteAction =async(id)=> {
        try {
            await departmentCRUD.delete(id)
            reload()
        } catch (err) {

        }
    }
    return <DashboardLayout>
        <DepartmentCRUDTable onClickDetailsButton={detailAction}
                             onClickDeleteButton={deleteAction}
                             onClickEditButton={editAction} reloadValue={reloadValue}/>
    </DashboardLayout>

}

export default DepartmentList