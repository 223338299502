// export const LOGIN_ROUTE = '/sign-in';
export const LOGIN_ROUTE = "/sign-in";
export const SIGN_UP_ROUTE = "/sign-up";
export const SERVICE_LIST_ROUTE = "/serviceList";
export const SERVICE_LIST_CLIENT_ROUTE = "/serviceListClient";
export const SERVICE_LIST_CLIENT_ADD_ROUTE = "/serviceListClient-add";
export const SERVICE_LIST_ADD_ROUTE = "/serviceList-add";
export const SERVICE_LIST_EDIT_ROUTE = "/serviceList-edit";
export const SERVICE_LIST_CLIENT_EDIT_ROUTE = "/serviceListClient-edit";
export const REFERRAL_FORM_LIST_ROUTE = "/referralFormList";
export const REFERRAL_LIST_ROUTE = "/referralList";
export const DASHBOARD_ROUTE = "/dashboard";
export const OBJECT_REFERRAL_LIST_ROUTE = "/objectReferralList";
export const OBJECT_REFERRAL_DETAIL_ROUTE = (id = null) =>
    `/object-referral/${id || ":id"}`;
export const CLINICAL_REQUEST_LIST_ROUTE = "/clinicalRequestList";
export const DEPARTURE_SCHEDULE_REQUEST_LIST_ROUTE =
    "/departureScheduleRequestList";
export const DESINSECTION_REQUEST_LIST_ROUTE = "/desinsectionRequestList";
export const SERVICE_WITHOUT_ACT_REQUEST_LIST_ROUTE =
    "/serviceWithoutActRequestList";
export const WITHOUT_DEPARTURE_REQUEST_LIST_ROUTE =
    "/withoutDepartureRequestList";
export const BRIGADE_LIST_ROUTE = "/brigadeList";
export const DESINFECTION_REFERENCE_LIST_ROUTE = "/desinfectionReferenceList";
export const RESULT_FORM_LIST_ROUTE = "/resultFormList";
export const PROBE_LIST_ROUTE = "/probeList";
export const RESULT_LIST_ROUTE = "/resultList";
export const RESULT_LIST_ROUTE_PAID = "/resultListPaid";
export const RESULT_LIST_ROUTE_OR = "/resultListObjectReferral";
export const PROTOCOL_FORM_LIST_ROUTE = "/protocolFormList";
export const PROTOCOL_LIST_ROUTE = "/protocolList";
export const PROTOCOL_LIST_ROUTE_PAID = "/protocolListPaid";
export const PROTOCOL_LIST_ROUTE_OR = "/protocolListObjectReferral";
export const PATIENT_LIST_ROUTE = "/patientList";
export const SERVICE_KIND_LIST_ROUTE = "/serviceKindList";
export const SERVICE_PRICE_LIST_ROUTE = "/servicePriceList";
export const SERVICE_EXPENSE_LIST_ROUTE = "/serviceExpenseList";
export const CONTRACT_SPECIFICATION_LIST_ROUTE = "/contractSpecificationList";
export const REAGENT_LIST_ROUTE = "/reagentList";

export const REQUEST_LIST_ROUTE = "/requestList";
export const TARIFFICATION_ROUTE = "/tariffication";
export const ACT_ROUTE = (formNumber, id = null) => {
    switch (formNumber) {
        case 'ActBioTestDSEK':
        case 'ActBioTest':
            return ACT_BIO_TEST_DETAIL(id);
        case 'ActBacteriologicalTest':
        case 'ActBacteriologicalTestDSEK':
            return ACT_BACTERIOLOGICAL_DETAIL(id);
        case 'ActWasteDSEK':
        case 'ActWaste':
            return ACT_WASTE_DETAIL(id);
        case 'ActBuyProductsDSEK':
        case 'ActBuyProducts':
            return ACT_BUY_PRODUCTS_DETAIL(id);
        case 'ActWaterDSEK':
        case 'ActWater':
            return ACT_WATER_DETAIL(id);
        case 'ActSamplingReportDSEK':
        case 'ActSamplingReport':
            return ACT_SAMPLING_REPORT_DETAIL(id);
        case 'ActLandDSEK':
        case 'ActLand':
            return ACT_LAND_DETAIL(id);
        case 'ActProductsDSEK':
        case 'ActProducts':
            return ACT_PRODUCTS_DETAIL(id);
        case 'ActRadioDSEK':
        case 'ActRadio':
            return ACT_RADIO_DETAIL(id);
        case 'ActDisinfectantDSEK':
        case 'ActDisinfectant':
            return ACT_DISINFECTANT_DETAIL(id);
        case 'ActZoonoticDSEK':
        case 'ActZoonotic':
            return ACT_ZOONOTIC_DETAIL(id);
        case 'ActTicksDSEK':
        case 'ActTicks':
            return ACT_TICKS_DETAIL(id);
        case 'DirectionVirus':
            return DIRECTION_VIRUS_DETAIL(id);
        case 'DirectionBlood':
            return DIRECTION_BLOOD_DETAIL(id);
        case 'DirectionSampling':
            return DIRECTION_SAMPLING_DETAIL(id);
        case 'DirectionMaterial':
            return DIRECTION_MATERIAL_DETAIL(id);
        case 'DirectionParasite':
            return DIRECTION_PARASITE_DETAIL(id);
        case 'DirectionMicrobio':
            return DIRECTION_MICROBIO_DETAIL(id);
        case 'DirectionPeople':
            return DIRECTION_PEOPLE_DETAIL(id);
        case 'DirectionCholera':
            return DIRECTION_CHOLERA_DETAIL(id);
        case 'ReferenceDisinfection':
            return REFERENCE_DISINFECTION_DETAIL(id);
        default:
            return null;
    }
}

export const BRIGADE_ROUTE = (typeName, id = null) => {
    switch (typeName) {
        case 'DddDisinsection':
            return DDD_DISINSECTION_DETAIL(id);
        case 'DddFinalDisinfection':
            return DDD_FINAL_DISINFECTION_DETAIL(id);
        default:
            return null;
    }
}

export const ACT_BIO_TEST = "/actBioTest";
export const ACT_BIO_TEST_DSEK = "/actBioTestDSEK";
export const ACT_BIO_TEST_DETAIL = (id = null) => `/actBioTestDetail/${id || ":id"}`;

export const ACT_BACTERIOLOGICAL_TEST = "/actBacteriologicalTest";
export const ACT_BACTERIOLOGICAL_TEST_DSEK = "/actBacteriologicalTestDSEK";
export const ACT_BACTERIOLOGICAL_DETAIL = (id = null) => `/actBacteriologicalTestDetail/${id || ":id"}`;

export const ACT_WASTE = "/actWaste";
export const ACT_WASTE_DSEK = "/actWasteDSEK";
export const ACT_WASTE_DETAIL = (id = null) => `/actWasteDetail/${id || ":id"}`;
export const ACT_WATER = "/actWater";
export const ACT_WATER_DSEK = "/actWaterDSEK";
export const ACT_BUY_PRODUCTS = "/actBuyProducts";
export const ACT_BUY_PRODUCTS_DETAIL = (id = null) => `/actBuyProductsDetail/${id || ":id"}`;
export const ACT_BUY_PRODUCTS_DSEK = "/actBuyProductsDSEK";
export const ACT_WATER_DETAIL = (id = null) => `/actWaterDetail/${id || ":id"}`;
export const ACT_SAMPLING_REPORT = "/actSamplingReport";
export const ACT_SAMPLING_REPORT_DSEK = "/actSamplingReportDSEK";
export const ACT_SAMPLING_REPORT_DETAIL = (id = null) =>
    `/actSamplingReportDetail/${id || ":id"}`;
export const ACT_LAND = "/actLand";
export const ACT_LAND_DSEK = "/actLandDSEK";
export const ACT_LAND_DETAIL = (id = null) => `/actLandDetail/${id || ":id"}`;
export const ACT_PRODUCTS = "/actProducts";
export const ACT_PRODUCTS_DSEK = "/actProductsDSEK";
export const ACT_PRODUCTS_DETAIL = (id = null) =>
    `/actProductsDetail/${id || ":id"}`;
export const ACT_RADIO = "/actRadio";
export const ACT_RADIO_DSEK = "/actRadioDSEK";
export const ACT_RADIO_DETAIL = (id = null) => `/actRadioDetail/${id || ":id"}`;
export const ACT_DISINFECTANT = "/actDisinfectant";
export const ACT_DISINFECTANT_DSEK = "/actDisinfectantDSEK";
export const ACT_DISINFECTANT_DETAIL = (id = null) =>
    `/actDisinfectantDetail/${id || ":id"}`;
export const ACT_ZOONOTIC = "/actZoonotic";
export const ACT_ZOONOTIC_DSEK = "/actZoonoticDSEK";
export const ACT_ZOONOTIC_DETAIL = (id = null) =>
    `/actZoonoticDetail/${id || ":id"}`;
export const ACT_TICKS = "/actTicks";
export const ACT_TICKS_DSEK = "/actTicksDSEK";
export const ACT_TICKS_DETAIL = (id = null) => `/actTicksDetail/${id || ":id"}`;
export const DIRECTION_VIRUS = "/directionVirus";
export const DIRECTION_VIRUS_DETAIL = (id = null) =>
    `/directionVirusDetail/${id || ":id"}`;
export const DIRECTION_DISINFECTION_CHAMBER = "/directionDisinfectionChamber";
export const DIRECTION_DISINFECTION_CHAMBER_DETAIL = (id = null) =>
    `/directionDisinfectionChamberDetail/${id || ":id"}`;
export const DIRECTION_BLOOD = "/directionBlood";
export const DIRECTION_BLOOD_DETAIL = (id = null) =>
    `/directionBloodDetail/${id || ":id"}`;
export const DIRECTION_SAMPLING = "/directionSampling";
export const DIRECTION_SAMPLING_DETAIL = (id = null) =>
    `/directionSamplingDetail/${id || ":id"}`;
export const DIRECTION_MATERIAL = "/directionMaterial";
export const DIRECTION_MATERIAL_DETAIL = (id = null) =>
    `/directionMaterialDetail/${id || ":id"}`;
export const DIRECTION_PARASITE = "/directionParasite";
export const DIRECTION_PARASITE_DETAIL = (id = null) =>
    `/directionParasiteDetail/${id || ":id"}`;
export const DIRECTION_MICROBIO = "/directionMicrobio";
export const DIRECTION_MICROBIO_DETAIL = (id = null) =>
    `/directionMicrobioDetail/${id || ":id"}`;
export const DIRECTION_PEOPLE = "/directionPeople";
export const DIRECTION_PEOPLE_DETAIL = (id = null) =>
    `/directionPeopleDetail/${id || ":id"}`;
export const DIRECTION_CHOLERA = "/directionCholera";
export const DIRECTION_CHOLERA_DETAIL = (id = null) =>
    `/directionCholeraDetail/${id || ":id"}`;

// ContractRequest
export const CONTRACT_REQUEST_ROUTE = (route) => `/contract-requests/${route}`;
export const CONTRACT_REQUEST = "/contract-requests";
export const CONTRACT_REQUEST_CREATE = "/contract-requests/create";
export const CONTRACT_REQUEST_INFO = "/contract-requests/:id";
export const CONTRACT_REQUEST_EDIT = "/contract-requests/:id/edit";

// KDIRequest
export const REQUEST_KDI = (id = null) => `/requestKDI/${id || ":id"}`;
export const KDI_REQUEST_ROUTE = (id = null) => `/kdi-requests/${id || ":id"}`;
export const KDI_REQUEST = "/kdi-requests";
export const KDI_REQUEST_CREATE = `/kdi-requests/create`;
export const KDI_REQUEST_INFO = `/kdi-requests/:id`;
export const KDI_REQUEST_EDIT = `/kdi-requests/:id/edit`;

// DDDRequest
export const DDD_REQUEST_ROUTE = (id = null) => `/ddd-requests/${id || ":id"}`;
export const DDD_REQUEST = "/ddd-requests";
export const DDD_REQUEST_CREATE = `/ddd-requests/create`;
export const DDD_REQUEST_INFO = `/ddd-requests/:id`;
export const DDD_REQUEST_EDIT = `/ddd-requests/:id/edit`;

// WithoutDepartureRequest
export const WITHOUT_DEPARTURE_REQUEST_ROUTE = (id = null) => `/without-departure-requests/${id || ":id"}`;
export const WITHOUT_DEPARTURE_REQUEST = "/without-departure-requests";
export const WITHOUT_DEPARTURE_REQUEST_INFO = "/without-departure-requests/:id";
export const WITHOUT_DEPARTURE_REQUEST_EDIT =
    "/without-departure-requests/:id/edit";
export const WITHOUT_DEPARTURE_REQUEST_CREATE =
    "/without-departure-requests/create";

// SelfControlRequest
export const SELF_CONTROL_REQUEST_ROUTE = (id = null) => `/self-control-requests/${id || ":id"}`;
export const SELF_CONTROL_REQUEST = "/self-control-requests";
export const SELF_CONTROL_REQUEST_INFO = "/self-control-requests/:id";
export const SELF_CONTROL_REQUEST_EDIT = (id = null) => `/self-control-requests/${id || ":id"}/edit`;
export const SELF_CONTROL_REQUEST_CREATE =
    "/self-control-requests/create";

// ServiceWithoutActRequest
export const SERVICE_WITHOUT_ACT_REQUEST_ROUTE = (id = null) => `/service-without-act-requests/${id || ":id"}`;
export const SERVICE_WITHOUT_ACT_REQUEST = `/service-without-act-requests`;
export const SERVICE_WITHOUT_ACT_REQUEST_CREATE = `/service-without-act-requests/create`;
export const SERVICE_WITHOUT_ACT_REQUEST_INFO = `/service-without-act-requests/:id`;
export const SERVICE_WITHOUT_ACT_REQUEST_EDIT = `/service-without-act-requests/:id/edit`;

// DepartureSchedules
export const DEPARTURE_SCHEDULE_REQUEST_ROUTE = (id = null) => `/departure-schedules/${id || ":id"}`;
export const DEPARTURE_SCHEDULE = `/departure-schedules`;
export const DEPARTURE_SCHEDULE_CREATE = `/departure-schedules/create`;
export const DEPARTURE_SCHEDULE_INFO = `/departure-schedules/:id`;
export const DEPARTURE_SCHEDULE_EDIT = `/departure-schedules/:id/edit`;

// Object referrals
export const OBJECT_REFERRAL_ROUTE = (route) => `/object-referral/${route}`;
export const OBJECT_REFERRAL = `/object-referral`;
export const OBJECT_REFERRAL_COPY = (id = null) => `/object-referral/copy/${id || ":id"}`;
export const OBJECT_REFERRAL_CREATE = `/object-referral/create`;
export const OBJECT_REFERRAL_INFO = `/object-referral/:id`;
export const OBJECT_REFERRAL_EDIT = `/object-referral/:id/edit`;

// resolutions
export const RESOLUTION_ROUTE = (route) => `/resolution/${route}`;
export const RESOLUTION = `/resolution`;
export const RESOLUTION_CREATE = `/resolution/create`;
export const RESOLUTION_INFO = `/resolution/:id`;
export const RESOLUTION_EDIT = `/resolution/:id/edit`;
export const RESOLUTION_EDIT_ROUTE = (route) => `/resolution/${route}/edit`;


// ddd
export const DDD_DISINSECTION = "/dddDisinsection";
export const DDD_DISINSECTION_DETAIL = (id = null) =>
    `/dddDisinsectionDetail/${id || ":id"}`;
export const DDD_FINAL_DISINFECTION = "/dddFinalDisinfection";
export const DDD_FINAL_DISINFECTION_DETAIL = (id = null) =>
    `/dddFinalDisinfectionDetail/${id || ":id"}`;
export const RECEIPT_THINGS = "/receiptForThings";
export const RECEIPT_THINGS_DETAIL = (id = null) =>
    `/receiptForThingsDetail/${id || ":id"}`;
export const REFERENCE_DISINFECTION = "/referenceDisinfection";
export const REFERENCE_DISINFECTION_DETAIL = (id = null) =>
    `/referenceDisinfectionDetail/${id || ":id"}`;

// Referral
export const REFERRAL_ROUTE = (route) => `/referral/${route}`;
export const REFERRAL_DETAIL_ROUTE = (id = null) => `/referral/${id || ":id"}`;
export const REFERRAL_COPY = (id = null) => `/referral/copy/${id || ":id"}`;
export const REFERRAL = `/referral`;
export const REFERRAL_CREATE = `/referral/create`;
export const REFERRAL_INFO = `/referral/:id`;
export const REFERRAL_EDIT = `/referral/:id/edit`;

// ksec
export const PRESCRIPTION = "/prescription";
export const PRESCRIPTION_ROUTE = (route) => `/prescription/${route}`;
export const PRESCRIPTION_CREATE = `/prescription/create`;
export const PRESCRIPTION_INFO = `/prescription/:id`;
export const PRESCRIPTION_EDIT = `/prescription/:id/edit`;
export const PRESCRIPTION_EDIT_ROUTE = (route) => `/prescription/${route}/edit`;


export const REGULATION = "/regulation";

// dictionaries
export const CURRENCY_LIST_ROUTE = "/currencyList";
export const GENDER_LIST_ROUTE = "/genderList";
export const GOVERNMENT_STANDARD_LIST_ROUTE = "/governmentStandardList";
export const MEDICAL_ORDER_LIST_ROUTE = "/medicalOrderList";
export const POSITION_LIST_ROUTE = "/positionList";
export const PURPOSE_OF_SELECTION_LIST_ROUTE = "/purposeOfSelectionList";
export const REFERRAL_ADDITIONAL_INFO_LIST_ROUTE =
    "/referralAdditionalInfoList";
export const REFERRAL_ORDER_TYPE_LIST_ROUTE = "/referralOrderTypeList";
export const SAMPLE_PRESERVATION_METHOD_LIST_ROUTE =
    "/samplePreservationMethodList";
export const SAMPLING_PURPOSE_LIST_ROUTE = "/samplingPurposeList";
export const SERVICE_SCHEDULE_LIST_ROUTE = "/serviceScheduleList";
export const STORAGE_CONDITION_LIST_ROUTE = "/storageConditionList";
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_LIST_ROUTE =
    "/technicalRegulationsOfCustomsUnionList";
export const TRANSPORTATION_CONDITION_LIST_ROUTE =
    "/transportationConditionList";
export const TRANSPORT_TYPE_LIST_ROUTE = "/transportTypeList";
export const UNIT_OF_MEASURE_LIST_ROUTE = "/unitOfMeasureList";
export const CHEMICAL_INDICES_LIST_ROUTE = "/chemicalIndicesList";
export const DISEASE_LIST_ROUTE = "/diseaseList";
export const INFECTION_KIND_LIST_ROUTE = "/infectionKindList";
export const SICKNESS_KIND_LIST_ROUTE = "/sicknessKindList";

export const ACCREDITATION_LIST_ROUTE = "/accreditationList";
export const ACCREDITATION_DETAIL_ROUTE = (id = null) =>
    `/accreditationDetail/${id || ":id"}`;

export const PLAN_LIST_ROUTE = "/planList";
export const PLAN_DETAIL_ROUTE = (id = null) =>
    `/planDetail/${id || ":id"}`;
export const PLAN_EDIT_ROUTE = (id = null) =>
    `/planEdit/${id || ":id"}`;


export const AN_OBJECT_SECTION_LIST_ROUTE = "/anObjectSectionList";
export const AN_OBJECT_LIST_ROUTE = "/anObjectList";
export const AN_OBJECT_SUB_SECTION_LIST_ROUTE = "/anObjectSubSectionList";

export const EMPLOYEE_LIST_ROUTE = "/employeeList";
export const EMPLOYEE_DETAIL_ROUTE = (id = null) =>
    `/employeeDetail/${id || ":id"}`;
export const DISEASE_INDEX_LIST_ROUTE = "/diseaseIndexList";
export const DISEASE_INDEX_DETAIL_ROUTE = (id = null) =>
    `/diseaseIndexDetail/${id || ":id"}`;

export const FILIAL_LIST_ROUTE = "/filialList";
export const FILIAL_DETAIL_ROUTE = (id = null) =>
    `/filialDetail/${id || ":id"}`;
export const FILIAL_DIVISION_LIST_ROUTE = "/filialDivisionList";
export const FILIAL_DIVISION_DETAIL_ROUTE = (id = null) =>
    `/filialDivisionDetail/${id || ":id"}`;
export const FORM_MAP_LIST_ROUTE = "/formMapList";
export const FORM_MAP_DETAIL_ROUTE = (id = null) => `/formMapDetail/${id || ":id"}`;

export const DEPARTMENT_LIST_ROUTE = "/departmentList";
export const DEPARTMENT_DETAIL_ROUTE = (id = null) => `/departmentDetail/${id || ":id"}`;

export const DIVISION_LIST_ROUTE = "/divisionList";
export const DIVISION_DETAIL_ROUTE = (id = null) => `/divisionDetail/${id || ":id"}`;

export const REQUEST_LOCK_LIST_ROUTE = '/requestLockList';
export const REQUEST_LOCK_ROUTE = (route) => `/requestLockList/${route}`;
export const REQUEST_LOCK_CREATE_ROUTE = '/requestLockList/create';
export const REQUEST_LOCK_DETAILS_ROUTE = (id) => `/requestLockList/${id}`;
export const REQUEST_LOCK_EDIT_ROUTE = (id) => `/requestLockList/${id}/edit`;

export const ORGANIZATION_FILIAL_MAP_LIST_ROUTE = '/organizationFilialMapList';
export const ORGANIZATION_FILIAL_MAP_ROUTE = (route) => `/organizationFilialMapList/${route}`;
export const ORGANIZATION_FILIAL_MAP_CREATE_ROUTE = '/organizationFilialMapList/create';
export const ORGANIZATION_FILIAL_MAP_DETAILS_ROUTE = (id) => `/organizationFilialMapList/${id}`;
export const ORGANIZATION_FILIAL_MAP_EDIT_ROUTE = (id) => `/organizationFilialMapList/${id}/edit`;

// reportAndAnalytics
export const REQUEST_TARIFICATIONS_QUANTITY = '/requestTarificationsQuantity';
export const REQUEST_TARIFICATIONS_QUANTITY_INDICES = '/requestTarificationsQuantityIndices';
export const REQUEST_TARIFICATIONS_QUANTITY_INDICES_2 = '/requestTarificationsQuantityIndices2';
export const REQUEST_TARIFICATIONS_QUANTITY_INDICES_3 = '/requestTarificationsQuantityIndices3';
export const REQUEST_TARIFICATIONS_QUANTITY_INDICES_4 = '/requestTarificationsQuantityIndices4';
export const REQUEST_TARIFICATIONS_QUANTITY_DEPARTMENTS = '/requestTarificationsQuantityDepartments';


export const SERVICE_MAP_LIST_ROUTE = '/serviceMapList';
export const SERVICE_MAP_ROUTE = (route) => `/serviceMapList/${route}`;
export const SERVICE_MAP_CREATE_ROUTE = '/serviceMapList/create';
export const SERVICE_MAP_DETAILS_ROUTE = (id) => `/serviceMapList/${id}`;
export const SERVICE_MAP_EDIT_ROUTE = (id) => `/serviceMapList/${id}/edit`;

export const SERVICE_GROUP_LIST_ROUTE = '/serviceGroupList';
export const SERVICE_GROUP_ROUTE = (route) => `/serviceGroupList/${route}`;
export const SERVICE_GROUP_CREATE_ROUTE = '/serviceGroupList/create';
export const SERVICE_GROUP_DETAILS_ROUTE = (id) => `/serviceGroupList/${id}`;
export const SERVICE_GROUP_EDIT_ROUTE = (id) => `/serviceGroupList/${id}/edit`;