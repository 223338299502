import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

ActProductsPDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data
  const schema = [
    {
      key: '1. Объектінің атауы (Наименование объекта)',
      value: actData?.object?.value?.name
    },
    {
      key: '2. Тексеру белгіленгені туралы актінің нөмірі (Номер акта о назначении проверки)',
      value: actData?.number?.value
    },
    {
      key: '3. Тағайындау актісі күні (Дата акта о назначении проверки)',
      value: actData?.date?.value ? formatDate(new Date(actData?.date.value)) : ' '
    },
    {
      key: '4. Сынама үлгісі алынған орын, мекенжай (Место отбора образца, адрес)',
      value: actData?.selectionPlace?.value
    },
    {
      key: '5. Сынамалар алу мақсаты (Цель отбора)',
      value: actData?.samplingPurpose?.value?.name
    },
    {
      key: '6. Алынған күні мен уақыты (Дата и время отбора)',
      value: actData?.selectionDateTime?.value ? formatTime(new Date(actData?.selectionDateTime.value)) : ' '
    },
    {
      key: '7. Жарамдылық мерзімі (Срок годности)',
      value: actData?.expirationDate?.value ? formatTime(new Date(actData?.expirationDate.value)) : ' '
    },
    {
      key: '8. Жеткізілген күні мен уақыты (Дата и время доставки)',
      value: actData?.deliveryDateTime?.value ? formatTime(new Date(actData?.deliveryDateTime.value)) : ' '
    },
    {
      key: '9. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на метод отбора)',
      value: actData?.standard?.value?.name
    },
    {
      key: '10. Тасымалдау жағдайы (Условия транспортировки)',
      value: actData?.transportationCondition?.value?.name
    },
    {
      key: '11. Сақтау жағдайы (Условия хранения)',
      value: actData?.storageCondition?.value?.name
    },
    {
      key: '12. Орама түрі пломба нөмірі (Вид упаковки номер печати (пломбы))',
      value: actData?.sealTypeAndNumber?.value
    },
    {
      key: '13. Қосымша мәліметтер (Дополнительные сведения)',
      value: actData?.additionalInfo?.value
    },
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Table style={styles.table}>
          <Row>
            <Cell></Cell>
            <Cell>
              <Text>Нысанның БҚСЖ бойынша коды</Text>
              <Text>Код формы по ОКУД ___________________</Text>
              <Text>КҰЖЖ бойынша ұйым коды</Text>
              <Text>Код организации по ОКПО _____________</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрлігі Министерство здравоохранения Республики Казахстан</Text>
            </Cell>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы "__" __________ № бұйрығымен бекітілген №____011/е нысанды медициналық құжаттама</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк органының атауы Наименование государственного органа санитарно-эпидемиологической службы</Text>
            </Cell>
            <Cell>
              <Text>Медицинская документация Форма № 011/у Утверждена приказом Министра здравоохранения Республики Казахстан от "__" ______ 2021 года №______</Text>
            </Cell>
          </Row>
        </Table>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {actData?.dateOfAct?.value ? formatDate(new Date(actData.dateOfAct.value)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>

        {actData?.samples?.value.map(el => {
          return <Table style={styles.table}>
            <Row>
              <Cell>
                <Text style={styles.sampleBold}>
                  Тiркелу нөмірi (Регистрационный номер):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.registrationNumber?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleBold}>
                  Алынған үлгілер тізімі (Перечень отобранных образцов):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.sample?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleBold}>
                  Өндірушісі, сериясы (партия нөмірі) көрсетілген сынама үлгілерінің саны (Количество отобранных образцов продукции с указанием производителя, серия (номера партии):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.quantity?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleBold}>
                  Шығарылған күні (Дата производства):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.manufactureInfo?.value || ' '}
                </Text>
              </Cell>
            </Row>
            <Row>
              <Cell>
                <Text style={styles.sampleBold}>
                  Сынаманың салмағы, көлемі (Вес, объем образца):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.weightSize?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleBold}>
                  Сақтау мерзімі (срок годности):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.expirationDate?.value ? formatTime(new Date(el.expirationDate?.value)) : ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleBold}>
                  Күні, ТАӘ, үлгіні (сынама) қабылдаған тұлғаның қолы Дата, ФИО, подпись лица принявшего образец (пробу):
                </Text>
                <Text style={styles.sampleRegular}>
                  {el.receiptInfo?.value || ' '}
                </Text>
              </Cell>
            </Row>
          </Table>
        })}

        <View style={{ fontFamily: 'Times New Roman' }}>
          <Text style={styles.simpleString}>Зерттеу жүргiзген маманның Т.А.Ә.(болған жағдайда), лауазымы (Ф.И.О.(при наличии), должность специалиста проводившего исследование): {data.performer?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зертхана меңгерушісінің Т.А.Ә. (болған жағдайда), қолы. (Ф.И.О.(при наличии), подпись заведующего лабораторией): {data.responsible?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
          <Text style={styles.string}>Акт составлен в экземплярах(кол-во) _____</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function ActProductsPDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
