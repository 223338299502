import React, { useCallback, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";

export const TextField2 = ({ id, label, value, onChange, xs = 12, textarea = false, rows=4 }) => {
    return (
      <Grid item xs={xs}>
        {!textarea ? <TextField
          margin="normal"
          value={value}
          onChange={(e) => onChange(e.target.value, e)}
          fullWidth
          id={id}
          name={id}
          label={label}
        /> :
        <TextField
          margin="normal"
          value={value}
          onChange={(e) => onChange(e.target.value, e)}
          fullWidth
          multiline
          rows={rows}
          id={id}
          name={id}
          label={label}
        />}
      </Grid>
    );
  };