import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useDialogs from "../../components/Dialog/useDialogs";
import useLoader from "../../hooks/useLoader";
import { renderControls } from "../../functions";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import CheckboxField from "../../components/Fields/CheckboxField";
import { TextField2 } from "../../components/Fields/TextField2";
import { TextPhoneField } from "../../components/Fields/TextPhoneField";
import { DateField } from "../../components/Fields/DateField";
import { DateTimeField } from "../../components/Fields/DateTimeField";
import { NumberField } from "../../components/Fields/NumberField";
import { GridItem } from "../../components/Fields/GridItem";
import { ResearchObjectSelect } from "../../components/Select/domainSelects";
import { ServiceGroupSelect } from "../../components/Select/domainSelects";
import { ServiceSubGroupSelect } from "../../components/Select/domainSelects";
import { ServiceKindSelect } from "../../components/Select/domainSelects";
import { GovernmentStandardSelect } from "../../components/Select/domainSelects";
import { TechnicalRegulationsOfCustomsUnionSelect } from "../../components/Select/domainSelects";
import { changeData, changeDataMany } from "../../functions/changeHandlers";
import { SERVICE_GROUP_LIST_ROUTE } from "../../routes";
import { submitData2 } from "../../functions/submitHandlers";
import RestSelect from "../../components/Select/RestSelect";
import { actFormCRUD, governmentCRUD, researchObjectCRUD, serviceGroupCRUD, serviceKindCRUD, serviceSubGroupCRUD, technicalRegulationsOfCustomsUnionCRUD } from "../../http";
import { editServiceGroup, getServiceGroupByKey } from "../../redux/actions/serviceGroupActions";

export const EditServiceGroup = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const [data, setData] = useState({});
    const [serviceSubGroupQuery, setServiceSubGroupQuery] = useState({
        paging: { skip: 0 },
        filter: {
            // groupId: {
            //     operand1: 0,
            //     operator: 'equals'
            // }
        }
    });

    const {
        loading: submitLoading,
        start: startSubmit,
        stop: stopSubmit,
    } = useLoader(false);

    const handleChange = useCallback((key, value) => changeData(data, setData, key, value), [data]);
    const handleChangeMany = useCallback((obj) => changeDataMany(data, setData, obj), [data]);

    const { alert, DIALOG } = useDialogs();

    const fetch = async () => {
        fetchStart();
        const item = await dispatch(getServiceGroupByKey(id));
        if (!item) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setData(item);
        fetchStop();
    };

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, fetchStart, fetchStop]);

    const handleSubmit = async (e) => {
        e?.preventDefault();

        await submitData2(data, async () => {
            return true;
        }, startSubmit, stopSubmit, async () => {
            return await dispatch(editServiceGroup(data));
        });
    };

    const controls = [
        {
            state: null,
            buttons: [
                <LoadingButton loading={submitLoading} onClick={handleSubmit}>
                    Сохранить
                </LoadingButton>,
                <Button
                    variant={"text"}
                    onClick={() => push(SERVICE_GROUP_LIST_ROUTE)}
                >
                    Вернуться
                </Button>,
            ],
        }
    ];

    return (
        <DashboardLayout>
            <>
                <DIALOG />
                <PageHeader heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
                </PageHeader>
                <PageContainer>
                    <FormSection>
                        <Grid container>
                            <GridItem xs={6}>
                                <TextField2
                                    id={"code"}
                                    label={"Код"}
                                    value={data["code"]}
                                    onChange={(value) => handleChange("code", value)}
                                    xs={6}
                                />
                                <TextField2
                                    id={"description"}
                                    label={"Описание"}
                                    value={data["description"]}
                                    onChange={(value) => handleChange("description", value)}
                                    xs={6}
                                />
                                <TextField2
                                    id={"name"}
                                    label={"Наименование"}
                                    value={data["name"]}
                                    onChange={(value) => handleChange("name", value)}
                                    xs={6}
                                />
                                <RestSelect
                                    restApi={actFormCRUD}
                                    id={"actFormId"}
                                    label={"Акт"}
                                    value={data.actFormId}
                                    onChange={(value, obj) => handleChangeMany({ actForm: obj, actFormId: value })}
                                    fullWidth
                                    isNullable={true}
                                />
                            </GridItem>

                        </Grid>
                    </FormSection>
                </PageContainer>
                <PageFooter heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
                </PageFooter>
            </>
        </DashboardLayout>
    )
};
