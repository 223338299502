import { 
    ACT_TRACE_GET_REQUEST, ACT_TRACE_GET_SUCCESS, ACT_TRACE_GET_ERROR, 
    ACT_TRACE_GET_BY_KEY_REQUEST, ACT_TRACE_GET_BY_KEY_SUCCESS, ACT_TRACE_GET_BY_KEY_ERROR, 
    ACT_TRACE_ADD_REQUEST, ACT_TRACE_ADD_SUCCESS, ACT_TRACE_ADD_ERROR, 
    ACT_TRACE_EDIT_REQUEST, ACT_TRACE_EDIT_SUCCESS, ACT_TRACE_EDIT_ERROR, 
    ACT_TRACE_DELETE_REQUEST, ACT_TRACE_DELETE_SUCCESS, ACT_TRACE_DELETE_ERROR
} from "../constants/actTraceConstants";

const initialState = {
    actTraces: [],
    actTrace: {},
    loading: false,
    success: null,
    error: null,
    actTraceRequestedCount: 0,
};

export const actTraceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case ACT_TRACE_GET_REQUEST:
            return {
                ...state,
                actTraceRequestedCount: ++state.actTraceRequestedCount,
                loading: true,
            };
        case ACT_TRACE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                actTraces: action.payload,
                success: true,
            };
        case ACT_TRACE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_TRACE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_TRACE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                actTrace: action.payload,
                success: true,
            };
        case ACT_TRACE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_TRACE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_TRACE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_TRACE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_TRACE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_TRACE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_TRACE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_TRACE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_TRACE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_TRACE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
