export const SERVICE_SCHEDULE_GET_REQUEST = 'SERVICE_SCHEDULE_GET_REQUEST';
export const SERVICE_SCHEDULE_GET_SUCCESS = 'SERVICE_SCHEDULE_GET_SUCCESS';
export const SERVICE_SCHEDULE_GET_ERROR = 'SERVICE_SCHEDULE_GET_ERROR';
export const SERVICE_SCHEDULE_GET_BY_KEY_REQUEST = 'SERVICE_SCHEDULE_GET_BY_KEY_REQUEST';
export const SERVICE_SCHEDULE_GET_BY_KEY_SUCCESS = 'SERVICE_SCHEDULE_GET_BY_KEY_SUCCESS';
export const SERVICE_SCHEDULE_GET_BY_KEY_ERROR = 'SERVICE_SCHEDULE_GET_BY_KEY_ERROR';
export const SERVICE_SCHEDULE_ADD_REQUEST = 'SERVICE_SCHEDULE_ADD_REQUEST';
export const SERVICE_SCHEDULE_ADD_SUCCESS = 'SERVICE_SCHEDULE_ADD_SUCCESS';
export const SERVICE_SCHEDULE_ADD_ERROR = 'SERVICE_SCHEDULE_ADD_ERROR';
export const SERVICE_SCHEDULE_EDIT_REQUEST = 'SERVICE_SCHEDULE_EDIT_REQUEST';
export const SERVICE_SCHEDULE_EDIT_SUCCESS = 'SERVICE_SCHEDULE_EDIT_SUCCESS';
export const SERVICE_SCHEDULE_EDIT_ERROR = 'SERVICE_SCHEDULE_EDIT_ERROR';
export const SERVICE_SCHEDULE_DELETE_REQUEST = 'SERVICE_SCHEDULE_DELETE_REQUEST';
export const SERVICE_SCHEDULE_DELETE_SUCCESS = 'SERVICE_SCHEDULE_DELETE_SUCCESS';
export const SERVICE_SCHEDULE_DELETE_ERROR = 'SERVICE_SCHEDULE_DELETE_ERROR';
