import { $authHost } from "../../http";
import { 
    UNIT_OF_MEASURE_GET_REQUEST, UNIT_OF_MEASURE_GET_SUCCESS, UNIT_OF_MEASURE_GET_ERROR, 
    UNIT_OF_MEASURE_GET_BY_KEY_REQUEST, UNIT_OF_MEASURE_GET_BY_KEY_SUCCESS, UNIT_OF_MEASURE_GET_BY_KEY_ERROR, 
    UNIT_OF_MEASURE_ADD_REQUEST, UNIT_OF_MEASURE_ADD_SUCCESS, UNIT_OF_MEASURE_ADD_ERROR, 
    UNIT_OF_MEASURE_EDIT_REQUEST, UNIT_OF_MEASURE_EDIT_SUCCESS, UNIT_OF_MEASURE_EDIT_ERROR, 
    UNIT_OF_MEASURE_DELETE_REQUEST, UNIT_OF_MEASURE_DELETE_SUCCESS, UNIT_OF_MEASURE_DELETE_ERROR
} from "../constants/unitOfMeasureConstants";

export const getUnitOfMeasures = (args) => async (dispatch) => {
    try {
        dispatch({
            type: UNIT_OF_MEASURE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/unitOfMeasure/query",
            args || {},
            config,
        );
        dispatch({
            type: UNIT_OF_MEASURE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: UNIT_OF_MEASURE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getUnitOfMeasureByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: UNIT_OF_MEASURE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/unitOfMeasure/${id}`,
        );
        dispatch({
            type: UNIT_OF_MEASURE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: UNIT_OF_MEASURE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addUnitOfMeasure = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: UNIT_OF_MEASURE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/unitOfMeasure",
            { name, code, description },
            config,
        );
        dispatch(getUnitOfMeasures(queryArgs || {}));
        dispatch({
            type: UNIT_OF_MEASURE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: UNIT_OF_MEASURE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editUnitOfMeasure = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: UNIT_OF_MEASURE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/unitOfMeasure",
            { id, name, code, description },
            config,
        );
        dispatch(getUnitOfMeasures(queryArgs || {}));
        dispatch({
            type: UNIT_OF_MEASURE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: UNIT_OF_MEASURE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteUnitOfMeasure = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: UNIT_OF_MEASURE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/unitOfMeasure",
            { data: { id } },
            config,
        );
        dispatch(getUnitOfMeasures(queryArgs || {}));
        dispatch({
            type: UNIT_OF_MEASURE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: UNIT_OF_MEASURE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

