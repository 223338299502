import { 
    PERSON_GET_REQUEST, PERSON_GET_SUCCESS, PERSON_GET_ERROR, 
    PERSON_GET_BY_KEY_REQUEST, PERSON_GET_BY_KEY_SUCCESS, PERSON_GET_BY_KEY_ERROR, 
    PERSON_ADD_REQUEST, PERSON_ADD_SUCCESS, PERSON_ADD_ERROR, 
    PERSON_EDIT_REQUEST, PERSON_EDIT_SUCCESS, PERSON_EDIT_ERROR, 
    PERSON_DELETE_REQUEST, PERSON_DELETE_SUCCESS, PERSON_DELETE_ERROR
} from "../constants/personConstants";

const initialState = {
    persons: [],
    person: {},
    loading: false,
    success: null,
    error: null,
    personRequestedCount: 0,
};

export const personReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PERSON_GET_REQUEST:
            return {
                ...state,
                personRequestedCount: ++state.personRequestedCount,
                loading: true,
            };
        case PERSON_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                persons: action.payload,
                success: true,
            };
        case PERSON_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PERSON_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                person: action.payload,
                success: true,
            };
        case PERSON_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PERSON_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PERSON_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PERSON_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PERSON_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PERSON_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PERSON_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PERSON_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
