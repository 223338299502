import { educationServiceKindCRUD } from "../../http/CRUD";
import {
  EDUCATION_SERVICE_KIND_GET_REQUEST,
  EDUCATION_SERVICE_KIND_GET_SUCCESS,
  EDUCATION_SERVICE_KIND_GET_ERROR,
  EDUCATION_SERVICE_KIND_GET_BY_KEY_REQUEST,
  EDUCATION_SERVICE_KIND_GET_BY_KEY_SUCCESS,
  EDUCATION_SERVICE_KIND_GET_BY_KEY_ERROR,
  EDUCATION_SERVICE_KIND_ADD_REQUEST,
  EDUCATION_SERVICE_KIND_ADD_SUCCESS,
  EDUCATION_SERVICE_KIND_ADD_ERROR,
  EDUCATION_SERVICE_KIND_EDIT_REQUEST,
  EDUCATION_SERVICE_KIND_EDIT_SUCCESS,
  EDUCATION_SERVICE_KIND_EDIT_ERROR,
  EDUCATION_SERVICE_KIND_DELETE_REQUEST,
  EDUCATION_SERVICE_KIND_DELETE_SUCCESS,
  EDUCATION_SERVICE_KIND_DELETE_ERROR,
} from "../constants/educationServiceKindConstants";

export const getEducationServiceKinds = (args) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_KIND_GET_REQUEST,
    });

    const data = await educationServiceKindCRUD.search(args);
    dispatch({
      type: EDUCATION_SERVICE_KIND_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_KIND_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getEducationServiceKindByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EDUCATION_SERVICE_KIND_GET_BY_KEY_REQUEST,
    });

    const { data } = await educationServiceKindCRUD.get(
      `api/v1/educationServiceKind/${id}`
    );
    dispatch({
      type: EDUCATION_SERVICE_KIND_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data.result;
  } catch (e) {
    dispatch({
      type: EDUCATION_SERVICE_KIND_GET_BY_KEY_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const addEducationServiceKind =
  ({ name, code, description }, queryArgs) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDUCATION_SERVICE_KIND_ADD_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await educationServiceKindCRUD.post(
        "api/v1/educationServiceKind",
        { name, code, description },
        config
      );
      dispatch(getEducationServiceKinds(queryArgs || {}));
      dispatch({
        type: EDUCATION_SERVICE_KIND_ADD_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: EDUCATION_SERVICE_KIND_ADD_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };

export const editEducationServiceKind =
  ({ id, name, code, description }, queryArgs) =>
  async (dispatch) => {
    try {
      dispatch({
        type: EDUCATION_SERVICE_KIND_EDIT_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await educationServiceKindCRUD.put(
        "api/v1/educationServiceKind",
        { id, name, code, description },
        config
      );
      dispatch(getEducationServiceKinds(queryArgs || {}));
      dispatch({
        type: EDUCATION_SERVICE_KIND_EDIT_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: EDUCATION_SERVICE_KIND_EDIT_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };

export const deleteEducationServiceKind =
  (id, queryArgs) => async (dispatch) => {
    try {
      dispatch({
        type: EDUCATION_SERVICE_KIND_DELETE_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      await educationServiceKindCRUD.delete(
        "api/v1/educationServiceKind",
        { data: { id } },
        config
      );
      dispatch(getEducationServiceKinds(queryArgs || {}));
      dispatch({
        type: EDUCATION_SERVICE_KIND_DELETE_SUCCESS,
      });
    } catch (e) {
      dispatch({
        type: EDUCATION_SERVICE_KIND_DELETE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
