export const POSITION_GET_REQUEST = 'POSITION_GET_REQUEST';
export const POSITION_GET_SUCCESS = 'POSITION_GET_SUCCESS';
export const POSITION_GET_ERROR = 'POSITION_GET_ERROR';
export const POSITION_GET_BY_KEY_REQUEST = 'POSITION_GET_BY_KEY_REQUEST';
export const POSITION_GET_BY_KEY_SUCCESS = 'POSITION_GET_BY_KEY_SUCCESS';
export const POSITION_GET_BY_KEY_ERROR = 'POSITION_GET_BY_KEY_ERROR';
export const POSITION_ADD_REQUEST = 'POSITION_ADD_REQUEST';
export const POSITION_ADD_SUCCESS = 'POSITION_ADD_SUCCESS';
export const POSITION_ADD_ERROR = 'POSITION_ADD_ERROR';
export const POSITION_EDIT_REQUEST = 'POSITION_EDIT_REQUEST';
export const POSITION_EDIT_SUCCESS = 'POSITION_EDIT_SUCCESS';
export const POSITION_EDIT_ERROR = 'POSITION_EDIT_ERROR';
export const POSITION_DELETE_REQUEST = 'POSITION_DELETE_REQUEST';
export const POSITION_DELETE_SUCCESS = 'POSITION_DELETE_SUCCESS';
export const POSITION_DELETE_ERROR = 'POSITION_DELETE_ERROR';
