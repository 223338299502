import { 
    TRANSPORT_TYPE_GET_REQUEST, TRANSPORT_TYPE_GET_SUCCESS, TRANSPORT_TYPE_GET_ERROR, 
    TRANSPORT_TYPE_GET_BY_KEY_REQUEST, TRANSPORT_TYPE_GET_BY_KEY_SUCCESS, TRANSPORT_TYPE_GET_BY_KEY_ERROR, 
    TRANSPORT_TYPE_ADD_REQUEST, TRANSPORT_TYPE_ADD_SUCCESS, TRANSPORT_TYPE_ADD_ERROR, 
    TRANSPORT_TYPE_EDIT_REQUEST, TRANSPORT_TYPE_EDIT_SUCCESS, TRANSPORT_TYPE_EDIT_ERROR, 
    TRANSPORT_TYPE_DELETE_REQUEST, TRANSPORT_TYPE_DELETE_SUCCESS, TRANSPORT_TYPE_DELETE_ERROR
} from "../constants/transportTypeConstants";

const initialState = {
    transportTypes: [],
    transportType: {},
    loading: false,
    success: null,
    error: null,
    transportTypeRequestedCount: 0,
};

export const transportTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case TRANSPORT_TYPE_GET_REQUEST:
            return {
                ...state,
                transportTypeRequestedCount: ++state.transportTypeRequestedCount,
                loading: true,
            };
        case TRANSPORT_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                transportTypes: action.payload,
                success: true,
            };
        case TRANSPORT_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORT_TYPE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORT_TYPE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                transportType: action.payload,
                success: true,
            };
        case TRANSPORT_TYPE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORT_TYPE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORT_TYPE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TRANSPORT_TYPE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORT_TYPE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORT_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TRANSPORT_TYPE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TRANSPORT_TYPE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TRANSPORT_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TRANSPORT_TYPE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
