import React, { useState, useEffect } from "react";

import {
  Table,
  TablePagination,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";

export const EnhancedTable = ({
  cells,
  actionFn,
  usePagingByApi,
  selectedKey,
  selected,
  setSelected,
  emptyPlaceholder,
  addButton: AddButton,
  addButtonText,
  onAddButtonClick,
  onRowClick
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({});
  const [sorting, setSorting] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState([]);

  const [order, setOrder] = React.useState("asc");
  const [selectedRow, setSelectedRow] = useState(null);

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  const handleChangePage = (e, page) => {
    setPage(page);
  };

  const handleRowClick = (e, row) => {
    e.preventDefault();
    onRowClick && onRowClick(e, row);
    setSelectedRow({ id: selectedKey, value: row[selectedKey] });
  };

  const sliceRows = (rows) =>
    rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const isRowSelected = (row) => selectedRow?.value === row[selectedKey];

  useEffect(() => {
    (async () => {
      if (!actionFn) return;

      const paging = { skip: rowsPerPage * page, take: rowsPerPage, returnCount: !!usePagingByApi && (page == 0) };
      const filter = {};
      const sort = {
        id: { operator: "desc", ordinal: 0 },
      };

      const data = await dispatch(actionFn({ paging, filter, sort }));

      console.log({ actionFn: data });

      if (usePagingByApi) {
        if (!data.result) return;

        setRows(data.result);
        page == 0 && setTotal(data.total);
      } else {
        if (!data) return;

        setRows(data);
      }
    })();
  }, [dispatch, actionFn, page, rowsPerPage]);

  useEffect(() => {
    if (!selectedRow) return;

    setSelected(selectedRow);
  }, [selectedRow]);

  return (
    <Grid container>
      {rows?.length ? (
        <>
          <TableContainer component={Paper} style={{ margin: "12px auto" }}>
            <Table sx={{ minWidth: 650 }} aria-label="">
              <TableHead style={{ background: "#FAFBFB" }}>
                <TableRow>
                  {cells?.map((cell, key) => (
                    <TableCell key={key} align="left">
                      {cell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {(usePagingByApi ? rows : sliceRows(rows))?.map((row, key) => (
                  <TableRow
                    key={key}
                    selected={isRowSelected(row)}
                    style={{ cursor: "pointer" }}
                    hover={true}
                    onClick={(e) => handleRowClick(e, row)}
                  >
                    {cells?.map((cell, key) => (
                      <TableCell key={key} align="left">
                        {cell.render ? cell.render(row) : row[cell.id] || "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage=""
            rowsPerPageOptions={usePagingByApi ? [5, 10, 25, 50, 100] : []}
            component="div"
            count={usePagingByApi ? total : rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            align="right"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          />
        </>
      ) : (
        <Typography
          component="span"
          variant="h6"
          style={{
            fontWeight: 300,
            color: "#8B8799",
            fontSize: "16px",
            margin: "10px 0px",
          }}
        >
          {emptyPlaceholder}
        </Typography>
      )}
      <Grid item xs={12}>
        {AddButton ? (
          AddButton
        ) : (
          <Button onClick={onAddButtonClick}>{addButtonText}</Button>
        )}
      </Grid>
    </Grid>
  );
};

EnhancedTable.defaultProps = {
  emptyPlaceholder: "Нет данных",
  addButtonText: "Добавить",
  onAddButtonClick: (e) => {},
};
