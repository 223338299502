import * as React from "react";
import {styled, createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Avatar from "@mui/material/Avatar";
import {MenuListItems} from "../components";
import TextField from "@mui/material/TextField";
import {stringAvatar} from "../functions";
import {useDispatch, useSelector} from "react-redux";
import {
    SIDEBAR_CLOSE,
    SIDEBAR_OPEN,
} from "../redux/constants/genericConstants";
import {DialogProvider} from "../components/Dialog/useDialogs";
import {useCallback, useEffect, useMemo, useState} from "react";
import {getAuthorizedEmployee} from "../redux/actions";
import {positionCRUD, userCRUD} from "../http";
import {useLocation} from "react-router-dom";

import { mapRole } from "../functions/viewDataMap";
import ConfirmDialog from "../components/Dialog/DialogDelete";
import CommentDialog from "../components/Dialog/CommentDialog";
import { isPublicProtocolSee } from "../functions/renderProtocol";
import CommentWithSupervisorDialog from "../components/Dialog/CommentWithSupervisorDialog";

const drawerWidth = 350;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const mdTheme = createTheme({
    palette: {
        primary: {
            main: "#2f9e69",
            contrastText: "white",
        },
        secondary: {
            main: "#D0D3D7",
            contrastText: "#443550",
        },
        gray: {
            main: "gray",
            dark: "#2E132F",
            light: "#8B8799",
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                margin: "normal",
                value: "",
                fullWidth: true,
                sx: {
                    "& .Mui-disabled input ": {
                        "text-fill-color": "#2E132F",
                        color: "red",
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                color: "primary",
                variant: "contained",
            },
        },
    },
});

const DashboardLayout = ({children}) => {
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)
    const userRole = useMemo(()=>{
        return JSON.parse(localStorage.getItem("roles")) || []
    }, [authorizedEmployee.id])
    const dispatch = useDispatch();
    const [userLogin, setUserLogin] = useState("Отсутствует")
    const {open} = useSelector((state) => state.generic);
    const toggleDrawer = () => {
        if (open) dispatch({type: SIDEBAR_CLOSE});
        else dispatch({type: SIDEBAR_OPEN});
    };
    const { search, pathname } = useLocation();
    const protocolSee = isPublicProtocolSee(pathname, search);
    const fetchUser = useCallback(() => {
        if (protocolSee)
            return;

        dispatch(getAuthorizedEmployee()).then((resp) => {
            const userInfo = localStorage.getItem('userInfo');
            if (!userInfo)
                return;
            const {userName} = JSON.parse(userInfo);
            setUserLogin(userName)
        })
    }, [dispatch])
    useEffect(fetchUser, [fetchUser])

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{display: "flex"}}>
                <CssBaseline/>
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: "24px",
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && {display: "none"}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Box sx={{display: "flex", alignItems: "center", flexGrow: 1}}>
                            <Avatar src="/images/logo.svg"/>
                            <Typography
                                component="span"
                                color="inherit"
                                sx={{
                                    marginLeft: "10px",
                                    textTransform: "uppercase",
                                    fontSize: "14px",
                                    maxWidth: "250px",
                                }}
                            >
                                Национальный центр экспертизы
                            </Typography>
                            <Typography
                                noWrap
                                title={authorizedEmployee.fullName || ""}
                                component="span"
                                color="inherit"
                                sx={{
                                    marginLeft: "10px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}
                            >
                                ФИО: <br/>
                                {authorizedEmployee.fullName || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                title={userLogin}
                                color="inherit"
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}

                            >Должность: <br/>
                                {authorizedEmployee.position?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                title={authorizedEmployee.filial?.name || ""}
                                color="inherit"
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"
                                }}
                            >
                                Филиал: <br/>
                                {authorizedEmployee.filial?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                title={authorizedEmployee.filialDivision?.name || ""}
                                color="inherit"
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "300px",
                                    textOverflow: "ellipsis"

                                }}
                            >
                                Отделение: <br/>
                                {authorizedEmployee.filialDivision?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                color="inherit"
                                title={authorizedEmployee.department?.name || ""}
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}
                            >
                                Лаборатория: <br/>
                                {authorizedEmployee.department?.name || ""}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                color="inherit"
                                title={authorizedEmployee.division?.name || "Не указано"}
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}
                            >
                                Отделение лаборатории: <br/>
                                {authorizedEmployee.division?.name || "Не указано"}
                            </Typography>
                            <Typography
                                noWrap
                                component="span"
                                color="inherit"
                                title={authorizedEmployee.division?.name || "Не указано"}
                                sx={{
                                    marginLeft: "30px",
                                    textTransform: "uppercase",
                                    fontSize: "12px",
                                    maxWidth: "250px",
                                    textOverflow: "ellipsis"

                                }}
                            >Роли:<br/>
                                {userRole && userRole.map(el=> mapRole(el)).join(', ')}
                            </Typography>


                        </Box>
                        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton sx={{ marginRight: "10px" }} color="inherit">
                <Badge badgeContent={4} color="info">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton>
                <Avatar {...stringAvatar("Firstname Lastname")} />
              </IconButton>
            </Box> */}
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open} sx={{height: "100vh"}}>
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            px: [1],
                            overflow: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        <Typography
                            component="span"
                            color="inherit"
                            sx={{
                                marginLeft: "10px",
                                fontSize: "18px",
                            }}
                        >
                            Реестры
                        </Typography>
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List>
                        <MenuListItems/>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === "light"
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="xxl" sx={{mt: 4, mb: 2, position: "relative"}}>
                        {children}
                    </Container>
                </Box>
            </Box>
            <ConfirmDialog/>
            <CommentDialog/>
            <CommentWithSupervisorDialog/>
        </ThemeProvider>
    );
};

export default DashboardLayout;
