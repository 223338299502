import common from './common/index.js'

import aktubinskaya_oblast from './aktubinskaya_oblast/index.js'
import aktubinskaya_oblast_aktobe from './aktubinskaya_oblast/aktobe/index.js'
import aktubinskaya_oblast_kargalinsk from './aktubinskaya_oblast/kargalinsk/index.js'
import aktubinskaya_oblast_mugalzharskoe from './aktubinskaya_oblast/mugalzharskoe/index.js'
import aktubinskaya_oblast_shalkar from './aktubinskaya_oblast/shalkar/index.js'

import almaty from './almaty/index.js'
import almaty_almatinskoe_ro from './almaty/almatinskoe-ro/index.js'
import almaty_almaty from './almaty/almaty/index.js'
import almaty_auezov from './almaty/auezov/index.js'
import almaty_bostandykskoe from './almaty/bostandykskoe/index.js'
import almaty_turksib from './almaty/turksib/index.js'
import almaty_zhetysu from './almaty/zhetysu/index.js'

import almtinskaya_oblast from './almtinskaya_oblast/index.js'
import almtinskaya_oblast_karasai from './almtinskaya_oblast/karasai/index.js'
import almtinskaya_oblast_konaev from './almtinskaya_oblast/konaev/index.js'

import astana from './astana/index.js'
import astana_almaty from './astana/almaty/index.js'
import astana_baikonyr from './astana/baikonyr/index.js'
import astana_esil from './astana/esil/index.js'
import astana_saryarka from './astana/saryarka/index.js'

import atirauskaya_oblast from './atirauskaya_oblast/index.js'
import atirauskaya_oblast_atyrau from './atirauskaya_oblast/atyrau/index.js'
import atirauskaya_oblast_kulsarinskoe from './atirauskaya_oblast/kulsarinskoe/index.js'

import bko from './bko/index.js'
import bko_region1 from './bko/region1/index.js'
import bko_region2 from './bko/region2/index.js'
import bko_ust_kamenogorsk from './bko/ust-kamenogorsk/index.js'

import karagandinskaya_oblast_karagandy from './karagandinskaya_oblast/karaganda/index.js'
import karagandinskaya_oblast_temirtau from './karagandinskaya_oblast/temirtau/index.js'
import karagandinskaya_oblast_zhezkagan from './karagandinskaya_oblast/zhezkagan/index.js'
import karagandinskaya_oblast_balhash from './karagandinskaya_oblast/balhash/index.js'

import kizilordinskaya_oblast from './kizilordinskaya_oblast/index.js'
import kizilordinskaya_oblast_aral from './kizilordinskaya_oblast/aral/index.js'
import kizilordinskaya_oblast_karmakshinskoe from './kizilordinskaya_oblast/karmakshinskoe/index.js'
import kizilordinskaya_oblast_kyzylorda from './kizilordinskaya_oblast/kyzylorda/index.js'
import kizilordinskaya_oblast_shieliskoe from './kizilordinskaya_oblast/shieliskoe/index.js'
import kizilordinskaya_oblast_zhalagash from './kizilordinskaya_oblast/zhalagash/index.js'
import kizilordinskaya_oblast_kazaly from './kizilordinskaya_oblast/kazaly/index.js'
import kizilordinskaya_oblast_syrdarya from './kizilordinskaya_oblast/syrdarya/index.js'

import kostanayskaya_oblast from './kostanayskaya_oblast/index.js'
import kostanayskaya_oblast_kostanai from './kostanayskaya_oblast/kostanai/index.js'

import mangistauskaya_oblast from './mangistauskaya_oblast/index.js'
import mangistauskaya_oblast_aktau from './mangistauskaya_oblast/aktau/index.js'
import mangistauskaya_oblast_beineu from './mangistauskaya_oblast/beineu/index.js'
import mangistauskaya_oblast_karakianskoe from './mangistauskaya_oblast/karakianskoe/index.js'
import mangistauskaya_oblast_mangistau from './mangistauskaya_oblast/mangistau/index.js'
import mangistauskaya_oblast_munailinskoe from './mangistauskaya_oblast/munailinskoe/index.js'
import mangistauskaya_oblast_tupkaragan from './mangistauskaya_oblast/tupkaragan/index.js'
import mangistauskaya_oblast_zhanaozen from './mangistauskaya_oblast/zhanaozen/index.js'

import pavlodarskaya_oblast from './pavlodarskaya_oblast/index.js'
import pavlodarskaya_oblast_pavlodar from './pavlodarskaya_oblast/pavlodar/index.js'
import pavlodarskaya_oblast_pavlodarski_r from './pavlodarskaya_oblast/pavlodarski_r/index.js'

import sko from './sko/index.js'
import sko_esil from './sko/esil/index.js'
import sko_gabita_mus from './sko/gabita_mus/index.js'
import sko_petropavl from './sko/petropavl/index.js'

import turkestanskaya_oblast from './turkestanskaya_oblast/index.js'
import turkestanskaya_oblast_aryskoe from './turkestanskaya_oblast/aryskoe/index.js'
import turkestanskaya_oblast_sairam from './turkestanskaya_oblast/sairam/index.js'
import turkestanskaya_oblast_shymkenskoe from './turkestanskaya_oblast/shymkenskoe/index.js'
import turkestanskaya_oblast_shymkent_abai from './turkestanskaya_oblast/shymkent/abai/index.js'
import turkestanskaya_oblast_shymkent_al_farabi from './turkestanskaya_oblast/shymkent/al-farabi/index.js'
import turkestanskaya_oblast_shymkent_enbekshi from './turkestanskaya_oblast/shymkent/enbekshi/index.js'
import turkestanskaya_oblast_shymkent_karatau from './turkestanskaya_oblast/shymkent/karatau/index.js'
import turkestanskaya_oblast_shymkent_turan from './turkestanskaya_oblast/shymkent/turan/index.js'

import turkestanskaya_oblast_turkestanskoe from './turkestanskaya_oblast/turkestanskoe/index.js'

import ulytau_oblast_zhezkazgan from './ulytau_oblast/zhezkazgan/index.js'

import zhambilskaya_oblast from './zhambilskaya_oblast/index.js'
import zhambilskaya_oblast_ryskul from './zhambilskaya_oblast/ryskul/index.js'
import zhambilskaya_oblast_shu from './zhambilskaya_oblast/shu/index.js'
import zhambilskaya_oblast_talas from './zhambilskaya_oblast/talas/index.js'
import zhambilskaya_oblast_taraz from './zhambilskaya_oblast/taraz/index.js'

import zhetisu_oblast_taldykorgan from './zhetisu_oblast/taldykorgan/index.js'

import zko from './zko/index.js'
import zko_baiterek from './zko/baiterek/index.js'
import zko_burlinskoe from './zko/burlinskoe/index.js'
import zko_taskalinskoe from './zko/taskalinskoe/index.js'
import zko_uralsk from './zko/uralsk/index.js'

import akmolinskaya_oblast from './akmolinskaya_oblast/index.js'
import akmolinskaya_oblast_atbasar from './akmolinskaya_oblast/atbasar/index.js'
import akmolinskaya_oblast_burabai from './akmolinskaya_oblast/burabai/index.js'
import akmolinskaya_oblast_kokshetau from './akmolinskaya_oblast/kokshetau/index.js'
import akmolinskaya_oblast_stepnogorsk from './akmolinskaya_oblast/stepnogorsk/index.js'



export default {
  common,
  'Кызылординская область': kizilordinskaya_oblast,
  'Атырауская область': atirauskaya_oblast,
  'Алматинская область': almtinskaya_oblast,
  'ЗКО': zko,
  'Актюбинская область': aktubinskaya_oblast,
  'Жамбылская область': zhambilskaya_oblast,
  'Карагандинская область': {
    'Темиртау': karagandinskaya_oblast_temirtau,
    'Жезказган': karagandinskaya_oblast_zhezkagan,
    'Балхаш': karagandinskaya_oblast_balhash,
  },
  'Мангистауская область': mangistauskaya_oblast,
  'Павлодарская область': pavlodarskaya_oblast,
  'СКО': sko,
  'Костанайская область': kostanayskaya_oblast,
  'Акмолинская область': akmolinskaya_oblast,
  'ВКО': bko,
  'Туркестанская область': turkestanskaya_oblast,
}

// export const filialDivisionsMapping = [
//   ['Кызылординская область', [127, 126, 125, 122, 123, 124, 251, 121]],
//   ['Атырауская область', [55, 56, 57, 58, 59, 60, 61, 54]],
//   ['Алматинская область', [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 34]],
//   ['ЗКО', [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 34]],
//   ['Актюбинская область', [33, 32, 31, 22, 23, 24, 25, 26, 27, 28, 29, 30, 21]],
//   ['Жамбылская область', [77, 78, 79, 81, 82, 83, 85, 80, 76, 84]],
//   [['Карагандинская область', 'common'], [98, 99, 100, 101, 102, 103, 97, 96, 95, 94, 87, 88, 89, 90, 91, 92, 93, 86]],
//   [['Карагандинская область', 'Темиртау'], [93]],
//   [['Карагандинская область', 'Жезказган'], [88]],
//   [['Карагандинская область', 'Балхаш'], [87]],
//   ['Мангыстауская область', [129, 130, 131, 132, 133, 134, 128]],
//   ['Нур-Султан', [1]],
//   ['Павлодарская область', [136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 135]],
//   ['СКО', [159, 158, 156, 148, 149, 150, 151, 152, 153, 154, 155, 157, 232, 233, 234, 235, 236, 237, 238, 240, 241, 242, 243, 244, 245, 246, 147, 239]],
//   ['Костанайская область', [105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 104]],
//   ['Акмолинская область', [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 18, 19, 20, 17, 2]],
//   ['ВКО', [177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 176]],
//   ['Туркестанская область', [161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 201, 202, 203, 204, 206, 207, 208, 209, 210, 211, 212, 213, 214, 216, 217, 205, 160, 215]],
//   ['г.Алматы', [195, 196, 197, 198, 199, 200, 194]],
// ]

// export const filialsMapping = [
//   { filialId: 10, filialName: 'Кызылординская область' },
//   { filialId: 5, filialName: 'Атырауская область' },
//   { filialId: 4, filialName: 'Алматинская область' },
//   { filialId: 6, filialName: 'ЗКО' },
//   { filialId: 7, filialName: 'Жамбылская область' },
//   { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: [98, 99, 100, 101, 102, 103, 97, 96, 95, 94, 87, 88, 89, 90, 91, 92, 93, 86], filialDivisionName: 'common' },
//   { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 93, filialDivisionName: 'Темиртау' },
//   { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 88, filialDivisionName: 'Жезказган' },
//   { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 87, filialDivisionName: 'Балхаш' },
//   { filialId: 11, filialName: 'Мангистауская область' },
//   { filialId: 16, filialName: 'Нур-Султан' },
//   { filialId: 12, filialName: 'Павлодарская область' },
//   { filialId: [13, 22], filialName: 'СКО' },
//   { filialId: 9, filialName: 'Костанайская область' },
//   { filialId: 2, filialName: 'Акмолинская область' },
//   { filialId: 15, filialName: 'ВКО' },
//   { filialId: [14, 21], filialName: 'Туркестанская область' },
//   { filialId: [17, 19], filialName: 'г.Алматы' },
//   { filialId: 3, filialName: 'Актюбинская область' },
// ]

export const filialsMapping = [
  { filialId: 2, filialName: 'Акмолинская область', filialDivisionId: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20], logo: akmolinskaya_oblast},
  { filialId: 2, filialName: 'Акмолинская область', filialDivisionId: 19, logo: akmolinskaya_oblast_stepnogorsk},
  { filialId: 2, filialName: 'Акмолинская область', filialDivisionId: 7, logo: akmolinskaya_oblast_atbasar},
  { filialId: 2, filialName: 'Акмолинская область', filialDivisionId: 9, logo: akmolinskaya_oblast_burabai},
  { filialId: 2, filialName: 'Акмолинская область', filialDivisionId: 2, logo: akmolinskaya_oblast_kokshetau},

  { filialId: 3, filialName: 'Актюбинская область', filialDivisionId: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33], logo: aktubinskaya_oblast},
  { filialId: 3, filialName: 'Актюбинская область', filialDivisionId: 21, logo: aktubinskaya_oblast_aktobe},
  { filialId: 3, filialName: 'Актюбинская область', filialDivisionId: 26, logo: aktubinskaya_oblast_kargalinsk},
  { filialId: 3, filialName: 'Актюбинская область', filialDivisionId: 29, logo: aktubinskaya_oblast_mugalzharskoe},
  { filialId: 3, filialName: 'Актюбинская область', filialDivisionId: 33, logo: aktubinskaya_oblast_shalkar},

  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: [194, 195, 196, 197, 198, 199, 200], logo: almaty},
  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: 195, logo: almaty_almatinskoe_ro},
  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: 194, logo: almaty_almaty},
  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: 196, logo: almaty_auezov},
  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: 197, logo: almaty_bostandykskoe},
  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: 200, logo: almaty_turksib},
  { filialId: [17, 19], filialName: 'г.Алматы',  filialDivisionId: 198, logo: almaty_zhetysu},

  { filialId: 4, filialName: 'Алматинская область', filialDivisionId: [523, 524, 525, 526, 527, 528, 529, 530, 531, 532], logo: almtinskaya_oblast },
  { filialId: 4, filialName: 'Алматинская область', filialDivisionId: 527, logo: almtinskaya_oblast_karasai },
  { filialId: 4, filialName: 'Алматинская область', filialDivisionId: 532, logo: almtinskaya_oblast_konaev },

  { filialId: 16, filialName: 'Астана', filialDivisionId: [266, 267, 268, 269], logo: astana},
  { filialId: 16, filialName: 'Астана', filialDivisionId: 266, logo: astana_almaty},
  { filialId: 16, filialName: 'Астана', filialDivisionId: 267, logo: astana_baikonyr},
  { filialId: 16, filialName: 'Астана', filialDivisionId: 268, logo: astana_esil},
  { filialId: 16, filialName: 'Астана', filialDivisionId: 269, logo: astana_saryarka},

  { filialId: 5, filialName: 'Атырауская область', filialDivisionId: [54, 55, 56, 57, 58, 59, 60, 61], logo: atirauskaya_oblast },
  { filialId: 5, filialName: 'Атырауская область', filialDivisionId: 54, logo: atirauskaya_oblast_atyrau },
  { filialId: 5, filialName: 'Атырауская область', filialDivisionId: 55, logo: atirauskaya_oblast_kulsarinskoe },

  { filialId: 15, filialName: 'ВКО', filialDivisionId: [176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193], logo: bko},
  { filialId: 15, filialName: 'ВКО', filialDivisionId: [184, 185, 188], logo: bko_region1},
  { filialId: 15, filialName: 'ВКО', filialDivisionId: [183, 191], logo: bko_region2},
  { filialId: 15, filialName: 'ВКО', filialDivisionId: 176, logo: bko},

  { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: [86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103], filialDivisionName: 'common', logo: karagandinskaya_oblast_karagandy},
  { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 93, filialDivisionName: 'Темиртау', logo: karagandinskaya_oblast_temirtau },
  { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 88, filialDivisionName: 'Жезказган', logo: karagandinskaya_oblast_zhezkagan},
  { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 87, filialDivisionName: 'Балхаш', logo:karagandinskaya_oblast_balhash },
  { filialId: 8, filialName: 'Карагандинская область', filialDivisionId: 87, filialDivisionName: 'Караганда', logo:karagandinskaya_oblast_karagandy },

  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: [121, 122, 123, 124, 125, 126, 127, 251], logo: kizilordinskaya_oblast},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 122, logo: kizilordinskaya_oblast_aral},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 124, logo: kizilordinskaya_oblast_karmakshinskoe},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 121, logo: kizilordinskaya_oblast_kyzylorda},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 126, logo: kizilordinskaya_oblast_shieliskoe},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 251, logo: kizilordinskaya_oblast_zhalagash},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 123, logo: kizilordinskaya_oblast_kazaly},
  { filialId: 10, filialName: 'Кызылординская область', filialDivisionId: 125, logo: kizilordinskaya_oblast_syrdarya},


  { filialId: 9, filialName: 'Костанайская область', filialDivisionId: [104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120], logo: kostanayskaya_oblast },
  { filialId: 9, filialName: 'Костанайская область', filialDivisionId: 104, logo: kostanayskaya_oblast_kostanai },

  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: [128, 129, 130, 131, 132, 133, 134], logo: mangistauskaya_oblast },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 128, logo: mangistauskaya_oblast_aktau },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 133, logo: mangistauskaya_oblast_beineu },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 134, logo: mangistauskaya_oblast_karakianskoe },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 132, logo: mangistauskaya_oblast_mangistau },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 130, logo: mangistauskaya_oblast_munailinskoe },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 131, logo: mangistauskaya_oblast_tupkaragan },
  { filialId: 11, filialName: 'Мангистауская область', filialDivisionId: 129, logo: mangistauskaya_oblast_zhanaozen },

  { filialId: 12, filialName: 'Павлодарская область', filialDivisionId: [135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 522], logo: pavlodarskaya_oblast },
  { filialId: 12, filialName: 'Павлодарская область', filialDivisionId: 135, logo: pavlodarskaya_oblast_pavlodar },
  { filialId: 12, filialName: 'Павлодарская область', filialDivisionId: 522, logo: pavlodarskaya_oblast_pavlodarski_r },

  { filialId: [13, 22], filialName: 'СКО', filialDivisionId: [147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159], logo: sko },
  { filialId: [13, 22], filialName: 'СКО', filialDivisionId: 151, logo: sko_esil },
  { filialId: [13, 22], filialName: 'СКО', filialDivisionId: 150, logo: sko_gabita_mus },
  { filialId: [13, 22], filialName: 'СКО', filialDivisionId: 147, logo: sko_petropavl },

  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: [160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 258, 259, 260, 261], logo: turkestanskaya_oblast },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 162, logo: turkestanskaya_oblast_aryskoe },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 168, logo: turkestanskaya_oblast_sairam },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 161, logo: turkestanskaya_oblast_shymkenskoe },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 261, logo: turkestanskaya_oblast_shymkent_abai },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 258, logo: turkestanskaya_oblast_shymkent_al_farabi },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 260, logo: turkestanskaya_oblast_shymkent_enbekshi },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 259, logo: turkestanskaya_oblast_shymkent_karatau },
  // { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 147, logo: turkestanskaya_oblast_shymkent_turan },
  { filialId: [14, 21], filialName: 'Туркестанская область', filialDivisionId: 174, logo: turkestanskaya_oblast_turkestanskoe },

  { filialId: 49, filialName: 'Улытау', filialDivisionId: [505, 506, 507, 508, 509], logo: ulytau_oblast_zhezkazgan},
  { filialId: 49, filialName: 'Улытау', filialDivisionId: 508, logo: ulytau_oblast_zhezkazgan},

  { filialId: 7, filialName: 'Жамбылская область', filialDivisionId: [76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 252], logo: zhambilskaya_oblast },
  { filialId: 7, filialName: 'Жамбылская область', filialDivisionId: 82, logo: zhambilskaya_oblast_ryskul },
  { filialId: 7, filialName: 'Жамбылская область', filialDivisionId: 85, logo: zhambilskaya_oblast_shu },
  { filialId: 7, filialName: 'Жамбылская область', filialDivisionId: 252, logo: zhambilskaya_oblast_talas },
  { filialId: 7, filialName: 'Жамбылская область', filialDivisionId: 76, logo: zhambilskaya_oblast_taraz },

  { filialId: 43, filialName: 'Жетысу', filialDivisionId: [34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53], logo: zhetisu_oblast_taldykorgan },

  { filialId: 6, filialName: 'ЗКО', filialDivisionId: [62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75], logo: zko },
  { filialId: 6, filialName: 'ЗКО', filialDivisionId: 251, logo: zko_baiterek },
  { filialId: 6, filialName: 'ЗКО', filialDivisionId: 65, logo: zko_burlinskoe },
  { filialId: 6, filialName: 'ЗКО', filialDivisionId: 68, logo: zko_taskalinskoe },
  { filialId: 6, filialName: 'ЗКО', filialDivisionId: 62, logo: zko_uralsk },

  // { filialId: [17, 19], filialName: 'г.Алматы' },
]
