import { 
    LEGAL_PERSON_TYPE_GET_REQUEST, LEGAL_PERSON_TYPE_GET_SUCCESS, LEGAL_PERSON_TYPE_GET_ERROR, 
    LEGAL_PERSON_TYPE_GET_BY_KEY_REQUEST, LEGAL_PERSON_TYPE_GET_BY_KEY_SUCCESS, LEGAL_PERSON_TYPE_GET_BY_KEY_ERROR, 
    LEGAL_PERSON_TYPE_ADD_REQUEST, LEGAL_PERSON_TYPE_ADD_SUCCESS, LEGAL_PERSON_TYPE_ADD_ERROR, 
    LEGAL_PERSON_TYPE_EDIT_REQUEST, LEGAL_PERSON_TYPE_EDIT_SUCCESS, LEGAL_PERSON_TYPE_EDIT_ERROR, 
    LEGAL_PERSON_TYPE_DELETE_REQUEST, LEGAL_PERSON_TYPE_DELETE_SUCCESS, LEGAL_PERSON_TYPE_DELETE_ERROR
} from "../constants/legalPersonTypeConstants";

const initialState = {
    legalPersonTypes: [],
    legalPersonType: {},
    loading: false,
    success: null,
    error: null,
    legalPersonTypeRequestedCount: 0,
};

export const legalPersonTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case LEGAL_PERSON_TYPE_GET_REQUEST:
            return {
                ...state,
                legalPersonTypeRequestedCount: ++state.legalPersonTypeRequestedCount,
                loading: true,
            };
        case LEGAL_PERSON_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                legalPersonTypes: action.payload,
                success: true,
            };
        case LEGAL_PERSON_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case LEGAL_PERSON_TYPE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LEGAL_PERSON_TYPE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                legalPersonType: action.payload,
                success: true,
            };
        case LEGAL_PERSON_TYPE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case LEGAL_PERSON_TYPE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LEGAL_PERSON_TYPE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case LEGAL_PERSON_TYPE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case LEGAL_PERSON_TYPE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LEGAL_PERSON_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case LEGAL_PERSON_TYPE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case LEGAL_PERSON_TYPE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LEGAL_PERSON_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case LEGAL_PERSON_TYPE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
