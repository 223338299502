import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {filialCRUD } from "../../../http";
import {FILIAL_DETAIL_ROUTE } from "../../../routes";
import React from "react";
import FilialDivisionCRUDTable from "./FilialDivisionCRUDTable";
import { mapOrganizationName, mapOrganizationValues } from "../../../functions/viewDataMap";

export default function FilialCRUDTable({withOrganization, ...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("filialList.tableItem.id"), dataIndex: "id", key:"id"},
        {
            title: "Организация",
            key: 'organizationId',
            render: (unknown, row) => row.organizationId ? mapOrganizationName(row.organizationId) :
                <span style={{color: "gray"}}>Не задан</span>,
            hidden: !withOrganization
        },
        {title: t("filialList.tableItem.name"), dataIndex: "name", key:"name"},
        {title: t("filialList.tableItem.address"), dataIndex: "address", key:"address",render: (unknown, row) => row.addressLine ? row.addressLine : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("filialList.tableItem.supervisor"),dataIndex: "supervisor", key: "supervisor", render: (unknown, row) => row.supervisor ? row.supervisor.fullName : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("filialList.tableItem.bin"),dataIndex: "bin", key: "bin", render: (unknown, row) => row.bin ? row.bin : <span style={{color: "gray"}}>Не задан</span>},
    ]

    const searchFields = [
        {
            title: "Наименование",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Организация",
            key: "organizationId",
            compareType: "equals",
            reducerKey: "organization",
            options: mapOrganizationValues(),         
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ]

    return <CRUDTable
        search={filialCRUD.search}
        columns={columns}
        onClickCreateButton={() => push(FILIAL_DETAIL_ROUTE("new"))}
        onRowClick={(record, rowIndex) => push({
            pathname: FILIAL_DETAIL_ROUTE(record.id),
            state:{
                record
            }
        })}
        reducerFilterKey={"filialId"}
        reducerParentKey={"id"}
        CollapseCRUDTable={FilialDivisionCRUDTable}
        searchFields={searchFields}
        filterFields = {filterFields}
        {...props}
    />
}