import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

function ReferalRadio({ label, array, value, required, setValue, disabled }) {
  useEffect(() => {
    if (!value) setValue(array[0]);
  }, [value]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" required={required}>
        {label}
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {disabled ? array.filter(el => el === value).map((el, idx) => (
            <FormControlLabel
                key={idx}
                value={el}
                control={<Radio />}
                disabled={disabled}
                label={el}
            />
        )) : array.map((el, idx) => (
          <FormControlLabel
            key={idx}
            value={el}
            control={<Radio />}
            disabled={disabled}
            label={el}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default ReferalRadio;
