import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProtocolForms } from "../../../redux/actions/protocolFormActions";
import { getReferrals } from "../../../redux/actions/referralActions";
import { getResults } from "../../../redux/actions/resultActions";
import { getFilials } from "../../../redux/actions/filialActions";
import { getFilialDivisions } from "../../../redux/actions/filialDivisionActions";
import { getDepartments } from "../../../redux/actions/departmentActions";
import { getDivisions } from "../../../redux/actions/divisionActions";
import { getEmployees } from "../../../redux/actions/employeeActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function RemoveProtocol({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [number, setNumber] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [formId, setFormId] = useState('');
    const [referralId, setReferralId] = useState('');
    const [resultId, setResultId] = useState('');
    const [filialId, setFilialId] = useState('');
    const [filialDivisionId, setFilialDivisionId] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [divisionId, setDivisionId] = useState('');
    const [responsibleId, setResponsibleId] = useState('');
    const [performerId, setPerformerId] = useState('');
    const { loading, protocols, protocol } = useSelector((state) => state.protocol);
    const { protocolForms, protocolFormRequestedCount } = useSelector((state) => state.protocolForm);
    const { referrals, referralRequestedCount } = useSelector((state) => state.referral);
    const { results, resultRequestedCount } = useSelector((state) => state.result);
    const { filials, filialRequestedCount } = useSelector((state) => state.filial);
    const { filialDivisions, filialDivisionRequestedCount } = useSelector((state) => state.filialDivision);
    const { departments, departmentRequestedCount } = useSelector((state) => state.department);
    const { divisions, divisionRequestedCount } = useSelector((state) => state.division);
    const { employees, employeeRequestedCount } = useSelector((state) => state.employee);
    const emptyState = () => {
        setId(null);
        setId('');
        setNumber('');
        setCreatedAt('');
        setFormId('');
        setReferralId('');
        setResultId('');
        setFilialId('');
        setFilialDivisionId('');
        setDepartmentId('');
        setDivisionId('');
        setResponsibleId('');
        setPerformerId('');
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, id, number, createdAt, formId, referralId, resultId, filialId, filialDivisionId, departmentId, divisionId, responsibleId, performerId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, number, createdAt, formId, referralId, resultId, filialId, filialDivisionId, departmentId, divisionId, responsibleId, performerId
        } = protocol || {};
        setId(id);
        setId(id);
        setNumber(number);
        setCreatedAt(createdAt);
        setFormId(formId);
        setReferralId(referralId);
        setResultId(resultId);
        setFilialId(filialId);
        setFilialDivisionId(filialDivisionId);
        setDepartmentId(departmentId);
        setDivisionId(divisionId);
        setResponsibleId(responsibleId);
        setPerformerId(performerId);
    }, [protocol, protocols])
    useEffect(() => {
        if (!open)
        {
            return;
        }
        let response;
        const getProtocolFormsAsync = async () => {
            response = await dispatch(getProtocolForms({ paging: { skip: 0 } }));
        }
        const getReferralsAsync = async () => {
            response = await dispatch(getReferrals({ paging: { skip: 0 } }));
        }
        const getResultsAsync = async () => {
            response = await dispatch(getResults({ paging: { skip: 0 } }));
        }
        const getFilialsAsync = async () => {
            response = await dispatch(getFilials({ paging: { skip: 0 } }));
        }
        const getFilialDivisionsAsync = async () => {
            response = await dispatch(getFilialDivisions({ paging: { skip: 0 } }));
        }
        const getDepartmentsAsync = async () => {
            response = await dispatch(getDepartments({ paging: { skip: 0 } }));
        }
        const getDivisionsAsync = async () => {
            response = await dispatch(getDivisions({ paging: { skip: 0 } }));
        }
        const getEmployeesAsync = async () => {
            response = await dispatch(getEmployees({ paging: { skip: 0 } }));
        }
        if (protocolFormRequestedCount == 0)
        {
            getProtocolFormsAsync();
        }
        if (referralRequestedCount == 0)
        {
            getReferralsAsync();
        }
        if (resultRequestedCount == 0)
        {
            getResultsAsync();
        }
        if (filialRequestedCount == 0)
        {
            getFilialsAsync();
        }
        if (filialDivisionRequestedCount == 0)
        {
            getFilialDivisionsAsync();
        }
        if (departmentRequestedCount == 0)
        {
            getDepartmentsAsync();
        }
        if (divisionRequestedCount == 0)
        {
            getDivisionsAsync();
        }
        if (employeeRequestedCount == 0)
        {
            getEmployeesAsync();
        }
    }, [dispatch, action, open])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    ID: {id}
                                </Grid>
                                <Grid item xs={6}>
                                    Номер: {number}
                                </Grid>
                                <Grid item xs={6}>
                                    Создано: {createdAt}
                                </Grid>
                                <Grid item xs={6}>
                                    Форма: {protocolForms.find(_ => _.id == formId)?.number}
                                </Grid>
                                <Grid item xs={6}>
                                    Направление: {referrals.find(_ => _.id == referralId)?.number}
                                </Grid>
                                <Grid item xs={6}>
                                    Журнал: {results.find(_ => _.id == resultId)?.number}
                                </Grid>
                                <Grid item xs={6}>
                                    Филиал: {filials.find(_ => _.id == filialId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Районнное отделение: {filialDivisions.find(_ => _.id == filialDivisionId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Лаборатория: {departments.find(_ => _.id == departmentId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Отдел: {divisions.find(_ => _.id == divisionId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Заведующий лабораторией: {employees.find(_ => _.id == responsibleId)?.fullName}
                                </Grid>
                                <Grid item xs={6}>
                                    Исполнитель (лаборант): {employees.find(_ => _.id == performerId)?.fullName}
                                </Grid>
                                {action != 'details' &&
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Удалить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
