import React, { useEffect, useMemo, useState } from 'react';
import { FormSection, PageContainer, PageHeader } from "../../../components";
import { CircularProgress, Grid } from "@mui/material";
import { RESOLUTION, RESOLUTION_EDIT_ROUTE } from "../../../routes";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/Dashboard";
import { FormProvider } from "../../../components/hook-form";
import RHFTextField from "../../../components/hook-form/RHFTextField";
import useReload from "../../../hooks/useReload";
import { useForm, useWatch } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import RHFActField from "../../../components/hook-form/RHFActField";
import RHFDate from "../../../components/hook-form/RHFDate";
import AnObjectField from "../../../components/Relations/AnObjectField";
import { FilialDivisionSelect, FilialSelect, OrderTypeSelect } from "../../../components/Select/domainSelects";
import EmployeeField from "../../../components/Relations/DoctorField";
import { yupResolver } from "@hookform/resolvers/yup";
import { resolutionSchema } from "../../../models/schemas/resolutionSchema";
import { actCRUD, protocolCRUD, resolutionActCRUD, resolutionCRUD } from "../../../http";
import useLoader from "../../../hooks/useLoader";
import RHFIndicator from "../../../components/hook-form/RHFIndicator";
import { REQUEST_STATE_ID_CREATED } from "../../../redux/constants/requestStateConstants";
import RHFGovernment from "../../../components/hook-form/RHFGovernment";
import { asJSONSafe, renderControls, RequestStateChecker, State } from "../../../functions";
import { controlsKSEK } from "../../../sections/Header/KSESHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ORGANIZATION_ID_NCE } from '../../../redux/constants/organizationConstants';
import useRoles from '../../../hooks/useRoles';
import ResultsAndProtocolsSection from "../../../sections/ResultsAndProtocolsSection";
import EditResultAndProtocolSection from "../../../sections/EditResultAndProtocolSection";
import ProtocolDetails from "../../Protocol/Popups/ProtocolDetails";
import { addRange } from '../../../functions/linqSimulation';
import RHFTechnicalRegulations from '../../../components/hook-form/RHFTechnicalRegulations';
import { normalizeIndicators } from '../../../functions/normalization';

function ResolutionCreate() {
    const { push } = useHistory();
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)
    const defaultValues = useMemo(() => ({
        targetDate: new Date(),
        createdAt: new Date(),
        number: '',
        acts: [],
        indicators: [],
        targetObjectId: '',
        targetObject: {},
        orderTypeId: "",
        standards: [],
        createdBy: {},
        createdById: "",
        filialId: "",
        filialDivisionId: "",
    }), []);
    const { id } = useParams();
    const { reload, reloadValue } = useReload();
    const isEditing = useMemo(() => id, [id]);
    const [isCreated, setIsCreated] = useState(true);
    const [recreated, setRecreated] = useState(false);
    const { employee } = useRoles();
    const [res, setRes] = useState({});

    const [protocols, setProtocols] = useState([]);



    const disabled = (!isCreated && isEditing)

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(resolutionSchema)
    });

    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(false);

    const fetchProtocols = async (acts, resolutionId) => {
        const result = [];
        for (let i = 0; i < acts.length; i++) {
            const item = acts[i];
            const actId = item.actId;

            if (!actId) {
                continue;
            }

            const protocolsResponse = await protocolCRUD.search({
                filter: {
                    actId: {
                        operand1: actId,
                        operator: 'equals'
                    }
                },
                paging: { skip: 0, take: 100 }
            });
            const protocols2 = protocolsResponse.result;

            addRange(result, protocols2);
        }

        setProtocols(result);
    }


    useEffect(() => {
        (async function () {
            if (isEditing) {
                fetchStart()
                const res = await resolutionCRUD.get(id);
                setIsCreated(res?.state?.code === REQUEST_STATE_ID_CREATED)
                const acts = res?.acts.map(el => ({ actId: el.actId, ...el?.act }))
                setRes(res)
                setRecreated(res.recreated);
                fetchProtocols(res.acts, id);
                const indicators = normalizeIndicators(asJSONSafe(res?.indicators));
                reset({
                    ...res,
                    acts,
                    indicators: indicators,
                    standards: asJSONSafe(res?.standards),
                    regulationOfCUs: asJSONSafe(res?.regulationOfCUs),
                    createdBy: authorizedEmployee,
                    createdById: authorizedEmployee.id
                });
                fetchStop()
            }
        }())
    }, [reloadValue]);

    const { t } = useTranslation()

    const {
        handleSubmit,
        setValue,
        control,
        reset,
        watch,
        formState: { isSubmitting },
    } = methods;

    const targetObject = useWatch({
        control,
        name: "targetObject",
    });

    const orderTypeId = useWatch({
        control,
        name: "orderTypeId",
    });

    const createdBy = useWatch({
        control,
        name: "createdBy",
    });

    const filialId = useWatch({
        control,
        name: "filialId",
    });
    const filialDivisionId = useWatch({
        control,
        name: "filialDivisionId",
    });

    const value = watch()

    const state = useMemo(
        () => new RequestStateChecker(value?.state?.code),
        [value?.state?.code]
    );
    const stateCode = state?.stateCode?.toLowerCase();

    const onSubmit = async state => {

        if (isEditing) {
            const data = { ...state }
            const acts = data?.acts?.map(el => ({ actId: el.actId }))
            delete data.targetObjectLegalPersonType;
            delete data.targetObjectLegalPersonTypeId;
            delete data.targetObjectSection;
            delete data.targetObjectSectionId;
            delete data.targetObjectSubSection;
            delete data.targetObjectSubSectionId;
            await resolutionCRUD.edit({
                ...data,
                id: +id,
                acts: [...acts],
                indicators: JSON.stringify(state.indicators),
                standards: JSON.stringify(state.standards),
                regulationOfCUs: JSON.stringify(state.regulationOfCUs)
            })
        } else {
            const { id } = await resolutionCRUD.create({
                ...state,
                indicators: JSON.stringify(state.indicators),
                standards: JSON.stringify(state?.standards),
                regulationOfCUs: JSON.stringify(state.regulationOfCUs)
            })
            return push(RESOLUTION_EDIT_ROUTE(id));
        }
    }

    React.useEffect(() => {
        if (authorizedEmployee) {
            setValue('createdBy', authorizedEmployee)
            setValue('createdById', authorizedEmployee.id)
        }
    }, [authorizedEmployee, authorizedEmployee?.id])


    useEffect(() => {
        const acts = value?.acts;

        if (!acts || acts.length == 0) {
            return;
        }

        let data = acts[0].data;

        if (!data) {
            return;
        }

        data = JSON.parse(data);

        const obj = data?.object?.value;

        if (!obj) {
            return;
        }

        setValue('targetObjectId', obj.id);
        setValue('targetObject', obj);
    }, [value?.acts])

    const {
        start: startSubmit,
        stop: stopSubmit,
    } = useLoader(false);

    const [editProtocolOpen, setEditProtocolOpen] = useState(false);
    const [editProtocol, setEditProtocol] = useState(null);

    const handleEditProtocolOpen = async (protocolId) => {
        await startSubmit();
        const protocol = await protocolCRUD.get(protocolId);
        await setEditProtocol(protocol);
        await setEditProtocolOpen(true);

        await stopSubmit();
    }

//
    return (
        <DashboardLayout>
            <PageHeader controls={renderControls(controlsKSEK(value, reload, push, resolutionCRUD, RESOLUTION, 'Resolution'), {
                state: stateCode,
                roles: [],
            })} heading={isEditing ? `Постановление #${value?.number || ""} ${stateCode === State.Processsing ? '(отправлено в НЦЭ)' :
                stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
                }` : "Постановление: Создание"}>

            </PageHeader>
            {fetchLoading ? (
                <Grid item xs={12}>
                    <CircularProgress />
                </Grid>
            ) : <PageContainer>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingBottom: "20px",
                        }}
                    >
                        <p>
                            Приложение 3 к правилам проведения санитарно- эпидемиологической
                            экспертизы
                        </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <p>Форма</p>
                    </div>
                    <h4 style={{ textAlign: "center", fontWeight: '700' }}>
                        Постановление о проведении санитарно-эпидемиологической экспертизы
                    </h4>
                    {protocols?.length ? <FormSection heading="Протоколы" mb={4}>
                        <ResultsAndProtocolsSection
                            resolution
                            onProtocolClick={async (_) => {
                                await handleEditProtocolOpen(_);
                            }}
                            protocols={protocols}
                            resultsReloadValue={reloadValue}
                        />
                    </FormSection> : null}
                    {employee ?
                        <Grid container>
                            <FilialSelect
                                style={{ marginBottom: "5px" }}
                                label={"Филиал пользователя"}
                                fullWidth
                                disabled={true}
                                value={employee.filialId}
                            />
                            {employee.filialDivisionId && <FilialDivisionSelect
                                style={{ marginBottom: "5px" }}
                                label={"Районное отделение пользователя"}
                                fullWidth
                                disabled={true}
                                value={employee.filialDivisionId}
                            />}
                        </Grid>
                        : null}
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <RHFTextField disabled={true} label="№" name="number" />
                        </Grid>
                        <Grid item xs={6}>
                            <RHFDate disabled={disabled} label="Дата" name="createdAt" />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Дата назначения</p>
                            <RHFDate disabled={disabled} style={{ width: "50%", marginLeft: "30px" }}
                                label="Дата назначения"
                                name="targetDate" />
                        </Grid>
                        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <AnObjectField disabled={disabled}
                                label="Наименование проверяемого субъекта"
                                value={targetObject}
                                onChange={(obj) => {
                                    setValue('targetObject', obj)
                                    setValue('targetObjectId', obj.id)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>
                                Должностное лицо санитарно-эпидемиологической службы
                            </p>
                            <RHFTextField disabled={disabled} style={{ width: "50%", marginLeft: "30px" }} label="ФИО"
                                name="responsibleFullName" />
                        </Grid>
                        <RHFActField disabled={disabled || recreated} label="Рассмотрев представленные документы" reload={reload}>
                            Рассмотрев представленные документы
                        </RHFActField>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Установил (указать характер складывающейся
                                санитарно-эпидемиологической ситуации на
                                территории, группе объектов, нарушения законодательства Республики Казахстан в области
                                санитарно-эпидемиологического благополучия населения)
                            </p>
                            <RHFTextField disabled={disabled} style={{ width: "50%", marginLeft: "30px" }}
                                label="Характер ситуации"
                                name="establishedSituationDescription" />
                        </Grid>


                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>
                                Основание вынесения Постановления
                            </p>
                            <OrderTypeSelect
                                disabled={disabled}
                                fullWidth
                                formStyle={{ width: "50%", marginLeft: "30px" }}
                                required={false}
                                label="Тип заказа"
                                value={orderTypeId}
                                onChange={(val) => setValue('orderTypeId', val)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployeeField
                                disabled
                                label={"Создал заявку"}
                                fullWidth
                                object={createdBy}
                                value={createdBy?.id}
                                onChange={(obj) => {
                                    setValue('createdBy', obj)
                                    setValue('createdById', obj.id)
                                }}
                            />
                        </Grid>
                        <h6 style={{ fontWeight: "bold" }}>Постановил</h6>
                        <Grid item xs={12}>
                            <FilialSelect
                                disabled={disabled}
                                label={"Филиал"}
                                fullWidth
                                organizationId={ORGANIZATION_ID_NCE}
                                value={filialId}
                                onChange={(val) => {
                                    setValue('filialId', val)
                                    setValue('filialDivisionId', Number(val) !== Number(filialId)
                                        ? null
                                        : filialDivisionId)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FilialDivisionSelect
                                disabled={disabled}
                                label={"Наименование отделения"}
                                fullWidth
                                organizationId={ORGANIZATION_ID_NCE}
                                value={filialDivisionId}
                                filialId={filialId}
                                onChange={(filialDivisionId, filialDivision) => {
                                    setValue('filialDivisionId', filialDivisionId)
                                    setValue('filialId', Number(filialDivision.filialId) !==
                                        Number(filialId)
                                        ? filialDivision.filialId
                                        : filialId)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Документ санитарно-эпидемиологического нормирования (на
                                соответствие, которого проводится исследование (ГОСТ)
                            </p>
                            <RHFGovernment disabled={disabled} />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ width: "50%" }}>Документ санитарно-эпидемиологического нормирования (на
                                соответствие, которого проводится исследование (ТР/ТС)
                            </p>
                            <RHFTechnicalRegulations disabled={disabled} />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                            <RHFIndicator disabled={disabled} />
                        </Grid>
                    </Grid>
                    <LoadingButton disabled={disabled} loading={isSubmitting} type="submit" variant="contained"
                        sx={{ mt: 3, mb: 2, alignSelf: 'flex-end' }}>
                        {isEditing ? 'Сохранить' : 'Добавить'}
                    </LoadingButton>
                </FormProvider>
                {protocols?.length ? <ProtocolDetails action={'details'} title={t("protocolList.detailsText")} reload={reload} open={editProtocolOpen} setOpen={setEditProtocolOpen}
                    protocol={editProtocol} /> : null}
            </PageContainer>}
        </DashboardLayout>
    );
}

export default ResolutionCreate;
