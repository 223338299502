import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractRequests } from "../../../redux/actions/contractRequestActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditDepartureScheduleRequest({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [dateOfDeparture, setDateOfDeparture] = useState(new Date());
    const [authorComment, setAuthorComment] = useState('');
    const [performerComment, setPerformerComment] = useState('');
    const [contractRequestId, setContractRequestId] = useState(null);
    const { loading, departureScheduleRequests, departureScheduleRequest } = useSelector((state) => state.departureScheduleRequest);
    const { contractRequests, contractRequestRequestedCount } = useSelector((state) => state.contractRequest);
    const emptyState = () => {
        setId(null);
        setName('');
        setAddressLine('');
        setDateOfDeparture(new Date());
        setAuthorComment('');
        setPerformerComment('');
        setContractRequestId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, name, addressLine, dateOfDeparture, authorComment, performerComment, contractRequestId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, name, addressLine, dateOfDeparture, authorComment, performerComment, contractRequestId
        } = departureScheduleRequest || {};
        setId(id);
        setName(name);
        setAddressLine(addressLine);
        setDateOfDeparture(dateOfDeparture);
        setAuthorComment(authorComment);
        setPerformerComment(performerComment);
        setContractRequestId(contractRequestId);
    }, [departureScheduleRequest, departureScheduleRequests])
    useEffect(() => {
        if (!open)
        {
            return;
        }
        let response;
        const getContractRequestsAsync = async () => {
            response = await dispatch(getContractRequests({ paging: { skip: 0 } }));
        }
        if (contractRequestRequestedCount == 0)
        {
            getContractRequestsAsync();
        }
    }, [dispatch, action, open])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={name} onChange={(e) => setName(e.target.value)} fullWidth id="name" name="name" label="Наименование объекта" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={addressLine} onChange={(e) => setAddressLine(e.target.value)} fullWidth id="addressLine" name="addressLine" label="Адрес" />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                        <DatePicker views={['day']} label="Дата выезда" value={dateOfDeparture} onChange={(newValue) => {
                                            setDateOfDeparture(newValue);
                                        }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                                            <TextField margin="normal"  {...params} helperText={null}/>
                                        }/>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={authorComment} onChange={(e) => setAuthorComment(e.target.value)} fullWidth id="authorComment" name="authorComment" label="Комментарий автора" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={performerComment} onChange={(e) => setPerformerComment(e.target.value)} fullWidth id="performerComment" name="performerComment" label="Комментарий исполнителя" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField  value={contractRequestId} onChange={(e) => setContractRequestId(e.target.value)} margin="normal" fullWidth id="contractRequestId" select>
                                        {contractRequests && contractRequests.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.number}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
