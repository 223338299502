import { employeeCRUD } from "../../http";
import {
  EMPLOYEE_GET_REQUEST,
  EMPLOYEE_GET_SUCCESS,
  EMPLOYEE_GET_ERROR,
  EMPLOYEE_GET_BY_KEY_REQUEST,
  EMPLOYEE_GET_BY_KEY_SUCCESS,
  EMPLOYEE_GET_BY_KEY_ERROR,
  EMPLOYEE_ADD_REQUEST,
  EMPLOYEE_ADD_SUCCESS,
  EMPLOYEE_ADD_ERROR,
  EMPLOYEE_EDIT_REQUEST,
  EMPLOYEE_EDIT_SUCCESS,
  EMPLOYEE_EDIT_ERROR,
  EMPLOYEE_DELETE_REQUEST,
  EMPLOYEE_DELETE_SUCCESS,
  EMPLOYEE_DELETE_ERROR,
  EMPLOYEE_GET_AUTHORIZED_REQUEST,
  EMPLOYEE_GET_AUTHORIZED_SUCCESS,
  EMPLOYEE_GET_AUTHORIZED_ERROR,
} from "../constants/employeeConstants";

export const getEmployees = (args) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_GET_REQUEST,
    });

    const data = await employeeCRUD.search(args);
    dispatch({
      type: EMPLOYEE_GET_SUCCESS,
      payload: data.result,
    });

    return data.result;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// Why we need to keep two versions together?!!!
// Please always return data, not data.result!!! 
// Because we haven't access to value of total records now!
export const getEmployees2 = (args) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_GET_REQUEST,
    });

    const data = await employeeCRUD.search(args);
    dispatch({
      type: EMPLOYEE_GET_SUCCESS,
      payload: data.result,
    });

    return data;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAuthorizedEmployee = () => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_GET_AUTHORIZED_REQUEST,
    });

    const employeeId = JSON.parse(localStorage.getItem("employee")).id;
    if (!employeeId) throw Error("Authorized Employee Not Found!");

    const data = await employeeCRUD.get(employeeId);

    dispatch({
      type: EMPLOYEE_GET_AUTHORIZED_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_GET_AUTHORIZED_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEmployeeByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_GET_BY_KEY_REQUEST,
    });

    const data = await employeeCRUD.get(id);

    console.log({ getEmployeeByKey, data });

    dispatch({
      type: EMPLOYEE_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: EMPLOYEE_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addEmployee = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_ADD_REQUEST,
    });

    await employeeCRUD.create(data);
    await dispatch(getEmployees(args));

    dispatch({
      type: EMPLOYEE_ADD_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editEmployee = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_EDIT_REQUEST,
    });

    await employeeCRUD.edit(data);
    await dispatch(getEmployees(args));

    dispatch({
      type: EMPLOYEE_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });

    throw error;
  }
};

export const deleteEmployee = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: EMPLOYEE_DELETE_REQUEST,
    });

    await employeeCRUD.delete(id);
    await dispatch(getEmployees(args));
    dispatch({
      type: EMPLOYEE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
