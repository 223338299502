import { brigadeCRUD } from "../../http";
import {
  BRIGADE_GET_REQUEST,
  BRIGADE_GET_SUCCESS,
  BRIGADE_GET_ERROR,
  BRIGADE_GET_BY_KEY_REQUEST,
  BRIGADE_GET_BY_KEY_SUCCESS,
  BRIGADE_GET_BY_KEY_ERROR,
  BRIGADE_ADD_REQUEST,
  BRIGADE_ADD_SUCCESS,
  BRIGADE_ADD_ERROR,
  BRIGADE_EDIT_REQUEST,
  BRIGADE_EDIT_SUCCESS,
  BRIGADE_EDIT_ERROR,
  BRIGADE_DELETE_REQUEST,
  BRIGADE_DELETE_SUCCESS,
  BRIGADE_DELETE_ERROR,
} from "../constants/brigadeConstants";

export const getBrigades = (args) => async (dispatch) => {
  try {
    dispatch({
      type: BRIGADE_GET_REQUEST,
    });

    const data = await brigadeCRUD.search(args);
    dispatch({
      type: BRIGADE_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: BRIGADE_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBrigadeByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: BRIGADE_GET_BY_KEY_REQUEST,
    });

    const data = await brigadeCRUD.get(id);
    dispatch({
      type: BRIGADE_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: BRIGADE_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addBrigade = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: BRIGADE_ADD_REQUEST,
    });

    const response = await brigadeCRUD.create(data);
    await dispatch(getBrigades(args));

    dispatch({
      type: BRIGADE_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: BRIGADE_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editBrigade = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: BRIGADE_EDIT_REQUEST,
    });

    await brigadeCRUD.edit(data, args);
    await dispatch(getBrigades(args));

    dispatch({
      type: BRIGADE_EDIT_SUCCESS,
    });
  } catch (error) {
    console.log({ error });

    dispatch({
      type: BRIGADE_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteBrigade = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: BRIGADE_DELETE_REQUEST,
    });

    await brigadeCRUD.delete(id);
    await dispatch(getBrigades(args));

    dispatch({
      type: BRIGADE_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BRIGADE_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
