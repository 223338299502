import m_09_2201 from './KZ.M.09.2201.svg'
import m_09_2201_b from './KZ.M.09.2201 black.svg'

import t_09_0592 from './KZ.T.09.0592.svg'
import t_09_0592_b from './KZ.T.09.0592 black.svg'

export default [
  m_09_2201,
  m_09_2201_b,
  t_09_0592,
  t_09_0592_b,
]
