import React, { useState } from "react";

import {useDispatch, useSelector} from "react-redux";

import {
  Form,
  FormSection,
  SelectField,
} from "../../../../components";

import {
  _addAct,
  getWithoutDepartureRequestByKey,
  getDepartureScheduleRequestByKey,
  getAnObjectReferralByKey, getDesinsectionRequestByKey,
} from "../../../../redux/actions";

import { Grid } from "@mui/material";
import { renderSections } from "../../../../functions";

import DialogLayout from "../../../../components/Dialog/DialogLayout";
import DddDisinsectionSection from "../Sections/DddDisinsectionSection";
import {brigadeCRUD} from "../../../../http";
import DddFinalSection from "../Sections/DddFinalSection";

export const DddDisinsectionCreateModal = ({ open, setOpen, onClose }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [dddId, setDddId] = useState(null);

  const { desinsectionRequest } = useSelector(
      (s) => s.desinsectionRequest
  );


  const options = [{
    label: 'Наряд на дезинфекцию, дератизацию', value: 'DddDisinsection',
  },{
    label: 'Наряд на заключительную дезинфекцию', value: 'DddFinalDisinfection',
  }];

  const handleReset = () => {
    setDddId(null);
  };

  const handleSelect = (e) => {
    e.preventDefault();
    setDddId(e.target.value);
  };

  const handleSubmit = async (e, data) => {
    setLoading(true);
    const desinsectionRequestId = desinsectionRequest?.id
        ? desinsectionRequest.id
        : null;

    const refreshAction = desinsectionRequestId
        ? getDesinsectionRequestByKey(desinsectionRequestId)
        : null;

    await brigadeCRUD.create({
      ...data,
      desinsectionRequestId
    })

    await dispatch(refreshAction);
    //
    setLoading(false);
    setOpen(false);
    handleReset();
  };

  const sections = [
    {
      mode: "DddDisinsection",
      component: DddDisinsectionSection,
      onSubmit: handleSubmit,
    },
    {
      mode: "DddFinalDisinfection",
      component: DddFinalSection,
      onSubmit: handleSubmit,
    },
  ];

  return (
      <DialogLayout setOpen={setOpen} open={open} loading={loading} title="Наряд: Создание">
        <Form onSubmit={handleSubmit}>
          <FormSection heading="Наряды" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SelectField
                  label="Тип наряда"
                  options={options}
                  onChange={handleSelect}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection mb={4}>
            {renderSections(sections, { mode: dddId })}
          </FormSection>
        </Form>
      </DialogLayout>
  );
};

DddDisinsectionCreateModal.defaultProps = {};
