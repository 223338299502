import { 
    PATIENT_GET_REQUEST, PATIENT_GET_SUCCESS, PATIENT_GET_ERROR, 
    PATIENT_GET_BY_KEY_REQUEST, PATIENT_GET_BY_KEY_SUCCESS, PATIENT_GET_BY_KEY_ERROR, 
    PATIENT_ADD_REQUEST, PATIENT_ADD_SUCCESS, PATIENT_ADD_ERROR, 
    PATIENT_EDIT_REQUEST, PATIENT_EDIT_SUCCESS, PATIENT_EDIT_ERROR, 
    PATIENT_DELETE_REQUEST, PATIENT_DELETE_SUCCESS, PATIENT_DELETE_ERROR
} from "../constants/patientConstants";

const initialState = {
    patients: [],
    patient: {},
    loading: false,
    success: null,
    error: null,
    patientRequestedCount: 0,
};

export const patientReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PATIENT_GET_REQUEST:
            return {
                ...state,
                patientRequestedCount: ++state.patientRequestedCount,
                loading: true,
            };
        case PATIENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                patients: action.payload,
                success: true,
            };
        case PATIENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PATIENT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PATIENT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                patient: action.payload,
                success: true,
            };
        case PATIENT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PATIENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PATIENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PATIENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PATIENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PATIENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PATIENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PATIENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PATIENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PATIENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
