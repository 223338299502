import { resultTypeCRUD} from "../../http";
import {
  RESULT_TYPE_GET_SUCCESS,
  RESULT_TYPE_GET_REQUEST,
  RESULT_TYPE_GET_ERROR,
} from "../constants/resultConstants";

export const getResultsType = (args) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_TYPE_GET_REQUEST,
    });

    const data = await resultTypeCRUD.search(args);
    dispatch({
      type: RESULT_TYPE_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: RESULT_TYPE_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

