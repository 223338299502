import React from "react";
import DashboardLayout from "../../../layouts/Dashboard";
import {ResolutionCRUDTable} from "../../../components/CRUD/Tables/ResolutionCRUDTable";

const Resolution = () => {
  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
        <ResolutionCRUDTable />
    </DashboardLayout>
  );
};

export default Resolution;
