import React, { useState } from "react";

import { Box, Button, Typography, Modal, Paper } from "@mui/material";
import { BasicDialog } from "../../Dialog";

export const BasicModal = ({
  children,
  title,
  description,
  open,
  setOpen,
  style,
  safeMode,
  onClose,
}) => {
  const [dialog, setDialog] = useState(false);

  const handleModalClose = () => {
    if (!safeMode) return setOpen(false);

    handleDialogOpen();
  };

  const handleDialogOpen = () => setDialog(true);
  const handleDialogClose = () => setDialog(false);

  const handleDialogConfirm = async () => {
    await onClose();
    await handleDialogClose();
  };
  const handleDialogCancel = async () => {
    await handleDialogClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            width: "85%",
            borderRadius: "15px",
            padding: "20px 40px",
            boxShadow: 20,
            bgcolor: "background.paper",
            maxHeight: "80vh",
            overflow: "auto",
          }}
          style={style}
        >
          {children ? (
            children
          ) : (
            <>
              <Typography id="modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <Typography id="modal-description" sx={{ mt: 2 }}>
                {description}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
      <BasicDialog
        open={dialog}
        setOpen={setDialog}
        onConfirm={handleDialogConfirm}
        onCancel={handleDialogCancel}
      />
    </>
  );
};

BasicModal.defaultProps = {
  title: "Модальное окно",
  description: "",
  safeMode: false,
  onClose: () => {},
};
