import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractRequests } from "../../../redux/actions/contractRequestActions";
import { getRequestStates } from "../../../redux/actions/requestStateActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function RemoveDepartureScheduleRequest({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [name, setName] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [dateOfDeparture, setDateOfDeparture] = useState('');
    const [authorComment, setAuthorComment] = useState('');
    const [performerComment, setPerformerComment] = useState('');
    const [contractRequestId, setContractRequestId] = useState('');
    const [stateId, setStateId] = useState('');
    const { loading, departureScheduleRequests, departureScheduleRequest } = useSelector((state) => state.departureScheduleRequest);
    const { contractRequests, contractRequestRequestedCount } = useSelector((state) => state.contractRequest);
    const { requestStates, requestStateRequestedCount } = useSelector((state) => state.requestState);
    const emptyState = () => {
        setId(null);
        setId('');
        setCreatedAt('');
        setName('');
        setAddressLine('');
        setDateOfDeparture('');
        setAuthorComment('');
        setPerformerComment('');
        setContractRequestId('');
        setStateId('');
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, id, createdAt, name, addressLine, dateOfDeparture, authorComment, performerComment, contractRequestId, stateId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, createdAt, name, addressLine, dateOfDeparture, authorComment, performerComment, contractRequestId, stateId
        } = departureScheduleRequest || {};
        setId(id);
        setId(id);
        setCreatedAt(createdAt);
        setName(name);
        setAddressLine(addressLine);
        setDateOfDeparture(dateOfDeparture);
        setAuthorComment(authorComment);
        setPerformerComment(performerComment);
        setContractRequestId(contractRequestId);
        setStateId(stateId);
    }, [departureScheduleRequest, departureScheduleRequests])
    useEffect(() => {
        if (!open)
        {
            return;
        }
        let response;
        const getContractRequestsAsync = async () => {
            response = await dispatch(getContractRequests({ paging: { skip: 0 } }));
        }
        const getRequestStatesAsync = async () => {
            response = await dispatch(getRequestStates({ paging: { skip: 0 } }));
        }
        if (contractRequestRequestedCount == 0)
        {
            getContractRequestsAsync();
        }
        if (requestStateRequestedCount == 0)
        {
            getRequestStatesAsync();
        }
    }, [dispatch, action, open])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    ID: {id}
                                </Grid>
                                <Grid item xs={6}>
                                    Создано: {createdAt}
                                </Grid>
                                <Grid item xs={6}>
                                    Наименование объекта: {name}
                                </Grid>
                                <Grid item xs={6}>
                                    Адрес: {addressLine}
                                </Grid>
                                <Grid item xs={6}>
                                    Дата выезда: {dateOfDeparture}
                                </Grid>
                                <Grid item xs={6}>
                                    Комментарий автора: {authorComment}
                                </Grid>
                                <Grid item xs={6}>
                                    Комментарий исполнителя: {performerComment}
                                </Grid>
                                <Grid item xs={6}>
                                    Сводная заявка: {contractRequests.find(_ => _.id == contractRequestId)?.number}
                                </Grid>
                                <Grid item xs={6}>
                                    Статус: {requestStates.find(_ => _.id == stateId)?.name}
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
