import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContractRequests } from "../../../redux/actions/contractRequestActions";
import { getReferrals } from "../../../redux/actions/referralActions";
import { getFilials } from "../../../redux/actions/filialActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditClinicalRequest({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [contractRequestId, setContractRequestId] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const [filialId, setFilialId] = useState(null);
    const { loading, clinicalRequests, clinicalRequest } = useSelector((state) => state.clinicalRequest);
    const { contractRequests, contractRequestRequestedCount } = useSelector((state) => state.contractRequest);
    const { referrals, referralRequestedCount } = useSelector((state) => state.referral);
    const { filials, filialRequestedCount } = useSelector((state) => state.filial);
    const emptyState = () => {
        setId(null);
        setContractRequestId(null);
        setReferralId(null);
        setFilialId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, contractRequestId, referralId, filialId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, contractRequestId, referralId, filialId
        } = clinicalRequest || {};
        setId(id);
        setContractRequestId(contractRequestId);
        setReferralId(referralId);
        setFilialId(filialId);
    }, [clinicalRequest, clinicalRequests])
    useEffect(() => {
        if (!open)
        {
            return;
        }
        let response;
        const getContractRequestsAsync = async () => {
            response = await dispatch(getContractRequests({ paging: { skip: 0 } }));
        }
        const getReferralsAsync = async () => {
            response = await dispatch(getReferrals({ paging: { skip: 0 } }));
        }
        const getFilialsAsync = async () => {
            response = await dispatch(getFilials({ paging: { skip: 0 } }));
        }
        if (contractRequestRequestedCount == 0)
        {
            getContractRequestsAsync();
        }
        if (referralRequestedCount == 0)
        {
            getReferralsAsync();
        }
        if (filialRequestedCount == 0)
        {
            getFilialsAsync();
        }
    }, [dispatch, action, open])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField  value={contractRequestId} onChange={(e) => setContractRequestId(e.target.value)} margin="normal" fullWidth id="contractRequestId" select>
                                        {contractRequests && contractRequests.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.number}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField  value={referralId} onChange={(e) => setReferralId(e.target.value)} margin="normal" fullWidth id="referralId" select>
                                        {referrals && referrals.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.number}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField  value={filialId} onChange={(e) => setFilialId(e.target.value)} margin="normal" fullWidth id="filialId" select>
                                        {filials && filials.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
