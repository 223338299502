import { 
    SERVICE_PRICE_LIST_GET_REQUEST, SERVICE_PRICE_LIST_GET_SUCCESS, SERVICE_PRICE_LIST_GET_ERROR, 
    SERVICE_PRICE_LIST_GET_BY_KEY_REQUEST, SERVICE_PRICE_LIST_GET_BY_KEY_SUCCESS, SERVICE_PRICE_LIST_GET_BY_KEY_ERROR, 
    SERVICE_PRICE_LIST_ADD_REQUEST, SERVICE_PRICE_LIST_ADD_SUCCESS, SERVICE_PRICE_LIST_ADD_ERROR, 
    SERVICE_PRICE_LIST_EDIT_REQUEST, SERVICE_PRICE_LIST_EDIT_SUCCESS, SERVICE_PRICE_LIST_EDIT_ERROR, 
    SERVICE_PRICE_LIST_DELETE_REQUEST, SERVICE_PRICE_LIST_DELETE_SUCCESS, SERVICE_PRICE_LIST_DELETE_ERROR
} from "../constants/servicePriceListConstants";

const initialState = {
    servicePriceLists: [],
    servicePriceList: {},
    loading: false,
    success: null,
    error: null,
    servicePriceListRequestedCount: 0,
};

export const servicePriceListReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_PRICE_LIST_GET_REQUEST:
            return {
                ...state,
                servicePriceListRequestedCount: ++state.servicePriceListRequestedCount,
                loading: true,
            };
        case SERVICE_PRICE_LIST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                servicePriceLists: action.payload,
                success: true,
            };
        case SERVICE_PRICE_LIST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                servicePriceList: action.payload,
                success: true,
            };
        case SERVICE_PRICE_LIST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PRICE_LIST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PRICE_LIST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_PRICE_LIST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_PRICE_LIST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_PRICE_LIST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
