import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

DirectionMaterialPDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Table style={styles.table}>
          <Row>
            <Cell></Cell>
            <Cell>
              <Text>Нысанның БҚСЖ бойынша коды</Text>
              <Text>Код формы по ОКУД ___________________</Text>
              <Text>КҰЖЖ бойынша ұйым коды</Text>
              <Text>Код организации по ОКПО _____________</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрлігі Министерство здравоохранения Республики Казахстан</Text>
            </Cell>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы "__" __________ № бұйрығымен бекітілген №____017/е нысанды медициналық құжаттама</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк органының атауы Наименование государственного органа санитарно-эпидемиологической службы</Text>
            </Cell>
            <Cell>
              <Text>Медицинская документация Форма № 017/у Утверждена приказом Министра здравоохранения Республики Казахстан от "__" ______ 2021 года №______</Text>
            </Cell>
          </Row>
        </Table>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {actData?.dateOfDirection?.value ? formatDate(new Date(actData.dateOfDirection.value)) : ' '}</Text>
        </View>
        <Table style={{
          fontSize: 10,
          fontFamily: 'Times New Roman',
          marginTop: 5
        }}>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Облысы, ауданы/қаласы (Область, район/город):
              </Text>
              <Text>
                {actData?.location?.value || ' '}
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Науқастың эпиднөмірі(Эпидномер больного):
              </Text>
              <Text>
                {actData?.epidNumber?.value || ' '}
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Күні (Дата):
              </Text>
              <Text>
                {actData?.date?.value ? formatDate(new Date(actData?.date.value)) : ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Аурудың Т.А.Ә (болған жағдайда): Ф.И.О (при наличии) больного:
              </Text>
              <Text>
                {actData?.sickName?.value || ' '}
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Кабат (Пол):
              </Text>
              <Text>
                {actData?.sex?.value || ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Туылған күні(Дата рождения):
              </Text>
              <Text>
                {actData?.birthday?.value ? formatDate(new Date(actData?.birthday.value)) : ' '}
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Жасы (Возраст):
              </Text>
              <Text>
                {actData?.age?.value || ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Кәмелеттік жасқа толмаған балалар үшін анасының /әкесінің/қамқоршысының Т.А.Ә. (болған жағдайда) Ф.И.О.(при наличии) матери/отца/опекуна для несовершеннолетних детей:
              </Text>
              <Text>
                {actData?.parent?.value || ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Мекен-жайы (Адрес):
              </Text>
              <Text>
                {actData?.adress?.value || ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Қызылшаға, қызамыққа қарсы екпелер саны(Количество вакцинаций против кори, краснухи):
              </Text>
              <Text>
                {actData?.vaccinationsQuantity?.value || ' '}
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Соңғы екпе егілген күні(Дата последней вакцинации):
              </Text>
              <Text>
                {actData?.lastVaccinationDate?.value ? formatDate(new Date(actData?.lastVaccinationDate.value)) : ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Дене қызуының көтерілген күні(Дата повышения температуры):
              </Text>
              <Text>
                {actData?.temperatureRiseDate?.value ? formatDate(new Date(actData?.temperatureRiseDate.value)) : ' '}
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Бөртпе пайда болған күні (Дата появления сыпи):
              </Text>
              <Text>
                {actData?.emergenceRashDate?.value ? formatDate(new Date(actData?.emergenceRashDate.value)) : ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Бөртпенің түрі(Тип сыпи):
              </Text>
              <Text>
                {actData?.typeOfRash?.value || ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Алдын ала қойылған клиникалық диагнозы(Предварительный клинический диагноз):
              </Text>
              <Text>
                {actData?.diagnosis?.value || ' '}
              </Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
              Зерттеу нәтижелерін мына қызметкерге Т.А.Ә.(болған жағдайда) жіберу керек(Ф.И.О. (при наличии) сотрудника, которому должны быть отправлены результаты исследования):
              </Text>
              <Text>
                {actData?.recipient?.value || ' '}
              </Text>
            </Cell>
          </Row>
        </Table>
        <Table style={{
          fontSize: 10,
          fontFamily: 'Times New Roman'
        }}>
          <Row>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Үлгісі (Образец)
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Талдау алынған күні (Дата сбора)
              </Text>
            </Cell>
            <Cell>
              <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                Жіберілген күні (Дата отправки)
              </Text>
            </Cell>
          </Row>
        </Table>
        <Table style={{
          fontSize: 10,
          fontFamily: 'Times New Roman',
          marginBottom: 5
        }}>
          {actData?.samples?.value.map(el => {
            return <Row>
              <Cell>
                <Text>
                  {el.sample?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.selectionDate?.value ? formatDate(new Date(el.selectionDate.value)) : ' '}
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.sendingDate?.value ? formatDate(new Date(el.sendingDate.value)) : ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зерттеу жүргiзген маманның Т.А.Ә.(болған жағдайда), лауазымы (Ф.И.О.(при наличии), должность специалиста проводившего исследование): {data.performer?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зертхана меңгерушісінің Т.А.Ә. (болған жағдайда), қолы. (Ф.И.О.(при наличии), подпись заведующего лабораторией): {data.responsible?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
          <Text style={styles.string}>Направление составлено в экземплярах(кол-во) _____</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function DirectionMaterialPDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
