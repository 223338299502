import { 
    WITHOUT_DEPARTURE_REQUEST_GET_REQUEST, WITHOUT_DEPARTURE_REQUEST_GET_SUCCESS, WITHOUT_DEPARTURE_REQUEST_GET_ERROR, 
    WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_REQUEST, WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_SUCCESS, WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_ERROR, 
    WITHOUT_DEPARTURE_REQUEST_ADD_REQUEST, WITHOUT_DEPARTURE_REQUEST_ADD_SUCCESS, WITHOUT_DEPARTURE_REQUEST_ADD_ERROR, 
    WITHOUT_DEPARTURE_REQUEST_EDIT_REQUEST, WITHOUT_DEPARTURE_REQUEST_EDIT_SUCCESS, WITHOUT_DEPARTURE_REQUEST_EDIT_ERROR, 
    WITHOUT_DEPARTURE_REQUEST_DELETE_REQUEST, WITHOUT_DEPARTURE_REQUEST_DELETE_SUCCESS, WITHOUT_DEPARTURE_REQUEST_DELETE_ERROR
} from "../constants/withoutDepartureRequestConstants";

const initialState = {
    withoutDepartureRequests: [],
    withoutDepartureRequest: {},
    loading: false,
    success: null,
    error: null,
    withoutDepartureRequestRequestedCount: 0,
};

export const withoutDepartureRequestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case WITHOUT_DEPARTURE_REQUEST_GET_REQUEST:
            return {
                ...state,
                withoutDepartureRequestRequestedCount: ++state.withoutDepartureRequestRequestedCount,
                loading: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                withoutDepartureRequests: action.payload,
                success: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                withoutDepartureRequest: action.payload,
                success: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case WITHOUT_DEPARTURE_REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case WITHOUT_DEPARTURE_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case WITHOUT_DEPARTURE_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case WITHOUT_DEPARTURE_REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
