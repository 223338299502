/* eslint-disable import/no-anonymous-default-export */
export default {
  '29': {
    version: 2,
    name: 'ПРОТОКОЛ бактериологического исследования фекалий',
    kazakhName: 'Нәжісті бактериологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество обследуемого',
        kazakhLabel: 'Тексерілушінің тегі, аты, әкесінің аты'
      },
      {
        id: 'first_or_repeated',
        type: 'switch',
        label: 'Исследование первичное, повторное',
        kazakhLabel: 'Зерттеу алғашқы, қайталанып',
        options: [
          'первичное',
          'повторное'
        ]
      },
      {
        id: 'med_organization',
        type: 'text',
        label: 'Медицинская организация',
        kazakhLabel: 'Медициналық ұйым'
      },
      {
        id: 'sample_obtain_date',
        type: 'datetime',
        label: 'Дата поступления образца',
        kazakhLabel: 'Үлгінің келіп түскен күні'
      },
      {
        id: 'research_end_date',
        type: 'datetime',
        label: 'Дата окончания исследования',
        kazakhLabel: 'Зерттеу аяқталған күн'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама нөмірі'
      },      
      {
        id: 'production_expiration_dates',
        type: 'date',
        label: 'Дата выработки / срок годности',
        kazakhLabel: 'Өндірілген мерзімі / жарамдылық мерзімі'
      },      
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1037 style='border-collapse:
        collapse;table-layout:fixed;width:778pt'>
        <col width=64 span=2 style='width:48pt'>
        <col width=441 style='mso-width-source:userset;mso-width-alt:15672;width:331pt'>
        <col width=64 style='width:48pt'>
        <col width=340 style='mso-width-source:userset;mso-width-alt:12088;width:255pt'>
        <col width=64 style='width:48pt'>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1523353 width=64 style='height:26.4pt;width:48pt'></td>
         <td rowspan=4 class=xl7123353 width=64 style='width:48pt'>№</td>
         <td rowspan=4 class=xl7223353 width=441 style='width:331pt'>Микрофлора</td>
         <td colspan=2 class=xl7223353 width=404 style='border-left:none;width:303pt'>1
         граммда&#1171;ы нормасы</td>
         <td class=xl6623353 width=64 style='border-left:none;width:48pt'>Зерттеу
         н&#1241;тижесі</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1523353 style='height:52.8pt'></td>
         <td colspan=2 class=xl6523353 width=404 style='border-left:none;width:303pt'>(Норма
         в 1 грамме)</td>
         <td class=xl6823353 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Результат исследования)</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
         <td height=53 class=xl1523353 style='height:39.6pt'></td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>1 жас&#1179;а дейін балалар</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>Ересектер</td>
         <td class=xl6923353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1523353 style='height:26.4pt'></td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Дети до года)</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>(Взрослые)</td>
         <td class=xl6923353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td rowspan=2 class=xl6723353 width=64 style='border-top:none;width:48pt'>1.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Ішек т&#1201;&#1179;ымдасты&#1171;ыны&#1187; патогенді
         микробтары</td>
         <td rowspan=2 class=xl6523353 width=64 style='border-top:none;width:48pt'>0</td>
         <td rowspan=2 class=xl6523353 width=340 style='border-top:none;width:255pt'>0</td>
         <td rowspan=2 class=xl7023353 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>(Патогенные микробы семейства кишечных)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>2.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Бифидобактериялар (Бифидобактерии)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>109-1010</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>108-109</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>3.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Лактобактериялар (Лактобактерии)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>106-107</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>107-108</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1523353 style='height:26.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>4.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>&#1178;алыпты ферментативтік активті ішек тая&#1179;шасы
         (Кишечная палочка с нормальной фермен. активностью)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>107-108</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>107-108</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1523353 style='height:26.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>5.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Ферментативтік активтілігі т&#1257;мен ішек тая&#1179;шасы
         (Кишечная палочка со сниженной фермент.активностью)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>105-107</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>106-107</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td rowspan=2 class=xl6723353 width=64 style='border-top:none;width:48pt'>6.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Лактоза негативтік ішек тая&#1179;шасы</td>
         <td rowspan=2 class=xl6523353 width=64 style='border-top:none;width:48pt'>&lt;
         104</td>
         <td rowspan=2 class=xl6523353 width=340 style='border-top:none;width:255pt'>&lt;
         105</td>
         <td rowspan=2 class=xl7023353 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>(Лактозонегативная кишечная палочка)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td rowspan=2 class=xl6723353 width=64 style='border-top:none;width:48pt'>7.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Гемолиз тудырушы ішек тая&#1179;шасы</td>
         <td rowspan=2 class=xl6523353 width=64 style='border-top:none;width:48pt'>&lt;
         104</td>
         <td rowspan=2 class=xl6523353 width=340 style='border-top:none;width:255pt'>&lt;
         104</td>
         <td rowspan=2 class=xl7023353 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>(Гемолитически активная кишечная палочка)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>8.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Протей</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&lt; 104</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>&lt; 104</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td rowspan=2 class=xl6723353 width=64 style='border-top:none;width:48pt'>9.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Шартты патогендік энтеробактериялар</td>
         <td rowspan=2 class=xl6523353 width=64 style='border-top:none;width:48pt'>&lt;
         104</td>
         <td rowspan=2 class=xl6523353 width=340 style='border-top:none;width:255pt'>&lt;
         105</td>
         <td rowspan=2 class=xl7023353 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>(Условно-патогенные энтеробактерии)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>10.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Патогенді стафилококк (Патогенный стафилококк)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&lt; 101</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>&lt; 103</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>11.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Бас&#1179;а стафилококктар (Другие стафилококки)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&lt; 105</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>&lt; 104</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>12.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Энтерококктар (Энтерококки)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>106-107</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>105-106</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>13.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Клостридиялар (Клостридии)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&lt; 101</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>&lt; 105</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td rowspan=2 class=xl6723353 width=64 style='border-top:none;width:48pt'>14.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>Кандида т&#1201;&#1179;ымдас санырау&#1179;&#1201;ла&#1179;тар</td>
         <td rowspan=2 class=xl6523353 width=64 style='border-top:none;width:48pt'>&lt;
         103</td>
         <td rowspan=2 class=xl6523353 width=340 style='border-top:none;width:255pt'>&lt;
         105</td>
         <td rowspan=2 class=xl7023353 width=64 style='border-top:none;width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>(Грибы рода кандида)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1523353 style='height:14.4pt'></td>
         <td class=xl6723353 width=64 style='border-top:none;width:48pt'>15.</td>
         <td class=xl6523353 width=441 style='border-top:none;border-left:none;
         width:331pt'>ТГТБ (НГОБ)</td>
         <td class=xl6523353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&lt; 103</td>
         <td class=xl6523353 width=340 style='border-top:none;border-left:none;
         width:255pt'>&lt; 103</td>
         <td class=xl7023353 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="37"><col width="455"><col width="87"><col width="128"><col width="135"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="2" width="37" height="129" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="455" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Микрофлора</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="215" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1 граммдағы нормасы<br>(Норма в 1 грамме)1 граммдағы нормасы<br>(Норма в 1 грамме)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" width="135" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижесі<br>(Результат исследования)Зерттеу нәтижесі<br>(Результат исследования)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1 жасқа дейін балалар(Дети до года)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ересектер<br>(Взрослые)Ересектер<br>(Взрослые)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ішек тұқымдастығының патогенді микробтары (Патогенные микробы семейства кишечных)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="0" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">0</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="0" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">0</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бифидобактериялар (Бифидобактерии)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">109-1010</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">108-109</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Лактобактериялар (Лактобактерии)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">106-107</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">107-108</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қалыпты ферментативтік активті ішек таяқшасы (Кишечная палочка с нормальной фермен. активностью)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">107-108</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">107-108</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ферментативтік активтілігі төмен ішек таяқшасы (Кишечная палочка со сниженной фермент.активностью)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">105-107</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">106-107</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Лактоза негативтік ішек таяқшасы<br>(Лактозонегативная кишечная палочка)Лактоза негативтік ішек таяқшасы<br>(Лактозонегативная кишечная палочка)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 105</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Гемолиз тудырушы ішек таяқшасы <br>(Гемолитически активная кишечная палочка)Гемолиз тудырушы ішек таяқшасы <br>(Гемолитически активная кишечная палочка)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Протей</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шартты патогендік энтеробактериялар <br>(Условно-патогенные энтеробактерии)Шартты патогендік энтеробактериялар <br>(Условно-патогенные энтеробактерии)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 105</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Патогенді стафилококк (Патогенный стафилококк)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 101</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 103</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Басқа стафилококктар (Другие стафилококки)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 105</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 104</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Энтерококктар (Энтерококки)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">106-107</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">105-106</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Клостридиялар (Клостридии)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 101</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 105</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кандида тұқымдас санырауқұлақтар (Грибы рода кандида)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 103</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 105</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ТГТБ (НГОБ)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 103</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&lt; 103</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '30': {
    version:2,
    name: 'ПРОТОКОЛ исследования образца переработки нефтепродуктов и специальных жидкостей',
    kazakhName: 'Мұнай өнімдерін және арнайы сұйықтықтарды қайта өңдеу үлгісін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, место отбора',
        kazakhLabel: 'Объектінің атауы, алу орны'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'producer',
        type: 'text',
        label: 'Страна (фирма) изготовитель (поставщик))',
        kazakhLabel: 'Өндіруші ел (жеткізуші)'
      },
      {
        id: 'obtain_expiration_dates',
        type: 'date',
        label: 'Дата получения образца, срок хранения',
        kazakhLabel: 'Үлгіні алу күні, сақтау мерзімі'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Сынама алу әдiсiне қолданылған нормативтік құжат (НҚ)'
      },
      {
        id: 'selection_act',
        type: 'text',
        label: 'Акт отбора образца',
        kazakhLabel: 'Үлгіні алу актісі'
      },
      {
        id: 'sample_description',
        type: 'text',
        label: 'Описание образца',
        kazakhLabel: 'Үлгінің сипаттамасы'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'production_expiration_dates',
        type: 'date',
        label: 'Дата выработки / срок годности',
        kazakhLabel: 'Өндірілген мерзімі / жарамдылық мерзімі'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама нөмірі'
      },
      {
        id: 'research_start_end_dates',
        type: 'datetime',
        label: 'Дата начала исследования',
        kazakhLabel: 'Зерттеудің басталу күні'
      },
      {
        id: 'research_end_dates',
        type: 'datetime',
        label: 'Дата окончания исследования',
        kazakhLabel: 'Зерттеудің аяқталу күні'
      },      
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=735 style='border-collapse:
        collapse;table-layout:fixed;width:551pt'>
        <col width=64 style='width:48pt'>
        <col width=215 style='mso-width-source:userset;mso-width-alt:7651;width:161pt'>
        <col width=152 style='mso-width-source:userset;mso-width-alt:5404;width:114pt'>
        <col width=240 style='mso-width-source:userset;mso-width-alt:8533;width:180pt'>
        <col width=64 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1512044 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl6312044 width=215 style='width:161pt'>К&#1257;рсеткіш атауы</td>
         <td class=xl6312044 width=152 style='border-left:none;width:114pt'>Аны&#1179;тал&#1171;ан
         концентрация</td>
         <td class=xl6312044 width=240 style='border-left:none;width:180pt'>Нормативтік
         к&#1257;рсеткіш</td>
         <td class=xl6312044 width=64 style='border-left:none;width:48pt'>Зерттеулер
         &#1241;дістеріне Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1512044 style='height:52.8pt'></td>
         <td class=xl6312044 width=215 style='border-top:none;width:161pt'>(Наименование
         показателя)</td>
         <td class=xl6312044 width=152 style='border-top:none;border-left:none;
         width:114pt'>(Обнаруженная концентрация)</td>
         <td class=xl6312044 width=240 style='border-top:none;border-left:none;
         width:180pt'>(Нормативные показатели)</td>
         <td class=xl6312044 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на методы исследований)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1512044 style='height:14.4pt'></td>
         <td class=xl6312044 width=215 style='border-top:none;width:161pt'>1</td>
         <td class=xl6312044 width=152 style='border-top:none;border-left:none;
         width:114pt'>2</td>
         <td class=xl6312044 width=240 style='border-top:none;border-left:none;
         width:180pt'>3</td>
         <td class=xl6312044 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1512044 style='height:14.4pt'></td>
         <td class=xl6412044 width=215 style='border-top:none;width:161pt'>&nbsp;</td>
         <td class=xl6412044 width=152 style='border-top:none;border-left:none;
         width:114pt'>&nbsp;</td>
         <td class=xl6412044 width=240 style='border-top:none;border-left:none;
         width:180pt'>&nbsp;</td>
         <td class=xl6412044 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="4" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="188"><col width="177"><col width="183"><col width="187"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="188" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіш атауы <br>(Наименование показателя)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="177" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған концентрация <br>(Обнаруженная концентрация)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="183" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативтік көрсеткіш<br>(Нормативные показатели)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="187" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеулер әдістеріне НҚ (НД на методы исследований)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '31': {
    version: 2,
    name: 'ПРОТОКОЛ исследования сырья и пищевых продуктов на молекулярно-генетические испытания',
    kazakhName: 'Шикізат пен тамақ өнімдерін молекулярлық-генетикалық сынақтарға зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгі атауы'
      },
      {
        id: 'amount',
        type: 'text',
        label: 'Количество (масса) пробы на испытание',
        kazakhLabel: 'Зерттеуге алынған үлгінің саны (мөлшері)'
      },
      {
        id: 'partion_number_and_pack_type',
        type: 'text',
        label: 'Номер партии, вид упаковки',
        kazakhLabel: 'Партияның нөмірі, сыртқы ораманың түрі'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Сынаманы алған күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партии',
        kazakhLabel: 'Топтама нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },      
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізу күні мен уақыты'
      },           
      {
        id: 'research_datetime',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },      
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне НҚ'
      },      
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'production_and_expiration_date',
        type: 'date',
        label: 'Дата выработки, срок хранения',
        kazakhLabel: 'Жасалған күні, сақтау мерзімі'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод испытаний',
        kazakhLabel: 'Зерттеу әдісіне қолданылған нормативтік құжат (НҚ)'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1204 style='border-collapse:
        collapse;table-layout:fixed;width:903pt'>
        <col width=64 style='width:48pt'>
        <col width=291 style='mso-width-source:userset;mso-width-alt:10353;width:218pt'>
        <col width=153 style='mso-width-source:userset;mso-width-alt:5432;width:115pt'>
        <col width=376 style='mso-width-source:userset;mso-width-alt:13368;width:282pt'>
        <col width=64 span=5 style='width:48pt'>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1512672 width=64 style='height:79.2pt;width:48pt'></td>
         <td class=xl6612672 width=291 style='width:218pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl6712672 width=153 style='border-left:none;width:115pt'>&#1198;лгіні&#1187;
         атауы, Объектіні&#1187; атауы</td>
         <td class=xl6712672 width=376 style='border-left:none;width:282pt'>&#1198;лгіні&#1187;
         н&#1257;мірі</td>
         <td class=xl6712672 width=64 style='border-left:none;width:48pt'>Молекулярлы&#1179;-генетикалы&#1179;
         к&#1257;рсеткіштер</td>
         <td colspan=2 class=xl6712672 width=128 style='border-left:none;width:96pt'>Зерттеу
         н&#1241;тижелері</td>
         <td class=xl6712672 width=64 style='border-left:none;width:48pt'>Нормаланатын
         к&#1257;рсеткіш</td>
         <td class=xl6812672 width=64 style='border-left:none;width:48pt'>Н&#1178; –
         &#1241;дісіне зерттеу</td>
        </tr>
        <tr height=106 style='height:79.2pt'>
         <td height=106 class=xl1512672 style='height:79.2pt'></td>
         <td class=xl6912672 width=291 style='border-top:none;width:218pt'>(Регистрационный
         номер)</td>
         <td class=xl6512672 width=153 style='border-top:none;border-left:none;
         width:115pt'>(Наименование образца, наименование объекта)</td>
         <td class=xl6512672 width=376 style='border-top:none;border-left:none;
         width:282pt'>(Номер образца)</td>
         <td class=xl6512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Молекулярно-генетические показатели)</td>
         <td colspan=2 class=xl6512672 width=128 style='border-left:none;width:96pt'>(Результаты
         исследований)</td>
         <td class=xl6512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Нормируемый показатель)</td>
         <td class=xl7012672 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
         <td height=53 class=xl1512672 style='height:39.6pt'></td>
         <td class=xl7612672 width=291 style='border-top:none;width:218pt'>&nbsp;</td>
         <td class=xl7412672 width=153 style='border-top:none;border-left:none;
         width:115pt'>&nbsp;</td>
         <td class=xl7412672 width=376 style='border-top:none;border-left:none;
         width:282pt'>&nbsp;</td>
         <td class=xl7412672 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>Сапалы&#1179; к&#1257;рсеткіш</td>
         <td class=xl6512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>Санды&#1179; к&#1257;рсеткіш</td>
         <td class=xl7412672 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7012672 width=64 style='border-top:none;border-left:none;
         width:48pt'>Метод испытания)</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1512672 style='height:52.8pt'></td>
         <td class=xl7612672 width=291 style='border-top:none;width:218pt'>&nbsp;</td>
         <td class=xl7412672 width=153 style='border-top:none;border-left:none;
         width:115pt'>&nbsp;</td>
         <td class=xl7412672 width=376 style='border-top:none;border-left:none;
         width:282pt'>&nbsp;</td>
         <td class=xl7412672 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl6512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Качественный показатель)</td>
         <td class=xl6512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Количественный показатель)</td>
         <td class=xl7412672 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl7512672 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1512672 style='height:15.0pt'></td>
         <td class=xl7112672 width=291 style='border-top:none;width:218pt'>1</td>
         <td class=xl7212672 width=153 style='border-top:none;border-left:none;
         width:115pt'>2</td>
         <td class=xl7212672 width=376 style='border-top:none;border-left:none;
         width:282pt'>3</td>
         <td class=xl7212672 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl7212672 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl7212672 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
         <td class=xl7212672 width=64 style='border-top:none;border-left:none;
         width:48pt'>7</td>
         <td class=xl7312672 width=64 style='border-top:none;border-left:none;
         width:48pt'>8</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="8" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="171"><col width="157"><col width="122"><col width="132"><col width="91"><col width="73"><col width="104"><col width="106"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="2" width="171" height="122" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Тіркеу нөмірі<br>(Регистрационный номер)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="157" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Үлгінің атауы, <br>Объектінің атауы<br>(Наименование образца, наименование объекта)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="122" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Үлгінің нөмірі<br>(Номер образца)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="132" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Молекулярлық-генетикалық көрсеткіштер<br>(Молекулярно-генетические показатели)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="165" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Зерттеу нәтижелері<br>(Результаты исследований)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="104" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Нормаланатын көрсеткіш<br>(Нормируемый показатель)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" width="106" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">НҚ – әдісіне зерттеу (НД на метод испытания)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Сапалық көрсеткіш<br>(Качественный показатель)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Сандық көрсеткіш<br>(Количественный показатель)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="19" align="JUSTIFY" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="JUSTIFY" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">8</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '32': {
    version: 2,
    name: 'ПРОТОКОЛ исследования пищевых продуктов на молекулярно-генетические испытания методом полимеразно-цепной реакций (ПЦР) и иммуноферментный анализ',
    kazakhName: 'Тамақ өнімдерін молекулярлық-генетикалық сынақтарға полимеразды-тізбекті реакция (ПТР) әдісімен зерттеу және иммудық ферментті талдау (ИФТ) ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'mass',
        type: 'text',
        label: 'Количество (масса) пробы на испытание',
        kazakhLabel: 'Зерттелетін сынамалар саны (мөлшері)'
      },
      {
        id: "instance_number",
        type: 'text',
        label: 'номер партии, вид упаковки',
        kazakhLabel: 'Партияның нөмері, ораманың түрі'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгіні зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Сынама алынған күні мен уақыты'
      },
      {
        id: "instance_number_2",
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне қолданылған нормативтік құжат'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'production_expiration_date',
        type: 'date',
        label: 'Дата выработки, срок хранения',
        kazakhLabel: 'Жасалған күні, сақтау мерзімі'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1046 style='border-collapse:
        collapse;table-layout:fixed;width:785pt'>
        <col width=64 style='width:48pt'>
        <col width=290 style='mso-width-source:userset;mso-width-alt:10296;width:217pt'>
        <col width=178 style='mso-width-source:userset;mso-width-alt:6343;width:134pt'>
        <col width=322 style='mso-width-source:userset;mso-width-alt:11463;width:242pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl156952 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl666952 width=290 style='width:217pt'>Тіркеу н&#1257;мірі</td>
         <td class=xl676952 width=178 style='border-left:none;width:134pt'>&#1198;лгіні&#1187;
         н&#1257;мірі</td>
         <td class=xl676952 width=322 style='border-left:none;width:242pt'>К&#1257;рсеткіштер</td>
         <td class=xl676952 width=64 style='border-left:none;width:48pt'>Зерттеу
         н&#1241;тижелері</td>
         <td class=xl676952 width=64 style='border-left:none;width:48pt'>Нормаланатын
         к&#1257;рсеткіш</td>
         <td class=xl686952 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дiсiне &#1179;олданыл&#1171;ан Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl156952 style='height:52.8pt'></td>
         <td class=xl696952 width=290 style='border-top:none;width:217pt'>(Регистрационный
         номер)</td>
         <td class=xl656952 width=178 style='border-top:none;border-left:none;
         width:134pt'>(Номер образца)</td>
         <td class=xl656952 width=322 style='border-top:none;border-left:none;
         width:242pt'>(Показатели)</td>
         <td class=xl656952 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Результаты исследований)</td>
         <td class=xl656952 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Нормируемый показатель)</td>
         <td class=xl706952 width=64 style='border-top:none;border-left:none;
         width:48pt'>(НД на метод испытания)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl156952 style='height:15.0pt'></td>
         <td class=xl716952 width=290 style='border-top:none;width:217pt'>1</td>
         <td class=xl726952 width=178 style='border-top:none;border-left:none;
         width:134pt'>2</td>
         <td class=xl726952 width=322 style='border-top:none;border-left:none;
         width:242pt'>3</td>
         <td class=xl726952 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl726952 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl736952 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="156"><col width="107"><col width="107"><col width="119"><col width="119"><col width="114"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="156" height="96" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>(Регистрационный номер)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің нөмірі<br>(Номер образца)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштер<br>(Показатели)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="119" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижелері<br>(Результаты исследований)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="119" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормаланатын көрсеткіш<br>(Нормируемый показатель)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="114" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдiсiне қолданылған НҚ<br>(НД на метод испытания)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '33': {
    version: 2,
    name: 'ПРОТОКОЛ микробиологического исследования на зоонозные и особо опасные инфекции',
    kazakhName: 'Зоонозды және аса қауіпті инфекцияларға микробиологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'organization_name',
        type: 'text',
        label: 'Наименование учреждений получающий результат исследования образца',
        kazakhLabel: 'Үлгілердің зерттеу нәтижесін алатын мекеме атауы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Сынама алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'prob_amount',
        type: 'number',
        label: 'количество проб',
        kazakhLabel: 'Сынамалар саны'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) на метод отбора',
        kazakhLabel: 'Сынама алу әдiсiне қолданылған нормативтік құжат (НҚ)'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      // {
      //   id: 'measurement_results',
      //   type: 'text',
      //   label: 'Результаты измерений',
      //   kazakhLabel: 'Өлшеулер нәтижелері'
      // },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=990 style='border-collapse:
        collapse;table-layout:fixed;width:742pt'>
        <col width=64 style='width:48pt'>
        <col width=232 style='mso-width-source:userset;mso-width-alt:8248;width:174pt'>
        <col width=200 style='mso-width-source:userset;mso-width-alt:7111;width:150pt'>
        <col width=366 style='mso-width-source:userset;mso-width-alt:12999;width:274pt'>
        <col width=64 span=2 style='width:48pt'>
        <tr height=35 style='height:26.4pt'>
         <td height=35 class=xl1515117 width=64 style='height:26.4pt;width:48pt'></td>
         <td class=xl6615117 width=232 style='width:174pt'>&#1198;лгілер н&#1257;мірi</td>
         <td class=xl6715117 width=200 style='border-left:none;width:150pt'>Алын&#1171;ан
         &#1199;лгілер атауы, &#1199;лгі алын&#1171;ан орын ж&#1241;не н&#1199;ктелер</td>
         <td class=xl6715117 width=366 style='border-left:none;width:274pt'>Зерттеу
         &#1241;дістері Реакция т&#1199;рі</td>
         <td class=xl6715117 width=64 style='border-left:none;width:48pt'>Зерттеу
         ма&#1179;саты</td>
         <td class=xl6815117 width=64 style='border-left:none;width:48pt'>Зерттеу
         н&#1241;тижесі</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1515117 style='height:52.8pt'></td>
         <td class=xl6915117 width=232 style='border-top:none;width:174pt'>(Номер
         образца)</td>
         <td class=xl6515117 width=200 style='border-top:none;border-left:none;
         width:150pt'>(Наименование отобранных образцов, место и точки отбора)</td>
         <td class=xl6515117 width=366 style='border-top:none;border-left:none;
         width:274pt'>(Методы исследования Вид реакции)</td>
         <td class=xl6515117 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Цель исследования)</td>
         <td class=xl7015117 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Результаты исследования)</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1515117 style='height:15.0pt'></td>
         <td class=xl7115117 width=232 style='border-top:none;width:174pt'>1</td>
         <td class=xl7215117 width=200 style='border-top:none;border-left:none;
         width:150pt'>2</td>
         <td class=xl7215117 width=366 style='border-top:none;border-left:none;
         width:274pt'>3</td>
         <td class=xl7215117 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl7315117 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="114"><col width="206"><col width="184"><col width="120"><col width="113"></colgroup>
        <tbody>          
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгілер нөмірi<br>(Номер образца)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Алынған үлгілер атауы, үлгі алынған орын және нүктелер<br>(Наименование отобранных образцов, место и точки отбора)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістері <br>Реакция түрі<br>(Методы исследования <br>Вид реакции)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу мақсаты<br>(Цель исследования)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижесі<br>(Результаты исследования)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '34': {
    version:2,
    name: 'ПРОТОКОЛ исследования образца полимерных и других материалов, изделий из них, химических веществ и композиций',
    kazakhName: 'Полимерлік және басқа материалдардың, олардан жасалған бұйымдардың, химиялық заттар мен композициялардың үлгілерін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование изделия',
        kazakhLabel: 'Бұйымның атауы'
      },
      {
        id: 'producer',
        type: 'text',
        label: 'Страна фирма - изготовитель поставщик',
        kazakhLabel: 'Өндіруші ел фирма жеткізуші'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ на материалы, из которого выполнено изделие',
        kazakhLabel: 'Бұйымдар жасалған материалға нормативтік құжат'
      },
      {
        id: 'research_start',
        type: 'date',
        label: 'Дата начала исследования',
        kazakhLabel: 'Зерттеудің басталу күні'
      },
      {
        id: 'research_end',
        type: 'date',
        label: 'Дата окончания исследования',
        kazakhLabel: 'Зерттеудің аяқталуы күні',
        required: false
      },
      {
        id: 'sample_description',
        type: 'text',
        label: 'Описание образца',
        kazakhLabel: 'Үлгінің жазбаша сипаты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Партия нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі',
        required: false
      },
      {
        id: 'expiration_date',
        type: 'text',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'components_migration',
        type: 'text',
        label: 'Миграция ингредиентов в объекты окружающей среды: воздух, вода, модельная среда',
        kazakhLabel: 'Ингредиенттердің қоршаған орта нысандарына көшуі ауа, су, үлгі ортасы'
      },
      // {
      //   id: 'nd_selection_method_organoleptic',
      //   type: 'text',
      //   label: 'НД на методы исследований Органолептические',
      //   kazakhLabel: 'Зерттеулер әдістеріне НҚ'
      // },
      // {
      //   id: 'nd_selection_method_physicochemical',
      //   type: 'text',
      //   label: 'НД на методы исследований Физико-химические',
      //   kazakhLabel: 'Зерттеулер әдістеріне НҚ'
      // },
      // {
      //   id: 'nd_selection_method_toxic',
      //   type: 'text',
      //   label: 'НД на методы исследований Токсикологические',
      //   kazakhLabel: 'Зерттеулер әдістеріне НҚ'
      // },
      // {
      //   id: 'research_on_nd',
      //   type: 'text',
      //   label: 'Исследование проб проводились на соответствие НД',
      //   kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестігіне зерттеулер жүргізілді'
      // },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=906 style='border-collapse:
        collapse;table-layout:fixed;width:679pt'>
        <col width=64 style='width:48pt'>
        <col width=251 style='mso-width-source:userset;mso-width-alt:8931;width:188pt'>
        <col width=64 style='width:48pt'>
        <col width=399 style='mso-width-source:userset;mso-width-alt:14193;width:299pt'>
        <col width=64 span=2 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl154254 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl694254 width=251 style='width:188pt'>Ингредиентер
         к&#1257;рсеткішіні&#1187; атауы</td>
         <td class=xl744254 width=64 style='border-left:none;width:48pt'>&#1198;лгі
         ортасы</td>
         <td class=xl744254 width=399 style='border-left:none;width:299pt'>Аны&#1179;тал&#1171;ан
         концентрация</td>
         <td class=xl744254 width=64 style='border-left:none;width:48pt'>Шектелген
         концентрация</td>
         <td class=xl634254 width=64 style='border-left:none;width:48pt'>Зерттеулер
         &#1241;дістеріне Н&#1178;</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl154254 style='height:52.8pt'></td>
         <td class=xl704254 width=251 style='border-top:none;width:188pt'>(Наименование
         показателя ингредиентов)</td>
         <td class=xl664254 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Модельная среда)</td>
         <td class=xl664254 width=399 style='border-top:none;border-left:none;
         width:299pt'>(параметрлері, уыттылы&#1171;ы токсичности)</td>
         <td class=xl664254 width=64 style='border-top:none;border-left:none;
         width:48pt'>(Допустимая концентрация)</td>
         <td class=xl644254 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на методы исследований</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl154254 style='height:14.4pt'></td>
         <td class=xl754254 width=251 style='border-top:none;width:188pt'>&nbsp;</td>
         <td class=xl674254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl664254 width=399 style='border-top:none;border-left:none;
         width:299pt'>Обнаруженная концентрация (параметры токсичности)</td>
         <td class=xl674254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl714254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl154254 style='height:14.4pt'></td>
         <td class=xl704254 width=251 style='border-top:none;width:188pt'>1</td>
         <td class=xl664254 width=64 style='border-top:none;border-left:none;
         width:48pt'>2</td>
         <td class=xl664254 width=399 style='border-top:none;border-left:none;
         width:299pt'>3</td>
         <td class=xl664254 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl644254 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl154254 style='height:14.4pt'></td>
         <td class=xl704254 width=251 style='border-top:none;width:188pt'>Органолептические:</td>
         <td class=xl684254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl684254 width=399 style='border-top:none;border-left:none;
         width:299pt'>&nbsp;</td>
         <td class=xl684254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl764254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl154254 style='height:14.4pt'></td>
         <td class=xl704254 width=251 style='border-top:none;width:188pt'>Физико-химические:</td>
         <td class=xl684254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl684254 width=399 style='border-top:none;border-left:none;
         width:299pt'>&nbsp;</td>
         <td class=xl684254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl764254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl154254 style='height:15.0pt'></td>
         <td class=xl654254 width=251 style='border-top:none;width:188pt'>Токсикологические:</td>
         <td class=xl724254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl724254 width=399 style='border-top:none;border-left:none;
         width:299pt'>&nbsp;</td>
         <td class=xl724254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
         <td class=xl734254 width=64 style='border-top:none;border-left:none;
         width:48pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },      
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="260"><col width="118"><col width="358"><col width="134"><col width="136"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="260" height="78" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ингредиентер көрсеткішінің атауы (Наименование показателя ингредиентов)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="118" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгі ортасы<br>(Модельная среда)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="358" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған концентрация (параметрлері, уыттылығы токсичности) Обнаруженная концентрация (параметры токсичности)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шектелген концентрация<br>(Допустимая концентрация)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="136" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеулер әдістеріне НҚ <br>НД на методы исследований</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Органолептические:</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="20" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Физико-химические:</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="20" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Токсикологические:</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="20" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '35': {
    version: 2,
    splitted: true,
    name: 'ПРОТОКОЛ измерений концентрации аэроионов',
    kazakhName: 'Аэроиондар концентрациясын өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'name',
        type: 'text',
        label: 'Полное наименование хозяйствующего объекта, адрес цех, участок',
        kazakhLabel: 'Шаруашылық объектісінің толық атауы, мекенжайы, цех, учаске'
      },
      {
        id: 'measurements_goals',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеу мақсаты'
      },
      {
        id: 'measurements_done_with_representative',
        type: 'text',
        label: 'Замеры проводились в присутствии представителя объекта',
        kazakhLabel: 'Өлшеулер объект өкілінің қатысуымен жүргізілді'
      },
      {
        id: 'tools_and_inventory_number',
        type: 'text',
        label: 'Средства измерений наименование, тип, инвентарный номер',
        kazakhLabel: 'Өлшеу құралдары атауы, түрі, инвентарлық нөмірі'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства, Сведения о поверке',
        kazakhLabel: 'Тексеру туралы мәліметтер куәліктің берілген күні мен нөмірі'
      },
      {
        id: 'normative_document',
        type: 'text',
        label: 'Нормативный документ (НД) в соответствии с которой проводились измерения',
        kazakhLabel: 'Жүргізілген өлшеулер мен берілген санитариялық-эпидемиологиялық қорытынды мына Нормативтік құжатқа (НҚ) сай жүргізілді'
      },
      {
        id: 'main_sources',
        type: 'text',
        label: 'Основные источники',
        kazakhLabel: 'Негізгі көздер'
      },
      {
        id: 'persons_working',
        type: 'number',
        label: 'Количество работающих человек',
        kazakhLabel: 'Жұмыс істейтін адамдардың саны'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проводились на соответствие НД',
        kazakhLabel: 'Үлгілердің (нің) НҚ-ға сәйкестiгiне зерттеулер жүргiзiлдi'
      },
      {
        id: 'working_places',
        type: 'file',
        label: 'Эскиз рабочих мест',
        kazakhLabel: 'Жұмыс орнының сұлбасы'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=880 style='border-collapse:
        collapse;table-layout:fixed;width:659pt'>
        <col width=64 style='width:48pt'>
        <col width=342 style='mso-width-source:userset;mso-width-alt:12174;width:257pt'>
        <col width=171 style='mso-width-source:userset;mso-width-alt:6087;width:128pt'>
        <col width=162 style='mso-width-source:userset;mso-width-alt:5774;width:122pt'>
        <col width=64 span=9 style='width:48pt'>
        <col width=94 style='mso-width-source:userset;mso-width-alt:3356;width:71pt'>
        <tr height=106 style='height:79.2pt'>
          <td class=xl6629667 width=342 style='width:257pt'>Реттік бойынша н&#1257;мірі</td>
          <td class=xl6629667 width=171 style='border-left:none;width:128pt'>Кескіні
          бойынша н&#1199;ктені&#1187; орны</td>
          <td class=xl6629667 width=162 style='border-left:none;width:122pt'>&#1256;лшеу
          орны</td>
          <td class=xl6629667 width=64 style='border-left:none;width:48pt'>Аусым бойы
          ж&#1201;мыс аума&#1171;ында болу уы&#1179;ыты</td>
          <td colspan=4 class=xl6629667 width=256 style='border-left:none;width:192pt'>Аэроиондарды&#1187;
          орташа концентрациясы</td>
          <td colspan=2 rowspan=4 class=xl6629667 width=128 style='width:96pt'>У,
          Униполярлылы&#1179; коэффициенті, Коэффициент униполярности, У</td>
        </tr>
        <tr height=88 style='height:66.0pt'>
          <td class=xl6629667 width=342 style='border-top:none;width:257pt'>(Номер по
          порядку)</td>
          <td class=xl6629667 width=171 style='border-top:none;border-left:none;
          width:128pt'>(Номер точек по эскизу)</td>
          <td class=xl6629667 width=162 style='border-top:none;border-left:none;
          width:122pt'>(Место измерения)</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Время пребывания в зоне в течении смены)</td>
          <td colspan=4 class=xl6629667 width=256 style='border-left:none;width:192pt'>(Средняя
          концентрация аэроионов)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl6729667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl6729667 width=171 style='border-top:none;border-left:none;
          width:128pt'>&nbsp;</td>
          <td class=xl6729667 width=162 style='border-top:none;border-left:none;
          width:122pt'>&nbsp;</td>
          <td class=xl6729667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td colspan=2 class=xl6629667 width=128 style='border-left:none;width:96pt'>1
          см3 – тегі о&#1187; иондар саны</td>
          <td colspan=2 class=xl6629667 width=128 style='border-left:none;width:96pt'>1
          см3 – тегі теріс иондар саны</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl6729667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl6729667 width=171 style='border-top:none;border-left:none;
          width:128pt'>&nbsp;</td>
          <td class=xl6729667 width=162 style='border-top:none;border-left:none;
          width:122pt'>&nbsp;</td>
          <td class=xl6729667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td colspan=2 class=xl6629667 width=128 style='border-left:none;width:96pt'><span
          style='mso-spacerun:yes'> </span>(Число положительных ионов, в 1 см3<span
          style='mso-spacerun:yes'> </span></td>
          <td colspan=2 class=xl6629667 width=128 style='border-left:none;width:96pt'>(Число
          отрицательных ионов, в 1 см3)</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
          <td class=xl6729667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl6729667 width=171 style='border-top:none;border-left:none;
          width:128pt'>&nbsp;</td>
          <td class=xl6729667 width=162 style='border-top:none;border-left:none;
          width:122pt'>&nbsp;</td>
          <td class=xl6729667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&#1256;лшеулер</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>Р&#1201;&#1179;сат етілген шегі</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&#1256;лшеулер</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>Р&#1201;&#1179;сат етілген шегі</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&#1256;лшеулер</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>Р&#1201;&#1179;сат етілген шегі</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
          <td class=xl6729667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl6729667 width=171 style='border-top:none;border-left:none;
          width:128pt'>&nbsp;</td>
          <td class=xl6729667 width=162 style='border-top:none;border-left:none;
          width:122pt'>&nbsp;</td>
          <td class=xl6729667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Измерения)</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Предельно-допустимая)</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Измерения)</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Предельно-допустимая)</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Измерения)</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>(Предельно-допустимая)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl6629667 width=342 style='border-top:none;width:257pt'>1</td>
          <td class=xl6629667 width=171 style='border-top:none;border-left:none;
          width:128pt'>2</td>
          <td class=xl6629667 width=162 style='border-top:none;border-left:none;
          width:122pt'>3</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>4</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>5</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>6</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>7</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>8</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>9</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>10</td>
        </tr>
       </table>`,
       label: 'Таблица № 1 заполняется по показаниям прибора, сразу определяющего среднюю концентрацию аэроионов и коэфициента униполярности (например: прибор МАС-01).',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_2',
        type: 'table',
        htmlTable: `<table border="1" class="table table-bordered" cellpadding=0 cellspacing=0 width=880 style='border-collapse:
        collapse;table-layout:fixed;width:659pt'>
        <tr height=53 style='height:39.6pt'>
          <td class=xl6929667 width=342 style='width:257pt'>Аэроиондарды&#1187;
          полярлы&#1171;ы</td>
          <td colspan=11 class=xl7729667 width=909 style='border-left:none;width:682pt'>Аэроиндарды&#1187;
          концентрациясы, р, (ион/см3)</td>
          <td class=xl6329667 width=94 style='border-left:none;width:71pt'>Униполярлы
          коэффициенті, У</td>
        </tr>
        <tr height=53 style='height:39.6pt'>
          <td class=xl7029667 width=342 style='border-top:none;width:257pt'>(Полярность
          аэроионов)</td>
          <td colspan=11 class=xl6629667 width=909 style='border-left:none;width:682pt'>Концентрации
          аэроионов, р, (ион/см3)</td>
          <td class=xl6429667 width=94 style='border-top:none;border-left:none;
          width:71pt'>(Коэффициент униполярности, У)</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7429667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td colspan=8 class=xl6629667 width=717 style='border-left:none;width:538pt'>Бірлік
          &#1257;лшеу м&#1241;ні, рi,</td>
          <td colspan=3 class=xl6629667 width=192 style='border-left:none;width:144pt'>Орташа
          м&#1241;ні</td>
          <td class=xl7129667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7429667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td colspan=8 class=xl6629667 width=717 style='border-left:none;width:538pt'>(Значения
          единичного измерения, рi)</td>
          <td colspan=3 class=xl6629667 width=192 style='border-left:none;width:144pt'>(Средние
          значения)</td>
          <td class=xl7129667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7429667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td rowspan=4 class=xl6629667 width=171 style='border-top:none;width:128pt'>1</td>
          <td rowspan=4 class=xl6629667 width=162 style='border-top:none;width:122pt'>2</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>3</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>4</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>5</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>6</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>7</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>8</td>
          <td rowspan=4 height=76 width=64 style='height:57.6pt;width:48pt' align=left
          valign=top><!--[if gte vml 1]><v:shapetype id="_x0000_t75" coordsize="21600,21600"
          o:spt="75" o:preferrelative="t" path="m@4@5l@4@11@9@11@9@5xe" filled="f"
          stroked="f">
          <v:stroke joinstyle="miter"/>
          <v:formulas>
            <v:f eqn="if lineDrawn pixelLineWidth 0"/>
            <v:f eqn="sum @0 1 0"/>
            <v:f eqn="sum 0 0 @1"/>
            <v:f eqn="prod @2 1 2"/>
            <v:f eqn="prod @3 21600 pixelWidth"/>
            <v:f eqn="prod @3 21600 pixelHeight"/>
            <v:f eqn="sum @0 0 1"/>
            <v:f eqn="prod @6 1 2"/>
            <v:f eqn="prod @7 21600 pixelWidth"/>
            <v:f eqn="sum @8 21600 0"/>
            <v:f eqn="prod @7 21600 pixelHeight"/>
            <v:f eqn="sum @10 21600 0"/>
          </v:formulas>
          <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
          <o:lock v:ext="edit" aspectratio="t"/>
          </v:shapetype><v:shape id="Рисунок_x0020_2" o:spid="_x0000_s1025" type="#_x0000_t75"
          style='position:absolute;margin-left:0;margin-top:14.4pt;width:17.4pt;
          height:25.8pt;z-index:1;visibility:visible' o:gfxdata="UEsDBBQABgAIAAAAIQD0vmNdDgEAABoCAAATAAAAW0NvbnRlbnRfVHlwZXNdLnhtbJSRQU7DMBBF
        90jcwfIWJQ4sEEJJuiCwhAqVA1j2JDHEY8vjhvb2OEkrQVWQWNoz7//npFzt7MBGCGQcVvw6LzgD
        VE4b7Cr+tnnK7jijKFHLwSFUfA/EV/XlRbnZeyCWaKSK9zH6eyFI9WAl5c4DpknrgpUxHUMnvFQf
        sgNxUxS3QjmMgDGLUwavywZauR0ie9yl68Xk3UPH2cOyOHVV3NgpYB6Is0yAgU4Y6f1glIzpdWJE
        fWKWHazyRM471BtPV0mdn2+YJj+lvhccuJf0OYPRwNYyxGdpk7rQgYQ3Km4DpK3875xJ1FLm2tYo
        yJtA64U8iv1WoN0nBhj/m94k7BXGY7qY/2z9BQAA//8DAFBLAwQUAAYACAAAACEACMMYpNQAAACT
        AQAACwAAAF9yZWxzLy5yZWxzpJDBasMwDIbvg76D0X1x2sMYo05vg15LC7saW0nMYstIbtq+/UzZ
        YBm97ahf6PvEv91d46RmZAmUDKybFhQmRz6kwcDp+P78CkqKTd5OlNDADQV23eppe8DJlnokY8ii
        KiWJgbGU/Ka1uBGjlYYyprrpiaMtdeRBZ+s+7YB607Yvmn8zoFsw1d4b4L3fgDrecjX/YcfgmIT6
        0jiKmvo+uEdU7emSDjhXiuUBiwHPcg8Z56Y+B/qxd/1Pbw6unBk/qmGh/s6r+ceuF1V2XwAAAP//
        AwBQSwMEFAAGAAgAAAAhAK+XHIboAgAAcgcAABIAAABkcnMvcGljdHVyZXhtbC54bWysVW2O0zAQ
        /Y/EHSL/z8Zp0zaNNl2VpEVIfKwQHMB1nMYiiSPb7XaF+IG4CMdASHCG7o0YO8m2y7ICbemfOhN7
        3pt5b5zzi11VOlsmFRd1jPwzjBxWU5Hxeh2j9++WbogcpUmdkVLULEbXTKGL2dMn57tMRqSmhZAO
        pKhVBIEYFVo3kecpWrCKqDPRsBre5kJWRMOjXHuZJFeQvCq9AcZjTzWSkUwVjOm0fYNmNre+Egkr
        y7mFaEO5FFW7oqKc+fjcMyTM2p6AxZs8nx3C5sm+keJq5g/b7WbdB4+2Q9hutykPQFrc5n4QcOCP
        pvgh1ODPqH4wxqPuzB3oHrDhtEWut5ecXsqOxuvtpXR4FqMBcmpSgSD7r/tvN59vvux/7H/uvzsD
        5B22tgdJBMleCvpBdUqRR+hUEV4DpEgKUq/ZXDWMavDLUUhCHYXR0oSBRCsOEG5Z2Mc7xaxK3ix5
        CeqRyKxPZtf68J9cKPKcU5YKuqlYrVsrSlYSDWOgCt4o5MiIVSsGrZYvMh9MSSK20y+V7lbORvIY
        fRyEc4yng2duMsKJG+DJwp1Pg4k7wYtJgIPQT/zkkzntB9FGMZCBlGnD+1r94J4WFadSKJHrMyoq
        ryXaTw0Q9bHXarElZYyw7bSlBh0/UISlaanhqiR9C2L1iPfw/j6jFg8UhVxaMk2LU3OZVDkob3gZ
        p9wm7lxzcIYZcNWA51dXr0QGdicbLawYu1xW/4MHNNjZxWiMp2OM4fa7jlEY+sPQPNiaQXWHwo52
        zJFDYcdwiOH26npvmJiKGqn0cyZOZuWYRGA7aI6tlGzBdW2beggDVwszPKe2oC8RIE5NZUiZZrWD
        McXTRbgIAzcYjBcwGGnqzpdJ4I6X/mSUDtMkSf1+MAqeZaw+Lufxc2FYKFHyrL9alFyvklI6dl6W
        9tcJd7TNM/N5oNHPUv9vx97eYMaMnUtvr2hacrhEUqKJUclY9rePl421H8vZLwAAAP//AwBQSwME
        FAAGAAgAAAAhAFhgsxu6AAAAIgEAAB0AAABkcnMvX3JlbHMvcGljdHVyZXhtbC54bWwucmVsc4SP
        ywrCMBBF94L/EGZv07oQkaZuRHAr9QOGZJpGmwdJFPv3BtwoCC7nXu45TLt/2ok9KCbjnYCmqoGR
        k14ZpwVc+uNqCyxldAon70jATAn23XLRnmnCXEZpNCGxQnFJwJhz2HGe5EgWU+UDudIMPlrM5Yya
        B5Q31MTXdb3h8ZMB3ReTnZSAeFINsH4Oxfyf7YfBSDp4ebfk8g8FN7a4CxCjpizAkjL4DpvqGkgD
        71r+9Vn3AgAA//8DAFBLAwQUAAYACAAAACEAiJ8BnQkBAACDAQAADwAAAGRycy9kb3ducmV2Lnht
        bGxQy07DMBC8I/EP1iJxQdRJCKWEOlUBIfWEaAl3K3EeIvZGtmnSfD1bqBQhcdsZ78zOeLkadMv2
        yroGjYBwFgBTJseiMZWA7P3legHMeWkK2aJRAg7KwSo9P1vKpMDebNV+5ytGJsYlUkDtfZdw7vJa
        aelm2ClDbyVaLT1BW/HCyp7MdcujIJhzLRtDF2rZqada5Z+7Ly3AHML5x/MbPiKO4e0VNlmQj5kQ
        lxfD+gGYV4Oflk/qTSEggmMVqgEp5RvatclrtKzcKteMFP6XLy1qZrEnfAMsx5YGqk3Ma1k65QUQ
        InpC/Gjn8SSK/xeFcXy3+KuMouCeKJLzKc4PmP4u/QYAAP//AwBQSwMECgAAAAAAAAAhAPbUfsWb
        AwAAmwMAABUAAABkcnMvbWVkaWEvaW1hZ2UxLmpwZWf/2P/gABBKRklGAAEBAQAAAAAAAP/bAEMA
        AwICAwICAwMDAwQDAwQFCAUFBAQFCgcHBggMCgwMCwoLCw0OEhANDhEOCwsQFhARExQVFRUMDxcY
        FhQYEhQVFP/bAEMBAwQEBQQFCQUFCRQNCw0UFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQU
        FBQUFBQUFBQUFBQUFBQUFBQUFP/AABEIABoAEQMBIgACEQEDEQH/xAAfAAABBQEBAQEBAQAAAAAA
        AAAAAQIDBAUGBwgJCgv/xAC1EAACAQMDAgQDBQUEBAAAAX0BAgMABBEFEiExQQYTUWEHInEUMoGR
        oQgjQrHBFVLR8CQzYnKCCQoWFxgZGiUmJygpKjQ1Njc4OTpDREVGR0hJSlNUVVZXWFlaY2RlZmdo
        aWpzdHV2d3h5eoOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU
        1dbX2Nna4eLj5OXm5+jp6vHy8/T19vf4+fr/xAAfAQADAQEBAQEBAQEBAAAAAAAAAQIDBAUGBwgJ
        Cgv/xAC1EQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVi
        ctEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqC
        g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl
        5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/AP0e8a6t8RbDVYo/CPhXwvrmmmENJca34ludNmWX
        c2UEcen3AK7Qh3bwSSRtGATq+Cr3xVf6VLJ4u0bR9D1ITFY7fRNWl1KFotq4cySW1uQ24uNuwgAA
        7jkgcp418W+KtV8fReCfA15o+mala6YNZ1fVdb06W/htIpJWhtIBbpcW5dp2hvG8xZSIxZFXT99G
        w1vg941vvH3ggajqcVvHqVpqep6NdPaKyQzy2N/cWTzojMxjWRrcyCMs5QOFLvt3sAdrRRRQB5pr
        n7PfhLxV4m1jXNcOsarcapMjXFrJrFzDaSW6QRxCxlghdEuLTKSyfZ7gSx77u7OMTyA+gaTpNjoG
        lWemaZZ2+nabZQpbWtnaRLFDBEihUjRFACqqgAKAAAABVuigAooooA//2VBLAQItABQABgAIAAAA
        IQD0vmNdDgEAABoCAAATAAAAAAAAAAAAAAAAAAAAAABbQ29udGVudF9UeXBlc10ueG1sUEsBAi0A
        FAAGAAgAAAAhAAjDGKTUAAAAkwEAAAsAAAAAAAAAAAAAAAAAPwEAAF9yZWxzLy5yZWxzUEsBAi0A
        FAAGAAgAAAAhAK+XHIboAgAAcgcAABIAAAAAAAAAAAAAAAAAPAIAAGRycy9waWN0dXJleG1sLnht
        bFBLAQItABQABgAIAAAAIQBYYLMbugAAACIBAAAdAAAAAAAAAAAAAAAAAFQFAABkcnMvX3JlbHMv
        cGljdHVyZXhtbC54bWwucmVsc1BLAQItABQABgAIAAAAIQCInwGdCQEAAIMBAAAPAAAAAAAAAAAA
        AAAAAEkGAABkcnMvZG93bnJldi54bWxQSwECLQAKAAAAAAAAACEA9tR+xZsDAACbAwAAFQAAAAAA
        AAAAAAAAAAB/BwAAZHJzL21lZGlhL2ltYWdlMS5qcGVnUEsFBgAAAAAGAAYAhQEAAE0LAAAAAA==
        ">
          <v:imagedata src="35.files/протокола%20таблицы_29667_image001.png" o:title=""/>
          <x:ClientData ObjectType="Pict">
            <x:SizeWithCells/>
            <x:CF>Bitmap</x:CF>
            <x:AutoPict/>
          </x:ClientData>
          </v:shape><![endif]--><![if !vml]><span style='mso-ignore:vglayout;
          position:absolute;z-index:1;margin-left:0px;margin-top:19px;width:23px;
          height:34px'><img width=23 height=34
          src="35.files/протокола%20таблицы_29667_image002.png" v:shapes="Рисунок_x0020_2"></span><![endif]><span
          style='mso-ignore:vglayout2'>
            <table cellpadding=0 cellspacing=0>
            <tr>
              <td rowspan=4 height=76 class=xl7829667 width=64 style='height:57.6pt;
              border-top:none;width:48pt'>&nbsp;</td>
            </tr>
            </table>
          </span></td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>Рmin</td>
          <td rowspan=4 class=xl6629667 width=64 style='border-top:none;width:48pt'>Рmax</td>
          <td class=xl7129667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7429667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl7129667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7429667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl7129667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7429667 width=342 style='border-top:none;width:257pt'>&nbsp;</td>
          <td class=xl7129667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7029667 width=342 style='border-top:none;width:257pt'>1</td>
          <td class=xl6629667 width=171 style='border-top:none;border-left:none;
          width:128pt'>2</td>
          <td class=xl6629667 width=162 style='border-top:none;border-left:none;
          width:122pt'>3</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>4</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>5</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>6</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>7</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>8</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>9</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>10</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>11</td>
          <td class=xl6629667 width=64 style='border-top:none;border-left:none;
          width:48pt'>12</td>
          <td class=xl6429667 width=94 style='border-top:none;border-left:none;
          width:71pt'>13</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
          <td class=xl7029667 width=342 style='border-top:none;width:257pt'>р+</td>
          <td class=xl6829667 width=171 style='border-top:none;border-left:none;
          width:128pt'>&nbsp;</td>
          <td class=xl6829667 width=162 style='border-top:none;border-left:none;
          width:122pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl6829667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7529667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
          <td class=xl6529667 width=342 style='border-top:none;width:257pt'>р-</td>
          <td class=xl7229667 width=171 style='border-top:none;border-left:none;
          width:128pt'>&nbsp;</td>
          <td class=xl7229667 width=162 style='border-top:none;border-left:none;
          width:122pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7229667 width=64 style='border-top:none;border-left:none;
          width:48pt'>&nbsp;</td>
          <td class=xl7329667 width=94 style='border-top:none;border-left:none;
          width:71pt'>&nbsp;</td>
        </tr>
       </table>`,
       label: 'Таблица № 2 протокола заполняется в случае выполнения нескольких замеров и определения среднего значения концентрации аэронов и коэфициента униполярности',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table cellspacing="0" cols="10" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="120"><col width="138"><col width="103"><col width="108"><col width="79"><col width="97"><col width="81"><col width="103"><col width="78"><col width="99"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="120" height="281" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Реттік бойынша нөмірі<br>(Номер по порядку)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="138" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кескіні бойынша нүктенің орны<br>(Номер точек по эскизу)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="103" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу орны<br>(Место измерения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="108" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аусым бойы жұмыс аумағында болу уықыты<br>(Время пребывания в зоне в течении смены)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" width="360" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аэроиондардың орташа концентрациясы<br>(Средняя концентрация аэроионов)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" rowspan="2" width="177" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">У, Униполярлылық коэффициенті, Коэффициент униполярности, У</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1 см3 – тегі оң иондар саны  (Число положительных ионов, в 1 см3 </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1 см3 – тегі теріс иондар саны<br>(Число отрицательных ионов, в 1 см3)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измерения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілген шегі <br>(Предельно-допустимая)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измерения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілген шегі <br>(Предельно-допустимая)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеулер<br>(Измерения)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рұқсат етілген шегі <br>(Предельно-допустимая)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Таблица № 1 заполняется по показаниям прибора, сразу определяющего среднюю концентрацию аэроионов и коэфициента униполярности (например: прибор МАС-01).',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table align="LEFT" cellspacing="0" cols="13" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered">
        <colgroup><col width="120"><col width="46"><col width="55"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="65"><col width="117"></colgroup>
        <tbody>
          <tr>
            <td width="120" height="19" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General" colspan="13"><b><font face="Times New Roman" color="#000000"> Кесте/Таблица № 2</font></b></td>
          </tr>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="3" height="163" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аэроиондардың полярлығы<br>(Полярность аэроионов)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="11" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аэроиндардың концентрациясы, р, (ион/см3)<br>Концентрации аэроионов, р, (ион/см3)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Униполярлы коэффициенті, У<br>(Коэффициент униполярности, У)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="8" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бірлік өлшеу мәні, рi,<br>(Значения единичного измерения, рi)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Орташа мәні<br>(Средние значения)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рmin</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Рmax</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">р+</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">р-</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Таблица № 2 протокола заполняется в случае выполнения нескольких замеров и определения среднего значения концентрации аэронов и коэфициента униполярности',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  }
}
