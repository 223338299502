import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  OPEN_REQUEST,
  CLOSE_REQUEST,
  OPEN_DICTIONARIES,
  CLOSE_DICTIONARIES,
  OPEN_ACT,
  CLOSE_ACT,
  OPEN_DIRECTION,
  CLOSE_DIRECTION,
  OPEN_DDD,
  CLOSE_DDD,
  OPEN_REPORT_AND_ANALYTICS,
  CLOSE_REPORT_AND_ANALYTICS,
  OPEN_KSEC,
  CLOSE_KSEC,  
  OPEN_ADMINISTRATION,
  CLOSE_ADMINISTRATION,
  CLOSE_REFERAL,
  OPEN_REFERAL,
  OPEN_RESULT,
  CLOSE_RESULT,
  OPEN_PROTOCOL,
  CLOSE_PROTOCOL,
  CLOSE_OBJECT_REFERAL,
  OPEN_OBJECT_REFERAL, CLOSE_ACT_DSEK, OPEN_ACT_DSEK
} from "../constants/genericConstants";

const initialState = {
  open: false,
  openListRequests: false,
  openListReferal: false,
  openListObjectReferal: false,
  openListDictionaries: false,
  openListResult: false,
  openListProtocol: false,
  openListActs: false,
  openListActsDSEK: false,
  openListDirections: false,
  openListDDD: false,
  openListReportAndAnalytics: false,
  openListKsec: false,
  openAdministration: false
};

export const genericReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIDEBAR_OPEN:
      return {
        ...state,
        open: true,
      };
    case SIDEBAR_CLOSE:
      return {
        ...state,
        open: false,
      };
    case OPEN_REQUEST:
      return {
        ...state,
        openListRequests: true,
      };
    case CLOSE_REQUEST:
      return {
        ...state,
        openListRequests: false,
      };
      case CLOSE_ACT_DSEK:
      return {
        ...state,
        openListActsDSEK: false,
      };
      case OPEN_ACT_DSEK:
      return {
        ...state,
        openListActsDSEK: true,
      };

    case OPEN_ADMINISTRATION:
      return {
        ...state,
        openAdministration:true
      };
    case CLOSE_ADMINISTRATION:
      return {
        ...state,
        openAdministration:false,
      };
    case OPEN_DICTIONARIES:
      return {
        ...state,
        openListDictionaries: true,
      };
    case CLOSE_DICTIONARIES:
      return {
        ...state,
        openListDictionaries: false,
      };
    case OPEN_ACT:
      return {
        ...state,
        openListActs: true,
      };
    case CLOSE_ACT:
      return {
        ...state,
        openListActs: false,
      };
    case OPEN_DIRECTION:
      return {
        ...state,
        openListDirections: true,
      };
    case CLOSE_DIRECTION:
      return {
        ...state,
        openListDirections: false,
      };
    case OPEN_DDD:
      return {
        ...state,
        openListDDD: true,
      };
    case CLOSE_DDD:
      return {
        ...state,
        openListDDD: false,
      };
    case OPEN_REPORT_AND_ANALYTICS:
      return {
        ...state,
        openListReportAndAnalytics: true,
      };
    case CLOSE_REPORT_AND_ANALYTICS:
      return {
        ...state,
        openListReportAndAnalytics: false,
      };
    case OPEN_KSEC:
      return {
        ...state,
        openListKsec: true,
      };
    case CLOSE_KSEC:
      return {
        ...state,
        openListKsec: false,
      };
    case OPEN_REFERAL:
      return {
        ...state,
        openListReferal: true,
      };
    case CLOSE_REFERAL:
      return {
        ...state,
        openListReferal: false,
      };
    case OPEN_OBJECT_REFERAL:
      return {
        ...state,
        openListObjectReferal: true,
      };
    case CLOSE_OBJECT_REFERAL:
      return {
        ...state,
        openListObjectReferal: false,
      };
    case OPEN_RESULT:
      return {
        ...state,
        openListResult: true,
      };
    case CLOSE_RESULT:
      return {
        ...state,
        openListResult: false,
      };
    case OPEN_PROTOCOL:
      return {
        ...state,
        openListProtocol: true,
      };
    case CLOSE_PROTOCOL:
      return {
        ...state,
        openListProtocol: false,
      };
    default:
      return state;
  }
};
