import React, {useEffect, useState} from 'react';
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";

function ReferalRadio({label, array, value, required, setValue}) {
    useEffect(() => {
        //if(!value) setValue(array[0])
    }, [value])
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <FormControl component="fieldset">
            {label && <FormLabel component="legend" required={required}>{label}</FormLabel>}
            <RadioGroup
                aria-label="gender"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel key={0} value={'Не указано'} control={<Radio />} label={'Не указано'} />
                {array.map((el, idx) => (
                    <FormControlLabel key={idx + 1} value={el} control={<Radio />} label={el} />
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default ReferalRadio;
