import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Grid,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import {
  getContractRequestByKey,
  getEmployeeByKey,
  getAuthorizedEmployee,
  getContractByKey,
  addDepartureScheduleRequest,
} from "../../../../redux/actions";
import {
  BasicModalContainer,
  CustomDatePicker,
  Form,
  FormSection,
  BasicTable,
} from "../../../../components";
import {
  FilialDivisionSelect,
  FilialSelect,
} from "../../../../components/Select/domainSelects";

import { prepareContractRequestChildRequestDataForSubmit } from "../../../../functions/viewDataMap";
import { trySetCount } from '../../../../models/data/ContractRequestChildrenModel';
import EmployeeField from "../../../../components/Relations/EmployeeField";
import DialogLayout from "../../../../components/Dialog/DialogLayout";
import {autoCompleteFilialAndFilialDivision} from "../../../../functions/dateHelpers";
import {addSelfControlRequest} from "../../../../redux/actions/selfControlRequestActions";

export const SelfControlRequestCreateModal = ({
                                                      open,
                                                      setOpen,
                                                    }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authorizedEmployee = useSelector(state=>state.employee.authorizedEmployee)
  const [loading, setLoading] = useState(false);

  const { contractRequest } = useSelector((s) => s.contractRequest);

  const [localDateOfDeparture, setLocalDateOfDeparture] = useState(new Date());

  const [localCreatedById, setLocalCreatedById] = useState(null);
  const [localCreatedBy, setLocalCreatedBy] = useState({});

  const [localObjects, setLocalObjects] = useState([]);
  const [localSpecifications, setLocalSpecifications] = useState([]);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState([]);

  const [localFilialId, setLocalFilialId] = useState(null);
  const [filialDivisionId, setFilialDivisionId] = useState(null);

  const [localPerformerId, setLocalPerformerId] = useState(null);
  const [localPerformer, setLocalPerformer] = useState({});
  const [localAuthorComment, setLocalAuthorComment] = useState(null);

  const handleSpecificationQuantityChange = (e, id) => {
    const isChanged = trySetCount(selectedSpecifications, localSpecifications, id, contractRequest,
        setSelectedSpecifications, setLocalSpecifications, e.target.value);

    if (!isChanged)
      return alert("Превышено максимальное значение исследований для этого элемента");
  };

  const handleReset = () => {
    setSelectedObjects([]);
    setSelectedSpecifications([]);
    //setLocalFilial({});
    setFilialDivisionId(null);
    //setLocalFilial({});
    //setLocalDivision({});
    //setLocalFilial(null);
    setLocalFilialId(null);
    //setLocalDivisionId(null);
    setLocalDateOfDeparture(new Date());
    setLocalAuthorComment(null);
  };

  const handleDateOfDepartureChange = (data) => {
    setLocalDateOfDeparture(data);
  };

  const handleAuthorComment = (e) => setLocalAuthorComment(e.target.value);

  useEffect(() => {
    autoCompleteFilialAndFilialDivision(authorizedEmployee, setLocalFilialId, setFilialDivisionId);
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking Fields
    if (!selectedSpecifications?.length) return alert("Выберите услуги!");

    prepareContractRequestChildRequestDataForSubmit(selectedSpecifications, selectedObjects);

    setLoading(true);

    const data = {
      contractRequestId: contractRequest.id,
      contractRequestNumber: contractRequest.number,
      performerId: localPerformerId,
      filialId: localFilialId || contractRequest.filialId,
      filialDivisionId: filialDivisionId || contractRequest.contractFilialDivisionId || contractRequest.filialDivisionId,
      createdId: localCreatedById,
      objects: selectedObjects,
      specifications: selectedSpecifications,
      authorComment: localAuthorComment,
      dateOfDeparture: localDateOfDeparture,
    };

    // Creating
    const createdId = await dispatch(addSelfControlRequest(data));
    if (!createdId) return setLoading(false);

    // Updating Contract Request
    const contractReq = await dispatch(
        getContractRequestByKey(contractRequest.id)
    );
    if (!contractReq) return setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      handleReset();
    }, 500);
  };

  useEffect(() => {
    if (!open)
      return;

    dispatch(getAuthorizedEmployee()).then(setLocalCreatedBy);
    dispatch(getContractByKey(contractRequest.contractId));
  }, [open]);

  useEffect(() => {
    if (!contractRequest)
      return;

    setLocalObjects(contractRequest?.objects);
    setLocalSpecifications(contractRequest?.specifications);
  }, [contractRequest]);

  useEffect(() => {
    if (!localPerformerId) return;

    dispatch(getEmployeeByKey(localPerformerId)).then(setLocalPerformer);
  }, [dispatch, localPerformerId]);


  return (
      <DialogLayout open={open} setOpen={setOpen} loading={loading} title="Заявка по самоконтролю: Создание">
        <Form onSubmit={handleSubmit}>
          <BasicModalContainer>
            <FormSection heading="Заявка" mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                      label="Сводная заявка"
                      type="text"
                      value={contractRequest?.id}
                      disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                      label="Автор заявки"
                      value={localCreatedBy?.fullName}
                      disabled
                  />
                </Grid>
                <Grid item xs={4}>
                  <EmployeeField
                      style={{ marginBottom: "5px" }}
                      label={"Исполнитель заявки"}
                      object={localPerformer}
                      value={localPerformerId}
                      onChange={(val) => {
                        setLocalPerformer(val)
                        setLocalPerformerId(val?.id)
                      }}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection heading="Договор" mb={4}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                      label="Номер договора"
                      type="number"
                      value={contractRequest?.contractNumber || 0}
                      disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                      label="Статус по договору"
                      type="text"
                      value={contractRequest?.contractState?.name}
                      disabled
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomDatePicker
                      label="Дата договора"
                      value={contractRequest?.contractActionAt}
                      disabled
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection
                heading={`Объекты (${localObjects?.length || 0})`}
                mb={5}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                      cells={[
                        { id: "id", label: t("object.tableItem.id") },
                        {
                          id: "name",
                          label: t("object.tableItem.name"),
                          render: (row) => row?.object?.name,
                        },
                        {
                          id: "locality",
                          label: t("object.tableItem.locality"),
                          render: (row) => row?.locality?.name,
                        },
                        {
                          id: "district",
                          label: t("object.tableItem.district"),
                          render: (row) => row?.district?.name,
                        },
                        {
                          id: "city",
                          label: t("object.tableItem.city"),
                          render: (row) => row?.city?.name,
                        },
                        {
                          id: "addressLine",
                          label: t("object.tableItem.addressLine"),
                        },
                      ]}
                      rows={localObjects}
                      disabled={true}
                      selected={selectedObjects}
                      setSelected={setSelectedObjects}
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection
                heading={`Услуги (${localSpecifications?.length || 0})`}
                mb={5}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                      cells={[
                        { id: "id", label: t("specification.tableItem.id") },
                        {
                          id: "object",
                          label: t("specification.tableItem.object"),
                          render: ({ object }) => object?.name,
                        },
                        {
                          id: "serviceKind",
                          label: t("specification.tableItem.serviceKind"),
                          render: ({ serviceKind }) => serviceKind?.name,
                        },
                        {
                          id: "service",
                          label: t("specification.tableItem.service"),
                          render: ({ service }) => service?.name,
                        },
                        {
                          id: "unit",
                          label: t("specification.tableItem.unit"),
                          render: ({ unit }) => unit?.name,
                        },
                        {
                          id: "schedule",
                          label: t("specification.tableItem.schedule"),
                          render: ({ schedule }) => schedule?.name,
                        },
                        {
                          id: "count",
                          label: t("specification.tableItem.quantity"),
                          render: ({ id, count }) => (
                              <TextField
                                  type="number"
                                  variant="standard"
                                  onWheel={event => event.target.blur()}
                                  value={count}
                                  size="small"
                                  InputProps={{
                                    inputProps: { min: 0, maxLength: 5 },
                                  }}
                                  sx={{ margin: 0 }}
                                  onChange={(e) =>
                                      handleSpecificationQuantityChange(e, id)
                                  }
                                  onInput={(e) => {
                                    e.preventDefault();
                                  }}
                              />
                          ),
                        },
                        { id: "price", label: t("specification.tableItem.price") },
                      ]}
                      rows={localSpecifications}
                      disabled={true}
                      selected={selectedSpecifications}
                      setSelected={setSelectedSpecifications}
                  />
                </Grid>
              </Grid>
            </FormSection>

            <FormSection heading="Филиал" mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FilialSelect
                      label={"Филиал"}
                      fullWidth
                      value={localFilialId}
                      onChange={(val) => {
                        setLocalFilialId(val);
                        setFilialDivisionId(Number(val) !== Number(localFilialId) ?
                            null
                            : filialDivisionId);
                      }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FilialDivisionSelect
                      label={"Районное отделение"}
                      fullWidth
                      value={filialDivisionId}
                      filialId={localFilialId}
                      onChange={(val, filialDivision) => {
                        setFilialDivisionId(val);
                        setLocalFilialId(Number(filialDivision.filialId) !== Number(localFilialId)
                            ? filialDivision.filialId
                            : localFilialId);
                      }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  <TextField
                      label="Комментарий автора"
                      value={localAuthorComment}
                      onChange={handleAuthorComment}
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomDatePicker
                      label="Дата выезда"
                      value={localDateOfDeparture}
                      onChange={handleDateOfDepartureChange}
                  />
                </Grid>
              </Grid>
            </FormSection>
          </BasicModalContainer>

          <BasicModalContainer>
            <Grid container spacing={2}>
              <Grid item>
                <LoadingButton
                    variant="contained"
                    loading={loading}
                    type="submit"
                >
                  Создать заявку
                </LoadingButton>
              </Grid>
            </Grid>
          </BasicModalContainer>
        </Form>
      </DialogLayout>
  );
};
