import { 
    USER_GET_REQUEST, USER_GET_SUCCESS, USER_GET_ERROR, 
    USER_GET_BY_KEY_REQUEST, USER_GET_BY_KEY_SUCCESS, USER_GET_BY_KEY_ERROR, 
    USER_ADD_REQUEST, USER_ADD_SUCCESS, USER_ADD_ERROR, 
    USER_EDIT_REQUEST, USER_EDIT_SUCCESS, USER_EDIT_ERROR, 
    USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_ERROR
} from "../constants/userConstants";

const initialState = {
    users: [],
    user: {},
    loading: false,
    success: null,
    error: null,
    userRequestedCount: 0,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type)     {
        case USER_GET_REQUEST:
            return {
                ...state,
                userRequestedCount: ++state.userRequestedCount,
                loading: true,
            };
        case USER_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
                success: true,
            };
        case USER_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                success: true,
            };
        case USER_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
