import moment from "moment";

export const MOMENT_FORMAT_DATE_DMY_DOTS = "DD.MM.YYYY";
export const MOMENT_FORMAT_DATE_MY_DOTS = "MM.YYYY";
export const MOMENT_FORMAT_DATE_DMYHM_DOTS = "DD.MM.YYYY H:mm";
export const MOMENT_FORMAT_DATE_YMD = "YYYY-MM-DD";

export function formatDate(date, format = MOMENT_FORMAT_DATE_DMYHM_DOTS) {
  if (!date)
    return "";
  return moment(date).isValid() && moment(date).format(format)
}

export function formatDate2(date, format = MOMENT_FORMAT_DATE_YMD) {
  if (!date)
    return "";
  return moment(date).isValid() && moment(date).format(format)
}

export function formatDateAsDate(date) {
  return formatDate(date, MOMENT_FORMAT_DATE_DMY_DOTS);
}

export function formatDateAsDateTime(date) {
  return formatDate(date, MOMENT_FORMAT_DATE_DMYHM_DOTS);
}

export function formatDateAsMonthYear(date) {
  return formatDate(date, MOMENT_FORMAT_DATE_MY_DOTS);
}

export function isEmptyDate(date) {
  if (!date)
    return true;

  if(typeof date === 'string' && date.startsWith('0001'))
    return true;

  return false;
}

export function withoutTime(date, utc = false) {
  if (!date)
    return undefined;
  if (!date.getTime || !date.getUTCDate)
    return date;
  const result = new Date(!utc ? date.getTime() : date.getUTCDate());
  result.setHours(0, 0, 0, 0);
}

export function formatFloat(value) {
  if (!value)
    return "";
  return value.toString().replace(".", ",");
}

export function intToWords(int, names) {
  var result = [];
  if (typeof int === 'number') {
    int = int.toString();
  } else if (typeof int !== 'string') {
    int = '';
  }
  if (!(names instanceof Array) || (typeof names[0] !== 'string') || (typeof names[1] !== 'string') || (typeof names[2] !== 'string')) {
    names = null;
  }
  if (int.length && !/[^0-9]/.test(int)) {
    var selectName = function (number, names) {
      return names[((parseInt(number) % 100 > 4) && (parseInt(number) % 100 < 20)) ? 2 : [2, 0, 1, 1, 1, 2][Math.min(parseInt(number) % 10, 5)]];
    };
    var name = null;
    var zero = 'ноль';
    if (int === '0') {
      result.push(zero);
    } else {
      var from0To2 = [zero, 'одна', 'две'];
      var from0To19 = [
        zero, 'один', 'два', 'три', 'четыре',
        'пять', 'шесть', 'семь', 'восемь', 'девять',
        'десять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать',
        'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'
      ];
      var tens = [
        'десять', 'двадцать', 'тридцать', 'сорок', 'пятьдесят',
        'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'
      ];
      var hundreds = [
        'сто', 'двести', 'триста', 'четыреста', 'пятьсот',
        'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'
      ];
      var thousands = [
        ['тысяча', 'тысячи', 'тысяч'],
        ['миллион', 'миллиона', 'миллионов'],
        ['миллиард', 'миллиарда', 'миллиардов'],
        ['триллион', 'триллиона', 'триллионов'],
        ['квадриллион', 'квадриллиона', 'квадриллионов'],
        ['квинтиллион', 'квинтиллиона', 'квинтиллионов'],
        ['секстиллион', 'секстиллиона', 'секстиллионов'],
        ['септиллион', 'септиллиона', 'септиллионов'],
        ['октиллион', 'октиллиона', 'октиллионов'],
        ['нониллион', 'нониллиона', 'нониллионов'],
        ['дециллион', 'дециллиона', 'дециллионов']
      ];
      var unknown = '{неизвестно}';
      var numberParts = int.replace(/(?=(\d{3})+(?!\d))/g, ' ').split(' ');
      var i = numberParts.length - 1;
      for (var j in numberParts) {
        var numberPart = parseInt(numberParts[j]);
        if (numberPart) {
          var numberPartResult = [];
          var hundred = Math.floor(numberPart / 100);
          if (hundred) {
            numberPartResult.push(hundreds[hundred - 1]);
            numberPart -= hundred * 100;
          }
          if (numberPart > 19) {
            var ten = Math.floor(numberPart / 10);
            numberPartResult.push(tens[ten - 1]);
            numberPart -= ten * 10;
          }
          if (numberPart) {
            numberPartResult.push(((i === 1) && ([1, 2].indexOf(numberPart) !== -1)) ? from0To2[numberPart] : from0To19[numberPart]);
          }
          if (thousands[i - 1] !== undefined) {
            numberPartResult.push(selectName(numberParts[j], thousands[i - 1]));
          } else if (i !== 0) {
            numberPartResult.push(unknown);
          } else if (names) {
            name = selectName(numberParts[j], names);
          }
          result.push(numberPartResult.join(' '));
        }
        i--;
      }
      if (!result.length) {
        result.push(zero);
      }
    }
    if (!name && names) {
      name = selectName(0, names);
    }
    if (name) {
      result.push(name);
    }
  }
  return result.join(' ');
}

export function roundTo2(figureToRound){
  var roundOff = Math.round((figureToRound* 100 ).toFixed(2))/100;
  return roundOff;
}