import React from "react";
import { useLocation } from "react-router";

import ReceiptCRUDTable from "../../../components/CRUD/Tables/ReceiptCRUDTable";
import DashboardLayout from "../../../layouts/Dashboard";

const ReceiptForThingsTable = () => {
  const { pathname } = useLocation()

  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
      <ReceiptCRUDTable />
    </DashboardLayout>
  );
};

export default ReceiptForThingsTable;
