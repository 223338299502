import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  BasicModal,
  BasicModalContainer,
  BasicModalHeader,
  BasicTable,
  CustomDatePicker,
  Form,
  FormSection,
} from "../../../../components";
import {
  addServiceWithoutActRequest,
  getAuthorizedEmployee,
  getContractByKey,
  getContractRequestByKey,
  getDepartmentByKey,
  getDepartments,
  getDivisionByKey,
  getDivisions,
  getEmployeeByKey,
  getEmployees,
  getFilialByKey,
  getFilials,
  getServiceWithoutActRequestByKey,
} from "../../../../redux/actions";
import EmployeeField from "../../../../components/Relations/EmployeeField";
import DialogLayout from "../../../../components/Dialog/DialogLayout";
import {DepartmentSelect} from "../../../../components/Select/domainSelects";
import {trySetCount} from "../../../../models/data/ContractRequestChildrenModel";

export const ServiceWithoutActRequestCreateModal = ({
  open,
  setOpen,
  externalState,
  onClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { contract } = useSelector((s) => s.contract);
  const { contractRequest } = useSelector((s) => s.contractRequest);
  const { employees, employeeCount } = useSelector((s) => s.employee);
  const { filials, filialCount } = useSelector((s) => s.filial);
  const { departments, departmentCount } = useSelector((s) => s.department);
  const { divisions, divisionCount } = useSelector((s) => s.division);

  const [localCreatedById, setLocalCreatedById] = useState("");
  const [localCreatedBy, setLocalCreatedBy] = useState({});

  const [localDepartureDate, setLocalDepartureDate] = useState(new Date());
  const [localObjectCreatingRow, setlocalObjectCreatingRow] = useState([]);

  const [localObjects, setLocalObjects] = useState([]);
  const [localSpecifications, setLocalSpecifications] = useState([]);

  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedSpecifications, setSelectedSpecifications] = useState([]);

  const [localDepartmentId, setLocalDepartmentId] = useState(null);
  const [localDepartment, setLocalDepartment] = useState({});

  const [localDepartmentHeadId, setLocalDepartmentHeadId] = useState(null);
  const [localDepartmentHead, setLocalDepartmentHead] = useState({});

  const [localSpecialistId, setLocalSpecialistId] = useState(null);
  const [localSpecialist, setLocalSpecialist] = useState({});

  const [localFilialId, setLocalFilialId] = useState(null);
  const [localFilial, setLocalFilial] = useState({});

  const [localResponsibleId, setLocalResponsibleId] = useState(null);
  const [localResponsible, setLocalResponsible] = useState(null);

  const [localPerformerId, setLocalPerformerId] = useState(null);
  const [localPerformer, setLocalPerformer] = useState({});

  const [localDateOfDeparture, setLocalDateOfDeparture] = useState(new Date());

  const handleDateOfDepartureChange = (data) => {
    setLocalDateOfDeparture(data);
  };

  const handleSpecificationQuantityChange = (e, id) => {
    const isChanged = trySetCount(selectedSpecifications, localSpecifications, id, contractRequest,
        setSelectedSpecifications, setLocalSpecifications, e.target.value);

    if (!isChanged)
      return alert("Превышено максимальное значение исследований для этого элемента");
  };

  const handleReset = () => {
    setSelectedObjects([]);
    setSelectedSpecifications([]);
    setLocalFilial(null);
    setLocalDepartment(null);
    setLocalDepartmentHead(null);
    setLocalSpecialist(null);
    setLocalFilial(null);
    setLocalResponsible(null);
    setLocalFilial(null);
    setLocalDepartmentId(null);
    setLocalDepartmentHeadId(null);
    setLocalSpecialistId(null);
    setLocalFilialId(null);
    setLocalResponsibleId(null);
    setLocalPerformer(null);
    setLocalPerformerId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Checking Fields
    if (!selectedSpecifications?.length) return alert("Выберите услуги!");

    setLoading(true);

    const data = {
      contractRequestId: contractRequest.id,
      filialId: contractRequest.filialId,
      filialDivisionId: contractRequest.contractFilialDivisionId,
      departmentId: localDepartmentId,
      responsibleId: localResponsibleId,
      createdId: localCreatedById,
      objects: selectedObjects,
      specifications: selectedSpecifications,
      reponsiblePerformerId: localPerformerId,
      departureDate: localDateOfDeparture,
    };

    // Creating
    const createdId = await dispatch(addServiceWithoutActRequest(data));
    if (!createdId) return setLoading(false);

    // Updating Contract Request
    const contractReq = await dispatch(
      getContractRequestByKey(contractRequest.id)
    );
    if (!contractReq) return setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      handleReset();
    }, 500);
  };

  useEffect(() => {
    if (!open)
      return;

    console.log("render");
    setSelectedObjects([]);
    setSelectedSpecifications([]);

    dispatch(getAuthorizedEmployee()).then(setLocalCreatedBy);
    dispatch(getContractByKey(contractRequest.contractId));
  }, [open]);

  useEffect(() => {
    if (!contract)
      return;

    setLocalObjects(contract.objects);
    setLocalSpecifications(contract.specifications);
  }, [contract]);

  useEffect(() => {
    if (!localFilialId) return;
    dispatch(getFilialByKey(localFilialId)).then(setLocalFilial);
  }, [dispatch, localFilialId]);

  useEffect(() => {
    if (!localDepartmentId) return;
    dispatch(getDepartmentByKey(localDepartmentId)).then(setLocalDepartment);
  }, [dispatch, localDepartmentId]);

  useEffect(() => {
    if (!localPerformerId) return;
    dispatch(getEmployeeByKey(localPerformerId)).then(setLocalPerformer);
  }, [dispatch, localPerformerId]);

  useEffect(() => {
    if (!localDepartmentHeadId) return;
    dispatch(getEmployeeByKey(localDepartmentHeadId)).then(
      setLocalDepartmentHead
    );
  }, [dispatch, localDepartmentHeadId]);

  useEffect(() => {
    if (!localSpecialistId) return;
    dispatch(getEmployeeByKey(localSpecialistId)).then(setLocalSpecialist);
  }, [dispatch, localSpecialistId]);

  useEffect(() => {
    if (!localResponsibleId) return;
    dispatch(getEmployeeByKey(localResponsibleId)).then(setLocalResponsible);
  }, [dispatch, localResponsibleId]);

  return (
      <DialogLayout open={open} setOpen={setOpen} loading={loading} title="Заявка на исследование без акта: Создание">

      <Form onSubmit={handleSubmit}>
        <BasicModalContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={contractRequest?.id}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Автор заявки"
                  value={localCreatedBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <EmployeeField
                    style={{ marginBottom: "5px" }}
                    label={"Исполнитель заявки"}
                    object={localPerformer}
                    value={localPerformerId}
                    onChange={(val) => {
                      setLocalPerformer(val)
                      setLocalPerformerId(val?.id)
                    }}
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection heading="Договор" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextField
                  label="Номер договора"
                  type="number"
                  value={contract?.id || 0}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Статус по договору"
                  type="text"
                  value={contract?.state?.name || ""}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата договора"
                  value={contract?.actionAt}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Объекты (${localObjects?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    {
                      id: "name",
                      label: t("object.tableItem.name"),
                    },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={localObjects}
                  disabled={true}
                  selected={selectedObjects}
                  setSelected={setSelectedObjects}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection
            heading={`Услуги (${localSpecifications?.length || 0})`}
            mb={5}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                      render: ({ id, count }) => (
                        <TextField
                          type="number"
                          variant="standard"
                          onWheel={event => event.target.blur()}
                          value={count}
                          size="small"
                          InputProps={{
                            inputProps: { min: 0, maxLength: 5 },
                          }}
                          sx={{ margin: 0 }}
                          onChange={(e) =>
                            handleSpecificationQuantityChange(e, id)
                          }
                          onInput={(e) => {
                            e.preventDefault();
                          }}
                        />
                      ),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={localSpecifications}
                  disabled={true}
                  selected={selectedSpecifications}
                  setSelected={setSelectedSpecifications}
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Лаборатория" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <DepartmentSelect
                    style={{ marginBottom: "5px" }}
                    label={"Лаборатория"}
                    fullWidth
                    value={localDepartmentId}
                    onChange={(val, obj) => {
                      setLocalDepartmentId(val)
                      setLocalDepartment(obj)
                    }}
                />
              </Grid>
              <Grid item xs={5}>
                <EmployeeField
                    label={"Заведующий лаборатории"}
                    object={localResponsible}
                    value={localResponsibleId}
                    onChange={(responsible) => {
                      setLocalResponsibleId(responsible.id)
                      setLocalResponsible(responsible)
                    }}
                />
              </Grid>
              <Grid item xs={2}>
                <CustomDatePicker
                  label="Дата выезда"
                  value={localDateOfDeparture}
                  onChange={handleDateOfDepartureChange}
                />
              </Grid>
            </Grid>
          </FormSection>
        </BasicModalContainer>

        <BasicModalContainer>
          <Grid container spacing={2}>
            <Grid item>
              <LoadingButton
                variant="contained"
                loading={loading}
                type="submit"
              >
                Создать заявку
              </LoadingButton>
            </Grid>
          </Grid>
        </BasicModalContainer>
      </Form>
      </DialogLayout>
  );
};
