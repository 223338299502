import React, {useCallback, useMemo, useState} from "react";
import useDialogs from "../components/Dialog/useDialogs";


export default function useValidations(data, rules) {
  const {alert} = useDialogs();
  const errors = useMemo(() => {
    return rules.reduce((acc, item) => {
      item.rules.map(rule => {
        if(rule === 'required' && !data[item.code]) {
          acc.push(`'${item.label}' - обязательное поле.`)
        }
      })
      return acc;
    }, [])
  }, [data, rules]);
  const renderedErrors = useMemo(() => errors.map((error, i) => <div style={{display: 'flex', flexDirection: 'column'}} key={i}>{error}</div>), [errors])
  const showErrorsAlert = useCallback(() => {
    alert("Ошибки валидации", renderedErrors);
  }, [alert, renderedErrors]);
  const isValid = useMemo(() => !errors.length, [errors])

  return {isValid, errors, renderedErrors, showErrorsAlert};
}