
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { dashboardCRUD } from "../../http";
import { yearEnd, yearStart } from "../../functions/dateHelpers";
import FilterDatePicker from "../../components/DatePicker/FilterDatePiecker";
import { Button, Stack } from "@mui/material";
import { Divider } from "antd";
import { LoadingButton } from "@mui/lab";
import {sum} from "../../functions/linqSimulation";
import { roundTo2 } from "../../functions/formatters";

const RequestTarificationsQuantity = () => {
    const start = yearStart();
    const end = yearEnd();
    
    const [query, setQuery] = useState({
        paging: { skip: 0 },
        filter: {
            researchEndDateTime: {
                operand1: start,
                operand2: end,
                operator: "between"
            }
        }
    });
    const [yearlyQuery, setYearlyQuery] = useState({
        paging: { skip: 0 },
        filter: {
            researchEndDateTime: {
                operand1: start,
                operand2: end,
                operator: "between"
            }
        }
    });
    const [year, setYear] = useState(null);
    const [yearlyData, setYearlyData] = useState(null);    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAsync = async () => {
        setLoading(true);        

        const response = await dashboardCRUD.post('requestTarifications/quantity', query);
        setData(response);

        setLoading(false);
    }

    const fetchYearlyAsync = async () => {
        setLoading(true);        

        const response = await dashboardCRUD.post('requestTarifications/quantity', yearlyQuery);
        setYearlyData(response);              

        setLoading(false);
    }

    useEffect(() => {
        const newYear = yearlyQuery.filter.researchEndDateTime.operand1.getUTCFullYear();

        if (year == newYear) {
            return;
        }

        fetchYearlyAsync();

        setYear(newYear);
    }, [yearlyQuery]);

    return (
        <DashboardLayout>
            <Stack direction="row" alignItems="center" justifyContent="normal">
                <label style={{minWidth:"360px"}}>Исполнение государственного заказа за ПЕРИОД</label>
                <FilterDatePicker key="start" label="от" value={query.filter.researchEndDateTime.operand1} handleFunction={(value) =>
                {
                    if (!value) {
                        return;
                    }
                    query.filter.researchEndDateTime.operand1 = value;
                    setQuery({...query});

                    const year = value.getUTCFullYear();
                    yearlyQuery.filter.researchEndDateTime.operand1 = yearStart(year);
                    setYearlyQuery({...yearlyQuery});
                }} style={{width:"250px"}} />
                <FilterDatePicker key="start" label="до" value={query.filter.researchEndDateTime.operand2} handleFunction={(value) =>
                {
                    if (!value) {
                        return;
                    }
                    query.filter.researchEndDateTime.operand2 = value;
                    setQuery({...query});

                    const year = value.getUTCFullYear();
                    yearlyQuery.filter.researchEndDateTime.operand2 = yearEnd(year);
                    setYearlyQuery({...yearlyQuery});
                }} style={{width:"250px", marginLeft: "10px"}} />
                <LoadingButton loading={loading} style={{marginLeft: "10px"}} onClick={() => fetchAsync()}>
                    Сформировать
                </LoadingButton>                  
            </Stack>

            <div style={{height: "10px"}}></div>
            
            <table>
                <thead className="ant-table-thead">
                    <tr>
                        <th rowSpan={2} className="ant-table-cell">

                        </th>
                        <th rowSpan={2} style={{verticalAlign:"top"}} className="ant-table-cell">
                            Договора c ДСЭК  (Регионы)
                        </th>
                        <th rowSpan={2} style={{verticalAlign:"top"}} className="ant-table-cell">
                        План в сумме за выбранный период 
                        </th>
                        <th colSpan={2} className="ant-table-cell">
                        факт исполнение госзаказа за выбранный период по сумме
                        </th>
                        <th colSpan={2} style={{verticalAlign:"top"}} className="ant-table-cell">
                        % исполнения 
                        </th>
                        <th rowSpan={2} style={{verticalAlign:"top"}} className="ant-table-cell">
                        План по количеству услуг за выбранный период 
                        </th>
                        <th colSpan={2} className="ant-table-cell">
                        факт исполнение госзаказа за выбранный период по количеству услуг
                        </th>
                        <th colSpan={2} style={{verticalAlign:"top"}} className="ant-table-cell">
                        % исполнения 
                        </th>
                    </tr>
                    <tr>                                                
                        <th>
                        лабораторным исследованиям
                        </th>
                        <th>
                        ДДД
                        </th>
                        <th>
                        За выбранный период
                        </th>
                        <th>
                        от годового
                        </th>
                        <th>
                        лабораторным исследованиям
                        </th>
                        <th>
                        ДДД
                        </th>
                        <th>
                        За выбранный период
                        </th>
                        <th>
                        от годового
                        </th>
                    </tr>
                </thead>
                <tbody className="ant-table-tbody">
                    {data && data.map((_, index) => {
                        const yearlyItem = yearlyData.find(i => i.name == _.name);
                        let relativeSumPercent = 0;
                        const sumPercent = _.planSum == 0 ? 0 : Math.round(_.sum * 100 / _.planSum);
                        if (yearlyItem) {
                            const yearlySumPercent = yearlyItem.planSum == 0 ? 0 : Math.round(yearlyItem.sum * 100 / yearlyItem.planSum);
                            relativeSumPercent = yearlySumPercent == 0 ? 0 : Math.round(sumPercent * 100 / yearlySumPercent);
                        }
                        let relativeQuantityPercent = 0;
                        const quantityPercent = _.planQuantity == 0 ? 0 : Math.round(_.quantity * 100 / _.planQuantity);
                        if (yearlyItem) {
                            const yearlyQuantityPercent = yearlyItem.planQuantity == 0 ? 0 : Math.round(yearlyItem.quantity * 100 / yearlyItem.planQuantity);
                            relativeQuantityPercent = yearlyQuantityPercent == 0 ? 0 : Math.round(quantityPercent * 100 / yearlyQuantityPercent);
                        }
                        return <tr className="ant-table-row">
                            <td className="ant-table-cell">{index + 1}</td>
                            <td className="ant-table-cell">{_.name}</td>
                            <td className="ant-table-cell">{roundTo2(_.planSum)}</td>
                            <td className="ant-table-cell">{roundTo2(_.notDesinfectionSum)}</td>
                            <td className="ant-table-cell">{roundTo2(_.desinfectionSum)}</td>
                            <td className="ant-table-cell">{sumPercent + "%"}</td>
                            <td className="ant-table-cell">{relativeSumPercent + "%"}</td>
                            <td className="ant-table-cell">{_.planQuantity}</td>
                            <td className="ant-table-cell">{_.notDesinfectionQuantity}</td>
                            <td className="ant-table-cell">{_.desinfectionQuantity}</td>
                            <td className="ant-table-cell">{quantityPercent + "%"}</td>
                            <td className="ant-table-cell">{relativeQuantityPercent + "%"}</td>
                        </tr>})
                    }
                    {data.length > 0 && <tr className="ant-table-row" style={{backgroundColor:"#3cb37188"}}>
                        <td></td>
                        <td>Всего:</td>
                        <td>{roundTo2(sum(data, _ => _.planSum))}</td>
                        <td>{roundTo2(sum(data, _ => _.notDesinfectionSum))}</td>
                        <td>{roundTo2(sum(data, _ => _.desinfectionSum))}</td>
                        <td></td>
                        <td></td>
                        <td>{sum(data, _ => _.planQuantity)}</td>
                        <td>{sum(data, _ => _.notDesinfectionQuantity)}</td>
                        <td>{sum(data, _ => _.desinfectionQuantity)}</td>
                        <td></td>
                        <td></td>
                    </tr>}
                </tbody>
            </table>
        </DashboardLayout>
    )

};

export default RequestTarificationsQuantity;
