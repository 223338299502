import { 
    ACT_GET_REQUEST, ACT_GET_SUCCESS, ACT_GET_ERROR, 
    ACT_GET_BY_KEY_REQUEST, ACT_GET_BY_KEY_SUCCESS, ACT_GET_BY_KEY_ERROR, 
    ACT_ADD_REQUEST, ACT_ADD_SUCCESS, ACT_ADD_ERROR, 
    ACT_EDIT_REQUEST, ACT_EDIT_SUCCESS, ACT_EDIT_ERROR, 
    ACT_DELETE_REQUEST, ACT_DELETE_SUCCESS, ACT_DELETE_ERROR
} from "../constants/actConstants";

const initialState = {
    acts: [],
    act: {},
    loading: false,
    success: null,
    error: null,
    actRequestedCount: 0,
};

export const actReducer = (state = initialState, action) => {
    switch (action.type)     {
        case ACT_GET_REQUEST:
            return {
                ...state,
                actRequestedCount: ++state.actRequestedCount,
                loading: true,
            };
        case ACT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                acts: action.payload,
                success: true,
            };
        case ACT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                act: action.payload,
                success: true,
            };
        case ACT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
