import React, { useCallback, useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../../layouts/Dashboard";
import { useHistory, useParams } from "react-router-dom";
import {
    Checkbox,
    CircularProgress,
    Dialog,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import ReferalRadio from "../ReferalRadio";
import {
    benefitsToCDI,
    expressTests,
    probCodes,
    probStatus,
    statusPatient,
} from "../stateReferal";
import { v4 as uuidv4 } from "uuid";
import CollectionTube from "../CollectionTube";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../../hooks/useLoader";
import { materialTypeCRUD, referalsCRUD } from "../../../http/CRUD";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    REFERRAL,
    REFERRAL_DETAIL_ROUTE,
    REFERRAL_LIST_ROUTE,
} from "../../../routes/const";
import Toolbar from "@mui/material/Toolbar";
import {
    REQUEST_STATE_ID_APPROVING,
    REQUEST_STATE_ID_COMPLETED,
    REQUEST_STATE_ID_CREATED,
    REQUEST_STATE_ID_DECLINED,
    REQUEST_STATE_ID_PROCESSING,
    REQUEST_STATE_ID_RESEARCH,
} from "../../../redux/constants/requestStateConstants";
import { formatDate } from "../../../functions/formatters";
import PatientField from "../../../components/Relations/PatientField";
import { Radio } from "../../../components/Radio/Radio";
import Select from "../../../components/Select/Select";
import CreateResult from "../../Result/Popups/CreateResult";
import { useTranslation } from "react-i18next";
import RemovePopup from "../../../components/Popups/RemovePopup";
import {
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect,
} from "../../../components/Select/domainSelects";
import { BooleanRadio } from "../../../components/Radio/radios";
import CreateProtocol from "../../Protocol/Popups/CreateProtocol";
import { addProtocol } from "../../../redux/actions/protocolActions";
import Tooltip from "@mui/material/Tooltip";
import { Document, Page } from "react-pdf";
import Box from "@mui/material/Box";
import EmployeeField from "../../../components/Relations/EmployeeField";
import useDialogs from "../../../components/Dialog/useDialogs";
import CheckboxesMultiColumnField from "../../../components/Fields/CheckboxesMultiColumnField";
import ServiceListField from "../../../components/Relations/ServiceListField";
import DictionaryField from "../../../components/Relations/DictionaryField";
import { mapReferralForSubmit } from "../../../functions/viewDataMap";
import { useLocation } from "react-router-dom";

import { Barcode } from "../../../components";
import { RequestStateChecker, asJSONSafe } from "../../../functions";
import { barcodeToPdf } from "../../../functions/barcodeHelpers";
import { FormSection } from "../../../components";

import ResultsAndProtocolsSection from "../../../sections/ResultsAndProtocolsSection";
import EditResultAndProtocolSection from "../../../sections/EditResultAndProtocolSection";
import { protocolCRUD, resultCRUD } from "../../../http";

import { validateReferralSpecificationsForSubmit } from "../../../functions/validations";
import { REQUEST_TYPE_ID_ReferralRequest } from "../../../redux/constants/requestTypeConstants";
import { confirmDialog } from '../../../components/Dialog/DialogDelete';
import { commentDialog } from "../../../components/Dialog/CommentDialog";

import { IfElse as If } from "../../../components/ConditionalContainer/IfElse";

import useReload from "../../../hooks/useReload";
import { validateReferralForSubmit } from "../../../functions/validations";
import { toUIDate } from "../../../functions/dateHelpers";
import { all } from "../../../functions/linqSimulation";
import { PROTOCOL_STATE_IDS_SIGNED } from "../../../redux/constants/protocolConstants";

export const ReferralDetail = () => {
    const location = useLocation();
    const { alert, DIALOG } = useDialogs();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { push, goBack } = useHistory();
    const { diseases } = useSelector((state) => state.disease);

    const { id } = useParams();
    const isNew = useMemo(() => !(id > 0), [id]);

    const [data, setData] = useState({
        paymentPassed: false,
        version: 2
    });
    const handleChange = useCallback(
        (key, value) => {
            setData({ ...data, [key]: value });
        },
        [data]
    );

    const { reload, reloadValue } = useReload();

    const stateCode = data.state?.code;
    const state = new RequestStateChecker(stateCode);

    // TRASH**
    const handleChangeMulti = useCallback(
        (obj) => {
            setData({ ...data, ...obj });
        },
        [data]
    );

    const [targetPatient, setTargetPatient] = useState(null);
    const handleChangePatient = useCallback(
        (key, value) => setTargetPatient({ ...targetPatient, [key]: value }),
        [targetPatient]
    );

    const [newPatient, setNewPatient] = useState(null);
    const handleChangeNewPatient = useCallback(
        (key, value) => setNewPatient({ ...newPatient, [key]: value }),
        [newPatient]
    );

    const [testTubes, setTestTubes] = useState([]);
    const [specifications, setSpecifications] = useState([]);
    // endregion

    // region Form edit/view
    const [formType, setFormType] = useState("edit");
    const isEditing = true;
    const isProcessing = isNew || isEditing;
    // endregion

    const fetchMaterialType = async (code) => {
        const response = await materialTypeCRUD.search({
            paging: { take: 1 },
            filter: {
                code: { operand1: code, operator: 'equals' }
            }
        });
        const result = response.result;
        const item = result.length > 0 ? result[0] : null;

        if (!item)
            return;

        setData({ ...data, materialType: item });
    }

    useEffect(() => {
        if (!data.probeCode)
            return;

        fetchMaterialType(data.probeCode);

    }, [data.probeCode]);


    // useEffect(() => {
    //     if (!data.diagnosis)
    //         return;

    //     if (data.diagnosis) {
    //         data.diagnosis = asJSONSafe(data.diagnosis);
    //         data.diagnosisId = data.diagnosis?.id;
    //     }        
    // }, [data.diagnosis]);

    const renderItemGrid = (children, xs = 12) => (
        <Grid item xs={xs}>
            {children}
        </Grid>
    );

    const renderDateField = (key, label, xs = 12) => {
        return (
            <Grid item xs={xs}>
                <FormControl component="fieldset">
                    {label && <FormLabel component="legend">{label}</FormLabel>}
                    <TextField
                        type={"date"}
                        margin="normal"
                        value={toUIDate(data[key])}
                        label="Дата"
                        onChange={(e) => handleChange(key, e.target.value)}
                        fullWidth
                        id={key}
                        name={key}
                    />
                </FormControl>
            </Grid>
        );
    };

    const [editResultOpen, setEditResultOpen] = useState(false);
    const [editResultId, setEditResultId] = useState(null);
    const [editProtocolOpen, setEditProtocolOpen] = useState(false);
    const [editProtocol, setEditProtocol] = useState(null);

    const handleEditResultOpen = async (resultId) => {
        setEditResultId(resultId);
        setEditResultOpen(true);
    }

    const handleEditProtocolOpen = async (protocolId) => {
        await startSubmit();

        const protocol = await protocolCRUD.get(protocolId);
        await setEditProtocol(protocol);
        await setEditProtocolOpen(true);

        await stopSubmit();
    }

    // region Fetch
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const fetch = useCallback(() => {
        if (isNew) {
            fetchStop();
            return;
        }
        fetchStart();
        referalsCRUD
            .get(id)
            .then((data) => {
                if (!data) {
                    alert('Нет данных (неверный ID или данная запись удалена)!');
                    return;
                }
                if (data.diagnosis) {
                    data.diagnosis = asJSONSafe(data.diagnosis);
                }
                if (data.patientStatus) {
                    data.patientStatus = asJSONSafe(data.patientStatus);
                }
                if (data.externalState) {
                    data.externalState = asJSONSafe(data.externalState);
                }
                setData({ ...data });
                setTargetPatient({ ...data.targetPatient });
                setTestTubes([...(data.testTubes || [])]);
                setSpecifications([...(data.specifications || [])]);
                setFormType(
                    [REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_DECLINED].includes(
                        data.state?.code
                    )
                        ? "view"
                        : "edit"
                );
                // if (data.diagnosis) {
                //     data.diagnosis = asJSONSafe(data.diagnosis);
                // }
            })
            .catch(alert)
            .finally(fetchStop);
    }, [id, fetchStart, fetchStop, isNew]);
    useEffect(fetch, [fetch]);
    // endregion

    // region Submit
    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: loadingSubmit,
    } = useLoader(false);
    const submitData = useMemo(() => {
        const submitData = mapReferralForSubmit(
            data,
            newPatient,
            targetPatient,
            testTubes
        );
        return submitData;
    }, [data, targetPatient, testTubes, specifications, newPatient]);

    const submit = useCallback(
        () =>
            new Promise((resolve, reject) => {
                startSubmit();


                if (!validateReferralForSubmit(submitData, alert, REQUEST_TYPE_ID_ReferralRequest)) {
                    stopSubmit();
                    return;
                }

                (isNew
                    ? referalsCRUD.create(submitData)
                    : referalsCRUD.edit(submitData)
                )
                    .then((response) => {
                        if (isNew && response.id) {
                            push(REFERRAL_DETAIL_ROUTE(response.id));
                        } else {
                            fetch();
                        }
                        resolve();
                        // onSubmit();
                    })
                    .catch((error) => {
                        reject();
                        alert(error);
                    })
                    .finally(stopSubmit);
            }),
        [submitData, startSubmit, stopSubmit, isNew, fetch, push]
    );
    // endregion

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const [protocolAddOpen, setProtocolAddOpen] = useState(false);
    const [protocolViewPdf, setProtocolViewPdf] = useState(false);
    const [protocolViewOpen, setProtocolViewOpen] = useState(false);
    const [createResultOpen, setCreateResultOpen] = useState(false);
    const [deleteReferralOpen, setDeleteReferralOpen] = useState(false);

    const [validationError, setValidationError] = useState(false)

    const title = useMemo(() => {
        return isNew
            ? "Создание единоразовой заявки"
            : `Единоразовая заявка ` +
            `№${data.number}.`;
    }, [data, isNew, isEditing]);

    const hasResults = useMemo(
        () => data.results && data.results.length > 0,
        [data]
    );
    const hasProtocols = useMemo(
        () => data.protocols && data.protocols.length > 0,
        [data]
    );

    const {
        start: workflowStart,
        stop: workflowStop,
        loading: workflowLoading,
    } = useLoader(false);
    const renderedStatusLine = useMemo(() => {
        const style = { marginLeft: 10 },
            stateCode = data.state?.code;

        const setStatus = (lastStateCode, nextStateCode, thenAction = fetch, comment) => {
            const validates = [];
            const validateNull = (key, label) => {
                if (!data[key]) validates.push(label + " нужно заполнить!");
            };
            if (nextStateCode === REQUEST_STATE_ID_PROCESSING) {
                validateNull("departmentId", "Лаборатория");
                validateNull(
                    "responsibleId",
                    "Заведующий лаборатории/Начальник отдела"
                );
            } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
                validateNull("performerId", "Лаборант");
            }
            if (validates.length > 0) {
                alert(validates.join("\n"));
                return;
            }

            workflowStart();
            submit()
                .then(() => referalsCRUD.workflowRun(id, lastStateCode, nextStateCode, comment))
                .then(thenAction)
                .catch(alert)
                .finally(workflowStop);
        };
        // REQUEST_STATE_ID_CREATED
        // REQUEST_STATE_ID_PROCESSING
        // REQUEST_STATE_ID_RESEARCH
        // REQUEST_STATE_ID_COMPLETED
        // REQUEST_STATE_ID_DECLINED
        return (
            <Grid item xs={12}>
                <Paper>
                    <Toolbar>
                        <Typography style={{ marginRight: "auto" }} variant={"h6"}>
                            Статус заявки: {data.state?.name || "создание новой заявки"}
                            {stateCode === REQUEST_STATE_ID_DECLINED && data.declineComment && <span style={{ "margin-left": "15px" }}>Комментарий: {data.declineComment}</span>

                            }
                        </Typography>
                        {REQUEST_STATE_ID_CREATED === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() => {
                                    const valid = validateReferralSpecificationsForSubmit(data?.specifications, null, null, alert, REQUEST_TYPE_ID_ReferralRequest,
                                        null, data?.researchEndDateTime);

                                    if (!valid) {
                                        setValidationError(true)
                                        return;
                                    }

                                    setValidationError(false)

                                    confirmDialog('Изменение статуса', 'Вы действительно хотите изменить статус этой заявки?', () => {
                                        return setStatus(
                                            REQUEST_STATE_ID_CREATED,
                                            REQUEST_STATE_ID_PROCESSING,
                                            () => push(REFERRAL)
                                        )
                                    })
                                }}
                            >
                                Отправить в лабораторию
                            </LoadingButton>
                        )}
                        {REQUEST_STATE_ID_PROCESSING === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() => {
                                    confirmDialog('Изменение статуса', 'Вы действительно хотите изменить статус этой заявки?', () => {
                                        return setStatus(
                                            REQUEST_STATE_ID_PROCESSING,
                                            REQUEST_STATE_ID_RESEARCH
                                        )
                                    })
                                }
                                }
                            >
                                Назначить исполнителя
                            </LoadingButton>
                        )}
                        {REQUEST_STATE_ID_APPROVING === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() =>
                                    setStatus(
                                        REQUEST_STATE_ID_APPROVING,
                                        REQUEST_STATE_ID_RESEARCH,
                                        () => push(REFERRAL_LIST_ROUTE)
                                    )
                                }
                            >
                                Согласовать
                            </LoadingButton> 
                        )}
                         {REQUEST_STATE_ID_APPROVING === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() =>
                                    setStatus(
                                        REQUEST_STATE_ID_APPROVING,
                                        REQUEST_STATE_ID_COMPLETED,
                                        () => push(REFERRAL_LIST_ROUTE)
                                    )
                                }
                            >
                                Отклонить
                            </LoadingButton> 
                        )}
                        {(REQUEST_STATE_ID_PROCESSING === stateCode || REQUEST_STATE_ID_RESEARCH === stateCode) && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                color={"warning"}
                                loading={workflowLoading}
                                onClick={() => {
                                    commentDialog('Изменение статуса', 'Укажите комментарий для отклонения.', (_) => {
                                        return setStatus(
                                            REQUEST_STATE_ID_PROCESSING,
                                            REQUEST_STATE_ID_DECLINED,
                                            () => push(REFERRAL),
                                            _?.comment
                                        )
                                    })
                                }
                                }
                            >
                                Отклонить с комментариями
                            </LoadingButton>
                        )}
                        {REQUEST_STATE_ID_RESEARCH === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() => {
                                    let valid = true;

                                    if (data?.department) {
                                        if (!data.department.completeWithoutProtocol) {
                                            if (data?.protocols.length == 0) {
                                                valid = false;
                                                alert('Ошибка валидации', 'Данной лаборатории запрещено завершать заявку без протокола, пожалуйста добавьте протокол. Или проверьте, что все протоколы подписаны.');
                                            }
                                            if (data?.protocols.find(_ => _.stateId != PROTOCOL_STATE_IDS_SIGNED)) {
                                                valid = false;
                                                alert('Ошибка валидации', 'Не все протоколы подписаны.');
                                            }
                                        }
                                    }

                                    if (!valid) {
                                        setValidationError(true)
                                        return;
                                    }

                                    setValidationError(false)

                                    confirmDialog('Изменение статуса', 'Вы действительно хотите изменить статус этой заявки?', () => {
                                        return setStatus(
                                            REQUEST_STATE_ID_RESEARCH,
                                            REQUEST_STATE_ID_COMPLETED
                                        )
                                    })
                                }
                                }
                            >
                                Завершить
                            </LoadingButton>
                        )}
                        {REQUEST_STATE_ID_RESEARCH === stateCode && !hasProtocols && (
                            <LoadingButton
                                style={style}
                                variant={"outlined"}
                                loading={workflowLoading}
                                onClick={() => setCreateResultOpen(true)}
                            >
                                Добавить запись в журнал
                            </LoadingButton>
                        )}
                        {REQUEST_STATE_ID_RESEARCH === stateCode && (
                            <Tooltip
                                title={
                                    !hasResults
                                        ? "Необходима запись в журнале"
                                        : hasProtocols
                                            ? "Протокол уже создан"
                                            : "Создание протокола"
                                }
                            >
                                <div style={style}>
                                    <LoadingButton
                                        variant={"outlined"}
                                        loading={workflowLoading}
                                        onClick={() => setProtocolAddOpen(true)}
                                        disabled={!hasResults || hasProtocols}
                                    >
                                        Создать протокол
                                    </LoadingButton>
                                </div>
                            </Tooltip>
                        )}
                        {REQUEST_STATE_ID_DECLINED === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() =>
                                    setStatus(REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_CREATED)
                                }
                            >
                                Пересоздать
                            </LoadingButton>
                        )}
                        {REQUEST_STATE_ID_DECLINED === stateCode && (
                            <LoadingButton
                                style={style}
                                variant={"contained"}
                                loading={workflowLoading}
                                onClick={() => {
                                    confirmDialog('Изменение статуса', 'Вы действительно хотите удалить эту заявку?', () => {
                                        return setDeleteReferralOpen(true)
                                    })
                                }}
                                color={"warning"}
                            >
                                Удалить
                            </LoadingButton>
                        )}
                    </Toolbar>
                </Paper>
            </Grid>
        );
    }, [
        id,
        data,
        fetch,
        workflowLoading,
        workflowStop,
        workflowStart,
        push,
        submit,
        hasProtocols,
        hasResults,
    ]);

    const renderedToolbarButtons = useMemo(() => {
        const style = { marginLeft: 10 };
        return (
            <>
                <Button variant={"text"} style={style} onClick={() => goBack()}>
                    Вернуться
                </Button>

                <div style={{ margin: "auto" }} />

                {(isEditing && (!state.isProcessing() && !state.isCompleted() && !state.isResearch()) || isNew) && (
                    <LoadingButton
                        variant="contained"
                        style={style}
                        onClick={submit}
                        loading={loadingSubmit}
                    >
                        {isNew ? "Создать" : "Сохранить"}
                    </LoadingButton>
                )}
            </>
        );
    }, [isNew, isEditing, loadingSubmit, submit, push]);

    const renderToolbar = useCallback(
        (withTitle = true) => {
            return (
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar>
                            {withTitle && (
                                <Typography id="modal-modal-title" variant="h5" component="h2">
                                    {title}
                                    <br />
                                    <Typography style={{ fontSize: 12 }}>
                                        от {formatDate(data.createdAt)}
                                    </Typography>
                                </Typography>
                            )}
                            {withTitle && !isNew && <>
                                <LoadingButton
                                    color="success"
                                    onClick={barcodeToPdf}
                                    sx={{ marginLeft: '10px', marginRight: '20px' }}
                                >
                                    Печать штрих кода
                                </LoadingButton>
                                <div id="div-svg">
                                    <Barcode value={data?.number} />
                                </div>
                            </>
                            }
                            {renderedToolbarButtons}
                        </Toolbar>
                    </Paper>
                </Grid>
            );
        },
        [title, renderedToolbarButtons, data, isNew]
    );

    return (
        <DashboardLayout>
            <DIALOG />

            <Grid container spacing={1}>
                {fetchLoading ? (
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        {renderToolbar(true)}
                        {renderedStatusLine}

                        {/* region FormEdit */}
                        <Grid item xs={12} style={{ paddingTop: 16 }}>
                            {!isNew && data?.results && data?.protocols && <FormSection mb={4}>
                                <ResultsAndProtocolsSection
                                    results={data?.results}
                                    protocols={data?.protocols}
                                    onResultClick={async (_) => {
                                        await handleEditResultOpen(_);
                                    }}
                                    onProtocolClick={async (_) => {
                                        await handleEditProtocolOpen(_);
                                    }}
                                    resultsReloadValue={reloadValue}
                                    fetch={fetch}
                                    hasProtocols={hasProtocols}
                                    startLoading={async () => {
                                        await startSubmit();
                                    }}
                                    stopLoading={async () => {
                                        await stopSubmit();
                                    }}
                                />
                            </FormSection>}
                        </Grid>
                        {data.externalState && <Grid item xs={12} style={{ paddingTop: 16 }}>
                            <Paper>
                                <Grid
                                        container
                                        spacing={1}
                                        style={{ paddingLeft: 20, paddingRight: 20 }}
                                    >
                                    <Grid item xs={12}>
                                        <span><b>НАПРАВЛЕНИЕ</b></span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <span>LIS ID: {data.externalState.Id}</span>
                                    </Grid>
                                    {data.externallyReceived && <Grid item xs={12}>
                                        <span>Результат доставлен во внешнюю систему, время доставки {formatDate(data.externalState.DeliveryTime)}</span>
                                    </Grid>}
                                </Grid>
                            </Paper>
                        </Grid>}
                        {isProcessing && (
                            <Grid item xs={12} style={{ paddingTop: 16 }}>
                                <Paper>
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{ paddingLeft: 20, paddingRight: 20 }}
                                    >
                                        {isEditing && (
                                            <Grid item xs={12}>
                                                <PatientField
                                                    value={targetPatient?.id}
                                                    object={targetPatient}
                                                    onChange={(pat) => setTargetPatient({ ...pat })}
                                                    disabled={!state.isCreated()}
                                                />
                                            </Grid>
                                        )}

                                        {renderItemGrid(
                                            <ServiceListField
                                                label="Список исследований"
                                                error={validationError}
                                                value={data.specifications}
                                                onChange={(val) => {
                                                    if (validationError)
                                                        setValidationError(false)
                                                    handleChange("specifications", val)
                                                }}
                                                disabled={!state.isCreated()}
                                            />
                                        )}

                                        {/* 19. Дата окончания исследования  - todo*/}
                                        {renderDateField(
                                            "researchEndDateTime",
                                            "Дата окончания исследования (цены тарификатора зависят от этой даты)",
                                            12,
                                            { disabled: !state.isCreated() }
                                        )}


                                        {isEditing && (
                                            <>
                                                <Grid item xs={12}>
                                                    <DictionaryField
                                                        entityType="disease"
                                                        label={"Диагноз"}
                                                        dialogTitle={"диагноза"}
                                                        value={data.diagnosis}
                                                        object={data.diagnosis}
                                                        onChange={(val) => {
                                                            setData({
                                                                ...data,
                                                                diagnosis: val,
                                                                //diagnosisId: val?.id
                                                            });
                                                        }}
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>


                                                <Grid item xs={12}>
                                                    <DictionaryField
                                                        entityType="materialType"
                                                        label={"Проба"}
                                                        dialogTitle={"пробы"}
                                                        value={data.probeCode}
                                                        object={data.materialType}
                                                        onChange={(val) => {
                                                            setData({
                                                                ...data,
                                                                materialType: val,
                                                                probeCode: val.code
                                                            });
                                                        }}
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        margin="normal"
                                                        value={data.antibioticUse}
                                                        onChange={(e) =>
                                                            handleChange("antibioticUse", e.target.value)
                                                        }
                                                        fullWidth
                                                        id="antibioticUse"
                                                        name="antibioticUse"
                                                        label="Применение антибиотиков"
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>
                                                {/* <Grid item xs={12}>
                                                    <ReferalRadio
                                                        label="6. Наименование пробы *п (мазок из зева и носа/трупный материал)"
                                                        array={probCodes}
                                                        value={data.probeCode}
                                                        setValue={(val) => handleChange("probeCode", val)}
                                                        required
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <ReferalRadio
                                                        label="7. Статус отбора пробы"
                                                        array={probStatus}
                                                        value={data.probeStatus}
                                                        setValue={(val) => handleChange("probeStatus", val)}
                                                        required
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <CheckboxesMultiColumnField
                                                        label={"8. Статус пациента"}
                                                        value={data.patientStatus}
                                                        onChange={(val) =>
                                                            handleChange("patientStatus", val)
                                                        }
                                                        options={statusPatient.map((label) => ({
                                                            label,
                                                            value: label,
                                                        }))}
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <BooleanRadio
                                                        label={"Наличие клинических симптомов КВИ"}
                                                        value={data.clinicalSymptomsCVI}
                                                        onChange={(val) =>
                                                            handleChange("clinicalSymptomsCVI", val)
                                                        }
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Radio
                                                        label="Результаты экспресс теста"
                                                        required
                                                        options={expressTests.map((et, i) => ({
                                                            label: et,
                                                            value: i + 1,
                                                        }))}
                                                        value={data.expressTestResult}
                                                        onChange={(val) =>
                                                            handleChange("expressTestResult", val)
                                                        }
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        margin="normal"
                                                        value={data.sampleSentOrganizationName}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "sampleSentOrganizationName",
                                                                e.target.value
                                                            )
                                                        }
                                                        fullWidth
                                                        id="sampleSentOrganizationName"
                                                        name="sampleSentOrganizationName"
                                                        label="13. Наименование учреждения направившего образец"
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        required
                                                        margin="normal"
                                                        value={data.referralNumber}
                                                        onChange={(e) =>
                                                            handleChange("referralNumber", e.target.value)
                                                        }
                                                        fullWidth
                                                        id="referralNumber"
                                                        name="referralNumber"
                                                        label="Номер направления"
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        required
                                                        margin="normal"
                                                        value={data.probeNumber}
                                                        onChange={(e) =>
                                                            handleChange("probeNumber", e.target.value)
                                                        }
                                                        fullWidth
                                                        id="probeNumber"
                                                        name="probeNumber"
                                                        label="Номер пробы"
                                                        disabled={!state.isCreated()}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={"h5"}
                                                        style={{ display: "inline" }}
                                                    >
                                                        Пробирки
                                                    </Typography>
                                                    <Button variant={"outlined"} style={{ marginLeft: "15px" }}
                                                        disabled={!state.isCreated()}
                                                        onClick={() =>
                                                            setTestTubes([
                                                                ...testTubes,
                                                                { id: uuidv4(), name: "Пусто", quantity: 0 },
                                                            ])
                                                        }
                                                        sx={{ marginLeft: 2 }}
                                                    >
                                                        <AddIcon sx={{ marginRight: 2 }} /> Добавить
                                                    </Button>
                                                </Grid>
                                                <CollectionTube
                                                    setData={(tubes) => {
                                                        setTestTubes([...tubes]);
                                                    }}
                                                    data={testTubes}
                                                    disabled={state.isCompleted()}
                                                />


                                                <Grid container spacing={1} item xs={12}>
                                                    <Grid item xs={2}>
                                                        <Typography variant="h6" component="h2">
                                                            Оплата по ФЛ
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                label="Оплата прошла"
                                                                checked={data.paymentPassed}
                                                                onChange={(event) =>
                                                                    handleChange(
                                                                        "paymentPassed",
                                                                        event.target.checked
                                                                    )
                                                                }
                                                                control={<Checkbox defaultChecked />}
                                                                disabled={!state.isCreated()}
                                                            />
                                                        </FormGroup>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            options={benefitsToCDI.map((label, value) => ({
                                                                label,
                                                                value,
                                                            }))}
                                                            value={data.benefitsToCDI}
                                                            onChange={(val) =>
                                                                handleChange("benefitsToCDI", val)
                                                            }
                                                            fullWidth
                                                            id="benefitsToCDI"
                                                            name="benefitsToCDI"
                                                            label="Льготы на право получения услуг по КДИ на безвозмездной основе предоставляются следующим лицам/договор с поликлиникой"
                                                            required
                                                            disabled={!state.isCreated()}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <EmployeeField
                                                            label={"Ответственный за отбор проб"}
                                                            value={data.responsibleForProbesId}
                                                            object={data.responsibleForProbes}
                                                            onChange={(val) => {
                                                                setData({
                                                                    ...data,
                                                                    responsibleForProbesId: val?.id,
                                                                    responsibleForProbes: val,
                                                                });
                                                            }}
                                                            disabled={!state.isCreated()}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        Подразделение
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <If condition={state.isCreated()}
                                                            elseIf={
                                                                <TextField
                                                                    margin="normal"
                                                                    value={data.filial?.name}
                                                                    fullWidth
                                                                    id="filialId"
                                                                    name="filialId"
                                                                    label="Филиал"
                                                                    disabled={true}
                                                                />
                                                            }>
                                                            <FilialSelect
                                                                label={"Филиал"}
                                                                fullWidth
                                                                value={data.filialId}
                                                                onChange={(val) => {
                                                                    handleChangeMulti({
                                                                        filialId: val,
                                                                        filialDivisionId:
                                                                            Number(val) !== Number(data.filialId)
                                                                                ? null
                                                                                : data.filialDivisionId,
                                                                    });
                                                                }}
                                                            />
                                                        </If>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <If condition={state.isCreated()}
                                                            elseIf={
                                                                <TextField
                                                                    margin="normal"
                                                                    value={data.filialDivision?.name}
                                                                    fullWidth
                                                                    id="filialDivisionId"
                                                                    name="filialDivisionId"
                                                                    label="Районное отделение"
                                                                    disabled={true}
                                                                />
                                                            }
                                                        >
                                                            <FilialDivisionSelect
                                                                label={"Районное отделение"}
                                                                fullWidth
                                                                value={data.filialDivisionId}
                                                                filialId={data.filialId}
                                                                onChange={(filialDivisionId, filialDivision) => {
                                                                    handleChangeMulti({
                                                                        filialDivisionId: filialDivisionId,
                                                                        filialId:
                                                                            Number(filialDivision.filialId) !==
                                                                                Number(data.filialId)
                                                                                ? filialDivision.filialId
                                                                                : data.filialId,
                                                                    });
                                                                }}
                                                            />
                                                        </If>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Typography variant={"h5"}>
                                                            Передача проб в лабораторию:
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <If condition={state.isCreated()}
                                                            elseIf={
                                                                <TextField
                                                                    margin="normal"
                                                                    value={data.department?.name}
                                                                    fullWidth
                                                                    id="departmentId"
                                                                    name="departmentId"
                                                                    label="Районное отделение"
                                                                    disabled={true}
                                                                />
                                                            }
                                                        >
                                                            <DepartmentSelect
                                                                label={"Лаборатория"}
                                                                fullWidth
                                                                value={data.departmentId}
                                                                onChange={(val) =>
                                                                    handleChange("departmentId", val)
                                                                }
                                                            />
                                                        </If>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <If condition={state.isCreated()}
                                                            elseIf={
                                                                <TextField
                                                                    margin="normal"
                                                                    value={data.division?.name}
                                                                    fullWidth
                                                                    id="divisionId"
                                                                    name="divisionId"
                                                                    label="Отдел"
                                                                    disabled={true}
                                                                />
                                                            }
                                                        >
                                                            <DivisionSelect
                                                                label={"Отдел"}
                                                                fullWidth
                                                                value={data.divisionId}
                                                                onChange={(val) =>
                                                                    handleChange("divisionId", val)
                                                                }
                                                                disabled={!state.isCreated()}
                                                                filterOptions={(opt) => opt.id !== 5 && (!data.departmentId || (Number(opt.departmentId) === Number(data.departmentId)))}
                                                            />
                                                        </If>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <EmployeeField
                                                            label={"Заведующий лаборатории/Начальник отдела"}
                                                            fullWidth
                                                            object={data.responsible}
                                                            value={data.responsibleId}
                                                            onChange={(responsible) => {
                                                                const obj = {
                                                                    responsible,
                                                                    responsibleId: responsible.id,
                                                                    //departmentId: responsible.departmentId,
                                                                };
                                                                handleChangeMulti(obj);
                                                            }}
                                                            disabled={!state.isCreated()}
                                                        />
                                                        {/* <EmployeesSelect label={"Заведующий лаборатории/Начальник отдела"} fullWidth value={data.responsibleId} onChange={(val) => handleChange('responsibleId', val)}/> */}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <EmployeeField
                                                            label={"Лаборант"}
                                                            fullWidth
                                                            object={data.performer}
                                                            value={data.performerId}
                                                            onChange={(performer) => {
                                                                const obj = {
                                                                    performer,
                                                                    performerId: performer.id,
                                                                };
                                                                handleChangeMulti(obj);
                                                            }}
                                                            disabled={!state.isCreated() && !state.isProcessing()}
                                                        />
                                                        {/* <EmployeesSelect label={"Лаборант"} fullWidth value={data.performerId} onChange={(val) => handleChange('performerId', val)}/> */}
                                                    </Grid>
                                                </Grid>

                                                {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                                                {/*  <Grid item xs={6}>*/}
                                                {/*    <TextField label="Ссылка на договор ЮЛ или ИП" margin="normal" fullWidth select>*/}
                                                {/*      <MenuItem value={0}>*/}
                                                {/*        <em>Нет</em>*/}
                                                {/*      </MenuItem>*/}
                                                {/*    </TextField>*/}
                                                {/*  </Grid>}*/}
                                                {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                                                {/*  <Grid item xs={6}>*/}
                                                {/*    <TextField label="Ссылка на договор ФЛ" margin="normal" fullWidth select>*/}
                                                {/*      <MenuItem value={0}>*/}
                                                {/*        <em>Нет</em>*/}
                                                {/*      </MenuItem>*/}
                                                {/*    </TextField>*/}
                                                {/*  </Grid>}*/}
                                                {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                                                {/*  <Grid item xs={6}>*/}
                                                {/*    <TextField label="Ссылка на сводную заявку" margin="normal" fullWidth select>*/}
                                                {/*      <MenuItem value={0}>*/}
                                                {/*        <em>Нет</em>*/}
                                                {/*      </MenuItem>*/}
                                                {/*    </TextField>*/}
                                                {/*  </Grid>}*/}
                                                {/*  /!* todo Надо будет включить на определённом статусе*/}
                                                {/*                  <Grid item xs={12}>*/}
                                                {/*                      <Typography variant="h6" component="h2">*/}
                                                {/*                      Заказные услуги*/}
                                                {/*                      </Typography>*/}
                                                {/*                  </Grid>*/}
                                                {/*                  <Grid item xs={12}>*/}
                                                {/*                      Блок для добавления услуг (Service)*/}
                                                {/*                  </Grid>*/}
                                                {/*                  <Grid item xs={12}>*/}
                                                {/*                      <ReferalTable row={tableRow} setRow={setTableRow}/>*/}
                                                {/*                  </Grid>*!/*/}
                                                <Grid item xs={12} />
                                            </>
                                        )}
                                    </Grid>
                                </Paper>
                            </Grid>
                        )}

                        {renderToolbar(false)}
                    </>
                )}
            </Grid>
            {createResultOpen && (
                <CreateResult
                    action={"createByReferral"}
                    title={t("resultList.createByReferralText")}
                    onClose={() => setCreateResultOpen(false)}
                    onSubmit={async (response) => {
                        fetch();
                    }}
                    referral={data}
                />
            )}
            {deleteReferralOpen && (
                <RemovePopup
                    deleteAction={() => referalsCRUD.delete(id)}
                    title={`Вы действительно хотите удалить сводную заявку №${data.number}?`}
                    onClose={() => setDeleteReferralOpen(false)}
                    onSubmit={() => push(REFERRAL_LIST_ROUTE)}
                />
            )}
            {protocolAddOpen && (
                <CreateProtocol
                    action={"createByReferral"}
                    open={protocolAddOpen}
                    initNumber={data.number}
                    title={`Создание протокола для сводной заявки №${data.number}?`}
                    onClose={() => setDeleteReferralOpen(false)}
                    onSubmit={async (data) => {
                        return dispatch(
                            addProtocol(data, { sort: { id: { operator: "asc" } } })
                        ).then(fetch);
                    }}
                    setOpen={setProtocolAddOpen}
                    request={data}
                    referral={data}
                />
            )}
            <EditResultAndProtocolSection
                editResultOpen={editResultOpen}
                setEditResultOpen={setEditResultOpen}
                editProtocolOpen={editProtocolOpen}
                setEditProtocolOpen={setEditProtocolOpen}
                editResultId={editResultId}
                editProtocol={editProtocol}
                startLoading={async () => {
                    await startSubmit();
                }}
                stopLoading={async () => {
                    await stopSubmit();
                }}
                onEditResultSubmitted={async (_) => {
                    reload();
                }}
                results={data.results}
            />
            <Dialog
                open={protocolViewOpen}
                onClose={() => setProtocolViewOpen(false)}
                fullWidth
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Document
                                file={protocolViewPdf}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </DashboardLayout>
    );
};
