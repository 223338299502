import { requestLockCRUD } from "../../http";
import { 
    REQUEST_LOCK_GET_REQUEST, REQUEST_LOCK_GET_SUCCESS, REQUEST_LOCK_GET_ERROR, 
    REQUEST_LOCK_GET_BY_KEY_REQUEST, REQUEST_LOCK_GET_BY_KEY_SUCCESS, REQUEST_LOCK_GET_BY_KEY_ERROR, 
    REQUEST_LOCK_ADD_REQUEST, REQUEST_LOCK_ADD_SUCCESS, REQUEST_LOCK_ADD_ERROR, 
    REQUEST_LOCK_EDIT_REQUEST, REQUEST_LOCK_EDIT_SUCCESS, REQUEST_LOCK_EDIT_ERROR, 
    REQUEST_LOCK_DELETE_REQUEST, REQUEST_LOCK_DELETE_SUCCESS, REQUEST_LOCK_DELETE_ERROR
} from "../constants/requestLockConstants";

export const getRequestLocks = (args) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_LOCK_GET_REQUEST
        });

        const data = await requestLockCRUD.search(args);
        dispatch({
            type: REQUEST_LOCK_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: REQUEST_LOCK_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getRequestLockByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_LOCK_GET_BY_KEY_REQUEST
        });

        const data = await requestLockCRUD.get(id);
        dispatch({
            type: REQUEST_LOCK_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: REQUEST_LOCK_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addRequestLock = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_LOCK_ADD_REQUEST
        });

        const response = await requestLockCRUD.create(data);
        await dispatch(getRequestLocks(args));
        dispatch({
            type: REQUEST_LOCK_ADD_SUCCESS,
        });
        return response.id;
    } catch (error) {
        dispatch({
            type: REQUEST_LOCK_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editRequestLock = (data, args) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_LOCK_EDIT_REQUEST
        });

        const response = await requestLockCRUD.edit(data);
        await dispatch(getRequestLocks(args));
        dispatch({
            type: REQUEST_LOCK_EDIT_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: REQUEST_LOCK_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteRequestLock = (id, args) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_LOCK_DELETE_REQUEST
        });

        const response = await requestLockCRUD.delete(id);
        await dispatch(getRequestLocks(args));
        dispatch({
            type: REQUEST_LOCK_DELETE_SUCCESS,
        });
        return response;
    } catch (error) {
        dispatch({
            type: REQUEST_LOCK_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

