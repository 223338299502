/* eslint-disable import/no-anonymous-default-export */
export default {
  '69': {
    name: 'ПРОТОКОЛ исследования готовых блюд и полуфабрикатов',
    kazakhName: 'Дайын тағамдар мен жартылай фабрикаттарды зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'food_name',
        type: 'text',
        label: 'Наименование блюда, полуфабриката',
        kazakhLabel: 'Тағамның, жартылай фабрикаттың атауы'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Іріктелген күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partion_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар саны'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'expiration_date',
        type: 'datetime',
        label: 'Срок годности',
        kazakhLabel: 'Жарамдылық мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгі (нің) НҚ-ға сәйкестігіне зерттеулер жүргізілді'
      }
    ]
  },
  '70': {
    version: 2,
    name: 'ПРОТОКОЛ отбора и исследования проб атмосферного воздуха населенных мест',
    kazakhName: 'Елді мекендердің атмосфералық ауасының сынамасын іріктеу және зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'selection_sample_place',
        type: 'text',
        label: 'Место отбора образца воздуха',
        kazakhLabel: 'Ауа үлгісін алған орын',
        number: '1'
      },
      {
        id: 'sample_type',
        type: 'text',
        label: 'Вид образца (разовая, среднесуточная)',
        kazakhLabel: 'Үлгінің түрі (бір жолғы, тәуліктік орташа)',
        number: '2'
      },
      {
        id: 'normative_document_type',
        type: 'text',
        label: 'НД, в соответствии с которой произведен отбор образца',
        kazakhLabel: 'НҚ-ға сәйкес алынған үлгі',
        number: '3'
      },
      {
        id: 'probe_selection_date_time',
        type: 'datetime',
        label: 'Дата и время отбора проб',
        kazakhLabel: 'Сынамалардың іріктелген күні мен уақыты',
        number: '4'
      },
      {
        id: 'probe_delivery_date_time',
        type: 'datetime',
        label: 'Дата и время доставки проб',
        kazakhLabel: 'Сынамалардың жеткізілу күні мен уақыты',
        number: '5'
      },
      {
        id: 'measurement_tools_used_for_selection',
        type: 'text',
        label: 'Средства измерений, применяемые при отборе образца',
        kazakhLabel: 'Үлгілерді алуда қолданылған өлшем құралы',
        number: '6'
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Сәйкестігі туралы мәлімет',
        number: '7'
      },
      {
        id: 'place_details',
        type: 'text',
        label: 'Характеристика местности',
        kazakhLabel: 'Өңірдің сипаттамасы',
        number: '8'
      },
      {
        id: 'relief',
        type: 'text',
        label: 'рельеф',
        kazakhLabel: 'рельефі',
        number: ''
      },
      {
        id: 'green_array',
        type: 'text',
        label: 'зеленый массив',
        kazakhLabel: 'жасыл желектер',
        number: ''
      },
      {
        id: 'its_height',
        type: 'text',
        label: 'его высота',
        kazakhLabel: 'оның биіктігі',
        number: ''
      },
      {
        id: 'dirty_source_distance',
        type: 'text',
        label: 'расстояние от источника загрязнения',
        kazakhLabel: 'ластану көзінен ара қашықтығы',
        number: ''
      },
      {
        id: 'near_objects',
        type: 'text',
        label: 'Близлежащие объекты',
        kazakhLabel: 'Жақын орналасқан нысандар',
        number: '9'
      },
      {
        id: 'selector_full_name',
        type: 'text',
        label: 'Должность, фамилия, имя, отчество лица производившего отбор образца',
        kazakhLabel: 'Үлгіні алған адамның лауазымы, тегі, аты, әкесінің аты',
        number: '10'
      },
      {
        id: 'representative_position_full_name',
        type: 'text',
        label: 'Занимаемая должность представителя района, фамилия, имя, отчество',
        kazakhLabel: 'Аудан өкілінің атқаратын қызметі, тегі, аты, әкесінің аты',
        number: '11'
      },
      {
        id: 'form_table1',
        type: 'table',
        rows: [
          [
            'Нөмірлері\r\n    Номера',
            'Сүзгіштердің,\r\n  жұтқыштардың\r\n    Фильтров, поглотителей',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Кескіні\r\n  бойынша алу нүктелерінің\r\n    Точек отбора по эскизу',
            '&nbsp;'
          ],
          [
            'Үлгілерді\r\n  алған орын\r\n    Точка отбора образцов',
            '&nbsp;'
          ],
          [
            'Зерттеу\r\n  әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            '&nbsp;'
          ],
          [
            'Метеорологиялық факторлар\r\n    Метеорологические факторы',
            'Атмосфералық\r\n  қысым, мм. сын. бағ.\r\n    Атмосферное давление мм. рт. ст.',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Ауа\r\n  температурасы Со\r\n    Температуравоздуха',
            'Құрғақ\r\n    Сухого',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Ылғалды\r\n    Влажного',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Салыстырмалы\r\n  ылғалдылығы\r\n    Относительная влажность',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Жел\r\n    Ветер',
            'Бағыты\r\n    Направление',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Жылдамдығы\r\n  м/сек.\r\n    Скорость',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Ауа\r\n  райының жағдайы\r\n    Состояние ',
            '&nbsp;'
          ],
          [
            'Алу уақыты (сағ.,мин)\r\n    Время отбора (час,мин)',
            'Басталуы\r\n    Начало',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Аяқталуы\r\n    Конец',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Аспирация\r\n  жылдамдығы, л/минутпен\r\n    Скорость аспирации, в л/мин',
            '&nbsp;'
          ]
        ],
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1298 style='border-collapse:
        collapse;width:972pt'>
       
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=104 style='mso-width-source:userset;mso-width-alt:3328;width:78pt'>
        <col width=100 style='mso-width-source:userset;mso-width-alt:3200;width:75pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <col width=132 style='mso-width-source:userset;mso-width-alt:4224;width:99pt'>
        <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
        <col width=107 style='mso-width-source:userset;mso-width-alt:3413;width:80pt'>
        <col width=88 style='mso-width-source:userset;mso-width-alt:2816;width:66pt'>
        <col width=71 span=5 style='width:53pt'>
        <col width=89 style='mso-width-source:userset;mso-width-alt:2858;width:67pt'>
        <tr height=41 style='mso-height-source:userset;height:31.25pt'>
         <td colspan=2 height=41 class=xl66 width=209 style='height:31.25pt;
         width:157pt'>Нөмірлері<br>
           Номера</td>
         <td rowspan=3 class=xl63 width=100 style='width:75pt'>Үлгілерді алған
         орын<br>
           Точка отбора образцов</td>
         <td rowspan=3 class=xl63 width=99 style='width:74pt'>Зерттеу әдістеменің
         НҚ-ры<br>
           НД на метод испытаний</td>
         <td colspan=7 class=xl63 width=659 style='border-left:none;width:493pt'>Метеорологиялық
         факторлар<br>
           Метеорологические факторы</td>
         <td colspan=3 class=xl63 width=231 style='border-left:none;width:173pt'>Алу
         уақыты (сағ.,мин)<br>
           Время отбора (час,мин)</td>
        </tr>
        <tr height=72 style='mso-height-source:userset;height:54.5pt'>
         <td rowspan=2 height=151 class=xl66 width=105 style='height:114.0pt;
         border-top:none;width:79pt'>Сүзгіштердің, жұтқыштардың<br>
           Фильтров, поглотителей</td>
         <td rowspan=2 class=xl63 width=104 style='border-top:none;width:78pt'>Кескіні
         бойынша алу нүктелерінің<br>
           Точек отбора по эскизу</td>
         <td rowspan=2 class=xl63 width=132 style='border-top:none;width:99pt'>Атмосфералық
         қысым, мм. сын. бағ.<br>
           Атмосферное давление мм. рт. ст.</td>
         <td colspan=2 class=xl63 width=226 style='border-left:none;width:169pt'>Ауа
         температурасы Со<br>
           Температуравоздуха</td>
         <td rowspan=2 class=xl63 width=88 style='border-top:none;width:66pt'>Салыстырмалы
         ылғалдылығы<br>
           Относительная влажность</td>
         <td colspan=2 class=xl63 width=142 style='border-left:none;width:106pt'>Жел<br>
           Ветер</td>
         <td rowspan=2 class=xl63 width=71 style='border-top:none;width:53pt'>Ауа
         райының жағдайы<br>
           Состояние<span style='mso-spacerun:yes'> </span></td>
         <td rowspan=2 class=xl63 width=71 style='border-top:none;width:53pt'>Басталуы<br>
           Начало</td>
         <td rowspan=2 class=xl63 width=71 style='border-top:none;width:53pt'>Аяқталуы<br>
           Конец</td>
         <td rowspan=2 class=xl63 width=89 style='border-top:none;width:67pt'>Аспирация
         жылдамдығы, л/минутпен<br>
           Скорость аспирации, в л/мин</td>
        </tr>
        <tr height=79 style='mso-height-source:userset;height:59.5pt'>
         <td height=79 class=xl63 width=119 style='height:59.5pt;border-top:none;
         border-left:none;width:89pt'>Құрғақ<br>
           Сухого</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>Ылғалды<br>
           Влажного</td>
         <td class=xl63 width=71 style='border-top:none;border-left:none;width:53pt'>Бағыты<br>
           Направление</td>
         <td class=xl63 width=71 style='border-top:none;border-left:none;width:53pt'>Жылдамдығы
         м/сек.<br>
           Скорость</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl64 style='height:16.0pt;border-top:none'>1</td>
         <td class=xl65 style='border-top:none;border-left:none'>2</td>
         <td class=xl65 style='border-top:none;border-left:none'>3</td>
         <td class=xl65 style='border-top:none;border-left:none'>4</td>
         <td class=xl65 style='border-top:none;border-left:none'>5</td>
         <td class=xl65 style='border-top:none;border-left:none'>6</td>
         <td class=xl65 style='border-top:none;border-left:none'>7</td>
         <td class=xl65 style='border-top:none;border-left:none'>8</td>
         <td class=xl65 style='border-top:none;border-left:none'>9</td>
         <td class=xl65 style='border-top:none;border-left:none'>10</td>
         <td class=xl65 style='border-top:none;border-left:none'>11</td>
         <td class=xl65 style='border-top:none;border-left:none'>12</td>
         <td class=xl65 style='border-top:none;border-left:none'>13</td>
         <td class=xl65 style='border-top:none;border-left:none'>14</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="11" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="123"><col width="111"><col width="172"><col width="128"><col width="105"><col width="65"><col width="82"><col width="120"><col width="65"><col width="81"><col width="88"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="234" height="68" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нөмірлері<br>Номера</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="172" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгілерді алған орын<br>Точка отбора образцов</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="128" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="7" width="606" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Метеорологиялық факторлар<br>Метеорологические факторы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="161" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сүзгіштердің, жұтқыштардың<br>Фильтров, поглотителей</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кескіні бойынша алу нүктелерінің<br>Точек отбора по эскизу</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Атмосфералық&nbsp;қысым, мм. сын. бағ.<br>Атмосферное давление мм. рт. ст.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауа температурасы Со<br>Температура воздуха</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Салыстырмалы ылғалдылығы<br>Относительная влажность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жел<br>Ветер</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауа райының жағдайы<br>Состояние погоды</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрғақ<br>Сухого</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ылғалды<br>Влажного</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бағыты Направление</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жылдамдығы м/сек Скорость</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="11" height="84" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Жұтқыштар мен сүзгіштердің нөмірі атмосфералық ауаны зерттеу нәтижелерін тіркеу журналынан көшіріп жазылады <br>(Номера поглотителей и фильтров переписываются из журнала регистрации результатов исследования атмосферного воздуха).</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'filter_atmosphere_air',
        type: 'text',
        label: 'Номера поглотителей и фильтров переписываются из журнала регистрации результатов исследования атмосферного воздуха',
        kazakhLabel: 'Жұтқыштар мен сүзгіштердің нөмірі атмосфералық ауаны зерттеу нәтижелерін тіркеу журналынан көшіріп жазылады',
        number: '13'
      },
      {
        id: 'form_table2',
        type: 'table',
        rows: [
          [
            'Анықталатын\r\n  заттың,ингредиенттің атауы\r\n    Наименование определяемого вещества, ингредиента',
            '&nbsp;'
          ],
          [
            'Өлшем бірліктері, қанықтығын зерттеу нәтижесі\r\n    Единицы измерения, результат исследования концентрации',
            'Ең\r\n  жоғары бір жолғы\r\n    Максимально-разовая',
            'Анықталған\r\n    Обнаруженная мг/м3',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'РЕШШ\r\n    ПДК',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Тәуліктік\r\n  орташа\r\n    Среднесуточная',
            'Анықталған\r\n    Обнаруженная мг/м3',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'РЕШШ\r\n    ПДК',
            '&nbsp;'
          ],
          [
            'Сәйкестiгiмензерттеуөткiзiлгеннормативтікқұжаттама\r\n    Нормативная документация в соответствии с которой проводились исследования',
            '&nbsp;'
          ]
        ],
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=659 style='border-collapse:
        collapse;width:494pt'>
       
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <col width=104 style='mso-width-source:userset;mso-width-alt:3328;width:78pt'>
        <col width=100 style='mso-width-source:userset;mso-width-alt:3200;width:75pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <col width=132 style='mso-width-source:userset;mso-width-alt:4224;width:99pt'>
        <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
        <tr height=69 style='mso-height-source:userset;height:52.75pt'>
         <td rowspan=3 height=197 class=xl68 width=105 style='height:149.25pt;
         width:79pt'>Анықталатын заттың,ингредиенттің атауы<br>
           Наименование определяемого вещества, ингредиента</td>
         <td colspan=4 class=xl65 width=435 style='border-left:none;width:326pt'>Өлшем
         бірліктері, қанықтығын зерттеу нәтижесі<br>
           Единицы измерения, результат исследования концентрации</td>
         <td rowspan=3 class=xl65 width=119 style='width:89pt'>Сәйкестiгiмензерттеуөткiзiлгеннормативтікқұжаттама<br>
           Нормативная документация в соответствии с которой проводились исследования</td>
        </tr>
        <tr height=67 style='mso-height-source:userset;height:50.5pt'>
         <td colspan=2 height=67 class=xl65 width=204 style='height:50.5pt;border-left:
         none;width:153pt'>Ең жоғары бір жолғы<br>
           Максимально-разовая</td>
         <td colspan=2 class=xl65 width=231 style='border-left:none;width:173pt'>Тәуліктік
         орташа<br>
           Среднесуточная</td>
        </tr>
        <tr height=61 style='height:46.0pt'>
         <td height=61 class=xl65 width=104 style='height:46.0pt;border-top:none;
         border-left:none;width:78pt'>Анықталған<br>
           Обнаруженная мг/м3</td>
         <td class=xl65 width=100 style='border-top:none;border-left:none;width:75pt'>РЕШШ<br>
           ПДК</td>
         <td class=xl65 width=99 style='border-top:none;border-left:none;width:74pt'>Анықталған<br>
           Обнаруженная мг/м3</td>
         <td class=xl65 width=132 style='border-top:none;border-left:none;width:99pt'>РЕШШ<br>
           ПДК</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl67 style='height:16.0pt;border-top:none'>14</td>
         <td class=xl66 style='border-top:none;border-left:none'>15</td>
         <td class=xl66 style='border-top:none;border-left:none'>16</td>
         <td class=xl66 style='border-top:none;border-left:none'>17</td>
         <td class=xl66 style='border-top:none;border-left:none'>18</td>
         <td class=xl66 style='border-top:none;border-left:none'>19</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="9" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="97"><col width="97"><col width="122"><col width="115"><col width="97"><col width="97"><col width="97"><col width="97"><col width="120"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" width="316" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Алу уақыты (сағ.,мин) <br>Время отбора (час,мин)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталатын заттың,ингредиенттің атауы<br>Наименование определяемого вещества, ингредиента</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" width="387" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшем бірліктері, қанықтығын зерттеу нәтижесі<br>Единицы измерения, результат исследования концентрации</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="120" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сәйкестiгiмен зерттеу өткiзiлген нормативтік құжаттама<br>Нормативная документация в соответствии с которой проводились исследования</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="185" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Басталуы<br>Начало</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аяқталуы<br>Конец</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аспирация жылдамдығы, л/минутпен<br>Скорость аспирации, в л/мин</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ең жоғары бір жолғы<br>Максимально-разовая</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тәуліктік орташа<br>Среднесуточная</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған<br>Обнаруженная мг/м3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">РЕШШ<br>ПДК</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған<br>Обнаруженная мг/м3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">РЕШШ<br>ПДК</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="18" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">18</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="19" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">19</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="20" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">20</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  },
  '71': {
    version: 2,
    name: 'ПРОТОКОЛ отбора и исследования проб воздуха закрытых помещений и рабочей зоны',
    kazakhName: 'Жабық үй-жайлардың және жұмыс аймағының ауасынан сынаманы іріктеу және зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы'
      },
      {
        id: 'selection_air_place',
        type: 'text',
        label: 'Место отбора проб воздуха цех, участок, отд., помещение, здание, комната, класс и другие',
        kazakhLabel: 'Ауа үлгілерін алу орны (цех, учаске, бөлімше, үй-жай, ғимарат класс және басқалар)'
      },
      {
        id: 'normative_document_of_selection',
        type: 'text',
        label: 'НД,согласно которой произведен отбор',
        kazakhLabel: 'Соған сәйкес сынама жүргізілген НҚ'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Іріктелген күні және уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уакыты'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'tools_used_for_selection',
        type: 'text',
        label: 'Средства измерений, применяемые при отборе проб',
        kazakhLabel: 'Сынамаларды алу кезінде қолданылатын өлшеу аспаптары'
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Тексеру туралы деректер'
      },
      {
        id: 'room_characteristic',
        type: 'text',
        label: 'Характеристика помещения',
        kazakhLabel: 'Үй-жайдың сипаттамасы'
      },
      {
        id: 'working_personal_number',
        type: 'number',
        label: 'Количество работающих человек',
        kazakhLabel: 'Жұмыс істейтін адамдар саны'
      },
      {
        id: 'main_dirty_sources',
        type: 'text',
        label: 'Основные источники загрязнения',
        kazakhLabel: 'Ластанудың негізгі көздері'
      },
      {
        id: 'place_sketch_dot_numbers',
        type: 'file',
        label: 'Эскиз помещения (территории, площадки, рабочего места и другие с указанием источника загрязнения и точек отбора проб воздуха) порядковые номера точек отбора',
        kazakhLabel: 'Ауаның ластану көздері мен ауа сынамаларын алған жерлерді (алу нүктелерінің реттік нөмірі) көрсетуімен үй-жайдың (аумақтың, алаңның, жұмыс орнының және басқалардың) эскизі'
      },
      {
        id: 'representative_full_name',
        type: 'text',
        label: 'Должность, фамилия, имя, отчество представителя обследуемого объекта, присутствующего при отборе воздуха',
        kazakhLabel: 'Ауа сынамысын алуға қатысқан тексерілетін объект өкілінің лауазымы, тегі, аты, әкесінің аты'
      },
      {
        id: 'technological_process_characteristic_working_equipment_percentage',
        type: 'text',
        label: 'Характеристика технологического процесса: Процент работающего оборудования',
        kazakhLabel: 'Технологиялық үрдістің сипаттамасы: Жұмыс істейтін құрал-жабдықтардың пайызы'
      },
      // {
      //   id: 'working_equipment_percentage',
      //   type: 'text',
      //   label: 'Процент работающего оборудования',
      //   kazakhLabel: 'Жұмыс істейтін құрал-жабдықтардың пайызы'
      // },
      {
        id: 'has_hand_operations',
        type: 'text',
        label: 'Режим, наличие ручных операций',
        kazakhLabel: 'Режимі қолмен орындалатын операциялар'
      },
      {
        id: 'selector_full_name',
        type: 'text',
        label: 'Должность, Ф.И.О (при наличии), проводившего отбор воздуха',
        kazakhLabel: 'Ауа үлгісін алған адамның лауазымы Т.А.Ә (болған жағдайда)'
      },
      {
        id: 'measurements_results',
        type: 'text',
        label: 'Результаты измерения',
        kazakhLabel: 'Өлшеу нәтижелері'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Нөмірлері\r\n    Номера',
            'Сүзгіштердің,\r\n  жұтқыштардың\r\n    Фильтров, поглотителей',
            '&nbsp;',
            '&nbsp;',
            'Ара қашықтық м,\r\n  см\r\n    Расстояние в м, см',
            'Еденнен\r\n    От пола',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Эскиз\r\n  бойынша алу нүктелерінің\r\n    Точек отбора по эскизу',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Нөмірлері\r\n    Номера\tЗерттеу әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            'Ластану\r\n  көзінен\r\n    От источника загрязнения',
            '&nbsp;'
          ],
          [
            'Үлгілерді алған орын\r\n    Точка отбора образцов',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Метеорологиялық факторлар\r\n    Метеорологические факторы',
            'Атмосфералық\r\n  қысым, мм. сын.бағ.\r\n    Атмосферное давление мм.рт. ст.',
            '&nbsp;',
            '&nbsp;',
            'Алу\r\n  уақыты (сағ.,мин)\r\n    Время отбора (час,мин)',
            'Басталуы\r\n    Начало',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Ауа\r\n  температурасы Со\r\n    Температура воздуха',
            'Құрғақ\r\n    Сухого',
            '&nbsp;',
            '&nbsp;',
            'Аяқталуы\r\n    Конец',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Ылғалды\r\n    Влажного',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Салыстырмалы\r\n  ылғалдылығы\r\n    Относительная влажность',
            '&nbsp;',
            '&nbsp;',
            'Аспирация\r\n  жылдамдығы,л/минутпен\r\n    Скорость аспирации, вл/мин',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Ауаның\r\n  жылдамдығы\r\n    Скорость движения воздуха',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=663 style='border-collapse:
        collapse;width:496pt'>
       
        <col width=84 style='mso-width-source:userset;mso-width-alt:2688;width:63pt'>
        <col width=71 style='width:53pt'>
        <col width=61 style='mso-width-source:userset;mso-width-alt:1962;width:46pt'>
        <col width=71 span=2 style='width:53pt'>
        <col width=92 style='mso-width-source:userset;mso-width-alt:2944;width:69pt'>
        <col width=71 span=3 style='width:53pt'>
        <tr height=40 style='mso-height-source:userset;height:30.5pt'>
         <td colspan=2 height=40 class=xl65 width=155 style='height:30.5pt;width:116pt'>Нөмірлері<br>
           Номера</td>
         <td rowspan=3 class=xl65 width=61 style='width:46pt'>Нөмірлері<br>
           Номера	Зерттеу әдістеменің НҚ-ры<br>
           НД на метод испытаний</td>
         <td rowspan=3 class=xl65 width=71 style='width:53pt'>Үлгілерді алған
         орын<br>
           Точка отбора образцов</td>
         <td colspan=5 class=xl65 width=376 style='border-left:none;width:281pt'>Метеорологиялық
         факторлар<br>
           Метеорологические факторы</td>
          <td colspan=4 height=47 class=xl65 width=287 style='height:35.5pt;width:215pt'>Ара
          қашықтық м, см<br>
            Расстояние в м, см</td>
          <td colspan=5 class=xl65 width=376 style='border-left:none;width:281pt'>Алу
          уақыты (сағ.,мин)<br>
            Время отбора (час,мин)</td>
        </tr>
        <tr height=52 style='mso-height-source:userset;height:39.0pt'>
         <td rowspan=2 height=213 class=xl65 width=84 style='height:160.0pt;
         border-top:none;width:63pt'>Сүзгіштердің, жұтқыштардың<br>
           Фильтров, поглотителей</td>
         <td rowspan=2 class=xl65 width=71 style='border-top:none;width:53pt'>Эскиз
         бойынша алу нүктелерінің<br>
           Точек отбора по эскизу</td>
         <td rowspan=2 class=xl65 width=71 style='border-top:none;width:53pt'>Атмосфералық
         қысым, мм. сын.бағ.<br>
           Атмосферное давление мм.рт. ст.</td>
         <td colspan=2 class=xl65 width=163 style='border-left:none;width:122pt'>Ауа
         температурасы Со<br>
           Температура воздуха</td>
         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>

         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl66 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>

        <tr height=161 style='height:121.0pt'>
         <td height=161 class=xl65 width=92 style='height:121.0pt;border-top:none;
         border-left:none;width:69pt'>Құрғақ<br>
           Сухого</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>Ылғалды<br>
           Влажного</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>Салыстырмалы
         ылғалдылығы<br>
           Относительная влажность</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>Ауаның
         жылдамдығы<br>
           Скорость движения воздуха</td>
          <td height=100 class=xl65 width=155 style='height:75.5pt;
          width:116pt'>Еденнен<br>
            От пола</td>
          <td class=xl65 width=132 style='border-left:none;width:99pt'>Ластану
          көзінен<br>
            От источника загрязнения</td>
          <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>Басталуы<br>
            Начало</td>
          <td class=xl65 width=163 style='border-left:none;width:122pt'>Аяқталуы<br>
            Конец</td>
          <td class=xl65 width=142 style='border-left:none;width:106pt'>Аспирация
          жылдамдығы,л/минутпен<br>
            Скорость аспирации, вл/мин</td>
        </tr>


        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl66 style='height:16.0pt;border-top:none'>1</td>
         <td class=xl66 style='border-top:none;border-left:none'>2</td>
         <td class=xl66 style='border-top:none;border-left:none'>3</td>
         <td class=xl66 style='border-top:none;border-left:none'>4</td>
         <td class=xl66 style='border-top:none;border-left:none'>5</td>
         <td class=xl66 style='border-top:none;border-left:none'>6</td>
         <td class=xl66 style='border-top:none;border-left:none'>7</td>
         <td class=xl66 style='border-top:none;border-left:none'>8</td>
         <td class=xl66 style='border-top:none;border-left:none'>9</td>
         <td height=21 class=xl66 style='height:16.0pt'>10</td>
         <td class=xl66 style='border-left:none'>11</td>
         <td class=xl66 style='border-top:none;border-left:none'>12</td>
         <td class=xl66 style='border-left:none'>13</td>
         <td class=xl66 style='border-left:none'>14</td>
        </tr>
       </table>`
      },
      {
        id: 'form_table2',
        type: 'table',
        rows: [
          [
            'Анықталатын заттың, ингредиентті атауы\r\n    Наименование определяемого вещества, ингредиента',
            '&nbsp;'
          ],
          [
            'Зерттеу нәтижелері, мг/м3\r\n    Результаты исследования в мг/м3',
            'Ең\r\n  жоғары – бір реттік\r\n    Максимально-разовая',
            'Анықталған қанықтық\r\n    Обнаруженная концентрация',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'РЕШШ,\r\n  ЗҚДА және басқалар\r\n    ПДК, ОБУВ и другие',
            '&nbsp;'
          ],
          [
            'Зерттеу әдістемесі\r\n    Методика исследования',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=663 style='border-collapse:
        collapse;width:496pt'>
       
        <col width=84 style='mso-width-source:userset;mso-width-alt:2688;width:63pt'>
        <col width=71 style='width:53pt'>
        <col width=61 style='mso-width-source:userset;mso-width-alt:1962;width:46pt'>
        <col width=71 span=2 style='width:53pt'>
        <col width=92 style='mso-width-source:userset;mso-width-alt:2944;width:69pt'>
        <col width=71 span=3 style='width:53pt'>
        <tr height=61 style='mso-height-source:userset;height:46.25pt'>
         <td rowspan=3 height=195 class=xl67 width=216 style='height:148.75pt;
         width:162pt'>Анықталатын заттың, ингредиентті атауы<br>
           Наименование определяемого вещества, ингредиента</td>
         <td colspan=2 class=xl65 width=234 style='border-left:none;width:175pt'>Зерттеу
         нәтижелері, мг/м3<br>
           Результаты исследования в мг/м3</td>
         <td rowspan=3 class=xl67 width=213 style='width:159pt'>Зерттеу
         әдістемесі<br>
           Методика исследования</td>
        </tr>
        <tr height=41 style='mso-height-source:userset;height:31.75pt'>
         <td colspan=2 height=41 class=xl65 width=234 style='height:31.75pt;
         border-left:none;width:175pt'>Ең жоғары – бір реттік<br>
           Максимально-разовая</td>
        </tr>
        <tr height=93 style='mso-height-source:userset;height:70.75pt'>
         <td height=93 class=xl65 width=142 style='height:70.75pt;
         border-left:none;width:106pt'>Анықталған қанықтық<br>
           Обнаруженная концентрация</td>
         <td class=xl65 width=92 style='border-top:none;border-left:none;width:69pt'>РЕШШ,
         ЗҚДА және басқалар<br>
           ПДК, ОБУВ и другие</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl66 style='height:16.0pt'>15</td>
         <td class=xl66 style='border-left:none'>16</td>
         <td class=xl66 style='border-top:none;border-left:none'>17</td>
         <td class=xl66 style='border-left:none'>18</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="9" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="129"><col width="135"><col width="146"><col width="90"><col width="108"><col width="65"><col width="93"><col width="119"><col width="117"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="264" height="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нөмірлері<br>Номера</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="146" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="90" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгілерді алған орын<br>Точка отбора образцов</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="5" width="502" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Метеорологиялық факторлар<br>Метеорологические факторы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" height="117" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сүзгіштердің, жұтқыштардың<br>Фильтров, поглотителей</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Эскиз бойынша алу нүктелерінің<br>Точек отбора по эскизу</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Атмосфералық қысым, мм. сын.бағ.<br>Атмосферное давление мм.рт. ст.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауа температурасы Со<br>Температура воздуха</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрғақ<br>Сухого</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ылғалды<br>Влажного</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Салыстырмалы ылғалдылығы<br>Относительная влажность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауаның жылдамдығы<br>Скорость движения воздуха</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="94"><col width="122"><col width="90"><col width="88"><col width="128"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="216" height="20" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Нөмірлері</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="90" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="216" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="20" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">Номера</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="JUSTIFY" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000"><br></font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="45" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ара қашықтық м, см<br>Расстояние в м, см</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Алу уақыты (сағ.,мин)<br>Время отбора (час,мин)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Еденнен<br>От пола</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ластану көзінен<br>От источника загрязнения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Басталуы<br>Начало</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аяқталуы<br>Конец</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аспирация жылдамдығы,л/минутпен<br>Скорость аспирации, вл/мин</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="5" height="137" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сорғыштар мен сүзгілердің нөмірлері осы үлгінің (№__) артқы бетіне сай жүргізілетін жабық үй-жайлардың ауасын зерттеу нәтижелерін тіркейтін журналдан көшіріліп жазылады.<br>Номера поглотителей и фильтров переписываются из журнала регистрации результатов исследования воздуха закрытых помещений, который ведется в соответствии с разворотом данной формы.</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },
      {
        id: 'form_table_v2_3',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="4" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="302"><col width="128"><col width="141"><col width="106"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="302" height="195" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталатын заттың, ингредиентті атауы<br>Наименование определяемого вещества, ингредиента</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="269" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижелері, мг/м3<br>Результаты исследования в мг/м3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="106" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістемесі<br>Методика исследования</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ең жоғары – бір реттік<br>Максимально-разовая</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған қанықтық<br>Обнаруженная концентрация</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">РЕШШ, ЗҚДА және басқалар<br>ПДК, ОБУВ и другие</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="4" height="56" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгі (нің) НҚ-ға сәйкестігіне зерттеулер жүргізілді / <br>(Исследование проб проводились на соответствие НД)____</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },    
    ]
  },
  '72': {
    version: 2,
    name: 'ПРОТОКОЛ измерений освещенности',
    kazakhName: 'Жарықты өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекен-жайы',
        number: '1'
      },
      {
        id: 'adress_specific',
        type: 'text',
        label: 'цех, участок, класс, комната',
        kazakhLabel: 'цех, учаске, сынып, бөлме, мекен-жайы',
        number: ''
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеулер мақсаты',
        number: '2'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя обследуемого объекта',
        kazakhLabel: 'Өлшеулер тексерілетін объект өкiлiнiң қатысуымен жүргiзiлдi',
        number: '3'
      },
      {
        id: 'photoelectric_luximeter',
        type: 'text',
        label: 'Фотоэлектрический люксметр типа (инвентарный)',
        kazakhLabel: 'Фотоэлектрлiк люксметр типі (мүкаммал)',
        number: '4'
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке, дата и номер свидетельства',
        kazakhLabel: 'Тексеру туралы деректер, күнi мен куәлiктiң нөмiрi',
        number: '5'
      },
      // {
      //   id: 'certificate_date_number',
      //   type: 'text',
      //   label: 'дата и номер свидетельства',
      //   kazakhLabel: 'күнi мен куәлiктiң нөмiрi',
      //   number: ''
      // },
      {
        id: 'normative_document_measurement',
        type: 'text',
        label: 'НД, в соответствии которой проводились измерения (перечислить)',
        kazakhLabel: 'Өлшеулер жүргізуге және қорытынды беруге негіз болған НҚ (атап өтiңiз)',
        number: '6'
      },
      {
        id: 'place_sketch',
        type: 'file',
        label: 'Эскиз помещения с указанием расстановки оборудования, размещения светильников, расположения светонесущих конструкций (окон, световых фонарей) и нанесением точек замеров',
        kazakhLabel: 'Жабдықтардың, шамдардың орналасуы, жарық беретін құрылғылардың орналасуы (терезе, жарық беретін шамдар) көрсетілген және өлшеу нүктелері енгiзiлген үй-жайдың кескіні'
      },
      {
        id: 'fake_lightning_measurement_result',
        type: 'text',
        label: 'Результаты измерения искусственной освещенности',
        kazakhLabel: 'Жасанды жарық өлшеу нәтижелері'
      },
      {
        id: 'form_table1',
        type: 'table',
        rows: [
          [
            'Тіркеу\r\n  нөмірі\r\n    Регистрационный номер',
            '&nbsp;'
          ],
          [
            'Эскиз бойынша нүктенің нөмірі\r\n    Номер точки по эскизу',
            '&nbsp;'
          ],
          [
            'Өлшеу орны\r\n    Место измерения',
            '&nbsp;'
          ],
          [
            'Жұмыс разряды кіші разряд\r\n    Разряд работы Под разряд',
            '&nbsp;'
          ],
          [
            'Жарықтандыру жүйесі жалпы, жергілікті, аралас\r\n    Система освещения общее, местное, комбинированное',
            '&nbsp;'
          ],
          [
            'Шамның түрі, типі және маркасы\r\n    Вид, тип и марка ламп',
            '&nbsp;'
          ],
          [
            'Өлшенген жарықтандыру люкспен\r\n    Освещенность в люксах',
            '&nbsp;'
          ],
          [
            'Нормалар бойынша рұқсат етілген\r\n    Допустимая по нормам, лк',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=1170 style='border-collapse:
        collapse;width:877pt'>
       
        <col width=115 style='mso-width-source:userset;mso-width-alt:3669;width:86pt'>
        <col width=121 style='mso-width-source:userset;mso-width-alt:3882;width:91pt'>
        <col width=104 style='mso-width-source:userset;mso-width-alt:3328;width:78pt'>
        <col width=135 style='mso-width-source:userset;mso-width-alt:4309;width:101pt'>
        <col width=272 style='mso-width-source:userset;mso-width-alt:8704;width:204pt'>
        <col width=191 style='mso-width-source:userset;mso-width-alt:6101;width:143pt'>
        <col width=121 style='mso-width-source:userset;mso-width-alt:3882;width:91pt'>
        <col width=111 style='mso-width-source:userset;mso-width-alt:3541;width:83pt'>
        <tr height=99 style='mso-height-source:userset;height:74.5pt'>
         <td height=99 class=xl63 width=115 style='height:74.5pt;width:86pt'>Тіркеу
         нөмірі<br>
           Регистрационный номер</td>
         <td class=xl63 width=121 style='border-left:none;width:91pt'>Эскиз бойынша
         нүктенің нөмірі<br>
           Номер точки по эскизу</td>
         <td class=xl63 width=104 style='border-left:none;width:78pt'>Өлшеу орны<br>
           Место измерения</td>
         <td class=xl63 width=135 style='border-left:none;width:101pt'>Жұмыс разряды
         кіші разряд<br>
           Разряд работы Под разряд</td>
         <td class=xl63 width=272 style='border-left:none;width:204pt'>Жарықтандыру
         жүйесі жалпы, жергілікті, аралас<br>
           Система освещения общее, местное, комбинированное</td>
         <td class=xl63 width=191 style='border-left:none;width:143pt'>Шамның түрі,
         типі және маркасы<br>
           Вид, тип и марка ламп</td>
         <td class=xl63 width=121 style='border-left:none;width:91pt'>Өлшенген
         жарықтандыру люкспен<br>
           Освещенность в люксах</td>
         <td class=xl63 width=111 style='border-left:none;width:83pt'>Нормалар бойынша
         рұқсат етілген<br>
           Допустимая по нормам, лк</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl64 style='height:16.0pt;border-top:none'>1</td>
         <td class=xl64 style='border-top:none;border-left:none'>2</td>
         <td class=xl64 style='border-top:none;border-left:none'>3</td>
         <td class=xl64 style='border-top:none;border-left:none'>4</td>
         <td class=xl64 style='border-top:none;border-left:none'>5</td>
         <td class=xl64 style='border-top:none;border-left:none'>6</td>
         <td class=xl64 style='border-top:none;border-left:none'>7</td>
         <td class=xl64 style='border-top:none;border-left:none'>8</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="8" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="146"><col width="133"><col width="96"><col width="105"><col width="141"><col width="108"><col width="112"><col width="102"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="146" height="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>Регистрационный номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="133" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Эскиз бойынша нүктенің нөмірі<br>Номер точки <br>по эскизу</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="96" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу орны Место измерения</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жұмыс разряды кіші разряд<br>Разряд работы Под разряд</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="141" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жарықтандыру жүйесі жалпы, жергілікті, аралас<br>Система освещения общее, местное, комбинированное</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="108" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Шамның түрі, типі және маркасы<br>Вид, тип и марка ламп</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="112" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшенген жарықтандыру люкспен<br>Освещенность в люксах</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="102" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормалар бойынша рұқсат етілген<br>Допустимая по нормам, лк</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },    
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгі (нің) НҚ-ға сәйкестігіне зерттеулер жүргізілді'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Тіркеу\r\n  нөмірі\r\n    Регистрационный номер',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Кескін бойынша нүкте нөмірі\r\n    Номер точки по эскизу',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Өлшеу орны\r\n    Место измерения',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Зерттеу әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жұмыс разряды\r\n    Разряд, подразряд работы',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Тәулікті өлшеу жүргізілген уақыты\r\n    Время проведения замеров',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Бөлме ішіндегі табиғи жарық\r\n    Естественная освещенность внутри помещения',
            'Жоғары\r\n  жарықтану кезінде\r\n    При верхнем освещении',
            'ТЖК\r\n  өлшенген\r\n    КЕО измеренное',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Норма\r\n  бойынша рұқсат етілген\r\n    Допустимая по нормам',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Бүйірінен\r\n  жарық түсіру кезінде\r\n    При боковом освещении',
            'ТЖК\r\n  өлшенген\r\n    КЕО измеренное',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Норма\r\n  бойынша рұқсат етілген\r\n    Допустимая по нормам',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Құрастырылған\r\n  жары\ufffd\ufffdтану кезінде комбинированном освещении',
            'ТЖК\r\n  өлшенген\r\n    КЕО измеренное',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Норма\r\n  бойынша рұқсат етілген\r\n    Допустимая по нормам',
            '&nbsp;'
          ],
          [
            'Сыртқы жарық\r\n    Наружная освещенность',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'ТЖК орташа мәні\r\n    КЕО среднее значение',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=1596 style='border-collapse:
        collapse;width:1195pt'>
       
        <col width=115 style='mso-width-source:userset;mso-width-alt:3669;width:86pt'>
        <col width=121 style='mso-width-source:userset;mso-width-alt:3882;width:91pt'>
        <col width=104 style='mso-width-source:userset;mso-width-alt:3328;width:78pt'>
        <col width=135 style='mso-width-source:userset;mso-width-alt:4309;width:101pt'>
        <col width=272 style='mso-width-source:userset;mso-width-alt:8704;width:204pt'>
        <col width=191 style='mso-width-source:userset;mso-width-alt:6101;width:143pt'>
        <col width=121 style='mso-width-source:userset;mso-width-alt:3882;width:91pt'>
        <col width=111 style='mso-width-source:userset;mso-width-alt:3541;width:83pt'>
        <col width=71 span=6 style='width:53pt'>
        <tr height=41 style='mso-height-source:userset;height:31.75pt'>
         <td rowspan=3 height=259 class=xl67 width=115 style='height:196.5pt;
         width:86pt'>Тіркеу нөмірі<br>
           Регистрационный номер</td>
         <td rowspan=3 class=xl67 width=121 style='width:91pt'>Кескін бойынша нүкте
         нөмірі<br>
           Номер точки по эскизу</td>
         <td rowspan=3 class=xl67 width=104 style='width:78pt'>Өлшеу орны<br>
           Место измерения</td>
         <td rowspan=3 class=xl67 width=135 style='width:101pt'>Зерттеу әдістеменің
         НҚ-ры<br>
           НД на метод испытаний</td>
         <td rowspan=3 class=xl67 width=272 style='width:204pt'>Жұмыс разряды<br>
           Разряд, подразряд работы</td>
         <td rowspan=3 class=xl67 width=191 style='width:143pt'>Тәулікті өлшеу
         жүргізілген уақыты<br>
           Время проведения замеров</td>
         <td colspan=6 class=xl65 width=516 style='border-left:none;width:386pt'>Бөлме
         ішіндегі табиғи жарық<br>
           Естественная освещенность внутри помещения</td>
         <td rowspan=3 class=xl67 width=71 style='width:53pt'>Сыртқы жарық<br>
           Наружная освещенность</td>
         <td rowspan=3 class=xl67 width=71 style='width:53pt'>ТЖК орташа мәні<br>
           КЕО среднее значение</td>
        </tr>
        <tr height=77 style='mso-height-source:userset;height:58.75pt'>
         <td colspan=2 height=77 class=xl65 width=232 style='height:58.75pt;
         border-left:none;width:174pt'>Жоғары жарықтану кезінде<br>
           При верхнем освещении</td>
         <td colspan=2 class=xl65 width=142 style='border-left:none;width:106pt'>Бүйірінен
         жарық түсіру кезінде<br>
           При боковом освещении</td>
         <td colspan=2 class=xl65 width=142 style='border-left:none;width:106pt'>Құрастырылған
         жарықтану кезінде комбинированном освещении</td>
        </tr>
        <tr height=141 style='height:106.0pt'>
         <td height=141 class=xl65 width=121 style='height:106.0pt;border-top:none;
         border-left:none;width:91pt'>ТЖК өлшенген<br>
           КЕО измеренное</td>
         <td class=xl65 width=111 style='border-top:none;border-left:none;width:83pt'>Норма
         бойынша рұқсат етілген<br>
           Допустимая по нормам</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>ТЖК
         өлшенген<br>
           КЕО измеренное</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>Норма
         бойынша рұқсат етілген<br>
           Допустимая по нормам</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>ТЖК
         өлшенген<br>
           КЕО измеренное</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>Норма
         бойынша рұқсат етілген<br>
           Допустимая по нормам</td>
        </tr>
        <tr height=25 style='mso-height-source:userset;height:19.25pt'>
         <td height=25 class=xl65 width=115 style='height:19.25pt;border-top:none;
         width:86pt'>1</td>
         <td class=xl65 width=121 style='border-top:none;border-left:none;width:91pt'>2</td>
         <td class=xl65 width=104 style='border-top:none;border-left:none;width:78pt'>3</td>
         <td class=xl65 width=135 style='border-top:none;border-left:none;width:101pt'>4</td>
         <td class=xl65 width=272 style='border-top:none;border-left:none;width:204pt'>5</td>
         <td class=xl65 width=191 style='border-top:none;border-left:none;width:143pt'>6</td>
         <td class=xl65 width=121 style='border-top:none;border-left:none;width:91pt'>7</td>
         <td class=xl65 width=111 style='border-top:none;border-left:none;width:83pt'>8</td>
         <td class=xl65 width=71 style='border-top:none;border-left:none;width:53pt'>9</td>
         <td class=xl66 style='border-top:none;border-left:none'>10</td>
         <td class=xl66 style='border-top:none;border-left:none'>11</td>
         <td class=xl66 style='border-top:none;border-left:none'>12</td>
         <td class=xl66 style='border-top:none;border-left:none'>13</td>
         <td class=xl66 style='border-top:none;border-left:none'>14</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="115"><col width="110"><col width="87"><col width="114"><col width="86"><col width="110"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" rowspan="3" width="115" height="213" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>Регистрационный номер</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="110" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кескін бойынша нүкте нөмірі<br>Номер точки по эскизу</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="87" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу орны<br>Место измерения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="114" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="86" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жұмыс разряды<br>Разряд, подразряд работы</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="110" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тәулікті өлшеу жүргізілген уақыты<br>Время проведения замеров</font></td>
          </tr>
          <tr>
            </tr>
          <tr>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2_3',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="9" rules="NONE" border="1" class="table table-bordered">
        <colgroup><col width="98"><col width="100"><col width="98"><col width="65"><col width="65"><col width="90"><col width="94"><col width="108"><col width="93"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="7" width="609" height="61" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бөлме ішіндегі табиғи жарық<br>Естественная освещенность внутри помещения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" width="108" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сыртқы жарық<br>Наружная освещенность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="3" width="93" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ТЖК орташа мәні<br>КЕО среднее значение</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="58" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жоғары жарықтану кезінде<br>При верхнем освещении</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="3" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бүйірінен жарық түсіру кезінде<br>При боковом освещении</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрастырылған жарықтану кезінде комбинированном освещении</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ТЖК өлшенген<br>КЕО измеренное</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Норма бойынша рұқсат етілген<br>Допустимая по нормам</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ТЖК өлшенген<br>КЕО измеренное</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Норма бойынша рұқсат етілген<br>Допустимая по нормам</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ТЖК өлшенген<br>КЕО измеренное</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Норма бойынша рұқсат етілген<br>Допустимая по нормам</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      },
    ]
  },
  '73': {
    version: 2,
    name: 'ПРОТОКОЛ измерений метеорологических факторов',
    kazakhName: 'Метеорологиялық факторларды өлшеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'measurement_place',
        type: 'text',
        label: 'Место проведения измерений',
        kazakhLabel: 'Өлшеу жүргізу орны'
      },
      {
        id: 'adress_details',
        type: 'text',
        label: 'цех, участок, отделение, здание и другое',
        kazakhLabel: 'цех, учаске, бөлімше, ғимарат және басқалары'
      },
      {
        id: 'measurement_goal',
        type: 'text',
        label: 'Цель измерения',
        kazakhLabel: 'Өлшеулер мақсаты'
      },
      {
        id: 'measurement_done_with_representative',
        type: 'text',
        label: 'Измерения проводились в присутствии представителя обследуемого объекта',
        kazakhLabel: 'Өлшеулер тексерiлетiн объект өкiлiнiң қатысуымен жүргiзiлдi'
      },
      {
        id: 'full_name_position',
        type: 'text',
        label: 'должность, фамилия, имя, отчество',
        kazakhLabel: 'лауазымы, тегі, аты, әкесiнiң аты'
      },
      {
        id: 'measurement_tool',
        type: 'text',
        label: 'Средство измерений',
        kazakhLabel: 'Өлшеулер құралы'
      },
      {
        id: 'name_mark_inventory_number',
        type: 'text',
        label: 'наименование, марка, инвентарный номер',
        kazakhLabel: 'атауы, маркасы, мүкаммал нөмiрi'
      },
      {
        id: 'check_data',
        type: 'text',
        label: 'Сведения о поверке',
        kazakhLabel: 'Сәйкестiгi туралы деректер'
      },
      {
        id: 'certificate_date_number',
        type: 'datetime',
        label: 'дата и номер свидетельства, справки',
        kazakhLabel: 'күнi және куәлiктiң, анықтаманың нөмiрi'
      },
      {
        id: 'result_normative_document',
        type: 'text',
        label: 'НД, в соответствии с которой проводились измерения и давалось заключение',
        kazakhLabel: 'Өлшеу жүргізгенде және қорытынды бергенде негізге алынған НҚ'
      },
      {
        id: 'room_characteristic',
        type: 'number',
        label: 'Характеристика помещения: площадь общий',
        kazakhLabel: 'Үй-жайдың сипаттамасы: ауданы'
      },
      {
        id: 'room_characteristic_2',
        type: 'number',
        label: 'Количество работающих человек',
        kazakhLabel: 'Жұмыс iстейтiн адамдардың саны'
      },
      {
        id: 'room_characteristic_3',
        type: 'number',
        label: 'Влаговыделение',
        kazakhLabel: 'Ылғалды бөлу'
      },
      {
        id: 'room_characteristic_4',
        type: 'number',
        label: 'Кубатура',
        kazakhLabel: 'Кубатура'
      },
      {
        id: 'room_characteristic_5',
        type: 'number',
        label: 'Избытки явного тепла',
        kazakhLabel: 'Шамадан тыс жылу'
      },
      {
        id: 'room_sketch',
        type: 'file',
        label: 'Эскиз помещения с указанием размещения оборудования и нанесением точек замеров',
        kazakhLabel: 'Жабдықтарының орналасуы мен өлшеу нүктелері көрсетілген үй-жай кескіні'
      },
      {
        id: 'air_meteo_research_results',
        type: 'text',
        label: 'Результаты измерений метеорологических факторов атмосферного воздуха',
        kazakhLabel: 'Атмосфералық ауаның метеорологиялық факторларын өлшеудің нәтижелері'
      },
      {
        id: 'air_temp',
        type: 'text',
        label: 'температура сухого воздуха',
        kazakhLabel: 'құрғақ ауа температурасы'
      },
      {
        id: 'air_temp_2',
        type: 'text',
        label: 'относительная влажность',
        kazakhLabel: 'салыстырмалы ылғалдылық'
      },      
      {
        id: 'pressure',
        type: 'text',
        label: 'давление мм. рт. ст.',
        kazakhLabel: 'қысымы мм. сын. бағ.'
      },
      {
        id: 'form_table1',
        type: 'table',
        rows: [
          [
            'Тіркеу\r\n  нөмірі\r\n    Регистрационный номер',
            '&nbsp;',
            '&nbsp;',
            'Ауаның қозғалыс\r\n  жылдамдығы\r\n    Скорость движения воздуха не более м/с ',
            'Өлшенген\r\n    Измеренная',
            '&nbsp;'
          ],
          [
            'Кескін\r\n  бойынша нүктелердің №\r\n    № точек по эскизу',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Өлшеу\r\n  орны\r\n    Место измерений',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            'Нормалар\r\n  бойынша оңтайлы/рұқсат етілген\r\n    оптимальная/допустимая по нормам',
            '&nbsp;'
          ],
          [
            'Ауырлық\r\n  бойынша жұмыс санаты\r\n    Категория работ по тяжести',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Зерттеу\r\n  әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            '&nbsp;',
            '&nbsp;',
            'Жылу\r\n  шығару\r\n    Тепловое излучение',
            'Жылу\r\n  шығару көзінің атауы\r\n    Наименование источника излучения',
            '&nbsp;'
          ],
          [
            'Тәулiктiң\r\n  өлшеу жүргiзiлген уақыты\r\n    Время суток проведения измерений',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            'Жылу\r\n  көзінен ара қашықтық, сантиметрмен\r\n    Расстояние от источника в см',
            '&nbsp;'
          ],
          [
            'Ауа\r\n  температурасы оС\r\n    Температура воздуха оС',
            'өлшенген\r\n    измеренная',
            '&nbsp;',
            '&nbsp;',
            'Актинометр\r\n  көрсеткіші Вт/м 2\r\n    Показание актинометра Вт/ м 2',
            '&nbsp;'
          ],
          [
            '&nbsp;',
            'Нормалар\r\n  бойынша оңтайлы/рұқсат етілген\r\n    оптимальная/допустимая по нормам',
            '&nbsp;',
            '&nbsp;',
            'Нормалар\r\n  бойынша рұқсат етілген кВт/ м 2\r\n    Допустимое по нормам кВт/м 2',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=934 style='border-collapse:
        collapse;width:700pt'>
       
        <col width=87 style='mso-width-source:userset;mso-width-alt:2773;width:65pt'>
        <col width=113 style='mso-width-source:userset;mso-width-alt:3626;width:85pt'>
        <col width=84 style='mso-width-source:userset;mso-width-alt:2688;width:63pt'>
        <col width=85 style='mso-width-source:userset;mso-width-alt:2730;width:64pt'>
        <col width=103 style='mso-width-source:userset;mso-width-alt:3285;width:77pt'>
        <col width=100 style='mso-width-source:userset;mso-width-alt:3200;width:75pt'>
        <col width=73 style='mso-width-source:userset;mso-width-alt:2346;width:55pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <col width=79 style='mso-width-source:userset;mso-width-alt:2517;width:59pt'>
        <col width=111 style='mso-width-source:userset;mso-width-alt:3541;width:83pt'>
        <tr height=140 style='mso-height-source:userset;height:105.5pt'>
         <td rowspan=2 height=277 class=xl65 width=87 style='border-bottom:1.0pt solid #AEAAAA;
         height:209.25pt;width:65pt'>Тіркеу нөмірі<br>
           Регистрационный номер</td>
         <td rowspan=2 class=xl65 width=113 style='border-bottom:1.0pt solid #AEAAAA;
         width:85pt'>Кескін бойынша нүктелердің №<br>
           № точек по эскизу</td>
         <td rowspan=2 class=xl65 width=84 style='border-bottom:1.0pt solid #AEAAAA;
         width:63pt'>Өлшеу орны<br>
           Место измерений</td>
         <td rowspan=2 class=xl65 width=85 style='border-bottom:1.0pt solid #AEAAAA;
         width:64pt'>Ауырлық бойынша жұмыс санаты<br>
           Категория работ по тяжести</td>
         <td rowspan=2 class=xl65 width=103 style='border-bottom:1.0pt solid #AEAAAA;
         width:77pt'>Зерттеу әдістеменің НҚ-ры<br>
           НД на метод испытаний</td>
         <td rowspan=2 class=xl65 width=100 style='border-bottom:1.0pt solid #AEAAAA;
         width:75pt'>Тәулiктiң өлшеу жүргiзiлген уақыты<br>
           Время суток проведения измерений</td>
         <td colspan=2 class=xl63 width=172 style='border-left:none;width:129pt'>Ауа
         температурасы оС<br>
           Температура воздуха оС</td>
         <td colspan=2 class=xl63 width=190 style='border-left:none;width:142pt'>Ауаның
         салыстырмалы ылғалдылығы %<br>
           Относительная влажность воздуха в %</td>
        </tr>
        <tr height=137 style='mso-height-source:userset;height:103.75pt'>
         <td height=137 class=xl63 width=73 style='height:103.75pt;border-top:none;
         border-left:none;width:55pt'>өлшенген<br>
           измеренная</td>
         <td class=xl63 width=99 style='border-top:none;border-left:none;width:74pt'>Нормалар
         бойынша оңтайлы/рұқсат етілген<br>
           оптимальная/допустимая по нормам</td>
         <td class=xl63 width=79 style='border-top:none;border-left:none;width:59pt'>өлшенген<br>
           измеренная</td>
         <td class=xl63 width=111 style='border-top:none;border-left:none;width:83pt'>Нормалар
         бойынша оңтайлы/рұқсат етілген<br>
           оптимальная/допустимая по нормам</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl64 style='height:16.0pt;border-top:none'>1</td>
         <td class=xl64 style='border-top:none;border-left:none'>2</td>
         <td class=xl64 style='border-top:none;border-left:none'>3</td>
         <td class=xl64 style='border-top:none;border-left:none'>4</td>
         <td class=xl64 style='border-top:none;border-left:none'>5</td>
         <td class=xl64 style='border-top:none;border-left:none'>6</td>
         <td class=xl64 style='border-top:none;border-left:none'>7</td>
         <td class=xl64 style='border-top:none;border-left:none'>8</td>
         <td class=xl64 style='border-top:none;border-left:none'>9</td>
         <td class=xl64 style='border-top:none;border-left:none'>10</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Ауаның салыстырмалы ылғалдылығы %\r\n    Относительная влажность воздуха в %',
            'өлшенген\r\n    измеренная',
            '&nbsp;',
            'Ескертуге\r\n    Примечание',
            '&nbsp;'
          ],
          [
            'Нормалар бойынша оңтайлы/рұқсат етілген\r\n    оптимальная/допустимая по нормам',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=934 style='border-collapse:
        collapse;width:700pt'>
       
        <col width=87 style='mso-width-source:userset;mso-width-alt:2773;width:65pt'>
        <col width=113 style='mso-width-source:userset;mso-width-alt:3626;width:85pt'>
        <col width=84 style='mso-width-source:userset;mso-width-alt:2688;width:63pt'>
        <col width=85 style='mso-width-source:userset;mso-width-alt:2730;width:64pt'>
        <col width=103 style='mso-width-source:userset;mso-width-alt:3285;width:77pt'>
        <col width=100 style='mso-width-source:userset;mso-width-alt:3200;width:75pt'>
        <col width=73 style='mso-width-source:userset;mso-width-alt:2346;width:55pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <col width=79 style='mso-width-source:userset;mso-width-alt:2517;width:59pt'>
        <col width=111 style='mso-width-source:userset;mso-width-alt:3541;width:83pt'>
        <tr height=44 style='mso-height-source:userset;height:33.5pt'>
         <td colspan=4 height=44 class=xl65 width=369 style='height:33.5pt;width:277pt'>Ауаның
         қозғалыс жылдамдығы<br>
           Скорость движения воздуха не более м/с<span 
         style='mso-spacerun:yes'> </span></td>
         <td colspan=4 class=xl65 width=375 style='border-left:none;width:281pt'>Жылу
         шығару<br>
           Тепловое излучение</td>
         <td colspan=2 rowspan=2 class=xl67 width=190 style='width:142pt'>Ескертуге<br>
           Примечание</td>
        </tr>
        <tr height=181 style='height:136.0pt'>
         <td colspan=2 height=181 class=xl67 width=200 style='height:136.0pt;
         width:150pt'>Өлшенген<br>
           Измеренная</td>
         <td colspan=2 class=xl67 width=169 style='border-left:none;width:127pt'>Нормалар
         бойынша оңтайлы/рұқсат етілген<br>
           оптимальная/допустимая по нормам</td>
         <td class=xl65 width=103 style='border-top:none;border-left:none;width:77pt'>Жылу
         шығару көзінің атауы<br>
           Наименование источника излучения</td>
         <td class=xl65 width=100 style='border-top:none;border-left:none;width:75pt'>Жылу
         көзінен ара қашықтық, сантиметрмен<br>
           Расстояние от источника в см</td>
         <td class=xl65 width=73 style='border-top:none;border-left:none;width:55pt'>Актинометр
         көрсеткіші Вт/м 2<br>
           Показание актинометра Вт/ м 2</td>
         <td class=xl65 width=99 style='border-top:none;border-left:none;width:74pt'>Нормалар
         бойынша рұқсат етілген кВт/ м 2<br>
           Допустимое по нормам кВт/м 2</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 style='height:16.0pt'>11</td>
         <td colspan=2 class=xl66 style='border-left:none'>12</td>
         <td class=xl66 style='border-top:none;border-left:none'>13</td>
         <td class=xl66 style='border-top:none;border-left:none'>14</td>
         <td class=xl66 style='border-top:none;border-left:none'>15</td>
         <td class=xl66 style='border-top:none;border-left:none'>16</td>
         <td colspan=2 class=xl66 style='border-left:none'>17</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="10" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="115"><col width="102"><col width="113"><col width="127"><col width="112"><col width="115"><col width="102"><col width="101"><col width="97"><col width="132"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="115" height="151" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тіркеу нөмірі<br>Регистрационный <br>номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="102" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кескін бойынша нүктелердің №<br>№ точек по эскизу</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшеу орны<br>Место измерений</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="127" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауырлық бойынша жұмыс санаты<br>Категория работ по тяжести</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="112" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тәулiктiң өлшеу жүргiзiлген уақыты<br>Время суток проведения измерений</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="203" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауа температурасы оС<br>Температура воздуха оС</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" colspan="2" width="229" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауаның салыстырмалы ылғалдылығы %<br>Относительная влажность воздуха в %</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">өлшенген<br>измеренная</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормалар бойынша оңтайлы/рұқсат етілген<br>оптимальная/допустимая по нормам</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">өлшенген<br>измеренная</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормалар бойынша оңтайлы/рұқсат етілген<br>оптимальная/<br>допустимая по нормам</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="9" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">9</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="10" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">10</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
      {
        id: 'form_table_v2_2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="91"><col width="149"><col width="100"><col width="148"><col width="105"><col width="114"><col width="113"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="240" height="71" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ауаның қозғалыс жылдамдығы<br>Скорость движения воздуха не более м/с </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" width="467" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жылу шығару<br>Тепловое излучение</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескертуге<br>Примечание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="134" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшенген<br>Измеренная</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормалар бойынша оңтайлы/рұқсат етілген<br>оптимальная/допустимая по нормам</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жылу шығару көзінің атауы<br>Наименование источника излучения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жылу көзінен ара қашықтық, сантиметрмен<br>Расстояние от источника в см</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Актинометр көрсеткіші Вт/м 2<br>Показание актинометра Вт/ м 2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормалар бойынша рұқсат етілген кВт/ м 2<br>Допустимое по нормам кВт/м 2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="11" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">11</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="12" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">12</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="13" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">13</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="14" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">14</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="15" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">15</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="16" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">16</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="17" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">17</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Продолжение таблицы',
       kazakhLabel: 'Продолжение таблицы'
      }, 
    ]
  },
  '74': {
    version: 2,
    name: 'ПРОТОКОЛ исследования образцов питьевой воды централизованного и нецентрализованного водоснабжения',
    kazakhName: 'Орталықтандырылған және орталықтандырылмаған сумен жабдықтаудың ауыз су үлгілерін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объктінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Іріктелген күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'nd_on_research_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне НҚ'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'water_conservation_methods',
        type: 'text',
        label: 'Методы консервации образца воды',
        kazakhLabel: 'Су үлгілерін консервациялау әдiстерi'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Көрсеткiштердiң атауы\r\n    Наименование показателей',
            'Анықталған\r\n  концентрация\r\n    Обнаруженная концентрация',
            'Нормативтiк\r\n  көрсеткiштер\r\n    Нормативные показатели',
            'Қолданыстағы\r\n  нормативтік құқықтық актілердің (бұдан әрі –НҚА) атауы\r\n    Наименование действующих нормативных правовых актов (далее - НПА)'
          ],
          [
            'Иісі (запах) 20оС кезіндегі баллдары(балы при\r\n  20оС)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Иісі (запах) 60оС кезіндегі баллдары (балы при\r\n  60оС)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Дәмі (привкус)20оС кезіндегі баллдары (балы при\r\n  20оС)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Түстілігі (цветность) градустар (градусы)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Лайлылығы(мутность)\r\n  стандарттық шкала бойынша мг/дм3 (по стандартной шкале)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'рН',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Қалдық хлор (остаточный хлор) мг/дмм',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Еркін\r\n  хлор (Свободный хлор) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Байланыстағы хлор (Связанный хлор) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Қалдық\r\n  озон (Остаточный озон) мг/дм3 ',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Тотығуы\r\n  (Окисляемость) мгО2/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Аммиак азоты (Азот аммиака) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Нитриттер\r\n  азоты (Азот нитритов) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Нитраттар\r\n  азоты (Азот нитратов) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жалпы кермектік (Общая жесткость) моль/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Құрғақ\r\n  қалдық (Сухой остаток) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Хлоридтер\r\n  (Хлориды) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            ' Сульфаттар (Сульфаты)\r\n  мг/дм3 ',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Темір (Железо) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мыс (Медь) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Кадмий\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мырыш\r\n  (Цинк) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Қорғасын\r\n  (Свинец) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Күшән (Мышьяк) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Ртуть\r\n  (Сынап) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Фтор\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Молибден\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Бериллий\r\n  (Ве 2+) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Қалдық\r\n  аллюминий мг/дм3\r\n    (Остаточный аллюминий)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Марганец мг/дмі',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Полифосфаттар\r\n  (Полифосфаты) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Бор\r\n  (В) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Селен\r\n  (Se) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Хром\r\n  (Cr 6+)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Хром\r\n  (Cr 3+)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Никель\r\n  (Ni) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мұнай\r\n  өнімдері/Нефтепродукты, мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Полиакриламид\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жергілікті\r\n  жағдайға тән арнаулы заттар\r\n    (Специфические вещества, характерные для местных условий) мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=858 style='border-collapse:
        collapse;width:644pt'>
       
        <col width=221 style='mso-width-source:userset;mso-width-alt:7082;width:166pt'>
        <col width=184 style='mso-width-source:userset;mso-width-alt:5888;width:138pt'>
        <col width=113 style='mso-width-source:userset;mso-width-alt:3626;width:85pt'>
        <col width=107 style='mso-width-source:userset;mso-width-alt:3413;width:80pt'>
        <col width=233 style='mso-width-source:userset;mso-width-alt:7466;width:175pt'>
        <tr height=115 style='mso-height-source:userset;height:86.5pt'>
         <td colspan=2 height=115 class=xl68 width=405 style='border-right:1.0pt solid #AEAAAA;
         height:86.5pt;width:304pt'>Көрсеткiштердiң атауы<br>
           Наименование показателей</td>
         <td class=xl63 width=113 style='border-left:none;width:85pt'>Анықталған
         концентрация<br>
           Обнаруженная концентрация</td>
         <td class=xl63 width=107 style='border-left:none;width:80pt'>Нормативтiк
         көрсеткiштер<br>
           Нормативные показатели</td>
         <td class=xl63 width=233 style='border-left:none;width:175pt'>Қолданыстағы
         нормативтік құқықтық актілердің (бұдан әрі –НҚА) атауы<br>
           Наименование действующих нормативных правовых актов (далее - НПА)</td>
        </tr>
        <tr height=23 style='mso-height-source:userset;height:17.5pt'>
         <td colspan=2 height=23 class=xl64 width=405 style='border-right:1.0pt solid #AEAAAA;
         height:17.5pt;width:304pt'>Иісі (запах) 20оС кезіндегі баллдары(балы при
         20оС)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='mso-height-source:userset;height:14.5pt'>
         <td colspan=2 height=19 class=xl64 width=405 style='border-right:1.0pt solid #AEAAAA;
         height:14.5pt;width:304pt'>Иісі (запах) 60оС кезіндегі баллдары (балы при
         60оС)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='mso-height-source:userset;height:15.5pt'>
         <td colspan=2 height=20 class=xl64 width=405 style='border-right:1.0pt solid #AEAAAA;
         height:15.5pt;width:304pt'>Дәмі (привкус)20оС кезіндегі баллдары (балы при
         20оС)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.75pt;
         width:304pt'>Түстілігі (цветность) градустар (градусы)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=40 style='mso-height-source:userset;height:30.0pt'>
         <td colspan=2 height=40 class=xl63 width=405 style='height:30.0pt;width:304pt'>Лайлылығы(мутность)
         стандарттық шкала бойынша мг/дм3 (по стандартной шкале)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='mso-height-source:userset;height:15.5pt'>
         <td colspan=2 height=20 class=xl63 width=405 style='height:15.5pt;width:304pt'>рН</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='mso-height-source:userset;height:16.25pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.25pt;
         width:304pt'>Қалдық хлор (остаточный хлор) мг/дмм</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='mso-height-source:userset;height:15.5pt'>
         <td colspan=2 height=20 class=xl63 width=405 style='height:15.5pt;width:304pt'>Еркін
         хлор (Свободный хлор) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.75pt;
         width:304pt'>Байланыстағы хлор (Связанный хлор) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='mso-height-source:userset;height:14.5pt'>
         <td colspan=2 height=19 class=xl63 width=405 style='height:14.5pt;width:304pt'>Қалдық
         озон (Остаточный озон) мг/дм3<span style='mso-spacerun:yes'> </span></td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Тотығуы
         (Окисляемость) мгО2/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.25pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.25pt;
         width:304pt'>Аммиак азоты (Азот аммиака) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Нитриттер
         азоты (Азот нитритов) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Нитраттар
         азоты (Азот нитратов) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.75pt;
         width:304pt'>Жалпы кермектік (Общая жесткость) моль/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Құрғақ
         қалдық (Сухой остаток) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='mso-height-source:userset;height:14.5pt'>
         <td colspan=2 height=19 class=xl63 width=405 style='height:14.5pt;width:304pt'>Хлоридтер
         (Хлориды) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl67 width=405 style='height:13.75pt;
         width:304pt'><span style='mso-spacerun:yes'> </span>Сульфаттар (Сульфаты)
         мг/дм3<span style='mso-spacerun:yes'> </span></td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.75pt;
         width:304pt'>Темір (Железо) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.25pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.25pt;
         width:304pt'>Мыс (Медь) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Кадмий
         мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Мырыш
         (Цинк) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='mso-height-source:userset;height:15.5pt'>
         <td colspan=2 height=20 class=xl63 width=405 style='height:15.5pt;width:304pt'>Қорғасын
         (Свинец) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.75pt;
         width:304pt'>Күшән (Мышьяк) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='mso-height-source:userset;height:14.5pt'>
         <td colspan=2 height=19 class=xl63 width=405 style='height:14.5pt;width:304pt'>Ртуть
         (Сынап) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Фтор
         мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Молибден
         мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Бериллий
         (Ве 2+) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Қалдық
         аллюминий мг/дм3<br>
           (Остаточный аллюминий)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=17 style='mso-height-source:userset;height:13.75pt'>
         <td colspan=2 height=17 class=xl63 width=405 style='height:13.75pt;
         width:304pt'>Марганец мг/дмі</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Полифосфаттар
         (Полифосфаты) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Бор
         (В) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Селен
         (Se) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Хром
         (Cr 6+)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Хром
         (Cr 3+)</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=20 style='mso-height-source:userset;height:15.0pt'>
         <td colspan=2 height=20 class=xl63 width=405 style='height:15.0pt;width:304pt'>Никель
         (Ni) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=19 style='mso-height-source:userset;height:14.5pt'>
         <td colspan=2 height=19 class=xl63 width=405 style='height:14.5pt;width:304pt'>Мұнай
         өнімдері/Нефтепродукты, мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Полиакриламид
         мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl66 width=405 style='height:16.0pt;width:304pt'>Жергілікті
         жағдайға тән арнаулы заттар<br>
           (Специфические вещества, характерные для местных условий) мг/дм3</td>
         <td class=xl63 width=113 style='border-top:none;border-left:none;width:85pt'>&nbsp;</td>
         <td class=xl63 width=107 style='border-top:none;border-left:none;width:80pt'>&nbsp;</td>
         <td class=xl63 width=233 style='border-top:none;border-left:none;width:175pt'>&nbsp;</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="4" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="287"><col width="124"><col width="123"><col width="179"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="287" height="187" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткіштердің атауы<br>Наименование показателей</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="124" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған концентрация<br>Обнаруженная концентрация</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="123" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативтік көрсеткіштер<br>Нормативные показатели</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="179" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қолданыстағы нормативтік құқықтық актілердің (бұдан әрі –НҚА) атауы<br>Наименование действующих нормативных правовых актов (далее - НПА)Қолданыстағы нормативтік құқықтық актілердің (бұдан әрі –НҚА) атауы<br>Наименование действующих нормативных правовых актов (далее - НПА)</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="115" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Иісі (запах) 20оС кезіндегі баллдары<br>(балы при 20оС)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="115" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Иісі (запах) 60оС кезіндегі баллдары <br>(балы при 60оС)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="134" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Дәмі (привкус)20оС кезіндегі баллдары <br>(балы при 20оС)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Түстілігі (цветность) градустар (градусы)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="59" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Лайлылығы(мутность) стандарттық шкала бойынша мг/дм3 (по стандартной шкале)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">рН</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қалдық хлор (остаточный хлор) мг/дмм</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Еркін хлор (Свободный хлор) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Байланыстағы хлор (Связанный хлор) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қалдық озон (Остаточный озон) мг/дм3&nbsp;</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тотығуы (Окисляемость) мгО2/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аммиак азоты (Азот аммиака) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нитриттер азоты (Азот нитритов) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нитраттар азоты (Азот нитратов) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жалпы кермектік (Общая жесткость) моль/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Құрғақ қалдық (Сухой остаток) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Хлоридтер (Хлориды) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сульфаттар (Сульфаты) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Темір (Железо) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Мыс (Медь) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кадмий мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Мырыш (Цинк) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қорғасын (Свинец) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Күшән (Мышьяк) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ртуть (Сынап) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Фтор мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Молибден мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бериллий (Ве 2+) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қалдық аллюминий мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">(Остаточный аллюминий)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Марганец мг/дмі</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Полифосфаттар (Полифосфаты) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Бор (В) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Селен (Se) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Хром (Cr 6+)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Хром (Cr 3+)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Никель (Ni) мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="40" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Мұнай өнімдері/Нефтепродукты, мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Полиакриламид мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жергілікті жағдайға тән арнаулы заттар</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" rowspan="2" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="59" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">(Специфические вещества, характерные для местных условий) мг/дм3</font></td>
            </tr>
          <tr>
            <td style="border-top: 3px solid #000000" colspan="4" height="56" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді /<br>(Исследование проб проводились на соответствие НД)______________________</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },
    ]
  }
}
