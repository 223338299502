import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl, InputLabel, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import { Table } from "../../../components/PDFGenerator/Table";
import { GovernmentStandardsSelect, TransportationConditionSelect } from "../../../components/Select/domainSelects";

const DirectionParasiteForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>1. Тіркеу № (регистрационный №)</p>
        <TextField
          id="outlined-basic"
          label="регистрационный №"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.registrationNumber?.value}
          type="number"
          onChange={(e) => handleChangeData('registrationNumber', e.target.value, 'registrationNumber')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Материал жіберілетін санитариялық-эпидемиологиялық сараптама
          орталығы зертханасының атауы (Наименование лаборатории центра
          санитарно-эпидемиологической экспертизы, в которую направляется
          материал)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование лаборатории центра санитарно-эпидемиологической
              экспертизы, в которую направляется материал"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.laboratory?.value}
          onChange={(e) => handleChangeData('laboratory', e.target.value, 'laboratory')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Нұсқаның атауы және саны (Наименование и число образцов)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование и число образцов"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.samplesInfo?.value}
          onChange={(e) => handleChangeData('samplesInfo', e.target.value, 'samplesInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          4. Ыдысы, орамасы, таңбалау (Тара, упаковка, маркировка)
        </p>
        <TextField
          id="outlined-basic"
          label="Тара, упаковка, маркировка"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.packMark?.value}
          onChange={(e) => handleChangeData('packMark', e.target.value, 'packMark')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5. Нормативтік құжаттың аты, сынама іріктелген орын, уақыты,
          мезгілі (Место, дата и время отбора, наименование нормативного
          документа)
        </p>
        <TextField
          id="outlined-basic"
          label="Место, дата и время отбора, наименование нормативного документа"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.selectionInfo?.value}
          onChange={(e) => handleChangeData('selectionInfo', e.target.value, 'selectionInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Сынамаларды паразитологиялық зертаханаға жеткізу уақыты және
          шарттарды (Время и условия доставки проб в паразитологическую
          лабораторию)
        </p>
        <TextField
          id="outlined-basic"
          label="Время и условия доставки проб в вирусологическую лабораторию"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.deliveryInfo?.value}
          onChange={(e) => handleChangeData('deliveryInfo', e.target.value, 'deliveryInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Зерттеу мақсаты (Цель исследования)
        </p>
        <TextField
          id="outlined-basic"
          label="Цель исследования"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.researchPurpose?.value}
          onChange={(e) => handleChangeData('researchPurpose', e.target.value, 'researchPurpose')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Зерттеуге дәлелдеме: ағымдығы санитариялық қадағалау
          тәртібімен, эпидемиологиялық көрсеткіші бойынша (астын сызу немесе
          қосып жазу) (Показания к исследованию: в порядке текущего
          санитарного надзора, по эпидемиологическим показаниям)
        </p>
        <TextField
          id="outlined-basic"
          label="Показания к исследованию: в порядке текущего
              санитарного надзора, по эпидемиологическим показаниям"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.researchIndications?.value}
          onChange={(e) => handleChangeData('researchIndications', e.target.value, 'researchIndications')}
        />
      </div>
    </div >
  );
};

export default DirectionParasiteForm;
