import { 
    RESULT_FORM_GET_REQUEST, RESULT_FORM_GET_SUCCESS, RESULT_FORM_GET_ERROR, 
    RESULT_FORM_GET_BY_KEY_REQUEST, RESULT_FORM_GET_BY_KEY_SUCCESS, RESULT_FORM_GET_BY_KEY_ERROR, 
    RESULT_FORM_ADD_REQUEST, RESULT_FORM_ADD_SUCCESS, RESULT_FORM_ADD_ERROR, 
    RESULT_FORM_EDIT_REQUEST, RESULT_FORM_EDIT_SUCCESS, RESULT_FORM_EDIT_ERROR, 
    RESULT_FORM_DELETE_REQUEST, RESULT_FORM_DELETE_SUCCESS, RESULT_FORM_DELETE_ERROR
} from "../constants/resultFormConstants";

const initialState = {
    resultForms: [],
    resultForm: {},
    loading: false,
    success: null,
    error: null,
    resultFormRequestedCount: 0,
};

export const resultFormReducer = (state = initialState, action) => {
    switch (action.type)     {
        case RESULT_FORM_GET_REQUEST:
            return {
                ...state,
                resultFormRequestedCount: ++state.resultFormRequestedCount,
                loading: true,
            };
        case RESULT_FORM_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                resultForms: action.payload,
                success: true,
            };
        case RESULT_FORM_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_FORM_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_FORM_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                resultForm: action.payload,
                success: true,
            };
        case RESULT_FORM_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_FORM_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_FORM_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_FORM_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_FORM_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_FORM_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_FORM_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case RESULT_FORM_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case RESULT_FORM_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case RESULT_FORM_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
