import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import forms, { common, isFieldVersion } from './forms'
import keyMapping from './mapping'
import { Formik } from 'formik'
import { decodeEntity } from 'html-entities'
import styles from './styles.module.scss'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ruLocale from 'date-fns/locale/ru'
import DateTimePicker from '@mui/lab/DateTimePicker'
import EmployeeField from "../../../components/Relations/EmployeeField"
import Typography from '@mui/material/Typography'
import { distinct } from '../../../models/data/EntityCollectionModel'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputLabel,
  MenuItem,
  FormGroup,
  Checkbox,
  Stack,
  Grid
} from '@mui/material'
import {Select} from 'antd'
import Select2 from '@mui/material/Select'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import table from 'suneditor/src/plugins/submenu/table'

import htmlConverter from 'html2json'
import { mapProtocolFieldNumber } from '../../../functions/viewDataMap'
import {mappingActToProtocolData} from "../../../functions/mapping/protocols";
import {mappingObjectReferralToProtocolData, mappingReferralToProtocolData} from "../../../functions/mapping/protocolsReferral";
import { DatePicker } from '@mui/lab'
import {useDropzone} from "react-dropzone";
import {CloudUpload, InsertDriveFile} from "@material-ui/icons";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import { parseJsonSafe, toUIJson } from '../../../functions'
import logos, { filialsMapping } from '../PDFGenerator/logos'
import optionalLogo from '../PDFGenerator/logos/common/ilac_mra.png';
import useRoles from '../../../hooks/useRoles'
import { selectMany } from '../../../functions/linqSimulation'


const defaults = {
  number: 0,
  switch: 0,
  string: '',
  datetime: null,
  table: undefined
}

const validators = {
  number: () => true,
  switch: () => true,
  text: () => true,
  file: () => true,
  employee: () => true,
  date: (date, required) => {
    const isEmpty = date === null || date === '' || date === undefined;

    if (isEmpty && !required)
      return true;

    const result = isEmpty || (date !== 'Invalid Date' && date instanceof Object && !isNaN(date));

    try {
      if (!result && (date || "").endsWith("Z")) {

          const parsedDate = new Date(result);
          return true;
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }

    return result;
  },
  datetime: (date, required) => {
    const isEmpty = date === null || date === '' || date === undefined;

    if (isEmpty && !required)
      return true;

    const result = isEmpty || (date !== 'Invalid Date' && (date instanceof Date || date.getDate) && !isNaN(date));

    try {
      if (!result) {
        const isString = typeof date === 'string' || date instanceof String;

        if (isString && (date || "").endsWith("Z")) {
          const parsedDate = new Date(result);
          return true;
        }
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }

    return result;
  },
  table: () => true
}

ProtocolForms.propTypes = {
  protocolID: PropTypes.number,
  formID: PropTypes.number,
  protocolData: PropTypes.object,
  handleGetProtocol: PropTypes.func,
  onSubmit: PropTypes.func,
  onEdit: PropTypes.func,
  allowedProtocols: PropTypes.array,
}


const {Option} = Select

export default function ProtocolForms(props) {

  const [initialValues, setInitialValues] = React.useState({})
  const [isActive, setIsActive] = React.useState(false)
  const [results, setResults] = React.useState(null);
  const [formID, setFormID] = React.useState(props.formID)
  const mode = props.protocolID ? 'editing' : 'creating';
  const referral = props?.referral || {};
  console.log(props)
  const act = props.act?.data ? props.act?.data : {};
  const actData = props?.act || {};
  const actNumber = props.act?.form?.number ? props.act?.form?.number : null;
  const jsonProtocolData = toUIJson(props?.protocol?.data)
  const [logo, setLogo] = React.useState(jsonProtocolData? jsonProtocolData ["logoName"] : '')
  const [availableLogos, setAvailableLogos] = useState(null);
  const [addOptionalLogo, setAddOptionalLogo] = React.useState(jsonProtocolData? jsonProtocolData ["addOptionalLogo"] : false)
  const [employee, setEmployee] = useState(parseJsonSafe(window.localStorage.employee));
  const { isAdministrator } = useRoles();

  
  const saveInitialState = protocol => {
    setInitialValues(
      Object.fromEntries(
        Object.entries(protocol)
          .map(
            ([fieldName, value]) => {
              const field = forms[props.formID.id].fields.find(({ id }) => id === fieldName)
              let fieldValue = value?.value ?? value
              if(field?.type === 'table' && field.htmlTable) {
                fieldValue = htmlConverter.json2html(fieldValue)
              }
              return [fieldName, fieldValue]
            }
          )
      )
    )
  }

  const detectResults = async () =>
  {
    let result = [];

    let formMapsByProtocol = props?.formMaps?.filter(_ => _.protocolForm?.number == formID?.id);
    let resultFormIds = formMapsByProtocol?.map(_ => _.resultFormId);

    if (resultFormIds?.length) {
      let resultFormIdsUn = distinct(resultFormIds, (a, b) => a == b);
      props?.result?.some(r => {
        if (resultFormIdsUn.includes(r.formId)) {
          result.push(r)
        }
      })
    }

    return result;
  };

  React.useEffect(async () => {
    // results
    // formMaps
    // formId (protocol)
    if (!formID)
      return;

    try {
      const candidates = await detectResults();

      if (candidates.length > 1) {
        await setResults(candidates);
      }

      // multiple results detected
      if (candidates?.length > 1) {
        return;
      }

      // switch to protocol view
      await switchToProtocolView();
      await mappingProtocolFromAct()
      await mappingProtocolFromObjectReferral()
    } catch (e) {
      // debugger;
      throw e;
    }
  }, [mode, formID]);

  const switchToProtocolView = async () => {
    try {
    let data = {};
    if (props.handleGetProtocol) {
      data = await props.handleGetProtocol();
    }

    const mapping = keyMapping[formID.id]
    const map = data => Object.fromEntries(
      Object.entries(data)
        .filter(([key,]) => mapping?.[key])
        .map(([key, value]) => ([mapping?.[key] ?? key, value])))
    if(props.protocolData?.act) data = { ...map(props.protocolData.act) }
    if(props.protocolData?.journal) data = { ...map(props.protocolData.journal) }
    saveInitialState(data);
    setIsActive(true);
    } catch (e) {
      // debugger;
      throw e;
    }
  }

  const mappingProtocolFromAct = () => {
    if (Object.keys(act)?.length) {
      if (mode === 'creating') {
        console.log(act)
        // const parsedData = JSON.parse(act);
        const data = mappingActToProtocolData(act, forms?.[formID?.id]?.fields, formID?.id, actNumber)
        setInitialValues(prevState => ({...prevState, ...data}))
      }
    }
  }
  const mappingProtocolFromObjectReferral = () => {
    if (!Object.keys(act)?.length) {
      if (mode === 'creating') {
        const data = mappingObjectReferralToProtocolData(referral, forms?.[formID?.id]?.fields)
        setInitialValues(prevState => ({...prevState, ...data}))
      }
    }
  }

  const mappingProtocolFromReferral = () => {
    if (!Object.keys(act)?.length) {
      if (mode === 'creating') {
        const data = mappingReferralToProtocolData(referral, forms?.[formID?.id]?.fields)
        setInitialValues(prevState => ({...prevState, ...data}))
      }
    }
  }

  useEffect(async () => {
    const logoMappings = filialsMapping
      .filter(logosGroup => {
        if (isAdministrator())
          return true;
        if (Array.isArray(logosGroup.filialId)) {
            return logosGroup.filialId.includes(employee.filialId)
        } else {
            return logosGroup.filialId === employee.filialId
        }
      })
      .filter(logosGroup => {
        if (isAdministrator())
          return true;
        if (!logosGroup.filialDivisionId || !employee.filialDivisionId) return true;
          if (Array.isArray(logosGroup.filialDivisionId)) {
              return logosGroup.filialDivisionId.includes(employee.filialDivisionId)
          } else {
              return logosGroup.filialDivisionId === employee.filialDivisionId
          }
      });
    let logos = selectMany(logoMappings, _ => _.logo);
    logos = distinct(logos, (a, b) => a == b);
    setAvailableLogos(logos);

}, [props?.protocol, employee?.filialId]);

// console.log(props)
  return (
    isActive === false
      ? <><Autocomplete
        disablePortal
        name='formType'
        value={formID}
        onChange={(_, value) => setFormID(value)}
        noOptionsText='Ничего не найдено'
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={Object.entries(forms)
          .map(([id, { name }]) => ({ id, label: `№${id} ${name}` }))
          .filter(({ id }) => window.localStorage?.disableFormsFiltering || props.allowedProtocols?.includes(id))
        }
        renderInput={params => <TextField {...params} label='Тип формы' />}
      />
      {results?.length ?
        <>
        <FormControl margin={"normal"} fullWidth>
            <span>Протоколу соотвествует несколько журналов</span>
            <Select
                onChange={async (value) => {
                  await props?.setSelectedResultId(value);
                  await switchToProtocolView();
                  await mappingProtocolFromAct()
                  await mappingProtocolFromObjectReferral()
                  //setIsActive(true)
                }}
                id="objectId"
                value={props.selectedResultId}
                placeholder="Журнал"
            >
                {results && results.map(_ => (
                    <Option key={_.id} value={_.id}>
                        {`${_.id}) ${_.form?.number}) ${_?.number}`}
                    </Option>
                ))}
            </Select>
        </FormControl>
        </>
         : <></>}
      </>
      : <>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Тип формы: {forms[formID.id].name}
        </Typography>
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validateOnChange={false} validateOnBlur={false}
            validate={values => {
            const errors = {}
            forms[formID.id].fields.forEach(field => {
              let value = values[field.id]
              if(field.type === 'switch') values[field.id] = value ?? 0;

              let required = true;

              if (field.required == false) {              
                required = false;
              }
              // if(field.type === 'employee') values[field.id] = value?.fullName
              if(!validators[field.type]?.(value, required)) {
                // debugger;
                return errors[field.id] = 'Заполните поле';
              }
            })
            return errors
          }}
          onSubmit={(values, { setSubmitting }) => {
            const resultValues = Object.fromEntries(Object.entries(values).map(([fieldID, value]) => {
              //let newValue = JSON.parse(JSON.stringify(value))
              let newValue = value;
              const fieldInfo = [...forms[formID.id].fields, ...common].find(({ id }) => id === fieldID)
            
              if (!fieldInfo) {
                return [fieldID, null];
              }
            
              if(fieldInfo.type === 'table' && fieldInfo.htmlTable) newValue = htmlConverter.html2json(newValue)
              return [fieldID, newValue]
            }))
              if(logo)
                resultValues.logoName = logo;
              if(addOptionalLogo)  
                resultValues.addOptionalLogo = addOptionalLogo;
            props[mode === 'creating' ? 'onSubmit' : 'onEdit'](resultValues, formID.id).then(() => setSubmitting(false))
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            setTouched,
            touched
          }) => {
            const fields = forms?.[formID?.id]?.fields
            if(fields && Object.keys(touched).length !== fields.length)
              setTouched(Object.fromEntries(fields.map(({ id }) => [id, true])))
            return (
              <form onSubmit={handleSubmit} className={styles.form}>
                      <Grid item xs={12}>
                        <Stack spacing={2} sx={{marginTop: 2}}>
                          <div>
                          <FormControl fullWidth>
                              <InputLabel id='logo-select'>Выберете логотип для документа</InputLabel>
                              <Select2
                                  labelId='logo-select'
                                  id="demo-simple-select"
                                  value={logo}
                                  label='Выберете логотип для документа'
                                  onChange={({target: {value}}) => setLogo(value)}
                              >
                                  <MenuItem value=''>
                                      Без логотипа
                                  </MenuItem>
                                  {availableLogos && availableLogos.map(logoURL => (
                                      <MenuItem value={logoURL} key={logoURL}>
                                          <img src={logoURL} alt='Логотип филиала'
                                                style={{height: 70}}/>
                                      </MenuItem>
                                  ))}
                              </Select2>
                          </FormControl>
                          </div>
                          <div>
                            <FormGroup>
                              <FormControlLabel
                                  control={<Checkbox checked={addOptionalLogo}
                                                      onChange={(_, value) => setAddOptionalLogo(value)}/>}
                                  label='Добавить логотип ilac-MRA'
                              />
                            </FormGroup>  
                          </div>
                        </Stack>
                      </Grid>

                {formID !== null && initialValues && [...forms[formID.id].fields, ...common].map((fieldInfo, i) => {
                  if(fieldInfo.type === 'employee') {
                    //debugger;
                  }
                  const defaultProps = {
                    error: Boolean(errors[fieldInfo.id]),
                    helperText: errors[fieldInfo.id],
                    id: fieldInfo.id,
                    name: fieldInfo.id,
                    label: fieldInfo.label,
                    value: values?.[fieldInfo.id] ?? defaults[fieldInfo.type],
                    onChange: eventOrValue => {

                      try {
                      } catch (e) {

                      }

                      if(eventOrValue?.type === 'change') {
                        handleChange(eventOrValue)
                      }
                      else {
                        setFieldValue(fieldInfo.id, eventOrValue)
                      }
                    }
                  }
                  const sharedProps = { ...defaultProps, ...fieldInfo.props }
                  const fields = {
                    text: <TextField />,
                    number: <TextField type='number' />,
                    date: <Date />,
                    datetime: <DateTime />,
                    file: <File />,
                    switch: <Switch options={fieldInfo.options} />,
                    employee: <EmployeeField label=' ' object={sharedProps.value} />,
                    table: fieldInfo.htmlTable
                      ? <TableNew data={fieldInfo.htmlTable} fieldInfo={fieldInfo} />
                      : <TableOld data={fieldInfo.rows} />,
                    label: <div></div>
                  }
                  if(fieldInfo.type === 'employee') {
                    delete sharedProps.label
                  }

                  let protocolVersion = forms[formID.id].version;

                  if (!protocolVersion) {
                    protocolVersion = 1;
                  }

                  if (fieldInfo.htmlTable && !isFieldVersion(fieldInfo, protocolVersion)) {
                    return <></>;
                  }

                  return <div key={i} className={styles.field}>
                    {fieldInfo.type !== 'table' &&
                      <div>{fieldInfo.type === 'employee' ? '':`${mapProtocolFieldNumber(fieldInfo.number, i)}`}{fieldInfo.label}</div>
                    }
                    <div style={{ maxWidth: '100%' }}>{React.cloneElement(fields[fieldInfo.type], sharedProps)}</div>
                  </div>
                })}
                <Button
                  variant='contained'
                  type='submit'
                  disabled={isSubmitting || !formID}
                  style={{"min-height": "40px"}}
                >
                  {mode === 'creating' ? 'Создать протокол' : 'Сохранить протокол'}
                </Button>
              </form>
            )
          }}
        </Formik>
      </>
  )
}

Switch.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
  label: PropTypes.string,
}
function Switch({ value, onChange, ...props }) {
  // const tabsStyles = appleTabsStylesHook.useTabs()
  // const tabItemStyles = appleTabsStylesHook.useTabItem()

  React.useEffect(() => {
    if(onChange && (value === undefined || value === null)) onChange(0)
  }, [onChange, value])

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        {props.options.label}
      </FormLabel>
      <RadioGroup
        aria-label="gender"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e, index) => onChange(index)}
      >
        {props.options.map(option => (
            <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option}
            />
        ))}
      </RadioGroup>
    </FormControl>

    // <Tabs
    //   classes={tabsStyles}
    //   value={value}
    //   onChange={(e, index) => onChange(index)}
    //   {...props}
    // >
    //   {props.options.map(option => <Tab classes={tabItemStyles} disableRipple label={option} key={option} />)}
    // </Tabs>
  )
}

function Date({ onChange, value, ...sharedProps }) {
  // React.useEffect(() => {
  //   if(!onChange) return
  //   if(typeof value === 'string') onChange?.(new Date(value))
  //   else if(value === null) onChange?.(new Date())
  // }, [onChange, value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <DatePicker
        renderInput={props => <TextField {...props} error={sharedProps.error} helperText={sharedProps.helperText} />}
        showTodayButton
        onChange={onChange}
        value={value}
        {...sharedProps}
      />
    </LocalizationProvider>
  )
}

function DateTime({ onChange, value, ...sharedProps }) {
  // React.useEffect(() => {
  //   if(!onChange) return
  //   if(typeof value === 'string') onChange?.(new Date(value))
  //   else if(value === null) onChange?.(new Date())
  // }, [onChange, value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <DateTimePicker
        renderInput={props => <TextField {...props} error={sharedProps.error} helperText={sharedProps.helperText} />}
        showTodayButton
        onChange={onChange}
        value={value}
        {...sharedProps}
      />
    </LocalizationProvider>
  )
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

function File({ value, onChange, ...props }) {

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop: acceptedFiles1 => {
      toBase64(acceptedFiles1[0]).then(r => {
        onChange(r)
      })
    },
    multiple: false,
    maxFiles: 1,
    accept: {
      'image/jpeg': [],
      'image/png': []
    }
  });

  return (
      <>
        <Paper {...getRootProps()} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} variant="outlined">
          <CloudUpload/>
          <input {...getInputProps()} multiple={false}/>
          <p>Перетащите файл или нажмите сюда</p>
        </Paper>
        <List>
          {
            acceptedFiles.map((f, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <InsertDriveFile/>
                  </ListItemIcon>
                  <ListItemText primary={f.name} secondary={f.size}/>
                </ListItem>
            ))
          }
        </List>
      </>
  )
}

function TableOld(props) {
  React.useEffect(() => {
    if(!props.value) {
      props.onChange(props.data.map(row => row.map(cell => decodeEntity(cell))))
    }
  }, [props.value])

  const changeCell = (x, y) => e => {
    props.value[y][x] = e.target.value
    props.onChange(props.value)
  }

  return (
    <div className={styles.table}>
      {props.data.map((row, i) => (
        <div className={styles.row} key={i}>
          {row.map((cell, j) => (
            <div className={styles.cell} key={j}>
              <textarea value={props.value?.[i]?.[j]} onInput={changeCell(j, i)} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

function TableNew(props) {
  const noContent = !props.value || /^<p>([\u200B\s]|<br>)*<\/p>$/.test(props.value)
  React.useEffect(() => {
    if(noContent) {
      props.onChange(props.data)
    }
  }, [props.value])

  return (
    <div className={styles.table} style={{"paddingBottom": "100px"}}>
      {props.fieldInfo.label && <div>{props.fieldInfo.label}</div>}
      <SunEditor
        lang='ru'
        width='fit-content'
        height='auto'
        mode='balloon-always'
        defaultTag='table'
        setOptions={{
          mode: 'balloon-always',
          "addTagsWhitelist": "talbe",
          "buttonList": [
            [
              "table"
            ]
          ],
          plugins: [table],
        }}
        setContents={noContent ? props.htmlTable : props.value}
        onChange={props.onChange}
        //onScroll={(e) => false}
      />
    </div>
  )
}
