import m_08_2030 from './KZ.M.08.2030.svg'
import m_08_2030_b from './KZ.M.08.2030 black.svg'
import t_08_e0663_colorized from './KZ.T.08.Е0663.svg'
import t_08_e0663 from './KZ.T.08.Е0663 black.svg'

export default [
  m_08_2030,
  m_08_2030_b,
  t_08_e0663_colorized,
  t_08_e0663
]
