import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid, TextField } from "@mui/material";
import useDialogs from "../../components/Dialog/useDialogs";
import useLoader from "../../hooks/useLoader";
import { renderControls } from "../../functions";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import { formatDateAsDate, formatDateAsDateTime } from "../../functions/formatters";
import { getServiceMapByKey } from "../../redux/actions/serviceMapActions";
import { SERVICE_MAP_LIST_ROUTE } from "../../routes";
import Label from "../../components/Fields/label";

export const ServiceMapDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const {
        loading: fetchLoading,
        start: fetchStart,
        stop: fetchStop,
    } = useLoader(true);
    const [data, setData] = useState({});

    const {alert, DIALOG} = useDialogs();

    const fetch = async () => {
        fetchStart();

        const item = await dispatch(getServiceMapByKey(id));
        if (!item) {
            alert('Нет данных (неверный ID или данная запись удалена)!');
            fetchStop();
            return;
        }

        setData(item);
        fetchStop();
    };

    useEffect(async () => {
        await fetch();
    }, [dispatch, id, fetchStart, fetchStop]);

    const controls = [
    {
        state: "current",
        buttons: [
            <Button
                variant={"text"}
                onClick={() => push(SERVICE_MAP_LIST_ROUTE)}
            >
                Вернуться
            </Button>,
        ],
    }
    ];

    return (
        <DashboardLayout>
            <DIALOG/>
            <PageHeader heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
            </PageHeader>
            <PageContainer>
                <FormSection>
                    <Grid container>
                        <Label>{data.object?.name}</Label>
                        <Label>{data.group?.name}</Label>
                        <Label>{data.subGroup?.name}</Label>
                        <Label>{data.kind?.name}</Label>
                        <Label>{data.researchStandard?.name}</Label>
                        <Label>{data.governmentStandard?.name}</Label>
                        <Label>{data.regulationOfCU?.name}</Label>
                    </Grid>
                </FormSection>
            </PageContainer>
            <PageFooter heading={`ID записи ${data?.id}`} controls={renderControls(controls, {})}>
            </PageFooter>
        </DashboardLayout>
    )
};
