import React, {useState} from "react";

import {diseaseIndexCRUD} from "../../../http/CRUD";
import CRUDTable from "../CRUDTable";
import {DISEASE_INDEX_DETAIL_ROUTE} from "../../../routes";
import {useHistory} from "react-router-dom";

export default function DiseaseIndexCRUDTable({...props}) {
    const {handleChangeFilterCrud} = props
    const {push} = useHistory()
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "Название", dataIndex: 'name', key: 'name'},
        {
            title: "Должность",
            dataIndex: "description",
            key: 'description',
            render: (unknown, row) => row.description ? row.description : <span style={{color: "gray"}}>Не задано</span>
        }
    ]
    const searchFields = [
        {
            title: "Id",
            key: "id",
            compareType: "equals",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "Название",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
    ]

    const filterFields = [
    ]

    return (
        <CRUDTable search={diseaseIndexCRUD.search}
                   columns={columns} {...props}
                   onClickCreateButton={() => push(DISEASE_INDEX_DETAIL_ROUTE("new"))}
                   searchBy={"name"}
                   searchByOperator={"like"}
                   searchPlaceholder={"Введите название"}
                   searchFields={searchFields}
                   filterFields={filterFields}
                   rowClassNameHandler={(record) => {
                       if (record?.isDeleted) {
                           return 'banned'
                       }
                   }}
                   {...props}
        />
    );
}
