import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "antd";
import { makeStyles } from "@mui/styles";
import {
  TablePagination,
  Button,
  Grid,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import { CheckBox, ControlPoint as PlusIcon } from "@mui/icons-material";

import useLoader from "../../hooks/useLoader";

const useStyles = makeStyles({
  trClickable: {
    cursor: "pointer",
  },
});

export const EnhancedCRUDTable = ({
  extraFilter,
  search,
  columns,
  toolbar,
  onClickCreateButton,
  onClickEditButton,
  onClickDeleteButton,
  extraActions,
  reloadValue,
  rowKey,
  extraHeader,
  onRowClick,
  ...props
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [data, setData] = useState([]);

  const [total, setTotal] = useState(0);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(10);

  const [loading, setLoading] = useState(false);
  const [sort] = useState({ id: { operator: "desc" } });
  const [filter, setFilter] = useState({});

  const paging = useMemo(
    () => ({ take, skip, returnCount: true }),
    [take, skip]
  );

  const fetch = useCallback(async () => {
    setLoading(true);

    const data = await search({
      paging,
      sort,
      filter: { ...filter, ...(extraFilter || {}) },
    });

    if (!data) return setLoading(false);

    setData(data.result);
    setTotal(data.total);

    setLoading(false);
  }, [paging, filter, sort, search]);

  useEffect(fetch, [fetch, reloadValue]);

  const rowClassName = useMemo(() => {
    const classNames = [];
    if (onRowClick) classNames.push(classes.trClickable);
    return classNames.join(" ");
  }, [onRowClick, classes]);

  return (
    <>
      {toolbar && (
        <Grid
          container
          mb={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={6}></Grid>
          {onClickCreateButton && <Grid
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={onClickCreateButton}>Добавить</Button>
          </Grid>}
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          <Table
            columns={columns}
            rowKey={rowKey}
            dataSource={data}
            loading={loading}
            pagination={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => onRowClick && onRowClick(record, rowIndex),
              };
            }}
            rowClassName={rowClassName}
            {...props}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            labelRowsPerPage=""
            rowsPerPageOptions={[]}
            count={total}
            page={skip / take}
            rowsPerPage={take}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
            onPageChange={(e, page) => {
              setSkip(page * take);
            }}
            // onRowsPerPageChange={(event) => {
            //   setSkip(0);
            //   setTake(event.target.value);
            // }}
          />
        </Grid>
      </Grid>
    </>
  );
};

EnhancedCRUDTable.defaultProps = {
  toolbar: true,
  rowKey: "id",
};
