import React from 'react';
import CRUDTable from "../CRUDTable";
import {serviceCRUD, serviceGroupCRUD} from "../../../http/CRUD";
import {getDepartments, getDivisions} from "../../../redux/actions";
import {getServiceGroups} from '../../../redux/actions/serviceGroupActions';
import {getServiceSubGroups} from '../../../redux/actions/serviceSubGroupActions';
import {getServiceKinds} from '../../../redux/actions/serviceKindActions';

export default function ServiceGroupCRUDTable({ defaultFilter, ...props}) {
    const columns = [
        {title: "ID", dataIndex: 'id', key: 'id'},
        {title: "Наименование", dataIndex: 'name', key: 'name'},
        {title: "Код", dataIndex: 'code', key: 'code'},
        {title: "Описание", dataIndex: 'description', key: 'description'},
        {title: "Акт", dataIndex: 'actFormId', key: 'actFormId'},

    ]

    const searchFields = [
        {
            title: "Наименование",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ];

    const filterFields = [
        {
            title: "Наименование",
            key: "name",
            compareType: "equals",
            reducerKey: "name",
            reducerArrayKey: "name",
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
    
    ];

    return <CRUDTable
        search={serviceGroupCRUD.search}
        columns={columns}
        searchFields={searchFields}
     //   filterFields={filterFields}
        defaultFilter={defaultFilter}
        {...props}
    />
}