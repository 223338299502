import { resultCRUD } from "../../http";
import {
  RESULT_ADD_ERROR,
  RESULT_ADD_REQUEST,
  RESULT_ADD_SUCCESS,
  RESULT_DELETE_ERROR,
  RESULT_DELETE_REQUEST,
  RESULT_DELETE_SUCCESS,
  RESULT_EDIT_ERROR,
  RESULT_EDIT_REQUEST,
  RESULT_EDIT_SUCCESS,
  RESULT_GET_BY_KEY_ERROR,
  RESULT_GET_BY_KEY_REQUEST,
  RESULT_GET_BY_KEY_SUCCESS,
  RESULT_GET_ERROR,
  RESULT_GET_REQUEST,
  RESULT_GET_SUCCESS,
} from "../constants/resultConstants";

export const getResults = (args) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_GET_REQUEST,
    });

    const data = await resultCRUD.search(args);
    dispatch({
      type: RESULT_GET_SUCCESS,
      payload: data,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: RESULT_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getResultByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_GET_BY_KEY_REQUEST,
    });

    const data = await resultCRUD.get(id);
    dispatch({
      type: RESULT_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: RESULT_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addResult = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_ADD_REQUEST,
    });

    const response = await resultCRUD.create(data);
    await dispatch(getResults(args));
    dispatch({
      type: RESULT_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: RESULT_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editResult = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_EDIT_REQUEST,
    });

    await resultCRUD.edit(data);
    await dispatch(getResults(args));

    dispatch({
      type: RESULT_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: RESULT_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteResult = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: RESULT_DELETE_REQUEST,
    });

    await resultCRUD.delete(id);
    await dispatch(getResults(args));

    dispatch({
      type: RESULT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: RESULT_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
