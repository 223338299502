import t_05_0594_b from '././aktobe/KZ.T.05.0594 black.svg'
import t_05_0294 from '././aktobe/KZ.T.05.0294.svg'
import t_05_0594 from '././aktobe/KZ.T.05.0594.svg'
import m_05_2165 from '././aktobe/KZ.М.05.2165 black.svg'
import m_05_2165_b from '././aktobe/KZ.М.05.2165.svg'
import t_05_2332_b from '././kargalinsk/KZ.T.05.2332 black.svg'
import t_05_2332 from '././kargalinsk/KZ.T.05.2332.svg'
import t_05_2255_b from '././mugalzharskoe/KZ.T.05.2255 black.svg'
import t_05_2255 from '././mugalzharskoe/KZ.T.05.2255.svg'
import t_05_2331_b from '././shalkar/KZ.T.05.2331 black.svg'
import t_05_2331 from '././shalkar/KZ.T.05.2331.svg'

// import t_05_0294 from './t_05_0294.png'

export default [
  t_05_0594_b,
  t_05_0594,
  t_05_0294,
  m_05_2165,
  m_05_2165_b,
  t_05_2255,
  t_05_2255_b,
  t_05_2331,
  t_05_2331_b,
  t_05_2332,
  t_05_2332_b
]
