import React, { Component, useEffect, useState } from 'react';
import Chart from 'react-apexcharts'
import { dashboardCRUD } from '../../http';
import DashboardLayout from '../../layouts/Dashboard';
import { DateField } from '../../components/Fields/DateField';
import { Stack } from '@mui/material';
import FilterDatePicker from '../../components/DatePicker/FilterDatePiecker';
import { yearEnd, yearStart } from '../../functions/dateHelpers';

export const Dashboard = () => {
    const start = yearStart();
    const end = yearEnd();
    const [data, setData] = useState(null);

    const [query, setQuery] = useState({
        paging: { skip: 0 },
        filter: {
            researchEndDateTime: {
                operand1: start,
                operand2: end,
                operator: "between"
            }
        }
    });

    const fetchAsync = async () => {
        const response = await dashboardCRUD.post('requestTarifications/quantity', query);
        setData(response);
    }

    useEffect(() => {
        fetchAsync();
    }, [query]);

    console.log(data);


    const barOptions = {
        options: {
            chart: {
                type: 'bar',
                height: 450,
                stacked: true,
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            dataLabels: {
                formatter: (val) => {
                    if (val) { return val.toFixed(2) }
                    else { return '' }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            xaxis: {
                categories: data ? data.map(_ => _.name) : [],
                labels: {
                    formatter: (val) => {
                        if (val) { return val.toFixed(2) }
                        else { return '' }
                    }
                }
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left'
            }
        }
    }



    return (
        <DashboardLayout>
            <h4>Исполнение государственного заказа за период</h4>
            <Stack direction="row" alignItems="center" justifyContent="normal" margin={3}>
                <FilterDatePicker key="start" label="от" value={query.filter.researchEndDateTime.operand1} handleFunction={(value) => {
                    if (!value) {
                        return;
                    }
                    query.filter.researchEndDateTime.operand1 = value;
                    setQuery({ ...query });
                }} style={{ width: "250px" }} />
                <FilterDatePicker key="end" label="до" value={query.filter.researchEndDateTime.operand2} handleFunction={(value) => {
                    if (!value) {
                        return;
                    }
                    query.filter.researchEndDateTime.operand2 = value;
                    setQuery({ ...query });
                }} style={{ width: "250px", marginLeft: "10px" }} />
            </Stack>
            <label style={{minWidth:"360px"}}>По сумме</label>

            <Chart options={barOptions.options} series={[{ name: "Лабораторные исследования", data: data && data.map(_ => _.notDesinfectionSum) },
            { name: "ДДД", data: data && data.map(_ => _.desinfectionSum) }] || []} type="bar" height={550} />
                <label style={{minWidth:"360px"}}>По количеству</label>

            <Chart options={barOptions.options} series={[{ name: "Лабораторные исследования", data: data && data.map(_ => _.notDesinfectionQuantity) },
            { name: "ДДД", data: data && data.map(_ => _.desinfectionQuantity) }] || []} type="bar" height={550} />
        </DashboardLayout>
    );
}
