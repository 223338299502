import React from "react";
import DashboardLayout from "../../../layouts/Dashboard";

import DictionaryCRUDTable from "../../../components/CRUD/Tables/DictionaryCRUDTable";

const ChemicalIndicesList = () => {
    return (
        <DashboardLayout>
            <DictionaryCRUDTable
                entityType="chemicalIndices"
                hasCode={true}
                hasDescription={true}
            />
        </DashboardLayout>
    )
};

export default ChemicalIndicesList;
