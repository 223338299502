import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PageContainer, PageHeader } from "../../components";
import { ContractRequestCRUDTable } from "../../components/CRUD/Tables/Request/ContractRequestCRUDTable";
import DashboardLayout from "../../layouts/Dashboard";
import {
  Button,
  Grid,
} from "@mui/material";
import useReload from "../../hooks/useReload";
import {confirmDialog} from "../../components/Dialog/DialogDelete";
import {contractRequestCRUD, resultCRUD} from "../../http";
import {checkRoles} from "../../functions/checkRoles";

export const ContractRequestTable = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { reload, reloadValue } = useReload();

  const handleDelete = (_) => {
      return confirmDialog('Удаление сводной заявки, ID:' + _, 'Вы действительно хотите удалить эту сводную заявку?', async () => {
          await contractRequestCRUD.delete(_);
          await reload();
      })
  }

    return (
    <DashboardLayout>
      <PageHeader heading="Сводные заявки">
        <Button onClick={() => push(`${pathname}/create`)}>Добавить</Button>
      </PageHeader>
      <PageContainer>
        <ContractRequestCRUDTable onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} reloadValue={reloadValue}/>
      </PageContainer>
    </DashboardLayout>
  )
};
