import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export const BasicDialog = ({
  children,
  title,
  description,
  open,
  setOpen,
  confirmButton,
  cancelButton,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onCancel,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby="dialog-title"
      style={{
        width: "100%",
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      {children ? (
        children
      ) : (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button variant="text" color="primary" autoFocus onClick={onConfirm}>
          {confirmButtonText}
        </Button>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BasicDialog.defaultProps = {
  title: "Отменить, данные не будут сохранены?",
  description: "",
  confirmButtonText: "Да",
  cancelButtonText: "Нет",
};
