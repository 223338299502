import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useTranslation } from "react-i18next";
import { SearchPanel, TableCustom } from "../../components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getContractSpecifications, getContractSpecificationByKey } from "../../redux/actions/contractSpecificationActions";
import ContractSpecificationDetails from "./Popups/ContractSpecificationDetails";

function createData(id, price, quantity, contract, service, serviceKind, unit, servicePriceList) {
    return {id, price, quantity, contract, service, serviceKind, unit, servicePriceList};
}

let rows = [
    createData(1, 1.0, 1, null, null, null, null, null),
];
const ContractSpecificationList = () => {
    const {t} = useTranslation();
    const rowName = [
        {text: t("contractSpecificationList.tableItem.id")},
        {text: t("contractSpecificationList.tableItem.price")},
        {text: t("contractSpecificationList.tableItem.quantity")},
        {text: t("contractSpecificationList.tableItem.contract")},
        {text: t("contractSpecificationList.tableItem.service")},
        {text: t("contractSpecificationList.tableItem.serviceKind")},
        {text: t("contractSpecificationList.tableItem.unit")},
        {text: t("contractSpecificationList.tableItem.servicePriceList")},
        {text: t("contractSpecificationList.tableItem.actions")},
    ];
    const dispatch = useDispatch();

    const [query, setQuery] = useState({ "sort": { "id": { "operator": "asc" } } });

    const [id, setId] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const setDetailsHandler = async (id) => {
        await dispatch(getContractSpecificationByKey(id));
        setDetailsOpen(false);
        setDetailsOpen(true);
        setId(id);
    }
    

    const localData = false;
    const state = useSelector((state) => state.contractSpecification);
    if (!localData && state.contractSpecifications)
    {
        rows = state.contractSpecifications;
    }
    useEffect(() => {
        if (!localData)
        {
            dispatch(getContractSpecifications(query));
        }
    }, [dispatch]);
    return (
        <DashboardLayout>
            <Box sx={{margin: '20px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <SearchPanel searchName="id" search={rows} label={t("contractSpecificationList.searchLabel")}/>
            </Box>
            <TableCustom avatars={false} rowName={rowName} rows={rows.map(_ => {
                return {
                    id: _.id,
                    price: _.price,
                    quantity: _.quantity,
                    contract: _.contract?.name,
                    service: _.service?.name,
                    serviceKind: _.serviceKind?.name,
                    unit: _.unit?.name,
                    servicePriceList: _.servicePriceList?.name,
                }
            })} actions={(_) => [<VisibilityIcon onClick={() => setDetailsHandler(_.id)}/>]}/>
            <ContractSpecificationDetails action={'details'} title={t("contractSpecificationList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
        </DashboardLayout>
    )
};

export default ContractSpecificationList;
