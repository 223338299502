import React from 'react';

import {governmentCRUD} from "../../../http/CRUD";
import CRUDTable from "../CRUDTable";
import {useHistory} from "react-router-dom";


export default function GovernmentCRUDTable({...props}) {
    const {push} = useHistory()
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "Имя", dataIndex: 'name', key: 'name'},
        {title: "Код", dataIndex: 'code', key: 'code'},
        {title: "Описание", dataIndex: 'description', key: 'description'},
    ]

    const searchFields = [
        {
            title: "Название ГОСТ",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    return (
        <CRUDTable searchFields={searchFields} search={governmentCRUD.search} columns={columns} {...props}
        />
    );
}
