import React, {useCallback, useEffect, useMemo, useState} from "react";
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom";
import useLoader from "../../../hooks/useLoader";
import {CircularProgress, Grid} from "@mui/material";
import DashboardLayout from "../../../layouts/Dashboard"
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {mapEmployeeData, mapUserData} from "../../../functions/viewDataMap";
import {useDispatch} from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import {v4 as uuidv4} from "uuid";
import {
    DepartmentSelect,
    DivisionSelect,
    FilialDivisionSelect,
    FilialSelect, OrganizationSelect, RoleSelect
} from "../../../components/Select/domainSelects";
import {$authHost, employeeCRUD, userCRUD} from "../../../http";
import useAlert from "../../../hooks/useAlert";
import {EMPLOYEE_LIST_ROUTE} from "../../../routes";
import PositionField from "../../../components/Relations/PositionField";
import Stack from "@mui/material/Stack";
import useReload from "../../../hooks/useReload";
import { getEmployees } from "../../../redux/actions";
import { asJSONSafe } from "../../../functions";

const EmployeeDetailForm = () => {
    const {id} = useParams();
    const history = useHistory()
    const [userData, setUserData] = useState(null)
    const alert = useAlert(false, 1)
    const [isNew, setIsNew] = useState(id === "new")
    const dispatch = useDispatch()
    const {review} = useLocation().state || false
    const [roleBlocks, setRoleBlocks] = useState({})
    const [newPassword, setNewPassword] = useState("")

    const { reload, reloadValue } = useReload();

    const employeeUserConfig = {isActive: true, typeId: 3}
    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: loadingSubmit,
    } = useLoader(false);
    const selectedRoles = useMemo(() => {
        return Object.keys(roleBlocks).map(el => roleBlocks[el].roleId)
    }, [roleBlocks])
    const [correctID, seCorrectID] = useState(true)
    const [fields, setFields] = useState({})
    const submitEmployeeData = useMemo(() => {
        const submitData = mapEmployeeData(fields);
        return submitData;
    }, [fields]);
    const submitUserData = useMemo(() => {
        const submitData = mapUserData(fields);
        return submitData;
    }, [fields]);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
    
            fileReader.onload = () => {
                //resolve(fileReader.result);
                //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
                let bytes = Array.from(new Uint8Array(fileReader.result));

                //if you want the base64encoded file you would use the below line:
                let base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));

                //Resolve the promise with your custom file structure
                resolve({ 
                    bytes,
                    base64StringFile,
                    fileName: file.name, 
                    fileType: file.type
                });
            };
    
            fileReader.onerror = (error) => {
                reject(error);
            };
            fileReader.readAsArrayBuffer(file);
        });
    };

    const onUploadAuthChange = async event => {    
        if (event.target.files.length == 0)
            return;

        const name = event.target.files[0].name;
        
        const base64 = await convertBase64(event.target.files[0]);

        setFields((state) => {
            return {
                ...state,
                authCertificate: {
                    name: name,
                    base64: base64.base64StringFile
                }
            }
        })
    };

    const onUploadSignChange = async event => {    
        if (event.target.files.length == 0)
            return;

        const name = event.target.files[0].name;

        const base64 = await convertBase64(event.target.files[0]);
        //console.log(base64)

        setFields((state) => {
            return {
                ...state,
                signCertificate: {
                    name: name,
                    base64: base64.base64StringFile
                }
            }
        })
    };

    const {loading, start, stop} = useLoader(false)
    const map = (data) => {
        if (data.authCertificate) {
            data.authCertificate = asJSONSafe(data.authCertificate);
        }
        if (data.signCertificate) {
            data.signCertificate = asJSONSafe(data.signCertificate);
        }
        return data;
    }
    const fetch = useCallback(async () => {
        try {
            start()
            if (!isNew) {
                let data = await employeeCRUD.get(id)
                data = map(data);
                setFields((state) => {
                    return {
                        ...state,
                        departmentId: data.department?.id || null,
                        organizationId: data.organizationId || null,
                        filialId: data.filial?.id || null,
                        positionId: data.position?.id || null,
                        fullName: data.fullName || null,
                        filialDivisionId: data.filialDivisionId || null,
                        divisionId: data.divisionId || null,
                        personnelNumber: data.personnelNumber || null,
                        identityNumber: data.identityNumber || null,
                        isDeleted: data.isDeleted || null,
                        authCertificate: data.authCertificate,
                        signCertificate: data.signCertificate
                    }
                })

                if (data.userId) {
                    const resp = await userCRUD.get(data.userId)
                    setUserData(resp)
                    setFields((state) => {
                        return {
                            ...state,
                            userName: resp.userName || null,
                        }
                    })
                    if (resp.roles) {
                        let rolesObj = {}
                        for (let el of resp.roles) {
                            rolesObj[el.id] = {
                                ...el,
                                type: "exists"
                            }
                        }
                        setRoleBlocks(state => {
                            return {
                                ...state,
                                ...rolesObj
                            }
                        })
                    }
                }
            }
            stop()
        } catch (err) {
            stop()
            alert.show(0,"Ошибка загрузки!")

        }

    }, [dispatch, id])

    useEffect(fetch, [fetch, reloadValue])

    useEffect(async () => {
        if(!review)
        if (fields.identityNumber && fields.identityNumber.toString().length === 12) {
            const result = await dispatch(getEmployees({
                paging: {
                  take: 10,
                  skip: 0,
                  returnCount: true
                },
                sort: {
                  id: {
                    operator: "desc"
                  }
                },
                filter: {
                  identityNumber: {
                    operator: "like",
                    operand1: fields.identityNumber
                  }
                }
              }));
              if(result.length > 0) {
                seCorrectID(false);
                alert.show(0, "Сотрудник с таким ИИН-ом уже существует!")
              }

        }
    }, [fields.identityNumber]);

    const submit = useCallback(async () => {
        try {
            startSubmit()
            if (!isNew) {
                if (userData) {
                    await employeeCRUD.edit({
                        id: id,
                        userId: userData.id,
                        ...submitEmployeeData
                    })
                    if (submitUserData?.userName) {
                        await userCRUD.edit({
                            id: userData.id,
                            ...submitUserData,
                            ...employeeUserConfig,
                            roles: selectedRoles.map(el => {
                                return {
                                    userId: userData.id || null,
                                    roleId: el
                                }
                            })
                        })

                        if (newPassword.trim().length > 0) {
                            await $authHost.put("/api/v1/user/newPassword", {
                                userId: userData.id,
                                newPassword: newPassword
                            })
                        }
                    }
                    alert.show(1, "Изменения сохранены")

                } else {
                    const resp = await userCRUD.create({
                        ...submitUserData,
                        ...employeeUserConfig
                    })

                    await userCRUD.edit({
                        id: resp.id,
                        ...submitUserData,
                        roles: selectedRoles.map(el => {
                            return {
                                userId: resp.id || null,
                                roleId: el
                            }
                        })

                    })
                    await employeeCRUD.edit({
                        id: id,
                        userId: resp.id,
                        ...submitEmployeeData
                    })

                }
            } else {
                if (newPassword.trim().length > 0) {
                    if(fields.identityNumber.toString().length === 12 && correctID) {
                        const resp = await userCRUD.create({
                            ...submitUserData,
                            newPassword,
                            roles: selectedRoles.map(_ => {
                                return {
                                    roleId: _
                                }
                            }),
                            isActive: true,
                            typeId: 3
                        })


                        const employeeResp = await employeeCRUD.create({
                            userId: resp.id,
                            ...submitEmployeeData
                        })

                        history.push(EMPLOYEE_LIST_ROUTE);
                        //history.push(EMPLOYEE_DETAIL_ROUTE(employeeResp.id))
                        alert.show(1, "Сотрудник создан") 
                    } else {
                        alert.show(0, "Не действительный ИИН")
                    }
                } else {
                    alert.show(0, "Пароль не должен быть пустым")
                }


            }
            stopSubmit()
        } catch (err) {
            alert.show(0, err)
        }


    }, [id, dispatch, submitEmployeeData, submitUserData, roleBlocks, newPassword])

    const handleBanned = async (val) => {
        await employeeCRUD.edit({
            id: id,
            userId: userData.id,
            ...submitEmployeeData,
            isDeleted: val
        })
        reload()
        alert.show(1,val ?  "Пользователь заблокирован" : "Пользователь разаблокирован")
    }

    console.log(fields)

    return (
        <DashboardLayout>
            {loading ? <CircularProgress/> : <>
                <Grid item xs={12}>
                    <Paper>
                        <Toolbar style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}>
                            <Typography id="modal-modal-title" variant="h5" component="h2">
                                {isNew ? "Создание" : "Редактирование"} сотрудника<br/><Typography
                                style={{fontSize: 12}}>{fields.fullName || "Имя пользователя отсутствует"}</Typography>
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                {fields.isDeleted ? <Button color="info" onClick={() => handleBanned(false)}>Разблокировать</Button> : <Button color="error" onClick={
                                    () => handleBanned(true)
                                }>Заблокировать</Button>}
                                <NavLink style={{color: '#2f9e69'}} to={EMPLOYEE_LIST_ROUTE}><span
                                    style={{color: "2f9e69"}}>Вернуться к таблице</span></NavLink>
                            </Stack>
                        </Toolbar>
                    </Paper>
                </Grid>
                <Grid item xs={12} spacing={1} style={{paddingTop: 16}}>
                    <Paper style={{paddingBottom: 20}}>
                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={2}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Основная информация</Typography>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={6}>
                                    <TextField disabled={review} required margin="normal"
                                               fullWidth id="fullName" name="fullName" label="ФИО"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           fullName: ev.target.value
                                                       }
                                                   })
                                               }
                                               }
                                               value={fields.fullName || ""}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <PositionField
                                        label={"Должность"}
                                        disabled={review}
                                        fullWidth
                                        positionId={fields.positionId}
                                        value={fields.positionId}
                                        onChange={(val) => setFields(state => {
                                            return {
                                                ...state,
                                                positionId: val.id
                                            }
                                        })}
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={6}>
                                    <TextField required margin="normal"
                                               disabled={review}
                                               fullWidth id="personnelNumber" name="personnelNumber"
                                               label="Tабельный номер"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           personnelNumber: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.personnelNumber || ""}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required margin="normal"
                                               disabled={review}
                                               fullWidth id="identityNumber" name="identityNumber" label="ИИН"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           identityNumber: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.identityNumber || ""}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={5}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Информация о
                                    пользователе</Typography>
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={6}>
                                    <TextField required margin="normal"
                                               disabled={review}

                                               fullWidth id="userName" name="userName" label="Логин"
                                               onChange={(ev) => {
                                                   setFields((state) => {
                                                       return {
                                                           ...state,
                                                           userName: ev.target.value
                                                       }
                                                   })
                                               }}
                                               value={fields.userName || ""}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required margin="normal"
                                               disabled={review}
                                               fullWidth id="newPassword" name="newPassword"
                                               label={userData ? "Новый пароль" : "Введите пароль"}
                                               onChange={(ev) => {
                                                   setNewPassword(ev.target.value);
                                               }}
                                               value={newPassword}
                                    />
                                </Grid>

                            </Grid>


                        </Grid>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={5}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Роли</Typography>
                            </Grid>
                            <Grid container spacing={3} style={{display: "flex", alignItems: "center"}} xs={12}>
                                {Object.keys(roleBlocks).map((key) => {
                                    return (roleBlocks[key].type !== "deleted" ? <>
                                            <Grid item xs={11}>
                                                <RoleSelect
                                                    label={"Роль"}
                                                    disabled={review}
                                                    fullWidth
                                                    selectedRoles={selectedRoles.filter(el => el != roleBlocks[key].roleId)}
                                                    value={roleBlocks[key].roleId || ""}
                                                    onChange={(val) => {
                                                        setRoleBlocks((state) => {
                                                            return {
                                                                ...state,
                                                                [key]: {
                                                                    ...state[key],
                                                                    roleId: val
                                                                }
                                                            }
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Button onClick={() => {
                                                    setRoleBlocks((state) => {
                                                        delete state[key]
                                                        return {
                                                            ...state,

                                                        }
                                                    })
                                                }}>Удалить</Button>
                                            </Grid>
                                        </> : <></>

                                    )
                                })}


                                <Grid item xs={12}>
                                    <Button onClick={() => {
                                        setRoleBlocks((state) => {
                                            return {
                                                ...state,
                                                [uuidv4()]: {
                                                    roleId: null,
                                                    type: "new"
                                                },
                                            }
                                        })
                                    }}><AddIcon sx={{marginRight: 2}}/> Добавить роль</Button>
                                </Grid>
                            </Grid>


                        </Grid>

                        <Grid container xs={12} style={{paddingLeft: 20, paddingRight: 20}}>
                            <Grid item xs={12} marginTop={5}>
                                <Typography variant={"h6"} style={{display: 'inline'}}>Организация</Typography>                                
                            </Grid>
                            <Grid item xs={12}>
                                <OrganizationSelect
                                    label={"Организация"}
                                    fullWidth
                                    disabled={review}
                                    value={fields.organizationId || ""}
                                    onChange={(val) => {
                                        setFields(state => {
                                            return {
                                                ...state,
                                                organizationId: val
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid container marginTop={1} spacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <FilialSelect
                                        label={"Филиал"}
                                        disabled={review}
                                        organizationId={fields.organizationId || null}
                                        fullWidth
                                        value={fields.filialId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    filialId: val
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FilialDivisionSelect
                                        disabled={review}
                                        label={"Районное отделение"}
                                        fullWidth
                                        value={fields.filialDivisionId}
                                        isNullable={true}
                                        filialId={fields.filialId || ""}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    filialDivisionId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DepartmentSelect
                                        label={"Лаборатория"}
                                        disabled={review}
                                        fullWidth
                                        value={fields.departmentId || ""}
                                        isNullable={true}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    departmentId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <DivisionSelect
                                        label={"Отделение лаборатории"}
                                        disabled={review}
                                        fullWidth
                                        value={fields.divisionId || ""}
                                        isNullable={true}
                                        onChange={(val) => {
                                            setFields(state => {
                                                return {
                                                    ...state,
                                                    divisionId: val
                                                }
                                            })
                                        }}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Grid item xs={6}>{fields.authCertificate?.name}</Grid>
                                        <Grid item xs={6}>
                                            <input 
                                                style={{ display: 'none' }}
                                                type="file" 
                                                onChange={onUploadAuthChange}
                                                //accept="image/*"
                                                id="uploadAuth" 
                                                name="uploadAuth"
                                                />
                                            <label htmlFor="uploadAuth">
                                            <Button
                                                fullWidth
                                                component="span"
                                                variant="contained"
                                            >
                                                Загрузить AUTH
                                            </Button>
                                            </label>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item xs={6}>{fields.signCertificate?.name}</Grid>
                                        <Grid item xs={6}>
                                            <input 
                                                style={{ display: 'none' }}
                                                type="file" 
                                                onChange={onUploadSignChange}
                                                //accept="image/*"
                                                id="uploadSign" 
                                                name="uploadSign"
                                                />
                                            <label htmlFor="uploadSign">
                                            <Button
                                                fullWidth
                                                component="span"
                                                variant="contained"
                                            >
                                                Загрузить SIGN
                                            </Button>
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                        </Grid>


                    </Paper>
                </Grid>
                {!review && (
                    <LoadingButton style={{marginTop: "10px"}} loading={loadingSubmit} variant="contained" onClick={() => {
                        submit()
                    }}>
                        Сохранить
                    </LoadingButton>
                )}

                {alert.render()}
            </>}


        </DashboardLayout>
    )
}

export default EmployeeDetailForm