import React from "react";
import DashboardLayout from "../../../layouts/Dashboard";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { style } from "../../../components/TableMUIStyle";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useHistory, useParams } from "react-router-dom";
import { CircularProgress, Paper, Toolbar, Typography } from "@mui/material";
import { useCallback, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addReceipt } from "../../../redux/actions/receiptAction";
import { receiptCRUD } from "../../../http";
import useLoader from "../../../hooks/useLoader";
import { REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_SIGNED, REQUEST_STATE_ID_SIGNING } from "../../../redux/constants/requestStateConstants";
import { checkRoles } from "../../../functions/checkRoles";
import { LoadingButton } from "@mui/lab";


const ReceiptForThings = () => {
  const [open, setOpen] = React.useState(false);
  const [nd, setNd] = React.useState("");
  const { id } = useParams();
  const isNew = useMemo(() => !(id > 0), [id]);
  const { push } = useHistory();
  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const { start: startSubmit, stop: stopSubmit, loading: loadingSubmit } = useLoader(false);
  const { start: workflowStart, stop: workflowStop, loading: workflowLoading } = useLoader(false);
  const referenceName = 'ReceiptForThings';
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const stateCode = data.state?.code;
  const dispatch = useDispatch();
  const [createResultOpen, setCreateResultOpen] = React.useState(false);

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  // const handleChangeSelect = (event) => {
  //   setNd(event.target.value);
  // };

  const handleChange = useCallback((key, value) => {
    setData({ ...data, [key]: value })
  }, [data]);
  const handleChangeMulti = useCallback((obj) => {
    setData({ ...data, ...obj })
  }, [data]);

  const handleChangeData = useCallback((key, value, key2) => {
    setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
  }, [data]);

  const handleChangeSample = useCallback((key, value, i, key2) => {
    const array = data.data?.samples?.value.slice(0)
    array[i][key] = { 'key': key2, value }
    setData({
      ...data, data: {
        ...data.data,
        samples: { key: 'samples', value: array }
      }
    })
  }, [data]);


  const { loading: fetchLoading, start: fetchStart, stop: fetchStop } = useLoader(true);
  const fetch = useCallback(() => {
    if (isNew) {
      fetchStop();
      return;
    }
    fetchStart();
    receiptCRUD.get(id)
      .then((data) => {
        const dataInside = typeof data.data === 'string' ? JSON.parse(data.data) : {}
        setData({ ...data, data: { ...dataInside } });
      })
      .catch(alert)
      .finally(fetchStop)
  }, [id, fetchStart, fetchStop, isNew]);
  useEffect(fetch, [fetch]);

  const setStatus = (lastStateCode, nextStateCode, thenAction = fetch) => {
    const validates = [];
    const validateNull = (key, label) => {
      if (!data[key]) validates.push(label + " нужно заполнить!");
    };
    // if (nextStateCode === REQUEST_STATE_ID_PROBESELECTION) {
    //   validateNull("responsibleForProbesId", "Ответственный за отбор проб");
    // } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
    //   validateNull("performerId", "Лаборант");
    // } 
    if (nextStateCode === REQUEST_STATE_ID_SIGNING) {
      validateNull("departmentId", "Лаборатория");
    }
    if (validates.length > 0) {
      alert(validates.join("\n"));
      return;
    }
    workflowStart();
    submit()
      .then(() => receiptCRUD.workflowRun(id, lastStateCode, nextStateCode))
      .then(thenAction)
      .catch(alert)
      .finally(workflowStop);
  };

  const submit = useCallback(() => new Promise((resolve, reject) => {
    const newData = { ...data }
    startSubmit();
    (isNew
      ? receiptCRUD.create({
        createdAt: new Date(),
        stateId: 1,
        ...newData
      })
      : receiptCRUD.edit({
        id: id,
        ...newData,
      })
    ).then((response) => {
      if (isNew && response.id) {
        push(`/${referenceName}Detail/${response.id}`)
      } else {
        fetch();
      }
      resolve();
      // onSubmit();
    })
      .catch((error) => {

        reject();
        alert(error);
      })
      .finally(stopSubmit)
  }), [data, startSubmit, stopSubmit, isNew, fetch, push]);

  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
        <Button
          style={{
            border: "1px solid black",
            padding: "5px 10px",
            borderRadius: "6px",
            marginBottom: '10px'
          }}
          variant="contained"
          type="button"
          onClick={() => { push(`/${referenceName}`) }}
        >
          Вернуться
        </Button>
        { fetchLoading
        ? <CircularProgress /> 
        : <Box>
          <Paper style={{ marginBottom: '15px' }}>
            <Toolbar>
              <Typography style={{ marginRight: "auto" }} variant={"h6"}>Статус квитанции: {data.state?.name || "создаётся"}</Typography>
              {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_SIGNING)}>Отправить на подпись</LoadingButton>}
              {REQUEST_STATE_ID_SIGNING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_SIGNING, REQUEST_STATE_ID_SIGNED)}>Подписать</LoadingButton>}
            </Toolbar>
          </Paper>
          <h4 style={{ textAlign: "center" }}>
            Заттарға берілетін түбіртек(Квитанция на вещи)
          </h4>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            №
            <TextField
              id="outlined-basic"
              label="Номер"
              style={{ width: "20%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.number}
              onChange={(e) => handleChange('number', e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>Наряд бойынша (По наряду)№</p>
            <TextField
              id="outlined-basic"
              label="По наряду №"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.data?.brigadeName?.value}
              onChange={(e) => handleChangeData('brigadeName', e.target.value, 'brigadeName')}
              />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              Азаматтан қабылданды (Приняты от гражданина)
            </p>
            <TextField
              id="outlined-basic"
              label="Приняты от гражданина"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.data?.citizenName?.value}
              onChange={(e) => handleChangeData('citizenName', e.target.value, 'citizenName')}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "5px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>Мекен-жайы (Адрес)</p>
            <TextField
              id="outlined-basic"
              label="Адрес"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.addressLine}
              onChange={(e) => handleChange('addressLine', e.target.value)}
            />
          </div>

          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              Дезинфекциялау себептері (Для дезинфекции по поводу)
            </p>
            <TextField
              id="outlined-basic"
              label="Для дезинфекции по поводу"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.data?.disinfectionReasons?.value}
              onChange={(e) => handleChangeData('disinfectionReasons', e.target.value, 'disinfectionReasons')}
            />
          </div>
          {/* <div
            style={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              Зерттеу әдістеменің НҚ-ры (НД на метод испытаний)
            </p>
            <div style={{ width: "50%" }}>
              <FormControl
                fullWidth
                style={{ paddingRight: "13px", marginLeft: "14px" }}
              >
                <InputLabel id="demo-simple-select-label">
                  НД на метод испытаний
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data?.data?.methodTest?.value}
                  label="НД на метод испытаний"
                  onChange={(e) => handleChangeData('methodTest', e.target.value, 'methodTest')}
                  >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div> */}

          <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Дата"
                inputFormat="MM/dd/yyyy"
                value={data?.data?.date?.value}
                onChange={(value) => {
                  handleChangeData('date', value, 'date')
                }}      
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              Заттар Дvезинфекциялауға тапсырылды (вещи сдал в дезинфекцию)
            </p>
            <TextField
              id="outlined-basic"
              label="Вещи переданы на дизенфекцию"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.data?.gaveTheClothes?.value}
              onChange={(e) => handleChangeData('gaveTheClothes', e.target.value, 'gaveTheClothes')}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "10px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>Азаматтың қолы (подпись гражданина)</p>
            <div style={{ width: "50%" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              Заттарды камералық дезинфекциялауға қабылдады (Вещи для камерной
              дезинфекции принял)
            </p>
            <div style={{ width: "50%" }}>
              <FormControl
                fullWidth
                style={{ paddingRight: "13px", marginLeft: "14px" }}
              >
                <InputLabel id="demo-simple-select-label">
                  Вещи принял
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data?.data?.tookTheClothes?.value}
                  label="Вещи принял"
                  onChange={(e) => handleChangeData('tookTheClothes', e.target.value, 'tookTheClothes')}
                  >
                  <MenuItem value={true}>Да</MenuItem>
                  <MenuItem value={false}>Нет</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>
              Дезинфекциялаушының қолы (подпись дезинфектора)
            </p>
            <div style={{ width: "50%" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <p style={{ width: "50%" }}>Наразылығым жоқ (Претензий не имею)</p>
            <TextField
              id="outlined-basic"
              label="Претензий не имею"
              style={{ width: "50%", marginLeft: "30px" }}
              variant="outlined"
              value={data?.data?.haveNoComplaints?.value}
              onChange={(e) => handleChangeData('haveNoComplaints', e.target.value, 'haveNoComplaints')}
            />
          </div>
          <Button
            style={{
              border: "1px solid black",
              padding: "5px 10px",
              borderRadius: "6px",
            }}
            variant="contained"
            loading={loading} onClick={submit}
          >
            {isNew ? "Создать" : "Сохранить"}
          </Button>
        </Box>
      }
    </DashboardLayout>
  );
};

export default ReceiptForThings;
