import { $authHost } from "../../http";
import { 
    STORAGE_CONDITION_GET_REQUEST, STORAGE_CONDITION_GET_SUCCESS, STORAGE_CONDITION_GET_ERROR, 
    STORAGE_CONDITION_GET_BY_KEY_REQUEST, STORAGE_CONDITION_GET_BY_KEY_SUCCESS, STORAGE_CONDITION_GET_BY_KEY_ERROR, 
    STORAGE_CONDITION_ADD_REQUEST, STORAGE_CONDITION_ADD_SUCCESS, STORAGE_CONDITION_ADD_ERROR, 
    STORAGE_CONDITION_EDIT_REQUEST, STORAGE_CONDITION_EDIT_SUCCESS, STORAGE_CONDITION_EDIT_ERROR, 
    STORAGE_CONDITION_DELETE_REQUEST, STORAGE_CONDITION_DELETE_SUCCESS, STORAGE_CONDITION_DELETE_ERROR
} from "../constants/storageConditionConstants";

export const getStorageConditions = (args) => async (dispatch) => {
    try {
        dispatch({
            type: STORAGE_CONDITION_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/storageCondition/query",
            args || {},
            config,
        );
        dispatch({
            type: STORAGE_CONDITION_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: STORAGE_CONDITION_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getStorageConditionByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: STORAGE_CONDITION_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/storageCondition/${id}`,
        );
        dispatch({
            type: STORAGE_CONDITION_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: STORAGE_CONDITION_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addStorageCondition = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: STORAGE_CONDITION_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/storageCondition",
            { name, code, description },
            config,
        );
        dispatch(getStorageConditions(queryArgs || {}));
        dispatch({
            type: STORAGE_CONDITION_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: STORAGE_CONDITION_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editStorageCondition = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: STORAGE_CONDITION_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/storageCondition",
            { id, name, code, description },
            config,
        );
        dispatch(getStorageConditions(queryArgs || {}));
        dispatch({
            type: STORAGE_CONDITION_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: STORAGE_CONDITION_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteStorageCondition = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: STORAGE_CONDITION_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/storageCondition",
            { data: { id } },
            config,
        );
        dispatch(getStorageConditions(queryArgs || {}));
        dispatch({
            type: STORAGE_CONDITION_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: STORAGE_CONDITION_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

