import { $authHost } from "../../http";
import { ACT_ADD_ERROR, ACT_ADD_REQUEST, ACT_ADD_SUCCESS, ACT_DELETE_ERROR, ACT_DELETE_REQUEST, ACT_DELETE_SUCCESS, ACT_EDIT_ERROR, ACT_EDIT_REQUEST, ACT_EDIT_SUCCESS, ACT_GET_BY_KEY_ERROR, ACT_GET_BY_KEY_REQUEST, ACT_GET_BY_KEY_SUCCESS, ACT_GET_ERROR, ACT_GET_REQUEST, ACT_GET_SUCCESS, ACT_WORKFLOW_RUN_ERROR, ACT_WORKFLOW_RUN_REQUEST, ACT_WORKFLOW_RUN_SUCCESS } from "../constants/actConstants";

export const getActs = (args) => async (dispatch) => {
    try {
        dispatch({
            type: ACT_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await $authHost.post(
            "/api/v1/act/query",
            args || {},
            config,
        );
        dispatch({
            type: ACT_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: ACT_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getActByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ACT_GET_BY_KEY_REQUEST
        });

        const { data } = await $authHost.get(
            `/api/v1/act/${id}`,
        );
        dispatch({
            type: ACT_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: ACT_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addAct = ({ createdAt, number, schema, data, formId, stateId, probeSelectionRequestId, withoutDepartureRequestId, filialId, departmentId, divisionId, filialDivisionId, performerId, responsibleForProbesId, responsibleId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: ACT_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/act",
            { createdAt, number, schema, data, formId, stateId, probeSelectionRequestId, withoutDepartureRequestId, filialId, departmentId, divisionId, filialDivisionId, performerId, responsibleForProbesId, responsibleId },
            config,
        );
        dispatch(getActs(queryArgs || {}));
        dispatch({
            type: ACT_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ACT_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editAct = ({ id, createdAt, number, schema, data, formId, stateId, probeSelectionRequestId, withoutDepartureRequestId, filialId, departmentId, divisionId, filialDivisionId, performerId, responsibleForProbesId, responsibleId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: ACT_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/act",
            { id, createdAt, number, schema, data, formId, stateId, probeSelectionRequestId, withoutDepartureRequestId, filialId, departmentId, divisionId, filialDivisionId, performerId, responsibleForProbesId, responsibleId },
            config,
        );
        dispatch(getActs(queryArgs || {}));
        dispatch({
            type: ACT_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ACT_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteAct = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: ACT_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/act",
            { data: { id } },
            config,
        );
        dispatch(getActs(queryArgs || {}));
        dispatch({
            type: ACT_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ACT_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const runActWorkflow = ({ id, oldStateId, newStateId, queryArgs }) => async (dispatch) => {
    try {
        dispatch({
            type: ACT_WORKFLOW_RUN_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/act/workflow/run",
            { data: { id, oldStateId, newStateId } },
            config,
        );
        dispatch(getActs(queryArgs || {}));
        dispatch({
            type: ACT_WORKFLOW_RUN_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ACT_WORKFLOW_RUN_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};