import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import { CheckBox, HighlightOff } from "@mui/icons-material";
import { useController, useFieldArray, useFormContext } from "react-hook-form";
import { Button, Dialog, DialogContent, Divider, FormHelperText } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IndicatorCRUDTable from "../CRUD/Tables/IndicatorCRUDTable";
import { serviceGroupCRUD, serviceMapCRUD, serviceSubGroupCRUD } from '../../http';
import RestSelect from '../Select/RestSelect';
import { copy, distinct } from '../../functions/linqSimulation';
import LoadingContainer from '../Loader/LoadingContainer';
import { RHFCheckbox } from './RHFCheckbox';
import RestAutoComplete from '../Autocompletes/RestAutoComplete';

function RHFGroupIndicator({ label, name, disabled }) {
    const { control, watch, setValue } = useFormContext();
    const { fieldState: { error } } = useController({ name: name + '.indicators' });

    //const [groupId, setGroupId] = useState(null);
    const groupId = watch(name + '.groupId');
    console.log('groupId:' + groupId);
    const [serviceSubGroups, setServiceSubGroups] = useState(null);
    const [serviceSubGroupIds, setServiceSubGroupIds] = useState(null);
    const [loading, setLoading] = useState(false);
    const [groupChanged, setGroupChanged] = useState(false);

    const [query, setQuery] = useState({
        paging: { skip: 0, take: 0 }
    });
    const [groupQuery, setGroupQuery] = useState({
        paging: { skip: 0, take: 10000 }, sort: {
          name: { operator: 'asc' }
        }
    });

    const indicatorsName = name + '.indicators';

    const { fields, append, remove } = useFieldArray({
        control,
        name: indicatorsName,
    });

    const indicators = watch(indicatorsName, []);

    const fetchServiceMap = async () => {
        const response = await serviceMapCRUD.search({
            paging: { skip: 0 },
            filter: {
                groupId: {
                    operand1: groupId,
                    operator: 'equals'
                }
            }
        });

        return response.result;
    }

    const fetchServiceSubGroup = async () => {
        if (serviceSubGroups) {
            return serviceSubGroups;
        }

        const response2 = await serviceSubGroupCRUD.search({
            paging: { skip: 0 },
            sort: {
                name: { operator: 'asc' }
              }
        });
        return response2.result;
    }

    const fetch = async () => {
        setLoading(true);
        const serviceMaps = await fetchServiceMap();
        const serviceSubGroupIds2 = distinct(serviceMaps.map(_ => _.subGroupId), (a, b) => a == b);
        setServiceSubGroupIds(serviceSubGroupIds2);

        const serviceSubGroups2 = await fetchServiceSubGroup();
        setServiceSubGroups(serviceSubGroups2);

        const data = serviceSubGroups2.filter(_ => serviceSubGroupIds2.find(i => i == _.id));

        remove();
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            item.groupId = groupId;
            item.checked = true;
        }

        append(data);

        setLoading(false);
    }

    const setSelectedForAll = (value) => {
        for (let i = 0; i < indicators.length; i++) {
            const item = indicators[i];
            item.checked = value;            
        }
        const newIndicators = copy(indicators);        
        remove();
        //append(newIndicators);
        setTimeout(() => append(newIndicators), 100);        
    }

    useEffect(() => {
        if (!groupId || !groupChanged)
            return;        
        //setValue(name + '.groupId');
        fetch();
    }, [groupId, groupChanged]);

    const deleteColumnStyles = {
        position: "sticky",
        right: 0,
    },
        cellHeadStyles = { fontWeight: "bold" };

    function deleteActHandler(id) {
        const index = fields.findIndex(el => el.actId === id)
        remove(index);
    }

    const [open, setOpen] = useState(false)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>                
                <Button size="small" sx={{ marginTop: '20px', marginLeft: "15px" }} onClick={() => setSelectedForAll(true)} disabled={disabled} variant={"contained"}>
                    Выбрать все
                </Button>
                <Button size="small" sx={{ marginTop: '20px', marginLeft: "15px" }} onClick={() => setSelectedForAll(false)} disabled={disabled} variant={"contained"}>
                    Снять выбор со всех
                </Button>
            </Grid>
            <Grid item xs={12} style={{ overflowX: "auto" }}>
                <Typography color={"textSecondary"}>
                    {label || "Показатели"}
                </Typography>
            </Grid>
            {/* <Button size="large" sx={{ marginTop: '20px', marginLeft: "15px" }} onClick={() => setOpen(true)} disabled={disabled} variant={"contained"}>
                Выбрать показатели
            </Button> */}

            <Divider />
            <Grid container spacing={1}>
                <Grid item xs={6} style={{ overflowX: "auto" }}>
                    <RestAutoComplete
                        restApi={serviceGroupCRUD}
                        query={groupQuery}
                        id={"serviceGroup"}
                        label={"Группа"}
                        value={groupId}
                        onChange={(value, obj) => {
                            //setGroupId(value?.id);
                            setGroupChanged(true);
                            setValue(name + '.groupId', value?.id);
                        }}
                        //getOptionLabel={(_) => _.name}
                        fullWidth
                    />

                </Grid>

            </Grid>
            <LoadingContainer loading={loading}>
                <Grid container spacing={1}>
                    <Grid item style={{ overflowX: "auto" }}>
                        <Table>
                            <TableBody>
                                {(fields || []).map((item, index) => {
                                    return (
                                        <TableRow>
                                            <TableCell variant={"head"} style={cellHeadStyles}>
                                                {item.name}
                                            </TableCell>
                                            <TableCell align={"right"} style={deleteColumnStyles}>
                                                <RHFCheckbox name={name + `.indicators[${index}].checked`} ></RHFCheckbox>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </LoadingContainer>

            <Divider />
            <FormHelperText error sx={{ p: 2 }}>
                {error?.message ? error?.message : null}
            </FormHelperText>
        </Grid>
    );
}

export default RHFGroupIndicator;
