import { actCRUD } from "../../http";
import {
  ACT_ADD_ERROR,
  ACT_ADD_REQUEST,
  ACT_ADD_SUCCESS,
  ACT_DELETE_ERROR,
  ACT_DELETE_REQUEST,
  ACT_DELETE_SUCCESS,
  ACT_EDIT_ERROR,
  ACT_EDIT_REQUEST,
  ACT_EDIT_SUCCESS,
  ACT_GET_BY_KEY_ERROR,
  ACT_GET_BY_KEY_REQUEST,
  ACT_GET_BY_KEY_SUCCESS,
  ACT_GET_ERROR,
  ACT_GET_REQUEST,
  ACT_GET_SUCCESS,
  ACT_WORKFLOW_RUN_ERROR,
  ACT_WORKFLOW_RUN_REQUEST,
  ACT_WORKFLOW_RUN_SUCCESS,
} from "../constants/actConstants";

export const _getActs = (args) => async (dispatch) => {
  try {
    dispatch({
      type: ACT_GET_REQUEST,
    });

    const data = await actCRUD.search(args);
    dispatch({
      type: ACT_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: ACT_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _getActByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACT_GET_BY_KEY_REQUEST,
    });

    const data = await actCRUD.get(id);
    dispatch({
      type: ACT_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: ACT_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _addAct = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: ACT_ADD_REQUEST,
    });

    const response = await actCRUD.create(data);
    dispatch(_getActs(args));

    dispatch({
      type: ACT_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    console.log({ error });
    dispatch({
      type: ACT_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _editAct = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: ACT_EDIT_REQUEST,
    });

    await actCRUD.edit(data);
    await dispatch(_getActs(args));

    dispatch({
      type: ACT_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACT_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _deleteAct = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: ACT_DELETE_REQUEST,
    });

    await actCRUD.delete(id);
    await dispatch(_getActs(args));

    dispatch({
      type: ACT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ACT_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const _workflowAct =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({
        type: ACT_WORKFLOW_RUN_REQUEST,
      });

      await actCRUD.workflowRun(id, prevStateId, nextStateId);
      // await dispatch(getActs(args));

      dispatch({
        type: ACT_WORKFLOW_RUN_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ACT_WORKFLOW_RUN_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
