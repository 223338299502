import { CRUD } from "./utils";
import {PrescriptionCRUDTable} from "../components/CRUD/Tables/PrescriptionCRUDTable";

export const accreditationCRUD = CRUD("accreditations");
export const actCRUD = CRUD("act");
export const actFormCRUD = CRUD("actForm");
export const anObjectsCRUD = CRUD("anObject");
export const patientsCRUD = CRUD("patient");
export const probeTypeCRUD = CRUD("probeType");

export const infectionKindCRUD = CRUD("infectionKind");
export const diagnosisCRUD = CRUD("disease");
export const contractCRUD = CRUD("contract");
export const employeeCRUD = CRUD("employee");
export const employeesCRUD = CRUD("employee");
export const diseaseIndexCRUD = CRUD("diseaseIndex");
export const diseaseIndexServiceCRUD = CRUD("diseaseIndexService");
export const governmentCRUD = CRUD("governmentStandard");
export const brigadeTypeCRUD = CRUD("brigadeType");
export const resultFormCRUD = CRUD("resultForm");
export const userRoleCRUD = CRUD("userRole");
export const planCRUD = CRUD("plan");

export const technicalRegulationsOfCustomsUnionCRUD = CRUD("technicalRegulationsOfCustomsUnion");

export const entityCRUD = (entityType) => CRUD(entityType);
export const dictionaryCRUD = (entityType) => CRUD(entityType);

// Requests
export const contractRequestCRUD = CRUD(`contractRequest`);
export const DDDRequestCRUD = CRUD("desinsectionRequest");
export const KDIRequestCRUD = CRUD("clinicalRequest");
export const serviceWithoutActRequestCRUD = CRUD("serviceWithoutActRequest");
export const withoutDepartureRequestCRUD = CRUD("withoutDepartureRequest");
export const selfControlRequestCRUD = CRUD("selfControlRequest");
export const departureScheduleRequestCRUD = CRUD("departureScheduleRequest");
export const indicatorCRUD = CRUD("indicator");

export const educationServiceCRUD = CRUD("educationService");
export const educationServiceKindCRUD = CRUD("educationServiceKind");
export const geoObjectCRUD = CRUD("geoObject");
export const geoObjectTypeCRUD = CRUD("geoObjectType");
export const requestSectionCRUD = CRUD("requestSection");
export const requestStateCRUD = CRUD("requestState");
export const contractStateCRUD = CRUD(`contractState`);
export const resultCRUD = CRUD("result");
export const resultTypeCRUD = CRUD("resultType");
export const brigadeCRUD = CRUD("brigade");
export const receiptCRUD = CRUD("receipt")

export const requestTarificationCRUD = CRUD("requestTarification");
export const positionCRUD = CRUD("position");

export const filialCRUD = CRUD("filial");
export const filialDivisionCRUD = CRUD("filialDivision");
export const formMapCRUD = CRUD("formMap");
export const serviceMapCRUD = CRUD("serviceMap");
export const userCRUD = CRUD("user");
export const divisionCRUD = CRUD("division");
export const departmentCRUD = CRUD("department");
export const materialTypeCRUD = CRUD("materialType");

export const referalsCRUD = CRUD("referral");
export const referralCRUD = CRUD("referral");
export const desinfectionReferenceCRUD = CRUD("desinfectionReference");
export const anObjectReferralCRUD = CRUD("anObjectReferral");
export const resolutionCRUD = CRUD("resolution");
export const resolutionActCRUD = CRUD("resolutionAct");
export const prescriptionCRUD = CRUD("prescription");
export const referralSpecificationCRUD = CRUD("referralSpecification");
export const serviceCRUD = CRUD("service");
export const serviceGroupCRUD = CRUD("serviceGroup");
export const serviceSubGroupCRUD = CRUD("serviceSubGroup");
export const servicePriceListCRUD = CRUD("servicePriceList");
export const serviceKindCRUD = CRUD("serviceKind");
export const samplingPurposeCRUD = CRUD("samplingPurpose");
export const requestCRUD = CRUD("request");
export const surObjectCRUD = CRUD("surObject");

export const protocolsCRUD = CRUD("protocol");
export const protocolCRUD = CRUD("protocol");
export const organizationFilialMapCRUD = CRUD("organizationFilialMap");

export const requestLockCRUD = CRUD("requestLock");
export const protocolFormCRUD = CRUD("protocolForm");
export const protocolSignerCRUD = CRUD("protocolSigner");

export const researchObjectCRUD = CRUD(`researchObject`);

export const dashboardCRUD = CRUD("dashboard");

export const api = CRUD("");
