import React, {useEffect, useMemo, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Grid, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import {
  getContractRequestByKey,
  getContractByKey,
  getContractRequests,
  workflowContractRequest,
} from "../../redux/actions";
import { PageHeader, PageContainer, PageFooter } from "../../components";

import DashboardLayout from "../../layouts/Dashboard";
import LoadingLayout from "../../layouts/LoadingLayout";
import {
  ContractRequestInfoSection,
  ContractRequestEditSection,
} from "./Sections";

import {renderControls, renderSections, RequestStateChecker} from "./../../functions";
import {CONTRACT_REQUEST, OBJECT_REFERRAL} from "../../routes/const";
import {confirmDialog} from "../../components/Dialog/DialogDelete";
import {REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_DECLINED} from "../../redux/constants/requestStateConstants";

export const ContractRequestInfo = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: contractRequestId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("read");

  const [stateId, setStateId] = useState(0);


  const { contractRequest } = useSelector((s) => s.contractRequest);
  const stateCode = contractRequest?.state?.code?.toLowerCase();

  const state = useMemo(
      () => new RequestStateChecker(contractRequest?.state?.code),
      [contractRequest?.state?.code]
  );

  const isEditMode = mode === "edit";

  const handleSwitchMode = (e, mode) => {
    e.preventDefault();

    switch (mode) {
      case "read":
        return setMode("read");
      case "edit":
        return setMode("edit");
      default:
        return setMode("read");
    }
  };

  const handleWorkflow = async (e, nextStateId) => {
    e.preventDefault();
    setLoading(true);

    if (!contractRequest?.state?.id) return setLoading(false);

    const prevStateId = contractRequest.state.id;

    // Updating Workflow
    const updated = await dispatch(
      workflowContractRequest(contractRequestId, prevStateId, nextStateId)
    );
    if (!updated) return setLoading(false);

    console.log({ nextStateId });

    setStateId(nextStateId);
    setLoading(false);
  };

  const handleWorkflowDeclinedToCreate = async () => {
    setLoading(true);

    if (!contractRequest?.state?.id) return setLoading(false);

    const prevStateId = contractRequest.state.id;
    const nextStateId = state.getId(REQUEST_STATE_ID_CREATED);

    // Updating Workflow
    const updated = await dispatch(
        workflowContractRequest(contractRequestId, prevStateId, nextStateId)
    );
    if (!updated) return setLoading(false);

    setStateId(nextStateId);
    setLoading(false);
  };


  const sections = [
    {
      mode: "read",
      component: ContractRequestInfoSection,
    },
    {
      mode: "edit",
      component: ContractRequestInfoSection,
    },
  ];

  const controls = [
    {
      state: "created",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 2)}
        >
          Начать обработку
        </LoadingButton>,
        <>
          {isEditMode ? (
            <Button
              color="secondary"
              onClick={(e) => handleSwitchMode(e, "read")}
            >
              Просмотр
            </Button>
          ) : (
            <Button
              color="secondary"
              onClick={(e) => handleSwitchMode(e, "edit")}
            >
              Редактировать
            </Button>
          )}
        </>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "processing",
      roles: [],
      buttons: [
        <LoadingButton
          loading={loading}
          color="primary"
          onClick={(e) => handleWorkflow(e, 4)}
        >
          Завершить
        </LoadingButton>,
        <LoadingButton color="warning" onClick={(e) => handleWorkflow(e, 5)}>
          Отклонить с комментариями
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    },
    {
      state: "declined",
      roles: [],
      buttons: [
        <LoadingButton
            loading={loading}
            onClick={() => {
              confirmDialog('Пересоздание', 'Вы действительно хотите пересоздать заявку?', async () => {
                await handleWorkflowDeclinedToCreate()
              })
            }
            }
        >
          Пересоздать
        </LoadingButton>,
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      ],
    }
  ];

  useEffect(async () => {
    console.log({ contractRequestId, stateId });
    if (!contractRequestId)
      return;

    setLoading(true);
    await dispatch(getContractRequestByKey(contractRequestId));
    setLoading(false);
  }, [dispatch, contractRequestId, stateId]);

  return (
    <LoadingLayout loading={loading}>
      <PageHeader
        heading={`Сводная заявка №${contractRequestId} ${
          stateCode ? `(${t(`dashboard.state.${stateCode}`)})` : ""
        }`}
        controls={renderControls(controls, {
          state: stateCode,
          roles: [],
        })}
      />
      {renderSections(sections, { mode })}
    </LoadingLayout>
  );
};
