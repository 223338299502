import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CircularProgress, Grid, MenuItem, Popover, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";
import { $authHost, protocolSignerCRUD, resultCRUD, referralCRUD, anObjectReferralCRUD } from '../../../http';
import { accreditationCRUD, protocolsCRUD } from '../../../http/CRUD'
import { asJSONSafe, fetchAsBase64 } from "../../../functions";

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import ruLocale from 'date-fns/locale/ru'
import DateTimePicker from '@mui/lab/DateTimePicker'
import logos, { filialsMapping } from '../PDFGenerator/logos/index.js'
import HtmlGenerator from '../HtmlGenerator/index'
import { js2xml } from 'xml-js';
import { parseJsonSafe, toUIJson } from "../../../functions";
import { useNCALayerClient } from "../../../functions/sign/SignHooks";
import LoadingButton from "@mui/lab/LoadingButton";
import useLoader from "../../../hooks/useLoader";
import useAlert from "../../../hooks/useAlert";
import { generateLink, renderPageNumeration, renderPageNumerationAndWatermark } from "../../../functions/renderProtocol";
import { formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS } from "../../../functions/formatters";
import { distinct, selectMany } from "../../../functions/linqSimulation";
import useRoles from "../../../hooks/useRoles";
import logoWatermark from '../PDFGenerator/watermark/logo_watermark.png';
import { ClientPdf } from "../_sections/ClientPdf.jsx";
import { equals, page, where } from "../../../functions/resourceLinq1.js";
import useResourceLinq from "../../../data/useResourceLinq.js";
import { ProtocolPdf } from "../_sections/ProtocolPdf.jsx";
import { TabPanel } from "@mui/lab";
import { InfoCircleOutlined } from "@ant-design/icons";


window.Buffer = window.Buffer || require("buffer").Buffer;
const {TabPane} = Tabs;

const style = {
    position: 'absolute',
    top: '10%',
    bottom: '20%',
    left: '20%',
    right: '20%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    height: '85%',
    display: 'block',
    p: 4,
};

export const fetchPdf = () => {
    // var data = { id: 1 };
    // let urlFull = `${getLocationOrigin(20081)}/${'api/v1/protocol/pdf'}`;
    // urlFull = 'http://185.237.186.69:20081/api/v1/protocol/pdf';
    // return postForDownloadFile(data, urlFull);
};

export default function ProtocolDetails({
    action,
    reload,
    title,
    open,
    setOpen,
    onSubmit,
    query,
    protocol,
    loading,
    ...props
}) {
    const [id, setId] = useState(null);
    const [number, setNumber] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [formId, setFormId] = useState('');
    const [referralId, setReferralId] = useState('');
    const [resultId, setResultId] = useState('');
    const [filialId, setFilialId] = useState('');
    const [filialDivisionId, setFilialDivisionId] = useState('');
    const [employee, setEmployee] = useState(null);
    const [filialDivision, setFilialDivision] = useState(null);
    const [availableLogos, setAvailableLogos] = useState(null);
    const [logos, setLogos] = useState(null);
    const [departmentId, setDepartmentId] = useState('');
    const [divisionId, setDivisionId] = useState('');
    const [responsibleId, setResponsibleId] = useState('');
    const [performerId, setPerformerId] = useState('');
    const [data, setData] = useState('');
    const [addOptionalLogo, setAddOptionalLogo] = React.useState(false)
    const [signed, setSigned] = React.useState(false)
    const [logo, setLogo] = React.useState('')
    const [protocolIssueDate, setProtocolIssueDate] = React.useState(new Date())
    const [employeeFilial, setEmployeeFilial] = React.useState({})
    const [signers, setSigners] = React.useState([])
    const [result, setResult] = React.useState(null);
    const [userCanSignProtocol, setUserCanSignProtocol] = React.useState(false);
    const [signersIds, setSignersIds] = React.useState([]);

    const [openChild, setOpenChild] = React.useState(false)
    const [comment, setComment] = React.useState('')

    const [client, ready] = useNCALayerClient();
    const [ecpProtocolXml, setEcpProtocolXml] = useState('');
    const [ecpMessage, setEcpMessage] = useState(null);
    const [ecpProtocolSignedXml, setEcpProtocolSigned] = useState('');
    const { loading: loadingButton, start, stop } = useLoader(false);
    const alert = useAlert(false, 1);
    const { isAdministrator } = useRoles();
    const [watermarkUrl, setWatermarkUrl] = useState(null);
    const [clientPdf, setClientPdf] = useState(null);

    useEffect(() => {
        if (!open) {
            return;
        }

        //setEcpProtocolSigned('');
    }, [open]);

    useEffect(() => {
        if (!client)
            return;

        client.onopen = function () {
            setEcpMessage(null);
        }

        client.onclose = function () {
            setEcpMessage("Ошибка соединения с провайдером ЭЦП (NCALayer)");
        }

        client.close = function () {
            setEcpProtocolSigned('')
            setEcpProtocolXml('')
        }

        client.onmessage = function (msg) {
            if (msg.responseObject)
                setEcpProtocolSigned(msg.responseObject || {});

        };

    }, [client])

    useEffect(async () => {
        if (!open || !protocol)
            return;

        await fetchAsBase64(logoWatermark, (_) => {
            setWatermarkUrl(_);
        });
    }, [open, protocol]);

    const emptyState = () => {
        setId(null);
        setNumber('');
        setCreatedAt('');
        setFormId('');
        setReferralId('');
        setResultId('');
        setFilialId('');
        setFilialDivisionId('');
        setDepartmentId('');
        setDivisionId('');
        setResponsibleId('');
        setPerformerId('');
    };

    const handleForm = async (e) => {
    };

    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(async () => {
        if (!open || !protocol)
            return;

        const {
            id,
            number,
            data,
            createdAt,
            formId,
            referralId,
            objectReferralId,
            resultId,
            filialId,
            filialDivisionId,
            departmentId,
            divisionId,
            responsibleId,
            performerId,
            state,
            signers
        } = protocol || { signers: [] };
        setData(data && toUIJson(data))
        setSigned(state?.code === 'Signed')
        setId(id);
        setNumber(number);
        setCreatedAt(createdAt);
        setFormId(formId);
        setReferralId(referralId);
        setResultId(resultId);
        setFilialId(filialId);
        setFilialDivisionId(filialDivisionId);
        setDepartmentId(departmentId);
        setDivisionId(divisionId);
        setResponsibleId(responsibleId);
        setPerformerId(performerId);
        setSigners(signers)
        // console.log(toUIJson(data))
        setLogo(toUIJson(data)["logoName"])
        setAddOptionalLogo(toUIJson(data)["addOptionalLogo"]);

        setClientPdf(null);

        if (resultId) {
            const result = await resultCRUD.get(resultId);
            await setResult(result);
        }

        if (objectReferralId) {
            const objectReferral = await anObjectReferralCRUD.get(objectReferralId)
            if (objectReferral.researchEndDateTime != "0001-01-01T00:00:00")
                await setProtocolIssueDate(new Date(objectReferral.researchEndDateTime))
        }



        // if(referralId){
        //     const referral = await referralCRUD.get(referralId)
        //     await setProtocolIssueDate(new Date(.researchEndDateTime))  
        // }

        const employeeId = JSON.parse(window.localStorage.employee).id;
        const canSign = (signers || [])
            .map(_ => _?.employeeId)
            .includes(employeeId);
        setUserCanSignProtocol(canSign);

        const signerIds = signers?.map?.(({ employeeId }) => employeeId);
        setSignersIds(signerIds);

        setEmployee(parseJsonSafe(window.localStorage.employee));
        setFilialDivision(protocol?.filialDivision);
    }, [open, protocol]);

    const accrediationsResource = useResourceLinq(accreditationCRUD);

    useEffect(async () => {
        if (!open || !protocol || !employee?.filialId)
            return;
        // console.log(employee)
        // const logoMappings = filialsMapping
        //     .filter(logosGroup => {
        //         if (Array.isArray(logosGroup.filialId)) {
        //             return logosGroup.filialId.includes(employee.filialId)
        //         } else {
        //             return logosGroup.filialId === employee.filialId
        //         }
        //     })
        //     .filter(logosGroup => {
        //         if (!logosGroup.filialDivisionId) return true
        //         if (Array.isArray(logosGroup.filialDivisionId)) {
        //             return logosGroup.filialDivisionId.includes(employee.filialDivisionId)
        //         } else {
        //             return logosGroup.filialDivisionId === employee.filialDivisionId
        //         }
        //     })
        //     .map(logosGroup => {
        //         let group = logos[logosGroup.filialName]
        //         if (logosGroup.filialDivisionName) group = group[logosGroup.filialDivisionName]
        //         return group
        //     })
        //     .reduce((prev, cur) => prev.concat(cur), [])
        //     .concat(...logos.common);

        const logoMappings = filialsMapping
            .filter(logosGroup => {
                if (isAdministrator())
                    return true;
                if (Array.isArray(logosGroup.filialId)) {
                    return logosGroup.filialId.includes(employee.filialId)
                } else {
                    return logosGroup.filialId === employee.filialId
                }
            })
            .filter(logosGroup => {
                if (isAdministrator())
                    return true;
                if (!logosGroup.filialDivisionId || !employee.filialDivisionId) return true;
                if (Array.isArray(logosGroup.filialDivisionId)) {
                    return logosGroup.filialDivisionId.includes(employee.filialDivisionId)
                } else {
                    return logosGroup.filialDivisionId === employee.filialDivisionId
                }
            });
        // console.log(logoMappings)
        let logos = selectMany(logoMappings, _ => _.logo);
        logos = distinct(logos, (a, b) => a == b);
        setAvailableLogos(logos);

        let accreditationQuery = page({}, 0, null);

        if (!isAdministrator()) {
            if (filialId || employee.filialId) {
                accreditationQuery = where(accreditationQuery, {
                    filialId: equals(filialId || employee.filialId)
                });
            }

            if (filialDivisionId || employee.filialDivisionId) {
                accreditationQuery = where(accreditationQuery, {
                    filialDivisionId: equals(filialDivisionId || employee.filialDivisionId) 
                });
            }
        }

        let accreditations2 = await accrediationsResource.toList(accreditationQuery);
        accreditations2 = accreditations2.map(_ => {
            return { ..._, sign: asJSONSafe(_.sign) };
        });
        setLogos(accreditations2);
    }, [open, protocol, employee?.filialId]);

    const signProtocol = async () => {
        start()
        const request = { id, comment: "Подписано", pdfBase64: clientPdf };

        if (ecpProtocolXml && ecpProtocolSignedXml) {
            request.sign = {
                xml: ecpProtocolXml,
                signedXml: ecpProtocolSignedXml
            }
        }
        await protocolsCRUD.workflowSignRunProtocol(request);
        alert.show(1, 'Протокол подписан!');
        setOpen(false)
        reload && reload()
        //setEcpProtocolSigned('');
        stop()
    }

    const createClientPdf = async () => {
        start()
        const request = await protocolsCRUD.get(id);
        request.data = asJSONSafe(request.data);
        request.pdf = clientPdf;
        request.externallyReceived = false;
        await protocolsCRUD.edit(request);
        reload && reload()
        stop()
    }


    const editorRef = useRef(null);

    const [tabIndex, setTabIndex] = useState(0);
    const authorizedEmployee = useSelector(state => state.employee.authorizedEmployee)

    const lastSigned = React.useMemo(() => {
        return signers?.find(el => el.lastSigned) || null
    }, [signers])

    const hasYourSign = React.useMemo(() => {
        return signers?.find(el => el.userId === authorizedEmployee.userId && el.isSigned) || false
    }, [signers])



    const last = React.useMemo(() => {
        if (signed) {
            return signers?.slice(-1)[0] || null
        }
        return null

    }, [signers])

    const textTemplate = (s) => {
        return `<span style="font-size: 14pt; font-family: 'Times New Roman', serif">${s || ""}</span>`;
    }


    const handleGenerateHTML = (numeration = true, signed = false, qrData) => {
        const html = editorRef.current.getContent();
        const header = `${textTemplate("№" + number + " " + Intl.DateTimeFormat('ru-RU', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).format(new Date()) + " ж. (г.)")}`
        const readHtml = `
                <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
                <html xmlns:v="urn:schemas-microsoft-com:vml"
                      xmlns:o="urn:schemas-microsoft-com:office:office"
                      xmlns:w="urn:schemas-microsoft-com:office:word"
                      xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
                      xmlns="http://www.w3.org/TR/REC-html40">
                
                <head>
                <style>
                v\:* {behavior:url(#default#VML);}
                o\:* {behavior:url(#default#VML);}
                w\:* {behavior:url(#default#VML);}
                .shape {behavior:url(#default#VML);}
                </style>
                <style>            
                      @bottom-left {
                        content: counter(page) ' of ' counter(pages);
                      }
                @page Section1 {size:595.45pt 841.7pt; margin:0.5in 0.5in 0.5in 0.5in;mso-page-orientation:portrait;mso-header-margin:.5in;mso-footer-margin:.5in;mso-paper-source:0; mso-footer: f1; mso-header: h1; mso-first-header: fh1; mso-first-footer: ff1;}
                        div.Section1 {page:Section1;}
                        @page Section2 {size:841.7pt 595.45pt;mso-page-orientation:landscape;margin:1.25in 1.0in 1.25in 1.0in;mso-header-margin:.5in;mso-footer-margin:.5in;mso-paper-source:0;mso-footer: f1; mso-header: h1; mso-first-header: fh1; mso-first-footer: ff1;}
                        div.Section2 {page:Section2;}
                        div.table-bordered td { border: 1px solid black !important; } 
                         p.headerFooter { margin:0in; text-align: center; }
                </style>
                </head>
                <body>
                <div class=Section1>
                ${html}
                </div>
                ${signed ? `<p style="font-size: 8pt; margin-top: 200px">Осы құжат "Электрондық құжат және электрондық цифрлық қолтаңба туралы " Қазақстан Республикасының 2003 жылғы 7 қаңтардағы N 370-II Заңы 7 бабының 1 тармағына сәйкес қағаз тасығыштағы құжатпен
            бірдей.</p>` : ''}
                ${signed ? `<p style="font-size: 8pt;">Данный документ согласно пункту 1 статьи 7 ЗРК от 7 января 2003 года «Об электронном документе и электронной цифровой подписи» равнозначен документу на бумажном носителе.</p>` : ''}
                ${numeration ? renderPageNumerationAndWatermark(header, watermarkUrl) : ''} 
                ${qrData && signed ? `<img width="122" height="123" src=${qrData}/>` : ''}
                </body>
                </html>
         `

        return readHtml
    }

    const willSelectEcp = (data, last = false) => {
        let lastData = null;
        if (last) {
            lastData = unescape(encodeURIComponent(data));
        }
        const protocolXML = last ?
            lastData : data;
        setEcpProtocolXml(protocolXML);

        client.signXml('PKCS12', 'SIGNATURE', protocolXML);
    }

    const handleSignEcp = async () => {

        if (lastSigned) {
            const data = await protocolSignerCRUD.get(lastSigned.id)
            const jsonData = JSON.parse(data?.sign);
            await willSelectEcp(jsonData?.SignedXml, true)
        } else {
            const xml = js2xml(generateLink(id), { compact: true });
            await willSelectEcp(xml);
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;

    const handleDeclineProtocol = async () => {
        $authHost.post('/api/v1/protocol/workflow/decline/run', {
            id,
            comment
        }).then(() => {
            alert.show(1, 'Протокол отклонён!');
            setOpen(false)
            reload()
            stop()
        })
    }

    console.log('clientPdf: ' + clientPdf);
    const [forceLandscape, setForceLandscape] = useState(false);

    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                {loading ? <CircularProgress /> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    Номер: {number}
                                </Grid>
                                <Grid item xs={6}>
                                    Создано: {formatDate(createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS)}
                                </Grid>
                                <Grid item xs={6}>
                                    Форма: {protocol?.form?.number}
                                </Grid>
                                <Grid item xs={6}>
                                    Направление: {protocol?.referralId}
                                </Grid>
                                <Grid item xs={6}>
                                    Журнал: {result?.number}, ({result?.form?.name || result?.form?.number})
                                </Grid>
                                <Grid item xs={6}>
                                    Филиал: {protocol?.filial?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Районнное отделение: {filialDivision?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Лаборатория: {protocol?.department?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Отдел: {protocol?.division?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Заведующий лабораторией: {protocol?.responsible?.fullName}
                                </Grid>
                                <Grid item xs={6}>
                                    Исполнитель (лаборант): {protocol?.performer?.fullName}
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={2}>
                                        <div>
                                            <Typography variant='h6' component='h2'>Экспорт WORD</Typography>
                                        </div>
                                        <div>
                                            <FormControl fullWidth>
                                                <InputLabel id='logo-select'>Выберете логотип для документа</InputLabel>
                                                <Select
                                                    labelId='logo-select'
                                                    id="demo-simple-select"
                                                    value={logo}
                                                    label='Выберете логотип для документа'
                                                    onChange={({ target: { value } }) => setLogo(value)}
                                                >
                                                    <MenuItem value=''>
                                                        Без логотипа
                                                    </MenuItem>
                                                    {availableLogos && availableLogos.map(logoURL => (
                                                        <MenuItem value={logoURL} key={logoURL}>
                                                            <img src={logoURL} alt='Логотип филиала'
                                                                style={{ height: 70 }} />
                                                        </MenuItem>
                                                    ))}
                                                    {logos && logos.map(_ => (
                                                        <MenuItem value={_.sign?.base64} key={_.id}>
                                                            <img src={_.sign?.base64} alt='Логотип филиала'
                                                                style={{ height: 70 }} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={addOptionalLogo}
                                                        onChange={(_, value) => setAddOptionalLogo(value)} />}
                                                    label='Добавить логотип ilac-MRA'
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox checked={forceLandscape}
                                                        onChange={(_, value) => setForceLandscape(value)} />}
                                                    label='Pdf альбомный'
                                                />
                                            </FormGroup>
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
                                                <DateTimePicker
                                                    renderInput={props => <TextField {...props} />}
                                                    showTodayButton
                                                    label='Дата выдачи протокола'
                                                    value={protocolIssueDate}
                                                    onChange={setProtocolIssueDate}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </Stack>
                                </Grid>

                                {open && <Grid item xs={12}>
                                    <Box>
                                        <Tabs value={tabIndex} onChange={(event, value) => setTabIndex(value)}>
                                            <Tab label="Word"/>
                                            <Tab label="Pdf"/>                                            
                                        </Tabs>
                                        {tabIndex == 0 && <Box>
                                            {protocol && signersIds && <HtmlGenerator
                                                    data={data}
                                                    protocol={protocol}
                                                    ref={editorRef}
                                                    lastSigned={lastSigned}
                                                    last={last}
                                                    signed={signed}
                                                    handleClose={handleClose}
                                                    signers={signers}
                                                    handleGenerateHTML={handleGenerateHTML}
                                                    formID={formId}
                                                    filialDivision={filialDivision}
                                                    filial={protocol?.filial ?? employeeFilial}
                                                    logo={logo}
                                                    number={number}
                                                    addOptionalLogo={addOptionalLogo}
                                                    dateOfIssue={protocolIssueDate}
                                                />}
                                        </Box>}
                                        {tabIndex == 1 && <Box>
                                            {protocol && signersIds && <ProtocolPdf
                                                    number={number}
                                                    protocolData={data}
                                                    formId={formId}
                                                    protocolSigners={signers}
                                                    logo={logo}
                                                    clientPdf={clientPdf}
                                                    setClientPdf={setClientPdf}
                                                    date={formatDate(createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS)}
                                                    protocol={protocol}
                                                    addOptionalLogo={addOptionalLogo}
                                                    dateOfIssue={protocolIssueDate}
                                                    forceLandscape={forceLandscape}
                                                />}
                                        </Box>}

                                        <ClientPdf number={number}
                                            clientPdf={clientPdf}
                                            setClientPdf={setClientPdf}
                                            date={formatDate(createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS)}
                                            protocol={protocol}
                                        />
                                    </Box>
                                </Grid>}

                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography variant='caption'>Подписали:</Typography>
                                            {
                                                ['performer', 'responsible', 'leader'].map((key, i) => (
                                                    protocol?.[key] && <Typography variant='caption'>
                                                        {['Лаборант', 'Заведующий лабораторией', 'Руководитель организацией'][i]}
                                                        ({protocol[key].fullName}):
                                                        {signers.find(signer => signer.employeeId === protocol[key].id)?.isSigned ? 'Да' : 'Нет'}
                                                    </Typography>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={6} style={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            flexDirection: 'column'
                                        }}>
                                            <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 2 }} alignItems="center">
                                                <LoadingButton loading={loadingButton} type="button"
                                                    disabled={signed || hasYourSign || !userCanSignProtocol || (!Object.keys(ecpProtocolXml)?.length || 
                                                        !Object.keys(ecpProtocolSignedXml)?.length)}
                                                    variant="contained"
                                                    onClick={signProtocol}>
                                                    Подписать
                                                </LoadingButton>
                                                <LoadingButton loading={loadingButton} type="button" color="error"
                                                    disabled={signed || !userCanSignProtocol || hasYourSign}
                                                    variant="contained"
                                                    onClick={handleClick}>
                                                    Не согласен
                                                </LoadingButton>
                                                {isAdministrator() &&
                                                    <LoadingButton loading={loadingButton} type="button"

                                                        variant="contained"
                                                        onClick={createClientPdf}>
                                                        Сформировать
                                                    </LoadingButton>
                                                }
                                                <Popover
                                                    id={idPopover}
                                                    open={openPopover}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClosePopover}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Stack direction="column" spacing={2} sx={{ padding: 3, width: '350px' }}>
                                                        <TextField
                                                            multiline
                                                            label={"Комментарий"}
                                                            onChange={e => setComment(e.target.value)}
                                                            fullWidth
                                                            value={comment}
                                                        />
                                                        <Button onClick={handleDeclineProtocol} fullWidth variant="contained" sx={{ mt: 3 }}>
                                                            {'Подтвердить'}
                                                        </Button>
                                                    </Stack>
                                                </Popover>
                                            </Stack>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    disabled={signed || !userCanSignProtocol || hasYourSign}
                                                    sx={{ margin: '10px 0 10px 0px' }}
                                                    onClick={handleSignEcp}
                                                >
                                                    Выбрать ЭЦП
                                                </Button>
                                            </div>
                                            {(signed || !userCanSignProtocol) && <Typography variant='caption' style={{
                                                maxWidth: 200,
                                                color: '#515151',
                                                textAlign: 'right'
                                            }}>
                                                {signed
                                                    ? 'Протокол уже подписан'
                                                    : !userCanSignProtocol
                                                    && 'Вы не можете подписать протокол, недостаточно прав'
                                                }
                                            </Typography>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {action !== 'details' &&
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
            {alert.render()}
        </div>
    )
}
