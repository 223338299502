import {
    ApartmentOutlined,
    AppstoreOutlined,
    AuditOutlined,
    BookOutlined,
    BranchesOutlined,
    BarChartOutlined,
    CloudServerOutlined,
    ClusterOutlined,
    DeliveredProcedureOutlined,
    DollarOutlined,
    ExperimentOutlined,
    FileProtectOutlined,
    LogoutOutlined,
    ProfileOutlined,
    ProjectOutlined,
    TeamOutlined,
} from "@ant-design/icons";
import {
    AccountBalance,
    AccountBalanceWallet,
    AddModeratorOutlined,
    Apartment,
    AutoStories,
    Build,
    ContentPaste,
    ContentPasteOffOutlined,
    ContentPasteSharp,
    Engineering,
    Feed,
    FindInPage,
    LocalHospitalOutlined,
    MedicalServices,
    MedicalServicesRounded,
    MedicalServicesSharp,
    Medication,
    MedicationOutlined,
    MedicationTwoTone,
    AlignHorizontalLeftIcon,
    MenuOpen,
    NavigateBefore,
    WorkOutline,
    FolderCopy,
    AdsClickIcon
} from "@mui/icons-material";
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleIcon from "@mui/icons-material/People";
import EmojiPeopleIcon from "@mui/icons-material/People";
import {Paper} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {ExceptionMap} from "antd/lib/result";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";

import {intersection} from "../../functions/arrayHelpers";
import {logout} from "../../redux/actions/authActions";
import {CONTRACT_REQUEST, LOGIN_ROUTE} from "../../routes/const";
import ListItemDropDown from "./ListItemDropDown";

const style = {
    fontSize: "1.6rem",
    marginLeft: "0.1rem",
};
const MenuListItems = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {pathname} = useLocation();
    const {
        openListRequests,
        openListDictionaries,
        openListActs,
        openListActsDSEK,
        openListDirections,
        openListDDD,
        openListReportAndAnalytics,
        openListKsec,        
        openListResult,
        openListProtocol,
        openListReferal,
        openListObjectReferal,
        openAdministration,
    } = useSelector((state) => state.generic);
    const jsonRoles = localStorage.getItem("roles");
    const roles = jsonRoles && JSON.parse(jsonRoles);

    const filterByRoles = (_) => {
        if (!roles) return _;
        return intersection(_, roles, (a, b) => {
            return !a.roles || a.roles.find((_) => _ == b);
        });
    };

    let itemsFirst = [
        {
            text: t("dashboard.listItem.requestList"),
            route: "requestList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                "FilialAdministrator",
                'Committee',
                "Specialist",
                "Marketer",
                "Supervisor",
                "Performer",
                "Director",
                "CommitteeAdministrator"
            ],
            icon: <AppstoreOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.dashboard"),
            route: "dashboard",
            roles: [
                "SuperAdministrator",
                "Administrator",
                "AdministratorReadonly"
            ],
            icon: <AppstoreOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.referralList"),
            route: "referral",
            icon: <MedicalServices style={style}/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer", "Director"],
        },
        {
            text: t("dashboard.listItem.objectReferralList"),
            route: "object-referral",
            icon: <MedicalServices style={style}/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer", "Director"],
        }
    ];

    let itemsReferral = [
        {
            text: 'Все статусы',
            route: "referral",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'Создана',
            route: "referral?state=1",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'В процессе',
            route: "referral?state=2",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'Проведение исследования',
            route: "referral?state=3",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer", "Director"],
        },
        {
            text: 'Завершено',
            route: "referral?state=4",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'Отклонено',
            route: "referral?state=5",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'На согласовании',
            route: "referral?state=7",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer", "Director"],
        },
        // {
        //   text: 'Отбор проб',
        //   route: "referral?state=6",
        //   icon: <LocalHospitalOutlined/>,
        //   roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Committee"],
        // }
    ]

    let items0bjectReferral = [
        {
            text: 'Все статусы',
            route: "object-referral",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'Создана',
            route: "object-referral?state=1",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'В процессе',
            route: "object-referral?state=2",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'Проведение исследования',
            route: "object-referral?state=3",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer", "Director"],
        },
        {
            text: 'Завершено',
            route: "object-referral?state=4",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'Отклонено',
            route: "object-referral?state=5",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        },
        {
            text: 'На согласовании',
            route: "referral?state=7",
            icon: <LocalHospitalOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer", "Director"],
        },
        // {
        //   text: 'Отбор проб',
        //   route: "object-referral?state=6",
        //   icon: <LocalHospitalOutlined/>,
        //   roles: ["SuperAdministrator", "Administrator", "Specialist", "FilialAdministrator", "Supervisor", "Performer"],
        // }
    ]

    itemsReferral = filterByRoles(itemsReferral);
    items0bjectReferral = filterByRoles(items0bjectReferral);


    itemsFirst = filterByRoles(itemsFirst);
    let itemsSecond = [
        {
            text: t("dashboard.listItem.contractRequest"),
            route: "contract-requests",
            icon: <MenuOpen/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Marketer", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.request1"),
            route: "kdi-requests",
            icon: <ProfileOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.request5"),
            route: "ddd-requests",
            icon: <ProfileOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.request2"),
            route: "without-departure-requests",
            icon: <ProfileOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.request3"),
            route: "service-without-act-requests",
            icon: <ProfileOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.request4"),
            route: "departure-schedules",
            icon: <ProfileOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.request6"),
            route: "self-control-requests",
            icon: <ProfileOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Supervisor", "Performer"],
        }
    ];

    itemsSecond = filterByRoles(itemsSecond);

    let administrationItems = [
        {
            text: t("dashboard.listItem.employees"),
            route: "employeeList",
            icon: <Engineering style={style}/>,
            roles: ["SuperAdministrator", "Administrator", "FilialAdministrator"],
        },

        {
            text: t("dashboard.listItem.filials"),
            route: 'filialList',
            icon: <Apartment style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.diseaseIndex"),
            route: 'diseaseIndexList',
            icon: <BarChartOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.serviceMapList"),
            route: 'serviceMapList',
            icon: <BranchesOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "FilialAdministrator"],
        },
        {
            text: t("dashboard.listItem.serviceGroupList"),
            route: 'serviceGroupList',
            icon: <BranchesOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "FilialAdministrator"],
        },
        {
            text: t("dashboard.listItem.formMap"),
            route: 'formMapList',
            icon: <BranchesOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.filialDivisions"),
            route: 'filialDivisionList',
            icon: <BranchesOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.departments"),
            route: "departmentList",
            icon: <BranchesOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },

        {
            text: t("dashboard.listItem.divisions"),
            route: "divisionList",
            icon: <BranchesOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.requestLockList"),
            route: 'requestLockList',
            icon: <BranchesOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.resultFormList"),
            route: "resultFormList",
            roles: ["SuperAdministrator", "Administrator"],
            icon: <BookOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.protocolFormList"),
            route: "protocolFormList",
            roles: ["SuperAdministrator", "Administrator"],
            icon: <ProfileOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.servicePriceList"),
            route: "servicePriceList",
            icon: <ProjectOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.organizationFilialMapList"),
            route: 'organizationFilialMapList',
            icon: <ProjectOutlined style={style}/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        {
            text: t("dashboard.listItem.accreditationList"),
            route: 'accreditationList',
            icon: <PlaylistAddCircleIcon/>,
            roles: ["SuperAdministrator", "Administrator"],
        },
        
    ];

    administrationItems = filterByRoles(administrationItems);

    let itemsOfDictionaries = [
        {
            text: t("dashboard.listItem.serviceList"),
            route: "serviceList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <CloudServerOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.serviceListClient"),
            route: "serviceListClient",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <CloudServerOutlined style={style}/>,
        },
        {
            text: 'Объекты',
            route: "anObjectList",
            roles: [
                "SuperAdministrator",
                "Administrator"
            ],
            icon: <CloudServerOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.governmentStandardList"),
            route: "governmentStandardList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.medicalOrderList"),
            route: "medicalOrderList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.positionList"),
            route: "positionList",
            roles: ["SuperAdministrator", "Administrator"],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.purposeOfSelectionList"),
            route: "purposeOfSelectionList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.referralAdditionalInfoList"),
            route: "referralAdditionalInfoList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.referralOrderTypeList"),
            route: "referralOrderTypeList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.samplePreservationMethodList"),
            route: "samplePreservationMethodList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },

        {
            text: t("dashboard.listItem.serviceScheduleList"),
            route: "serviceScheduleList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.storageConditionList"),
            route: "storageConditionList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.technicalRegulationsOfCustomsUnionList"),
            route: "technicalRegulationsOfCustomsUnionList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.transportationConditionList"),
            route: "transportationConditionList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.transportTypeList"),
            route: "transportTypeList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.unitOfMeasureList"),
            route: "unitOfMeasureList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.chemicalIndicesList"),
            route: "chemicalIndicesList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.diseaseList"),
            route: "diseaseList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.infectionKindList"),
            route: "infectionKindList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.sicknessKindList"),
            route: "sicknessKindList",
            roles: [
                "SuperAdministrator",
                "Administrator",
                // "Specialist",
                // "Supervisor",
            ],
            icon: <ExperimentOutlined/>,
        },
        {
            text: t("dashboard.listItem.anObjectSectionList"),
            route: "anObjectSectionList",
            roles: [
                "SuperAdministrator",
                "Administrator"
            ],
            icon: <CloudServerOutlined style={style}/>,
        },
        {
            text: t("dashboard.listItem.anObjectSubSectionList"),
            route: "anObjectSubSectionList",
            roles: [
                "SuperAdministrator",
                "Administrator"
            ],
            icon: <CloudServerOutlined style={style}/>,
        },
    ];

    itemsOfDictionaries = filterByRoles(itemsOfDictionaries);

    let itemsThird = [
        {
            text: t("dashboard.listItem.actBuyProducts"),
            route: "ActBuyProducts",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer" ],
        },
        {
            text: t("dashboard.listItem.actBioTest"),
            route: "ActBioTest",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer" ],
        },
        {
            text: t("dashboard.listItem.actBacteriologicalTest"),
            route: "ActBacteriologicalTest",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actWater"),
            route: "ActWater",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actProducts"),
            route: "ActProducts",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actZoonotic"),
            route: "ActZoonotic",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actTicks"),
            route: "ActTicks",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actDisinfectant"),
            route: "ActDisinfectant",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actRadio"),
            route: "ActRadio",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actWaste"),
            route: "ActWaste",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actSampling"),
            route: "ActSamplingReport",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
        {
            text: t("dashboard.listItem.actLand"),
            route: "ActLand",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Marketer", "Specialist", "Supervisor", "Performer"],
        },
    ];

    let itemsThirdDSEK = [
        {
            text: t("dashboard.listItem.actBuyProducts"),
            route: "ActBuyProductsDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actBioTest"),
            route: "ActBioTestDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actBacteriologicalTest"),
            route: "ActBacteriologicalTestDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actWater"),
            route: "ActWaterDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actProducts"),
            route: "ActProductsDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actZoonotic"),
            route: "ActZoonoticDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actTicks"),
            route: "ActTicksDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actDisinfectant"),
            route: "ActDisinfectantDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actRadio"),
            route: "ActRadioDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actWaste"),
            route: "ActWasteDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actSampling"),
            route: "ActSamplingReportDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.actLand"),
            route: "ActLandDSEK",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
    ];


    itemsThird = filterByRoles(itemsThird);
    itemsThirdDSEK = filterByRoles(itemsThirdDSEK);

    let directionItems = [
        {
            text: t("dashboard.listItem.directionVirus"),
            route: "DirectionVirus",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionBlood"),
            route: "DirectionBlood",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionSampling"),
            route: "DirectionSampling",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionMaterial"),
            route: "DirectionMaterial",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionParasite"),
            route: "DirectionParasite",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionMicroBio"),
            route: "DirectionMicrobio",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionPeople"),
            route: "DirectionPeople",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
        {
            text: t("dashboard.listItem.directionCholera"),
            route: "DirectionCholera",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist", "Committee"],
        },
    ];

    directionItems = filterByRoles(directionItems);

    let dddItems = [
        {
            text: t("dashboard.listItem.detailDisinsection"),
            route: "DddDisinsection",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist"],
        },
        {
            text: t("dashboard.listItem.detailFinalDisinfection"),
            route: "DddFinalDisinfection",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist"],
        },
        {
            text: t("dashboard.listItem.receiptForThings"),
            route: "ReceiptForThings",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist"],
        },
        {
            text: t("dashboard.listItem.referenceDisinfection"),
            route: "ReferenceDisinfection",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Specialist"],
        },
    ];

    dddItems = filterByRoles(dddItems);

    let ksecItems = [
        {
            text: t("dashboard.listItem.prescription"),
            route: "Resolution",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
        {
            text: t("dashboard.listItem.regulation"),
            route: "Prescription",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator", "Administrator", "Committee", "Specialist", "CommitteeAdministrator"],
        },
    ];

    ksecItems = filterByRoles(ksecItems);

    let reportAndAnalyticsItems = [
        {
            text: t("dashboard.listItem.summary"),
            route: "requestTarificationsQuantity",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator",
            "Administrator",
            "AdministratorReadonly",
            ],
        },
        {
            text: t("dashboard.listItem.summaryIndices"),
            route: "requestTarificationsQuantityIndices",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator",
            "Administrator",
            "AdministratorReadonly",
            ],
        },
        {
            text: t("dashboard.listItem.summaryIndices2"),
            route: "requestTarificationsQuantityIndices2",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator",
            "Administrator",
            "AdministratorReadonly",
            ],
        },
        {
            text: t("dashboard.listItem.summaryIndices3"),
            route: "requestTarificationsQuantityIndices3",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator",
            "Administrator",
            "AdministratorReadonly",
            ],
        },
        {
            text: t("dashboard.listItem.summaryIndices4"),
            route: "requestTarificationsQuantityIndices4",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator",
            "Administrator",
            "AdministratorReadonly",
            ],
        },
        {
            text: t("dashboard.listItem.summaryDepartments"),
            route: "requestTarificationsQuantityDepartments",
            icon: <ExperimentOutlined/>,
            roles: ["SuperAdministrator",
            "Administrator",
            "AdministratorReadonly",
            ],
        },
    ];

    reportAndAnalyticsItems = filterByRoles(reportAndAnalyticsItems);

    let resultItems = [
        {
            text: t("dashboard.listItem.resultListAll"),
            route: "resultList",
            icon: <FolderCopy style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
                "Performer"
            ],
        },
        {
            text: t("dashboard.listItem.resultListPaid"),
            route: "resultListPaid",
            icon: <AutoStories style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
                "Performer"
            ],
        },
        {
            text: t("dashboard.listItem.resultListOR"),
            route: "resultListObjectReferral",
            icon: <AutoStories style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
                "Performer"
            ],
        }
    ]

    resultItems = filterByRoles(resultItems);

    let protocolItems = [
        {
            text: t("dashboard.listItem.protocolListAll"),
            route: "protocolList",
            icon: <FolderCopy style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
                "Performer",
                "Director",
                "Committee",
                "CommitteeAdministrator"
            ],
        },
        {
            text: t("dashboard.listItem.protocolListPaid"),
            route: "protocolListPaid",
            icon: <FindInPage style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
                "Performer",
                "Director"
            ],
        },
        {
            text: t("dashboard.listItem.protocolListOR"),
            route: "protocolListObjectReferral",
            icon: <FindInPage style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
                "Performer",
                "Director",
                "CommitteeAdministrator"
            ],
        },
    ]

    protocolItems = filterByRoles(protocolItems);

    let items = [
        {
            text: t("dashboard.listItem.tariffication"),
            route: "tariffication",
            roles: ["SuperAdministrator", "Administrator", "Tarificator", "Director"],
            icon: <AccountBalanceWallet style={style}/>,
        },
        {
            text: t("dashboard.listItem.plan"),
            route: "planList",
            roles: ["SuperAdministrator", "Administrator", "Tarificator"],
            icon: <AssignmentIcon style={style}/>,
        },
        // {
        //     text: t("dashboard.listItem.resultList"),
        //     route: "resultList",
        //     icon: <AutoStories style={style}/>,
        //     roles: [
        //         "SuperAdministrator",
        //         "Administrator",
        //         "Specialist",
        //         "Supervisor",
        //         "Performer"
        //     ],
        // },

        // {
        //     text: t("dashboard.listItem.resultListOR"),
        //     route: "resultListObjectReferral",
        //     icon: <AutoStories style={style}/>,
        //     roles: [
        //         "SuperAdministrator",
        //         "Administrator",
        //         "Specialist",
        //         "Supervisor",
        //         "Performer"
        //     ],
        // },

        // {
        //     text: t("dashboard.listItem.protocolList"),
        //     route: "protocolList",
        //     icon: <FindInPage style={style}/>,
        //     roles: [
        //         "SuperAdministrator",
        //         "Administrator",
        //         "Specialist",
        //         "Supervisor",
        //         "Performer"
        //     ],
        // },
        // {
        //     text: t("dashboard.listItem.protocolListOR"),
        //     route: "protocolListObjectReferral",
        //     icon: <FindInPage style={style}/>,
        //     roles: [
        //         "SuperAdministrator",
        //         "Administrator",
        //         "Specialist",
        //         "Supervisor",
        //         "Performer"
        //     ],
        // },
        {
            text: t("dashboard.listItem.patientList"),
            route: "patientList",
            icon: <TeamOutlined style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
            ],
        },

        {
            text: t("dashboard.listItem.serviceExpenseList"),
            route: "serviceExpenseList",
            icon: <DollarOutlined style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
            ],
        },

        {
            text: t("dashboard.listItem.reagentList"),
            route: "reagentList",
            icon: <ExperimentOutlined style={style}/>,
            roles: [
                "SuperAdministrator",
                "Administrator",
                "Specialist",
                "Supervisor",
            ],
        },
        {
            text: t("dashboard.listItem.exit"),
            route: "exit",
            icon: <LogoutOutlined style={style}/>,
            authAction: logout,
        },
    ];
    items = filterByRoles(items);
    const isSupervisor = roles?.includes('Supervisor');
    return (
        <Paper style={{overflow: "hidden", overflowX: "hidden"}}>
            {itemsFirst.map((el, idxx) => {
                if (isSupervisor && el.route === 'referral') {
                    return (
                        <>
                            <ListItemDropDown
                                icon={<MedicalServices style={style}/>}
                                open={openListReferal}
                                pathname={pathname}
                                text={t("dashboard.listItem.referralList")}
                                items={itemsReferral}
                                type="REFERAL"
                            />
                            <Divider/>
                        </>
                    )
                }
                if (isSupervisor && el.route === 'object-referral') {
                    return (
                        <>
                            <ListItemDropDown
                                icon={<MedicalServices style={style}/>}
                                open={openListObjectReferal}
                                pathname={pathname}
                                text={t("dashboard.listItem.objectReferralList")}
                                items={items0bjectReferral}
                                type="OBJECT_REFERAL"
                            />
                            <Divider/>
                        </>
                    )
                }
                return (
                    <ListItem
                        onClick={() => {
                            history.push("/" + el.route);
                        }}
                        key={idxx}
                        button
                        selected={pathname.split("/")[1] === el.route}
                    >
                        <ListItemIcon>{el.icon}</ListItemIcon>
                        <ListItemText primary={el.text}/>
                    </ListItem>
                )
            })}
            {itemsSecond.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        icon={<WorkOutline/>}
                        open={openListRequests}
                        pathname={pathname}
                        text={t("dashboard.requests")}
                        items={itemsSecond}
                        type="REQUEST"
                    />
                </>
            )}
            {itemsThird.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        icon={<Feed/>}
                        open={openListActs}
                        pathname={pathname}
                        text={t("dashboard.acts")}
                        items={itemsThird}
                        type="ACT"
                    />
                </>
            )}
            {itemsThirdDSEK.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        icon={<Feed/>}
                        open={openListActsDSEK}
                        pathname={pathname}
                        text={t("dashboard.actsDSEK")}
                        items={itemsThirdDSEK}
                        type="ACT_DSEK"
                    />
                </>
            )}
            {directionItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<ContentPaste/>}
                        open={openListDirections}
                        pathname={pathname}
                        text={t("dashboard.directions")}
                        items={directionItems}
                        type="DIRECTION"
                    />
                </>
            )}

            {dddItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<AddModeratorOutlined/>}
                        open={openListDDD}
                        pathname={pathname}
                        text={t("dashboard.ddd")}
                        items={dddItems}
                        type="DDD"
                    />
                </>
            )}
            {reportAndAnalyticsItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<AccountBalance/>}
                        open={openListReportAndAnalytics}
                        pathname={pathname}
                        text={t("dashboard.reportAndAnalytics")}
                        items={reportAndAnalyticsItems}
                        type="REPORT_AND_ANALYTICS"
                    />
                </>
            )}
            {ksecItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<AccountBalance/>}
                        open={openListKsec}
                        pathname={pathname}
                        text={t("dashboard.ksec")}
                        items={ksecItems}
                        type="KSEC"
                    />
                </>
            )}
            {itemsOfDictionaries.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<MenuOpen/>}
                        open={openListDictionaries}
                        pathname={pathname}
                        text={t("dashboard.dictionaries")}
                        items={itemsOfDictionaries}
                        type="DICTIONARIES"
                    />
                </>
            )}
            {administrationItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<Build/>}
                        open={openAdministration}
                        pathname={pathname}
                        text={t("dashboard.administration")}
                        items={administrationItems}
                        type="ADMINISTRATION"
                    />
                </>
            )}
            {resultItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<AutoStories/>}
                        open={openListResult}
                        pathname={pathname}
                        text={t("dashboard.listItem.resultList")}
                        items={resultItems}
                        type="RESULT"
                    />
                </>
            )}
            {protocolItems.length > 0 && (
                <>
                    <Divider/>
                    <ListItemDropDown
                        scroll={true}
                        icon={<FindInPage/>}
                        open={openListProtocol}
                        pathname={pathname}
                        text={t("dashboard.listItem.protocolList")}
                        items={protocolItems}
                        type="PROTOCOL"
                    />
                </>
            )}
            {items.map((el, idx) => (
                <ListItem
                    onClick={() => {
                        if (!el.authAction) {
                            if (el.route === "exit") {
                                history.push(LOGIN_ROUTE);
                            } else {
                                history.push("/" + el.route);
                            }
                        } else {
                            dispatch(el.authAction()).then((link) => {
                                history.push(link);
                            });
                        }
                    }}
                    key={idx}
                    button
                    selected={pathname.split("/")[1] === el.route}
                >
                    <ListItemIcon>{el.icon}</ListItemIcon>
                    <ListItemText primary={el.text}/>
                </ListItem>
            ))}
        </Paper>
    );
};

export default MenuListItems;
