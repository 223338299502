import React, {useState} from "react";

import {employeesCRUD} from "../../../http/CRUD";
import CRUDTable from "../CRUDTable";
import {EMPLOYEE_DETAIL_ROUTE, FILIAL_DETAIL_ROUTE, REFERRAL_DETAIL_ROUTE} from "../../../routes";
import {useHistory} from "react-router-dom";
import {getReferralStates} from "../../../redux/actions/referralStateActions";
import {getDepartments, getFilials} from "../../../redux/actions";
import {getFilialDivisions} from "../../../redux/actions/filialDivisionActions";
import { mapOrganizationName, mapOrganizationValues } from '../../../functions/viewDataMap';


export default function EmployeeCRUDTable({withOrganization, ...props}) {
    const {handleChangeFilterCrud} = props
    const [organization, setOrganization] = useState(null);
    const [filial, setFilial] = useState(null);
    const {push} = useHistory()
    const columns = [
        {title: "#", dataIndex: 'id', key: 'id'},
        {title: "Полное имя", dataIndex: 'fullName', key: 'fullName'},
        {
            title: "Должность",
            key: 'position',
            render: (unknown, row) => row.positionId ? row.position?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Организация",
            key: 'organizationId',
            render: (unknown, row) => row.organizationId ? mapOrganizationName(row.organizationId) :
                <span style={{color: "gray"}}>Не задан</span>,
            hidden: !withOrganization
        },
        {
            title: "Филиал",
            key: 'filial',
            render: (unknown, row) => row.filialId ? row.filial?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "ИИН",
            key: 'identityNumber',
            render: (unknown, row) => row.identityNumber ? row.identityNumber :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Районное отделение",
            key: 'filialDivision',
            render: (unknown, row) => row.filialDivisionId ? row.filialDivision?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Лаборатория",
            key: 'department',
            render: (unknown, row) => row.departmentId ? row.department?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
        {
            title: "Отдел лаборатории",
            key: 'division',
            render: (unknown, row) => row.divisionId ? row.division?.name :
                <span style={{color: "gray"}}>Не задан</span>
        },
    ]
    const searchFields = [
        {
            title: "ФИО работника",
            key: "fullName",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        },
        {
            title: "ИИН",
            key: "identityNumber",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Организация",
            key: "organizationId",
            compareType: "equals",
            reducerKey: "organization",
            options: mapOrganizationValues(),         
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            filter: (opt) => {
                let result = true;

                if (opt.id == null) {
                return true;
                }

                if(organization) {
                    return opt.organizationId === organization;
                }
                return result;
            },
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            filter: (opt) => {
                let result = true;

                if (opt.id == null) {
                return true;
                }

                if(filial && organization) {
                    return opt.filialId === filial && opt.organizationId === organization;
                }

                if(organization) {
                    return opt.organizationId === organization;
                }

                if(filial) {
                    return opt.filialId === filial;
                }
                return result;
            },
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ]

    return (
        <CRUDTable search={employeesCRUD.search}
                   columns={columns} {...props}
                   onClickCreateButton={() => push(EMPLOYEE_DETAIL_ROUTE("new"))}
                   searchBy={"fullName"}
                   searchByOperator={"like"}
                   searchPlaceholder={"Введите ФИО"}
                   searchFields={searchFields}
                   filterFields={filterFields}
                   rowClassNameHandler={(record) => {
                       if (record?.isDeleted) {
                           return 'banned'
                       }
                   }}
                   sendFilterValues={async (name, value) => {
                    if (name === 'organization' && value) {
                        setOrganization(value);
                    }
                    if (name === 'filial' && value) {
                        setFilial(value);
                    }
                    }}
                   {...props}
        />
    );
}
