import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useDialogs from "../../components/Dialog/useDialogs";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import CheckboxField from "../../components/Fields/CheckboxField";
import { TextField2 } from "../../components/Fields/TextField2";
import { TextPhoneField } from "../../components/Fields/TextPhoneField";
import { DateField } from "../../components/Fields/DateField";
import { DateTimeField } from "../../components/Fields/DateTimeField";
import { NumberField } from "../../components/Fields/NumberField";
import { GridItem } from "../../components/Fields/GridItem";
import { FilialSelect } from "../../components/Select/domainSelects";
import { changeData, changeDataMany } from "../../functions/changeHandlers";
import { addOrganizationFilialMap } from "../../redux/actions/organizationFilialMapActions";
import { ORGANIZATION_FILIAL_MAP_LIST_ROUTE } from "../../routes";
import { submitData } from "../../functions/submitHandlers";

export const CreateOrganizationFilialMap = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const handleChange = useCallback((key, value) => changeData(data, setData, key, value), [data]);
    const handleChangeMany = useCallback((obj) => changeDataMany(data, setData, obj), [data]);

    const {alert, DIALOG} = useDialogs();

    const handleSubmit = async () => {
        if (await submitData(data, async () =>
        {
            return true;
        }, setLoading, async () =>
        {
            return await dispatch(addOrganizationFilialMap(data));
        }))
        {
            return push(ORGANIZATION_FILIAL_MAP_LIST_ROUTE);
        }
    };

    return (
        <DashboardLayout>
            <>
                <DIALOG/>
                <PageHeader heading="Создание новой записи">
                    <Button variant={"text"} onClick={() => push(ORGANIZATION_FILIAL_MAP_LIST_ROUTE)}>
                        Вернуться
                    </Button>
                </PageHeader>
                <PageContainer>
                    <FormSection>
                        <Grid container>
                            <GridItem xs={6}>
                                <FilialSelect
                                    label={"Филиал отобразить c"}
                                    fullWidth
                                    value={data.sourceFilialId || ""}
                                    onChange={(value, obj) => {
                                        handleChangeMany({ sourceFilial: obj, sourceFilialId: value })
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <FilialSelect
                                    label={"Филиал отобразить на"}
                                    fullWidth
                                    value={data.targetFilialId || ""}
                                    onChange={(value, obj) => {
                                        handleChangeMany({ targetFilial: obj, targetFilialId: value })
                                    }}
                                />
                            </GridItem>
                        </Grid>
                    </FormSection>
                </PageContainer>
                <PageFooter>
                    <LoadingButton loading={loading} onClick={handleSubmit}>
                        Создать запись
                    </LoadingButton>
                </PageFooter>
            </>
        </DashboardLayout>
    )
};
