import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getGeoObjects } from "../../../redux/actions/geoObjectActions";
import { getEmployees } from "../../../redux/actions/employeeActions";
import { getBrigadeTypes } from "../../../redux/actions/brigadeTypeActions";
import {
  BasicModalContainer,
  BasicModalHeader,
  Form,
} from "../../../components";
import EmployeeField from "../../../components/Relations/EmployeeField";

export const BrigadeCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [number, setNumber] = useState("");
  const [createdAt, setCreatedAt] = useState(new Date());
  const [addressLine, setAddressLine] = useState("");
  const [addressId, setAddressId] = useState(null);
  const [disinfector, setDisinfector] = useState({});
  const [disinfectorId, setDisinfectorId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const { brigades, brigade } = useSelector((s) => s.brigade);

  const { brigadeTypes, brigadeTypeRequestedCount } = useSelector(
    (s) => s.brigadeType
  );

  const handleSubmit = async (e) => {
    setLoading(true);

    const data = {
      addressLine,
      addressId,
      disinfectorId,
      typeId,
    };

    await onSubmit(e, data);

    setLoading(false);
  };

  // ---

  const emptyState = () => {
    setNumber("");
    setCreatedAt(new Date());
    setAddressLine("");
    setAddressId(null);
    setDisinfectorId(null);
    setTypeId(null);
  };

  useEffect(() => {
    const { number, createdAt, addressLine, addressId, disinfectorId, typeId } =
      brigade || {};
    setNumber(number);
    setCreatedAt(createdAt);
    setAddressLine(addressLine);
    setAddressId(addressId);
    setDisinfectorId(disinfectorId);
    setTypeId(typeId);
  }, [brigade, brigades]);

  useEffect(() => {
    let response;
    const getBrigadeTypesAsync = async () => {
      response = await dispatch(
        getBrigadeTypes({ paging: { skip: 0, take: 10 } })
      );
    };
    if (brigadeTypeRequestedCount == 0) {
      getBrigadeTypesAsync();
    }
  }, []);
  return (
    <>
      <BasicModalContainer>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                value={addressLine}
                onChange={(e) => setAddressLine(e.target.value)}
                fullWidth
                id="addressLine"
                name="addressLine"
                label="Адрес"
              />
            </Grid>
            {/*<Grid item xs={6}>*/}
            {/*  <TextField*/}
            {/*    value={addressId}*/}
            {/*    onChange={(e) => setAddressId(e.target.value)}*/}
            {/*    margin="normal"*/}
            {/*    fullWidth*/}
            {/*    id="addressId"*/}
            {/*    select*/}
            {/*  >*/}
            {/*    {geoObjects &&*/}
            {/*      geoObjects.map((_) => (*/}
            {/*        <MenuItem key={_.id} value={_.id}>*/}
            {/*          {_.name}*/}
            {/*        </MenuItem>*/}
            {/*      ))}*/}
            {/*  </TextField>*/}
            {/*</Grid>*/}
            <Grid item xs={6}>
              <EmployeeField
                  label={"Дезинфектор"}
                  object={disinfector}
                  value={disinfectorId}
                  onChange={e => {
                    setDisinfector(e)
                    setDisinfectorId(e.id)
                  }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={typeId}
                onChange={(e) => setTypeId(e.target.value)}
                margin="normal"
                label="Тип бригады"
                fullWidth
                id="typeId"
                select
              >
                {brigadeTypes &&
                  brigadeTypes.map((_) => (
                    <MenuItem key={_.id} value={_.id}>
                      {_.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
              <LoadingButton loading={loading} onClick={handleSubmit}>
                Создать
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </BasicModalContainer>
    </>
  );
};
