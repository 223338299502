import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import ObjectField from "../../../components/Relations/ObjectField";
import {
    PurposeOfSelectionsSelect,
    StorageConditionSelect,
    TransportationConditionSelect
} from "../../../components/Select/domainSelects";
import GovernmentField from "../../../components/Relations/GovernmentField";
import AnObjectField from "../../../components/Relations/AnObjectField";

const ActLandForm = ({ isDisabledData, data, handleChangeData, handleChangeSample, addSample, deleteSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
          <AnObjectField
              bold={false}
              label={"1. Объектінің атауы (Наименование объекта)"}
              value={data.data?.object?.value}
              disabled={isDisabledData}
              onChange={(obj) => {
                  handleChangeData('object', obj, 'facility')
              }}
          />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Тексеру белгіленгені туралы актінің нөмірі  (Номер акта о назначении проверки)
        </p>
        <TextField
          id="outlined-basic"
          label="Номер акта"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          disabled={isDisabledData}
          variant="outlined"
          type="number"
          value={data.data?.number?.value}
          onChange={(e) => handleChangeData('number', e.target.value, 'number')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Тағайындау актісі күні (Дата акта о назначении проверки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата акта"
            disabled={isDisabledData}
            value={data.data?.date?.value}
            onChange={(value) => {
              handleChangeData('date', value, 'date')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          4. Сынама алынған орын (Место отбора)
        </p>
        <TextField
          id="outlined-basic"
          label="Место отбора"
          disabled={isDisabledData}
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          value={data.data?.selectionPlace?.value}
          onChange={(e) => handleChangeData('selectionPlace', e.target.value, 'selection_place')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5. Сынамалар алу мақсаты (Цель отбора):
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <PurposeOfSelectionsSelect
            label={"Сынамалар алу мақсаты (Цель отбора)"}
            fullWidth
            value={data.data?.samplingPurpose?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('samplingPurpose', obj, 'samplingPurpose') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5.1 Наименования нормативных документов
        </p>
        <TextField
          id="outlined-basic"
          label="Наименования нормативных документов"
          style={{ width: "50%",  margin: "10px 0 10px 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.documentsName?.value}
          onChange={(e) =>
            handleChangeData(
              "documentsName",
              e.target.value,
              "documentsName"
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Алынған күні мен уақыты (Дата и время отбора)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время отбора"
            disabled={isDisabledData}
            value={data.data?.selectionDateTime?.value}
            onChange={(value) => handleChangeData('selectionDateTime', value, 'selection_time')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Жеткізілген күні мен уақыты (Дата и время доставки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время доставки"
            disabled={isDisabledData}
            value={data.data?.deliveryDateTime?.value}
            onChange={(value) => handleChangeData('deliveryDateTime', value, 'delivery_time')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на
          метод отбора)
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
            <GovernmentField
                fullWidth
                disabled={isDisabledData}
                label={null}
                array={data.data?.standard?.value}
                onChange={(val) => { handleChangeData('standard', val, 'nd_selection') }}
            />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Тасымалдау жағдайы (Условия транспортировки)
        </p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <TransportationConditionSelect
            label={"Условия транспортировки"}
            fullWidth
            value={data.data?.transportationCondition?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('transportationCondition', obj, 'transportation_conditions') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>10. Сақтау жағдайы (Условия хранения)</p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <StorageConditionSelect
            label={"Условия хранения"}
            fullWidth
            value={data.data?.storageCondition?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('storageCondition', obj, 'storage_conditions') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          11. Қосымша мәліметтер (Дополнительные сведения)
        </p>
        <TextField
          disabled={isDisabledData}
          id="outlined-basic"
          label="Дополнительные сведения"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          value={data.data?.additionalInfo?.value}
          onChange={(e) => handleChangeData('additionalInfo', e.target.value, 'additionalInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          12. ФИО присутствующего при отборе
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО присутствующего при отборе"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.representativeFullName?.value}
          onChange={(e) => handleChangeData('representativeFullName', e.target.value, 'representativeFullName')}
        />
      </div>
      <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
        <Table
          aria-label="simple table"
          className="border-table-word"
        >
          <TableHead>
            <TableRow>
              <TableCell className="border-table-word">
                Тiркелу нөмірi (Регистрационный номер)
              </TableCell>
              <TableCell className="border-table-word">
                Алынған үлгілер тізімі (Перечень отобранных образцов)
              </TableCell>
              <TableCell className="border-table-word">
                Мөлшері, (грамм) Количество (грамм)
              </TableCell>
              <TableCell className="border-table-word">
                Алу тереңдігі (сантиметр) Глубина отбора (см)
              </TableCell>
              <TableCell className="border-table-word">
                Орама түрі мен пломба нөмірі (Вид упаковки и номер
                печати(пломбы))
              </TableCell>
              <TableCell className="border-table-word">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.samples?.value?.map((sample, i) => {
              return <TableRow key={i}>
                <TableCell className="border-table-word">
                  <TextField
                    disabled={isDisabledData}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Регистрационный номер"
                    variant="outlined"
                    type="number"
                    value={sample.registrationNumber?.value}
                    onChange={(e) => handleChangeSample('registrationNumber', e.target.value, i, 'registrationNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    disabled={isDisabledData}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Перечень отобранных образцов"
                    variant="outlined"
                    value={sample.sample?.value}
                    onChange={(e) => handleChangeSample('sample', e.target.value, i, 'sample')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    disabled={isDisabledData}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Количество"
                    variant="outlined"
                    value={sample.quantity?.value}
                    type="number"
                    onChange={(e) => handleChangeSample('quantity', e.target.value, i, 'quantity')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">(гр)</InputAdornment>,
                    }}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    disabled={isDisabledData}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Глубина отбора"
                    variant="outlined"
                    type="number"
                    value={sample.samplingDepth?.value}
                    onChange={(e) => handleChangeSample('samplingDepth', e.target.value, i, 'samplingDepth')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">(см)</InputAdornment>,
                    }}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    disabled={isDisabledData}
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Вид упаковки и номер печати"
                    variant="outlined"
                    value={sample.sealNumber?.value}
                    onChange={(e) => handleChangeSample('sealNumber', e.target.value, i, 'sealNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <IconButton color="error"
                      size="large"
                      aria-label="delete"
                      disabled={isDisabledData}
                      onClick={() => { deleteSample(i) }}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isDisabledData}
          variant="contained"
          onClick={addSample}
        >
          Добавить
        </Button>
      </div>
    </div >
  );
};

export default ActLandForm;
