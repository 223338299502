import { $authHost } from "../../http";
import {
    PROTOCOL_FORM_GET_REQUEST, PROTOCOL_FORM_GET_SUCCESS, PROTOCOL_FORM_GET_ERROR,
    PROTOCOL_FORM_GET_BY_KEY_REQUEST, PROTOCOL_FORM_GET_BY_KEY_SUCCESS, PROTOCOL_FORM_GET_BY_KEY_ERROR,
    PROTOCOL_FORM_ADD_REQUEST, PROTOCOL_FORM_ADD_SUCCESS, PROTOCOL_FORM_ADD_ERROR,
    PROTOCOL_FORM_EDIT_REQUEST, PROTOCOL_FORM_EDIT_SUCCESS, PROTOCOL_FORM_EDIT_ERROR,
    PROTOCOL_FORM_DELETE_REQUEST, PROTOCOL_FORM_DELETE_SUCCESS, PROTOCOL_FORM_DELETE_ERROR
} from "../constants/protocolFormConstants";

export const getProtocolForms = (args) => async (dispatch) => {
    try {
        dispatch({
            type: PROTOCOL_FORM_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/protocolForm/query",
            args || {},
            config,
        );
        dispatch({
            type: PROTOCOL_FORM_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: PROTOCOL_FORM_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getProtocolFormByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PROTOCOL_FORM_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/protocolForm/${id}`,
        );
        dispatch({
            type: PROTOCOL_FORM_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: PROTOCOL_FORM_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addProtocolForm = ({ number, createdAt, schema, filialId, departmentId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: PROTOCOL_FORM_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/protocolForm",
            { number, createdAt, schema, filialId, departmentId },
            config,
        );
        dispatch(getProtocolForms(queryArgs || {}));
        dispatch({
            type: PROTOCOL_FORM_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PROTOCOL_FORM_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editProtocolForm = ({ id, number, createdAt, schema, filialId, departmentId }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: PROTOCOL_FORM_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/protocolForm",
            { id, number, createdAt, schema, filialId, departmentId },
            config,
        );
        dispatch(getProtocolForms(queryArgs || {}));
        dispatch({
            type: PROTOCOL_FORM_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PROTOCOL_FORM_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteProtocolForm = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: PROTOCOL_FORM_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/protocolForm",
            { data: { id } },
            config,
        );
        dispatch(getProtocolForms(queryArgs || {}));
        dispatch({
            type: PROTOCOL_FORM_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: PROTOCOL_FORM_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};
