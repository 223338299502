import t_06_2204 from '././kulsarinskoe/KZ.T.06.2204.svg'
import t_06_2204_b from '././kulsarinskoe/KZ.T.06.2204 black.svg'

import t_06_2257 from '././atyrau/KZ.M.06.2257.svg'
import t_06_2257_b from '././atyrau/KZ.M.06.2257 black.svg'

import t06_e1246 from '././atyrau/KZ.T.06.Е1246.svg'
import t06_e1246_b from '././atyrau/KZ.T.06.Е1246 black.svg'

export default [
  t_06_2204,
  t_06_2204_b,
  t_06_2257,
  t_06_2257_b,
  t06_e1246,
  t06_e1246_b
]
