import React, { useMemo } from "react";
import { TextField } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/lab";

export const CustomTimePicker = ({
  value,
  maxValue,
  onChange,
  label,
  required,
  disabled,
  fullWidth,
}) => {
  return useMemo(() => {
    const defaultOnChange = (e) => {};
    return (
      <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
        <TimePicker
          label={label}
          value={value}
          disabled={disabled}
          required={required}
          onChange={onChange || defaultOnChange}
          renderInput={(params) => (
            <TextField
              margin="normal"
              disabled={disabled}
              fullWidth={fullWidth}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  }, [label, value, maxValue, onChange, fullWidth]);
};

CustomTimePicker.defaultProps = {
  required: true,
  disabled: false,
  fullWidth: true,
};
