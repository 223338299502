import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { EnhancedCRUDTable } from '..';
import { checkRoles } from "../../../functions/checkRoles";
import { formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS } from "../../../functions/formatters";
import { brigadeCRUD } from '../../../http';
import CRUDTable from "../CRUDTable";
import {brigadeFilterFields, brigadeSearchFields} from "../../../sections/Filters/BrigadeFilters";

export default function DisinsectionCRUDTable({ ...props }) {
  const { t } = useTranslation();
  const { push } = useHistory()
  const { pathname } = useLocation()
  const columns = [
    { title: "№", dataIndex: 'id', key: 'id' },
    { title: "Создано", dataIndex: 'createdAt', key: 'createdAt', render: row => formatDate(row, MOMENT_FORMAT_DATE_DMYHM_DOTS) },
    { title: "Номер", dataIndex: 'number', key: 'number' },
    {
      title: "Наименование объекта", dataIndex: 'object', key: 'object', render: (unknown, row) => {
        const data = typeof row.data === 'string' ? JSON.parse(row.data) : {}
        return data?.object?.value?.name ? data.object.value?.name : <span style={{ color: "gray" }}>Не задан</span>
      }
    },
    { title: "Адрес", dataIndex: 'addressLine', key: 'addressLine', render: (unknown, row) => {
      return row.addressLine ? row.addressLine : <span style={{ color: "gray" }}>Не задан</span>
    } },
    { title: "Вид насекомого", dataIndex: 'insectType', key: 'insectType', render: (unknown, row) => {
      const data = typeof row.data === 'string' ? JSON.parse(row.data) : {}
      return data?.insectType?.value ? data.insectType.value : <span style={{ color: "gray" }}>Не задан</span>
    } },
    { title: "ФИО выполнившего дезинфекцию", dataIndex: 'disinfector', key: 'disinfector', render: (unknown, row) => row.disinfector ? row.disinfector.fullName : <span style={{ color: "gray" }}>Не задан</span> },
    { title: "Статус", dataIndex: 'test', key: 'test', render: (unknown, row) => row.state ? row.state.name : <span style={{ color: "gray" }}>Не задан</span> },
  ];

  return <CRUDTable
    search={brigadeCRUD.search}
    columns={columns}
    searchFields={brigadeSearchFields}
    filterFields={brigadeFilterFields}
    onClickEditButton={(id) => { push(`${pathname}Detail/${id}`) }}
    onClickCreateButton={checkRoles('SuperAdministrator', 'Administrator', 'Specialist') ? () => push(`${pathname}Detail/new`) : null}
    {...props}
  />
}
