export const BRIGADE_GET_REQUEST = 'BRIGADE_GET_REQUEST';
export const BRIGADE_GET_SUCCESS = 'BRIGADE_GET_SUCCESS';
export const BRIGADE_GET_ERROR = 'BRIGADE_GET_ERROR';
export const BRIGADE_GET_BY_KEY_REQUEST = 'BRIGADE_GET_BY_KEY_REQUEST';
export const BRIGADE_GET_BY_KEY_SUCCESS = 'BRIGADE_GET_BY_KEY_SUCCESS';
export const BRIGADE_GET_BY_KEY_ERROR = 'BRIGADE_GET_BY_KEY_ERROR';
export const BRIGADE_ADD_REQUEST = 'BRIGADE_ADD_REQUEST';
export const BRIGADE_ADD_SUCCESS = 'BRIGADE_ADD_SUCCESS';
export const BRIGADE_ADD_ERROR = 'BRIGADE_ADD_ERROR';
export const BRIGADE_EDIT_REQUEST = 'BRIGADE_EDIT_REQUEST';
export const BRIGADE_EDIT_SUCCESS = 'BRIGADE_EDIT_SUCCESS';
export const BRIGADE_EDIT_ERROR = 'BRIGADE_EDIT_ERROR';
export const BRIGADE_DELETE_REQUEST = 'BRIGADE_DELETE_REQUEST';
export const BRIGADE_DELETE_SUCCESS = 'BRIGADE_DELETE_SUCCESS';
export const BRIGADE_DELETE_ERROR = 'BRIGADE_DELETE_ERROR';
