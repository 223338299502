import { 
    USER_TYPE_GET_REQUEST, USER_TYPE_GET_SUCCESS, USER_TYPE_GET_ERROR, 
    USER_TYPE_GET_BY_KEY_REQUEST, USER_TYPE_GET_BY_KEY_SUCCESS, USER_TYPE_GET_BY_KEY_ERROR, 
    USER_TYPE_ADD_REQUEST, USER_TYPE_ADD_SUCCESS, USER_TYPE_ADD_ERROR, 
    USER_TYPE_EDIT_REQUEST, USER_TYPE_EDIT_SUCCESS, USER_TYPE_EDIT_ERROR, 
    USER_TYPE_DELETE_REQUEST, USER_TYPE_DELETE_SUCCESS, USER_TYPE_DELETE_ERROR
} from "../constants/userTypeConstants";

const initialState = {
    userTypes: [],
    userType: {},
    loading: false,
    success: null,
    error: null,
    userTypeRequestedCount: 0,
};

export const userTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case USER_TYPE_GET_REQUEST:
            return {
                ...state,
                userTypeRequestedCount: ++state.userTypeRequestedCount,
                loading: true,
            };
        case USER_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                userTypes: action.payload,
                success: true,
            };
        case USER_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_TYPE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_TYPE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                userType: action.payload,
                success: true,
            };
        case USER_TYPE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_TYPE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_TYPE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_TYPE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_TYPE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_TYPE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case USER_TYPE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case USER_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case USER_TYPE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
