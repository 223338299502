import React from "react";
import {
  IconButton,
  Table,
  Paper,
  TableHead,
  TableRow,
  Avatar,
  TableBody,
  Box,
  TableContainer,
} from "@mui/material";
import { stringAvatar } from "../../functions";
import { StyledTableCell, StyledTableRow } from "../TableMUIStyle";

const TableCustom = ({ rowName, rowName2, rowName3, rowName4, rows, actions, avatars, onRowClick }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} size="big" aria-label="a dense table">
        <TableHead>
          <StyledTableRow>
            {rowName.map((el, idx) => {
              return (
                <StyledTableCell
                  // colSpan={
                  //   el.text === "Первичное заражение"
                  //     ? 3
                  //     : el.text === "Первый пассаж"
                  //     ? 3
                  //     : el.text === "Второй пассаж"
                  //     ? 3
                  //     : el.text === "Адаптация"
                  //     ? 3
                  //     : null
                  // }
                  key={idx}
                  align={idx !== 0 ? "center" : "left"}
                  style={{ borderRight: "1px solid gray" }}
                >
                  {el.text}
                </StyledTableCell>
              );
              // if (el.text === "") {
              //   return null;
              // } else {

              // }
            })}
          </StyledTableRow>
          {rowName2 && rowName2.length > 0 && (
            <StyledTableRow>
              {rowName2.map((el, idx) => {
                return (
                  <StyledTableCell
                    key={idx}
                    align={idx !== 0 ? "center" : "left"}
                    style={{
                      borderRight: "1px solid gray",
                      borderLeft: "1px solid gray",
                    }}
                  >
                    {el.text}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          )}
          {rowName3 && rowName3.length > 0 && (
            <StyledTableRow>
              {rowName3.map((el, idx) => {
                return (
                  <StyledTableCell
                    key={idx}
                    align={idx !== 0 ? "center" : "left"}
                    style={{
                      borderRight: "1px solid gray",
                      borderLeft: "1px solid gray",
                    }}
                  >
                    {el.text}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          )}
          {rowName4 && rowName4.length > 0 && (
            <StyledTableRow>
              {rowName4.map((el, idx) => {
                return (
                  <StyledTableCell
                    key={idx}
                    align={idx !== 0 ? "center" : "left"}
                    style={{
                      borderRight: "1px solid gray",
                      borderLeft: "1px solid gray",
                    }}
                  >
                    {el.text}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          )}
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.id}
              hover
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
              }}
              onClick={() => onRowClick?.(row)}
            >
              {Object.keys(row).map((rowChild, idx) => {
                if (idx === 0) {
                  return (
                    <StyledTableCell key={idx} component="th" scope="row">
                      <Box>
                        {avatars && (
                          <IconButton>
                            <Avatar
                              {...stringAvatar(
                                row[rowChild].split(" ")[0] +
                                  " " +
                                  row[rowChild].split(" ")[1]
                              )}
                            />
                          </IconButton>
                        )}
                        {row[rowChild]}
                      </Box>
                    </StyledTableCell>
                  );
                }

                let value = row[rowChild];
                if (typeof value === 'object') {
                  value = false;
                }
                if(value){
                  return (
                      <StyledTableCell key={idx} align="center">
                        {value}
                      </StyledTableCell>
                  );
                } else {
                  return (
                    <StyledTableCell key={idx} align="center">
                    </StyledTableCell>
                );
                }
              })}
              <StyledTableCell align="center" minWidth={"150px"}>
                <Box className="table-actions">
                  {actions && actions(row).map((el, idx) => (
                    <IconButton key={idx} disabled={el.props?.disabled}>{el}</IconButton>
                  ))}
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCustom;
