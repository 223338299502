import { 
    STORAGE_CONDITION_GET_REQUEST, STORAGE_CONDITION_GET_SUCCESS, STORAGE_CONDITION_GET_ERROR, 
    STORAGE_CONDITION_GET_BY_KEY_REQUEST, STORAGE_CONDITION_GET_BY_KEY_SUCCESS, STORAGE_CONDITION_GET_BY_KEY_ERROR, 
    STORAGE_CONDITION_ADD_REQUEST, STORAGE_CONDITION_ADD_SUCCESS, STORAGE_CONDITION_ADD_ERROR, 
    STORAGE_CONDITION_EDIT_REQUEST, STORAGE_CONDITION_EDIT_SUCCESS, STORAGE_CONDITION_EDIT_ERROR, 
    STORAGE_CONDITION_DELETE_REQUEST, STORAGE_CONDITION_DELETE_SUCCESS, STORAGE_CONDITION_DELETE_ERROR
} from "../constants/storageConditionConstants";

const initialState = {
    storageConditions: [],
    storageCondition: {},
    loading: false,
    success: null,
    error: null,
    storageConditionRequestedCount: 0,
};

export const storageConditionReducer = (state = initialState, action) => {
    switch (action.type)     {
        case STORAGE_CONDITION_GET_REQUEST:
            return {
                ...state,
                storageConditionRequestedCount: ++state.storageConditionRequestedCount,
                loading: true,
            };
        case STORAGE_CONDITION_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                storageConditions: action.payload,
                success: true,
            };
        case STORAGE_CONDITION_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case STORAGE_CONDITION_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case STORAGE_CONDITION_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                storageCondition: action.payload,
                success: true,
            };
        case STORAGE_CONDITION_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case STORAGE_CONDITION_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case STORAGE_CONDITION_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case STORAGE_CONDITION_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case STORAGE_CONDITION_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case STORAGE_CONDITION_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case STORAGE_CONDITION_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case STORAGE_CONDITION_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case STORAGE_CONDITION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case STORAGE_CONDITION_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
