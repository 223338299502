import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

DirectionVirusPDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data
  const schema = [
    {
      key: '1. Медициналық ұйымның атауы (Наименование медицинской организации)',
      value: actData?.organizationName?.value
    },
    {
      key: '2. Жолданған материал: аңқадан, мұрыннан алынған жағынды, жұлын сұйықтығы, қан, нәжіс-вирусқа зерттеу үшін, астын сызыңыз немесе толықтырыңыз (Направляется материал: мазок из зева, носа, ликвор, кровь, фекалии для исследования на вирус)',
      value: actData?.materials?.value
    },
    {
      key: '3. Науқастың Т.А.Ә (болған жағдайда)(Ф.И.О (при наличии) больного)',
      value: actData?.sickName?.value
    },
    {
      key: '4. Жасы (Возраст)',
      value: actData?.age?.value
    },
    {
      key: '5. Мекен жайы (Домашний адрес)',
      value: actData?.homeAdress?.value
    },
    {
      key: '6. Мектепке дейінгі балалар ұйымы, оқу орны, жұмыс орны (Детская школьная организация, место учебы, место работы)',
      value: actData?.workPlace?.value
    },
    {
      key: '7. Ауырған күні (Дата заболевания)',
      value: actData?.illnessDate?.value ? formatDate(new Date(actData?.illnessDate.value)) : ' '
    },
    {
      key: '8. Емдеуге жатқызу күні (Дата госпитализации)',
      value: actData?.hospitalizationDate?.value ? formatDate(new Date(actData?.hospitalizationDate.value)) : ' '
    },
    {
      key: '9. Материал алынған күн (Дата взятия материала)',
      value: actData?.materialSelectionDate?.value ? formatDate(new Date(actData?.materialSelectionDate.value)) : ' '
    },
    {
      key: '10. Диагнозы (Диагноз)',
      value: actData?.diagnosis?.value
    },
    {
      key: '11. Аурудың негізгі симптомдары (Основные симптомы заболевания)',
      value: actData?.symptoms?.value
    },
    {
      key: '12. Қысқа эпидемиологиялық анамнез (Краткий эпидемиологической анамнез)',
      value: actData?.anamnesis?.value
    },
    {
      key: '13. Екпелер саны (Количество прививок)',
      value: actData?.vaccinationsQuantity?.value
    },
    {
      key: '14. Соңғы егілу күні (Дата последней прививки)',
      value:  actData?.lastVaccinationDate?.value ? formatDate(new Date(actData?.lastVaccinationDate.value)) : ' '
    },
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Table style={styles.table}>
          <Row>
            <Cell></Cell>
            <Cell>
              <Text>Нысанның БҚСЖ бойынша коды</Text>
              <Text>Код формы по ОКУД ___________________</Text>
              <Text>КҰЖЖ бойынша ұйым коды</Text>
              <Text>Код организации по ОКПО _____________</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрлігі Министерство здравоохранения Республики Казахстан</Text>
            </Cell>
            <Cell>
              <Text>Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы "__" __________ № бұйрығымен бекітілген №____013/е нысанды медициналық құжаттама</Text>
            </Cell>
          </Row>
          <Row>
            <Cell>
              <Text>Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк органының атауы Наименование государственного органа санитарно-эпидемиологической службы</Text>
            </Cell>
            <Cell>
              <Text>Медицинская документация Форма № 013/у Утверждена приказом Министра здравоохранения Республики Казахстан от "__" ______ 2021 года №______</Text>
            </Cell>
          </Row>
        </Table>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {actData?.dateOfDirection?.value ? formatDate(new Date(actData.dateOfDirection.value)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <View style={{ fontFamily: 'Times New Roman' }}>
          <Text style={styles.simpleString}>Дәрігер (Врач) тегі, аты, әкесінің аты (болған жағдайда) фамилия, имя, отчество (при наличии), : {actData?.doctor?.value?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10  }}>
          <Text style={styles.simpleString}>Зерттеу жүргiзген маманның Т.А.Ә.(болған жағдайда), лауазымы (Ф.И.О.(при наличии), должность специалиста проводившего исследование): {data.performer?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись) </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Зертхана меңгерушісінің Т.А.Ә. (болған жағдайда), қолы. (Ф.И.О.(при наличии), подпись заведующего лабораторией): {data.responsible?.fullName}</Text>
          <Text style={styles.string}>Қолы(подпись)</Text>
          <Text style={styles.string}>Направление составлено в экземплярах(кол-во) _____</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function DirectionVirusPDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
