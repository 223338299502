import { 
    CURRENCY_GET_REQUEST, CURRENCY_GET_SUCCESS, CURRENCY_GET_ERROR, 
    CURRENCY_GET_BY_KEY_REQUEST, CURRENCY_GET_BY_KEY_SUCCESS, CURRENCY_GET_BY_KEY_ERROR, 
    CURRENCY_ADD_REQUEST, CURRENCY_ADD_SUCCESS, CURRENCY_ADD_ERROR, 
    CURRENCY_EDIT_REQUEST, CURRENCY_EDIT_SUCCESS, CURRENCY_EDIT_ERROR, 
    CURRENCY_DELETE_REQUEST, CURRENCY_DELETE_SUCCESS, CURRENCY_DELETE_ERROR
} from "../constants/currencyConstants";

const initialState = {
    currencies: [],
    currency: {},
    loading: false,
    success: null,
    error: null,
    currencyRequestedCount: 0,
};

export const currencyReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CURRENCY_GET_REQUEST:
            return {
                ...state,
                currencyRequestedCount: ++state.currencyRequestedCount,
                loading: true,
            };
        case CURRENCY_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                currencies: action.payload,
                success: true,
            };
        case CURRENCY_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CURRENCY_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CURRENCY_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                currency: action.payload,
                success: true,
            };
        case CURRENCY_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CURRENCY_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CURRENCY_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CURRENCY_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CURRENCY_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CURRENCY_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CURRENCY_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CURRENCY_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CURRENCY_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CURRENCY_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
