import { 
    CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_REQUEST, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_SUCCESS, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_ERROR, 
    CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_REQUEST, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_SUCCESS, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_ERROR, 
    CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_REQUEST, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_SUCCESS, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_ERROR, 
    CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_REQUEST, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_SUCCESS, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_ERROR, 
    CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_REQUEST, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_SUCCESS, CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_ERROR
} from "../constants/contractRequestAdditionalAgreementConstants";

const initialState = {
    contractRequestAdditionalAgreements: [],
    contractRequestAdditionalAgreement: {},
    loading: false,
    success: null,
    error: null,
    contractRequestAdditionalAgreementRequestedCount: 0,
};

export const contractRequestAdditionalAgreementReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_REQUEST:
            return {
                ...state,
                contractRequestAdditionalAgreementRequestedCount: ++state.contractRequestAdditionalAgreementRequestedCount,
                loading: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractRequestAdditionalAgreements: action.payload,
                success: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractRequestAdditionalAgreement: action.payload,
                success: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_ADDITIONAL_AGREEMENT_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
