import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {Grid} from "@mui/material";

import {
    formatDate,
    MOMENT_FORMAT_DATE_DMYHM_DOTS,
} from "../../../functions/formatters";
import {prescriptionCRUD, protocolFormCRUD} from "../../../http";
import {PRESCRIPTION_EDIT_ROUTE, PRESCRIPTION_ROUTE} from "../../../routes";
import CRUDTable from "../CRUDTable";
import {checkRoles} from '../../../functions/checkRoles';
import {Button} from '@mui/material';
import {confirmDialog} from '../../Dialog/DialogDelete';

import {
    REQUEST_STATE_ID_CREATED,
} from "../../../redux/constants/requestStateConstants";

import {objectReferralFilterFields, referralSearchFields} from "../../../sections/Filters/ReferralFilters";

import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";

export const ProtocolFormListCRUDTable = ({...props}) => {
    const {t} = useTranslation();
    const {reload, reloadValue} = useReload();

    const columns = [
        {title: 'id', dataIndex: "id", key: "id"},
        {
            title: 'Номер',
            dataIndex: "number",
            key: "number",
            render: (unknown, row) => row.number ? (row.number.length == 2 ? `0${row.number}` : row.number) : <span style={{color: "gray"}}>Не задан</span>

        },
        {
            title: 'Схема',
            dataIndex: "schema",
            key: "schema",
        },
        {
            title: 'Название',
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Создано",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (unknown, row) => formatDate(row.createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS),
        },
    ];

    const searchFields = [
        {
            title: "Номер",
            key: "number",
            compareType: "like",
            operandPosition:1,
            filterType:"normal"
        }
    ];

    return (
        <CRUDTable
            search={protocolFormCRUD.search}
            columns={columns}
            // filterFields={filterFields}
            searchFields={searchFields}
            reloadValue={props.reloadValue || reloadValue}
            {...props}
        />
    );
};
