import { StyleSheet, View } from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignContent: 'center',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
  },
  cell: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    alignSelf: 'stretch',
    padding: 3
  },
  border: {
    border: '1px solid black'
  }
})

export function Table(props) {
  return (
    <View style={props.style}>
      {/* {Array.isArray(props.children)
        ? props.children.map((child, i) => React.cloneElement(child, { noBorder: props?.noBorder, key: i }))
        : React.cloneElement(props.children, { noBorder: props?.noBorder })
      } */}
      {props.children}
    </View>
  )
}

export function Row(props) {
  return (
    <View style={[styles.row, ...(props.style ?? [])]}>
      {/* {Array.isArray(props.children)
        ? props.children.map((child, i) => React.cloneElement(child, { noBorder: props?.noBorder, key: i }))
        : React.cloneElement(props.children, { noBorder: props?.noBorder })
      } */}
      {props.children}
    </View>
  )
}

export function Cell(props) {
  return (
    <View style={[styles.cell, ...(props?.noBorder ? [] : [styles.border]), ...(props.style ?? [])]}>
      {props.children}
    </View>
  )
}