import { 
    ACT_FORM_GET_REQUEST, ACT_FORM_GET_SUCCESS, ACT_FORM_GET_ERROR, 
    ACT_FORM_GET_BY_KEY_REQUEST, ACT_FORM_GET_BY_KEY_SUCCESS, ACT_FORM_GET_BY_KEY_ERROR, 
    ACT_FORM_ADD_REQUEST, ACT_FORM_ADD_SUCCESS, ACT_FORM_ADD_ERROR, 
    ACT_FORM_EDIT_REQUEST, ACT_FORM_EDIT_SUCCESS, ACT_FORM_EDIT_ERROR, 
    ACT_FORM_DELETE_REQUEST, ACT_FORM_DELETE_SUCCESS, ACT_FORM_DELETE_ERROR
} from "../constants/actFormConstants";

const initialState = {
    actForms: [],
    actForm: {},
    loading: false,
    success: null,
    error: null,
    actFormRequestedCount: 0,
};

export const actFormReducer = (state = initialState, action) => {
    switch (action.type)     {
        case ACT_FORM_GET_REQUEST:
            return {
                ...state,
                actFormRequestedCount: ++state.actFormRequestedCount,
                loading: true,
            };
        case ACT_FORM_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                actForms: action.payload,
                success: true,
            };
        case ACT_FORM_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_FORM_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_FORM_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                actForm: action.payload,
                success: true,
            };
        case ACT_FORM_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_FORM_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_FORM_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_FORM_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_FORM_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_FORM_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_FORM_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case ACT_FORM_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case ACT_FORM_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case ACT_FORM_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
