import {Collapse, Divider, ListItem} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import React, {useCallback, useContext, useState} from "react";
import { Grid} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useLocation} from "react-router-dom";
import {checkRoles} from "../../functions/checkRoles";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {ExcelContext} from "../../screens/Protocol/ProtocolList";


const CRUDFilter = ({children, filter, rightChildren})=>{
    const [open, setOpen] = useState(true)
    const openHandler = useCallback(()=>{
        setOpen(!open)
    },[open])

    const isSuperAdmin = checkRoles("SuperAdministrator")
    const {pathname} = useLocation()
    const context = useContext(ExcelContext)

    return (
        <>
            <Grid container xs={12}>
                <Grid item xs={4}>
                    <ListItem button onClick={openHandler} style={{"maxWidth": "150px"}}>
                        <ListItemText primary={"Фильтры"}/>
                        {open ? <IconExpandLess/> : <IconExpandMore/>}
                    </ListItem>
                </Grid>
                <Grid item xs={8}>
                    <div style={{"float":"right"}}>
                        {rightChildren && rightChildren}
                        {(pathname === "/protocolList" || pathname === '/resultList' || pathname === '/resultListPaid' || pathname === '/resultListObjectReferral') && <LoadingButton size="small" variant="outlined" sx={{marginLeft: 3}} onClick={() => {
                            if (context?.handleGenerateExcel) {
                                context?.handleGenerateExcel(filter)
                            }
                        }
                        }>
                            <CloudDownloadIcon sx={{marginRight: 1}}/> Выгрузить
                        </LoadingButton> }
                    </div>
                </Grid>
            </Grid>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider/>
                {children}
            </Collapse>
        </>
    )
}

export default CRUDFilter