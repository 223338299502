import React, {useState} from "react";
import {requestTarificationCRUD} from "../../../http";
import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS} from "../../../functions/formatters";
import CRUDTable from "../CRUDTable";
import {getDepartments, getDivisions, getFilials, getGovernmentContractsIn10Years} from "../../../redux/actions";
import { getContracts } from "../../../redux/actions";
import ruLocale from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {DatePicker} from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {IconButton, TextField, Tooltip} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Box from "@mui/material/Box";
import {checkRoles} from "../../../functions/checkRoles";
import useAlert from "../../../hooks/useAlert";
import { getFilialDivisions } from '../../../redux/actions/filialDivisionActions';
import { getGovernmentContracts } from "../../../redux/actions";

import { withoutTime } from "../../../functions/formatters";
import { getServiceGroups } from "../../../redux/actions/serviceGroupActions";
import { getServiceSubGroups } from "../../../redux/actions/serviceSubGroupActions";
import { getServiceKinds } from "../../../redux/actions/serviceKindActions";
import { getAnObjectSections } from "../../../redux/actions/anObjectSectionActions";
import { getAnObjectSubSections } from "../../../redux/actions/anObjectSubSectionActions";
import { contractCRUD } from "../../../http/CRUD";
import { getPurposeOfSelectionByKey, getPurposeOfSelections } from "../../../redux/actions/purposeOfSelectionActions";
import { getOrderTypes } from "../../../redux/actions/requestActions";

export default function RequestTarificationCRUDTable({...props}) {
    const {handleChangeFilterCrud} = props
    const [researchEndDateTime, setResearchEndDateTime] = useState({})
    const isAdmin = checkRoles("SuperAdministrator", "Administrator")
    const [contract, setContract] = useState(null); 
    const [contractStartYear, setContractStartYear] = useState(null); 
    const [contractEndYear, setContractEndYear] = useState(null); 

    const changeMonth = (value) => {
        return async () => {
           props.changeMonth(value, researchEndDateTime)
        }
    }

    const columns = [
        {title: "id", dataIndex: "id", key: "id"},
        {
            title: "Номер заявки",
            dataIndex: "referral",
            key: "referral",
            render: (value, row) => {
                return row.referral?.number || row.payedReferral?.number;
            },
        },
        {
            title: "Код исследования",
            dataIndex: "researchCode",
            key: "researchCode",
        },
        {
            title: "Количество с несоответствием",
            dataIndex: "inconsistenceQuantity",
            key: "inconsistenceQuantity",
        },
        {
            title: "Договор",
            dataIndex: "contract",
            key: "contract",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Филиал",
            dataIndex: "filial",
            key: "filial",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Районное отделение",
            dataIndex: "filialDivision",
            key: "filialDivision",
            render: (row, _) => {
                return row?.name;
            },
        },
        {
            title: "Лаборатория",
            dataIndex: "department",
            key: "department",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Отдел",
            dataIndex: "division",
            key: "division",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Группа",
            dataIndex: "group",
            key: "group",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Подгруппа",
            dataIndex: "subGroup",
            key: "subGroup",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Метод",
            dataIndex: "method",
            key: "method",
            render: (row) => {
                return row?.name;
            },
        },
        {
            title: "Отчетный месяц",
            dataIndex: "researchEndDateTime",
            key: "researchEndDateTime",
            render: (row, value) => {
                if (isAdmin) {
                    if (props.totalDate) {
                        if (!researchEndDateTime.hasOwnProperty(value.id)) {
                            console.log('test');
                            setResearchEndDateTime(prevState => ({
                                ...prevState,
                                [value.id]: row
                            }))
                        }
                        return (
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                    <DatePicker mask="__.__.____" views={['day', 'year']} label="Отчетный месяц	"
                                                value={researchEndDateTime[value.id]}
                                                onChange={(newValue) => {
                                                    console.log('newValue', newValue)
                                                    setResearchEndDateTime(prevState => ({
                                                        ...prevState,
                                                        [value.id]: newValue
                                                    }));
                                                }} minDate={new Date('01.01.1915')} renderInput={(params) =>
                                        <TextField sx={{minWidth: 150}} fullWidth margin="normal"  {...params}
                                                   helperText={null}/>
                                    }/>
                                </LocalizationProvider>
                                <Tooltip title="Необходимо нажать ,чтобы сохранить новый отчётный месяц ">
                                    <IconButton onClick={changeMonth(value)} sx={{marginLeft: '10px'}}
                                                children={<CheckIcon/>}/>
                                </Tooltip>
                            </Box>
                        )
                    }
                } else {
                    return formatDate(row, MOMENT_FORMAT_DATE_DMY_DOTS)
                }
            }
        },
        {title: "Количество", dataIndex: "quantity", key: "quantity", render: (value) => {
            return value || 0;
        },},
        {title: "Цена", dataIndex: "price", key: "price"},
        {
            title: "Весовой коэффииент",
            dataIndex: "weightCoefficient",
            key: "weightCoefficient",
        },
        {title: "Сумма", dataIndex: "summary", key: "summary"},
    ];

    const filterFields = [
        {
            title: "Вид заявки",
            key: "orderType",
            compareType: "equals",
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
            options: [
                {
                    id: 2,
                    name: 'Госзаказ'                    
                },
                {
                    id: 1,
                    name: 'Платные'                    
                }

            ]
        },
        {
            title: "Номер заявки",
            key: "referral",
            key1: "referral",
            key2: "number",
            operandPosition: 1,
            compareType: "like",
            type: "text",
            filterType: "normal",
        }, 
        {
            title: "Отчетный месяц с",
            key: "researchEndDateTime",
            operandPosition: 1,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Отчетный месяц по",
            key: "researchEndDateTime",
            operandPosition: 2,
            compareType: "between",
            type: "date2",
            filterType: "normal",
        },
        {
            title: "Договор",
            key: "contractId",
            compareType: "equals",
            reducerKey: "contract",
            reducerCountKey: 'contractIn10Year',
            reducerArrayKey: "contractsIn10Year",
            reducerAction: getGovernmentContractsIn10Years,
            filter: (_) => {
                if (!contractStartYear && !contractEndYear)
                    return _;
                if (!_.parsedActionAt)
                    return _;
                const year = _.parsedActionAt.getFullYear();
                let result = true;
                if (contractStartYear) {
                    result = result && contractStartYear.getFullYear() <= year;
                }
                if (contractEndYear) {
                    result = result && year <= contractEndYear.getFullYear();
                }

                return result;
            },
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            filter: (opt) => {
                let result = true;

                if (opt.id == null) {
                return true;
                }

                if(contract) {
                    return contract?.filials?.map(_ => _.filialId).includes(opt.id)
                }

                return result;
            },
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            operandPosition: 1,
            parentKey: 'filialId',
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Отдел",
            key: "divisionId",
            compareType: "equals",
            reducerKey: "division",
            reducerArrayKey: "divisions",
            reducerAction: getDivisions,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Код исследования",
            key: "researchCode",
            operandPosition: 1,
            compareType: "like",
            type: "text",
            filterType: "normal",
        },
        {
            title: "Группа",
            key: "groupId",
            compareType: "equals",
            reducerKey: "serviceGroup",
            reducerArrayKey: "serviceGroups",
            reducerAction: getServiceGroups,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Подгруппа",
            key: "subGroupId",
            compareType: "equals",
            reducerKey: "serviceSubGroup",
            reducerArrayKey: "serviceSubGroups",
            reducerAction: getServiceSubGroups,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Метод",
            key: "methodId",
            compareType: "equals",
            reducerKey: "serviceKind",
            reducerArrayKey: "serviceKinds",
            reducerAction: getServiceKinds,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Раздел объекта",
            key: "referral",
            key1: "referral",
            key2: "targetObjectSectionId",
            compareType: "equals",
            reducerKey: "anObjectSection",
            reducerArrayKey: "anObjectSections",
            reducerAction: getAnObjectSections,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Подраздел объекта",
            key: "referral",
            key1: "referral",
            key2: "targetObjectSubSectionId",
            compareType: "equals",
            reducerKey: "anObjectSubSection",
            reducerArrayKey: "anObjectSubSections",
            reducerAction: getAnObjectSubSections,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Цель отбора",
            key: "referral",
            key1: "referral",
            key2: "purposeOfSelectionId",
            compareType: "equals",
            reducerKey: "purposeOfSelection",
            reducerArrayKey: "purposeOfSelections",
            reducerAction: getPurposeOfSelections,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
    ];

    return (
            <CRUDTable
                search={requestTarificationCRUD.search}
                columns={columns}
                toolbar={false}
                filterFields={filterFields}
                sendFilterValues={async (name, value) => {
                    if (name == 'researchEndDateTime1' && value && value.length > 0) {
                        const parsed = new Date(value);
                        setContractStartYear(parsed);
                    }
                    if (name == 'researchEndDateTime2' && value && value.length > 0) {
                        const parsed = new Date(value);
                        setContractEndYear(parsed);
                    }
                    if (name === 'contract' && value) {
                        let selectContract = await contractCRUD.get(value);
                        setContract(selectContract);
                    }
                    handleChangeFilterCrud(name, value);
                }}
                {...props}
            />
    );
}
