import { Box, Button, Grid } from "@mui/material";
import { useEffect, forwardRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { getProtocolFormByKey } from "../../../redux/actions/protocolFormActions";
import forms, { isFieldVersion } from '../ProtocolForms/forms';
import optionalLogo from '../PDFGenerator/logos/common/ilac_mra.png';
import logoWatermark from '../PDFGenerator/watermark/logo_watermark.png';
import { mapProtocolFieldNumber } from "../../../functions/viewDataMap";
import htmlConverter from 'html2json';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { fetchAsBase64 } from "../../../functions";
import { protocolSignerCRUD } from "../../../http";
import QRCode from "qrcode";
import { generateLink, renderPageNumeration, renderPageNumerationAndWatermark } from "../../../functions/renderProtocol";
import { xml2js } from "xml-js";
import { svgToPng } from "../../../functions/svgToPng"
import { formatDate2 } from "../../../functions/formatters";
import { protocolModelFormatField } from "../../../models/protocolModel";

const formatTime = Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
}).format


const HtmlProtocol = forwardRef((props, ref) => {
    const [formTypeID, setFormTypeID] = useState();
    const textTemplate = (s) => {
        return `<span style="font-size: 14pt; font-family: 'Times New Roman', serif">${s || ""}</span>`;
    }
    const header = `${textTemplate("№" + 1 + " " + Intl.DateTimeFormat('ru-RU', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
    }).format(new Date()) + " ж. (г.)")}`
    const dispatch = useDispatch();

    const generateBigSource = (res) => {

        return `
        <!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
        <html xmlns:v="urn:schemas-microsoft-com:vml"
              xmlns:o="urn:schemas-microsoft-com:office:office"
              xmlns:w="urn:schemas-microsoft-com:office:word"
              xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
              xmlns="http://www.w3.org/TR/REC-html40">
        
        <head>
        <style>
        v\:* {behavior:url(#default#VML);}
        o\:* {behavior:url(#default#VML);}
        w\:* {behavior:url(#default#VML);}
        .shape {behavior:url(#default#VML);}
        </style>
        <style>            
              @bottom-left {
                content: counter(page) ' of ' counter(pages);
              }
        @page Section1 {size:595.45pt 841.7pt;
            margin:.5in .5in .5in .5in;
            mso-header-margin:.5in;
            mso-footer-margin:.5in;
            mso-footer:f1;
            mso-first-header: fh1;
            mso-paper-source:0;}
                div.Section1 {page:Section1;}
                @page Section2 {size:841.7pt 595.45pt;
                    mso-page-orientation:landscape;
                    margin:.5in 44.65pt .5in .5in;
                    mso-header-margin:.5in;
                    mso-footer-margin:.5in;
                    mso-footer:f1;
                    mso-first-header: fh1;
                    mso-paper-source:0;}
                div.Section2 {page:Section2;}
                div.table-bordered td { border: 1px solid black !important; } 
                 p.headerFooter { margin:0in; text-align: center; }
        </style>
        </head>
        <body lang=RU style='tab-interval:35.4pt'>
        <div class=Section1 >
                
                ${renderHeader()}
                ${renderTable()}
                
                </div>  
                <span style='font-size:8.0pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
                "Times New Roman";mso-ansi-language:EN-US;mso-fareast-language:EN-US;
                mso-bidi-language:AR-SA'><br clear=all style='page-break-before:always;
                mso-break-type:section-break'>
                </span>
                <div class=Section2>
                ${renderHtmlTable()}
                ${renderFooter()}
                </div>
        <p style="font-size: 8pt;">Данный документ согласно пункту 1 статьи 7 ЗРК от 7 января 2003 года «Об электронном документе и электронной цифровой подписи» равнозначен документу на бумажном носителе.</p> 
        <span style="font-size: 8pt; text-indent: 25px; font-family: 'Times New Roman', serif">Осы құжат "Электрондық құжат және электрондық цифрлық қолтаңба туралы " Қазақстан Республикасының 2003 жылғы 7 қаңтардағы N 370-II Заңы 7 бабының 1 тармағына сәйкес қағаз тасығыштағы құжатпен бірдей.</span>
        <span style="font-size: 8pt; text-indent: 25px; font-family: 'Times New Roman', serif">Данный документ согласно пункту 1 статьи 7 ЗРК от 7 января 2003 года «Об электронном документе и электронной цифровой подписи» равнозначен документу на бумажном носителе.</span>
        ${renderPageNumerationAndWatermark(header, watermarkUrl)} 
        <img width="122" height="123" src=${res}/>
        </body>
        </html>
         `
    }

    let signed = props.signed;
    let last = props.last;


    const [form, setForm] = useState(null);
    const [signers, setSigners] = useState({});

    const [optionalLogoUrl, setOptionalLogoUrl] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const [splitted, setSplitted] = useState(false);
    const [watermarkUrl, setWatermarkUrl] = useState(null);

    useEffect(() => {
        if (props.formID === undefined) return
        dispatch(getProtocolFormByKey(props.formID)).then((obj) => {
            const number = obj?.number;
            setFormTypeID(number);
            const theForm = forms[number];
            setForm(theForm);
            setSplitted(theForm?.splitted || false);

            setSigners(Object.fromEntries(['research_specialist', 'lab_director', 'organization_leader'].map((signerTitle, i) => {
                const titleID = ['performer', 'responsible', 'leader'][i]
                const employeeID = props.protocol[titleID]?.id
                if (employeeID == null)
                    return null;

                const isSigned = props.signers.find(signer => signer.employeeId === employeeID)?.isSigned
                return [signerTitle, isSigned]
            }).filter(_ => _)));
        });
    }, [props.formID])

    useEffect(async () => {
        if (props.formID === undefined) return;
        if (!props.addOptionalLogo) return;

        await fetchAsBase64(optionalLogo, (_) => {
            setOptionalLogoUrl(_);
        });
    }, [props.formID, props.addOptionalLogo]);

    useEffect(async () => {
        if (props.formID === undefined) return;
        if (!props.logo) return;

        await fetchAsBase64(props.logo, (_) => {
            svgToPng(_, 95, 103, pngSrc => {
                setLogoUrl(pngSrc);
                setImageUrl(pngSrc);
            })
        });
        // await fetchAsBase64(props.logo, (_) => {
        //     setLogoUrl(_);
        //     setImageUrl(_);
        // });
    }, [props.formID, props.logo]);

    useEffect(async () => {
        await fetchAsBase64(logoWatermark, (_) => {
            setWatermarkUrl(_);
        });
    }, []);

    useEffect(async () => {

        if (props.data?.sketch) {
            await fetchAsBase64(props.data?.sketch, (_) => {
                setImageUrl(_);
            })
        }
    }, [props.data?.sketch]);

    const handleDownload = (html) => {
        let sourceHTML = html ? html : props.handleGenerateHTML(true, true)

        let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
        let fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = `Протокол (№: ${formTypeID}, ${props.number}).doc`;
        fileDownload.click();
        document.body.removeChild(fileDownload);
    }

    const handleDownload2Side = () => {
        let sourceHTML = generateBigSource();
        let source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML);
        let fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = `Протокол (№: ${formTypeID}, ${props.number}).doc`;
        fileDownload.click();
        document.body.removeChild(fileDownload);
    }

    const isAdmin = false;
    const plugins = isAdmin ? 'a11ychecker advcode casechange export formatpainter image editimage linkchecker autolink lists checklist media mediaembed pageembed permanentpen powerpaste table advtable tableofcontents tinymcespellchecker' :
        'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help noneditable';
    const toolbar = isAdmin ? 'a11ycheck addcomment showcomments casechange checklist code export formatpainter image editimage pageembed permanentpen table tableofcontents' :
        'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify ';

    //<p style="margin: 0cm 8.75pt 0.0001pt 0cm; text-align: center; font-size: 16pt; font-family: Cambria, serif; font-weight: bold;"><span style="font-size: 9.0pt; font-family: 'Times New Roman', serif;">№ ${number} </span></p>

    const optionalLogoTemplate = () => {
        return props.addOptionalLogo ? `<span style="width: 5mm;">&nbsp;&nbsp;&nbsp;&nbsp;</span><img src="${optionalLogoUrl}" style="width: 20mm;object-fit: fill" class="optionalLogo"></img>` : "";
    }

    const logoTemplate = () => {
        return props.logo ? `<span style="width: 5mm;">&nbsp;&nbsp;&nbsp;&thinsp;</span><img src="${logoUrl}" style="object-fit: fill;" class="logo"></img>` : "";
    }


    const textTemplate8Size = (s) => {
        return `<span style="font-size: 8pt; font-family: 'Times New Roman', serif">${s || ""}</span>`;
    }

    const textTemplate12Size = (s) => {
        return `<span style="font-size: 12pt; font-family: 'Times New Roman', serif">${s || ""}</span>`;
    }

    const textTemplateWithTab = (s) => {
        return `<span style="font-size: 8pt; text-indent: 25px; font-family: 'Times New Roman', serif">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${s || ""}</span>`;
    }

    const underlineTextTemplate = (s) => {
        return `<span style="font-size: 12pt; font-family: 'Times New Roman', serif"><u>${s || ""}</u></span>`;
    }

    const renderImage = (imgData) => {

        return `<img src="${imgData}" width=200 height=200 style="max-height: 400px; max-width: 400px;object-fit: cover"/>`;
    }

    const headTextTemplate = (s) => {
        return `<span style="font-size: 14pt; font-family: 'Times New Roman', serif; font-weight: bold;">${s}</span>`;
    }

    const format = (field, data, type) => {
        if (!data) {
            return ''
        }

        switch (type) {
            case 'text':
            case 'number':
                return data;

            case 'employee':
                return data?.fullName ?? data;

            case 'switch':
                return data;
            case 'date':
                try {
                    return formatDate2(new Date(data));
                } catch (e) {
                    return data;
                }

            case 'datetime':
                try {
                    return formatTime(new Date(data));
                } catch (e) {
                    return data;
                }
            default:
                return data;
        }
    }

    const renderTable = () => {
        const fields = form?.fields ?? [];

        if (fields.length == 0) {
            return "";
        }

        let result = `<table style="border-collapse: collapse; width: 100%" border="0">`;

        const primitiveFields = fields.filter(_ => _.type != 'table');

        for (let i = 0; i < primitiveFields.length; i++) {
            const field = primitiveFields[i];

            if (field.type === 'file') {
                console.log(field.id)
                result += `
                <tr>
                    <td style="width: 100%">
                        ${textTemplate(mapProtocolFieldNumber(field.number, i) + field.kazakhLabel + "(" + field.label + "):")}
                        &nbsp;
                        ${renderImage(props.data[field.id])}
                    </td>
                </tr>
                `
            } else {
                result += `
                <tr>
                    <td style="width: 100%">
                        ${textTemplate12Size(mapProtocolFieldNumber(field.number, i) + field.kazakhLabel + "(" + field.label + "):")}
                        &nbsp;
                        ${underlineTextTemplate(protocolModelFormatField(field, props.data[field.id], field.type))}
                    </td>
                </tr>
                `
            }
        }

        result += "</table>";
        return result;
    }

    const handleDownloadPDF = async () => {

        if (signed && last) {
            let link = generateLink(props?.protocol.id, props?.protocol.guid);


            if (link) {
                const res = await QRCode.toDataURL(link.url)

                let sourceHTML = !splitted ? props.handleGenerateHTML(true, true, res) : generateBigSource(res);

                handleDownload(sourceHTML)
            }
        }
    }

    const renderHtmlTable = () => {
        const fields = form?.fields ?? [];
        const version = form?.version;

        const primitiveFields = fields.filter(_ => _.type != 'table');
        const tableFields = fields.filter(_ => _.type == 'table');

        if (tableFields.length == 0) {
            return "";
        }

        let result = '';

        result += `<table style="border-collapse: collapse; width: 100%; border-style: none; margin-top: 10px" border="0">`;

        for (let i = 0; i < tableFields.length; i++) {
            const field = tableFields[i];

            if (field.htmlTable && !isFieldVersion(field, version)) {
                continue;
            }

            if (field.label) {
                result += `
                <tr>
                    <td colspan="2">
                        ${mapProtocolFieldNumber(field.number, primitiveFields.length - 1 + i) + " " + field.kazakhLabel + " (" + field.label + ")" + ":"}
                    </td>
                </tr>
                `;
            }

            if (!field.htmlTable) {
                continue;
            }

            const data = props.data[field.id];

            if (!data) {
                continue;
            }

            let html = htmlConverter.json2html(data);
            html = html.replace(/<td>/g, "<td style='border: 1px solid black'>");
            result += `
            <tr>
                <td colspan="2">
                    ${html}
                </td>
            </tr>
            `;
        }

        result += "</table>";
        return result;
    }

    const renderHeader = () => {
        return `
        <table style="border-collapse: collapse; width: 100%; border-style: solid;" border="1">
            <tr>
                <td style="width: 50%">
                    <span style="width: 5mm;">&nbsp;&nbsp;&nbsp;&nbsp;</span> 
                    <div> 
                        ${optionalLogoTemplate()} 
                        ${logoTemplate()} 
                    <div> 
                    <span style="width: 5mm;">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </td>
                <td style="width: 50%">
                    ${textTemplate12Size("Нысанның БҚСЖ бойынша коды")}
                    <br/>
                    ${textTemplate12Size("Код формы по ОКУД ___________________")}
                    <br/>
                    ${textTemplate12Size("КҰЖЖ бойынша ұйым коды")}
                    <br/>
                    ${textTemplate12Size("Код организации по ОКПО _____________")}
                </td>
            </tr>
            <tr>
                <td style="width: 50%">
                    ${textTemplate12Size("Қазақстан Республикасының Денсаулық сақтау министрлігі")}
                    <br/>
                    ${textTemplate12Size("Министерство здравоохранения Республики Казахстан")}
                </td>
                <td style="width: 50%">
                    ${textTemplate12Size("Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы 20 тамызынан №84 бұйрығымен  Бекітілген №" + formTypeID + " нысанды медициналық құжаттама")}
                </td>
            </tr>
            <tr>
                <td style="width: 50%">
                    ${textTemplate12Size(props.filial?.nameKz ?? '')}
                    <br/>
                    ${textTemplate12Size(props.filialDivision?.nameKz || '')}
                    <br/>
                    ${textTemplate12Size(props.filial?.name ?? '')}
                    <br/>
                    ${textTemplate12Size(props.filialDivision?.name || '')}
                </td>
                <td style="width: 50%">
                    ${textTemplate12Size("Медицинская документация Форма №" + formTypeID + " Утверждена приказом Министра здравоохранения Республики Казахстан от 20 августа 2021 года №84")}
                </td>
            </tr>
        </table>

        <table style="border-collapse: collapse; width: 100%; border-style: hidden;" border="0">
            <tr>
                <td style="text-align: center;">
                    ${headTextTemplate(form?.kazakhName)}
                    <br/>
                    ${headTextTemplate(form?.name)}
                </td>
            </tr>
            <tr>
                <td colspan="2" style="text-align:center">
                    ${textTemplate("№" + props.number + " " + Intl.DateTimeFormat('ru-RU', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).format(props.dateOfIssue) + " ж. (г.)")}
                </td>
            </tr>
        </table>
        `;
    }

    const renderEmployeeName = (employee) => {
        let result = "";

        if (!employee)
            return result;

        return employee.fullName;
    }

    const renderEmployeePosition = (employee) => {
        let result = "";

        if (!employee?.position)
            return result;

        if (employee.position.nameKz && employee.position.name)
            return `${employee.position.nameKz} (${employee.position.name})`;

        return employee.position.name;
    }

    const renderFooter = () => {
        return `
        <table style="border-collapse: collapse; width: 100%; border-style: hidden;" border="0">
            <tr>
                <td style="width: 33%">
                    ${textTemplate12Size(renderEmployeePosition(props.data?.research_specialist))}
                </td>
                <td style="width: 33%">
                    ${textTemplate12Size(signers.research_specialist ? 'Қол қойылды(Подписано)' : 'Қолы (Подпись)')}
                </td>
                <td style="width: 34%">
                    ${textTemplate12Size(renderEmployeeName(props.data?.research_specialist))}
                </td>
            </tr>
            <tr>
                <td style="width: 33%">
                    ${textTemplate12Size(renderEmployeePosition(props.data?.lab_director))}
                </td>
                <td style="width: 33%">
                    ${textTemplate12Size(signers.lab_director ? 'Қол қойылды(Подписано)' : 'Қолы (Подпись)')}
                </td>
                <td style="width: 34%">
                    ${textTemplate12Size(renderEmployeeName(props.data?.lab_director))}
                </td>
            </tr>
            <tr>
                <td style="width: 33%">
                    ${textTemplate12Size(renderEmployeePosition(props.data?.organization_leader))}
                </td>
                <td style="width: 33%">
                    ${textTemplate12Size(signers.organization_leader ? 'Қол қойылды(Подписано)' : 'Қолы (Подпись)')}
                </td>
                <td style="width: 34%">
                    ${textTemplate12Size(renderEmployeeName(props.data?.organization_leader))}
                </td>
            </tr>
        </table>
        <br/>
        ${textTemplateWithTab("Хаттама __ данада толтырылды (Протокол составлен в ___ экземплярах)")}
        <br/>
        ${textTemplate8Size("Хаттама берілген күні (Дата выдачи протокола) " + Intl.DateTimeFormat('ru-RU', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
        }).format(props.dateOfIssue) + " ж. (г.)")}
        <br/>
        ${textTemplate8Size("Парақтар саны (Количество страниц) ")}
        <br/>
        ${textTemplate8Size("Сынау нәтижелері тек қана сыналуға жататын үлгілерге қолданылады")}
        <br/>
        ${textTemplate8Size("(Результаты исследования распространяются только на образцы, подвергнутые испытаниям)")}
        <br/>
        ${textTemplate8Size("Рұқсатсыз хаттаманы жартылай қайта басуға ТЫЙЫМ САЛЫНҒАН")}
        <br/>
        ${textTemplate8Size("(Частичная перепечатка протокола без разрешения ЗАПРЕЩЕНА)")}
        <br/>
        ${textTemplate8Size("Санитариялық дәрігердің немесе гигиенист дәрігердің зерттелген өнімдердің,химиялық заттардың, физикалық және радиациялық факторлардың")}
        ${textTemplate8Size("үлгілері/сынамалары туралы қорытындысы")}
        <br/>
        ${textTemplate8Size("(Заключение санитарного врача или врача-гигиениста по образцам/пробам")}
        ${textTemplate8Size("исследуемой продукции, химических веществ, физических и радиационных факторов):")}
        <br/>
        ${textTemplate8Size("____________________________________________________________________")}
        ${textTemplate8Size("____________________________________________________________________")}

        `;
        //    
    }

    return (
        <>
            {formTypeID && <Box sx={{ mt: 5 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Editor disabled                    
                            apiKey="4i5rbvjc94bmm1gedi3jbzqvkndzatboqnqjqz48gllonuzl"
                            onInit={(evt, editor) => ref.current = editor}
    
                        initialValue={`
                    ${renderHeader()}

                    ${renderTable()}
                    ${renderHtmlTable()}                    

                    ${renderFooter()}
                    `}
                            init={{
                                height: 1024,
                                menubar: false,
                                toolbar: false,
                                readonly: true,
                                // selector: "textarea",
                                plugins: plugins,
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:12px } .tox-checklist--checked {font-weight: bold;} .table-bordered td { border: 1px solid black !important; border-collapse: collapse }'
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* <Button
                                onClick={!splitted ? () => handleDownload() : handleDownload2Side}
                                startIcon={<ArrowDownwardIcon/>}
                                sx={{marginTop: 3}} size="small" variant="outlined">Скачать</Button> */}
                        {(signed && last) && <Button
                            onClick={handleDownloadPDF}
                            startIcon={<ArrowDownwardIcon />}
                            sx={{ marginTop: 3 }} size="small" variant="outlined">Скачать
                            (подписанный)</Button>}
                    </Grid>
                </Grid>


            </Box>}
        </>
    )
}
)

export default HtmlProtocol
