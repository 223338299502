import { 
    TEST_TUBE_GET_REQUEST, TEST_TUBE_GET_SUCCESS, TEST_TUBE_GET_ERROR, 
    TEST_TUBE_GET_BY_KEY_REQUEST, TEST_TUBE_GET_BY_KEY_SUCCESS, TEST_TUBE_GET_BY_KEY_ERROR, 
    TEST_TUBE_ADD_REQUEST, TEST_TUBE_ADD_SUCCESS, TEST_TUBE_ADD_ERROR, 
    TEST_TUBE_EDIT_REQUEST, TEST_TUBE_EDIT_SUCCESS, TEST_TUBE_EDIT_ERROR, 
    TEST_TUBE_DELETE_REQUEST, TEST_TUBE_DELETE_SUCCESS, TEST_TUBE_DELETE_ERROR
} from "../constants/testTubeConstants";

const initialState = {
    testTubes: [],
    testTube: {},
    loading: false,
    success: null,
    error: null,
    testTubeRequestedCount: 0,
};

export const testTubeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case TEST_TUBE_GET_REQUEST:
            return {
                ...state,
                testTubeRequestedCount: ++state.testTubeRequestedCount,
                loading: true,
            };
        case TEST_TUBE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                testTubes: action.payload,
                success: true,
            };
        case TEST_TUBE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TEST_TUBE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TEST_TUBE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                testTube: action.payload,
                success: true,
            };
        case TEST_TUBE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TEST_TUBE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TEST_TUBE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TEST_TUBE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TEST_TUBE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TEST_TUBE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TEST_TUBE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case TEST_TUBE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case TEST_TUBE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case TEST_TUBE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
