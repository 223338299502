import React from "react";

import { getAnObjectSections } from "../../redux/actions/anObjectSectionActions";
import { getAnObjectSubSections } from "../../redux/actions/anObjectSubSectionActions";
import { getDepartments } from "../../redux/actions/departmentActions";
import { getDiseases } from "../../redux/actions/diseaseActions";
import { getDivisions } from "../../redux/actions/divisionActions";
import { getEmployees } from "../../redux/actions/employeeActions";
import { getFilials } from "../../redux/actions/filialActions";
import { getFilialDivisions } from "../../redux/actions/filialDivisionActions";
import { getGeoObjects } from "../../redux/actions/geoObjectActions";
import { getGovernmentStandards } from "../../redux/actions/governmentStandardActions";
import { getInfectionKinds } from "../../redux/actions/infectionKindActions";
import { getLegalPersons } from "../../redux/actions/legalPersonActions";
import { getMedicalOrders } from "../../redux/actions/medicalOrderActions";
import { getPurposeOfSelections } from "../../redux/actions/purposeOfSelectionActions";
import { getReferralAdditionalInfos } from "../../redux/actions/referralAdditionalInfoActions";
import { getReferralOrderTypes } from "../../redux/actions/referralOrderTypeActions";
import { getSamplePreservationMethods } from "../../redux/actions/samplePreservationMethodActions";
import { getSamplingPurposes } from "../../redux/actions/samplingPurposeActions";
import { getStorageConditions } from "../../redux/actions/storageConditionActions";
import { getTechnicalRegulationsOfCustomsUnions } from "../../redux/actions/technicalRegulationsOfCustomsUnionActions";
import { getTransportationConditions } from "../../redux/actions/transportationConditionActions";
import DomainSelect from "./DomainSelect";
import { getPositions } from "../../redux/actions/positionActions";
import { getRoles } from "../../redux/actions/roleActions";
import {getServices} from "../../redux/actions/serviceActions";
import {getProtocolForms} from "../../redux/actions/protocolFormActions";
import {getResultForms} from "../../redux/actions/resultFormActions";
import {getBrigadeTypes} from "../../redux/actions/brigadeTypeActions";
import { getGenders } from "../../redux/actions/genderActions";
import {getProbeTypes} from "../../redux/actions/probeTypeActions";
import {getActForms} from "../../redux/actions";

import { getContracts } from "../../redux/actions";
import { CONTRACT_TYPE_ID_GOVERNMENT_REQUESTS } from "../../redux/constants/contractTypeConstants";
import { mapOrganizationValues } from "../../functions/viewDataMap";
import {getUnitOfMeasures} from "../../redux/actions/unitOfMeasureActions";
import {getServiceGroups} from "../../redux/actions/serviceGroupActions";
import {getServiceKinds} from "../../redux/actions/serviceKindActions";
import {getServiceSubGroups} from "../../redux/actions/serviceSubGroupActions";
import {getServiceCategories} from "../../redux/actions/serviceCategoryActions";
import {getResultsType} from "../../redux/actions/resultTypeActions";
import { getResearchObjects } from "../../redux/actions/researchObjectActions";

export const ContractSelect = ({year, value, ...props}) => <DomainSelect
  label={"Договор"}
  reducerKey={"contract"}
  reducerArrayKey={"contracts"}
  reducerAction={getContracts}
  reducerQuery={{ filter: {
    actionAt: { operand1: new Date(year, 1, 1), operand2: new Date(year, 12, 31), operator: "between" },
    typeId: { operand1: CONTRACT_TYPE_ID_GOVERNMENT_REQUESTS, operator: "equals" }
  } }}
  {...props}
  value={value}
/>

export const OrganizationSelect = ({value, ...props}) => <DomainSelect
  label={"Организация"}
  reducerKey={"organization"}
  initialOptions={mapOrganizationValues()}
  {...props}
  value={value}
/>

export const FilialSelect = ({filialId, filialIds, value, organizationId, ...props}) => <DomainSelect
  label={"Филиал"}
  reducerKey={"filial"}
  reducerArrayKey={"filials"}
  reducerAction={getFilials}
  filterOptions={(opt) => {
    let result = true;

    if (opt.id == null) {
      return true;
    }

    if (organizationId) {
      result = result && opt.organizationId == organizationId;
    }

    if (filialId) {
      result = result && [filialId, value].includes(opt.id);
    }

    if (filialIds) {
      result = result && [...filialIds, value].includes(opt.id);
    }

    return result;
  }}
  {...props}
  value={value}
/>

export const FilialDivisionSelect = ({filialId, organizationId, ...props}) => <DomainSelect
  label={"Филиал"}
  reducerKey={"filialDivision"}
  reducerArrayKey={"filialDivisions"}
  reducerAction={getFilialDivisions}
  filterOptions={(opt) => {
    let result = true;

    if (opt.id == null) {
      return true;
    }

    if (filialId) {
      result = result && (Number(opt.filialId) === Number(filialId));
    }

    return result;
  }}
  {...props}
/>

export const ResearchObjectSelect = ({...props}) => <DomainSelect
    label={"Объект исследования"}
    reducerKey={"researchObject"}
    reducerArrayKey={"researchObjects"}
    reducerAction={getResearchObjects}
    {...props}
/>

export const ServiceSelect = ({selectedServices,...props}) => <DomainSelect
    label={"Сервис"}
    reducerKey={"service"}
    reducerArrayKey={"services"}
    reducerAction={getServices}
    filterOptions={(opt) => selectedServices.indexOf(opt.id)===-1}
    {...props}
/>

export const PositionSelect = ({...props}) => <DomainSelect
    label={"Должность"}
    reducerKey={"position"}
    reducerArrayKey={"positions"}
    reducerAction={getPositions}
    {...props}
/>


export const RoleSelect = ({selectedRoles,...props}) => <DomainSelect
    label={"Роль"}
    reducerKey={"role"}
    reducerArrayKey={"roles"}
    reducerAction={getRoles}
    filterOptions={(opt) => selectedRoles.indexOf(opt.id)===-1}
    {...props}
/>

export const DiagnosisSelect = ({...props}) => <DomainSelect
  label={"Диагноз"}
  reducerKey={"disease"}
  reducerArrayKey={"diseases"}
  reducerAction={getDiseases}
  {...props}
/>

export const UnitOfMeasure = ({...props}) => <DomainSelect
  label={"Ед. измерения"}
  reducerKey={"unitOfMeasure"}
  reducerArrayKey={"unitOfMeasures"}
  reducerAction={getUnitOfMeasures}
  {...props}
/>


export const ServiceGroupSelect = ({...props}) => <DomainSelect
  label={"Группа"}
  reducerKey={"serviceGroup"}
  reducerArrayKey={"serviceGroups"}
  reducerAction={getServiceGroups}
  {...props}
/>


export const ServiceKindSelect = ({...props}) => <DomainSelect
  label={"Метод"}
  reducerKey={"serviceKind"}
  reducerArrayKey={"serviceKinds"}
  reducerAction={getServiceKinds}
  {...props}
/>

export const ServiceSubGroupSelect = ({...props}) => <DomainSelect
  label={"Подгруппа"}
  reducerKey={"serviceSubGroup"}
  reducerArrayKey={"serviceSubGroups"}
  reducerAction={getServiceSubGroups}
  {...props}
/>

export const ServiceCategorySelect = ({...props}) => <DomainSelect
  label={"Категория"}
  reducerKey={"serviceCategory"}
  reducerArrayKey={"serviceCategories"}
  reducerAction={getServiceCategories}
  {...props}
/>

export const DepartmentSelect = ({departmentId, value, ...props}) => <DomainSelect
  label={"Лаборатория"}
  reducerKey={"department"}
  reducerArrayKey={"departments"}
  reducerAction={getDepartments}
  filterOptions={(opt) => !departmentId || [departmentId, value].includes(opt.id)}
  {...props}
  value={value}
/>

export const ProtocolFormSelect = ({...props}) => <DomainSelect
    label={"Форма протокола"}
    reducerKey={"protocolForm"}
    reducerArrayKey={"protocolForms"}
    optionLabelKey={"number"}
    reducerAction={getProtocolForms}
    {...props}
/>

export const ResultFormSelect = ({...props}) => <DomainSelect
    label={"Форма результата"}
    reducerKey={"resultForm"}
    reducerArrayKey={"resultForms"}
    optionLabelKey={"number"}
    reducerAction={getResultForms}
    {...props}
/>

export const BrigadeSelect = ({...props}) => <DomainSelect
    label={"Тип бригады"}
    reducerKey={"brigadeType"}
    reducerArrayKey={"brigadeTypes"}
    reducerAction={getBrigadeTypes}
    {...props}
/>

export const ProbTypeSelect = ({...props}) => <DomainSelect
    label={"Тип пробы"}
    reducerKey={"probeType"}
    reducerArrayKey={"probeTypes"}
    optionLabelKey={"name"}
    reducerAction={getProbeTypes}
    {...props}
/>

export const ActFormSelect = ({...props}) => <DomainSelect
    label={"Форма акта"}
    reducerKey={"actForm"}
    reducerArrayKey={"actForms"}
    optionLabelKey={"name"}
    reducerAction={getActForms}
    {...props}
/>

export const ResultTypeSelect = ({...props}) => <DomainSelect
    label={"Тип журнала"}
    reducerKey={"resultType"}
    reducerArrayKey={"resultTypes"}
    optionLabelKey={"name"}
    reducerAction={getResultsType}
    {...props}
/>

export const DivisionSelect = ({departmentId, ...props}) => <DomainSelect
  label={"Отдел лаборатории"}
  reducerKey={"division"}
  reducerArrayKey={"divisions"}
  reducerAction={getDivisions}
  filterOptions={(opt) => opt.id !== 5 && (!departmentId || (Number(opt.departmentId) === Number(departmentId)))}
  filterDivisionOptions={(opt) => !departmentId || (opt.departmentId === departmentId)}
  {...props}
/>

export const EmployeesSelect = ({...props}) => <DomainSelect
  label={"Сотрудник"}
  reducerKey={"employee"}
  reducerArrayKey={"employees"}
  reducerAction={getEmployees}
  optionLabelKey={"fullName"}
  {...props}
/>

export const ObjectSectionSelect = ({...props}) => <DomainSelect
  label={"Раздел объекта"}
  reducerKey={"anObjectSection"}
  reducerArrayKey={"anObjectSections"}
  reducerAction={getAnObjectSections}
  {...props}
/>

export const ObjectSubSectionSelect = ({...props}) => <DomainSelect
  label={"Подраздел объекта"}
  reducerKey={"anObjectSubSection"}
  reducerArrayKey={"anObjectSubSections"}
  reducerAction={getAnObjectSubSections}
  {...props}
/>

export const SamplingPurposeSelect = ({...props}) => <DomainSelect
  label={"6. Цель отбора образца / обработки для дез"}
  reducerKey={"samplingPurpose"}
  reducerArrayKey={"samplingPurposes"}
  reducerAction={getSamplingPurposes}
  {...props}
/>
export const AdditionalInfoSelect = ({...props}) => <DomainSelect
  label={"Цель отбора (дополнительное поле, заполняется при необходимости)"}
  reducerKey={"referralAdditionalInfo"}
  reducerArrayKey={"referralAdditionalInfos"}
  reducerAction={getReferralAdditionalInfos}
  {...props}
/>
export const InfectionKindSelect = ({...props}) => <DomainSelect
  label={"Заболеваемость"}
  reducerKey={"infectionKind"}
  reducerArrayKey={"infectionKinds"}
  reducerAction={getInfectionKinds}
  {...props}
/>

export const MedicalOrderSelect = ({...props}) => <DomainSelect
  label={"Список приказов для ДДД"}
  reducerKey={"medicalOrder"}
  reducerArrayKey={"medicalOrders"}
  reducerAction={getMedicalOrders}
  getOptionLabel={(opt) => `${opt.code} - ${opt.name} - ${opt.description}`}
  {...props}
/>

export const TechnicalRegulationsOfCustomsUnionsSelect = ({...props}) => <DomainSelect
  label={"ТР/ТС"}
  reducerKey={"technicalRegulationsOfCustomsUnion"}
  reducerArrayKey={"technicalRegulationsOfCustomsUnions"}
  reducerAction={getTechnicalRegulationsOfCustomsUnions}
  getOptionLabel={(opt) => `${opt.code} - ${opt.name} - ${opt.description}`}
  {...props}
/>

export const PurposeOfSelectionsSelect = ({...props}) => <DomainSelect
  label={"Цель отбора"}
  reducerKey={"purposeOfSelection"}
  reducerArrayKey={"purposeOfSelections"}
  reducerAction={getPurposeOfSelections}
  {...props}
/>

// Выдаёт 9 мб данных, пока лучше не использовать, надо править бек
export const LegalPersonSelect = ({...props}) => <DomainSelect
  label={"Организационно-правовая форма"}
  reducerKey={"legalPerson"}
  reducerArrayKey={"legalPersons"}
  reducerAction={getLegalPersons}
  {...props}
/>

export const GovernmentStandardsSelect = ({...props}) => <DomainSelect
  label={"9. НД на метод отбора (тем, кто пробы не отбирает заполнять не нужно)"}
  reducerKey={"governmentStandard"}
  reducerArrayKey={"governmentStandards"}
  reducerAction={getGovernmentStandards}
  {...props}
/>
export const TransportationConditionSelect = ({...props}) => <DomainSelect
  label={"10. Условия транспортировки (тем, кто пробы не отбирает заполнять не нужно)"}
  reducerKey={"transportationCondition"}
  reducerArrayKey={"transportationConditions"}
  reducerAction={getTransportationConditions}
  {...props}
/>

export const StorageConditionSelect = ({...props}) => <DomainSelect
  label={"11. Условия хранения (тем, кто пробы не отбирает заполнять не нужно)"}
  reducerKey={"storageCondition"}
  reducerArrayKey={"storageConditions"}
  reducerAction={getStorageConditions}
  {...props}
/>

export const SamplePreservationMethodSelect = ({...props}) => <DomainSelect
  label={"12. Методы консервации образца"}
  reducerKey={"samplePreservationMethod"}
  reducerArrayKey={"samplePreservationMethods"}
  reducerAction={getSamplePreservationMethods}
  {...props}
/>

export const ReferralAdditionalInfosSelect = ({...props}) => <DomainSelect
  label={"13. Дополнительные сведения"}
  reducerKey={"referralAdditionalInfo"}
  reducerArrayKey={"referralAdditionalInfos"}
  reducerAction={getReferralAdditionalInfos}
  {...props}
/>
export const OrderTypeSelect = ({...props}) => <DomainSelect
  label={"15. Тип заказа"}
  reducerKey={"referralOrderType"}
  reducerArrayKey={"referralOrderTypes"}
  reducerAction={getReferralOrderTypes}
  {...props}
/>
export const GeoObjectSelect = ({...props}) => <DomainSelect
  label={"Адрес"}
  reducerKey={"geoObject"}
  reducerArrayKey={"geoObjects"}
  reducerAction={getGeoObjects}
  {...props}
/>

export const GenderSelect = ({...props}) => <DomainSelect
  label={"Пол"}
  reducerKey={"gender"}
  reducerArrayKey={"genders"}
  reducerAction={getGenders}
  {...props}
/>

// todo
// export const ActInfoSelect = ({...props}) => <DomainSelect
//   label={"14. №, дата акта от Департамента"}
//   reducerKey={"referralAdditionalInfo"}
//   reducerArrayKey={"referralAdditionalInfos"}
//   reducerAction={getReferralAdditionalInfos}
//   {...props}
// />
