import EmployeeCRUDTable from "../CRUD/Tables/EmployeeCRUDTable";
import {Button, Dialog, DialogContent, Divider} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import Stack from "@mui/material/Stack";

export default function EmployeeField({
                                          departmentId,
                                          label,
                                          value,
                                          object,
                                          onChange,
                                          extraFilter,
                                          disabled
                                      }) {
    const [open, setOpen] = useState(false);
    const authUser = useSelector(state => state.employee.authorizedEmployee);

    return (
        <>
            <Grid container spacing={1}>
                {/* Field Line */}
                <Grid item xs={12}>
                    <Toolbar>
                        <Typography style={{fontWeight: "bold", width: "50%"}}>
                            {label || "Сотрудник"}:{" "}
                        </Typography>
                        <Typography>
                            {object?.fullName}/{object?.position?.name}/
                            {object?.department?.name}
                        </Typography>
                        <Button disabled={disabled} onClick={() => setOpen(true)} variant={"outlined"}
                                style={{marginLeft: "10px"}}>Выбрать</Button>
                    </Toolbar>
                </Grid>
            </Grid>
            {/* Dialog Select*/}
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <span>Выбор сотрудника</span>
                        <Button onClick={() => {
                            onChange(authUser)
                            setOpen(false)
                        }}>Выбрать себя</Button>
                    </Stack>
                </DialogTitle>
                <Divider/>
                <DialogContent>
                    <EmployeeCRUDTable
                        extraFilter={extraFilter}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            onChange(record);
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button disabled={disabled}
                            variant={"text"}
                            style={{marginLeft: "auto"}}
                            onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
