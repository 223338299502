import m_07_2175_colorized from '././ust-kamenogorsk/№ KZ.M.07.2175.svg'
import m_07_2175 from '././ust-kamenogorsk/№ KZ.M.07.2175 black.svg'
import m_07_0719_colorized from '././ust-kamenogorsk/№ KZ.T.07.E0719.svg'
import m_07_0719 from '././ust-kamenogorsk/№ KZ.T.07.E0719 black.svg'
import altay from '././ust-kamenogorsk/Алтай.svg'
import katonkaragay from '././ust-kamenogorsk/Катон-Карагай.svg'
import kurchum from '././ust-kamenogorsk/Курчумский.svg'
import zaisan from '././ust-kamenogorsk/Зайсан район ВКО.svg'
import tarbagatai from '././ust-kamenogorsk/Тарбагатайский район ВКО.svg'


export default [
  m_07_2175_colorized,
  m_07_2175,
  m_07_0719_colorized,
  m_07_0719,
  altay,
  katonkaragay,
  kurchum,
  zaisan,
  tarbagatai
]
