import {useFormContext, Controller, useController} from 'react-hook-form';
import React from "react";
import {FormHelperText, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import Paper from "@mui/material/Paper";
import Dropzone, {useDropzone} from 'react-dropzone';
import {CloudUpload, InsertDriveFile} from "@material-ui/icons";
import {makeStyles} from "@mui/styles";
import List from "@mui/material/List";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#eee",
        textAlign: "center",
        cursor: "pointer",
        color: "#333",
        padding: "10px",
        marginTop: "20px",
    },
    icon: {
        marginTop: "16px",
        color: "#888",
        fontSize: "42px",
    }
}))

export function RHFUploadSingleFile({name, ...other}) {
    const {control} = useFormContext();
    const styles = useStyles()
    const {field, fieldState: {error}} = useController({name: 'acts'});

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        onDrop: acceptedFiles1 => {
            field.onChange(acceptedFiles1)
        },
        accept: {
            'application/pdf': ['.pdf'],
        }
    });

    return (
        <>
            <Paper className={styles.root} {...getRootProps()} variant="outlined">
                <CloudUpload className={styles.icon}/>
                <input {...getInputProps()} multiple={false}/>
                <p>Перетащите файл или нажмите сюда</p>
            </Paper>
            <List>
                {
                    acceptedFiles.map((f, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <InsertDriveFile/>
                            </ListItemIcon>
                            <ListItemText primary={f.name} secondary={f.size}/>
                        </ListItem>
                    ))
                }
            </List>
            <FormHelperText error>
                {error?.message ? error?.message : null}
            </FormHelperText>
        </>
    );
}
