import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDesinsectionRequestByKey,
} from "../../../redux/actions";
import CreateResult from "../Popups/CreateResult";

export const ResultCreateModal = ({ open, setOpen, data, action = 'createByObjectReferral' }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { desinsectionRequest } = useSelector((s) => s.desinsectionRequest);

  const handleReset = () => {};

  const handleSubmit = async (data) => {
    setLoading(true);

    const desinsectionRequestId = desinsectionRequest?.id
      ? desinsectionRequest.id
      : null;

    const refreshAction = getDesinsectionRequestByKey(desinsectionRequestId);

    await dispatch(refreshAction);

    setLoading(false);
    setOpen(false);
    handleReset();
  };

  return (
    open && (
      <CreateResult
        action={action}
        title={"Журнал: Создание"}
        referral={data}
        onClose={() => setOpen(false)}
        onSubmit={(response) => {
          handleSubmit(response)
        }}
      />
    )
  );
};

ResultCreateModal.defaultProps = {};
