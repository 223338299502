import { 
    SERVICE_ROUTE_GET_REQUEST, SERVICE_ROUTE_GET_SUCCESS, SERVICE_ROUTE_GET_ERROR, 
    SERVICE_ROUTE_GET_BY_KEY_REQUEST, SERVICE_ROUTE_GET_BY_KEY_SUCCESS, SERVICE_ROUTE_GET_BY_KEY_ERROR, 
    SERVICE_ROUTE_ADD_REQUEST, SERVICE_ROUTE_ADD_SUCCESS, SERVICE_ROUTE_ADD_ERROR, 
    SERVICE_ROUTE_EDIT_REQUEST, SERVICE_ROUTE_EDIT_SUCCESS, SERVICE_ROUTE_EDIT_ERROR, 
    SERVICE_ROUTE_DELETE_REQUEST, SERVICE_ROUTE_DELETE_SUCCESS, SERVICE_ROUTE_DELETE_ERROR
} from "../constants/serviceRouteConstants";

const initialState = {
    serviceRoutes: [],
    serviceRoute: {},
    loading: false,
    success: null,
    error: null,
    serviceRouteRequestedCount: 0,
};

export const serviceRouteReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_ROUTE_GET_REQUEST:
            return {
                ...state,
                serviceRouteRequestedCount: ++state.serviceRouteRequestedCount,
                loading: true,
            };
        case SERVICE_ROUTE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceRoutes: action.payload,
                success: true,
            };
        case SERVICE_ROUTE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_ROUTE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ROUTE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceRoute: action.payload,
                success: true,
            };
        case SERVICE_ROUTE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_ROUTE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ROUTE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_ROUTE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_ROUTE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ROUTE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_ROUTE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_ROUTE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ROUTE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_ROUTE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
