import React, {useCallback, useMemo, useState} from "react";
import {Alert, AlertTitle} from "@mui/material";

const alertStyle = {
    position:"fixed",
    top:"50%",
    left:"50%",
    transform:"translate(-50%,-50%)",
    width:"30%",
    zIndex:100000000000
}

const AlertSuccess = ({message})=>{
    return (
        <Alert style={alertStyle} severity="success">
            <AlertTitle>Успешно</AlertTitle>
            {message}
        </Alert>
    )
}

const AlertError = ({message})=>{
    return (
        <Alert style={alertStyle} severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {message}
        </Alert>)
}

function useAlert(shownDefault = true, typeDefault = 1, messageDefault="Hi, I am a message!") {
    const [shown, _setShown] = useState(shownDefault);
    const [type, _setType] = useState(typeDefault)
    const [message, _setMessage]=useState(messageDefault)


    const setShown = useCallback((state) => {_setShown(state)}, []);
    const setMessage = useCallback((state) => {_setMessage(state)}, []);
    const setType = useCallback((type) =>{_setType(type)} , []);


    const show = useCallback((type, message) => {
        setType(type)
        setMessage(message)
        setShown(true)
        setTimeout(()=>{
            setShown(false)
        },3000)
    }, [setShown, setType,setMessage]);
    const hide = useCallback(() => {
        setShown(false)
    }, [setShown]);

    const render = useCallback(() => {
        if (shown){
            switch (type){
                case 1:
                    return <AlertSuccess message={message}/>
                case 0:
                    return <AlertError message={message} />

            }
        }
    }, [shown]);

    return useMemo(() => ({
            show,
            hide,
            render,
        }
    ), [
        show,
        hide,
        render,
    ]);
}

export default useAlert;
