import { 
    PROTOCOL_FORM_GET_REQUEST, PROTOCOL_FORM_GET_SUCCESS, PROTOCOL_FORM_GET_ERROR, 
    PROTOCOL_FORM_GET_BY_KEY_REQUEST, PROTOCOL_FORM_GET_BY_KEY_SUCCESS, PROTOCOL_FORM_GET_BY_KEY_ERROR, 
    PROTOCOL_FORM_ADD_REQUEST, PROTOCOL_FORM_ADD_SUCCESS, PROTOCOL_FORM_ADD_ERROR, 
    PROTOCOL_FORM_EDIT_REQUEST, PROTOCOL_FORM_EDIT_SUCCESS, PROTOCOL_FORM_EDIT_ERROR, 
    PROTOCOL_FORM_DELETE_REQUEST, PROTOCOL_FORM_DELETE_SUCCESS, PROTOCOL_FORM_DELETE_ERROR
} from "../constants/protocolFormConstants";

const initialState = {
    protocolForms: [],
    protocolForm: {},
    loading: false,
    success: null,
    error: null,
    protocolFormRequestedCount: 0,
};

export const protocolFormReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PROTOCOL_FORM_GET_REQUEST:
            return {
                ...state,
                protocolFormRequestedCount: ++state.protocolFormRequestedCount,
                loading: true,
            };
        case PROTOCOL_FORM_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                protocolForms: action.payload,
                success: true,
            };
        case PROTOCOL_FORM_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FORM_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FORM_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                protocolForm: action.payload,
                success: true,
            };
        case PROTOCOL_FORM_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FORM_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FORM_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_FORM_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FORM_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FORM_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_FORM_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_FORM_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_FORM_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_FORM_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
