import useLoader from "../../hooks/useLoader";
import {Button, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@mui/styles";
import {Table} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import DomainSelect from "../Select/DomainSelect";
import FilterDatePicker from "../DatePicker/FilterDatePiecker";
import FilterDatePicker2 from "../DatePicker/FilterDatePicker2";
import {cleanObject} from "../../functions/viewDataMap";
import CRUDFilter from "./CRUDFilter";
import CollapsibleCRUDTable from "./CollapsibleCRUDTable";
import CRUDTableContext from "./CRUDTableContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

const useStyles = makeStyles({
    trClickable: {
        cursor: "pointer",
    },
});


const CRUDTable = ({
                       extraFilter,
                       defaultFilter,
                       searchPlaceholder,
                       search,
                       searchByIdHide,
                       columns,
                       onClickDetailsButton,
                        rowClassNameHandler,
                       onClickEditButton,
                       onClickDeleteButton,
                       onClickCreateButton,
                       detailsVisibleFunc,
                       editVisibleFunc,
                       deleteVisibleFunc,
                       extraActions,
                       reloadValue,
                       rowKey,
                       searchByOperator,
                       extraHeader,
                       filterFields,
                       onRowClick,
                       CollapseCRUDTable,
                       searchFields,
                       sendFilterValues,
                       searchBy,
                       pagination,
                       otherColumns,
                       collapsibleTable,
                       collapsedTableTitle,
                       reducerFilterKey,
                       reducerParentKey,
                       disableCreateButton,
                       onResult,
                       onData,
                       mapData,
                       extraQuery,
                       onDidRemoveFilter,
                       wait,
                       filterId,
                       ...props
                   }) => {
    const {t} = useTranslation();

    const classes = useStyles();
    const _columns = useMemo(() => {
        const result = columns.filter(_ => !_.hidden);

        if (extraActions)
            for (let i = 0; i < extraActions.length; i++)
                result.push(extraActions[i]);

        if (onClickDetailsButton || onClickEditButton || onClickDeleteButton) {
            if (!result.find(el => el.key === 'actions')) {
                result.push({
                    title: 'Действия',
                    key: 'actions',
                    fixed: 'right',
                    width: 100,
                    render: (_, record) => {
                        return (
                            <>
                            {onClickDetailsButton && <VisibilityIcon style={{fontSize: '1.5rem', marginRight: '20px'}} onClick={() => onClickDetailsButton(record.id)}/>}
                            {editVisibleFunc ?
                                ((onClickEditButton && editVisibleFunc(record)) && <EditIcon style={{fontSize: '1.5rem', marginRight: '20px'}} onClick={() => onClickEditButton(record.id)}/>)
                                : onClickEditButton && <EditIcon style={{fontSize: '1.5rem', marginRight: '20px'}} onClick={() => onClickEditButton(record.id)}/>}

                                {deleteVisibleFunc ?
                                    ((onClickDeleteButton && deleteVisibleFunc(record)) && <DeleteForeverIcon style={{fontSize: '1.5rem'}} onClick={() => onClickDeleteButton(record.id)}/>)
                                    : onClickDeleteButton && <DeleteForeverIcon style={{fontSize: '1.5rem'}} onClick={() => onClickDeleteButton(record.id)}/>}
                            </>
                        )
                    },
                })
            };
        }

        return result;
    }, [columns, extraActions, onClickDeleteButton, onClickEditButton, onClickDetailsButton]);

    const [data, setData] = useState([]);
    const Context = CRUDTableContext

    const [total, setTotal] = useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(pagination ? 10 : null);

    const {loading, start, stop} = useLoader(true);
    const paging = useMemo(
        () => ({take, skip, returnCount: true}),
        [take, skip]
    );
    const [sort] = useState({id: {operator: "desc"}});

    console.log('defaultFilter: ' + JSON.stringify(defaultFilter));

    const [filter, setFilter] = useState(defaultFilter || {});
    const [searchValue, setSearchValue] = useState(defaultFilter || {});
    const [advancedSearchValue, setAdvancedSearchValue] = useState({});

    useEffect(() => {
        if (!filterId) {
            return;
        }

        const oldFilterString = localStorage.getItem(filterId);
        const oldFilter = oldFilterString ? JSON.parse(oldFilterString) : null;

        if (!oldFilter) {
            return;
        }

        setFilter(oldFilter.filter);
        setSearchValue(oldFilter.searchValue);
        setAdvancedSearchValue(oldFilter.advancedSearchValue);
    }, [filterId]);

    const fetch = useCallback(async (reloadExtraFilter) => {
        start();
        extraQuery = extraQuery || {};
        if (typeof search === 'function') {
            search({paging, sort, filter: {...filter, ...(reloadExtraFilter || extraFilter || {})}, ...extraQuery})
                .then(async (data) => {
                    if (mapData)
                        data.result = mapData(data.result);
                    if (onData) {
                        await onData(data.result);
                    }
                    if (onResult) {
                        await onResult(data);
                    }
                    setData(data.result);
                    setTotal(data.total);
                })
                .catch(alert)
                .finally(stop);
        } else {
            if (mapData && search?.result)
                search.result = mapData(search.result);
            if (onData) {
                await onData(search?.result);
            }

            setData(search?.result);
            setTotal(search?.total);
            stop();
        }
    }, [paging, filter, extraFilter, sort, start, stop, search]);
    useEffect(() => {
        if (wait)
            return;

        fetch(extraFilter);
    }, [skip, take, paging, filter, extraFilter, search, reloadValue, wait]);

    const rowClassName = useMemo(() => {
        const classNames = [];
        if (onRowClick) classNames.push(classes.trClickable);
        return classNames.join(" ");
    }, [onRowClick, classes]);


    const handleSearchPanelChange = (val, key, compareType, operandPosition, filterType, key1, key2) => {
        switch (filterType) {
            case "normal":
            case "text":
                setSearchValue(state => {
                    let filter = state[key1 || key];

                    const stateObject = {
                        ...state
                    };

                    if (key2) {
                        if (!filter) {
                            filter = {};
                        }
                        filter[key2] = {
                            ...filter[key2],
                            operator: compareType,
                            [`operand${operandPosition}`]: val,
                        }
                        stateObject[key1 || key] = filter;
                    } else {
                        stateObject[key1 || key] = {
                            ...state[key1 || key],
                            operator: compareType,
                            [`operand${operandPosition}`]: val,
                        };
                    }
                    if (!val) {
                        delete stateObject[key1 || key][`operand${operandPosition}`];
                        if (!stateObject[key1 || key].operand1 && !stateObject[key1 || key].operand2) {
                            stateObject[key1 || key] = null;
                        }
                    }
                    return stateObject;
                });
                break
            case "advanced":
                setAdvancedSearchValue(state => {
                    const stateObject = {
                        ...state,
                        [key]: val
                    }
                    if (!val) {
                        stateObject[key] = null
                    }
                    return stateObject
                })
                break

        }


    };
    const applyFilter = () => {
        setSkip(0);
        cleanObject(searchValue);

        setFilter(state => {
            return {
                //...state,
                ...searchValue,
                // advancedFilter: {
                //     ...advancedSearchValue
                // }
            }
        });

        if (!filterId) {
            return;
        }

        const currentFilter = {
            filter: filter,
            searchValue: searchValue,
            advancedSearchValue: advancedSearchValue
        }
        localStorage.setItem(filterId, JSON.stringify(currentFilter));
    }
    const removeFilter = () => {
        setSearchValue({})
        setAdvancedSearchValue({})
        setFilter({});

        onDidRemoveFilter && onDidRemoveFilter();

        if (!filterId) {
            return;
        }

        const currentFilter = {
            filter: {},
            searchValue: {},
            advancedSearchValue: {}
        }
        localStorage.setItem(filterId, JSON.stringify(currentFilter));
    }
    return (
        <>{
            (searchFields || filterFields) && <CRUDFilter filter={filter} rightChildren={
                onClickCreateButton && <Button onClick={onClickCreateButton}>Добавить</Button>
            }>
                {searchFields && searchFields.map((field, idx) => {
                    return (
                        <Box
                            key={idx}
                            sx={{
                                margin: "0 0 0 0",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <TextField
                                type="text"
                                label={field.title}
                                value={(() => {
                                    switch (field.filterType) {
                                        case "normal":
                                            let value = searchValue[field.key1 || field.key];
                                            if (field.key2 && value) {
                                                value = value[field.key2];
                                            }
                                            return value?.[`operand${field.operandPosition}`] || ""
                                        case "advanced":
                                            return advancedSearchValue?.[field.key] || ""
                                    }
                                })()}
                                style={{
                                    margin: "8px 0"
                                }}
                                onChange={(ev) => handleSearchPanelChange(ev.target.value, field.key, field.compareType, field.operandPosition, field.filterType,
                                    field.key1, field.key2)}

                            />
                        </Box>
                    )
                })}

                <Grid container style={{
                    margin: "8px 0 8px -10px"
                }} xs={12}>
                    {filterFields && filterFields.map((field, idx) => {
                        switch (field.type) {
                            case "normal":
                                return <Grid key={idx} style={{
                                    marginLeft: "10px"
                                }} item xs={2}>
                                    <DomainSelect
                                        label={field.title}
                                        fullWidth
                                        margin={"none"}
                                        filterQuery={field.filterQuery}
                                        reducerKey={field.reducerKey}
                                        reducerArrayKey={field.reducerArrayKey}
                                        reducerAction={field.reducerAction}
                                        reducerCountKey={field.reducerCountKey}
                                        parentKey={field.parentKey}
                                        parentKey2={field?.parentKey2}
                                        parentKey3={field?.parentKey3}
                                        parentId={field.parentKey && searchValue[field.parentKey]}
                                        parentId2={field?.parentKey2 && searchValue[field?.parentKey2]}
                                        parentId3={field?.parentKey3 && searchValue[field?.parentKey3]}
                                        optionLabelKey={field.optionLabelKey}
                                        initialOptions={field.options}
                                        filter={field.filter}
                                        value={(() => {
                                            switch (field.filterType) {
                                                case "normal":
                                                    let value = searchValue[field.key1 || field.key];
                                                    if (field.key2 && value) {
                                                        value = value[field.key2];
                                                    }
                                                    return value?.[`operand${field.operandPosition}`] || null
                                                case "advanced":
                                                    return advancedSearchValue?.[field.key] || ""
                                            }
                                        })()}
                                        filterOptions = {field.key === "divisionId" ? (opt) => !searchValue["departmentId"]?.[`operand1`] || (opt.departmentId === searchValue["departmentId"]?.[`operand1`]) : null}
                                        key={field.key}
                                        onChange={(val) => {
                                            if (sendFilterValues && (field.reducerKey && val)) {
                                                sendFilterValues(field.reducerKey, val)
                                            }
                                            handleSearchPanelChange(val, field.key, field.compareType, field.operandPosition, field.filterType, field.key1, field.key2)
                                            field.onChange && field.onChange(val);
                                        }}
                                    />
                                </Grid>
                            case "date":
                                return <Grid key={idx} style={{
                                    marginLeft: "10px"
                                }} item xs={2}>
                                    <FilterDatePicker key={field.key} label={field.title} value={(() => {
                                        switch (field.filterType) {
                                            case "normal":
                                                return searchValue[field.key] ? searchValue[field.key][`operand${field.operandPosition}`] ? searchValue[field.key][`operand${field.operandPosition}`] : null : null
                                        }
                                    })()}
                                                      handleFunction={(val) => {
                                                          if (sendFilterValues && (field.key && val)) {
                                                              sendFilterValues(field.key + field.operandPosition, val)
                                                          }
                                                          handleSearchPanelChange(val ? new Date(val) : null, field.key, field.compareType, field.operandPosition, field.filterType)
                                                      }}/>
                                </Grid>
                            case "date2":
                                return <Grid key={idx} style={{
                                    marginLeft: "10px"
                                }} item xs={2}>
                                    <FilterDatePicker2 key={field.key} label={field.title} value={(() => {
                                        switch (field.filterType) {
                                            case "normal":
                                                return searchValue[field.key] ? searchValue[field.key][`operand${field.operandPosition}`] ? searchValue[field.key][`operand${field.operandPosition}`] : null : null
                                        }
                                    })()}
                                                      handleFunction={(e) => {
                                                          const val = e.target.value;
                                                          if (sendFilterValues && (field.key && val)) {
                                                              sendFilterValues(field.key + field.operandPosition, val)
                                                          }
                                                          handleSearchPanelChange(val ? new Date(val) : null, field.key, field.compareType, field.operandPosition, field.filterType)
                                                      }}/>
                                </Grid>
                            case "text":
                                return <Grid key={idx} style={{
                                    marginLeft: "10px"
                                }} item xs={2}>
                                    <TextField key={field.key}
                                        label={field.title}
                                        InputLabelProps={{ shrink: true }}
                                        margin="normal"
                                        style={{marginTop: "0px"}}
                                        value={(() => {
                                            let value = searchValue[field.key1 || field.key];
                                            if (field.key2 && value) {
                                                value = value[field.key2];
                                            }
                                            return value?.[`operand${field.operandPosition}`] || null
                                        })()}
                                        onChange={(e) => {
                                            const val = e.target.value;
                                            if (sendFilterValues && (field.key)) {
                                                sendFilterValues(field.key + field.operandPosition, val)
                                            }
                                            handleSearchPanelChange(val, field.key, field.compareType, field.operandPosition, field.filterType, field.key1, field.key2)
                                        }}/>
                                </Grid>
                            default:
                                <></>
                        }

                    })}


                </Grid>
                <Grid container xs={12} marginBottom={2} justifyContent={"space-between"}>
                    <Grid container xs={10}>
                        <Button onClick={() => applyFilter()}>
                            Найти
                        </Button>
                        <Button style={{marginLeft: "10px"}} onClick={() => removeFilter()}>
                            Сбросить фильтры
                        </Button>
                    </Grid>
                </Grid>
            </CRUDFilter>
        }
            {collapsibleTable ?
                <Context.Provider value={{
                    CollapseCRUDTable: CollapseCRUDTable,
                    onRowClick: onRowClick,
                    reducerFilterKey: reducerFilterKey,
                    reducerParentKey: reducerParentKey,
                    columns: _columns,
                    rows: data,
                    collapsedTableTitle: collapsedTableTitle
                }}>
                    <CollapsibleCRUDTable otherColumns={otherColumns} {...props}/>
                </Context.Provider>

                :
                <Table
                    columns={_columns}
                    rowKey={rowKey}
                    dataSource={data}
                    loading={loading}
                    pagination={false}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (e) => {
                                if (e.target?.id !== 'deleteReferal' && e.target?.id !== 'copyReferal')
                                onRowClick && onRowClick(record, rowIndex)}, // click row
                            onDoubleClick: (event) => {
                            }, // double click row
                            onContextMenu: (event) => {
                            }, // right button click row
                            onMouseEnter: (event) => {
                            }, // mouse enter row
                            onMouseLeave: (event) => {
                            }, // mouse leave row
                        };
                    }}
                    rowClassName={rowClassNameHandler ? rowClassNameHandler : rowClassName}
                    {...props}
                />
            }


            {pagination && <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                labelRowsPerPage={"Количество записей на странице"}
                style={{alignItems: "center"}}
                component="div"
                count={total}
                rowsPerPage={take}
                page={skip / take}
                onPageChange={(e, page) => {
                    setSkip(page * take);
                }}
                onRowsPerPageChange={(event) => {
                    setSkip(0);
                    setTake(event.target.value);
                }}
            />}

        </>
    );
};

CRUDTable.defaultProps = {
    rowKey: "id",
    pagination: true
};

export default CRUDTable;
