import React, {useCallback, useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    TextField,
    Grid,
    Typography,
    Divider,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {
    editServiceWithoutActRequest,
} from "../../../../redux/actions";
import {
    PageContainer,
    Form,
    FormSection,
    CustomDatePicker,
    BasicTable,
    ConditionalContainer,
} from "../../../../components";

import {RequestStateChecker} from "../../../../functions";
import CreateProtocol from "../../../Protocol/Popups/CreateProtocol";
import EmployeeField from "../../../../components/Relations/EmployeeField";
import ResultsAndProtocolsSection from "../../../../sections/ResultsAndProtocolsSection";
import useReload from "../../../../hooks/useReload";
import CreateResult from "../../../Result/Popups/CreateResult";
import {addProtocol} from "../../../../redux/actions/protocolActions";
import EditResultAndProtocolSection from "../../../../sections/EditResultAndProtocolSection";
import useLoader from "../../../../hooks/useLoader";
import {protocolCRUD} from "../../../../http";

export const ServiceWithoutActRequestInfoSection = ({
                                                        setData,
                                                        setProtocolAddOpen,
                                                        protocolAddOpen,
                                                        data,
                                                        results,
                                                        fetch,
                                                        protocols,
                                                        createResultOpen,
                                                        setCreateResultOpen
                                                    }) => {
    const {t} = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch();
    const {
        start: startSubmit,
        stop: stopSubmit,
        loading: submitLoading,
    } = useLoader(false);

    const [loading, setLoading] = useState(false);

    const [request, setRequest] = useState(data);

    const {serviceWithoutActRequest} = useSelector(
        (s) => s.serviceWithoutActRequest
    );

    const stateCode = serviceWithoutActRequest?.state?.code?.toLowerCase();
    const state = new RequestStateChecker(stateCode);

    const [responsiblePerformerId, setResponsiblePerformerId] = useState({});
    const [specifications, setSpecifications] = useState([]);

    const [objects, setObjects] = useState([]);
    const [responsiblePerformer, setResponsiblePerformer] = useState({});
    const [departureDate, setDepartureDate] = useState(new Date());
    const [editResultOpen, setEditResultOpen] = useState(false);
    const [editResultId, setEditResultId] = useState(null);
    const [editProtocolOpen, setEditProtocolOpen] = useState(false);
    const [editProtocol, setEditProtocol] = useState(null);

    const {reload, reloadValue} = useReload();

    const handleChangeObject = useCallback(
        (objectKey, value) => {
            setData({ ...data, [objectKey]: value, [`${objectKey}Id`]: value.id });
        },
        [data]
    );

    const handleEditResultOpen = async (resultId) => {
        setEditResultId(resultId);
        setEditResultOpen(true);
    }

    const handleSpecialistUpdate = async (e) => {
        e.preventDefault();

        setLoading(true);

        const data = {
            ...request,
            reponsiblePerformerId: responsiblePerformerId,
        };

        console.log({data});

        // Updating
        await dispatch(editServiceWithoutActRequest(data));

        setLoading(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    // useEffect(() => {
    //   dispatch(getServiceWithoutActRequestByKey(requestId)).then(setRequest);
    // }, [dispatch, requestId]);

    useEffect(() => {
        setObjects(request.objects);
        setSpecifications(request.specifications);
        setResponsiblePerformer(request.setResponsiblePerformer);
        setDepartureDate(request.departureDate);
        setResponsiblePerformer(request.reponsiblePerformer);

    }, [request]);

    useEffect(() => {
        setRequest({...request, ...serviceWithoutActRequest});
    }, [serviceWithoutActRequest]);

    const handleEditProtocolOpen = async (protocolId) => {
        await startSubmit();

        const protocol = await protocolCRUD.get(protocolId);
        await setEditProtocol(protocol);
        await setEditProtocolOpen(true);

        await stopSubmit();
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <PageContainer>
                    <FormSection heading="Заявка" mb={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    label="Номер заявки"
                                    type="number"
                                    value={serviceWithoutActRequest?.number}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Сводная заявка"
                                    type="text"
                                    value={serviceWithoutActRequest?.contractRequestId}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Автор заявки"
                                    type="text"
                                    value={serviceWithoutActRequest?.createdBy?.fullName}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <CustomDatePicker
                                            label="Дата заявки"
                                            value={serviceWithoutActRequest?.createdAt}
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </FormSection>

                    <FormSection heading="Журналы и протоколы" mb={4}>
                        <ResultsAndProtocolsSection
                            results={results}
                            protocols={protocols}
                            onResultClick={async (_) => {
                                await handleEditResultOpen(_);
                            }}
                            onProtocolClick={async (_) => {
                                await handleEditProtocolOpen(_);
                            }}
                            resultsReloadValue={reloadValue}
                            fetch={fetch}
                            startLoading={async () => {
                                //await startSubmit();
                            }}
                            stopLoading={async () => {
                                //await stopSubmit();
                            }}
                        />
                    </FormSection>

                    <FormSection mb={4}>
                        <Typography component="h4" align="left" variant="h6">
                            Объекты ({objects?.length || 0})
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <BasicTable
                                    cells={[
                                        {id: "id", label: t("object.tableItem.id")},
                                        {id: "name", label: t("object.tableItem.name")},
                                        {
                                            id: "locality",
                                            label: t("object.tableItem.locality"),
                                            render: (row) => row?.locality?.name,
                                        },
                                        {
                                            id: "district",
                                            label: t("object.tableItem.district"),
                                            render: (row) => row?.district?.name,
                                        },
                                        {
                                            id: "city",
                                            label: t("object.tableItem.city"),
                                            render: (row) => row?.city?.name,
                                        },
                                        {
                                            id: "addressLine",
                                            label: t("object.tableItem.addressLine"),
                                        },
                                    ]}
                                    rows={objects}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                    <FormSection mb={4}>
                        <Typography component="h4" align="left" variant="h6">
                            Услуги ({specifications?.length || 0})
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <BasicTable
                                    cells={[
                                        {id: "id", label: t("specification.tableItem.id")},
                                        {
                                            id: "object",
                                            label: t("specification.tableItem.object"),
                                            render: ({object}) => object?.name,
                                        },
                                        {
                                            id: "serviceKind",
                                            label: t("specification.tableItem.serviceKind"),
                                            render: ({serviceKind}) => serviceKind?.name,
                                        },
                                        {
                                            id: "service",
                                            label: t("specification.tableItem.service"),
                                            render: ({service}) => service?.name,
                                        },
                                        {
                                            id: "unit",
                                            label: t("specification.tableItem.unit"),
                                            render: ({unit}) => unit?.name,
                                        },
                                        {
                                            id: "schedule",
                                            label: t("specification.tableItem.schedule"),
                                            render: ({schedule}) => schedule?.name,
                                        },
                                        {
                                            id: "count",
                                            label: t("specification.tableItem.quantity"),
                                        },
                                        {id: "price", label: t("specification.tableItem.price")},
                                    ]}
                                    rows={specifications}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </FormSection>

                    <FormSection heading="Лаборатория">
                        <Grid container spacing={2}>
                            <Grid item xs={5}>
                                <TextField
                                    label="Лаборатория"
                                    value={request?.department?.name}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    label="Зав. лабораторией"
                                    value={request?.responsible?.fullName}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <CustomDatePicker
                                    label="Дата выезда"
                                    value={departureDate}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </FormSection>

                    <ConditionalContainer
                        condition={
                            state.isProcessing() || state.isResearch() || state.isFinished()
                        }
                    >
                        <Divider style={{margin: "50px 0px"}}/>
                        <FormSection heading="Назначение ответственных за оказание услуги">
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <EmployeeField
                                        label={"Лаборант"}
                                        fullWidth
                                        object={data?.reponsiblePerformer}
                                        value={data?.reponsiblePerformerId}
                                        onChange={(performer) => {
                                            handleChangeObject('reponsiblePerformer', performer);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            {state.isProcessing() && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            loading={loading}
                                            onClick={handleSpecialistUpdate}
                                        >
                                            Сохранить
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )}
                        </FormSection>
                    </ConditionalContainer>
                </PageContainer>
                <EditResultAndProtocolSection
                    editResultOpen={editResultOpen}
                    setEditResultOpen={setEditResultOpen}
                    editProtocolOpen={editProtocolOpen}
                    setEditProtocolOpen={setEditProtocolOpen}
                    editResultId={editResultId}
                    editProtocol={editProtocol}
                    startLoading={async () => {
                        await startSubmit();
                    }}
                    stopLoading={async () => {
                        await stopSubmit();
                    }}
                    onEditResultSubmitted={async (_) => {
                        reload();
                    }}
                    results={results}
                />
                {createResultOpen && (
                    <CreateResult
                        action={"createByServiceWithoutActRequest"}
                        title={t("resultList.createByReferralText")}
                        onClose={() => setCreateResultOpen(false)}
                        onSubmit={async (response) => {
                            await fetch();
                        }}
                        referral={request}
                    />
                )}
                {protocolAddOpen && (
                    <CreateProtocol
                        action={"createByServiceWithoutActRequest"}
                        open={protocolAddOpen}
                        initNumber={data.number}
                        title={`Создание протокола для заявки без акта №${data.number}?`}
                        onClose={() => setProtocolAddOpen(false)}
                        onSubmit={(data) => {
                            return dispatch(
                                addProtocol(data, { sort: { id: { operator: "asc" } } })
                            ).then(fetch);
                        }}
                        setOpen={setProtocolAddOpen}
                        request={data}
                    />
                )}

            </Form>
        </>
    );
};
