export function changeData(data, setData, key, value) {
    setData({ ...data, [key]: value });
}

export function changeDataMany(data, setData, obj) {
    setData({ ...data, ...obj });
}

export function changeDataRel(data, setData, key, value) {
    setData({ ...data, [key]: value, [`${key}Id`]: value.id });
}    