import {getActStates} from "../../redux/actions/actStateActions";
import {getDepartments, getDivisions, getFilials} from "../../redux/actions";
import {getFilialDivisions} from "../../redux/actions/filialDivisionActions";

export const actsSearchFields = [
    {
        title: "ID",
        key: "id",
        compareType: "equals",
        operandPosition:1,
        filterType:"normal"
    },
    {
        title: "Номер",
        key: "number",
        compareType: "like",
        operandPosition:1,
        filterType:"normal"
    }
];

export const actsFilterFields = [
    {
        title: "Cтатус",
        key: "stateId",
        compareType: "equals",
        reducerKey: "actState",
        reducerArrayKey: "actStates",
        reducerAction: getActStates,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Филиал",
        key: "filialId",
        compareType: "equals",
        reducerKey: "filial",
        reducerArrayKey: "filials",
        reducerAction: getFilials,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Районное отделение",
        key: "filialDivisionId",
        compareType: "equals",
        reducerKey: "filialDivision",
        reducerArrayKey: "filialDivisions",
        reducerAction: getFilialDivisions,
        operandPosition: 1,
        parentKey: 'filialId',
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Лаборатория",
        key: "departmentId",
        compareType: "equals",
        reducerKey: "department",
        reducerArrayKey: "departments",
        reducerAction: getDepartments,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
    {
        title: "Отдел",
        key: "divisionId",
        compareType: "equals",
        reducerKey: "division",
        reducerArrayKey: "divisions",
        reducerAction: getDivisions,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    },
]