export const HEALTHCARE_ATTRIBUTE_GET_REQUEST = 'HEALTHCARE_ATTRIBUTE_GET_REQUEST';
export const HEALTHCARE_ATTRIBUTE_GET_SUCCESS = 'HEALTHCARE_ATTRIBUTE_GET_SUCCESS';
export const HEALTHCARE_ATTRIBUTE_GET_ERROR = 'HEALTHCARE_ATTRIBUTE_GET_ERROR';
export const HEALTHCARE_ATTRIBUTE_GET_BY_KEY_REQUEST = 'HEALTHCARE_ATTRIBUTE_GET_BY_KEY_REQUEST';
export const HEALTHCARE_ATTRIBUTE_GET_BY_KEY_SUCCESS = 'HEALTHCARE_ATTRIBUTE_GET_BY_KEY_SUCCESS';
export const HEALTHCARE_ATTRIBUTE_GET_BY_KEY_ERROR = 'HEALTHCARE_ATTRIBUTE_GET_BY_KEY_ERROR';
export const HEALTHCARE_ATTRIBUTE_ADD_REQUEST = 'HEALTHCARE_ATTRIBUTE_ADD_REQUEST';
export const HEALTHCARE_ATTRIBUTE_ADD_SUCCESS = 'HEALTHCARE_ATTRIBUTE_ADD_SUCCESS';
export const HEALTHCARE_ATTRIBUTE_ADD_ERROR = 'HEALTHCARE_ATTRIBUTE_ADD_ERROR';
export const HEALTHCARE_ATTRIBUTE_EDIT_REQUEST = 'HEALTHCARE_ATTRIBUTE_EDIT_REQUEST';
export const HEALTHCARE_ATTRIBUTE_EDIT_SUCCESS = 'HEALTHCARE_ATTRIBUTE_EDIT_SUCCESS';
export const HEALTHCARE_ATTRIBUTE_EDIT_ERROR = 'HEALTHCARE_ATTRIBUTE_EDIT_ERROR';
export const HEALTHCARE_ATTRIBUTE_DELETE_REQUEST = 'HEALTHCARE_ATTRIBUTE_DELETE_REQUEST';
export const HEALTHCARE_ATTRIBUTE_DELETE_SUCCESS = 'HEALTHCARE_ATTRIBUTE_DELETE_SUCCESS';
export const HEALTHCARE_ATTRIBUTE_DELETE_ERROR = 'HEALTHCARE_ATTRIBUTE_DELETE_ERROR';
