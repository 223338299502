import axios from "axios";
import {LOGIN_ROUTE} from "../routes";

// super admin
// admin
// q1234567q

const $host = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_PROD_API_URL
});

const $authHost = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : process.env.REACT_PROD_API_URL
});


export const $s3host = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.REACT_APP_S3_API_URL : process.env.REACT_APP_PROD_S3_API_URL
})

const authIntterceptor = (config) => {
    config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;

    const csrf = localStorage.getItem("csrfToken");

    if (csrf) {
        config.headers["X-XSRF-TOKEN"] = csrf;
    }

    return config;
};

$s3host.interceptors.request.use(authIntterceptor)

const logout = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    window.location.href = LOGIN_ROUTE
}

$authHost.interceptors.request.use(authIntterceptor);


$authHost.interceptors.response.use(response => {
    return response;
}, async error => {
    if (error.response.status === 401) {
        console.log(error.response);

        if (`/${window.location.href.split('/')[3]}` !== LOGIN_ROUTE) {
            logout();
        }
        // const inf = JSON.parse(localStorage.getItem('userInfo'))
        // const refreshToken = localStorage.getItem('refreshToken')
        // if (inf && refreshToken) {
        //     if (error.response.config.url !== '/api/v1/refreshToken') {
        //         const {data} = await $authHost.post("/api/v1/refreshToken",
        //             {username: inf.userName, refreshToken})
        //         localStorage.setItem("token", data.token);
        //         localStorage.setItem("refreshToken", data.refreshToken);
        //         window.location.reload()
        //     } else {
        //         logout()
        //     }
        // } else if (`/${window.location.href.split('/')[3]}` !== LOGIN_ROUTE) {
        //     logout()
        // }
    }
    throw error;
});


export const JWTHeader = () => {
    return {};
};

export function postForDownloadFile(
    data,
    url,
    contentType = "application/json"
) {
    const headers = JWTHeader();
    const promise = axios.request({
        method: "POST",
        url,
        headers: {
            "Content-Type": contentType,
            ...headers,
        },
        data: data,
        responseType: "blob",
    });
    return promise;
}

export function postForDownloadFileS3(
    data,
    url,
    contentType = 'application/json',
) {
    return axios.request({
        method: 'POST',
        url,
        headers: {
            'Content-Type': contentType,
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        data: data,
        responseType: 'blob',
    });
}


export {$host, $authHost};
export * from "./CRUD";
