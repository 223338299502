import React, {useEffect, useState} from "react";
import DashboardLayout from "../../layouts/Dashboard";
import {useTranslation} from "react-i18next";
import {EyeOutlined, LoadingOutlined} from '@ant-design/icons';
import {Table, Typography, Space, Row, Col} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {getServiceByKey, getServices, getServiceChilds} from "../../redux/actions/serviceActions";
import ServiceDetails from "./Popups/ServiceDetails";
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv6";
import {getServiceKinds} from "../../redux/actions/serviceKindActions";
import {getServiceTypes} from "../../redux/actions/serviceTypeActions";
import {getUnitOfMeasures} from "../../redux/actions/unitOfMeasureActions";
import {getServiceGroups} from "../../redux/actions/serviceGroupActions";
import {getServiceSubGroups} from "../../redux/actions/serviceSubGroupActions";
import { parseSchema } from '../../functions/index';
import { mapService } from '../../functions/viewDataMap';

import ServiceCRUDTable from '../../components/CRUD/Tables/ServiceCRUDTable';
import {SERVICE_LIST_ADD_ROUTE, SERVICE_LIST_CLIENT_EDIT_ROUTE, SERVICE_LIST_EDIT_ROUTE} from "../../routes";
import {useHistory} from "react-router-dom";

let rows = [];

const {Text} = Typography;

const ServiceList = () => {
    const {t} = useTranslation();
    const {service, serviceRequestedCount} = useSelector((state) => state.service);
    const {serviceKinds, serviceKindRequestedCount} = useSelector((state) => state.serviceKind);
    const {serviceTypes, serviceTypeRequestedCount} = useSelector((state) => state.serviceType);
    const {unitOfMeasures, unitOfMeasureRequestedCount} = useSelector((state) => state.unitOfMeasure);
    const {serviceGroups, serviceGroupRequestedCount} = useSelector((state) => state.serviceGroup);
    const {serviceSubGroups, serviceSubGroupRequestedCount} = useSelector((state) => state.serviceSubGroup);

    const [jsonSchema, setJsonSchema] = useState(null);
    const [rowData, setRowData] = useState([])

    const expandedRowRender = () => {
        const columns = [
            {
                title: 'Подробнее',
                dataIndex: 'details',
                key: 'details',
                render: () => {
                    const values = rowData ? Object.values(rowData) : []
                    return (
                        <Row>
                            <Col span={12}>
                                <Space direction="vertical">
                                    <Text>Id:</Text>
                                    <Text>Наименование:</Text>
                                    <Text>Код:</Text>
                                    <Text>Направление:</Text>
                                    <Text>Проба:</Text>
                                    <Text>Результат:</Text>
                                    <Text>Родительская:</Text>
                                    <Text>Вид:</Text>
                                    <Text>Тип:</Text>
                                    <Text>Группа:</Text>
                                    <Text>Подгруппа:</Text>
                                    <Text>Единица измерения:</Text>
                                </Space>
                            </Col>
                            <Col span={12}>
                                <Space direction="vertical">
                                    {
                                        values.map(_ => <Text>{_}</Text>)
                                    }
                                </Space>
                            </Col>
                        </Row>
                    )
                }
            },
            {
                title: 'Форма',
                dataIndex: 'form',
                key: 'form',
                render: () =>
                    {
                        return (jsonSchema ?
                            <Form
                                validator={validator}
                                schema={jsonSchema ? parseSchema(jsonSchema) : {}}
                                disabled
                            >
                                <button style={{display: 'none'}}/>
                            </Form>
                            : <LoadingOutlined style={{ fontSize: '24px' }}/>)
                    }
            },
        ];

        const data = [{
            key: Math.floor(Math.random() * 1000),
            details: 'detail',
            form: 'form',
        }];

        return <Table columns={columns} dataSource={data} pagination={false}/>;

    };
    const setDetailsHandler = async (id) => {
        await dispatch(getServiceByKey(id));
        setDetailsOpen(true)
        setId(id);
    }

    const {push} = useHistory()

    const dispatch = useDispatch();

    const [query, setQuery] = useState({
        paging: {
          skip: 0,
          take: 10
        },
        filter: {
        },
        sort: {id: {operator: "asc"}
    }});

    const [id, setId] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);

    const localData = false;
    const state = useSelector((state) => state.service);
    if (!localData && state.services) {
        rows = state.services;
    }

    useEffect(() => {
        if (!localData) {
            if (serviceRequestedCount === 0) {
                dispatch(getServices(query));
            }
        }
    }, [dispatch])
    useEffect(() => {
        let response;
        const getServiceKindsAsync = async () => {
            response = await dispatch(getServiceKinds({ paging: { skip: 0 } }));
        }
        const getServiceTypesAsync = async () => {
            response = await dispatch(getServiceTypes());
        }
        const getUnitOfMeasuresAsync = async () => {
            response = await dispatch(getUnitOfMeasures({ paging: { skip: 0 } }));
        }
        const getServiceGroupsAsync = async () => {
            response = await dispatch(getServiceGroups({ paging: { skip: 0 } }));
        }
        const getServiceSubGroupsAsync = async () => {
            response = await dispatch(getServiceSubGroups({ paging: { skip: 0 } }));
        }
        if (serviceKindRequestedCount === 0) {
            getServiceKindsAsync();
        }
        if (serviceTypeRequestedCount === 0) {
            getServiceTypesAsync();
        }
        if (unitOfMeasureRequestedCount === 0) {
            getUnitOfMeasuresAsync();
        }
        if (serviceGroupRequestedCount === 0) {
            getServiceGroupsAsync();
        }
        if (serviceSubGroupRequestedCount === 0) {
            getServiceSubGroupsAsync();
        }
    }, [dispatch])
    useEffect(() => {
        const {
            referralSchema
        } = service || {};

        let referralSchemaJson = parseSchema(referralSchema);
        setJsonSchema(referralSchemaJson?.jsonSchema);
    }, [service])

    const [parentService, setParentService] = useState(null);

    useEffect(() => {
        if (!parentService || !parentService.id)
            return;
        dispatch(getServiceChilds({ item: parentService, query: query, parentId: parentService.id }));
    }, [dispatch, parentService]);

    const onExpand = (expanded, record) => {
        if (!expanded || record.isChildsRequested || !record.id)
            return;
        setParentService(record.data);
    };

    const data = mapService(rows, {
        serviceGroups,
        serviceSubGroups,
        serviceKinds,
        serviceTypes,
        unitOfMeasures
    });

    return (
        <DashboardLayout>
            <ServiceCRUDTable
                searchByIdHide={true}
                onClickCreateButton={() => push(SERVICE_LIST_ADD_ROUTE)}
                onClickEditButton={(id) => push(SERVICE_LIST_EDIT_ROUTE + `/${id}`)}

                extraActions={[
                    {
                        title: 'Действия',
                        key: 'operation',
                        fixed: 'right',
                        width: 100,
                        render: (_) => {
                            return(
                                <EyeOutlined style={{fontSize: '1.5rem'}} onClick={() => setDetailsHandler(_.id)}/>
                            )
                        },
                    }
                ]}
                />
            <ServiceDetails action={'details'} title={t("referralList.detailsText")} open={detailsOpen} setOpen={setDetailsOpen} />
        </DashboardLayout>
    )
};

export default ServiceList;
