import forms_22_28 from './22-28.js'
import forms_29_35 from './29-35.js'
import forms_36_42 from './36-42.js'
import forms_43_48 from './43-48.js'
import forms_49_56 from './49-56.js'
import forms_57_61 from './57-61.js'
import forms_62_68 from './62-68.js'
import forms_69_74 from './69-74.js'
import forms_75_81 from './75-81.js'
import forms_82_88 from './82-88.js'
import forms_285_289 from './285-289.js'

const forms = {
  ...forms_22_28,
  ...forms_29_35,
  ...forms_36_42,
  ...forms_43_48,
  ...forms_49_56,
  ...forms_57_61,
  ...forms_62_68,
  ...forms_69_74,
  ...forms_75_81,
  ...forms_82_88,
  ...forms_285_289
}
export default forms

export function isFieldVersion(field, version) {
  if (version > 1 && field.version != version) {
    return false;
  } else if (version == 1 && field.version > 1) {
    return false;
  }

  return true;
}

/* eslint-disable no-throw-literal, no-unused-vars */
function validate(obj){
  for(let protocolForm of Object.values(obj)){
    if(!protocolForm.fields.every(({ id }) => id !== '')) throw 'id is empty'+JSON.stringify(protocolForm)
     if((new Set(protocolForm.fields)).size !== protocolForm.fields.length) throw 'ids duplicated'+JSON.stringify(protocolForm)
  }
}
/* eslint-enable no-throw-literal, no-unused-vars */

export const common = [
  {
    type: 'employee',
    id: 'research_specialist',
    label: 'Ф.И.О.(при наличии), должность специалиста проводившего исследование'
  },
  {
    type: 'employee',
    id: 'lab_director',
    label: 'Ф.И.О.(при наличии), подпись заведующего лабораторией'
  },
  {
    type: 'employee',
    id: 'organization_leader',
    label: 'Руководитель организации (заместитель) (Ф.И.О (при наличии), подпись)'
  }
]
