import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGenders } from "../../../redux/actions/genderActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function PatientDetails({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [fullName, setFullName] = useState('');
    const [identityNumber, setIdentityNumber] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [yearsOld, setYearsOld] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [rpnId, setRpnId] = useState('');
    const [genderId, setGenderId] = useState('');
    const { loading, patients, patient } = useSelector((state) => state.patient);
    const { genders } = useSelector((state) => state.gender);
    const emptyState = () => {
        setId(null);
        setFirstName('');
        setLastName('');
        setSecondName('');
        setFullName('');
        setIdentityNumber('');
        setBirthDate('');
        setYearsOld('');
        setPhoneNumber('');
        setRpnId('');
        setGenderId('');
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, genderId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, firstName, lastName, secondName, fullName, identityNumber, birthDate, yearsOld, phoneNumber, rpnId, genderId
        } = patient || {};
        setId(id);
        setFirstName(firstName);
        setLastName(lastName);
        setSecondName(secondName);
        setFullName(fullName);
        setIdentityNumber(identityNumber);
        setBirthDate(birthDate);
        setYearsOld(yearsOld);
        setPhoneNumber(phoneNumber);
        setRpnId(rpnId);
        setGenderId(genderId);
    }, [patient, patients])
    useEffect(() => {
        let response;
        const getGendersAsync = async () => {
            response = await dispatch(getGenders());
        }
        if (genders.length == 0)
        {
            getGendersAsync();
        }
    }, [dispatch, action])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    Имя: {firstName}
                                </Grid>
                                <Grid item xs={6}>
                                    Фамилия: {lastName}
                                </Grid>
                                <Grid item xs={6}>
                                    Отчество: {secondName}
                                </Grid>
                                <Grid item xs={6}>
                                    ФИО: {fullName}
                                </Grid>
                                <Grid item xs={6}>
                                    ИИН: {identityNumber}
                                </Grid>
                                <Grid item xs={6}>
                                    Дата рождения: {birthDate}
                                </Grid>
                                <Grid item xs={6}>
                                    Возраст: {yearsOld}
                                </Grid>
                                <Grid item xs={6}>
                                    Телефон: {phoneNumber}
                                </Grid>
                                <Grid item xs={6}>
                                    ID системы РПН: {rpnId}
                                </Grid>
                                <Grid item xs={6}>
                                    Пол: {genders.find(_ => _.id == genderId)?.name}
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
