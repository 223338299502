export const GENDER_GET_REQUEST = 'GENDER_GET_REQUEST';
export const GENDER_GET_SUCCESS = 'GENDER_GET_SUCCESS';
export const GENDER_GET_ERROR = 'GENDER_GET_ERROR';
export const GENDER_GET_BY_KEY_REQUEST = 'GENDER_GET_BY_KEY_REQUEST';
export const GENDER_GET_BY_KEY_SUCCESS = 'GENDER_GET_BY_KEY_SUCCESS';
export const GENDER_GET_BY_KEY_ERROR = 'GENDER_GET_BY_KEY_ERROR';
export const GENDER_ADD_REQUEST = 'GENDER_ADD_REQUEST';
export const GENDER_ADD_SUCCESS = 'GENDER_ADD_SUCCESS';
export const GENDER_ADD_ERROR = 'GENDER_ADD_ERROR';
export const GENDER_EDIT_REQUEST = 'GENDER_EDIT_REQUEST';
export const GENDER_EDIT_SUCCESS = 'GENDER_EDIT_SUCCESS';
export const GENDER_EDIT_ERROR = 'GENDER_EDIT_ERROR';
export const GENDER_DELETE_REQUEST = 'GENDER_DELETE_REQUEST';
export const GENDER_DELETE_SUCCESS = 'GENDER_DELETE_SUCCESS';
export const GENDER_DELETE_ERROR = 'GENDER_DELETE_ERROR';
