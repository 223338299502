import React, { useMemo } from "react";
import { TextField } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker } from "@mui/lab";

export const CustomDatePicker = ({
  value,
  maxValue,
  onChange,
  label,
  fullWidth,
  required,
  disabled,
}) => {
  return useMemo(() => {
    return (
      <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
        <DatePicker
          views={["day"]}
          label={label}
          value={value}
          onChange={onChange}
          minDate={new Date("1910-03-01")}
          maxDate={maxValue}
          disabled={disabled}
          required={required}
          renderInput={(params) => (
            <TextField
              margin="normal"
              disabled={disabled}
              fullWidth={fullWidth}
              {...params}
            />
          )}
        />
      </LocalizationProvider>
    );
  }, [label, value, maxValue, onChange, fullWidth]);
};

CustomDatePicker.defaultProps = {
  required: true,
  disabled: false,
  fullWidth: true,
};
