import { Grid, CircularProgress } from "@mui/material";

const LoadingContainer = ({ children, loading }) => {
    return (
        <>
            {loading &&
            <Grid item xs={12}>
                <CircularProgress/>
            </Grid>}
            <>
            {children}
            </>
        </>
    )
}

export default LoadingContainer;