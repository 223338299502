import { FormControl } from "@mui/material";
import { useEffect, useMemo } from "react";
import * as React from "react";
import { useState } from "react";
import SelectField from "../Fields/SelectField";

const RestSelect = ({ restApi, query, isNullable, isNullLabel, items, ...props }) => {
    const [options, setOptions] = useState(items || []);
    
    useEffect(() => {
        async function search() {
            if (!restApi)
              return;

            const response = await restApi.search(query || { paging: { skip: 0 } });
            if (!isNullable) {
              setOptions(response.result);
            } else {
              const nullOption = {};
              nullOption[props.optionValueKey] = null;
              nullOption[props.optionLabelKey] = isNullLabel || 'Не указано';
              setOptions([nullOption, ...response.result]);
            }
        }

        search();
    }, [restApi, query]);
    return (
        <FormControl disabled={props.disabled} margin={props.margin || "normal"} {...props}>
            <SelectField options={options} {...props} />
        </FormControl>
    );
};

RestSelect.defaultProps = {
    optionValueKey: "id",
    optionLabelKey: "name",
};

export default RestSelect;