import PositionCRUDTable from "../CRUD/Tables/PositionCRUDTable";
import {Button, Dialog, DialogContent, Divider} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {positionCRUD} from "../../http";

export default function PositionField({
                                          departmentId,
                                          label,
                                          value,
                                          object,
                                          onChange,
                                          extraFilter,
                                          disabled,
                                          positionId
                                      }) {
    const [open, setOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState({})
    useEffect(()=>{
        if(positionId){
            positionCRUD.get(positionId).then(val=>{
                console.log(val);
                setSelectedValue(val)
            })
        }
    },[positionId])
    return (
        <>
            <Grid container>
                {/* Field Line */}
                <Grid item style={{width:"100%"}} xs={12}>
                    <Toolbar style={{display:"flex", justifyContent:"space-between"}}>
                        <Typography style={{fontWeight: "bold", width: "50%"}}>
                            {label || "Должность"}:{" "}
                        </Typography>
                        <Typography>
                            {selectedValue?.name}
                        </Typography>
                        <Button disabled={disabled} onClick={() => setOpen(true)}>Выбрать</Button>
                    </Toolbar>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор должности</DialogTitle>
                <Divider/>
                <DialogContent>
                    <PositionCRUDTable
                        extraFilter={extraFilter}
                        onClickCreateButton={undefined} // что бы не показывать кнопку создания
                        onRowClick={(record, rowIndex) => {
                            setSelectedValue(record)
                            onChange(record);
                            setOpen(false);
                        }}
                    />
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{marginLeft: "auto"}}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
