/* eslint-disable import/no-anonymous-default-export */
export default {
  '285': {
    name: 'РЕЗУЛЬТАТ иммуно-ферментного анализа',
    kazakhName: 'Иммундық-ферменттік талдау НӘТИЖЕСІ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии) обследуемого',
        kazakhLabel: 'Зерттелушінің тегі, аты, әкесінің аты (болған жағдайда),'
      },
      {
        id: 'age',
        type: 'number',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен жайы'
      },
      {
        id: 'place_of_work',
        type: 'text',
        label: 'Место работы',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'forwarder_org_name',
        type: 'text',
        label: 'Наименование учреждения направившего образец',
        kazakhLabel: 'Үлгіні жіберген мекеменің атауы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование пробы',
        kazakhLabel: 'Сынама атауы'
      },
      {
        id: 'first_or_repeated',
        type: 'switch',
        label: 'первичное или повторное исследование пробы',
        kazakhLabel: 'Сынаманы бірінші рет немесе қайталап зерттеу',
        options: [
          'первичное',
          'повторное'
        ]
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата и время забора материала',
        kazakhLabel: 'Материал алу күні және уақыты'
      },
      {
        id: 'probe_delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки пробы',
        kazakhLabel: 'Сынама жеткізілген күні, уақыты'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель обследования',
        kazakhLabel: 'Тексеру мақсаты'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результаты исследований',
        kazakhLabel: 'Зерттеу нәтижелері'
      },
      {
        id: 'antibodies',
        type: 'text',
        label: 'антитела к возбудителю',
        kazakhLabel: 'Қоздырғышқа антиденелер'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'antigenes',
        type: 'text',
        label: 'антигены',
        kazakhLabel: 'Антигендер'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'НД на метод исследования/испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      }
    ]
  },
  '286': {
    version:2,
    name: 'РЕЗУЛЬТАТ бактериологических исследований материала от людей',
    kazakhName: 'Адамдардан алынған материалдарды бактериологиялық зерттеу НӘТИЖЕСІ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии) обследуемого',
        kazakhLabel: 'Тексерушінің тегі, аты, әкесінің аты (болған жағдайда)'
      },
      {
        id: 'age',
        type: 'number',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен жайы'
      },
      {
        id: 'place_of_work',
        type: 'text',
        label: 'Место работы',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'forwarder_full_name',
        type: 'text',
        label: 'Наименование учреждений направивший образец',
        kazakhLabel: 'Үлгіні жіберген мекемелердің атауы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование пробы',
        kazakhLabel: 'Сынаманың атауы'
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата и время забора материала',
        kazakhLabel: 'Сынама алынған күні, уақыты'
      },
      {
        id: 'sample_delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки материала',
        kazakhLabel: 'Материалды жеткізу күні және уақыты'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель обследования',
        kazakhLabel: 'Тексеру мақсаты'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результат исследования',
        kazakhLabel: 'Зерттеу нәтижесі'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            '№',
            '1'
          ],
          [
            'Өсіріп алынған өсірінді',
            'Выделенная\r\n  культура',
            '&nbsp;',
            '2'
          ],
          [
            'Микробқа\r\n  қарсы препараттардың атаулары',
            '(Наименование\r\n  антимикробных препаратов)',
            '&nbsp;',
            '3'
          ],
          [
            'өсуінің\r\n  тежелу аймағының диаметрі(мм)',
            'диаметр зоны\r\n  задержки роста (мм)',
            '&nbsp;',
            '4'
          ],
          [
            'Сезімтал',
            'Чувствительный',
            '(S)',
            '5'
          ],
          [
            'Сезімталдығы\r\n  төмен',
            'Умеренно\r\n  резистентный',
            '(I)',
            '6'
          ],
          [
            'Төзімді',
            'Резистентный (R)',
            '&nbsp;',
            '7'
          ],
          [
            'Зерттеу\r\n  әдістеменің НҚ-ры',
            'НД на метод\r\n  испытаний',
            '&nbsp;',
            '8'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=1886 style='border-collapse:
        collapse;width:1414pt'>
       
        <col width=652 style='mso-width-source:userset;mso-width-alt:20864;width:489pt'>
        <col width=132 style='mso-width-source:userset;mso-width-alt:4224;width:99pt'>
        <col width=463 style='mso-width-source:userset;mso-width-alt:14805;width:347pt'>
        <col width=137 style='mso-width-source:userset;mso-width-alt:4394;width:103pt'>
        <col width=135 style='mso-width-source:userset;mso-width-alt:4309;width:101pt'>
        <col width=116 span=2 style='mso-width-source:userset;mso-width-alt:3712;
        width:87pt'>
        <col width=135 style='mso-width-source:userset;mso-width-alt:4309;width:101pt'>
        <tr height=56 style='height:42.0pt'>
         <td rowspan=3 height=114 class=xl63 width=652 style='border-bottom:1.0pt solid black;
         height:86.0pt;width:489pt'>№</td>
         <td class=xl63 width=132 style='border-left:none;width:99pt'>Өсіріп алынған
         өсірінді</td>
         <td class=xl63 width=463 style='border-left:none;width:347pt'>Микробқа қарсы
         препараттардың атаулары</td>
         <td class=xl63 width=137 style='border-left:none;width:103pt'>өсуінің тежелу
         аймағының диаметрі(мм)</td>
         <td class=xl63 width=135 style='border-left:none;width:101pt'>Сезімтал</td>
         <td class=xl63 width=116 style='border-left:none;width:87pt'>Сезімталдығы
         төмен</td>
         <td class=xl63 width=116 style='border-left:none;width:87pt'>Төзімді</td>
         <td class=xl63 width=135 style='border-left:none;width:101pt'>Зерттеу
         әдістеменің НҚ-ры</td>
        </tr>
        <tr height=37 style='height:28.0pt'>
         <td height=37 class=xl64 width=132 style='height:28.0pt;border-left:none;
         width:99pt'>Выделенная культура</td>
         <td class=xl64 width=463 style='border-left:none;width:347pt'>(Наименование
         антимикробных препаратов)</td>
         <td class=xl64 width=137 style='border-left:none;width:103pt'>диаметр зоны
         задержки роста (мм)</td>
         <td class=xl64 width=135 style='border-left:none;width:101pt'>Чувствительный</td>
         <td class=xl64 width=116 style='border-left:none;width:87pt'>Умеренно
         резистентный</td>
         <td class=xl64 width=116 style='border-left:none;width:87pt'>Резистентный (R)</td>
         <td class=xl64 width=135 style='border-left:none;width:101pt'>НД на метод
         испытаний</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl65 width=132 style='height:16.0pt;border-left:none;
         width:99pt'>&nbsp;</td>
         <td class=xl65 width=463 style='border-left:none;width:347pt'>&nbsp;</td>
         <td class=xl65 width=137 style='border-left:none;width:103pt'>&nbsp;</td>
         <td class=xl66 width=135 style='border-left:none;width:101pt'>(S)</td>
         <td class=xl66 width=116 style='border-left:none;width:87pt'>(I)</td>
         <td class=xl65 width=116 style='border-left:none;width:87pt'>&nbsp;</td>
         <td class=xl65 width=135 style='border-left:none;width:101pt'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl67 width=652 style='height:16.0pt;border-top:none;
         width:489pt'>1</td>
         <td class=xl67 width=132 style='border-top:none;border-left:none;width:99pt'>2</td>
         <td class=xl67 width=463 style='border-top:none;border-left:none;width:347pt'>3</td>
         <td class=xl67 width=137 style='border-top:none;border-left:none;width:103pt'>4</td>
         <td class=xl67 width=135 style='border-top:none;border-left:none;width:101pt'>5</td>
         <td class=xl67 width=116 style='border-top:none;border-left:none;width:87pt'>6</td>
         <td class=xl67 width=116 style='border-top:none;border-left:none;width:87pt'>7</td>
         <td class=xl67 width=135 style='border-top:none;border-left:none;width:101pt'>8</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl68 width=652 style='height:16.0pt;border-top:none;
         width:489pt'>&nbsp;</td>
         <td class=xl68 width=132 style='border-top:none;border-left:none;width:99pt'>&nbsp;</td>
         <td class=xl68 width=463 style='border-top:none;border-left:none;width:347pt'>&nbsp;</td>
         <td class=xl68 width=137 style='border-top:none;border-left:none;width:103pt'>&nbsp;</td>
         <td class=xl68 width=135 style='border-top:none;border-left:none;width:101pt'>&nbsp;</td>
         <td class=xl68 width=116 style='border-top:none;border-left:none;width:87pt'>&nbsp;</td>
         <td class=xl68 width=116 style='border-top:none;border-left:none;width:87pt'>&nbsp;</td>
         <td class=xl68 width=135 style='border-top:none;border-left:none;width:101pt'>&nbsp;</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="8" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="34"><col width="127"><col width="135"><col width="113"><col width="127"><col width="114"><col width="113"><col width="95"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="34" height="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="127" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өсіріп алынған өсірінді<br>Выделенная культура</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="135" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Микробқа қарсы <br>препараттардың атаулары<br>(Наименование <br>антимикробных препаратов)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">өсуінің тежелу аймағының диаметрі(мм)<br>диаметр зоны задержки роста (мм)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="127" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сезімтал<br>Чувствительный (S)<br></font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="114" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сезімталдығы төмен<br>Умеренно резистентный (I)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="113" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төзімді Резистентный ®</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="95" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="8" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">8</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },      
    ]
  },
  '287': {
    name: 'РЕЗУЛЬТАТ серологических исследований материала от людей',
    kazakhName: 'Адамдардан алынған материалдарды серологиялық зерттеулер НӘТИЖЕСІ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии) обследуемого',
        kazakhLabel: 'Тексерушінің тегі, аты, әкесінің аты (болған жағдайда),'
      },
      {
        id: 'age',
        type: 'number',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен жайы'
      },
      {
        id: 'place_of_work',
        type: 'text',
        label: 'Место работы',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'forwarder_org_name',
        type: 'text',
        label: 'Наименование учреждений направивший образец',
        kazakhLabel: 'Үлгіні жіберген мекемелердің атауы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование пробы',
        kazakhLabel: 'Сынаманың атауы'
      },
      {
        id: 'first_or_repeated',
        type: 'switch',
        label: 'первичное или повторное',
        kazakhLabel: 'Бірінші рет н/е қайталап',
        options: [
          'первичное',
          'повторное'
        ]
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата и время забора материала',
        kazakhLabel: 'Сынама алынған күні, уақыты'
      },
      {
        id: 'sample_delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки материала',
        kazakhLabel: 'Материалды жеткізу күні және уақыты'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель обследования',
        kazakhLabel: 'Тексеру мақсаты'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'serelogic_research_results',
        type: 'text',
        label: 'Результаты серологических исследований при выявлении антител к возбудителям',
        kazakhLabel: 'Қоздырғыштарға қарсы денелерді анықтаудағы серологиялық зерттеу нәтижелері'
      },
      {
        id: 'iersinoz',
        type: 'text',
        label: 'Иерсиниоз',
        kazakhLabel: 'Иерсиниоз',
        number: '1'
      },
      {
        id: 'pseudotuberkulez',
        type: 'text',
        label: 'Псевдотуберкулез',
        kazakhLabel: 'Жалған туберкуллез',
        number: '2'
      },
      {
        id: 'leptospiroz',
        type: 'text',
        label: 'Лептоспироз',
        kazakhLabel: 'Лептоспироз',
        number: '3'
      },
      {
        id: 'listerioz',
        type: 'text',
        label: 'Листериоз',
        kazakhLabel: 'Листериоз',
        number: '4'
      },
      {
        id: 'pasterelliz',
        type: 'text',
        label: 'Пастереллез',
        kazakhLabel: 'Пастереллез',
        number: '5'
      },
      {
        id: 'tiff',
        type: 'text',
        label: 'Сыпной тиф',
        kazakhLabel: 'Бөртпе сүзек',
        number: '6'
      },
      {
        id: 'tylaremia',
        type: 'text',
        label: 'Туляремия',
        kazakhLabel: 'Туляремия',
        number: '7'
      },
      {
        id: 'brucellez',
        type: 'text',
        label: 'Бруцеллез',
        kazakhLabel: 'Сарып',
        number: '8'
      },
      {
        id: 'hedelson_reaction',
        type: 'text',
        label: 'Реакция Хедльсона',
        kazakhLabel: 'Хедльсон реакциясы',
        number: '9'
      },
      {
        id: 'rait_reaction',
        type: 'text',
        label: 'Реакция Райта',
        kazakhLabel: 'Райта реакциясы',
        number: '10'
      },
      {
        id: 'rbp',
        type: 'text',
        label: 'РБП',
        kazakhLabel: 'РБП',
        number: '11'
      },
      {
        id: 'rsk',
        type: 'text',
        label: 'РСК',
        kazakhLabel: 'КБР',
        number: '12'
      },
      {
        id: 'syberia_yazva',
        type: 'text',
        label: 'Сибирская язва',
        kazakhLabel: 'Күйдіргі',
        number: '13'
      }
    ]
  },
  '288': {
    version:2,
    name: 'РЕЗУЛЬТАТ определения чувствительности к антимикробным препаратам',
    kazakhName: 'Микробтарға қарсы қолданылатын препараттарға сезімталдықты анықтау НӘТИЖЕСІ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии) обследуемого',
        kazakhLabel: 'Зерттелушінің тегі, аты, әкесінің аты (болған жағдайда),'
      },
      {
        id: 'age',
        type: 'number',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Мекен жайы'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'forwarder_org_name',
        type: 'text',
        label: 'Наименование учреждений направивший образец',
        kazakhLabel: 'Үлгіні жолдаған мекемелердің атауы'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемі'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование пробы',
        kazakhLabel: 'Сынама атауы'
      },
      {
        id: 'sample_delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки материала',
        kazakhLabel: 'Материалды жеткізу күні және уақыты'
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата и время забора материала',
        kazakhLabel: 'материалды алу күні және уақыты'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель обследования',
        kazakhLabel: 'Тексеру мақсаты'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результат исследования',
        kazakhLabel: 'Зерттеу нәтижесі'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            '№',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
            '1'
          ],
          [
            'Микробтарға\r\n  қарсы қолданылатын препараттардың атауы',
            'Наименование антимикробных\r\n  препаратов',
            '&nbsp;',
            '&nbsp;',
            '2'
          ],
          [
            'Зерттеу\r\n  әдістеменің НҚ-ры',
            'НД на метод испытаний',
            '&nbsp;',
            '&nbsp;',
            '3'
          ],
          [
            'Препараттардың\r\n  сезімталдығын анықтау',
            'Определение чувсвительности к\r\n  препаратам',
            'Сезімталдылығы жоғары',
            'Высокочувств.',
            '4'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Сезімталды',
            'Чувствительный',
            '5'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Сезімталдығы төмен',
            'Слабо чувств.',
            '6'
          ],
          [
            '&nbsp;',
            '&nbsp;',
            'Төзімді',
            'Устойчив',
            '7'
          ]
        ],
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=1474 style='border-collapse:
        collapse;width:1105pt'>
       
        <col width=643 style='mso-width-source:userset;mso-width-alt:20565;width:482pt'>
        <col width=153 style='mso-width-source:userset;mso-width-alt:4906;width:115pt'>
        <col width=164 style='mso-width-source:userset;mso-width-alt:5248;width:123pt'>
        <col width=99 style='mso-width-source:userset;mso-width-alt:3157;width:74pt'>
        <col width=92 style='mso-width-source:userset;mso-width-alt:2944;width:69pt'>
        <col width=71 style='width:53pt'>
        <col width=252 style='mso-width-source:userset;mso-width-alt:8064;width:189pt'>
        <tr height=56 style='height:42.0pt'>
         <td rowspan=4 height=189 class=xl71 width=643 style='border-bottom:1.0pt solid black;
         height:142.0pt;width:482pt'>№</td>
         <td class=xl65 width=153 style='width:115pt'>Микробтарға қарсы қолданылатын
         препараттардың атауы</td>
         <td class=xl65 width=164 style='width:123pt'>Зерттеу әдістеменің НҚ-ры</td>
         <td colspan=4 class=xl73 width=514 style='border-right:1.0pt solid black;
         border-left:none;width:385pt'>Препараттардың сезімталдығын анықтау</td>
        </tr>
        <tr height=57 style='height:43.0pt'>
         <td height=57 class=xl66 width=153 style='height:43.0pt;width:115pt'>Наименование
         антимикробных препаратов</td>
         <td class=xl66 width=164 style='width:123pt'>НД на метод испытаний</td>
         <td colspan=4 class=xl75 width=514 style='border-right:1.0pt solid black;
         border-left:none;width:385pt'>Определение чувсвительности к препаратам</td>
        </tr>
        <tr height=37 style='height:28.0pt'>
         <td height=37 class=xl67 width=153 style='height:28.0pt;width:115pt'>&nbsp;</td>
         <td class=xl67 width=164 style='width:123pt'>&nbsp;</td>
         <td class=xl66 width=99 style='width:74pt'>Сезімталдылығы жоғары</td>
         <td class=xl66 width=92 style='width:69pt'>Сезімталды</td>
         <td class=xl66 width=71 style='width:53pt'>Сезімталдығы төмен</td>
         <td class=xl66 width=252 style='width:189pt'>Төзімді</td>
        </tr>
        <tr height=39 style='height:29.0pt'>
         <td height=39 class=xl68 width=153 style='height:29.0pt;width:115pt'>&nbsp;</td>
         <td class=xl68 width=164 style='width:123pt'>&nbsp;</td>
         <td class=xl69 width=99 style='width:74pt'>Высокочувств.</td>
         <td class=xl69 width=92 style='width:69pt'>Чувствительный</td>
         <td class=xl69 width=71 style='width:53pt'>Слабо чувств.</td>
         <td class=xl69 width=252 style='width:189pt'>Устойчив</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl70 width=643 style='height:16.0pt;width:482pt'>1</td>
         <td class=xl69 width=153 style='width:115pt'>2</td>
         <td class=xl69 width=164 style='width:123pt'>3</td>
         <td class=xl69 width=99 style='width:74pt'>4</td>
         <td class=xl69 width=92 style='width:69pt'>5</td>
         <td class=xl69 width=71 style='width:53pt'>6</td>
         <td class=xl69 width=252 style='width:189pt'>7</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="7" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="35"><col width="179"><col width="218"><col width="132"><col width="90"><col width="107"><col width="81"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="35" height="144" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">№</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="179" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Микробтарға қарсы қолданылатын препараттардың атауы<br>Наименование антимикробных препаратов</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="218" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="4" width="411" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Препараттардың сезімталдығын анықтау<br>Определение чувсвительности к препаратам</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сезімталдылығы жоғары<br>Высокочувств.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сезімталды<br>Чувствительный</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сезімталдығы төмен<br>Слабо чувств.</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Төзімді<br>Устойчив</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="7" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">7</font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },      
    ]
  },
  '289': {
    name: 'РЕЗУЛЬТАТ полимеразной цепной реакции',
    kazakhName: 'Полимераздық тізбектеу реакциясы НӘТИЖЕСІ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество обследуемого (при наличии)',
        kazakhLabel: 'Тексерушінің тегі, аты, әкесінің аты (болған жағдайда)'
      },
      {
        id: 'iin',
        type: 'text',
        label: 'ИИН',
        kazakhLabel: 'ЖСН'
      },
      {
        id: 'age',
        type: 'number',
        label: 'Возраст',
        kazakhLabel: 'Жасы'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Домашний адрес',
        kazakhLabel: 'Үй мекенжайы'
      },
      {
        id: 'place_of_work',
        type: 'text',
        label: 'Место работы',
        kazakhLabel: 'Жұмыс орны'
      },
      {
        id: 'contact_number',
        type: 'text',
        label: 'Контактный телефон',
        kazakhLabel: 'Байланыс телефоны'
      },
      {
        id: 'diagnosis',
        type: 'text',
        label: 'Диагноз',
        kazakhLabel: 'Диагнозы'
      },
      {
        id: 'forwarder_full_name',
        type: 'text',
        label: 'Наименование организации направившего образец',
        kazakhLabel: 'Үлгіні жіберген мекеменің атауы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование пробы',
        kazakhLabel: 'Сынаманың атауы'
      },
      {
        id: 'sample_delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки материала',
        kazakhLabel: 'Материалды жеткізу күні жәнеу ақыты'
      },
      {
        id: 'sample_obtaining_date',
        type: 'datetime',
        label: 'Дата и время забора',
        kazakhLabel: 'Сынама алынған күні, уақыты'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель обследования',
        kazakhLabel: 'Тексеру мақсаты'
      },
      {
        id: 'research_results',
        type: 'text',
        label: 'Результаты исследований: ПЦР на /указать вид инфекции',
        kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '300': {
    version:2,
    name: 'ПРОТОКОЛ Исследования угля',
    kazakhName: 'Көмірдің зерттеуі',
    fields: [
      {
        id: 'object_info',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Нысанның атауы, мекен-жайы'
      },
      {
        id: 'sampling_location',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Материалдың, бұйымның атауы'
      },
      {
        id: 'research_method',
        type: 'text',
        label: 'Метод исследования',
        kazakhLabel: 'Өлшеулер мақсаты'
      },
      {
        id: 'sample_volume',
        type: 'text',
        label: 'Объем образца',
        kazakhLabel: 'Мөлшері'
      },      
      {
        id: 'government_check',
        type: 'text',
        label: 'Сведения о государственной поверке',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер'
      },
      {
        id: 'measurement_conditions_info',
        type: 'text',
        label: 'Дополнительные сведения об условиях измерения',
        kazakhLabel: 'Мемлекеттік тексеру туралы мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table frame="VOID" cols="9" rules="NONE" cellspacing="0" border="0">
        <colgroup><col width="86"><col width="86"><col width="86"><col width="86"><col width="86"><col width="86"><col width="86"><col width="86"><col width="86"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" sdnum="1049;1049;Standard" width="86" valign="BOTTOM" height="94" align="CENTER"><font face="Calibri" color="#000000">№ п/п</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" width="86" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Үлгі атауы</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" width="86" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">күлділік %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" width="86" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">ылғалдық %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" width="86" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">ұшпалылық %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" sdnum="1049;1049;Standard" width="171" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">жанудың жоғары <br>Жылылығы</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" sdnum="1049;1049;Standard" width="171" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">жанудың төменгі <br>Жылылығы</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Наименование образца</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Зольность %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Влага % </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Выход летучих <br>Веществ %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Times New Roman">Высшая теплота <br>Сгорания </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Низшая теплота<br> Сгорания  (Дж/г)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000"> (Дж/г)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Ккал/кг</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000"> (Дж/г)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdnum="1049;1049;Standard" valign="BOTTOM" align="CENTER"><font face="Calibri" color="#000000">Ккал/кг</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="1" sdnum="1049;1049;Standard" valign="BOTTOM" height="19" align="RIGHT"><font face="Calibri" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="2" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="3" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="4" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="5" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="6" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="7" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="8" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" sdval="9" sdnum="1049;1049;Standard" valign="BOTTOM" align="RIGHT"><font face="Calibri" color="#000000">9</font></td>
          </tr>
        </tbody>
      </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="9" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="65"><col width="98"><col width="88"><col width="98"><col width="112"><col width="65"><col width="65"><col width="65"><col width="65"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="65" height="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3">№ п/п</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="98" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">Үлгі атауы<br>Наименование образца</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="88" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">күлділік %<br>Зольность %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="98" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">ылғалдық %<br>Влага % </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="2" width="112" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">ұшпалылық %<br>Выход летучих веществ %</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="129" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">жанудың жоғары жылылығы<br>Высшая теплота сгорания </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="129" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">жанудың төменгі жылылығы<br>Низшая теплота сгорания  (Дж/г)</font></td>
            </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="20" align="CENTER" valign="BOTTOM" sdval="1" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="2" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="3" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="4" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="5" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="6" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">6</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="7" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">7</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="8" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">8</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdval="9" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">9</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="20" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000">көмір / уголь</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" size="3" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },      
    ]
  },
}
