import React, {useState, useEffect} from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {HighlightOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import {Dialog, DialogContent, Divider, Toolbar} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import ReferralSpecificationCRUDTable from "../CRUD/Tables/ReferralSpectificationCRUDTable";
import ServiceCRUDTable from "../CRUD/Tables/ServiceCRUDTable";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {DepartmentSelect} from "../Select/domainSelects";
import {SERVICE_TYPE_GOVERNMENT} from "../../redux/constants/serviceTypeConstants";
import useReload from "../../hooks/useReload";
import { NumberField2 } from "../Fields/NumberField2";

const ServiceField = ({label, value, object, error, onChange, disabled, ...props}) => {
    const deleteColumnStyles = {
            position: "sticky",
            right: 0,
        },
        cellHeadStyles = {fontWeight: "bold"};

    const [open, setOpen] = useState(false);

    const {reload, reloadValue} = useReload();
    const [extraFilter, setExtraFilter] = useState({
        typeId: {operand1: SERVICE_TYPE_GOVERNMENT, operator: "equals"},
    });

    useEffect(() => {
        setExtraFilter({
            ...extraFilter,
        });
    }, []);
    useEffect(() => {
        reload();
    }, [extraFilter]);

    return (
        <Grid container spacing={2} sx={{border: error && !value.length && '1px dashed red'}}>
            {/* <Grid item xs={12} style={{overflowX: "auto"}}>
                <Typography color={"textSecondary"}>
                    {label || "17. Список исследований"}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{overflowX: "auto"}}>
                <Table> 
                    <TableHead>
                        <TableRow>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                № n/n
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Код исследования
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Лаборатория
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Отделение лаборатории
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Группа
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Подгруппа
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Метод
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Количество
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Цена
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Скидка %
                            </TableCell>
                            <TableCell style={deleteColumnStyles}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(value || []).map((item, i) => {
                            const handleChange = (key, val) => {
                                value[i][key] = val;
                                onChange([...value]);
                            };
                            let prices = item.service?.prices || [];
                            let price = prices.length > 0 ? prices[0].price : 0;
                            if (!item.price) item.price = price;
                            return (
                                <TableRow>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {i + 1}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.service?.code}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.department?.name}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.division?.name}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.service?.group?.name}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.service?.subGroup?.name}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.service?.kind?.name}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        1
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.price}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        <NumberField2
                                            fullWidth
                                            value={item.discountPercent || 0}
                                            defaultValue={1}                  
                                            onChange={(e) =>
                                                handleChange("discountPercent", e.target.value)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell align={"right"} style={deleteColumnStyles}>
                                        <IconButton
                                            onClick={() => {
                                                value.splice(i, 1);
                                                onChange([...value]);
                                            }}
                                        >
                                            <HighlightOff/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={() => setOpen(true)} disabled={disabled} variant={"outlined"}
                        style={{marginLeft: "10px"}}>
                    + Добавить исследование
                </Button>
            </Grid> */}
        <Grid container spacing={1}>
                {/* Field Line */}
                <Grid item xs={12}>
                    <Toolbar>
                        <Typography style={{fontWeight: "bold", width: "50%"}}>
                            {label || "Услуга"}:{" "}
                        </Typography>
                        <Typography>
                            {object?.name}
                        </Typography>
                        <Button disabled={disabled} onClick={() => setOpen(true)} variant={"outlined"}
                                style={{marginLeft: "10px"}}>Выбрать</Button>
                    </Toolbar>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
                PaperProps={{sx: {maxHeight: '85%'}}}
                maxWidth={"80%"}
            >
                <DialogTitle>Выбор</DialogTitle>
                <Divider/>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ServiceCRUDTable
                                searchByIdHide={true}
                                reloadValue={reloadValue}
                                extraFilter={extraFilter}
                                onClickCreateButton={undefined} // что бы не показывать кнопку создания
                                onRowClick={(record, rowIndex) => {
                                    let specification = {
                                        service: record,
                                        department: record.department,
                                        division: record.division,

                                        serviceId: record.id,
                                        departmentId: record.departmentId,
                                        divisionId: record.divisionId,
                                        price: record.price,
                                        discountPercent: record.discountPercent || 0,
                                    };
                                    if (onChange) onChange(record);
                                    setOpen(false);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <Divider/>
                <DialogActions>
                    <Button
                        variant={"text"}
                        style={{marginLeft: "auto"}}
                        onClick={() => setOpen(false)}
                    >
                        Отменить
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};
export default ServiceField;
