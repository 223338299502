export const PROTOCOL_FILE_GET_REQUEST = 'PROTOCOL_FILE_GET_REQUEST';
export const PROTOCOL_FILE_GET_SUCCESS = 'PROTOCOL_FILE_GET_SUCCESS';
export const PROTOCOL_FILE_GET_ERROR = 'PROTOCOL_FILE_GET_ERROR';
export const PROTOCOL_FILE_GET_BY_KEY_REQUEST = 'PROTOCOL_FILE_GET_BY_KEY_REQUEST';
export const PROTOCOL_FILE_GET_BY_KEY_SUCCESS = 'PROTOCOL_FILE_GET_BY_KEY_SUCCESS';
export const PROTOCOL_FILE_GET_BY_KEY_ERROR = 'PROTOCOL_FILE_GET_BY_KEY_ERROR';
export const PROTOCOL_FILE_ADD_REQUEST = 'PROTOCOL_FILE_ADD_REQUEST';
export const PROTOCOL_FILE_ADD_SUCCESS = 'PROTOCOL_FILE_ADD_SUCCESS';
export const PROTOCOL_FILE_ADD_ERROR = 'PROTOCOL_FILE_ADD_ERROR';
export const PROTOCOL_FILE_EDIT_REQUEST = 'PROTOCOL_FILE_EDIT_REQUEST';
export const PROTOCOL_FILE_EDIT_SUCCESS = 'PROTOCOL_FILE_EDIT_SUCCESS';
export const PROTOCOL_FILE_EDIT_ERROR = 'PROTOCOL_FILE_EDIT_ERROR';
export const PROTOCOL_FILE_DELETE_REQUEST = 'PROTOCOL_FILE_DELETE_REQUEST';
export const PROTOCOL_FILE_DELETE_SUCCESS = 'PROTOCOL_FILE_DELETE_SUCCESS';
export const PROTOCOL_FILE_DELETE_ERROR = 'PROTOCOL_FILE_DELETE_ERROR';
