import { 
    DESINFECTION_REFERENCE_GET_REQUEST, DESINFECTION_REFERENCE_GET_SUCCESS, DESINFECTION_REFERENCE_GET_ERROR, 
    DESINFECTION_REFERENCE_GET_BY_KEY_REQUEST, DESINFECTION_REFERENCE_GET_BY_KEY_SUCCESS, DESINFECTION_REFERENCE_GET_BY_KEY_ERROR, 
    DESINFECTION_REFERENCE_ADD_REQUEST, DESINFECTION_REFERENCE_ADD_SUCCESS, DESINFECTION_REFERENCE_ADD_ERROR, 
    DESINFECTION_REFERENCE_EDIT_REQUEST, DESINFECTION_REFERENCE_EDIT_SUCCESS, DESINFECTION_REFERENCE_EDIT_ERROR, 
    DESINFECTION_REFERENCE_DELETE_REQUEST, DESINFECTION_REFERENCE_DELETE_SUCCESS, DESINFECTION_REFERENCE_DELETE_ERROR
} from "../constants/desinfectionReferenceConstants";

const initialState = {
    desinfectionReferences: [],
    desinfectionReference: {},
    loading: false,
    success: null,
    error: null,
    desinfectionReferenceRequestedCount: 0,
};

export const desinfectionReferenceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case DESINFECTION_REFERENCE_GET_REQUEST:
            return {
                ...state,
                desinfectionReferenceRequestedCount: ++state.desinfectionReferenceRequestedCount,
                loading: true,
            };
        case DESINFECTION_REFERENCE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                desinfectionReferences: action.payload,
                success: true,
            };
        case DESINFECTION_REFERENCE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINFECTION_REFERENCE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINFECTION_REFERENCE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                desinfectionReference: action.payload,
                success: true,
            };
        case DESINFECTION_REFERENCE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINFECTION_REFERENCE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINFECTION_REFERENCE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DESINFECTION_REFERENCE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINFECTION_REFERENCE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINFECTION_REFERENCE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DESINFECTION_REFERENCE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINFECTION_REFERENCE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINFECTION_REFERENCE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DESINFECTION_REFERENCE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
