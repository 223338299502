import React from "react";

import { PageContainer, PageHeader } from "../../../components";
import { ServiceWithoutActRequestCRUDTable } from "../../../components/CRUD/Tables";
import DashboardLayout from "../../../layouts/Dashboard";
import useReload from "../../../hooks/useReload";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";
import {contractRequestCRUD, serviceWithoutActRequestCRUD} from "../../../http";
import {checkRoles} from "../../../functions/checkRoles";

export const ServiceWithoutActRequestTable = () => {

    const { reload, reloadValue } = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление заявки без акта, ID:' + _, 'Вы действительно хотите удалить эту заявку?', async () => {
            await serviceWithoutActRequestCRUD.delete(_);
            await reload();
        })
    }

    return (
        <DashboardLayout>
            <PageHeader heading="Заявки без акта" />
            <PageContainer>
                <ServiceWithoutActRequestCRUDTable onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} reloadValue={reloadValue}/>
            </PageContainer>
        </DashboardLayout>
    )
};
