import {
  REFERRAL_GET_REQUEST,
  REFERRAL_GET_SUCCESS,
  REFERRAL_GET_ERROR,
  REFERRAL_GET_BY_KEY_REQUEST,
  REFERRAL_GET_BY_KEY_SUCCESS,
  REFERRAL_GET_BY_KEY_ERROR,
  REFERRAL_ADD_REQUEST,
  REFERRAL_ADD_SUCCESS,
  REFERRAL_ADD_ERROR,
  REFERRAL_EDIT_REQUEST,
  REFERRAL_EDIT_SUCCESS,
  REFERRAL_EDIT_ERROR,
  REFERRAL_DELETE_REQUEST,
  REFERRAL_DELETE_SUCCESS,
  REFERRAL_DELETE_ERROR,
  REFERRAL_WORKFLOW_RUN_REQUEST,
  REFERRAL_WORKFLOW_RUN_SUCCESS,
  REFERRAL_WORKFLOW_RUN_ERROR,
  REFERRAL_WORKFLOW_REQUEST,
  REFERRAL_WORKFLOW_SUCCESS,
  REFERRAL_WORKFLOW_ERROR,
} from "../constants/referralConstants";

const initialState = {
  referrals: [],
  referral: {},
  loading: false,
  success: null,
  error: null,
  referralRequestedCount: 0,
};

export const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_GET_REQUEST:
      return {
        ...state,
        referralRequestedCount: ++state.referralRequestedCount,
        loading: true,
      };
    case REFERRAL_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        referrals: action.payload,
        success: true,
      };
    case REFERRAL_GET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REFERRAL_GET_BY_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFERRAL_GET_BY_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        referral: action.payload,
        success: true,
      };
    case REFERRAL_GET_BY_KEY_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REFERRAL_ADD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };
    case REFERRAL_ADD_SUCCESS:
      return {
        ...state,
        referralRequestedCount: 0,
        loading: false,
        success: true,
      };
    case REFERRAL_ADD_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REFERRAL_EDIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFERRAL_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REFERRAL_EDIT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REFERRAL_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFERRAL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REFERRAL_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case REFERRAL_WORKFLOW_RUN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFERRAL_WORKFLOW_RUN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REFERRAL_WORKFLOW_RUN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case REFERRAL_WORKFLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case REFERRAL_WORKFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REFERRAL_WORKFLOW_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
