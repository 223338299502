export const LEGAL_PERSON_TYPE_GET_REQUEST = 'LEGAL_PERSON_TYPE_GET_REQUEST';
export const LEGAL_PERSON_TYPE_GET_SUCCESS = 'LEGAL_PERSON_TYPE_GET_SUCCESS';
export const LEGAL_PERSON_TYPE_GET_ERROR = 'LEGAL_PERSON_TYPE_GET_ERROR';
export const LEGAL_PERSON_TYPE_GET_BY_KEY_REQUEST = 'LEGAL_PERSON_TYPE_GET_BY_KEY_REQUEST';
export const LEGAL_PERSON_TYPE_GET_BY_KEY_SUCCESS = 'LEGAL_PERSON_TYPE_GET_BY_KEY_SUCCESS';
export const LEGAL_PERSON_TYPE_GET_BY_KEY_ERROR = 'LEGAL_PERSON_TYPE_GET_BY_KEY_ERROR';
export const LEGAL_PERSON_TYPE_ADD_REQUEST = 'LEGAL_PERSON_TYPE_ADD_REQUEST';
export const LEGAL_PERSON_TYPE_ADD_SUCCESS = 'LEGAL_PERSON_TYPE_ADD_SUCCESS';
export const LEGAL_PERSON_TYPE_ADD_ERROR = 'LEGAL_PERSON_TYPE_ADD_ERROR';
export const LEGAL_PERSON_TYPE_EDIT_REQUEST = 'LEGAL_PERSON_TYPE_EDIT_REQUEST';
export const LEGAL_PERSON_TYPE_EDIT_SUCCESS = 'LEGAL_PERSON_TYPE_EDIT_SUCCESS';
export const LEGAL_PERSON_TYPE_EDIT_ERROR = 'LEGAL_PERSON_TYPE_EDIT_ERROR';
export const LEGAL_PERSON_TYPE_DELETE_REQUEST = 'LEGAL_PERSON_TYPE_DELETE_REQUEST';
export const LEGAL_PERSON_TYPE_DELETE_SUCCESS = 'LEGAL_PERSON_TYPE_DELETE_SUCCESS';
export const LEGAL_PERSON_TYPE_DELETE_ERROR = 'LEGAL_PERSON_TYPE_DELETE_ERROR';
