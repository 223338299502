export const REQUEST_STATE_GET_REQUEST = 'REQUEST_STATE_GET_REQUEST';
export const REQUEST_STATE_GET_SUCCESS = 'REQUEST_STATE_GET_SUCCESS';
export const REQUEST_STATE_GET_ERROR = 'REQUEST_STATE_GET_ERROR';
export const REQUEST_STATE_GET_BY_KEY_REQUEST = 'REQUEST_STATE_GET_BY_KEY_REQUEST';
export const REQUEST_STATE_GET_BY_KEY_SUCCESS = 'REQUEST_STATE_GET_BY_KEY_SUCCESS';
export const REQUEST_STATE_GET_BY_KEY_ERROR = 'REQUEST_STATE_GET_BY_KEY_ERROR';
export const REQUEST_STATE_ADD_REQUEST = 'REQUEST_STATE_ADD_REQUEST';
export const REQUEST_STATE_ADD_SUCCESS = 'REQUEST_STATE_ADD_SUCCESS';
export const REQUEST_STATE_ADD_ERROR = 'REQUEST_STATE_ADD_ERROR';
export const REQUEST_STATE_EDIT_REQUEST = 'REQUEST_STATE_EDIT_REQUEST';
export const REQUEST_STATE_EDIT_SUCCESS = 'REQUEST_STATE_EDIT_SUCCESS';
export const REQUEST_STATE_EDIT_ERROR = 'REQUEST_STATE_EDIT_ERROR';
export const REQUEST_STATE_DELETE_REQUEST = 'REQUEST_STATE_DELETE_REQUEST';
export const REQUEST_STATE_DELETE_SUCCESS = 'REQUEST_STATE_DELETE_SUCCESS';
export const REQUEST_STATE_DELETE_ERROR = 'REQUEST_STATE_DELETE_ERROR';

export const REQUEST_STATE_ID_CREATED = 'Created';
export const REQUEST_STATE_ID_PROBESELECTION = 'ProbeSelection';
export const REQUEST_STATE_ID_PROCESSING = 'Processing';
export const REQUEST_STATE_ID_RESEARCH = 'Research';
export const REQUEST_STATE_ID_COMPLETED = 'Completed';
export const REQUEST_STATE_ID_DECLINED = 'Declined';
export const REQUEST_STATE_ID_SIGNING = 'Signing';
export const REQUEST_STATE_ID_SIGNED = 'Signed';
export const REQUEST_STATE_ID_APPROVING = 'Negotiation';