import {$authHost, probeTypeCRUD} from "../../http";
import {
    PROBE_GET_REQUEST, PROBE_GET_SUCCESS, PROBE_GET_ERROR,
    PROBE_GET_BY_KEY_REQUEST, PROBE_GET_BY_KEY_SUCCESS, PROBE_GET_BY_KEY_ERROR,
    PROBE_ADD_REQUEST, PROBE_ADD_SUCCESS, PROBE_ADD_ERROR,
    PROBE_EDIT_REQUEST, PROBE_EDIT_SUCCESS, PROBE_EDIT_ERROR,
    PROBE_DELETE_REQUEST, PROBE_DELETE_SUCCESS, PROBE_DELETE_ERROR
} from "../constants/probeConstants";

export const getProbeTypes = (args) => async (dispatch) => {
    try {
        dispatch({
            type: "PROBE_TYPE_GET_REQUEST"
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const {data} = await $authHost.post("/api/v1/probeType/query",args || {}, config)
        dispatch({
            type: "PROBE_TYPE_GET_SUCCESS",
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: "PROBE_TYPE_GET_ERROR",
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

