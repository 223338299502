import React, {useEffect, useState} from 'react';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS, MOMENT_FORMAT_DATE_DMYHM_DOTS} from "../../functions/formatters";
import IconButton from "@mui/material/IconButton";
import {HighlightOff} from "@mui/icons-material";
import {confirmDialog} from "../Dialog/DialogDelete";
import {actCRUD} from "../../http";
import {getActForms} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import {ActCreateModal} from "../../screens";

function ActField({ value, reload, label, error, button, departureSchedules}) {

    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [actForClone, setActForClone] = useState({})
    const [actNumber, setActNumber] = useState({})

    const handleCloneAct = (act, number) => {
        setActForClone(act)
        setActNumber(number)
        setOpen(true)
    }

    const {actForms} = useSelector((s) => s.actForm);

    useEffect(() => {
        if (actForms.length === 0) {
            dispatch(getActForms({paging: {skip: 0}}));
        }
    }, [])


    const deleteColumnStyles = {
            position: "sticky",
            right: 0,
        },
        cellHeadStyles = {fontWeight: "bold"};

    function deleteActHandler(id) {
        return confirmDialog('Удаление акта, ID:' + id, 'Вы действительно хотите удалить этот акт?', async () => {
            await actCRUD.delete(id);
            await reload();
        })
    }

    return (
        <Grid container spacing={2} sx={{border: error && !value.length && '1px dashed red'}}>
            <Grid item xs={12} style={{overflowX: "auto"}}>
                <Typography color={"textSecondary"}>
                    {label || "Список актов"}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{overflowX: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                № n/n
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Название акта
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Номер акта
                            </TableCell>
                            <TableCell variant={"head"} style={cellHeadStyles}>
                                Дата создания
                            </TableCell>
                            <TableCell style={deleteColumnStyles}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(value || []).map((item) => {
                            const name = actForms.find(el => el.id === item.formId)?.name
                            return (
                                <TableRow>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.id}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {name}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {item.number}
                                    </TableCell>
                                    <TableCell variant={"head"} style={cellHeadStyles}>
                                        {formatDate(item.createdAt, MOMENT_FORMAT_DATE_DMY_DOTS)}
                                    </TableCell>
                                    <TableCell align={"right"} style={deleteColumnStyles}>
                                        {departureSchedules && <Button onClick={() => handleCloneAct(item, actForms.find(el => el.id === item.formId)?.number)} color="warning">Клонировать</Button>}
                                        <IconButton
                                            onClick={() => deleteActHandler(item.id)}
                                        >
                                            <HighlightOff/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12}>
                {button}
            </Grid>
            <ActCreateModal actNumber={actNumber} open={open} setOpen={setOpen} act={actForClone} />
        </Grid>
    );
}

export default ActField;
