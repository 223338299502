import {getPatients} from "../../redux/actions/patientActions";
import ReferalAutocompleteAsync from "../../screens/Referral/ReferalAutocompleteAsync";
import * as React from "react";
import {useSelector} from "react-redux";

export const getPatientName = (patient) => {
  return patient.fullName || patient.firstName || ""
};

export default function PatientsAutocomplete({values, setValues}) {
  const {patients, patientRequestedCount} = useSelector((state) => state.patient);

  return <ReferalAutocompleteAsync
    name={"fullName"}
    func={getPatients}
    args={{paging: {skip: 0}}} array={patients}
    label="Пациенты"
    values={values}
    setValues={setValues}
    getOptionLabel={(option) => getPatientName(option)}
  />;
}