import { 
    PROBE_GET_REQUEST, PROBE_GET_SUCCESS, PROBE_GET_ERROR, 
    PROBE_GET_BY_KEY_REQUEST, PROBE_GET_BY_KEY_SUCCESS, PROBE_GET_BY_KEY_ERROR, 
    PROBE_ADD_REQUEST, PROBE_ADD_SUCCESS, PROBE_ADD_ERROR, 
    PROBE_EDIT_REQUEST, PROBE_EDIT_SUCCESS, PROBE_EDIT_ERROR, 
    PROBE_DELETE_REQUEST, PROBE_DELETE_SUCCESS, PROBE_DELETE_ERROR
} from "../constants/probeConstants";

const initialState = {
    probes: [],
    probe: {},
    loading: false,
    success: null,
    error: null,
    probeRequestedCount: 0,
};

export const probeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PROBE_GET_REQUEST:
            return {
                ...state,
                probeRequestedCount: ++state.probeRequestedCount,
                loading: true,
            };
        case PROBE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                probes: action.payload,
                success: true,
            };
        case PROBE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                probe: action.payload,
                success: true,
            };
        case PROBE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROBE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROBE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROBE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROBE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROBE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
