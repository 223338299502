import { 
    SELF_CONTROL_REQUEST_GET_REQUEST, SELF_CONTROL_REQUEST_GET_SUCCESS, SELF_CONTROL_REQUEST_GET_ERROR, 
    SELF_CONTROL_REQUEST_GET_BY_KEY_REQUEST, SELF_CONTROL_REQUEST_GET_BY_KEY_SUCCESS, SELF_CONTROL_REQUEST_GET_BY_KEY_ERROR, 
    SELF_CONTROL_REQUEST_ADD_REQUEST, SELF_CONTROL_REQUEST_ADD_SUCCESS, SELF_CONTROL_REQUEST_ADD_ERROR, 
    SELF_CONTROL_REQUEST_EDIT_REQUEST, SELF_CONTROL_REQUEST_EDIT_SUCCESS, SELF_CONTROL_REQUEST_EDIT_ERROR, 
    SELF_CONTROL_REQUEST_DELETE_REQUEST, SELF_CONTROL_REQUEST_DELETE_SUCCESS, SELF_CONTROL_REQUEST_DELETE_ERROR
} from "../constants/selfControlRequestConstants";

const initialState = {
    selfControlRequests: [],
    selfControlRequest: {},
    loading: false,
    success: null,
    error: null,
    selfControlRequestRequestedCount: 0,
};

export const selfControlRequestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SELF_CONTROL_REQUEST_GET_REQUEST:
            return {
                ...state,
                selfControlRequestRequestedCount: ++state.selfControlRequestRequestedCount,
                loading: true,
            };
        case SELF_CONTROL_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                selfControlRequests: action.payload,
                success: true,
            };
        case SELF_CONTROL_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SELF_CONTROL_REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SELF_CONTROL_REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                selfControlRequest: action.payload,
                success: true,
            };
        case SELF_CONTROL_REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SELF_CONTROL_REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SELF_CONTROL_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SELF_CONTROL_REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SELF_CONTROL_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SELF_CONTROL_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SELF_CONTROL_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SELF_CONTROL_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SELF_CONTROL_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SELF_CONTROL_REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
