import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Box,
  Button,
  FormGroup,
  InputLabel,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingButton, TimePicker } from "@mui/lab";

import {
  getContractRequests,
  getEmployeeByKey,
  getEmployees,
  getContracts,
  getEducationServices,
  getEducationServiceKinds,
  getGeoObjects,
  getRequestSections,
  getContractByKey,
  addContractRequest,
  getAuthorizedEmployee,
  getFilials,
  getFilialByKey,
} from "./../../redux/actions";
import {
  PageHeader,
  PageContainer,
  PageFooter,
  CustomDatePicker,
  SelectFieldModal,
  CustomTimePicker,
  SelectField,
  Form,
  FormSection,
  ConditionalContainer,
  BasicTable,
} from "./../../components";
import {
  ContractSelect,
  FilialDivisionSelect,
  FilialSelect,
} from "./../../components/Select/domainSelects";
import { mapContractSpecificationsForSubmit } from "../../functions/viewDataMap";

import DashboardLayout from "./../../layouts/Dashboard";
import { CONTRACT_REQUEST } from "../../routes/const";
import ContractField from "../../components/Relations/ContractField";
import EmployeeField from "../../components/Relations/EmployeeField";

export const ContractRequestCreate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [contractId, setContractId] = useState("");
  const [performerId, setPerformerId] = useState(null);
  const [contractReady, setContractReady] = useState(false);

  const [localPerformer, setLocalPerformer] = useState({});
  const [localAuthor, setLocalAuthor] = useState({});

  //const [localFilial, setLocalFilial] = useState(null);
  const [localFilialId, setLocalFilialId] = useState(null);
  const [filialDivisionId, setFilialDivisionId] = useState(null);

  const { contract } = useSelector((s) => s.contract);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { objects, specifications, educationServices } = contract;

    mapContractSpecificationsForSubmit(specifications);

    // Validating
    if (!localFilialId) return alert("Укажите филиал!");
    if (!localPerformer?.id) return alert("Укажите исполнителя!");

    setLoading(true);

    const data = {
      performerId: localPerformer?.id,
      filialId: localFilialId,
      filialDivisionId: filialDivisionId,
      createdById: localAuthor?.id,
      objects,
      specifications,
      educationServices,

      // contract
      contractId: contract.id,
      contractNumber: contract?.number,
      contractSummary: contract?.summary,
      contractPaySummaryRemainder: contract?.paySummaryRemainder,
      contractPayedSummary: contract?.payedSummary,
      contractActionAt: contract?.actionAt,
      contractDeadline: contract?.deadline,
      contractType: contract?.typeId,
      contractPayStateId: contract?.payStateId,
      contractStateId: contract?.stateId,
      contractFilialId: contract?.filialId,
      contractFilialDivisionId: contract?.filialDivisionId || filialDivisionId,
      researchCount: researchCount()
    };

    // Creating
    const createdId = await dispatch(addContractRequest(data));

    setTimeout(() => {
      setLoading(false);
      if (!createdId) return;

      history.push(`${CONTRACT_REQUEST}/${createdId}`);
    }, 500);
  };

  useEffect(() => {
    (async () => {
      if (!contractId) return;

      console.log(contractId)

      await dispatch(getContractByKey(contractId));

      setContractReady(contractId ? true : false);
    })();
  }, [dispatch, contractId]);

  useEffect(() => {
    (async () => {
      if (!performerId) return;
      dispatch(getEmployeeByKey(performerId)).then(setLocalPerformer);
    })();
  }, [dispatch, performerId]);

  // useEffect(() => {
  //   if (localFilialId)
  //     dispatch(getFilialByKey(localFilialId)).then(setLocalFilial);
  // }, [dispatch, localFilialId]);

  useEffect(() => {
    dispatch(getAuthorizedEmployee()).then(setLocalAuthor);
  }, []);

  const researchCount = () => {
    if (contract == null)
      return 0;
    let result = 0;
    for (let i = 0; i < contract.specifications?.length; i++)
      result += contract.specifications[i]?.quantity;
    return result;
  }

  return (
    <DashboardLayout>
      <PageHeader heading="Сводная Заявка: Создание">
        <Button
            variant={"text"}
            onClick={() => history.push(CONTRACT_REQUEST)}
        >
          Вернуться
        </Button>
      </PageHeader>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection heading="Договор" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ContractField
                    label={"Договор"}
                    sx={{paddingLeft: '0 !important'}}
                    value={contractId}
                    onChange={(val, obj) => {
                      setContractId(val?.id)
                    }}
                />
              </Grid>
            </Grid>

            {contractReady && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Вид договора"
                      type="text"
                      value={contract?.type?.name || "-"}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Статус по договору"
                      type="text"
                      value={contract?.state?.name || "-"}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomDatePicker
                      label="Дата договора"
                      value={contract?.actionAt}
                      disabled
                    />
                  </Grid>

                  {/* <Grid item xs={2}>
                    <CustomDatePicker
                      label="Срок действия договора"
                      value={contract?.deadline}
                      disabled
                    />
                  </Grid> */}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label="Оплаченная сумма"
                      type="number"
                      value={contract?.paySummary || 0}
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Сумма договора"
                      type="number"
                      value={contract?.summary || 0}
                      disabled
                      required
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      label="Статус оплаты"
                      type="text"
                      value={contract?.payState?.name || "Не указано"}
                      disabled
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Исполнитель по договору"
                      type="text"
                      value={contract?.filial?.name || "Не указано"}
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Районное отделение"
                      type="text"
                      value={contract?.filialDivision?.name || "Не указано"}
                      disabled
                      required
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label="Количество исследований"
                      type="number"
                      value={researchCount() || 0}
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Сумма реализации"
                      type="number"
                      value={contract?.realizationSummary || 0}
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Оплаченная сумма по договору"
                      type="number"
                      value={contract?.paySummary || 0}
                      disabled
                      required
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Остаток суммы выплаты по договору"
                      type="number"
                      value={contract?.paySummaryRemainder || 0}
                      disabled
                      required
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </FormSection>

          <ConditionalContainer condition={contractReady}>
            <FormSection
              heading={`Обучение (${contract?.educationServices?.length || 0})`}
              mb={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("educationService.tableItem.id") },
                      {
                        id: "object",
                        label: t("educationService.tableItem.kind"),
                        render: (row) => row?.kind?.name,
                      },
                      {
                        id: "serviceKind",
                        label: t("educationService.tableItem.service"),
                        render: (row) => row?.service?.name,
                      },
                    ]}
                    rows={contract?.educationServices}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection
              heading={`Объекты (${contract?.objects?.length || 0})`}
              mb={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("object.tableItem.id") },
                      {
                        id: "object",
                        label: t("object.tableItem.name"),
                        render: (row) => row?.object?.name,
                      },
                      {
                        id: "district",
                        label: t("object.tableItem.district"),
                        render: (row) => row?.district?.name,
                      },
                      {
                        id: "city",
                        label: t("object.tableItem.city"),
                        render: (row) => row?.city?.name,
                      },
                      {
                        id: "locality",
                        label: t("object.tableItem.locality"),
                        render: (row) => row?.locality?.name,
                      },
                      {
                        id: "addressLine",
                        label: t("object.tableItem.addressLine"),
                      },
                    ]}
                    rows={contract?.objects}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection
              heading={`Услуги (${contract?.specifications?.length || 0})`}
              mb={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("specification.tableItem.id") },
                      {
                        id: "object",
                        label: t("specification.tableItem.object"),
                        render: (row) => row?.object?.name,
                      },
                      {
                        id: "serviceKind",
                        label: t("specification.tableItem.serviceKind"),
                        render: (row) => row?.serviceKind?.name,
                      },
                      {
                        id: "service",
                        label: t("specification.tableItem.service"),
                        render: (row) => row?.service?.name,
                      },
                      {
                        id: "unit",
                        label: t("specification.tableItem.unit"),
                        render: (row) => row?.unit?.name,
                      },
                      {
                        id: "schedule",
                        label: t("specification.tableItem.schedule"),
                        render: (row) => row?.schedule?.name,
                      },
                      {
                        id: "quantity",
                        label: t("specification.tableItem.quantity"),
                      },
                      {
                        id: "price",
                        label: t("specification.tableItem.price"),
                      },
                    ]}
                    rows={contract?.specifications}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection
              heading={`График (${contract?.schedules?.length || 0})`}
              mb={4}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <BasicTable
                    cells={[
                      { id: "id", label: t("object.tableItem.id") },
                      {
                        id: "object",
                        label: t("object.tableItem.name"),
                        render: (row) => row?.name,
                      },
                    ]}
                    rows={contract?.schedules}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </FormSection>
          </ConditionalContainer>

          <FormSection heading="Заявка">
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  label="Автор заявки"
                  value={localAuthor?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <FilialSelect
                  label={"Филиал"}
                  fullWidth
                  value={localFilialId}
                  onChange={(val) => {
                    setLocalFilialId(val);
                    setFilialDivisionId(Number(val) !== Number(localFilialId) ?
                      null
                      : filialDivisionId);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <FilialDivisionSelect
                    label={"Районное отделения"}
                    fullWidth
                    value={filialDivisionId}
                    filialId={localFilialId}
                    onChange={(val, filialDivision) => {
                      setFilialDivisionId(val);
                      setLocalFilialId(Number(filialDivision.filialId) !== Number(localFilialId)
                        ? filialDivision.filialId
                        : localFilialId);
                    }}
                  />
              </Grid>
              <Grid item xs={3}>
                <EmployeeField
                    style={{ marginBottom: "5px" }}
                    label={"Исполнитель заявки"}
                    object={localPerformer}
                    value={localPerformer.id}
                    onChange={(val) => {
                      setLocalPerformer(val)
                    }}
                />
              </Grid>
            </Grid>
            {contractReady && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* <SelectFieldModal
                    cells={[
                      { text: t("employee.tableItem.id") },
                      { text: t("employee.tableItem.fullName") },
                    ]}
                    label="Аккаунт для ЮЛ и ИП"
                    placeholder="Выберите аккаунт"
                    nameFilter="fullName"
                    setValue={setLocalAuthor}
                    value={localAuthor}
                    actionFn={getEmployees}
                    storeValue={employees}
                    storeCount={employeeCount}
                    disabled
                  /> */}
                </Grid>
                <Grid item xs={6}>
                  {/* <SelectFieldModal
                    cells={[
                      { text: t("employee.tableItem.id") },
                      { text: t("employee.tableItem.fullName") },
                    ]}
                    label="Ответственный за отбор"
                    nameFilter="fullName"
                    setValue={setLocalAuthor}
                    value={localAuthor}
                    actionFn={getEmployees}
                    storeValue={employees}
                    storeCount={employeeCount}
                    disabled
                  /> */}
                </Grid>
              </Grid>
            )}
          </FormSection>
        </PageContainer>

        <PageFooter>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Создать заявку
          </LoadingButton>
        </PageFooter>
      </Form>
    </DashboardLayout>
  );
};
