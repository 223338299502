import React, {useState} from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {statusPatient} from "./stateReferal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function ReferalMultipleSelectCheckmarks({value, setValue}) {
    const [statusPatientState, setStatusPatientState] = useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setStatusPatientState(typeof value === 'string' ? value.split(',') : value,);
        setValue(typeof value === 'string' ? value.split(',') : value)
    };
    return (
        <FormControl sx={{ width: '100%' }}>
            <InputLabel id="demo-multiple-checkbox-label">Статус пациента</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={statusPatientState}
                onChange={handleChange}
                required
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {statusPatient.map((name, idx) => (
                    <MenuItem key={idx} value={name}>
                        <Checkbox checked={statusPatientState.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default ReferalMultipleSelectCheckmarks;