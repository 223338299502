import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

ReferenceDisinfectionPDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const referenceData = data.data
  const schema = [
    {
      key: ' Тегі, аты, әкесінің аты (болған жағдайда), (Фамилия, имя, отчество (при наличии))',
      value: data?.fullName
    },
    {
      key: 'Жасы (Возраст)',
      value: data?.age
    },
    {
      key: 'Мекен-жайы (Адрес)',
      value: data?.addressLine
    },
    {
      key: 'Диагнозы (Диагноз)',
      value: data?.diagnosis?.name
    },
    {
      key: 'Зерттеу әдістеменің НҚ-ры (НД на метод испытаний)',
      value: referenceData?.standard?.value?.name
    },
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {referenceData?.dateOfReference?.value ? formatDate(new Date(referenceData.dateOfReference.value)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <View style={{ fontFamily: 'Times New Roman' }}>
          <Text style={styles.simpleString}>Дезинфекциялау жүргізген адам (Дезинфекцию проводил)  лауазымы, тегі, аты, әкесінің аты (болған жағдайда), (должность, фамилия, имя, отчество (при наличии))</Text>
          <Text style={styles.simpleString}>{data.disinfector?.fullName}</Text>
          <Text style={styles.simpleString}>Қолы (Подпись)</Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Пәтер иесінің наразылығы жоқ (Время ознакомления)</Text>
          <Text style={styles.simpleString}>{referenceData?.time?.value ? formatTime(new Date(referenceData.time?.value)) : ' '}</Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Пәтер иесінің наразылығы жоқ, жадынамамен таныстырылды. (Владелец квартиры претензий не имеет, с памяткой ознакомлен)</Text>
          <Text style={styles.simpleString}>{referenceData?.haveNoComplaints?.value}</Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Пәтер иесінің тегі, аты, әкесінің аты (болған жағдайда), (Фамилия, имя, отчество (при наличии) владельца квартиры)</Text>
          <Text style={styles.simpleString}>{referenceData?.homeOwner?.value}</Text>
          <Text style={styles.simpleString}>Қолы (Подпись)</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function ReferenceDisinfectionPDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
