import { 
    UNIT_OF_MEASURE_GET_REQUEST, UNIT_OF_MEASURE_GET_SUCCESS, UNIT_OF_MEASURE_GET_ERROR, 
    UNIT_OF_MEASURE_GET_BY_KEY_REQUEST, UNIT_OF_MEASURE_GET_BY_KEY_SUCCESS, UNIT_OF_MEASURE_GET_BY_KEY_ERROR, 
    UNIT_OF_MEASURE_ADD_REQUEST, UNIT_OF_MEASURE_ADD_SUCCESS, UNIT_OF_MEASURE_ADD_ERROR, 
    UNIT_OF_MEASURE_EDIT_REQUEST, UNIT_OF_MEASURE_EDIT_SUCCESS, UNIT_OF_MEASURE_EDIT_ERROR, 
    UNIT_OF_MEASURE_DELETE_REQUEST, UNIT_OF_MEASURE_DELETE_SUCCESS, UNIT_OF_MEASURE_DELETE_ERROR
} from "../constants/unitOfMeasureConstants";

const initialState = {
    unitOfMeasures: [],
    unitOfMeasure: {},
    loading: false,
    success: null,
    error: null,
    unitOfMeasureRequestedCount: 0,
};

export const unitOfMeasureReducer = (state = initialState, action) => {
    switch (action.type)     {
        case UNIT_OF_MEASURE_GET_REQUEST:
            return {
                ...state,
                unitOfMeasureRequestedCount: ++state.unitOfMeasureRequestedCount,
                loading: true,
            };
        case UNIT_OF_MEASURE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                unitOfMeasures: action.payload,
                success: true,
            };
        case UNIT_OF_MEASURE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case UNIT_OF_MEASURE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UNIT_OF_MEASURE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                unitOfMeasure: action.payload,
                success: true,
            };
        case UNIT_OF_MEASURE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case UNIT_OF_MEASURE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UNIT_OF_MEASURE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case UNIT_OF_MEASURE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case UNIT_OF_MEASURE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UNIT_OF_MEASURE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case UNIT_OF_MEASURE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case UNIT_OF_MEASURE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case UNIT_OF_MEASURE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case UNIT_OF_MEASURE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
