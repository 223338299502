import React from 'react';
import CRUDTable from "../CRUDTable";
import {infectionKindCRUD} from "../../../http/CRUD";

export default function InfectionKindCRUDTable({...props}) {
  const columns = [
    {title: "id", dataIndex: 'id', key: 'id'},
    {title: "Наименование", dataIndex: 'name', key: 'name'},
    {title: "Описание", dataIndex: 'description', key: 'description'},
  ]

  return <CRUDTable
    search={infectionKindCRUD.search}
    columns={columns}
    {...props}
  />
}