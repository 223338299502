import {
    PROTOCOL_STATE_GET_ERROR,
    PROTOCOL_STATE_GET_REQUEST,
    PROTOCOL_STATE_GET_SUCCESS
} from "../constants/protocolConstants";

const initialState = {
    protocolStates: [],
    protocolState: {},
    loading: false,
    success: null,
    error: null,
    protocolStateRequestedCount: 0,
};

export const protocolStateReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PROTOCOL_STATE_GET_REQUEST:
            return {
                ...state,
                protocolStateRequestedCount: ++state.protocolStateRequestedCount,
                loading: true,
            };
        case PROTOCOL_STATE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                protocolStates: action.payload,
                success: true,
            };
        case PROTOCOL_STATE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
