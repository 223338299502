import CancelIcon from '@mui/icons-material/Cancel';
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

import ObjectField from "../../../components/Relations/ObjectField";
import {
  PurposeOfSelectionsSelect,
  StorageConditionSelect,
  TransportationConditionSelect
} from "../../../components/Select/domainSelects";
import GovernmentField from "../../../components/Relations/GovernmentField";
import AnObjectField from "../../../components/Relations/AnObjectField";

const ActBuyProductsForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample, addSample, deleteSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <AnObjectField
          bold={false}
          label={"1. Объектінің атауы (Наименование объекта)"}
          value={data.data?.object?.value}
          disabled={isDisabledData}
          onChange={(obj) => {
            handleChangeData('object', obj, 'facility')
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Тексеру белгіленгені туралы актінің нөмірі мен күні (Номер и дата акта о назначении контрольного закупа)
        </p>
        <TextField
          id="outlined-basic"
          label="Номер акта"
          disabled={isDisabledData}
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          value={data.data?.numberandDate?.value}
          onChange={(e) => handleChangeData('numberandDate', e.target.value, 'numberandDate')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Сатып алынатын өнімнің (тауардың) атауы, олардың саны және өндірушісі (Наименование закупаемой продукции (товара), их количество и производитель)
        </p>
        <TextField
          id="outlined-basic"
          label="Наименование закупаемой продукции"
          disabled={isDisabledData}
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          value={data.data?.productName?.value}
          onChange={(e) => handleChangeData('productName', e.target.value, 'productName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          4. Сатып алуды жүргізу күні мен уақыты (Дата и время проведения покупки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время покупки"
            disabled={isDisabledData}
            value={data.data?.selectionDateTime?.value}
            onChange={(value) => handleChangeData('selectionDateTime', value, 'selection_time')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          5. Санитариялық-эпидемиологиялық зерттеу өткізу үшін санитариялық-эпидемиологиялық мекемеге жеткізілген күні мен уақыты (Дата и время доставки в организацию санитарно-эпидемиологической службы для проведения санитарно-эпидемиологической экспертизы)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время доставки"
            disabled={isDisabledData}
            value={data.data?.deliveryDateTime?.value}
            onChange={(value) => handleChangeData('deliveryDateTime', value, 'delivery_time')}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Өнімдерді тасымалдау барысында сақтау шарттары (Условия хранения продукции при транспортировки)</p>
        <div style={{ width: "50%", marginLeft: "30px" }}>
          <TransportationConditionSelect
            label={"Условия транспортировки"}
            fullWidth
            value={data.data?.transportationCondition?.value?.id}
            disabled={isDisabledData}
            onChange={(val, obj) => { handleChangeData('transportationCondition', obj, 'transportation_conditions') }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Пломбалау кезінде қаптаманың түрі (вид упаковки при опломбировании) (полимерлі, қағаз, шыны, басқалар) (полимерное, бумага, стекло, другое)
        </p>
        <TextField
          style={{ width: "50%", margin: "0 0 0 30px" }}
          id="outlined-basic"
          label="Вид упаковки и номер печати (пломбы)"
          variant="outlined"
          type="sealTypeAndNumber"
          disabled={isDisabledData}
          value={data.data?.sealTypeAndNumber?.value}
          onChange={(e) => handleChangeData('sealTypeAndNumber', e.target.value, 'sealTypeAndNumber')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Жарамдылық мерзімі көрсетілмеген және (немесе) өтіп кеткен және айқын бұзылу белгілері бар өнімдер (тауарлар) туралы ақпарат (Информация о продукции (товарах) с неустановленным и (или) истекшим сроком годности, явными признаками порчи)        </p>
        <TextField
          style={{ width: "50%", margin: "0 0 0 30px" }}
          id="outlined-basic"
          label="Информация о продукции (товарах) с неустановленным и (или) истекшим сроком годности, явными признаками порчи"
          variant="outlined"
          type="outdatedProduction"
          disabled={isDisabledData}
          value={data.data?.outdatedProduction?.value}
          onChange={(e) => handleChangeData('outdatedProduction', e.target.value, 'outdatedProduction')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Қабылданған жедел ден қою шаралары туралы белгі (Отметка о принятых мерах оперативного реагирования) </p>
        <TextField
          style={{ width: "50%", margin: "0 0 0 30px" }}
          id="outlined-basic"
          label="Отметка о принятых мерах оперативного реагирования"
          variant="outlined"
          type="reactionOperations"
          disabled={isDisabledData}
          value={data.data?.reactionOperations?.value}
          onChange={(e) => handleChangeData('reactionOperations', e.target.value, 'reactionOperations')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          10. Қосымша мәліметтер (Дополнительные сведения)
        </p>
        <TextField
          id="outlined-basic"
          label="Дополнительные сведения"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.additionalInfo?.value}
          onChange={(e) => handleChangeData('additionalInfo', e.target.value, 'additionalInfo')}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          3. Тағайындау актісі күні (Дата акта о назначении проверки)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата акта"
            disabled={isDisabledData}
            value={data.data?.date?.value}
            onChange={(value) => {
              handleChangeData('date', value, 'date')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div> */}
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Осы актіге қоса беріледі (К настоящему акту прилагаются) бақылау-касса машинасының чегі немесе тауар чегі, тауарларға ілеспе құжат (чек контрольно-кассовой машины или товарный чек, сопроводительная накладная на товары)        </p>
        <TextField
          id="outlined-basic"
          label="Чек контрольно-кассовой машины или товарный чек, сопроводительная накладная на товары"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.bill?.value}
          onChange={(e) => handleChangeData('bill', e.target.value, 'bill')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Сатушы (Продавец)лауазымы, тегі, аты,әкесінің аты (бар болса), қолы (подпись, должность, фамилия, имя,отчество (при его наличии)
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО продавца"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.seller?.value}
          onChange={(e) => handleChangeData('seller', e.target.value, 'seller')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Лауазымды тұлға (Должностное лицо) лауазымы, тегі, аты, әкесінің аты (бар болса), қолы (подпись, должность,
          фамилия, имя, отчество (при его наличии)        </p>
        <TextField
          id="outlined-basic"
          label="ФИО должностного лица"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.responsible?.value}
          onChange={(e) => handleChangeData('responsible', e.target.value, 'responsible')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          Сатушы өнімді (тауарды) сатып алу актісіне қол қоюдан бас тартты (Продавец отказался от подписания акта покупки продукции (товара))лауазымды адамның Тегі, Аты, Әкесінің аты (бар болса) және қолы (подпись и фамилия, имя, отчество (при его наличии) должностного лица)        </p>
        <TextField
          id="outlined-basic"
          label="Продавец отказался от подписания акта покупки продукции"
          style={{ width: "50%", margin: "0 0 0 30px" }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.rejected?.value}
          onChange={(e) => handleChangeData('rejected', e.target.value, 'rejected')}
        />
      </div>
      <TableContainer component={Paper} style={{ marginBottom: "30px", overflow: "hidden" }}>
        <Table
          aria-label="simple table"
          className="border-table-word"
        >
          <TableHead>
            <TableRow>
              <TableCell className="border-table-word">
                Тiркелу нөмірi (Регистрационный номер)
              </TableCell>
              <TableCell className="border-table-word">
                Үлгілер өнімдерінің (тауардың) атауы, өндірушісі (наименования образцов продукции (товара), производитель)
              </TableCell>
              <TableCell className="border-table-word">
                Алынған үлгілер саны
                (Количество образцов, ед.)
              </TableCell>
              <TableCell className="border-table-word">
                Сынаманың салмағы, көлемі (Вес, объем образца)
              </TableCell>
              <TableCell className="border-table-word">
                Сақтау мерзімі (Cрок годности)
              </TableCell>
              <TableCell className="border-table-word">
                Бірлік үшін бағасы, теңге
                (Цена за единицу, тенге)
              </TableCell>
              <TableCell className="border-table-word">
                "Жалпы сомасы, теңге
                (Итого сумма, тенге)"
              </TableCell>
              <TableCell className="border-table-word">
                "Пломба нөмірі
                (Номер пломбы)"
              </TableCell>
              <TableCell className="border-table-word">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.samples?.value?.map((sample, i) => {
              return <TableRow key={i}>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Регистрационный номер"
                    variant="outlined"
                    type="number"
                    disabled={isDisabledData}
                    value={sample.registrationNumber?.value}
                    onChange={(e) => handleChangeSample('registrationNumber', e.target.value, i, 'registrationNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Перечень отобранных образцов"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.sample?.value}
                    onChange={(e) => handleChangeSample('sample', e.target.value, i, 'sample')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Количество отобранных образцов"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.quantity?.value}
                    type="number"
                    onChange={(e) => handleChangeSample('quantity', e.target.value, i, 'quantity')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Вес, объем образца"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.weightSize?.value}
                    onChange={(e) => handleChangeSample('weightSize', e.target.value, i, 'weightSize')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker views={['day']} label="Cрок годности"
                      disabled={isDisabledData}
                      value={sample.expirationDate?.value}
                      onChange={(value) => handleChangeSample('expirationDate', value, i, 'expirationDate')}
                      minDate={new Date('1910-03-01')} renderInput={(params) =>
                        <TextField style={{ width: "100%" }} margin="normal"  {...params} helperText={null} />
                      } />
                  </LocalizationProvider>
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Цена за единицу, тенге"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.priceOne?.value}
                    onChange={(e) => handleChangeSample('priceOne', e.target.value, i, 'priceOne')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Итого сумма, тенге"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.summary?.value}
                    onChange={(e) => handleChangeSample('summary', e.target.value, i, 'summary')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <TextField
                    style={{ width: '100%' }}
                    id="outlined-basic"
                    label="Номер пломбы"
                    variant="outlined"
                    disabled={isDisabledData}
                    value={sample.plombNumber?.value}
                    onChange={(e) => handleChangeSample('plombNumber', e.target.value, i, 'plombNumber')}
                  />
                </TableCell>
                <TableCell className="border-table-word">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IconButton color="error"
                      size="large"
                      aria-label="delete"
                      disabled={isDisabledData}
                      onClick={() => { deleteSample(i) }}>
                      <CancelIcon />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <Button
          disabled={isDisabledData}
          variant="contained"
          onClick={addSample}
        >
          Добавить
        </Button>
      </div>
    </div >
  );
};

export default ActBuyProductsForm;
