import React, {useState} from "react";

import {Button} from "@mui/material";
import {BasicModal} from "./BasicModal/BasicModal";
import {BasicDialog} from "../Dialog";

export const ButtonModal = ({
                                children,
                                variant,
                                modal: Modal,
                                externalState,
                                setValue,
                                onSave,
                                onClose,
                                action,
                                data
                            }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = async () => {
        if (!onClose) return setOpen(false);

        await onClose();
        await setOpen(false);
    };

    return (
        <>
            <Button variant={variant} onClick={handleOpen}>
                {children}
            </Button>
            {Modal && (
                <Modal action={action}
                       open={open}
                       setOpen={setOpen}
                       onSave={onSave}
                       setValue={setValue}
                       externalState={externalState}
                       onClose={handleClose}
                       data={data}
                />
            )}
        </>
    );
};

ButtonModal.defaultProps = {
    children: "Открыть",
    open: false,
    variant: "contained",
    externalState: {},
    onSave: () => {
    },
    onClose: () => {
    },
};
