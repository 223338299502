export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_REQUEST = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_REQUEST';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_SUCCESS = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_SUCCESS';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_ERROR = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_ERROR';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_REQUEST = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_REQUEST';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_SUCCESS = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_SUCCESS';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_ERROR = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_GET_BY_KEY_ERROR';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_REQUEST = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_REQUEST';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_SUCCESS = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_SUCCESS';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_ERROR = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_ADD_ERROR';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_REQUEST = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_REQUEST';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_SUCCESS = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_SUCCESS';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_ERROR = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_EDIT_ERROR';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_REQUEST = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_REQUEST';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_SUCCESS = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_SUCCESS';
export const TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_ERROR = 'TECHNICAL_REGULATIONS_OF_CUSTOMS_UNION_DELETE_ERROR';
