import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DashboardLayout from "../../layouts/Dashboard";
import { Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useDialogs from "../../components/Dialog/useDialogs";
import { FormSection, PageContainer, PageFooter, PageHeader } from "../../components";
import CheckboxField from "../../components/Fields/CheckboxField";
import { TextField2 } from "../../components/Fields/TextField2";
import { TextPhoneField } from "../../components/Fields/TextPhoneField";
import { DateField } from "../../components/Fields/DateField";
import { DateTimeField } from "../../components/Fields/DateTimeField";
import { NumberField } from "../../components/Fields/NumberField";
import { GridItem } from "../../components/Fields/GridItem";
import { ResearchObjectSelect } from "../../components/Select/domainSelects";
import { ServiceGroupSelect } from "../../components/Select/domainSelects";
import { ServiceSubGroupSelect } from "../../components/Select/domainSelects";
import { ServiceKindSelect } from "../../components/Select/domainSelects";
import { GovernmentStandardSelect } from "../../components/Select/domainSelects";
import { TechnicalRegulationsOfCustomsUnionSelect } from "../../components/Select/domainSelects";
import { changeData, changeDataMany } from "../../functions/changeHandlers";
import { addServiceMap } from "../../redux/actions/serviceMapActions";
import { SERVICE_GROUP_LIST_ROUTE, SERVICE_MAP_LIST_ROUTE } from "../../routes";
import { submitData } from "../../functions/submitHandlers";
import { governmentCRUD, researchObjectCRUD, serviceGroupCRUD, serviceKindCRUD, serviceSubGroupCRUD, technicalRegulationsOfCustomsUnionCRUD } from "../../http";
import RestSelect from "../../components/Select/RestSelect";
import { addServiceGroup } from "../../redux/actions/serviceGroupActions";

export const CreateServiceGroup = () => {
    const dispatch = useDispatch();
    const { push } = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [serviceSubGroupQuery, setServiceSubGroupQuery] = useState({
        paging: { skip: 0 },
        filter: {
            // groupId: {
            //     operand1: 0,
            //     operator: 'equals'
            // }
        }
    });

    const handleChange = useCallback((key, value) => changeData(data, setData, key, value), [data]);
    const handleChangeMany = useCallback((obj) => changeDataMany(data, setData, obj), [data]);

    // useEffect(() => {
    //     const groupId = data?.groupId;
    //     if (!groupId)
    //         return;
    //     serviceSubGroupQuery.filter.groupId.operand1 = groupId;
    //     setServiceSubGroupQuery({...serviceSubGroupQuery});
    // }, [data?.groupId]);

    const {alert, DIALOG} = useDialogs();

    const handleSubmit = async () => {
        if (await submitData(data, async () =>
        {
            return true;
        }, setLoading, async () =>
        {
            return await dispatch(addServiceGroup(data));
        }))
        {
            return push(SERVICE_GROUP_LIST_ROUTE);
        }
    };

    return (
        <DashboardLayout>
            <>
                <DIALOG/>
                <PageHeader heading="Создание новой записи">
                    <Button variant={"text"} onClick={() => push(SERVICE_GROUP_LIST_ROUTE)}>
                        Вернуться
                    </Button>
                </PageHeader>
                <PageContainer>
                    <FormSection>
                        <Grid container>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={researchObjectCRUD}
                                    id={"object"}
                                    label={"Объект исследования"}
                                    value={data.objectId}
                                    onChange={(value, obj) => handleChangeMany({ researchObject: obj, objectId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={serviceGroupCRUD}
                                    id={"group"}
                                    label={"Группа"}
                                    value={data.groupId}
                                    onChange={(value, obj) => handleChangeMany({ serviceGroup: obj, groupId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={serviceSubGroupCRUD}
                                    query={serviceSubGroupQuery}
                                    id={"subGroup"}
                                    label={"Вид исследования"}
                                    value={data.subGroupId}
                                    onChange={(value, obj) => handleChangeMany({ serviceSubGroup: obj, subGroupId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={serviceKindCRUD}
                                    id={"kind"}
                                    label={"Метод"}
                                    value={data.kindId}
                                    onChange={(value, obj) => handleChangeMany({ serviceKind: obj, kindId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={governmentCRUD}
                                    id={"researchStandard"}
                                    label={"НД на метод исследования"}
                                    value={data.researchStandardId}
                                    onChange={(value, obj) => handleChangeMany({ governmentStandard: obj, researchStandardId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={governmentCRUD}
                                    id={"governmentStandard"}
                                    label={"НД на метод отбора"}
                                    value={data.governmentStandardId}
                                    onChange={(value, obj) => handleChangeMany({ governmentStandard: obj, governmentStandardId: value })}
                                    fullWidth
                                />
                            </GridItem>
                            <GridItem xs={6}>
                                <RestSelect
                                    restApi={technicalRegulationsOfCustomsUnionCRUD}
                                    id={"regulationOfCU"}
                                    label={"Документ санитарно-эпидемиологического нормирования (на соответствие, которого проводится исследование)"}
                                    value={data.regulationOfCUId}
                                    onChange={(value, obj) => handleChangeMany({ regulationOfCU: obj, regulationOfCUId: value })}
                                    fullWidth
                                />
                            </GridItem>
                        </Grid>
                    </FormSection>
                </PageContainer>
                <PageFooter>
                    <LoadingButton loading={loading} onClick={handleSubmit}>
                        Создать запись
                    </LoadingButton>
                </PageFooter>
            </>
        </DashboardLayout>
    )
};
