import { 
    FILIAL_SERVICE_GET_REQUEST, FILIAL_SERVICE_GET_SUCCESS, FILIAL_SERVICE_GET_ERROR, 
    FILIAL_SERVICE_GET_BY_KEY_REQUEST, FILIAL_SERVICE_GET_BY_KEY_SUCCESS, FILIAL_SERVICE_GET_BY_KEY_ERROR, 
    FILIAL_SERVICE_ADD_REQUEST, FILIAL_SERVICE_ADD_SUCCESS, FILIAL_SERVICE_ADD_ERROR, 
    FILIAL_SERVICE_EDIT_REQUEST, FILIAL_SERVICE_EDIT_SUCCESS, FILIAL_SERVICE_EDIT_ERROR, 
    FILIAL_SERVICE_DELETE_REQUEST, FILIAL_SERVICE_DELETE_SUCCESS, FILIAL_SERVICE_DELETE_ERROR
} from "../constants/filialServiceConstants";

const initialState = {
    filialServices: [],
    filialService: {},
    loading: false,
    success: null,
    error: null,
    filialServiceRequestedCount: 0,
};

export const filialServiceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case FILIAL_SERVICE_GET_REQUEST:
            return {
                ...state,
                filialServiceRequestedCount: ++state.filialServiceRequestedCount,
                loading: true,
            };
        case FILIAL_SERVICE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                filialServices: action.payload,
                success: true,
            };
        case FILIAL_SERVICE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_SERVICE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_SERVICE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                filialService: action.payload,
                success: true,
            };
        case FILIAL_SERVICE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_SERVICE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_SERVICE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FILIAL_SERVICE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_SERVICE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_SERVICE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FILIAL_SERVICE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case FILIAL_SERVICE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FILIAL_SERVICE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case FILIAL_SERVICE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
