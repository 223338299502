import React, {useCallback} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import Box from "@mui/material/Box";
import { Divider, Typography} from "antd";
import useLoader from "../../hooks/useLoader";
import LoadingButton from "@mui/lab/LoadingButton";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

// const style = {
//   position: 'absolute',
//   top: '0%',
//   left: '0%',
//   //transform: 'translate(-50%, -50%)',
//   width: 800,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
//   overflow: "auto"
// };

const style = {
  position: 'absolute',
  top: '10%',
  bottom: '20%',
  left: '20%',
  right: '20%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'auto',
  height: '85%',
  p: 4,
};


const RemovePopup = ({deleteAction, expandView, title, content, onClose, onSubmit}) => {
  const {start, stop, loading} = useLoader(false);

  const submit = useCallback(() => {
    start();
    deleteAction()
      .then(() => {
        onSubmit();
        onClose();
      })
      .catch(alert)
      .finally(stop)
  }, [start, stop, onClose, onSubmit, deleteAction]);

  return (
    <div>
      <Dialog open={true} onClose={onClose}>
        <DialogTitle>{title || "Вы действительно хотите удалить этот элемент?"}</DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} block type="primary" fullWidth variant="contained" sx={{mt: 3, mb: 2}}
                         onClick={submit}>
            {'Удалить'}
          </LoadingButton>
          <Button block fullWidth variant="contained" sx={{mt: 3, mb: 2}} style={{backgroundColor: '#aa0000'}}
                         onClick={() => onClose()}>
            {'Отмена'}
          </Button>
        </DialogActions>
        {/* {loading ? <LoadingOutlined/> :
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box sx={{width: '100%', typography: 'body1'}} onSubmit={submit} component={"form"}>
              {expandView}
              <Divider/>
            </Box>
          </Box>
        } */}
      </Dialog>
    </div>
  )
}

export default RemovePopup;
