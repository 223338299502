import { 
    CHEMICAL_INDICES_GET_REQUEST, CHEMICAL_INDICES_GET_SUCCESS, CHEMICAL_INDICES_GET_ERROR, 
    CHEMICAL_INDICES_GET_BY_KEY_REQUEST, CHEMICAL_INDICES_GET_BY_KEY_SUCCESS, CHEMICAL_INDICES_GET_BY_KEY_ERROR, 
    CHEMICAL_INDICES_ADD_REQUEST, CHEMICAL_INDICES_ADD_SUCCESS, CHEMICAL_INDICES_ADD_ERROR, 
    CHEMICAL_INDICES_EDIT_REQUEST, CHEMICAL_INDICES_EDIT_SUCCESS, CHEMICAL_INDICES_EDIT_ERROR, 
    CHEMICAL_INDICES_DELETE_REQUEST, CHEMICAL_INDICES_DELETE_SUCCESS, CHEMICAL_INDICES_DELETE_ERROR
} from "../constants/chemicalIndicesConstants";

const initialState = {
    chemicalIndiceses: [],
    chemicalIndices: {},
    loading: false,
    success: null,
    error: null,
    chemicalIndicesRequestedCount: 0,
};

export const chemicalIndicesReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CHEMICAL_INDICES_GET_REQUEST:
            return {
                ...state,
                chemicalIndicesRequestedCount: ++state.chemicalIndicesRequestedCount,
                loading: true,
            };
        case CHEMICAL_INDICES_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                chemicalIndiceses: action.payload,
                success: true,
            };
        case CHEMICAL_INDICES_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CHEMICAL_INDICES_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CHEMICAL_INDICES_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                chemicalIndices: action.payload,
                success: true,
            };
        case CHEMICAL_INDICES_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CHEMICAL_INDICES_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CHEMICAL_INDICES_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CHEMICAL_INDICES_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CHEMICAL_INDICES_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CHEMICAL_INDICES_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CHEMICAL_INDICES_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CHEMICAL_INDICES_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CHEMICAL_INDICES_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CHEMICAL_INDICES_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
