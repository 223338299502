import React from "react";

import {PageContainer, PageHeader} from "../../../components";
import {DDDRequestCRUDTable} from "../../../components/CRUD/Tables";
import DashboardLayout from "../../../layouts/Dashboard";
import useReload from "../../../hooks/useReload";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";
import {DDDRequestCRUD, KDIRequestCRUD} from "../../../http";
import {checkRoles} from "../../../functions/checkRoles";

export const DDDRequestTable = () => {
    const {reload, reloadValue} = useReload();

    const handleDelete = (_) => {
        return confirmDialog('Удаление заявки ДДД, ID:' + _, 'Вы действительно хотите удалить эту заявку?', async () => {
            await DDDRequestCRUD.delete(_);
            await reload();
        })
    }

    return (
        <DashboardLayout>
            <PageHeader heading="Заявки на ДДД"/>
            <PageContainer>
                <DDDRequestCRUDTable onClickDeleteButton={checkRoles('SuperAdministrator') ? handleDelete : null} reloadValue={reloadValue}/>
            </PageContainer>
        </DashboardLayout>
    )
};
