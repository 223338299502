import { 
    HEALTHCARE_ATTRIBUTE_GET_REQUEST, HEALTHCARE_ATTRIBUTE_GET_SUCCESS, HEALTHCARE_ATTRIBUTE_GET_ERROR, 
    HEALTHCARE_ATTRIBUTE_GET_BY_KEY_REQUEST, HEALTHCARE_ATTRIBUTE_GET_BY_KEY_SUCCESS, HEALTHCARE_ATTRIBUTE_GET_BY_KEY_ERROR, 
    HEALTHCARE_ATTRIBUTE_ADD_REQUEST, HEALTHCARE_ATTRIBUTE_ADD_SUCCESS, HEALTHCARE_ATTRIBUTE_ADD_ERROR, 
    HEALTHCARE_ATTRIBUTE_EDIT_REQUEST, HEALTHCARE_ATTRIBUTE_EDIT_SUCCESS, HEALTHCARE_ATTRIBUTE_EDIT_ERROR, 
    HEALTHCARE_ATTRIBUTE_DELETE_REQUEST, HEALTHCARE_ATTRIBUTE_DELETE_SUCCESS, HEALTHCARE_ATTRIBUTE_DELETE_ERROR
} from "../constants/healthcareAttributeConstants";

const initialState = {
    healthcareAttributes: [],
    healthcareAttribute: {},
    loading: false,
    success: null,
    error: null,
    healthcareAttributeRequestedCount: 0,
};

export const healthcareAttributeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case HEALTHCARE_ATTRIBUTE_GET_REQUEST:
            return {
                ...state,
                healthcareAttributeRequestedCount: ++state.healthcareAttributeRequestedCount,
                loading: true,
            };
        case HEALTHCARE_ATTRIBUTE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                healthcareAttributes: action.payload,
                success: true,
            };
        case HEALTHCARE_ATTRIBUTE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case HEALTHCARE_ATTRIBUTE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case HEALTHCARE_ATTRIBUTE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                healthcareAttribute: action.payload,
                success: true,
            };
        case HEALTHCARE_ATTRIBUTE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case HEALTHCARE_ATTRIBUTE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case HEALTHCARE_ATTRIBUTE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case HEALTHCARE_ATTRIBUTE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case HEALTHCARE_ATTRIBUTE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case HEALTHCARE_ATTRIBUTE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case HEALTHCARE_ATTRIBUTE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case HEALTHCARE_ATTRIBUTE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case HEALTHCARE_ATTRIBUTE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case HEALTHCARE_ATTRIBUTE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
