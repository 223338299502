import { 
    PROTOCOL_GET_REQUEST, PROTOCOL_GET_SUCCESS, PROTOCOL_GET_ERROR, 
    PROTOCOL_GET_BY_KEY_REQUEST, PROTOCOL_GET_BY_KEY_SUCCESS, PROTOCOL_GET_BY_KEY_ERROR, 
    PROTOCOL_ADD_REQUEST, PROTOCOL_ADD_SUCCESS, PROTOCOL_ADD_ERROR, 
    PROTOCOL_EDIT_REQUEST, PROTOCOL_EDIT_SUCCESS, PROTOCOL_EDIT_ERROR, 
    PROTOCOL_DELETE_REQUEST, PROTOCOL_DELETE_SUCCESS, PROTOCOL_DELETE_ERROR
} from "../constants/protocolConstants";

const initialState = {
    protocols: [],
    protocol: {},
    loading: false,
    success: null,
    error: null,
    protocolRequestedCount: 0,
};

export const protocolReducer = (state = initialState, action) => {
    switch (action.type)     {
        case PROTOCOL_GET_REQUEST:
            return {
                ...state,
                protocolRequestedCount: ++state.protocolRequestedCount,
                loading: true,
            };
        case PROTOCOL_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                protocols: action.payload,
                success: true,
            };
        case PROTOCOL_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                protocol: action.payload,
                success: true,
            };
        case PROTOCOL_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case PROTOCOL_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case PROTOCOL_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case PROTOCOL_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
