import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {filialCRUD, filialDivisionCRUD, referalsCRUD} from "../../../http";
import {FILIAL_DETAIL_ROUTE, REFERRAL_DETAIL_ROUTE, FILIAL_DIVISION_DETAIL_ROUTE} from "../../../routes";
import { checkRoles } from "../../../functions/checkRoles";
import React from "react";
import {getFilialDivisions} from "../../../redux/actions/filialDivisionActions";
import EmployeeField from "../../Relations/EmployeeField";
import {mapOrganizationName, mapOrganizationValues} from "../../../functions/viewDataMap";
import {getFilials} from "../../../redux/actions";

export default function FilialDivisionCRUDTable({extraFilter,withOrganization, pagination=true,...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();

    const columns = [
        {title: t("filialDivisionList.tableItem.id"), dataIndex: "id", key:"id"},
        {
            title: "Организация",
            key: 'organizationId',
            render: (unknown, row) => row.organizationId ? mapOrganizationName(row.organizationId) :
                <span style={{color: "gray"}}>Не задан</span>,
            hidden: !withOrganization
        },
        {title: t("filialDivisionList.tableItem.name"), dataIndex: "name", key:"name"},
        {title: t("filialDivisionList.tableItem.address"), dataIndex: "address", key:"address",render: (unknown, row) => row.addressLine ? row.addressLine : <span style={{color: "gray"}}>Не задан</span>},
        {title: t("filialDivisionList.tableItem.supervisor"),dataIndex: "supervisor", key: "supervisor", render: (unknown, row) => row.supervisor ? row.supervisor.fullName : <span style={{color: "gray"}}>Не задан</span>},
    ]

    const searchFields = [
        {
            title: "Наименование",
            key: "name",
            compareType: "like",
            operandPosition: 1,
            filterType: "normal"
        }
    ]

    const filterFields = [
        {
            title: "Организация",
            key: "organizationId",
            compareType: "equals",
            reducerKey: "organization",
            options: mapOrganizationValues(),
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ]
    return <CRUDTable 
        searchFields={searchFields} 
        filterFields={filterFields}
        search={filialDivisionCRUD.search}
        onClickCreateButton={checkRoles('SuperAdministrator') ? () => push(FILIAL_DIVISION_DETAIL_ROUTE("new")) : null}
        pagination={pagination}
        extraFilter={extraFilter}
        columns={columns}
        {...props}
    />
}

