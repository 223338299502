import React from 'react';
import {Button, Grid} from "@mui/material";
import TextField from "@mui/material/TextField";
import RemoveIcon from "@mui/icons-material/Remove";
import { red } from '@mui/material/colors';

function CollectionTube({data, setData, disabled}) {
    const handleChangeInput = (event, id, val) => {
        let mas = [...data]
        const idx = data.findIndex(el => el.id == id)
        mas[idx] = {...mas[idx], [val]: event.target.value}
        setData(mas)
    }
    const removeItem = (id) => {
        let mas = [...data]
        setData(mas.filter(el => el.id != id))
    }
    return (
        <>
            {data && data.map((el, idx) => {
                return (<React.Fragment key={idx}>
                    <Grid item xs={5}>
                        <TextField disabled={disabled} margin="normal"  value={el?.name} onChange={(event) => handleChangeInput(event, el.id, 'name')} fullWidth label="Пустой блок" />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField disabled={disabled} inputProps={{min: 1}} margin="normal" type='number' value={el?.quantity} onChange={(event) => handleChangeInput(event, el.id, 'quantity')} fullWidth label="Введите количество" />
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Button disabled={disabled} variant={"contained"} color={"warning"} style={{  marginLeft: "5px" }} onClick={() => removeItem(el.id)}><RemoveIcon/> </Button>
                    </Grid>
                    <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        
                    </Grid>
                </React.Fragment>)
                })}
        </>
    );
}

export default CollectionTube;