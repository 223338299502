import { contractRequestCRUD } from "../../http/CRUD";
import {
  CONTRACT_REQUEST_GET_REQUEST,
  CONTRACT_REQUEST_GET_SUCCESS,
  CONTRACT_REQUEST_GET_ERROR,
  CONTRACT_REQUEST_GET_BY_KEY_REQUEST,
  CONTRACT_REQUEST_GET_BY_KEY_SUCCESS,
  CONTRACT_REQUEST_GET_BY_KEY_ERROR,
  CONTRACT_REQUEST_CREATE_REQUEST,
  CONTRACT_REQUEST_CREATE_SUCCESS,
  CONTRACT_REQUEST_CREATE_ERROR,
  CONTRACT_REQUEST_UPDATE_REQUEST,
  CONTRACT_REQUEST_UPDATE_SUCCESS,
  CONTRACT_REQUEST_UPDATE_ERROR,
  CONTRACT_REQUEST_DELETE_REQUEST,
  CONTRACT_REQUEST_DELETE_SUCCESS,
  CONTRACT_REQUEST_DELETE_ERROR,
  CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST,
  CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/contractRequestConstants";

export const getContractRequests = (args) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACT_REQUEST_GET_REQUEST });

    const data = await contractRequestCRUD.search(args);
    dispatch({ type: CONTRACT_REQUEST_GET_SUCCESS, payload: data.result });

    return data.result;
  } catch (e) {
    dispatch({
      type: CONTRACT_REQUEST_GET_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const getContractRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await contractRequestCRUD.get(id);

    dispatch({
      type: CONTRACT_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });

    return data;
  } catch (e) {
    dispatch({
      type: CONTRACT_REQUEST_GET_BY_KEY_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const addContractRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACT_REQUEST_CREATE_REQUEST });

    const response = await contractRequestCRUD.create(data);
    await dispatch(getContractRequests(args));

    dispatch({ type: CONTRACT_REQUEST_CREATE_SUCCESS });

    return response.id;
  } catch (e) {
    dispatch({
      type: CONTRACT_REQUEST_CREATE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const updateContractRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_REQUEST_UPDATE_REQUEST,
    });

    const response = await contractRequestCRUD.edit(data);
    await dispatch(getContractRequests(args));

    dispatch({ type: CONTRACT_REQUEST_UPDATE_SUCCESS });
  } catch (e) {
    dispatch({
      type: CONTRACT_REQUEST_UPDATE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const deleteContractRequest = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_REQUEST_DELETE_REQUEST,
    });

    const response = await contractRequestCRUD.delete(id);
    await dispatch(getContractRequests(args));

    dispatch({ type: CONTRACT_REQUEST_DELETE_SUCCESS });
  } catch (e) {
    dispatch({
      type: CONTRACT_REQUEST_DELETE_ERROR,
      payload:
        e.response && e.response.data.message
          ? e.response.data.message
          : e.message,
    });
  }
};

export const workflowContractRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: CONTRACT_REQUEST_WORKFLOW_UPDATE_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await contractRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      await dispatch({ type: CONTRACT_REQUEST_WORKFLOW_UPDATE_SUCCESS });

      console.log({ response });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: CONTRACT_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
