
export const renderPageNumerationAndWatermark = (header, logo) => {
    return `<table style='margin-left:50in;'><tr style='height:1pt;mso-height-rule:exactly'>
    <td>
      <div style='mso-element:header' id=h1>
        <p class=headerFooter>
        ${header}
        </p>
      </div>

      <div style='mso-element:footer' id=f1>
        <span style='mso-no-proof:yes'><!--[if gte vml 1]><v:shapetype
        id="_x0000_t75" coordsize="21600,21600" o:spt="75" o:preferrelative="t"
        path="m@4@5l@4@11@9@11@9@5xe" filled="f" stroked="f">
        <v:stroke joinstyle="miter"/>
        <v:formulas>
          <v:f eqn="if lineDrawn pixelLineWidth 0"/>
          <v:f eqn="sum @0 1 0"/>
          <v:f eqn="sum 0 0 @1"/>
          <v:f eqn="prod @2 1 2"/>
          <v:f eqn="prod @3 21600 pixelWidth"/>
          <v:f eqn="prod @3 21600 pixelHeight"/>
          <v:f eqn="sum @0 0 1"/>
          <v:f eqn="prod @6 1 2"/>
          <v:f eqn="prod @7 21600 pixelWidth"/>
          <v:f eqn="sum @8 21600 0"/>
          <v:f eqn="prod @7 21600 pixelHeight"/>
          <v:f eqn="sum @10 21600 0"/>
        </v:formulas>
        <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
        <o:lock v:ext="edit" aspectratio="t"/>
        </v:shapetype><v:shape id="WordPictureWatermark2508501" o:spid="_x0000_s2050"
        type="#_x0000_t75" style='position:absolute;margin-left:0;margin-top:0;
        width:521.35pt;height:718.45pt;z-index:-251657216;mso-position-horizontal:center;
        mso-position-horizontal-relative:margin;mso-position-vertical:center;
        mso-position-vertical-relative:margin' o:allowincell="f">
        <v:imagedata src="https://i.ibb.co/kKy5Ynq/watermark-text.png"/>
        </v:shape><![endif]--></span>
        <p class=headerFooter>
        Страница
        <span style='mso-field-code:PAGE'></span>
        из
        <span style='mso-field-code:NUMPAGES'></span>
      </p>
      </div>
      <div style='mso-element:header' id=fh1>
        <p class=MsoHeader>        
        </p>
      </div>
      <div style='mso-element:footer' id=ff1>
      <p class=MsoFooter><o:p>&nbsp;</o:p></p>
      </div>
</td></tr></table>`
}

export const renderPageNumeration = (header) => {
    return `<table style='margin-left:50in;'><tr style='height:1pt;mso-height-rule:exactly'>
    <td>
        <div style='mso-element:header' id=h1>
        <p class=headerFooter>${header}</p>
      </div>
      <div style='mso-element:footer' id=f1>
        <p class=headerFooter>
        Страница
        <span style='mso-field-code:PAGE'></span>
        из
        <span style='mso-field-code:NUMPAGES'></span>
      </p>
      </div>
      &nbsp;
</td></tr></table>`
}

export function isLocalNetwork(hostname = window.location.hostname) {
    return (
        (['localhost', '127.0.0.1', '', '::1'].includes(hostname))
        || (hostname.startsWith('192.168.'))
        || (hostname.startsWith('10.0.'))
        || (hostname.endsWith('.local'))
    )
}

export const generateLink = (id, guid) => {
    let link;
    console.log(process.env.REACT_PROD_API_URL);
    if (isLocalNetwork()) {
        link = `${window.location.host}/protocolList?see=${id}&guid=${guid}`
    } else {
        link = `https://ekpo.nce.kz/protocolList?see=${id}&guid=${guid}`
    }
    return {url: link}
}

export const isPublicProtocolSee = (pathname, search) => {
    return pathname.indexOf('/protocolList') >= 0 && search.indexOf("see") >= 0;
}