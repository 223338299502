import m_11_2338_colorized from '././kostanai/KZ.M.11.2338.svg'
import m_11_2338 from '././kostanai/KZ.M.11.2338 black.svg'
import t_11_0264_colorized from '././kostanai/KZ.T.11.0264.svg'
import t_11_0264 from '././kostanai/KZ.T.11.0264 black.svg'

export default [
  m_11_2338_colorized,
  m_11_2338,
  t_11_0264_colorized,
  t_11_0264
]
