import React from "react";
import DashboardLayout from "../../layouts/Dashboard";
import AccreditationCRUDTable from "../../components/CRUD/Tables/AccreditationCRUDTable";
import {DIVISION_DETAIL_ROUTE, ACCREDITATION_DETAIL_ROUTE} from "../../routes";
import {accreditationCRUD} from "../../http";
import {useHistory} from "react-router-dom";
import useReload from "../../hooks/useReload";

const AccreditationList = () => {
    const history = useHistory()
    const {reload, reloadValue} = useReload();
    const detailAction = (id) => {
        history.push(ACCREDITATION_DETAIL_ROUTE(id), {
            review: true
        })
    }

    const editAction = (id) => {
        history.push(ACCREDITATION_DETAIL_ROUTE(id), {
            review: false
        })
    }

    const deleteAction =async(id)=>{
        try{
            await accreditationCRUD.delete(id)
            reload()
        }catch (err){

        }
    }
    return (
        <DashboardLayout>
            <AccreditationCRUDTable 
                withOrganization={true}
                onClickDetailsButton={detailAction}
                             onClickDeleteButton={deleteAction}
                             onClickEditButton={editAction} reloadValue={reloadValue}/>
        </DashboardLayout>
    )
};

export default AccreditationList;
