import DashboardLayout from "../../layouts/Dashboard";
import DivisionCRUDTable from "../../components/CRUD/Tables/DivisionCRUDTable";
import {useHistory} from "react-router-dom";
import useReload from "../../hooks/useReload";
import {DEPARTMENT_DETAIL_ROUTE, DIVISION_DETAIL_ROUTE} from "../../routes";
import {departmentCRUD, divisionCRUD} from "../../http";


const DivisionList = ()=>{
    const history = useHistory()
    const {reload, reloadValue} = useReload();

    const detailAction =(id)=>{
        history.push(DIVISION_DETAIL_ROUTE(id),{
            review:true
        })
    }

    const editAction =(id)=>{
        history.push(DIVISION_DETAIL_ROUTE(id),{
            review:false
        })
    }

    const deleteAction =async(id)=> {
        try {
            await divisionCRUD.delete(id)
            reload()
        } catch (err) {

        }
    }
    return <DashboardLayout>
        <DivisionCRUDTable onClickDetailsButton={detailAction}
                           onClickDeleteButton={deleteAction}
                           onClickEditButton={editAction} reloadValue={reloadValue} />
    </DashboardLayout>
}

export default DivisionList