import m_13_2071 from './KZ.M.13.2071.svg'
import m_13_2071_b from './KZ.M.13.2071 black.svg'
import t_13_e0493_colorized from './KZ.T.13.E0493.svg'
import t_13_e0493 from './KZ.T.13.E0493 black.svg'

export default [
  m_13_2071,
  m_13_2071_b,
  t_13_e0493_colorized,
  t_13_e0493
]
