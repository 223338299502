import { selfControlRequestCRUD } from "../../http";
import {
  SELF_CONTROL_REQUEST_GET_REQUEST,
  SELF_CONTROL_REQUEST_GET_SUCCESS,
  SELF_CONTROL_REQUEST_GET_ERROR,
  SELF_CONTROL_REQUEST_GET_BY_KEY_REQUEST,
  SELF_CONTROL_REQUEST_GET_BY_KEY_SUCCESS,
  SELF_CONTROL_REQUEST_GET_BY_KEY_ERROR,
  SELF_CONTROL_REQUEST_ADD_REQUEST,
  SELF_CONTROL_REQUEST_ADD_SUCCESS,
  SELF_CONTROL_REQUEST_ADD_ERROR,
  SELF_CONTROL_REQUEST_EDIT_REQUEST,
  SELF_CONTROL_REQUEST_EDIT_SUCCESS,
  SELF_CONTROL_REQUEST_EDIT_ERROR,
  SELF_CONTROL_REQUEST_DELETE_REQUEST,
  SELF_CONTROL_REQUEST_DELETE_SUCCESS,
  SELF_CONTROL_REQUEST_DELETE_ERROR,
  SELF_CONTROL_REQUEST_WORKFLOW_UPDATE_REQUEST,
  SELF_CONTROL_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  SELF_CONTROL_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/selfControlRequestConstants";

export const getSelfControlRequests = (args) => async (dispatch) => {
  if (!args)
    return;

  try {
    dispatch({
      type: SELF_CONTROL_REQUEST_GET_REQUEST,
    });

    const data = await selfControlRequestCRUD.search(args);
    dispatch({
      type: SELF_CONTROL_REQUEST_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: SELF_CONTROL_REQUEST_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSelfControlRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SELF_CONTROL_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await selfControlRequestCRUD.get(id);
    dispatch({
      type: SELF_CONTROL_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: SELF_CONTROL_REQUEST_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addSelfControlRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: SELF_CONTROL_REQUEST_ADD_REQUEST,
    });

    const response = await selfControlRequestCRUD.create(data);
    await dispatch(getSelfControlRequests(args));

    dispatch({
      type: SELF_CONTROL_REQUEST_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: SELF_CONTROL_REQUEST_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editSelfControlRequest =
  (data, args) => async (dispatch) => {
    try {
      dispatch({
        type: SELF_CONTROL_REQUEST_EDIT_REQUEST,
      });

      await selfControlRequestCRUD.edit(data);
      await dispatch(getSelfControlRequests(args));

      dispatch({
        type: SELF_CONTROL_REQUEST_EDIT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SELF_CONTROL_REQUEST_EDIT_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteSelfControlRequest =
  (id, args) => async (dispatch) => {
    try {
      dispatch({
        type: SELF_CONTROL_REQUEST_DELETE_REQUEST,
      });

      await selfControlRequestCRUD.delete(id);
      await dispatch(getSelfControlRequests(args));

      dispatch({
        type: SELF_CONTROL_REQUEST_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SELF_CONTROL_REQUEST_DELETE_ERROR,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const workflowSelfControlRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: SELF_CONTROL_REQUEST_WORKFLOW_UPDATE_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await selfControlRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      const updatedScheduleRequest = await selfControlRequestCRUD.get(id);

      console.log({ updatedScheduleRequest });

      dispatch({
        type: SELF_CONTROL_REQUEST_WORKFLOW_UPDATE_SUCCESS,
      });

      console.log({ response });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: SELF_CONTROL_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
