import { 
    BRIGADE_TYPE_GET_REQUEST, BRIGADE_TYPE_GET_SUCCESS, BRIGADE_TYPE_GET_ERROR, 
    BRIGADE_TYPE_GET_BY_KEY_REQUEST, BRIGADE_TYPE_GET_BY_KEY_SUCCESS, BRIGADE_TYPE_GET_BY_KEY_ERROR, 
    BRIGADE_TYPE_ADD_REQUEST, BRIGADE_TYPE_ADD_SUCCESS, BRIGADE_TYPE_ADD_ERROR, 
    BRIGADE_TYPE_EDIT_REQUEST, BRIGADE_TYPE_EDIT_SUCCESS, BRIGADE_TYPE_EDIT_ERROR, 
    BRIGADE_TYPE_DELETE_REQUEST, BRIGADE_TYPE_DELETE_SUCCESS, BRIGADE_TYPE_DELETE_ERROR
} from "../constants/brigadeTypeConstants";

const initialState = {
    brigadeTypes: [],
    brigadeType: {},
    loading: false,
    success: null,
    error: null,
    brigadeTypeRequestedCount: 0,
};

export const brigadeTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case BRIGADE_TYPE_GET_REQUEST:
            return {
                ...state,
                brigadeTypeRequestedCount: ++state.brigadeTypeRequestedCount,
                loading: true,
            };
        case BRIGADE_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                brigadeTypes: action.payload,
                success: true,
            };
        case BRIGADE_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_TYPE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_TYPE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                brigadeType: action.payload,
                success: true,
            };
        case BRIGADE_TYPE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_TYPE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_TYPE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case BRIGADE_TYPE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_TYPE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case BRIGADE_TYPE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case BRIGADE_TYPE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case BRIGADE_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case BRIGADE_TYPE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
