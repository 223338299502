import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import CRUDTable from "../CRUDTable";
import { organizationFilialMapCRUD } from "../../../http";
import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";
import { confirmDialog } from "../../Dialog/DialogDelete";

export default function OrganizationFilialMapCRUDTable({ ...props }) {
    const { t } = useTranslation();
    const { push } = useHistory();

    const columns = [
        {
            title: t("organizationFilialMapList.tableItem.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("organizationFilialMapList.tableItem.sourceFilial"),
            dataIndex: "sourceFilial",
            key: "sourceFilial",
            render: (value, row) => value?.name,
        },
        {
            title: t("organizationFilialMapList.tableItem.targetFilial"),
            dataIndex: "targetFilial",
            key: "targetFilial",
            render: (value, row) => value?.name,
        },
    ];

    const searchFields = [
        {
            filterType: "normal",
            title: t("organizationFilialMapList.filter.IdFilter"),
            key: "id",
            compareType: "equals",
            operandPosition: 1,
        },
    ];

    const filterFields = [
    ];

    return (
        <CRUDTable
            search={organizationFilialMapCRUD.search}
            columns={columns}
            filterFields={filterFields}
            searchFields={searchFields}
            {...props}
        />
    )
}

