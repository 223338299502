import React, { useEffect, useState} from 'react';
import {Document, Font, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import TimesNewRoman from "../../components/PDFGenerator/fonts/Times New Roman.ttf";
import TimesNewRomanItalic from "../../components/PDFGenerator/fonts/Times New Roman Italic.ttf";
import TimesNewRomanBold from "../../components/PDFGenerator/fonts/Times New Roman Bold.ttf";
import {formatDate, MOMENT_FORMAT_DATE_DMY_DOTS} from "../../functions/formatters";

function TarifficationPdf({initialValue, filter, contracts, filials, filialDivisions, groupedData}) {
    const styles = StyleSheet.create({
        info: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        },
        infoBlock: {
            maxWidth: '229px'
        },
        infoText: {
            textAlign: 'right',
            fontSize: 10,
        },
        page: {
            padding: '20 70 150 50',
            fontFamily: 'Times New Roman'
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '53px',
            border: '1px dashed black'
        },
        infoText1: {
            textAlign: 'center',
            fontFamily: 'Times New Roman Bold',
            fontSize: 13,
            marginBottom: 3
        },
        infoText2: {
            fontSize: 10,
            marginBottom: 3
        },
        infoText3: {
            fontSize: 10,
            fontFamily: 'Times New Roman Bold',
            marginTop: 15,
            marginLeft: 23
        },
        headerElement: {
            width: '10%',
            height: '100%',
            borderRight: '1px dashed black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        headerElement2: {
            width: '25%',
            height: '100%',
            borderRight: '1px dashed black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        headerElementFinal: {
            width: '15%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        headerElementText: {
            fontSize: 10,
            fontWeight: 'bold',
            textAlign: 'center'
        },
        text1: {
            fontFamily: 'Times New Roman Bold',
            fontSize: 10,
            fontWeight: 'bold',
            marginTop: 25
        },
        text2: {
            fontFamily: 'Times New Roman Bold',
            fontSize: 10,
            fontWeight: 'bold',
            marginTop: 10
        },
        text3: {
            fontFamily: 'Times New Roman Bold',
            fontSize: 10,
            fontWeight: 'bold',
        },
        bodyList: {
            marginTop: 7,
            marginBottom: 7
        },
        body: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '43px',
            border: '1px solid black'
        },
        bodyFinal: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        },
        bodyElement: {
            width: '10%',
            height: '100%',
            borderRight: '1px black black',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
        },
        bodyElement2: {
            width: '25%',
            height: '100%',
            paddingLeft: '4px',
            paddingTop: '4px',
            borderRight: '1px black black',
            display: 'flex',
        },
        bodyElementFinal: {
            width: '15%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            paddingRight: 3
        },
        bodyElementText: {
            fontSize: 10,
            fontWeight: 'bold',
            textAlign: 'center'
        },
        bodyElementText2: {
            fontSize: 10,
            fontWeight: 'bold',
            textAlign: 'left'
        },
        finalElementFirst: {
            width: '55%',
            height: '100%',
            display: 'flex',
        },
        finalElementFirst2: {
            width: '25%',
            height: '100%',
            paddingLeft: '4px',
            paddingTop: '4px',
            display: 'flex',
        },
        finalElementFirst3: {
            width: '10%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
        },
        finalElementFinal: {
            width: '15%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            paddingRight: 3
        },
    })
    const [data2, setData2] = useState([]);

    let result = []
    console.log('result', result)

    useEffect(async () => {
        const array = initialValue
        if (array.length) {
            await setData2(groupedData);
        }
    }, [])

    Font.register({
        family: 'Times New Roman',
        src: TimesNewRoman,
    })
    Font.register({
        family: 'Times New Roman Italic',
        src: TimesNewRomanItalic,
    })
    Font.register({
        family: 'Times New Roman Bold',
        src: TimesNewRomanBold,
    })

    const renderBodyList = (key, children, final = false) => {
        return (
            <View style={final && styles.bodyList}>
                <View style={final ? styles.bodyFinal : styles.body}>
                    {children}
                </View>
            </View>
        )
    }

    const renderBodyElement = (label, type = 1) => {
        switch (type) {
            case 1: {
                return <View style={styles.bodyElement}>
                    <Text style={styles.bodyElementText}>{label}</Text>
                </View>
            }
            case 2: {
                return <View style={styles.bodyElementFinal}>
                    <Text style={styles.bodyElementText}>{label}</Text>
                </View>
            }
            case 3: {
                return <View style={styles.bodyElement2}>
                    <Text style={styles.bodyElementText2}>{label[0]}</Text>
                    <Text style={styles.bodyElementText2}>\{label[1]}</Text>
                    <Text style={styles.bodyElementText2}>{label[2]}</Text>
                </View>
            }
        }
    }

    const renderFinalElement = (label, type = 1) => {
        switch (type) {
            case 1: {
                return <View style={styles.finalElementFirst3}>
                    {label && <Text style={styles.bodyElementText}>{label}</Text>}
                </View>
            }
            case 2: {
                return <View style={styles.finalElementFinal}>
                    {label && <Text style={styles.bodyElementText}>{label}</Text>}
                </View>
            }
            case 3: {
                return <View style={styles.finalElementFirst2}>
                    <Text style={styles.bodyElementText2}>{label[0]}</Text>
                    <Text style={styles.bodyElementText2}>\{label[1]}</Text>
                    <Text style={styles.bodyElementText2}>{label[2]}</Text>
                </View>
            }
            case 4: {
                return <View style={styles.finalElementFirst}>
                    <Text style={styles.text3}>{label}</Text>
                </View>
            }
        }
    }

    const renderGroupGroups = (groupGroups) => {
        return groupGroups && groupGroups.map(groupGroup => 
            <React.Fragment key={groupGroup.key}>
                <Text style={styles.text2}>{groupGroup.first?.group?.name}</Text>
                {groupGroup.researchCodeGroups && groupGroup.researchCodeGroups.map(researchCodeGroup => {
                    return renderBodyList(researchCodeGroup.key, 
                        <>
                            {renderBodyElement(researchCodeGroup.totalSummary.idx)}
                            {renderBodyElement(researchCodeGroup.totalSummary.researchCode)}
                            {renderBodyElement([
                                researchCodeGroup.first?.subGroup?.name, 
                                researchCodeGroup.first?.method?.name, 
                                '(одно исследование)'], 3)}
                            {/* {renderBodyElement(researchCodeGroup.totalSummary.subGroup)} */}
                            {renderBodyElement(researchCodeGroup.totalSummary.weightCoefficient)}
                            {renderBodyElement(researchCodeGroup.totalSummary.quantity)}
                            {renderBodyElement(researchCodeGroup.totalSummary.baseValue.toFixed(2))}
                            {renderBodyElement(researchCodeGroup.totalSummary.summary.toFixed(2))}
                            {renderBodyElement(researchCodeGroup.totalSummary.inconsistenceQuantity, 2)}
                        </>
                    )
                })
                }    
            </React.Fragment>
        )        
    }

    const renderGroupTotal = (group, title) => {
        const quantity = group.totalSummary.quantity || '0';
        const baseValue = group.totalSummary.baseValue.toFixed(2) || '0';
        const summary = group.totalSummary.summary.toFixed(2) || '0';
        const inconsistenceQuantity = group.totalSummary.inconsistenceQuantity || '0';
        return renderBodyList(group.key,
            <>
                {renderFinalElement(`Итого (${title})`, 4)}
                {renderFinalElement(quantity)}
                {renderFinalElement(baseValue)}
                {renderFinalElement(summary)}
                {renderFinalElement(inconsistenceQuantity, 2)}
            </>, true
        );
    }

    const first = data2.length > 0 ? data2[0].first : null;

    return (
        <Document language="ru">
            <Page wrap orientation="landscape" size="A3" style={styles.page}>
                <View>
                    <View style={styles.info}>
                        <View style={styles.infoBlock}><Text style={styles.infoText}>Приложение
                            к приказу Председателя Комитета охраны общественного
                            здоровья Министерства здравоохранения
                            Республики Казахстан от 17 января 2019 года №9-НК</Text></View>
                    </View>
                    <View>
                        <Text style={styles.infoText1}>Счет реестр</Text>
                        <Text style={styles.infoText1}>за оказанные услуги по проведению лабораторных исследований,
                            включая инструментальные замеры при проведении санитарно-</Text>
                        <Text style={styles.infoText1}>пидемиологической экспертизы,очаговой
                            дезинфекции,дезинсекции,дератизации в очагах инфекционных и паразитарных заболеваний
                            человека</Text>
                        <Text style={styles.infoText1}>отчетный период c {formatDate(filter?.researchEndDateTime1, MOMENT_FORMAT_DATE_DMY_DOTS)} по {formatDate(filter?.researchEndDateTime2, MOMENT_FORMAT_DATE_DMY_DOTS)}</Text>
                        {filter?.contract && <Text style={styles.infoText2}>Наименование договора КСЭК: {(contracts && contracts.find(_ => _.id === filter?.contract)?.name || '')}</Text>}
                        {filter?.filial && <Text style={styles.infoText2}>Наименование организации: {(filials && filials.find(_ => _.id === filter?.filial)?.name || '')}</Text>}
                        {filter?.filialDivision && <Text style={styles.infoText2}>Районное отделение: {filialDivisions && (filialDivisions.find(_ => _.id === filter?.filialDivision)?.name || "")}</Text>}
                        <Text style={styles.infoText2}>Код и наименование бюджетной программы : 070 "Охрана общественного здоровья"</Text>
                        <Text style={styles.infoText2}>Код и наименование бюджетной подпрограммы : 100 "Обеспечение
                            санитарно эпидемиологического благополучия населения"</Text>
                        <Text style={styles.infoText3}>Филиал</Text>
                    </View>
                    <View style={styles.header}>
                        <View style={styles.headerElement}>
                            <Text style={styles.headerElementText}>№ п/п </Text>
                        </View>
                        <View style={styles.headerElement}>
                            <Text style={styles.headerElementText}>Код услуги </Text>
                        </View>
                        <View style={styles.headerElement2}>
                            <Text style={styles.headerElementText}>Наименование</Text>
                        </View>
                        <View style={styles.headerElement}>
                            <Text style={styles.headerElementText}>Вес. коэффицент </Text>
                        </View>
                        <View style={styles.headerElement}>
                            <Text style={styles.headerElementText}>Количество услуг</Text>
                        </View>
                        <View style={styles.headerElement}>
                            <Text style={styles.headerElementText}>Количество
                                базовых ставок</Text>
                        </View>
                        <View style={styles.headerElement}>
                            <Text style={styles.headerElementText}>Стоимость
                                в тенге</Text>
                        </View>
                        <View style={styles.headerElementFinal}>
                            <Text style={styles.headerElementText}>Количество исследований
                                по которым получены
                                результаты не соответствующие
                                требованиям НТД</Text>
                        </View>
                    </View>
                    {data2.length && data2.map(_ => 
                        <React.Fragment key={_.key}>
                            <Text style={styles.text1}>{_.first?.department?.name}</Text>
                            {_.divisionGroups && _.divisionGroups.map(divisionGroup =>
                                <React.Fragment key={divisionGroup.key}>
                                    <Text style={styles.text2}>{divisionGroup.first?.division?.name}</Text>
                                    {renderGroupGroups(divisionGroup.groupGroups)}
                                    {renderGroupTotal(divisionGroup, divisionGroup.first?.division?.name)}
                                </React.Fragment>
                                )
                            }
                            {renderGroupGroups(_.groupGroups)}
                            {renderGroupTotal(_, _.first?.department?.name)}
                        </React.Fragment>
                        )
                    }
                    <React.Fragment>
                        {data2 && data2.totalSummary && renderGroupTotal(data2, 'Все лаборатории')}
                    </React.Fragment>
                </View>
            </Page>
        </Document>
    );
}

export default TarifficationPdf;