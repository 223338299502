import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LoadingButton from "@mui/lab/LoadingButton";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv6";
import ruLocale from "date-fns/locale/ru";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { style } from "../../components/TableMUIStyle";
import useLoader from "../../hooks/useLoader";
import { addResult } from "../../redux/actions/resultActions";
import { getResultForms } from "../../redux/actions/resultFormActions";

export default function CreateResult({ action, title, onClose, onSubmit, act }) {
  const dispatch = useDispatch();
  const [createdAt, setCreatedAt] = useState(new Date());
  const [formSchema, setFormSchema] = useState(null);
  const { loading } = useSelector((state) => state.result);

  // createByReferral
  const [resultFormId, setResultFormId] = useState(null);
  const [resultForm, setResultForm] = useState(null);
  const { resultForms, resultFormRequestedCount } = useSelector((state) => state.resultForm);

  const [formData, setFormData] = useState({});

  const { loading: submitLoading, start: submitStart, stop: submitStop } = useLoader(false);

  const submit = useCallback((_) => {
    submitStart();
    dispatch(addResult(_, {}))
      .then((r) => {
        onSubmit(r);
        onClose();
      })
      .catch(alert)
      .finally(submitStop);
  }, [onSubmit, dispatch, submitStart, submitStop, onClose]);

  const handleForm = async (e) => {
    e.preventDefault();
    if (resultForm == null) {
      alert('Нужно выбрать форму направления');
    } else if (resultForm.schema == null) {
      alert('Не определена схема формы');
    } else {
      submit({
        createdAt, schema: resultForm.schema, data: formData,
        formId: resultForm.id, actId: act.id, number: act.number
      });
    }
  };

  useEffect(() => {
    let response;
    const getResultFormsAsync = async () => {
      response = await dispatch(getResultForms({ paging: { skip: 0 } }));
    }
    if (resultFormRequestedCount == 0) {
      getResultFormsAsync();
    }

  }, [dispatch, action]);

  useEffect(() => {
    if (!resultFormId)
      return;
    let resultForm = resultForms.find(_ => _.id == resultFormId);
    let schema = resultForm.schema;

    if (!schema)
      return;
    let json = JSON.parse(schema);
    setFormSchema(json);
    setResultForm(resultForm);
  }, [dispatch, resultFormId]);
  let currentSchema = formSchema;
  return (
    <div>
      <Modal open={true} onClose={onClose} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {loading ? <CircularProgress /> :
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {title}
            </Typography>
            <Box component="form" onSubmit={handleForm} sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                    <DatePicker views={['day']} label="Создано" value={createdAt} onChange={(newValue) => {
                      setCreatedAt(newValue);
                    }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                      <TextField margin="normal"  {...params} helperText={null} />
                    } />
                  </LocalizationProvider>
                </Grid>
                <>
                  <Grid item xs={6}>
                    Направление №: {act?.number}
                  </Grid>
                  <Grid item xs={12}>
                    Форма журнала
                  </Grid>
                  <Grid item xs={12}>
                    <TextField value={resultFormId} onChange={(e) => setResultFormId(e.target.value)} margin="normal" fullWidth
                      id="resultFormId" select>
                      {resultForms && resultForms.map(_ => (
                        <MenuItem key={_.id} value={_.id}>
                          {_.number}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </>

                <Grid item xs={12}>
                  <Form
                      validator={validator}
                    schema={currentSchema ? currentSchema.jsonSchema : {}}
                    uiSchema={currentSchema ? currentSchema.uiSchema : {}}
                    formData={formData}
                    onChange={({ formData }) => setFormData(formData)}
                    onError={err => console.error('FORM ERROR: ', err)}
                  >
                    <button style={{ display: 'none' }} />
                  </Form>
                </Grid>
                <Grid item xs={6}>
                  <LoadingButton loading={submitLoading} type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    {'Сохранить'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
      </Modal>
    </div>
  )
}
