import { DatePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Label, MenuItem, TextField, Typography } from "@mui/material";
import ruLocale from "date-fns/locale/ru";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BasicModalContainer, Form } from "../../../components";
import useLoader from "../../../hooks/useLoader";
import { addResult, getEmployees, getGeoObjects, getReferrals, getResultForms, getResultStates, getServices } from "../../../redux/actions";

export const ResultCreateSection = ({ onSubmit }) => {
  const dispatch = useDispatch();

  // const [loading,setLoading] = useState(false);
  const { loading } = useSelector((s) => s.result);

  // TRASH**
  const [createdAt, setCreatedAt] = useState(new Date());
  const [formSchema, setFormSchema] = useState(null);
  const [data, setData] = useState({});
  const [referralId, setReferralId] = useState(null);
  const { referral, referrals } = useSelector((s) => s.referral);

  // createByReferral
  const [resultFormId, setResultFormId] = useState(null);
  const [resultForm, setResultForm] = useState(null);
  const { resultForms } = useSelector((s) => s.resultForm);

  const [formData, setFormData] = useState({});

  const {
    loading: submitLoading,
    start: submitStart,
    stop: submitStop,
  } = useLoader(false);

  // const submit = useCallback(
  //   (_) => {
  //     submitStart();
  //     dispatch(addResult(_, query || {}))
  //       .then((r) => {
  //         onSubmit(r);
  //         onClose();
  //       })
  //       .catch(alert)
  //       .finally(submitStop);
  //   },
  //   [onSubmit, dispatch, submitStart, submitStop, onClose]
  // );

  const handleForm = async (e) => {
    e.preventDefault();
    // switch (action) {
    //   case "create":
    // let referralById = referrals.find((_) => _.id == referralId);
    // let number = referralById?.number;
    // submit({
    //   createdAt,
    //   schema: schema,
    //   data: formData,
    //   formId,
    //   referralId,
    //   number,
    // });
    //     break;
    //   case "createByReferral":
    //   case "createByObjectReferral":
    //     if (resultForm == null) {
    //       alert("Нужно выбрать форму направления");
    //       break;
    //     }

    //     if (resultForm.schema == null) {
    //       alert("Не определена схема формы");
    //       break;
    //     }


    //     const request = {
    //       createdAt,
    //       schema: resultForm.schema,
    //       data: formData,
    //       formId: resultForm.id,
    //     };

    //     switch (action) {
    //       case "createByReferral":
    //         request.referralId = referral.id;
    //         request.number = referral.number;
    //         break;
    //       case "createByObjectReferral":
    //         request.objectReferralId = referral.id;
    //         request.number = referral.number;
    //         break;
    //     }

    //     submit(request);
    //     break;
    // }
  };

  const handleSubmit = async () => {
    const data = formData;
    const schema = resultForm?.schema;
    const formId = resultForm?.id;

    const referralById = referrals.find(({ id }) => id === referralId);
    const number = referralById?.number || null;

    const _data = {
      number,
      data,
      schema,
      createdAt,
      formId,
      referralId,
    };

    console.log({ _data, formData });
  };

  useEffect(() => {
    // let response;
    // const getReferralsAsync = async () => {
    //   response = await dispatch(getReferrals({ paging: { skip: 0 } }));
    // };
    // if (referralRequestedCount == 0) {
    //   getReferralsAsync();
    // }
    // // createByReferral
    // if (action === "createByReferral" || action === "createByObjectReferral") {
    //   const getResultFormsAsync = async () => {
    //     response = await dispatch(getResultForms({ paging: { skip: 0 } }));
    //   };
    //   if (resultFormRequestedCount == 0) {
    //     getResultFormsAsync();
    //   }
    // }
  }, [dispatch]);

  useEffect(() => {
    console.log({ resultFormId });
    if (!resultFormId) return;

    let resultForm = resultForms.find((form) => form.id === resultFormId);
    let schema = resultForm.schema;

    if (!schema) return;
    let json = JSON.parse(schema);

    setFormSchema(json);
    setResultForm(resultForm);
  }, [dispatch, resultFormId]);

  // let currentSchema = null;
  // switch (action) {
  //   case "create":
  //     currentSchema = schema;
  //     break;
  //   case "createByReferral":
  //   case "createByObjectReferral":
  //     currentSchema = formSchema;
  //     break;
  //   default:
  //     break;
  // }

  return (
    <>
      <BasicModalContainer>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LocalizationProvider
                locale={ruLocale}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  views={["day"]}
                  label="Создано"
                  value={createdAt}
                  onChange={(newValue) => {
                    setCreatedAt(newValue);
                  }}
                  minDate={new Date("1910-03-01")}
                  renderInput={(params) => (
                    <TextField margin="normal" {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            {/* <Grid item xs={6}>
              <TextField
                required
                value={referralId}
                onChange={(e) => setReferralId(e.target.value)}
                margin="normal"
                fullWidth
                id="referralId"
                select
              >
                {referrals &&
                  referrals.map((_) => (
                    <MenuItem key={_.id} value={_.id}>
                      {_.number}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid> */}

            <>
              <Grid item xs={6}>
                <TextField
                  label="Направление"
                  value={referral?.number}
                  onChange={(e) => setResultFormId(e.target.value)}
                  select
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Форма журнала"
                  value={resultFormId}
                  onChange={(e) => setResultFormId(e.target.value)}
                  select
                >
                  {resultForms &&
                    resultForms.map((_) => (
                      <MenuItem key={_.id} value={_.id}>
                        {_.number}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            </>

            {/* <Grid item xs={12}>
              <Form
                schema={currentSchema ? currentSchema.jsonSchema : {}}
                uiSchema={currentSchema ? currentSchema.uiSchema : {}}
                formData={formData}
                //FieldTemplate={CustomFieldTemplate}
                onChange={({ formData }) => setFormData(formData)}
                onError={(err) => console.error("FORM ERROR: ", err)}
              >
                <button style={{ display: "none" }} />
              </Form>
            </Grid> */}
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <LoadingButton loading={loading} onClick={handleSubmit}>
                Создать журнал
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      </BasicModalContainer>
    </>
  );
};
