import * as React from "react";
import {useEffect, useState} from "react";

import {Modal, Steps, Button, Input, Select} from 'antd';
import { DatePicker, Space } from 'antd';
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv6";
import moment from 'moment';

import {useDispatch, useSelector} from "react-redux";
import {getProbeStates} from "../../../redux/actions/probeStateActions";
import {getServices} from "../../../redux/actions/serviceActions";
import {getReferrals} from "../../../redux/actions/referralActions";

import '../Probe.scss'

const { Step } = Steps;
const { Option } = Select;

export default function CreateProbe({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();

    //USE_STATES
    const [id, setId] = useState(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    const [schema, setSchema] = useState('');
    const [data, setData] = useState('');
    const [stateId, setStateId] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const [current, setCurrent] = React.useState(0);
    const [formedData, setFormedData] = useState()
    const [formedProbeSchema, setFormedProbeSchema] = useState()
    const [serviceInput, setServiceInput] = useState('Направление не выбрано')
    const [dataInput, setDataInput] = useState('Направление не выбрано')

    //SELECTORS
    const {loading, probes, probe} = useSelector((state) => state.probe);
    const {probeStates} = useSelector((state) => state.probeState);
    const {services} = useSelector((state) => state.service);
    const {referrals} = useSelector((state) => state.referral);

    //FUNCTION
    const emptyState = () => {
        setId(null);
        setCreatedAt(new Date());
        setSchema('');
        setData('');
        setStateId(null);
        setServiceId(null);
        setReferralId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        setCurrent(0);
        setReferralId(null)
        setDataInput(null)
        setServiceInput('Направление не выбрано')
        onSubmit({id, createdAt, schema: null, data: formedData, stateId, serviceId, referralId});
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        setCurrent(0);
        setReferralId(null)
        setDataInput(null)
        setServiceInput('Направление не выбрано')
        emptyState();
    };
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const optionFilter = (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

    //USE_EFFECTS
    useEffect(() => {
        const {
            id, createdAt, schema, data, stateId, serviceId, referralId
        } = probe || {};
        setId(id);
        setCreatedAt(createdAt);
        setSchema(schema);
        setData(data);
        setStateId(stateId);
        setServiceId(serviceId);
        setReferralId(referralId);
    }, [probe, probes])
    useEffect(() => {
        let response;
        const getProbeStatesAsync = async () => {
            response = await dispatch(getProbeStates());
        }
        const getServicesAsync = async () => {
            response = await dispatch(getServices());
        }
        const getReferralsAsync = async () => {
            response = await dispatch(getReferrals());
        }
        if (probeStates.length === 0) {
            getProbeStatesAsync();
        }
        if (services.length === 0) {
            getServicesAsync();
        }
        if (referrals.length === 0) {
            getReferralsAsync();
        }
    }, [dispatch, action])
    useEffect(() => {
        referrals.map(_ => {
            if(_.id === referralId){
                setServiceInput(_.service.name)
                setDataInput(_.data)
                setFormedData(JSON.parse(_.data))
                setFormedProbeSchema(_.service.probeSchema)
                setServiceId(_.service.id)
            }else{
                return null
            }
        })
    }, [referralId])
    const dateFormat = 'YYYY/MM/DD';
    const steps = [
        {
            title: 'Заполните данные',
            content:
            <>
                <Space direction="vertical" size={12}>
                    <DatePicker
                        defaultValue={moment(createdAt)}
                        format={dateFormat}
                        fullWidth
                        style={{zIndex: '1300 !important', marginTop: '2rem', width: '50rem'}}
                    />
                    <Select
                        showSearch
                        className='mb-lg-0 mb-4'
                        placeholder='Выбирите направление'
                        optionFilterProp='children'
                        filterOption={optionFilter}
                        onChange={(value) => setReferralId(value)}
                        id="referralId"
                        required
                        fullWidth
                        value={referralId}
                    >
                        {referrals && referrals.map(_ => (
                            <Option key={_.id} value={_.id}>
                                {_.number}
                            </Option>
                        ))}
                    </Select>
                    <Input
                        placeholder='Направление не выбрано'
                        style={{ borderRadius: '0', width: '50rem'}}
                        required
                        value={dataInput}
                        onChange={(value) => setData(value)}
                        fullWidth
                        id="data"
                        name="data"
                        margin="normal"

                    />
                    <Select
                        className='mb-lg-0 mb-4'
                        id="serviceId"
                        placeholder='Направление не выбрано'
                        required
                        fullWidth
                        value={serviceInput}
                        disabled
                    />
                </Space>

            </>
        },
        {
            title: 'Форма',
            content:
                <Form
                    validator={validator}
                    schema={formedProbeSchema ?? {'type': 'object', 'properties': { 'email': { 'type': 'string', 'format': 'email' } }, 'required': [ 'email' ] }}
                    action="#"
                    onChange={({formData}) => setFormedData(formData)}
                    formData={formedData}
                    onError={err => console.error('FORM ERROR: ', err)}
                >
                    <button style={{display: 'none'}}/>
                </Form>

        }
    ];

    return (
        <div>
            <Modal
                title={title}
                visible={open}
                onCancel={handleClose}
                zIndex={'inherit'}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                width={750}
                footer={null}
            >
                <div>
                    <Steps current={current}>
                        {steps.map(item => (
                            <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div className="steps-content">{steps[current].content}</div>
                    <div className="steps-action">
                        {current < steps.length - 1 && (
                            <Button
                                type="primary"
                                onClick={() => next()}
                                disabled={!referralId}
                            >
                                Дальше
                            </Button>
                        )}
                        {current === steps.length - 1 && (
                            <Button type="primary" onClick={handleForm}>
                                Сохранить
                            </Button>
                        )}
                        {current > 0 && (
                            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                                Предыдущее
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    )
}
