import * as React from 'react';
import TextField from '@mui/material/TextField';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Stack} from "@mui/material";

import { formatDate2 } from '../../functions/formatters';

const FilterDatePicker2 = ({handleFunction, value, label})=>{
    let v = "";

    if (value) {
        v = formatDate2(value);
    }

    return (
        <TextField
            type={"date"}
            margin="normal"
            value={v}
            label={label}
            onChange={handleFunction}
            fullWidth
            style={{marginTop: "0px"}}
            InputLabelProps={{ shrink: true }}
        />
        // <LocalizationProvider dateAdapter={AdapterDateFns}>
        //     <DatePicker
        //         label={label}
        //         inputFormat="dd/MM/yyyy"
        //         value={value}
        //         onChange={handleFunction}
        //         renderInput={(params) => <TextField margin={"none"} {...params} />}
        //     />
        // </LocalizationProvider>

    );
}

export default FilterDatePicker2;
