export const GOVERNMENT_STANDARD_GET_REQUEST = 'GOVERNMENT_STANDARD_GET_REQUEST';
export const GOVERNMENT_STANDARD_GET_SUCCESS = 'GOVERNMENT_STANDARD_GET_SUCCESS';
export const GOVERNMENT_STANDARD_GET_ERROR = 'GOVERNMENT_STANDARD_GET_ERROR';
export const GOVERNMENT_STANDARD_GET_BY_KEY_REQUEST = 'GOVERNMENT_STANDARD_GET_BY_KEY_REQUEST';
export const GOVERNMENT_STANDARD_GET_BY_KEY_SUCCESS = 'GOVERNMENT_STANDARD_GET_BY_KEY_SUCCESS';
export const GOVERNMENT_STANDARD_GET_BY_KEY_ERROR = 'GOVERNMENT_STANDARD_GET_BY_KEY_ERROR';
export const GOVERNMENT_STANDARD_ADD_REQUEST = 'GOVERNMENT_STANDARD_ADD_REQUEST';
export const GOVERNMENT_STANDARD_ADD_SUCCESS = 'GOVERNMENT_STANDARD_ADD_SUCCESS';
export const GOVERNMENT_STANDARD_ADD_ERROR = 'GOVERNMENT_STANDARD_ADD_ERROR';
export const GOVERNMENT_STANDARD_EDIT_REQUEST = 'GOVERNMENT_STANDARD_EDIT_REQUEST';
export const GOVERNMENT_STANDARD_EDIT_SUCCESS = 'GOVERNMENT_STANDARD_EDIT_SUCCESS';
export const GOVERNMENT_STANDARD_EDIT_ERROR = 'GOVERNMENT_STANDARD_EDIT_ERROR';
export const GOVERNMENT_STANDARD_DELETE_REQUEST = 'GOVERNMENT_STANDARD_DELETE_REQUEST';
export const GOVERNMENT_STANDARD_DELETE_SUCCESS = 'GOVERNMENT_STANDARD_DELETE_SUCCESS';
export const GOVERNMENT_STANDARD_DELETE_ERROR = 'GOVERNMENT_STANDARD_DELETE_ERROR';
