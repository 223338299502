import { 
    SERVICE_TYPE_GET_REQUEST, SERVICE_TYPE_GET_SUCCESS, SERVICE_TYPE_GET_ERROR, 
    SERVICE_TYPE_GET_BY_KEY_REQUEST, SERVICE_TYPE_GET_BY_KEY_SUCCESS, SERVICE_TYPE_GET_BY_KEY_ERROR, 
    SERVICE_TYPE_ADD_REQUEST, SERVICE_TYPE_ADD_SUCCESS, SERVICE_TYPE_ADD_ERROR, 
    SERVICE_TYPE_EDIT_REQUEST, SERVICE_TYPE_EDIT_SUCCESS, SERVICE_TYPE_EDIT_ERROR, 
    SERVICE_TYPE_DELETE_REQUEST, SERVICE_TYPE_DELETE_SUCCESS, SERVICE_TYPE_DELETE_ERROR
} from "../constants/serviceTypeConstants";

const initialState = {
    serviceTypes: [],
    serviceType: {},
    loading: false,
    success: null,
    error: null,
    serviceTypeRequestedCount: 0,
};

export const serviceTypeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_TYPE_GET_REQUEST:
            return {
                ...state,
                serviceTypeRequestedCount: ++state.serviceTypeRequestedCount,
                loading: true,
            };
        case SERVICE_TYPE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceTypes: action.payload,
                success: true,
            };
        case SERVICE_TYPE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_TYPE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_TYPE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceType: action.payload,
                success: true,
            };
        case SERVICE_TYPE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_TYPE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_TYPE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_TYPE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_TYPE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_TYPE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_TYPE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_TYPE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_TYPE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_TYPE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
