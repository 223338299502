import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CRUDTable from "../CRUDTable";
import {planCRUD} from "../../../http";
import {PLAN_DETAIL_ROUTE, PLAN_EDIT_ROUTE } from "../../../routes";
import {getDepartments, getFilials} from "../../../redux/actions";
import {getFilialDivisions} from "../../../redux/actions/filialDivisionActions";
import { mapOrganizationName, mapOrganizationValues } from "../../../functions/viewDataMap";
import { toUIJson } from "../../../functions";
import { formatDate, formatDateAsDate, formatDateAsMonthYear, MOMENT_FORMAT_DATE_DMYHM_DOTS } from "../../../functions/formatters";

export default function PlanCRUDTable({withOrganization, ...props}) {
    const {t} = useTranslation();
    const {push} = useHistory();
    const [filial, setFilial] = useState(null);


    const columns = [
        {title: t("filialList.tableItem.id"), dataIndex: "id", key:"id"},
        {
            title: "Год и месяц",
            dataIndex: "month",
            key: "month",
            render: (value, row) => formatDateAsMonthYear(value),
        },
        {
            title: t("requestLockList.tableItem.filial"),
            dataIndex: "filial",
            key: "filial",
            render: (value, row) => value?.name,
        },
        {
            title: "Услуга",
            dataIndex: "service",
            key: "service",
            render: (value, row) => value?.name,
        },
        {title: "Количество", dataIndex: "amount", key:"amount"},
        {title: "Сумма", dataIndex: "sum", key:"sum"},
        // {
        //     title: "Создано",
        //     dataIndex: "createdAt",
        //     key: "createdAt",
        //     render: (unknown, row) => formatDateAsDate(row.createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS),
        // },

    ]

    const searchFields = [
        
    ]

    const filterFields = [
        {
            title: "Филиал",
            key: "filialId",
            compareType: "equals",
            reducerKey: "filial",
            reducerArrayKey: "filials",
            reducerAction: getFilials,
            filter: (opt) => {
                let result = true;

                if (opt.id == null) {
                return true;
                }

                return result;
            },
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Районное отделение",
            key: "filialDivisionId",
            compareType: "equals",
            reducerKey: "filialDivision",
            reducerArrayKey: "filialDivisions",
            reducerAction: getFilialDivisions,
            filter: (opt) => {
                let result = true;

                if (opt.id == null) {
                return true;
                }

                if(filial) {
                    return opt.filialId === filial;
                }
                return result;
            },
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        }
    ]

    return <CRUDTable
        search={planCRUD.search}
        columns={columns}
        onClickCreateButton={() => push(PLAN_DETAIL_ROUTE("new"))}
        // onRowClick={(record, rowIndex) => push({
        //     pathname: PLAN_EDIT_ROUTE(record.id),
        //     state:{
        //         record
        //     }
        // })}
        reducerFilterKey={"planId"}
        reducerParentKey={"id"}
        searchFields={searchFields}
        filterFields = {filterFields}
        {...props}
        sendFilterValues={async (name, value) => {
            if (name === 'filial' && value) {
                setFilial(value);
            }
            }}
    />
}