import { 
    INFECTION_KIND_GET_REQUEST, INFECTION_KIND_GET_SUCCESS, INFECTION_KIND_GET_ERROR, 
    INFECTION_KIND_GET_BY_KEY_REQUEST, INFECTION_KIND_GET_BY_KEY_SUCCESS, INFECTION_KIND_GET_BY_KEY_ERROR, 
    INFECTION_KIND_ADD_REQUEST, INFECTION_KIND_ADD_SUCCESS, INFECTION_KIND_ADD_ERROR, 
    INFECTION_KIND_EDIT_REQUEST, INFECTION_KIND_EDIT_SUCCESS, INFECTION_KIND_EDIT_ERROR, 
    INFECTION_KIND_DELETE_REQUEST, INFECTION_KIND_DELETE_SUCCESS, INFECTION_KIND_DELETE_ERROR
} from "../constants/infectionKindConstants";

const initialState = {
    infectionKinds: [],
    infectionKind: {},
    loading: false,
    success: null,
    error: null,
    infectionKindRequestedCount: 0,
};

export const infectionKindReducer = (state = initialState, action) => {
    switch (action.type)     {
        case INFECTION_KIND_GET_REQUEST:
            return {
                ...state,
                infectionKindRequestedCount: ++state.infectionKindRequestedCount,
                loading: true,
            };
        case INFECTION_KIND_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                infectionKinds: action.payload,
                success: true,
            };
        case INFECTION_KIND_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case INFECTION_KIND_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INFECTION_KIND_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                infectionKind: action.payload,
                success: true,
            };
        case INFECTION_KIND_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case INFECTION_KIND_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INFECTION_KIND_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case INFECTION_KIND_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case INFECTION_KIND_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INFECTION_KIND_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case INFECTION_KIND_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case INFECTION_KIND_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case INFECTION_KIND_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case INFECTION_KIND_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
