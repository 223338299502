import moment from "moment";

const convertDate = (date) => {
    return moment(date).format('YYYY-MM-DD')
}

const convertDateToTime = (date) => {
    return moment(date).format('HH:mm')
}

const resultParsingObject = (data, formData, el) => (
    {
        213: () => {
            if (el === 'selectionDateTime' && data[el].value) {
                formData.samplingDate = convertDate(data[el].value);
                return
            }
            if (el === 'object' && data[el].value) {
                formData.placeOfSelectionAddressAndNameOfTheOrganization =
                    `${data[el].value?.addressLine}, ${data[el].value?.name}`;
            }
        },
        223: () => {
            if (el === 'selectionDateTime' && data[el].value) {
                formData.samplingDate = convertDate(data[el].value);
            }
        },
        208: () => {
            if (el === 'selectionDateTime' && data[el].value) {
                formData.dateTime.theTeam = convertDate(data[el].value);
            }
        },
        283: () => {
            if (el === 'selectionDateTime' && data[el].value) {
                formData.dateOfSampling = convertDate(data[el].value);
            }
        }
    }
)
export const resultsIdHasSamples = [212, 223, 325, 243, 250, 220, 227, 228, 333, 335, 288, 332, 207, 210]

export const mappingActToResultData = (data, formNumber, resultForm, chooseSampleObject) => {
    let formData = {
        date: {},
        dateTime: {},
        dateMonthYear: {},
        dateAndTime: {}
    };
    Object.keys(data).map(el => {
        const object = resultParsingObject(data, formData, el)
        switch (formNumber) {
            case "ActZoonotic": {
                switch (resultForm) {
                    case 212: {
                        if (el === 'selectionPlace' && data[el].value) {
                            formData.samplingLocation = data[el]?.value;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTimeOfSampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 243: {
                        if (el === 'object' && data[el].value) {
                            formData.sampleOrganizationPlace =
                                data[el].value;
                            break;
                        }
                        break
                    }
                    case 327:
                    case 326: {
                        if (el === 'deliveryDateTime' && data[el].value) {
                            formData.deliveryDate = convertDate(data[el].value);
                            formData.timeOfDelivery = convertDateToTime(data[el].value);
                            break;
                        }
                        break
                    }
                    case 213: {
                        object[213]();
                        break
                    }
                    case 223: {
                        object[223]();
                        break
                    }
                    case 208: {
                        object[208]();
                        break
                    }
                    case 283: {
                        object[283]();
                        break
                    }
                }
                // if (el === 'selectionDateTime' && data[el].value) {
                //     formData.dateTime.sampling = convertDate(data[el].value);
                //     break;
                // }
                // break
                break
            }
            case "ActProducts": {
                switch (resultForm) {
                    case 212: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.date.theObtainedSample = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 238: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.alreadyTaken = convertDate(data[el].value);
                            break;
                        }
                        if (el === 'selectionPlace' && data[el].value) {
                            formData.nameOfTheSamplePlaceOfReceiptPositionOfTheRecipient = data[el]?.value;
                            break;
                        }
                        break
                    }
                    case 327:
                    case 326: {
                        if (el === 'deliveryDateTime' && data[el].value) {
                            formData.deliveryDate = convertDate(data[el].value);
                            formData.timeOfDelivery = convertDateToTime(data[el].value);
                            break;
                        }
                        break
                    }
                    case 213: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.samplingDate = convertDate(data[el].value);
                            break;
                        }
                        if (el === 'object' && data[el].value) {
                            formData.placeOfSelectionAddressAndNameOfTheOrganization =
                                `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break;
                        }

                        break
                    }
                    case 223: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.samplingDate = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 208: { //120
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.theTeam = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 283: { //255
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateOfSampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                }
                break
                // if (el === 'selectionDateTime' && data[el].value) {
                //     formData.dateTime.alreadyTaken = convertDate(data[el].value);
                //     break;
                // }
                // if (el === 'deliveryDateTime' && data[el].value) {
                //     formData.deliveryDate = convertDate(data[el].value);
                //     break;
                // }
                // break;
            }
            case "ActWater": {
                switch (resultForm) {
                    case 239: {
                        if (el === 'object' && data[el].value) {
                            formData.sampleLocationObject =
                                `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.alreadyTaken = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 325: {
                        if (el === 'selectionPlace' && data[el].value) {
                            formData.placeOfSampling = data[el].value;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateMonthYear.sampling = convertDate(data[el].value);
                            break;
                        }

                        break
                    }
                    case 223: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.samplingDate = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                }
                break
            }
            case "ActTicks": {
                switch (resultForm) {
                    case 250: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.sampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                }
                break
            }
            case "ActDisinfectant": {
                switch (resultForm) {
                    case 220: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateAndTime.sampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                }
                break
            }
            case "ActWaste": {
                switch (resultForm) {
                    case 325: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateMonthYear.sampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                }
                break
            }
            case "ActLand": {
                switch (resultForm) {
                    case 325: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateMonthYear.sampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 217: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.date.sampling = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 223: {
                        object[223]();
                        break
                    }
                }
                break
            }
            case "ActSamplingReport": {
                switch (resultForm) {
                    case 242: {
                        if (el === 'selectionPlace' && data[el].value) {
                            formData.samplingLocation = data[el]?.value;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTimeOfSampling = convertDate(data[el].value);
                            break;
                        }

                        break
                    }
                    case 243: {
                        if (el === 'object' && data[el].value) {
                            formData.sampleOrganizationPlace = `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break;
                        }

                        break
                    }
                    case 312:
                    case 313: {
                        if (el === 'object' && data[el].value) {
                            formData.sampleSelectedLocationObject = `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.alreadyTaken = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 314: {
                        if (el === 'object' && data[el].value) {
                            formData.sampleSelectedLocationObject = `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.selected = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 311: {
                        if (el === 'object' && data[el].value) {
                            formData.sampleSelectedLocationObject = `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break;
                        }
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateTime.theTeam = convertDate(data[el].value);
                            break;
                        }
                        break
                    }
                    case 223: {
                        object[223]();
                        break
                    }
                    case 207: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.samplingDate = convertDate(data[el].value);
                            break;
                        }

                        break
                    }
                    case 210: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.samplingDate = convertDate(data[el].value);
                            break;
                        }

                        break
                    }
                }
                break
            }
            case "ActRadio": {
                switch (resultForm) {
                    case 227: {
                        if (el === 'selectionDateTime' && data[el].value) {
                            formData.dateAndTime.sampling = convertDate(data[el].value);
                            break;
                        }
                        if (el === 'object' && data[el].value) {
                            formData.placeAndTimeOfSelection =
                                `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break
                        }
                        break
                    }
                    case 229: {
                        if (el === 'selectionPlace' && data[el].value) {
                            formData.measurementLocation =
                                data[el].value;
                            break
                        }
                        break
                    }
                    case 290: {
                        if (el === 'object' && data[el].value) {
                            formData.objectName =
                                `${data[el].value?.addressLine}, ${data[el].value?.name}`;
                            break
                        }
                        if (el === 'selectionPlace' && data[el].value) {
                            formData.measurementLocation =
                                data[el].value;
                            break
                        }
                        break
                    }
                }
                break
            }
        }
    })

    if (Object.keys(chooseSampleObject).length) {
        formData = {...formData, ...chooseSampleObject}
    }

    return formData
}
