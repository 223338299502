const objectFieldMatching = {
    adress: 'targetObject',
    organization_name: 'targetObject',
    forwarder_full_name: 'targetObject',
    sample_obtaining_place: 'samplingLocation',
    place_of_selection: 'samplingLocation',
    forwarder_org_name: 'samplingLocation',
    forwarder_name: 'samplingLocation',
    selection_date: 'sampleSelectionTime',
    sample_obtaining_date: 'sampleSelectionTime',
    sample_delivery_date: 'sampleDeliveryTime',
    probe_delivery_date: 'sampleDeliveryTime',
    delivery_date: 'sampleDeliveryTime',
    nd_on_research_method: 'standard2',
    normative_document: 'standard2',
    normative_document_selection: 'standard2',
    normative_document_measurements: 'standard2',
    transport_conditions: 'transportationCondition',
    storage_conditions: 'storageCondition',
    contact_number: 'phoneNumber',
    applicant_full_name: 'fullName',
    full_name: 'fullName',
    name: 'fullName',
    iin: 'identityNumber',
    age: 'yearsOld',
    place_of_work: 'placeOfWorkOrEducation',
    work_place: 'placeOfWorkOrEducation',
    home_adress: 'addressLine',
}

const isObjects = ['adress','organization_name', 'research_goal', 'transport_conditions', 'storage_conditions']
const KDI = ['contact_number','applicant_full_name', 'iin', 'age', 'place_of_work', 'home_adress', 'full_name', 'work_place', 'name']
const KDI_Object = ['forwarder_full_name']
const isArray = ['nd_on_research_method', 'normative_document', 'normative_document_selection', 'normative_document_measurements']
const isDate = ['delivery_date', 'selection_date', 'sample_obtaining_date', 'sample_delivery_date', 'probe_delivery_date']
const isSampling = ['place_of_selection', 'sample_obtaining_place', 'forwarder_org_name', 'forwarder_name']

export const mappingObjectReferralToProtocolData = (data, fields) => {
    let formData = {};


    fields?.map(el => {
        if (objectFieldMatching.hasOwnProperty(el?.id) && (data[objectFieldMatching?.[el?.id]] || data?.targetPatient?.[objectFieldMatching?.[el?.id]])) {
            if (KDI.includes(el.id)) {
                formData[el.id] = data?.targetPatient[objectFieldMatching[el?.id]];
            } 
            else if (KDI_Object.includes(el.id)) {
                formData[el.id] = data[objectFieldMatching[el.id]]?.name;
            }
            else if (isObjects.includes(el.id)) {
                formData[el.id] = data[objectFieldMatching[el.id]]?.name;
            } 
            else if (isArray.includes(el.id)) {
                let array = JSON.parse(data[objectFieldMatching[el.id]])
                formData[el.id] = array?.map(el => el?.name).join(', ');
            } 
            else if (isDate.includes(el.id)) {
                formData[el.id] = new Date(data[objectFieldMatching[el.id]]);
            } 
            else if (isSampling.includes(el.id)){
                console.log(data)
                formData[el.id] = data?.samplingLocation;
            }
            else {
                formData[el.id] = data[objectFieldMatching[el.id]];
            }
        }
    })
    return formData
}

export const mappingReferralToProtocolData = (data, fields) => {
    let formData = {};


    fields?.map(el => {
        if (objectFieldMatching.hasOwnProperty(el?.id) && (data[objectFieldMatching?.[el?.id]] || data?.targetPatient?.[objectFieldMatching?.[el?.id]])) {
            if (KDI.includes(el.id)) {
                formData[el.id] = data?.targetPatient[objectFieldMatching[el?.id]];
            } 
            else if (KDI_Object.includes(el.id)) {
                formData[el.id] = data[objectFieldMatching[el.id]]?.name;
            }
            else if (isObjects.includes(el.id)) {
                formData[el.id] = data[objectFieldMatching[el.id]]?.name;
            } 
            else if (isArray.includes(el.id)) {
                let array = JSON.parse(data[objectFieldMatching[el.id]])
                formData[el.id] = array?.map(el => el?.name).join(', ');
            } 
            else if (isDate.includes(el.id)) {
                formData[el.id] = new Date(data[objectFieldMatching[el.id]]);
            } 
            else if (isSampling.includes(el.id)){
                console.log(data)
                formData[el.id] = data?.samplingLocation;
            }
            else {
                formData[el.id] = data[objectFieldMatching[el.id]];
            }
        }
    })
    return formData
}
