import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGeoObjects } from "../../../redux/actions/geoObjectActions";
import { getEmployees } from "../../../redux/actions/employeeActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditDesinfectionReference({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [number, setNumber] = useState('');
    const [createdAt, setCreatedAt] = useState(new Date());
    const [fullName, setFullName] = useState('');
    const [addressLine, setAddressLine] = useState('');
    const [age, setAge] = useState(null);
    const [addressId, setAddressId] = useState(null);
    const [disinfectorId, setDisinfectorId] = useState(null);
    const { loading, desinfectionReferences, desinfectionReference } = useSelector((state) => state.desinfectionReference);
    const { geoObjects, geoObjectRequestedCount } = useSelector((state) => state.geoObject);
    const { employees, employeeRequestedCount } = useSelector((state) => state.employee);
    const emptyState = () => {
        setId(null);
        setNumber('');
        setCreatedAt(new Date());
        setFullName('');
        setAddressLine('');
        setAge(null);
        setAddressId(null);
        setDisinfectorId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, number, createdAt, fullName, addressLine, age, addressId, disinfectorId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, number, createdAt, fullName, addressLine, age, addressId, disinfectorId
        } = desinfectionReference || {};
        setId(id);
        setNumber(number);
        setCreatedAt(createdAt);
        setFullName(fullName);
        setAddressLine(addressLine);
        setAge(age);
        setAddressId(addressId);
        setDisinfectorId(disinfectorId);
    }, [desinfectionReference, desinfectionReferences])
    useEffect(() => {
        if (!open)
        {
            return;
        }
        let response;
        const getGeoObjectsAsync = async () => {
            response = await dispatch(getGeoObjects({ paging: { skip: 0, take: 10 } }));
        }
        const getEmployeesAsync = async () => {
            response = await dispatch(getEmployees({ paging: { skip: 0, take: 10 } }));
        }
        if (geoObjectRequestedCount == 0)
        {
            getGeoObjectsAsync();
        }
        if (employeeRequestedCount == 0)
        {
            getEmployeesAsync();
        }
    }, [dispatch, action, open])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={number} onChange={(e) => setNumber(e.target.value)} fullWidth id="number" name="number" label="Номер" />
                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                        <DatePicker views={['day']} label="Создано" value={createdAt} onChange={(newValue) => {
                                            setCreatedAt(newValue);
                                        }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                                            <TextField margin="normal"  {...params} helperText={null}/>
                                        }/>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={fullName} onChange={(e) => setFullName(e.target.value)} fullWidth id="fullName" name="fullName" label="ФИО" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={addressLine} onChange={(e) => setAddressLine(e.target.value)} fullWidth id="addressLine" name="addressLine" label="Адрес" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={age} onChange={(e) => setAge(e.target.value)} fullWidth id="age" name="age" label="Возраст" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField  value={addressId} onChange={(e) => setAddressId(e.target.value)} margin="normal" fullWidth id="addressId" select>
                                        {geoObjects && geoObjects.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField  value={disinfectorId} onChange={(e) => setDisinfectorId(e.target.value)} margin="normal" fullWidth id="disinfectorId" select>
                                        {employees && employees.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.fullName}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
