import useCreateObjectForm from "../../models/Object/useCreateObjectForm";
import AnObjectCRUDTable from "../CRUD/Tables/AnObjectCRUDTable";
import EmployeeCRUDTable from "../CRUD/Tables/EmployeeCRUDTable";
import SimpleTable from "../SimpleTable";
import { Button, Dialog, DialogContent, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

export default function EmployeeField({
  departmentId,
  label,
  value,
  object,
  onChange,
  extraFilter,
  disabled
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid container spacing={1}>
        {/* Field Line */}
        <Grid item xs={12}>
          <Toolbar>
            <Typography style={{ fontWeight: "bold", width: "50%" }}>
              {label || "Сотрудник"}:{" "}
            </Typography>
            <Typography>
              {object?.fullName}/{object?.position?.name}/
              {object?.department?.name}
            </Typography>
            <Button disabled={disabled} onClick={() => setOpen(true)}>Выбрать</Button>
          </Toolbar>
        </Grid>
      </Grid>
      {/* Dialog Select*/}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"80%"}
      >
        <DialogTitle>Выбор врача</DialogTitle>
        <Divider />
        <DialogContent>
          <EmployeeCRUDTable
            extraFilter={extraFilter}
            onClickCreateButton={undefined} // что бы не показывать кнопку создания
            onRowClick={(record, rowIndex) => {
              onChange(record);
              setOpen(false);
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant={"text"}
            style={{ marginLeft: "auto" }}
            onClick={() => setOpen(false)}
          >
            Отменить
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
