import { Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import { Cell, Row, Table } from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}).format

const styles = StyleSheet.create({
  page: {
    padding: '20 50 10 50'
  },
  titleWorks: {
    fontSize: 12,
    fontWidth: 600,
    fontFamily: 'Times New Roman Bold',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginVertical: 10
  },
  string: {
    fontSize: 10,
    marginVertical: 5
  },
  simpleString: {
    display: 'inline',
    fontSize: '10px'
  },
  table: {
    fontSize: 10,
    fontFamily: 'Times New Roman',
    marginVertical: 5
  },
  sampleBold: {
    fontFamily: 'Times New Roman Bold',
    fontSize: 8
  },
  sampleRegular: {
    fontFamily: 'Times New Roman',
    fontSize: 8
  }
})

Font.register({
  family: 'Times New Roman',
  src: TimesNewRoman,
})
Font.register({
  family: 'Times New Roman Italic',
  src: TimesNewRomanItalic,
})
Font.register({
  family: 'Times New Roman Bold',
  src: TimesNewRomanBold,
})

DddDisinsectionPDFGenerator.propTypes = PDFDocument.propTypes = {
  data: PropTypes.object,
  name: PropTypes.string,
  kazakhName: PropTypes.string,
}

function PDFDocument({ data, name, kazakhName }) {
  const actData = data.data
  const schema = [
    {
      key: 'Дезинфекциялауға өтінім қабылданды(Заявка на дезинфекцию принята)',
      value: actData?.disinfectionRequestAccepted?.value
    },
    {
      key: 'Уйымның атауы(наименование организации)',
      value: actData?.sendingOrganization?.value
    },
    {
      key: 'Науқастың жасы (возраст больного)',
      value: actData?.age?.value
    },
    {
      key: 'Науқастың тегі, аты, әкесінің аты (Фамилия, имя, отчество больного)',
      value: actData?.namePatient?.value
    },
    {
      key: 'Мекен-жайы (Адрес)',
      value: actData?.adress?.value
    },
    {
      key: 'Диагнозы (Диагноз)',
      value: actData?.diagnosis?.value?.name
    },
    {
      key: 'Емдеуге жатқызылды (Госпитализация состоялась)',
      value: actData?.diagnosis?.value?.name
    },
    {
      key: 'Диагнозы (Диагноз)',
      value: actData?.hospitalizationDate?.value ? formatTime(new Date(actData.hospitalizationDate?.value)) : ' '
    },
    {
      key: 'Ауруханаға (В больницу)',
      value: actData?.inHospital?.value
    },
    {
      key: 'Үйде қалдырылды (оставлен дома)',
      value: actData?.leftAtHome?.value
    },
    {
      key: 'Көшіру наряды (Наряд на эвакуацию) №',
      value: actData?.orderNumber?.value
    },
    {
      key: 'Дезинфекциялауға (Наряд на дезинфекцию вручен)',
      value: actData?.orderDate?.value ? formatTime(new Date(actData.orderDate?.value)) : ' '
    },
    {
      key: 'ФИО должностного лица, вручившего наряд за дезинфекцию',
      value: actData?.sendingNameChamber?.value?.fullName
    },
    {
      key: 'Зерттеу әдістеменің НҚ-ры (НД на метод испытаний)',
      value: actData?.standard?.value?.name
    },
    {
      key: 'Дезинфекциялау бригадасы (Дезбригада)',
      value: actData?.desbrigade?.value
    },
    {
      key: 'Дезинфекциялау нұсқаушысы (Дезинструктор)',
      value: actData?.disinstructor?.value?.fullName
    },
    {
      key: 'Дезинфекциялаушы (Дезинфектор)',
      value: data.disinfector?.fullName
    },
    {
      key: 'Диспетчер',
      value: actData?.dispatcher?.value?.fullName
    },
    {
      key: 'Дезинфекциялаудың жүргізілмеу(Причины не состоявшейся или поздней (дезинфекция начата))',
      value: actData?.disinfectionStart?.value ? formatTime(new Date(actData.disinfectionStart?.value)) : ' '
    },
    {
      key: 'Дезинфекциялау басталды(Окончена)',
      value: actData?.disinfectionEnd?.value ? formatTime(new Date(actData.disinfectionEnd?.value)) : ' '
    },
  ]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.titleWorks} wrap>
          <Text>{kazakhName}</Text>
          <Text>{name}</Text>
          <Text>№{data.number} от {actData?.dateOfAct?.value ? formatDate(new Date(actData.dateOfAct.value)) : ' '}</Text>
        </View>
        <Table style={styles.table}>
          {schema.map(el => {
            return <Row>
              <Cell>
                <Text style={{ fontFamily: 'Times New Roman Bold' }}>
                  {el.key}:
                </Text>
              </Cell>
              <Cell>
                <Text>
                  {el.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>
        <Table style={styles.table}>
          <Row>
            <Cell >
              <Text style={styles.sampleBold}>
              Объектілер тізбесі Перечень объектов:
              </Text>
            </Cell>
            <Cell >
              <Text style={styles.sampleBold}>
              Өлшем бірлігі Единица измерения:
              </Text>
            </Cell>
            <Cell>
              <Text style={styles.sampleBold}>
              Орындалды Выполнено:
              </Text>
            </Cell>
            <Cell>
              <Text style={styles.sampleBold}>
              Жұмсалған дезинфекциялау заттарының атауы Наименование израсходованых дезинфицирующих средств:
              </Text>
            </Cell>
            <Cell>
              <Text style={styles.sampleBold}>
              Мөлшері, кг Количество в кг:
              </Text>
            </Cell>
          </Row>
          {actData?.samples?.value.map(el => {
            return <Row>
              <Cell >
                <Text style={styles.sampleRegular}>
                  {el.object?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.unit?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.done?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.names?.value || ' '}
                </Text>
              </Cell>
              <Cell>
                <Text style={styles.sampleRegular}>
                  {el.quantity?.value || ' '}
                </Text>
              </Cell>
            </Row>
          })}
        </Table>

        <View style={{ fontFamily: 'Times New Roman' }}>
          <Text style={styles.simpleString}> Камералық дезинфекциялау үшін заттардың саны</Text>
          <Text style={styles.simpleString}> (Количество вещей для камерной дезинфекции) кг</Text>
          <Text style={styles.string}>{actData?.thingsQuantity?.value} </Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Санитариялық өңделуге жататын</Text>
          <Text style={styles.simpleString}>(Подлежат санитарной обработке)</Text>
          <Text style={styles.string}>{actData?.subjectToSanitization?.value} адамдар (человек) ___________________</Text>
        </View>
        <View style={{ fontFamily: 'Times New Roman', marginTop: 10 }}>
          <Text style={styles.simpleString}>Қазақстан Республикасы</Text>
        </View>
      </Page>
    </Document>
  )
}

export default function DddDisinsectionPDFGenerator(props) {
  return (
    <>
      <PDFViewer style={{ width: '100%', height: 700 }}>
        <PDFDocument {...props} />
      </PDFViewer>
      <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
        {({ url }) => url ? 'Скачать PDF' : 'Загрузка...'}
      </PDFDownloadLink>
    </>
  )
}
