import React, {useEffect, useState} from 'react'
import {Col, Row, Space, Table, Typography} from 'antd'
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {getReferralByKey} from "../../redux/actions/referralActions";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
const {Text} = Typography;

const TableComponent = ({columns, data, pagination, ExpandView}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {service} = useSelector((state) => state.service);

    const [rowData, setRowData] = useState([])
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [id, setId] = useState(null);
    const [removeOpen, setRemoveOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [jsonSchema, setJsonSchema] = useState('');


    const expandedRowRender = (record, i, ...args) => {
        if(ExpandView) return <ExpandView id={record.id} i={i} />;

        const columns = [
            {
                title: 'Подробнее',
                dataIndex: 'details',
                key: 'details',
                render: () => {
                    const values = rowData ? Object.values(rowData) : []

                    // values.shift()
                    return (
                      <Row>
                          <Col span={12}>
                              <Space direction="vertical">
                                  <Text>Id:</Text>
                                  <Text>Номер:</Text>
                                  <Text>Создано:</Text>
                                  <Text>Услуга:</Text>
                                  <Text>Контракт:</Text>
                              </Space>
                          </Col>
                          <Col span={12}>
                              <Space direction="vertical">
                                  {
                                      values.map(_ => <Text>{_}</Text>)
                                  }
                              </Space>
                          </Col>
                      </Row>
                    )
                }
            }
        ];

        const data = [{
            key: Math.floor(Math.random() * 1000),
            details: 'detail',
            form: 'form',
        }];

        return <TableComponent columns={columns} data={data} pagination={false}/>;

    };


    const setDetailsHandler = async (id) => {
        await dispatch(getReferralByKey(id));
        setCreateOpen(false);
        setDetailsOpen(false);
        setEditOpen(false);
        setRemoveOpen(false);
        setDetailsOpen(true);
        setId(id);
    }

    useEffect(() => {
        const {
            referralSchema
        } = service || {};
        setJsonSchema(referralSchema?.jsonSchema.replaceAll("'", '"') ?? '')
    }, [service])

    return(
        <Table
            pagination={pagination}
            className='components-table-demo-nested'
            columns={columns}
            rowKey={"id"}
            dataSource={data}

            expandIconColumnIndex={columns.length}
            expandable={!ExpandView ? undefined : {
                expandedRowRender: expandedRowRender,
                expandIcon: ({ expanded, onExpand, record, ...other }) => {
                    return expanded ? (
                      <EyeInvisibleOutlined style={{fontSize: '1.5rem'}} onClick={e => {
                          onExpand(record, e)
                      }}/>
                    ) : (
                      <EyeOutlined style={{fontSize: '1.5rem'}} onClick={e => {
                          onExpand(record, e)
                      }}/>
                    )
                }            }}
        />
    )
}

export default TableComponent
