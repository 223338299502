import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

import DisinfectionFinalCRUDTable from '../../../components/CRUD/Tables/DisinfectionFinalCRUDTable'
import {actCRUD, brigadeCRUD, brigadeTypeCRUD} from "../../../http";
import DashboardLayout from "../../../layouts/Dashboard";
import useReload from "../../../hooks/useReload";
import {confirmDialog} from "../../../components/Dialog/DialogDelete";

const DisinfectionTable = () => {
  const { pathname } = useLocation()
  const [brigade, setBrigade] = useState()
  useEffect(() => {
    brigadeTypeCRUD.search({
      "filter": {
        "code": {
          "operand1": `${pathname[1].toUpperCase()}${pathname.substring(2)}`,
          "operator": 'equals'
        }
      }
    }).then((data) => {
      setBrigade(data.result[0])
    })
  }, [])

  const extraFilter = {
    "typeId": {
      "operand1": brigade?.id,
      "operator": 'equals'
    }
  }

  const { reload, reloadValue } = useReload();

  const handleDelete = (_) => {
    return confirmDialog('Удаление наряда, ID:' + _, 'Вы действительно хотите удалить этот наряд?', async () => {
      await brigadeCRUD.delete(_);
      await reload();
    })
  }
  // if (employee?.filialId !== 1) {
  //   if (employee?.filialId) {
  //     extraFilter["filialId"] = {
  //       "operand1": employee?.filialId,
  //       "operator": "equals"
  //     }
  //   }
  //   if (employee?.departmentId) {
  //     extraFilter["departmentId"] = {
  //       "operand1": employee?.departmentId,
  //       "operator": "equals"
  //     }
  //   }
  //   if (employee?.filialDivisionId) {
  //     extraFilter["filialDivisionId"] = {
  //       "operand1": employee?.filialDivisionId,
  //       "operator": "equals"
  //     }
  //   }
  // }
  return (
    <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
      {brigade?.id
        ? <DisinfectionFinalCRUDTable reloadValue={reloadValue} onClickDeleteButton={handleDelete} extraFilter={extraFilter} />
        : <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>}
    </DashboardLayout>
  );
};

export default DisinfectionTable
