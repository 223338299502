import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import React from "react";

import SamplingPurposesCRUDTable from "../../components/CRUD/Tables/SamplingPurposesCRUDTable";

const ActSamplingPurpose = ({ isOpen, handleClose, onRowClick }) => {
  const handleSetSamplingPurpose = (object) => {
    onRowClick(object)
    handleClose()
  }

  return (
    <Dialog fullWidth maxWidth={'xl'} open={isOpen} onClose={handleClose}>
      <DialogTitle>Выберите цель</DialogTitle>
      <Divider />
      <DialogContent>
        <SamplingPurposesCRUDTable onRowClick={handleSetSamplingPurpose} />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>Отменить</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActSamplingPurpose;