import React, {useCallback, useMemo} from 'react';
import BlockTableForm from "./BlockTableForm";
import { toUIJson } from '../../functions/index';

export default function ProbeTypeBlockTableForm({data, probeType, onChange, ...props}) {
  const intProbe = useMemo(() => parseInt(probeType), [probeType]);

  const values = useMemo(() => (data || []).map(item => {
    if (item.data)
      return toUIJson(item.data);
    return item;
  }), [data]);

  const columns = useMemo(() => {
    if (!intProbe) return {columns: null, canDelete: null, canAdd: null};
    const columns = [];

    if ([1,2,4].includes(intProbe)) columns.push({type: "string", label: "Регистрационный номер", code: "Registration_number"});
    if ([3].includes(intProbe))     columns.push({type: "string", label: "Регистрационный номер образца", code: "Sample_registration_number"});
    if ([4].includes(intProbe))     columns.push({type: "textarea", label: "Характеристика помещения (кубатура)", code: "Characteristics_of_the_room"});
    if ([4].includes(intProbe))     columns.push({type: "number", label: "Количество работающих", code: "Number_of_employees"});
    if ([4].includes(intProbe))     columns.push({type: "string", label: "Метеорологические условия (температура, относительная влажность, атмосферное давление)", code: "Meteorological_conditions"});
    if ([1,2,3].includes(intProbe)) columns.push({type: "textarea", label: "Перечень отобранных образцов", code: "List_of_samples_taken"});
    if ([1].includes(intProbe))     columns.push({type: "textarea", label: "Количество отобранных образцов продукции с указанием производителя, серия (номера партии)", code: "The_number_of_product_samples_taken_with_an_indication_of_the_manufacturer_series"});
    if ([2].includes(intProbe))     columns.push({type: "number", label: "Количество отобранных образцов", code: "Number_of_samples_taken"});
    if ([2,3].includes(intProbe))   columns.push({type: "string", label: "Вес, объем Образца", code: "Sample_weight,_volume"});
    if ([3].includes(intProbe))     columns.push({type: "string", label: "Глубина отбора (см)", code: "Sampling_depth_(cm)"});
    if ([1,2].includes(intProbe))   columns.push({type: "date", label: "Дата производства", code: "Date_of_manufacture"});
    if ([1,2].includes(intProbe))   columns.push({type: "string", label: "Серия номер партии", code: "Batch_number_series"});
    if ([1].includes(intProbe))     columns.push({type: "date", label: "Дата производства (доп. информация)", code: "Date_of_manufacture2"});
    if ([1].includes(intProbe))     columns.push({type: "string", label: "Производитель", code: "Manufacturer"});
    if ([1].includes(intProbe))     columns.push({type: "string", label: "Общая стоимость образцов", code: "Total_cost_of_samples"});
    if ([1].includes(intProbe))     columns.push({type: "date", label: "Срок годности (до)", code: "Expiry_date_(before)"});
    if ([1].includes(intProbe))     columns.push({type: "textarea", label: "Срок годности (доп. информация)", code: "Expiry_date"});
    if ([1].includes(intProbe))     columns.push({type: "string", label: "Вес образца", code: "Sample_weight"});
    if ([1].includes(intProbe))     columns.push({type: "string", label: "Единица измерения веса", code: "Unit_of_measurement_of_weight"});
    if ([1].includes(intProbe))     columns.push({type: "string", label: "Объем Образца", code: "Sample_Volume"});
    if ([1].includes(intProbe))     columns.push({type: "string", label: "Единица измерения объема", code: "Unit_of_measure_for_volume"});
    if ([1,2,3].includes(intProbe)) columns.push({type: "textarea", label: "Вид упаковки и номер печати (пломбы)", code: "Type_of_packaging_and_number_of_the_seal"});

    return columns;
  }, [intProbe]);

  const handleChange = useCallback((values) => {
    onChange(values.map(val => ({data: val})));
  }, [onChange]);

  return columns ? <BlockTableForm
    columns={columns}
    data={values}
    onChange={handleChange}
    {...props}
  /> : null;
}
