import { 
    SERVICE_CATEGORY_GET_REQUEST, SERVICE_CATEGORY_GET_SUCCESS, SERVICE_CATEGORY_GET_ERROR, 
    SERVICE_CATEGORY_GET_BY_KEY_REQUEST, SERVICE_CATEGORY_GET_BY_KEY_SUCCESS, SERVICE_CATEGORY_GET_BY_KEY_ERROR, 
    SERVICE_CATEGORY_ADD_REQUEST, SERVICE_CATEGORY_ADD_SUCCESS, SERVICE_CATEGORY_ADD_ERROR, 
    SERVICE_CATEGORY_EDIT_REQUEST, SERVICE_CATEGORY_EDIT_SUCCESS, SERVICE_CATEGORY_EDIT_ERROR, 
    SERVICE_CATEGORY_DELETE_REQUEST, SERVICE_CATEGORY_DELETE_SUCCESS, SERVICE_CATEGORY_DELETE_ERROR
} from "../constants/serviceCategoryConstants";

const initialState = {
    serviceCategories: [],
    serviceCategory: {},
    loading: false,
    success: null,
    error: null,
    serviceCategoryRequestedCount: 0,
};

export const serviceCategoryReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_CATEGORY_GET_REQUEST:
            return {
                ...state,
                serviceCategoryRequestedCount: ++state.serviceCategoryRequestedCount,
                loading: true,
            };
        case SERVICE_CATEGORY_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceCategories: action.payload,
                success: true,
            };
        case SERVICE_CATEGORY_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_CATEGORY_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_CATEGORY_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceCategory: action.payload,
                success: true,
            };
        case SERVICE_CATEGORY_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_CATEGORY_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_CATEGORY_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_CATEGORY_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_CATEGORY_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_CATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_CATEGORY_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_CATEGORY_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_CATEGORY_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_CATEGORY_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
