import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Form from '@rjsf/mui';
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {resultCRUD} from "../../../http";
import validator from "@rjsf/validator-ajv6";

import { toUIJson } from '../../../functions/index';

const style = {
    position: 'absolute',
    top: '10%',
    bottom: '20%',
    left: '20%',
    right: '20%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow:'auto',
    height:'85%',
    display:'block',
    p: 4,
};

export default function EditResult({ action, title, open, setOpen, onSubmit, idResult }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    const [schema, setSchema] = useState({});
    const [data, setData] = useState({});
    const [stateId, setStateId] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const [referral, setReferral] = useState(null);
    const [form, setForm] = useState(null);
    const [formId, setFormId] = useState(null);
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(null);
    //const { loading, results, result } = useSelector((state) => state.result);
    const { resultStates, resultStateRequestedCount } = useSelector((state) => state.resultState);
    const emptyState = () => {
        setId(null);
        setCreatedAt(new Date());
        setSchema({});
        setData({});
        setStateId(null);
        setReferralId(null);
        setReferral(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ ...result, id, createdAt, schema, data, stateId, referralId, formId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(async () => {
        if (!open)
            return;

        await setLoading(true);
        const res = await resultCRUD.get(idResult);
        setResult(res);
        if (res) {
            if (schema) {
                setSchema(toUIJson(res.schema));
            }

            setId(res.id);
            setCreatedAt(res.createdAt);
            if (res.data) {
                setData(JSON.parse(res.data));
            }
            setStateId(res.stateId);
            setReferralId(res.referralId);
            setReferral(res.referral);
            setForm(res.form);
            setFormId(res.formId);
         }
         await setLoading(false);
    }, [idResult, open])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                        <DatePicker views={['day']} label="Создано" value={createdAt} onChange={(newValue) => {
                                            setCreatedAt(newValue);
                                        }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                                            <TextField margin="normal"  {...params} helperText={null}/>
                                        }/>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Статус: {resultStates.find(_ => _.id == stateId)?.name} */}
                                    <Typography>Номер формы: {result?.form?.name || result?.form?.number}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    Направление №: {referral?.number}
                                </Grid>
                                <Grid item xs={12}>
                                    {schema.jsonSchema && schema.uiSchema && <Form
                                        validator={validator}
                                        schema={schema ? schema.jsonSchema : {}}
                                        uiSchema={schema ? schema.uiSchema : {}}
                                        formData={data}
                                        onChange={({formData}) => setData(formData)}
                                        onError={err => console.error('FORM ERROR: ', err)}
                                        validator={validator}
                                    >
                                        <button style={{display: 'none'}}/>
                                    </Form>}
                                </Grid>
                                <Grid item xs={6}>
                                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                        {'Сохранить'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
