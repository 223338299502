import { REQUEST_TYPE_ID_AnObjectReferralRequest } from "../redux/constants/requestTypeConstants";
import { REQUEST_TYPE_ID_ReferralRequest } from "../redux/constants/requestTypeConstants";
import { isEmptyDate } from "./formatters";

export function validateReferralForSubmit(data, alert, requestType = REQUEST_TYPE_ID_AnObjectReferralRequest) {
    if (isNull(data.filialId)) {
        alert('Ошибка валидации', 'Филиал необходимо заполнить');
        return false;
    }

    switch (requestType) {
        case REQUEST_TYPE_ID_AnObjectReferralRequest:
            if (isNull(data.governmentContractId && isNull(data.filialDivisionId))) {
                alert('Ошибка валидации', 'Договор КСЭК и Наименование отделения необходимо заполнить');
                return false;
            }
            else if (isNull(data.governmentContractId)) {
                alert('Ошибка валидации', 'Договор КСЭК необходимо заполнить');
                return false;
            }
            else if (isNull(data.filialDivisionId)) {
                alert('Ошибка валидации', 'Наименование отделения необходимо заполнить');
                return false;
            }
            break;
    }

    return true;
}

export function validateReferralSpecificationsForSubmit(specifications, results, protocols, alert, requestType = REQUEST_TYPE_ID_AnObjectReferralRequest, 
    researchStartDateTime, researchEndDateTime, actId) {
    if (!specifications?.length) {
        alert('Ошибка валидации', 'Список исследований необходимо заполнить');
        return false;
    }
    
    const errors = [];
    specifications?.forEach(_ => {
        switch (requestType) {
            case REQUEST_TYPE_ID_AnObjectReferralRequest:                
                if ((isNull(_?.inconsistenceQuantity) || !_?.quantity) && !actId) {
                    errors.push(_.id);
                }
                break;
            case REQUEST_TYPE_ID_ReferralRequest:
                if (isNull(_?.discountPercent) && !actId) {
                    errors.push(_.id);
                }
                break;
        }        
    })

    switch (requestType) {
        case REQUEST_TYPE_ID_AnObjectReferralRequest:
            if (isEmptyDate(researchStartDateTime)) {
                alert('Ошибка валидации', 'Дата начала исследования необходимо заполнить');
                return false;
            }

            if (isEmptyDate(researchEndDateTime)) {
                alert('Ошибка валидации', 'Дата окончания исследования необходимо заполнить');
                return false;
            }
            break;
        case REQUEST_TYPE_ID_ReferralRequest:
                if (isEmptyDate(researchEndDateTime)) {
                    alert('Ошибка валидации', 'Дата окончания исследования необходимо заполнить');
                    return false;
                }
                break;
    }        

    if (results && results.length == 0) {
        alert('Ошибка валидации', 'Необходимо добавить журналы');
        return false;
    }

    if (protocols && protocols.length == 0) {
        alert('Ошибка валидации', 'Необходимо добавить протокол');
        return false;
    }

    if (errors.length > 0) {
        switch (requestType) {
            case REQUEST_TYPE_ID_AnObjectReferralRequest:
                alert('Ошибка валидации', 'В списке исследований поля "Кол-во исследований" и "Кол-во исследований с несоответствием" должны быть заполнены');
                return false;
            case REQUEST_TYPE_ID_ReferralRequest:
                alert('Ошибка валидации', 'В списке исследований поля "Скидка" должны быть заполнены');
                return false;
        }
        
    }
    
    return true;
}

export function isNull(value) {
    return !value && value != 0;
}