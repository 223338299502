import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField,
  MenuItem,
  Box,
  Button,
  FormGroup,
  InputLabel,
  Input,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingButton, TimePicker } from "@mui/lab";

import {
  getEmployeeByKey,
  getEmployees2,
} from "../../../../redux/actions";
import {
  getSelfControlRequestByKey,
  editSelfControlRequest,
} from "../../../../redux/actions/selfControlRequestActions";

import {
  PageHeader,
  PageContainer,
  PageFooter,
  Form,
  FormSection,
  CustomDatePicker,
  SelectFieldModal,
  CustomTimePicker,
  SelectField,
  BasicTable,
  ButtonModal,
  NavLinkButton,
} from "../../../../components";
import {
  FilialDivisionSelect,
  FilialSelect,
  DepartmentSelect,
  DivisionSelect
} from "../../../../components/Select/domainSelects";

import DashboardLayout from "../../../../layouts/Dashboard";

export const SelfControlRequestEditSection = ({ mode }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [requestId, setRequestId] = useState(id);
  const [request, setRequest] = useState({});

  const { selfControlRequest } = useSelector(
    (s) => s.selfControlRequest
  );

  const [educationServices, setEducationServices] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  const [objects, setObjects] = useState([]);
  const [acts, setActs] = useState([]);

  const [filialId, setFilialId] = useState(null);
  const [filialDivisionId, setFilialDivisionId] = useState(null);
  const [departmentId, setDepartmentId] = useState(null);
  const [divisionId, setDivisionId] = useState(null);
  const [responsibleId, setResponsibleId] = useState(null);
  const [responsible, setResponsible] = useState({});
  const [performerId, setPerformerId] = useState(null);
  const [performer, setPerformer] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);


    const data = {
      ...selfControlRequest,
      filialId,
      filialDivisionId,
      departmentId,
      divisionId,
      responsibleId,
      performerId
    };

    await dispatch(editSelfControlRequest(data));

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    dispatch(getSelfControlRequestByKey(requestId)).then(setRequest);
  }, [dispatch, requestId]);

  useEffect(() => {
    setRequest(selfControlRequest);
    setObjects(selfControlRequest.objects);
    setSpecifications(selfControlRequest.specifications);
    setActs(selfControlRequest.acts);
    setFilialId(selfControlRequest.filialId);
    setFilialDivisionId(selfControlRequest.filialDivisionId);
    setDepartmentId(selfControlRequest.departmentId);
    setDivisionId(selfControlRequest.divisionId);
    setResponsibleId(selfControlRequest.responsibleId);
    setPerformerId(selfControlRequest.performerId);
  }, [selfControlRequest]);

  useEffect(() => {
    if (!responsibleId) return;
    dispatch(getEmployeeByKey(responsibleId)).then(setResponsible);
  }, [dispatch, responsibleId]);

  useEffect(() => {
    if (!performerId) return;
    dispatch(getEmployeeByKey(performerId)).then(setPerformer);
  }, [dispatch, performerId]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PageContainer>
          <FormSection heading="Заявка" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <TextField
                  label="Номер заявки"
                  type="number"
                  value={request?.contractRequestNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Сводная заявка"
                  type="text"
                  value={request?.contractRequestId}
                  disabled
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label="Автор заявки"
                  type="text"
                  value={request?.createdBy?.fullName}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <SelectFieldModal
                  label="Исполнитель заявки"
                  cells={[
                    { id: "id", label: t("employee.tableItem.id") },
                    {
                      id: "fullName",
                      label: t("employee.tableItem.fullName"),
                    },
                    {
                      id: "position",
                      label: t("employee.tableItem.position"),
                      render: (row) => row?.position?.name,
                    },
                    {
                      id: "filial",
                      label: t("employee.tableItem.filial"),
                      render: (row) => row?.filial?.name,
                    },
                    {
                      id: "department",
                      label: t("employee.tableItem.department"),
                      render: (row) => row?.department?.name,
                    },
                  ]}
                  actionFn={getEmployees2}
                  valueName="id"
                  value={performer?.fullName}
                  setValue={setPerformerId}
                  usePagingByApi={true}
                />
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomDatePicker
                      label="Дата заявки"
                      value={request?.createdAt}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Объекты ({objects?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("object.tableItem.id") },
                    { id: "name", label: t("object.tableItem.name") },
                    {
                      id: "locality",
                      label: t("object.tableItem.locality"),
                      render: (row) => row?.locality?.name,
                    },
                    {
                      id: "district",
                      label: t("object.tableItem.district"),
                      render: (row) => row?.district?.name,
                    },
                    {
                      id: "city",
                      label: t("object.tableItem.city"),
                      render: (row) => row?.city?.name,
                    },
                    {
                      id: "addressLine",
                      label: t("object.tableItem.addressLine"),
                    },
                  ]}
                  rows={objects}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSection mb={4}>
            <Typography component="h4" align="left" variant="h6">
              Услуги ({specifications?.length || 0})
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <BasicTable
                  cells={[
                    { id: "id", label: t("specification.tableItem.id") },
                    {
                      id: "object",
                      label: t("specification.tableItem.object"),
                      render: ({ object }) => object?.name,
                    },
                    {
                      id: "serviceKind",
                      label: t("specification.tableItem.serviceKind"),
                      render: ({ serviceKind }) => serviceKind?.name,
                    },
                    {
                      id: "service",
                      label: t("specification.tableItem.service"),
                      render: ({ service }) => service?.name,
                    },
                    {
                      id: "unit",
                      label: t("specification.tableItem.unit"),
                      render: ({ unit }) => unit?.name,
                    },
                    {
                      id: "schedule",
                      label: t("specification.tableItem.schedule"),
                      render: ({ schedule }) => schedule?.name,
                    },
                    {
                      id: "group",
                      label: 'Группа',
                      render: ({ service }) => service?.group?.name,
                    },
                    {
                      id: "method",
                      label: 'Метод',
                      render: ({ service }) => service?.kind?.name,
                    },
                    {
                      id: "count",
                      label: t("specification.tableItem.quantity"),
                    },
                    {
                      id: "contractCount",
                      label: t("specification.tableItem.quantityContract"),
                    },
                    { id: "price", label: t("specification.tableItem.price") },
                  ]}
                  rows={specifications}
                  disabled
                />
              </Grid>
            </Grid>
          </FormSection>

          <FormSection heading="Филиал" mb={4}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <FilialSelect
                  label={"Филиал"}
                  fullWidth
                  value={filialId}
                  onChange={(val) => {
                    setFilialId(val);
                    setFilialDivisionId(Number(val) !== Number(filialId) ? 
                      null
                      : filialDivisionId);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <FilialDivisionSelect
                  label={"Районное отделение"}
                  fullWidth
                  value={filialDivisionId}
                  filialId={filialId}
                  onChange={(val, filialDivision) => {
                    setFilialDivisionId(val);
                    setFilialId(Number(filialDivision.filialId) !== Number(filialId)
                      ? filialDivision.filialId
                      : filialId);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <DepartmentSelect
                  label={"Лаборатория"}
                  fullWidth
                  value={departmentId}
                  onChange={(val) => {
                    if (!val)
                      return;

                    setDepartmentId(val);
                    setDivisionId(Number(val) !== Number(departmentId) ? 
                      null
                      : divisionId);
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <DivisionSelect
                  label={"Отдел лаборатории"}
                  fullWidth
                  value={divisionId}
                  departmentId={departmentId}
                  onChange={(val, division) => {
                    if (!val)
                      return;

                    setDivisionId(val);
                    setDepartmentId(Number(division.departmentId) !== Number(departmentId)
                      ? division.departmentId
                      : departmentId);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <SelectFieldModal
                  label="Зав. лаборатории"
                  cells={[
                    { id: "id", label: t("employee.tableItem.id") },
                    {
                      id: "fullName",
                      label: t("employee.tableItem.fullName"),
                    },
                    {
                      id: "position",
                      label: t("employee.tableItem.position"),
                      render: (row) => row?.position?.name,
                    },
                    {
                      id: "filial",
                      label: t("employee.tableItem.filial"),
                      render: (row) => row?.filial?.name,
                    },
                    {
                      id: "department",
                      label: t("employee.tableItem.department"),
                      render: (row) => row?.department?.name,
                    },
                  ]}
                  actionFn={getEmployees2}
                  valueName="id"
                  value={responsible?.fullName}
                  setValue={setResponsibleId}
                  usePagingByApi={true}
                />
              </Grid>
            </Grid>
          </FormSection>
        </PageContainer>
        <PageFooter>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Сохранить
          </LoadingButton>
        </PageFooter>
      </Form>
    </>
  );
};
