import DirectionMicrobioForm from "./DirectionMicrobioForm";
import DirectionMicrobioPDFGenerator from "./DirectionMicrobioPDFGenerator";

import withDirectionDetail from "../withDirectionDetail";

const directionFormKazakhName = 'Адамдардан іріктелген сынамаларды микробиологиялық тексеруге ЖОЛДАМА'
const directionFormName = 'НАПРАВЛЕНИЕ материала от людей на микробиологические исследования'
const directionFormNumber = '019'
const directionName = 'DirectionMicrobio'

export default withDirectionDetail(DirectionMicrobioForm, DirectionMicrobioPDFGenerator, directionFormKazakhName,  directionFormName,  directionFormNumber, directionName)
