export const PROBE_SELECTION_REQUEST_GET_REQUEST = 'PROBE_SELECTION_REQUEST_GET_REQUEST';
export const PROBE_SELECTION_REQUEST_GET_SUCCESS = 'PROBE_SELECTION_REQUEST_GET_SUCCESS';
export const PROBE_SELECTION_REQUEST_GET_ERROR = 'PROBE_SELECTION_REQUEST_GET_ERROR';
export const PROBE_SELECTION_REQUEST_GET_BY_KEY_REQUEST = 'PROBE_SELECTION_REQUEST_GET_BY_KEY_REQUEST';
export const PROBE_SELECTION_REQUEST_GET_BY_KEY_SUCCESS = 'PROBE_SELECTION_REQUEST_GET_BY_KEY_SUCCESS';
export const PROBE_SELECTION_REQUEST_GET_BY_KEY_ERROR = 'PROBE_SELECTION_REQUEST_GET_BY_KEY_ERROR';
export const PROBE_SELECTION_REQUEST_ADD_REQUEST = 'PROBE_SELECTION_REQUEST_ADD_REQUEST';
export const PROBE_SELECTION_REQUEST_ADD_SUCCESS = 'PROBE_SELECTION_REQUEST_ADD_SUCCESS';
export const PROBE_SELECTION_REQUEST_ADD_ERROR = 'PROBE_SELECTION_REQUEST_ADD_ERROR';
export const PROBE_SELECTION_REQUEST_EDIT_REQUEST = 'PROBE_SELECTION_REQUEST_EDIT_REQUEST';
export const PROBE_SELECTION_REQUEST_EDIT_SUCCESS = 'PROBE_SELECTION_REQUEST_EDIT_SUCCESS';
export const PROBE_SELECTION_REQUEST_EDIT_ERROR = 'PROBE_SELECTION_REQUEST_EDIT_ERROR';
export const PROBE_SELECTION_REQUEST_DELETE_REQUEST = 'PROBE_SELECTION_REQUEST_DELETE_REQUEST';
export const PROBE_SELECTION_REQUEST_DELETE_SUCCESS = 'PROBE_SELECTION_REQUEST_DELETE_SUCCESS';
export const PROBE_SELECTION_REQUEST_DELETE_ERROR = 'PROBE_SELECTION_REQUEST_DELETE_ERROR';
