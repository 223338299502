import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Form from '@rjsf/mui';
import validator from "@rjsf/validator-ajv6";
import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getResultStates } from "../../../redux/actions/resultStateActions";

import { toUIJson } from '../../../functions/index';

const style = {
    position: 'absolute',
    top: '10%',
    bottom: '20%',
    left: '20%',
    right: '20%',
    //transform: 'translate(-50%, -50%)',
    //width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow:'auto',
    height:'85%',
    display:'block',
    p: 4,
};

export default function RemoveResult({ action, title, open, setOpen, onSubmit, defaultId }) {
    const dispatch = useDispatch();
    const [id, setId] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [schema, setSchema] = useState('');
    const [data, setData] = useState('');
    const [stateId, setStateId] = useState('');
    const [referralId, setReferralId] = useState('');
    const [referral, setReferral] = useState(null);
    const [form, setForm] = useState(null);
    const { loading, results, result } = useSelector((state) => state.result);
    const { resultStates, resultStateRequestedCount } = useSelector((state) => state.resultState);
    const emptyState = () => {
        setId(null);
        setCreatedAt('');
        setSchema({});
        setData({});
        setStateId('');
        setReferralId('');
    };
    const handleForm = async (e) => {
        e.preventDefault();
        debugger;
        onSubmit(id);
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(async () => {
        if (!open)
            return;

        if (resultStateRequestedCount == 0)
            await dispatch(getResultStates({ paging: { skip: 0 } }));

        const {
            id, createdAt, schema, data, stateId, referralId, referral, form
        } = result || {};
        setId(defaultId || id);
        setCreatedAt(createdAt);
        if (schema) {
            setSchema(toUIJson(schema));
        }
        if (data) {
            setData(JSON.parse(data));
        }
        setStateId(stateId);
        setReferralId(referralId);
        setReferral(referral);
        setForm(form);
    }, [result, results, open]);
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    ID: {id}
                                </Grid>
                                <Grid item xs={6}>
                                    Создано: {createdAt}
                                </Grid>
                                <Grid item xs={6}>
                                    Статус: {resultStates && resultStates.find(_ => _.id == stateId)?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Направление №: {referral?.number}
                                </Grid>
                                <Grid item xs={12}>
                                    {schema && <Form
                                        validator={validator}
                                        schema={schema ? schema.jsonSchema : {}}
                                        uiSchema={schema ? schema.uiSchema : {}}
                                        formData={data}
                                        readonly
                                        onError={err => console.error('FORM ERROR: ', err)}
                                    >
                                        <button style={{display: 'none'}}/>
                                    </Form>}
                                </Grid>
                                <Grid item xs={6}>
                                    <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                        {'Удалить'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
