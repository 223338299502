import { $authHost } from "../../http";
import { 
    SAMPLE_PRESERVATION_METHOD_GET_REQUEST, SAMPLE_PRESERVATION_METHOD_GET_SUCCESS, SAMPLE_PRESERVATION_METHOD_GET_ERROR, 
    SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_REQUEST, SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_SUCCESS, SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_ERROR, 
    SAMPLE_PRESERVATION_METHOD_ADD_REQUEST, SAMPLE_PRESERVATION_METHOD_ADD_SUCCESS, SAMPLE_PRESERVATION_METHOD_ADD_ERROR, 
    SAMPLE_PRESERVATION_METHOD_EDIT_REQUEST, SAMPLE_PRESERVATION_METHOD_EDIT_SUCCESS, SAMPLE_PRESERVATION_METHOD_EDIT_ERROR, 
    SAMPLE_PRESERVATION_METHOD_DELETE_REQUEST, SAMPLE_PRESERVATION_METHOD_DELETE_SUCCESS, SAMPLE_PRESERVATION_METHOD_DELETE_ERROR
} from "../constants/samplePreservationMethodConstants";

export const getSamplePreservationMethods = (args) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/samplePreservationMethod/query",
            args || {},
            config,
        );
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getSamplePreservationMethodByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/samplePreservationMethod/${id}`,
        );
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addSamplePreservationMethod = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/samplePreservationMethod",
            { name, code, description },
            config,
        );
        dispatch(getSamplePreservationMethods(queryArgs || {}));
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editSamplePreservationMethod = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/samplePreservationMethod",
            { id, name, code, description },
            config,
        );
        dispatch(getSamplePreservationMethods(queryArgs || {}));
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteSamplePreservationMethod = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/samplePreservationMethod",
            { data: { id } },
            config,
        );
        dispatch(getSamplePreservationMethods(queryArgs || {}));
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: SAMPLE_PRESERVATION_METHOD_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

