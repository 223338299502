import { 
    GENDER_GET_REQUEST, GENDER_GET_SUCCESS, GENDER_GET_ERROR, 
    GENDER_GET_BY_KEY_REQUEST, GENDER_GET_BY_KEY_SUCCESS, GENDER_GET_BY_KEY_ERROR, 
    GENDER_ADD_REQUEST, GENDER_ADD_SUCCESS, GENDER_ADD_ERROR, 
    GENDER_EDIT_REQUEST, GENDER_EDIT_SUCCESS, GENDER_EDIT_ERROR, 
    GENDER_DELETE_REQUEST, GENDER_DELETE_SUCCESS, GENDER_DELETE_ERROR
} from "../constants/genderConstants";

const initialState = {
    genders: [],
    gender: {},
    loading: false,
    success: null,
    error: null,
    genderRequestedCount: 0,
};

export const genderReducer = (state = initialState, action) => {
    switch (action.type)     {
        case GENDER_GET_REQUEST:
            return {
                ...state,
                genderRequestedCount: ++state.genderRequestedCount,
                loading: true,
            };
        case GENDER_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                genders: action.payload,
                success: true,
            };
        case GENDER_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GENDER_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GENDER_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                gender: action.payload,
                success: true,
            };
        case GENDER_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GENDER_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GENDER_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GENDER_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GENDER_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GENDER_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GENDER_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case GENDER_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GENDER_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case GENDER_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
