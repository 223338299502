import React from "react";
import { Box, Paper } from "@mui/material";

export const PageContainer = (props) => {
  const { children } = props;

  return (
    <Box
      component={Paper}
      sx={{
        marginTop: 2,
        padding: "30px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

PageContainer.defaultProps = {};
