import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {CircularProgress, Grid} from "@mui/material";
import {useDispatch} from "react-redux";
import { resultCRUD} from '../../../http';
import logos, {filialsMapping} from '../PDFGenerator/logos/index.js'
import {parseJsonSafe, toUIJson} from "../../../functions";
import {useNCALayerClient} from "../../../functions/sign/SignHooks";
import useAlert from "../../../hooks/useAlert";
import {formatDate, MOMENT_FORMAT_DATE_DMYHM_DOTS} from "../../../functions/formatters";
import useRoles from "../../../hooks/useRoles";
import { distinct, selectMany } from "../../../functions/linqSimulation";

window.Buffer = window.Buffer || require("buffer").Buffer;

const style = {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
    left: '20%',
    right: '20%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    height: '30%',
    display: 'block',
    p: 4,
};

export default function SignProtocolDetails({
                                            action,
                                            reload,
                                            title,
                                            open,
                                            setOpen,
                                            onSubmit,
                                            query,
                                            protocol,
                                            loading,
                                            ...props
                                        }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [number, setNumber] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [formId, setFormId] = useState('');
    const [referralId, setReferralId] = useState('');
    const [resultId, setResultId] = useState('');
    const [filialId, setFilialId] = useState('');
    const [filialDivisionId, setFilialDivisionId] = useState('');
    const [employee, setEmployee] = useState(null);
    const [filialDivision, setFilialDivision] = useState(null);
    const [availableLogos, setAvailableLogos] = useState(null);
    const [departmentId, setDepartmentId] = useState('');
    const [divisionId, setDivisionId] = useState('');
    const [responsibleId, setResponsibleId] = useState('');
    const [performerId, setPerformerId] = useState('');
    const [data, setData] = useState('');
    const [signed, setSigned] = React.useState(false)
    const [signers, setSigners] = React.useState([])
    const [result, setResult] = React.useState(null);
    const [userCanSignProtocol, setUserCanSignProtocol] = React.useState(false);
    const [signersIds, setSignersIds] = React.useState([]);


    const [client, ready] = useNCALayerClient();
    const [ecpProtocolXml, setEcpProtocolXml] = useState({});
    const [ecpMessage, setEcpMessage] = useState(null);
    const [ecpProtocolSignedXml, setEcpProtocolSigned] = useState({});
    const alert = useAlert(false, 1);
    const { isAdministrator } = useRoles();


    useEffect(() => {
        if (!client)
            return;

        client.onopen = function () {
            setEcpMessage(null);
        }

        client.onclose = function () {
            setEcpMessage("Ошибка соединения с провайдером ЭЦП (NCALayer)");
        }

        client.close = function () {
            setEcpProtocolSigned({})
            setEcpProtocolXml({})
        }

        client.onmessage = function (msg) {
            if (msg.responseObject)
                setEcpProtocolSigned(msg.responseObject || {});

        };

    }, [client])

    const emptyState = () => {
        setId(null);
        setNumber('');
        setCreatedAt('');
        setFormId('');
        setReferralId('');
        setResultId('');
        setFilialId('');
        setFilialDivisionId('');
        setDepartmentId('');
        setDivisionId('');
        setResponsibleId('');
        setPerformerId('');
    };

    const handleForm = async (e) => {
    };

    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(async () => {
        if (!open || !protocol)
            return;

        const {
            id,
            number,
            data,
            createdAt,
            formId,
            referralId,
            resultId,
            filialId,
            filialDivisionId,
            departmentId,
            divisionId,
            responsibleId,
            performerId,
            state,
            signers
        } = protocol || {signers: []};
        setData(data && toUIJson(data))
        setSigned(state?.code === 'Signed')
        setId(id);
        setNumber(number);
        setCreatedAt(createdAt);
        setFormId(formId);
        setReferralId(referralId);
        setResultId(resultId);
        setFilialId(filialId);
        setFilialDivisionId(filialDivisionId);
        setDepartmentId(departmentId);
        setDivisionId(divisionId);
        setResponsibleId(responsibleId);
        setPerformerId(performerId);
        setSigners(signers)

        if (resultId) {
            const result = await resultCRUD.get(resultId);
            await setResult(result);
        }
        const employeeId = window.localStorage.employee ? JSON.parse(window.localStorage.employee).id : null;
        const canSign = employeeId ? (signers || [])
            .map(_ => _?.employeeId)
            .includes(employeeId) : false;
        setUserCanSignProtocol(canSign);

        const signerIds = signers?.map?.(({employeeId}) => employeeId);
        setSignersIds(signerIds);

        setEmployee(parseJsonSafe(window.localStorage.employee));
        setFilialDivision(protocol?.filialDivision?.name);
    }, [open, protocol]);

    useEffect(async () => {
        if (!open || !protocol || !employee?.filialId)
            return;
            const logoMappings = filialsMapping
                .filter(logosGroup => {
                    if (isAdministrator())
                    return true;
                    if (Array.isArray(logosGroup.filialId)) {
                        return logosGroup.filialId.includes(employee.filialId)
                    } else {
                        return logosGroup.filialId === employee.filialId
                    }
                })
                .filter(logosGroup => {
                    if (isAdministrator())
                    return true;
                    if (!logosGroup.filialDivisionId || !employee.filialDivisionId) return true;
                    if (Array.isArray(logosGroup.filialDivisionId)) {
                        return logosGroup.filialDivisionId.includes(employee.filialDivisionId)
                    } else {
                        return logosGroup.filialDivisionId === employee.filialDivisionId
                    }
                });
                let logos = selectMany(logoMappings, _ => _.logo);
                logos = distinct(logos, (a, b) => a == b);
                setAvailableLogos(logos);
    }, [open, protocol, employee?.filialId]);






    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    Номер: {number}
                                </Grid>
                                <Grid item xs={6}>
                                    Создано: {formatDate(createdAt, MOMENT_FORMAT_DATE_DMYHM_DOTS) }
                                </Grid>
                                <Grid item xs={6}>
                                    Форма: {protocol?.form?.number}
                                </Grid>
                                <Grid item xs={6}>
                                    Филиал: {protocol?.filial?.name}
                                </Grid>
                                <Grid item xs={6}>
                                    Районное отделение: {protocol?.filialDivision?.name}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={6} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'column'
                                        }}>
                                            <Typography variant='caption'>Подписали:</Typography>
                                            {
                                                ['performer', 'responsible', 'leader'].map((key, i) => (
                                                    protocol?.[key] && <Typography variant='caption'>
                                                        {['Лаборант', 'Заведующий лабораторией', 'Руководитель организацией'][i]}
                                                        ({protocol[key].fullName}):
                                                        {signers.find(signer => signer.employeeId === protocol[key].id)?.isSigned ? 'Да' : 'Нет'}
                                                    </Typography>
                                                ))
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
            {alert.render()}
        </div>
    )
}
