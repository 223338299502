import React from "react";

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  OutlinedInput,
} from "@mui/material";

export const SelectField = ({
  id,
  label,
  value,
  options,
  placeholder,
  sx,
  disabled,
  required,
  fullWidth,
  onChange,
}) => {
  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel id="select-field-label">{label}</InputLabel>
      <Select
        id="select-field"
        name="select-field"
        labelId="select-field-label"
        value={value}
        input={<OutlinedInput label={label} />}
        sx={sx}
        disabled={disabled}
        required={required}
        onChange={onChange}
      >
        <MenuItem value="">
          <em>Нет выбора</em>
        </MenuItem>
        {options?.length ? options?.map((el, key) => (
          <MenuItem key={key} value={el?.value}>
            {el?.label}
          </MenuItem>
        )) : null}
      </Select>
    </FormControl>
  );
};

SelectField.defaultProps = {
  label: "",
  required: true,
  fullWidth: true,
  disabled: false,
};
