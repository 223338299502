import React from "react";
import DashboardLayout from "../../layouts/Dashboard";
import FilialCRUDTable from "../../components/CRUD/Tables/FilialCRUDTable";
import {DIVISION_DETAIL_ROUTE, FILIAL_DETAIL_ROUTE} from "../../routes";
import {divisionCRUD, filialCRUD} from "../../http";
import {useHistory} from "react-router-dom";
import useReload from "../../hooks/useReload";

const FilialList = () => {
    const history = useHistory()
    const {reload, reloadValue} = useReload();
    const detailAction = (id) => {
        history.push(FILIAL_DETAIL_ROUTE(id), {
            review: true
        })
    }

    const editAction = (id) => {
        history.push(FILIAL_DETAIL_ROUTE(id), {
            review: false
        })
    }

    const deleteAction = async (id) => {
        try {
            await filialCRUD.delete(id)
            reload()
        } catch (err) {

        }
    }
    return (
        <DashboardLayout>
            <FilialCRUDTable 
                withOrganization={true}
                onClickDetailsButton={detailAction}
                             onClickDeleteButton={deleteAction}
                             onClickEditButton={editAction} reloadValue={reloadValue}/>
        </DashboardLayout>
    )
};

export default FilialList;
