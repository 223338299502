import React from "react";
import { Box, Paper, Grid } from "@mui/material";

export const PageFooter = (props, style) => {
  const { children } = props;

  return (
    <Grid
      component={Paper}
      sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxSizing: "border-box",
          padding: "25px 30px",
          mt: 2,
          minHeight: "30px",
          ...style
      }}
      {...props}
    >
      {children}
    </Grid>
  );
};

PageFooter.defaultProps = {};
