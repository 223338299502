export const REFERRAL_GET_REQUEST = "REFERRAL_GET_REQUEST";
export const REFERRAL_GET_SUCCESS = "REFERRAL_GET_SUCCESS";
export const REFERRAL_GET_ERROR = "REFERRAL_GET_ERROR";
export const REFERRAL_GET_BY_KEY_REQUEST = "REFERRAL_GET_BY_KEY_REQUEST";
export const REFERRAL_GET_BY_KEY_SUCCESS = "REFERRAL_GET_BY_KEY_SUCCESS";
export const REFERRAL_GET_BY_KEY_ERROR = "REFERRAL_GET_BY_KEY_ERROR";
export const REFERRAL_ADD_REQUEST = "REFERRAL_ADD_REQUEST";
export const REFERRAL_ADD_SUCCESS = "REFERRAL_ADD_SUCCESS";
export const REFERRAL_ADD_ERROR = "REFERRAL_ADD_ERROR";
export const REFERRAL_EDIT_REQUEST = "REFERRAL_EDIT_REQUEST";
export const REFERRAL_EDIT_SUCCESS = "REFERRAL_EDIT_SUCCESS";
export const REFERRAL_EDIT_ERROR = "REFERRAL_EDIT_ERROR";
export const REFERRAL_DELETE_REQUEST = "REFERRAL_DELETE_REQUEST";
export const REFERRAL_DELETE_SUCCESS = "REFERRAL_DELETE_SUCCESS";
export const REFERRAL_DELETE_ERROR = "REFERRAL_DELETE_ERROR";

export const REFERRAL_WORKFLOW_RUN_REQUEST = "REFERRAL_WORKFLOW_RUN_REQUEST";
export const REFERRAL_WORKFLOW_RUN_SUCCESS = "REFERRAL_WORKFLOW_RUN_SUCCESS";
export const REFERRAL_WORKFLOW_RUN_ERROR = "REFERRAL_WORKFLOW_RUN_ERROR";

export const REFERRAL_WORKFLOW_REQUEST = "REFERRAL_WORKFLOW_REQUEST";
export const REFERRAL_WORKFLOW_SUCCESS = "REFERRAL_WORKFLOW_SUCCESS";
export const REFERRAL_WORKFLOW_ERROR = "REFERRAL_WORKFLOW_ERROR";
