import { KDIRequestCRUD } from "../../http";
import {
  CLINICAL_REQUEST_GET_REQUEST,
  CLINICAL_REQUEST_GET_SUCCESS,
  CLINICAL_REQUEST_GET_ERROR,
  CLINICAL_REQUEST_GET_BY_KEY_REQUEST,
  CLINICAL_REQUEST_GET_BY_KEY_SUCCESS,
  CLINICAL_REQUEST_GET_BY_KEY_ERROR,
  CLINICAL_REQUEST_ADD_REQUEST,
  CLINICAL_REQUEST_ADD_SUCCESS,
  CLINICAL_REQUEST_ADD_ERROR,
  CLINICAL_REQUEST_EDIT_REQUEST,
  CLINICAL_REQUEST_EDIT_SUCCESS,
  CLINICAL_REQUEST_EDIT_ERROR,
  CLINICAL_REQUEST_DELETE_REQUEST,
  CLINICAL_REQUEST_DELETE_SUCCESS,
  CLINICAL_REQUEST_DELETE_ERROR,
  CLINICAL_REQUEST_WORKFLOW_UPDATE_REQUEST,
  CLINICAL_REQUEST_WORKFLOW_UPDATE_SUCCESS,
  CLINICAL_REQUEST_WORKFLOW_UPDATE_ERROR,
} from "../constants/clinicalRequestConstants";

export const getClinicalRequests = (args) => async (dispatch) => {
  try {
    dispatch({
      type: CLINICAL_REQUEST_GET_REQUEST,
    });

    const data = await KDIRequestCRUD.search(args);
    dispatch({
      type: CLINICAL_REQUEST_GET_SUCCESS,
      payload: data.result,
    });
    return data.result;
  } catch (error) {
    dispatch({
      type: CLINICAL_REQUEST_GET_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getClinicalRequestByKey = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CLINICAL_REQUEST_GET_BY_KEY_REQUEST,
    });

    const data = await KDIRequestCRUD.get(id);
    dispatch({
      type: CLINICAL_REQUEST_GET_BY_KEY_SUCCESS,
      payload: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: CLINICAL_REQUEST_GET_BY_KEY_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addClinicalRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CLINICAL_REQUEST_ADD_REQUEST,
    });

    const response = await KDIRequestCRUD.create(data);
    await dispatch(getClinicalRequests(args));

    dispatch({
      type: CLINICAL_REQUEST_ADD_SUCCESS,
    });

    return response.id;
  } catch (error) {
    dispatch({
      type: CLINICAL_REQUEST_ADD_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editClinicalRequest = (data, args) => async (dispatch) => {
  try {
    dispatch({
      type: CLINICAL_REQUEST_EDIT_REQUEST,
    });

    await KDIRequestCRUD.edit(data);
    await dispatch(getClinicalRequests(args));

    dispatch({
      type: CLINICAL_REQUEST_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CLINICAL_REQUEST_EDIT_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteClinicalRequest = (id, args) => async (dispatch) => {
  try {
    dispatch({
      type: CLINICAL_REQUEST_DELETE_REQUEST,
    });

    await KDIRequestCRUD.delete(id);
    await dispatch(getClinicalRequests(args));

    dispatch({
      type: CLINICAL_REQUEST_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CLINICAL_REQUEST_DELETE_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const workflowClinicalRequest =
  (id, prevStateId, nextStateId) => async (dispatch) => {
    try {
      dispatch({ type: CLINICAL_REQUEST_WORKFLOW_UPDATE_REQUEST });

      console.log({ id, prevStateId, nextStateId });

      const response = await KDIRequestCRUD.workflowRun(
        id,
        prevStateId,
        nextStateId
      );
      await dispatch({ type: CLINICAL_REQUEST_WORKFLOW_UPDATE_SUCCESS });

      console.log({ response });

      return response.affected;
    } catch (e) {
      console.log({ e });

      dispatch({
        type: CLINICAL_REQUEST_WORKFLOW_UPDATE_ERROR,
        payload:
          e.response && e.response.data.message
            ? e.response.data.message
            : e.message,
      });
    }
  };
