import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProbeStates } from "../../../redux/actions/probeStateActions";
import { getServices } from "../../../redux/actions/serviceActions";
import { getReferrals } from "../../../redux/actions/referralActions";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditProbe({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [createdAt, setCreatedAt] = useState(new Date());
    const [schema, setSchema] = useState('');
    const [data, setData] = useState('');
    const [stateId, setStateId] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [referralId, setReferralId] = useState(null);
    const { loading, probes, probe } = useSelector((state) => state.probe);
    const { probeStates } = useSelector((state) => state.probeState);
    const { services } = useSelector((state) => state.service);
    const { referrals } = useSelector((state) => state.referral);
    const emptyState = () => {
        setId(null);
        setCreatedAt(new Date());
        setSchema('');
        setData('');
        setStateId(null);
        setServiceId(null);
        setReferralId(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, createdAt, schema, data, stateId, serviceId, referralId });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, createdAt, schema, data, stateId, serviceId, referralId
        } = probe || {};
        setId(id);
        setCreatedAt(createdAt);
        setSchema(schema);
        setData(data);
        setStateId(stateId);
        setServiceId(serviceId);
        setReferralId(referralId);
    }, [probe, probes])
    useEffect(() => {
        let response;
        const getProbeStatesAsync = async () => {
            response = await dispatch(getProbeStates());
        }
        const getServicesAsync = async () => {
            response = await dispatch(getServices());
        }
        const getReferralsAsync = async () => {
            response = await dispatch(getReferrals());
        }
        if (probeStates.length == 0)
        {
            getProbeStatesAsync();
        }
        if (services.length == 0)
        {
            getServicesAsync();
        }
        if (referrals.length == 0)
        {
            getReferralsAsync();
        }
    }, [dispatch, action])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                                        <DatePicker views={['day']} label="Создано" value={createdAt} onChange={(newValue) => {
                                            setCreatedAt(newValue);
                                        }} minDate={new Date('1910-03-01')} renderInput={(params) =>
                                            <TextField margin="normal"  {...params} helperText={null}/>
                                        }/>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={schema?.jsonSchema} onChange={(e) => setSchema(e.target.value)} fullWidth id="schema" name="schema" label="Форма" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={data?.toString()} onChange={(e) => setData(e.target.value)} fullWidth id="data" name="data" label="Данные" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={stateId} onChange={(e) => setStateId(e.target.value)} margin="normal" fullWidth id="stateId" select>
                                        {probeStates && probeStates.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={serviceId} onChange={(e) => setServiceId(e.target.value)} margin="normal" fullWidth id="serviceId" select>
                                        {services && services.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required value={referralId} onChange={(e) => setReferralId(e.target.value)} margin="normal" fullWidth id="referralId" select>
                                        {referrals && referrals.map(_ => (
                                            <MenuItem key={_.id} value={_.id}>
                                                {_.number}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
