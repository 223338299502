import React, {useEffect, useState} from "react";
import { ObjectSectionSelect } from "../../../components/Select/domainSelects";
import DashboardLayout from "../../../layouts/Dashboard";

import DictionaryCRUDTable from "../../../components/CRUD/Tables/DictionaryCRUDTable";
import { getAnObjectSections } from "../../../redux/actions/anObjectSectionActions";

import { setValue } from "../../../functions/entityHelpers";

const filterFields = [
    {
        title: "Раздел",
        key: "sectionId",
        compareType: "equals",
        reducerKey: "anObjectSection",
        reducerArrayKey: "anObjectSections",
        reducerAction: getAnObjectSections,
        operandPosition: 1,
        optionLabelKey: "name",
        type: "normal",
        filterType: "normal",
    }
]

const AnObjectSubSectionList = () => {
    const [entity, setEntity] = useState({});

    return (
        <DashboardLayout>
            <DictionaryCRUDTable
                entityType="anObjectSubSection"
                hasCode={true}
                hasDescription={true}
                filterFields={filterFields}
                entity={entity}
                setEntity={setEntity}
                extraColumns={[
                    {title: "Раздел объекта", dataIndex: 'sectionId', key: 'sectionId', render: (unknown, record) => record.section?.name}
                ]}
                createFields={[
                    <ObjectSectionSelect
                        label={"Раздел объекта"}
                        onChange={val => setValue("sectionId", val, entity, setEntity)}
                        fullWidth
                    />
                ]}
                editFields={[
                    <ObjectSectionSelect
                        label={"Раздел объекта"}
                        value={entity.sectionId}
                        onChange={val => setValue("sectionId", val, entity, setEntity)}
                        fullWidth
                    />
                ]}
                detailsFields={[
                    "Раздел объекта: " + (entity.section?.name || entity.sectionId)
                ]}
                removeFields={[
                    "Раздел объекта: " + (entity.section?.name || entity.sectionId)
                ]}
            />
        </DashboardLayout>
    )
};

export default AnObjectSubSectionList;
