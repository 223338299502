import React, { useCallback, useEffect, useMemo, useState } from "react";
import DashboardLayout from "../../layouts/Dashboard";
import { useHistory, useParams } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SimpleTable from "../../components/SimpleTable";
import TextField from "@mui/material/TextField";
import DiagnosisAutocomplete from "../../components/Autocompletes/DiagnosisAutocomplete";
import ReferalRadio from "./ReferalRadio";
import {
  benefitsToCDI,
  expressTests,
  probCodes,
  probStatus,
  statusPatient,
} from "./stateReferal";
// import ReferalMultipleSelectCheckmarks from "./ReferalMultipleSelectCheckmarks";
import { v4 as uuidv4 } from "uuid";
import CollectionTube from "./CollectionTube";
import { useDispatch, useSelector } from "react-redux";
import useLoader from "../../hooks/useLoader";
import { referalsCRUD } from "../../http/CRUD";
import LoadingButton from "@mui/lab/LoadingButton";
import { REFERRAL_DETAIL_ROUTE, REFERRAL_LIST_ROUTE } from "../../routes/const";
import Toolbar from "@mui/material/Toolbar";
import {
  REQUEST_STATE_ID_COMPLETED,
  REQUEST_STATE_ID_CREATED,
  REQUEST_STATE_ID_DECLINED,
  REQUEST_STATE_ID_PROCESSING,
  REQUEST_STATE_ID_RESEARCH,
} from "../../redux/constants/requestStateConstants";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Collapse } from "antd";
import { formatDate } from "../../functions/formatters";
import PatientField from "../../components/Relations/PatientField";
import { Radio } from "../../components/Radio/Radio";
import Select from "../../components/Select/Select";
import CreateResult from "../Result/Popups/CreateResult";
import { useTranslation } from "react-i18next";
import RemovePopup from "../../components/Popups/RemovePopup";
import {
  DepartmentSelect,
  DivisionSelect,
  FilialDivisionSelect,
  FilialSelect,
} from "../../components/Select/domainSelects";
import { BooleanRadio } from "../../components/Radio/radios";
import EAN from "../../components/EAN";
import CreateProtocol from "../Protocol/Popups/CreateProtocol";
import { addProtocol } from "../../redux/actions/protocolActions";
import Tooltip from "@mui/material/Tooltip";
import { Document, Page } from "react-pdf";
import { fetchPdf } from "../Protocol/Popups/ProtocolDetails";
import Box from "@mui/material/Box";
import EmployeeField from "../../components/Relations/EmployeeField";
import useDialogs from "../../components/Dialog/useDialogs";
import CheckboxesMultiColumnField from "../../components/Fields/CheckboxesMultiColumnField";
import ServiceListField from "../../components/Relations/ServiceListField";
import DictionaryField from "../../components/Relations/DictionaryField";
import { mapReferralForSubmit } from "../../functions/viewDataMap";
import ResearchListField from "../../components/Relations/ResearchListField";
import { useLocation } from "react-router-dom";

import { ReferralPatientCreateModal } from "./_modals";

export const ReferralCopy = () => {
  const location = useLocation();
  const { alert, DIALOG } = useDialogs();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { push, goBack } = useHistory();
  const { diseases } = useSelector((state) => state.disease);

  const { id } = useParams();

  // region Data
  const [data, setData] = useState({
    paymentPassed: false,
  });
  const handleChange = useCallback(
    (key, value) => {
      setData({ ...data, [key]: value });
    },
    [data]
  );
  const handleChangeMulti = useCallback(
    (obj) => {
      setData({ ...data, ...obj });
    },
    [data]
  );

  const [targetPatient, setTargetPatient] = useState(null);
  const handleChangePatient = useCallback(
    (key, value) => setTargetPatient({ ...targetPatient, [key]: value }),
    [targetPatient]
  );

  const [newPatient, setNewPatient] = useState(null);
  const handleChangeNewPatient = useCallback(
    (key, value) => setNewPatient({ ...newPatient, [key]: value }),
    [newPatient]
  );

  const [testTubes, setTestTubes] = useState([]);
  const [specifications, setSpecifications] = useState([]);
  // endregion

  console.log('data.targetPatient', data.targetPatient)

  // region Form edit/view
  const [formType, setFormType] = useState("edit");


  const renderItemGrid = (children, xs = 12) => (
    <Grid item xs={xs}>
      {children}
    </Grid>
  );



  // region Fetch
  const {
    loading: fetchLoading,
    start: fetchStart,
    stop: fetchStop,
  } = useLoader(true);
  const fetch = useCallback(() => {
    fetchStart();
    referalsCRUD
      .get(id)
      .then((data) => {
        setData({...data, id: null, state: null, stateId: null, targetPatient: null, targetPatientId: null, number: null})
        setTargetPatient({});
        setTestTubes([...(data.testTubes || [])]);
        setSpecifications([...(data.specifications || [])]);
        setFormType(
          [REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_DECLINED].includes(
            data.state?.code
          )
            ? "view"
            : "edit"
        );
      })
      .catch(alert)
      .finally(fetchStop);
  }, [id, fetchStart, fetchStop]);
  useEffect(fetch, [fetch]);
  // endregion

  // region Submit
  const {
    start: startSubmit,
    stop: stopSubmit,
    loading: loadingSubmit,
  } = useLoader(false);
  const submitData = useMemo(() => {
    const submitData = mapReferralForSubmit(
      data,
      newPatient,
      targetPatient,
      testTubes
    );
    return submitData;
  }, [data, targetPatient, testTubes, specifications, newPatient]);

  const submit = useCallback(
    () =>
      new Promise((resolve, reject) => {
        startSubmit();
        (referalsCRUD.create(submitData))
          .then((response) => {
            if (response.id) {
              push(REFERRAL_DETAIL_ROUTE(response.id));
            } else {
              fetch();
            }
            resolve();
            // onSubmit();
          })
          .catch((error) => {
            reject();
            alert(error);
          })
          .finally(stopSubmit);
      }),
    [submitData, startSubmit, stopSubmit, fetch, push]
  );
  // endregion

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [protocolAddOpen, setProtocolAddOpen] = useState(false);
  const [protocolViewPdf, setProtocolViewPdf] = useState(false);
  const [protocolViewOpen, setProtocolViewOpen] = useState(false);
  const [createResultOpen, setCreateResultOpen] = useState(false);
  const [deleteReferralOpen, setDeleteReferralOpen] = useState(false);

  console.log('data', data)

  const title = useMemo(() => {
    return "Копирование единоразовой заявки"
  }, [data]);

  const hasResults = useMemo(
    () => data.results && data.results.length > 0,
    [data]
  );
  const hasProtocols = useMemo(
    () => data.protocols && data.protocols.length > 0,
    [data]
  );

  const {
    start: workflowStart,
    stop: workflowStop,
    loading: workflowLoading,
  } = useLoader(false);
  const renderedStatusLine = useMemo(() => {
    const style = { marginLeft: 10 },
      stateCode = data.state?.code;

    const setStatus = (lastStateCode, nextStateCode, thenAction = fetch) => {
      const validates = [];
      const validateNull = (key, label) => {
        if (!data[key]) validates.push(label + " нужно заполнить!");
      };
      if (nextStateCode === REQUEST_STATE_ID_PROCESSING) {
        validateNull("departmentId", "Лаборатория");
        validateNull(
          "responsibleId",
          "Заведующий лаборатории/Начальник отдела"
        );
      } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
        validateNull("performerId", "Лаборант");
      }
      if (validates.length > 0) {
        alert(validates.join("\n"));
        return;
      }

      workflowStart();
      submit()
        .then(() => referalsCRUD.workflowRun(id, lastStateCode, nextStateCode))
        .then(thenAction)
        .catch(alert)
        .finally(workflowStop);
    };
    // REQUEST_STATE_ID_CREATED
    // REQUEST_STATE_ID_PROCESSING
    // REQUEST_STATE_ID_RESEARCH
    // REQUEST_STATE_ID_COMPLETED
    // REQUEST_STATE_ID_DECLINED
    return (
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <Typography style={{ marginRight: "auto" }} variant={"h6"}>
              Статус заявки: {data.state?.name || "создание новой заявки"}
            </Typography>
            {REQUEST_STATE_ID_CREATED === stateCode && (
              <LoadingButton
                style={style}
                variant={"contained"}
                loading={workflowLoading}
                onClick={() =>
                  setStatus(
                    REQUEST_STATE_ID_CREATED,
                    REQUEST_STATE_ID_PROCESSING,
                    () => push(REFERRAL_LIST_ROUTE)
                  )
                }
              >
                Отправить в лабораторию
              </LoadingButton>
            )}
            {REQUEST_STATE_ID_PROCESSING === stateCode && (
              <LoadingButton
                style={style}
                variant={"contained"}
                loading={workflowLoading}
                onClick={() =>
                  setStatus(
                    REQUEST_STATE_ID_PROCESSING,
                    REQUEST_STATE_ID_RESEARCH
                  )
                }
              >
                Назначить исполнителя
              </LoadingButton>
            )}
            {REQUEST_STATE_ID_PROCESSING === stateCode && (
              <LoadingButton
                style={style}
                variant={"contained"}
                color={"warning"}
                loading={workflowLoading}
                onClick={() =>
                  setStatus(
                    REQUEST_STATE_ID_PROCESSING,
                    REQUEST_STATE_ID_DECLINED
                  )
                }
              >
                Отклонить с комментариями
              </LoadingButton>
            )}
            {REQUEST_STATE_ID_RESEARCH === stateCode && (
              <LoadingButton
                style={style}
                variant={"contained"}
                loading={workflowLoading}
                onClick={() =>
                  setStatus(
                    REQUEST_STATE_ID_RESEARCH,
                    REQUEST_STATE_ID_COMPLETED
                  )
                }
              >
                Завершить
              </LoadingButton>
            )}
            {REQUEST_STATE_ID_RESEARCH === stateCode && !hasProtocols && (
              <LoadingButton
                style={style}
                variant={"outlined"}
                loading={workflowLoading}
                onClick={() => setCreateResultOpen(true)}
              >
                Добавить запись в журнал
              </LoadingButton>
            )}
            {REQUEST_STATE_ID_RESEARCH === stateCode && (
              <Tooltip
                title={
                  !hasResults
                    ? "Необходима запись в журнале"
                    : hasProtocols
                    ? "Протокол уже создан"
                    : "Создание протокола"
                }
              >
                <div style={style}>
                  <LoadingButton
                    variant={"outlined"}
                    loading={workflowLoading}
                    onClick={() => setProtocolAddOpen(true)}
                    disabled={!hasResults || hasProtocols}
                  >
                    Создать протокол
                  </LoadingButton>
                </div>
              </Tooltip>
            )}
            {REQUEST_STATE_ID_DECLINED === stateCode && (
              <LoadingButton
                style={style}
                variant={"contained"}
                loading={workflowLoading}
                onClick={() =>
                  setStatus(REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_CREATED)
                }
              >
                Пересоздать
              </LoadingButton>
            )}
            {REQUEST_STATE_ID_DECLINED === stateCode && (
              <LoadingButton
                style={style}
                variant={"contained"}
                loading={workflowLoading}
                onClick={() => setDeleteReferralOpen(true)}
                color={"warning"}
              >
                Удалить
              </LoadingButton>
            )}
          </Toolbar>
        </Paper>
      </Grid>
    );
  }, [
    id,
    data,
    fetch,
    workflowLoading,
    workflowStop,
    workflowStart,
    push,
    submit,
    hasProtocols,
    hasResults,
  ]);

  const renderedToolbarButtons = useMemo(() => {
    const style = { marginLeft: 10 };
    return (
      <>
        <Button variant={"text"} style={style} onClick={() => goBack()}>
          Вернуться
        </Button>

        <div style={{ margin: "auto" }} />

        {(
          <LoadingButton
            variant="contained"
            style={style}
            onClick={submit}
            loading={loadingSubmit}
          >
            {"Создать"}
          </LoadingButton>
        )}
      </>
    );
  }, [loadingSubmit, submit, push]);

  const renderToolbar = useCallback(
    (withTitle = true) => {
      return (
        <Grid item xs={12}>
          <Paper>
            <Toolbar>
              {withTitle && (
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  {title}
                  <br />
                  <Typography style={{ fontSize: 12 }}>
                    от {formatDate(data.createdAt)}
                  </Typography>
                </Typography>
              )}
              {renderedToolbarButtons}
            </Toolbar>
          </Paper>
        </Grid>
      );
    },
    [title, renderedToolbarButtons, data]
  );


  return (
    <DashboardLayout>
      <DIALOG />

      <Grid container spacing={1}>
        {fetchLoading ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {renderToolbar(true)}
            {renderedStatusLine}

            {/* region FormEdit */}

            {(
              <Grid item xs={12} style={{ paddingTop: 16 }}>
                <Paper>
                  <Grid
                    container
                    spacing={1}
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                  >
                    <Grid item xs={12}>
                      <PatientField
                        value={targetPatient?.id}
                        object={targetPatient}
                        onChange={(pat) => setTargetPatient({ ...pat })}
                      />
                    </Grid>

                    {
                      renderItemGrid(
                        <ServiceListField
                          label="Список исследований"
                          value={data.specifications}
                          onChange={(val) =>
                            handleChange("specifications", val)
                          }
                        />
                      )}
                    <Grid item xs={12}>
                      <DictionaryField
                        entityType="disease"
                        label={"Диагноз"}
                        dialogTitle={"диагноза"}
                        value={data.diagnosis}
                        object={data.diagnosis}
                        onChange={(val) => {
                          setData({
                            ...data,
                            diagnosis: val,
                          });
                        }}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <DiagnosisSelect
                        value={data.diagnosis}
                        onChange={(val) => handleChange("diagnosis", val)}
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        value={data.antibioticUse}
                        onChange={(e) =>
                          handleChange("antibioticUse", e.target.value)
                        }
                        fullWidth
                        id="antibioticUse"
                        name="antibioticUse"
                        label="Применение антибиотиков"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReferalRadio
                        label="6. Наименование пробы *п (мазок из зева и носа/трупный материал)"
                        array={probCodes}
                        value={data.probeCode}
                        setValue={(val) => handleChange("probeCode", val)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReferalRadio
                        label="7. Статус отбора пробы"
                        array={probStatus}
                        value={data.probeStatus}
                        setValue={(val) => handleChange("probeStatus", val)}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <CheckboxesMultiColumnField
                        label={"8. Статус пациента"}
                        value={data.patientStatus}
                        onChange={(val) => handleChange("patientStatus", val)}
                        options={statusPatient.map((label) => ({
                          label,
                          value: label,
                        }))}
                      />

                      {/* <ReferalMultipleSelectCheckmarks
                        label={"8. Статус пациента"}
                        value={data.patientStatus}
                        setValue={(val) => handleChange("patientStatus", val)}
                      /> */}
                    </Grid>

                    <Grid item xs={12}>
                      <BooleanRadio
                        label={"Наличие клинических симптомов КВИ"}
                        value={data.clinicalSymptomsCVI}
                        onChange={(val) =>
                          handleChange("clinicalSymptomsCVI", val)
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Radio
                        label="Результаты экспресс теста"
                        required
                        options={expressTests.map((et, i) => ({
                          label: et,
                          value: i + 1,
                        }))}
                        value={data.expressTestResult}
                        onChange={(val) =>
                          handleChange("expressTestResult", val)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        value={data.sampleSentOrganizationName}
                        onChange={(e) =>
                          handleChange(
                            "sampleSentOrganizationName",
                            e.target.value
                          )
                        }
                        fullWidth
                        id="sampleSentOrganizationName"
                        name="sampleSentOrganizationName"
                        label="13. Наименование учреждения направившего образец"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        value={data.referralNumber}
                        onChange={(e) =>
                          handleChange("referralNumber", e.target.value)
                        }
                        fullWidth
                        id="referralNumber"
                        name="referralNumber"
                        label="Номер направления"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        value={data.probeNumber}
                        onChange={(e) =>
                          handleChange("probeNumber", e.target.value)
                        }
                        fullWidth
                        id="probeNumber"
                        name="probeNumber"
                        label="Номер пробы"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant={"h5"} style={{ display: "inline" }}>
                        Пробирки
                      </Typography>
                      <Button
                        onClick={() =>
                          setTestTubes([
                            ...testTubes,
                            { id: uuidv4(), name: "Пусто", quantity: 0 },
                          ])
                        }
                        sx={{ marginLeft: 2 }}
                      >
                        <AddIcon sx={{ marginRight: 2 }} />Добавить блок
                      </Button>
                    </Grid>
                    <CollectionTube
                      setData={(tubes) => {
                        console.log("setDataTubes", tubes);
                        setTestTubes([...tubes]);
                      }}
                      data={testTubes}
                    />

                    {data.stateId &&
                      data.state?.code != REQUEST_STATE_ID_CREATED &&
                      renderItemGrid(
                        <ServiceListField
                          label="Список исследований"
                          value={data.specifications}
                          onChange={(val) =>
                            handleChange("specifications", val)
                          }
                        />
                      )}

                    <Grid container spacing={1} item xs={12}>
                      <Grid item xs={2}>
                        <Typography variant="h6" component="h2">
                          Оплата по ФЛ
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <FormGroup>
                          <FormControlLabel
                            label="Оплата прошла"
                            checked={data.paymentPassed}
                            onChange={(event) =>
                              handleChange(
                                "paymentPassed",
                                event.target.checked
                              )
                            }
                            control={<Checkbox defaultChecked />}
                          />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Select
                          options={benefitsToCDI.map((label, value) => ({
                            label,
                            value,
                          }))}
                          value={data.benefitsToCDI}
                          onChange={(val) => handleChange("benefitsToCDI", val)}
                          fullWidth
                          id="benefitsToCDI"
                          name="benefitsToCDI"
                          label="Льготы на право получения услуг по КДИ на безвозмездной основе предоставляются следующим лицам/договор с поликлиникой"
                          required
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <BooleanRadio
                          label={"Заявка на COVID-19"}
                          value={data.isCovid19}
                          onChange={(val) => handleChange("isCovid19", val)}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <DepartmentSelect
                          label={"Лаборатория"}
                          fullWidth
                          value={data.departmentId}
                          onChange={(val) => handleChange("departmentId", val)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DivisionSelect
                          label={"Отдел"}
                          fullWidth
                          value={data.divisionId}
                          filterOptions={(opt) => opt.id !== 5 && (!data.departmentId || (Number(opt.departmentId) === Number(data.departmentId)))}
                          onChange={(val) => handleChange("divisionId", val)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={"h5"}>
                          Передача проб в лабораторию:
                        </Typography>
                      </Grid>

                      {/*<Grid item xs={12} >Отправить заявку на доработку в ОПП (сотруднику, который создал заявку)</Grid>*/}

                      <Grid item xs={12}>
                        <EmployeeField
                          label={"Ответственный за отбор проб"}
                          value={data.responsibleForProbesId}
                          object={data.responsibleForProbes}
                          onChange={(val) => {
                            setData({
                              ...data,
                              responsibleForProbesId: val?.id,
                              responsibleForProbes: val,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        Подразделение
                      </Grid>
                      <Grid item xs={12}>
                        <FilialSelect
                          label={"Филиал"}
                          fullWidth
                          value={data.filialId}
                          onChange={(val) => {
                            handleChangeMulti({
                              filialId: val,
                              filialDivisionId:
                                Number(val) !== Number(data.filialId)
                                  ? null
                                  : data.filialDivisionId,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FilialDivisionSelect
                          label={"Районное отделение"}
                          fullWidth
                          value={data.filialDivisionId}
                          filialId={data.filialId}
                          onChange={(filialDivisionId, filialDivision) => {
                            handleChangeMulti({
                              filialDivisionId: filialDivisionId,
                              filialId:
                                Number(filialDivision.filialId) !==
                                Number(data.filialId)
                                  ? filialDivision.filialId
                                  : data.filialId,
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        Районное отделение
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant={"h5"}>
                          Передача проб в лабораторию:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <EmployeeField
                          label={"Заведующий лаборатории/Начальник отдела"}
                          fullWidth
                          extraFilter={
                            data.departmentId > 0
                              ? {
                                  departmentId: {
                                    operand1: data.departmentId,
                                    operator: "equals",
                                  },
                                }
                              : null
                          }
                          object={data.responsible}
                          value={data.responsibleId}
                          onChange={(responsible) => {
                            const obj = {
                              responsible,
                              responsibleId: responsible.id,
                              departmentId: responsible.departmentId,
                            };
                            handleChangeMulti(obj);
                          }}
                        />
                        {/* <EmployeesSelect label={"Заведующий лаборатории/Начальник отдела"} fullWidth value={data.responsibleId} onChange={(val) => handleChange('responsibleId', val)}/> */}
                      </Grid>
                      <Grid item xs={12}>
                        <EmployeeField
                          label={"Лаборант"}
                          fullWidth
                          object={data.performer}
                          value={data.performerId}
                          onChange={(performer) => {
                            const obj = {
                              performer,
                              performerId: performer.id,
                            };
                            handleChangeMulti(obj);
                          }}
                        />
                        {/* <EmployeesSelect label={"Лаборант"} fullWidth value={data.performerId} onChange={(val) => handleChange('performerId', val)}/> */}
                      </Grid>
                    </Grid>

                    {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                    {/*  <Grid item xs={6}>*/}
                    {/*    <TextField label="Ссылка на договор ЮЛ или ИП" margin="normal" fullWidth select>*/}
                    {/*      <MenuItem value={0}>*/}
                    {/*        <em>Нет</em>*/}
                    {/*      </MenuItem>*/}
                    {/*    </TextField>*/}
                    {/*  </Grid>}*/}
                    {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                    {/*  <Grid item xs={6}>*/}
                    {/*    <TextField label="Ссылка на договор ФЛ" margin="normal" fullWidth select>*/}
                    {/*      <MenuItem value={0}>*/}
                    {/*        <em>Нет</em>*/}
                    {/*      </MenuItem>*/}
                    {/*    </TextField>*/}
                    {/*  </Grid>}*/}
                    {/*  {payFlId === 'Договор с поликлиникой' &&*/}
                    {/*  <Grid item xs={6}>*/}
                    {/*    <TextField label="Ссылка на сводную заявку" margin="normal" fullWidth select>*/}
                    {/*      <MenuItem value={0}>*/}
                    {/*        <em>Нет</em>*/}
                    {/*      </MenuItem>*/}
                    {/*    </TextField>*/}
                    {/*  </Grid>}*/}
                    {/*  /!* todo Надо будет включить на определённом статусе*/}
                    {/*                  <Grid item xs={12}>*/}
                    {/*                      <Typography variant="h6" component="h2">*/}
                    {/*                      Заказные услуги*/}
                    {/*                      </Typography>*/}
                    {/*                  </Grid>*/}
                    {/*                  <Grid item xs={12}>*/}
                    {/*                      Блок для добавления услуг (Service)*/}
                    {/*                  </Grid>*/}
                    {/*                  <Grid item xs={12}>*/}
                    {/*                      <ReferalTable row={tableRow} setRow={setTableRow}/>*/}
                    {/*                  </Grid>*!/*/}
                    <Grid item xs={12} />
                  </Grid>
                </Paper>
              </Grid>
            )}
            {/* endregion */}

            {renderToolbar(false)}
          </>
        )}
      </Grid>
      {createResultOpen && (
        <CreateResult
          action={"createByReferral"}
          title={t("resultList.createByReferralText")}
          onClose={() => setCreateResultOpen(false)}
          onSubmit={(response) => {
            fetch();
          }}
          referral={data}
        />
      )}
      {deleteReferralOpen && (
        <RemovePopup
          deleteAction={() => referalsCRUD.delete(id)}
          title={`Вы действительно хотите удалить сводную заявку №${data.number}?`}
          onClose={() => setDeleteReferralOpen(false)}
          onSubmit={() => push(REFERRAL_LIST_ROUTE)}
        />
      )}
      {protocolAddOpen && (
        <CreateProtocol
          action={"createByReferral"}
          open={protocolAddOpen}
          initNumber={data.number}
          title={`Создание протокола для сводной заявки №${data.number}?`}
          onClose={() => setDeleteReferralOpen(false)}
          onSubmit={(data) => {
            return dispatch(
              addProtocol(data, { sort: { id: { operator: "asc" } } })
            ).then(fetch);
          }}
          setOpen={setProtocolAddOpen}
          request={data}
          // departmentId={data.departmentId}
          // filialId={data.filialId}
          // performerId={data.performerId}
          // responsibleId={data.responsibleId}
          // filialDivisionId={data.filialDivisionId}
          // divisionId={data.divisionId}
        />
      )}
      <Dialog
        open={protocolViewOpen}
        onClose={() => setProtocolViewOpen(false)}
        fullWidth
      >
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Document
                file={protocolViewPdf}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </DashboardLayout>
  );
};
