import { 
    CONTRACT_REQUEST_EDUCATION_SERVICE_GET_REQUEST, CONTRACT_REQUEST_EDUCATION_SERVICE_GET_SUCCESS, CONTRACT_REQUEST_EDUCATION_SERVICE_GET_ERROR, 
    CONTRACT_REQUEST_EDUCATION_SERVICE_GET_BY_KEY_REQUEST, CONTRACT_REQUEST_EDUCATION_SERVICE_GET_BY_KEY_SUCCESS, CONTRACT_REQUEST_EDUCATION_SERVICE_GET_BY_KEY_ERROR, 
    CONTRACT_REQUEST_EDUCATION_SERVICE_ADD_REQUEST, CONTRACT_REQUEST_EDUCATION_SERVICE_ADD_SUCCESS, CONTRACT_REQUEST_EDUCATION_SERVICE_ADD_ERROR, 
    CONTRACT_REQUEST_EDUCATION_SERVICE_EDIT_REQUEST, CONTRACT_REQUEST_EDUCATION_SERVICE_EDIT_SUCCESS, CONTRACT_REQUEST_EDUCATION_SERVICE_EDIT_ERROR, 
    CONTRACT_REQUEST_EDUCATION_SERVICE_DELETE_REQUEST, CONTRACT_REQUEST_EDUCATION_SERVICE_DELETE_SUCCESS, CONTRACT_REQUEST_EDUCATION_SERVICE_DELETE_ERROR
} from "../constants/contractRequestEducationServiceConstants";

const initialState = {
    contractRequestEducationServices: [],
    contractRequestEducationService: {},
    loading: false,
    success: null,
    error: null,
    contractRequestEducationServiceRequestedCount: 0,
};

export const contractRequestEducationServiceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case CONTRACT_REQUEST_EDUCATION_SERVICE_GET_REQUEST:
            return {
                ...state,
                contractRequestEducationServiceRequestedCount: ++state.contractRequestEducationServiceRequestedCount,
                loading: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                contractRequestEducationServices: action.payload,
                success: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                contractRequestEducationService: action.payload,
                success: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case CONTRACT_REQUEST_EDUCATION_SERVICE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
