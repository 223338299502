/* eslint-disable import/no-anonymous-default-export */
export default {
  '75': {
    version: 2,
    name: 'ПРОТОКОЛ исследования образцов поверхностных водных объектов и сточных вод',
    kazakhName: 'Жер үстi су объектісінің және ағынды су үлгілерін зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгінің атауы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Іріктелген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'nd_on_research_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Іріктеу әдiсiне НҚ'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Көрсеткiштердiң атауы\r\n    Наименование показателей',
            'Анықталған\r\n  концентрация\r\n    Обнаруженная концентрация',
            'Нормативтiк\r\n  көрсеткiштер\r\n    Нормативные показатели',
            'Тексеру\r\n  әдiсiнеқолданылған НҚ \r\n    НД на методы исследования'
          ],
          [
            'Иiсi, Қарқындылығы, баллмен\r\n    Запах, Интенсивность в баллах',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Иiсi, Сипаты (суреттеледі)\r\n    Запах, Характер (описать)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Иiсi, Жоғалу шегi (еселегенде)\r\n    Запах, Порог исчезновения(в разведении)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Түстiлiгi, градуспен\r\n    Цветность в градусах',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Түсi\r\n  (суреттеу)\r\n    Цвет (описать)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Түсiнiң\r\n  жоғалу шегi (еселегенде)\r\n    Порог исчезновения цвета (в разведении)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Лай, тұнба (суреттеу)\r\n    Муть, осадок (описать)',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мөлдiрлiгi\r\n    прозрачность',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жүзiп жүрген қоспалар, қабыршақ\r\n    Плавающие примеси, пленка мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Өлшенген заттар, мг/дм3\r\n    Взвешанные вещества',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'рН',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Ерiтiлген оттегi мгО2/дм3\r\n    Растворенный кислород',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'БПК-5, мг02/ дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'БПК-20,\r\n  мг02/ дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Тотығуы мг02/ дм3\r\n    Окисляемость',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'ХПК,\r\n  мг02/ дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Сiлтiлiгi мг-экв/дм3\r\n    Щелочность',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            ' Қышқылдығы\r\n  мг-экв/дм3\r\n    Кислотность ',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жалпы кермектiгi моль/дм3\r\n    Жесткость общая',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Құрғақ қалдық мг/дм3\r\n    Сухой остаток',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Кальций мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Магний\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Жалпы темiр мг/дм3\r\n    Железо общее',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Хлоридтер мг/дм3\r\n    Хлориды',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Сульфаттар мг/дм3\r\n    Сульфаты',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Азот, Аммиактың мг/дм3\r\n    Аммиака',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Азот, Нитриттердiң мг/дм3\r\n    Нитритов',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Азот, Нитраттардың мг/дм3\r\n    Нитратов',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Фтор\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мұнай өнiмдерi мг/дм3\r\n    Нефтепродукты',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Фенолдар\r\n  мг/дм3 Фенолы',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Цианидтер\r\n  мг/дм3 Цианиды',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мыс\r\n  мг/дм3 Медь ',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Қорғасын\r\n  мг/дм3 Свинец',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Мырыш\r\n  мг/дм3&nbsp;Цинк',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Үш валенттi хром мг/дм3\r\n    Хром трехвалентный',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Алты\r\n  валенттi хром мг/дм3\r\n    Хром шестивалентный',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Сынап,\r\n  мг/дм3 Ртуть',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Кадмий\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Марганец\r\n  мг/дм3',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'СБАЗ\r\n  мг/дм3 СПАВ',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Табиғи уран\r\n    Уран естественный',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Табиғи торий\r\n    Торий естественный',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Радий 226',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Қорғасын\r\n  210\r\n    Свинец',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Стронций\r\n  90',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Цезий\r\n  137',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ],
          [
            'Басқа заттар\r\n    Другие вещества',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=736 style='border-collapse:
        collapse;width:552pt'>
       
        <col width=221 style='mso-width-source:userset;mso-width-alt:7082;width:166pt'>
        <col width=184 style='mso-width-source:userset;mso-width-alt:5888;width:138pt'>
        <col width=113 style='mso-width-source:userset;mso-width-alt:3626;width:85pt'>
        <col width=107 style='mso-width-source:userset;mso-width-alt:3413;width:80pt'>
        <col width=111 style='mso-width-source:userset;mso-width-alt:3541;width:83pt'>
        <tr height=95 style='mso-height-source:userset;height:71.5pt'>
         <td colspan=2 height=95 class=xl66 width=405 style='border-right:1.0pt solid #AEAAAA;
         height:71.5pt;width:304pt'>Көрсеткiштердiң атауы<br>
           Наименование показателей</td>
         <td class=xl63 width=113 style='border-left:none;width:85pt'>Анықталған
         концентрация<br>
           Обнаруженная концентрация</td>
         <td class=xl63 width=107 style='border-left:none;width:80pt'>Нормативтiк
         көрсеткiштер<br>
           Нормативные показатели</td>
         <td class=xl63 width=111 style='border-left:none;width:83pt'>Тексеру
         әдiсiнеқолданылған НҚ <br>
           НД на методы исследования</td>
        </tr>
        <tr height=41 style='mso-height-source:userset;height:31.25pt'>
         <td rowspan=3 height=130 class=xl70 width=221 style='height:99.5pt;
         border-top:none;width:166pt'>Иiсi<br>
           Запах</td>
         <td class=xl63 width=184 style='border-top:none;border-left:none;width:138pt'>Қарқындылығы,
         баллмен<br>
           Интенсивность в баллах</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=36 style='mso-height-source:userset;height:27.5pt'>
         <td height=36 class=xl63 width=184 style='height:27.5pt;border-top:none;
         border-left:none;width:138pt'>Сипаты (суреттеледі)<br>
           Характер (описать)</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=53 style='mso-height-source:userset;height:40.75pt'>
         <td height=53 class=xl63 width=184 style='height:40.75pt;border-top:none;
         border-left:none;width:138pt'>Жоғалу шегi (еселегенде)<br>
           Порог исчезновения(в разведении)</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.25pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.25pt;
         width:304pt'>Түстiлiгi, градуспен<br>
           Цветность в градусах</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=40 style='mso-height-source:userset;height:30.0pt'>
         <td colspan=2 height=40 class=xl63 width=405 style='height:30.0pt;width:304pt'>Түсi
         (суреттеу)<br>
           Цвет (описать)</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=60 style='mso-height-source:userset;height:45.0pt'>
         <td colspan=2 height=60 class=xl63 width=405 style='height:45.0pt;width:304pt'>Түсiнiң
         жоғалу шегi (еселегенде)<br>
           Порог исчезновения цвета (в разведении)</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Лай, тұнба (суреттеу)<br>
           Муть, осадок (описать)</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Мөлдiрлiгi<br>
           прозрачность</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Жүзiп жүрген қоспалар, қабыршақ<br>
           Плавающие примеси, пленка мг/дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Өлшенген заттар, мг/дм3<br>
           Взвешанные вещества</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>рН</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Ерiтiлген оттегi мгО2/дм3<br>
           Растворенный кислород</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl68 style='height:16.0pt'>БПК-5, мг02/ дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>БПК-20,
         мг02/ дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Тотығуы мг02/ дм3<br>
           Окисляемость</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>ХПК,
         мг02/ дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Сiлтiлiгi мг-экв/дм3<br>
           Щелочность</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl69 width=405 style='height:28.75pt;
         width:304pt'><span style='mso-spacerun:yes'> </span>Қышқылдығы
         мг-экв/дм3<br>
           Кислотность<span style='mso-spacerun:yes'> </span></td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Жалпы кермектiгi моль/дм3<br>
           Жесткость общая</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Құрғақ қалдық мг/дм3<br>
           Сухой остаток</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl68 style='height:16.0pt'>Кальций мг/дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Магний
         мг/дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Жалпы темiр мг/дм3<br>
           Железо общее</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Хлоридтер мг/дм3<br>
           Хлориды</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Сульфаттар мг/дм3<br>
           Сульфаты</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=41 style='height:31.0pt'>
         <td rowspan=3 height=123 class=xl70 width=221 style='height:93.0pt;
         border-top:none;width:166pt'>Азот</td>
         <td class=xl63 width=184 style='border-top:none;border-left:none;width:138pt'>Аммиактың
         мг/дм3<br>
           Аммиака</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=41 style='height:31.0pt'>
         <td height=41 class=xl63 width=184 style='height:31.0pt;border-top:none;
         border-left:none;width:138pt'>Нитриттердiң мг/дм3<br>
           Нитритов</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=41 style='height:31.0pt'>
         <td height=41 class=xl63 width=184 style='height:31.0pt;border-top:none;
         border-left:none;width:138pt'>Нитраттардың мг/дм3<br>
           Нитратов</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Фтор
         мг/дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Мұнай өнiмдерi мг/дм3<br>
           Нефтепродукты</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Фенолдар
         мг/дм3 Фенолы</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Цианидтер
         мг/дм3 Цианиды</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Мыс
         мг/дм3 Медь<span style='mso-spacerun:yes'> </span></td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl63 width=405 style='height:16.0pt;width:304pt'>Қорғасын
         мг/дм3 Свинец</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl68 style='height:16.0pt'>Мырыш
         мг/дм3&nbsp;Цинк</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Үш валенттi хром мг/дм3<br>
           Хром трехвалентный</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=39 style='mso-height-source:userset;height:29.5pt'>
         <td colspan=2 height=39 class=xl63 width=405 style='height:29.5pt;width:304pt'>Алты
         валенттi хром мг/дм3<br>
           Хром шестивалентный</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl65 width=405 style='height:16.0pt;width:304pt'>Сынап,
         мг/дм3 Ртуть</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl65 width=405 style='height:16.0pt;width:304pt'>Кадмий
         мг/дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl65 width=405 style='height:16.0pt;width:304pt'>Марганец
         мг/дм3</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl65 width=405 style='height:16.0pt;width:304pt'>СБАЗ
         мг/дм3 СПАВ</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Табиғи уран<br>
           Уран естественный</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Табиғи торий<br>
           Торий естественный</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl68 style='height:16.0pt'>Радий 226</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=39 style='mso-height-source:userset;height:29.5pt'>
         <td colspan=2 height=39 class=xl63 width=405 style='height:29.5pt;width:304pt'>Қорғасын
         210<br>
           Свинец</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl65 width=405 style='height:16.0pt;width:304pt'>Стронций
         90</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td colspan=2 height=21 class=xl65 width=405 style='height:16.0pt;width:304pt'>Цезий
         137</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=37 style='mso-height-source:userset;height:28.75pt'>
         <td colspan=2 height=37 class=xl63 width=405 style='height:28.75pt;
         width:304pt'>Басқа заттар<br>
           Другие вещества</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl64 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="5" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="59"><col width="138"><col width="124"><col width="120"><col width="153"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" width="197" height="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Көрсеткiштердiң атауы<br>Наименование показателейКөрсеткiштердiң атауы<br>Наименование показателейКөрсеткiштердiң атауы<br>Наименование показателей</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="124" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған концентрация<br>Обнаруженная концентрацияАнықталған концентрация<br>Обнаруженная концентрацияАнықталған концентрация<br>Обнаруженная концентрация</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="120" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативтiк көрсеткiштер<br>Нормативные показателиНормативтiк көрсеткiштер<br>Нормативные показателиНормативтiк көрсеткiштер<br>Нормативные показатели</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="153" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тексеру әдiсiнеқолданылған НҚ&nbsp;<br>НД на методы исследованияТексеру әдiсiнеқолданылған НҚ&nbsp;<br>НД на методы исследованияТексеру әдiсiнеқолданылған НҚ&nbsp;<br>НД на методы исследования</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" height="630" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Иiсi<br>ЗапахИiсi<br>ЗапахИiсi<br>Запах</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қарқындылығы, баллмен<br>Интенсивность в баллахҚарқындылығы, баллмен<br>Интенсивность в баллахҚарқындылығы, баллмен<br>Интенсивность в баллах</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сипаты (суреттеледі)<br>Характер (описать)Сипаты (суреттеледі)<br>Характер (описать)Сипаты (суреттеледі)<br>Характер (описать)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жоғалу шегi (еселегенде)<br>Порог исчезновения(в разведении)Жоғалу шегi (еселегенде)<br>Порог исчезновения(в разведении)Жоғалу шегi (еселегенде)<br>Порог исчезновения(в разведении)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="44" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Түстiлiгi, градуспен<br>Цветность в градусахТүстiлiгi, градуспен<br>Цветность в градусахТүстiлiгi, градуспен<br>Цветность в градусах</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="51" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Түсi (суреттеу)<br>Цвет (описать)Түсi (суреттеу)<br>Цвет (описать)Түсi (суреттеу)<br>Цвет (описать)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="87" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Түсiнiң жоғалу шегi (еселегенде)<br>Порог исчезновения цвета (в разведении)Түсiнiң жоғалу шегi (еселегенде)<br>Порог исчезновения цвета (в разведении)Түсiнiң жоғалу шегi (еселегенде)<br>Порог исчезновения цвета (в разведении)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="44" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Лай, тұнба (суреттеу)<br>Муть, осадок (описать)Лай, тұнба (суреттеу)<br>Муть, осадок (описать)Лай, тұнба (суреттеу)<br>Муть, осадок (описать)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Мөлдiрлiгi прозрачность</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="58" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жүзiп жүрген қоспалар, қабыршақ<br>Плавающие примеси, пленка мг/дм3Жүзiп жүрген қоспалар, қабыршақ<br>Плавающие примеси, пленка мг/дм3Жүзiп жүрген қоспалар, қабыршақ<br>Плавающие примеси, пленка мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="42" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Өлшенген заттар, мг/дм3<br>Взвешанные веществаӨлшенген заттар, мг/дм3<br>Взвешанные веществаӨлшенген заттар, мг/дм3<br>Взвешанные вещества</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">рН</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ерiтiлген оттегi мгО2/дм3<br>Растворенный кислородЕрiтiлген оттегi мгО2/дм3<br>Растворенный кислородЕрiтiлген оттегi мгО2/дм3<br>Растворенный кислород</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="44" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">БПК-5, мг02/ дм3<br>БПК-20, мг02/ дм3БПК-5, мг02/ дм3<br>БПК-20, мг02/ дм3БПК-5, мг02/ дм3<br>БПК-20, мг02/ дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="42" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тотығуы мг02/ дм3<br>ОкисляемостьТотығуы мг02/ дм3<br>ОкисляемостьТотығуы мг02/ дм3<br>Окисляемость</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">ХПК, мг02/ дм3<br>Сiлтiлiгi мг-экв/дм3ХПК, мг02/ дм3<br>Сiлтiлiгi мг-экв/дм3ХПК, мг02/ дм3<br>Сiлтiлiгi мг-экв/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="48" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Щелочность<br>Қышқылдығы мг-экв/дм3Щелочность<br>Қышқылдығы мг-экв/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="38" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кислотность<br>Жалпы кермектiгi моль/дм3Кислотность<br>Жалпы кермектiгi моль/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="37" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Жесткость общая<br>Құрғақ&nbsp;қалдық мг/дм3Жесткость общая<br>Құрғақ&nbsp;қалдық мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сухой остаток<br>Кальций мг/дм3Сухой остаток<br>Кальций мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="38" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Магний мг/дм3<br>Железо общееМагний мг/дм3<br>Железо общее</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="42" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Хлоридтер мг/дм3<br>ХлоридыХлоридтер мг/дм3<br>Хлориды</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сульфаттар мг/дм3<br>СульфатыСульфаттар мг/дм3<br>Сульфаты</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" rowspan="3" height="289" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Азот</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Аммиактың мг/дм3<br>АммиакаАммиактың мг/дм3<br>Аммиака</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нитриттердiң мг/дм3<br>НитритовНитриттердiң мг/дм3<br>Нитритов</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нитраттардың мг/дм3<br>НитратовНитраттардың мг/дм3<br>Нитратов</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Фтор мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="38" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Мұнай өнiмдерi мг/дм3<br>НефтепродуктыМұнай өнiмдерi мг/дм3<br>Нефтепродукты</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Фенолдар мг/дм3 Фенолы</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Цианидтер мг/дм3 Цианиды</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"> Мыс мг/дм3 Медь </font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қорғасын мг/дм3 Свинец</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Мырыш мг/дм3&nbsp;Цинк</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="36" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үш валенттi хром мг/дм3<br>Хром трехвалентный</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="37" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Алты валенттi хром мг/дм3<br>Хром шестивалентный</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Сынап, мг/дм3 Ртуть</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Кадмий мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Марганец мг/дм3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">СБАЗ мг/дм3 СПАВ</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="41" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Табиғи уран<br>Уран естественный</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="40" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Табиғи торий<br>Торий естественный</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Радий 226</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="39" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Қорғасын 210<br>Свинец</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Стронций 90</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="21" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Цезий 137</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" colspan="2" height="45" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Басқа заттар<br>Другие вещества</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="5" height="68" align="CENTER" valign="BOTTOM" sdnum="1033;1033;General"><font face="Times New Roman" size="4" color="#000000">Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді /<br>( Исследование проб проводились на соответствие НД)__________</font></td>
            </tr>
        </tbody>
      </table>`,
      label: 'Результаты измерений',
       kazakhLabel: 'Олшеу нәтижелері'
      },   
    ]
  },
  '76': {
    version:2,
    name: 'ПРОТОКОЛ исследования образцов пищевых продуктов (остаточное количество антибиотиков, гормоны, b- адреностимуляторы, микробная трансглутаминаза, массовая концентрация сухого молока)',
    kazakhName: 'Тағам өнімдері үлгілерін зерттеу (антибиотиктердің қалдық мөлшері, гормондар, b- адреностимуляторлар, микробты трансглутаминаза, құрғақ сүттің массалық үлесі) ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'sample_name',
        type: 'text',
        label: 'Наименование образца',
        kazakhLabel: 'Үлгі атауы'
      },
      {
        id: 'number',
        type: 'number',
        label: 'Количество',
        kazakhLabel: 'Саны'
      },
      {
        id: 'research_goals',
        type: 'text',
        label: 'Цель исследования',
        kazakhLabel: 'Зерттеу алу мақсаты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама саны'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'nd_on_research_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Үлгі алу әдiсiне НҚ'
      },
      {
        id: 'transport_conditions',
        type: 'text',
        label: 'Условия транспортировки',
        kazakhLabel: 'Тасымалдау жағдайы'
      },
      {
        id: 'storage_conditions',
        type: 'text',
        label: 'Условия хранения',
        kazakhLabel: 'Сақтау жағдайы'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Тағам өнiмдерiндегi анықталынатын көрсеткіш атауы\r\n  (антибиотиктердің, гормондардың және т.б. атауы бойынша тізбесі)\r\n    Наименование определяемого показателя в продуктах питания (перечень\r\n  антибиотиков, гормонов и т.д. по названию)',
            '&nbsp;'
          ],
          [
            'Группа показателей (антибиотики, гормоны, b- адреностимуляторы и\r\n  т.д)',
            '&nbsp;'
          ],
          [
            'Анықтауға қолданылған әдіс\r\n    Метод использованный для определения',
            '&nbsp;'
          ],
          [
            'Анықталған шоғырлану\r\n    Обнаруженная концентрация',
            '&nbsp;'
          ],
          [
            'Нормативтік көрсеткіштер\r\n    Нормативные показатели',
            '&nbsp;'
          ],
          [
            'Зерттеу әдістеріне қолданылған НҚ\r\n    НД на методы исследования',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=674 style='border-collapse:
        collapse;width:506pt'>
       
        <col width=71 style='width:53pt'>
        <col width=137 style='mso-width-source:userset;mso-width-alt:4394;width:103pt'>
        <col width=116 style='mso-width-source:userset;mso-width-alt:3712;width:87pt'>
        <col width=89 style='mso-width-source:userset;mso-width-alt:2858;width:67pt'>
        <col width=65 style='mso-width-source:userset;mso-width-alt:2090;width:49pt'>
        <col width=71 style='width:53pt'>
        <col width=125 style='mso-width-source:userset;mso-width-alt:4010;width:94pt'>
        <tr height=208 style='mso-height-source:userset;height:156.0pt'>
         <td colspan=2 height=208 class=xl63 width=208 style='height:156.0pt;
         width:156pt'>Тағам өнiмдерiндегi анықталынатын көрсеткіш атауы
         (антибиотиктердің, гормондардың және т.б. атауы бойынша тізбесі)<br>
           Наименование определяемого показателя в продуктах питания (перечень
         антибиотиков, гормонов и т.д. по названию)</td>
         <td class=xl63 width=116 style='border-left:none;width:87pt'>Группа
         показателей (антибиотики, гормоны, b- адреностимуляторы и т.д)</td>
         <td class=xl63 width=89 style='border-left:none;width:67pt'>Анықтауға
         қолданылған әдіс<br>
           Метод использованный для определения</td>
         <td class=xl63 width=65 style='border-left:none;width:49pt'>Анықталған
         шоғырлану<br>
           Обнаруженная концентрация</td>
         <td class=xl63 width=71 style='border-left:none;width:53pt'>Нормативтік
         көрсеткіштер<br>
           Нормативные показатели</td>
         <td class=xl63 width=125 style='border-left:none;width:94pt'>Зерттеу
         әдістеріне қолданылған НҚ<br>
           НД на методы исследования</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td colspan=2 height=20 class=xl64 style='height:15.0pt'>1</td>
         <td class=xl64 style='border-top:none;border-left:none'>2</td>
         <td class=xl64 style='border-top:none;border-left:none'>3</td>
         <td class=xl64 style='border-top:none;border-left:none'>4</td>
         <td class=xl64 style='border-top:none;border-left:none'>5</td>
         <td class=xl64 style='border-top:none;border-left:none'>6</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="197"><col width="107"><col width="264"><col width="115"><col width="129"><col width="111"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="197" height="210" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тағам өнiмдерiндегi анықталынатын көрсеткіш атауы (антибиотиктердің, гормондардың және т.б. атауы бойынша тізбесі)<br>Наименование определяемого показателя в продуктах питания (перечень антибиотиков, гормонов и т.д. по названию)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000"> Группа показателей (антибиотики, гормоны, b- адреностимуляторы и т.д)</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="264" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықтауға қолданылған әдіс<br>Метод использованный для определения</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Анықталған шоғырлану<br>Обнаруженная концентрация</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="129" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нормативтік көрсеткіштер<br>Нормативные показатели</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="111" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеріне қолданылған НҚ<br>НД на методы исследования</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" height="19" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" color="#000000">6</font></td>
          </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '77': {
    name: 'ПРОТОКОЛ энтомологического исследования членистоногих',
    kazakhName: 'Бунақаяқтыларды энтомологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество обратившегося лица',
        kazakhLabel: 'Өтініш білдірген адамның тегі, аты, әкесінің аты'
      },
      {
        id: 'adress_age',
        type: 'number',
        label: 'Возраст, Адрес',
        kazakhLabel: 'Жасы, Мекен-жайы'
      },
      {
        id: 'place_of_work',
        type: 'text',
        label: 'Место работы, учебы, наименование детской организации',
        kazakhLabel: 'Жұмыс, оқу орны, балалар ұйымының атауы'
      },
      {
        id: 'foundation_place_prediction',
        type: 'text',
        label: 'Предполагаемое место обнаружения',
        kazakhLabel: 'Болжанған орынды айқындау'
      },
      {
        id: 'type_of_dick',
        type: 'text',
        label: 'Видовая принадлежность членистоного, пол',
        kazakhLabel: 'Бунақ аяқты түрі, жынысы'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгі (нің) НҚ-ға сәйкестігіне зерттеулер жүргізілді'
      }
    ]
  },
  '78': {
    version:2,
    name: 'ПРОТОКОЛ паразитологического исследования смывов',
    kazakhName: 'Шайындыларды паразитологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгілер алу мақсаты'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'research_date',
        type: 'datetime',
        label: 'Дата и время исследования',
        kazakhLabel: 'Зерттеу күні мен уақыты'
      },
      {
        id: 'nd_on_research_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Іріктеу әдiсiне НҚ'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        rows: [
          [
            'Үлгілер\r\n  нөмірi\r\n    Номер проб',
            '&nbsp;'
          ],
          [
            'Үлгі алынған орын және нүктелер\r\n    Место и точки отбора',
            '&nbsp;'
          ],
          [
            'Нәтиже\r\n    Результат',
            '&nbsp;'
          ],
          [
            'Зерттеу әдістеменің НҚ-ры\r\n    НД на метод испытаний',
            '&nbsp;'
          ],
          [
            'Ескерту\r\n    Примечание',
            '&nbsp;'
          ]
        ],
        htmlTable: `     <table border=0 cellpadding=0 cellspacing=0 width=671 style='border-collapse:
        collapse;width:504pt'>
       
        <col width=119 style='mso-width-source:userset;mso-width-alt:3797;width:89pt'>
        <col width=165 style='mso-width-source:userset;mso-width-alt:5290;width:124pt'>
        <col width=101 style='mso-width-source:userset;mso-width-alt:3242;width:76pt'>
        <col width=181 style='mso-width-source:userset;mso-width-alt:5802;width:136pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3370;width:79pt'>
        <tr height=71 style='mso-height-source:userset;height:53.5pt'>
         <td height=71 class=xl63 width=119 style='height:53.5pt;width:89pt'>Үлгілер
         нөмірi<br>
           Номер проб</td>
         <td class=xl64 width=165 style='border-left:none;width:124pt'>Үлгі алынған
         орын және нүктелер<br>
           Место и точки отбора</td>
         <td class=xl64 width=101 style='border-left:none;width:76pt'>Нәтиже<br>
           Результат</td>
         <td class=xl64 width=181 style='border-left:none;width:136pt'>Зерттеу
         әдістеменің НҚ-ры<br>
           НД на метод испытаний</td>
         <td class=xl64 width=105 style='border-left:none;width:79pt'>Ескерту<br>
           Примечание</td>
        </tr>
        <tr height=21 style='height:16.0pt'>
         <td height=21 class=xl65 style='height:16.0pt;border-top:none'>1</td>
         <td class=xl66 style='border-top:none;border-left:none'>2</td>
         <td class=xl66 style='border-top:none;border-left:none'>3</td>
         <td class=xl66 style='border-top:none;border-left:none'>4</td>
         <td class=xl66 style='border-top:none;border-left:none'>5</td>
        </tr>
       
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table cellspacing="0" cols="5" rules="NONE" border="1" style="border-collapse: collapse" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="98"><col width="121"><col width="81"><col width="148"><col width="105"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="98" height="96" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгілер нөмірi<br>Номер проб</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="121" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгі алынған орын және нүктелер<br>Место и точки отбора</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="81" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Нәтиже<br>Результат</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="148" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="105" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескерту<br>Примечание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid; border-bottom: 1px solid; border-left: 1px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid; border-bottom: 1px solid; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid; border-bottom: 1px solid; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid; border-bottom: 1px solid; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid; border-bottom: 1px solid; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000" colspan="5" height="64" align="LEFT" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді /<br>( Исследование проб проводились на соответствие НД)________________</font></td>
            </tr>
        </tbody>
      </table>`,
        label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      }
    ]
  },
  '79': {
    version: 2,
    name: 'ПРОТОКОЛ исследования соскоба с перианальных складок',
    kazakhName: 'Анус айналасының қатпарынан қырындыны зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'adress',
        type: 'text',
        label: 'Наименование объекта, адрес',
        kazakhLabel: 'Объектінің атауы, мекенжайы'
      },
      {
        id: 'place_of_selection',
        type: 'text',
        label: 'Место отбора образца',
        kazakhLabel: 'Үлгі алынған орын'
      },
      {
        id: 'selection_date',
        type: 'datetime',
        label: 'Дата и время отбора',
        kazakhLabel: 'Алынған күні мен уақыты'
      },      
      {
        id: 'delivery_date',
        type: 'datetime',
        label: 'Дата и время доставки',
        kazakhLabel: 'Жеткізілген күні мен уақыты'
      },
      {
        id: 'research_goal',
        type: 'text',
        label: 'Цель исследования образца',
        kazakhLabel: 'Үлгілер алу мақсаты'
      },
      {
        id: 'nd_on_research_method',
        type: 'text',
        label: 'НД на метод отбора',
        kazakhLabel: 'Іріктеу әдiсiне НҚ'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі'
      },
      {
        id: 'additional_data',
        type: 'text',
        label: 'Дополнительные сведения',
        kazakhLabel: 'Қосымша мәліметтер'
      },
      {
        id: 'form_table',
        type: 'table',
        htmlTable: `<table border=0 cellpadding=0 cellspacing=0 width=989 style='border-collapse:
        collapse;table-layout:fixed;width:742pt'>
        <col width=64 style='width:48pt'>
        <col width=347 style='mso-width-source:userset;mso-width-alt:12344;width:260pt'>
        <col width=105 style='mso-width-source:userset;mso-width-alt:3726;width:79pt'>
        <col width=281 style='mso-width-source:userset;mso-width-alt:9984;width:211pt'>
        <col width=64 span=3 style='width:48pt'>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1522990 width=64 style='height:52.8pt;width:48pt'></td>
         <td class=xl6822990 width=347 style='width:260pt'>Тiркеу н&#1257;мiрi</td>
         <td class=xl7022990 width=105 style='border-left:none;width:79pt'>Т.А.&#1240;
         (бол&#1171;ан жа&#1171;дайда)</td>
         <td class=xl7022990 width=281 style='border-left:none;width:211pt'>Ту&#1171;ан
         жылы</td>
         <td class=xl7022990 width=64 style='border-left:none;width:48pt'>Зерттеу
         н&#1241;тижесі</td>
         <td class=xl7022990 width=64 style='border-left:none;width:48pt'>Зерттеу
         &#1241;дістемені&#1187; Н&#1178;-ры</td>
         <td class=xl6522990 width=64 style='border-left:none;width:48pt'>Ескертуге</td>
        </tr>
        <tr height=70 style='height:52.8pt'>
         <td height=70 class=xl1522990 style='height:52.8pt'></td>
         <td class=xl6922990 width=347 style='border-top:none;width:260pt'>Регистрационный
         номер</td>
         <td class=xl6722990 width=105 style='border-top:none;border-left:none;
         width:79pt'>Ф.И.О (при наличии)</td>
         <td class=xl6722990 width=281 style='border-top:none;border-left:none;
         width:211pt'>Год рождения</td>
         <td class=xl6722990 width=64 style='border-top:none;border-left:none;
         width:48pt'>Результат исследования</td>
         <td class=xl6722990 width=64 style='border-top:none;border-left:none;
         width:48pt'>НД на метод испытаний</td>
         <td class=xl6622990 width=64 style='border-top:none;border-left:none;
         width:48pt'>Примечание</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1522990 style='height:14.4pt'></td>
         <td class=xl6922990 width=347 style='border-top:none;width:260pt'>1</td>
         <td class=xl6722990 width=105 style='border-top:none;border-left:none;
         width:79pt'>2</td>
         <td class=xl6722990 width=281 style='border-top:none;border-left:none;
         width:211pt'>3</td>
         <td class=xl6722990 width=64 style='border-top:none;border-left:none;
         width:48pt'>4</td>
         <td class=xl6722990 width=64 style='border-top:none;border-left:none;
         width:48pt'>5</td>
         <td class=xl6622990 width=64 style='border-top:none;border-left:none;
         width:48pt'>6</td>
        </tr>
        <tr height=19 style='height:14.4pt'>
         <td height=19 class=xl1522990 style='height:14.4pt'></td>
         <td class=xl7222990 style='border-top:none'>&nbsp;</td>
         <td class=xl7122990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7122990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7122990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7122990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7322990 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
        <tr height=20 style='height:15.0pt'>
         <td height=20 class=xl1522990 style='height:15.0pt'></td>
         <td class=xl7422990 style='border-top:none'>&nbsp;</td>
         <td class=xl7522990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7522990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7522990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7522990 style='border-top:none;border-left:none'>&nbsp;</td>
         <td class=xl7622990 style='border-top:none;border-left:none'>&nbsp;</td>
        </tr>
       </table>`
      },
      {
        id: 'form_table_v2',
        type: 'table',
        version: 2,
        htmlTable: `<table frame="VOID" cellspacing="0" cols="6" rules="NONE" border="1" class="table table-bordered" style="border-collapse: collapse">
        <colgroup><col width="143"><col width="126"><col width="107"><col width="115"><col width="135"><col width="99"></colgroup>
        <tbody>
          <tr>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" width="143" height="96" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Тiркеу нөмiрi<br>Регистрационный номер</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="126" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Т.А.Ә (болған жағдайда)<br>Ф.И.О (при наличии)</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="107" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Туған жылы<br>Год рождения</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="115" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу нәтижесі<br>Результат исследования</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" width="135" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Зерттеу әдістеменің НҚ-ры<br>НД на метод испытаний</font></td>
            <td style="border-top: 3px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" width="99" align="CENTER" valign="MIDDLE" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">Ескертуге<br>Примечание</font></td>
          </tr>
          <tr>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="21" align="CENTER" valign="MIDDLE" sdval="1" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">1</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="2" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">2</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="3" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">3</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="4" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">4</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="CENTER" valign="MIDDLE" sdval="5" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">5</font></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="CENTER" valign="MIDDLE" sdval="6" sdnum="1033;1033;General"><font face="Times New Roman" size="3" color="#000000">6</font></td>
          </tr>
          <tr>
            <td height="19" align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td height="19" align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td height="19" align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
          </tr>
          <tr>
            <td height="19" align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000">220 журнал бак лаб</font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
            <td align="LEFT" valign="BOTTOM" sdnum="1033;1033;General"><font face="Calibri" color="#000000"><br></font></td>
          </tr>
        </tbody>
      </table>`,
      label: 'Результаты исследования',
       kazakhLabel: 'Зерттеу нәтижелері'
      },
    ]
  },
  '80': {
    name: 'ПРОТОКОЛ паразитологического исследования фекалий, соскобов с перианальных складок',
    kazakhName: 'Нәжісті, анус айналасының қатпарынан қырындыларды паразитологиялық зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Фамилия, имя, отчество (при наличии) обследуемого',
        kazakhLabel: 'Тексерілушінің тегі, аты, әкесінің аты (болған жағдайда),',
        number: '1'
      },
      {
        id: 'age_adress',
        type: 'text',
        label: 'Возраст, Адрес',
        kazakhLabel: 'Жасы, Мекен-жайы',
        number: '2'
      },
      {
        id: 'place_of_work_adress',
        type: 'text',
        label: 'Место работы, учебы, наименование детской организации',
        kazakhLabel: 'Жұмыс, оқу орны, балалар ұйымының атауы',
        number: '3'
      },
      {
        id: 'type_of_research',
        type: 'switch',
        label: 'Исследование первичное, повторное, контрольное',
        kazakhLabel: 'Зерттеу алғашқы, кайталап, қорытынды',
        options: [
          'первичное',
          'повторное',
          'контрольное'
        ],
        number: '4'
      },
      {
        id: 'found_while_research',
        type: 'text',
        label: 'при исследовании обнаружены',
        kazakhLabel: 'Зерттеу кезінде анықталды',
        number: '5'
      },
      {
        id: 'helmint_eggs',
        type: 'text',
        label: 'яйца гельминтов',
        kazakhLabel: 'гельминт жұмыртқалары',
        number: ''
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Көлемін',
        number: '6'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтамалар нөмірі',
        number: '7'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі',
        number: '8'
      },
      {
        id: 'cists',
        type: 'text',
        label: 'Простейшие, вегетативные цисты',
        kazakhLabel: 'Қарапайымдар, вегетативті цисталар',
        number: '9'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ры',
        number: '10'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгі (нің) НҚ-ға сәйкестігіне зерттеулер жүргізілді',
        number: '11'
      }
    ]
  },
  '81': {
    name: 'ПРОТОКОЛ исследования крови на малярию',
    kazakhName: 'Қанды безгекке зерттеу ХАТТАМАСЫ',
    fields: [
      {
        id: 'blood_research',
        type: 'text',
        label: 'паразитологическое исследование препаратов крови',
        kazakhLabel: 'Қан препараттарын паразитологиялық зерттеу',
        number: '1'
      },
      {
        id: 'including',
        type: 'text',
        label: 'в том числе',
        kazakhLabel: 'оның ішінде',
        number: ''
      },
      {
        id: 'tonkih_mazkov',
        type: 'text',
        label: 'тонких мазков',
        kazakhLabel: 'жұқа жағындылар',
        number: ''
      },
      {
        id: 'thicc_drops',
        type: 'text',
        label: 'толстых капель',
        kazakhLabel: 'қалың тамшылар',
        number: ''
      },
      {
        id: 'applicant_full_name',
        type: 'text',
        label: 'Ф.И.О. (при наличии) обследуемого',
        kazakhLabel: 'Тексерілушінің Т.А.Ә. (болған жағдайда)',
        number: '2'
      },
      {
        id: 'age',
        type: 'number',
        label: 'Возраст',
        kazakhLabel: 'Жасы',
        number: '3'
      },
      {
        id: 'home_adress',
        type: 'text',
        label: 'Адрес места жительства',
        kazakhLabel: 'Тұрғылықты мекен-жайы',
        number: ''
      },
      {
        id: 'malyariaya',
        type: 'text',
        label: 'Обнаружены возбудители малярии (указать сокращенное родовое и полное видовое название паразита (н-р: Р.vivax))',
        kazakhLabel: 'Безгек ауруының қоздырғыштары анықталды (паразиттің қысқартылған туыстастық және толық түр атауын көрсетіңіз (мысалы: Р.vivax))',
        number: '4'
      },
      {
        id: 'parasitemy_intensity',
        type: 'text',
        label: 'Интенсивность паразитемии',
        kazakhLabel: 'Паразитемияның қарқындылығы',
        number: '5'
      },
      {
        id: 'volume',
        type: 'text',
        label: 'Объем',
        kazakhLabel: 'Мөлшері',
        number: '6'
      },
      {
        id: 'partions_number',
        type: 'text',
        label: 'Номер партий',
        kazakhLabel: 'Топтама сана',
        number: '7'
      },
      {
        id: 'production_date',
        type: 'date',
        label: 'Дата выработки',
        kazakhLabel: 'Өндірілген мерзімі',
        number: '8'
      },
      {
        id: 'nd_selection_method',
        type: 'text',
        label: 'НД на метод испытаний',
        kazakhLabel: 'Зерттеу әдістеменің НҚ-ы',
        number: '9'
      },
      {
        id: 'research_on_nd',
        type: 'text',
        label: 'Исследование проб проводились на соответствие НД',
        kazakhLabel: 'Үлгінің НҚ-ға сәйкестігіне зерттеулер жүргізілді',
        number: '10'
      }
    ]
  }
}
