import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import React, { useCallback, useMemo } from 'react';
import { generateUniqueID } from "web-vitals/dist/modules/lib/generateUniqueID";

const SelectField = ({options, margin, fullWidth, label, onChange, value,
                  optionValueKey, optionLabelKey, getOptionLabel,
                  required, filter, ...props}) => {
  const uniqueId = useMemo(() => generateUniqueID(), []);
  const _options = useMemo(() => {
    if(filter) {
      return (options || []).filter(filter);
    }
    return options || [];
  }, [options, filter]);

  const optionsMap = useMemo(() => {
    return _options.reduce((acc, opt, i) => {
      acc[opt[optionValueKey]] = i;
      return acc;
    }, {});
  }, [_options, optionValueKey]);

  const _getOptionLabel = useCallback((item) => {
    if(getOptionLabel) return getOptionLabel(item);
    return item[optionLabelKey];
  }, [optionLabelKey, getOptionLabel])

  return <FormControl fullWidth={fullWidth}>
    {label && <InputLabel id={`${uniqueId}-select`} >{label}</InputLabel>}
    <MuiSelect
      labelId={`${uniqueId}-select`}
      margin={margin || "normal"}
      label={label}
      value={(value != 0 && value != "0") ? value || "" : 0}
      onChange={(e) => {
        const item = _options[optionsMap[e.target.value]];
        onChange(e.target.value, item)
      }}
      {...props}
    >
      {_options.map(_ => (
        <MenuItem key={_[optionValueKey]} value={_[optionValueKey] || ""}>
          {_getOptionLabel(_)}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
};

SelectField.defaultProps = {
  optionValueKey: 'value',
  optionLabelKey: 'key',
};

export default SelectField;
