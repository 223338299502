import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import { Button } from "@mui/material";
import CRUDTable from "../CRUDTable";
import { serviceMapCRUD } from "../../../http";
import useAlert from "../../../hooks/useAlert";
import useReload from "../../../hooks/useReload";
import { confirmDialog } from "../../Dialog/DialogDelete";
import { getServiceGroups } from "../../../redux/actions/serviceGroupActions";
import { getServiceSubGroups } from "../../../redux/actions/serviceSubGroupActions";
import { getServiceKinds } from "../../../redux/actions/serviceKindActions";
import { getDepartments } from "../../../redux/actions";

export default function ServiceMapCRUDTable({ ...props }) {
    const { t } = useTranslation();
    const { push } = useHistory();

    const columns = [
        {
            title: t("serviceMapList.tableItem.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("serviceMapList.tableItem.object"),
            dataIndex: "object",
            key: "object",
            render: (value, row) => value?.name,
        },
        {
            title: t("serviceMapList.tableItem.group"),
            dataIndex: "group",
            key: "group",
            render: (value, row) => value?.name,
        },
        {
            title: t("serviceMapList.tableItem.subGroup"),
            dataIndex: "subGroup",
            key: "subGroup",
            render: (value, row) => value?.name,
        },
        {
            title: t("serviceMapList.tableItem.kind"),
            dataIndex: "kind",
            key: "kind",
            render: (value, row) => value?.name,
        },
        {
            title: t("serviceMapList.tableItem.researchStandard"),
            dataIndex: "researchStandard",
            key: "researchStandard",
            render: (value, row) => value?.name,
        },
        {
            title: t("serviceMapList.tableItem.governmentStandard"),
            dataIndex: "governmentStandard",
            key: "governmentStandard",
            render: (value, row) => value?.name,
        },
        {
            title: t("serviceMapList.tableItem.regulationOfCU"),
            dataIndex: "regulationOfCU",
            key: "regulationOfCU",
            render: (value, row) => value?.name,
        },
    ];

    const searchFields = [
        {
            filterType: "normal",
            title: t("serviceMapList.filter.IdFilter"),
            key: "id",
            compareType: "equals",
            operandPosition: 1,
        },
    ];

    const filterFields = [        
        {
            title: "Группа",
            key: "groupId",
            parentKey: 'departmentId',
            compareType: "equals",
            reducerKey: "serviceGroup",
            reducerArrayKey: "serviceGroups",
            reducerAction: getServiceGroups,
            filterQuery: true,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Подгруппа",
            key: "subGroupId",
            parentKey: 'groupId',
            parentKey2: 'departmentId',
            compareType: "equals",
            reducerKey: "serviceSubGroup",
            reducerArrayKey: "serviceSubGroups",
            reducerAction: getServiceSubGroups,
            filterQuery: true,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Метод",
            key: "methodId",
            parentKey: 'subGroupId',
            parentKey2: 'groupId',
            parentKey3: 'departmentId',
            compareType: "equals",
            reducerKey: "serviceKind",
            reducerArrayKey: "serviceKinds",
            reducerAction: getServiceKinds,
            filterQuery: true,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
        {
            title: "Лаборатория",
            key: "departmentId",
            compareType: "equals",
            reducerKey: "department",
            reducerArrayKey: "departments",
            reducerAction: getDepartments,
            operandPosition: 1,
            optionLabelKey: "name",
            type: "normal",
            filterType: "normal",
        },
    ];

    return (
        <CRUDTable
            search={serviceMapCRUD.search}
            columns={columns}
            filterFields={filterFields}
            searchFields={searchFields}
            {...props}
        />
    )
}

