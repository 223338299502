import { LoadingButton } from "@mui/lab";
import { CircularProgress, Paper, Toolbar, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import useDialogs from "../../components/Dialog/useDialogs";
import ResearchListField from "../../components/Relations/ResearchListField";
import { DepartmentSelect, DivisionSelect, FilialDivisionSelect, FilialSelect, GeoObjectSelect, GovernmentStandardsSelect } from "../../components/Select/domainSelects";
import { checkRoles } from "../../functions/checkRoles";
import useLoader from "../../hooks/useLoader";
import { brigadeCRUD } from "../../http";
import DashboardLayout from "../../layouts/Dashboard";
import { getBrigadeTypes } from "../../redux/actions/brigadeTypeActions";
import { REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH } from "../../redux/constants/requestStateConstants";
import CreateDirection from "../Directions/Popups/CreateDirection";
import DddPDF from "./DddPDF";
import DddResult from "./DddResult";

const withDddDetail = (DddForm, DddPDFGenerator, dddFormKazakhName, dddFormName, dddName) => {
  return () => {
    const { alert, DIALOG } = useDialogs();
    const { t } = useTranslation();
    const brigadeType = useSelector(state => state.brigadeType)
    const { id } = useParams();
    const isNew = useMemo(() => !(id > 0), [id]);
    const [data, setData] = useState({});
    const { start: startSubmit, stop: stopSubmit, loading: loadingSubmit } = useLoader(false);
    const { start: workflowStart, stop: workflowStop, loading: workflowLoading } = useLoader(false);
    const stateCode = data.state?.code;
    const style = { marginLeft: 10 }
    const [createDirectionOpen, setCreateDirectionOpen] = useState(false)

    const enhancedSamples = [
      {object: {key: 'object', value: 'Тұрғын үй-жайлар Жилых помещений'}, unit: {key: 'unit', value: 'ml'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
      {object: {key: 'object', value: 'Жалпы пайдалану орны Место общего пользования'}, unit: {key: 'unit', value:'ml'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
      {object: {key: 'object', value: 'Ауладағы дәретханалар Уборных дворовых'}, unit: {key: 'unit', value: 'шт.'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
      {object: {key: 'object', value: 'Қоқыс салатын жәшіктер Мусорных ящиков'}, unit: {key: 'unit', value: 'шт.'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
      {object: {key: 'object', value: 'Ыдыс-аяқжиынтығы Комплект посуды'}, unit: {key: 'unit', value: 'шт.'}, done: {key: 'done', value: null}, names: {key: 'names', value: null}, quantity: {key: 'quantity', value: null}},
    ]

    const handleChange = useCallback((key, value) => {
      setData({ ...data, [key]: value })
    }, [data]);
    const handleChangeMulti = useCallback((obj) => {
      setData({ ...data, ...obj })
    }, [data]);

    const handleChangeData = useCallback((key, value, key2) => {
      setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
    }, [data]);

    const handleChangeSample = useCallback((key, value, i, key2) => {
      const array = data.data?.samples?.value.slice(0)
      array[i][key] = { 'key': key2, value }
      setData({
        ...data, data: {
          ...data.data,
          samples: { key: 'samples', value: array }
        }
      })
    }, [data]);

    const addSample = useCallback(() => {
      data.data?.samples?.value
        ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, {}] } } })
        : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [{}] } } })
    }, [data]);

    const addSampleEnhanced = useCallback(() => {
      data.data?.samples?.value
        ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, ...enhancedSamples] } } })
        : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...enhancedSamples] } } })
    }, [data]);

    const deleteSample = useCallback((index) => {
      setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i) } } })
    }, [data]);

    const dispatch = useDispatch();
    const { push } = useHistory();

    const schema = {}

    const { loading: fetchLoading, start: fetchStart, stop: fetchStop } = useLoader(true);
    const fetch = useCallback(() => {
      if (isNew) {
        fetchStop();
        return;
      }
      fetchStart();
      brigadeCRUD.get(id)
        .then((data) => {
          let actData = typeof data.data === 'string' ? JSON.parse(data.data) : {}
          setData({ ...data, data: { ...actData } });
        })
        .catch(alert)
        .finally(fetchStop)
    }, [id, fetchStart, fetchStop, isNew]);
    useEffect(fetch, [fetch]);

    const submit = useCallback((actualyData) => new Promise((resolve, reject) => {
      if (!data.filialId) {
        alert('Филиал нужно заполнить!');
        return
      }
      const brigadeObj = brigadeType.brigadeTypes.find(a => {
        return a.name === dddName
      })
      const newData = actualyData ? actualyData : { ...data }
      startSubmit();
      (isNew
        ? brigadeCRUD.create({
          createdAt: new Date(),
          number: dddName,
          schema,
          type: brigadeObj,
          typeId: brigadeObj.id,
          ...newData
        })
        : brigadeCRUD.edit({
          id: id,
          number: dddName,
          schema,
          type: brigadeObj,
          typeId: brigadeObj.id,
          ...newData,
        })
      ).then((response) => {
        if (isNew && response.id) {
          push(`/${dddName}Detail/${response.id}`)
        } else {
          fetch();
        }
        resolve();
        // onSubmit();
      })
        .catch((error) => {
          reject();
          alert(error);
        })
        .finally(stopSubmit)
    }), [data, startSubmit, stopSubmit, isNew, fetch, push, brigadeType]);

    const setStatus = (lastStateCode, nextStateCode, thenAction = fetch) => {
      const validates = [];
      const validateNull = (key, label) => {
        if (!data[key]) validates.push(label + " нужно заполнить!");
      };
      // if (nextStateCode === REQUEST_STATE_ID_PROBESELECTION) {
      //   validateNull("responsibleForProbesId", "Ответственный за отбор проб");
      // } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
      //   validateNull("performerId", "Лаборант");
      // } else if (nextStateCode === REQUEST_STATE_ID_PROCESSING) {
      //   validateNull("departmentId", "Лаборатория");
      //   validateNull("responsibleId", "Заведующий лаборатории/Начальник отдела");
      // }
      if (validates.length > 0) {
        alert(validates.join("\n"));
        return;
      }
      workflowStart();
      submit()
        .then(() => brigadeCRUD.workflowRun(id, lastStateCode, nextStateCode))
        .then(thenAction)
        .catch(alert)
        .finally(workflowStop);
    };

    const hasResult = useMemo(() => data.resultId, [data]);
    const hasDirection = useMemo(() => data.actId, [data]);
    const hasProtocols = useMemo(() => data.protocols && data.protocols.length > 0, [data]);

    const [createResultOpen, setCreateResultOpen] = useState(false);
    const [deleteActOpen, setDeleteActOpen] = useState(false);

    useEffect(() => {
      if (brigadeType.brigadeTypes.length === 0) {
        dispatch(getBrigadeTypes())
      }
    }, [brigadeType.brigadeTypes.length])

    const [isDisabledData, setIsDisabledData] = useState(false)
    const [isDisabledPerformer, setIsDisabledPerformer] = useState(false)

    return (
      <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
        <Button
          style={{
            marginBottom: '10px'
          }}
          variant="contained"
          type="button"
          onClick={() => { push(`/${dddName}`) }}
        >
          Вернуться
        </Button>
        <DIALOG />
        {fetchLoading
          ? <CircularProgress />
          : stateCode === REQUEST_STATE_ID_COMPLETED
            ? <DddPDF data={data} name={dddFormName} kazakhName={dddFormKazakhName}>
              <DddPDFGenerator data={data} name={dddFormName} kazakhName={dddFormKazakhName} />
            </DddPDF>

            : <Box component="form">
              <Paper style={{ marginBottom: '15px' }}>
                <Toolbar>
                  <Typography style={{ marginRight: "auto" }} variant={"h6"}>Статус наряда: {data.state?.name || "создаётся"}</Typography>
                  {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROCESSING)}>В процессе</LoadingButton>}
                  {REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_COMPLETED)}>Завершить</LoadingButton>}
                  {REQUEST_STATE_ID_PROCESSING === stateCode && <Tooltip title={hasDirection ? "Направление создано" : "Направление необходимо создать"}>
                    <div style={style}> <LoadingButton
                      style={style} variant={"outlined"} disabled={hasDirection} loading={workflowLoading} onClick={() => setCreateDirectionOpen(true)}>Создать направление</LoadingButton>
                    </div>
                  </Tooltip>}
                  {REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && <Tooltip title={hasResult ? "Журнал создан" : "Журнал необходимо создать"}>
                    <div style={style}>
                      <LoadingButton variant={"outlined"} loading={workflowLoading} onClick={() => setCreateResultOpen(true)} disabled={hasResult}>Создать журнал</LoadingButton>
                    </div>
                  </Tooltip>}
                </Toolbar>
              </Paper>
              <h4 style={{ textAlign: "center" }}>
                {dddFormKazakhName}
              </h4>
              <h4 style={{ textAlign: "center" }}>
                {dddFormName}
              </h4>
              {data.number && <h5 style={{ marginBottom: "30px" }} align="center">
                №{data.number}
              </h5>}
              <DddForm addSample={dddName === 'DddDisinsection' ? addSample : addSampleEnhanced}
                handleChangeSample={handleChangeSample}
                isDisabledData={isDisabledData} data={data}
                handleChangeData={handleChangeData}
                handleChangeMulti={handleChangeMulti}
                deleteSample={deleteSample} />
                {REQUEST_STATE_ID_RESEARCH === stateCode && <ResearchListField
                label='Список Исследований'
                value={data.specifications}
                onChange={(val) => handleChange("specifications", val)}
              />}
              <FilialSelect
                style={{ marginBottom: "5px" }}
                label={"Филиал"}
                fullWidth
                filialId={checkRoles('AdministratorReadonly') ? null : JSON.parse(localStorage.employee)?.filialId}
                disabled={isDisabledData}
                value={data.filialId}
                onChange={(val) => {
                  handleChangeMulti({
                    filialId: val,
                    filialDivisionId: Number(val) !== Number(data.filialId) ? null : data.filialDivisionId
                  })
                }}
              />
              <FilialDivisionSelect
                style={{ marginBottom: "5px" }}
                label={"Районное отделение"}
                fullWidth
                disabled={isDisabledData}
                value={data.filialDivisionId}
                filialId={data.filialId === 1 ? null : data.filialId}
                onChange={(filialDivisionId, filialDivision) => {
                  handleChangeMulti({
                    filialDivisionId: filialDivisionId,
                    filialId: Number(filialDivision.filialId) !== Number(data.filialId) ? filialDivision.filialId : data.filialId
                  })
                }}
              />
              <Typography gutterBottom variant={'h5'}>Передача проб в лабораторию:</Typography>
              <DepartmentSelect
                style={{ marginBottom: "5px" }}
                label={"Лаборатория"}
                fullWidth
                value={data.departmentId}
                onChange={(val) => handleChange('departmentId', val)}
              />
              <DivisionSelect
                style={{ marginBottom: "5px" }}
                label={"Отдел"}
                fullWidth
                value={data.divisionId}
                onChange={(val) => handleChange('divisionId', val)}
                filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
              />
              <LoadingButton style={{
                border: "1px solid black",
                padding: "5px 10px",
                borderRadius: "6px",
                marginRight: '15px'
              }}
                variant="contained"
                disabled={isDisabledData}
                onClick={() => submit()}
                loading={loadingSubmit}>
                {isNew ? "Создать" : "Сохранить"}
              </LoadingButton>
            </Box>}
        {
          createDirectionOpen && <CreateDirection
            title={'Создание направления для наряда'}
            onClose={() => setCreateDirectionOpen(false)}
            onSubmit={(response) => {
              handleChange("actId", response)
              submit({ ...data, actId: response })
            }}
            open={createDirectionOpen}
            ddd={data}
          />
        }
        {
          createResultOpen && <DddResult
            title={'Создание журнала наряда'}
            onClose={() => setCreateResultOpen(false)}
            onSubmit={(response) => {
              handleChange("resultId", response)
              submit({ ...data, resultId: response })
            }}
            ddd={data}
          />
        }
      </DashboardLayout>
    );
  };
}

export default withDddDetail;
