export const REQUEST_LOCK_GET_REQUEST = 'REQUEST_LOCK_GET_REQUEST';
export const REQUEST_LOCK_GET_SUCCESS = 'REQUEST_LOCK_GET_SUCCESS';
export const REQUEST_LOCK_GET_ERROR = 'REQUEST_LOCK_GET_ERROR';
export const REQUEST_LOCK_GET_BY_KEY_REQUEST = 'REQUEST_LOCK_GET_BY_KEY_REQUEST';
export const REQUEST_LOCK_GET_BY_KEY_SUCCESS = 'REQUEST_LOCK_GET_BY_KEY_SUCCESS';
export const REQUEST_LOCK_GET_BY_KEY_ERROR = 'REQUEST_LOCK_GET_BY_KEY_ERROR';
export const REQUEST_LOCK_ADD_REQUEST = 'REQUEST_LOCK_ADD_REQUEST';
export const REQUEST_LOCK_ADD_SUCCESS = 'REQUEST_LOCK_ADD_SUCCESS';
export const REQUEST_LOCK_ADD_ERROR = 'REQUEST_LOCK_ADD_ERROR';
export const REQUEST_LOCK_EDIT_REQUEST = 'REQUEST_LOCK_EDIT_REQUEST';
export const REQUEST_LOCK_EDIT_SUCCESS = 'REQUEST_LOCK_EDIT_SUCCESS';
export const REQUEST_LOCK_EDIT_ERROR = 'REQUEST_LOCK_EDIT_ERROR';
export const REQUEST_LOCK_DELETE_REQUEST = 'REQUEST_LOCK_DELETE_REQUEST';
export const REQUEST_LOCK_DELETE_SUCCESS = 'REQUEST_LOCK_DELETE_SUCCESS';
export const REQUEST_LOCK_DELETE_ERROR = 'REQUEST_LOCK_DELETE_ERROR';
