import { 
    DESINSECTION_REQUEST_GET_REQUEST, DESINSECTION_REQUEST_GET_SUCCESS, DESINSECTION_REQUEST_GET_ERROR, 
    DESINSECTION_REQUEST_GET_BY_KEY_REQUEST, DESINSECTION_REQUEST_GET_BY_KEY_SUCCESS, DESINSECTION_REQUEST_GET_BY_KEY_ERROR, 
    DESINSECTION_REQUEST_ADD_REQUEST, DESINSECTION_REQUEST_ADD_SUCCESS, DESINSECTION_REQUEST_ADD_ERROR, 
    DESINSECTION_REQUEST_EDIT_REQUEST, DESINSECTION_REQUEST_EDIT_SUCCESS, DESINSECTION_REQUEST_EDIT_ERROR, 
    DESINSECTION_REQUEST_DELETE_REQUEST, DESINSECTION_REQUEST_DELETE_SUCCESS, DESINSECTION_REQUEST_DELETE_ERROR
} from "../constants/desinsectionRequestConstants";

const initialState = {
    desinsectionRequests: [],
    desinsectionRequest: {},
    loading: false,
    success: null,
    error: null,
    desinsectionRequestRequestedCount: 0,
};

export const desinsectionRequestReducer = (state = initialState, action) => {
    switch (action.type)     {
        case DESINSECTION_REQUEST_GET_REQUEST:
            return {
                ...state,
                desinsectionRequestRequestedCount: ++state.desinsectionRequestRequestedCount,
                loading: true,
            };
        case DESINSECTION_REQUEST_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                desinsectionRequests: action.payload,
                success: true,
            };
        case DESINSECTION_REQUEST_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINSECTION_REQUEST_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINSECTION_REQUEST_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                desinsectionRequest: action.payload,
                success: true,
            };
        case DESINSECTION_REQUEST_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINSECTION_REQUEST_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINSECTION_REQUEST_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DESINSECTION_REQUEST_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINSECTION_REQUEST_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINSECTION_REQUEST_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DESINSECTION_REQUEST_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case DESINSECTION_REQUEST_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DESINSECTION_REQUEST_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case DESINSECTION_REQUEST_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
