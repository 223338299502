import moment from "moment";

const convertDate = (date) => {
    return moment(date).format('YYYY-MM-DD')
}
const convertDateString = (date) => {
    return moment(date).format('DD.MM.YYYY')
}

const convertDateTime = (date) => {
    return moment(date).format('DD.MM.YYYY HH:mm')
}

const convertDateToTime = (date) => {
    return moment(date).format('HH:mm')
}

const referralParsingObject = (data, formData, el) => (
    {
        325: () => {
            if (el === 'sampleSelectionTime' && data[el]) {
                formData.dateMonthYear.sampling = convertDateTime(data[el]);
                return
            }
            if (el === 'samplingLocation' && data[el]) {
                formData.placeOfSampling = data[el];
            }
        }
    }
)

export const resultsIdObjectReferralHasSamples = [325, 223, 227, 228, 210, 207, 243, 212, 333, 335, 332, 220, 250]


export const resultsIdObjectReferralHasSamplesNoAct = [325, 239, 223, 242, 243];

export const mappingObjectReferralToResultData = (data, resultForm, chooseSampleObjectWithoutAct) => {
    const {probes: probesReferral, probeType, ...referral} = data;
    let formData = {
        date: {},
        dateTime: {},
        dateMonthYear: {},
        dateAndTime: {}
    };
    Object.keys(referral).map(el => {
        switch (resultForm) {
            case 325: {
                console.log(el)
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateMonthYear.sampling = convertDateTime(data[el]);
                    return
                }
                if (el === 'samplingLocation' && data[el]) {
                    formData.placeOfSampling = data[el];
                }
                if(el === 'targetObject' && data[el])
                    formData.modelName = data[el].name;
                break
            }
            case 239: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.alreadyTaken = convertDate(data[el]);
                    break;
                }
                if (el === 'targetObject' && data[el]) {
                    formData.sampleLocationObject = `${data[el].name}, ${data.samplingLocation}`;
                    break;
                }
                break
            }
            case 223: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.samplingDate = convertDate(data[el]);
                    break;
                }
                break
            }
            case 242: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTimeOfSampling = convertDate(data[el]);
                    break;
                }
                if (el === 'samplingLocation' && data[el]) {
                    formData.organizationPlaceOfSampling = data[el];
                    break;
                }
                break
            }
            case 243: {
                if (el === 'targetObject' && data[el]) {
                    formData.sampleOrganizationPlace = `${data[el].name}`;
                    formData.sampleOrganizationPlace = `${data[el].name}`;
                    break;
                }
                break
            }
            case 313: {
                if (el === 'targetObject' && data[el]) {
                    formData.sampleSelectedLocationObject = `${data[el].name}`;
                    break;
                }
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.alreadyTaken = convertDate(data[el]);
                    break;
                }
                break
            }
            case 314: {
                if (el === 'targetObject' && data[el]) {
                    formData.sampleSelectedLocationObject = `${data[el].name}`;
                    break;
                }
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.selected = convertDate(data[el]);
                    break;
                }
                break
            }
            case 312: {
                if (el === 'targetObject' && data[el]) {
                    formData.sampleSelectedLocationObject = `${data[el].name}`;
                    break;
                }
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.alreadyTaken = convertDate(data[el]);
                    break;
                }
                break
            }
            case 311: {
                if (el === 'targetObject' && data[el]) {
                    formData.sampleSelectedLocationObject = `${data[el].name}`;
                    break;
                }
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.theTeam = convertDate(data[el]);
                    break;
                }
                break
            }
            case 207: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.samplingDate = convertDate(data[el]);
                    break;
                }
                break
            }
            case 210: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.samplingDate = convertDate(data[el]);
                    break;
                }
                if (el === 'targetObject' && data[el]) {
                    formData.sampleName = `${data[el].name}`;
                    break;
                }
                break
            }
            case 227: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.placeAndTimeOfSelection = `${data?.samplingLocation}, ${convertDateToTime(data[el])}`;
                    break;
                }
                break
            }
            case 228: {
                if (el === 'targetObject' && data[el]) {
                    formData.sampleName = `${data[el].name}`;
                    break;
                }
                break
            }
            case 217: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.date.sampling = convertDate(data[el]);
                    break;
                }
                break
            }
            case 212: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.date.theObtainedSample = convertDate(data[el]);
                    break;
                }
                break
            }
            case 238: {
                if (el === 'samplingLocation' && data[el]) {
                    formData.nameOfTheSamplePlaceOfReceiptPositionOfTheRecipient = `${data[el]}`;
                    break;
                }
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.alreadyTaken = convertDate(data[el]);
                    break;
                }
                break
            }
            case 327: {
                if (el === 'sampleDeliveryTime' && data[el]) {
                    formData.timeOfDelivery = convertDateToTime(data[el]);
                    formData.deliveryDate = convertDateString(data[el]);
                    break;
                }
                break
            }
            case 213: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.samplingDate = convertDate(data[el]);
                    break;
                }
                if (el === 'samplingLocation' && data[el]) {
                    formData.placeOfSelectionAddressAndNameOfTheOrganization = `${data?.samplingLocation},  ${data[el]?.name ? data[el]?.name : '-'}, ${data[el]?.addressLine ? data[el]?.addressLine : '-'}`;
                    break;
                }
                break
            }
            case 208: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.theTeam = convertDate(data[el]);
                    break;
                }
                break
            }
            case 283: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateOfSampling = convertDate(data[el]);
                    break;
                }
                break
            }
            case 289: {
                if (el === 'targetObject' && data[el]) {
                    formData.objectName = `${data[el].name}`;
                    break;
                }
                break
            }
            case 229: {
                if (el === 'targetObject' && data[el]) {
                    formData.objectNameAddress = `${data[el].name}, ${data[el]?.addressLine ? data[el]?.addressLine : '-'}`;
                    break;
                }
                if (el === 'samplingLocation' && data[el]) {
                    formData.measurementLocation = `${data[el]}`;
                    break;
                }
                break
            }
            case 290: {
                if (el === 'targetObject' && data[el]) {
                    formData.objectName = `${data[el].name}`;
                    break;
                }
                if (el === 'samplingLocation' && data[el]) {
                    formData.measurementLocation = `${data[el]}`;
                    break;
                }
                break
            }
            case 220: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateAndTime.sampling = convertDate(data[el]);
                    break;
                }
                break
            }
            case 250: {
                if (el === 'sampleSelectionTime' && data[el]) {
                    formData.dateTime.sampling = convertDate(data[el]);
                    break;
                }
                break
            }
        }
    })

    if (Object.keys(chooseSampleObjectWithoutAct).length) {
        formData = {...formData, ...chooseSampleObjectWithoutAct}
    }

    console.log(formData)

    return formData
}


// export const mappingObjectReferralToResultData = (data, resultForm, chooseSampleObjectWithoutAct) => {
//     let probes = []
//     const {probes: probesReferral, probeType, ...referral} = data;

//     if (probesReferral?.length)
//     probesReferral.map(el => {
//         if (el.data) {
//             let localData = typeof el.data === 'string' ? JSON.parse(el.data) : el.data
//             probes.push({...localData})
//         }
//     })

//     let formData = {
//         date: {},
//         dateTime: {},
//         dateMonthYear: {},
//         dateAndTime: {}
//     };
//     Object.keys(referral).map(el => {
//         const object = referralParsingObject(data, formData, el)
//         switch (probeType) {
//             case 2: {
//                 switch (resultForm) {
//                     case 325: {
//                         object[325]();
//                         break
//                     }
//                     case 220: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateAndTime.sampling = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 250: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.sampling = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                 }
//                 break;
//             }
//             case 3: {
//                 switch (resultForm) {
//                     case 239: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.alreadyTaken = convertDate(data[el]);
//                             break;
//                         }
//                         if (el === 'targetObject' && data[el]) {
//                             formData.sampleLocationObject = `${data[el].name}, ${data.samplingLocation}`;
//                             break;
//                         }
//                         break
//                     }
//                     case 290:
//                     case 289: {
//                         if (el === 'targetObject' && data[el]) {
//                             formData.objectName = `${data[el].name}`;
//                             break;
//                         }
//                         break
//                     }
//                     case 325: {
//                         object[325]();
//                         break
//                     }
//                     case 223: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.samplingDate = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 217: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.date.sampling = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                 }
//                 break;
//             }
//             case 1: {
//                 switch (resultForm) {
//                     case 242: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTimeOfSampling = convertDate(data[el]);
//                             break;
//                         }
//                         if (el === 'samplingLocation' && data[el]) {
//                             formData.organizationPlaceOfSampling = data[el];
//                             break;
//                         }
//                         break
//                     }
//                     case 243: {
//                         if (el === 'targetObject' && data[el]) {
//                             formData.sampleOrganizationPlace = `${data[el].name}`;
//                             break;
//                         }
//                         break
//                     }
//                     case 313: {
//                         if (el === 'targetObject' && data[el]) {
//                             formData.sampleSelectedLocationObject = `${data[el].name}`;
//                             break;
//                         }
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.alreadyTaken = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 314: {
//                         if (el === 'targetObject' && data[el]) {
//                             formData.sampleSelectedLocationObject = `${data[el].name}`;
//                             break;
//                         }
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.selected = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 312: {
//                         if (el === 'targetObject' && data[el]) {
//                             formData.sampleSelectedLocationObject = `${data[el].name}`;
//                             break;
//                         }
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.alreadyTaken = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 311: {
//                         if (el === 'targetObject' && data[el]) {
//                             formData.sampleSelectedLocationObject = `${data[el].name}`;
//                             break;
//                         }
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.theTeam = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 223: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.samplingDate = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 207: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.samplingDate = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 210: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.samplingDate = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 227: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.placeAndTimeOfSelection = `${data?.samplingLocation}, ${convertDateToTime(data[el])}`;
//                             break;
//                         }
//                         break
//                     }
//                     case 212: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.date.theObtainedSample = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 238: {
//                         if (el === 'samplingLocation' && data[el]) {
//                             formData.nameOfTheSamplePlaceOfReceiptPositionOfTheRecipient = `${data[el]}`;
//                             break;
//                         }
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.alreadyTaken = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 208: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateTime.theTeam = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 283: {
//                         if (el === 'sampleSelectionTime' && data[el]) {
//                             formData.dateOfSampling = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                     case 327:
//                     case 326: {
//                         if (el === 'sampleDeliveryTime' && data[el]) {
//                             formData.deliveryDate = convertDate(data[el]);
//                             break;
//                         }
//                         break
//                     }
//                 }
//                 break;
//             }
//         }
//     })

//     if (Object.keys(chooseSampleObjectWithoutAct).length) {
//         formData = {...formData, ...chooseSampleObjectWithoutAct}
//     }

//     console.log(formData)

//     return formData
// }
