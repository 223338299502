import { 
    SAMPLING_PURPOSE_GET_REQUEST, SAMPLING_PURPOSE_GET_SUCCESS, SAMPLING_PURPOSE_GET_ERROR, 
    SAMPLING_PURPOSE_GET_BY_KEY_REQUEST, SAMPLING_PURPOSE_GET_BY_KEY_SUCCESS, SAMPLING_PURPOSE_GET_BY_KEY_ERROR, 
    SAMPLING_PURPOSE_ADD_REQUEST, SAMPLING_PURPOSE_ADD_SUCCESS, SAMPLING_PURPOSE_ADD_ERROR, 
    SAMPLING_PURPOSE_EDIT_REQUEST, SAMPLING_PURPOSE_EDIT_SUCCESS, SAMPLING_PURPOSE_EDIT_ERROR, 
    SAMPLING_PURPOSE_DELETE_REQUEST, SAMPLING_PURPOSE_DELETE_SUCCESS, SAMPLING_PURPOSE_DELETE_ERROR
} from "../constants/samplingPurposeConstants";

const initialState = {
    samplingPurposes: [],
    samplingPurpose: {},
    loading: false,
    success: null,
    error: null,
    samplingPurposeRequestedCount: 0,
};

export const samplingPurposeReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SAMPLING_PURPOSE_GET_REQUEST:
            return {
                ...state,
                samplingPurposeRequestedCount: ++state.samplingPurposeRequestedCount,
                loading: true,
            };
        case SAMPLING_PURPOSE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                samplingPurposes: action.payload,
                success: true,
            };
        case SAMPLING_PURPOSE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLING_PURPOSE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLING_PURPOSE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                samplingPurpose: action.payload,
                success: true,
            };
        case SAMPLING_PURPOSE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLING_PURPOSE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLING_PURPOSE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SAMPLING_PURPOSE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLING_PURPOSE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLING_PURPOSE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SAMPLING_PURPOSE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SAMPLING_PURPOSE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAMPLING_PURPOSE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SAMPLING_PURPOSE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
