import {Document, Font, Page, PDFDownloadLink, PDFViewer, StyleSheet, Text, View} from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import React from 'react'

import TimesNewRomanBoldItalic from '../../../components/PDFGenerator/fonts/Times New Roman Bold Italic.ttf'
import TimesNewRomanBold from '../../../components/PDFGenerator/fonts/Times New Roman Bold.ttf'
import TimesNewRomanItalic from '../../../components/PDFGenerator/fonts/Times New Roman Italic.ttf'
import TimesNewRoman from '../../../components/PDFGenerator/fonts/Times New Roman.ttf'
import {Cell, Row, Table} from '../../../components/PDFGenerator/Table'

const formatTime = Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
}).format

const formatDate = Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}).format

const styles = StyleSheet.create({
    page: {
        padding: '20 50 10 50'
    },
    titleWorks: {
        fontSize: 12,
        fontWidth: 600,
        fontFamily: 'Times New Roman Bold',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginVertical: 10
    },
    string: {
        fontSize: 10,
        marginVertical: 5
    },
    text: {
        fontSize: 10,
        textAlign: 'center',
        marginVertical: 5,
    },
    simpleString: {
        display: 'inline',
        fontSize: '10px'
    },
    table: {
        fontSize: 10,
        fontFamily: 'Times New Roman',
        marginVertical: 5
    },
    sampleBold: {
        fontFamily: 'Times New Roman Bold',
        fontSize: 8
    },
    sampleRegular: {
        fontFamily: 'Times New Roman',
        fontSize: 8
    }
})

Font.register({
    family: 'Times New Roman',
    src: TimesNewRoman,
})
Font.register({
    family: 'Times New Roman Italic',
    src: TimesNewRomanItalic,
})
Font.register({
    family: 'Times New Roman Bold',
    src: TimesNewRomanBold,
})

ActWaterPDFGenerator.propTypes = PDFDocument.propTypes = {
    data: PropTypes.object,
    name: PropTypes.string,
    kazakhName: PropTypes.string,
}

function PDFDocument({data, name, kazakhName}) {
    const actData = data.data
    const schema = [
        {
            key: '1. Объектінің атауы (Наименование объекта)',
            value: actData?.object?.value?.name
        },
        {
            key: '2. Тексеру белгіленгені туралы актінің нөмірі (Номер акта о назначении проверки)',
            value: actData?.number?.value
        },
        {
            key: '3. Тағайындау актісі күні (Дата акта о назначении проверки)',
            value: actData?.date?.value ? formatDate(new Date(actData?.date.value)) : ' '
        },
        {
            key: '4. Сынама үлгісі алынған орын (Место отбора образца)',
            value: actData?.selectionPlace?.value
        },
        {
            key: '5. Сынамалар алу мақсаты (Цель отбора)',
            value: actData?.samplingPurpose?.value?.name
        },
        {
            key: '6. Алынған күні мен уақыты (Дата и время отбора)',
            value: actData?.selectionDateTime?.value ? formatTime(new Date(actData?.selectionDateTime?.value)) : ' '
        },
        {
            key: '7. Жарамдылық мерзімі (Срок годности)',
            value: actData?.expirationDate?.value ? formatTime(new Date(actData?.expirationDate?.value)) : ' '
        },
        {
            key: '8. Жеткізілген күні мен уақыты (Дата и время доставки)',
            value: actData?.deliveryDateTime?.value ? formatTime(new Date(actData?.deliveryDateTime?.value)) : ' '
        },
        {
            key: '9. Сынама алу әдiсiне нормативтік құжат (Нормативный документ на метод отбора)',
            value: Array.isArray(actData?.standard?.value) ? actData?.standard?.value.map(el => {
                return el.name
            }).join(', ') : actData?.standard?.value?.name
        },
        {
            key: '10. Тасымалдау жағдайы (Условия транспортировки)',
            value: actData?.transportationCondition?.value?.name
        },
        {
            key: '11. Сақтау жағдайы (Условия хранения)',
            value: actData?.storageCondition?.value?.name
        },
        {
            key: '12. Таңбалау (маркировка)',
            value: actData?.mark?.value
        },
        {
            key: '13. Үлгілерді консервациялау әдiстерi (Методы консервации образца)',
            value: actData?.preservationMethod?.value?.name
        },
        {
            key: '14. Қосымша мәліметтер (Дополнительные сведения)',
            value: actData?.additionalInfo?.value
        }
    ]

    return (
        <Document>
            <Page size='A4' style={styles.page}>
                <Table style={styles.table}>
                    <Row>
                        <Cell></Cell>
                        <Cell>
                            <Text>Нысанның БҚСЖ бойынша коды</Text>
                            <Text>Код формы по ОКУД ___________________</Text>
                            <Text>КҰЖЖ бойынша ұйым коды</Text>
                            <Text>Код организации по ОКПО _____________</Text>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <Text>Қазақстан Республикасының Денсаулық сақтау министрлігі Министерство здравоохранения
                                Республики Казахстан</Text>
                        </Cell>
                        <Cell>
                            <Text>Қазақстан Республикасының Денсаулық сақтау министрінің 2021 жылғы "__" __________ №
                                бұйрығымен бекітілген №____004/е нысанды медициналық құжаттама</Text>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <Text>Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк органының атауы Наименование
                                государственного органа санитарно-эпидемиологической службы</Text>
                        </Cell>
                        <Cell>
                            <Text>Медицинская документация Форма № 004/у Утверждена приказом Министра здравоохранения
                                Республики Казахстан от "__" ______ 2021 года №______</Text>
                        </Cell>
                    </Row>
                </Table>
                <View style={styles.titleWorks} wrap>
                    <Text>{kazakhName}</Text>
                    <Text>{name}</Text>
                    <Text>№{data.number} от {actData?.dateOfAct?.value ? formatDate(new Date(actData.dateOfAct.value)) : ' '}</Text>
                </View>
                <Table style={styles.table}>
                    {schema.map(el => {
                        return <Row>
                            <Cell>
                                <Text style={{fontFamily: 'Times New Roman Bold'}}>
                                    {el.key}:
                                </Text>
                            </Cell>
                            <Cell>
                                <Text>
                                    {el.value || ' '}
                                </Text>
                            </Cell>
                        </Row>
                    })}
                </Table>
                <Table style={styles.table}>
                    {actData?.samples?.value?.map(el => {
                        return <Row>
                            <Cell>
                                <Text style={styles.sampleBold}>
                                    Үлгіні тiркеу нөмірi (Регистрационный номер образца):
                                </Text>
                                <Text style={styles.sampleRegular}>
                                    {el.registrationNumber?.value || ' '}
                                </Text>
                            </Cell>
                            <Cell>
                                <Text style={styles.sampleBold}>
                                    Алынған үлгілер атауының тізбесі (Перечень наименований отобранных образцов):
                                </Text>
                                <Text style={styles.sampleRegular}>
                                    {el.sample?.value || ' '}
                                </Text>
                            </Cell>
                            <Cell>
                                <Text style={styles.sampleBold}>
                                    Үлгінің көлемi (Объем образца):
                                </Text>
                                <Text style={styles.sampleRegular}>
                                    {el.size?.value || ' '}
                                </Text>
                            </Cell>
                            <Cell>
                                <Text style={styles.sampleBold}>
                                    Орама түрі мен мөр (пломба) нөмірі (Вид упаковки и номер печати (пломбы):
                                </Text>
                                <Text style={styles.sampleRegular}>
                                    {el.sealTypeAndNumber?.value || ' '}
                                </Text>
                            </Cell>

                        </Row>
                    })}
                </Table>
                <View style={{fontFamily: 'Times New Roman'}}>
                    <Text style={styles.simpleString}>Зерттеу жүргiзген маманның Т.А.Ә.(болған жағдайда), лауазымы
                        (Ф.И.О.(при наличии), должность специалиста проводившего
                        исследование): {data.performer?.fullName}</Text>
                    <Text style={styles.string}>Қолы(подпись) </Text>
                </View>
                <View style={{fontFamily: 'Times New Roman', marginTop: 10}}>
                    <Text style={styles.simpleString}>Зертхана меңгерушісінің Т.А.Ә. (болған жағдайда), қолы.
                        (Ф.И.О.(при наличии), подпись заведующего лабораторией): {data.responsible?.fullName}</Text>
                    <Text style={styles.string}>Қолы(подпись)</Text>
                    <Text style={styles.string}>Акт составлен в экземплярах(кол-во) _____</Text>
                </View>
                <View style={{fontFamily: 'Times New Roman', marginTop: 10}}>
                    <Text style={styles.text}>Сынама алу барысында қатысқан нысан өкiлiнiң Т.А.Ә. (болған жағдайда)
                        (Должность, Ф.И.О. (при наличии) представителя объекта, в присутствии которого произведен
                        отбор)_______________________________________________________
                        Қолы (Подпись) _____________________
                        Үлгілерді алған адамның лауазымы, Т.А.Ә. (болған жағдайда) (Должность, Ф.И.О. (при наличии)
                        производившего отбор образцов) ________________________________________________________
                        Қолы (Подпись)______________
                        Акт ___ данада толтырылды
                        (Акт составлен в ____ экземплярах)
                        Акт әр (1-ден 5 үлгiге дейiн) әкелiнген сынамаға толтырылады
                        (Акт заполняется на каждую представленные (от 1 до 5 образцов)
                    </Text>
                </View>
            </Page>
        </Document>
    )
}

export default function ActWaterPDFGenerator(props) {
    return (
        <>
            <PDFViewer style={{width: '100%', height: 700}}>
                <PDFDocument {...props} />
            </PDFViewer>
            <PDFDownloadLink document={<PDFDocument {...props} />} fileName={`${formatTime(new Date())}.pdf`}>
                {({url}) => url ? 'Скачать PDF' : 'Загрузка...'}
            </PDFDownloadLink>
        </>
    )
}
