import { $authHost } from "../../http";
import { 
    REQUEST_TYPE_GET_REQUEST, REQUEST_TYPE_GET_SUCCESS, REQUEST_TYPE_GET_ERROR, 
    REQUEST_TYPE_GET_BY_KEY_REQUEST, REQUEST_TYPE_GET_BY_KEY_SUCCESS, REQUEST_TYPE_GET_BY_KEY_ERROR, 
    REQUEST_TYPE_ADD_REQUEST, REQUEST_TYPE_ADD_SUCCESS, REQUEST_TYPE_ADD_ERROR, 
    REQUEST_TYPE_EDIT_REQUEST, REQUEST_TYPE_EDIT_SUCCESS, REQUEST_TYPE_EDIT_ERROR, 
    REQUEST_TYPE_DELETE_REQUEST, REQUEST_TYPE_DELETE_SUCCESS, REQUEST_TYPE_DELETE_ERROR
} from "../constants/requestTypeConstants";

export const getRequestTypes = (args) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_TYPE_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/requestType/query",
            args || {},
            config,
        );
        dispatch({
            type: REQUEST_TYPE_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: REQUEST_TYPE_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getRequestTypeByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_TYPE_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/requestType/${id}`,
        );
        dispatch({
            type: REQUEST_TYPE_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: REQUEST_TYPE_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addRequestType = ({ name, code }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_TYPE_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/requestType",
            { name, code },
            config,
        );
        dispatch(getRequestTypes(queryArgs || {}));
        dispatch({
            type: REQUEST_TYPE_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_TYPE_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editRequestType = ({ id, name, code }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_TYPE_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/requestType",
            { id, name, code },
            config,
        );
        dispatch(getRequestTypes(queryArgs || {}));
        dispatch({
            type: REQUEST_TYPE_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_TYPE_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteRequestType = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: REQUEST_TYPE_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/requestType",
            { data: { id } },
            config,
        );
        dispatch(getRequestTypes(queryArgs || {}));
        dispatch({
            type: REQUEST_TYPE_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: REQUEST_TYPE_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

