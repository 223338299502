import { $authHost } from "../../http";
import { 
    INFECTION_KIND_GET_REQUEST, INFECTION_KIND_GET_SUCCESS, INFECTION_KIND_GET_ERROR, 
    INFECTION_KIND_GET_BY_KEY_REQUEST, INFECTION_KIND_GET_BY_KEY_SUCCESS, INFECTION_KIND_GET_BY_KEY_ERROR, 
    INFECTION_KIND_ADD_REQUEST, INFECTION_KIND_ADD_SUCCESS, INFECTION_KIND_ADD_ERROR, 
    INFECTION_KIND_EDIT_REQUEST, INFECTION_KIND_EDIT_SUCCESS, INFECTION_KIND_EDIT_ERROR, 
    INFECTION_KIND_DELETE_REQUEST, INFECTION_KIND_DELETE_SUCCESS, INFECTION_KIND_DELETE_ERROR
} from "../constants/infectionKindConstants";

export const getInfectionKinds = (args) => async (dispatch) => {
    try {
        dispatch({
            type: INFECTION_KIND_GET_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const {data} = await $authHost.post(
            "/api/v1/infectionKind/query",
            args || {},
            config,
        );
        dispatch({
            type: INFECTION_KIND_GET_SUCCESS,
            payload: data.result,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: INFECTION_KIND_GET_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const getInfectionKindByKey = (id) => async (dispatch) => {
    try {
        dispatch({
            type: INFECTION_KIND_GET_BY_KEY_REQUEST
        });

        const {data} = await $authHost.get(
            `/api/v1/infectionKind/${id}`,
        );
        dispatch({
            type: INFECTION_KIND_GET_BY_KEY_SUCCESS,
            payload: data,
        });
        return data.result;
    } catch (error) {
        dispatch({
            type: INFECTION_KIND_GET_BY_KEY_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const addInfectionKind = ({ name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: INFECTION_KIND_ADD_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.post(
            "/api/v1/infectionKind",
            { name, code, description },
            config,
        );
        dispatch(getInfectionKinds(queryArgs || {}));
        dispatch({
            type: INFECTION_KIND_ADD_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: INFECTION_KIND_ADD_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const editInfectionKind = ({ id, name, code, description }, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: INFECTION_KIND_EDIT_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.put(
            "/api/v1/infectionKind",
            { id, name, code, description },
            config,
        );
        dispatch(getInfectionKinds(queryArgs || {}));
        dispatch({
            type: INFECTION_KIND_EDIT_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: INFECTION_KIND_EDIT_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const deleteInfectionKind = (id, queryArgs) => async (dispatch) => {
    try {
        dispatch({
            type: INFECTION_KIND_DELETE_REQUEST
        });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        await $authHost.delete(
            "/api/v1/infectionKind",
            { data: { id } },
            config,
        );
        dispatch(getInfectionKinds(queryArgs || {}));
        dispatch({
            type: INFECTION_KIND_DELETE_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: INFECTION_KIND_DELETE_ERROR,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

