import { 
    SERVICE_GET_REQUEST, SERVICE_GET_SUCCESS, SERVICE_GET_ERROR,
    SERVICE_GET_BY_KEY_REQUEST, SERVICE_GET_BY_KEY_SUCCESS, SERVICE_GET_BY_KEY_ERROR, 
    SERVICE_ADD_REQUEST, SERVICE_ADD_SUCCESS, SERVICE_ADD_ERROR, 
    SERVICE_EDIT_REQUEST, SERVICE_EDIT_SUCCESS, SERVICE_EDIT_ERROR, 
    SERVICE_DELETE_REQUEST, SERVICE_DELETE_SUCCESS, SERVICE_DELETE_ERROR,
    SERVICE_GET_CHILDS_REQUEST, SERVICE_GET_CHILDS_SUCCESS, SERVICE_GET_CHILDS_ERROR,
} from "../constants/serviceConstants";

const initialState = {
    services: [],
    service: {},
    parentService: null,
    loading: false,
    success: null,
    error: null,
    serviceRequestedCount: 0,
};

export const serviceReducer = (state = initialState, action) => {
    switch (action.type)     {
        case SERVICE_GET_REQUEST:
            return {
                ...state,
                serviceRequestedCount: ++state.serviceRequestedCount,
                loading: true,
            };
        case SERVICE_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                services: action.payload,
                success: true,
            };
        case SERVICE_GET_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_GET_CHILDS_REQUEST:
            return {
                ...state,
                loading: true,
                parentService: action.payload,
            };
        case SERVICE_GET_CHILDS_SUCCESS:
            state.parentService.childs = action.payload;
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_GET_CHILDS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_GET_BY_KEY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_GET_BY_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                service: action.payload,
                success: true,
            };
        case SERVICE_GET_BY_KEY_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_ADD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_ADD_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_EDIT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_EDIT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_EDIT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case SERVICE_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SERVICE_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };
        case SERVICE_DELETE_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
