import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Card,
  CardContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  CardActions,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { CheckBox, ControlPoint as PlusIcon } from "@mui/icons-material";

import {
  BasicModal,
  BasicModalHeader,
  BasicModalContainer,
  BasicModalFooter,
  BasicTable,
} from "./../../components";
import { EnhancedTable } from "../Table";

export const SelectFieldModal = React.memo(
  ({
    type,
    label,
    cells,
    actionFn,
    sx,
    valueName,
    value,
    setValue,
    modalHeading,
    modalButtonText,
    placeholder,
    disabled,
    required,
    acceptOnSubmit,
    usePagingByApi,
  }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState({});

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleRowClick = (e, row) => {
      if (acceptOnSubmit)
        return;

      setValue(row[valueName ?? "id"]);
      handleClose();
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      setValue(selected.value);
      handleClose();
    };

    useEffect(() => {
      console.log({ selected });
    }, [selected]);

    return (
      <>
        <TextField
          label={label}
          placeholder={placeholder}
          type={type}
          sx={sx}
          InputProps={{
            endAdornment: loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              !disabled && (
                <IconButton
                  children={<PlusIcon />}
                  onClick={disabled ? () => {} : handleOpen}
                />
              )
            ),
          }}
          value={value}
          required={required}
          disabled={disabled}
          onClick={disabled ? () => {} : handleOpen}
        />

        <BasicModal
          style={{ overflow: "auto" }}
          open={open}
          setOpen={setOpen}
          onClose={handleClose}
        >
          <BasicModalHeader heading={modalHeading} />
          <BasicModalContainer>
            <EnhancedTable
              cells={cells}
              rows={rows}
              actionFn={actionFn}
              selectedKey={valueName}
              selected={selected}
              setSelected={setSelected}
              onRowClick={handleRowClick}
              addButton={<></>}
              usePagingByApi={usePagingByApi}
            />
          </BasicModalContainer>

          <BasicModalFooter>
            {acceptOnSubmit && <Button onClick={handleSubmit}>{modalButtonText}</Button>}
          </BasicModalFooter>
        </BasicModal>
      </>
    );
  }
);

SelectFieldModal.defaultProps = {
  type: "text",
  cells: [],
  modalHeading: "Выберите запись",
  modalButtonText: "Выбрать запись",
  placeholder: "Выберите значение",
  disabled: false,
};
