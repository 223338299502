import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import CreateEntity from './Popups/CreateEntity';
import EditEntity from './Popups/EditEntity';
import DetailsEntity from './Popups/DetailsEntity';
import RemoveEntity from './Popups/RemoveEntity';

import EntityCRUDTable from './EntityCRUDTable';

export default function DictionaryCRUDTable({ hasCode = true, hasName = true, hasKZName = false, hasDescription = true, extraColumns, extraSearchFields, 
  createEntityTitle, 
  editEntityTitle,
  detailsEntityTitle,
  removeEntityTitle,
  createFields,
  editFields,
  detailsFields,
  removeFields,
  ...props }) {
  const columns = [
  ]

  hasName && columns.push({title: "Наименование", dataIndex: 'name', key: 'name'});
  hasKZName && columns.push({title: "Наименование(каз)", dataIndex: 'nameKz', key: 'nameKz'});
  hasCode && columns.push({title: "Код", dataIndex: 'code', key: 'code'});
  hasDescription && columns.push({title: "Описание", dataIndex: 'description', key: 'description'});
  extraColumns && columns.push(...extraColumns);

  const searchFields = [
  ];

  hasName && searchFields.push(
    {
      title: "Наименование",
      key: "name",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal"
    });

  hasKZName && searchFields.push(
    {
      title: "Наименование(каз)",
      key: "nameKz",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal"
    });  
  hasCode && searchFields.push(
    {
      title: "Код",
      key: "code",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal"
    });
  hasDescription && searchFields.push(
    {
      title: "Описание",
      key: "description",
      compareType: "like",
      operandPosition: 1,
      filterType: "normal"
    });
  extraSearchFields && searchFields.push(...extraSearchFields);

  let entity = props.entity;
  let setEntity = props.setEntity;

  const [useEntity, useSetEntity] = useState({});
  if (!entity) {
    entity = useEntity;
    setEntity = useSetEntity;
  }

  let clearEntity = props.clearEntity;

  if (!clearEntity) {
    clearEntity = async () => {
      await setEntity({});
    }
  }

  const setValue = (key, value) => {
    let newEntity = {...entity};
    newEntity[key] = value;
    setEntity(newEntity)
  }

  const createEntityFields = [
  ];
  hasName && createEntityFields.push(
    <TextField margin="normal" value={entity.name} onChange={(e) => setValue("name", e.target.value)} fullWidth id="name" name="name" label="Наименование" />
  );
  hasKZName && createEntityFields.push(
    <TextField margin="normal" value={entity.nameKz} onChange={(e) => setValue("nameKz", e.target.value)} fullWidth id="nameKz" name="nameKz" label="Наименование(каз)" />
  );
  hasCode && createEntityFields.push(
    <TextField margin="normal" value={entity.code} onChange={(e) => setValue("code", e.target.value)} fullWidth id="code" name="code" label="Код" />
  );
  hasDescription && createEntityFields.push(
    <TextField margin="normal" value={entity.description} onChange={(e) => setValue("description", e.target.value)} fullWidth id="description" name="description" label="Описание" />
  );
  createFields && createEntityFields.push(...createFields);

  const editEntityFields = [
  ];
  hasName && editEntityFields.push(
    <TextField margin="normal" value={entity.name} onChange={(e) => setValue("name", e.target.value )} fullWidth id="name" name="name" label="Наименование" />
  );
  hasKZName && editEntityFields.push(
    <TextField margin="normal" value={entity.nameKz} onChange={(e) => setValue("nameKz", e.target.value )} fullWidth id="nameKz" name="nameKz" label="Наименование(каз)" />
  );
  hasCode && editEntityFields.push(
    <TextField margin="normal" value={entity.code} onChange={(e) => setValue("code", e.target.value)} fullWidth id="code" name="code" label="Код" />
  );
  hasDescription && editEntityFields.push(
    <TextField margin="normal" value={entity.description} onChange={(e) => setValue("description", e.target.value)} fullWidth id="description" name="description" label="Описание" />
  );
  editFields && editEntityFields.push(...editFields);

  const detailsOrRemoveEntityFields = [
  ];
  hasName && detailsOrRemoveEntityFields.push(
    "Наименование: " + entity.name
  );
  hasName && detailsOrRemoveEntityFields.push(
    "Наименование(каз): " + entity.nameKz
  );
  hasCode && detailsOrRemoveEntityFields.push(
    "Код: " + entity.code
  );
  hasDescription && detailsOrRemoveEntityFields.push(
    "Описание: " + entity.description
  );
  detailsFields && detailsOrRemoveEntityFields.push(...detailsFields);

  return <>
    <EntityCRUDTable
      {...props}
      searchFields={searchFields}
      columns={columns}
      createFields={createEntityFields}
      editFields={editEntityFields}
      detailsFields={detailsOrRemoveEntityFields}
      removeFields={detailsOrRemoveEntityFields}
      createEntityTitle={createEntityTitle}
      editEntityTitle={editEntityTitle}
      detailsEntityTitle={detailsEntityTitle}
      removeEntityTitle={removeEntityTitle}
      entity={entity}
      setEntity={setEntity}
      setValue={setValue}
      clearEntity={clearEntity}
    />
  </>
}