import m_08_2030 from '././taraz/KZ.M.08.2030.svg'
import m_08_2030_b from '././taraz/KZ.M.08.2030 black.svg'
import t_08_2302 from '././ryskul/KZ.T.08.2302.svg'
import t_08_2302_b from '././ryskul/KZ.T.08.2302 black.svg'
import t_08_2310 from '././talas/KZ.T.08.2310.svg'
import t_08_2310_b from '././talas/KZ.T.08.2310 black.svg'
import t_08_2370 from '././shu/KZ.T.08.2370.svg'
import t_08_2370_b from '././shu/KZ.T.08.2370 black.svg'
import t_08_e0663_colorized from '././taraz/KZ.T.08.Е0663.svg'
import t_08_e0663 from '././taraz/KZ.T.08.Е0663 black.svg'

export default [
  m_08_2030,
  m_08_2030_b,
  t_08_2302,
  t_08_2302_b,
  t_08_2310,
  t_08_2310_b,
  t_08_2370,
  t_08_2370_b,
  t_08_e0663_colorized,
  t_08_e0663
]
