import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TextField from "@mui/material/TextField";
import ruLocale from "date-fns/locale/ru";
import React from "react";

const DirectionCholeraForm = ({ isDisabledData, data, handleChangeData, handleChangeMulti, handleChangeSample }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          1. Зерттелушінің Т.А.Ә. (болған жағдайда) (Ф.И.О. (при наличии) обследуемого)
        </p>
        <TextField
          id="outlined-basic"
          label="ФИО"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.examineName?.value}
          onChange={(e) => handleChangeData('examineName', e.target.value, 'examineName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          2. Жасы (Возраст)
        </p>
        <TextField
          id="outlined-basic"
          label="Возраст"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.age?.value}
          onChange={(e) => handleChangeData('age', e.target.value, 'age')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>3. Мекен-жайы (Адрес)</p>
        <TextField
          id="outlined-basic"
          label="адрес"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.adress?.value}
          onChange={(e) => handleChangeData('adress', e.target.value, 'adress')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>4. Жұмыс орны (Место работы)</p>
        <TextField
          id="outlined-basic"
          label="Место работы"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.workPlace?.value}
          onChange={(e) => handleChangeData('workPlace', e.target.value, 'workPlace')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>5. Диагнозы (Диагноз)</p>
        <TextField
          id="outlined-basic"
          label="Диагноз"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.diagnosis?.value}
          onChange={(e) => handleChangeData('diagnosis', e.target.value, 'diagnosis')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          6. Наманың алынған күні және уақыты (Дата и время забора материала)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время забора материала"
            disabled={isDisabledData}
            value={data.data?.takeDateTime?.value}
            onChange={(value) => {
              handleChangeData('takeDateTime', value, 'takeDateTime')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          7. Бірінші рет немесе қайталап (Первичный или повторный)
        </p>
        <FormControl style={{ width: "50%", margin: '0 0 0 30px' }}>
          <InputLabel id="Первичный или повторный">Первичный или повторный</InputLabel>
          <Select
            labelId="Первичный или повторный"
            label="Первичный или повторный"
            disabled={isDisabledData}
            value={data.data?.firstTimeOrRepeated?.value}
            onChange={(e) => handleChangeData('firstTimeOrRepeated', e.target.value, 'firstTimeOrRepeated')}
          >
            <MenuItem value={'Первично'}>{'Первично'}</MenuItem>
            <MenuItem value={'Повторно'}>{'Повторно'}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          8. Антибиотикті қабылдағаны туралы (Применения антибиотика)
        </p>
        <TextField
          id="outlined-basic"
          label="Применения антибиотика"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.antibioticsInfo?.value}
          onChange={(e) => handleChangeData('antibioticsInfo', e.target.value, 'antibioticsInfo')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          9. Жіберген мекеме (Направляющее учреждения)
        </p>
        <TextField
          id="outlined-basic"
          label="Направляющее учреждения"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.placeSender?.value}
          onChange={(e) => handleChangeData('placeSender', e.target.value, 'placeSender')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          10. Үлгіні жіберген адамның Т.А.Ә (болған жағдайда), (Ф.И.О
          (при наличии), направившего материал)
        </p>
        <TextField
          id="outlined-basic"
          label="Ф.И.О (при наличии), подпись направившего материал"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.senderName?.value}
          onChange={(e) => handleChangeData('senderName', e.target.value, 'senderName')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>11. Сынаманың № (№ анализа)</p>
        <TextField
          id="outlined-basic"
          label="№ анализа"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          type="number"
          disabled={isDisabledData}
          value={data.data?.analysis?.value}
          onChange={(e) => handleChangeData('analysis', e.target.value, 'analysis')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          12. Қабылдау күні және уақыты (Дата и время приема)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DateTimePicker views={['day']} label="Дата и время приема"
            disabled={isDisabledData}
            value={data.data?.receptionDateTime?.value}
            onChange={(value) => {
              handleChangeData('receptionDateTime', value, 'receptionDateTime')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          13. Зерттеу қорытындысы (Результат исследования)
        </p>
        <TextField
          id="outlined-basic"
          label="Результат исследования"
          style={{ width: "50%", margin: '0 0 0 30px' }}
          variant="outlined"
          disabled={isDisabledData}
          value={data.data?.result?.value}
          onChange={(e) => handleChangeData('result', e.target.value, 'result')}
        />
      </div>
      <div
        style={{
          display: "flex",
          marginBottom: "5px",
          alignItems: "center",
        }}
      >
        <p style={{ width: "50%" }}>
          14. Қорытынды берілген күн (Дата выдачи ответа)
        </p>
        <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
          <DatePicker views={['day']} label="Дата выдачи ответа"
            disabled={isDisabledData}
            value={data.data?.responseDate?.value}
            onChange={(value) => {
              handleChangeData('responseDate', value, 'responseDate')
            }}
            minDate={new Date('1910-03-01')} renderInput={(params) =>
              <TextField style={{ width: "50%", margin: "0 0 0 30px" }} margin="normal"  {...params} helperText={null} />
            } />
        </LocalizationProvider>
      </div>
    </div >
  );
};

export default DirectionCholeraForm;
