
import { DatePicker, DateTimePicker, LoadingButton } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { CircularProgress, InputAdornment, Toolbar } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from "@mui/material/Paper";
import Select from '@mui/material/Select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ruLocale from "date-fns/locale/ru";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

import useDialogs from "../../components/Dialog/useDialogs";
import RemovePopup from "../../components/Popups/RemovePopup";
import DoctorField from "../../components/Relations/DoctorField";
import EmployeeField from "../../components/Relations/EmployeeField";
import ResearchListField from "../../components/Relations/ResearchListField";
import { DepartmentSelect, DivisionSelect, FilialDivisionSelect, FilialSelect } from "../../components/Select/domainSelects";
import { checkRoles } from "../../functions/checkRoles";
import useLoader from "../../hooks/useLoader";
import { actCRUD } from "../../http/CRUD";
import DashboardLayout from "../../layouts/Dashboard";
import { getActForms } from "../../redux/actions/actFormActions";
import { addProtocol } from "../../redux/actions/protocolActions";
import { REQUEST_STATE_ID_COMPLETED, REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_PROBESELECTION, REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH } from "../../redux/constants/requestStateConstants";
import CreateProtocol from "../Protocol/Popups/CreateProtocol";
import DirectionPDF from "./DirectionPDF";
import DirectionResult from './DirectionResult'

const withDirectionDetail = (DirectionForm, DirectionPDFGenerator, directionFormKazakhName, directionFormName, directionFormNumber, directionName) => {
  return () => {
    const { alert, DIALOG } = useDialogs();
    const { t } = useTranslation();
    const actForm = useSelector(state => state.actForm)
    const { id } = useParams();
    const isNew = useMemo(() => !(id > 0), [id]);
    const [data, setData] = useState({});
    const { start: startSubmit, stop: stopSubmit, loading: loadingSubmit } = useLoader(false);
    const { start: workflowStart, stop: workflowStop, loading: workflowLoading } = useLoader(false);
    const style = { marginLeft: 10 }
    const stateCode = data.state?.code;

    const handleChange = useCallback((key, value) => {
      setData({ ...data, [key]: value })
    }, [data]);
    const handleChangeMulti = useCallback((obj) => {
      setData({ ...data, ...obj })
    }, [data]);

    const handleChangeData = useCallback((key, value, key2) => {
      setData({ ...data, data: { ...data.data, [key]: { 'key': key2, value } } })
    }, [data]);

    const handleChangeSample = useCallback((key, value, i, key2) => {
      const array = data.data?.samples?.value.slice(0)
      array[i][key] = { 'key': key2, value }
      setData({
        ...data, data: {
          ...data.data,
          samples: { key: 'samples', value: array }
        }
      })
    }, [data]);

    const deleteSample = useCallback((index) => {
      setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: data.data?.samples?.value.filter((el, i) => index !== i) } } })
    }, [data]);

    const dispatch = useDispatch();
    const { push } = useHistory();

    const schema = {}

    const { loading: fetchLoading, start: fetchStart, stop: fetchStop } = useLoader(true);
    const fetch = useCallback(() => {
      if (isNew) {
        fetchStop();
        return;
      }
      fetchStart();
      actCRUD.get(id)
        .then((data) => {
          let actData = typeof data.data === 'string' ? JSON.parse(data.data) : {}
          setData({ ...data, data: { ...actData } });
        })
        .catch(alert)
        .finally(fetchStop)
    }, [id, fetchStart, fetchStop, isNew]);
    useEffect(fetch, [fetch]);

    const submit = useCallback(() => new Promise((resolve, reject) => {
      if (!data.filialId) {
        alert('Филиал нужно заполнить!');
        return
      }
      const actFormObj = actForm.actForms.find(a => {
        return a.number === directionName
      })
      const newData = { ...data }
      delete newData.responsible
      delete newData.responsibleForProbes
      delete newData.performer
      startSubmit();
      (isNew
        ? actCRUD.create({
          createdAt: new Date(),
          number: directionName,
          schema,
          ...newData,
          form: actFormObj,
          formId: actFormObj.id
        })
        : actCRUD.edit({
          id: id,
          number: directionName,
          schema,
          ...newData,
          form: actFormObj,
          formId: actFormObj.id
        })
      )
        .then((response) => {
          if (isNew && response.id) {
            push(`/${directionName}Detail/${response.id}`)
          } else {
            fetch();
          }
          resolve();
          // onSubmit();
        })
        .catch((error) => {
          reject();
          alert(error);
        })
        .finally(stopSubmit)
    }), [data, startSubmit, stopSubmit, isNew, fetch, push, actForm]);

    const setStatus = (lastStateCode, nextStateCode, thenAction = fetch) => {
      const validates = [];
      const validateNull = (key, label) => {
        if (!data[key]) validates.push(label + " нужно заполнить!");
      };
      if (nextStateCode === REQUEST_STATE_ID_PROBESELECTION) {
        validateNull("responsibleForProbesId", "Ответственный за отбор проб");
      } else if (nextStateCode === REQUEST_STATE_ID_RESEARCH) {
        validateNull("performerId", "Лаборант");
      } else if (nextStateCode === REQUEST_STATE_ID_PROCESSING) {
        validateNull("responsibleForProbesId", "Ответственный за отбор проб");
        validateNull("departmentId", "Лаборатория");
        validateNull("responsibleId", "Заведующий лаборатории/Начальник отдела");
      }
      if (validates.length > 0) {
        alert(validates.join("\n"));
        return;
      }
      workflowStart();
      submit()
        .then(() => actCRUD.workflowRun(id, lastStateCode, nextStateCode))
        .then(thenAction)
        .catch(alert)
        .finally(workflowStop);
    };

    const hasResults = useMemo(() => data.results && data.results.length > 0, [data]);
    const hasProtocols = useMemo(() => data.protocols && data.protocols.length > 0, [data]);

    const [protocolAddOpen, setProtocolAddOpen] = useState(false);
    const [createResultOpen, setCreateResultOpen] = useState(false);
    const [deleteActOpen, setDeleteActOpen] = useState(false);

    useEffect(() => {
      if (actForm.actForms.length === 0) {
        dispatch(getActForms({ paging: { skip: 0, take: 25 } }))
      }
    }, [actForm.actForms.length])

    const [isDisabledData, setIsDisabledData] = useState(false)
    const [isDisabledPerformer, setIsDisabledPerformer] = useState(false)
    useEffect(() => {
      const returnIsDisabledData = () => {
        return ([REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_COMPLETED].includes(stateCode) || !checkRoles('SuperAdministrator', 'Administrator', 'Specialist', "Committee", "CommitteeAdministrator"))
      }
      const returnIsDisabledPerformer = () => {
        return (([REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_COMPLETED].includes(stateCode) || !checkRoles('SuperAdministrator', 'Administrator', 'Supervisor', "Committee", "CommitteeAdministrator") || stateCode === undefined))
      }
      setIsDisabledData(returnIsDisabledData())
      setIsDisabledPerformer(returnIsDisabledPerformer())
    }, [stateCode])

    const addSample = useCallback(() => {
      data.data?.samples?.value
        ? setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [...data.data?.samples?.value, {}] } } })
        : setData({ ...data, data: { ...data.data, samples: { key: 'samples', value: [{}] } } })
    }, [data]);

    return (
      <DashboardLayout style={{ display: "flex", flexDirection: "column" }}>
        <DIALOG />
        <Button
          style={{
            marginBottom: '10px'
          }}
          variant="contained"
          type="button"
          onClick={() => { push(`/${directionName}`) }}
        >
          Вернуться
        </Button>
        {fetchLoading
          ? <CircularProgress />
          : stateCode === REQUEST_STATE_ID_COMPLETED
            ? <DirectionPDF data={data} name={directionFormName} kazakhName={directionFormKazakhName}>
              <DirectionPDFGenerator data={data} name={directionFormName} kazakhName={directionFormKazakhName} />
            </DirectionPDF>
            : <Box component="form" onSubmit={submit}>
              <Paper style={{ marginBottom: '15px' }}>
                <Toolbar>
                  <Typography style={{ marginRight: "auto" }} variant={"h6"}>Статус направления: {data.state?.name || "создаётся"}</Typography>
                  {/* {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist', "Committee", "CommitteeAdministrator") && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} 
                  onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROBESELECTION)}>Отбор Пробы</LoadingButton>} */}
                  {REQUEST_STATE_ID_CREATED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist', "Committee", "CommitteeAdministrator") && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} 
                  onClick={() => setStatus(REQUEST_STATE_ID_CREATED, REQUEST_STATE_ID_PROCESSING)}>Отправить в лабораторию</LoadingButton>}
                  {/* {REQUEST_STATE_ID_PROBESELECTION === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} 
                  onClick={() => setStatus(REQUEST_STATE_ID_PROBESELECTION, REQUEST_STATE_ID_PROCESSING)}>Отправить в лабораторию</LoadingButton>} */}
                  {REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} 
                  onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_RESEARCH)}>Назначить исполнителя</LoadingButton>}
                  {REQUEST_STATE_ID_PROCESSING === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Supervisor') && <LoadingButton style={style} variant={"contained"} color={"warning"} loading={workflowLoading} 
                  onClick={() => setStatus(REQUEST_STATE_ID_PROCESSING, REQUEST_STATE_ID_DECLINED)}>Отклонить с комментариями</LoadingButton>}
                  {REQUEST_STATE_ID_RESEARCH === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} 
                  onClick={() => setStatus(REQUEST_STATE_ID_RESEARCH, REQUEST_STATE_ID_COMPLETED)}>Завершить</LoadingButton>}
                  {(REQUEST_STATE_ID_RESEARCH === stateCode) && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && !hasProtocols && <LoadingButton
                    style={style} variant={"outlined"} loading={workflowLoading}
                    onClick={() => setCreateResultOpen(true)}
                  >Добавить запись в журнал</LoadingButton>}
                  {REQUEST_STATE_ID_RESEARCH === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Performer') && <Tooltip title={!hasResults ? "Необходима запись в журнале" : (hasProtocols ? "Протокол уже создан" : "Создание протокола")}>
                    <div style={style}>
                      <LoadingButton variant={"outlined"} loading={workflowLoading} onClick={() => setProtocolAddOpen(true)} disabled={!hasResults || hasProtocols}>Создать протокол</LoadingButton>
                    </div>
                  </Tooltip>}
                  {REQUEST_STATE_ID_DECLINED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist', "Committee", "CommitteeAdministrator") && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setStatus(REQUEST_STATE_ID_DECLINED, REQUEST_STATE_ID_CREATED)}>Пересоздать</LoadingButton>}
                  {REQUEST_STATE_ID_DECLINED === stateCode && checkRoles('SuperAdministrator', 'Administrator', 'Specialist', "Committee", "CommitteeAdministrator") && <LoadingButton style={style} variant={"contained"} loading={workflowLoading} onClick={() => setDeleteActOpen(true)} color={"warning"}>Удалить</LoadingButton>}
                </Toolbar>
              </Paper>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody className="border-table-word">
                      <TableRow>
                        <TableCell className="border-table-word"></TableCell>
                        <TableCell
                          className="border-table-word"
                          align="left"
                        >
                          <p>Нысанның БҚСЖ бойынша коды</p>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ width: "50%" }}>Код формы по ОКУД</p>
                            <TextField
                              id="outlined-basic"
                              label="Код"
                              style={{ width: "50%" }}
                              variant="outlined"
                            />
                          </div>
                          <p>КҰЖЖ бойынша ұйым коды</p>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ width: "50%" }}>Код организации по ОКПО</p>
                            <TextField
                              id="outlined-basic"
                              label="Код"
                              style={{ width: "50%" }}
                              variant="outlined"
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="border-table-word">
                          <p>
                            Қазақстан Республикасының Денсаулық сақтау министрлігі
                            Министерство здравоохранения Республики Казахстан
                          </p>
                        </TableCell>
                        <TableCell
                          className="border-table-word"
                          align="left"
                        >
                          <p>
                            Қазақстан Республикасының Денсаулық сақтау министрінің
                            2021 жылғы "20" 08 № 84 бұйрығымен бекітілген № {directionFormNumber}/е
                            нысанды медициналық құжаттама
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="border-table-word">
                          <p>
                            Санитариялық-эпидемиологиялық қызметтiң мемлекеттiк
                            органының атауы Наименование государственного органа
                            санитарно-эпидемиологической службы
                          </p>
                        </TableCell>
                        <TableCell
                          className="border-table-word"
                          align="left"
                        >
                          <p>
                            Медицинская документация Форма № {directionFormNumber}/у Утверждена
                            приказом Министра здравоохранения Республики Казахстан
                            от "20" 08 2021 года №84
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div style={{ margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <h5 align='center'>
                  {directionFormKazakhName}
                </h5>
                <h5 align='center'>
                  {directionFormName} (от)
                </h5>
                <LocalizationProvider locale={ruLocale} dateAdapter={AdapterDateFns}>
                  <DatePicker views={['day']} label="от"
                    disabled={isDisabledData}
                    value={data.data?.dateOfDirection?.value}
                    onChange={(value) => {
                      handleChangeData('dateOfDirection', value, 'dateOfDirection')
                    }}
                    minDate={new Date('1910-03-01')} renderInput={(params) =>
                      <TextField margin="normal"  {...params} helperText={null} />
                    } />
                </LocalizationProvider>
              </div>

              {data.number && <h5 style={{ marginBottom: "30px" }} align="center">
                №{data.number}
              </h5>}
              {REQUEST_STATE_ID_RESEARCH === stateCode && <ResearchListField
                label='Список Исследований'
                value={data.specifications}
                onChange={(val) => handleChange("specifications", val)}
              />}
              <DirectionForm addSample={addSample}
                handleChangeSample={handleChangeSample}
                isDisabledData={isDisabledData} data={data}
                handleChangeData={handleChangeData}
                handleChangeMulti={handleChangeMulti}
                deleteSample={deleteSample} />
              <EmployeeField
                style={{ marginBottom: "5px" }}
                label={"Ответственный за отбор проб"}
                value={data.responsibleForProbesId}
                disabled={isDisabledData}
                object={data.responsibleForProbes} onChange={(val) => {
                  setData({ ...data, responsibleForProbesId: val?.id, responsibleForProbes: val })
                }}
              />
              <FilialSelect
                style={{ marginBottom: "5px" }}
                label={"Филиал"}
                fullWidth
                disabled={isDisabledData}
                //filialId={checkRoles('AdministratorReadonly') ? null : JSON.parse(localStorage.employee)?.filialId}
                value={data.filialId}
                onChange={(val) => {
                  handleChangeMulti({
                    filialId: val,
                    filialDivisionId: Number(val) !== Number(data.filialId) ? null : data.filialDivisionId
                  })
                }}
              />
              <FilialDivisionSelect
                style={{ marginBottom: "5px" }}
                label={"Районное отделение"}
                fullWidth
                disabled={isDisabledData}
                value={data.filialDivisionId}
                filialId={data.filialId === 1 ? null : data.filialId}
                onChange={(filialDivisionId, filialDivision) => {
                  handleChangeMulti({
                    filialDivisionId: filialDivisionId,
                    filialId: Number(filialDivision.filialId) !== Number(data.filialId) ? filialDivision.filialId : data.filialId
                  })
                }}
              />
              <Typography gutterBottom variant={'h5'}>Передача проб в лабораторию:</Typography>
              <DepartmentSelect
                disabled={isDisabledData}
                style={{ marginBottom: "5px" }}
                label={"Лаборатория"}
                fullWidth
                value={data.departmentId}
                onChange={(val) => handleChange('departmentId', val)}
              />
              <DivisionSelect
                style={{ marginBottom: "5px" }}
                label={"Отдел"}
                fullWidth
                value={data.divisionId}
                disabled={isDisabledData}
                onChange={(val) => handleChange('divisionId', val)}
                filterOptions={(opt) => !data.departmentId || (Number(opt.departmentId) === Number(data.departmentId))}
              />
              <EmployeeField
                label={"Заведующий лаборатории/Начальник отдела"}
                fullWidth
                extraFilter={data.departmentId > 0 ? { departmentId: { operand1: data.departmentId, operator: "equals" } } : null}
                disabled={isDisabledData}
                object={data.responsible}
                value={data.responsibleId}
                onChange={(responsible) => {
                  const obj = {
                    responsible,
                    responsibleId: responsible.id,
                    departmentId: responsible.departmentId,
                  };
                  handleChangeMulti(obj);
                }}
              />
              <EmployeeField
                label={"Лаборант"} fullWidth
                object={data.performer}
                value={data.performerId}
                disabled={isDisabledPerformer}
                onChange={(performer) => {
                  const obj = {
                    performer,
                    performerId: performer.id,
                  };
                  handleChangeMulti(obj);
                }}
              />
              <LoadingButton style={{
                border: "1px solid black",
                padding: "5px 10px",
                borderRadius: "6px",
                marginRight: '15px'
              }}
                variant="contained"
                // disabled={isDisabledData}
                onClick={submit}
                loading={loadingSubmit}>
                {isNew ? "Создать" : "Сохранить"}
              </LoadingButton>
            </Box>}
        {
          createResultOpen && <DirectionResult
            title={'Создание журнала направления'}
            onClose={() => setCreateResultOpen(false)}
            onSubmit={(response) => {
              fetch();
            }}
            act={data}
          />
        }
        {
          deleteActOpen && <RemovePopup
            deleteAction={() => actCRUD.delete(id)}
            title={`Вы действительно хотите удалить направление №${data.number}?`}
            onClose={() => setDeleteActOpen(false)}
            onSubmit={() => push(`/${directionName}`)}
          />
        }
        {
          protocolAddOpen && <CreateProtocol
            action={"createByAct"}
            open={protocolAddOpen}
            initNumber={data.number}
            title={`Создание протокола для направления №${data.number}?`}
            onClose={() => setDeleteActOpen(false)}
            onSubmit={(data) => {
              return dispatch(addProtocol(data, { "sort": { "id": { "operator": "asc" } } }))
                .then(fetch);
            }}
            setOpen={setProtocolAddOpen}
            request={data}
          />
        }
      </DashboardLayout >
    );
  };
}

export default withDirectionDetail