import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputMask from "react-input-mask";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import { CircularProgress, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function CreateReagent({ action, title, open, setOpen, onSubmit }) {
    const dispatch = useDispatch();
    const [id, setId] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState(null);
    const { loading, reagents, reagent } = useSelector((state) => state.reagent);
    const emptyState = () => {
        setId(null);
        setName('');
        setDescription('');
        setPrice(null);
    };
    const handleForm = async (e) => {
        e.preventDefault();
        onSubmit({ id, name, description, price });
        setOpen(false);
        emptyState();
    };
    const handleClose = async () => {
        setOpen(false);
        emptyState();
    };
    useEffect(() => {
        const {
            id, name, description, price
        } = reagent || {};
        setId(id);
        setName(name);
        setDescription(description);
        setPrice(price);
    }, [reagent, reagents])
    useEffect(() => {
        let response;
    }, [dispatch, action])
    return (
        <div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                {loading ? <CircularProgress/> :
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {title}
                        </Typography>
                        <Box component="form" onSubmit={handleForm} sx={{mt: 1, display: 'flex', flexWrap: 'wrap'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField margin="normal" required value={name} onChange={(e) => setName(e.target.value)} fullWidth id="name" name="name" label="Наименование" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={description} onChange={(e) => setDescription(e.target.value)} fullWidth id="description" name="description" label="Описание" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField margin="normal"  value={price} onChange={(e) => setPrice(e.target.value)} fullWidth id="price" name="price" label="Стоимость" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                                </Grid>
                                {action != 'details' && 
                                    <Grid item xs={6}>
                                        <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
                                            {'Сохранить'}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Box>
                }
            </Modal>
        </div>
    )
}
